import React, { useState, useEffect } from "react";
import mapImg from "../../images/MobileMap.png";
import BedSmallIcn from "../../images/bed-small-icn.svg";
import BathSmallIcn from "../../images/bath-small-icn.svg";
import SqftSmallIcn from "../../images/sqft-small-icn.svg";
import PropIconHouse from "../../../images/property-icon-house.svg";
import HearttIcon from "../../../images/mob-like-outline-btn.svg";
import HearttIconFilled from "../../../images/mob-like-filled-btn.svg";
import Dropdown from "react-bootstrap/Dropdown";

import WantAdMoreIcon from "../../../images/want-ad-more-icon.svg";
import {
  setLotSizeValue,
  wantAdBudgetValue,
  wantAdSqftValue,
} from "../../../helpers/createWantAd";
import {
  ADTYPE_BUY,
  ADTYPE_LTR,
  ADTYPE_STR,
  BUDGET_NOMIN_NOMAX,
  DRAFT_TAB,
  IS_LAND,
  PAUSED_TAB,
  PUBLISHED_TAB,
  WANT_AD_KEY,
} from "../../../constants/common";
import { setLocalStorage, truncate, getDisplayTextForClientFilledWantAds, locationDisplayInListing } from "../../../helpers/common";
import { pageURLs } from "../../../constants/pageURLs";
import { getPropertyIcon } from "../../../helpers/getPropertyIcons";
import noImage from "../../../images/no-image-placeholder.png";
import { convertToNumber } from "../../../helpers/validations";
import wantAdListContext from "../../../contextApi/wantAdListContext";
import { useNavigate } from "react-router-dom";
import LakeViewImage from "../../../images/lake-view-icon.svg";
import _ from "lodash";
import { getWantAdById } from "../../../services/wantAdService";
import ViewADDropdown from "../ViewAdDropdown";

const WantAdFeatures = (props) => {
  const listContext = wantAdListContext();
  const navigate = useNavigate();
  const {
    list,
    status,
    noAdMessage,
    isFromAgentDashboard,
    clienFirstName,
    isFromFavorite,
    handleFavoriteWantAd,
    clientId,
    clientName,
    favCount,
    updateStateAfterDelete
  } = listContext;

  const showWantAdDetails = (wantAdId, status) => {
    //if (status === PUBLISHED_TAB) {
    let statesToUpdate = { wantAdId: wantAdId, introId: 1 };
    if (isFromFavorite) {
      statesToUpdate.isFromFavorite = true;
      statesToUpdate.clientId = clientId;
      statesToUpdate.clientName = clientName;
      statesToUpdate.favCount = favCount;
    } else if (!isFromAgentDashboard) {
      statesToUpdate.isFromMyWantAd = true;
    } else  if (isFromAgentDashboard) {
      statesToUpdate.clientId = clientId;
      statesToUpdate.isFromAgentDashboard = true;
    }
    setLocalStorage(WANT_AD_KEY, statesToUpdate);
    navigate(pageURLs.viewAd);
    //}
  };

  const loadNiceToHaveList = (N2H) => {
    let count = 0;
    let nice = N2H?.slice(0, 3);
    return (
      nice &&
      nice.map((nh, index) => {
        //   if (count) {

        count++;
        const id = _.get(nh, "N2H_Id");
        const itemText = _.get(nh, "Text", "");
        const icon = _.get(nh, "Icon", "");
        return (
          <>
            <div
              className="property-tag-viewad"
              key={index + "_" + _.get(nh, "Want_ad_Id", "")}
            >
              <div className="tag-icon-mobile-viewad">
                <img
                  src={
                    icon ? require("../../../images/" + icon) : LakeViewImage
                  }
                />
              </div>
              <span>{itemText}</span>
            </div>
          </>
        );
      })
    );
  };

  const editWantAd = async (wantAdId, isFromUnPause) => {
    if (wantAdId) {
      await getWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if(isFromUnPause){
              navigate(pageURLs.mobileEditWantAd, {
                state: { data: responseResult, isFromMyWantAd: true, isFromUnPause: true },
              });
            } else{
              navigate(pageURLs.mobileEditWantAd, {
                state: { data: responseResult, isFromMyWantAd: true },
              });
            }
            
          
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.createwantad);
    }
  };



  if (list) {
    if (list?.length === 0) {
      if (isFromAgentDashboard) {
        return (
          <div className="no-data d-flex align-items-center justify-content-center flex-column text-center">
            <h4>You don’t have any published want-ads for this client</h4>
            <p className="m-0">
              Let other’s know what {clienFirstName} is looking for by posting a
              new want-ad. Tap the button below to get started!
            </p>
          </div>
        );
      } else if (isFromFavorite) {
        if (clientId) {
          return (
            <div className="no-data d-flex align-items-center justify-content-center flex-column text-center">
              <h4>You don’t have any favorited want-ads for this client</h4>
              <p className="m-0">
                Use the explore tab to browse for want-ads. When you find one
                you want to favorite, use the Heart icon in the top right
                corner.
              </p>
            </div>
          );
        } else {
          return (
            <div className="no-data d-flex align-items-center justify-content-center flex-column text-center">
              <h4>You don’t have any favorited want-ads</h4>
              <p className="m-0">
                Use the explore tab to browse for want-ads. When you find one
                you want to favorite, use the Heart icon in the top right
                corner.
              </p>
            </div>
          );
        }
      } else {
        return (
          <div className="no-result-wrap no-result-wrap-pad">
            <h3>{noAdMessage || "There aren't any want-ads."}</h3>
          </div>
        );
      }
    } else {
      return list?.map((wantAd, index) => {
        const mapImg = _.get(wantAd, "Location_Img", "");
        const PropertyTypeId = _.get(wantAd, "PropertyTypeId", "");
        const subPropertyTypeId = _.get(wantAd, "subPropertyTypeId", "");
        const Buy_or_Rent = convertToNumber(_.get(wantAd, "Buy_or_Rent", 1));
        const IsFilledByClient = _.get(wantAd, "IsFilledByClient", 0);
        const IsReadyToReview = _.get(wantAd, "IsReadyToReview", 0);
        let budget = wantAdBudgetValue(
          _.get(wantAd, "Budget_mn", ""),
          _.get(wantAd, "Budget_mx", "")
        );
        if (budget !== BUDGET_NOMIN_NOMAX) {          
          if (Buy_or_Rent === ADTYPE_LTR) {
            budget = budget + "/Month";
          }
          if (Buy_or_Rent === ADTYPE_STR) {
            budget = budget + "/Night";
          }
        }
        const sqFt = wantAdSqftValue(wantAd?.Sqft_mn, wantAd?.Sqft_mx);
        const ltSize = setLotSizeValue(wantAd?.LotSz_mn, wantAd?.LotSz_mx);

        return (
          <div className="property-listing-mob" key={index}>
            {isFromFavorite && (
              <a
                role="button"
                className="mobile-like-btn"
                href={void 0}
                onClick={(e) =>{
                  handleFavoriteWantAd(
                    wantAd?.Want_ad_Id,
                    e,
                    _.get(wantAd, "isFav", null)
                  )
                }}
              >
                <img
                  src={
                    _.get(wantAd, "isFav", null) ? HearttIconFilled : HearttIcon
                  }
                />
              </a>
            )}

            <a
              href={void 0}
              role={status !== DRAFT_TAB ? "button" : ""}
              className="txt-no-underline"
              onClick={() => {
                if(IsFilledByClient && !IsReadyToReview){

                }
                else {
                  status !== DRAFT_TAB
                  ? showWantAdDetails(wantAd?.Want_ad_Id, status)
                  : editWantAd(wantAd?.Want_ad_Id);
             } }}
            >
              {subPropertyTypeId?.length > 0 && (
                <div className="property-type-wrap-mob">
                  {/* <div className="property-tile-mob w-auto">
                   <img src={PropIconHouse} />
                 </div> */}
                  <div className="property-tile-mob w-auto">
                    {
                      // Buy_or_Rent === 1 ? (
                      //   <img
                      //     src={getPropertyIcon(PropertyTypeId)}
                      //     alt={getPropertyIcon(PropertyTypeId)}
                      //     className="property-icon-img"
                      //   />
                      // ) : (
                      subPropertyTypeId.map((subtype, index) => {
                        return (
                          <img
                            src={getPropertyIcon(subtype)}
                            alt={getPropertyIcon(subtype)}
                            key={index}
                          />
                        );
                      })
                      // )
                    }
                  </div>
                </div>
              )}

              <div className="property-img-wrap-mob h-120">
                {mapImg ? (
                  <img src={mapImg + "?" + _.get(wantAd, "Latitude", null)} />
                ) : (
                  <img src={noImage} />
                )}
              </div>

              <div className="property-info-outer-mob">
                <div className="property-top-mob">
                  <div className="property-info-wrap-mob">
                    
                    <div className="prize-range-badge-mob">
                      {Buy_or_Rent === ADTYPE_BUY
                        ? "BUY"
                        : Buy_or_Rent === ADTYPE_LTR
                        ? "RENT"
                        : Buy_or_Rent === ADTYPE_STR
                        ? "STAY" : ''}
                    </div>{" "}
                    {budget && PropertyTypeId ? (
                      <div className="prize-range-badge-mob">{budget}</div>
                    ) : (
                      ""
                    )}{" "}
                    {wantAd.IsArchived == 1 && (
                      <div className="prize-range-badge-mob red-tag-bg">
                        PAUSED
                      </div>
                    )}{" "}
                    {(IsFilledByClient == 1 && status===DRAFT_TAB) && (
                    <div className={IsReadyToReview ? "prize-range-badge-mob grey-tag-bg" :"prize-range-badge-mob red-tag-bg"}>
                        {getDisplayTextForClientFilledWantAds(IsFilledByClient, IsReadyToReview)}
                    </div>
                  )}
                    {status !== DRAFT_TAB &&
                      status !== PAUSED_TAB &&
                      !isFromFavorite && (
                        <span
                          className={
                            wantAd.IntroCount > 0
                              ? "want-ad-tag align-middle green-tag-bg"
                              : "want-ad-tag mb-2 grey-tag-bg"
                          }
                        >
                          <i
                            className={
                              wantAd.IntroCount > "0"
                                ? "buy-green-icon"
                                : "buy-grey-icon"
                            }
                          ></i>
                          {wantAd.IntroCount}
                        </span>
                      )}
                    {wantAd.Title_Text ? (
                      <h6 className="long-txt-fix">
                        {truncate(wantAd.Title_Text, 24)}
                      </h6>
                    ) : (
                      <h6 style={{ fontStyle: "italic" }}>Untitled</h6>
                    )}
                    <ul className="want-ad-btm-list">
                      <li>
                        <span className="list-txt-btm me-0">
                          {locationDisplayInListing(wantAd)}
                          {/* {" "}
                          {wantAd.City
                            ? (wantAd.Radius || wantAd.mapRadius) +
                              " mi from " +
                              wantAd.City +
                              ", " +
                              wantAd.State
                            : "No location added"}{" "} */}
                        </span>
                      </li>

                      {wantAd.Bedrooms && <li className="sep-v-line">|</li>}
                      {PropertyTypeId !== IS_LAND && wantAd.Bedrooms ? (
                        <li>
                          <span className="list-txt-btm">
                            {" "}
                            {wantAd.Bedrooms === "any"
                              ? "Any"
                              : wantAd.Bedrooms + "+"}
                          </span>
                          <span className="list-icns-btm">
                            <img src={BedSmallIcn} />
                          </span>
                        </li>
                      ) : (
                        ""
                      )}

                      {wantAd.Bathrooms && <li className="sep-v-line">|</li>}
                      {PropertyTypeId !== IS_LAND && wantAd.Bathrooms ? (
                        <li>
                          <span className="list-txt-btm">
                            {wantAd.Bathrooms == "any"
                              ? "Any"
                              : wantAd.Bathrooms + "+"}
                          </span>
                          <span className="list-icns-btm">
                            <img src={BathSmallIcn} />
                          </span>
                        </li>
                      ) : (
                        ""
                      )}
                      {((sqFt || ltSize) && PropertyTypeId) && <li className="sep-v-line">|</li>}
                        
                      {PropertyTypeId && PropertyTypeId !== IS_LAND ? (
                        <li>
                          <span className="list-txt-btm">{sqFt}</span>
                          <span className="list-icns-btm">
                            <img src={SqftSmallIcn} />
                          </span>
                        </li>
                      ) : (
                        PropertyTypeId &&
                        <li>
                          <span className="list-txt-btm">{ltSize}</span>
                          <span className="list-icns-btm">
                            <img src={SqftSmallIcn} />
                          </span>
                        </li>
                      )}
                    </ul>
                    {isFromFavorite && wantAd?.N2H?.length > 0 && (
                      <div className="property-tag-viewad-wrap">
                        {loadNiceToHaveList(wantAd?.N2H)}

                        {wantAd?.N2H?.length > 3 && (
                          <div className="property-tag-count propery-tag-count-btmmargin">
                            +{wantAd?.N2H?.length - 3}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {
                    status === DRAFT_TAB && (
                      <div
                        className="social-btns-wrap rmt-12"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ViewADDropdown
                          wantAdId={wantAd?.Want_ad_Id}
                          isFromMyWantAd={true}
                          editWantAd={editWantAd}
                          disableEdit={IsFilledByClient && !IsReadyToReview ? true: false}
                          dropDownClass = "dropdown-overflow-fix"
                          dropDowntoggleClass = "dropdown-bg-white"
                          updateStateAfterDelete = {updateStateAfterDelete}
                          Client_Want_ad_Id={wantAd?.Client_Want_ad_Id}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </a>
          </div>
        );
      });
    }
  }
};

export default WantAdFeatures;
