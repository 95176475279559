import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import FireWorkImgTop from "../../images/party-popper.png";
import FireWorkImg from "../../images/fireworks-overly.svg";

const ShareMyWantAdSmallModal = (props) => {
  const {show, onHide, showShareModalPopup} = props
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-100 pb-32">
        <div className="fireworks-overlay-top">
        <div class="pyro pyro-left-top">
          <div class="before"></div>
          <div class="after"></div>
        </div>
        <img className="mw-48" src={FireWorkImgTop} width={'48px'} height={'48px'} />
        </div>
        
        <button type="button" class="btn-close" onClick={() => onHide()}></button>
        <h3 className="text-center mt-0">Your want-ad looks great!</h3>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-center">
              Be on the lookout for introductions from members who want you to
              view their property. You'll decide if it's a good match.
            </p>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
              <Button variant="primary" className="btn w-100" onClick={()=> showShareModalPopup()}>
                Share My Ad
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default ShareMyWantAdSmallModal;
