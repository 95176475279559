import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import WantAdMoreIcon from "../../images/want-ad-more-icon.svg";
import _ from "lodash";
import IntroductionDetail from "./IntroductionDetail";
import { INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST, INTRODUCTION_NOT_INTERESTED, INVENTORY_KEY } from "../../constants/common";
import { useNavigate } from "react-router";
import { setLocalStorage } from "../../helpers/common";
import { pageURLs } from "../../constants/pageURLs";

const AcceptedIntroductionList = (props) => {

  const { list, count, updateIntroPageOnScroll, markNotFitModelShow } =
    props;
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    const el = scrollRef.current;
    const scrollHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const clientHeight = el.clientHeight;
    if (
      scrollHeight !== clientHeight &&
      scrollTop !== 0 &&
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
    ) {
      const section = "accepted";
      updateIntroPageOnScroll(section);
    } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
      //setCurrPage(prevState => prevState -1);
    }
  };

  const getIntroductionDetails = async (introId) => {
    setLocalStorage(INVENTORY_KEY, { introductionId: introId })
    navigate(pageURLs.viewIntroducedInventory)
  };

  return (
    <>
      {count > 0 && (
        <div className="intro-list-sec">
          <div className="title-sec">
            <h5>
              Accepted <span>({count})</span>
            </h5>
          </div>
          <div
            className="property-block-scroll"
            onScroll={handleScroll}
            ref={scrollRef}
          >
            {list.map((intro) => {
              const introId = _.get(intro, "IntroductionId", "")
              const FirstName = _.get(intro, "FirstName", "")
              return (
                <div className="property-block" key={introId}>
                  <div className="property-info property-info-hoverstyling" 
                  
                  onClick={() => {   _.get(intro, "IsExpired", "") != 1 ? getIntroductionDetails(introId) : void(0) }}
                  >
                    <IntroductionDetail introDetails={intro} />
                  </div>
                  {
                    _.get(intro, "IsExpired", "") != 1 ?

                    <div className="property-action drop-pos-fix flex-shrink-0">
                      <Button
                        variant="outline"
                        className="btn-outline-primary"
                        onClick={() => navigate('/messages')}
                      >
                        Message
                      </Button>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          variant=""
                          align="end"
                        >
                          <img src={WantAdMoreIcon} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="dpdown-menu-style"
                          align={"end"}
                        >
                          <Dropdown.Item
                            onClick={() => markNotFitModelShow(introId, INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST, FirstName)}>
                            Mark not a fit</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    :
                    <div className="property-action drop-pos-fix flex-shrink-0 modal-wrap-label-orange f-size-14 me-0">
                      Expired
                    </div>
                  }
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default AcceptedIntroductionList;
