import React, { useState, useEffect } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import {
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
} from "../../constants/common";

const HandleScroll = (props) => {
 
  const [scrollPosition, setScrollPosition] = useState(0);
  const prevScrollPosition = usePrevious(scrollPosition);
  const [scrollStates, setScrollstates] = useState({
    direction: null,
    isReachedBottom: null
  });

  useEffect(() => {
    window.addEventListener('scroll', onScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    // if (props?.data) {
      const scrollDirection = _.get(scrollStates, 'direction', null)
      if (
        scrollPosition > prevScrollPosition &&
        scrollDirection !== SCROLL_DIRECTION_DOWN
      ) {
        setScrollstates((prevState) => ({
          ...prevState,
          direction: SCROLL_DIRECTION_DOWN,
        }));
      } else if (
        scrollPosition < prevScrollPosition &&
        scrollDirection !== SCROLL_DIRECTION_UP
      ) {
        setScrollstates((prevState) => ({
          ...prevState,
          direction: SCROLL_DIRECTION_UP,
        }));
      }
    //}
  }, [scrollPosition]);

  const onScroll = () => {
    const isBottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
    setScrollstates((prevState) => ({
      ...prevState,
      isReachedBottom: isBottom,
    }));
   // document.body.style.height = window.innerHeight + "px";
    if (!isBottom) {
      setScrollPosition(window.scrollY);
    }
  };

  return scrollStates;
};
export default HandleScroll;
