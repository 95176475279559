/**
 * @file   src\mobile\pages\ProfileNormalUser.js
 * @brief  This file is responsible for account settings view for consumer
 * @date   April, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import {
  getPeriod,
  getPeriod_Mobile,
  getPlanName_Mobile,
  formateDate,
  getPlanPeriodText,
  getClientLogo,
} from "../../helpers/common";
import DeleteGitchaModal from "../components/DeleteGitchaAccountModal";
import {
  NOT_FREE_OR_MEMBER_PERIOD,
  RESET_PWD_EMAIL_VERIFICATION,
} from "../../constants/common";
import { Button } from "react-bootstrap";

const ProfileNormalUser = ({ ...props }) => {
  const { userDetails, upComingPlanDetails } = props;
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const DeleteModalClose = () => setShowDeleteModal(false);
  const DeleteModalShow = () => setShowDeleteModal(true);
  let isPremiumPlan
  if(_.get(upComingPlanDetails, 'Is_Upcoming', null)== 1){
    isPremiumPlan = upComingPlanDetails?.period && upComingPlanDetails?.period > NOT_FREE_OR_MEMBER_PERIOD;
  } else {
    isPremiumPlan = userDetails?.period && userDetails?.period > NOT_FREE_OR_MEMBER_PERIOD;
  }
    
  
  useEffect(()=>{
    document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
  },[])

  return (
    <>
      <div className="inner-layout-content-new h-100 ">
        <div className="settings-wrap-mob min-h-100 bg-grey-variant">
          <div className="">
            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Name</h3>
                <a
                  href={void 0}
                  className="link"
                  onClick={() => navigate(pageURLs.mobilesettingsEditName)}
                >
                  Edit
                </a>
              </div>
              <p className="ps-3 mb-0 txtLabel">
                {userDetails?.FirstName} {userDetails?.LastName}
              </p>
            </div>

            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Email</h3>
                <a
                  href={void 0}
                  className="link"
                  onClick={() => navigate(pageURLs.mobileEditSettingsEmail)}
                >
                  Edit
                </a>
              </div>
              <p className="ps-3 mb-0 txtLabel">{userDetails?.Email}</p>
            </div>

            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Password</h3>
                <a
                  href={void 0}
                  className="link"
                  onClick={() =>
                    navigate(pageURLs.changePassword, {
                      state: { activePage: RESET_PWD_EMAIL_VERIFICATION, isFromSettings: true },
                    })
                  }
                >
                  Reset
                </a>
              </div>
              <p className="ps-3 mb-0 txtLabel">*************</p>
            </div>
            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Your Subscription</h3>
                <a
                  href={void 0}
                  role="button"
                  className="link"
                  onClick={() =>
                    navigate(pageURLs.updatePlan, {
                          state: { isFromSettings: true, UpgradeToAgent : true },
                        })
                  }
                >
                  Switch to an agent profile
                </a>
              </div>
              {/* <p className="ps-3 mb-0 txtLabel">Free(Member)</p> */}
              {upComingPlanDetails?.Is_Upcoming == 1 && (
                <div className="subscription-alert-box mt-2 mb-3 d-flex">
                  <div className="align-self-center pe-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99967 1.33325C4.32634 1.33325 1.33301 4.32659 1.33301 7.99992C1.33301 11.6733 4.32634 14.6666 7.99967 14.6666C11.673 14.6666 14.6663 11.6733 14.6663 7.99992C14.6663 4.32659 11.673 1.33325 7.99967 1.33325ZM7.49967 5.33325C7.49967 5.05992 7.72634 4.83325 7.99967 4.83325C8.27301 4.83325 8.49967 5.05992 8.49967 5.33325V8.66658C8.49967 8.93992 8.27301 9.16658 7.99967 9.16658C7.72634 9.16658 7.49967 8.93992 7.49967 8.66658V5.33325ZM8.61301 10.9199C8.57967 11.0066 8.53301 11.0733 8.47301 11.1399C8.40634 11.1999 8.33301 11.2466 8.25301 11.2799C8.17301 11.3133 8.08634 11.3333 7.99967 11.3333C7.91301 11.3333 7.82634 11.3133 7.74634 11.2799C7.66634 11.2466 7.59301 11.1999 7.52634 11.1399C7.46634 11.0733 7.41967 11.0066 7.38634 10.9199C7.3521 10.8398 7.33397 10.7537 7.33301 10.6666C7.33301 10.5799 7.35301 10.4933 7.38634 10.4133C7.41967 10.3333 7.46634 10.2599 7.52634 10.1933C7.59301 10.1333 7.66634 10.0866 7.74634 10.0533C7.90865 9.98657 8.0907 9.98657 8.25301 10.0533C8.33301 10.0866 8.40634 10.1333 8.47301 10.1933C8.53301 10.2599 8.57967 10.3333 8.61301 10.4133C8.64634 10.4933 8.66634 10.5799 8.66634 10.6666C8.66634 10.7533 8.64634 10.8399 8.61301 10.9199Z"
                        fill="#4A4C4F"
                      />
                    </svg>
                  </div>
                  {userDetails?.PlanName == "Trial" ? (
                    <div>
                      Your {userDetails?.PlanName} subscription will end on{" "}
                      {formateDate(userDetails?.ToDate, "shortMonth")}.
                    </div>
                  ) : (
                    <div>
                      Your {getPlanPeriodText(userDetails?.period)}{" "}
                      {userDetails?.PlanName} subscription will end on{" "}
                      {formateDate(userDetails?.ToDate, "shortMonth")}. To keep
                      your {userDetails?.PlanName} features, you can{" "}
                      <a
                        href={void 0}
                        role="button"
                        onClick={() => PremiumPlanModalShow()}
                      >
                        resubscribe here.{" "}
                      </a>{" "}
                    </div>
                  )}
                </div>
              )}
              {upComingPlanDetails?.Is_Upcoming == 1 ? (
                <>
                  <p className="ps-3 mb-2 txtLabel">
                    {/* {getPlanName_Mobile(
                      upComingPlanDetails?.UserTypeId,
                      upComingPlanDetails?.period
                    )}{" "} */}
                    {upComingPlanDetails?.PlanName}{" "}
                    (
                      {/* {upComingPlanDetails?.UserTypeId === 2 ? "Pro," : ""}{" "} */}
                    {getPeriod_Mobile(upComingPlanDetails?.period)})
                    {/* (Pro, Annual) - $120/year */}
                  </p>
                  <p className="ps-3 mb-0 txtLabel fnt12">
                    Next payment on{" "}
                    {upComingPlanDetails?.ToDate
                      ? formateDate(upComingPlanDetails?.ToDate, "mm/dd/yyyy")
                      : "-"}{" "}
                    to {upComingPlanDetails?.Brand} {upComingPlanDetails?.Last4}
                  </p>
                </>
              ) : userDetails?.period === 1 ? (
                <p className="ps-3 mb-2 txtLabel">
                  {userDetails?.UserTypeId === 2 ? "Pro " : ""}{" "}
                  {userDetails?.PlanName}{" "}
                  {/* {getPlanName_Mobile(
                    userDetails?.UserTypeId,
                    userDetails?.period
                  )}{" "} */}
                  {/* ({"Member"}) */}
                  {/* Pro Member (Free) */}
                </p>
              ) :  (
                <>
                  <p className="ps-3 mb-2 txtLabel">
                    {/* {getPlanName_Mobile(
                      userDetails?.UserTypeId,
                      userDetails?.period
                    )}{" "} */}
                    {userDetails?.PlanName}{" "}
                    ({userDetails?.UserTypeId === 2 ? "Pro," : ""}{" "}
                    {getPeriod_Mobile(userDetails?.period)})
                    {/* (Pro, Annual) - $120/year */}
                  </p>
                  <p className="ps-3 mb-0 txtLabel fnt12">
                    Next payment on{" "}
                    {userDetails?.ToDate
                      ? formateDate(userDetails?.ToDate, "mm/dd/yyyy")
                      : "-"}{" "}
                    to {userDetails?.Brand} {userDetails?.Last4}
                  </p>
                </>
              )}
              <Button variant="primary" 
              className="ml-6 mr-2 btn-tweak mt-3"
              onClick={() =>
                isPremiumPlan
                  ? navigate(pageURLs.updatePlan, {
                      state: { isFromSettings: true, isEditPlan: true },
                    })
                  : navigate(pageURLs.updatePlan, {
                      state: { isFromSettings: true },
                    })
              }
              >
                {isPremiumPlan ? "Edit" : "View Plans"}
              </Button>
            </div>
            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">
                  <a href={void 0} className="delete" onClick={DeleteModalShow}>
                    Delete My Account
                  </a>
                </h3>
              </div>
              <p className="ps-3 mb-0 txtLabel">
                Permanently delete your Gitcha Account
              </p>
            </div>
          </div>
          <DeleteGitchaModal
            show={showDeleteModal}
            onHide={DeleteModalClose}
            userEmail={userDetails?.Email}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileNormalUser;
