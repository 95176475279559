import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PlusMinusIcon from "../../images/plus-minus-circle-icon.svg";
import ZipCloseIcon from "../../images/zipcode-close-icon.svg";
import InputGroupControl from "../Common/InputGroup";
import { capitalizeString } from "../../actions/common";
import { getGeoLocation, saveGitchaAvailableLocationRequest } from "../../services/commonServices";
import AddInventoryPopup from "./AddInventoryPopup";
import { getLocalStorage, getLocalityFromZipCode, getUserId, setLocalStorage } from "../../helpers/common";


const RequestGitchaAvailability = (props) => {
  const {locationFromSTorage, fromLocationSearch} = props;
  const [errorMessage, setErrorMessage] = useState("");
  const { show, onHide} = props;
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState(null)
  const [locationArray, setLocationArray] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [showAddInventoryPopup, setShowAddInventoryPopup] = useState(false)

  const getAddresseDetails = async ({ eventType, reqPayload }) => {
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      let locArray = [];
      if (results?.length > 0) {
        const formatted_address = _.get(results?.[0], 'formatted_address', '')
        if(results?.length > 0  && formatted_address!== "United States"){
          locArray.push(results[0]);          
          setLocationList(locArray);
        }
      } 
    });
  };  

  const clearAddressList = () => {
    setLocationList([]);
  };

  useEffect(()=>{   
    const locArray = [];
    locArray.push({
      city : locationFromSTorage?.city,
      state : locationFromSTorage?.state,
      county : locationFromSTorage?.County,
      formatted_address : locationFromSTorage?.formatted_Address
    })
    setLocationArray(locArray)

  },[locationFromSTorage])

  const handleLocationChange = (e) => { 
    setErrorMessage("")
    const value = capitalizeString(e.target.value);
    const reqPayload = `address=${value}&components=country:US`;
    if (value.trim() !== "") {
      getAddresseDetails({ reqPayload: reqPayload });
    } else {
      clearAddressList();
    }
    if (value && value.trim() !== "") {
      setLocation(value);
    } 
    else {
      clearLocationStates();
    }     
  };

  const clearLocationStates = () => {
     setLocation("")
    };

  const handleItemSelection = async (address) => {
    let loc = {}    
    const firstAddressComponent = _.get(address, "address_components", "");
    const formatted_address = _.get(address, "formatted_address", "");
    const state = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_1";
    });
    if (state.length) {
      loc.state = _.get(state?.[0], "short_name", "");
    }
    const locality = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "locality";
    });
    const zip = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "postal_code";
    });
    if (zip.length) {
      loc.zip = _.get(zip?.[0], "short_name", "");
    }
    if (locality.length) {
      loc.city = _.get(locality?.[0], "long_name", "");
    }
    else{
      await getLocalityFromZipCode(loc)
    }

    const county = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_2";
    });

    if (county.length) {
      loc.county = _.get(county?.[0], "short_name", "");
    }
    
    loc.formattedAddress = formatted_address
    if(!loc.city){
      setErrorMessage("Please enter a valid city and state. ")
      setLocation("")
    }
    else{ 
      
      setSelectedLocation(loc)
      setLocation(loc.city+", "+loc.state); 
    }    
    clearAddressList();
  };

  const addToLocationArray = () => {
    setErrorMessage("")
    if(!selectedLocation){
      setErrorMessage("Enter City, State")
    }
    else {
      const someArray = locationArray.find(loc => loc.city == selectedLocation.city && loc.state == selectedLocation.state);
      if(someArray){
        setErrorMessage("City, State already added to the list.")
      }
      else {
        setErrorMessage("")
        setLocationArray([...locationArray, selectedLocation])
        setLocation("")
        setSelectedLocation(null)
      }
    }
    
    
  }

  const handleRemoveLocation = (removeLocation) => {
    const someArray = locationArray.filter(loc => loc.formattedAddress != removeLocation.formattedAddress);
    setLocationArray(someArray);
  }

  const handleSubmit = async() => {
    const payload = {
      locations : locationArray
    }
    await saveGitchaAvailableLocationRequest(payload).then((response)=>{
      setLocalStorage("gitchaAvailablePopupHandled", true);
      if(!fromLocationSearch){setShowAddInventoryPopup(true);}
      props.onHide();
    }).catch((error)=>{
      props.onHide();
      console.log(error)
    }
    )
    
  }

  return (
    <>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
      show={show}
      backdrop="static"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {setLocalStorage("gitchaAvailablePopupHandled", true);onHide()}}
        ></button>
        <h3 className="text-start">
            Gitcha isn’t currently available in this location yet
        </h3>
        <p className="f-size-16 mb-4">
        We are diligently working to open up your area! Please type in the cities where you need us, and we'll take it up with the bosses.
        </p>

        <Row>
          <Col xl={12}>
            <div className="textbox-wrap pr-42 position-relative mb-1">
            {locationList.length > 0 ? (
                    <Button
                      variant=""
                      className="search-clear-btn"
                      onClick={() => clearAddressList()}
                    ></Button>
                  ) : (
                    ""
                  )}
                  <InputGroupControl
                    inputGroupClass="wantad-location-search position-relative"
                    ariaLabel="Example"
                    ariaDescribedBy="basic-addon3"
                    placeholder="City, State"
                    errorMessage={errorMessage}
                    value={location ? location : ""}
                    iconClass="location-icon"
                    isIconEnable={false}
                    iconId="button-addon3"
                    onChange={(e) => {
                      handleLocationChange(e);
                    }}
                  />
                  {locationList.length > 0 ? (
                    <div className="location-result-wrap">
                      <ul>
                        {locationList?.map((address, i) => {
                          return (
                            <li key={i}>
                              <a
                                role="button"
                                href={void 0}
                                onClick={(e) => {
                                  handleItemSelection(address);
                                }}
                              >
                                {_.get(address, "formatted_address", "")}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
              <button
                className="btn add-zipcode-btn"
                onClick={() => addToLocationArray()}
              >
                <img
                  src={PlusMinusIcon}
                  alt={PlusMinusIcon}
                  className=""
                  width={24}
                  height={24}
                />
              </button>
            </div>
            <div className="zipcode-tag-wrap pb-32">
              {locationArray.map((loc) => {
                return (
                  <div className="zipcode-tag me-3">
                    <button className="btn zipcode-close-btn me-1" onClick={()=>handleRemoveLocation(loc)}>
                      <img src={ZipCloseIcon} alt={ZipCloseIcon} className="" />
                    </button>
                    {loc.city!=undefined && loc.city !=""? loc.city+", "+loc.state : loc.formatted_address}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <div className="modal-btn-wrap">  
          <Button variant="primary" className={locationArray && locationArray.length > 0? "btn":  "btn disabled" } onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal.Body>      
    </Modal> 
    <AddInventoryPopup
    show={showAddInventoryPopup}
    onHide={()=>setShowAddInventoryPopup(false)}
    />       
       </>
  );
  
};
export default RequestGitchaAvailability;
