import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import {
  displayAdTimeLine,
  getLocalStorage,
  isValidDate,
  setBodyBackgroundWhite,
  setLocalStorage,
} from "../../helpers/common";
import { getTimeline } from "../../services/commonServices";
import SelectTimeLineBuy from "./SelectTimeLineBuy";
import {
  BUY,
  DATE_FORMAT_MM_DD_YYYY,
  LONG_RENT,
  WANT_AD_KEY,
  WANT_AD_KEY_TEMP,
} from "../../constants/common";
import SelectTimeLineLTR from "./SelectTimeLineLTR";
import { EXACT_MOVE_DATE_TYPE } from "../../constants/createWantAd";
import { convertToNumber } from "../../helpers/validations";

const AddTimeLine = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isEdit,
    isFromBuyerListingService
  } = props;

  const [disableSave, setDisableSave] = useState(true);
  const [dateValError, setDateError] = useState("");
  const moveInTimeLine = convertToNumber(_.get(formValues, "moveInTimeLine", ""));
  const moveInDate = _.get(formValues, "startDate", "");


  useEffect(() => {
   setBodyBackgroundWhite()
  }, []);

  const loadTimeLine = () => {
    if (adType === BUY) {
      return (
        <SelectTimeLineBuy
          handleFormValues={handleFormValues}
          formValues={formValues}
          setDisableSave={setDisableSave}
          adType={adType}
          isEdit={isEdit}          
        />
      );
    } else {
      return (
        <SelectTimeLineLTR
          handleFormValues={handleFormValues}
          formValues={formValues}
          setDisableSave={setDisableSave}
          adType={adType}
          isEdit={isEdit}
          dateValError={dateValError}
          setDateError={setDateError}
          clearPreviousStates={clearPreviousStates}
        />
      );
    }
  };

  const validate = () => {
    if(adType===LONG_RENT){
      if (moveInTimeLine === EXACT_MOVE_DATE_TYPE && moveInDate) {
        const isDateValid = isValidDate({dateString :moveInDate, format : DATE_FORMAT_MM_DD_YYYY});
        console.log("isDateValid", isDateValid)
        if (!isDateValid) {
          setDateError("Invalid Date.")
          return false
        }
      }
      return true;
    }
    else{
      return true;
    }
  }

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />

          <div className="inner-layout-content-new h-100 bg-white pb-0 position-relative">
            <div className="select-plan-wrap h-100 pl-0 pr-0 pt-0 position-relative">
            <div className="layout-content-btm-sec layout-content-btm-sec-stickyfix">
              <h6 className="">Timeline</h6>
              <h2 className="">
                {isAgent
                  ? "When is your client planning to "
                  : "When are you planning to "}{" "}
                {displayAdTimeLine(adType)}?
              </h2>
            </div>
            
            {loadTimeLine()}
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if(validate()){
                    if (isAgent) {
                      if (completedPercentage > 30) {
                        setCompletedPercentage(completedPercentage);
                      } else {
                        setCompletedPercentage(30);
                      }
                    } else {
                      if (completedPercentage > 38) {
                        setCompletedPercentage(completedPercentage);
                      } else {
                        setCompletedPercentage(38);
                      }
                    }
                    setTimeout(()=>{
                      setLocalStorage(
                        WANT_AD_KEY,
                        getLocalStorage(WANT_AD_KEY_TEMP)
                      );
                      setUIKey(null);

                    }, 500)                  
                  }}
                }
              >
                {isEdit ? "Update":"Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTimeLine;
