/**
 * @file   Source/gitcha_Web/src/constants/pageURLs.js
 * @brief  This file is responsible for urls used in navigation across app
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
const  mobileBasePath = '/mobile/'
export const pageURLs = Object.freeze({
    login: '/login',
    signup: '/signup',
    wantad: '/home',
    dashboard: '/home',
    home: '/home',
    match: "/match",
    createwantad: '/create-want-ad',
    wantAdDetails: '/want-ad-details',
    wantAdStrengthen : '/strengthen-want-ad',
    notFound : '404-not-found',
    agentCreatewantadMainScreen: '/agent-want-ad-creation',
    agentCreatewantad: '/agent-create-want-ad',
    agentEditwantad: '/edit-want-ad',
    editWantAdForAgents: '/agent-edit-want-ad',
    memberInventoryDetails :'/member-inventory-details',
    memberCreateInventory : '/create-inventory',
    inventoryStrengthen : '/strengthen-inventory',
    inventoryListing : '/my-inventory-listing',
    wantAdListing : '/my-want-ad',
    agentCreateInventory : '/agent-create-inventory',
    agentDraftList: '/agent-draft-inventory',
    memberEditInventory : '/member-edit-Inventory',
    settings : '/settings',
    addNewCard : '/add-new-card',
    viewClientDetails : '/view-client',
    agentDashboard : '/agent-dashboard',
    allIntroductions: '/all-introductions',
    viewIntroducedInventory : '/view-introduced-property',
    accessCodePage: '/',
    gitchaMemberWelcome: '/wantadd-onboard',
    gitchaPrivacyPolicy: '/privacy-policy',
    gitchaTermsOfService: '/terms-of-service',
    gitchaInvetoryOnBoard: '/inventory-onboard',
    wantAdPubicDetailsPage: '/want-ad',
    wantAdPubicMobileDetailsPage: `${mobileBasePath}want-ad`,
    mobileSignIn: `${mobileBasePath}signin`,
    updatePlan: `${mobileBasePath}update-plan`,
    mobileHome: `${mobileBasePath}home`,
    createWantAdResponsive: `${mobileBasePath}create-want-ad`,   
    changePassword: `${mobileBasePath}update-password`,
    // clientManagement: `${mobileBasePath}client-management`,
    mobileInapp: `${mobileBasePath}inapp`,
    viewAd: `${mobileBasePath}view-ad`,
    strengthenAdResponsive :`${mobileBasePath}strengthen-want-ad`,
    mobileSettings: `${mobileBasePath}settings`,
    mobileBaseRoot: '/',
    mobileRoot: '/mobile',
    messages: '/messages',
    proDashboard: `${mobileBasePath}pro-dashboard`,
    agentClientListing: `${mobileBasePath}agent-client-listing`,
    favoriteClients : '/favorite-clients',
    webFavorites: '/my-favorites',
    viewClientDetailsMob : `${mobileBasePath}view-client`,
    mobileReceiveIntroNotification: `${mobileBasePath}receive-intro-notification`,
    myWantAds: `${mobileBasePath}my-want-ads`,
    mobileEditWantAd: `${mobileBasePath}edit-want-ad`,
    mobileClientNoteListing: `${mobileBasePath}client-notes-list`,
    mobileManageInventory: `${mobileBasePath}manage-inventory`,
    mobileClientInventory: `${mobileBasePath}view-client-inventory`,
    mobileClientWantAd: `${mobileBasePath}view-client-wantad`,
    mobileReviewOrder: `${mobileBasePath}review-order`,
    mobileCreateInventory :`${mobileBasePath}create-inventory`,
    mobilememberInventoryDetails:`${mobileBasePath}member-view-property`,
    mobileWantAdFavorites:`${mobileBasePath}favorites`,
    clientFavoriteSummary:`${mobileBasePath}client-favorites-summary`,
    mobileAccountSettings : `${mobileBasePath}account-settings`,
    mobilePremiumAgentProfileEdit : `${mobileBasePath}premium-profile-edit`,
    strengthenInventoryReponsive:`${mobileBasePath}strengthen-inventory`,
    mobileEditInventory:`${mobileBasePath}edit-inventory`,
    mobileEditSettingsEmail: `${mobileBasePath}settings-edit-email`,
    mobileEditEmailCheck: `${mobileBasePath}settings-check-email`,
    mobileEmailNotification:`${mobileBasePath}email-notification`,
    mobilePaymentHistory:`${mobileBasePath}payment-history`,
    mobileAddPaymentMethod:`${mobileBasePath}add-payment-method`,
    mobileDeleteAccountCheck: `${mobileBasePath}settings-delete-account`,
    mobilesettingsEditName: `${mobileBasePath}settings-edit-name`,
    mobileInventoryIntroduction:`${mobileBasePath}view-all-introductions`,
    mobileMessages: `${mobileBasePath}messages`,
    viewFullGallery: `${mobileBasePath}view-full-gallery`,
    wantAdTutorialResponsive: `${mobileBasePath}want-ad-tutorial`,
    inventoryTutorialResponsive:`${mobileBasePath}inventory-tutorial`,
    mobileMatch:`${mobileBasePath}match`,
    mobilePremiumAgentCreateWantAd: `${mobileBasePath}create-ad-mob`,
    buyerListingConfirmation: "/confirmation"
});
