import { createSlice } from "@reduxjs/toolkit";
import { fetchUserDetails, fetchUserPrivileges, fetchUserStatistics, getNotificationSettings } from "../actions/userprofile";
const initialState = {
  loading: false,
  userDetails: {},
  error: "",
  notificationLoading : false,
  notificationSettings : {},
  notificationError : "",
  userPrivilegesLoading : false,
  userPrivileges : {},
  userPrivilegesError : "",
  userStaticticsLoading : false,
  userStatictics : {},
  userStaticticsError : "",
  userPlan : "",
};

const userProfile = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserPlan: (state, payload) => {
      state.userPlan = payload.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state, action) => {
      state.loading = true;
      state.userDetails = [];
      state.error = "";
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
          state.loading = false;
          state.userDetails = action.payload.result;
          state.error = "";        
      });
    
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.userDetails = [];
      state.error = action.error.message;
    });

    builder.addCase(getNotificationSettings.pending, (state, action) => {
      state.notificationLoading = true;
      state.notificationSettings = [];
      state.notificationError = "";
    });

    builder.addCase(getNotificationSettings.fulfilled, (state, action) => {
      state.notificationLoading = false;
      state.notificationSettings = action.payload.result;
      state.notificationError = "";
    });

    builder.addCase(getNotificationSettings.rejected, (state, action) => {
      state.notificationLoading = false;
      state.notificationSettings = {};
      state.notificationError = action?.error?.message;
    });

    builder.addCase(fetchUserPrivileges.pending, (state, action) => {
      state.userPrivilegesLoading = true;
      state.userPrivileges = {};
      state.userPrivilegesError = "";
    });

    builder.addCase(fetchUserPrivileges.fulfilled, (state, action) => {
      state.userPrivilegesLoading = false;
      state.userPrivileges = action.payload.result;
      state.userPrivilegesError = "";
    });

    builder.addCase(fetchUserPrivileges.rejected, (state, action) => {
      state.userPrivilegesLoading = false;
      state.userPrivileges = {};
      state.userPrivilegesError = action?.error?.message;
    });

    builder.addCase(fetchUserStatistics.pending, (state, action) => {
      state.userStaticticsLoading = true;
      state.userStatictics = {};
      state.userStaticticsError = "";
    });

    builder.addCase(fetchUserStatistics.fulfilled, (state, action) => {
      state.userStaticticsLoading = false;
      state.userStatictics = action.payload.result;
      state.userStaticticsError = "";
    });

    builder.addCase(fetchUserStatistics.rejected, (state, action) => {
      state.userStaticticsLoading = false;
      state.userStatictics = {};
      state.userStaticticsError = action?.error?.message;
    });
  },
  
});
// Action creators are generated for each case reducer function
export const { setUserPlan } = userProfile.actions

export default userProfile.reducer;
