/**
 * @file   src\components\Common\NiceToHave\NiceToHave.js
 * @brief  This file is responsible for nice to have selection in want ad creation
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { components } from 'react-select';
import NiceToHaveSelect from "./NiceToHaveSelect";
import { getNiceToHaves } from "../../../services/commonServices";
import { BUY_LAND, BUY, IS_LAND, INVENTORY_LAND } from '../../../constants/common';
import PlaceHolderIcon from "../../../images/dpdown-placeholder-icon.svg";
import { checkInventoryType } from "../../../helpers/inventory";

const NiceToHave = (props) => {
    const {
        selectedNiceToHave,
        handleFormValues,
        propertyType,
        adType,
        selectedniceToHavesList,
        isFromInventory,
        proprtyPurpose
    } = props;

    const propertyTab = "Property";
    const layoutTab = "Layout";
    const interiorTab = "Interior";
    const exteriorTab = "Exterior";
    const otherTab = "Other";
    const viewsTab = "Views";
    const characteristicsTab = "Characteristics";

    const [niceToHavesList, setNiceToHavesList] = useState([]);

    useEffect(() => {
        if (propertyType) {
            let param;
            if (isFromInventory) {
                param = propertyType == IS_LAND ? INVENTORY_LAND : checkInventoryType(proprtyPurpose);
            } else {
                param = (propertyType == 6 ? BUY_LAND : adType) || BUY;
            }
            getNiceToHaves(param)
                .then((response) => {
                    setNiceToHavesList(response.result);

                })
                .catch((err) => {
                    console.log("Error", err);
                });
        }
    }, [propertyType, adType, proprtyPurpose]);

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
    }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        if (isFocused) bg = "#eee";
        if (isActive) bg = "#B2D4FF";

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: "inherit",
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style,
            rest
        };

        return (
            <components.Option
                {...rest}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                getStyles={getStyles}
                innerProps={props}
            >
                <div class="form-check ms-2">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" defaultChecked={isSelected} />
                    <label class="form-check-label checkbox-label" for="defaultCheck1" >
                        {children}
                    </label>
                </div>
            </components.Option>
        );
    };

    const MoreSelectedBadge = ({ items }) => {
        const length = items.length;
        const label = `+ ${length}`;
        return (
            <div className="root">
                {label}
            </div>
        );
    };


    const MultiValue = ({ index, getValue, ...props }) => {
        const maxToShow = 3;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);
        let icon = props.data.Icon ?? props.data.icon;

        return index < maxToShow ? (
            <components.MultiValue {...props}>
                <span className="nice-have-iconwrap">
                    <img
                        src={icon ? require("../../../images/" + icon) : PlaceHolderIcon}
                        style={{ width: 16, height: 16 }}
                        alt={props.data.label}
                    />
                </span>
                <span>{props.data.label}</span>
            </components.MultiValue>

        ) : index === maxToShow ? (
            <MoreSelectedBadge items={overflow} />
        ) : null;
    };

    return (
        <>
            {propertyType != IS_LAND ? (
                <div className="multi-select-outerwrap pb-24">
                    <h5 className="mb-1">{props.title}</h5>
                    <h6 className="mb-12">These are the ideal features that make a home unique. Select up to 16 items in total.</h6>
                    <div className="multi-select-wrap">
                        <label className="form-label">Property features</label>
                        <NiceToHaveSelect
                            placeholder="Select property features"
                            selectedNiceToHave={selectedNiceToHave}
                            niceToHavesList={niceToHavesList?.Property}
                            tab={propertyTab}
                            handleFormValues={handleFormValues}
                            propertyType={propertyType}
                            componentValue={{ Option: InputOption, MultiValue }}
                            selectedniceToHavesList={selectedniceToHavesList}
                        />

                    </div>
                    <div className="multi-select-wrap">
                        <label className="form-label">Layout features</label>
                        <NiceToHaveSelect
                            placeholder="Select layout features"
                            selectedNiceToHave={selectedNiceToHave}
                            niceToHavesList={niceToHavesList?.Layout}
                            tab={layoutTab}
                            handleFormValues={handleFormValues}
                            propertyType={propertyType}
                            componentValue={{ Option: InputOption, MultiValue }}
                            selectedniceToHavesList={selectedniceToHavesList}
                        />
                    </div>
                    <div className="multi-select-wrap">
                        <label className="form-label">Exterior features</label>
                        <NiceToHaveSelect
                            placeholder="Select exterior features"
                            selectedNiceToHave={selectedNiceToHave}
                            niceToHavesList={niceToHavesList?.Exterior}
                            tab={exteriorTab}
                            handleFormValues={handleFormValues}
                            propertyType={propertyType}
                            componentValue={{ Option: InputOption, MultiValue }}
                            selectedniceToHavesList={selectedniceToHavesList}
                        />
                    </div>
                    <div className="multi-select-wrap">
                        <label className="form-label">Interior features</label>
                        <NiceToHaveSelect
                            placeholder="Select interior features"
                            selectedNiceToHave={selectedNiceToHave}
                            niceToHavesList={niceToHavesList?.Interior}
                            tab={interiorTab}
                            handleFormValues={handleFormValues}
                            propertyType={propertyType}
                            componentValue={{ Option: InputOption, MultiValue }}
                            selectedniceToHavesList={selectedniceToHavesList}
                        />
                    </div>
                    <div className="multi-select-wrap">
                        <label className="form-label">Other features</label>
                        <NiceToHaveSelect
                            placeholder="Select other features"
                            selectedNiceToHave={selectedNiceToHave}
                            niceToHavesList={niceToHavesList?.Other}
                            tab={otherTab}
                            handleFormValues={handleFormValues}
                            propertyType={propertyType}
                            componentValue={{ Option: InputOption, MultiValue }}
                            selectedniceToHavesList={selectedniceToHavesList}
                        />
                    </div>
                </div>
            ) : (<div className="multi-select-outerwrap">
                <h5>{props.title}</h5>
                <h6>These are the ideal features that make a home unique. Select up to 10 items in total.</h6>
                <div className="multi-select-wrap">
                    <label className="form-label">Views features</label>

                    <NiceToHaveSelect
                        placeholder="Select views features"
                        selectedNiceToHave={selectedNiceToHave}
                        niceToHavesList={niceToHavesList?.Views}
                        tab={viewsTab}
                        handleFormValues={handleFormValues}
                        propertyType={propertyType}
                        componentValue={{ Option: InputOption, MultiValue }}
                        selectedniceToHavesList={selectedniceToHavesList}
                    />
                </div>
                <div className="multi-select-wrap">
                    <label className="form-label">Characteristics features</label>
                    <NiceToHaveSelect
                        placeholder="Select characteristics features"
                        selectedNiceToHave={selectedNiceToHave}
                        niceToHavesList={niceToHavesList?.Characteristics}
                        tab={characteristicsTab}
                        handleFormValues={handleFormValues}
                        propertyType={propertyType}
                        componentValue={{ Option: InputOption, MultiValue }}
                        selectedniceToHavesList={selectedniceToHavesList}
                    />

                </div>

            </div>

            )}
        </>
    );
};
export default NiceToHave;
