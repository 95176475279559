import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Carousel } from "react-bootstrap";
import InventoryLocationMap from "../../images/inventory-location-map.png";
import Riverviewicon from "../../images/river-view.svg";
import noImage from "../../images/no-image-placeholder.png";
import MarkFitModal from "./MarkFitModal";
import AcceptIntroductionModal from "../Introduction/AcceptIntroductionModal";
import { updateIntroNotFit, updateIntroductionStatus } from "../../services/introductionService";
import  JoyRide,  { ACTIONS, EVENTS, STATUS } from "react-joyride";
import DocumentationTutorial from "../Tutorial/DocumentationTutorial";
import IntroductionTutorialIconStep1 from "../../images/tut-introreceived-icon.svg";
import IntroductionTutorialIconStep2 from "../../images/tut-accept-icon.svg";
import IntroductionTutorialIconStep3 from "../../images/tut-goodfit-icon.svg";

import {
  filterArray,
  formateDate,
  getLocalStorage,
  getpropertyName,
  removeDecimalZeros,
  setLocalStorage,
  thousandSeperatedValue,
  checkTutorialDisplayStatus,
  saveTutorialStatusToLocal
} from "../../helpers/common";
import {
  DEFAULT_WANT_AD_MAP_ZOOM,
  INTRODUCTION_ACCEPT,
  INTRODUCTION_NOT_A_FIT,
  INTRODUCTION_NOT_FIT,
  INVENTORY_KEY,
  IS_LAND,
  LOT_TYPES,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
  USER_TYPE_MEMBER,
} from "../../constants/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { updateIntroduction } from "../../helpers/message";
import GoogleMap from "./GoogleMap";
import { convertToNumber } from "../../helpers/validations";
import { getGeoLocation , saveTutorialStatus} from "../../services/commonServices";

const ViewIntroductionModal = (props) => {
  const tutorialPage = 3;
  const navigate = useNavigate()
  const { show, onHide, details } = props;
  const [showAcceptIntroductionModal, setAcceptIntroductionModal] =
    useState(false);
  const acceptIntroductionModalClose = () => {
    navigate(0)
    setAcceptIntroductionModal(false);
  }

  const acceptIntroductionModalShow = () => setAcceptIntroductionModal(true);
  const [showNotFitPopup, setNotFitPopup] = useState(false);
  const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);
  const markFitModalClose = () => setNotFitPopup(false);
  const markFitReasonModalClose = () => setNotFitReasonPopup(false);
  const mapImg = _.get(details, "Location_Img", "");
  const askingPrice = _.get(details, "AskingPrice", "");
  const introDate = _.get(details, "CreatedOn", "");
  const photos = _.get(details, "Photos", []);
  const noPhotosExist = photos?.length === 0;
  const isLand = _.get(details, "PropertyTypeId", "") === IS_LAND;
  const LotSz = _.get(details, "LotSz", "");
  const niceToHave = _.get(details, "N2H_Properties", []);
  const introductionId = _.get(details, "IntroductionId", "");
  const fullName = _.get(details, "Full_Name", "");
  const isAccept = _.get(details, "IsAccept", "");
  
  const [displayTutorial, setDisplayTutorial] = useState(false);

  const compensteAgent =
    _.get(details, "Compensate_Agent", null) === 0
      ? null
      : _.get(details, "Compensate_Agent", null);
  const agentPercent =
    _.get(details, "Compensate_Percent", null) === 0
      ? null
      : _.get(details, "Compensate_Percent", null);
  const insReport =
    _.get(details, "Inspection_Report", null) === 0
      ? null
      : _.get(details, "Inspection_Report", null);
  const appraisalRpt =
    _.get(details, "Appraisal_on_File", null) === 0
      ? null
      : _.get(details, "Appraisal_on_File", null);
  const floodCert =
    _.get(details, "Flood_Certificate", null) === 0
      ? null
      : _.get(details, "Flood_Certificate", null);

  const assMortgage =
    _.get(details, "assMortgage", null) === 0
      ? null
      : _.get(details, "assMortgage", null);
  const timeLineTxt =
    _.get(details, "timeLineTxt", null);

  const [userCoords, setUserCoords] = useState({})
  const handleUserCoords = (lat, lng) => {
    setUserCoords({ lat: lat, lng: lng })
  }
  //const userLocation = getLocalStorage(USER_LOCATION_KEY)
  const defaultProps = {
    center: {
      lat: convertToNumber(_.get(userCoords, 'lat', '')),
      lng: convertToNumber(_.get(userCoords, 'lng', '')),
    },
    zoom: 12,
  };

  const updateToNotFit = async (reqPayload) => {
    if (reqPayload) {
      await updateIntroNotFit(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isdeleted: true,
            }

            let updateresponse = await updateIntroduction(reqPayload?.IntroductionId, data);
            // setPendingList([]);
            // setStatusChange(INTRODUCTION_NOT_A_FIT);
            //navigate(pageURLs.allIntroductions)
            //markFitReasonModalClose()
            navigate(0)
            //navigate(pageURLs.allIntroductions)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };
  const updateToAccepted = async () => {
    const reqPayload = {
      introductionId: introductionId,
      status: INTRODUCTION_ACCEPT,
    };

    if (introductionId) {
      await updateIntroductionStatus(reqPayload)
        .then(async (response) => {
         
          if (response.errorCode == 0) {
            let data = {
              isaccepted: true,
              acceptedat: new Date()
            }
          
            let updateresponse = await updateIntroduction(introductionId, data);
         
            acceptIntroductionModalShow()
            onHide()
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const handleNotFitConfirm = () => {
    setNotFitPopup(false)
    setNotFitReasonPopup(true)
  }

  const handleViewInventoryClick = (introductionId) => {
    setLocalStorage(INVENTORY_KEY, { introductionId: introductionId })
    navigate(pageURLs.viewIntroducedInventory)
  }

  useEffect(() => {
    // const reqPayload = `address=${details?.City}, +${details?.State}`

    // getGeoLocation(reqPayload).then(data => {
    //   const results = _.get(data, 'results')
    //   if (results?.length > 0) {
    //     const firstLocationResult = _.get(results[0], 'geometry.location', '')
    //     const lat = _.get(firstLocationResult, 'lat', '')
    //     const lng = _.get(firstLocationResult, 'lng', '')
    //     handleUserCoords(lat, lng)
    //   } else {

    //   }
    // });

    handleUserCoords(details?.Latitude, details?.Longitude)
  }, [details?.Latitude, details?.Longitude]);

    //Tutorial documentation section
  //Defining tutorial steps
  const [tutorialSteps, setTutorialSteps] = useState( 
    [
      {
        target: '.modal-content',
        disableBeacon: true,
        placement : "left",
        title :"This is a preview of the introduction you received",
        icon : IntroductionTutorialIconStep1,
        content: 'You can scroll to view additional information. The full property details are only available when you accept the introduction.',
        hideCloseButton : true,
        showProgress : true,
        showSkipButton : true,
        disableOverlay : true
      },
      {
        target: '#acceptBtn',
        disableBeacon: true,
        placement : "bottom",
        title : "What happens when I accept?",
        icon : IntroductionTutorialIconStep2,
        content: "After you accept this introduction, you'll have 72 hours to send a message to the property owner if you're interested. If not, the introduction will expire.",
        hideCloseButton : true,
        showProgress : true,
        showSkipButton : true
      },
      {
        target: '#notFitBtn',
        disableBeacon: true,
        placement : "top",
        title : "Not a good fit for you?",
        icon : IntroductionTutorialIconStep3,
        content: "If this property doesn't meet your requirements, you can select Not a Fit. You won't receive an introduction for this property again.",
        hideCloseButton : true,
        showProgress : true,
        showSkipButton : true
      }
    ]
  );
  //Tutorial tooltip
  const TutorialToolTip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    size,
    isLastStep,
    skipProps
  }) => (
    
    <>
    <div {...tooltipProps}>
    <DocumentationTutorial continuous= {continuous} 
      index ={index} 
      step ={step} 
      backProps = {backProps} 
      skipProps ={skipProps} 
      primaryProps ={primaryProps}
      isLastStep = {isLastStep}
      size ={size}
      finalStepBtn = {'Got It!'}
    />
      </div>
    </>

    
  );
  //Tutorial status call back
  const handleJoyrideCallback = async(data) => {
    const { action, index, status, type, lifecycle } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle =='complete'){
      saveTutorialStatusToLocal(tutorialPage)
      await saveTutorialStatus(tutorialPage, 1); //Save status
      
    }
  }

  const hideModal = async() => {
      onHide(false);
      setDisplayTutorial(false)
  }

  return (
    <>
      {displayTutorial ? <JoyRide
        steps={tutorialSteps}
        tooltipComponent={TutorialToolTip}
        continuous
        callback={handleJoyrideCallback}
        disableScrolling  = {true}
        styles={{
          options: {
            zIndex: 10000
          }
        }}
      /> : ''}
      <Modal
        show={show}
        {...props}
        backdrop="static"
        aria-labelledby="accept-modal-title-vcenter"
        className="client-notes-modal accept-intro-modal right"
        onShow={() =>
          setTimeout(function() { 
            let tutorialDisplayStatus = checkTutorialDisplayStatus(tutorialPage)
            setDisplayTutorial(tutorialDisplayStatus)
          }, 300)
        }
      >
        <Modal.Body className="pt-32">
          <div className="client-notes-modal-top">
            <div className="flex-grow-1">
              <h3 className="long-txt-fix">
                {getpropertyName(details)} in {_.get(details, "City", "")}, {_.get(details, "State", "")}
                {/* {getpropertyName(details)} in {_.get(details, "Location", "")} */}

              </h3>
              <p>
                introduced {introDate && formateDate(introDate, "mm/dd/yyyy")}
              </p>
              <h5>${askingPrice && thousandSeperatedValue(askingPrice)}</h5>
            </div>
            <button
              type="button"
              class="btn btn-slide-right flex-shrink-0"
              aria-label="Close"
              onClick={() => hideModal()}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.781 12.781L5.28104 20.281C5.14031 20.4218 4.94944 20.5008 4.75042 20.5008C4.55139 20.5008 4.36052 20.4218 4.21979 20.281C4.07906 20.1403 4 19.9494 4 19.7504C4 19.5514 4.07906 19.3605 4.21979 19.2198L11.1901 12.2504L4.21979 5.28104C4.07906 5.14031 4 4.94944 4 4.75042C4 4.55139 4.07906 4.36052 4.21979 4.21979C4.36052 4.07906 4.55139 4 4.75042 4C4.94944 4 5.14031 4.07906 5.28104 4.21979L12.781 11.7198C12.8508 11.7894 12.9061 11.8722 12.9438 11.9632C12.9816 12.0543 13.001 12.1519 13.001 12.2504C13.001 12.349 12.9816 12.4466 12.9438 12.5376C12.9061 12.6287 12.8508 12.7114 12.781 12.781ZM20.281 11.7198L12.781 4.21979C12.6403 4.07906 12.4494 4 12.2504 4C12.0514 4 11.8605 4.07906 11.7198 4.21979C11.5791 4.36052 11.5 4.55139 11.5 4.75042C11.5 4.94944 11.5791 5.14031 11.7198 5.28104L18.6901 12.2504L11.7198 19.2198C11.5791 19.3605 11.5 19.5514 11.5 19.7504C11.5 19.9494 11.5791 20.1403 11.7198 20.281C11.8605 20.4218 12.0514 20.5008 12.2504 20.5008C12.4494 20.5008 12.6403 20.4218 12.781 20.281L20.281 12.781C20.3508 12.7114 20.4061 12.6287 20.4438 12.5376C20.4816 12.4466 20.501 12.349 20.501 12.2504C20.501 12.1519 20.4816 12.0543 20.4438 11.9632C20.4061 11.8722 20.3508 11.7894 20.281 11.7198Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="property-slider">
            <Carousel>
              {noPhotosExist ? (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={noImage}
                    alt="Third slide"
                  />
                </Carousel.Item>
              ) : (
                photos.map((index) => {

                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={index}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })
              )}
            </Carousel>
          </div>

          <div className="musthave-wrap">
            <h6>Property details</h6>
            {isLand ? (
              <ul>
                {LotSz && (
                  <li>
                    <span class="musthave-label">Lot size</span>
                    <span class="musthave-value">{LotSz} acre</span>
                  </li>
                )}
                <li>
                  <span class="musthave-label">Lot Type</span>
                  <span class="musthave-value">
                    {filterArray(
                      LOT_TYPES,
                      "value",
                      _.get(details, "LotType_Rural_City", "")
                    )}
                  </span>
                </li>
              </ul>
            ) : (
              <ul>
                { _.get(details, "Bedrooms", "") &&
                <li>
                  <span class="musthave-label">Beds</span>
                  <span class="musthave-value">
                    {removeDecimalZeros( _.get(details, "Bedrooms", ""))}
                  </span>
                </li>
}
{ _.get(details, "Bathrooms", "") &&
                <li>
                  <span class="musthave-label">Baths</span>
                  <span class="musthave-value">
                    {removeDecimalZeros(_.get(details, "Bathrooms", ""))}
                  </span>
                </li>
}
{ _.get(details, "Sqft", "") &&
                <li>
                  <span class="musthave-label">Sqft</span>
                  <span class="musthave-value">
                    {removeDecimalZeros(_.get(details, "Sqft", ""))}
                  </span>
                </li>
}
                {LotSz && (
                  <li>
                    <span class="musthave-label">Lot size</span>
                    <span class="musthave-value">{LotSz} acre</span>
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="Inventory-Location-wrap">
            <h6>Location</h6>
            <p>You will see the exact address upon accepting.</p>
            <div className="Inventory-Location-map-wrap mb-3">
              <div className="googlemap-responsive">
                <div className="map-outer">
                  <GoogleMap
                    defaultProps={defaultProps}
                    mapHeight="100%"
                    mapWidth="100%"
                  />
                </div>
              </div>
              {/* <img src={mapImg || InventoryLocationMap} alt={mapImg || InventoryLocationMap} /> */}
            </div>
          </div>
          {niceToHave?.length !== 0 && (
            <div className="nicetohaves-wrap">
              <h6>Property features</h6>
              <div className="nicetohaves-inner-wrap">
                {niceToHave.map((n2h, index) => {
                  const icon = _.get(n2h, "Img_Icon", "");
                  return (
                    <div class="nicetohaves-tag">
                      <div class="nicetohaves-tag-icon" key={index}>
                        <img
                          src={
                            icon
                              ? require("../../images/" + icon)
                              : Riverviewicon
                          }
                          alt={icon ? icon : Riverviewicon}
                        />
                      </div>
                      <span>{_.get(n2h, "N2H_CharText", "")}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div class="nicetohaves-wrap">
            <div className="add-profile-wrap">
              <div className="prof-img mr-12">
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                    fill="#A0A9BA"
                  />
                </svg>
              </div>
              {_.get(details, "UserTypeId", "") == USER_TYPE_AGENT && <h6>Represented by Agent <span class="pro-blue-badge">PRO</span></h6>}
              {_.get(details, "UserTypeId", "") == USER_TYPE_MEMBER && <h6>Property Owner</h6>}


            </div>
          </div>

          {(compensteAgent || insReport || appraisalRpt || floodCert) &&
            <div class="buyer-info">
              <h6>Other details</h6>
              <ul>
                {compensteAgent && (
                  <li>{agentPercent}% compensation to buyers agent</li>
                )}
                {insReport && <li>Inspection on file</li>}
                {appraisalRpt && <li>Appraisal</li>}
                {floodCert && <li>Flood certification</li>}
                {assMortgage && <li>  Assumable mortgage</li>}
                {timeLineTxt && <li> Timeline: {timeLineTxt}</li>}
              </ul>
            </div>}

        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-wrap">
            <Button
              variant=""
              id="notFitBtn"
              className="btn btn-tertiary me-3"
              onClick={() => setNotFitPopup(true)}
            >
              Not a fit
            </Button>
            {!isAccept && <Button
              variant="primary"
              id="acceptBtn"
              onClick={() =>
                //updateIntroStatus(INTRODUCTION_ACCEPT, introductionId, fullName)
                updateToAccepted()
              }
            >
              Accept to view more{" "}
            </Button>}

          </div>
          {/* <p className="w-100 text-center">
            <a role="button" onClick={() => alert('Not implemented')}>Learn more</a> about introductions
          </p> */}
        </Modal.Footer>
      </Modal>

      <MarkFitModal
        show={showNotFitPopup}
        onHide={markFitModalClose}
        titleText="Mark introduction is not a fit"
        description="This action will end the introduction. Once you delete this it cannot be undone."
        updateToNotFit={updateToNotFit}
        introductionId={introductionId}
        showNotFirReasnPopup={showNotFirReasnPopup}
        handleNotFitConfirm={handleNotFitConfirm}
        markFitReasonModalClose={markFitReasonModalClose}
        // name={fullName}
      // reasonPopupTitle = "Tell why their introduction wasn’t a good fit"
      // reasonPopupDesc = "This will help send better, more accurate introductions in the future"
      />
      <AcceptIntroductionModal
        introductionId={introductionId}
        show={showAcceptIntroductionModal}
        onHide={acceptIntroductionModalClose}
        fullName={fullName}
        handleViewInventoryClick={handleViewInventoryClick}
      />
    </>
  );
};
export default ViewIntroductionModal;
