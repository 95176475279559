import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import EditClientoffcanvas from "./EditClientoffcanvas";
import DeleteWantAdModal from "./DeleteWantAdModal";
import ReportWantAdModal from "./ReportWantAdModal";
import ArchiveModal from "./PauseWantAdModal";
import { getUserId, loggedInUser } from "../../helpers/common";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  archiveWantAd,
  unArchiveWantAd,
  deleteWantAd,
} from "../../services/wantAdService";
import { LONG_RENT, SHORT_RENT } from "../../constants/common";
import CustomLinkOffCanvas from "../commonComponents/CustomLinkOffCanvas";

const ViewADDropdown = (props) => {
  const {
    setShareModal,
    isFromHomeFeed,
    isFromAllIntroduction,
    isFromMyWantAd,
    editWantAd,
    WantCreatedUserId,
    wantAdId,
    adDetails,
    dropDownClass,
    dropDowntoggleClass,
    updateStateAfterDelete,
    disableEdit,
    Client_Want_ad_Id,
    setReload,
    reload
  } = props;
  const navigate = useNavigate();
  const [showEditClient, setShowEditClient] = useState(false);
  const EditClientClose = () => setShowEditClient(false);
  const EditClientShow = () => setShowEditClient(true);
  const userId = getUserId();
  const agent = loggedInUser();

  const [showReportModal, setReportModal] = useState(false);
  const ReportModalClose = () => setReportModal(false);
  const ReportModalShow = () => setReportModal(true);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [archiveModalShow, setArchiveModalShow] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [linkToShare, setLinkToShare] = useState("");

  const handleHideUrlModal = () => {
    setShowUrlModal(false);
  };

  const onWantAdDelete = () => {
    setDeleteModalShow(true);
  };

  /**
   * To handle the want-ad delete
   */
  const handleDeleteWantAd = async () => {
    // setLoading(true)
    if (wantAdId) {
      await deleteWantAd(wantAdId)
        .then((response) => {
          console.log(wantAdId);
          console.log(response);
          const responseResult = _.get(response, "result", null);

          if (response.errorCode === 0 && responseResult) {
            toast.success("Your want-ad has been deleted.");
            setDeleteModalShow(false);
            if(isFromMyWantAd){
              updateStateAfterDelete(wantAdId)
              //navigate(pageURLs.myWantAds);
            } else if(isFromHomeFeed){
              navigate(pageURLs.mobileHome);
            } else{
              navigate(-1);
            }
            
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const onEdit = (unPause =false) => {
    if(isFromMyWantAd){
      editWantAd(wantAdId)
    } else if(unPause == true){
        navigate(pageURLs.mobileEditWantAd, { state: { data: adDetails, isFromUnPause: true } });
    }else{
      navigate(pageURLs.mobileEditWantAd, { state: { data: adDetails } });
    }
  };


  const onWantAdArchived = (id) => {
    setArchiveModalShow(true);
  };


  const handleArchiveWantAd = async () => {
    if (wantAdId) {
      await archiveWantAd(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            toast.dismiss();
            toast.success("Your want-ad has been paused.");
            setArchiveModalShow(false);
            setArchiveStatus(!archiveStatus);
            setReload(reload + 1);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  /**
   * To handle the want-ad un archive
   */
  const handleUnArchiveWantAd = async () => {
    if (wantAdId) {
      if((adDetails?.adType == LONG_RENT && adDetails?.Move_in_TimeLine == 11) || (adDetails?.adType == SHORT_RENT)){
        let unPause = true; // from unpause option
        onEdit(unPause);
      } else{
        await unArchiveWantAd(wantAdId)
        .then((response) => {
          if (response.errorCode == 0) {
            toast.success("Your paused want-ad has been made public again.");
            navigate(pageURLs.myWantAds)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
      }
      
    }
  };

  const showBuyerListingFormUrl = (client_Want_ad_Id) =>{
    setLinkToShare(process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL+"/form/"+agent?.userInfo?.firstName+"-"+agent?.userInfo?.lastName+"-"+client_Want_ad_Id);
    setShowUrlModal(true);
  }

  return (
    <div>
      {showUrlModal && <CustomLinkOffCanvas show={showUrlModal} onHide={handleHideUrlModal} url={linkToShare} />}
      <Dropdown className={dropDownClass|| ""}>
        <Dropdown.Toggle variant="" id="dropdown-client" 
        className={dropDowntoggleClass? dropDowntoggleClass+" action-btn" :"action-btn" }>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.4167 11.6666C11.3371 11.6666 12.0833 10.9204 12.0833 9.99992C12.0833 9.07944 11.3371 8.33325 10.4167 8.33325C9.49619 8.33325 8.75 9.07944 8.75 9.99992C8.75 10.9204 9.49619 11.6666 10.4167 11.6666Z"
              fill="#1B1C1E"
            />
            <path
              d="M15 11.6666C15.9205 11.6666 16.6667 10.9204 16.6667 9.99992C16.6667 9.07944 15.9205 8.33325 15 8.33325C14.0795 8.33325 13.3333 9.07944 13.3333 9.99992C13.3333 10.9204 14.0795 11.6666 15 11.6666Z"
              fill="#1B1C1E"
            />
            <path
              d="M5.83333 11.6666C6.75381 11.6666 7.5 10.9204 7.5 9.99992C7.5 9.07944 6.75381 8.33325 5.83333 8.33325C4.91286 8.33325 4.16667 9.07944 4.16667 9.99992C4.16667 10.9204 4.91286 11.6666 5.83333 11.6666Z"
              fill="#1B1C1E"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {(isFromHomeFeed || isFromAllIntroduction) &&
          WantCreatedUserId !== userId ? (
            <>
              <Dropdown.Item onClick={() => setShareModal(true)}>
                Share
              </Dropdown.Item>
              {WantCreatedUserId !== userId && (
                <Dropdown.Item onClick={() => setReportModal(true)}>
                  Report
                </Dropdown.Item>
              )}
            </>
          ) : (
            <>
              {disableEdit? <Dropdown.Item onClick={() => showBuyerListingFormUrl(Client_Want_ad_Id)}>BuyerListing Form</Dropdown.Item> : <Dropdown.Item onClick={() => onEdit()}>Edit</Dropdown.Item>}
              {adDetails?.isArchived == 0 && (
                <Dropdown.Item onClick={() => onWantAdArchived(wantAdId)}>
                  Pause
                </Dropdown.Item>
              )}
              {adDetails?.isArchived == 1 && (
                <Dropdown.Item onClick={() => handleUnArchiveWantAd()}>
                  Unpause
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => onWantAdDelete()}>
                {" "}
                Delete{" "}
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <EditClientoffcanvas show={showEditClient} onHide={EditClientClose} />
      {showReportModal && <ReportWantAdModal
        show={showReportModal}
        wantAdId={wantAdId}
        onHide={ReportModalClose}
      />}
      <DeleteWantAdModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        handleConfirm={() => handleDeleteWantAd()}
      />
      <ArchiveModal
            show={archiveModalShow}
            onHide={() => setArchiveModalShow(false)}
            handleConfirm={() => handleArchiveWantAd()}
          />
    </div>
  );
};
export default ViewADDropdown;
