import React, { useState, useEffect } from "react";
import EmailVerification from "../components/ResetPassword/EmailVerification";
import ChangePassword from "../components/ResetPassword/ChangePassword";
import { RESET_PWD_EMAIL_VERIFICATION, WANT_AD, USER_INFO_KEY, } from "../../constants/common";
import { resetPassword, updatePassword } from "../../services/loginService";
import {
  resendEmail
} from "../../services/signupService";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { verifyEmail } from "../../services/signupService";
import { convertToNumber, emailValidation, passwordValidation } from "../../helpers/validations";
import { pageURLs } from "../../constants/pageURLs";
import { toast } from "react-toastify";
import { getLocalStorage } from "../../helpers/common";


const ResetPassword = () => {
  const [activePage, setActivePage] = useState();
  const [isEmailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isEmailView = activePage === RESET_PWD_EMAIL_VERIFICATION;
  const state = location.state;
  const isFromSettings = state && state?.isFromSettings;
  const initialFormData = {
    email: "",
    pwd: "",
    confPwd: "",
  };
  const initialErrors = {
    email: "",
    pwd: "",
    confPwd: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [isShowPassWord, setPassWordShow] = useState(false);
  const [passwordType, setPassWordType] = useState("password");
  const [searchParams] = useSearchParams();

  const handleSubmit = async () => {
    const isValid = await validate();

    if (isValid) {
      if (isEmailView) {
        const email = _.get(formData, "email", "");
        let reqPayload = { email: email };
        if (isFromSettings) {
          reqPayload.isFromSettings = true;
        }

        const res = await changePassword(reqPayload);
       
      } else {
        const pwd = _.get(formData, "pwd", "");
        const userId = searchParams.get("Id");
        const gCode = searchParams.get("g_code");
        const action = searchParams.get("action");
        //const decryptedUserId = decryption(userId);
        if (userId) {
          const res = await changePassword({
            resetPassword: pwd,
            token: gCode, 
            action : convertToNumber(action)
          });
          if (_.get(res, "errorCode", "") !== 0) {
            setFormErrors((formErrors) => ({
              ...formErrors,
              pwdError: _.get(res, "message", null),
            }));
          } else {
          }
        }
      }
    }
  };


  const changePassword = async (params) => {
    const email = _.get(params, "email", null);
    if (email) {
      return await resetPassword(params).then((response) => {
        if (response) {
          const resResult = _.get(response, "result", null);
          if (_.get(response, "errorCode", null) === 0) {
            if (_.get(resResult, "IsEmailVerified", null) !== 0) {
              setEmailSent(true);
              toast.success("Reset link successfully sent to the email id.");
              //return { isVerified: true };
            } 
            // else {
            //   // return {
            //   //   isVerified: false,
            //   //   message:
            //   //     "Email id is not verified. Please contact support team.",
            //   // };
            //   setFormErrors((formErrors) => ({
            //     ...formErrors,
            //     email:
            //       "Email id is not verified. Please contact support team.",
            //   }));
            // }
          } else {
        const message = _.get(response, "message", null);
        if (message) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            email: message,
          }));
        }
            //return { isVerified: false, message: "something went wrong." };
          }
        }
        return { isVerified: false, message: "something went wrong." };
      });
    } else {
      return await updatePassword(params).then((response) => {
        if (response) {
          if (_.get(response, "errorCode", "") === 0) {
            toast.success("Password changed successfully. Please login.");
            navigate(pageURLs.mobileSignIn);
            if (isFromSettings) {
              toast.success("Password changed successfully.");
            }
          } else {
            return response;
          }
        }
        return null;
      });
    }
  };

  const onTextChange = (e) => {
    const textChangeField = e.target.name;
    setFormErrors((formErrors) => ({
      ...formErrors,
      [textChangeField]: "",
    }));
    const value = e.target.value;
    setFormData((formData) => ({ ...formData, [e.target.name]: value }));
  };

  const handlePassWordShow = () => {
    setPassWordShow(!isShowPassWord);
  };

  const resentEmail = () => {
    const email = _.get(formData, "email", "");
    const userInfo = {
      Email: email,
      Process: "ResetPwd",
    };
    
    resendEmail(userInfo).then((response) => {
      if (response) {
        if (_.get(response, "errorCode", false) === 0) {
          toast.success("Reset link sent successfully.");
        } else {
          toast.error("something went wrong.");
        }
      }
    });
  };

  useEffect(() => {
    const state = location.state;
    setActivePage(state && state?.activePage);
  }, []);

  useEffect(() => {
    if (!isEmailView) {
      if (isShowPassWord) {
        setPassWordType("text");
      } else {
        setPassWordType("password");
      }
    }
  }, [isShowPassWord]);

  useEffect(() => {
    setFormErrors(initialErrors);
    setFormData(initialFormData);
  }, [activePage]);

  const checkEmailExist = async (email) => {
    const userEmail = {
      Email: email,
    };
    return await verifyEmail(userEmail).then((response) => {
      return response?.result;
    });
  };

  const clearField = () => {
    setFormData((formData) => ({ ...formData, email: "" }));
  };

  const validate = async () => {
    setFormErrors(initialErrors);
    let isValid = true;
    const email = _.get(formData, "email", null).trim();

    if (isEmailView) {
      if (!emailValidation(email)) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          email: "InValid Email",
        }));
        isValid = false;
      } else {
        const res = await checkEmailExist(email);
        const userExist = _.get(res, "UserExists", false);
        const userVerified = _.get(res, "IsVerified", false);
        if (userExist && userVerified) {
          isValid = true;
        } else {
          setFormErrors((formErrors) => ({
            ...formErrors,
            email: "Invalid user",
          }));
          isValid = false;
        }
      }
      if (isFromSettings) {
        const userDetails = getLocalStorage(USER_INFO_KEY);
        const userInfo = _.get(userDetails, "userInfo", "");
        const userEmail = _.get(userInfo, "email", "");
        if (userEmail.trim() !== email.trim()) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            email: "InValid Email",
          }));
          isValid = false;
        }
      }
    } else {
      const pwd = _.get(formData, "pwd", null).trim();
      const passWordError = passwordValidation(pwd);
      if (passWordError) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          pwd: passWordError,
        }));
        isValid = false;
      } else {
        const confPwd = _.get(formData, "confPwd", null).trim();
        if (pwd !== confPwd) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            confPwd: "Password not matching",
          }));
          isValid = false;
        } else {
          isValid = true;
        }
      }
    }

    return isValid;
  };

  const handleBackButtonClick = () => {
    if(isFromSettings) {
      navigate(pageURLs.mobileAccountSettings);
    }
    else {
      if (isEmailView) {
        navigate(pageURLs.mobileSignIn);
      } else {
        setActivePage(RESET_PWD_EMAIL_VERIFICATION);
      }
    }
  };

  if (isEmailView) {
    return (
      <EmailVerification
        handleSubmit={handleSubmit}
        email={_.get(formData, "email", null)}
        emailError={_.get(formErrors, "email", null)}
        onTextChange={onTextChange}
        clearField={clearField}
        handleBackButtonClick={handleBackButtonClick}
        isEmailSent={isEmailSent}
        resentEmail = {resentEmail}
      />
    );
  } else {
    return (
      <ChangePassword
        handleSubmit={handleSubmit}
        handlePassWordShow={handlePassWordShow}
        formData={formData}
        formErrors={formErrors}
        isShowPassWord={isShowPassWord}
        passwordType={passwordType}
        onTextChange={onTextChange}
      />
    );
  }
};

export default ResetPassword;
