import React, { useState, useEffect } from "react";
import noImage from "../../images/no-image-placeholder.png";
import inValidImage from "../../images/invalid-image-placeholder.png";
import FsLightbox from "fslightbox-react";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";

const PhotoGalleryMob = (props) => {
  const {
    photos,
    className = null,
    isFromDetailsPage,
    isFromConfirmationPage,
    setUIKey,
    inventoryId,
  } = props;

  const navigate = useNavigate();
  const photosCount = photos.length;
  const [toggler, setToggler] = useState(false);
  const [photoArray, setPhotoArray] = useState([]);

  useEffect(() => {
    if (photosCount > 0) {
      var newArray = [];
      photos.map((photo) => {
        if (isFromDetailsPage) {
          const isValid = _.get(photo, "IsValid", null);
          if (isValid) {
            newArray.push(photo.image);
          } else {
            newArray.push(inValidImage);
          }
        } else if (isFromConfirmationPage) {
          const isValid = _.get(photo, "IsValid", null);
          if (isValid == 0) {
            newArray.push(inValidImage);
          } else {
            newArray.push(photo.image);
          }
        } else {
          newArray.push(photo.image);
        }
      });
      setPhotoArray(newArray);
    }
  }, [photos]);

  const loadPhotos = (photos, className) => {
    var dom_content = [];
    if (photos.length === 0) {
      return (
        <div
          className={className || "inventory-banner-small position-relative"}
        >
          <div class="banner-row">
            {" "}
            <div className="banner-column-full h-300">
              <div className="full-col-img-wrap h-300 placeholder-wrap-bg brdr-8">
                <img
                  className="brdr-top-10 col-img-style"
                  src={noImage}
                  alt={noImage}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (photosCount > 0) {
      if (photosCount === 1) {
        dom_content.push(
          <div className="banner-column-full h-300">
            <div className="full-col-img-wrap h-300">
              <img
                className="brdr-8 w-100 col-img-style"
                src={photos[0]}
                alt={photos[0]}
                onClick={() => setToggler(!toggler)}
              />
            </div>
          </div>
        );
      }
      if (photosCount === 2) {
        dom_content.push(
          <>
            <div className="banner-column-full d-flex h-300">
              <div className="quarter-col-img-wrap-left w-50-percent">
                <img
                  className="brdr-btm-left-10 brdr-top-left-10 col-img-style"
                  src={photos[0]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
              <div className="quarter-col-img-wrap-right w-50-percent">
                <img
                  className="brdr-btm-right-10 brdr-top-right-10 col-img-style"
                  src={photos[1]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
            </div>
            {/* <div className="banner-column-half h-204">
                            <div className="half-col-img-wrap h-204">
                                <img
                                    className="brdr-top-left-8 brdr-btm-left-8 brdr-t-r-8 no-brdr-b-l-0 col-img-style"
                                    src={photos[0]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                        <div className="banner-column-half h-204">
                            <div className="half-col-img-wrap-right h-204">
                                <img
                                    className="brdr-top-right-8 brdr-btm-right-8 no-brdr-t-r-0 brdr-b-r-8 brdr-b-l-8 col-img-style"
                                    src={photos[1]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div> */}
          </>
        );
      }

      if (photosCount >= 3) {
        dom_content.push(
          <>
            <div className="banner-column-full h-200">
              <div className="full-col-img-wrap h-100">
                <img
                  className="brdr-top-10 col-img-style"
                  src={photos[0]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
            </div>
            <div className="banner-column-full d-flex h-98">
              <div className="quarter-col-img-wrap-left w-50-percent">
                <img
                  className="brdr-btm-left-10 col-img-style"
                  src={photos[1]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
              <div className="quarter-col-img-wrap-right w-50-percent">
                <img
                  className="brdr-btm-right-10 col-img-style"
                  src={photos[2]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
            </div>
          </>
        );
      }

      return (
        <div
          className={className || "inventory-banner-small pb-4 position-relative "}
        >
          {photosCount > 3 ? (
            <button
              className="btn upload-delete-btn-mob upload-btn-btm-pos btnCurve"
              onClick={() => {
                if(isFromDetailsPage){
                  navigate(pageURLs.viewFullGallery, { state: { photos: photos, isFromDetailsPage : isFromDetailsPage , inventoryId: inventoryId} })
                }
                else {setUIKey('imagesFullView')}
                // setToggler(!toggler)
              }}
            >
              +{photosCount - 3}
            </button>
          ) : (
            ""
          )}
          <div class="banner-row">{dom_content}</div>
        </div>
      );
    } else {
      return "";
    }
  };

  const loadFullViewImageArray = () => {
    var fullViewImageArray = [];
    photoArray.map((photo) => {
      fullViewImageArray.push(<img src={photo} />);
    });
    return fullViewImageArray;
  };

  return (
    <>
      {loadPhotos(photoArray, className)}
      <FsLightbox toggler={toggler} sources={loadFullViewImageArray()} />
    </>
  );
};

export default PhotoGalleryMob;
