import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import DispImg1 from "../../images/pastoral-view-img.png";
import FenceIcn from "../Common/FenceIcn";
import Image from 'react-image-webp';

const PropertyDisplay = (props) => {
  const { name, imgPath, webPImg, id, isSelected, order, icon } = props;

  return (
    <div
      className={
        isSelected
          ? "property-display mb-4 selected-property"
          : "property-display mb-4"
      }
    >
      <div className="property-img-wrap">
        <FenceIcn icon = {icon} />
        {order && (
          <div className="fence-icn-count">
            <span>{order}</span>
          </div>
        )}
        {imgPath ? (
          <Image
          src={require("..//../images/" + imgPath)}
          webp={require("..//../images/" + webPImg)}
         />
        ) : (
          <img src={DispImg1} />
        )}
      </div>
      <div className="property-img-info">{name}</div>
    </div>
  );
};
export default PropertyDisplay;
