import { InputGroup, FormControl, Button } from "react-bootstrap";
import React from "react";

const InputGroupControl = (props) => {
  const {
    inputGroupClass,
    ariaLabel,
    ariaDescribedBy,
    placeholder,
    errorMessage,
    iconClass,
    isIconEnable,
    iconId,
    btnVariant,
    value,
    onChange,
    inputRef,
    disabled,
    onClick,
    onBlur
  } = props;
 
  return (
    <>
      <InputGroup className={inputGroupClass} >
        <FormControl
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
          placeholder={placeholder}
          value ={value}
          onChange={onChange}
          ref={inputRef}
          disabled ={disabled}
          onClick = {onClick}
          onBlur={onBlur}
        />

        {isIconEnable ? (
          <Button
            variant={btnVariant}
            className={iconClass}
            id={iconId}
          >
            
          </Button>
          
        ) : (
          ""
        )}
      </InputGroup>
      {errorMessage &&
        <div className="error-msg">
        {errorMessage && errorMessage !== "" && (
          <span className="error-msg">{errorMessage}</span>
        )}
      </div>}
    
    </>
  );
};

export default InputGroupControl;
