import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import MenuBottomBar from "../components/menuBottomMobile";
import LakeViewImage from "../../images/lake-view-icon.svg";
import ConsumerIconImage from "../../images/consumer-icon.svg";
import TimelineIconImage from "../../images/timeline-icon.svg";
import FinanceIconImage from "../../images/financing-icon.svg";
import PropertyActionDropdown from "../components/PropertyActionDropdown";
import MarkAsSoldConfirmModal from "../components/MarkAsSoldModal";
import HelpFeedbackModal from "../components/MarkAsSoldStep2Modal";
import HelpFeedbackModal3 from "../components/MarkAsSoldStep3Modal";
import PhotoGallery from "../../components/Common/PhotoGallery";
import PhotoGalleryMob from "../../components/Common/PhotoGalleryMob";
import { convertToNumber } from "../../helpers/validations";
import { useRef } from "react";
import { getInventoryById, inventoryMarkAsSold } from "../../services/inventoryService";
import { usePrevious } from "../../hooks/usePrevious";
import {
  INVENTORY_ALL_TYPE,
  INVENTORY_KEY,
  INVENTORY_LAND,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  INVENTORY_STRENGTHEN,
  IS_LAND,
  PAGE_LIMIT,
  STRENGTHEN_INVENTORY_KEY,
  USER_TYPE_AGENT,
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
} from "../../constants/common";
import {
  convertToCurrencySystem,
  getLocalStorage,
  getUserId,
  loggedInUser,
  removeDecimalZeros,
  setLocalStorage,
  thousandSeperatedValue,
  truncate,
  replaceComma,
  getUserType,
  isIphone
} from "../../helpers/common";
import { pageURLs } from "../../constants/pageURLs";
import {
  buildMobInventoryMustHaveHtml,
  buildSchoolNeigborhoodHtmlForMobile,
  checkInventoryType,
  loadInventoryLocation,
} from "../../helpers/inventory";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ShowInventoryCreationSuccess from "../components/CreateInventory/ShowInventoryCreationSuccess";
import { inventoryAmenities } from "../../constants/createInventory";
import {
  getMyAcceptedIntroductionByInvId,
  getMyPendingIntroductionByInvId,
} from "../../services/introductionService";
import { useSelector } from "react-redux";

const MemberViewProperty = (props) => {
  const navigate = useNavigate();
  const [inventoryDetails, setInventoryDetails] = useState();
  const [showSuccessModal, setSuccessModal] = useState(false);
  const inventory = getLocalStorage(INVENTORY_KEY);
  const [showStrengthen, setShowStrengthen] = useState(false);
  const [pendingIntroductions, setPendingIntroductions] = useState([]);
  const [pendingIntroductionsCount, setPendingIntroductionsCount] = useState([]);
  const [acceptedIntroductionsCount, setAcceptedIntroductionsCount] = useState([]);
  const inventoryId = _.get(inventory, "inventoryId", "");
  const pageTopRef = useRef(null);
  const userDetails = loggedInUser();
  const loggedInUserId = getUserId();
  const isFromListingPage = _.get(inventory, "isFromListingPage", null);
  const isFromSendIntroduction = _.get(inventory, "isFromSendIntroduction", null);
  const isFromClientInventoryPage = _.get(inventory, "isFromClientInventoryPage", null);
  const clientId = _.get(inventory, "clientId", null);
  
  const isFromMatch = _.get(inventory, "isFromMatch", null);
  const city = _.get(inventoryDetails, "city", "");
  const state = _.get(inventoryDetails, "state", "");
  const zip = _.get(inventoryDetails, "zip", "");
  const isSold = _.get(inventoryDetails, "isSold", "");
  const proprtyPurpose = _.get(inventoryDetails, "proprtyPurpose", []);
  const mapImg = _.get(inventoryDetails, "mapImg", "");
  const description = _.get(inventoryDetails, "invtryDetails", "");
  const invType = checkInventoryType(proprtyPurpose);
  const isAgent = getUserType()===USER_TYPE_AGENT ? true : false;
  const salesPercnt = removeDecimalZeros(
    _.get(inventoryDetails, "salesPercnt", "")
  );
  const salesPercntLTR = removeDecimalZeros(
    _.get(inventoryDetails, "salesPercnt_LTR", "")
  );
  const salesPercntSTR = removeDecimalZeros(
    _.get(inventoryDetails, "salesPercnt_STR", "")
  );
  const [markAsSoldModalShow, setMarkAsSoldModal] = useState(false);
  const handleMarkAsSoldModal = (status) => setMarkAsSoldModal(status);
  const [markAsSoldStatus, setMarkAsSoldStatus] = useState(false);
  const [showGitchaHelpFeedbackModal, setShowGitchaHelpFeedbackModal] =
    useState(false);
  const GitchaHelpFeedbackModalShow = () =>
    setShowGitchaHelpFeedbackModal(true);
  const GitchaHelpFeedbackModalClose = () =>
    setShowGitchaHelpFeedbackModal(false);

    const [isPremiumUser , setIsPremiumUser] = useState(null);
    const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  
    useEffect(() => {
      if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
        if(userDetailsFromStore[0]?.period!==1 && isAgent){
          setIsPremiumUser(true);
        }
        else{
          setIsPremiumUser(false);
        }
      } 
    }, [userDetailsFromStore]);

  useEffect(() => {
    // window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section
    const inventoryId = _.get(inventory, "inventoryId", null);
    const showSuccess = _.get(inventory, "showSuccessModal", null);
    if (showSuccess) {
      setSuccessModal(true);
    }
    getInventoryDetails(inventoryId);
    getPendingIntroductionList();
    getAcceptdIntroductions();
  }, []);

  useEffect(() => {
    if (
      (invType === INVENTORY_SELL ||
        invType === INVENTORY_ALL_TYPE ||
        invType === INVENTORY_SELL_LTR ||
        invType === INVENTORY_SELL_STR ||
        invType === INVENTORY_LTR ||
        invType === INVENTORY_LTR_STR) &&
      !_.get(inventoryDetails, "isStrengthen", false) &&
      !isFromListingPage &&
      propertyType !== IS_LAND && !isAgent
    ) {
      setShowStrengthen(true);
    } else {
      setShowStrengthen(false);
    }
  }, [inventoryDetails]);
  
  const getInventoryDetails = async (inventoryId) => {
    if (inventoryId) {
      await getInventoryById(inventoryId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      userDetails?.userType == 1
        ? navigate(pageURLs.memberCreateInventorynventory)
        : navigate(pageURLs.agentCreateInventory);
    }
  };
  let titleText = "";
  if (isSold) {
    titleText = "Closed ";
  } else {
    switch (invType) {
      case INVENTORY_SELL:
        titleText = " selling";
        break;
      case INVENTORY_LTR:
        titleText = " renting";
        break;
      case INVENTORY_STR:
        titleText = " renting";
        break;
      case INVENTORY_LTR_STR:
        titleText = " renting";
        break;
      case INVENTORY_SELL_LTR:
        titleText = " selling, renting";
        break;
      case INVENTORY_SELL_STR:
        titleText = " selling, renting";
        break;
      case INVENTORY_ALL_TYPE:
        titleText = " selling, renting";
        break;
    }
  }
  const inventoryCreatedUserId = _.get(inventoryDetails, "userId", false);
  const photos = _.get(inventoryDetails, "photos", []);
  const highSchool = _.get(inventoryDetails, "highSchool", null);
  const neighborHood = _.get(inventoryDetails, "neighborHood", null);
  const agent = _.get(inventoryDetails, "isAgentCreated", "");
  const isAgentAsSeller = _.get(inventoryDetails, "isAgentAsSeller", "");
  const clientName =
    _.get(inventoryDetails, "clientName", "") ??
    _.get(inventoryDetails, "clientData.label", "");
  const propertyType = convertToNumber(
    _.get(inventoryDetails, "prprtyType", "")
  );
  const nice2Have = _.get(inventoryDetails, "nice2Have", []);
  const typeBasedOnProperty =
    propertyType === IS_LAND ? INVENTORY_LAND : invType;

  let noBedRm = removeDecimalZeros(_.get(inventoryDetails, "noBedRm", "")); // Multiply by 1 for remove unwanted zeros after decimal .
 
  let noBathRm = removeDecimalZeros(_.get(inventoryDetails, "noBathRm", ""));
  let sqFt = removeDecimalZeros(_.get(inventoryDetails, "sqFt", ""));
  if(noBedRm === 0 || noBedRm === "0"){
        noBedRm = ""
  }
  if(noBathRm === 0 || noBathRm === "0"){
        noBathRm = ""
  }
  if(sqFt === 0 || sqFt === "0"){
        sqFt = ""
  }



  const loadDetails = () => {
    return buildMobInventoryMustHaveHtml({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: inventoryDetails,
    });
  };
  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      typeBasedOnProperty === INVENTORY_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };
  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };
  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <div class="property-tag-mobile mb-2" key={id + uniqueType}>
            <div className="tag-icon-mobile">
              <img
                src={icon ? require("../../images/" + icon) : LakeViewImage}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };
  const isNiceToHaveExist = nice2Have.length > 0;

  const getPendingIntroductionList = async () => {
    const userId = getUserId();

    const page = 1;
    const reqPayload = {
      inventoryId: inventoryId,
      page: page,
      limit: PAGE_LIMIT,
    };
    await getMyPendingIntroductionByInvId(reqPayload).then((response) => {
      if (response) {
        const result = _.get(response, "result", null);
        if (response.errorCode === 0) {
          setPendingIntroductionsCount(result?.Count);
          setPendingIntroductions(result?.List);
        } else {
          console.log(`####error`);
        }
      }
    });
  };

  const getAcceptdIntroductions = async () => {
    const userId = getUserId();

    const page = 1;
    const reqPayload = {
      inventoryId: inventoryId,
      page: page,
      limit: PAGE_LIMIT,
    };
    await getMyAcceptedIntroductionByInvId(reqPayload).then((response) => {
      if (response) {
        const result = _.get(response, "result", null);
        if (response.errorCode === 0) {
          setAcceptedIntroductionsCount(result?.Count);
        } else {
          console.log(`####error`);
        }
      }
    });
  };
  const updateLocalStorage = () => {
    const inventoryId = _.get(inventory, "inventoryId", "");
    setLocalStorage(INVENTORY_KEY, { inventoryId: inventoryId });
  };
  const handleSuccessModalClose = (isClosePopUp) => {
    setSuccessModal(false);
    updateLocalStorage(); // This is for removing the 'showSuccessModal' key from the state to avoid showing the popup again in page refresh.
  };

  const onMarkAsSold = () => {
    handleMarkAsSoldModal(true);
  };

  const handleInventoryMarkAsSold = async (
    inventoryId,
    didGitchHelped,
    salesPrice
  ) => {
    const newPrice = salesPrice?.replace("$", "");
    if (inventoryId) {
      const payload = {
        inventoryId: inventoryId,
        didGitchHelped: didGitchHelped === true ? 1 : 0,
        salesPrice: newPrice ? replaceComma(newPrice) : null,
      };
      await inventoryMarkAsSold(payload)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            toast.success("Your inventory has been successfully mark as sold.");
            GitchaHelpFeedbackModalClose();
            setMarkAsSoldStatus(!markAsSoldStatus);
            navigate(pageURLs.mobileManageInventory, { state: { showClosed: true } });
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };



  useEffect(() => {
    document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
    document.body.classList.add("bg-offcanvas-fix");
    window.addEventListener("scroll", handleScroll); // attaching scroll event listener
  }, []);

  const onEdit = () => {
      navigate(pageURLs.mobileEditInventory, {
        state: {
          data: inventoryDetails,
          inventoryId: inventoryId,
        },
      });
};

  const handleStrngthenInventory = () =>{
    let strengthenAd = null;
    strengthenAd = _.get(inventoryAmenities, STRENGTHEN_INVENTORY_KEY, "");
    _.set(strengthenAd, "proprtyPurpose", INVENTORY_STRENGTHEN);
    if(inventoryDetails){
      _.set(strengthenAd, "proprtyPurpose", INVENTORY_STRENGTHEN);
            _.set(
              strengthenAd,
              "apprOnFile",
              _.get(inventoryDetails, "apprOnFile", false)
            );
            _.set(
              strengthenAd,
              "floodCert",
              _.get(inventoryDetails, "floodCert", false)
            );
            _.set(
              strengthenAd,
              "floorPlan",
              _.get(inventoryDetails, "floorPlan", false)
            );
            _.set(
              strengthenAd,
              "insReport",
              _.get(inventoryDetails, "insReport", false)
            );
            _.set(
              strengthenAd,
              "sellerFin",
              _.get(inventoryDetails, "sellerFin", false)
            );
            _.set(
              strengthenAd,
              "timeLine",
              _.get(inventoryDetails, "timeLine", false)
            );
            _.set(
              strengthenAd,
              "selectedPurposes",
              _.get(inventoryDetails, "proprtyPurpose", [])
            );      
    }
    setLocalStorage(INVENTORY_STRENGTHEN, strengthenAd);
    setTimeout(()=>{
      navigate(pageURLs.strengthenInventoryReponsive);
    }, 500)
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleStrengthenDismiss = () => {
    setShowStrengthen(false);
  };

  // const pageTopRef = useRef(null);
  const scrollDivRef = useRef(null);

  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const prevScrollPosition = usePrevious(scrollPosition);




  const handleScroll = () => {
    const isBottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (!isBottom && window.scrollY > 5) {
      setScrollPosition(window.scrollY);
    }
  };
  
  useEffect(() => {
    if(inventoryDetails){
    if (scrollPosition > prevScrollPosition && scrollDirection !== SCROLL_DIRECTION_DOWN) {
      setScrollDirection(SCROLL_DIRECTION_DOWN);
    } else if (
      scrollPosition < prevScrollPosition &&
      scrollDirection !== SCROLL_DIRECTION_UP
    ) {
      setScrollDirection(SCROLL_DIRECTION_UP);
    }
  }
  
},[scrollPosition])

const handleBackButton = () =>{
  if(isFromClientInventoryPage){
    navigate(pageURLs.mobileClientInventory, { state: { clientId: clientId }})
  } else{
    navigate(pageURLs.mobileManageInventory)
  }
 
}

let mainWrapperClass = "gitcha-main-wrapper pb-180 bg-white windowscroll-fix"
let contentWrapperClass = "content-wrapper content-wrapper-h-180-percent windowscroll-fix"
let innerLayoutClass = "inner-layout-header-new bg-white"
let bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix"
let isEnableIntoductionButton = false
  let isEnableViewWantAdBtn = false
  let introduceBtnClass = ""

console.log(_.get(inventoryDetails, "isSold", ""))

  if(isFromListingPage &&
    (pendingIntroductionsCount > 0 || acceptedIntroductionsCount > 0)    ){
      isEnableIntoductionButton = true
      introduceBtnClass = "pb-90"
  } else{
      if( isFromListingPage && inventoryDetails &&
        !_.get(inventoryDetails, "isSold", "") &&
        _.get(inventoryDetails, "isSold", "") !== 1){
      isEnableViewWantAdBtn = true
      introduceBtnClass = "pb-90"
    }else{
      introduceBtnClass = "pb-0"
    }
  }

if(scrollDirection === SCROLL_DIRECTION_DOWN){
  if(isEnableIntoductionButton || isEnableViewWantAdBtn){
    mainWrapperClass = "gitcha-main-wrapper pb-90 bg-white windowscroll-fix";
  } else{
    mainWrapperClass = "gitcha-main-wrapper pb-0 bg-white windowscroll-fix";
  }

  contentWrapperClass = `content-wrapper content-wrapper-h-180-percent windowscroll-fix ${introduceBtnClass}`
  innerLayoutClass = "inner-layout-header-new bg-white hide-layout-header"
  bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix hide-bottom-footer"
} else if(scrollDirection === SCROLL_DIRECTION_UP){
  mainWrapperClass = "gitcha-main-wrapper pb-180 bg-white windowscroll-fix"
  contentWrapperClass = "content-wrapper content-wrapper-h-180-percent windowscroll-fix"
  innerLayoutClass = "inner-layout-header-new bg-white"
  bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix"
}
if(!isEnableIntoductionButton && !isEnableViewWantAdBtn){
  contentWrapperClass = 'content-wrapper windowscroll-fix content-wrapper-h-90-percent'
  mainWrapperClass = "gitcha-main-wrapper pb-90 bg-white windowscroll-fix";
}


  return (
    <>
     <span ref={pageTopRef}></span>
      {/* <div className="gitcha-main-wrapper pb-180 view-property-wrap">
        <div className="content-wrapper content-wrapper-h-180"> */}
         <div className={mainWrapperClass}>
        <div className={contentWrapperClass}>
          <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a className="back-link-btn"  onClick={() => handleBackButton() }>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>
                <div className="heading">Property</div>
                <div className="right-mobbtn-wrap">
                  <div className="social-btns-wrap">
                    <PropertyActionDropdown
                     onEdit={onEdit}
                    
                    inventoryId={inventoryId}
                    isSold={isSold}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-layout-content-new h-100 bg-white windowscroll-fix">
            <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white h-100 windowscroll-fix">
            {showStrengthen && <div className="strengthen-wantad-wrap mb-4">
                <button className="btn strengthen-ad-closebtn" onClick={()=>handleStrengthenDismiss()}>close</button>
                <h5>
                Get more successful introductions?
                  <span className="ps-2 align-top">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.75 5C3.75 4.30964 4.30964 3.75 5 3.75H6.875C7.56536 3.75 8.125 4.30964 8.125 5V15C8.125 15.6904 7.56536 16.25 6.875 16.25H5C4.30964 16.25 3.75 15.6904 3.75 15V5ZM6.875 5H5V15H6.875V5Z"
                        fill="#1B1C1E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.875 5C11.875 4.30964 12.4346 3.75 13.125 3.75H15C15.6904 3.75 16.25 4.30964 16.25 5V15C16.25 15.6904 15.6904 16.25 15 16.25H13.125C12.4346 16.25 11.875 15.6904 11.875 15V5ZM15 5H13.125V15H15V5Z"
                        fill="#1B1C1E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 6.25C15 5.90482 15.2798 5.625 15.625 5.625H17.5C17.8315 5.625 18.1495 5.7567 18.3839 5.99112C18.6183 6.22554 18.75 6.54348 18.75 6.875V13.125C18.75 13.4565 18.6183 13.7745 18.3839 14.0089C18.1495 14.2433 17.8315 14.375 17.5 14.375H15.625C15.2798 14.375 15 14.0952 15 13.75C15 13.4048 15.2798 13.125 15.625 13.125H17.5V6.875H15.625C15.2798 6.875 15 6.59518 15 6.25Z"
                        fill="#1B1C1E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.61612 5.99112C1.85054 5.7567 2.16848 5.625 2.5 5.625H4.375C4.72018 5.625 5 5.90482 5 6.25C5 6.59518 4.72018 6.875 4.375 6.875H2.5V13.125H4.375C4.72018 13.125 5 13.4048 5 13.75C5 14.0952 4.72018 14.375 4.375 14.375H2.5C2.16848 14.375 1.85054 14.2433 1.61612 14.0089C1.3817 13.7745 1.25 13.4565 1.25 13.125V6.875C1.25 6.54348 1.3817 6.22554 1.61612 5.99112Z"
                        fill="#1B1C1E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.875 10C6.875 9.65482 7.15482 9.375 7.5 9.375H12.5C12.8452 9.375 13.125 9.65482 13.125 10C13.125 10.3452 12.8452 10.625 12.5 10.625H7.5C7.15482 10.625 6.875 10.3452 6.875 10Z"
                        fill="#1B1C1E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.5 10C17.5 9.65482 17.7798 9.375 18.125 9.375H19.375C19.7202 9.375 20 9.65482 20 10C20 10.3452 19.7202 10.625 19.375 10.625H18.125C17.7798 10.625 17.5 10.3452 17.5 10Z"
                        fill="#1B1C1E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 10C0 9.65482 0.279822 9.375 0.625 9.375H1.875C2.22018 9.375 2.5 9.65482 2.5 10C2.5 10.3452 2.22018 10.625 1.875 10.625H0.625C0.279822 10.625 0 10.3452 0 10Z"
                        fill="#1B1C1E"
                      />
                    </svg>
                  </span>
                </h5>
                <p>
                Adding additional information about your property gives
                    people a better idea of what you have.
                </p>
                <button variant="" 
                onClick={() => handleStrngthenInventory()}
                className="btn strengthen-ad-btn">
                Strengthen my Property
                </button>
              </div>}
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    {_.get(inventoryDetails, "isSold", "") ? (
                      <span
                        className={
                          isSold
                            ? "want-ad-tag align-middle orange-tag-bg mb-0 f-size-10"
                            : "want-ad-tag align-middle green-tag-bg mb-0 f-size-10"
                        }
                      >
                        {titleText}
                      </span>
                    ) : (
                      <div className="confirm-sub-title mb-1">
                        I'm open to:{" "}
                        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                          {titleText}
                        </span>
                      </div>
                    )}
                  </div>
                  <h2>{_.get(inventoryDetails, "locAddress", "")}</h2>
                  {/* <span className="trailing-txt pb-1">
                    {loadInventoryLocation({
                      city: city,
                      state: state,
                      zip: zip,
                    })}
                  </span> */}
                   {isPremiumUser && <>
                    {_.get(inventoryDetails, "isAgentCreated", "") == true &&
                      (_.get(inventoryDetails, "isAgentAsSeller", "") ==
                        "true" ||
                      _.get(inventoryDetails, "isAgentAsSeller", "") == 1 ? (
                        <h6 className="long-txt-fix">Represented by Agent</h6>
                      ) : (
                        <h6 className="long-txt-fix">
                          Client:{" "}
                          {_.get(inventoryDetails, "clientName", "")
                            ? _.get(inventoryDetails, "clientName", "")
                            : ""}{" "}
                          <span className="long-txt-fix">
                            (Only visible to you)
                          </span>
                        </h6>
                      ))}
                  </>}
                </div>
                <div className="property-details-mob mb-4">
                {noBedRm && (
                  <>
                   {noBedRm} <span className="details-text-mob">Beds</span>
                  <span className="vertical-divider-mob">|</span>
                  </>
                 
                )}
                {noBathRm && (
                  <>
                   {noBathRm} <span className="details-text-mob">Baths</span>
                  <span className="vertical-divider-mob">|</span>
                  </>
                 
                )}
                {sqFt && (
                  <>
                   <span>{sqFt}</span>
                  <span className="details-text-mob"> sqft</span>
                  </>
                 
                )}
                </div>
              </div>

              <div className="inventory-banner-small position-relative">
                <PhotoGalleryMob photos={photos} isFromDetailsPage={true} inventoryId={inventoryId}/>
                <div className="banner-row">
                  {/* <div className="banner-column-full h-200">
                    <div className="full-col-img-wrap h-100">
                      <img className="brdr-top-10 col-img-style" src="https://gitcha-uploads.s3.us-east-1.amazonaws.com/dev/InventoryImages/231_3301/1_home4.jpg" />
                    </div>
                  </div>
                  <div className="banner-column-full d-flex h-98">
                    <div className="quarter-col-img-wrap-left w-50-percent">
                      <img className="brdr-btm-left-10 col-img-style" src="https://gitcha-uploads.s3.us-east-1.amazonaws.com/dev/InventoryImages/231_3301/2_h-img-1.jpg" />
                    </div>
                    <div className="quarter-col-img-wrap-right w-50-percent">
                      <img className="brdr-btm-right-10 col-img-style" src="https://gitcha-uploads.s3.us-east-1.amazonaws.com/dev/InventoryImages/231_3301/3_h-img-2.jpg" />
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h2>Must-haves</h2>
                <div className="row">
                  {loadDetails()}
                  {/* <div className="col-6 pe-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div>
                  <div className="col-6 ps-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div>
                  <div className="col-6 pe-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div>
                  <div className="col-6 ps-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div>
                  <div className="col-6 pe-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div>
                  <div className="col-6 ps-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div>
                  <div className="col-6 pe-2">
                    <div className="musthave-content-mobwrap mb-3">
                      <div className="musthave-content-label">Budget</div>
                      <div className="musthave-content-text">$400 - 600k</div>

                    </div>
                  </div> */}
                </div>
              </div>


              {buildSchoolNeigborhoodHtmlForMobile({
                mapImg: mapImg,
                highSchool: highSchool,
                neighborHood: neighborHood,
              })}
              {isNiceToHaveExist ? (
                <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                  <h2>Features</h2>
                  {finalNiceToHaveList()}
                </div>
              ) : (
                ""
              )}
              {description && (
                <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                  <h2>Description</h2>
                  <p>{description}</p>
                </div>
              )}

              <div className="buyer-info-mob brdr-top-common-wrap pb-32">
                {/* <h2>{!agent ? "Additional Information" : "Owner Information"}</h2> */}
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap-mob">
                      <img src={ConsumerIconImage} />
                    </span>
                    {!agent ? "Property Owner" : "Represented by Agent"}
                  </li>
                  {salesPercnt && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      {!agent
                        ? "Seller is offering " +
                          salesPercnt +
                          "% to buyer's agent"
                        : salesPercnt + "% compensation to buyers agent"}
                    </li>
                  )}
                  {salesPercntLTR && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      {!agent
                        ? "Long-term Renter is offering " +
                          salesPercntLTR +
                          "% to renter's agent"
                        : salesPercntLTR + "% compensation to renter's agent"}
                    </li>
                  )}
                  {salesPercntSTR && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      {!agent
                        ? "Short-term Renter is offering " +
                          salesPercntSTR +
                          "% to renter's agent"
                        : salesPercntSTR + "% compensation to renter's agent"}
                    </li>
                  )}
                  {_.get(inventoryDetails, "timeLineTxt", "") && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={TimelineIconImage} />
                      </span>
                      Timeline: {_.get(inventoryDetails, "timeLineTxt", "")}
                    </li>
                  )}
                  {_.get(inventoryDetails, "apprOnFile", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      Appraisal on file
                    </li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "insReport", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      Inspection report on file
                    </li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "sellerFin", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={FinanceIconImage} />
                      </span>
                      Seller finance available
                    </li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "floorPlan", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      Floor plan on file
                    </li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "floodCert", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      Flood certificate available
                    </li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "assMortgage", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIconImage} />
                      </span>
                      Assumable mortgage
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      
        {scrollDirection !== SCROLL_DIRECTION_DOWN &&
        <div className="inner-layout-footer-new inner-layout-footer-topfix">
          <div className="bottomMenu bottom-menu-index">
            <MenuBottomBar scrollDirection = {scrollDirection} noScrollBounce = {true} />
          </div>
        </div>}
      </div>

      {isEnableIntoductionButton && (
          <div className={scrollDirection!==SCROLL_DIRECTION_DOWN ?"introduce-property-btnwrap-mob brdr-top-common-wrap"
              :"introduce-property-btnwrap-mob brdr-top-common-wrap bottom-0"}>
          <button type="button" class="btn btn-primary w-100 mb-0"  onClick={() => {
                    navigate(pageURLs.mobileInventoryIntroduction, {
                      state: {
                        inventoryId: inventoryId,
                        title: _.get(inventoryDetails, "locAddress", ""),
                      },
                    });
                  }}>
            My Pending Introductions ({pendingIntroductionsCount})  <i className="ic-caret"></i>
          </button>
        </div>
          )}
        {isEnableViewWantAdBtn && (
        
              <div className={scrollDirection!==SCROLL_DIRECTION_DOWN ?"introduce-property-btnwrap-mob brdr-top-common-wrap"
              :"introduce-property-btnwrap-mob brdr-top-common-wrap bottom-0"}>
              <button type="button" class="btn btn-primary w-100 mb-0" onClick={() => navigate(pageURLs.mobileHome)}>
              View want ads  
          </button>
             
      </div>
      )}

        
      <ShowInventoryCreationSuccess
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        isFromSendIntroduction={isFromSendIntroduction}
        isFromMatch={isFromMatch}
      />
      <MarkAsSoldConfirmModal
        show={markAsSoldModalShow}
        onHide={handleMarkAsSoldModal}
        handleInventoryMarkAsSold={() => {
          GitchaHelpFeedbackModalShow();
          handleMarkAsSoldModal(false);
        }}
        inventoryId={inventoryId}
      />
       <HelpFeedbackModal
        show={showGitchaHelpFeedbackModal}
        onHide={GitchaHelpFeedbackModalClose}
        handleInventoryMarkAsSold={handleInventoryMarkAsSold}
        inventoryId={inventoryId}
      />
    </>
  );
};

export default MemberViewProperty;
