import React, { useEffect, useState } from "react";

import { handleRadioButonChange } from "../../actions/common";
import { BUY, LONG_RENT, SHORT_RENT } from "../../constants/common";
import { usePrevious } from "../../hooks/usePrevious";
import RequestStayAvailability from "../Common/RequestStayAvailability";
import { getLocalStorage } from "../../helpers/common";
const WantAdOption = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    adType,
    clearPreviousStates,
    isEdit = false,
  } = props;

  const turnOffStay = process.env.REACT_APP_TURN_OFF_STAY ? true : false;
  const [showRequestStayPopuop, setShowRequestStayPopup] = useState(false);
  const handleStayRequestPopupOpen = () =>{
    setShowRequestStayPopup(true);
  }

  const handleStayRequestPopupClose = () => {
    setShowRequestStayPopup(false);
  }
  const browserLocation =  getLocalStorage("userLocation")

  useEffect(() => {
    let isDisable;
    if (adType) {
      isDisable = false;
    } else {
      isDisable = true;
    }
    handleContinueButtonState(isDisable);
  }, []);

  const previousAdType = usePrevious(adType);
  const getSelectedValue = (e) => {
    const typeOfAd = handleRadioButonChange(e);
    // if(typeOfAd !== BUY)
    //alert('Not implemented.')
    if(turnOffStay && typeOfAd===SHORT_RENT){
      handleStayRequestPopupOpen()
    }
    else {
    if (typeOfAd) {
      handleContinueButtonState();
      const value = {
        adType: typeOfAd,
        // adType: BUY,
      };
      clearPreviousSelectedValues(value);
      handleFormValues(value);
    }
  }
  };

  const clearPreviousSelectedValues = (currentType) => {
    if (previousAdType && previousAdType !== currentType) {
      clearPreviousStates({ type: "clearAll" });
    }
    if (isEdit) {
      clearPreviousStates({ type: "clearAll" });
    }
  };

  return (
    <>
    <RequestStayAvailability
      show={showRequestStayPopuop}
      locationFromSTorage={browserLocation}
      onHide={handleStayRequestPopupClose}
      />
    <div className={!isEdit ? "center-content-sec mw-460" : ""}>
      <div className="wantad-option-wrap">
        <label className="mb-3">
          <input
            type="radio"
            name="radio-ad-option"
            value={BUY}
            checked={adType && adType === BUY}
            onChange={getSelectedValue}
          />
          <span>
            <div className="option-wrap-info">
              <h2>Buy</h2>
              <p>
                {!isEdit
                  ? "You're looking to purchase property, whether it's your primary residence or investment property."
                  : "Purchasing property"}{" "}
              </p>
            </div>
          </span>
        </label>

        <label className="mb-3">
          <input
            type="radio"
            name="radio-ad-option"
            value={LONG_RENT}
            checked={adType && adType === LONG_RENT}
            onChange={getSelectedValue}
          />
          <span>
            <div className="option-wrap-info">
              <h2>Rent (Long-term)</h2>
              <p>
                {!isEdit
                  ? "You're looking to rent a place to call home. Select this option if you're planning to live here for more than 30 days."
                  : "Stay greater than 30 days"}
              </p>
            </div>
          </span>
        </label>
        <label className="mb-3">
          <input
            type="radio"
            name="radio-ad-option"
            value={SHORT_RENT}
            checked={adType && adType === SHORT_RENT}
            onChange={getSelectedValue}
          />
          <span>
            <div className="option-wrap-info">
              <h2>Rent (Short-term)</h2>
              <p>
                {!isEdit
                  ? " You're looking to rent a place for a short stay or vacation. Select this option if you're planning a stay for less than 30 days."
                  : "Stay less than 30 days"}
              </p>
            </div>
            {/* <div className="right-img-wrap">
              <img src={HouseImg} />
            </div> */}
          </span>
        </label>
      </div>
    </div>
    </>

  );
};
export default WantAdOption;
