import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import RangeSliderMobile from "../RangeSliderMobile";
import AdBtnsWrap from "./adBtnsWrap";
import { replaceComma, thousandSeperatedValue, thousandSeperatedValueFilter } from "../../../helpers/common";
import { BUY, LONG_RENT } from "../../../constants/common";
import { handleNegativeInput } from "../../../helpers/validations";
import { usePrevious } from "../../../hooks/usePrevious";


const Filter = (props) => {
  const { show, onHide, adTypeValue, minBudget, maxBudget, stepValue,
    budgetRangeGitchaGenie, setBudgetRangeGitchaGenie, handleApply, clearFilter } = props;

  const [defaultMin, setDefaultMin] = useState();
  const [defaultMax, setDefaultMax] = useState();
  const [defaultStepValue, setDefaultStepValue] = useState();

  const PRICE_MIN = 0;
  const PRICE_MAX = null;
  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;


  // const budgetMin = previousBudgetMin && previousBudgetMin[0];
  // const budgetMax = previousBudgetMin && previousBudgetMin[1];

  // const prevMinBdgt = usePrevious(budgetRangeGitchaGenie && budgetRangeGitchaGenie[0]);
  // const prevMaxBdgt = usePrevious(budgetRangeGitchaGenie && budgetRangeGitchaGenie[1]);
  const [changedBudgetRangeGitchaGenie, setChangedBudgetRangeGitchaGenie] = useState('');
  const [adType, setAdType] = useState();
  const prevAdType = usePrevious(adType);



  useEffect(() => {
    setChangedBudgetRangeGitchaGenie(budgetRangeGitchaGenie)
  }, [budgetRangeGitchaGenie]);
  useEffect(() => {
    setAdType(adTypeValue)
  }, [adTypeValue]);

  useEffect(() => {
    if (adType === BUY) {
      DEFAULT_MAX_BUDGET = 5000000;
      DEFAULT_STEP_VALUE = 25000;
    } else if (adType === LONG_RENT) {
      DEFAULT_MAX_BUDGET = 7500;
      DEFAULT_STEP_VALUE = 100;
    } else {
      DEFAULT_MAX_BUDGET = 5000;
      DEFAULT_STEP_VALUE = 50;
    }
    setDefaultMax(DEFAULT_MAX_BUDGET)
    setDefaultStepValue(DEFAULT_STEP_VALUE)
    setDefaultMin(DEFAULT_MIN_BUDGET)

    if (adType != prevAdType) {

      setChangedBudgetRangeGitchaGenie([DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET]);
    }
  }, [adType]);



  const handleAdTypeClick = (type) => {
    // console.log(type)
    // ("FilterAdtype")
    // removeLocalStorage("filterQuery")
    // removeLocalStorage("selectedPropertyId")
    // removeLocalStorage("priceRangeMatch")
    setAdType(type);

    // if(adType != type){
    //   setSelectedProperty(null);
    // }
    // setLocalStorage("FilterAdtype", type)
  };



  //   useEffect(() => {
  //     if(propertyList != null){ 
  //      if (prevMinBdgt && prevMinBdgt !== changedBudgetRangeGitchaGenie[0]) {
  //        setLocalStorage("priceRangeMatch", changedBudgetRangeGitchaGenie)
  //       //  updateFilterGitchaGenie({ priceMin: budgetRangeGitchaGenie[0]});
  //      }    
  //    }
  //  },[changedBudgetRangeGitchaGenie && changedBudgetRangeGitchaGenie[0]]);

  //  useEffect(() => {
  //    if(propertyList != null){ 
  //      const mxBdgt = changedBudgetRangeGitchaGenie[1] === DEFAULT_MAX_BUDGET ? "" : changedBudgetRangeGitchaGenie[1];
  //      if (prevMaxBdgt && prevMaxBdgt !== changedBudgetRangeGitchaGenie[1]) {
  //        setLocalStorage("priceRangeMatch", changedBudgetRangeGitchaGenie)
  //       //  updateFilterGitchaGenie({ priceMin : budgetMin, priceMax : mxBdgt});
  //      } 
  //   }
  //  },[changedBudgetRangeGitchaGenie && changedBudgetRangeGitchaGenie[1]]);



  const handleBudgetMinChange = (e) => {
    const budgetMax = changedBudgetRangeGitchaGenie && changedBudgetRangeGitchaGenie[1];
    const value = handleNegativeInput(e.target.value);
    if (parseInt(replaceComma(value))) {
      setChangedBudgetRangeGitchaGenie([parseInt(replaceComma(value)), budgetMax]);
    }
    else {
      setChangedBudgetRangeGitchaGenie([null, budgetMax]);
    }
    // updateFilterGitchaGenie({ priceMin: replaceComma(value) });
  };

  const handleBudgetMaxChange = (e) => {
    const budgetMin = changedBudgetRangeGitchaGenie && changedBudgetRangeGitchaGenie[0];
    const value = handleNegativeInput(e.target.value);
    if (parseInt(replaceComma(value))) {
      setChangedBudgetRangeGitchaGenie([budgetMin, parseInt(replaceComma(value))]);
    }
    else {
      setChangedBudgetRangeGitchaGenie([budgetMin, null]);
    }
    // updateFilterGitchaGenie({ priceMax: replaceComma(value) });  
  };


  const applyFilter = () => {
    handleApply(adType, changedBudgetRangeGitchaGenie);
    // setBudgetRangeGitchaGenie(changedBudgetRangeGitchaGenie)
  }

  return (

    <Offcanvas
      className="mobile-offcanvas zIndexPopup offcanvas-filter-height-fix offcanvas-filter-share-fix"
      show={show}
      onHide={onHide}
      placement='bottom'
      {...props}>
      <Offcanvas.Header className="filter-offcanvas-header" >
        <div className="share-yourad-topsec justify-content-center w-100 pt-32 mb-20">
          <div className="share-yourad-heading w-100 mt-0 mb-0 ">Filters</div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="offcanvas-sharead-wrap ">


          <div className="popupStyle pb-3 ps-3 pe-3">

            <h6 className="text-start">Ad Type</h6>
            <div>
              <AdBtnsWrap handleAdTypeClick={(type) => { handleAdTypeClick(type) }} adType={adType} />
            </div>

            <h6 className="text-start mb-3 mt-3">Price</h6>
            <div>
              <div className="mt-2 mb-2">

                <RangeSliderMobile
                  min={defaultMin}
                  max={defaultMax}
                  // defaultValue={[BUDGET_MIN, BUDGET_MAX]}
                  value={changedBudgetRangeGitchaGenie && [changedBudgetRangeGitchaGenie[0] == null ? defaultMin : changedBudgetRangeGitchaGenie[0], changedBudgetRangeGitchaGenie[1] == null ? defaultMax : changedBudgetRangeGitchaGenie[1]]}
                  stepValue={defaultStepValue}
                  onInput={(value) => { setChangedBudgetRangeGitchaGenie(value) }}
                />
              </div>

              <div className="d-flex justify-content-between pt-4 pb-2">


                <InputGroup className="">
                  <div className="position-relative">
                    <span className="dollar-sign-icon-mob">$</span>
                    <Form.Control
                      placeholder="0"
                      className="pt-2 pb-2 pad-left-25"

                      value={
                        changedBudgetRangeGitchaGenie && (changedBudgetRangeGitchaGenie[0] === "" ? "" : thousandSeperatedValueFilter(changedBudgetRangeGitchaGenie[0]))
                      }
                      onChange={(e) => {
                        handleBudgetMinChange(e);
                      }}
                    />
                  </div>
                </InputGroup>


                <div className="ps-2 pe-2 pt-2">-</div>
                <InputGroup className="">
                  <div className="position-relative">
                    <span className="dollar-sign-icon-mob">$</span>
                    <Form.Control className=" pt-2 pb-2  pad-left-25"

                      value={
                        changedBudgetRangeGitchaGenie && ((changedBudgetRangeGitchaGenie[1] === defaultMax) ? "" : thousandSeperatedValue(changedBudgetRangeGitchaGenie[1]))
                      }
                      placeholder="No max"
                      onChange={(e) => {
                        handleBudgetMaxChange(e);
                      }}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>



          </div>

          <div className="inner-btm-fixed-nav filter-btm-fixed-btnswrap">
            <div className="btn-wrap text-center pt-0">
              <button type="button" className="btn btn-primary w-100 mb-20" onClick={() => { applyFilter() }} >Apply</button>
              <a href={void (0)} className="blue-text-link noIcn pe-0 pt-0 pb-32 d-block" onClick={() => { clearFilter() }}>Clear all</a>
            </div>
          </div>



        </div>



      </Offcanvas.Body>
    </Offcanvas>




  );
};
export default Filter;
