import React, { useState, useEffect } from "react";
import EditUsernameModal from "../components/MemberWantAd/EditUsernameModal";
import EditEmailidModal from "../components/MemberWantAd/EditEmailidModal";
import DeleteAccountModal from "../components/MemberWantAd/DeleteAccountModal";
import SampleProfImg from "../images/sample-profile-image.png";
import EditProfileModal from "../components/MemberWantAd/EditProfileModal";
import PremiumPlanModal from "../components/PlanUpgrade/PremiumPlanModal";
import { getPeriod, formateDate, getPlanPeriodText } from "../helpers/common";
import noImage from "../images/no-image-small.png";
import AccountResetPassword from "../components/Settings/AccountResetPassword";
import ProfileImageCropper from "../mobile/components/Onboarding/ProfileImageCropper";

const ProfileAgentUserPremium = ({ ...props }) => {
  const { userDetails, upComingPlanDetails, isFromNotification, editAgentProfile } = props;
  const [showEditEmailidModal, setEditEmailidModal] = useState(false);
  const EditEmailidModalClose = () => setEditEmailidModal(false);
  const EditEmailidModalShow = () => setEditEmailidModal(true);

  const [showDeleteAccountModal, setDeleteAccountModal] = useState(false);
  const DeleteAccountModalClose = () => setDeleteAccountModal(false);
  const DeleteAccountModalShow = () => setDeleteAccountModal(true);

  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);

  const [showEditProfileModal, setEditProfileModal] = useState(false);
  const EditProfileModalClose = () => setEditProfileModal(false);
  const EditProfileModalShow = () => setEditProfileModal(true);

  const [image, setImage] = useState(null);
  const [showPofileImageUploadModal, setShowPofileImageUploadModal] = useState(false);
  const ShowPofileImageUploadModalClose = () => setShowPofileImageUploadModal(false);
  const ShowPofileImageUploadModalShow = () => setShowPofileImageUploadModal(true);

  const [reRenderEditProfileModel, setReRenderEditProfileModel] = useState(0);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");


  const updateReloadParam = () => {
    props.updateReloadParam();
  };
  const renderEditProfileModel = (uploadedImageUrl) => {
    setUploadedImageUrl(uploadedImageUrl)
    setReRenderEditProfileModel(reRenderEditProfileModel + 1)

  }
  const showResubscribeText = userDetails?.PlanName === upComingPlanDetails?.PlanName ? false : true;

  useEffect(()=>{
    if(editAgentProfile){
      EditProfileModalShow();
    }
  }, [editAgentProfile])

  useEffect(() => {
    if (isFromNotification == true) {
      PremiumPlanModalShow();
    }
  }, [isFromNotification])


  return (
    <>
      <div className="premium-account">
        <div className="content-block">
          <div className="title-sec">
            <h4>Your profile</h4>
            <a role="button" onClick={EditProfileModalShow}>
              Edit profile
            </a>
          </div>
          <div className="profile-info">
            <div className="profile-pic-sec">
              <div className="prof-img mr-12">
                {userDetails?.Img !=null && userDetails?.Img !="" ? (
                  <img src={userDetails?.Img + "?" + Math.random().toString(36)} />
                ) : (
                  <svg
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                      fill="#A0A9BA"
                    />
                  </svg>
                )}
              </div>
              <div className="profile-name">
                <p className="title">
                  {userDetails?.FirstName} {userDetails?.LastName}
                </p>
                <p className="position">Agent @ {userDetails?.Company}</p>
              </div>
            </div>
            {userDetails?.Bio &&
              <>
                <p className="title">About</p>
                <p className="long-txt-fix-new about-info">{userDetails?.Bio}</p>
              </>
            }
          </div>
          <div className="field-horizontal">
            <label>Agency</label>
            <p>{userDetails?.Company}</p>
          </div>
          <div className="field-horizontal">
            <label>Phone number</label>
            <p>{userDetails?.Phone}</p>
          </div>
          <div className="field-horizontal">
            <label>License number</label>
            <p>{userDetails?.LicenseNo}</p>
          </div>
          <div className="field-horizontal">
            <label>Location</label>
            <p>{userDetails?.Location}</p>
          </div>
        </div>
        <div className="content-block subscription-block">
          <div className="title-sec">
            <h4>Your subscription</h4>
            <a role="button" onClick={() => PremiumPlanModalShow()}>
              View plans
            </a>
          </div>
          {upComingPlanDetails?.Is_Upcoming == 1 && (
            <div className="subscription-info-wrap mb-3">
              <div className="subscription-info-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99967 1.3335C4.32634 1.3335 1.33301 4.32683 1.33301 8.00016C1.33301 11.6735 4.32634 14.6668 7.99967 14.6668C11.673 14.6668 14.6663 11.6735 14.6663 8.00016C14.6663 4.32683 11.673 1.3335 7.99967 1.3335ZM7.49967 5.3335C7.49967 5.06016 7.72634 4.8335 7.99967 4.8335C8.27301 4.8335 8.49967 5.06016 8.49967 5.3335V8.66683C8.49967 8.94016 8.27301 9.16683 7.99967 9.16683C7.72634 9.16683 7.49967 8.94016 7.49967 8.66683V5.3335ZM8.61301 10.9202C8.57967 11.0068 8.53301 11.0735 8.47301 11.1402C8.40634 11.2002 8.33301 11.2468 8.25301 11.2802C8.17301 11.3135 8.08634 11.3335 7.99967 11.3335C7.91301 11.3335 7.82634 11.3135 7.74634 11.2802C7.66634 11.2468 7.59301 11.2002 7.52634 11.1402C7.46634 11.0735 7.41967 11.0068 7.38634 10.9202C7.3521 10.84 7.33397 10.754 7.33301 10.6668C7.33301 10.5802 7.35301 10.4935 7.38634 10.4135C7.41967 10.3335 7.46634 10.2602 7.52634 10.1935C7.59301 10.1335 7.66634 10.0868 7.74634 10.0535C7.90865 9.98682 8.0907 9.98682 8.25301 10.0535C8.33301 10.0868 8.40634 10.1335 8.47301 10.1935C8.53301 10.2602 8.57967 10.3335 8.61301 10.4135C8.64634 10.4935 8.66634 10.5802 8.66634 10.6668C8.66634 10.7535 8.64634 10.8402 8.61301 10.9202Z"
                    fill="#4A4C4F"
                  />
                </svg>
              </div>
              {userDetails?.PlanName == "Trial" ? (
                <p>
                  Your {userDetails?.PlanName} subscription will end on{" "}
                  {formateDate(userDetails?.ToDate, "shortMonth")}.
                </p>
              ) : showResubscribeText ? 
                <p>
                  Your {getPlanPeriodText(userDetails?.period)} {userDetails?.PlanName} subscription will end on{" "}
                  {formateDate(userDetails?.ToDate, "shortMonth")}. To
                  keep your {userDetails?.PlanName} features, you can{" "}
                  <a
                    href={void 0}
                    role="button"
                    onClick={() => PremiumPlanModalShow()}
                  >
                    resubscribe here.
                  </a>{" "}
                </p> : 
                <p>
                Your {getPlanPeriodText(userDetails?.period)} {userDetails?.PlanName} subscription will end on{" "}
                {formateDate(userDetails?.ToDate, "shortMonth")}.
              </p>}
            </div>
          )}
          {upComingPlanDetails?.Is_Upcoming == 1 ?
            <div className="field-group">
              <div className="field-vertical">
                <label>Plan</label>
                <p>
                  {upComingPlanDetails?.PlanName}
                </p>
              </div>
              <div className="field-vertical">
                <label>Price</label>
                <p>
                  {upComingPlanDetails?.period === 1
                    ? "Free"
                    : parseInt(upComingPlanDetails?.Price) > 0 ? "$" +
                      parseInt(upComingPlanDetails?.Price) +
                      "/" +
                      getPeriod(upComingPlanDetails?.period) : "_"}
                </p>
              </div>
              <div className="field-vertical">
                <label>Billing date</label>
                <p>
                  {upComingPlanDetails?.Billing_Date
                    ? formateDate(upComingPlanDetails?.Billing_Date, "mm/dd/yyyy")
                    : "-"}
                </p>
              </div>
              <div className="field-vertical">
                <label>Payment</label>
                <p>{upComingPlanDetails?.period !== 1 ? upComingPlanDetails?.Brand + " ending in " + upComingPlanDetails?.Last4 : "-"}</p>
              </div>
            </div>
            : <div className="field-group">
              <div className="field-vertical">
                <label>Plan</label>
                <p>
                  {userDetails?.PlanName == null
                    ? "Member"
                    : userDetails?.PlanName}
                </p>
              </div>
              <div className="field-vertical">
                <label>Price</label>
                <p>
                  {userDetails?.period === 1
                    ? "Free"
                    : parseInt(userDetails?.Price) > 0 ? "$" +
                      parseInt(userDetails?.Price) +
                      "/" +
                      getPeriod(userDetails?.period) : "-"}
                </p>
              </div>
              <div className="field-vertical">
                <label>Billing date</label>
                <p>
                  {userDetails?.Billing_Date
                    ? formateDate(userDetails?.Billing_Date, "mm/dd/yyyy")
                    : "-"}
                </p>
              </div>
              <div className="field-vertical">
                <label>Payment</label>
                <p>{userDetails?.period !== 1 || userDetails?.period == null ? userDetails?.Brand + " ending in " + userDetails?.Last4 : "-"}</p>
              </div>
            </div>}
        </div>
        <div className="content-block">
          <div className="title-sec">
            <h4>Account information</h4>
          </div>
          <div className="field-group">
            <div
              className="field-horizontal link-cursor"
              onClick={() =>
                userDetails?.IsGoogleVerified != 1 ? EditEmailidModalShow() : {}
              }
            >
              <label>Email address</label>
              <p>{userDetails?.Email}</p>
            </div>
            <AccountResetPassword isGoogleUser={userDetails?.IsGoogleVerified} />
          </div>
          <div className="delete-account">
            <div className="field-delete">
              <a
                role="button"
                onClick={() =>
                  DeleteAccountModalShow()
                }
              >
                Delete account
              </a>
              <p>Permanently delete your Gitcha account</p>
            </div>
          </div>
        </div>
      </div>
      <EditEmailidModal
        Email={userDetails?.Email}
        show={showEditEmailidModal}
        userId={userDetails?.UserId}
        onHide={EditEmailidModalClose}
      />
      <DeleteAccountModal
        Email={userDetails?.Email}
        show={showDeleteAccountModal}
        onHide={DeleteAccountModalClose}
      />
      <EditProfileModal
        userDetails={props?.userDetails}
        show={showEditProfileModal}
        onHide={EditProfileModalClose}
        updateReloadParam={updateReloadParam}
        onClickChangePhoto={(image) => { ShowPofileImageUploadModalShow(); setImage(image) }}
        reRenderEditProfileModel={reRenderEditProfileModel}
        uploadedImageUrl={uploadedImageUrl}
        editAgentProfile={editAgentProfile}
      />
      <PremiumPlanModal
        userDetails={upComingPlanDetails?.Is_Upcoming == 1 ? upComingPlanDetails : userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}
        showPlanModal={PremiumPlanModalShow}
      />
      <ProfileImageCropper
        userId={userDetails?.UserId}
        image={image}
        show={showPofileImageUploadModal}
        onHide={() => { ShowPofileImageUploadModalClose(); }}
        updateImageAfterCrop={(imageUrl) => { renderEditProfileModel(imageUrl); ShowPofileImageUploadModalClose(); }}
      />
    </>
  );
};

export default ProfileAgentUserPremium;
