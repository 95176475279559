import React, { useState, useEffect } from "react";
import BarnImage from "../../../images/barn.svg";
import LakeViewImage from "../../../images/lake-view-icon.svg";
import ArtificalturfImage from "../../../images/artificial-turf.svg";
import BeachViewImage from "../../../images/beach-view.svg";
import BonusRoomImage from "../../../images/bonus-room.svg";
import WantadMapImage from "../../../images/confirm-map.png";
import UserIconOutline from "../../images/user-icon-outline.svg";
import NotAFitModal from "./NotAFitModal";
import AcceptIntroModal from "../../components/AcceptIntroModal";
import ReasonNotAFitModal from "../../components/ReasonNotAFitModal";
import "../../styles/clients.scss";
import {
  getIntroductionDetaisById,
  updateIntroNotFit,
  updateIntroductionStatus,
} from "../../../services/introductionService";
import {
  checkTutorialDisplayStatus,
  filterArray,
  formateDate,
  getLocalStorage,
  getpropertyName,
  removeDecimalZeros,
  saveTutorialStatusToLocal,
  setBodyBackgroundWhite,
  thousandSeperatedValue,
} from "../../../helpers/common";
import {
  INTRODUCTION_ACCEPT,
  IS_LAND,
  LOT_TYPES,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
  USER_TYPE_MEMBER,
} from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
import GoogleMaps from "../../../components/Introduction/GoogleMap";
import { updateIntroduction } from "../../../helpers/message";
import LearnIntroductionModal from "../LearnIntroductionModal";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { saveTutorialStatus } from "../../../services/commonServices";
import IntroductionTutorialIconStep1 from "../../../images/tut-introreceived-icon.svg";
import IntroductionTutorialIconStep2 from "../../../images/tut-accept-icon.svg";
import IntroductionTutorialIconStep3 from "../../../images/tut-goodfit-icon.svg";
import DocumentationTutorial from "../../../components/Tutorial/DocumentationTutorial";
import DocumentationTutorialMob from "../../../components/Tutorial/DocumentationTutorialMob";
import { INTRODUCTION_DETAILS, VIEW_RECEIVED_INTRODUCTIONS } from "../../../constants/onBoarding";

const IntroductionPreview = (props) => {
  const tutorialPage = 3;
  const introductionId = props.introductionId;
  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
  }, []);

  const [showNotAFitModal, setShowNotAFitModal] = useState(false);
  const NotAFitModalClose = () => setShowNotAFitModal(false);
  const NotAFitModalShow = () => setShowNotAFitModal(true);

  const [showAcceptIntroModal, setShowAcceptIntroModal] = useState(false);
  const AcceptIntroModalClose = () => setShowAcceptIntroModal(false);
  const AcceptIntroModalShow = () => setShowAcceptIntroModal(true);

  const [showReasonNotAFitModal, setShowReasonNotAFitModal] = useState(false);
  const ReasonNotAFitModalClose = () => setShowReasonNotAFitModal(false);
  const ReasonNotAFitModalShow = () => setShowReasonNotAFitModal(true);

  const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);
  const markFitReasonModalClose = () => setNotFitReasonPopup(false);

  const [details, setIntroDetails] = useState([]);

  const askingPrice = _.get(details, "AskingPrice", "");
  const introDate = _.get(details, "CreatedOn", "");
  const photos = _.get(details, "Photos", []);
  const noPhotosExist = photos?.length === 0;
  const isLand = _.get(details, "PropertyTypeId", "") === IS_LAND;
  const LotSz = _.get(details, "LotSz", "");
  const noBedRm = _.get(details, "Bedrooms", "");
  const noBathRm = _.get(details, "Bathrooms", "");
  const sqFt = removeDecimalZeros(_.get(details, "Sqft", ""));
  const acreage = _.get(details, "acreage", "");
  const lotType = _.get(details, "lotType", "");
  const niceToHave = _.get(details, "N2H_Properties", []);
  const fullName = _.get(details, "Full_Name", "");
  const isAccept = _.get(details, "IsAccept", "");

  const [displayTutorial, setDisplayTutorial] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [disabled, SetDisabled] = useState(false);

  const compensteAgent =
    _.get(details, "Compensate_Agent", null) === 0
      ? null
      : _.get(details, "Compensate_Agent", null);
  const agentPercent =
    _.get(details, "Compensate_Percent", null) === 0
      ? null
      : _.get(details, "Compensate_Percent", null);
  const insReport =
    _.get(details, "Inspection_Report", null) === 0
      ? null
      : _.get(details, "Inspection_Report", null);
  const appraisalRpt =
    _.get(details, "Appraisal_on_File", null) === 0
      ? null
      : _.get(details, "Appraisal_on_File", null);
  const floodCert =
    _.get(details, "Flood_Certificate", null) === 0
      ? null
      : _.get(details, "Flood_Certificate", null);

  const assMortgage =
    _.get(details, "assMortgage", null) === 0
      ? null
      : _.get(details, "assMortgage", null);
  const timeLineTxt = _.get(details, "timeLineTxt", null);

  const [userCoords, setUserCoords] = useState({});
  const handleUserCoords = (lat, lng) => {
    setUserCoords({ lat: lat, lng: lng });
  };
  //const userLocation = getLocalStorage(USER_LOCATION_KEY)
  const defaultProps = {
    center: {
      lat: convertToNumber(_.get(userCoords, "lat", "")),
      lng: convertToNumber(_.get(userCoords, "lng", "")),
    },
    zoom: 12,
  };

  useEffect(() => {
    handleUserCoords(details?.Latitude, details?.Longitude);
  }, [details?.Latitude, details?.Longitude]);

  useEffect(() => {
    setBodyBackgroundWhite()
    setTimeout(function () {
      let tutorialDisplayStatus = checkTutorialDisplayStatus(tutorialPage);
      setShowTutorialModal(tutorialDisplayStatus);
    }, 300);
  }, []);

  const userType = _.get(details, "UserTypeId", "");

  useEffect(() => {
    if (!introductionId) {
      navigate(pageURLs.home);
    }
    getIntroductionDetails(introductionId);
  }, []);

  const getIntroductionDetails = async (introId) => {
    // handleIntroDetailModal(true);
    if (introId) {
      await getIntroductionDetaisById(introId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setIntroDetails(responseResult?.[0]);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const updateToAccepted = async () => {
    SetDisabled(true);
    const reqPayload = {
      introductionId: introductionId,
      status: INTRODUCTION_ACCEPT,
    };

    if (introductionId) {
      await updateIntroductionStatus(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isaccepted: true,
              acceptedat: new Date(),
            };

            let updateresponse = await updateIntroduction(introductionId, data);
            SetDisabled(false);
            AcceptIntroModalShow();
            onHide();
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const updateToNotFit = async (reqPayload) => {
    SetDisabled(true);
    if (reqPayload) {
      await updateIntroNotFit(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isdeleted: true,
            };

            let updateresponse = await updateIntroduction(
              reqPayload?.IntroductionId,
              data
            );
            // setPendingList([]);
            // setStatusChange(INTRODUCTION_NOT_A_FIT);
            //navigate(pageURLs.allIntroductions)
            //markFitReasonModalClose()
            SetDisabled(false);
            props.setUIKey(VIEW_RECEIVED_INTRODUCTIONS);
            //navigate(pageURLs.allIntroductions)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const handleNotFitConfirm = () => {
    NotAFitModalClose();
    setNotFitReasonPopup(true);
  };

  const handleViewInventoryClick = (introductionId) => {
    // navigate(pageURLs.viewIntroducedInventory, { state:{introductionId: introductionId}})
  };

  //Tutorial documentation section
  //Defining tutorial steps
  const [tutorialSteps, setTutorialSteps] = useState([
    {
      target: "#intro-accept-tutorial-target",
      disableBeacon: true,
      placement: "left",
      title: "This is a preview of the introduction you received",
      icon: IntroductionTutorialIconStep1,
      content:
        "You can scroll to view additional information. The full property details are only available when you accept the introduction.",
      hideCloseButton: true,
      showProgress: true,
      showSkipButton: true,
      disableOverlay: false,
    },
    {
      target: "#acceptBtn",
      disableBeacon: true,
      placement: "bottom",
      title: "What happens when I accept?",
      icon: IntroductionTutorialIconStep2,
      content:
        "After you accept this introduction, you'll have 72 hours to send a message to the property owner if you're interested. If not, the introduction will expire.",
      hideCloseButton: true,
      showProgress: true,
      showSkipButton: true,
    },
    {
      target: "#notFitBtn",
      disableBeacon: true,
      placement: "top",
      title: "Not a good fit for you?",
      icon: IntroductionTutorialIconStep3,
      content:
        "If this property doesn't meet your requirements, you can select Not a Fit. You won't receive an introduction for this property again.",
      hideCloseButton: true,
      showProgress: true,
      showSkipButton: false,
    },
  ]);
  //Tutorial tooltip
  const TutorialToolTip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    size,
    isLastStep,
    skipProps,
  }) => (
    <>
      <div {...tooltipProps}>
        <DocumentationTutorialMob
          continuous={continuous}
          index={index}
          step={step}
          backProps={backProps}
          skipProps={skipProps}
          primaryProps={primaryProps}
          isLastStep={isLastStep}
          size={size}
          finalStepBtn={"Got It!"}
        />
      </div>
    </>
  );
  //Tutorial status call back
  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type, lifecycle } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle == "complete"
    ) {
      saveTutorialStatusToLocal(tutorialPage);
      await saveTutorialStatus(tutorialPage, 1); //Save status
    }
  };


  const handleSkipAll = () =>{
    saveTutorialStatusToLocal(tutorialPage)
  }

  return (
    <div className="gitcha-main-wrapper view-client-wrap intro-preview-wrap pb-138 bg-white">
      {displayTutorial ? (
        <JoyRide
          steps={tutorialSteps}
          tooltipComponent={TutorialToolTip}
          continuous
          callback={handleJoyrideCallback}
          disableScrolling={true}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : (
        ""
      )}
      <div className="content-wrapper content-wrapper-h-102-percent">
        <div className="inner-layout-header-new bg-white">
          <div className="review-plan-header header-brdr-btm w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              <a
                className="back-link-small"
                href={void 0}
                onClick={() => {
                  props.setUIKey(VIEW_RECEIVED_INTRODUCTIONS);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
              <span>Introduction Preview</span>
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new">
          <div className="premium-content-wrapper bg-white">
            <div id="intro-accept-tutorial-target">
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    Sent on {introDate && formateDate(introDate, "mm/dd/yyyy")}
                  </div>
                  <h2>
                    {" "}
                    {getpropertyName(details)} in {_.get(details, "City", "")},{" "}
                    {_.get(details, "State", "")}
                  </h2>
                </div>
                <div className="property-details-mob mb-4">
                  {isLand ? (
                     <>
                     {acreage && <span className="details-text-mob">{acreage} acres</span>}
                     {lotType ? 
                     <>
                     <span className="vertical-divider-mob">|</span>
                     {filterArray(LOT_TYPES, "value", lotType)}
                     <span className="details-text-mob">Lot Type</span> 
                     </> 
                     : <></>}
                   </>

                  ) : (
                    <>
                      {noBedRm && (
                        <>
                          {removeDecimalZeros(noBedRm)}{" "}
                          <span className="details-text-mob">
                            {removeDecimalZeros(noBedRm) > 1 ? "Beds" : "Bed"}
                          </span>
                        </>
                      )}
                      {noBathRm && (
                        <>
                          <span className="vertical-divider-mob">|</span>
                          {removeDecimalZeros(noBathRm)}{" "}
                          <span className="details-text-mob">
                            {removeDecimalZeros(noBathRm) > 1
                              ? "Baths"
                              : "Bath"}{" "}
                          </span>
                        </>
                      )}
                      {sqFt && (
                        <>
                          <span className="vertical-divider-mob">|</span>
                          <span>{sqFt}</span>
                          <span className="details-text-mob"> sqft</span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="inventory-banner-small position-relative">
                <div className="banner-row">
                  {/* {noPhotosExist ? (
                                    <div className="banner-column-full h-200">
                                        <div className="full-col-img-wrap h-100">
                                            <img className="brdr-top-10 col-img-style" src={noImage} />
                                        </div>
                                    </div>
                                ) : ( */}

                  {!noPhotosExist && (
                    <>
                      {photos.length == 1 && (
                        <>
                          <div className="banner-column-full h-200">
                            <div className="full-col-img-wrap h-100">
                              <img
                                className="brdr-top-10 col-img-style"
                                src={photos[0]}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {photos.length == 2 && (
                        <>
                          <div className="banner-column-full h-200">
                            <div className="full-col-img-wrap h-100">
                              <img
                                className="brdr-top-10 col-img-style"
                                src={photos[0]}
                              />
                            </div>
                          </div>
                          <div className="banner-column-full h-200">
                            <div className="full-col-img-wrap h-100">
                              <img
                                className="brdr-top-10 col-img-style"
                                src={photos[1]}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {photos.length == 3 && (
                        <>
                          <div className="banner-column-full h-200">
                            <div className="full-col-img-wrap h-100">
                              <img
                                className="brdr-top-10 col-img-style"
                                src={photos[0]}
                              />
                            </div>
                          </div>
                          <div className="banner-column-full d-flex h-98">
                            <div className="quarter-col-img-wrap-left w-50-percent">
                              <img
                                className="brdr-btm-left-10 col-img-style"
                                src={photos[1]}
                              />
                            </div>
                            <div className="quarter-col-img-wrap-right w-50-percent">
                              <img
                                className="brdr-btm-right-10 col-img-style"
                                src={photos[2]}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
              <h3>Details</h3>
              <div className="row">
                <div className="col-6 pe-2">
                  <div className="musthave-content-mobwrap mb-3">
                    <div className="musthave-content-label">Price</div>
                    <div className="musthave-content-text">
                      ${askingPrice && thousandSeperatedValue(askingPrice)}
                    </div>
                  </div>
                </div>
                {LotSz && <div className="col-6 ps-2">
                  <div className="musthave-content-mobwrap mb-3">
                    <div className="musthave-content-label">Lot Size</div>
                    <div className="musthave-content-text">{LotSz} acres</div>
                  </div>
                </div>}

              </div>
            </div>
            <div className="description-wrap-mob brdr-top-common-wrap pb-32">
              <h3>Location</h3>
              <p>
                Exact address will be visible to you after you accept their
                introduction.
              </p>
              <div className="property-listing-ad-mob">
                <div className="property-img-wrapad-mob h-200">
                  {/* <img src={WantadMapImage} /> */}
                  <GoogleMaps
                    defaultProps={defaultProps}
                    mapHeight="100%"
                    mapWidth="100%"
                  />
                </div>
              </div>
            </div>
            <div className="represent-agent brdr-top-common-wrap">
              <div className="d-flex align-items-center">
                <span class="profileIcnDiv d-flex align-items-center justify-content-center">
                  <img src={UserIconOutline} />
                </span>
                {userType == USER_TYPE_AGENT && (
                  <h5 className="m-0">
                    Represented by Agent <span class="pro-blue-badge">PRO</span>
                  </h5>
                )}
                {userType == USER_TYPE_MEMBER && (
                  <h5 className="m-0">Property Owner</h5>
                )}
              </div>
            </div>
            {niceToHave?.length !== 0 && (
              <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                <h3>Features</h3>
                {niceToHave.map((n2h, index) => {
                  const icon = _.get(n2h, "Img_Icon", "");
                  return (
                    <div class="property-tag-mobile mb-2" key={index}>
                      <div class="tag-icon-mobile">
                        <img
                          src={icon ? require("../../../images/" + icon) : ""}
                        />
                      </div>
                      <span>{_.get(n2h, "N2H_CharText", "")}</span>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="buyer-info-mob other-details brdr-top-common-wrap">
              <h3>Other Details</h3>
              <ul>
                {compensteAgent && (
                  <li>
                    {" "}
                    <span className="list-icn-wrap-mob"></span>
                    {agentPercent}% compensation to buyers agent
                  </li>
                )}
                {insReport && (
                  <li>
                    {" "}
                    <span className="list-icn-wrap-mob"></span> Inspection on
                    file
                  </li>
                )}
                {appraisalRpt && (
                  <li>
                    {" "}
                    <span className="list-icn-wrap-mob"></span> Appraisal
                  </li>
                )}
                {floodCert && (
                  <li>
                    {" "}
                    <span className="list-icn-wrap-mob"></span> Flood
                    certification
                  </li>
                )}
                {assMortgage && (
                  <li>
                    {" "}
                    <span className="list-icn-wrap-mob"></span> Assumable
                    mortgage
                  </li>
                )}
                {timeLineTxt && (
                  <li>
                    {" "}
                    <span className="list-icn-wrap-mob"></span> Timeline:{" "}
                    {timeLineTxt}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav">
          <div className="btn-wrap">
            <div className="button-group">
              <button
                type="button"
                id="notFitBtn"
                className="btn btn-light"
                onClick={NotAFitModalShow}
                disabled={disabled}
              >
                Not A Fit
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="acceptBtn"
                onClick={() =>
                  //updateIntroStatus(INTRODUCTION_ACCEPT, introductionId, fullName)
                  updateToAccepted()
                }
                disabled={disabled}
              >
                Accept To View More
              </button>
            </div>
            <button type="button" className="btn btn-link w-100">
              Learn more about introductions
            </button>
          </div>
        </div>
      </div>
      <NotAFitModal
        show={showNotAFitModal}
        onHide={NotAFitModalClose}
        titleText="Mark introduction is not a fit"
        description="This action will end the introduction. Once you delete this it cannot be undone."
        updateToNotFit={updateToNotFit}
        introductionId={introductionId}
        showNotFirReasnPopup={showNotFirReasnPopup}
        handleNotFitConfirm={handleNotFitConfirm}
        markFitReasonModalClose={markFitReasonModalClose}
        // name={fullName}
      />
      <AcceptIntroModal
        show={showAcceptIntroModal}
        onHide={AcceptIntroModalClose}
        handleViewInventoryClick={handleViewInventoryClick}
        onClickViewDetails={() => {
          props.setUIKey(INTRODUCTION_DETAILS);
        }}
      />
      <ReasonNotAFitModal
        show={showReasonNotAFitModal}
        onHide={ReasonNotAFitModalClose}
      />
      <LearnIntroductionModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
        handleShowTutorial={() => setDisplayTutorial(true)}
        isReceiveIntroduction={true}
        handleSkipAll={handleSkipAll}
      />
    </div>
  );
};

export default IntroductionPreview;
