import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { convertToNumber } from "../../helpers/validations";
import { getPreSignedUrl, getPreSignedUrls, getS3Details, uploadFileToS3 } from "../../services/fileUploadService";
import { createS3Folder, getFileType, getLocalStorage, loggedInUser, reSizeImage } from "../../helpers/common";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import { s3ImageBaseFolder } from "../../helpers/common";
import heic2any from "heic2any";
import inValidImage from "../../images/invalid-image-placeholder.png";


const ChangeCoverPhotosModal = (props) => {
    const { show, onHide, selectedProperty, showId } = props
    const inventoryPhotos = selectedProperty?.photosForPreview;
    const [selectedItem, setSelectedItem] = useState([]);
    const [s3ImageFolder, setS3ImageFolder] = useState( selectedProperty?.s3ImageFolder);

    const [inventoryPhoto, setInventoryPhoto] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const MAX_FILE_SIZE = 1000000;
    const supportedImageFormats = ["image/jpg", "image/jpeg", "image/png", "image/heic"];
    const MIN_WIDHT = 700;
    const MIN_HEIGHT = 400;


    useEffect(() => {
        if (inventoryPhotos && inventoryPhotos.length > 0) {
            const NewArray = inventoryPhotos.map((photos, index) => ({
                ...photos,
                id: convertToNumber(index) + 1,
            }));
            let defaultArray = [];
            let OtherMethods = [];
            defaultArray = NewArray?.filter((item) => item.isCover == 1)
            OtherMethods = NewArray?.filter((item) => item.isCover !== 1)
            let orderArray = defaultArray.sort((a, b) => a.order - b.order);
            setSelectedItem(orderArray)
            setInventoryPhoto(NewArray)
        }
        else {
            setInventoryPhoto([])
        }

    }, [showId, inventoryPhotos])


    const handleItemClick = (e) => {
        const itemId = e.currentTarget.id;

        const isAlreadyACoverItem = inventoryPhoto.filter(
            (item) => (convertToNumber(item.id) == convertToNumber(itemId) && item.isCover == 1)
        );

        
        if (isAlreadyACoverItem.length > 0 && selectedItem.length > 1) {

            const coverItemArray = inventoryPhoto.filter(
                (item) => item.isCover == 1
            );

            const newArray = inventoryPhoto.map((photos) => ({
                ...photos,
                isCover: photos.id == itemId ? 0 : photos.isCover,
                order: (photos.id == itemId && photos.isCover == 1) ? coverItemArray.length :
                    (photos.isCover == 1 ? (isAlreadyACoverItem[0].order <= photos?.order ? convertToNumber(photos.order) - 1 : photos?.order) : photos.order),
            }));

            const newCoverItemArray = newArray.filter(
                (item) => item.isCover == 1
            );

            let orderdNewArray = newArray.sort((a, b) => a.id - b.id);
            setInventoryPhoto(orderdNewArray);
            setSelectedItem(newCoverItemArray)
        } else {
            const currentItemExistInCover = selectedItem.filter(
                (item) => convertToNumber(item.id) == convertToNumber(itemId)
            );
            const previouslySelectedItemCount = selectedItem.length;

            if (currentItemExistInCover.length == 0 && previouslySelectedItemCount > 0) {

                let newSelectedArrayAfterRemoveFirstElement = selectedItem.length > 2 ? selectedItem.slice(1) : selectedItem;

                const currentClickedItem = inventoryPhoto.filter(
                    (item) => convertToNumber(item.id) == convertToNumber(itemId)
                );

                const removedCoverOrder = selectedItem[0].order

                // let newOrder = removedCoverOrder == 1 ? 2 : removedCoverOrder == 2 ? 3 : removedCoverOrder == 3 ? 1 : 0;
                // let newOrder =  removedCoverOrder == 3 ? 1 : removedCoverOrder;


                const newArray = inventoryPhoto.map((photos) => ({
                    ...photos,
                    isCover: convertToNumber(photos.id) == itemId ? 1 : selectedItem.length > 2 ? selectedItem[0].id == convertToNumber(photos.id) ? 0 : photos.isCover : photos.isCover,
                    order: (convertToNumber(photos.id) == itemId) ? selectedItem.length > 2 ? removedCoverOrder : selectedItem.length + 1 :
                        selectedItem.length > 2 ? selectedItem[0].id == convertToNumber(photos.id) ? currentClickedItem[0].order : photos.order : photos.order,
                }));

                const currentAddedItem = newArray.filter(
                    (item) => convertToNumber(item.id) == convertToNumber(itemId)
                );


                let orderdNewArray = newArray.sort((a, b) => a.id - b.id);
                setInventoryPhoto(orderdNewArray);

                const newSelectedItem = [...newSelectedArrayAfterRemoveFirstElement, currentAddedItem[0]]
                setSelectedItem(newSelectedItem)

            } else {

                const newArray = inventoryPhoto.map((photos, index) => ({
                    ...photos,
                    isCover: convertToNumber(photos.id) == itemId ? 1 : photos.isCover,
                    order: (convertToNumber(photos.id) == itemId) ? 1 : photos?.order,
                }));

                const currentAddedItem = newArray.filter(
                    (item) => convertToNumber(item.id) == convertToNumber(itemId)
                );
                let orderdNewArray = newArray.sort((a, b) => a.id - b.id);
                setInventoryPhoto(orderdNewArray);

                const newSelectedItem = [...currentAddedItem]
                setSelectedItem(newSelectedItem)
            }

        }
    

    };

    const SavePhotoOrder = () => {
        props.onHide();
        props.onSave(inventoryPhoto);
    }

    const baseFolderPath = s3ImageBaseFolder() + "/InventoryImages";
    useEffect(() => {
        const isFolderExist = getFolder();
        if (!isFolderExist) {
            getFolderName();
        }
      }, []);

    const getFolder = () => {
        let folderName = s3ImageFolder;
        setS3ImageFolder(folderName)
        return folderName;
      };

      const getFolderName = async () => {
        const user = loggedInUser();
        const userId = _.get(user, "userInfo.userId", null);
        const isWantAd = false;
        await createS3Folder(isWantAd).then(function (result) {
          const folderName = result;
          setS3ImageFolder(folderName);
        });
      };

    const handleImage = async (e) => {
        setIsUploading(true);
        const selectedFileType = getFileType(_.get(e.target.files[0], 'name'))
        const fileSize = _.get(e.target.files[0], 'size')
        let file = e.target.files[0];

        const fileType =  _.get(e.target.files[0], 'type');
          const isValidType = _.includes(supportedImageFormats, fileType)
        
          if (isValidType) {
            try {
              if (fileType === "image/heic") {
                // Convert HEIC to JPEG
                const convertedBlob = await heic2any({ blob: file, quality: 1 });

                // Create a new File with the converted data
                const convertedFile = new File(
                  [convertedBlob],
                  `${file.name}.jpeg`,
                  {
                    type: "image/jpeg",
                  }
                );

                // Use the converted file for further processing or upload
                file = convertedFile;
              }
            } catch {}


        const image = new Image();
        image.src = URL.createObjectURL(file); // This is for getting image width and height

        image.addEventListener("load", async () => {
        

        let isValid = false;
      
        if (
           (
                // fileSize <= MAX_FILE_SIZE &&
                image.width >= MIN_WIDHT &&
                image.height >= MIN_HEIGHT
            )
        ) {
            isValid = true;

        }

        if (isValid) {
            const resizedImage =
                fileSize > MAX_FILE_SIZE
                  ? await reSizeImage({
                      file: file,
                      width: MIN_WIDHT,
                      height: MIN_HEIGHT,
                      fileType: selectedFileType,
                    })
                  : file;
            var mimeType = file.type;
            const imageId = (inventoryPhoto.length !== 0 ? inventoryPhoto.length + 1 : 1);
            const fileName = Date.now() + "." + mimeType.split("/").pop();
            const imagePath = baseFolderPath + "/" + s3ImageFolder + "/" + imageId + '_' + fileName;

            const reqPayload = {
                key: imagePath
            }

            await getPreSignedUrl(reqPayload).then((response) => {
                if (response.errorCode === 0) {
                    try {
                        const headersValue = new Headers({ "Content-Type": file.type })
                        fetch(response.result, {
                            method: "PUT",
                            headers: headersValue,
                            body: resizedImage,
                        }).then((response) => {
                            const status = _.get(response, "status", "");
                            const fileFullUrl = _.get(response, "url", "");
                            const fileS3Url = fileFullUrl
                                ? fileFullUrl.split("?")[0]
                                : ""; // For excluding Aws credentials from the url and access s3 image Url.

                            if (status === 200) {

                                if (fileS3Url) {
                                    // const newArray = inventoryPhoto.map((photos, index) => ({
                                    //     ...photos,
                                    //     isCover: convertToNumber(photos.order) == 3 ? 0 : photos.isCover,
                                    //     order: convertToNumber(photos?.order) + 1,
                                    // }));

                                    const newArray = inventoryPhoto;
                                    const coverItemArray = inventoryPhoto.filter(
                                        (item) => item.isCover == 1
                                    );

                                 
                                    let currentItem = {
                                        image: fileS3Url,
                                        isCover: coverItemArray.length >= 3 ? 0 : 1,
                                        order: coverItemArray.length  >= 3 ? inventoryPhoto.length + 1 : coverItemArray.length + 1,
                                    }

                                    newArray.push(currentItem);

                                    const arrayWithId = newArray.map((photos, index) => ({
                                        ...photos,
                                        id: convertToNumber(index) + 1,
                                    }));

                                    let orderdNewArray = arrayWithId.sort((a, b) => a.id - b.id);
                                    setInventoryPhoto(orderdNewArray);
                                    const coverItems = orderdNewArray.filter(
                                        (item) => item.isCover == 1
                                    );

                                    let orderdCoverItems = coverItems.sort((a, b) => a.order - b.order);

                                    setSelectedItem(orderdCoverItems)
                                    setIsUploading(false)

                                }
                            }

                        }).catch((error) => {
                            setIsUploading(false);
                            toast.error(error)
                        })
                    }
                    catch (error) {
                        setIsUploading(false);
                        toast.error(error)
                    }
                }
                else {
                    setIsUploading(false);
                    toast.dismiss();
                    toast.error(response.errorMessage)
                }
            }).catch((err) => {
                setIsUploading(false);
                toast.dismiss()
                toast.error(err)
            })
        } else {
            setIsUploading(false);
            alert('Could not complete the process. Please upload valid images of minimum 700x400 pixels and jpg, jpeg, png or heic file type.')
        }
    });
    } else {
        setIsUploading(false);
        alert('Could not complete the process. Please upload valid images of minimum 700x400 pixels and jpg, jpeg, png or heic file type.')
    }

    }



    return (
        <Modal
            className="customize-intro-modal"
            show={show}
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body className="pb-32 pt-32 pe-0 ps-0">
                <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    onClick={props.onClickClose}
                ></button>

                <h4 className="text-start mt-0 pl-32 pr-32 mt-3 mb-1">
                    Change your cover photos for this introduction.
                </h4>
                <p className=" mb-0 modal-small-para text-start pl-32 pr-32">

                    You can show up to 3 photos in your introduction.

                </p>

                <div className="cover-photo-listwrap">
                    {isUploading ? <Loader /> : ""}

                    <ul className="cover-photo-list">
                        {inventoryPhoto &&
                            inventoryPhoto.map((photo, index) => {
                                return (
                                    <li onClick={handleItemClick}
                                        id={photo?.id}
                                        data={photo}
                                    >
                                        { photo?.IsValid != 0 ?
                                        ( 
                                        <>
                                        {(photo?.isCover == 1) && (
                                            <div className="cover-photo-count">{photo?.order}</div>
                                        )}
                                        <div className="cover-img-wrap">
                                            <img src={photo?.image} />
                                        </div>
                                        </>
                                        ) : 
                                        ( 
                                            <div className="cover-img-wrap">
                                            <img src={inValidImage} />
                                        </div>
                                        )}
                                    </li>

                                )
                            })}

                    </ul>
                </div>


            </Modal.Body>
            <Modal.Footer className="">
                {/*<Button onClick={props.onHide}>Close</Button>*/}

                <div className="add-photos-upload">
                    <input type="file" name="add-photo" id="add-photo" accept="image/*" onChange={(e) => { handleImage(e) }} />
                    <label className="active">Add photos</label>
                </div>

                {/*<a className="preview-intro-link">
                    Add photos
                    <input type="file" name="add-photo" id="add-photo" accept="image/*" onChange={(e) => { handleImage(e) }} />

                </a>*/}
                <div className="modal-btn-wrap modal-btnwrap-width">
                    <Button variant="tertiary" className="btn btn-tertiary me-3 btn-margin" onClick={props.onClickClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="btn" onClick={() => { SavePhotoOrder() }}>
                        Save
                    </Button>

                </div>
            </Modal.Footer>
        </Modal>
    );
};
export default ChangeCoverPhotosModal;
