import React, { useState, useEffect, useRef } from "react";
import DropZoneFileUploadMob from "../Common/DropZoneFileUploadMob";
import PhotoTipsOffcanvas from "../PhotoTipsOffcanvas";
import ProgressBarInventory from "./ProgressBarInventory";
import Loader from "../../../components/Common/Loader";
import {
  getLocalStorage,
  reSizeImage,
  s3ImageBaseFolder,
  setBodyBackgroundWhite,
  setLocalStorage,
  sortItems,
  loggedInUser,
} from "../../../helpers/common";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP } from "../../../constants/common";
import {
  deleteFile,
  getPreSignedUrls,
  getS3Details,
} from "../../../services/fileUploadService";
import { uploadToS3 } from "../../../helpers/S3/uploadToS3";
import { convertToNumber } from "../../../helpers/validations";
import inValidImage from "../../../images/invalid-image-placeholder.png";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { SortablePhoto } from "../SendIntroduction/SortablePhoto";

const UploadPhotos = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    completedPercentage,
    setUIKey,
    formValues,
    handleFormValues,
    setCompletedPercentage,
    isAgent,
    isEdit,
  } = props;
  const photos = _.get(formValues, "photos", []);
  const photosInSession = _.get(getLocalStorage(INVENTORY_KEY), "photos", []);
  const s3Imagefolder = _.get(formValues, "s3Imagefolder", "");
  const isFolderCreated = _.get(formValues, "isFolderCreated", "");
  const [isUploading, setIsUploading] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [invalidImgCount, setInvalidImgCount] = useState(0);
  const [uploadingPhotosCount, setPhotosCount] = useState(0);
  const MAX_FILE_SIZE = 1000000;
  const MAX_FILES = 20;
  const baseFolderPath = s3ImageBaseFolder() + "/InventoryImages/";
  const supportedImageFormats = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/heic",
  ];
  const MIN_WIDHT = 700;
  const MIN_HEIGHT = 400;
  const dragItem = useRef();
  const dragOverItem = useRef();
  const s3BucketBaseUrl = "https://gitcha-uploads.s3.us-east-1.amazonaws.com/";
  const isCoverPositionArray = [1, 2, 3];
  const [disableSave, setDisableSave] = useState(true);
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const getPropertyValue = (object, key) => {
    return _.get(object, key, "");
  };

  const uploadFile = async (uploadedFiles) => {
    setIsUploading(true);
    setInvalidImgCount(0);
    setPhotosCount(0);
    let updatedArray = photos;
    let filesArray = [];
    let urlUniqueId = 1;
    uploadedFiles.map((file, index) => {
      const imageId = (imageCount !== 0 ? imageCount + 1 : 1) + index;
      const fileName = getPropertyValue(file, "name")?.replace(/ /g, "-");
      const imagePath =
        baseFolderPath + getFolder() + "/" + imageId + "_" + fileName;
      filesArray.push({ id: urlUniqueId, path: imagePath, file: file });
      urlUniqueId++;
    });
    setPhotosCount(filesArray?.length);
    const filePathArray = filesArray.map(({ file, ...rest }) => rest); // Removing file from the array for passing into API call for getting PresignedUrls.

    try {
      const folderName = getFolder();
      const reqPayload = !folderName
        ? { folder: baseFolderPath + getFolder() + "/", keys: filePathArray }
        : { keys: filePathArray }; // Creating s3 folder for first image upload.
      const response = await getPreSignedUrls(reqPayload);
      if (response && _.get(response, "errorCode") === 0) {
        const preSignedUrlsArray = _.get(response, "result", []);
        let count = imageCount !== 0 ? imageCount + 1 : 1;

        filesArray.forEach(async (fa, index) => {
          let file = _.get(fa, "file", "");

          const fileType = file.type;
          const isValidType = _.includes(supportedImageFormats, fileType);

          if (isValidType) {
            try {
              if (fileType === "image/heic") {
                // Convert HEIC to JPEG
                const convertedBlob = await heic2any({
                  blob: file,
                  quality: 1,
                });

                // Create a new File with the converted data
                const convertedFile = new File(
                  [convertedBlob],
                  `${file.name}.jpeg`,
                  {
                    type: "image/jpeg",
                  }
                );

                // Use the converted file for further processing or upload
                file = convertedFile;
              }
            } catch {}

            const image = new Image();
            image.src = URL.createObjectURL(file); // This is for getting image width and height

            image.addEventListener("load", async () => {
              const fileSize = getPropertyValue(file, "size");
              const fileType = getPropertyValue(file, "type");
              const fileId = getPropertyValue(fa, "id");
              const filteredArray = preSignedUrlsArray.filter(function (url) {
                return url.id === fileId;
              });

              let isValid = await isValidImage(
                fileType,
                image.width,
                image.height,
                fileSize
              );
              const currentCount = count;

              const preSignedS3Url = filteredArray
                ? _.get(filteredArray[0], "presignedUrl", "")
                : "";

              if (isValid) {
                const resizedImage =
                  fileSize > MAX_FILE_SIZE
                    ? await reSizeImage({
                        file: file,
                        width: MIN_WIDHT,
                        height: MIN_HEIGHT,
                        fileType: fileType,
                      })
                    : file;

                const isCover = _.includes(isCoverPositionArray, currentCount);
                if (currentCount > MAX_FILES) {
                  setIsUploading(false);
                  alert("Upload limit 20 exceeds.");
                } else {
                  try {
                    await new Promise((resolve) => {
                      resolve(count++);
                    });

                    const res = await uploadToS3(preSignedS3Url, resizedImage);
                    const status = _.get(res, "status", "");
                    const fileFullUrl = _.get(res, "config.url", "");
                    const fileS3Url = fileFullUrl
                      ? fileFullUrl.split("?")[0]
                      : ""; // For excluding Aws credentials from the url and access s3 image Url.

                    if (status === 200) {
                      if (fileS3Url) {
                        updatedArray.push({
                          image: fileS3Url,
                          isCover: isCover,
                          order: currentCount,
                          fileId: fileId,
                        });
                        setImageCount((prevState) => prevState + 1);
                        handleFormValues({
                          photos: updatedArray,
                        });
                      }
                    } else {
                      console.log(`---#####---Error---#####---`);
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }
              } else {
                setInvalidImgCount((prevState) => prevState + 1);
              }
            });
          } else {
            setInvalidImgCount((prevState) => prevState + 1);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFolder = () => {
    let folderName = s3Imagefolder;
    if (!folderName) {
      const inventoryDetails = getLocalStorage(INVENTORY_KEY_TEMP);
      folderName = _.get(inventoryDetails, "s3ImageFolder", "");
    }
    return folderName;
  };

  const isValidImage = async (
    selectedFileType,
    imgWidth,
    imgHeight,
    fileSize
  ) => {
    if (
      // fileSize <= MAX_FILE_SIZE &&
      imgWidth >= MIN_WIDHT &&
      imgHeight >= MIN_HEIGHT
    ) {
      return true;
    }
    return false;
  };

  const getS3FolderName = async () => {
    const user = loggedInUser();
    const userId = _.get(user, "userInfo.userId", null);
    const folderName = getFolder();

    const requestPayload = {folderName: folderName };
    await getS3Details(requestPayload)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const resResult = _.get(response, "result", null);
          const folderName = _.get(resResult, "folderName", "");
          handleFormValues({
            s3ImageFolder: folderName,
          });
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    setBodyBackgroundWhite()
    setImageCount(photos.length);
    const isFolderExist = getFolder();
    if (!isFolderExist) {
      getS3FolderName();
    }
  }, []);

  useEffect(() => {
    if (uploadingPhotosCount === 1 && invalidImgCount === 1) {
      setIsUploading(false);
      alert(
        "Could not complete the process. Please upload valid files of minimum 700x400 pixels and jpg, jpeg, png or heic file type."
      );
    } else if (uploadingPhotosCount > 1 && invalidImgCount === 1) {
      alert(
        `Failed to upload ${invalidImgCount} file. Please upload valid files only of minimum 700x400 pixels and jpg, jpeg, png or heic file type.`
      );
    } else if (invalidImgCount > 1) {
      alert(
          `Failed to upload ${invalidImgCount} files. Please upload valid files only of minimum 700x400 pixels and jpg, jpeg, png or heic file type.`
      );
    }
  }, [invalidImgCount, uploadingPhotosCount]);

  useEffect(() => {
    setIsUploading(false);
    let isDisable = true;
    if (photos.length === 0) {
      setDisableSave(isDisable);
    } else {
      if (isEdit) {
        const condition =
          photos.length > photosInSession.length
            ? _.differenceWith(photos, photosInSession, _.isEqual).length <= 0
            : _.differenceWith(photosInSession, photos, _.isEqual).length <= 0;
        if (condition) {
          setDisableSave(isDisable);
        } else {
          setDisableSave(false);
        }
      } else {
        isDisable = false;
        setDisableSave(isDisable);
      }
    }
  }, [photos.length, photos]);

  const dragStart = (position) => {
    dragItem.current = position;
  };
  const dragEnter = (position) => {
    dragOverItem.current = position;
  };

  const drop = (order) => {
    const dragItemOrder = order;
    const dragOverItemOrder = convertToNumber(dragOverItem.current) + 1;
    if (dragItem.current !== dragOverItem.current) {
      const reOrderedArray = reArrangeImage(dragItemOrder, dragOverItemOrder);
      handleFormValues({ photos: reOrderedArray });
    }
    dragItem.current = null;
    dragOverItem.current = null;
  };

  const deleteFileFromS3 = async (imagePath, order) => {
    console.log("delete")
    const inventoryDetails = getLocalStorage(INVENTORY_KEY_TEMP);
    const inventoryId = _.get(inventoryDetails, "inventoryId", "");
    const requestPayload = { inventoryId: inventoryId, keys: [imagePath] };

    await deleteFile(requestPayload)
      .then((response) => {
        if (response.errorCode === 0) {
          const reOrderedPhotosArray = reOrderingAfterDelete(order);
          handleFormValues({
            photos: reOrderedPhotosArray,
          });
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const reOrderingAfterDelete = (updatedImageOrder) => {
    const photosCopy = [...photos];
    const filterPhotosArray = photosCopy.filter(function (imgArray) {
      return imgArray.order !== updatedImageOrder;
    });
    setImageCount(filterPhotosArray?.length);

    const reOrderedPhotos = filterPhotosArray.map((img) => {
      const currentOrder = convertToNumber(_.get(img, "order", ""));
      if (currentOrder > convertToNumber(updatedImageOrder)) {
        const updatedOrder = convertToNumber(currentOrder) - 1;
        const isCover = _.includes(isCoverPositionArray, updatedOrder);
        return {
          image: _.get(img, "image", ""),
          order: updatedOrder,
          isCover: isCover,
        };
      } else {
        return {
          image: _.get(img, "image", ""),
          order: convertToNumber(currentOrder),
          isCover: _.get(img, "isCover", ""),
        };
      }
    });
    return reOrderedPhotos;
  };
  const reArrangeImage = (dragItemOrder, dragOverItemOrder) => {
    const photosCopy = [...photos];
    if (dragItemOrder < dragOverItemOrder) {
      const reOrderedPhotos = photosCopy.map((img) => {
        const currentOrder = convertToNumber(_.get(img, "order", ""));
        if (currentOrder === convertToNumber(dragItemOrder)) {
          const isCover = _.includes(isCoverPositionArray, dragOverItemOrder);
          return {
            image: _.get(img, "image", ""),
            order: dragOverItemOrder,
            isCover: isCover,
          };
        } else if (
          currentOrder <= convertToNumber(dragOverItemOrder) &&
          currentOrder > convertToNumber(dragItemOrder)
        ) {
          const updatedOrder = currentOrder - 1;
          return {
            image: _.get(img, "image", ""),
            order: updatedOrder,
            isCover: _.includes(isCoverPositionArray, updatedOrder),
          };
        } else {
          return {
            image: _.get(img, "image", ""),
            order: convertToNumber(_.get(img, "order", "")),
            isCover: _.get(img, "isCover", ""),
          };
        }
      });

      return reOrderedPhotos;
    }
    if (dragItemOrder > dragOverItemOrder) {
      const reOrderedPhotos = photosCopy.map((img) => {
        const currentOrder = convertToNumber(_.get(img, "order", ""));
        if (currentOrder === convertToNumber(dragItemOrder)) {
          const isCover = _.includes(
            isCoverPositionArray,
            convertToNumber(dragOverItemOrder)
          );
          return {
            image: _.get(img, "image", ""),
            order: convertToNumber(dragOverItemOrder),
            isCover: isCover,
          };
        } else if (
          currentOrder <= convertToNumber(dragItemOrder) &&
          currentOrder >= convertToNumber(dragOverItemOrder)
        ) {
          const updatedOrder = currentOrder + 1;
          return {
            image: _.get(img, "image", ""),
            order: updatedOrder,
            isCover: _.includes(isCoverPositionArray, updatedOrder),
          };
        } else {
          return {
            image: _.get(img, "image", ""),
            order: convertToNumber(_.get(img, "order", "")),
            isCover: _.get(img, "isCover", ""),
          };
        }
      });
      return reOrderedPhotos;
    }
  };

  function handleDragStart(event) {
    setActiveId(event.active.id);
}

function handleDragEnd(event) {
    const { active, over } = event;
    let sourceIndex = active.data?.current?.sortable?.index;
    let destinationIndex = over.data?.current?.sortable?.index;
    if (sourceIndex !== destinationIndex) {

        let newInventoryPhotos = photos;
        newInventoryPhotos.splice(destinationIndex, 0, newInventoryPhotos.splice(sourceIndex, 1));

        newInventoryPhotos = newInventoryPhotos.map(item => Array.isArray(item) ? item[0] : item);
        newInventoryPhotos = newInventoryPhotos.map((feature, index) => {
            return { ...feature, order: index + 1, isCover: index > 2 ? 0 : 1 };
        });

        let orderdImages = newInventoryPhotos.sort((a, b) => a.order - b.order);
        handleFormValues({photos : orderdImages});
    }

    setActiveId(null);
}

function handleDragCancel() {
    setActiveId(null);
}

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
          />
          {imageCount === 0 ? (
            <div className="inner-layout-content h-100 bg-white">
              <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white h-100">
                <div className="top-three-comp-btm-sec mt-0 mb-3">
                  <h6 className="mb-2">Photos (0/20)</h6>
                  <h2 className="mb-2">
                    Upload photos of {isAgent ? "the" : "your"} property
                  </h2>
                  <p className="para-btm-common mb-0">
                    Using high quality images will increase the chances of
                    people accepting your introductions.
                  </p>
                </div>

                <div className="file-upload-sec-mob clearfix">
                  <div className="file-upload-outer-mob">
                    <div className="file-upload-inner-mob mt-0 position-relative">
                      {isUploading ? <Loader /> : ""}

                      <DropZoneFileUploadMob
                        isAgent={isAgent}
                        uploadFile={uploadFile}
                        uploadIconExist={true}
                        uploadTitle="We recommend adding at least 5"
                        uploadDesc="We recommend adding at least 5"
                      />
                    </div>
                  </div>
                  <div className="file-upload-text-mob">
                    <div className="file-upload-info">
                      Here are some tips for taking photos of your property.
                    </div>
                    <a
                      className="blue-info-icon-mob"
                      href={void 0}
                      role="button"
                      onClick={handleShow}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM8 12C7.85167 12 7.70666 11.956 7.58333 11.8736C7.45999 11.7912 7.36386 11.6741 7.30709 11.537C7.25033 11.4 7.23548 11.2492 7.26441 11.1037C7.29335 10.9582 7.36478 10.8246 7.46967 10.7197C7.57456 10.6148 7.7082 10.5434 7.85368 10.5144C7.99917 10.4855 8.14997 10.5003 8.28701 10.5571C8.42406 10.6139 8.54119 10.71 8.6236 10.8333C8.70602 10.9567 8.75 11.1017 8.75 11.25C8.75 11.4489 8.67098 11.6397 8.53033 11.7803C8.38968 11.921 8.19892 12 8 12ZM8.5 8.955V9C8.5 9.13261 8.44732 9.25979 8.35356 9.35355C8.25979 9.44732 8.13261 9.5 8 9.5C7.86739 9.5 7.74022 9.44732 7.64645 9.35355C7.55268 9.25979 7.5 9.13261 7.5 9V8.5C7.5 8.36739 7.55268 8.24021 7.64645 8.14645C7.74022 8.05268 7.86739 8 8 8C8.82688 8 9.5 7.4375 9.5 6.75C9.5 6.0625 8.82688 5.5 8 5.5C7.17313 5.5 6.5 6.0625 6.5 6.75V7C6.5 7.13261 6.44732 7.25979 6.35356 7.35355C6.25979 7.44732 6.13261 7.5 6 7.5C5.86739 7.5 5.74022 7.44732 5.64645 7.35355C5.55268 7.25979 5.5 7.13261 5.5 7V6.75C5.5 5.50938 6.62125 4.5 8 4.5C9.37875 4.5 10.5 5.50938 10.5 6.75C10.5 7.83625 9.64 8.74563 8.5 8.955Z"
                          fill="#009CDA"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="inner-layout-content h-100 bg-white">
              <div className="premium-content-wrapper pe-0 ps-0 pt-4 bg-white h-100">
                <div className="top-three-comp-btm-sec pe-3 ps-3 mt-0 mb-3">
                  <h6 className="mb-2">Photos ({imageCount}/20)</h6>
                  <h2 className="mb-2">Upload photos of your property</h2>
                  <p className="para-btm-common mb-0">
                    Drag to rearrange photos. The first photo will be the cover
                    photo for your property.
                  </p>
                </div>

                <div className="uploaded-files-wrap-mob">
                  <section className="uploaded-file-list-mob w-100 h-100 position-relative">
                    {isUploading ? <Loader /> : ""}
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      onDragCancel={handleDragCancel}
                    >
                      <SortableContext items={photos} strategy={rectSortingStrategy}>
                      {photos
                        .sort(sortItems("asc", "order"))
                        .map((img, index) => {
                          
                              return (
                                <SortablePhoto key={index} url={img} index={index} fromInventory={true} handleDelete={deleteFileFromS3} />
                            )                          
                        })}
                        </SortableContext>
                    </DndContext>
                    <div className="uploaded-photos-btnwrap-outer-mob">
                      <div className="uploaded-photos-btnwrap-inner-mob">
                        <DropZoneFileUploadMob
                          isAgent={isAgent}
                          uploadFile={uploadFile}
                          imagesAdded={true}                          
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div className="file-upload-text-mob pe-3 ps-3">
                  <div className="file-upload-info">
                    Here are some tips for taking photos of your property.
                  </div>
                  <a
                    className="blue-info-icon-mob"
                    href={void 0}
                    onClick={handleShow}
                    role="button"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM8 12C7.85167 12 7.70666 11.956 7.58333 11.8736C7.45999 11.7912 7.36386 11.6741 7.30709 11.537C7.25033 11.4 7.23548 11.2492 7.26441 11.1037C7.29335 10.9582 7.36478 10.8246 7.46967 10.7197C7.57456 10.6148 7.7082 10.5434 7.85368 10.5144C7.99917 10.4855 8.14997 10.5003 8.28701 10.5571C8.42406 10.6139 8.54119 10.71 8.6236 10.8333C8.70602 10.9567 8.75 11.1017 8.75 11.25C8.75 11.4489 8.67098 11.6397 8.53033 11.7803C8.38968 11.921 8.19892 12 8 12ZM8.5 8.955V9C8.5 9.13261 8.44732 9.25979 8.35356 9.35355C8.25979 9.44732 8.13261 9.5 8 9.5C7.86739 9.5 7.74022 9.44732 7.64645 9.35355C7.55268 9.25979 7.5 9.13261 7.5 9V8.5C7.5 8.36739 7.55268 8.24021 7.64645 8.14645C7.74022 8.05268 7.86739 8 8 8C8.82688 8 9.5 7.4375 9.5 6.75C9.5 6.0625 8.82688 5.5 8 5.5C7.17313 5.5 6.5 6.0625 6.5 6.75V7C6.5 7.13261 6.44732 7.25979 6.35356 7.35355C6.25979 7.44732 6.13261 7.5 6 7.5C5.86739 7.5 5.74022 7.44732 5.64645 7.35355C5.55268 7.25979 5.5 7.13261 5.5 7V6.75C5.5 5.50938 6.62125 4.5 8 4.5C9.37875 4.5 10.5 5.50938 10.5 6.75C10.5 7.83625 9.64 8.74563 8.5 8.955Z"
                        fill="#009CDA"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                disabled={disableSave}
                className="btn btn-primary w-100 mb-0"
                onClick={() => {
                  if (isAgent) {
                    if (completedPercentage >= 75) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(75);
                    }
                  } else {
                    if (completedPercentage >= 90) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(90);
                    }
                  }
                  setTimeout(() => {
                    setLocalStorage(
                      INVENTORY_KEY,
                      getLocalStorage(INVENTORY_KEY_TEMP)
                    );
                    setUIKey(null);
                  }, 500);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <PhotoTipsOffcanvas show={show} onHide={handleClose} />
    </>
  );
};

export default UploadPhotos;
