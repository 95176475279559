import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import TextAreaMob from "../TextAreaMob";
import { updateIntroReplyStatus } from "../../../services/messagesService";
import { addMessage, loginOrSignUp, updateIntroduction } from "../../../helpers/message";
import MessageSentOffcanvas from "./MessageSentSuccessOffcanvas";
import { loggedInUser } from "../../../helpers/common";

const SendMessageOffcanvas = (props) => {
  const {show, onHide} = props;

  const [message, SetMessage] = useState('');
  const [disabledStatus, SetDisabled] = useState(false);

  const [showMessageSentOffcanvas, setShowMessageSentOffcanvas] = useState(false);
  const MessageSentOffcanvasClose = () => setShowMessageSentOffcanvas(false);
  const MessageSentOffcanvasShow = () => setShowMessageSentOffcanvas(true);

  const userDetails = loggedInUser();

  const handleMessageChange = (e) => {
    SetMessage(e.target.value);
}

const sendMessage = async () => {
    SetDisabled(true);
    let email = _.get(userDetails, "userInfo.email", null);
    let data = {
        introductionId: props.IntroductionId,
        isrepiled: true,
        latestMessage: message,
        lastestMessageSentUserId: _.get(userDetails, "userInfo.userId", null),
    }

    let messageDoc = {
        createdUserId: _.get(userDetails, "userInfo.userId", null),
        uniqueId: props.IntroductionId,
        text: message,
    };

    let response = await addMessage(props.IntroductionId, messageDoc);
    let updateresponse = await updateIntroduction(props.IntroductionId, data);
    console.log("response", response, "updateresponse", updateresponse)
    if (response == 1 || updateresponse == 1) {
        await updateIntroReplyStatus(props.IntroductionId)
            .then(async (response) => {
                if (response.errorCode == 0) {
                    props.onHide();
                    setShowMessageSentOffcanvas(true);
                    SetDisabled(false);
                    props.msgSentStatus(true);

                } else {
                    console.log("Something went wrong");
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });

    }
}

  return (
<>
    <Offcanvas
     className="mobile-offcanvas offcanvas-fixed message-client-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}> 
        <Offcanvas.Header>
            
            <Offcanvas.Title>
                <div className="reply-time d-flex align-items-center justify-content-center">
                    <i className="ico-timer"></i><p className="m-0">You have <span>{props.timeAgo?.time} {props.timeAgo?.type}</span> left to reply</p>
                </div>
            </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
              <div className="client-message-sec">
                  <div className="reply-form">
                  {props.messageFromSendIntro &&
                    <div className="field-group">
                        <div className="mob-fld-wrap">
                            <label className="form-label">Peter said:</label>
                        </div>
                        <TextAreaMob
                          maxLength={2000}
                            value={props.messageFromSendIntro}
                            onChange={(e) => { handleMessageChange(e) }}
                            disabled
                        />
                    </div>
}
                    <div className="field-group">
                        <div className="mob-fld-wrap">
                            <label className="form-label">Your reply:</label>
                        </div>
                        <TextAreaMob
                            maxLength={2000}
                            onChange={(e) => { handleMessageChange(e) }}
                        />
                    </div>
                  </div>
                  <div className="inner-btm-fixed-nav">
                      <div className="btn-wrap text-center">
                          <button type="button" className="btn btn-primary w-100"  disabled={(message.length > 0 && !disabledStatus) ? false : true}
                            onClick={() => { sendMessage() }}>Send Message</button>

                      </div>
                  </div>
              </div>
        </Offcanvas.Body>
  </Offcanvas>

<MessageSentOffcanvas
show={showMessageSentOffcanvas}
onHide={MessageSentOffcanvasClose}
/>

</>
    
  );
};
export default SendMessageOffcanvas;
