/**
 * @file   src\components\Common\GeoLocation.js
 * @brief  This file is responsible for handling browser location permission
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useEffect } from "react";
import { GET_USER_IP } from "../../constants/endPoints";
import { setLocalStorage } from "../../helpers/common";
import { HOME_PAGE, USER_LOCATION_KEY } from "../../constants/common";
import { getGeoLocation } from "../../services/commonServices";

const GeoLocation = (props) => {
  const { page, handleUserLocation, handleUserCoords, handleFormValues } =
    props;
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  /**
   * get user location if permission is given
   * @param {*} position 
   */
  function successCallback(position) {
    getUserGeoLocationDetails(position);
  }

  /**
   * handle location if location permission is denied
   */
  function errorCallback() {
    // getUserIpDetails();
    const staticLocation = page===HOME_PAGE ? null:{"latitude":44.0520691,"longitude":-123.0867536,"county":"Lane County","city":"Eugene","userCity":"Eugene","state":"OR","zip":"97408", "formatted_address":"Eugene, OR", "gitchaAvailable" : true}
    updateToState(staticLocation);
  }

  const getUserIpDetails = async () => {
    await fetch(`${GET_USER_IP}`)
      .then((response) => response.json())
      .then((data) => {
        const userCity = _.get(data, "city", "");
        const latitude = _.get(data, "latitude", "");
        const longitude = _.get(data, "longitude", "");
        const zip = _.get(data, "postal", "");
        const state = _.get(data, "region_code", "");
        const formatted_address = userCity+", "+state
        const payload = {
          userCity: userCity,
          latitude: latitude,
          longitude: longitude,
          zipCode: zip,
          state: state,
          formatted_address: formatted_address
        };
        updateToState(payload);
        //setLocationToLocalStorage(userCity, latitude, longitude);
      });
  };

  const setLocationToLocalStorage = (userCity, latitude, longitude) => {
    setLocalStorage(USER_LOCATION_KEY, {
      loc: userCity,
      lat: latitude,
      lng: longitude,
    });
  };

  const updateToState = (payload) => {
    if (page === HOME_PAGE) {
      handleUserLocation(payload);
    } else {
      if (typeof handleUserCoords == "function") {
        handleUserCoords(
          _.get(payload, "latitude", ""),
          _.get(payload, "longitude", "")
        );
      } else {
        handleFormValues({
          lat: _.get(payload, "latitude", ""),
          lng: _.get(payload, "longitude", ""),
        });
      }
    }
  };

  //43.695692992226235},${-120.72845719195476

  const getUserGeoLocationDetails = async (position) => {
    const { latitude, longitude } = _.get(position, 'coords', '');
    const reqPayload = `latlng=${latitude},${longitude}`;
    getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results", []); // Plus code data example ''
      try {
        let addressObject = { latitude: latitude, longitude: longitude };
        if (results?.length) {
          const first_Address = _.get(results?.[0], "address_components", "");
          const locality = first_Address.filter(function (ca) {
            return ca.types?.[0] === "locality";
          });
          if (locality.length) {
            addressObject.city = _.get(locality?.[0], "short_name", "");
            addressObject.userCity = _.get(locality?.[0], "short_name", "");
          }
          const state = first_Address.filter(function (ca) {
            return ca.types?.[0] === "administrative_area_level_1";
          });
          if (state.length) {
            addressObject.state = _.get(state?.[0], "short_name", "");
          }
          const zip = first_Address.filter(function (ca) {
            return ca.types?.[0] === "postal_code";
          });
          if (zip.length) {
            addressObject.zip = _.get(zip?.[0], "short_name", "");
          }
          const county = first_Address.filter(function (ca) {
            return ca.types?.[0] === "administrative_area_level_2";
          });
      
          if (county.length) {
            addressObject.county = _.get(county?.[0], "short_name", "");
          }
          addressObject.formatted_address = addressObject.city+", "+addressObject?.state
          if (first_Address) {
            updateToState(addressObject);
            //setLocationToLocalStorage(userCity, latitude, longitude );
          } else {
            getUserIpDetails();
          }
        } else {
          getUserIpDetails();
        }
      } catch {
        getUserIpDetails();
      }
    });
  };
};
export default GeoLocation;
