import React, { useEffect, useState, useContext , useRef} from "react";
import {Button } from "react-bootstrap";
import FilterCategory from "../Dashboard/FilterCategory";
import FilterLocationSearch from "../Dashboard/FilterLocationSearch";
import FilterKeywordSearch from "../Dashboard/FilterKeywordSearch";
import Accordion from "react-bootstrap/Accordion";
import Collapse from "react-bootstrap/Collapse";
import { filterContext } from "../../../pages/Home";
import { BUY, WANT_AD_PAGE_LIMIT, LONG_RENT } from "../../../constants/common";
import { getPropertyTypes } from "../../../services/commonServices";
import { getSquareFeetList } from "../../../services/wantAdService";
import { handleRadioButonChange } from "../../../actions/common";
import RangeSliderComponent from "../../Common/RangeSlider";
import SelectDropDown from "../../Common/SelectDropDown";
import {
  checkMaxValue,
  convertToNumber,
  handleNegativeInput,
} from "../../../helpers/validations";
import TextBox from "../../Common/TextBox";
import { replaceComma,getLocalStorage, replaceString, thousandSeperatedValue,thousandSeperatedValueFilter, getPriceText, checkIsNumber, arraySplice, filterArrayBasedOnKey } from "../../../helpers/common";
import DateRangeSelector from "../../DateRangeSelector";
import { usePrevious } from "../../../hooks/usePrevious";
const LeftpanelTabContent = (props) => {
  const {updateFilter, filterQuery, setUserLocation, key, gitchaNotAvailble, searchedLocation, checkGitchaAvailableInLoc, userLocation} = props;
  const filterQueryFromStorage = getLocalStorage("filterQuery");
  const PROPERTY_TYPE_SHOW_MORE_COUNT = 4;
  const SQ_FT_MIN_VALUE = 0;
  const SQ_FT_MAX_VALUE = 20000;
  // const BUDGET_MIN = 0;
  // const BUDGET_MAX = 3001000;
  const [propertyList, setPropertyList] = useState([]);
  const [typeOfPlaceList, setTypeOfPlaceList] = useState([]);
  const [selectedTypeOfPlace, setSelectedTypeOfPlace] = useState([])
  const [budgetRange, setBudgetRange] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");
  const [squareFootageMinValue, setSquareFootageMinValue] = useState(null);
  const [squareFootageMaxValue, setSquareFootageMaxValue] = useState(null);
  // const [squareFootageMinError, setSquareFootageMinError] = useState("");
  // const [squareFootageMaxError, setSquareFootageMaxError] = useState("");
  const [open, setOpen] = useState(false);
  const filterCntxt = useContext(filterContext);
  const [propertyCount, setPropertyCount] = useState(0);
  const [typeOfPlaceCount, setTPCount] = useState(0);
  const [squareFeetList, setSqareFeetList] = useState([]);
  const { adType, clearFilterQuery } = filterCntxt;
  const [isPrivateRoomSelected, setIsPrivateRoomSelected] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [squareFeetMinList, setSqareFeetMinList] = useState([]);
  const [squareFeetMaxList, setSqareFeetMaxList] = useState([]);
  const alwPetsChkBx = "alwPets";
  const accsPvtBthRmchkBx = "pvtBath";
  const pvtEntrnceChkBx = "pvtEntrance";
  const adaAccessibleChkBx = "adaAccess"
  const MIN_SF_DROPDOWN = "minSFDrop";
  const MAX_SF_DROPDOWN = "maxSFDrop";
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const INDEX_TO_BE_SLICE = 15;
  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;

  if (adType === BUY) {
    DEFAULT_MAX_BUDGET = 5000000;
    DEFAULT_STEP_VALUE = 25000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
    DEFAULT_STEP_VALUE = 100;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
    DEFAULT_STEP_VALUE = 50;
  }

  const budgetMin = _.get(filterQueryFromStorage,'priceMin');
  const budgetMax = _.get(filterQueryFromStorage,'priceMax');
  const STR_OPTION_DETAILS_PVT_ROOM = [
    { id: adaAccessibleChkBx, text: "ADA Accessible" },
    { id: alwPetsChkBx, text: "Allows pets" },
    { id: accsPvtBthRmchkBx, text: "Access to private bathroom" },
    { id: pvtEntrnceChkBx, text: "Private Entrance" },
  ];

  const STR_OPTION_DETAILS_ALL = [
    { id: adaAccessibleChkBx, text: "ADA Accessible" },
    { id: alwPetsChkBx, text: "Allows pets" },    
  ];


  

  useEffect(() => {
    if(adType===BUY){
      getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    }
    else {
      setPropertyList([]);
    }
    // if(_.get(filterQueryFromStorage,'priceMin') || _.get(filterQueryFromStorage,'priceMax')){
    //   setBudgetRange([_.get(filterQueryFromStorage,'priceMin'), _.get(filterQueryFromStorage,'priceMax')])
    // }
    // else {setBudgetRange([BUDGET_MIN, BUDGET_MAX]);}
    let DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET];
    if (budgetMin && !budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, DEFAULT_MAX_BUDGET];
    } else if (!budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, budgetMax];
    } else if (budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, budgetMax];
    }
    setBudgetRange(DEFAULT_BUDGET_RANGE);
    setSelectedTypeOfPlace(_.get(filterQueryFromStorage,'typeOfPlace') || [10,11,6])
    getSquareFeetList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          let newList = [];
          list.map((lst) => {
            const value =
              _.get(lst, "Sqft_Value") ;
            const text = _.get(lst, "Sqft_Value_Text", "");
            newList.push({ value: value, label: text });
          });
          
          setSqareFeetMinList([{ value: null, label: "No min" }, ...newList]);
          setSqareFeetMaxList([{ value: null, label: "No max" }, ...newList]);
          const maxValue = _.get(filterQueryFromStorage,"sqrftMax") != null ? {value: _.get(filterQueryFromStorage,"sqrftMax", null), label: _.get(filterQueryFromStorage,"sqrftMax")} : { value: null, label: "No max" }
          const minValue = _.get(filterQueryFromStorage,"sqrftMin") != null ? {value: _.get(filterQueryFromStorage,"sqrftMin", null), label: _.get(filterQueryFromStorage,"sqrftMin")} : { value: null, label: "No min" }
          setSquareFootageMaxValue(maxValue)
          setSquareFootageMinValue(minValue)
          setStartDate(_.get(filterQueryFromStorage,"fromDate") || null )
          setEndDate(_.get(filterQueryFromStorage,"toDate") || null )
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, [adType]);


  useEffect(()=>{
    if(adType !== BUY){
    let type = ""    
    if(selectedTypeOfPlace.includes(11) && selectedTypeOfPlace.includes(10)){
      type = "es-pr"
    }
    else if(selectedTypeOfPlace.includes(11) && selectedTypeOfPlace.includes(6)){
      type = "pr-land"
    }
    else if(selectedTypeOfPlace.includes(10) && selectedTypeOfPlace.includes(6)){
      type = "es-land"
    }    
    if(selectedTypeOfPlace.includes(11) && selectedTypeOfPlace.includes(10) && selectedTypeOfPlace.includes(6)){
      type = "all"
    }
    if(selectedTypeOfPlace.length==1 && selectedTypeOfPlace.includes(11)){
      type = "privateroom"
    }
    if(selectedTypeOfPlace.length==1 && selectedTypeOfPlace.includes(10)){
      type = "entirespace"
    }
    if(selectedTypeOfPlace.length==1 && selectedTypeOfPlace.includes(6)){
      type = "land"
    }
    else {
      setPropertyList([])
    }
      getPropertyTypes(type)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

      if(selectedTypeOfPlace.includes(11)){
        setIsPrivateRoomSelected(true)
      }
      else {
        setIsPrivateRoomSelected(false)
      }
    }

    
    
  },[selectedTypeOfPlace])

  const prevMinBdgt = usePrevious(budgetRange[0]);
  const prevMaxBdgt = usePrevious(budgetRange[1]);

 
  useEffect(() => {
      updateRangeSliders();
  },[startDate, endDate]);
  
  useEffect(() => {
    if (prevMinBdgt && prevMinBdgt !== budgetRange[0]) {
      updateFilter({ priceMin: budgetRange[0]});
    }       
 },[budgetRange[0]]);

 useEffect(() => {
  const mxBdgt = budgetRange[1] === DEFAULT_MAX_BUDGET ? "" : budgetRange[1];
  if (prevMaxBdgt && prevMaxBdgt !== budgetRange[1]) {
    updateFilter({ priceMin: budgetMin, priceMax: mxBdgt});
  }
 
},[budgetRange[1]]);


  

  const updateRangeSliders = async () => {
    updateFilter({fromDate: startDate, toDate: endDate});
  };



  useEffect(() => {
    if (adType !== BUY) {    ///      For loading type of place
      getPropertyTypes("rent")
        .then((response) => {
          if (response.errorCode === 0 && _.get(response, "result", null)) {
            const propertyTypes = _.get(response, "result", null);
            setTypeOfPlaceList(propertyTypes);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, [adType]);

 

  const clearFilter = () => {
    clearFilterQuery();
    // setSquareFootageValues([0, 20000]);
    setSquareFootageMinValue({ value: null, label: "No min" });
    setSquareFootageMaxValue({ value: null, label: "No max" })
    setBudgetRange([DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET]);
    setPropertyCount(0);
    setTPCount(0);
  };


 
  const getSelectedItems = (property) => {
    const selectedItemArray = _.get(filterQuery, property, []);
    return selectedItemArray;
  };

  const handlePropertyClick = (e) => {
    const value = convertToNumber(handleRadioButonChange(e));
    const selectedItemArray = getSelectedItems("propertyType");
    if (value) {
      let itemArray;
      if (_.includes(selectedItemArray, value)) {
        itemArray = selectedItemArray.filter((e) => e !== value);
      } else {
        itemArray = [...selectedItemArray, value];
      }
      //setPropertyTypes(itemArray);
      updateFilter({ propertyType: itemArray });
      // handleFormValues({ prptySubTypes: subItemArray});
      setPropertyCount(itemArray.length);
    }
  };

  const handleAdditionalRequirementsClick = (e, typeId) => {
    const selectedItemArray = getSelectedItems(e.target.value);
      if(selectedItemArray == 0 || selectedItemArray == null){
        updateFilter({ [typeId]: 1 });
      }
      else {
        updateFilter({ [typeId]: 0 });
      }   
    
  };

  const handleTypeOfPlaceClick = (e) => {
    const selectedItemArray = getSelectedItems("typeOfPlace");
    const value = convertToNumber(handleRadioButonChange(e));
    if (value) {
      let itemArray;      
      if (_.includes(selectedItemArray, value)) {
        itemArray = selectedItemArray.filter((e) => e !== value);
      } else {
        itemArray = [...selectedItemArray, value];
      }
      setSelectedTypeOfPlace(itemArray)
      if(itemArray.includes(11)){
        setIsPrivateRoomSelected(true)
      }
      else {
        setIsPrivateRoomSelected(false)
      }

      updateFilter({ typeOfPlace: itemArray });
      // handleFormValues({ prptySubTypes: subItemArray});
      setTPCount(itemArray.length);
    }
  };

  const loadPropertyMain = () => {
    const selectedItemArray = getSelectedItems("propertyType");
    //let count = 1;
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const isChecked =
          selectedItemArray && _.includes(selectedItemArray, typeId);
        // if (count <= PROPERTY_TYPE_SHOW_MORE_COUNT) {
        //   count++;
          return (
              <label
                key={typeId}
                className="d-block mb-2 form-check-label checkbox-label"
                htmlFor={typeId}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="chkType"
                  value={typeId ? typeId : ""}
                  id={typeId}
                  checked={isChecked}
                  onChange={(e) => handlePropertyClick(e)}
                />
                {typeText}
              </label>
            
          );
        // } else {
        //   return null;
        // }
      });

    return List;
  };


  const loadAdditionalRequirements = () => {
    //let count = 1;
    const options = isPrivateRoomSelected ? STR_OPTION_DETAILS_PVT_ROOM : STR_OPTION_DETAILS_ALL

    const List =
    options &&
    options.map((pt) => {
        const typeId = _.get(pt, "id", "");
        const typeText = _.get(pt, "text", "");
        const isChecked = getSelectedItems(typeId);        
          return (
              <label
                key={typeId}
                className="d-block mb-2 form-check-label checkbox-label"
                htmlFor={typeId}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="chkType"
                  value={typeId ? typeId : ""}
                  id={typeId}
                  checked={isChecked==1 ? true : false}
                  onChange={(e) => handleAdditionalRequirementsClick(e, typeId)}
                />
                {typeText}
              </label>
            
          );
        // } else {
        //   return null;
        // }
      });

    return List;

  }

  const loadPropertyCollapsibleItems = () => {
    const selectedItemArray = getSelectedItems("propertyType");
    if (propertyList.length > PROPERTY_TYPE_SHOW_MORE_COUNT) {
      const collapsibleList = propertyList.slice(PROPERTY_TYPE_SHOW_MORE_COUNT);
      const List =
        collapsibleList &&
        collapsibleList.map((pt) => {
          const typeId = _.get(pt, "PropertyTypeId", "");
          const typeText = _.get(pt, "PropertyTypeText", "");
          const isChecked =
            selectedItemArray && _.includes(selectedItemArray, typeId);
          return (
              <label
                key={typeId}
                className="d-block mb-2 form-check-label checkbox-label"
                htmlFor={typeId}
              >
                <input
                  className="form-check-input"
                  name="chkType"
                  type="checkbox"
                  value={typeId ? typeId : ""}
                  id={typeId}
                  checked={isChecked}
                  onChange={(e) => handlePropertyClick(e)}
                />
                {typeText}
              </label>
          );
        });
      return List;
    }
    return null;
  };

  const loadTypeOfPlace = () => {
    const selectedItemArray = getSelectedItems("typeOfPlace");
    const List =
      typeOfPlaceList &&
      typeOfPlaceList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const isChecked =
          selectedItemArray && _.includes(selectedItemArray, typeId);
        return (
            <label
              key={typeId}
              className="d-block mb-2 form-check-label checkbox-label"
              htmlFor={typeId}
            >
              <input
                className="form-check-input"
                name="chkPlace"
                type="checkbox"
                value={typeId ? typeId : ""}
                id={typeId}
                checked={isChecked}
                onChange={(e) => handleTypeOfPlaceClick(e)}
              />
              {typeText}
            </label>
        );
      });

    return List;
  };

  const handleLocationSearch = (e) => {
    updateFilter({ location: e.target.value });
  };

  const handleKeyWordSearch = (e) => {
    updateFilter({ search: e.target.value });
  };

  const handleBudgetMinChange = (e) => {
    const budgetMax = budgetRange && budgetRange[1];
    const value = handleNegativeInput(e.target.value);
    //checkBudgetValues();
    if(value){
      setBudgetRange([parseInt(replaceComma(value)), budgetMax]);
    }
    else{
      setBudgetRange([null, budgetMax]);
    }
    // updateFilter({ priceMin: replaceComma(value) });
  };

  const handleBudgetMaxChange = (e) => {
    const budgetMin = budgetRange && budgetRange[0];
    const value = handleNegativeInput(e.target.value);
    if(parseInt(replaceComma(value))){
      setBudgetRange([budgetMin, parseInt(replaceComma(value))]);
    }
    else{
      setBudgetRange([budgetMin, null]);
    }
    // updateFilter({ priceMax: replaceComma(value) });
    //validateValues(SFMinValue,value)
   // checkBudgetValues();
    //saveBudgetValuesToState()
  };

  const handleSquareFootMinChange = (e) => {
    // const SFMaxValue = squareFootageValues && squareFootageValues[1];
    const value = _.get(e, "value", "");
    setSquareFootageMinValue(e);
    updateFilter({ sqrftMin: value});
    // saveSqFtValuesToState();
  };

  const handleSquareFootMaxChange = (e) => {
    const value = _.get(e, "value", "");
    setSquareFootageMaxValue(e);
    updateFilter({ sqrftMax: value});
    //saveSqFtValuesToState();
  };

  const checkBudgetValues = () => {
    let isValid = true;
    setMinError("");
    setMaxError("");
    if (budgetRange && convertToNumber(budgetRange[0]) > convertToNumber(budgetRange[1])) {
      isValid = false;
      setMinError("Value Must be less than maximum");
    }
    if (budgetRange && convertToNumber(budgetRange[1]) < convertToNumber(budgetRange[0])) {
      isValid = false;
      setMaxError("Value Must be greater than minimum");
    }
  };

  const handleBedRoomClick = (e) => {
    const noOfBedrooms = getSelectedItems("bedrooms");
    const value = handleRadioButonChange(e);
    const selectedValue = replaceString(value, "+");
    if(selectedValue==noOfBedrooms){
      updateFilter({ bedrooms: null });
    }
    else{
      updateFilter({ bedrooms: selectedValue });
    }
    //handleContinueButton()
  };

  const handleBedsClick = (e) => {
    const noOfBeds = getSelectedItems("beds");
    const value = handleRadioButonChange(e);
    const selectedValue = replaceString(value, "+");
    if(selectedValue==noOfBeds){
      updateFilter({ beds: null });
    }
    else{
      updateFilter({ beds: selectedValue });
    }
    //handleContinueButton()
  };

  const handleBathRoomClick = (e) => {
    const noOfBathrooms = getSelectedItems("baths");
    const value = handleRadioButonChange(e);
    const selectedValue = replaceString(value, "+");
    if(selectedValue==noOfBathrooms){
      updateFilter({ baths: null });
    }
    else{
      updateFilter({ baths: selectedValue });
    }
    //handleContinueButton()
  };

  const addNewOptionsToMinList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMinList(customList);
  };

  const addNewOptionsToMaxList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMaxList(customList);
  };

  const buildCustomList = (list, newOption) => {
    const filteredArray = filterArrayBasedOnKey({
      list: list,
      filterKey: "value",
      value: _.get(newOption, "value", null),
    });
    let customOptions = [...list];
    if (filteredArray?.length === 0 && _.get(newOption, "value", null)) {
      customOptions = [...list, newOption];
    }
    return customOptions;
  };


  const handleOnInputChange = (e, dropSection) => {
    // setSquareFootageMaxError("");
    if (dropSection === MIN_SF_DROPDOWN) {
      if (minRef.current && minRef.current.contains(document.activeElement)) {
        if (checkIsNumber(e)) {
          const options = { value: convertToNumber(e), label: e };
          addNewOptionsToMinList(
            arraySplice(squareFeetMinList, INDEX_TO_BE_SLICE),
            options
          );
          setSquareFootageMinValue(options)
          updateFilter({ sqrftMin: e });
        }
      }
    } else {
      if (maxRef.current && maxRef.current.contains(document.activeElement)) {
        if (checkIsNumber(e)) {
          const options = { value: convertToNumber(e), label: e };
          addNewOptionsToMaxList(
            arraySplice(squareFeetMaxList, INDEX_TO_BE_SLICE),
            options
          );
          setSquareFootageMaxValue(options)
          updateFilter({ sqrftMax: e });
        }
      }
    }
  };

  const loadBedRooms = () => {
    const items = ["1+", "2+", "3+", "4+", "5+"];
    const noOfBedrooms = getSelectedItems("bedrooms");
    const list =
      items &&
      items.map((itm, index) => {
        const itmValue = replaceString(itm, "+");
        return (
          <button
            type="button"
            value={itmValue}
            class={
              noOfBedrooms === itmValue
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            key={index}
            onClick={(e) => handleBedRoomClick(e)}
          >
            {itm}
          </button>
        );
      });
    return list;
  };

  

  const loadBeds = () => {
    const items = ["1+", "2+", "3+", "4+", "5+"];
    const noOfBedrooms = getSelectedItems("beds");
    const list =
      items &&
      items.map((itm, index) => {
        const itmValue = replaceString(itm, "+");
        return (
          <button
            type="button"
            value={itmValue}
            class={
              noOfBedrooms === itmValue
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            key={index}
            onClick={(e) => handleBedsClick(e)}
          >
            {itm}
          </button>
        );
      });
    return list;
  };


  const loadBathRooms = () => {
    const items = ["1+", "2+", "3+", "4+", "5+"];
    const noOfBathrooms = getSelectedItems("baths");
    const list =
      items &&
      items.map((itm, index) => {
        const itmValue = replaceString(itm, "+");
        return (
          <button
            type="button"
            value={itmValue}
            class={
              noOfBathrooms === itmValue
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            key={index}
            onClick={(e) => handleBathRoomClick(e)}
          >
            {itm}
          </button>
        );
      });
    return list;
  };

  return (
    <>
      <FilterCategory />
      {userLocation!==null && <>
        <FilterLocationSearch
          updateFilter={updateFilter}
          adType={adType}
          setUserLocation={setUserLocation}
          key={key}
          gitchaNotAvailble={gitchaNotAvailble}
          searchedLocation={searchedLocation}
          checkGitchaAvailableInLoc={checkGitchaAvailableInLoc}
        />
        <FilterKeywordSearch
          handleSearch={handleKeyWordSearch}
          keyWord={getSelectedItems("search")}
        />
      </>}
      <Accordion defaultActiveKey="2" alwaysOpen className={userLocation!==null ? "mt-4" : ""}>
        {adType !== BUY && (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Type of place{" "}               
                {getSelectedItems("typeOfPlace").length>0 ? `(${getSelectedItems("typeOfPlace").length})` : ""}
            </Accordion.Header>
            <Accordion.Body className="pt-0">
              <div className="field-wrap mt-0 mb-0">{loadTypeOfPlace()}</div>
            </Accordion.Body>
          </Accordion.Item>
        )}

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Property type{" "}
            {getSelectedItems("propertyType").length>0 ? `(${getSelectedItems("propertyType").length})` : ""}
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <div className="field-wrap mt-0 mb-0">
              {loadPropertyMain()}
              {/* <Collapse in={open}>
                <div id="example-collapse-text">
                  {loadPropertyCollapsibleItems()}
                </div>
              </Collapse> */}

              {/* {propertyList &&
                propertyList.length > PROPERTY_TYPE_SHOW_MORE_COUNT && (
                  <Button
                    variant=""
                    className="btn add-checkbox-btn"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  >
                    <span>
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                        >
                          <defs>
                            <clipPath id="a">
                              <rect
                                width="12"
                                height="12"
                                transform="translate(713 5245)"
                                fill="#886c6c"
                              />
                            </clipPath>
                          </defs>
                          <g
                            transform="translate(-713 -5245)"
                            clip-path="url(#a)"
                          >
                            <g transform="translate(713 5245)">
                              <path
                                d="M0,6a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,0,1H.5A.5.5,0,0,1,0,6Z"
                                fill="#15181e"
                                fill-rule="evenodd"
                              />
                            </g>
                          </g>
                        </svg>
                      ) : (
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 6C0 5.72386 0.223858 5.5 0.5 5.5H11.5C11.7761 5.5 12 5.72386 12 6C12 6.27614 11.7761 6.5 11.5 6.5H0.5C0.223858 6.5 0 6.27614 0 6Z"
                            fill="#15181E"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6 0C6.27614 0 6.5 0.223858 6.5 0.5V11.5C6.5 11.7761 6.27614 12 6 12C5.72386 12 5.5 11.7761 5.5 11.5V0.5C5.5 0.223858 5.72386 0 6 0Z"
                            fill="#15181E"
                          />
                        </svg>
                      )}
                    </span>
                    {open ? "Less" : "More"}
                  </Button>
                )} */}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{getPriceText(adType)}</Accordion.Header>
          <Accordion.Body className="pt-0">
            <div className="slider-sec">
              <div className="pb-4 pt-3">
                <RangeSliderComponent
                  min={DEFAULT_MIN_BUDGET}
                  max={DEFAULT_MAX_BUDGET}
                  // defaultValue={[BUDGET_MIN, BUDGET_MAX]}
                  value={budgetRange && [budgetRange[0]==null ? DEFAULT_MIN_BUDGET : budgetRange[0] ,budgetRange[1]==null ? DEFAULT_MAX_BUDGET : budgetRange[1]]}
                  stepValue={DEFAULT_STEP_VALUE}
                  onInput={setBudgetRange}
                />
              </div>
              <ul className="range-values-list rs-pb-37">
                <li className="position-relative">
                <span className="dollar-sign-icon">$</span>
                  <label className="d-none"></label>

                  <TextBox
                    type="text"
                    class="form-control pad-left-25"
                    errorMessage={minError}
                    // maxValue={BUDGET_MIN}
                    value={
                      budgetRange && budgetRange[0]==="" ? "" : thousandSeperatedValueFilter(budgetRange[0])  
                    }
                    placeholder="0"
                    onChange={(e) => {
                      handleBudgetMinChange(e);
                    }}
                  ></TextBox>
                  <div className="error-msg"></div>
                </li>
                <li className="text-center">
                  <span className="middle-separator">-</span>
                </li>
                <li className="position-relative">
                  <span className="dollar-sign-icon">$</span>
                  <label className="d-none"></label>

                  <TextBox
                    type="text"
                    class="form-control pad-left-25"
                    errorMessage={maxError}
                    // maxValue={BUDGET_MAX}
                    value={
                      budgetRange && (budgetRange[1]===DEFAULT_MAX_BUDGET) ? "No max" : thousandSeperatedValue(budgetRange[1])                     
                    }
                    placeholder="No max"
                    onChange={(e) => {
                      handleBudgetMaxChange(e);
                    }}
                  ></TextBox>
                </li>
              </ul>

              {/*<Row>
        <Col md={6}>
        <input type="text" className="form-control mb-4 mb-sm-4 mb-md-0" />
       </Col>
       <Col md={6}>
       <input type="text" className="form-control" />
       </Col>
       </Row> */}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {adType==="STR" && 
          <Accordion.Item eventKey="7">
            <Accordion.Header>Dates</Accordion.Header>
            <Accordion.Body className="pt-0">
              <DateRangeSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
            </Accordion.Body>
          </Accordion.Item>}
        <Accordion.Item eventKey="3">
          <Accordion.Header>{adType==="STR" ? "Rooms and beds" : "Beds and baths"}</Accordion.Header>
          <Accordion.Body className="pt-0">
          <div className="lp-btn-group-wrap">
              <label class="form-label">{adType==="STR" ? "Bedrooms" : "Beds"}</label>
              <div class="btn-group" role="group" aria-label="Basic example">
                {loadBedRooms()}
              </div>
            </div>       

            {adType==="STR" && isPrivateRoomSelected && <div className="lp-btn-group-wrap">
              <label class="form-label">Beds</label>
              <div class="btn-group" role="group" aria-label="Basic example">
                {loadBeds()}
              </div>
            </div>}

            <div className="lp-btn-group-wrap mb-3">
              <label class="form-label">Baths</label>
              <div class="btn-group" role="group" aria-label="Basic example">
                {loadBathRooms()}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {adType!=="STR" && <Accordion.Item eventKey="4">
          <Accordion.Header>Square footage</Accordion.Header>
          <Accordion.Body className="pt-0">
            <div className="slider-sec">
              {/* <div className="pb-4 pt-3">
                <RangeSliderComponent
                  min={SQ_FT_MIN_VALUE}
                  max={SQ_FT_MAX_VALUE}
                  defaultValue={[SQ_FT_MIN_VALUE, SQ_FT_MAX_VALUE]}
                  value={squareFootageValues}
                  onInput={setSquareFootageValues}
                />
              </div> */}
              <ul className="range-values-list list-pad-fix rs-pb-37">
                <li className="position-relative">
                  <label className="d-none"></label>
                  <div ref={minRef}>
                  <SelectDropDown                        
                  id={MIN_SF_DROPDOWN}
                  name="colors33"
                  options={squareFeetMinList}
                  className="lotsize-select"
                  classNamePrefix="select"
                  value={squareFootageMinValue}
                 onChange={(e) => handleSquareFootMinChange(e)}
                 onInputChange={(e) => handleOnInputChange(e, MIN_SF_DROPDOWN)}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform:
                        state.selectProps.menuIsOpen && "rotate(180deg)",
                    }),
                  }}
                  errorClass="error-msg error-msg-height"
                />
                </div>
                  
                </li>
                <li className="text-center">
                  <span className="middle-separator">-</span>
                </li>
                <li className="position-relative">
                  <label className="d-none"></label>
                  <div ref={maxRef}>
                  <SelectDropDown
                        
                  id={MAX_SF_DROPDOWN}
                  name="colors33"
                  options={squareFeetMaxList}
                  className="lotsize-select"
                  classNamePrefix="select"
                  placeholder="Select"
                  value={squareFootageMaxValue}
                 onChange={(e) => handleSquareFootMaxChange(e)}
                 onInputChange={(e) => handleOnInputChange(e, MAX_SF_DROPDOWN)}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform:
                        state.selectProps.menuIsOpen && "rotate(180deg)",
                    }),
                  }}
                  errorClass="error-msg error-msg-height"
                />
                </div>
                </li>
                
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>}
        {adType==="STR" && <Accordion.Item eventKey="6">
          <Accordion.Header>
            Additional requirements
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <div className="field-wrap mt-0 mb-0">
              {loadAdditionalRequirements()}             
            </div>
          </Accordion.Body>
        </Accordion.Item>}
      </Accordion>

      <div className="mt-2">
        <a role="button" className="reset-btn" onClick={() => clearFilter()}>
          Clear all
        </a>
      </div>
    </>
  );
};
export default LeftpanelTabContent;
