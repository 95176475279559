import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
const DocumentationTutorial = (props) => {
  const { index, step, backProps, continuous, primaryProps, skipProps,size, isLastStep, tooltipProps } = props;
  let finalStepBtn = (props.finalStepBtn) ? props.finalStepBtn : 'Got It !';
  let hideBtns = (props.hideBtns) ? props.hideBtns : false;
  let hideCounter = (props.hideCounter) ? props.hideCounter : false;
  return (
    <>
    <div  className="joyride-left-introsec">
      {!hideCounter && <div className="joyride-left-count">{parseInt(index) + 1} of {size}</div>}
      <div className="joyride-step-title">{step.title} {step.icon && <span className="joyride-step-icon"><img src={step.icon} /></span>}</div> 
      
      <div className="joyride-step-para">{step.content}</div>
      
      {(continuous && !hideBtns) && (
        <Button variant="" className="btn-primary" {...primaryProps}>
          {isLastStep ? finalStepBtn : 'Next'}
        </Button>
      )}
      {(step.showSkipButton && !hideBtns) && (
        <Button variant="" className="btn-secondary float-end" {...skipProps}>
          Skip All
        </Button>
      )}
      </div>
    </>
  );
};
export default DocumentationTutorial;
