import React from "react";
import GitchaLogo from "../images/gitcha-logo.svg";

const TermsOfService = (props) => {
  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <div class="top-header mb-24">
          <div class="w-100 h-100 d-flex p-0 flex-column flex-sm-column flex-md-row justify-content-between align-items-center">
            <div className="navbar-brand brand-fix p-0">
              <a href="/home">
                <img src={GitchaLogo} alt={GitchaLogo} />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="privacy-policy-wrap">
              <h2 className="text-center">Gitcha Terms Of Use</h2>

              <p className="privacy-para-bold">1. General Information.</p>
              <p>
                These Terms of Use (“Terms of Use”) describe your relationship
                with Gitcha Home LLC (“Gitcha,” “we,” “us,” or “our”) and apply
                to your (“you” or “Individual User” or “Real Estate
                Professional,” as applicable) access and use of the Gitcha
                website, app, and services (together, the “Services”).
              </p>
              <p>
                PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE
                SERVICES. If you have questions, please contact us. By accessing
                or using our Services, you signify your agreement to these Terms
                of Use and our Privacy Policy (INSERT LINK) (collectively,
                "Terms"). If you do not agree with these Terms of Use, you
                should not use, and are not permitted to use, our Services.{" "}
              </p>

              <p className="privacy-para-bold">2. Changes to Terms of Use.</p>

              <p>
                Gitcha reserves the right to change these Terms of Use at any
                time in its sole discretion. If Gitcha changes the Terms of Use
                in a material way, a notice will be posted on our website or app
                and you will receive notice through your Gitcha user account, if
                you have registered. You agree to review the posted updates or
                revisions to the Terms of Use. By continuing to access and use
                our Services after updated Terms of Use are posted, you agree to
                abide by the updated Terms of Use. In the event you do not
                consent to the terms of the updated Terms of Use, you must
                discontinue use of our Services.
              </p>

              <p className="privacy-para-bold">3. Authorized Users.</p>

              <p>
                Access or use by anyone under the age of 18 or outside the
                United States is strictly prohibited. If you are establishing a
                user account for the purpose of using or accessing our Services
                on behalf of a company, organization, or entity, you are
                agreeing to these Terms of Use on their behalf.
              </p>

              <p className="privacy-para-bold">4. Use of Services.</p>

              <p>
                As long as you comply with these Terms of Use, we grant you a
                non-exclusive, limited, revocable, personal, non-transferable
                license to use the Services, and to download and use any app on
                your mobile device, for your personal or business use. However,
                THE SERVICES ARE NOT INTENDED TO provide you with any financial,
                real estate, or related advice of any kind.
              </p>
              <p className="pl-20 privacy-para-bold f-size-16">
                4.1. Individual Users.
              </p>
              <p className="pl-20">
                If you are accessing or using our Services to review or post
                want-ads, available properties, or to communicate with other
                users via Introductions (defined in Section 6 below) on your own
                behalf, you are an “Individual User.” You can access some
                Services without creating a user account, but the majority of
                the Services are only available to you with an account. You
                acknowledge:
              </p>

              <ul className="firstlevel-od-list">
                <li>
                  <span className="f-bold">a.</span>Information that you obtain
                  or receive from Gitcha, its employees, contractors, partners,
                  sponsors, advertisers, licensors, or otherwise through the
                  Services is for informational and advertising purposes only.
                </li>

                <li>
                  <span className="f-bold">b.</span> If you rely on any
                  information, you do so solely at your own risk. We encourage
                  you to independently confirm any information relevant to you
                  with other sources.{" "}
                </li>
                <li>
                  <span className="f-bold">c.</span> You will not use the
                  Services to view, access or otherwise use, directly or
                  indirectly, content for any purpose other than your own
                  personal use.
                </li>

                <li>
                  <span className="f-bold">d.</span> Gitcha is not a referral
                  service and does not recommend or endorse any particular real
                  estate professional. Rather, Gitcha is only an intermediary
                  that connects individuals with real estate professionals or
                  others. We do not offer advice regarding the quality or
                  suitability of any particular real estate professional and no
                  information from Gitcha should be construed as professional
                  advice. Though some content may be provided by Real Estate
                  Professionals, the provision of such content does not
                  automatically create a professional relationship.
                </li>
              </ul>

              <p className="pl-20 privacy-para-bold f-size-16">
                4.2. Real Estate Professionals.
              </p>

              <p className="pl-20">
                If you are a real estate agent or broker acting in your
                professional capacity, you are a “Real Estate Professional,” and
                you may additionally use the Services to provide information, to
                the extent permitted by applicable law, to your clients and to
                take actions on behalf of your clients, including posting
                want-ads and available properties, and sending or accepting
                Introductions (defined in Section 6 below). If you use the
                Services as a Real Estate Professional to post information about
                a client, for example, information about a particular house for
                which you are the listing agent or about a particular client
                seeking to buy a house, you represent and warrant that you have
                obtained all required authorizations and consents from your
                client. Except as expressly stated herein, these Terms of Use do
                not provide you with a license to use, reproduce, distribute,
                display, or provide access to any portion of the Services on
                third-party web sites or otherwise. The Services may not be used
                for transactions in commercial real estate, which includes,
                without limitation, commercially zoned properties and vacation
                rentals.
              </p>
              <p className="pl-20 privacy-para-bold f-size-16">
                4.3. Mobile App.
              </p>

              <p className="pl-20">
                To use any mobile application feature of the Services, you must
                have a compatible mobile device. We do not warrant that the app
                will be compatible with your mobile device. You may use mobile
                data in connection with an app and may incur additional charges
                from your wireless provider for using an app. You agree that you
                are solely responsible for any applicable charges. We may update
                any app and may automatically electronically update the version
                of the app that you have installed on a mobile device. You
                consent to all automatic upgrades, and understand that these
                Terms of Use will apply to all updated versions of an app.
              </p>

              <p className="privacy-para-bold">5. Accounts.</p>

              <p className="pl-20 privacy-para-bold f-size-16">
                5.1. Pro Member Users.
              </p>

              <p className="pl-20">
                All users who create an account are eligible for a base user
                account, known as a Pro Member account, which is free to use to
                review want-ads and available properties posted by other users,
                and to post unlimited want-ads. If a user with a Pro Member
                account sends an Introduction (defined in Section 6 below) to
                another user, the user sending the Introduction will be charged
                a fee if the Introduction is accepted by the other user. Pro
                Member users may also respond to Introductions free of charge.
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                5.2. Premium Users.
              </p>

              <p className="pl-20">
                Users may upgrade to a Premium Member account, which requires a
                subscription and includes the same Services as the Pro Member
                account, but also enables users to post unlimited properties and
                send a set number of Introduction per month without additional
                charge.
              </p>

              <p className="privacy-para-bold">6. Introductions.</p>

              <p>
                Pro Member and Premium users may review and post want-ads and
                available properties, subject to the type of account they have
                selected. If a user desires to connect with another user
                regarding a specific want-ad or availably property, the user may
                send a message to such user, known as an “Introduction”. The
                other user has 72 hours to respond to the Introduction before it
                disappears. Once an Introduction is accepted, the users may have
                access to addition information related to the want-ad, property,
                and/or each other, including contact information. Individual
                Introductions, including the specific content included in the
                Introduction, is not visible to the public.
              </p>

              <p className="privacy-para-bold">7. Limitations on Use.</p>

              <p>
                While using the Services, you may not perform any actions which
                could interfere with or otherwise adversely affect the proper
                functioning of the Services. Without limiting the generality of
                the foregoing, you may not: (a) use any robot, spider, scraper
                or other automated means to access our Services for any purpose
                without prior express written permission from Gitcha; (b) take
                any action that imposes, or may impose, in Gitcha’s sole
                discretion, an unreasonable or disproportionately large load on
                Gitcha’s infrastructure; (c) bypass any measures Gitcha may use
                to prevent or restrict access to the Services; (d) use the
                Services in violation of the Prohibited Content Policy in
                Section 9, (e) use the Services to send altered, deceptive, or
                falsely-sourced information, including without limitation by
                forging TCP-IP packet headers or e-mail headers, (f)
                fraudulently or intentionally misuse the Services, including
                without limitation, or (g) create user accounts or submit
                information by automated means or under false or fraudulent
                pretenses.
              </p>

              <p>
                Gitcha has the right, in its sole discretion, to change, modify
                or discontinue any page, feature or other component or element
                of any of the Services or the Gitcha Content (as defined below)
                at any time, and for any reason, with or without prior notice to
                you. Gitcha reserves the right to restrict your access to some
                or all of the Services at any time for any reason with or
                without prior notice to you. Gitcha further reserves the right
                to charge fees for access to some or all of its Services. Gitcha
                will try to provide advance notice of fees where practical. You
                agree that the Terms of Use will apply to any upgrades, changes,
                or modifications to our Services or Gitcha Content.
              </p>

              <p>
                Any unauthorized or prohibited use of the Services or content
                may subject you to civil liability and criminal prosecution
                under applicable federal and state laws.
              </p>

              <p className="privacy-para-bold">8. Real Estate Professionals.</p>

              <p>
                If you are accessing or using the Services as a Real Estate
                Professional, your account is a business account, i.e. you are
                accessing and using the Services on behalf of a company,
                organization or entity. By accessing or using the Services in
                such a manner, you as the individual registering your account
                are agreeing to these Terms of Use on behalf of such entity and
                yourself as an agent of such entity and you hereby represent and
                warrant that you are authorized to grant all permissions and
                licenses provided in these Terms of Use and bind such company,
                organization or entity to these Terms of Use. Any undisclosed
                commercial use is prohibited. Keep in mind that federal, state,
                and local laws and regulations that govern advertising also
                apply to online advertising. You are responsible for your
                entity’s compliance with the law while using our Services. ​​You
                agree not to use the Services in any way that violates any
                federal, state or local law or regulation or professional rule
                applicable to your business and your use of the Services
                including, without limitation, the Fair Housing Act, the Civil
                Rights Act, the Rehabilitation Act, and the Americans with
                Disabilities Act. Your use of the information obtained through
                the Services is solely at your own risk, and you agree that
                Gitcha and its licensors are not responsible or liable for any
                claim, loss, or damage arising from your use of such
                information.
              </p>

              <p className="privacy-para-bold">9. Prohibited Content Policy.</p>

              <p>
                By using the Services, you agree that you will not post, submit,
                or otherwise distribute content in violation of the Prohibited
                Content Policy. You further agree that you will not embed or
                otherwise cause the Services to be displayed upon a website that
                violates the following Prohibited Content Policy. The Prohibited
                Content Policy applies to content that:
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.1. Infringes any right of Gitcha or a third party including:
                (a) copyright, trademark, patent, trade secret or other
                proprietary rights or contractual rights; (b) right of publicity
                or privacy (specifically, you must not distribute any other
                person’s personal information of any kind without their express
                permission); or (c) any confidentiality obligation.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.2. Promotes any activity that may be found illegal at the
                local, state or federal level or any content that is itself
                illegal in any way or otherwise violates any federal, state or
                local law.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.3. Is untrue, inaccurate, deliberately misleading, or trade
                libelous.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.4. Includes offensive language, such as profanities and
                expletives; sexually explicit or pornographic content; hate
                speech; defamatory, abusive, threatening or harassing speech; or
                racial, religious or personal attacks of any kind.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.5. Contains a virus or other harmful component or otherwise
                tampers with, impairs, or damages the Services or any related
                network connections, or otherwise interferes with any other
                person’s use or enjoyment of the Services.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.6. Contains encrypted content or content that can be
                demonstrated to contain encoded messages.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.7. Impersonates or misrepresents your connection to another
                entity or person or otherwise manipulates headers or identifiers
                to disguise the origin of the content.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.8. Interferes with, or otherwise harms or violates the
                security of the Services, or any system resources, accounts,
                passwords, servers or networks connected to or accessible
                through the Services or affiliated or linked sites.
              </p>

              <p className="pl-20 privacy-para-normal f-size-16">
                9.9. Contains link(s) to other sites that contain content that
                falls within the proscriptions set forth above.
              </p>

              <p>
                We cannot and do not assure that other users are or will be
                complying with the Prohibited Content Policy or any other
                provisions of these Terms of Use, and, as between you and
                Gitcha, you hereby assume all risk of harm or injury resulting
                from any such lack of compliance.
              </p>

              <p>
                While Gitcha is under no obligation to monitor, refuse, move, or
                remove any content that is posted by other users or any other
                content that is available via the Services, Gitcha reserves the
                right to do so in its sole discretion. Without limiting the
                foregoing, Gitcha and its designees shall have the right to
                remove any content that violates these Terms of Use or is
                otherwise objectionable.
              </p>

              <p className="privacy-para-bold">
                10. User Account, Password, and Security.
              </p>

              <p>
                Portions of the Services can be viewed without an account.
                ​​​​To benefit from all of the Services we offer, users must
                create a user account and provide certain basic information,
                which you authorize Gitcha to use and disclose as described in
                our Privacy Policy. For all users with an account, including
                Real Estate Professionals, your user account is personal to you,
                and you may not sublicense, transfer, sell or assign your
                account to any other person. You shall not impersonate any
                person or misrepresent your identity or affiliation with any
                person or entity, including any other person’s name, likeness,
                username or other account information.
              </p>

              <p>
                In order to create an account, you must complete the
                registration process by providing Gitcha with current, complete
                and accurate information as prompted by the applicable
                registration form and choose a user name and password. You are
                entirely responsible for maintaining the confidentiality of your
                password, user name and other account information. Furthermore,
                you are entirely responsible for any and all activities that
                occur under your account. If you become aware of any
                unauthorized use of your account or any other breach of
                security, you agree to notify Gitcha immediately. Gitcha will
                not be liable for any loss that you may incur as a result of
                someone else using your password or account, either with or
                without your knowledge. If Gitcha or a third party incurs losses
                due to someone else using your account or password, you could be
                held liable for those losses.
              </p>

              <p>
                Subject to applicable law, we may suspend or terminate your
                account and your ability to use any portion of our Services for
                failure to comply with these Terms of Use or for any other
                reason whatsoever.
              </p>
              <p className="privacy-para-bold">
                11. Provision of the Services.
              </p>

              <p>
                Gitcha has no responsibility to provide you access to the
                Services. Gitcha reserves the right at any time and from time to
                time to modify, suspend or discontinue, temporarily or
                permanently, the Services or any part thereof, or your access
                thereto (including suspending or disabling your user account),
                with or without notice. You acknowledge and agree that Gitcha
                shall not be liable to you for any modification, suspension or
                discontinuance of the Services, or your access thereto.
              </p>
              <p>
                Further, Gitcha shall not be liable to you for any loss or
                liability resulting, directly or indirectly, due to electronic
                or mechanical equipment failures, telephone interconnect
                problems, defects, weather, strikes, walkouts, fire, government
                orders, acts of God, riots, armed conflicts, acts of war, acts
                of terrorism, or to other like causes.
              </p>

              <p className="privacy-para-bold">
                12. Subscription; Subscription Fee; In-app Purchases.
              </p>

              <p>
                We may offer certain Services via a one-time purchase or by
                providing a subscription plan. Payments are processed through
                the Google Play Store or iOS App Store or through another
                third-party payment processor.
              </p>

              <p>
                When you subscribe to our subscription plan, your subscription
                will renew automatically. After you cancel your subscription, we
                will not charge you any subscription fees after the expiration
                of your then-current subscription. Please note that we do not
                provide full or partial refunds for prepaid sums. In any event,
                you will be able to continue to use the Services throughout the
                remainder of the subscription period for which you have already
                paid.
              </p>

              <p className="privacy-para-bold">
                13. Intellectual Property Rights.
              </p>

              <p>
                Our Services, including without limitation, all software code,
                site and app architecture and design, interactive features and
                the appearance and arrangement of various Gitcha Content
                (defined below), all trademarks, service marks and trade names
                used in connection with our Services (the "Marks") and all text,
                images, photographs, illustrations, audio clips, video clips,
                artwork, graphic material, and other copyrightable elements, and
                the selection and arrangements thereof (collectively, "Gitcha
                Content") are the property of Gitcha and/or its assigns,
                licensors or other respective owners and are protected, without
                limitation, pursuant to U.S. and foreign copyright, trademark
                and other intellectual property rights laws. You may not make
                any use of the Gitcha Content, without the express prior written
                consent of Gitcha.
              </p>

              <p>
                YOU ACKNOWLEDGE AND AGREE THAT NOTHING IN THESE TERMS OF USE
                SHALL HAVE THE EFFECT OF TRANSFERRING THE OWNERSHIP OF ANY
                COPYRIGHTS, TRADEMARKS OR OTHER PROPRIETARY RIGHTS IN THE
                SERVICES OR GITCHA CONTENT OR ANY PART THEREOF TO YOU OR ANY
                THIRD PARTY. You undertake not to take any action which could be
                considered inconsistent with or which is likely in any way to
                prejudice such ownership rights.
              </p>

              <p className="privacy-para-bold">14. User Materials.</p>

              <p>
                Certain portions of the Services may allow users to upload
                images, photos, video, data, text, listings, and other content
                (“User Materials”). By uploading or otherwise providing User
                Materials to the Services, you grant us an irrevocable,
                perpetual, royalty-free worldwide license to: (i) use, copy,
                distribute, transmit, publicly display, publicly perform,
                reproduce, edit, modify, prepare derivative works of or
                incorporate into other works, and translate your User Materials,
                in connection with the Services or in any other media; and (ii)
                sublicense these rights, to the maximum extent permitted by
                applicable law. We will not pay you for your User Materials or
                to exercise any rights related to your User Materials set forth
                in the preceding sentence. We may remove or modify your User
                Materials at any time. You are solely responsible for all User
                Materials made through your user account(s) on the Services or
                that you otherwise make available through the Services. For all
                User Materials, you represent and warrant that you are the
                creator and owner of the User Materials, or have the necessary
                licenses, rights, consents, and permissions (including all
                permissions required under applicable privacy and intellectual
                property law) to authorize us and other users to access and use
                your User Materials as necessary to exercise the licenses
                granted by you under these Terms of Use. We will not use client
                contact information that is uploaded as part of the Services for
                any purpose, unless that client contact information is otherwise
                provided to us directly by the client.
              </p>
              <p>
                We are under no obligation to edit or control your User
                Materials or the User Materials of any other User and will not
                be in any way responsible or liable for any User Materials. We
                may, however, at any time and without prior notice, screen,
                remove, edit, or block any User content on the Services,
                including User Materials, that in our sole judgment violates
                these Terms of Use or we find otherwise objectionable. You
                understand that when using the Services, you may be exposed to
                User Materials of other users and acknowledge that User
                Materials may be inaccurate, offensive, indecent, or
                objectionable. You agree to waive, and do waive, any legal or
                equitable right or remedy you may have against us with respect
                to User Materials. We expressly disclaim any and all liability
                in connection with User Materials. If notified by a user or
                content owner that User Materials allegedly do not conform with
                these Terms of Use, we may investigate the allegation and
                determine in our sole discretion whether to remove those User
                Materials, which we reserve the right to do at any time and
                without notice.
              </p>

              <p className="privacy-para-bold">15. Feedback.</p>

              <p>
                From time to time, you may choose to provide Gitcha with
                suggestions, comments or other feedback ("Feedback") with
                respect to the Services or Gitcha Content. You agree that all
                such Feedback is and shall be given entirely voluntarily. To the
                extent that any Feedback is protectable by copyright, trademark
                or other intellectual property rights, you hereby assign
                irrevocably all right, title and interest in and to the Feedback
                and such related rights to Gitcha. Gitcha shall be free to use,
                disclose, reproduce, license or otherwise distribute,
                commercialize and exploit the Feedback provided to it as it sees
                fit, entirely without obligation or restriction of any kind.
              </p>

              <p className="privacy-para-bold">
                16. Choice of Law and Jurisdiction.
              </p>

              <p>
                The laws of the State of Oregon, excluding any rule or principle
                that would refer to and apply the substantive law of another
                state or jurisdiction, shall govern these Terms of Use. To the
                extent allowed by applicable law, any claims or causes of action
                arising from or relating to your access and use of Services must
                be instituted within six (6) months from the date upon which
                such claim or cause arose or was accrued. Further, any such
                claim or cause of action must EXCLUSIVELY be brought in the
                state or federal courts located in Eugene, Lane County, Oregon,
                and you agree to submit to the exclusive personal jurisdiction
                of such courts and hereby appoint the Secretary of State of
                Oregon as your agent for service of process. You agree to waive
                any objection that the state or federal courts of Lane County,
                Oregon are an inconvenient forum.
              </p>

              <p>
                YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US (WHETHER OR NOT
                SUCH DISPUTE INVOLVES A THIRD PARTY) WITH REGARD TO YOUR
                RELATIONSHIP WITH US, INCLUDING, WITHOUT LIMITATION, DISPUTES
                RELATED TO THIS AGREEMENT, YOUR USE OF THE SERVICES, AND/OR
                RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING,
                INDIVIDUAL ARBITRATION UNDER THE AMERICAN ARBITRATION
                ASSOCIATION’S RULES FOR ARBITRATION OF CONSUMER-RELATED
                DISPUTES, AND YOU AND WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY.
                Neither you nor we will participate in a class action or
                class-wide arbitration for any claims covered by this Agreement
                to arbitrate. YOU ARE WAIVING THE ABILITY TO PARTICIPATE AS A
                CLASS REPRESENTATIVE OR MEMBER IN ANY CLASS OR COLLECTIVE CLAIM
                YOU MAY HAVE AGAINST US INCLUDING ANY RIGHT TO CLASS OR
                COLLECTIVE ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL
                ARBITRATIONS. You also agree not to participate in claims
                brought in a private attorney general or representative
                capacity, or consolidated claims involving another person’s
                account if we are a party to the proceeding. This dispute
                resolution provision will be governed by the Federal Arbitration
                Act and not by any state law concerning arbitration. Judgment on
                the award rendered by the arbitrator may be entered in any court
                having competent jurisdiction. Any provision of applicable law
                notwithstanding, the arbitrator will not have authority to award
                damages, remedies or awards that conflict with these Terms of
                Use.
              </p>

              <p className="privacy-para-bold">17. Disclaimer.</p>

              <p>
                YOUR USE OF THE SERVICES AND GITCHA CONTENT IS AT YOUR OWN RISK.
                THE SERVICES AND GITCHA CONTENT ARE PROVIDED "AS IS" AND "AS
                AVAILABLE." GITCHA MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
                KIND, INCLUDING WITHOUT LIMITATION, REPRESENTATIONS OR
                WARRANTIES REGARDING: (a) THE SERVICES; (b) THE GITCHA CONTENT;
                (c) INFORMATION, SERVICES OR SERVICES AVAILABLE THROUGH
                SERVICES; OR (d) THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE
                OF ANY OF THE FOREGOING.
              </p>

              <p>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, GITCHA
                DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE
                SERVICES AND GITCHA CONTENT, INCLUDING, WITHOUT LIMITATION,
                WARRANTIES OF INFRINGEMENT, TITLE, MERCHANTABILITY OR FITNESS
                FOR A PARTICULAR PURPOSE.
              </p>

              <p>
                GITCHA HAS NO RESPONSIBILITY OR LIABILITY FOR: (a) LOSS OR
                DELETION OF, OR FAILURE TO RECEIVE, PROCESS OR STORE ANY USER
                CONTENT OR OTHER INFORMATION MAINTAINED ON OR TRANSMITTED USING
                THE SERVICES; (b) VIRUSES THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT OR OTHER PROPERTY; (c) USER CONTENT OR OTHER
                INFORMATION POSTED ON THE SITE OR SERVICES BY THIRD PARTIES; OR
                (d) THE ACTIONS OF ANY THIRD PARTY.
              </p>

              <p className="privacy-para-bold">18. Limitation of Liability.</p>

              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN ALL CASES, GITCHA’S
                LIABILITY TO YOU, INCLUDING BUT NOT LIMITED TO, LIABILITY FOR A
                BREACH OF GITCHA’S OBLIGATIONS UNDER THESE TERMS OF USE OR
                GITCHA’S PRIVACY POLICY, OR FOR NEGLIGENCE, SHALL BE LIMITED TO
                THE GREATER OF $50.00 OR THE TOTAL FEES YOU HAVE PAID TO GITCHA
                FOR YOUR USE OF THE SERVICES IN THE 12 MONTH PERIOD IMMEDIATELY
                PRECEDING THE CLAIM, AS LIQUIDATED DAMAGES, NOT AS A PENALTY,
                AND AS THE SOLE AND EXCLUSIVE REMEDY. TO THE FULLEST EXTENT
                PERMITTED BY LAW, GITCHA, ITS SUPPLIERS, CONTENT PROVIDERS,
                LICENSORS, AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
                SHAREHOLDERS, EMPLOYEES, REPRESENTATIVES, SERVANTS, CONTRACTORS
                AND AGENTS SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES FOR ANY REASON,
                EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES OR EVEN IF THE ABOVE LIQUIDATED DAMAGES SHOULD FAIL IN
                THEIR ESSENTIAL PURPOSE.
              </p>

              <p className="privacy-para-bold">19. Indemnity.</p>

              <p>
                You agree to indemnify, defend and hold harmless, Gitcha, its
                suppliers, content providers, licensors, and their respective
                officers, directors, shareholders, employees, representatives,
                servants, contractors and agents from any and all claims and
                damages (including, without limitation, attorneys’ fees, expert
                witness fees, and court costs) arising from or relating to any
                allegation regarding: (a) your use of the Services; (b) content
                or other information posted or transmitted through your account,
                even if not posted or transmitted by you; and (c) any violation
                of these Terms of Use by you or any person through your user
                account.
              </p>

              <p className="privacy-para-bold">20. Privacy.</p>

              <p>
                Your use of our Services and any information you provide to
                Gitcha is governed by Gitcha’s Privacy Policy, which is
                available here, and which is incorporated herein by reference.
              </p>

              <p className="privacy-para-bold">
                21. Digital Millennium Copyright Act.
              </p>

              <p>
                If you are a copyright owner or an agent thereof and believe
                that any material available on the Services infringes upon your
                copyrights, you may submit a notification of claimed
                infringement ("Notification") pursuant to the Digital Millennium
                Copyright Act ("DMCA") by providing Gitcha’s agent designated to
                receive Notifications ("Copyright Agent") with a notice
                containing the information described below.
              </p>
              <p>
                Please note that under 17 U.S.C. § 512(f) any person who
                knowingly materially misrepresents that material is infringing
                may be subject to liability for damages.
              </p>
              <p>
                Please also note that the information provided in a Notification
                may be forwarded to the person who posted the allegedly
                infringing material.
              </p>

              <p>
                All Notifications must include the following (see 17 U.S.C §
                512(c)(3) for further details):
              </p>

              <ul className="firstlevel-unod-list">
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed;
                </li>

                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed or, if multiple copyrighted works at a single online
                  site are covered by a single notification, a representative
                  list of such works at that site;
                </li>
                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled and
                  information reasonably sufficient to permit Gitcha to locate
                  the material;
                </li>

                <li>
                  Information reasonably sufficient to permit Gitcha to contact
                  the complaining party, such as an address, telephone number,
                  and, if available, an electronic mail address at which the
                  complaining party may be contacted;
                </li>
                <li>
                  A statement that the complaining party has a good faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent, or the law; and
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ul>

              <p>Designated Agent:</p>

              <p>Gitcha Home LLC</p>

              <p>Attn:</p>

              <p>
                If you believe that material you provided to our Services that
                was removed (or to which access was disabled) as a result of a
                Notification is not infringing, or that you have the
                authorization from the copyright owner, the copyright owner’s
                agent, or pursuant to the law, to post and use the material, you
                may send a written counter notification ("Counter Notification")
                to the Copyright Agent containing the information described
                below.
              </p>

              <p>
                Please note Gitcha is required to send a copy of your Counter
                Notification to the party who submitted the Notification and
                that in response to a Counter Notification that person may file
                a lawsuit against you seeking a determination of its rights with
                respect to the material.
              </p>

              <p>
                Please also note that under 17 U.S.C. § 512(f), any person who
                knowingly materially misrepresents that material was removed or
                disabled by mistake or misidentification may be subject to
                liability for damages.
              </p>

              <p>
                All Counter Notifications must contain the following (see 17
                U.S.C. § 512(g)(3) for further details):{" "}
              </p>

              <ul className="firstlevel-unod-list">
                <li>Your physical or electronic signature;</li>

                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed or, if multiple copyrighted works at a single online
                  site are covered by a single notification, a representative
                  list of such works at that site;
                </li>
                <li>
                  Identification of the material that has been removed or to
                  which access has been disabled and the location at which the
                  material appeared before it was removed or access to it was
                  disabled;
                </li>

                <li>
                  A statement under penalty of perjury that you have a good
                  faith belief that the material was removed or disabled as a
                  result of mistake or a misidentification of the material to be
                  removed or disabled; and
                </li>
                <li>
                  Your name, address, and telephone number, and a statement that
                  you consent to the jurisdiction of the Federal District Court
                  for the District in which the address you list is located, or
                  if that address is located outside of the United States, for
                  the District of Oregon, and a statement that you will accept
                  service of process from the person who provided notification
                  of the alleged infringement or an agent of such person.
                </li>
              </ul>

              <p>
                Upon receipt of a Notification or Counter Notification, Gitcha
                will comply with the applicable procedures set forth in 17
                U.S.C. § 512.
              </p>

              <p className="privacy-para-bold">22. Assignment.</p>

              <p>
                These Terms of Use, and any rights and licenses granted
                hereunder, may not be transferred or assigned by you, but may be
                assigned by Gitcha without restriction.
              </p>
              <p className="privacy-para-bold">23. Miscellaneous.</p>

              <p>
                You agree that no joint venture, partnership, employment, or
                agency relationship exists between you and Gitcha as a result of
                these Terms of Use or use of the Services.
              </p>

              <p>
                A printed version of these Terms of Use and of any notice given
                in electronic form shall be admissible in judicial or
                administrative proceedings based upon or relating to these Terms
                of Use to the same extent and subject to the same conditions as
                other business documents and records originally generated and
                maintained in printed form.
              </p>

              <p>
                If any provision of these Terms of Use is held to be invalid,
                illegal or unenforceable by any court of competent jurisdiction,
                the remaining provisions shall remain in full force and effect
                to the extent that the remaining provisions can be substantially
                applied within the original intent of these Terms of Use taken
                as a whole. Any court holding a provision to be invalid, illegal
                or unenforceable shall not render the offending provision void
                or unenforceable, but instead shall modify the provision to the
                minimum extent necessary to make the provision valid, legal, and
                enforceable.
              </p>

              <p>
                Gitcha’s failure to act with respect to a breach of these Terms
                of Use by you or others does not waive its right to act with
                respect to subsequent or similar breaches.
              </p>

              <p>
                The section titles used in these Terms of Use are purely for
                convenience and carry with them no legal or contractual effect.
              </p>

              <p>
                In the event of the termination of these Terms of Use for any
                reason, you agree the following provisions will survive: the
                provisions regarding limitations on your use of content, the
                license(s) you have granted to Gitcha, the limitation on
                liability, indemnity, and all other provisions for which
                survival is equitable or appropriate.
              </p>

              <p>
                These Terms of Use are the entire agreement between you and
                Gitcha regarding the subject matter herein, and supersede any
                prior understandings or agreements, written or oral.
              </p>

              <p>How to Contact Gitcha:</p>

              <p>
                If you have any questions about these Terms of Use, our
                Services, or to resolve a dispute, you can contact Gitcha at:
              </p>

              <p className="f-medium">
                Gitcha Home LLC
                <br />
                450 Country Club Road, Suite 260
                <br />
                Eugene, Oregon 97401
              </p>

              <p>Attn:</p>

              <p className="f-size-14 f-medium mb-0">
                These Terms of Use last updated on 9th November, 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
