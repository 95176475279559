import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWantAds } from "../actions/wantAds";
import CommonHeader from "../components/Onboarding/CommonHeader";
const WantadDetailsSkeleton = (props) => {
  

  return (
    <>
    <div className="outer-wrap position-relative mw-1168">
    <CommonHeader />
      <div className="row">
        <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-8 ps-xl-2">
          <div className="wantad-confirmation-wrap mb-4">
          <div className="wantad-skeleton-wrapper">
          <div className="wantad-skeleton-topwrap">
           
            <div className="skeleton-info-wrap ps-0">
          <div className="wantad-skelton-info-top"></div>
          <div className="wantad-skelton-info-middle"></div>
          <div className="wantad-skelton-info-bottom"></div>
          
        </div>
        <div className="wantad-skeleton-btn-wrap">
          <div className="wantad-skeleton-btn"></div>
          <div className="wantad-skeleton-btn"></div>
        </div>
         
          </div>
          
            <div className="wantad-skeleton-img-wrap"></div>
            
            <div className="wantad-skeleton-musthave-wrap">
              <div className="wantad-skelton-info-top wp-18 mb-4"></div>
              <ul>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>

                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
                <li>
                  <div className="wantad-skeleton-label"></div>
                  <div className="wantad-skeleton-value"></div>
                </li>
              </ul>
            </div>

            <div className="wantad-skeleton-musthave-wrap pb-3">
              <div className="wantad-skelton-info-top wp-18 mb-4"></div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
                <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
              <div className="wantad-skelton-tag-wrap d-inline-block">
              <div className="skeleton-tag-switch"></div>
              </div>
             </div>
             <div className="wantad-skeleton-musthave-wrap pb-4">
             <div className="wantad-skelton-info-top wp-18 mb-4"></div>
             <div className="wantad-skelton-info-top h-14 mw-100 mb-2"></div>
              </div>
              <div className="wantad-skeleton-musthave-wrap border-bottom-0 pb-0">
              <div class="wantad-skelton-info-top wp-18 mb-4"></div>
              <div className="wantad-skeleton-topwrap mh-20 mb-3 wp-35">
                <div className="skelton-info-top flex-shrink-0 mt-0 me-3 mb-0 wth-24 h-20"></div>
                <div className="skeleton-info-wrap pe-0 ps-0 h-20">
                <div className="skelton-info-top mt-0 mb-0 mw-100 h-20"></div>
                </div>
              </div>

              <div className="wantad-skeleton-topwrap mh-20 mb-0 wp-35">
                <div className="skelton-info-top flex-shrink-0 mt-0 me-3 mb-0 wth-24 h-20"></div>
                <div className="skeleton-info-wrap pe-0 ps-0 h-20">
                <div className="skelton-info-top mt-0 mb-0 mw-100 h-20"></div>
                </div>
              </div>
              </div>
          </div>


     

      
     
      </div>
      </div>


      <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2">
        <div className="ad-strengthen-wrap mb-0 mb-sm-4">
        <div className="wantad-skeleton-wrapper">
        <div className="wantad-skeleton-btn-wrap w-32 ps-0 mx-auto">
          <div className="wantad-skeleton-btn"></div>
          
        </div>
        <div className="wantad-skelton-info-middle maxw-50 mx-auto"></div>

        <div className="wantad-skelton-info-bottom mw-100 mb-4"></div>
        <div className="add-property-outerwrap mb-4">
          <div className="add-your-property-wrap skeleton-property-border mb-0">
          <div className="left-prof-wrap-outer me-2">
            <div className="wantad-skeleton-prof-wrap"></div>
          </div>
          <div className="skeleton-modal-wrap-info"></div>
          </div>
          <div className="add-your-property-wrap skeleton-property-border mb-0">
          <div className="left-prof-wrap-outer me-2">
            <div className="wantad-skeleton-prof-wrap brdr-radius-12"></div>
            
          </div>
          <div className="skeleton-modal-wrap-info"></div>
          </div>
        </div>

        <div className="addyour-property-details-wrap position-relative mb-4">
          <div className="skeleton-price-info-icon"></div>
          <div className="skeleton-form-label"></div>
          <div className="skeleton-form-control"></div>
        </div>

        <div className="addyour-property-details-wrap position-relative mb-16">
          
          <div className="skeleton-form-label"></div>
          <div className="skeleton-form-control mh-120"></div>
          <div className="skeleton-form-label d-block w-32 mt-1 ms-auto"></div>
        </div>
        <div className="skeleton-primary-btn"></div>
        </div>
        </div>
      </div>
      </div>
      </div>

      

      
    </>
  );
};

export default WantadDetailsSkeleton;
