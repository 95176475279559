import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import IntroAcceptedImg from "../../images/intro-accepted-img.png";




const IntroTutorialModal = (props) => {
  const navigate = useNavigate();
  
  return (
    <Modal
      show
      centered
      backdropClassName='white-backdrop'
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      
      className="modal-left-aligned left"
      
    >
      
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="pt-24 pb-24 pl-24 pr-24">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {
            props.onHide()
          }}
        ></button>
        
       
        {/*<div
          className="fireworks-overlay top-65">
            
        
          <img src={CrackersImg} />
        </div>*/}
        
        
        <h6>
         
        Learn about introductions
           
        </h6>
         <p className="mb-4">Think your home might be a match? Learn how to introduce properties in under 1 minute.</p>
        
         <img src={IntroAcceptedImg} className="mb-4" />
          
            <Button
               variant=""
               className="btn btn-secondary"
               onClick={() => {
                props.accept()
              }}
             >
              Show me how
               
             </Button>
             
            
          
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default IntroTutorialModal;
