import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function createSubscriptionPlan(payload) {
  return await apiService.apiCall(endPoints.CREATE_SUBSCIPTION_PLAN, "POST", payload);
}

export async function downgradePlan(payload) {
  return await apiService.apiCall(endPoints.DOWNGRADE_PLAN, "POST", payload);
}

export async function getPromoCodeDetails(payload) {
  return await apiService.apiCall(endPoints.GET_PROMO_CODE_DETAILS, "GET", payload);
}

export async function createSubscriptionPlanOnSignup(payload) {
  return await apiService.apiCall(endPoints.CREATE_PLAN_ON_SIGNUP, "POST", payload);
}

export async function getActivePromoCodeDetails() {
  return await apiService.apiCall(endPoints.GET_ACTIVE_PROMOCODE_DETAILS, "GET", {} );
}

export async function getPreviousPlanBalanceAmount(payload) {
  return await apiService.apiCall(endPoints.GET_PREVIOUS_PLAN_BALANCE_AMOUNT, "GET", payload);
}






  