import React, { useState, useEffect, createContext } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getNiceToHaves } from "../../../services/commonServices";
import Property from "./Property";
import { convertToNumber } from "../../../helpers/validations";
import Layout from "./Layout";
import Exterior from "./Exterior";
import Interior from "./Interior";
import OtherNiceToHaves from "./OtherNiceToHaves";
import { checkArrayisEqual, getLocalStorage } from "../../../helpers/common";
import { INVENTORY_KEY, WANT_AD_KEY } from "../../../constants/common";
import { checkInventoryType } from "../../../helpers/inventory";
export const niceToHaveContext = createContext();

const NiceToHavesProperties = (props) => {
  const MAX_SELECTION_COUNT = 16;

  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setDisableSave,
    adType,
    clearPreviousStates,
    isEdit,
    isInventory
  } = props;

  const nice2Have = _.get(formValues, "nice2Have", []);
  const propertyPurpose = _.get(formValues, "proprtyPurpose", "");
  nice2Have.map(function (item) {
    delete item.text;
    delete item.img;
    delete item.icon;
    return item;
  });
  const nice2HaveInSession = isInventory ? _.get(
    getLocalStorage(INVENTORY_KEY),
    "nice2Have",
    []
  ) :  _.get(
    getLocalStorage(WANT_AD_KEY),
    "nice2Have",
    []
  );
  nice2HaveInSession.map(function (item) {
    delete item.text;
    delete item.img;
    delete item.icon;
    return item;
  });
  const propertyTab = "Property";
  const layoutTab = "Layout";
  const interiorTab = "Interior";
  const exteriorTab = "Exterior";
  const otherTab = "Other";
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const [propertyCount, setPropertyCount] = useState(0);
  const [layoutCount, setLayoutCount] = useState(0);
  const [exteriorCount, setExteriorCount] = useState(0);
  const [interiorCount, setInteriorCount] = useState(0);
  const [otherCount, setOtherCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState(propertyTab);
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemText, setSelectedItemText] = useState();
  const [itemArray, setItemarray] = useState([]);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    const invType = checkInventoryType(propertyPurpose)
    getNiceToHaves(isInventory ? invType : adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    if (nice2Have) {
      setItemarray(nice2Have);
      setOrder(nice2Have.length);
    }
  }, []);

  const filterItemsAndSetCount = (itemArray) => {
    const properties = itemArray.filter(function (co) {
      return co.tab === propertyTab;
    });
    const layout = itemArray.filter(function (co) {
      return co.tab === layoutTab;
    });
    const exterior = itemArray.filter(function (co) {
      return co.tab === exteriorTab;
    });
    const interior = itemArray.filter(function (co) {
      return co.tab === interiorTab;
    });
    const other = itemArray.filter(function (co) {
      return co.tab === otherTab;
    });
    setPropertyCount(properties && properties.length);
    setLayoutCount(layout && layout.length);
    setExteriorCount(exterior && exterior.length);
    setInteriorCount(interior && interior.length);
    setOtherCount(other && other.length);
  };

  useEffect(() => {
    setTotalCount(itemArray?.length);
    handleFormValues({ nice2Have: itemArray });
      if (isEdit) {
        if (itemArray?.length > nice2HaveInSession.length) {
          if (
            _.differenceWith(itemArray, nice2HaveInSession, _.isEqual).length <=
            0
          ) {
            setDisableSave(true);
          }  else{
            setDisableSave(false);
          }
        } else{
          if (
            _.differenceWith(nice2HaveInSession, itemArray, _.isEqual).length <=
            0
          ) {
            setDisableSave(true);
          } else{
            setDisableSave(false);
          }
        }        
      }
      else{
        setDisableSave(false)
      }
    
    filterItemsAndSetCount(itemArray);
  }, [itemArray]);

  useEffect(() => {
    handleItemOrdering();
  }, [selectedItemId, order]);

  const handleItemOrdering = () => {
    const arrayOfCurrentSelectedItem = itemArray.filter(
      (item) =>
        item.id === convertToNumber(selectedItemId) && item.tab === activeTab
    );
    const selectedItemOrder = arrayOfCurrentSelectedItem
      ? _.get(arrayOfCurrentSelectedItem[0], "order", "")
      : "";

    if (arrayOfCurrentSelectedItem.length === 0) {
      const updatedArray = [
        ...itemArray,
        {
          id: convertToNumber(selectedItemId),
          order: convertToNumber(order),
          tab: activeTab,
        },
      ];
      if (selectedItemId) setItemarray(updatedArray);
    } else {
      const newArray = itemArray.filter(
        (item) => item.order !== convertToNumber(selectedItemOrder)
      );

      const reOrderedArray = newArray.map((ro) => {
        const currentOrder = convertToNumber(_.get(ro, "order", ""));
        if (currentOrder > selectedItemOrder) {
          return {
            id: convertToNumber(ro.id),
            order: convertToNumber(currentOrder) - 1,
            tab: ro.tab,
          };
        } else {
          return {
            id: convertToNumber(ro.id),
            order: convertToNumber(currentOrder),
            tab: ro.tab,
          };
        }
      });

      if (selectedItemId) setItemarray(reOrderedArray);
    }
  };

  const handleItemClick = (e) => {
    const itemId = e.currentTarget.id;
    const isAlreadyExistedItem = itemArray.filter(
      (item) =>
        convertToNumber(item.id) === convertToNumber(itemId) &&
        item.tab === activeTab
    );

    if (
      totalCount + 1 <= MAX_SELECTION_COUNT ||
      isAlreadyExistedItem.length !== 0
    ) {
      setOrder(itemArray.length + 1);
      setSelectedItemId(itemId);
    } else {
      alert("Selection Up to 16 items only");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="wantad-nicetohaves-wrap-mobile">
        <niceToHaveContext.Provider
          value={{
            handleItemClick,
            selectedItems: itemArray.filter((item) => item.tab === activeTab),
          }}
        >
          <Tabs
            id="niceto-have-tab"
            className="mb-4 nav-tabs-fix"
            onSelect={(tab) => handleTabClick(tab)}
          >
            <Tab
              eventKey="Property"
              title={
                propertyCount && propertyCount !== 0
                  ? `Property(${propertyCount})`
                  : "Property"
              }
            >
                <div className="row">
                <Property
                  selectedProperties=""
                  properties={_.get(niceToHavesList, "Property", [])}
                />
              </div>              
            </Tab>
            <Tab
              eventKey={layoutTab}
              title={
                layoutCount && layoutCount !== 0
                  ? `Layout(${layoutCount})`
                  : "Layout"
              }
            >
              <div className="row">
                <Layout
                  selectedLayouts=""
                  layout={_.get(niceToHavesList, "Layout", [])}
                />
              </div>
            </Tab>
            <Tab
              eventKey={exteriorTab}
              title={
                exteriorCount && exteriorCount !== 0
                  ? `Exterior(${exteriorCount})`
                  : "Exterior"
              }
            >
              <div className="row">
                <Exterior
                  selectedExteriors=""
                  exterior={_.get(niceToHavesList, "Exterior", [])}
                />
              </div>
            </Tab>
            <Tab
              eventKey={interiorTab}
              title={
                interiorCount && interiorCount !== 0
                  ? `Interior(${interiorCount})`
                  : "Interior"
              }
            >
              <div className="row">
                <Interior
                  selectedInteriors=""
                  interior={_.get(niceToHavesList, "Interior", [])}
                />
              </div>
            </Tab>
            <Tab
              eventKey={otherTab}
              title={
                otherCount && otherCount !== 0
                  ? `Other(${otherCount})`
                  : "Other"
              }
            >
              <div className="row">
                <OtherNiceToHaves
                  selectedOthers=""
                  others={_.get(niceToHavesList, "Other", [])}
                />
              </div>
            </Tab>
          </Tabs>
        </niceToHaveContext.Provider>
      </div>
    </>
  );
};

export default NiceToHavesProperties;
