
import PlanReviewModal from "../PlanUpgrade/PlanReviewModal";


const PlanReviewModalWrapper = (props) => {
  return (
      <PlanReviewModal {...props} />
  );
};

export default PlanReviewModalWrapper;
