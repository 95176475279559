import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import {sendEmailContactSupport} from "../../../helpers/common"
const EmailResentModal = (props) => {
  const {email} = props
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        {/* <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={props.onHide}
        ></button> */}
        {/* <button type="button" class="btn-close" aria-label="Close" onClick={props.onHide}></button> */}
        <h2 className="text-center">Email Resent</h2>

        <Row>
          <Col xl={12}>
            <p className="mb-2 text-center">
              We've sent a {props.login ? 'login' : 'verification'} link to{" "}
              <strong>{email}</strong>
            </p>
            <p className="text-center">
             Still having trouble?{" "}
              <strong>
                {/* {props.login ? <a href="#">Let's try resending it.</a> : <a href="#">Contact support.</a>} */}
                <a href="#" onClick={()=>sendEmailContactSupport("Message")}>Contact support.</a>
              </strong>
            </p>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default EmailResentModal;
