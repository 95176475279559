import React from "react";
import { Offcanvas } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  EmailShareButton,
  EmailIcon,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Shareicon from "../../images/share-link.png";

const ShareLinkOffCanvas = (props) => {
  const { show, onHide, url, image } = props;

  return (
    <Offcanvas
      className="mobile-offcanvas offcanvas-filter-share-fix"
      show={show}
      onHide={onHide}
      placement="bottom"
      {...props}
    >
      <Offcanvas.Body>
        <div className="custom_link d-flex text-center flex-column align-items-center justify-content-center">
          <img src={Shareicon}/>
          <h2 className="pb-3 pt-3">
            Your Custom Link is <br/>Ready to Share!
          </h2>
          <h3 className="pb-3">
            Once you create your private viewing link, it will expire after 72 hours.
            This is to protect the privacy of property owners and their representatives.
            After the link expires, only you can review the introduction and property details.
          </h3>

          {/* <div className=" mb-4 text-center">
            <img src={image} className="searchImgmob" />
          </div> */}
        </div>

        <div className="text-center">
          <Form.Control className="bluetextInputMobSmall  mb-4" value={url} />
        </div>

        <div className="btn-wrap ">
          <CopyToClipboard text={url}>
            <a
              href={void 0}
              role="button"
              className="btn btn-primary w-100"
              onClick={() => toast.success("Link copied to clipboard")}
            >
              Copy Link
            </a>
          </CopyToClipboard>
          <EmailShareButton
            className="btn btn-link w-100 introduction-share"
            url={url}
          >
            Share via Email
          </EmailShareButton>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default ShareLinkOffCanvas;
