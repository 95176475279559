import React from 'react';
import FldCloseIcon from "../images/round-close-icon.svg";
import { Button } from 'react-bootstrap';

const TextBoxMob = props => {
  return (
    <>
      <label className= {props.label ? 'form-label d-block' :'form-label d-none' } >{props.label}</label>
      <input
        className={props.class ? `form-control ${props.class}` : "form-control"}
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        error={props.error}
        autoComplete={props.autoComplete}
        onChange={props.onChange}
        onBlur={props.onBlur}
        minLength={props.minLength}
        maxLength={props.maxLength}
        disabled={props.disabled}
        background-color={props.bgColor}
        ref={props.txtRef}
        min={props.minValue}
        max={props.maxValue}
        step = {props.step}
        readOnly = {props.readonly}
        tabIndex={props.tabIndex}
        autoFocus ={props.autoFocus}
        handleClear={props.handleClear}
        pattern={props.pattern}
        inputmode={props.inputmode}
      />
      {props.errorClass ?  <div  className={props.errorClass}>{props.errorMessage}</div> :
        <div  className='mob-error-msg'>
        {props.errorMessage && props.errorMessage !== '' && <span className='mob-error-msg'>{props.errorMessage}</span>}
      </div>}
       {props.closeIcon && <Button variant="" className="fld-close-btn">
            <img src={FldCloseIcon} onClick={() => props.handleClear()} />
      </Button>}
    
   </>
  );
}

export default TextBoxMob;
