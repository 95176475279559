/**
 * @file   src\components\Common\NiceToHave\NiceToHaveSelect.js
 * @brief  This file is responsible for nice to have selection in want ad creation
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";
import { formatResponse } from "../../../helpers/common";
import Select from "react-select";
import { convertToNumber } from "../../../helpers/validations";


const NiceToHaveSelect = (props) => {
    const {
        selectedNiceToHave,
        handleFormValues,
        tab,
        placeholder,
        niceToHavesList,
        propertyType,
        componentValue,
        selectedniceToHavesList
    } = props;

    /**
     * To handle nice to have selection
     * @param {*} e 
     * @param {*} tab 
     */
    const handleNiceHaveChange = (e, tab) => {
        if (Array.isArray(e)) {
            const MaxCount = convertToNumber(propertyType) === 6 ? 10 : 16;
            let value = e.map(opt => ({ ...opt, tab, id: opt.value }));  // Add id as value
            let selectedValues = [...selectedniceToHavesList];

            // New values for comparison
            const newValueSet = new Set(value.map(v => v.value));

            // Filter out the values to be removed
            let updatedValues = selectedValues.filter(item =>
                item.tab !== tab || newValueSet.has(item.value)
            );

            // Add new values
            value.forEach(v => {
                if (!updatedValues.some(item => item.value === v.value && item.tab === v.tab)) {
                    updatedValues.push({ ...v, order: updatedValues.length + 1 });
                }
            });

            // Check for max count
            if (updatedValues.length > MaxCount) {
                let msg = "Selection Up to " + MaxCount + " items only";
                    alert(msg);
            } else {
                // Reorder the updated values to ensure order is maintained
                updatedValues = updatedValues.map((item, index) => ({
                    ...item,
                    order: index + 1
                }));

                handleFormValues({
                    nice2Have: updatedValues,
                    focusOut: true
                });
            }
        }
    }


    /**
       * to set the selected option on load
       */
    const getOptionValue = (option, tab, mainList) => {
        if (option && mainList) {
            var selectedValue = option.filter(function (item) {
                if (item.tab == tab) {
                    return mainList.filter(function (nicetohave) {
                        if (nicetohave.value == item.id) {
                            item.value = nicetohave.value;
                            item.label = nicetohave.label;
                            return item;
                        }
                    })
                }
            })
            return selectedValue
        }
        return null;

    }

    return (
        <>
            <Select
                isMulti
                id="multi1"
                name="colors1"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(e) => { handleNiceHaveChange(e, tab) }}
                value={getOptionValue(selectedNiceToHave, tab, formatResponse(niceToHavesList))}
                placeholder={placeholder}
                options={formatResponse(niceToHavesList)}
                components={componentValue}
                className="basic-multi-select mb-4"
                classNamePrefix="select"
                isClearable={false}
            />
        </>
    );
};
export default NiceToHaveSelect;
