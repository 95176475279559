import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const PauseWantAdModal = (props) => {
  const {show, onHide, handleConfirm} = props;
 

  return (
    <Modal show={show} onHide={onHide} {...props} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Are you sure you want to pause your want-ad?</h3>
        <p>Pausing this ad will stop any further introductions from being sent to you, and it will be removed from the ad feed.</p>
       <p>You can unpause your want-ad when you’re ready to receive introductions.</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={handleConfirm}>Confirm</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default PauseWantAdModal;
