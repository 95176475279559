import React, { useState, useEffect } from "react";
import { USER_INFO_KEY, USER_TYPE_AGENT, INVENTORY_KEY, INVENTORY_KEY_TEMP } from "../../constants/common";
import { useNavigate, useLocation } from "react-router";
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorageOnLogout,
  getUserType,
  getUserId,
  removeLocalStorage,
  checkTutorialDisplayStatus,
} from "../../helpers/common";
import { userLogout } from "../../reducers/login";
import AddProperty from "../components/CreateInventory/AddProperty";
import { useSelector , useDispatch} from "react-redux";
import { fetchUserDetails, fetchUserPrivileges, fetchUserStatistics } from "../../actions/userprofile";
import InventoryMemberAddProperty from "./InventoryMemberAddProperty";
import AddLocationAndDetails from "../components/CreateInventory/AddLocationAndDetails";
import AddPropertyUse from "../components/CreateInventory/AddPropertyUse";
import NiceToHaves from "../components/createWantAd/NiceToHaves";
import AddAskingPrice from "../components/CreateInventory/AddAskingPrice";
import UploadPhotos from "../components/CreateInventory/UploadPhotos";
import AddDescription from "../components/CreateInventory/AddDescription";
import ReadyToPostInventory from "../components/CreateInventory/ReadyToPostInventory";
import SelectClient from "../components/CreateInventory/SelectClient";
import AddAskingPriceAgent from "../components/CreateInventory/AddAskingPriceAgent";
import AddAdditionalDetails from "../components/CreateInventory/AddAdditionalDetails";
import AddTimeLine from "../components/CreateInventory/AddTimeline";
import { saveInventoryStrengthenData, saveMemberInventoryData } from "../../services/inventoryService";
import { pageURLs } from "../../constants/pageURLs";
import { toast } from "react-toastify";
import ViewImagesFullGallery from "../commonComponents/ViewImagesFullGallery";
const AddPropertyInInventory = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let adAmenities = null;
  const [uiKey, setUIKey] = useState(null);
  const userType = getUserType();
  const [formValues, setFormValues] = useState(null);
  const [completedPercentage, setCompletedPercentage] = useState(7)
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const [isPremiumUser , setIsPremiumUser] = useState(null);
  const userId = getUserId();
  const isAgent = userType===USER_TYPE_AGENT ? true : false
  const isEdit = location.state && location.state['data'] ? true : false;
  const isFromMatch =  location.state && location.state['isFromMatch'];
  const isFromSendIntroduction =  location.state && location.state['isFromSendIntroduction'];

  const [userCount, setUserCount] = useState({});
  const [allowedCount, setAllowedCount] = useState({});
  const [userDetails, setUserDetails] = useState(null);

  const userStatictics = useSelector(
    (state) => state?.userProfile?.userStatictics
  );
  const userPrivileges = useSelector(
    (state) => state?.userProfile?.userPrivileges
  );

  useEffect(() => { //Need to show inventory onboard very first time
    let DisplayInventoryAddOnboard = checkTutorialDisplayStatus(7); //Inventory onboard display
    if (DisplayInventoryAddOnboard) {
      navigate(pageURLs.inventoryTutorialResponsive)
    }
  }, []);


  useEffect(()=>{
    if(location && location.state){
      setLocalStorage(INVENTORY_KEY, location.state['data'])
    }
  },[])

  useEffect(()=>{
    if(isEdit){
      handleFormValues({isEdit : _.get(formValues, "inventoryId", null) ? true : false})
    }
  },[isEdit])

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[0]);
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } else {
      setUserDetails(null);
    }
  }, [userDetailsFromStore]);

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id,
    };
    dispatch(fetchUserStatistics(payload));

    const data = {
      PlanId: userDetails?.PlanId,
    };
    dispatch(fetchUserPrivileges(data));

  }, [userDetails]);


  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);

  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);


  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;      
      if(type === "clearSell"){
        const array = { ...formValues }; // make a separate copy of the state
        ["sellAskngPrice", "isCompnsteAgent", "salesPercnt", "nice2Have"]
        .forEach((e) => delete array[e]);
        setFormValues(array);
      }
      if (type === "clearPropertyPurposeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwLrgDogs", "alwSmlDogs", "alwCats", "LTRAskngPrce", "isCompnsteAgent_LTR", "salesPercnt_LTR", "nice2Have" ].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "clearPropertyPurposeSTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwPets", "accsPvtBthRm", "pvtEntrnce", "noOfBeds", "STRAskngPrce", "isCompnsteAgent_STR", "salesPercnt_STR", "nice2Have"  ].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "locAddress",
        "noBedRm",
        "noBathRm",
        "sqFt",
        "stories",
        "lotSize",
        "grgeSpots",
        "adaAccess",
        "acreage",
        "lotType",
        "ltTypeTxt",
        "roadType",
        "rdTypeTxt",
        "isPowerAccess",
        "waterSuply",
        "wSText",
        "wasteWtrSys",
        "WstWatrSys",
        "watrWtrText",
        "isApprovedStrctr",
        "isInFloodPlain",
        "proprtyPurpose",
        "alwLrgDogs",
        "alwSmlDogs",
        "alwCats",
        "alwPets",
        "accsPvtBthRm",
        "isConfirmOwner",
        "isConfirmPropLoc",
        "nice2Have",
        "sellAskngPrice",
        "LTRAskngPrce",
        "STRAskngPrce",
        "isCompnsteAgent",
        "salesPercnt",
        "isCompnsteAgent_LTR", 
        "isCompnsteAgent_STR", 
        "salesPercnt_LTR", 
        "salesPercnt_STR",
        "photos",
        "invtryDetails",
        "prptySubTypes",
        "prptySubType",
        "prptySubTypeTxt",
         "prptyTypeTxt",
         "IsNewConstruction",
         "isPrvtDrInst",
         "isDrldWelInst",
         "isDrldWelAppr",
         "isSepTankInst",
         "isSepTankAppr",
         "pvtEntrnce",
         "neighborHood",
         "highSchool",
         "yearBuilt",
         "noOfBeds",
         "lat",
         "lng",
         "mapZoom",
         "mapType",
         "street",
         "city",
         "county",
         "state",
         "zip"
      ].forEach((e) => delete array[e]);

      setFormValues(array);
    }
  };

  const saveStrengthenData = async (InventoryId) => {
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(INVENTORY_KEY);
    }
    const userId = getUserId();
    requestPayLoad.inventoryId = InventoryId;
    requestPayLoad.userId = userId;
    requestPayLoad.isStrengthen = true;
    await saveInventoryStrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if(isEdit){
            setLocalStorage(INVENTORY_KEY, {
              inventoryId: _.get(responseResult, "inventoryId", ""),
            });
            toast.success("Property successfully edited")
            navigate(pageURLs.mobilememberInventoryDetails);
            removeLocalStorage(INVENTORY_KEY_TEMP);
          }
          else {
            removeLocalStorage(INVENTORY_KEY);
            removeLocalStorage(INVENTORY_KEY_TEMP);
            navigate(pageURLs.mobileManageInventory, { state: { showDraft: true } });} 
      }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  const saveInventoryData = async (isAddInventry) => {
    let userId = getUserId();
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(INVENTORY_KEY);
    }    
    requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
    requestPayLoad.userId = userId;
        if (isAddInventry) {
          requestPayLoad.IsSavedDraft = false;
          requestPayLoad.IsPublished = true;
          delete requestPayLoad.tlText;
          delete requestPayLoad.prptyTypeTxt;
          delete requestPayLoad.wantAdValErrors;
        } else {
          requestPayLoad.IsSavedDraft = true;
          requestPayLoad.IsPublished = false;
          requestPayLoad.actvStep = 9;
          requestPayLoad.cmpldPerctg =completedPercentage;
          if(isAgent){requestPayLoad.isAgentCreated = true}
          if(isAgent && !isPremiumUser){
            requestPayLoad.isAgentAsSeller = true;
          }
        }    
    saveMemberInventoryData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if (requestPayLoad.apprOnFile || requestPayLoad.insReport || requestPayLoad.sellerFin || requestPayLoad.floorPlan || requestPayLoad.floodCert || requestPayLoad.tlText) {
            saveStrengthenData(responseResult.inventoryId);
          } else {     
            if(isEdit)     {
              setLocalStorage(INVENTORY_KEY, {
                inventoryId: _.get(responseResult, "inventoryId", ""),
              });
              toast.success("Property successfully edited")
              navigate(pageURLs.mobilememberInventoryDetails);
              removeLocalStorage(INVENTORY_KEY_TEMP);
            }  
            else {
              removeLocalStorage(INVENTORY_KEY);
              removeLocalStorage(INVENTORY_KEY_TEMP);
              navigate(pageURLs.mobileManageInventory, { state: { showDraft: true } });}         
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

  };
  
const handleSaveAndExit = () =>{
  saveInventoryData()
}

const handleSave = () =>{
  const isAddInventry = true;
  saveInventoryData(isAddInventry)
}

  useEffect(() => {
    dispatch(fetchUserDetails({
      "userId" : userId,
    } ));
    adAmenities = getLocalStorage(INVENTORY_KEY);
    setFormValues(adAmenities);
    if(adAmenities?.cmpldPerctg){
      setCompletedPercentage(adAmenities?.cmpldPerctg)
    }
    
    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), "userInfo", null);
    if (!userInfo) {
      handleStateClear();
    }       
  }, [uiKey===null, isPremiumUser]);

  useEffect(()=>{
    handleCreateInventoryUi();
  }, [uiKey])

  useEffect(() => {
    setLocalStorage(INVENTORY_KEY_TEMP, formValues);
    //   if (userDetails?.period===1 && userCount?.Inventory >= 2) {        
    //     if((isEdit && formValues.isPublished==0) || !isEdit){
    //       navigate(pageURLs.updatePlan, {
    //         state: { isFromSettings: true },
    //       })
    //     }
    //   } 
    // else { 
      if(!isEdit)
      {if(formValues?.clientId == undefined && isPremiumUser===true) {
        setUIKey("client");
      }
      else 
      if (formValues?.prprtyType == undefined && isPremiumUser===false) {
        setUIKey("propertyType");
      }}
    // }    
  }, [formValues, userCount, userDetails]);

  useEffect(()=>{
    handleFormValues({ cmpldPerctg: completedPercentage });
  }, [completedPercentage])

  const handleStateClear = () => {
    // alert("Your session has expired. Not complete the request. Please log in");
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate('/mobile/home');
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };




  const handleCreateInventoryUi = () => {
    switch (uiKey) {    
      case "client" : 
      return <SelectClient
      handleFormValues={handleFormValues}
      formValues={formValues}
      setUIKey={setUIKey}
      completedPercentage={completedPercentage}
      clearPreviousStates={clearPreviousStates}
      setCompletedPercentage={setCompletedPercentage}
      isEdit={isEdit}
      uiKey={uiKey}
      isPremiumUser={isPremiumUser}
    />;
    case "propertyType" :
      return <AddProperty
        handleFormValues={handleFormValues}
        formValues={formValues}
        uiKey={uiKey}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage}
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent}  
        isEdit={isEdit}
        isPremiumUser={isPremiumUser}
      />  
      case "location" : 
      return <AddLocationAndDetails
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage} 
        setCompletedPercentage={setCompletedPercentage} 
        isAgent={isAgent}    
        isEdit={isEdit}
        uiKey={uiKey}
        />;
        case "propertyUse" : 
      return <AddPropertyUse
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage} 
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage} 
        isAgent={isAgent}    
        isEdit={isEdit}
        />;
      case "photos" : 
      return <UploadPhotos
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage}
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent}  
        isEdit={isEdit}
      />
      case "askingPrice" : 
      if(!isAgent)
      {return <AddAskingPrice
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage}
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent} 
        isEdit={isEdit}
      />}
      else{
        return <AddAskingPriceAgent
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage}
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent} 
        isEdit={isEdit}
      />
      }
        
      case "niceToHaves" : 
      return <NiceToHaves
        isInventory={true}
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage}
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent} 
        isEdit={isEdit}
      />
      case "description" : 
      return <AddDescription
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        completedPercentage={completedPercentage}
        clearPreviousStates={clearPreviousStates}
        setCompletedPercentage={setCompletedPercentage}
        isEdit={isEdit}
        isAgent={isAgent}
      />    
      case 'additionalInformation' : 
      return <AddAdditionalDetails
      handleFormValues={handleFormValues}
      formValues={formValues}
      setUIKey={setUIKey}
      completedPercentage={completedPercentage}
      clearPreviousStates={clearPreviousStates}
      setCompletedPercentage={setCompletedPercentage}
      isEdit={isEdit}
      />
      case 'timeline' : 
      return <AddTimeLine
      handleFormValues={handleFormValues}
      formValues={formValues}
      setUIKey={setUIKey}
      completedPercentage={completedPercentage}
      clearPreviousStates={clearPreviousStates}
      setCompletedPercentage={setCompletedPercentage}
      isEdit={isEdit}
      />
      case "readyToPost" :
        return <ReadyToPostInventory
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        uiKey={uiKey}
        completedPercentage={completedPercentage}
        handleSaveAndExit={handleSaveAndExit}
        isPremiumUser={isPremiumUser}
        isAgent={isAgent}
        isFromSendIntroduction={isFromSendIntroduction}
        isFromMatch={isFromMatch}
      />
      case "imagesFullView" :
        return <ViewImagesFullGallery
        photos={_.get(formValues, "photos", [])}
        setUIKey={setUIKey}
        uiKey={uiKey}       
      />
      default:
        return <InventoryMemberAddProperty 
        setUIKey={setUIKey}
        formValues={formValues}
        completedPercentage={completedPercentage}
        uiKey={uiKey}
        handleSaveAndExit={handleSaveAndExit}
        setCompletedPercentage={setCompletedPercentage}
        isPremiumUser={isPremiumUser}
        isEdit={isEdit}
        isAgent={isAgent}
        handleSave={handleSave}
        />;
    }
  };

  

  

  return <>         
          {handleCreateInventoryUi()}
      </>;
};

export default AddPropertyInInventory;
