import React, { useState, useEffect } from "react";
import TopRightBtn from "../../MemberWantAd/TopRightBtn";
import BottomNavBar from "../../MemberWantAd/BottomNavBar";
import InventoryStartPage from "./InventoryStartPage";
import InventoryPropertyTypes from "./InventoryPropertyTypes";
import InventoryLocationWithMustHaves from "./InventoryLocationWithMustHaves";
import InventoryFileUpload from "./InventoryFileUpload";
import InventoryNiceToHaves from "./InventoryNiceToHaves";
import InventoryBudget from "./InventoryBudget";
import InventoryPropertyDetails from "./InventoryPropertyDetails";
import InventoryConfirmation from "./InventoryConfirmation";
import InventoryLotLandLocation from "./InventoryLotLandLocation";
import InventoryPropertyPurpose from "./InventoryPropertyPurpose";
import InventoryLandNiceToHaves from "./InventoryLandNiceToHaves";
import InventoryMemberStrengthen from "../../Inventory/StengthenInventory/InventoryMemberStrengthen";
import { getInventoryMyDrafts } from "../../../services/inventoryService";
import {
  USER_INFO_KEY,
  INVENTORY_KEY,
  IS_LAND,
} from "../../../constants/common";
import { getLocalStorage, removeDecimalZeros } from "../../../helpers/common";
import { convertToNumber } from "../../../helpers/validations";

const InventoryRightSection = (props) => {
  const {
    skipButtonEnable,
    exitButtonText,
    backButtonEnable,
    handleContinueClick,
    handleBackButtonClick,
    handleContinueButtonState,
    isDisabled,
    handleSaveAndExit,
    handleFormValues,
    handleSkipButtonClick,
    step,
    formValues,
    continueButtonText,
    className,
    bottomNavClass,
    clearPreviousStates,
    exitButtonDisable,
    backButtonText,
    handleConfirmationModal,
    showConfirmStatuModal,
    hideContinueButton,
    isStrengthenInventory,
    setActiveStep,
    setDraftSelectedItem,
    draftSelectedItem,
  } = props;

  const propertyType = convertToNumber(_.get(formValues, "prprtyType", null));
  const propertySubType = convertToNumber(
    _.get(formValues, "prptySubType", null)
  );
  const propertyPurpose = _.get(formValues, "proprtyPurpose", "");

  const [myDrafts, setMyDrafts] = useState();
  useEffect(() => {
    if (!isStrengthenInventory && step === 1) {
      const userDetails = getLocalStorage(USER_INFO_KEY);
      const userId = _.get(userDetails, "userInfo.userId", null);
      getInventoryMyDrafts()
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (responseResult && responseResult.length !== 0) {
            setMyDrafts(responseResult);
          } else {
            setActiveStep(2);
            setMyDrafts({});
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, []);

  const loadStartingPage = () => {
    const inventory = getLocalStorage(INVENTORY_KEY);
    if (!isStrengthenInventory) {
      if (step === 1) {
        if (myDrafts) {
          if (myDrafts.length > 0) {
            return (
              <InventoryStartPage
                handleContinueClick={handleContinueClick}
                handleContinueButtonState={handleContinueButtonState}
                myDraft={myDrafts}
                setDraftSelectedItem={setDraftSelectedItem}
                draftSelectedItem={draftSelectedItem}
              />
            );
          } else {
            return (
              <InventoryPropertyTypes
                handleContinueButtonState={handleContinueButtonState}
                handleFormValues={handleFormValues}
                propertyType={propertyType}
                adType={_.get(formValues, "adType", null)}
                clearPreviousStates={clearPreviousStates}
                prptySubType={propertySubType}
              />
            );
          }
        }
      } else if (
        (_.get(inventory, "actvStep", "") ||
          _.get(inventory, "cmpldStep", "")) &&
        step === 2
      ) {
        return (
          <InventoryPropertyTypes
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            propertyType={propertyType}
            adType={_.get(formValues, "adType", null)}
            clearPreviousStates={clearPreviousStates}
            prptySubType={propertySubType}
          />
        );
      }
    }
  };

  const renderFlow = () => {
    if (isStrengthenInventory) {
      return (
        <InventoryMemberStrengthen
          formValues={formValues}
          step={step}
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          handleContinueClick={handleContinueClick}
        />
      );
    } else {
      switch (step) {
        // case 2:
        //   return (
        //     <InventoryPropertyTypes
        //       handleContinueButtonState={handleContinueButtonState}
        //       handleFormValues={handleFormValues}
        //       propertyType={propertyType}
        //       adType={_.get(formValues, "adType", null)}
        //       clearPreviousStates={clearPreviousStates}
        //       prptySubType={propertySubType}
        //     />
        //   );
        case 3:
          if (propertyType === IS_LAND) {
            return (
              <InventoryLotLandLocation
                handleContinueButtonState={handleContinueButtonState}
                handleFormValues={handleFormValues}
                locationAddress={_.get(formValues, "locAddress", null)}
                acreage={_.get(formValues, "acreage", null)}
                lotType={convertToNumber(_.get(formValues, "lotType", null))}
                roadType={convertToNumber(_.get(formValues, "roadType", null))}
                isPowerAccess={_.get(formValues, "isPowerAccess", null)}
                waterSuply={convertToNumber(
                  _.get(formValues, "waterSuply", null)
                )}
                wasteWtrSys={convertToNumber(
                  _.get(formValues, "wasteWtrSys", null)
                )}
                isApprovedStrctr={_.get(formValues, "isApprovedStrctr", null)}
                isInFloodPlain={_.get(formValues, "isInFloodPlain", null)}
                lat={_.get(formValues, "lat", null)}
                lng={_.get(formValues, "lng", null)}
                mapZoom={_.get(formValues, "mapZoom", null)}
                isPrvtDrInst={_.get(formValues, "isPrvtDrInst", null)}
                isDrldWelInst={_.get(formValues, "isDrldWelInst", null)}
                isDrldWelAppr={_.get(formValues, "isDrldWelAppr", null)}
                isSepTankInst={_.get(formValues, "isSepTankInst", null)}
                isSepTankAppr={_.get(formValues, "isSepTankAppr", null)}
                mapType={_.get(formValues, "mapType", null)}
                acre_Sqft={_.get(formValues, "acre_Sqft", null)}
                isPropertyExist={_.get(formValues, "isPropertyExist", null)}
              />
            );
          } else {
            return (
              <InventoryLocationWithMustHaves
                handleContinueButtonState={handleContinueButtonState}
                handleFormValues={handleFormValues}
                locationAddress={_.get(formValues, "locAddress", null)}
                noBedRm={_.get(formValues, "noBedRm", null)}
                noBathRm={ _.get(formValues, "noBathRm", null)}
                sqFt={_.get(formValues, "sqFt", null)}
                stories={_.get(formValues, "stories", null)}
                lotSize={_.get(formValues, "lotSize", null)}
                grgeSpots={_.get(formValues, "grgeSpots", null)}
                adaAccess={_.get(formValues, "adaAccess", false)}
                IsNewConstruction={_.get(
                  formValues,
                  "IsNewConstruction",
                  false
                )}
                propertyType={propertyType}
                propertySubType={propertySubType}
                lat={_.get(formValues, "lat", null)}
                lng={_.get(formValues, "lng", null)}
                mapZoom={_.get(formValues, "mapZoom", null)}
                neighborHood={_.get(formValues, "neighborHood", null)}
                highSchool={_.get(formValues, "highSchool", null)}
                yearBuilt={_.get(formValues, "yearBuilt", null)}
                invFormError={_.get(formValues, "invFormError", null)}
                errorUpdatedAt={_.get(formValues, "updatedAt", null)}
                mapType={_.get(formValues, "mapType", null)}
                city={_.get(formValues, "city", null)}
                state={_.get(formValues, "state", null)}
                isPropertyExist={_.get(formValues, "isPropertyExist", null)}
              />
            );
          }

        case 4:
          return (
            <InventoryPropertyPurpose
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              propertyPurpose={propertyPurpose}
              alwLrgDogs={_.get(formValues, "alwLrgDogs", null)}
              alwSmlDogs={_.get(formValues, "alwSmlDogs", null)}
              alwCats={_.get(formValues, "alwCats", null)}
              alwPets={_.get(formValues, "alwPets", null)}
              accsPvtBthRm={_.get(formValues, "accsPvtBthRm", null)}
              clearPreviousStates={clearPreviousStates}
              propertyType={propertyType}
              propertySubType={propertySubType}
              handleConfirmationModal={handleConfirmationModal}
              showConfirmStatuModal={showConfirmStatuModal}
              isConfirmOwner={_.get(formValues, "isConfirmOwner", null)}
              isConfirmPropLoc={_.get(formValues, "isConfirmPropLoc", null)}
              pvtEntrnce={_.get(formValues, "pvtEntrnce", null)}
              noOfBeds={_.get(formValues, "noOfBeds", null)}
            />
          );
        case 5:
          if (propertyType === IS_LAND) {
            return (
              <InventoryLandNiceToHaves
                handleContinueButtonState={handleContinueButtonState}
                handleFormValues={handleFormValues}
                nice2Have={_.get(formValues, "nice2Have", null)}
              />
            );
          } else {
            return (
              <InventoryNiceToHaves
                handleContinueButtonState={handleContinueButtonState}
                handleFormValues={handleFormValues}
                nice2Have={_.get(formValues, "nice2Have", [])}
                propertyPurpose={propertyPurpose}
              />
            );
          }

        case 6:
          return (
            <InventoryBudget
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              sellAskngPrice={ _.get(formValues, "sellAskngPrice", "")}
              LTRAskngPrce={_.get(formValues, "LTRAskngPrce", "")}
              STRAskngPrce={_.get(formValues, "STRAskngPrce", "")}
              isCompnsteAgent={_.get(formValues, "isCompnsteAgent", "")}
              isCompnsteAgentLTR={_.get(formValues, "isCompnsteAgent_LTR", "")}
              isCompnsteAgentSTR={_.get(formValues, "isCompnsteAgent_STR", "")}
              propertyPurpose={propertyPurpose}
              salesPercnt={_.get(formValues, "salesPercnt", null)}
              salesPercntLTR={_.get(formValues, "salesPercnt_LTR", null)}
              salesPercntSTR={_.get(formValues, "salesPercnt_STR", null)}
            />
          );
        case 7:
          return (
            <InventoryFileUpload
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              photos={_.get(formValues, "photos", [])}
              s3Imagefolder={_.get(formValues, "s3Imagefolder", "")}
              isFolderCreated={_.get(formValues, "isFolderCreated", "")}
            />
          );
        case 8:
          return (
            <InventoryPropertyDetails
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              invtryDetails={_.get(formValues, "invtryDetails", "")}
            />
          );
        case 9:
          return (
            <InventoryConfirmation
              handleContinueButtonState={handleContinueButtonState}
              formValues={formValues}
            />
          );
      }
    }
  };

  return (
    <div className="col-lg-7 offset-lg-5 right-pane-bg">
      <div
        className={
          className ? "right-pane-content " + className : "right-pane-content"
        }
      >
        <TopRightBtn
          exitButtonText={exitButtonText}
          handleSaveAndExit={handleSaveAndExit}
          exitButtonDisable={exitButtonDisable}
          step={step}
        />
        {loadStartingPage()}
        {renderFlow()}

        <BottomNavBar
          skipButtonEnable={skipButtonEnable}
          backButtonEnable={
            _.get(formValues, "isPublished", "") == 1 ? false : backButtonEnable
          }
          handleContinueClick={handleContinueClick}
          handleBackButtonClick={handleBackButtonClick}
          isDisabled={isDisabled}
          handleSkipButtonClick={handleSkipButtonClick}
          step={step}
          continueButtonText={continueButtonText}
          bottomNavClass={bottomNavClass}
          backButtonText={backButtonText}
          hideContinueButton={hideContinueButton}
        />
      </div>
    </div>
  );
};

export default InventoryRightSection;
