import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  IS_PRIVATE_ROOM,
  STORY_ANY,
  NEW_CONSTRUCTION,
  IMG_JPEG_FORMAT,
  IN_CITY_LIMITS,
  RURAL,
  BUDGET_NOMIN_NOMAX,
} from "../constants/common";
import {
  convertToCurrencySystem,
  durationForFlexibleDate,
  filterArrayBasedOnKey,
  formateDate,
  monthNameForFlexibleDate,
  removeDecimalZeros,
} from "./common";
import { convertToNumber } from "./validations";
import { saveMemberWantAd } from "../services/wantAdService";

export const setSqftValue = (value) => {
  return value == 5000 ? value + "+" : value;
};

export const setLotSizeValue = (minValue, maxValue) => {
  let minLtzValue =
    minValue == 0.25
      ? "1/4"
      : minValue == 0.5
      ? "1/2"
      : minValue == 100
      ? "100+"
      : minValue;
  let maxLtzValue =
    maxValue == 0.25
      ? "1/4"
      : maxValue == 0.5
      ? "1/2"
      : maxValue == 100
      ? "100+"
      : maxValue;

  let unit;
  if (maxLtzValue == "100+") {
    unit = " acres";
  } else if (convertToNumber(maxLtzValue) > 1) {
    unit = " acres";
  } else {
    unit = " acre";
  }

  let ltSize;
  if (minLtzValue && maxLtzValue) {
    ltSize = `${minLtzValue} - ${maxLtzValue} ${unit}`;
  } else if (!minLtzValue && maxLtzValue) {
    ltSize = `No min - ${maxLtzValue} ${unit}`;
  } else if (minLtzValue && !maxLtzValue) {
    ltSize = `${minLtzValue} - No max`;
  } else {
    //ltSize = `No min - No max`;
    ltSize = `Any`;
  }

  return ltSize;
};

export const wantAdSqftValue = (Sqft_mn, Sqft_mx) => {
  let sqFt = "";
  const sqFtMin = setSqftValue(removeDecimalZeros(Sqft_mn));
  const sqFtMax = setSqftValue(removeDecimalZeros(Sqft_mx));

  if (sqFtMin && sqFtMax) {
    sqFt = `${sqFtMin} - ${sqFtMax}`;
  } else if (!sqFtMin && sqFtMax) {
    sqFt = `No min - ${sqFtMax}`;
  } else if (sqFtMin && !sqFtMax) {
    sqFt = `${sqFtMin} - No max`;
  } else {
    // sqFt = `No min - No max`;
    sqFt = `Any`;
  }
  return sqFt;
};

export const wantAdBudgetValue = (bdgtMin, bdgtMax) => {
  let budget = "";
  const budgetMin = convertToCurrencySystem(bdgtMin);
  const budgetMax = convertToCurrencySystem(bdgtMax);

  if (budgetMin && budgetMax) {
    budget = `$${budgetMin} - $${budgetMax}`;
  } else if (!budgetMin && budgetMax) {
    budget = `No min - $${budgetMax}`;
  } else if (budgetMin && !budgetMax) {
    budget = `$${budgetMin} - No max`;
  } else {
    budget = `No min - No max`;
    // budget = `Any`;
  }
  return budget;
};

export const buildWantAdMustHavesHTML = ({
  adType,
  adTypeBasedOnProperty,
  formValues,
}) => {
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  let garrageSize = _.get(formValues, "grgSpce", "") || "Any";
  if (garrageSize) {
    if (garrageSize !== "Any") {
      garrageSize = garrageSize + "+";
    }
  }
  const budget = wantAdBudgetValue(
    _.get(formValues, "minBdgt", ""),
    _.get(formValues, "maxBdgt", "")
  );
  let ltSize = "";
  let sqFt = "";
  ltSize = setLotSizeValue(
    removeDecimalZeros(_.get(formValues, "minLtSz", null)),
    removeDecimalZeros(_.get(formValues, "maxLtSz", null))
  );

  const sqFtMin = setSqftValue(
    removeDecimalZeros(_.get(formValues, "sqFtMin", null))
  );
  const sqFtMax = setSqftValue(
    removeDecimalZeros(_.get(formValues, "sqFtMax", null))
  );
  const adaAccess = _.get(formValues, "adaAccess", "");
  if (sqFtMin && sqFtMax) {
    sqFt = `${sqFtMin} - ${sqFtMax}`;
  } else if (!sqFtMin && sqFtMax) {
    sqFt = `No min - ${sqFtMax}`;
  } else if (sqFtMin && !sqFtMax) {
    sqFt = `${sqFtMin} - No max`;
  } else {
    // sqFt = `No min - No max`;
    sqFt = `Any`;
  }
  if (adTypeBasedOnProperty === BUY) {
    const yearBuiltOption = _.get(formValues, "yearBuiltOption", null);
    const builtYrMin = _.get(formValues, "yrBltMin", "");
    const builtYrMax = _.get(formValues, "yrBltMax", "");

    let builtYear = "";
    let stories = _.get(formValues, "stories", null);
    if (stories) {
      stories = stories === STORY_ANY ? "Any" : "Single Story Only";
    }

    if (yearBuiltOption) {
      builtYear =
        yearBuiltOption === NEW_CONSTRUCTION
          ? "New Construction"
          : `${builtYrMin} - ${builtYrMax}`;
    }

    let noBathRoom = _.get(formValues, "noBathRm", "");
    let noBedRoom = _.get(formValues, "noBedRm", "");
    if (noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom !== "Any") noBedRoom = noBedRoom + "+";

    return (
      <>
        {" "}
        <div className="col-6 pe-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Budget</div>
            <div className="musthave-content-text">{budget}</div>
          </div>
        </div>
        {ltSize && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot size</div>

              <div className="musthave-content-text">{ltSize}</div>
            </div>
          </div>
        )}
        <div className="col-6 pe-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Garage spaces</div>
            <div className="musthave-content-text">{garrageSize}</div>
          </div>
        </div>
        {stories && stories != 0 && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Stories</div>

              <div className="musthave-content-text">{stories}</div>
            </div>
          </div>
        )}
        {builtYear && (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Year Built</div>
              <div className="musthave-content-text">{builtYear}</div>
            </div>
          </div>
        )}
        {adaAccess && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">ADA Accessible</div>

              {adaAccess == true && adaAccess != 0 && (
                <div className="musthave-content-text">Yes</div>
              )}
            </div>
          </div>
        )}
      </>
    );
  } else if (adTypeBasedOnProperty === LONG_RENT) {
    let noBathRoom = _.get(formValues, "noBathRm", "") || "Any";
    let noBedRoom = _.get(formValues, "noBedRm", "") || "Any";
    if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
    const alwLargeDogs = _.get(formValues, "alwLrgDogs", "");
    const alwSmlDogs = _.get(formValues, "alwSmlDogs", "");
    const alwCats = _.get(formValues, "alwCats", "");

    const alwPets = _.get(formValues, "alwPets", "");
    const accsPvtBthRm = _.get(formValues, "accsPvtBthRm", "");
    const pvtEntrnce = _.get(formValues, "pvtEntrnce", "");
    let stories = _.get(formValues, "stories", null);
    if (stories) {
      stories = stories === STORY_ANY ? "Any" : "Single Story Only";
    }
    return (
      <>
        {" "}
        <div className="col-6 pe-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Budget</div>
            <div className="musthave-content-text">
              {""} {budget != BUDGET_NOMIN_NOMAX ? budget + "/Month" : budget}
            </div>
          </div>
        </div>
        {ltSize && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot size</div>

              <div className="musthave-content-text">{ltSize}</div>
            </div>
          </div>
        )}
        {propertyType !== IS_PRIVATE_ROOM ? (
          <>
            <div className="col-6 pe-2">
              <div className="musthave-content-mobwrap mb-3">
                <div className="musthave-content-label">Garage spaces</div>
                <div className="musthave-content-text">{garrageSize}</div>
              </div>
            </div>
            {stories && stories != 0 && (
              <div className="col-6 ps-2">
                <div className="musthave-content-mobwrap mb-3">
                  <div className="musthave-content-label">Stories</div>

                  <div className="musthave-content-text">{stories}</div>
                </div>
              </div>
            )}
            {alwLargeDogs == true && alwLargeDogs != 0 && (
              <div className="col-6 pe-2">
                <div className="musthave-content-mobwrap mb-3">
                  <div className="musthave-content-label">
                    Allows large dogs
                  </div>

                  <div className="musthave-content-text">Yes</div>
                </div>
              </div>
            )}
            {alwSmlDogs == true && alwSmlDogs != 0 && (
              <div className="col-6 ps-2">
                <div className="musthave-content-mobwrap mb-3">
                  <div className="musthave-content-label">
                    Allows small dogs
                  </div>

                  <div className="musthave-content-text">Yes</div>
                </div>
              </div>
            )}
            {alwCats == true && alwCats != 0 && (
              <div className="col-6 pe-2">
                <div className="musthave-content-mobwrap mb-3">
                  <div className="musthave-content-label">Allows cats</div>

                  <div className="musthave-content-text">Yes</div>
                </div>
              </div>
            )}
            {adaAccess == true && adaAccess != 0 && (
              <div className="col-6 ps-2">
                <div className="musthave-content-mobwrap mb-3">
                  <div className="musthave-content-label">ADA Accessible</div>

                  <div className="musthave-content-text">Yes</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <> </>
        )}
      </>
    );
  } else if (adTypeBasedOnProperty === LOT_LAND) {
    let onSiteElectricity = _.get(formValues, "onSiteElec", "");
    if (onSiteElectricity === "true") {
      onSiteElectricity = "Yes";
    } else if (onSiteElectricity === "false") {
      onSiteElectricity = "No";
    } else {
      onSiteElectricity = null;
    }
    const lotTypeValue = _.get(formValues, "lotType", null);
    const lotType =
      lotTypeValue && (lotTypeValue === 1 ? RURAL : IN_CITY_LIMITS);

    const roadTypeValue = _.get(formValues, "roadType", null);
    const roadType =
      roadTypeValue && (roadTypeValue === 1 ? "City roads" : "Private drive");

    const waterSupplyValue = _.get(formValues, "waterSupply", "");
    const waterSupply =
      waterSupplyValue &&
      (waterSupplyValue === 1 ? "Domestic water supply" : "Drilled well");

    const wasteWtrSysValue = _.get(formValues, "wasteWtrSys", null);
    const wasteWater =
      wasteWtrSysValue && (wasteWtrSysValue === 1 ? "Sewer" : "Septic system");

    // const lotType = _.get(formValues, "lotTypeText", null);
    // const roadType = _.get(formValues, "rdTypText", null);
    // const waterSupply = _.get(formValues, "wSText", null);
    // const wasteWater = _.get(formValues, "wWSysText", null);
    return (
      <>
        {" "}
        <div className="col-6 pe-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Budget</div>
            <div className="musthave-content-text">
            {budget}
            </div>
          </div>
        </div>
        {ltSize && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot size</div>

              <div className="musthave-content-text">{ltSize}</div>
            </div>
          </div>
        )}
         {lotType && (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot Type</div>

              <div className="musthave-content-text">{lotType}</div>
            </div>
          </div>
        )}
      {roadType && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Road Type</div>

              <div className="musthave-content-text">{roadType == "" && _.get(formValues, "isPrvtDrInst", "") == ""
                    ? "Any"  : ""} {roadType}
                  {_.get(formValues, "isPrvtDrInst", "") ? "," : ""}
                  {_.get(formValues, "isPrvtDrInst", "") ? "Installed" : ""}</div>
            </div>
          </div>
        )}
           {onSiteElectricity && (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Electricity</div>

              <div className="musthave-content-text">{onSiteElectricity}</div>
            </div>
          </div>
        )}
         {waterSupply && (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Water Supply</div>

              <div className="musthave-content-text"> {waterSupply == "" &&
                  _.get(formValues, "isDrldWelInst", "") == ""
                    ? "Any"
                    : ""}
                  {waterSupply}
                  {_.get(formValues, "isDrldWelInst", "") ? ", Installed" : ""}</div>
            </div>
          </div>
        )}
         {wasteWater && (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Wastewater</div>

              <div className="musthave-content-text"> {wasteWater == "" &&
                  _.get(formValues, "isSepTankInst", "") == "" &&
                  _.get(formValues, "isSepTankAppr", "") == ""
                    ? "Any"
                    : ""}
                  {wasteWater}
                  {_.get(formValues, "isSepTankInst", "") ? ", Installed" : ""}
                  {_.get(formValues, "isSepTankAppr", "") ? ", Approved" : ""}</div>
            </div>
          </div>
        )}
      </>
    );
  } else if (adTypeBasedOnProperty === SHORT_RENT) {
    let noBathRoom = _.get(formValues, "noBathRm", "") || "Any";
    let noBedRoom = _.get(formValues, "noBedRm", "") || "Any";
    let noBeds = _.get(formValues, "noOfBeds", "") || "Any";
    if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
    if (noBeds && noBeds !== "Any") noBeds = noBeds + "+";

    const alwPets = _.get(formValues, "alwPets", "");
    const pvtEntrnce = _.get(formValues, "pvtEntrnce", "");
    const accsPvtBthRm = _.get(formValues, "accsPvtBthRm", "");

    let Dates = "";
    if (
      _.get(formValues, "startDate", "") &&
      _.get(formValues, "endDate", "")
    ) {
      Dates =
        formateDate(_.get(formValues, "startDate", ""), "mm/dd/yyyy") +
        " - " +
        formateDate(_.get(formValues, "endDate", ""), "mm/dd/yyyy");
    } else {
      Dates =
        durationForFlexibleDate(_.get(formValues, "duration", "")) +
        " in " +
        monthNameForFlexibleDate(_.get(formValues, "month", ""));
    }

    return (
      <>
        {" "}
        <div className="col-6 pe-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Budget</div>
            <div className="musthave-content-text">
            {budget != BUDGET_NOMIN_NOMAX ? budget + "/Night" : budget}
            </div>
          </div>
        </div>
        {propertyType !== IS_PRIVATE_ROOM ? (
              <>
          {/* <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Bedrooms</div>
              <div className="musthave-content-text">{noBedRoom}</div>
            </div>
          </div>
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Beds</div>
              <div className="musthave-content-text">{noBeds}</div>
            </div>
          </div>
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Bedrooms</div>
              <div className="musthave-content-text">{noBedRoom}</div>
            </div>
          </div>
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Baths</div>
              <div className="musthave-content-text">{noBathRoom}</div>
            </div>
          </div> */}
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Dates</div>
              <div className="musthave-content-text">{Dates}</div>
            </div>
          </div>
          {adaAccess == true && adaAccess != 0 && (
            <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">ADA Accessible</div>
              <div className="musthave-content-text">Yes</div>
            </div>
          </div>
          )}
          {alwPets == true && adaAccess != 0 && (
            <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Pets allowed</div>
              <div className="musthave-content-text">Yes</div>
            </div>
          </div>
          )}
          </>
            ) : (
              <>
              <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Dates</div>
              <div className="musthave-content-text">{Dates}</div>
            </div>
          </div>
          {adaAccess == true && adaAccess != 0 && (
            <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">ADA Accessible</div>
              <div className="musthave-content-text">Yes</div>
            </div>
          </div>
          )}
          {alwPets == true && adaAccess != 0 && (
            <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Pets allowed</div>
              <div className="musthave-content-text">Yes</div>
            </div>
          </div>
          )}
          {accsPvtBthRm == true && accsPvtBthRm != 0 && (
            <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Access to private bathroom</div>
              <div className="musthave-content-text">Yes</div>
            </div>
          </div>
          )}
          {pvtEntrnce == true && pvtEntrnce != 0 && (
            <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Private entrance</div>
              <div className="musthave-content-text">Yes</div>
            </div>
          </div>
          )}
              </>
            )}
     
      </>
    );
  }
};

export const SquareFeet = ({ formValues }) => {
  let sqFt = "";

  // const maxLtSz = setLotSizeValue(_.get(formValues, "maxLtSz", null));
  const sqFtMin = setSqftValue(
    removeDecimalZeros(_.get(formValues, "sqFtMin", null))
  );
  const sqFtMax = setSqftValue(
    removeDecimalZeros(_.get(formValues, "sqFtMax", null))
  );

  if (sqFtMin && sqFtMax) {
    sqFt = `${sqFtMin} - ${sqFtMax}`;
  } else if (!sqFtMin && sqFtMax) {
    sqFt = `No min - ${sqFtMax}`;
  } else if (sqFtMin && !sqFtMax) {
    sqFt = `${sqFtMin} - No max`;
  } else {
    // sqFt = `No min - No max`;
    sqFt = `Any`;
  }
  return sqFt;
};
