import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextArea from "../Common/TextArea";
import {
  capitalizeFirstString,
  getLocalStorage,
  trimString,
} from "../../helpers/common";
import { USER_INFO_KEY } from "../../constants/common";
import { handleTextBoxChange } from "../../actions/common";

const ReasonForNotFitModal = (props) => {
  const {
    showNotFirReasnPopup,
    onHide,
    reasonPopupTitle,
    reasonPopupDesc,
    introductionId,
    updateToNotFit,
    name
  } = props;
  const intitalCheckListStates = {
    locNotFit: false,
    notMyStyle: false,
    price: false,
    size: false,
    other: false
  }
  const [notFitReasons, setNotFitReasons] = useState(intitalCheckListStates);
  const [isSubmitDisabled, setSubmitDisable] = useState(false);
  const [updateMarkNotFitAPICalled, setupdateMarkNotFitAPICalled] = useState(false);
  const [detailsCount, setDetailsCount] = useState(0);
  const [reasonDetails, setDetails] = useState('');
  useEffect(() => {
    if (!updateMarkNotFitAPICalled) {
      if (
        _.get(notFitReasons, "locNotFit", false) ||
        _.get(notFitReasons, "notMyStyle", false) ||
        _.get(notFitReasons, "price", false) ||
        _.get(notFitReasons, "size", false) ||
        _.get(notFitReasons, "other", false) ||
        reasonDetails
      ) {
        setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    }
    setDetailsCount(reasonDetails && reasonDetails.length ? reasonDetails.length : 0);
  }, [notFitReasons, reasonDetails]);

  const handleDescriptionChange = (e) => {
    const value = capitalizeFirstString(handleTextBoxChange(e));
    if (trimString(value) !== "") {
      setDetailsCount(value.length);
      setDetails(value);
    } else {
      setDetails('');
    }
  };
  const checkListOnchnge = (e) => {
    const key = e.target.id;
    setNotFitReasons((prevState) => ({
      ...prevState,
      [key]: e.target.checked,
    }));
  };



  const buildPayload = (isSkip) => {
    const checkLists = isSkip ? intitalCheckListStates : notFitReasons
    const description = isSkip ? '' : reasonDetails
    return {
      IntroductionId: introductionId,
      Location_Not_Right: _.get(checkLists, 'locNotFit', ''),
      Not_my_Style: _.get(checkLists, 'notMyStyle', ''),
      Price: _.get(checkLists, 'price', ''),
      Size: _.get(checkLists, 'size', ''),
      Other: _.get(checkLists, 'other', ''),
      Reason_Text: description
    }
  }

  const handleButtonClick = async (isSkip = false) => {
    const reqPayload = buildPayload(isSkip)
    setSubmitDisable(true);
    setupdateMarkNotFitAPICalled(true)
    await updateToNotFit(reqPayload, isSkip)
  };
  const checkListArray = [
    { text: "Location wasn’t right for me", id: "locNotFit" },
    { text: "Not my style", id: "notMyStyle" },
    { text: "Price", id: "price" },
    { text: "Size", id: "size" },
    { text: "Other", id: "other" },
  ];
  return (
    <Modal
      show={showNotFirReasnPopup}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings modal-payment cancel-plan"
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => onHide()}
        ></button>
        <h3 className="text-start">
          Tell {name ? name : 'them'}  why their introduction wasn’t a good fit
          {/* Tell them why their introduction wasn’t a good fit */}
        </h3>
        <p className="desc">
          {/* This will help {name} send better, more accurate introductions in the future. */}
          This will help them send better, more accurate introductions in the future.
        </p>
        <p className="note">Reason for ending conversation:</p>
        <div className="modal-controls-wrap">
          {checkListArray.map((item, index) => {
            return (
              <label className="check-wrap mb-3">
                <input
                  type="checkbox"
                  name="check-confirm-option"
                  id={_.get(item, "id", "")}
                  onChange={checkListOnchnge}
                />
                <span>{_.get(item, "text", "")}</span>
              </label>
            );
          })}

        </div>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap">
              <TextArea
                className="form-control txt-area"
                errorMessage=""
                value={reasonDetails}
                maxLength={500}
                placeholder="Reason"
                onChange={(e) => {
                  handleDescriptionChange(e);
                }}
              />
              <div className="des-count mb-4">
                <span>{detailsCount}</span>/<span>500</span>
              </div>
            </div>
          </Col>
        </Row>

        <div className="modal-btn-wrap">
          <Button className="btn btn-tertiary me-2" onClick={() => handleButtonClick(true)}>
            Skip
          </Button>
          <Button
            variant="primary"
            onClick={() => handleButtonClick()}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ReasonForNotFitModal;
