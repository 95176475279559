import React, { useState, useEffect } from "react";
import { getPropertyTypes } from "../../services/commonServices";
import { convertToNumber } from "../../helpers/validations";
import {
  IS_ENTIRE_SPACE,
  PRIVATE_ROOM_TYPE,
  IS_PRIVATE_ROOM,
  ENTIRE_SPACE_TYPE,
  IS_LAND,
  WANT_AD_KEY,
} from "../../constants/common";
import { usePrevious } from "../../hooks/usePrevious";
import { handleRadioButonChange } from "../../actions/common";
import PropCollapse from "./PropertyCollapse";
import { Accordion } from "react-bootstrap";
import { checkArrayisEqual, getLocalStorage } from "../../helpers/common";
const AddPropertyTypeRent = (props) => {
  const { handleFormValues, formValues, setDisableSave, clearPreviousStates, isEdit} =
    props;

  const propertyType = _.get(formValues, "prprtyType", null);
  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const propertyTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "prprtyType", null);
  const prptySubTypesInSession = _.get(getLocalStorage(WANT_AD_KEY), "prptySubTypes", []);

  const [propertyList, setPropertyList] = useState([]);
  const [propertySubItems, setPropertySubItems] = useState([]);
  const [propertySubItemsEP, setPropertySubItemsEP] = useState([]);
  const [propertySubItemsPS, setPropertySubItemsPS] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState();

  useEffect(() => {
    if (propertyType && !isEdit) {
      if (
        convertToNumber(propertyType) !== IS_ENTIRE_SPACE &&
        convertToNumber(propertyType) !== IS_PRIVATE_ROOM
      ) {
        setDisableSave(false);
      }
    } else {
      const isDisable = true;
      setDisableSave(isDisable);
    }
    getProperiesSubItems(ENTIRE_SPACE_TYPE);
    getProperiesSubItems(PRIVATE_ROOM_TYPE);
    handlePropertySubTypes();
    const typeOfAd = "rent"; //adType === BUY ? "buy" : "rent";
    getProperties(typeOfAd);
  }, []);

  const getProperiesSubItems = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          if (adType === ENTIRE_SPACE_TYPE) {
            setPropertySubItemsEP(propertyTypes);
          } else if (adType === PRIVATE_ROOM_TYPE) {
            setPropertySubItemsPS(propertyTypes);
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getProperties = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handlePropertySubTypes = () => {
    if (
      convertToNumber(propertyType) === IS_ENTIRE_SPACE ||
      convertToNumber(propertyType) === IS_PRIVATE_ROOM
    ) {
      if (prptySubTypes && prptySubTypes.length > 0) {
        // setDisableSave(false);
        if(isEdit && checkArrayisEqual(prptySubTypes,prptySubTypesInSession)){
          const isDisabled = true;
          setDisableSave(isDisabled);
        }
        else{
          const isDisabled = false;
          setDisableSave(isDisabled);
        }
      } else {
        const isDisabled = true;
        setDisableSave(isDisabled);
      }
      if (
        prptySubTypes.length !== 0 &&
        propertySubItems.length === prptySubTypes.length
      ) {
        setIsSelectAll(true);
      }
    }
  };

  const previousValue = usePrevious(propertyType);

  const handlePropertyClick = (typeText, value) => {
    const isDisabled = true;
    setDisableSave(isDisabled);
    setIsSelectAll(false);
    if (value) {
      if (convertToNumber(value) === IS_ENTIRE_SPACE) {
        getProperiesSubItems(ENTIRE_SPACE_TYPE);
      } else if (convertToNumber(value) === IS_PRIVATE_ROOM) {
        getProperiesSubItems("privateroom");
      } else {
        if(isEdit && propertyTypeInSession === value){
          setDisableSave(true);
        }
        else {setDisableSave(false);}
        setPropertySubItems([]);
      }
      clearPreviousSelectedValues(value);
      if(convertToNumber(value)===IS_LAND){
        handleFormValues({ prprtyType: convertToNumber(value),  prptyTypeTxt: typeText, prptySubTypes: [convertToNumber(value)]});
      }
      else{handleFormValues({ prprtyType: value, prptyTypeTxt: typeText });}
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    if (previousValue) {
      const isClearPrevious =
        (convertToNumber(previousValue) === IS_LAND &&
          convertToNumber(currentSelectedValue) !== IS_LAND) ||
        (convertToNumber(previousValue) !== IS_LAND &&
          convertToNumber(currentSelectedValue) === IS_LAND) ||
        (convertToNumber(previousValue) === IS_ENTIRE_SPACE &&
          convertToNumber(currentSelectedValue) === IS_PRIVATE_ROOM) ||
        (convertToNumber(previousValue) === IS_PRIVATE_ROOM &&
          convertToNumber(currentSelectedValue) === IS_ENTIRE_SPACE);
      if (isClearPrevious) {
        clearPreviousStates();
      }
    }
  };

  const getSubTypes = (typeId) => {
    if (typeId === 10) {
      return propertySubItemsEP;
    } else if (typeId === 11) {
      return propertySubItemsPS;
    } else return null;
  };

  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const Img = _.get(pt, "Img", "");
        const Desc = _.get(pt, "Description", "");
        const subTypes = getSubTypes(typeId);

        return (
          <>
            <div className={propertyType && propertyType!==typeId ?"accordionWrap disabled" : "accordionWrap"}>
              <PropCollapse
                typeText={typeText}
                description={Desc}
                typeId={typeId}
                handlePropertyClick={handlePropertyClick}
                propertySubItems={subTypes}
                handlePropertySubItemClick={handlePropertySubItemClick}
                prptySubTypes={prptySubTypes}
                propertyType={propertyType}
                eventKey={typeId.toString()}
              />
            </div>
          </>
        );
      });

    return List;
  };

  const handlePropertySubItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      // setDisableSave(false);
      if (_.includes(prptySubTypes, convertToNumber(value))) {
        subItemArray = prptySubTypes.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {
        subItemArray = [...prptySubTypes, convertToNumber(value)];
      }
      if(subItemArray?.length<=0){
        setDisableSave(true)
      }else if(isEdit && checkArrayisEqual(subItemArray, prptySubTypesInSession))
      {        
        setDisableSave(true);
      }
      else{
        setDisableSave(false)
      }
      handleFormValues({ prptySubTypes: subItemArray });
    }
  };

  return (
    <>
      <div>
        <Accordion
          className="mb-3"
          defaultActiveKey={propertyType && [propertyType.toString()]}
        >
          {loadPropertyTypes()}
        </Accordion>
      </div>
    </>
  );
};

export default AddPropertyTypeRent;
