export const wantAdPageContents = {
    step_1 : {
        title : 'Let’s get started!',
        description : 'Do you want to create a new Want-Ad or pick up where you left off?',
        skipButtonEnable : false,
        completedPercentage : 0,
        exitButtonText : 'Exit',
        backButtonEnable : false
    },
    step_2 : {
        title : 'Are you looking to Buy or Rent?',
        description : '',
        skipButtonEnable : false,
        completedPercentage : 7,
        exitButtonText : 'Save & Exit',
        backButtonEnable : false
    },
    BUY : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 7,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where do you want to buy?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 18,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When are you planning to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 28,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 38,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves in your PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 48,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your PROPERTY_TYPE?',
            description : "Select up to 16.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "What's the budget for your PROPERTY_TYPE?",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_10 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    INVESTOR_BUY : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 7,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where do you want to buy?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 18,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When are you planning to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 28,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 38,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves in your PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 48,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your PROPERTY_TYPE?',
            description : "Select up to 16.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "List the financing aspects of your purchase.",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : 'What type of property purchase will this be?',
            description : '',
            skipButtonEnable : true,
            exitButtonText : 'Exit',
            completedPercentage : 80,
            backButtonEnable : true
        },        
        step_10 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 90,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_11 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    INVESTOR_BUY_INVESTMENT : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 7,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where do you want to buy?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 18,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When are you planning to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 28,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 38,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves in your PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 48,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your PROPERTY_TYPE?',
            description : "Select up to 16.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "List the financing aspects of your purchase.",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : 'What type of property purchase will this be?',
            description : '',
            skipButtonEnable : true,
            exitButtonText : 'Exit',
            completedPercentage : 80,
            backButtonEnable : true
        },
        step_10 : {
            title : 'What kind of investment criteria are you open to?',
            description : '',
            skipButtonEnable : true,
            exitButtonText : 'Exit',
            completedPercentage : 90,
            backButtonEnable : true
        },
        step_11 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_12 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    LTR : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where do you want to rent?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 15,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When would you like to move in?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 25,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to rent?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 35,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 50,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your rental?',
            description : "Select up to 16.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "What's the budget PROPERTY_TYPE?",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_10 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    STR : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where are you looking to stay?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 15,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When are you looking to stay?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 25,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to rent?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 35,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves for your stay?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 50,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your rental?',
            description : "Select up to 16.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "What's the nightly budget for your stay?",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_10 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    BUY_LAND : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where do you want to buy?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 15,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When are you planning to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 25,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to buy?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 35,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves in your PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 50,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your PROPERTY_TYPE?',
            description : "Select up to 10.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "What's the budget for your PROPERTY_TYPE?",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_10 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    LTR_LAND : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'When do you want to rent?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 15,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When would you like to move in?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 25,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to rent?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 35,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves in your PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 50,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your PROPERTY_TYPE?',
            description : "Select up to 10.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "What's the budget for your PROPERTY_TYPE?",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_10 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },
    STR_LAND : {
        step_1 : {
            title : 'Let’s get started!',
            description : 'Do you want to create a new Want-Ad or pick up where you left off?',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Exit',
            backButtonEnable : false
        },
        step_2 : {
            title : 'Are you looking to Buy or Rent?',
            description : '',
            skipButtonEnable : false,
            completedPercentage : 0,
            exitButtonText : 'Save & Exit',
            backButtonEnable : false
        },
        step_3 : {
            title : 'Where do you looking to stay?',
            description : "Be as specific as you'd like to here. Specifying neighborhood and school are optional.",
            skipButtonEnable : false,
            completedPercentage : 15,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_4 : {
            title : 'When are you looking to stay?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 25,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_5 : {
            title : 'Which type of property are you looking to rent?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 35,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_6 : {
            title : 'What are the must-haves in your PROPERTY_TYPE?',
            description : "",
            skipButtonEnable : false,
            completedPercentage : 50,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_7 : {
            title : 'What would be "Nice-to-have" in your PROPERTY_TYPE?',
            description : "Select up to 10.",
            skipButtonEnable : true,
            completedPercentage : 60,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_8 : {
            title : "What's the budget for your PROPERTY_TYPE?",
            description : "",
            skipButtonEnable : false,
            completedPercentage : 70,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_9 : {
            title : "Give your ad some personality.",
            description : "Users who add a description to their ads are more likely to have higher quality introductions.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true
        },
        step_10 : {
            title : "Ready to post?",
            description : "Confirm the details about your want-ad.",
            skipButtonEnable : false,
            completedPercentage : 100,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            continueButtonText : 'Post'
        },
    },

    EDIT:{
        // title:'Ready to save changes?'
        title:'Ready to post?'
    }
}

export const strengthenPageContents = {
    BUY_STRENGTHEN : {
        step_1 : {
            title : 'What type of property purchase will this be?',
            description : '',
            skipButtonEnable : true,
            exitButtonText : 'Exit',
            completedPercentage : 0,
            backButtonEnable : false
        },
        step_2 : {
            title : 'How are you financing this purchase?',
            description : '',
            skipButtonEnable : true,
            exitButtonText : 'Exit',
            backButtonEnable : true,
            completedPercentage : 100,
            continueButtonText : 'Complete'
        },
        step_3 : {
            title : "What's the budget for your [PROPERTY_TYPE]?",
            description : "",
            skipButtonEnable : false,
            exitButtonText : 'Exit',
            backButtonEnable : true,
            completedPercentage : 100
        }
    },
    LTR_STRENGTHEN : {
        step_1 : {
            title : 'What range is your credit score in?',
            description : '',
            skipButtonEnable : false,
            exitButtonDisable : true,
            completedPercentage : 100,
            backButtonEnable : true,
            backButtonText : 'Exit'
        }
    }
   
}

export const wantAdAmenities = {
    memberBuy : {
        wantAdId : '',
        userId : '',
        clientName : '',
        actvStep : '',
        cmpldPerctg : '',
        adType : '',
        location : '',
        startDate : '',
        endDate : '',
        dateType:'',
        duration : '',
        month : '',
        timeLine : '',
        moveInTimeLine : '',
        tlText : '',
        prprtyType : '',
        prptyTypeTxt : '',
        prptySubTypes : [],
        noBedRm : '',
        noBathRm :'',
        noOfBeds:'',
        sqFtMin : '',
        sqFtMax : '',
        grgSpce : '',
        minLtSz : '',
        maxLtSz :'',
        yrBltMin : '',
        yrBltMax : '',
        stories: '',
        yearBuiltOption: '',
        isNwCnstctn : '',
        minBdgt : '',
        maxBdgt : '',
        adTitle : '',
        adDesc : '',
        acreageMin : '',
        acreageMax : '',
        lotType :'',
        lotTypeText : '',
        roadType : '',
        rdTypText : '',
        isPrvtDrInst : '',
        onSiteElec :'',
        waterSupply : '',
        wSText : '',
        isDrldWelInst :'',
        wasteWtrSys :'',
        wWSysText : '',
        isSepTankInst : '',
        isSepTankAppr : '',
        nice2Have : [],
        alwLrgDogs : '',
        alwSmlDogs : '',
        alwPets : '',
        alwCats : '',
        adaAccess : '',
        accsPvtBthRm : '',
        pvtEntrnce: '',
        mnLtSzId :'',
        mnLtSzTxt:'',
        mxLtSzId :'',
        mxLtSzTxt:'',
        neighborHood:'',
        highSchool: ''
    },
    strengthenAd : {
        adType : '',
        userId : '',
        wantAdId : '',
        purchaseType : '',
        purchaseText : '',
        is1031Exchange :'',
        pFinance :'',  
        pFinanceText :'',
        isPreApprLoan : '',
        minBdgt : '',
        maxBdgt : '',
        creditScore :''
    }
    
}

export const EXACT_MOVE_DATE_TYPE = 11;
export const LTR_CAL = 'ltr_cal';
export const lotSizeMaxMissing = 'lotSizeMaxMissing'
export const lotSizeMinMissing=  'lotSizeMinMissing'

export const sqFtMaxMissing = 'sqFtMaxMissing'
export const sqFtMinMissing=  'sqFtMinMissing'

export const lotSizeMinGreater= 'lotSizeMinGreater'
export const lotSizeMaxLesser= 'lotSizeMaxLesser'
export const yearBuiltRangeMissing= 'yearBuiltRangeMissing'
export const yearBuiltMaxMissing= 'yearBuiltMaxMissing'
export const yearBuiltMinMissing= 'yearBuiltMinMissing'
export const yrMinInValid= 'yrMinInValid'
export const yrMaxInValid= 'yrMaxInValid'
export const yrBltMaxLesser= 'yrBltMaxLesser'
export const yrBltMinGreater= 'yrBltMinGreater'
export const budgetMinError = 'budgetMinError'
export const budgetMinValueError = 'budgetMinValueError'
export const budgetMaxError = 'budgetMaxError'
export const budgetMinMissing = 'budgetMinMissing'
export const budgetMaxMissing=  'budgetMaxMissing'
export const dateError = 'dateError'
export const sqftMaxLesser = 'sqftMaxLesser'

export const propertySubTypesItems = [
    {id : 1, text : 'house' },
    {id : 2, text : 'condo/apartment' },
    {id : 3, text : 'town home/attached home' },
    {id : 8, text : 'guest house' },
    {id : 5, text : 'manufactured' },
    {id : 9, text : 'villa' }
]
   






    

