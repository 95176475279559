import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function getUserDetails(requestPayload) {
    return await apiService.apiCall(endPoints.GET_USER_DETAILS, "GET", requestPayload,'useDetails');
}

export async function changeUserName(payload) {
  return await apiService.apiCall(endPoints.CHANGE_USER_NAME, "POST", payload);
}

export async function saveNotifications(payload) {
  return await apiService.apiCall(endPoints.SAVE_NOTIFICATION_SETTINGS, "POST", payload);
}

export async function getNotifications(payload) {
  return await apiService.apiCall(endPoints.GET_NOTIFICATION_SETTINGS, "GET", payload);
}

export async function deleteUser(payload) {
  return await apiService.apiCall(endPoints.DELETE_USER+ `?email=${payload.email}`, "PUT", {});
}

export async function changeEmailAddress(payload) {
  return await apiService.apiCall(endPoints.CHANGE_EMAIL_ADDRESS+ `?newEmail=${payload.newEmail}`, "PUT", {});
}

export async function editProfilePremiumAgent(payload) {
  return await apiService.apiCall(endPoints.EDIT_PREMIUM_AGENT_PROFILE, "POST", payload);
}

export async function getUserPrivileges(payload) {
  return await apiService.apiCall(endPoints.GET_USER_PRIVILEGES, "GET", payload);
} 

export async function getUserStatistics(payload) {
  return await apiService.apiCall(endPoints.GET_USER_STATISTICS, "GET", payload);
} 
export async function getUserPlanDetails(payload) {
  return await apiService.apiCall(endPoints.GET_USER_PLAN_DETAILS, "GET", payload);
}

export async function savePreLaunchInterest(payload) {
  return await apiService.apiCall(endPoints.SAVE_PRELAUNCH_INTEREST, "POST", payload);
} 