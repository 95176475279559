import React, { useState, useEffect, useRef } from "react";
import RangeSliderMobile from "../RangeSliderMobile";
import TextBox from "../../../components/Common/TextBox";
import ProgressBar from "../ProgressBar";
import { usePrevious } from "../../../hooks/usePrevious";
import {
  thousandSeperatedValue,
  replaceComma,
  checkIsNull,
  setLocalStorage,
  getLocalStorage,
  setBodyBackgroundWhite,
  capitalizeFirstString,
  trimString,
} from "../../../helpers/common";
import {  ERROR_TEXT, STRENGTHEN_WANT_AD_KEY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import AgentCompensation from "./AgentCompensation";
import TextAreaMob from "../TextAreaMob";
import { handleTextBoxChange } from "../../../actions/common";
import { textValidations } from "../../../helpers/validations";

const AddCompensation = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    setCompletedPercentage,
    isAgent,
    isEdit,
  } = props;

 
  const [disableSave, setDisableSave] = useState(true); 
  const agentCompenation = _.get(formValues, "agentCompenation", null);
  const agentCompenationInSession = _.get(getLocalStorage(WANT_AD_KEY), "agentCompenation", "");
  const otherConcessionsInSession = _.get(getLocalStorage(WANT_AD_KEY), "otherConcessions", "");
  const compenationType = parseInt(_.get(formValues, "compenationType", null));
  const otherConcessions = _.get(formValues, "otherConcessions", null);
  const compenationTypeInSession = parseInt(_.get(getLocalStorage(WANT_AD_KEY), "compenationType", ""));  
  const [compensationError, setCompensationError] = useState("");
  const [otherConcessionError, setOtherConcessionError] = useState("");
  const [descCursorPos, setDescCursorPos] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const OtherConcessionRef = useRef();

  useEffect(()=>{
    let disable = true;
    if(isEdit && (compenationTypeInSession===compenationType && agentCompenationInSession === agentCompenation) && otherConcessionsInSession === otherConcessions){
      disable =  true;
    }    
    else {
      if(!compenationType){
        disable = true
      }
      else {
        if(compenationType ==1 || compenationType ==2){
          if(agentCompenation){
              disable =  false}
          else {disable = true}
        }
        else if(otherConcessionError !== ""){
          disable = true
        }
        else{
          disable = false
        }
    }
  }
  setDisableSave(disable)
  }, [agentCompenation, compenationType, compensationError, otherConcessions, otherConcessionError])

  useEffect(() => {
    setBodyBackgroundWhite()    
  }, []); 

  const validate = () => {    
    setCompensationError("");
    let errorExist = false;
    if(!compenationType) {
      return false
    }   
    else {
      if(compenationType == 1 || compenationType == 2){
        if (!agentCompenation) {
          setCompensationError(compenationType == 1 ? "Enter percentage." : "Enter flat fee.");
          errorExist = true;
        }
      }      
    }
    if(otherConcessions && !textValidations(otherConcessions)){
      setOtherConcessionError(ERROR_TEXT);
      errorExist = true;
    }
    if (errorExist) {
      return false;
    }
    return true;
  }

  const sentenceCase = (input, lowercaseBefore) => {
    setOtherConcessionError("")
    input = input === undefined || input === null ? "" : input;
    if (lowercaseBefore) {
      input = input.toLowerCase();
    }
    return input
      .toString()
      .replace(/(^|\. *)([a-z])/g, function (match, separator, char) {
        return separator + char.toUpperCase();
      });
  };

  const handleOtherOncessionChange = (e) => {
    let value = capitalizeFirstString(handleTextBoxChange(e));
    console.log("value", value)

    value = sentenceCase(value);
    setDescCharCount(value.length);
    if (trimString(value) !== "") {
      handleFormValues({ otherConcessions: value });
      setDescCursorPos(e.target.selectionStart)
    } else {
      handleFormValues({ otherConcessions: "" });
    }
  };



  return (
    <>
    <div className="gitcha-main-wrapper pb-102 bg-white">
      <div className="content-wrapper content-wrapper-h-100-percent">
        <ProgressBar
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isEdit={isEdit}
          isFromAddCompensation={true}
        />
        <div className="inner-layout-content h-100 bg-white">
          <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white h-100">
            <div className="top-three-comp-btm-sec mt-0 mb-4">
              <h6>Compensation</h6>
              <h2>Requested Concessions From Seller </h2>
              <p className="fnt14 pt-3 ">
              After you select your requested compensation from the seller, you’re ready to post!
              </p>
            </div>              
             <>
                <AgentCompensation 
                  handleFormValues={handleFormValues}
                  agentCompenation={agentCompenation}
                  compenationType={compenationType}
                  compensationError={compensationError}
                  />
                  <div className="col-12 ">
                  <div className="mb-4 position-relative">
                    <div className="mob-fld-wrap">
                      <label className="form-label">
                      Other Concessions (Optional)
                      </label>
                    </div>
                    {/* <div className="search_field_input  mb-0 input-group"> */}
                      <TextAreaMob
                        class="form-control txtAreaStyle"
                        value={otherConcessions ? otherConcessions : ""}
                        errorMessage={otherConcessionError}
                        maxLength={500}
                        placeholder="Rate buy down, closing costs, etc."
                        onChange={(e) => {
                          handleOtherOncessionChange(e);
                        }}
                        txtRef={OtherConcessionRef}
                      ></TextAreaMob>
                    {/* </div> */}
                    <div className="text-end fnt10 pt-1 des-count-mob des-count-157">{descCharCount}/500</div>
                  </div>
                </div>
             </>  

          </div>
        </div>

     
        
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav ">
          <div className="btn-wrap text-center pb-40 pt-0">
            <button
              type="button"
              className="btn btn-primary w-100 mb-0"
              disabled={disableSave}
              onClick={() => {
                if(validate()){
                  if(isAgent){
                    if(completedPercentage>70){
                      setCompletedPercentage(completedPercentage)
                    }
                    else {
                      setCompletedPercentage(70)
                    }
                  }
                  else {
                    if(completedPercentage>85){
                    setCompletedPercentage(completedPercentage)
                    }
                    else {
                      setCompletedPercentage(85)
                    }
                  }
                  setTimeout(()=>{
                    setLocalStorage(WANT_AD_KEY, getLocalStorage(WANT_AD_KEY_TEMP));
                    if(!isEdit){setUIKey("readyToPost")}
                    else{
                      setUIKey(null)
                    }

                  }, 500)
                  

                }                
              }}
            >
              {isEdit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddCompensation;
