import { createSlice } from "@reduxjs/toolkit";
import { fetchPropertyList, fetchInventoryDetailsById } from "../actions/inventory";

const initialState = {
    loading: false,
    propertyList: {},
    error: "",
    inventoryListLoading : false,
    inventoryDetails : {},
    inventoryListError : "",
    
};

const inventory = createSlice({
    name: "inventory",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPropertyList.pending, (state, action) => {
            state.loading = true;
            state.propertyList = [];
            state.error = "";
        });
        builder.addCase(fetchPropertyList.fulfilled, (state, action) => {
            state.loading = false;
            state.propertyList = action.payload.result;
            state.error = "";
        });

        builder.addCase(fetchPropertyList.rejected, (state, action) => {
            state.loading = false;
            state.propertyList = [];
            state.error = action.error.message;
        });

        builder.addCase(fetchInventoryDetailsById.pending, (state, action) => {
            state.inventoryListLoading = true;
            state.inventoryListError = "";
        });
        builder.addCase(fetchInventoryDetailsById.fulfilled, (state, action) => {
            state.inventoryListLoading = false;
            state.inventoryDetails = action.payload.result;
            state.inventoryListError = "";
        });

        builder.addCase(fetchInventoryDetailsById.rejected, (state, action) => {
            state.inventoryListLoading = false;
            state.inventoryDetails = {};
            state.inventoryListError = action.error.message;
        });

    }
});

export default inventory.reducer;
