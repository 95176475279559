import Form from "react-bootstrap/Form";
import { capitalizeString, handleTextBoxChange } from "../../../actions/common";
import {
  removeDecimalZerosFromTextField,
  replaceComma,
  thousandSeperatedValue,
} from "../../../helpers/common";
import { removeNegativeInput } from "../../../helpers/validations";
import { yrMaxInValid } from "../../../constants/createWantAd";
import { useState, useEffect } from "react";
import {
  INVENTORY_RT_PRVT_DRIVE,
  INVENTORY_WS_DRLLD_WELL,
  INVENTORY_WWS_SEPTIC_SYS,
  YES_NO_OPTIONS,
} from "../../../constants/common";
import SelectDropDown from "../../../components/Common/SelectDropDown"
import MobileDropdownSelect from "../MobileDropdownSelect";

const MustHavesLand = (props) => {
  const {
    updateValuesToState,
    handleFormValues,
    acreage,
    lotType,
    roadType,
    isPrvtDrInst,
    isPropertyExist,
    isPowerAccess,
    isApprovedStrctr,
    isInFloodPlain,
    waterSuply,
    isDrldWelAppr,
    isDrldWelInst,
    wasteWtrSys,
    isSepTankInst,
    isSepTankAppr,
    acre_Sqft
  } = props;

  const RT_PVT_DRIVE_INSTALL = "isPrvtDrInst";
  const WS_DRILLED_WELL_INSTALL = "isDrldWelInst";
  const WS_DRILLED_WELL_APPRRV = "isDrldWelAppr";
  const WWS_SEPT_TANK_INSTALL = "isSepTankInst";
  const WWS_SEPT_TANK_APPROV = "isSepTankAppr";
  const poweAccessSection = "isPowerAccess";
  const approvedStrctrSection = "isApprovedStrctr";
  const floodPlainSection = "isInFloodPlain";
  const lotSizeOptions = [
    {value: 1,  label: 'Acres'},
    {value: 2,  label: 'SQFT'}
  ]

  const handleAcreageChanges = (e) => {
    const value = handleTextBoxChange(e);
    updateValuesToState("acreage", replaceComma(value));
    updateValuesToState("acre_Sqft", acre_Sqft ? acre_Sqft : 1);
  };

  const handleLotSizeTypeSelection = (e) =>{
    const values =  _.get(e, "value", "");
    updateValuesToState("acre_Sqft", values);
  }

  const handleLotTypeChange = (selectedValue) => {
    updateValuesToState("lotType", selectedValue);
  };

  const handleRoadTypeChange = (selectedValue) => {
    let stateToBeUpdated = {};
    if (selectedValue === roadType) {
      stateToBeUpdated = { roadType: "", isPrvtDrInst: "" };
    } else {
      stateToBeUpdated = { roadType: selectedValue, isPrvtDrInst: "" };
    }
    handleFormValues(stateToBeUpdated);
  };

  const handleCheckBoxSelection = (e) => {
    const key = e.target.name;
    handleFormValues({ [key]: e.target.checked });
  };

  const handleBooleanOptionSelection = (selectedValue, section) => {
    switch (section) {
      case poweAccessSection:
        if (selectedValue === isPowerAccess) {
          updateValuesToState(poweAccessSection, "");
        } else {
          updateValuesToState(poweAccessSection, selectedValue);
        }
        break;
      case approvedStrctrSection:
        if (selectedValue === isApprovedStrctr) {
          updateValuesToState(approvedStrctrSection, "");
        } else {
          updateValuesToState(approvedStrctrSection, selectedValue);
        }
        break;
      case floodPlainSection:
        if (selectedValue === isInFloodPlain) {
          updateValuesToState(floodPlainSection, "");
        } else {
          updateValuesToState(floodPlainSection, selectedValue);
        }
        break;
    }
  };

  const handleWaterSupplyChange = (selectedValue) => {
    let stateToBeUpdated = {};
    if (selectedValue === waterSuply) {
      stateToBeUpdated = {
        waterSuply: "",
        isDrldWelInst: "",
        isDrldWelAppr: "",
      };
    } else {
      stateToBeUpdated = {
        waterSuply: selectedValue,
        isDrldWelInst: "",
        isDrldWelAppr: "",
      };
    }
    handleFormValues(stateToBeUpdated);
  };

  const handleWasteWaterChange = (selectedValue) => {
    let stateToBeUpdated = {};
    if (selectedValue === wasteWtrSys) {
      stateToBeUpdated = {
        wasteWtrSys: "",
        isSepTankAppr: "",
        isSepTankInst: "",
      };
    } else {
      stateToBeUpdated = {
        wasteWtrSys: selectedValue,
        isSepTankAppr: "",
        isSepTankInst: "",
      };
    }
    handleFormValues(stateToBeUpdated);
  };

  const loadBooleanOptioLists = (section) => {
    const itemList = YES_NO_OPTIONS;
    let isCheckedYes = false;
    let isCheckedNo = false;
    switch (section) {
      case poweAccessSection:
        if (isPowerAccess === true || isPowerAccess === 1) {
          isCheckedYes = true;
        } else if (isPowerAccess === false || isPowerAccess === 0) {
          isCheckedNo = true;
        }
        break;
      case approvedStrctrSection:
        if (isApprovedStrctr === true || isApprovedStrctr === 1) {
          isCheckedYes = true;
        } else if (isApprovedStrctr === false || isApprovedStrctr === 0) {
          isCheckedNo = true;
        }
        break;
      case floodPlainSection:
        if (isInFloodPlain === true || isInFloodPlain === 1) {
          isCheckedYes = true;
        } else if (isInFloodPlain === false || isInFloodPlain === 0) {
          isCheckedNo = true;
        }
        break;
    }    

    return (
      <>
        <div className="col-6">
          <a
            className={
              isCheckedYes
                ? "blockBtn text-center active"
                : "blockBtn text-center"
            }
            onClick={(e) =>
              handleBooleanOptionSelection(
                _.get(itemList[0], "value", ""),
                section
              )
            }
          >
            {_.get(itemList[0], "text", "")}
          </a>
        </div>

        <div className="col-6">
          <a
            className={
              isCheckedNo
                ? "blockBtn text-center active"
                : "blockBtn text-center"
            }
            onClick={(e) =>
              handleBooleanOptionSelection(
                _.get(itemList[1], "value", ""),
                section
              )
            }
          >
            {_.get(itemList[1], "text", "")}
          </a>
        </div>
      </>
    );
  };

  const filterLotSizeArray = (value) => {
    if (lotSizeOptions && value) {
      const filterValue = lotSizeOptions.filter(function (lst) {
        return lst.value === value;
      });
      return filterValue;
    }
    return null;
  };

  return (
    <>
      <div className="pt-20">
        <p className="mt-0 mb-2 fnt12">DETAILS</p>
        <h2 className="mt-0 pt-0 mb-2 titleText">
          Does this information look right?
        </h2>
        <p className="fnt16">
          If anything doesn’t look accurate, update it with the correct
          information.
        </p>
      </div>
      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Lot Size*</h4>
          <div className="row">
            <div className="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  // type="text"
                  style={{"min-height": "47px",
                    "border-radius": "10px"}}
                  inputmode="numeric"
                  class="form-control"
                  value={
                    thousandSeperatedValue(
                      removeDecimalZerosFromTextField(acreage)
                    ) || ""
                  }
                  // disabled={!isPropertyExist}
                  onChange={(e) => {
                    handleAcreageChanges(e);
                  }}
                  placeholder="Total"
                />
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <MobileDropdownSelect
                  // disabled={!isPropertyExist}
                  name="colors3"
                  options={lotSizeOptions||[]}
                  className="mobile-select"
                  classNamePrefix="select"
                  value={filterLotSizeArray(acre_Sqft  ? acre_Sqft  : 1)}
                  onChange={(e) => handleLotSizeTypeSelection(e)}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform:
                        state.selectProps.menuIsOpen && "rotate(180deg)",
                    }),
                  }}  
                  errorClass="error-msg error-msg-height"
                  // errorMessage={lotSizeMinError}
                  // disabled={isPropertyDataExist ? false : true}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Lot Type *</h4>
          <div className="row">
            <div className="col-6">
              <a
                href={void 0}
                className={
                  lotType == 1
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={() => handleLotTypeChange(1)}
              >
                Rural
              </a>
            </div>
            <div className="col-6">
              <a
                href={void 0}
                className={
                  lotType == 2
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={() => handleLotTypeChange(2)}
              >
                In city limits
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> What type of road leads to your property?</h4>
          <div className="row">
            <div className="col-6">
              <a
                href={void 0}
                className={
                  roadType == 1
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={() => handleRoadTypeChange(1)}
              >
                City road
              </a>
            </div>
            <div className="col-6">
              <a
                href={void 0}
                className={
                  roadType == 2
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={() => handleRoadTypeChange(2)}
              >
                {" "}
                Private drive{" "}
              </a>
            </div>
          </div>

          {roadType === INVENTORY_RT_PRVT_DRIVE && (
            <div className="row">
              <div className="col-12 pt-4 ps-3">
                <label
                  class="form-check-label checkbox-label"
                  for="road-installed"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="road-installed"
                    name={RT_PVT_DRIVE_INSTALL}
                    onChange={handleCheckBoxSelection}
                    checked={isPrvtDrInst}
                  />
                  <span className=""> Installed</span>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Do you have power within 100’ of your property?</h4>
          <div className="row">{loadBooleanOptioLists(poweAccessSection)}</div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Water Supply</h4>
          <div className="row">
            <div className="col-6">
              <a
                href={void 0}
                className={
                  waterSuply === 1 || waterSuply === "1"
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={() => handleWaterSupplyChange(1)}
              >
                {" "}
                Domestic{" "}
              </a>
            </div>
            <div className="col-6">
              <a
                href={void 0}
                className={
                  waterSuply === 2 || waterSuply === "2"
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={() => handleWaterSupplyChange(2)}
              >
                Drill Well{" "}
              </a>
            </div>
          </div>

          {waterSuply === INVENTORY_WS_DRLLD_WELL && (
            <>
              <div className="row">
                <div className="col-12 pt-4 ps-3">
                  <label
                    class="form-check-label checkbox-label"
                    for="well-installed"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="well-installed"
                      name={WS_DRILLED_WELL_INSTALL}
                      onChange={handleCheckBoxSelection}
                      checked={isDrldWelInst}
                    />
                    <span className=""> Installed</span>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 pt-4 ps-3">
                  <label
                    class="form-check-label checkbox-label"
                    for="well-approved"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="well-approved"
                      name={WS_DRILLED_WELL_APPRRV}
                      onChange={handleCheckBoxSelection}
                      checked={isDrldWelAppr}
                    />
                    <span className=""> Approved</span>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Waste water system</h4>
          <div className="row">
            <div className="col-6">
              <a href={void 0}
               className={wasteWtrSys===1 || wasteWtrSys==="1" ? "blockBtn text-center active" : "blockBtn text-center"}
               onClick={()=>handleWasteWaterChange(1)}
               >
                {" "}
                Sewer{" "}
              </a>
            </div>
            <div className="col-6">
              <a href={void 0}
               className={wasteWtrSys===2 || wasteWtrSys==="2" ? "blockBtn text-center active" : "blockBtn text-center"}
               onClick={()=>handleWasteWaterChange(2)}>
                Septic{" "}
              </a>
            </div>
          </div>
          {wasteWtrSys === INVENTORY_WWS_SEPTIC_SYS && (
            <>
          <div className="row">
            <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="wws-installed"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="wws-installed"
                  name={WWS_SEPT_TANK_INSTALL}
                  onChange={handleCheckBoxSelection}
                  checked={isSepTankInst}
                />
                <span className=""> Installed</span>
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="wws-approved"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="wws-approved"
                  name={WWS_SEPT_TANK_APPROV}
                  onChange={handleCheckBoxSelection}
                  checked={isSepTankAppr}
                />
                <span className=""> Approved</span>
              </label>
            </div>
          </div>
          </>)}
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4>Is your property approved for a dwelling?</h4>
          <div className="row">
            {loadBooleanOptioLists(approvedStrctrSection)}
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4>Is your property on a flood plain?</h4>
          <div className="row">{loadBooleanOptioLists(floodPlainSection)}</div>
        </div>
      </div>
    </>
  );
};

export default MustHavesLand;
