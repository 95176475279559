import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
const DowngradeModal = (props) => {
  const { show, setDownGradeModal, expiryDate, handleDownGradeClick } = props;
  return (
    <Modal
      show={show}
   
      centered
      className="modalPopupstyle delete-popup"
    >
      <Modal.Body className="text-center">
        <h3>Cancel Premium and downgrade to Free plan</h3>
        <p>
          After {expiryDate}, you will lose access to paid features that come with
          the Premium plan.
        </p>
        <div></div>
        <div className="btn-wrap">
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={()=>handleDownGradeClick()}
          >
            Downgrade
          </button>
          <button
            type="button"
            className="btn btn-secondary w-100"
            onClick={()=>setDownGradeModal(false)}
          >
            Keep Premium
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DowngradeModal;
