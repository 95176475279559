import React, { useState, useEffect, useRef } from "react";
import { FormLabel } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import TextArea from "../Common/TextArea";
import { handleTextBoxChange } from "../../actions/common";
import { trimString } from "../../helpers/common";
import { textValidations } from "../../helpers/validations";
import { ERROR_TEXT } from "../../constants/common";
import ToolTip from "../Common/ToolTip";

const WantAdDescription = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    adTitle,
    adDescription,
    isEdit = false,
  } = props;

 
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const [titleError, setTitleError] = useState(null);
  const [descError, setDescError] = useState(null);
  const [titleCursorPos, setTitleCursorPos] = useState(0);
  const [descCursorPos, setDescCursorPos] = useState(0);
  const titleRef = useRef(null);
  const descRef = useRef(null);


  useEffect(() => {
    setTitleCharCount(adTitle && adTitle.length ? adTitle.length : 0);
    setDescCharCount(
      adDescription && adDescription.length ? adDescription.length : 0
    );
    let isTitleValid = true;
    let isDescValid = true;
    if (!adTitle) {
      isTitleValid = false;
    }
    if (trimString(adTitle) !== "") {
      isTitleValid = textValidations(adTitle);
      if (!isTitleValid) {
        setTitleError(ERROR_TEXT);
      }
    }
    if (trimString(adDescription) !== "") {
      isDescValid = textValidations(adDescription);
      if (!isDescValid) {
        setDescError(ERROR_TEXT);
      }
    }

    if (isDescValid && isTitleValid) {
      handleContinueButtonState();
    } else {
      disableContinueButton();
    }
  }, [adTitle, adDescription, titleError, descError]);

  const disableContinueButton = () => {
    const isDisabled = true;
    handleContinueButtonState(isDisabled);
  };

  const handleTitleChange = (e) => {
    const value = capitalizeFirstString(handleTextBoxChange(e))
    setTitleError(null);
    setTitleCharCount(value.length);
    if (trimString(value) !== "") {
      handleFormValues({ adTitle: value });
      setTitleCursorPos(e.target.selectionStart);
    } else {
      handleFormValues({ adTitle: "" });
    }
  };

  useEffect(() => {
    titleRef.current.setSelectionRange(titleCursorPos, titleCursorPos);
  }, [titleCursorPos]);

  useEffect(() => {
    descRef.current.setSelectionRange(descCursorPos, descCursorPos);
  }, [descCursorPos]);
  

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const sentenceCase = (input, lowercaseBefore) => {
    setDescError("")
    input = input === undefined || input === null ? "" : input;
    if (lowercaseBefore) {
      input = input.toLowerCase();
    }
    return input
      .toString()
      .replace(/(^|\. *)([a-z])/g, function (match, separator, char) {
        return separator + char.toUpperCase();
      });
  };

  const handleDescriptionChange = (e) => {
    setDescError(null);
    let value = capitalizeFirstString(handleTextBoxChange(e));
    value = sentenceCase(value);
    setDescCharCount(value.length);
    if (trimString(value) !== "") {
      handleFormValues({ adDesc: value });
      setDescCursorPos(e.target.selectionStart)
    } else {
      handleFormValues({ adDesc: "" });
    }
  };
  return (
    <div className={!isEdit ? "center-content-sec mw-524" : ""}>
      <div className="wantad-des-wrap overflow-hidden">
        <div className="position-relative ">
          <FormLabel>
            Title <span>*</span>
          </FormLabel>
          <ToolTip
            title={
              "In accordance with the Federal Fair Housing Act, we recommend not including personally identifiable information, such as race, gender, sexual preference or age. To learn more, <a href='https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations' target='_blank'>read about the Fair Housing Act here.</a>"
            }
          />
          <TextBox
            id="title"
            name="title"
            type="text"
            txtRef = {titleRef}
            value={adTitle ? adTitle : ""}
            errorMessage={titleError}
            maxLength={50}
            placeholder="Ex. Looking for dream home in city"
            onChange={(e) => {
              handleTitleChange(e);
            }}
          />
        
          
          <div className="des-count mb-4 des-count-fix">
            <span>{titleCharCount}</span>/<span>50</span>
          </div>
        </div>
        <div className="position-relative">
          <FormLabel>Description</FormLabel>
          <TextArea
            className="form-control txt-area"
            value={adDescription ? adDescription : ""}
            errorMessage={descError}
            txtRef = {descRef}
            maxLength={500}
            placeholder="Ex. My family and I have been on the hunt for our dream home in a walkable neighborhood..."
            onChange={(e) => {
              handleDescriptionChange(e);
            }}
          />

          <div className="des-count mb-4">
            <span>{descCharCount}</span>/<span>500</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantAdDescription;
