import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import WantAdSummary from "../mobile/buyerlistingServiceCreateAd/WantAdSummary";
import AddLocation from "../mobile/components/AddLocation";
import AddPropertyType from "../mobile/components/AddPropertyType";
import { BUY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../constants/common";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBuyerListWantAdById,
  saveBuyerListStrengthenWantAd,
} from "../services/wantAdService";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../helpers/common";
import AddTimeLine from "../mobile/components/AddTimeline";
import AddMustHaves from "../mobile/components/createWantAd/AddMustHaves";
import NiceToHaves from "../mobile/components/createWantAd/NiceToHaves";
import AddTitle from "../mobile/components/createWantAd/AddTitle";
import AddPurchaseType from "../mobile/components/createWantAd/AddPurchaseType";
import AddFinancing from "../mobile/components/createWantAd/AddFinancing";
import AddBudget from "../mobile/components/createWantAd/AddBudget";
import { saveToDraftBuyerListing } from "../helpers/createWantAd";
import ThankyouModal from "../components/Common/ThankyouModal";
import ErrorMessage from "../mobile/commonComponents/ErrorMessage";
import { pageURLs } from "../constants/pageURLs";

const BuyerListingCreateWantAdFormMob = (props) => {
  const [uiKey, setUIKey] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [completedPercentage, setCompletedPercentage] = useState(null);
  let adAmenities = null;
  const isAgent = false;
  const isEdit = false;
  const adType = _.get(formValues, "adType", null);
  const isFromBuyerListingService = true;
  const { id } = useParams();
  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [isValidId, setIsValidId] = useState(null);
  const navigate = useNavigate();
  const [showUrlExpired, setShowUrlExpired] = useState(false)

  useEffect(() => {
    getWantAdDetails();
  }, [id]);

  const getWantAdDetails = async () => {
    const splitId = id.split("-");
    const wantAdId = splitId[splitId.length - 1];
    if (wantAdId) {
      await getBuyerListWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setLocalStorage(WANT_AD_KEY, responseResult);
            setFormValues(responseResult);
            if (responseResult?.cmpldPerctg) {
              setCompletedPercentage(responseResult?.cmpldPerctg);
            }
            if (responseResult?.isReadyToReview) {
              if(responseResult?.isPublished){
                setShowUrlExpired(true);
              }
              else {navigate(pageURLs?.buyerListingConfirmation, {state : {wantAdData : responseResult}});}
            } else {
              setIsValidId(1);
              setFirstName(responseResult?.clientFirstName);
              setLastName(responseResult?.clientLastName);
            }
          } else {
            setIsValidId(0);
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };  

  useEffect(() => {
    handleCreateWantAdUi();

    let  wantAdData = getLocalStorage(WANT_AD_KEY);
    // to save the value to draft on each step completed in create want ad 
    if (wantAdData && !wantAdData?.backArrowClicked && uiKey== null) {
      handleSaveToDraft();
    } else{
      setLocalStorage(WANT_AD_KEY, {...wantAdData, backArrowClicked: false})
    }
  }, [uiKey]);

  // to save value to draft
  const handleSaveToDraft = () => {
    let wantAdData = getLocalStorage(WANT_AD_KEY);
    if (wantAdData?.isPublished != 1) {
      const type = "draft";
      saveWantAdData(type);
    }
  };

  const saveStrengthenData = async (wantAdIdValue, type) => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const wantAdId = wantAdIdValue;

    if (wantAd) {
      let requestPayLoad = {};
      let isApproveLoan = "";

      if (
        _.get(wantAd, "isPreApprLoan", null) === "Yes" ||
        _.get(wantAd, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = true;
      } else if (
        _.get(wantAd, "isPreApprLoan", null) === "No" ||
        _.get(wantAd, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = false;
      }
      requestPayLoad.isPreApprLoan === isApproveLoan;
      const is1031Exchange = wantAd.is1031Exchange === "Yes" ? true : false;
      requestPayLoad.client_Want_ad_Id = wantAdId;
      requestPayLoad.isPreApprLoan = isApproveLoan;
      requestPayLoad.is1031Exchange = is1031Exchange;
      requestPayLoad.completedStep = 1;
      requestPayLoad.adType =
        wantAd.adType == BUY ? "BUY_STRENGTHEN" : "LTR_STRENGTHEN";
      requestPayLoad.maxBdgt = wantAd.maxBdgt;
      requestPayLoad.minBdgt = wantAd.minBdgt;
      requestPayLoad.pFinance = wantAd.pFinance || wantAd.finTypeId;
      requestPayLoad.purchaseType = wantAd.purchaseType || wantAd.prchTypeId;

      await saveBuyerListStrengthenWantAd(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (type == "save") {
              // document.getElementById('pageTop')?.scrollIntoView({ behavior: 'smooth' });
              navigate(pageURLs?.buyerListingConfirmation, {state : {wantAdData : formValues}})
            } else {
              // const splitId = id.split("-");
              // getWantAdDetails(splitId[splitId.length - 1]);
            }
          }else{
            setIsValidId(0)
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const saveWantAdData = async (type) => {
    const requestPayLoad = getLocalStorage(WANT_AD_KEY);
    requestPayLoad.cmpldPerctg = completedPercentage;
    let arrayValue = [];
    if(requestPayLoad?.zipIdValues){
      requestPayLoad?.zipIdValues.map((zip)=>{
        arrayValue.push(zip?.zipId)
        })
        requestPayLoad.zipId = arrayValue;
    }  else if(requestPayLoad?.zipCode){
      requestPayLoad?.zipCode.map((zip)=>{
        if(zip?.zipId && zip?.zipId != null){
          arrayValue.push(zip?.zipId)
        }
      })
      requestPayLoad.zipId = arrayValue;
  }
    requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
    if (type === "save") {
      requestPayLoad.isReadyToReview = true;
    }
    if (requestPayLoad) {
      await saveToDraftBuyerListing({ requestPayLoad: requestPayLoad }).then(
        (response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (
              requestPayLoad.purchaseType ||
              requestPayLoad.prchTypeId ||
              requestPayLoad.pFinance
            ) {
              saveStrengthenData(responseResult.client_Want_ad_Id, type);
            } else {
              if (type === "save") {
                // document.getElementById('pageTop')?.scrollIntoView({ behavior: 'smooth' });
                navigate(pageURLs?.buyerListingConfirmation, {state : {wantAdData : formValues}})
              } else {
                // const splitId = id.split("-");
                // getWantAdDetails(splitId[splitId.length - 1]);
              }
            }
          } else {
            setIsValidId(0)
            console.log("Something went wrong");
          }
        }
      );
    }
  };

  useEffect(() => {
    setLocalStorage(WANT_AD_KEY_TEMP, formValues);
  }, [formValues]);

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearAll") {
        if (isEdit && !isPremiumUser) {
          setFormValues({
            wantAdId: _.get(formValues, "wantAdId", ""),
            client_Want_ad_Id : _.get(formValues, "client_Want_ad_Id", ""),
            isFilledByClient : _.get(formValues, "isFilledByClient", ""),
            clientData: _.get(formValues, "clientData", ""),
            clientId: _.get(formValues, "clientId", ""),
            clientName: _.get(formValues, "clientName", ""),
            isAgentCreated : _.get(formValues, "isAgentCreated", "")
          });
        } else if (isPremiumUser && isEdit) {
          setFormValues({
            clientId: _.get(formValues, "clientId", ""),
            clientName: _.get(formValues, "clientName", ""),
            clientData: _.get(formValues, "clientData", ""),
            wantAdId: _.get(formValues, "wantAdId", ""),
            userFirstName: _.get(formValues, "userFirstName", ""),
            userLastName: _.get(formValues, "userLastName", ""),
            isAgentAsSeller: _.get(formValues, "isAgentAsSeller", ""),
            client_Want_ad_Id : _.get(formValues, "client_Want_ad_Id", ""),
            isFilledByClient : _.get(formValues, "isFilledByClient", ""),
            isAgentCreated : _.get(formValues, "isAgentCreated", "")
          });
        } else if (!isEdit && isPremiumUser) {
          setFormValues({
            clientId: _.get(formValues, "clientId", ""),
            clientName: _.get(formValues, "clientName", ""),
            clientData: _.get(formValues, "clientData", ""),
            client_Want_ad_Id : _.get(formValues, "client_Want_ad_Id", ""),
            isFilledByClient : _.get(formValues, "isFilledByClient", ""),
            isAgentCreated : _.get(formValues, "isAgentCreated", "")
          });
        } else {
          setFormValues({});
        }
      } else if (type === "clearFlexDates") {
        const array = { ...formValues }; // make a separate copy of the state
        ["duration", "month"].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "dateRange") {
        const array = { ...formValues }; // make a separate copy of the state
        ["startDate", "endDate"].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "moveInTypeChangeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["startDate"].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "nice2Have",
        "noBedRm",
        "noBathRm",
        "noOfBeds",
        "sqFtMin",
        "sqFtMax",
        "grgSpce",
        "minLtSz",
        "maxLtSz",
        "yrBltMin",
        "yrBltMax",
        "isNwCnstctn",
        "acreageMin",
        "acreageMax",
        "lotType",
        "lotTypeText",
        "roadType",
        "rdTypText",
        "isPrvtDrInst",
        "onSiteElec",
        "waterSupply",
        "wSText",
        "isDrldWelInst",
        "wasteWtrSys",
        "wWSysText",
        "isSepTankInst",
        "isSepTankAppr",
        "prptySubTypes",
        "adaAccess",
        "accsPvtBthRm",
        "alwPets",
        "alwCats",
        "alwLrgDogs",
        "alwSmlDogs",
        "prptySubTypes",
        "adTitle",
        "adDesc",
        "minBdgt",
        "maxBdgt",
        "stories",
        "yearBuiltOption",
        "mnLtSzId",
        "mnLtSzTxt",
        "mxLtSzId",
        "mxLtSzTxt",
      ].forEach((e) => delete array[e]);

      setFormValues(array);
    }
  };

  const handleCreateWantAdUi = () => {
    switch (uiKey) {
      case "location":
        return (
          <AddLocation
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "timeLine":
        return (
          <AddTimeLine
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "budget":
        return (
          <AddBudget
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "propertyType":
        return (
          <AddPropertyType
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "mustHaves":
        return (
          <AddMustHaves
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "niceToHaves":
        return (
          <NiceToHaves
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "title":
        return (
          <AddTitle
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "purchaseType":
        return (
          <AddPurchaseType
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );
      case "financing":
        return (
          <AddFinancing
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            adType={adType}
            completedPercentage={completedPercentage}
            clearPreviousStates={clearPreviousStates}
            setCompletedPercentage={setCompletedPercentage}
            isAgent={isAgent}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
        );

      default:
        return (
          <WantAdSummary
            setUIKey={setUIKey}
            formValues={formValues}
            completedPercentage={completedPercentage}
            uiKey={uiKey}
            setCompletedPercentage={setCompletedPercentage}
            isEdit={isEdit}
            saveWantAdData={saveWantAdData}
          />
        );
    }
  };

  return (
    <>
      {showUrlExpired ? 
        <ErrorMessage
          message = "The URL cannot be accessed as the link has expired."
          // formValues={formValues}
        />
      : isValidId === 0 ? (
        <ErrorMessage
        message = "The url is not valid"
        // formValues={formValues}
      />
      ) : isValidId === 1 ? (
        handleCreateWantAdUi()
      ) : (
        <></>
      )}
    </>
  );
};

export default BuyerListingCreateWantAdFormMob;
