import HouseTagIcon from "../../../images/house-img.png";
import { useState, useEffect } from "react";
import AddProperty from "./AddProperty";
import AddClient from "./AddClient";
import { getInitials } from "../../../helpers/common";
import noImage from "../../../images/no-image-placeholder.png";

const AgentProperties = (props) => {
  const {
    propertyList,
    clientList,
    setShowSelectProperty,
    setShowSelectClients,
    userId,
    selectedClient,
    selectedProperty,
    userDetails
  } = props;
  const [initials, setInitials] = useState(null);

  useEffect(() => {
    setInitials(
      getInitials(selectedClient?.FirstName + " " + selectedClient?.LastName)
    );
  }, [selectedClient]);

  return (
    <div className="add-property-outerwrap">
      {!clientList ? (
        <AddClient userId={userId} userDetails={userDetails} />
      ) : (
        <a
          href={void 0}
          onClick={() => setShowSelectClients(true)}
          className="add-your-property-wrap link-wrap mb-0"
        >
          <div className="left-prof-wrap-outer me-2">
            <div className={"left-prof-wrap me-0 " + initials?.backgroundColor}>
              {initials?.initials}
            </div>
          </div>
          <div className="modal-wrap-info">
            <h2 className="pb-0">
              {selectedClient?.FirstName + " " + selectedClient?.LastName}
            </h2>
          </div>
          <span role="button" className="right-arrow-link">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                fill="#000022"
              ></path>
            </svg>
          </span>
        </a>
      )}
      {!propertyList ? (
        <AddProperty isAgent={true} selectedClient={selectedClient} userDetails={userDetails} />
      ) : (
        <a
          href={void 0}
          onClick={() => setShowSelectProperty(true)}
          className="add-your-property-wrap link-wrap"
        >
          <div className="make-intro-img">
            <img className="display-thumb-img" src={selectedProperty?.photo ? selectedProperty?.photo : noImage} />
          </div>
          <div className="make-intro-details">
            <h3>{selectedProperty?.Location}</h3>
            <p>{selectedProperty?.City},{selectedProperty?.State}</p>
          </div>
          <span role="button" className="right-arrow-link">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                fill="#000022"
              ></path>
            </svg>
          </span>
        </a>
      )}
    </div>
  );
};
export default AgentProperties;
