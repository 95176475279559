import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CommonHeader from "../components/Onboarding/CommonHeader";
import InventoryPropertyTypes from "../components/Inventory/EditInventory/InventoryPropertyTypes";
import {
  MEMBER_INVENTORY_EDIT_KEY,
  SHORT_RENT,
  INVENTORY_KEY,
  IS_LAND,
  INVENTORY_ALL_TYPE,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  DEFAULT_MAP_ZOOM,
  YEAR_BUILT_MIN,
  YEAR_BUILT_MAX,
  SELL,
} from "../constants/common";

import { createPreSignedUrl, createStaticMapImage, getLocalStorage, removeDecimalZeros, setLocalStorage } from "../helpers/common";
import { convertToNumber, textValidations } from "../helpers/validations";
import { checkInventoryType } from "../helpers/inventory";
import InventoryLotLandLocation from "../components/Inventory/EditInventory/InventoryLotLandLocation";
import InventoryLocationWithMustHaves from "../components/Inventory/EditInventory/InventoryLocationWithMustHaves";
import InventoryPropertyPurpose from "../components/Inventory/EditInventory/InventoryPropertyPurpose";
import InventoryBudget from "../components/Inventory/EditInventory/InventoryBudget";
import InventoryPropertyDetails from "../components/Inventory/EditInventory/InventoryPropertyDetails";
import InventoryDocuments from "../components/Inventory/StengthenInventory/InventoryDocuments";
import InventoryTimeLine from "../components/Inventory/StengthenInventory/InventoryTimeLine";
import PropertyPurposeConfirmModal from "../components/Inventory/EditInventory/PropertyPurposeConfirmModal";
import NiceToHave from "../components/Common/NiceToHave/NiceToHave";
import InventoryFileUpload from "../components/Inventory/EditInventory/InventoryFileUpload";
import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";


const MemberEditInventory = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  let sessionKey = location.state && location.state['isCreated'] == true ? INVENTORY_KEY : MEMBER_INVENTORY_EDIT_KEY;
  const isFromIntroduction = location.state && location.state['isFromIntroduction'];
  const wantAdId = location.state && location.state['wantAdId'];

  const [formValues, setFormValues] =
    useState(location.state && location.state['data'] ? location.state['data']
      : getLocalStorage(sessionKey));

  const propertyType = convertToNumber(_.get(formValues, "prprtyType", null));
  const propertySubType = convertToNumber(_.get(formValues, "prptySubType", null));
  const [disableButton, setDisableButton] = useState(false);
  const [selectedniceToHavesList, setSelectedNiceToHavesList] = useState([]);
  const [showConfirmStatuModal, setConfirmStatus] = useState(false);

  const confirmOwnerChkBx = "isConfirmOwner";
  const confirmPropLocChkBx = "isConfirmPropLoc";

  const invType = checkInventoryType(_.get(formValues, "proprtyPurpose", ''));
  const isSellOnly = invType === INVENTORY_SELL;
  const isLTROnly = invType === INVENTORY_LTR;
  const isSTROnly = invType === INVENTORY_STR;
  const isSell_And_LTR = invType === INVENTORY_SELL_LTR;
  const isSell_And_STR = invType === INVENTORY_SELL_STR;
  const isSTR_And_LTR = invType === INVENTORY_LTR_STR;
  const isAllType = invType === INVENTORY_ALL_TYPE;

  // error tracking
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [subPropertyTypeError, setSubPropertyTypeError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [askingPriceError, setAskingPriceError] = useState(false);
  const [longTermPriceError, setLongTermPriceError] = useState(false);
  const [shortTermPriceError, setShortTermPriceError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [financeTitleTextColor, setFinanceTitleTextColor] = useState(false);
  
  const propertyTypeRef = useRef(null);
  const locationRef = useRef(null);
  const adTypeRef = useRef(null);
  const BudgetRef = useRef(null);
  const DescriptionRef = useRef(null);


  useEffect(() => {

    formValues?.nice2Have ? setSelectedNiceToHavesList(formValues?.nice2Have) : setSelectedNiceToHavesList([]);

    if (formValues) {
      setLocalStorage(MEMBER_INVENTORY_EDIT_KEY, formValues);
     
      if (formValues?.prprtyType) {
        setPropertyTypeError(false)
      }
      if (formValues?.prprtyType != IS_LAND && formValues?.prptySubType) {
        setSubPropertyTypeError(false)
      
      }
      if (formValues?.locAddress) {
        setLocationError(false)
      }
      if (formValues?.proprtyPurpose?.length > 0) {
        setTypeError(false)
      }
      if (isAllType) {
        if (formValues?.sellAskngPrice && formValues?.LTRAskngPrce && formValues?.STRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
        }
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
        }
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
        }
      } else if (isSellOnly) {
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
          setFinanceTitleTextColor(false)
        }
      } else if (isLTROnly) {
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
          setFinanceTitleTextColor(false)
        }
      } else if (isSTROnly) {
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
          setFinanceTitleTextColor(false)
        }
      } else if (isSell_And_LTR) {
        if (formValues?.sellAskngPrice && formValues?.LTRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
        }
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
        }
      } else if (isSell_And_STR) {
        if (formValues?.sellAskngPrice && formValues?.STRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
        }
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
        }
      } else if (isSTR_And_LTR) {
        if (formValues?.LTRAskngPrce && formValues?.STRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
        }
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
        }
      }

      if (formValues?.invtryDetails && textValidations(formValues?.invtryDetails)) {
        setDescriptionError(false)
      } else if (formValues?.invtryDetails && !textValidations(formValues?.invtryDetails)) {
        setDescriptionError(true)
      } else {
        setDescriptionError(false)
      }
    }
  }, [formValues]);


  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if(type === "clearSell"){
        const array = { ...formValues }; // make a separate copy of the state
        ["sellAskngPrice", "isCompnsteAgent", "salesPercnt", "nice2Have"]
        .forEach((e) => delete array[e]);
        setFormValues(array);
      }
      if (type === "clearPropertyPurposeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwLrgDogs", "alwSmlDogs", "alwCats", "LTRAskngPrce", "isCompnsteAgent_LTR", "salesPercnt_LTR", "nice2Have" ].forEach((e) => delete array[e]);
        setFormValues(array);
        setFormValues(array);
      } else if (type === "clearPropertyPurposeSTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwPets", "accsPvtBthRm", "pvtEntrnce", "noOfBeds", "STRAskngPrce", "isCompnsteAgent_STR", "salesPercnt_STR", "nice2Have"  ].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "locAddress",
        "noBedRm",
        "noBathRm",
        "sqFt",
        "stories",
        "lotSize",
        "grgeSpots",
        "adaAccess",
        "acreage",
        "lotType",
        "ltTypeTxt",
        "roadType",
        "rdTypeTxt",
        "isPowerAccess",
        "waterSuply",
        "wSText",
        "wasteWtrSys",
        "WstWatrSys",
        "watrWtrText",
        "isApprovedStrctr",
        "isInFloodPlain",
        "proprtyPurpose",
        "alwLrgDogs",
        "alwSmlDogs",
        "alwCats",
        "alwPets",
        "accsPvtBthRm",
        "isConfirmOwner",
        "isConfirmPropLoc",
        "nice2Have",
        "sellAskngPrice",
        "LTRAskngPrce",
        "STRAskngPrce",
        "isCompnsteAgent",
        "salesPercnt",
        "isCompnsteAgent_LTR", 
        "isCompnsteAgent_STR", 
        "salesPercnt_LTR", 
        "salesPercnt_STR",
        "photos",
        "invtryDetails",
        "prptySubTypes",
        "prptySubType",
        "prptySubTypeTxt",
         "prptyTypeTxt",
         "IsNewConstruction",
         "isPrvtDrInst",
         "isDrldWelInst",
         "isDrldWelAppr",
         "isSepTankInst",
         "isSepTankAppr",
         "pvtEntrnce",
         "neighborHood",
         "highSchool",
         "yearBuilt",
         "noOfBeds",
         "lat",
         "lng",
         "mapZoom",
         "mapType",
         "street",
         "city",
         "county",
         "state",
         "zip",
         "invalidLocationExist",

      ].forEach((e) => delete array[e]);

      setFormValues(array);
    }
  };

  function handleSave() {
    let error = [];
    setDisableButton(true);
    if (!formValues?.prprtyType) {
      setPropertyTypeError(true)
      error.push("propertyTypeRef", "prprtyType")
    }

    if (formValues?.prprtyType != IS_LAND && !formValues?.prptySubType) {
      setSubPropertyTypeError(true)
      error.push("propertyTypeRef", "prprtyType")
    }
    if (!formValues?.locAddress) {
      setLocationError(true)
      error.push("locationRef",  "locAddress")
    }
    if (formValues.yearBuilt) {
      if ((convertToNumber(formValues.yearBuilt) < YEAR_BUILT_MIN ||
        convertToNumber(formValues.yearBuilt) > YEAR_BUILT_MAX)) {
        error.push("locationRef", "yearBuiltRange");

      }

    }
    if (!formValues?.proprtyPurpose || formValues?.proprtyPurpose?.length == 0) {
      setTypeError(true)
      error.push("adTypeRef",  "adType")
    }
    const is_STR_Selected = _.includes(formValues.proprtyPurpose, SHORT_RENT);
    if (is_STR_Selected && formValues.prprtyType != IS_LAND) {
      if (convertToNumber(formValues.noOfBeds) < 1) {
        error.push("adTypeRef", "noOfBeds");

      }
    }
   

    if (isAllType) {
      
      if (!formValues?.sellAskngPrice || !formValues?.LTRAskngPrce || !formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.sellAskngPrice) {
        setAskingPriceError(true)
        error.push("BudgetRef","sellAskngPrice")
      }
      if (!formValues?.LTRAskngPrce) {
        setLongTermPriceError(true)
        error.push("BudgetRef","LTRAskngPrce")
      }
      if (!formValues?.STRAskngPrce) {
        setShortTermPriceError(true)
        error.push("BudgetRef","STRAskngPrce")
      }
    } else if (isSellOnly) {
      
      if (!formValues?.sellAskngPrice) {
        setFinanceTitleTextColor(true)
        setAskingPriceError(true)
        error.push("BudgetRef","sellAskngPrice")
      }
    } else if (isLTROnly) {
      if (!formValues?.LTRAskngPrce) {
        setFinanceTitleTextColor(true)
        setLongTermPriceError(true)
        error.push("BudgetRef","LTRAskngPrce")
      }
    } else if (isSTROnly) {

      if (!formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
        setShortTermPriceError(true)
        error.push("BudgetRef","STRAskngPrce")
      }
    } else if (isSell_And_LTR) {
     
      if (!formValues?.sellAskngPrice || !formValues?.LTRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.sellAskngPrice) {
        setAskingPriceError(true)
        error.push("BudgetRef","sellAskngPrice")
      }
      if (!formValues?.LTRAskngPrce) {
        setLongTermPriceError(true)
        error.push("BudgetRef","LTRAskngPrce")
      }
    } else if (isSell_And_STR) {
     
      if (!formValues?.sellAskngPrice || !formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.sellAskngPrice) {
        setAskingPriceError(true)
        error.push("BudgetRef","sellAskngPrice")
      }
      if (!formValues?.STRAskngPrce) {
        setShortTermPriceError(true)
        error.push("BudgetRef","STRAskngPrce")
      }
    } else if (isSTR_And_LTR) {
      if (!formValues?.LTRAskngPrce || !formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.LTRAskngPrce) {
        setLongTermPriceError(true)
        error.push("BudgetRef", "LTRAskngPrce")
      }
      if (!formValues?.STRAskngPrce) {
        setShortTermPriceError(true)
        error.push("BudgetRef","STRAskngPrce")
      }
    }
    if (formValues?.invtryDetails && !textValidations(formValues?.invtryDetails)) {
      setDescriptionError(true)
      error.push("DescriptionRef", "invtryDetails")
    }
    if (error.length == 0) {
      const proprtyPurpose = _.get(formValues, 'proprtyPurpose', [])
      const is_Sell_Selected = _.includes(proprtyPurpose, SELL);
      if (!is_Sell_Selected) {
        handleFormValues({ sellAskngPrice:"" });
      }
      redirectToConfirmationPage()
    } else {
 if (error[0] == 'adTypeRef') {
        window.scrollTo(0, adTypeRef.current.offsetTop);
      } else if (error[0] == 'locationRef') {
        window.scrollTo(0, locationRef.current.offsetTop);
      } else if (error[0] == 'propertyTypeRef') {
        window.scrollTo(0, propertyTypeRef.current.offsetTop);
      }  else if (error[0] == 'BudgetRef') {
        window.scrollTo(0, BudgetRef.current.offsetTop);
      } else if (error[0] == 'DescriptionRef') {
        window.scrollTo(0, DescriptionRef.current.offsetTop);
      } 

      handleFormValues({ validationErrors: error, updatedAt: Date.now() });
      setDisableButton(false)
    }
  }


  const handleMapImageUpload = async () => {
    const lat = _.get(formValues, "lat", "");
    const lng = _.get(formValues, "lng", "");
    const mapZoom = _.get(formValues, 'mapZoom', DEFAULT_MAP_ZOOM)
    const mapResponse = await createStaticMapImage(lat, lng, mapZoom)
    const preSignedUrlRes = mapResponse ? await createPreSignedUrl(false, _.get(formValues, "s3ImageFolder", "")) : alert('Something went wrong while fetching map image.');
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl
          ? s3FullUrl.split("?")[0]
          : "";
        return imageUrl;
        // handleFormValues({ mapImg: imageUrl })
      }

    }
  };

  const redirectToConfirmationPage = async () => {
    let imageUrl = await handleMapImageUpload();
    handleFormValues({
      mapImg: imageUrl,
      cmpldPerctg: 100,
      actvStep: 9,
      isEdit: true,
      member: true,
      isFromIntroduction: isFromIntroduction,
      wantAdId: wantAdId
    });

    setTimeout(() => {
      navigate("/inventory-confirmation",
        {
          state:
            { inventoryId: _.get(formValues, "inventoryId", null), isAgentCreated: false }
        })
    }, 10);
  }

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (id) {
      case confirmOwnerChkBx:
        handleFormValues({ isConfirmOwner: e.target.checked });
        break;
      case confirmPropLocChkBx:
        handleFormValues({ isConfirmPropLoc: e.target.checked });
        break;
    }
  };

  const handleConfirmationModal = (modalState) => {
    setConfirmStatus(modalState);
  };

  const handleModalConfirmClick = () => {
    handleConfirmationModal(false)
    handleFormValues({ isConfirmAll: true })
    redirectToConfirmationPage();
  }

  const handleCancelClick = () => {
    handleFormValues({ isConfirmOwner: false, isConfirmPropLoc: false, isConfirmAll: false })
    setConfirmStatus(false)
  }

  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">
            <div className="edit-want-ad-wrap mw-640">
              <h2>Edit property</h2>
              <h6 className="mb-40">Changes made to your property will be shown to people you've introduced it to.</h6>
              <div className={(propertyTypeError || subPropertyTypeError) ? "wantad-type-wrap select-type-wrap red-marked-wrap" :
                "wantad-type-wrap select-type-wrap"} ref={propertyTypeRef}>
                <h6 className={(propertyTypeError) && "red-marked-subheading"}>Property type <span>*</span></h6>
                <InventoryPropertyTypes
                  handleFormValues={handleFormValues}
                  propertyType={propertyType}
                  adType={_.get(formValues, "adType", null)}
                  clearPreviousStates={clearPreviousStates}
                  prptySubType={propertySubType}
                  propertyTypeError={propertyTypeError}
                  subPropertyTypeError={subPropertyTypeError}
                />

              </div>
              {propertyType &&
                <div className={locationError ? "wantad-location-wrap mb-3 red-marked-wrap" : "wantad-location-wrap mb-3"} ref={locationRef}>
                  <h6  className={locationError && "red-marked-heading"}>Location *</h6>
                  {(propertyType == IS_LAND) ? (
                    <InventoryLotLandLocation
                      handleFormValues={handleFormValues}
                      locationAddress={_.get(formValues, "locAddress", null)}
                      acreage={_.get(formValues, "acreage", null)}
                      lotType={convertToNumber(_.get(formValues, "lotType", null))}
                      roadType={convertToNumber(_.get(formValues, "roadType", null))}
                      isPowerAccess={_.get(formValues, "isPowerAccess", null)}
                      waterSuply={convertToNumber(
                        _.get(formValues, "waterSuply", null)
                      )}
                      wasteWtrSys={convertToNumber(
                        _.get(formValues, "wasteWtrSys", null)
                      )}
                      isApprovedStrctr={_.get(formValues, "isApprovedStrctr", null)}
                      isInFloodPlain={_.get(formValues, "isInFloodPlain", null)}
                      isAgentCreated={false}
                      s3Imagefolder={_.get(formValues, "s3ImageFolder", false)}
                      lat={_.get(formValues, "lat", null)}
                      lng={_.get(formValues, "lng", null)}
                      mapZoom={_.get(formValues, "mapZoom", null)}
                      isPrvtDrInst={_.get(formValues, "isPrvtDrInst", null)}
                      isDrldWelInst={_.get(formValues, "isDrldWelInst", null)}
                      isDrldWelAppr={_.get(formValues, "isDrldWelAppr", null)}
                      isSepTankInst={_.get(formValues, "isSepTankInst", null)}
                      isSepTankAppr={_.get(formValues, "isSepTankAppr", null)}
                      acre_Sqft={_.get(formValues, "acre_Sqft", null)}
                      validationErrors={_.get(formValues, 'validationErrors', null)}
                      mapType={_.get(formValues, "mapType", null)}
                      locationError={locationError}
                      sessionKey={sessionKey}

                    />
                  ) : (
                    <InventoryLocationWithMustHaves
                      handleFormValues={handleFormValues}
                      locationAddress={_.get(formValues, "locAddress", null)}
                      noBedRm={removeDecimalZeros(_.get(formValues, "noBedRm", null))}
                      noBathRm={removeDecimalZeros(_.get(formValues, "noBathRm", null))}
                      sqFt={removeDecimalZeros(_.get(formValues, "sqFt", null))}
                      stories={_.get(formValues, "stories", null)}
                      lotSize={_.get(formValues, "lotSize", null)}
                      grgeSpots={_.get(formValues, "grgeSpots", null)}
                      adaAccess={_.get(formValues, "adaAccess", false)}
                      IsNewConstruction={_.get(formValues, "IsNewConstruction", false)}
                      propertyType={propertyType}
                      propertySubType={propertySubType}
                      isAgentCreated={false}
                      s3Imagefolder={_.get(formValues, "s3ImageFolder", false)}
                      lat={_.get(formValues, "lat", null)}
                      lng={_.get(formValues, "lng", null)}
                      mapZoom={_.get(formValues, "mapZoom", null)}
                      neighborHood={_.get(formValues, "neighborHood", null)}
                      highSchool={_.get(formValues, "highSchool", null)}
                      yearBuilt={_.get(formValues, "yearBuilt", null)}
                      validationErrors={_.get(formValues, 'validationErrors', null)}
                    // errorUpdatedAt = {_.get(formValues, "updatedAt", null)}
                    isFromAgentPage={true}
                    mapType={_.get(formValues, "mapType", null)}
                    locationError={locationError}
                    sessionKey={sessionKey}

                    />
                  )}

                </div>
              }
              {propertyType &&
                <div className={typeError ? "wantad-type-wrap select-type-wrap label-pad-adjust red-marked-wrap"
                : "wantad-type-wrap select-type-wrap label-pad-adjust"} ref={adTypeRef}>

                  <h6 className={typeError && "red-marked-subheading"}>Type <span>*</span></h6>
                  <InventoryPropertyPurpose
                    handleFormValues={handleFormValues}
                    propertyPurpose={_.get(formValues, "proprtyPurpose", '')}
                    alwLrgDogs={_.get(formValues, "alwLrgDogs", null)}
                    alwSmlDogs={_.get(formValues, "alwSmlDogs", null)}
                    alwCats={_.get(formValues, "alwCats", null)}
                    alwPets={_.get(formValues, "alwPets", null)}
                    accsPvtBthRm={_.get(formValues, "accsPvtBthRm", null)}
                    pvtEntrnce={_.get(formValues, "pvtEntrnce", null)}
                    clearPreviousStates={clearPreviousStates}
                    propertyType={propertyType}
                    propertySubType={propertySubType}
                    isConfirmOwner={_.get(formValues, "isConfirmOwner", null)}
                    isConfirmPropLoc={_.get(formValues, "isConfirmPropLoc", null)}
                    noOfBeds={_.get(formValues, "noOfBeds", null)}
                    errors={_.get(formValues, 'validationErrors', null)}
                    typeError={typeError}
                  />
                </div>
              }

              {(propertyType && invType) && (
                <NiceToHave
                  selectedNiceToHave={formValues?.nice2Have}
                  selectedniceToHavesList={selectedniceToHavesList}
                  propertyType={formValues?.prprtyType}
                  adType={formValues?.adType}
                  proprtyPurpose={formValues?.proprtyPurpose}
                  isFromInventory={true}
                  handleFormValues={handleFormValues}
                  title="Features" />
              )}

              {(propertyType && invType) &&
                <div  className={financeTitleTextColor ? "finance-wrap mb-2 red-marked-wrap" : "finance-wrap mb-2"} ref={BudgetRef}>
                  <h6 className={financeTitleTextColor && "red-marked-subhead"}>Finance</h6>
                  <h6  className={financeTitleTextColor ? "sub-head red-marked-subhead" :
                    "sub-head"}>You can adjust your asking price when you send each introduction. Our goal is to match your property to want-ads.</h6>

                  <InventoryBudget
                    handleFormValues={handleFormValues}
                  
                    sellAskngPrice={_.get(formValues, "sellAskngPrice", "")}
                    LTRAskngPrce={_.get(formValues, "LTRAskngPrce", "")}
                    STRAskngPrce={_.get(formValues, "STRAskngPrce", "")}
                    isCompnsteAgent={_.get(formValues, "isCompnsteAgent", "")}
                    isCompnsteAgentLTR={_.get(formValues, "isCompnsteAgent_LTR", "")}
                    isCompnsteAgentSTR={_.get(formValues, "isCompnsteAgent_STR", "")}
                    propertyPurpose={_.get(formValues, "proprtyPurpose", '')}
                    salesPercnt={_.get(formValues, "salesPercnt", null)}
                    salesPercntLTR={_.get(formValues, "salesPercnt_LTR", null)}
                    salesPercntSTR={_.get(formValues, "salesPercnt_STR", null)}
                    isAgentCreated={false}
                    askingPriceError={askingPriceError}
                    shortTermPriceError={shortTermPriceError}
                    longTermPriceError={longTermPriceError}
                  />
                </div>
              }

              {(propertyType && invType) &&
                <InventoryFileUpload
                    handleFormValues={handleFormValues}
                    photos={_.get(formValues, "photos", [])}
                    s3Imagefolder={_.get(formValues, "s3ImageFolder", '')}
                    isFolderCreated={_.get(formValues, "isFolderCreated", '')}
                    inventoryId={_.get(formValues, "inventoryId", '')}
                    deletedImages={_.get(formValues, "deletedImages", '')}
                    sessionKey={sessionKey}
                  />

              }

              {(isSellOnly ||
                isAllType ||
                isSell_And_LTR ||
                isSell_And_STR || isLTROnly) &&
                <>
                  <h6>Timeline</h6>
                  <InventoryTimeLine
                    handleFormValues={handleFormValues}
                    timeLine={_.get(formValues, "timeLine", null)}
                    isEdit={true}
                  />
                  <h6>Additional details </h6>
                  <InventoryDocuments
                    handleFormValues={handleFormValues}
                    apprOnFile={_.get(formValues, "apprOnFile", null)}
                    insReport={_.get(formValues, "insReport", null)}
                    sellerFin={_.get(formValues, "sellerFin", null)}
                    floorPlan={_.get(formValues, "floorPlan", null)}
                    floodCert={_.get(formValues, "floodCert", null)}
                    assMortgage= {_.get(formValues, "assMortgage", null)}
                    invType={invType}
                    isEdit={true}
                  />
                </>

              }

              {(propertyType && invType) &&
                <div className={descriptionError ? "description-wrap red-marked-wrap" : "description-wrap"} ref={DescriptionRef}>
                  <h6 className={descriptionError && "red-marked-subheading"}>Description</h6>

                  <InventoryPropertyDetails
                    handleFormValues={handleFormValues}
                    invtryDetails={_.get(formValues, "invtryDetails", "")}
                    descriptionError={descriptionError}
                  />
                </div>
              }
            </div>

            <div className="edit-want-ad-btnwrap mt-4">
              <Button variant="primary" className="btn" disabled={disableButton} onClick={handleSave} >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>

      <PropertyPurposeConfirmModal
        show={showConfirmStatuModal}
        isShortRentChecked={_.get(formValues, "proprtyPurpose", '') && _.includes(_.get(formValues, "proprtyPurpose", ''), SHORT_RENT)}
        onHide={handleConfirmationModal}
        isConfirmOwner={_.get(formValues, "isConfirmOwner", '')}
        isConfirmPropLoc={_.get(formValues, "isConfirmPropLoc", '')}
        handleCheckBoxItemClick={handleCheckBoxItemClick}
        handleModalConfirmClick={handleModalConfirmClick}
        handleCancelClick={handleCancelClick}
        isDisabled={false}
        confirmOwnerChkBx={confirmOwnerChkBx}
        confirmPropLocChkBx={confirmPropLocChkBx}
      ></PropertyPurposeConfirmModal>
    </>
  );
};

export default MemberEditInventory;
