import React, { useEffect } from "react";

import { convertToNumber } from "../../helpers/validations";

const WantAdRentMustHavePrivate = (props) => {
  const {
    accsPvtBthRm,
    alwPets,
    adaAccess,
    pvtEntrnce,
    handleFormValues,
    handleContinueButtonState,
    isEdit = false,
  } = props;

  useEffect(() => {
    if (accsPvtBthRm || alwPets || adaAccess) {
      handleContinueButtonState();
    } else {
      const isDisable = true;
      handleContinueButtonState(isDisable);
    }
  }, [accsPvtBthRm, alwPets, adaAccess, pvtEntrnce]);

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ adaAccess: e.target.checked });
        break;
      case 2:
        handleFormValues({ alwPets: e.target.checked });
        break;
      case 3:
        handleFormValues({ accsPvtBthRm: e.target.checked });
        break;
      case 4:
        handleFormValues({ pvtEntrnce: e.target.checked });
        break;
    }
  };
  const loadCheckBoxItems = () => {
    const itemArray = [
      { id: 1, text: "ADA Accessible" },
      { id: 2, text: "Allows Pets" },
      { id: 3, text: "Access to private bathroom" },
      { id: 4, text: "Access to Private Entrance" },
    ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 1:
          isChecked = adaAccess;
          break;
        case 2:
          isChecked = alwPets;
          break;
        case 3:
          isChecked = accsPvtBthRm;
          break;
        case 4:
          isChecked = pvtEntrnce;
          break;
      }

      return (
        <label className="check-wrap mb-3" key={id}>
          <input
            type="checkbox"
            name="chk-mh"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <span>
            <div className="timing-wrap-info">
              <h2 className="pb-0">{text}</h2>
            </div>
          </span>
        </label>
      );
    });

    return List;
  };
  return (
    <div className={!isEdit ? "center-content-sec mw-428" : ""}>
      <div className="wantad-timing-wrap">{loadCheckBoxItems()}</div>
    </div>
  );
};
export default WantAdRentMustHavePrivate;
