import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import MarkAsSoldModal from "./MarkAsSoldModal";
import MarkAsSoldStep2Modal from "./MarkAsSoldStep2Modal";
import MarkAsSoldStep3Modal from "./MarkAsSoldStep3Modal";
import DeletePropertyModal from "./DeletePropertyModal";
import {
  replaceComma,
} from "../../helpers/common";
import { 
  inventoryMarkAsSold,
  deleteMyInventory,
} from "../../services/inventoryService";
import { toast } from "react-toastify";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
const PropertyActionDropdown = (props) => {
    const {inventoryId, isSold, onEdit, isFromDraft,dropDownClass, updateAfterDelete}= props;
    const navigate = useNavigate();


  const [showMarkAsSold, setShowMarkAsSold] = useState(false);
  const MarkAsSoldClose = () => setShowMarkAsSold(false);
  const MarkAsSoldShow = () => setShowMarkAsSold(true);

  // const [showMarkAsSoldStep2, setShowMarkAsSoldStep2] = useState(false);
  // const MarkAsSoldStep2Close = () => setShowMarkAsSoldStep2(false);
  // const MarkAsSoldStep2Show = () => setShowMarkAsSoldStep2(true);

  // const [showMarkAsSoldStep3, setShowMarkAsSoldStep3] = useState(false);
  // const MarkAsSoldStep3Close = () => setShowMarkAsSoldStep3(false);
  // const MarkAsSoldStep3Show = () => setShowMarkAsSoldStep3(true);

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const DeleteModalClose = () => setDeleteModalShow(false);
  const DeleteModalShow = () => setDeleteModalShow(true);


  const [markAsSoldModalShow, setMarkAsSoldModal] = useState(false);
  const handleMarkAsSoldModal = (status) => setMarkAsSoldModal(status);
  const [markAsSoldStatus, setMarkAsSoldStatus] = useState(false);
  const [showGitchaHelpFeedbackModal, setShowGitchaHelpFeedbackModal] =
    useState(false);
  const GitchaHelpFeedbackModalShow = () =>
    setShowGitchaHelpFeedbackModal(true);
  const GitchaHelpFeedbackModalClose = () =>
    setShowGitchaHelpFeedbackModal(false);

  const onMarkAsSold = () => {
    handleMarkAsSoldModal(true);
  };

  const handleInventoryMarkAsSold = async (
    inventoryId,
    didGitchHelped,
    salesPrice
  ) => {
    const newPrice = salesPrice?.replace("$", "");
    if (inventoryId) {
      const payload = {
        inventoryId: inventoryId,
        didGitchHelped: didGitchHelped === true ? 1 : 0,
        salesPrice: newPrice ? replaceComma(newPrice) : null,
      };
      await inventoryMarkAsSold(payload)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            toast.success("Your inventory has been successfully mark as sold.");
            GitchaHelpFeedbackModalClose();
            setMarkAsSoldStatus(!markAsSoldStatus);
            navigate(pageURLs.mobileManageInventory, { state: { showClosed: true } });
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const onInventoryDelete = () => {
    setDeleteModalShow(true);
  };

  /**
   * To handle the inventory delete
   */
  const handleDeleteInventory = async () => {
    // setLoading(true)
    if (inventoryId) {
      await deleteMyInventory(inventoryId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);

          if (response.errorCode === 0 && responseResult) {
            toast.success("Your property has been deleted.");
            setDeleteModalShow(false);
            setDeleteStatus(!deleteStatus);
            navigate(pageURLs.mobileManageInventory);
            updateAfterDelete(inventoryId);
          } else {
            // toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };


  return (
    <div>
        <Dropdown className={dropDownClass || ""}>
            <Dropdown.Toggle variant="" id="dropdown-client" className="action-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.4167 11.6666C11.3371 11.6666 12.0833 10.9204 12.0833 9.99992C12.0833 9.07944 11.3371 8.33325 10.4167 8.33325C9.49619 8.33325 8.75 9.07944 8.75 9.99992C8.75 10.9204 9.49619 11.6666 10.4167 11.6666Z" fill="#1B1C1E" />
                    <path d="M15 11.6666C15.9205 11.6666 16.6667 10.9204 16.6667 9.99992C16.6667 9.07944 15.9205 8.33325 15 8.33325C14.0795 8.33325 13.3333 9.07944 13.3333 9.99992C13.3333 10.9204 14.0795 11.6666 15 11.6666Z" fill="#1B1C1E" />
                    <path d="M5.83333 11.6666C6.75381 11.6666 7.5 10.9204 7.5 9.99992C7.5 9.07944 6.75381 8.33325 5.83333 8.33325C4.91286 8.33325 4.16667 9.07944 4.16667 9.99992C4.16667 10.9204 4.91286 11.6666 5.83333 11.6666Z" fill="#1B1C1E" />
                </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
                {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                {!isSold && (
                  <>
                <Dropdown.Item onClick={()=>onEdit()}>Edit</Dropdown.Item>
                {!isFromDraft && <Dropdown.Item onClick={() => onMarkAsSold()}>Mark as sold</Dropdown.Item>}

                  </>
                  )}
               
                {/* <Dropdown.Item onClick={MarkAsSoldShow}>Mark as sold</Dropdown.Item> */}
                {/* <Dropdown.Item onClick={MarkAsSoldStep2Show}>Mark as sold</Dropdown.Item> */}
                {/* <Dropdown.Item onClick={MarkAsSoldStep3Show}>Mark as sold</Dropdown.Item> */}
                <Dropdown.Item  onClick={() => onInventoryDelete()} className="delete">Delete</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <MarkAsSoldModal
            // show={showMarkAsSold}
            // onHide={MarkAsSoldClose}
            show={markAsSoldModalShow}
        onHide={handleMarkAsSoldModal}
        handleInventoryMarkAsSold={() => {
          GitchaHelpFeedbackModalShow();
          handleMarkAsSoldModal(false);
        }}
        inventoryId={inventoryId}
        />
        <MarkAsSoldStep3Modal
            show={showGitchaHelpFeedbackModal}
            onHide={GitchaHelpFeedbackModalClose}
            handleInventoryMarkAsSold={handleInventoryMarkAsSold}
            inventoryId={inventoryId}
        /> 
        <DeletePropertyModal
         show={deleteModalShow}
         onHide={() => setDeleteModalShow(false)}
         
         handleConfirm={() => handleDeleteInventory()}
        />
    </div>
    
  );
};
export default PropertyActionDropdown;
