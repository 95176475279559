import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import MinCapIcon from "../../../images/minimum-icn.svg";
import LakeViewImage from "../../../images/lake-view-icon.svg";

import ConsumerIconImage from "../../../images/consumer-icon.svg";

import PrResIconImage from "../../../images/primary-residence-icon.svg";
import FinanceIconImage from "../../../images/financing-icon.svg";

import {  getWantAdById } from "../../../services/wantAdService";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  WANT_AD_KEY,
  USER_INFO_KEY,
  INVENTORY_KEY,
  STRENGTHEN_WANT_AD_KEY,
  IS_LAND,
  IS_MULTI_FAMILY,
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
  USER_TYPE_AGENT,
} from "../../../constants/common";
import base64 from "base-64";
import utf8 from "utf8";
import {
  getPropertyIcon,
  getTooltipText,
  getCombinedText,
  getDescriptionText,
} from "../../../helpers/getPropertyIcons";
import {
  SquareFeet,
  buildWantAdMustHavesHTML,
} from "../../../helpers/WantAdDetails";


import {
  loadTimeLineHtml,
  getLocalStorage,
  setLocalStorage,
  getUserId,
  loggedInUser,
  getUserType,
  loadPreferredLocationHtml,
  isIphone,
  loadCompensationText,
  locationDisplay,
} from "../../../helpers/common";

import _ from "lodash";
import { MESSAGES_VIEW } from "../../../constants/onBoarding";


const ViewWantAd = (props) => {
  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
    document.body.classList.add("bg-modal-fix");
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const wantAd = getLocalStorage(WANT_AD_KEY);
  const [searchParams] = useSearchParams();
  const [modalShow, setModalShow] = useState(false); 
  const [clientSearch, setClientSearch] = useState("");
  const [reloadAgents, setReloadAgents] = useState(false);
  const [results, setResults] = useState([]);
  searchParams.get("wantAdId");
  const id = searchParams.get("wantAdId");
  let decodedId;
  let idFromURL;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
    if (decodedId) {
      let decodedData = decodedId.split("-");
      idFromURL = decodedData.length > 0 ? decodedData[1] : null;
    }
  } catch {}
  // const isFromHomeFeed =
  //   location.state && location?.state["isFromHomeFeed"] ? true : false;
  searchParams.get("wantAdId");
  const [skeltonLoad, setSkeltonLoad] = useState(true); //
  const isFromHomeFeed = idFromURL
    ? true
    : _.get(wantAd, "isFromHomeFeed", false);
  const [showShareModal, setShareModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showReportModal, setReportModal] = useState(false);
  const handleShareClose = () => {
    setShareModal(false);
  };
  const [showStrengthen, setShowStrengthen] = useState(false);
  const [showViewIntroductionModal, setViewIntroductionModal] = useState(false);
  const wantAdId = props.wantAdId;
  const introId = _.get(wantAd, "introId", null);
  const isFromAllIntroduction = _.get(wantAd, "isFromAllIntroduction", false);
  const fromStrengthenWantAd =
    location.state && location.state["fromStrengthenWantAd"];
  const isEdit = _.get(wantAd, "isEdit", false);
  const [adDetails, setAdDetails] = useState();
  const adType = _.get(adDetails, "adType", "");
  const propertyType = Number(_.get(adDetails, "prprtyType", ""));
  const PropertyTypeId = _.get(adDetails, "prprtyType", "");
  const subPropertyTypeId = _.get(adDetails, "prptySubTypes", "");
  const adTypeBasedOnProperty = propertyType === 6 ? LOT_LAND : adType;
  const isAdTypeIsLand = adTypeBasedOnProperty === LOT_LAND;
  const nice2Have = _.get(adDetails, "nice2Have", []);
  const mapImage = _.get(adDetails, "mapImg", null);
  const consumerType = adType == BUY ? "Buyer" : "Renter";
  const propertySubTypes = _.get(adDetails, "prptySubTypes", []);
  const isFavourite = _.get(adDetails, "isFavourite", "unload");
  const [introductionList, setIntroductionList] = useState([]);
  const [introCount, setIntroCount] = useState(0);
  const WantCreatedUserId = _.get(adDetails, "userId", false);
  const userType = getUserType();
  const loggedInUserId = getUserId();
  const pageTopRef = useRef(null);
  

  useEffect(() => {
    window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section
    getWantAdDetails(wantAdId);
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section
  //   const showSuccess = _.get(wantAd, "showSuccess", null);
  //   if (showSuccess && !isFromHomeFeed && !isFromAllIntroduction) {
  //     setSuccessModal(true);
  //   } else if (!isFromHomeFeed && !isFromAllIntroduction) {
  //     // showStrengthen && setShareModal(true);
  //     // setShareModal(false);
  //     if (!introId && !fromStrengthenWantAd && isEdit == false) {
  //       setShareModal(true);
  //     }
  //   }
  //   getWantAdDetails(wantAdId);
  // }, [introId]);

  useEffect(() => {
    if (_.get(adDetails, "isAgentCreated", "") != 1) {
      if (
        (adTypeBasedOnProperty === BUY ||
          adTypeBasedOnProperty === LONG_RENT) &&
        !_.get(adDetails, "isStrengthen", false) &&
        !isFromHomeFeed &&
        !isFromAllIntroduction &&
        !introId &&
        isEdit == false
      ) {
        setShowStrengthen(true);
      } else {
        setShowStrengthen(false);
      }
    }

    // setIsFavourite(isFavourite);
  }, [adDetails]);


  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdById(wantAdId, loggedInUserId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setSkeltonLoad(false);
            setAdDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      // navigate(pageURLs.createwantad);
      // navigate(pageURLs.mobileHome);
    }
  };


  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }
    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );
  };

  const loadStrengthenDetails = () => {
    if (adType === BUY) {
      return (
        <>
          {" "}
          {(_.get(adDetails, "prchTypeTxt", "") ||
            _.get(adDetails, "purchaseText", "")) && (
            <li>
              <span className="list-icn-wrap-mob">
              <img src={PrResIconImage} alt={PrResIconImage} />
              </span>
              {_.get(adDetails, "prchTypeTxt", "") ||
                _.get(adDetails, "purchaseText", "")}
                  {(_.get(adDetails, "is1031Exchange", "") == "Yes" || _.get(adDetails, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
            </li>
          )}
          {(_.get(adDetails, "finTypeTxt", "") ||
            _.get(adDetails, "pFinanceText", "")) && (
            <li>
              <span className="list-icn-wrap-mob">
                <img src={FinanceIconImage} alt={FinanceIconImage} />
              </span>
              Financing:{" "}
              {_.get(adDetails, "finTypeTxt", "") ||
                _.get(adDetails, "pFinanceText", "")}
              {(_.get(adDetails, "isPreApprLoan", "") == "Yes" ||
                _.get(adDetails, "isPreApprLoan", "") == 1) &&
                " - pre approved"}
            </li>
          )}
        </>
      );
    } else if (adType === LONG_RENT) {
      return (
        <>
          {" "}
          {_.get(adDetails, "creditScoreText", "") && (
            <li>
              <span className="list-icn-wrap-mob">
                <img src={FinanceIconImage} alt={FinanceIconImage} />
              </span>
              Credit Score : {_.get(adDetails, "creditScoreText", "")}
            </li>
          )}
        </>
      );
    }
  };
 

  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(adDetails, "isMobileView");
  };

  const loadTimeLine = () => {
    return loadTimeLineHtml(
      adType,
      adTypeBasedOnProperty,
      adDetails,
      "isMobileView"
    );
  };

  const handleStrengthenDismiss = () => setShowStrengthen(false);

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isAdTypeIsLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };
  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <>
            <div className="property-tag-mobile mb-2" key={id + uniqueType}>
              <div class="tag-icon-mobile">
                <img
                  src={icon ? require("../../../images/" + icon) : LakeViewImage}
                  alt={icon ? require("../../../images/" + icon) : LakeViewImage}
                />
              </div>
              <span>{itemText}</span>
            </div>
          </>
        );
      })
    );
  };

  const isNiceToHaveExist = nice2Have.length > 0;

  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {adType === BUY
        ? getTooltipText(propertyType)
        : propertySubTypes &&
          propertySubTypes.length > 0 &&
          getCombinedText(propertySubTypes)}
    </Tooltip>
  );

  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      adDetails: adDetails,
    });
  };

  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix-new");
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleReportPopup = (isShow) => setReportModal(isShow);

  let noBathRoom = _.get(adDetails, "noBathRm", "");
  let noBedRoom = _.get(adDetails, "noBedRm", "");
  if (noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
  if (noBedRoom !== "Any") noBedRoom = noBedRoom + "+";



  const handleBackClick = () => {
    const isFromFav = _.get(wantAd, "isFromFavorite", false);
    const clientId = _.get(wantAd, "clientId", null);
    const clientName = _.get(wantAd, "clientName", null);
    const favCount = _.get(wantAd, "favCount", null);

    if (isFromFav) {
      navigate(pageURLs.mobileWantAdFavorites, {
        state: {
          clientName: clientName,
          clientId: clientId,
          favCount: favCount,
        },
      });
    } else {
      navigate(pageURLs.mobileHome);
    }
  };

  return (
    <>
     <span ref={pageTopRef}></span>
      <div className="gitcha-main-wrapper pb-0 bg-white">
        <div className="content-wrapper h-100">
          <div className="inner-layout-header-new bg-white position-top-header">
            <div className="top-three-comp-header w-100 top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
              <a
                  role="button"
                  className="back-link-btn me-4"
                  onClick={() =>props.setUIKey(MESSAGES_VIEW)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>

                <div class="w-100 text-center">Want-Ad</div>
              </div>
            </div>
          </div>

          <div
            className="home-layout-content h-100 bg-white"
            // onScroll={handleScroll}
            // ref={scrollDivRef}
          >
            <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white">
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    I'm looking to:{" "}
                    <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                      {" "}
                      {loadAdTitle()}
                    </span>
                  </div>
                  <h2>{_.get(adDetails, "adTitle", "")} </h2>
                  <span className="trailing-txt pb-1">
                  {locationDisplay(adDetails)}
                    {/* Within {_.get(adDetails, "mapRadius", "")} mi of{" "}
                    {_.get(adDetails, "city", "")}
                    {", "}
                    {_.get(adDetails, "state", "")} */}
                  </span>
                </div>
                <div className="property-details-mob f-regular mb-4">
                  {getDescriptionText(adDetails)}
                </div>
              </div>

              <div className="property-listing-ad-mob">
                <div className="property-type-wrap-mob top-pos-0 left-pos-0">
                  {/* <div className="property-tile w-auto"> */}
                    <OverlayTrigger
                      placement="bottom-start"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <div className="property-tile w-auto">
                        {propertyType === IS_LAND ||
                        propertyType === IS_MULTI_FAMILY ? (
                          <img
                            src={getPropertyIcon(propertyType)}
                            alt={getPropertyIcon(propertyType)}
                            className="property-icon-img"
                          />
                        ) : (
                          propertySubTypes &&
                          propertySubTypes.length > 0 &&
                          propertySubTypes.map((subtype) => {
                            return (
                              <img
                                src={getPropertyIcon(subtype)}
                                alt={getPropertyIcon(subtype)}
                                className="property-icon-img"
                              />
                            );
                          })
                        )}
                      </div>
                    </OverlayTrigger>
                  {/* </div> */}
                </div>
                <div className="property-img-wrapad-mob h-200">
                  <img
                    src={mapImage + "?" + _.get(adDetails, "lat", null)}
                    alt={mapImage}
                  />
                </div>
              </div>

              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h2>Must-haves</h2>
                <div className="row">{loadAdMustHaves()}</div>
              </div>
              {isNiceToHaveExist ? (
                <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                  <h2>Nice-to-haves</h2>
                  {finalNiceToHaveList()}
                </div>
              ) : (
                ""
              )}

              {_.get(adDetails, "adDesc", "") ? (
                <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                  <h2>Description</h2>
                  <p>{_.get(adDetails, "adDesc", "")}</p>
                </div>
              ) : (
                ""
              )}

              <div className="buyer-info-mob brdr-top-common-wrap pb-32">
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap-mob">
                      <img src={ConsumerIconImage} />
                    </span>
                    {_.get(adDetails, "isAgentCreated", "") == 1
                      ? " Represented by Agent"
                      : consumerType}
                  </li>
                  {(adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&

                    _.get(adDetails, "isAgentCreated", "") == 1 &&
                    _.get(adDetails, "compenationType", "") ? (
                      <li>
                        <span className="list-icn-wrap-mob">
                          <img src={FinanceIconImage} />
                        </span>
                        {loadCompensationText(parseInt(_.get(adDetails, "compenationType", "")),_.get(adDetails, "agentCompenation", ""))}
                        {/* Minimum requested buyer's agent compensation:{" "}
                        {_.get(adDetails, "agentCompenation", "")
                          ? _.get(adDetails, "agentCompenation", "")
                          : 0}
                        % */}
                      </li>
                    ): ""}

                  {loadTimeLine()}
                  {loadPreferredLocation()}
                </ul>
              </div>

              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>

        {/* {scrollDirection !== SCROLL_DIRECTION_DOWN && (
          <div className="inner-layout-footer-new inner-layout-footer-topfix">
            <div className="bottomMenu bottom-menu-index">
              <MenuBottomBar />
            </div>
          </div>
        )} */}
      </div>
     
    </>
  );
};

export default ViewWantAd;
