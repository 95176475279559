import React, { useState, useEffect, useRef } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import TextArea from "../../../components/Common/TextArea";
import ValidationAlertIcon from "../../images/validation-alert-icon.svg";
import { trimString , upperCaseEachSentenceFirst} from "../../../helpers/common";
import { handleTextBoxChange } from "../../../actions/common";
import { textValidations } from "../../../helpers/validations";
import { ERROR_TEXT } from "../../../constants/common";
import { saveClientNote } from "../../../services/agentClientsService";

const AddNoteoffcanvas = (props) => {
  const {show, onHide, clientId, loadClientNotes} = props;

  const inputRef = useRef(null);
  const [descError, setDescError] = useState(null);
  const [noteCharCount, setNoteCharCount] = useState(0);
  const [note, setNote] = useState('');
  const [isDisableButton, setDisable] = useState(true);

  const [clientNoteCursorPos, setClientNoteCursorPos] = useState(0);
  
  const handleEnterPress = event => {
    if (event.key === 'Enter') {
      //setNote(note+'\n')
    }
  };

  useEffect(() => {
    if(inputRef.current){
      inputRef.current.setSelectionRange(clientNoteCursorPos, clientNoteCursorPos);
    }
   
  }, [clientNoteCursorPos]);

  const handleNoteChange = (e) => {
    setDescError(null)
    const value = upperCaseEachSentenceFirst(handleTextBoxChange(e));
    // const value = (handleTextBoxChange(e));
    if(trimString(value) != ""){
      setClientNoteCursorPos(e.target.selectionStart)
    }
    // if(!textValidations(value)){
    //   setDescError(ERROR_TEXT)
    // } 
    setNoteCharCount(value.length);
    setNote(value)
  };

  const addClientNote = async () => {
    const reqPayload = {clientId : clientId, note: note}
    const res = await saveClientNote(reqPayload);
    if(!_.get(res, 'errorCode', '')){
      setNoteCharCount(0)
      setNote('')
      setDisable(true)
      loadClientNotes();
      onHide();
    }
  }
  const disableButton = () => {
    const isDisabled = true
    setDisable(isDisabled)
  }

  useEffect(() => {
    if(note){
      if(descError){
        disableButton()
      }
      if (trimString(note) !== ""  && textValidations(note)) {
        setDisable(false)
      } else {
        setDescError(ERROR_TEXT)
        disableButton()
      }
    } else {
      disableButton()
    }
  }, [note, descError]);

  return (

    <Offcanvas
     className="mobile-offcanvas offcanvas-fixed edit-client-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}
      onEntered={() => inputRef.current.focus()}>
        <Offcanvas.Header>
            <Offcanvas.Title>Add Note</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-edit-wrap">
            <div className="edit-info">
                <div className="overflowXhide" >
                    <div className="mob-fld-wrap position-relative">
                        {/* <textarea class="form-control txtAreaStyle" rows="3"></textarea> */}
                        <TextArea
                          className="form-control txtAreaStyle"
                          rows="3"
                          errorMessage={descError}
                          maxLength={500}
                          value ={note}
                          onChange={handleNoteChange}
                          onKeyDown = {handleEnterPress}
                          txtRef={inputRef}
                        />
                        <div className="des-count"><span>{noteCharCount}</span>/<span>500</span></div>
                    </div>
                </div>
            </div>
            <div className="inner-btm-fixed-nav">
                    <div className="btn-wrap">
                        <button type="button" className="btn btn-primary w-100" onClick={ () => addClientNote()} disabled={isDisableButton}>Save Note</button>
                    </div>
                </div>
          </div>
        </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default AddNoteoffcanvas;
