import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "react-bootstrap";
import "../styles/clients.scss";
import PaymentActionDropdown from "../components/Settings/PaymentActionDropdown";
import { getCardIcon, getUserId, setBodyBackgroundWhite } from "../../helpers/common";
import { fetchPaymentMethods } from "../../actions/payments";
import { updateDefaultPayment } from "../../services/paymentsService";
import { toast } from "react-toastify";
import PaymentHistoryListing from "../components/Settings/PaymentHistoryListing";

const PaymentHistory = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reload, setReload] = useState(0);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods
  );
  const [userPaymentMethods, setUserPaymentmethods] = useState(paymentMethods);
  const userId = getUserId();

  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, [userId, reload]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      const defaultArray = paymentMethods?.filter(
        (item) => item.IsDefault === 1
      );
      const OtherMethods = paymentMethods?.filter(
        (item) => item.IsDefault !== 1
      );
      setUserPaymentmethods(defaultArray.concat(OtherMethods));
    } else {
      setUserPaymentmethods([]);
    }
  }, [paymentMethods]);

  useEffect(() => {
    setBodyBackgroundWhite()
    document.body.classList.add("bg-offcanvas-fix");
  }, []);

  const handleSetAsDefault = async (paymentMethod) => {
    const payload = {
      PaymentMethodId: paymentMethod?.Payment_Mode_Id,
    };
    await updateDefaultPayment(payload)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.dismiss();
          toast.success("Successfully updated the default payment method..");
          setTimeout(() => {
            updateReloadParam();
          }, 500);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  return (
    <div className="gitcha-main-wrapper pb-0 bg-white">
      <div className="content-wrapper h-100">
        <div className="inner-layout-header-new bg-white">
          <div className="review-plan-header header-brdr-btm w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              <a
                className="back-link-small"
                href={void 0}
                onClick={() => navigate(pageURLs.mobileSettings)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
              <span>Payments</span>
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new">
          {!userPaymentMethods?.length > 0 ? (
            <div className="pro-premium-wrap">
              <div class="justify-content-between d-flex mb-2">
                <span className="titleSubhead">Payment Method</span>
              </div>

              <div>
                <Button
                  variant="primary"
                  className="btn btn-primary "
                  onClick={() => navigate(pageURLs.mobileAddPaymentMethod)}
                >
                  Add Payment Method &nbsp;
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 6C0 5.72386 0.223858 5.5 0.5 5.5H11.5C11.7761 5.5 12 5.72386 12 6C12 6.27614 11.7761 6.5 11.5 6.5H0.5C0.223858 6.5 0 6.27614 0 6Z"
                      fill="#ffffff"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 0C6.27614 0 6.5 0.223858 6.5 0.5V11.5C6.5 11.7761 6.27614 12 6 12C5.72386 12 5.5 11.7761 5.5 11.5V0.5C5.5 0.223858 5.72386 0 6 0Z"
                      fill="#ffffff"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          ) : (
            <div className="pro-premium-wrap">
              <div class="justify-content-between d-flex mb-2">
                <span className="titleSubhead">Payment Method</span>
                {userPaymentMethods?.length > 0 && (
                  <button
                    type="button"
                    class="btn btnTop"
                    onClick={() => navigate(pageURLs.mobileAddPaymentMethod)}
                  >
                    Add{" "}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 6C0 5.72386 0.223858 5.5 0.5 5.5H11.5C11.7761 5.5 12 5.72386 12 6C12 6.27614 11.7761 6.5 11.5 6.5H0.5C0.223858 6.5 0 6.27614 0 6Z"
                        fill="#009CDA"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 0C6.27614 0 6.5 0.223858 6.5 0.5V11.5C6.5 11.7761 6.27614 12 6 12C5.72386 12 5.5 11.7761 5.5 11.5V0.5C5.5 0.223858 5.72386 0 6 0Z"
                        fill="#009CDA"
                      />
                    </svg>{" "}
                  </button>
                )}
              </div>

              {userPaymentMethods?.map((paymentMethod, key = index) => {
                return (
                  <a className="txt-no-underline link-cursor mb-2 d-block">
                    <div className="add-your-property-wrap mh-auto mb-0 brdr-rds-10 border-0">
                      <div className="add-property-btn">
                        {/* <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8832 8.24628L10.2798 15.7425H8.34041L9.94398 8.24628H11.8832ZM20.0422 13.0867L21.063 10.2717L21.6504 13.0867H20.0422ZM22.2067 15.7425H24L22.4334 8.24628H20.7792C20.4064 8.24628 20.0921 8.46243 19.953 8.79575L17.0431 15.7425H19.0799L19.4842 14.623H21.9719L22.2067 15.7425ZM17.1441 13.2952C17.1526 11.3169 14.4092 11.2073 14.4276 10.3233C14.4335 10.0547 14.6898 9.76859 15.2499 9.69542C15.5276 9.65967 16.2939 9.63067 17.1625 10.0309L17.5022 8.44068C17.0357 8.27191 16.4353 8.10938 15.6883 8.10938C13.7711 8.10938 12.4224 9.12773 12.4116 10.5872C12.3993 11.6664 13.375 12.2681 14.1086 12.6276C14.865 12.995 15.1184 13.2305 15.1147 13.5588C15.1094 14.0617 14.5116 14.2844 13.9549 14.2929C12.9793 14.308 12.4138 14.0292 11.9632 13.8191L11.6111 15.4624C12.065 15.6702 12.9013 15.8509 13.7672 15.8602C15.8054 15.8602 17.1381 14.8538 17.1441 13.2952ZM9.1121 8.24628L5.96986 15.7425H3.92017L2.37375 9.75999C2.28001 9.3921 2.19823 9.25688 1.91313 9.10143C1.44678 8.84819 0.676937 8.6113 0 8.46395L0.0458603 8.24628H3.34574C3.76606 8.24628 4.14424 8.52599 4.24051 9.01022L5.05739 13.3483L7.07471 8.24628H9.1121Z"
                      fill="#1434CB"
                    />
                  </svg> */}
                        <img src={getCardIcon(paymentMethod.Brand)} />
                      </div>
                      <div className="make-intro-details pl-14">
                        <h4 className="mb-2">
                          {paymentMethod.Brand} {paymentMethod.Last4}{" "}
                          {paymentMethod?.IsDefault === 1 && (
                            <span className="table-badge-blue">Default</span>
                          )}
                        </h4>
                        <p>
                          Exp{" "}
                          {paymentMethod?.Exp_Month < 10
                            ? "0" + paymentMethod?.Exp_Month
                            : "" + paymentMethod?.Exp_Month}
                          /{String(paymentMethod?.Exp_Year).slice(-2)}
                        </p>
                      </div>
                      <div className="social-btns-wrap grey-bg-toggle">
                        <PaymentActionDropdown
                          paymentMethod={paymentMethod}
                          setAsDefault={handleSetAsDefault}
                          updateReloadParam={updateReloadParam}
                        />
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          )}
          <PaymentHistoryListing></PaymentHistoryListing>          
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
