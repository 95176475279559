import { createSlice } from "@reduxjs/toolkit";
import { getIntroClients, fetchClientNotesForExports } from "../actions/agentClients";
const initialState = {
  clientsLoading : false,
  clients : {},
  clientsError : "",
  clientNotesLoading : false,
  clientNotesForExport : {},
  clientNotesError : ""
};

const agentClients = createSlice({
  name: "agentClients",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getIntroClients.pending, (state, action) => {
      state.clientsLoading = true;
      state.clients = {};
      state.clientsError = "";
    });

    builder.addCase(getIntroClients.fulfilled, (state, action) => {
      state.clientsLoading = false;
      state.clients = action.payload.result;
      state.clientsError = "";
    });

    builder.addCase(getIntroClients.rejected, (state, action) => {
      state.clientsLoading = false;
      state.clients = {};
      state.clientsError = action?.error?.message;
    });   
    builder.addCase(fetchClientNotesForExports.pending, (state, action) => {
      state.clientNotesLoading = true;
      state.clientNotesForExport = {};
      state.clientNotesError = "";
    });
    builder.addCase(fetchClientNotesForExports.fulfilled, (state, action) => {
      state.clientNotesLoading = false;
      state.clientNotesForExport = action.payload.result;
      state.clientNotesError = "";
    });

    builder.addCase(fetchClientNotesForExports.rejected, (state, action) => {
      state.clientNotesLoading = false;
      state.clientNotesForExport = {};
      state.clientNotesError = action?.error?.message;
    });



  },
  
});

export default agentClients.reducer;
