import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  IS_PRIVATE_ROOM,
  STORY_ANY,
  NEW_CONSTRUCTION,
  IMG_JPEG_FORMAT,
  IN_CITY_LIMITS,
  RURAL,
  BUDGET_NOMIN_NOMAX,
} from "../constants/common";
import {
  convertToCurrencySystem,
  durationForFlexibleDate,
  filterArrayBasedOnKey,
  formateDate,
  monthNameForFlexibleDate,
  removeDecimalZeros,
} from "./common";
import { convertToNumber } from "./validations";
import {
  saveBuyerListingWantAd,
  saveMemberWantAd,
} from "../services/wantAdService";
import FixerUpperImg from "../images/fixer-upper.svg";
import ShortSaleImg from "../images/shortSale-icn.svg";
import GovtAssisImg from "../images/govt-icn.svg";
import RentalDollarImg from "../images/rentalDollar-icn.svg";
import VacationRental from "../images/vacationUmbrella-icn.svg";
import VacantPropertyImg from "../images/vacantUser-icn.svg";

export const saveToDraft = async ({ requestPayLoad, userId }) => {
  requestPayLoad.userId = userId;
  requestPayLoad.IsSavedDraft = true;
  requestPayLoad.IsPublished = false;
  requestPayLoad.cmpldStep = _.get(requestPayLoad, "actvStep", 1);
  delete requestPayLoad.tlText;
  delete requestPayLoad.prptyTypeTxt;
  delete requestPayLoad.wantAdValErrors;
  let arrayValue = [];
  if (requestPayLoad?.zipIdValues) {
    requestPayLoad?.zipIdValues.map((zip) => {
      arrayValue.push(zip?.zipId);
    });
    requestPayLoad.zipId = arrayValue;
  }
  requestPayLoad.isZipcodeBased =
    requestPayLoad.zipId?.length > 0 ? true : false;
  return await saveMemberWantAd(requestPayLoad)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("Error", err);
    });
};

export const saveToDraftBuyerListing = async ({ requestPayLoad }) => {
  requestPayLoad.IsSavedDraft = true;
  requestPayLoad.IsPublished = false;
  requestPayLoad.cmpldStep = _.get(requestPayLoad, "actvStep", 1);
  delete requestPayLoad.tlText;
  delete requestPayLoad.prptyTypeTxt;
  delete requestPayLoad.wantAdValErrors;
  return await saveBuyerListingWantAd(requestPayLoad)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("Error", err);
    });
};

export const lotSizeList = ({ list, filterKey, value, isNotEqual }) => {
  filterArrayBasedOnKey({
    list: list,
    filterKey: filterKey,
    value: value,
    isNotEqual: isNotEqual,
  });
};

export const setSqftValue = (value) => {
  return value == 5000 ? value + "+" : value;
};

export const setLotSizeValue = (minValue, maxValue) => {
  let minLtzValue =
    minValue == 0.25
      ? "1/4"
      : minValue == 0.5
      ? "1/2"
      : minValue == 100
      ? "100+"
      : minValue;
  let maxLtzValue =
    maxValue == 0.25
      ? "1/4"
      : maxValue == 0.5
      ? "1/2"
      : maxValue == 100
      ? "100+"
      : maxValue;

  // if(minValue == 0.25 ){
  //   value = 1/4

  // } else if(value == 0.50){
  //   value = 1/2

  // }
  let unit;
  if (maxLtzValue == "100+") {
    unit = " acres";
  } else if (convertToNumber(maxLtzValue) > 1) {
    unit = " acres";
  } else {
    unit = " acre";
  }

  let ltSize;
  if (minLtzValue && maxLtzValue) {
    ltSize = `${minLtzValue} - ${maxLtzValue} ${unit}`;
  } else if (!minLtzValue && maxLtzValue) {
    ltSize = `No min - ${maxLtzValue} ${unit}`;
  } else if (minLtzValue && !maxLtzValue) {
    ltSize = `${minLtzValue} - No max ${unit}`;
  } else {
    //ltSize = `No min - No max`;
    ltSize = `Any`;
  }

  return ltSize;

  // if (convertToNumber(value) > 1) {
  //   value = value == 100 ? ("100+ acres" ):  value  + " acres";
  // } else{
  //   value = value  + " acre"
  // }

  // return value ;

  // console.log(ltSize);
};

export const wantAdSqftValue = (Sqft_mn, Sqft_mx) => {
  let sqFt = "";
  const sqFtMin = setSqftValue(removeDecimalZeros(Sqft_mn));
  const sqFtMax = setSqftValue(removeDecimalZeros(Sqft_mx));

  if (sqFtMin && sqFtMax) {
    sqFt = `${sqFtMin} - ${sqFtMax}`;
  } else if (!sqFtMin && sqFtMax) {
    sqFt = `No min - ${sqFtMax}`;
  } else if (sqFtMin && !sqFtMax) {
    sqFt = `${sqFtMin} - No max`;
  } else {
    // sqFt = `No min - No max`;
    sqFt = `Any`;
  }
  return sqFt;
};

export const wantAdBudgetValue = (bdgtMin, bdgtMax) => {
  let budget = "";
  const budgetMin = convertToCurrencySystem(bdgtMin);
  const budgetMax = convertToCurrencySystem(bdgtMax);

  if (budgetMin && budgetMax) {
    budget = `$${budgetMin} - $${budgetMax}`;
  } else if (!budgetMin && budgetMax) {
    budget = `No min - $${budgetMax}`;
  } else if (budgetMin && !budgetMax) {
    budget = `$${budgetMin} - No max`;
  } else {
    //budget = `No min - No max`;
    budget = `No min - No max`;
  }
  return budget;
};

export const buildWantAdMustHavesHTML = ({
  adType,
  adTypeBasedOnProperty,
  formValues,
}) => {
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  let garrageSize = _.get(formValues, "grgSpce", "") || "Any";
  if (garrageSize) {
    if (garrageSize !== "Any") {
      garrageSize = garrageSize + "+";
    }
  }
  // const budget =
  //   convertToCurrencySystem(_.get(formValues, "minBdgt", "")) +
  //   " - " +
  //   convertToCurrencySystem(_.get(formValues, "maxBdgt", ""));
  const budget = wantAdBudgetValue(
    _.get(formValues, "minBdgt", ""),
    _.get(formValues, "maxBdgt", "")
  );
  let ltSize = "";
  let sqFt = "";
  ltSize = setLotSizeValue(
    removeDecimalZeros(_.get(formValues, "minLtSz", null)),
    removeDecimalZeros(_.get(formValues, "maxLtSz", null))
  );

  // const maxLtSz = setLotSizeValue(_.get(formValues, "maxLtSz", null));
  const sqFtMin = setSqftValue(
    removeDecimalZeros(_.get(formValues, "sqFtMin", null))
  );
  const sqFtMax = setSqftValue(
    removeDecimalZeros(_.get(formValues, "sqFtMax", null))
  );
  // const ltSizeDisplayUnit = _.get(formValues, "mxLtSzDisplay", null);
  const adaAccess = _.get(formValues, "adaAccess", "");
  // if (minLtSz && maxLtSz) {
  //   ltSize = `${minLtSz} - ${maxLtSz} ${ltSizeDisplayUnit}`;
  // } else if (!minLtSz && maxLtSz) {
  //   ltSize = `No min - ${maxLtSz} ${ltSizeDisplayUnit}`;
  // } else if (minLtSz && !maxLtSz) {
  //   ltSize = `${minLtSz} - No max`;
  // } else {
  //   ltSize = `No min - No max`;
  // }
  if (sqFtMin && sqFtMax) {
    sqFt = `${sqFtMin} - ${sqFtMax}`;
  } else if (!sqFtMin && sqFtMax) {
    sqFt = `No min - ${sqFtMax}`;
  } else if (sqFtMin && !sqFtMax) {
    sqFt = `${sqFtMin} - No max`;
  } else {
    // sqFt = `No min - No max`;
    sqFt = `Any`;
  }
  if (adTypeBasedOnProperty === BUY) {
    const yearBuiltOption = _.get(formValues, "yearBuiltOption", null);
    const builtYrMin = _.get(formValues, "yrBltMin", "");
    const builtYrMax = _.get(formValues, "yrBltMax", "");

    let builtYear = "";
    let stories = _.get(formValues, "stories", null);
    if (stories) {
      stories = stories === STORY_ANY ? "Any" : "Single Story Only";
    }

    if (yearBuiltOption) {
      builtYear =
        yearBuiltOption === NEW_CONSTRUCTION
          ? "New Construction"
          : `${builtYrMin} - ${builtYrMax}`;
    }

    let noBathRoom = _.get(formValues, "noBathRm", "");
    let noBedRoom = _.get(formValues, "noBedRm", "");
    if (noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom !== "Any") noBedRoom = noBedRoom + "+";

    return (
      <>
        {" "}
        <div className="musthave-wrap">
          <h2>Must-haves</h2>{" "}
          <ul>
            <li>
              <span className="musthave-label">Budget</span>
              <span className="musthave-value"> {budget}</span>
            </li>
            <li>
              <span className="musthave-label">Beds</span>
              <span className="musthave-value">{noBedRoom}</span>
            </li>
            <li>
              <span className="musthave-label">Baths</span>
              <span className="musthave-value">{noBathRoom}</span>
            </li>
            <li>
              <span className="musthave-label">Sqft</span>
              <span className="musthave-value">{sqFt}</span>
            </li>
            {stories && stories != 0 && (
              <li>
                <span className="musthave-label">Stories</span>
                <span className="musthave-value"> {stories}</span>
              </li>
            )}
            {/* </ul>
            <ul> */}
            <li>
              <span className="musthave-label">Garage spaces</span>
              <span className="musthave-value">{garrageSize}</span>
            </li>
            {ltSize && (
              <li>
                <span className="musthave-label">Lot size</span>
                <span className="musthave-value">{ltSize}</span>
              </li>
            )}

            {builtYear && (
              <li>
                <span className="musthave-label">Year Built</span>
                <span className="musthave-value">{builtYear}</span>
              </li>
            )}
            {adaAccess == true && adaAccess != 0 && (
              <li>
                <span className="musthave-label">ADA Accessible</span>
                <span className="musthave-value">
                  {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                  Yes
                </span>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  } else if (adTypeBasedOnProperty === LONG_RENT) {
    let noBathRoom = _.get(formValues, "noBathRm", "") || "Any";
    let noBedRoom = _.get(formValues, "noBedRm", "") || "Any";
    if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
    const alwLargeDogs = _.get(formValues, "alwLrgDogs", "");
    const alwSmlDogs = _.get(formValues, "alwSmlDogs", "");
    const alwCats = _.get(formValues, "alwCats", "");

    const alwPets = _.get(formValues, "alwPets", "");
    const accsPvtBthRm = _.get(formValues, "accsPvtBthRm", "");
    const pvtEntrnce = _.get(formValues, "pvtEntrnce", "");
    let stories = _.get(formValues, "stories", null);
    if (stories) {
      stories = stories === STORY_ANY ? "Any" : "Single Story Only";
    }
    return (
      <>
        {" "}
        <div className="musthave-wrap">
          <h2>Must-haves</h2>{" "}
          <ul>
            <li>
              <span className="musthave-label">Budget</span>
              <span className="musthave-value"> {budget != BUDGET_NOMIN_NOMAX ? budget + "/Month" : budget}</span>
            </li>
            {propertyType !== IS_PRIVATE_ROOM ? (
              <>
                <li>
                  <span className="musthave-label">Beds</span>
                  <span className="musthave-value">{noBedRoom}</span>
                </li>
                <li>
                  <span className="musthave-label">Baths</span>
                  <span className="musthave-value">{noBathRoom}</span>
                </li>
                <li>
                  <span className="musthave-label">Sqft</span>
                  <span className="musthave-value">{sqFt}</span>
                </li>

                <li>
                  <span className="musthave-label">Garage spaces</span>
                  <span className="musthave-value">{garrageSize}</span>
                </li>
                {stories && stories != 0 && (
                  <li>
                    <span className="musthave-label">Stories</span>
                    <span className="musthave-value"> {stories}</span>
                  </li>
                )}

                {alwLargeDogs == true && alwLargeDogs != 0 && (
                  <li>
                    <span className="musthave-label">Allows large dogs</span>
                    <span className="musthave-value">
                      {/* {" "}
                      {_.get(formValues, "alwLrgDogs", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {alwSmlDogs == true && alwSmlDogs != 0 && (
                  <li>
                    <span className="musthave-label">Allows small dogs</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "alwSmlDogs", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {alwCats == true && alwCats != 0 && (
                  <li>
                    <span className="musthave-label">Allows cats</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "alwCats", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {adaAccess == true && adaAccess != 0 && (
                  <li>
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
              </>
            ) : (
              <>
                {" "}
                {adaAccess == true && adaAccess != 0 && (
                  <li>
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {/* <li>
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {_.get(formValues, "adaAccess", "") ? "Yes" : "No"}
                    </span>
                  </li> */}
                {alwPets == true && alwPets != 0 && (
                  <li>
                    <span className="musthave-label">Pets allowed</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "alwPets", "") ? "All" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {accsPvtBthRm == true && accsPvtBthRm != 0 && (
                  <li>
                    <span className="musthave-label">
                      Access to private bathroom
                    </span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "accsPvtBthRm", null) ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {pvtEntrnce == true && pvtEntrnce != 0 && (
                  <li>
                    <span className="musthave-label">Private entrance</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "accsPvtBthRm", null) ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      </>
    );
  } else if (adTypeBasedOnProperty === LOT_LAND) {
    let onSiteElectricity = _.get(formValues, "onSiteElec", "");
    if (onSiteElectricity === "true") {
      onSiteElectricity = "Yes";
    } else if (onSiteElectricity === "false") {
      onSiteElectricity = "No";
    } else {
      onSiteElectricity = null;
    }
    const lotTypeValue = _.get(formValues, "lotType", null);
    const lotType =
      lotTypeValue && (lotTypeValue === 1 ? RURAL : IN_CITY_LIMITS);

    const roadTypeValue = _.get(formValues, "roadType", null);
    const roadType =
      roadTypeValue && (roadTypeValue === 1 ? "City roads" : "Private drive");

    const waterSupplyValue = _.get(formValues, "waterSupply", "");
    const waterSupply =
      waterSupplyValue &&
      (waterSupplyValue === 1 ? "Domestic water supply" : "Drilled well");

    const wasteWtrSysValue = _.get(formValues, "wasteWtrSys", null);
    const wasteWater =
      wasteWtrSysValue && (wasteWtrSysValue === 1 ? "Sewer" : "Septic system");

    // const lotType = _.get(formValues, "lotTypeText", null);
    // const roadType = _.get(formValues, "rdTypText", null);
    // const waterSupply = _.get(formValues, "wSText", null);
    // const wasteWater = _.get(formValues, "wWSysText", null);
    return (
      <>
        {" "}
        <div className="musthave-post-wrap">
          <h2>Must-haves</h2>
          <ul>
            <li>
              <span className="musthave-label text-capitalize list-item-color">
                Budget
              </span>
              <span className="musthave-value"> {budget}</span>
            </li>
            {/* <li className="w-14">
                <span className="musthave-label text-capitalize list-item-color">
                  Acreage
                </span>
                <span className="musthave-value">
                  {_.get(formValues, "acreageMin", "")} -{" "}
                  {_.get(formValues, "acreageMax", "")}
                </span>
              </li> */}
            {ltSize && (
              <li>
                <span className="musthave-label">Lot size</span>
                <span className="musthave-value">{ltSize}</span>
              </li>
            )}
            {lotType && (
              <li>
                <span className="musthave-label text-capitalize list-item-color">
                  Lot Type
                </span>
                <span className="musthave-value">
                  {/* {_.get(formValues, "lotTypeText", "") || "Any"} */}
                  {lotType}
                </span>
              </li>
            )}
            {roadType && (
              <li>
                <span className="musthave-label text-capitalize list-item-color">
                  Road Type
                </span>
                <span className="musthave-value">
                  {roadType == "" && _.get(formValues, "isPrvtDrInst", "") == ""
                    ? "Any"
                    : ""}
                  {roadType}
                  {_.get(formValues, "isPrvtDrInst", "") ? "," : ""}
                  <br />
                  {_.get(formValues, "isPrvtDrInst", "") ? "Installed" : ""}
                </span>
              </li>
            )}

            {onSiteElectricity && (
              <li>
                <span className="musthave-label text-capitalize list-item-color">
                  Electricity
                </span>
                <span className="musthave-value">{onSiteElectricity}</span>
              </li>
            )}
            {waterSupply && (
              <li>
                <span className="musthave-label text-capitalize list-item-color">
                  Water Supply
                </span>
                <span className="musthave-value">
                  {waterSupply == "" &&
                  _.get(formValues, "isDrldWelInst", "") == ""
                    ? "Any"
                    : ""}
                  {waterSupply}
                  {_.get(formValues, "isDrldWelInst", "") ? ", Installed" : ""}
                </span>
              </li>
            )}

            {wasteWater && (
              <li>
                <span className="musthave-label text-capitalize list-item-color">
                  Wastewater
                </span>
                <span className="musthave-value">
                  {wasteWater == "" &&
                  _.get(formValues, "isSepTankInst", "") == "" &&
                  _.get(formValues, "isSepTankAppr", "") == ""
                    ? "Any"
                    : ""}
                  {wasteWater}
                  {_.get(formValues, "isSepTankInst", "") ? ", Installed" : ""}
                  {_.get(formValues, "isSepTankAppr", "") ? ", Approved" : ""}
                </span>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  } else if (adTypeBasedOnProperty === SHORT_RENT) {
    let noBathRoom = _.get(formValues, "noBathRm", "") || "Any";
    let noBedRoom = _.get(formValues, "noBedRm", "") || "Any";
    let noBeds = _.get(formValues, "noOfBeds", "") || "Any";
    if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
    if (noBeds && noBeds !== "Any") noBeds = noBeds + "+";

    const alwPets = _.get(formValues, "alwPets", "");
    const pvtEntrnce = _.get(formValues, "pvtEntrnce", "");
    const accsPvtBthRm = _.get(formValues, "accsPvtBthRm", "");

    let Dates = "";
    if (
      _.get(formValues, "startDate", "") &&
      _.get(formValues, "endDate", "")
    ) {
      Dates =
        formateDate(_.get(formValues, "startDate", ""), "mm/dd/yyyy") +
        " - " +
        formateDate(_.get(formValues, "endDate", ""), "mm/dd/yyyy");
    } else {
      Dates =
        durationForFlexibleDate(_.get(formValues, "duration", "")) +
        " in " +
        monthNameForFlexibleDate(_.get(formValues, "month", ""));
    }

    return (
      <>
        {" "}
        <div className="musthave-wrap">
          <h2>Must-haves</h2>{" "}
          <ul>
            <li>
              <span className="musthave-label">Budget</span>
              <span className="musthave-value "> {budget != BUDGET_NOMIN_NOMAX ? budget + "/Night" : budget}</span>
            </li>
            {propertyType !== IS_PRIVATE_ROOM ? (
              <>
                <li>
                  <span className="musthave-label">Bedrooms</span>
                  <span className="musthave-value">{noBedRoom}</span>
                </li>
                <li>
                  <span className="musthave-label">Beds</span>
                  <span className="musthave-value">{noBeds}</span>
                </li>
                <li>
                  <span className="musthave-label">Baths</span>
                  <span className="musthave-value">{noBathRoom}</span>
                </li>
                <li>
                  <span className="musthave-label">Dates</span>
                  <span className="musthave-value">{Dates}</span>
                </li>
                <ul>
                  {" "}
                  {adaAccess == true && adaAccess != 0 && (
                    <li>
                      <span className="musthave-label">ADA Accessible</span>
                      <span className="musthave-value">
                        {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                        Yes
                      </span>
                    </li>
                  )}
                  {alwPets == true && alwPets != 0 && (
                    <li>
                      <span className="musthave-label">Pets allowed</span>
                      <span className="musthave-value">
                        {/* {allowPets ? "All" : "No"} */}
                        Yes
                      </span>
                    </li>
                  )}
                </ul>
              </>
            ) : (
              <>
                <li>
                  <span className="musthave-label">Dates</span>
                  <span className="musthave-value">{Dates}</span>
                </li>
                {adaAccess == true && adaAccess != 0 && (
                  <li>
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {alwPets == true && alwPets != 0 && (
                  <li>
                    <span className="musthave-label">Pets allowed</span>
                    <span className="musthave-value">
                      {/* {allowPets ? "All" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {accsPvtBthRm == true && accsPvtBthRm != 0 && (
                  <li>
                    <span className="musthave-label">
                      Access to private bathroom
                    </span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "accsPvtBthRm", null) ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {pvtEntrnce == true && pvtEntrnce != 0 && (
                  <li>
                    <span className="musthave-label">Private entrance</span>
                    <span className="musthave-value">
                      {/* {allowPets ? "All" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      </>
    );
  }
};

export const dataURLToBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: IMG_JPEG_FORMAT });
};

export const getEditAction = (isFromUnPause, isFromNotification) => {
  if (isFromUnPause || isFromNotification) {
    if (isFromUnPause == true || isFromNotification == true) {
      return 0; // set 0 for unarchive on save wantad
    } else {
      return 1;
    }
  }
};

export const loadInvestmentCriteria = (formValues) => {
  const investmentCriteria = _.get(formValues, "investmentId", []);
  return (
    <>
      {investmentCriteria &&
        investmentCriteria?.length > 0 &&
        investmentCriteria.map((criteria) => {
          switch (criteria) {
            case 1:
              return (
                <li>
                  <span className="list-icn-wrap">
                    <img src={FixerUpperImg} />
                  </span>
                  Fixer-upper
                </li>
              );
            case 2 :
              return (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ShortSaleImg} />
                  </span>
                  Short sale or foreclosure
                </li>
              );
              case 3 :
              return (
                <li>
                  <span className="list-icn-wrap">
                    <img src={GovtAssisImg} />
                  </span>
                  Government assistance programs/Section 8
                </li>
              );
              case 4 :
              return (
                <li>
                  <span className="list-icn-wrap">
                    <img src={RentalDollarImg} />
                  </span>
                  Existing long-term rental
                </li>
              );
              case 5 :
              return (
                <li>
                  <span className="list-icn-wrap">
                    <img src={VacationRental} />
                  </span>
                  Existing vacation rental
                </li>
              );
              case 6 :
              return (
                <li>
                  <span className="list-icn-wrap">
                    <img src={VacantPropertyImg} />
                  </span>
                  Vacant property
                </li>
              );
          }
        })}
    </>
  );
};
