/**
 * @file   src\pages\Messaging.js
 * @brief  This file is responsible for handling messaging/chat screen.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
import { useDispatch, useSelector } from "react-redux";
import MessageLeftSection from "../components/Messages/MessageLeftSection";
import { decodeParam, getLocalStorage, loggedInUser } from "../helpers/common";
import { USER_INFO_KEY } from "../constants/common";
import MessagesRightSection from "../components/Messages/MessagesRightSection";
import { loginOrSignUp } from "../helpers/message";
import { getIntroductionDetaisById } from "../services/introductionService";
import IntroDetails from "../components/Messages/IntroDetails";
import { db } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  or,
  doc,
  getDoc
} from "firebase/firestore";
import AgentProfileViewModal from "../components/Introduction/AgentProfileViewModal";
import SelectDropDown from "../components/Common/SelectDropDown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";

const Messaging = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionData = getLocalStorage(USER_INFO_KEY);
  // const messages = useSelector((state) => state.messages.message);
  const userId = _.get(sessionData, 'userInfo.userId', '');
  const [selectedIntroductionId, setSelectedIntroductionId] = useState(0);
  const [selectedData, setSelectedData] = useState('');
  const [filter, setFilter] = useState('all');
  const userDetails = loggedInUser();
  const [showViewIntroductionModal, setViewIntroductionModal] = useState(false);
  const handleIntroDetailModal = (modalState) => setViewIntroductionModal(modalState);
  const [introDetails, setIntroDetails] = useState([]);
  const [showProfileModal, setProfileModal] = useState(false);
  const ProfileModalClose = () => setProfileModal(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [isFromMessage, setIsFromMessage] = useState(false);
  const [filterList, setFilterList] = useState([]);

  const [message, setMessages] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [introIdFromURL, setIntroIDFromURL] = useState( searchParams.get("Id") ? decodeParam(searchParams.get("Id")) : null);

  const [userDeleted, setUserDeleted] = useState(false);

  const chatMessages = useSelector((state)=>state.unreadCount.chatMessages);
  // const decodedIntroId = introIdFromURL ? decodeParam(introIdFromURL) : null;


  useEffect(() => {
    let email = _.get(userDetails, "userInfo.email", null);
    let login = loginOrSignUp(email);

    if (!login) {
      navigate(pageURLs.home)
    }

  }, []);

  const handleWantAdSelection = async (msgData) => {
      if (msgData?.introductionId) {
        setSelectedIntroductionId(msgData?.introductionId);
        setSelectedData(msgData);
        setIntroIDFromURL(null)
      }
  }


  const handleFilter = (e) => {
    setFilter(e.value);
  }

  const handleFilterValue = (value) => {
    if (filterList && value) {
      const filterValue = filterList.filter(function (lst) {
        return lst.value === value;
      });
      return filterValue;
    }
    return null;
  };



  useEffect(() => {
    // const q = query(collection(db, "Introduction"),
    //   or(where("inventroyCreatedId", "==", userId),
    //     where("wantAdCreatedId", "==", userId)
    //   )

    // );

    // const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
    //   let fetchedMessages = [];
    //   let orderd = [];
    //   QuerySnapshot.forEach((doc) => {
    //     fetchedMessages.push({ ...doc.data(), id: doc.id });
    //   });
    let fetchedMessages = chatMessages?.payload;
    let orderd = [];
    if(fetchedMessages) {
      console.log("fetchedMessages", fetchedMessages)
    
      let assignedToMe = fetchedMessages?.filter((item) => item.wantAdCreatedId == userId && item.isaccepted == true && item.isdeleted == false);
      let assignedByMe = fetchedMessages?.filter((item) => item.inventroyCreatedId == userId && item.isaccepted == true && item.isdeleted == false)

      orderd = [...assignedToMe, ...assignedByMe];

      let allCountLabel = orderd && orderd.length > 0 ? "All conversations (" + orderd.length + ")" : "All conversations";
      let sentByMeCountLabel = assignedByMe && assignedByMe.length > 0 ? "Introductions sent (" + assignedByMe.length + ")" : "Introductions sent";
      let sentToMeCountLabel = assignedToMe && assignedToMe.length > 0 ? "Introduced to me (" + assignedToMe.length + ")" : "Introduced to me";

      const options = [
        { value: 'all', label: allCountLabel },
        { value: 'intro_sent', label: sentByMeCountLabel },
        { value: 'intro_to_me', label: sentToMeCountLabel }
      ];
      setFilterList(options)

      if (filter == "intro_to_me") {
        const doEmptyAction = assignedToMe.every(o => o.createdat)
        if (doEmptyAction) {
          orderd = assignedToMe.sort((a, b) => b.createdat - a.createdat);
          setMessages(orderd);
          setSelectedData(orderd[0]);
          setSelectedIntroductionId(orderd[0]?.introductionId);
        }
      } else if (filter == "intro_sent") {
        const doEmptyAction = assignedByMe.every(o => o.createdat)
        if (doEmptyAction) {
          orderd = assignedByMe.sort((a, b) => b.createdat - a.createdat);
          setMessages(orderd);
          setSelectedData(orderd[0]);
          setSelectedIntroductionId(orderd[0]?.introductionId);
        }
      } else {
        const doEmptyAction = orderd.every(o => o.createdat)
        if (doEmptyAction) {
          let orderdNew = orderd.sort((a, b) => (b.createdat) - (a.createdat));
          setMessages(orderdNew);
          setSelectedData(orderdNew[0]);
          setSelectedIntroductionId(orderdNew[0]?.introductionId);

        }

      }
    } else{
      const options = [
        { value: 'all', label: 0 },
        { value: 'intro_sent', label: 0 },
        { value: 'intro_to_me', label: 0 }
      ];
      setFilterList(options)
      setMessages([]);
      setSelectedData(null);
      setSelectedIntroductionId(null);

    }
    // });

    // return () => unsubscribe;

  }, [chatMessages, filter]);

  const getIntroductionDetails = async (introductionId) => {

    if (introductionId) {
      await getIntroductionDetaisById(introductionId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setIntroDetails(responseResult[0]);
            handleIntroDetailModal(true);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const ViewProfile = (agentId, isFromMessageScreen = false) => {
    handleIntroDetailModal(false);
    setIsFromMessage(isFromMessageScreen);
    setSelectedUserId(agentId);
    setProfileModal(true);
  }

  const closeAgentModal = (isFromMessage) => {
    if (!isFromMessage) {
      handleIntroDetailModal(true)
    }
    setProfileModal(false);
  }

  return (
    <>
      <div className="outer-wrap position-relative mw-1168 min-vh-100">
        <CommonHeader />
        <div className="row msg-height">
          <div className="col-12 col-md-6 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2 mb-4 mb-sm-4 mb-md-0 ">

            <div className="ad-strengthen-wrap strengthen-adjust-wrap mh-490 pe-0 ps-0 pt-0 pb-2">

              <div className="messaging-top-header pb-12 pl-16 pr-16 pt-4">
                <h3>Messages</h3>
                <div className="messaging-select-wrap">

                  <SelectDropDown

                    id="multi3444"
                    name="colors33"
                    options={filterList}
                    className="basic-single-inventory bg-color-fix message-custom-width mb-0"
                    classNamePrefix="select"
                    placeholder="Select"
                    defaultValue={filterList[0]}
                    value={handleFilterValue(filter)}
                    onChange={(e) => { handleFilter(e) }}
                    styles={{
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        transform:
                          state.selectProps.menuIsOpen && "rotate(180deg)",
                      }),
                    }}
                    errorClass="error-msg error-msg-height"
                  />
                </div>
              </div>

              <MessageLeftSection
                userId={userId}
                messages={message}
                selectedIntroductionId={selectedIntroductionId}
                handleWantAdSelection={(msgData) => { handleWantAdSelection(msgData) }} 
                introIdFromURL={introIdFromURL}/>
            </div>
          </div>
          <MessagesRightSection
            selectedId={selectedIntroductionId}
            userId={userId}
            selectedData={selectedData}
            getIntroductionDetails={(IntroductionId) => { getIntroductionDetails(IntroductionId) }}
            ViewProfileModal={(agentId) => { ViewProfile(agentId, true) }}
            userDeletedUpdate={(deleted)=> {setUserDeleted(deleted)}}
          />
        </div>
      </div>
      <IntroDetails
        details={introDetails}
        show={showViewIntroductionModal}
        onHide={handleIntroDetailModal}
        ViewProfileModal={(agentId) => { ViewProfile(agentId) }}
        userDeleted={userDeleted}
      />

      <AgentProfileViewModal
        show={showProfileModal}
        onHide={ProfileModalClose}
        userId={selectedUserId}
        isFromMessage={isFromMessage}
        onClose={(isFromMessage) => { closeAgentModal(isFromMessage) }} />
    </>
  );
};

export default Messaging;
