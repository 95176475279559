import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextArea from "../Common/TextArea";
import { toast } from "react-toastify";
import { formateDate, getUserId, getUserType } from "../../helpers/common";
import { getCancelPlanReasons } from "../../services/commonServices";
import { USER_TYPE_AGENT } from "../../constants/common";
import { createSubscriptionPlan } from "../../services/PlanService";
import { useNavigate } from "react-router";
import { pageURLs } from "../../constants/pageURLs";
const CancelPlanModal = (props) => {
  const {userDetails} = props;
  const navigate = useNavigate();
  const {updateReloadParam, planEndDate} = props;
  const [cancelPlanReasons, setCancelPlanreasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [responseText, setResponseText] = useState("")
  const userId = getUserId();
  const userType = getUserType();
  useEffect(() => {
    fetchCancelPlanReasons();
  }, []);

  const fetchCancelPlanReasons = async () => {
    await getCancelPlanReasons().then((response) => {
      if (response?.errorCode === 0) {
        setCancelPlanreasons(response?.result);
      } else {
        setCancelPlanreasons([]);
      }
    });
  };

  const handleDowngrade = async () => {
    const payload = {
      ReasonId: selectedReason,
      ReasonText: selectedReason === 7 ? responseText : null,
      PlanName: 'Lite',
      PlanPeriod: 1,
      UserType : userType
    };

    await createSubscriptionPlan(payload).then((response) => {
      if (response?.errorCode === 0) {
        toast.success("Your subscription has been cancelled.");
        props.onHide();
        navigate(pageURLs.settings);
        updateReloadParam();
      } else {
        toast.error("Failed to cancel subscription.");
        props.onHide();
        updateReloadParam();
      }
    });
  };

  const handleCancel = () => {
    setSelectedReason(null);
    props.onHide();
  };

  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings modal-payment cancel-plan"
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={props.onHide}
        ></button>
        <h3 className="text-start">
          Are you sure you want to cancel your {userDetails?.PlanName} plan?
        </h3>
        <p className="desc">
          You’ll be switched to the Lite plan{userDetails?.ToDate ? " on "+formateDate(userDetails?.ToDate, "shortMonth") : ""}.
        </p>
        <p className="note">
          Before you confirm, please let us know why you’re cancelling. We
          appreciate your feedback!
        </p>
        <div className="modal-controls-wrap">
          {cancelPlanReasons.length > 0 &&
            cancelPlanReasons.map((reason) => {
              return (
                <label className="radio-wrap mb-3">
                  <input
                    type="radio"
                    name="radio-ad-option"
                    onChange={() => {
                      setSelectedReason(reason?.ReasonId);
                    }}
                    checked={reason?.ReasonId === selectedReason}
                  />
                  <span>{reason?.Reason_Text}</span>
                </label>
              );
            })}
        </div>
        {selectedReason === 7 && (
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap">
                <TextArea
                  className="form-control txt-area"
                  errorMessage=""
                  value={responseText}
                  onChange={(e)=>setResponseText(e.target.value)}
                  maxLength={500}
                  placeholder="Any other feedback? (Optional)"
                />
              </div>
            </Col>
          </Row>
        )}

        <div className="modal-btn-wrap">
          <Button
            variant="outline"
            className="btn btn-outline-primary me-3"
            onClick={handleCancel}
          >
            Keep {userDetails?.PlanName}
          </Button>
          <Button className="btn btn-delete" disabled={(selectedReason === null)} onClick={handleDowngrade}>
            Cancel {userDetails?.PlanName}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CancelPlanModal;
