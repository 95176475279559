import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

const BottomNavBar = (props) => {
  const {
    handleContinueClick,
    isDisabled,
    backButtonEnable,
    handleBackButtonClick,
    skipButtonEnable,
    handleSkipButtonClick,
    continueButtonText,
    step,
    bottomNavClass,
    backButtonText,
    hideContinueButton,
    isFinalStep,
    exitButtonDisable,
    handleSaveAndExit,
    exitButtonText
  } = props;

  return (
    <div className={bottomNavClass || "btm-nav-bar"}>
      {backButtonEnable ? (
        <a
          role="button"
          href={void 0}
          className="bottom-back-btn"
          onClick={() => handleBackButtonClick(step)}
        >
          <span className="left-arrow">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                fill="#205ECA"
              />
            </svg>
          </span>
          {backButtonText || "Back"}
        </a>
      ) : ""}

      <div className="btm-nav-btn-wrap ms-sm-auto">
        {skipButtonEnable ? (
          <Button
            variant="primary"
            className="btn-grey me-2"
            onClick={() => handleSkipButtonClick(step)}
          >
            Skip
          </Button>
        ) : isFinalStep && !exitButtonDisable ? (
          <Button
            variant="primary"
            className="btn-grey"
            onClick={() => handleSaveAndExit(step)}
          >
            {exitButtonText}
          </Button>
        ) : (
          ""
        )} {" "}
        {!hideContinueButton && (
          <Button
            variant="primary"
            className="ms-0 me-0"
            disabled={isDisabled}
            onClick={() => handleContinueClick(step)}
          >
            {continueButtonText || "Continue"}
          </Button>
        )}
      </div>
    </div>
  );
};
export default BottomNavBar;
