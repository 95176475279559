import { uploadFileToS3 } from "../../services/fileUploadService";
import { IMG_JPEG_FORMAT } from "../../constants/common";

export const uploadToS3 = async (preSignedUrl, file) => {
  const method = "PUT";
  const header = {
    "Content-Type": "multipart/form-data",
  };
  const res = await uploadFileToS3(preSignedUrl, method, file, header);

  if (res) {
    return res;
  } else {
    return null;
  }
};

export const uploadMapImageToS3 = async (preSignedUrl, file) => {
  const response = await fetch(preSignedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": IMG_JPEG_FORMAT, // Change the content type if needed
    },
  });
  if (response.ok) {
    return response.url;
  } else {
    return "";
  }
};
