import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pageURLs } from "../../../constants/pageURLs";
import { getUserId, getUserType, removeLocalStorage } from "../../../helpers/common";
import { fetchUserStatistics } from "../../../actions/userprofile";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP } from "../../../constants/common";
import AddPropertyPlus from "../../../images/add-property-plus-icon.svg";

const AddProperty = (props) => {
  const { userDetails, selectedClient } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getUserId();
  const userStatictics = useSelector(
    (state) => state?.userProfile?.userStatictics
  );
  const userPrivileges = useSelector(
    (state) => state?.userProfile?.userPrivileges
  );
  const [userCount, setUserCount] = useState({});
  const [allowedCount, setAllowedCount] = useState({});
  const userType = getUserType();

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id,
    };

    dispatch(fetchUserStatistics(payload));
  }, [userDetails]);

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);

  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  const handleAddProperty = () => {
    if (userCount?.Inventory >= allowedCount?.Inventory) {
      navigate(pageURLs.updatePlan, {
        state: { isFromSettings: true },
      })
    } else {
      removeLocalStorage(INVENTORY_KEY);
      removeLocalStorage(INVENTORY_KEY_TEMP);
      navigate(pageURLs.mobileCreateInventory,{
        state: { isFromMatch: true },
      });
    }
  };

  return (
    <>
      <a role="button"
        href={void 0}
        onClick={() => handleAddProperty()}
      > 
        <div className="my-property-outermob">
        <div className="my-property-innermob">
        <div className="my-property-mob">
          <div className="add-property-smallbtn-mob">
            <img src={AddPropertyPlus} />
          </div>
          <div className="my-property-details-mob">
            <h3 className="mb-0">Add your property</h3>

          </div>
        </div>
        </div>
        </div>
      </a>
    </>
  );
};

export default AddProperty;
