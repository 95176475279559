import React, { useState, useEffect, useRef } from "react";
import { getInAppNotications, getIntroductions, updateInAppDeleteStatus } from "../../services/introductionService";
import { getLocalStorage, isIphone, loggedInUser, setBodyBackgroundGrey, setLocalStorage } from "../../helpers/common";
import { NOTIFICATIONCOUNT, SCROLL_DIRECTION_DOWN, USER_INFO_KEY } from "../../constants/common";
import DeleteModal from "../../components/Common/DeleteModal";
import NotificationComponent from "../components/inapp/NotificationComponent";
import ReceiveIntroNotificationComponent from "../components/ReceiveIntroduction/ReceiveIntroNotificationComponent";
import MenuBottomBar from "../components/menuBottomMobile";
import HandleScroll from "../commonComponents/HandleScroll";
import { useSelector } from "react-redux";

const ReceiveIntroductionNotification = (props) => {
  const scrollStates = HandleScroll()
  const isReachedBottom =  _.get(scrollStates, 'isReachedBottom', null)
  const scrollDirection = _.get(scrollStates, 'direction', null)
  const myRef = useRef(null);
  // receive introduction 
  const [isLoadIntroList, setLoadIntroList] = useState(false);
  const [notificationList, setIntroductionList] = useState([]);
  const [showIntroList, setIntroList] = useState(true);
  const INTRO_LIST_PAGE_LIMIT = 4;
  const [currPage, setCurrPage] = useState(1);
  const [isLoadNotificationList, setLoadNotificationList] = useState(false);

  // un read messages status for handshake icon
  const [unreadMessageCount, setUnreadMessageCount] = useState(getLocalStorage(NOTIFICATIONCOUNT) ? getLocalStorage(NOTIFICATIONCOUNT).count : 0);

  const user = loggedInUser();
  const UserId =
    _.get(user, "userInfo.userId", null) ||
    _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", null);

  const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
  const handleDeleteNotificationPopupClose = () =>
    setDeleteNotificationModal(false);

  // sse data
  let {sseData} = useSelector((state)=>state.sseData);
  const sseTriggedData = sseData?.payload;


    
  useEffect (()=>{
    if(sseTriggedData?.messageType == "receiveIntroduction"){
      const wantAdId = null;
      let page = 1
      getIntroductionList(page, wantAdId);
    } 

  }, [sseTriggedData])


  useEffect(() => {
    if (isReachedBottom) {
      setCurrPage((prevState) => prevState + 1);
    }
  }, [isReachedBottom]);

  // in-app notification
  useEffect(() => {
    if (UserId) {
      const wantAdId = null;
      //setIntroList(true);
      const page = 1;
      setCurrPage(1);
      getIntroductionList(page, wantAdId);
    }

  }, [UserId]);

  useEffect(() => {
    //const page = 1
    const wantAdId = null;
    currPage !== 1 && getIntroductionList(currPage, wantAdId);
  }, [currPage]);

  useEffect(() => {
    setBodyBackgroundGrey()
  },[])

  // const handleScroll = () => {
  //   const el = myRef.current;
  //   const scrollHeight = el.scrollHeight;
  //   const scrollTop = el.scrollTop;
  //   const clientHeight = el.clientHeight;
  //   if (
  //     scrollHeight !== clientHeight &&
  //     scrollTop !== 0 &&
  //     Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
  //   ) {
  //     //   updateIntroPageOnScroll();
  //     setCurrPage((prevState) => prevState + 1);
  //   } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
  //     //setCurrPage(prevState => prevState -1);
  //   }
  // };


  // receive Intro list
  const getIntroductionList = async (
    page,
    wantAdId,
  ) => {
    setLoadIntroList(false);
    const userId = _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", "");
    const reqPayload = {
      wantAdId: wantAdId,
      page: page,
      limit: INTRO_LIST_PAGE_LIMIT,
      isAccept: "1",
    };
    await getIntroductions(reqPayload).then((response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        if (response.errorCode === 0) {
          setIntroductionList([...notificationList, ...list]);
          if (_.get(response, "result.unReadMessageCount", null) == 0) {
            setLocalStorage(NOTIFICATIONCOUNT, {
              count: _.get(response, "result.unReadMessageCount", null),
            });
          }
          setUnreadMessageCount(
            _.get(response, "result.unReadMessageCount", 0)
          );
          setLoadIntroList(true);
        } else {
          setLoadIntroList(true);
          console.log(`####error`);
        }
      }
    });
  };

  let mainWrapperClass ="gitcha-main-wrapper windowscroll-fix"
  let contentWrapperClass = "content-wrapper content-wrapper-h-100-percent windowscroll-fix"
  if(scrollDirection === SCROLL_DIRECTION_DOWN){
    if(isIphone()){
      mainWrapperClass ="gitcha-main-wrapper pad-btm-0 windowscroll-fix"
    } else{
      mainWrapperClass ="gitcha-main-wrapper pad-btm-0 windowscroll-fix"
    }
     contentWrapperClass = "content-wrapper content-wrapper-h-100-percent windowscroll-fix"
  }

  return (
    <>
      <div className= {mainWrapperClass} >
        <div className= {contentWrapperClass}>
          <div className="inner-layout-header-new bg-white ">
            <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a className="back-link-btn" href="/mobile/home"  >
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                  </svg>
                </a>
                <div className="heading">Introductions</div>
                <div class="p-0 toplinks-mobile">
                  {/* <div className="topbar-links notif-delete-btn" onClick={() => { onDeleteInappNotification() }} >
                                    </div> */}
                </div>
              </div>

            </div>
          </div>
          <div className="home-layout-content">
            {notificationList.length == 0 && isLoadIntroList ?
              <div className="no-data-txt h-100 d-flex align-items-center justify-content-center flex-column no-data-txt-vh">

                <p className="m-0 text-center">No new notifications </p>
              </div>

            :
            <div className="pe-0 ps-0 pt-0 min-h-100">
              <div className="notif-content-wrapper pt-0">
                <ReceiveIntroNotificationComponent
                  notificationListData={notificationList}
                  reloadNotificationList={() => { getIntroductionList(1) }} />
              </div>
            </div>
}
          </div>
        </div>
     
        {scrollDirection !== SCROLL_DIRECTION_DOWN && (
        <div className="inner-layout-footer-new inner-layout-footer-topfix">
        <div className="bottomMenu bottom-menu-index">
          <MenuBottomBar unreadNotificationMessageCount={unreadMessageCount} scrollDirection = {scrollDirection} />
        </div>
      </div>
       )}
      </div>
   
    </>
  );
};

export default ReceiveIntroductionNotification;
