/**
 * @file   Source/gitcha_Web/src/components/PlanUpgrade/BasicPlanModal.js
 * @brief  This file is responsible for selecting subscription plans for member user
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Tab, Tabs } from "react-bootstrap";
import CancelPlanModal from "./CancelPlanModal";
import PlanReviewModalWrapper from "../Payments/StripeElementsWrapper";
import {
  getLocalStorage,
  getUserId,
  sendEmailSales,
  setLocalStorage,
  getUserType,
  checkPlanCanbeUpgraded,
} from "../../helpers/common";
import { toast } from "react-toastify";
import {
  createSubscriptionPlanOnSignup,
  getActivePromoCodeDetails,
} from "../../services/PlanService";
import { USER_INFO_KEY } from "../../constants/common";
import { useSelector } from "react-redux";
import ContactModal from "../Onboarding/ContactModal";
import PlanUpgradeRestrictionModal from "./PlanUpgradeRestrictionModal";

const BasicPlanModal = (props) => {
  const {
    userDetails,
    updateReloadParam,
    show,
    handleMemberProfileModal,
    handleUserTypeModal,
  } = props;
  const [showPlanReviewModal, setPlanReviewModal] = useState(false);
  const PlanReviewModalClose = () => setPlanReviewModal(false);
  const PlanReviewModalShow = () => setPlanReviewModal(true);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [key, setKey] = useState("annual");
  const [fromSignUp, setFromSignUp] = useState(false);
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const userId = getUserId();
  const [showCancelPlanModal, setCancelPlanModal] = useState(false);
  const CancelPlanModalClose = () => setCancelPlanModal(false);
  const CancelPlanModalShow = () => setCancelPlanModal(true);
  const [currentActivePlan, setCurrentActivePlan] = useState(null);
  const userType = getUserType();
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  // contact modal
  const [contactModalState, setContactModalState] = useState(false);
  const ContactModalClose = () => setContactModalState(false);
  const ContactModalShow = () => setContactModalState(true);
  const [enablePromocode, setEnablePromocode] = useState(false);
  const [enablePromocodePeriod, setEnablePromoCodePeriod] = useState([]);
  const [showPlanRestrictionModal, setShowPlanRestrictionModal] = useState(false);

  useEffect(() => {
    if (show) {
      getActivePromoCodeDetails().then((response) => {
        let temp = [];
        const res = _.get(response, "result", []);
        if (res?.length > 0) {
          const result = res.filter(
            (planDetails) =>
              planDetails?.UserType == 1 || planDetails?.UserType == 3
          );
          if (result && result.length > 0) {
            setEnablePromocode(true);
          }
          result.forEach((data) => {
            temp.push(data?.Period);
          });
          setEnablePromoCodePeriod(temp);
        }
      });
    }
  }, [show]);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      const planCurrent = userDetailsFromStore.filter((pl)=>pl?.Is_Upcoming===0 && pl?.IsActive === 1)
      setCurrentActivePlan(planCurrent[0])
    }
  }, [userDetailsFromStore]);

  useEffect(() => {
    if (userDetails?.PlanName == null) {
      setFromSignUp(true);
    } else {
      setFromSignUp(false);
    }
    setSelectedPeriod(userDetails?.period);
    setSelectedPlan(userDetails?.PlanName);
  }, [userDetails]);

  

  const handleUpgrade = (period, plan, continueClicked=false) => {
      setSelectedPeriod(period)
      setSelectedPlan(plan)
      if(!continueClicked){
        setContinueButtonClicked(false)
      }
      if (checkPlanCanbeUpgraded(currentActivePlan, plan, period)?.canPlanUpgrade == false  && !continueClicked) {
        setShowPlanRestrictionModal(true);      
      } else {     
        PlanReviewModalShow()
      }    
      props.onHide();    
  };

  useEffect(() => {
    if (selectedPeriod == 3) {
      setKey("annual");
    } else if (selectedPeriod == 2) {
      setKey("monthly");
    }
  }, [selectedPeriod]);

  const handleDowngrade = () => {
    props.onHide();
    CancelPlanModalShow();
  };

  const handleClose =() =>{ 
    if(userDetails?.period===2){
      setKey('monthly')
    }
    else{
      setKey('annual')
    }
    props.onHide()
  }

  const upgradeUserToFreePlan = async () => {
    const data = {
      PlanName: "Lite",
      PlanPeriod: 1,
      UserType: userType,
    };

    await createSubscriptionPlanOnSignup(data)
      .then(async (response) => {
        if (response.errorCode === 0) {
          props.onHide();
          props.updateReloadParam();
          toast.dismiss();
          toast.success("You've selected Lite!");
          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["planStatus"] = true;
          setLocalStorage(USER_INFO_KEY, userInfo);
          handleMemberProfileModal({showModal: true})
          // }
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const renderPlanTypes = (period) => {
    const priceMember = period===2 ? 13 : 10
    const priceInvestor = period===2 ? 15 : 12
    const periodText = period === 2 ? "monthly" : "annually"
    return (<div className="plan-container gridspace">
      <div className="plan-wraper member w-100">
        <h4 className="f-size-20">Lite</h4>
        <p className="info">
          Get a taste of what Gitcha can do with limited features.
        </p>
        <p className="price">Free</p>
        <div className="btn-sec">
          {userDetails?.PlanName == null ? (
            <Button
              variant="primary"
              onClick={() => upgradeUserToFreePlan()}
              className="plan-btn"
            >
              Select
            </Button>
          ) : userDetails?.PlanName === "Lite" ? (
            <Button variant="primary" className="plan-btn" disabled>
              Your current plan
            </Button>
          ) : (
            <Button
              variant="outline"
              className="btn-outline-primary plan-btn"
              onClick={handleDowngrade}
            >
              Downgrade
            </Button>
          )}
        </div>
        <ul className="plan-features">
          <li>Unlimited want-ads</li>
          <li>Up to 2 properties in your inventory</li>
          <li>Pay per introduction</li>
        </ul>
      </div>
      <div className="plan-wraper premium w-100">
        {userDetails?.PlanName === "Lite" ||
          userDetails?.PlanName == null && (
            <span className="table-badge-green">Most popular</span>
          )}
        <h4 className="d-flex align-items-center f-size-20">Member</h4>
        <p className="info">
          Unlock features to get the most out of Gitcha for less.{" "}
        </p>
        <p className="price">
          ${priceMember}/month<span>Billed {periodText}</span>
        </p>
        <div className="btn-sec">
          {userDetails?.PlanName == null ? (
            <Button
              variant="primary"
              className="plan-btn"
              onClick={() => handleUpgrade(period, "Member")}
            >
              Select
            </Button>
          ) : (
            userDetails?.PlanName==="Investor Member"? 
            <Button
              variant="outline"
              className="btn-outline-primary plan-btn"
              onClick={() => handleUpgrade(period, "Member")}
            >
              Downgrade to Member
            </Button>
            : <Button
              variant="primary"
              className="plan-btn"
              disabled={
                userDetails?.PlanName === "Member" &&
                userDetails?.period === period
              }
              onClick={() => handleUpgrade(period, "Member")}
            >
              {userDetails?.PlanName === "Member" &&
              userDetails?.period === period
                ? "Your Current Plan"
               : "Upgrade to Member"}
            </Button>
          )}
        </div>
        <ul className="plan-features">
          <li>Unlimited want-ads</li>
          <li>Unlimited properties in your inventory</li>
          <li>10 free introductions per month</li>
        </ul>
      </div>
      <div className="plan-wraper preferred w-100">
        <h4 className="d-flex align-items-center f-size-20">
          <span>Investor Member</span><span className="pro-gold-badge">INVESTOR</span>
        </h4>
        <p className="info">
          Includes investor specific features to create more opportunities.
        </p>
        <p className="price">
        {/* Coming soon */}
          ${priceInvestor}/month<span>Billed {periodText}</span>
        </p>
        <div className="btn-sec">
          {userDetails?.PlanName == null ? (
            <Button
              variant="primary"
              className="plan-btn"
              onClick={() => handleUpgrade(period, "Investor Member")}
              // disabled
            >
              Select
            </Button>
          ) : (
            <Button
              variant="primary"
              className="plan-btn"
              // disabled
              disabled={
                userDetails?.PlanName === "Investor Member" &&
                userDetails?.period === period
              }
              onClick={() => handleUpgrade(period, "Investor Member")}
            >
              {userDetails?.PlanName === "Investor Member" &&
              userDetails?.period === period
                ? "Your Current Plan"
                : "Upgrade to Investor Member"}
            </Button>
          )}
        </div>
        {/* <div className="plan-outer"> */}
        <ul className="plan-features">
          <li>Property management dashboard </li>
          <li>Additional buyer box criteria</li>
          <li>Unlimited want-ads</li>
          <li>Unlimited properties in your inventory</li>
          <li>10 free introductions per month</li>
          <li>Custom Investor profile and badge</li>
        </ul>
      {/* </div> */}
      </div>
      <div className="plan-wraper preferred w-100">
        <h4 className="f-size-20">Enterprise</h4>
        <p className="info">
        Want more seats for your company or team? 
        </p>
        <p className="price">Tiered pricing/seat</p>
        <div className="btn-sec">
          <Button
            variant="primary"
            className="plan-btn"
            // onClick={()=>sendEmailSales("")}
            onClick={() => {
              ContactModalShow();
            }}
          >
            Contact us
          </Button>
        </div>        
      </div>
    </div>);
  };

  return (
    <>
      <Modal
        show
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-plan premium-plan-modal"
        backdrop="static"
      >
        <Modal.Body>
          {!fromSignUp && !props.fromInventoryCreation && (
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          )}
          {
          fromSignUp && !_.get(getLocalStorage(USER_INFO_KEY), 'userInfo.planStatus') &&   
          <a role="button" href={void 0} className="bottom-back-btn" onClick={()=>{handleUserTypeModal({showModal: true}); props.onHide()}}>
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                fill="#205ECA"
              />
            </svg>
          </span>
          Back
        </a>
        }
          {userDetails?.PlanName == null ? (
            <h3 className="text-center">Select your plan</h3>
          ) : (
            <h3 className="text-center">
              {props?.fromInventoryListing
                ? "Upgrade to Premium to add more properties"
                : "Upgrade your professional plan"}
            </h3>
          )}
          <p className="desc text-center">
            {props?.fromInventoryListing
              ? "Your free plan is limited to 2 properties in your private inventory. Upgrade to our Premium plan to gain more that Gitcha has to offer."
              : "Subscribe to save time and create more deals with unlimited access to all of Gitcha’s features."}{" "}
          </p>
          <div className="tab-wrap">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="annual" title="Annual">
                {renderPlanTypes(3)}
              </Tab>

              <Tab eventKey="monthly" title="Monthly">
                {renderPlanTypes(2)}
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
      <PlanReviewModalWrapper
      period={selectedPeriod}
      setPeriod={setSelectedPeriod}
      selectedPlan={selectedPlan}
      show={showPlanReviewModal}
      onHide={PlanReviewModalClose}
      updateReloadParam={updateReloadParam}
      fromSignUp={fromSignUp}
      handleMemberProfileModal={handleMemberProfileModal}
      enablePromocode={enablePromocode}
      enablePromocodePeriod={enablePromocodePeriod}
      userDetails={userDetails}
      showPlanModal={props.showPlanModal}
      currentActivePlan={currentActivePlan}
      continueButtonClicked={continueButtonClicked}
      setContinueButtonClicked={setContinueButtonClicked}
      />
      {showCancelPlanModal && <CancelPlanModal
      show={showCancelPlanModal}
      onHide={CancelPlanModalClose}
      updateReloadParam={updateReloadParam}
      userDetails={userDetails}
      />}
      <PlanUpgradeRestrictionModal
      show={showPlanRestrictionModal}
      onHide={() => {setShowPlanRestrictionModal(false); setContinueButtonClicked(false)}}
      currentActivePlan={currentActivePlan}
      selectedPeriod={selectedPeriod}
      handleContinueButton={()=>{handleUpgrade(selectedPeriod, selectedPlan, true); setContinueButtonClicked(true)}}
    />
      <ContactModal
        show={contactModalState}
        onHide={ContactModalClose}
        sourceType={2} // Enterprise
      />
    </>
  );
};
export default BasicPlanModal;
