import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";


const IntroExpiredModal = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-settings"
      >
        <Modal.Body className="pt-32 pb-32">
          <h3 className="text-start">Introduction expired</h3>
          <p className="f-size-16 mb-4">Your opportunity to initiate a conversation related to this introduction has expired.</p>

          <div className="modal-btn-wrap">
            <Button variant="primary"  onClick={() => navigate(pageURLs.home)}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
     
    </>
  );
  
};
export default IntroExpiredModal;
