import React, { useState, useEffect } from "react";
import BuyerList from "../../images/buyer_listing_img.png";
import SegmentDivider from "../../components/MemberWantAd/SegmentDivider";
import CustomLinkOffCanvas from "../commonComponents/CustomLinkOffCanvas";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import ClientSelection from "../commonComponents/ClientSelection";
import ProgressBarCreateAdOptions from "../commonComponents/ProgressBarCreateAdOptions";

const CreateAdForm = (props) => {
  const navigate = useNavigate();  
  const [uiKey, setUIKey] = useState(null);
  const [completedPercentage, setCompletedPercentage] = useState(7)
  const [selectedOption, setSelectedOption] = useState(null)
 
  const handleNext = () =>{
    if(selectedOption===1){
      setUIKey("Client")
    }
    else if(selectedOption===2){
      navigate(pageURLs?.createWantAdResponsive, {
        state: { fromCreateAdForm: true },
      })
    }
  }

  const CreateWantAdOptionsScreen = () => {
    return (
      <>
        <div className="gitcha-main-wrapper pb-102 bg-white">
          <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarCreateAdOptions
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            uiKey={uiKey}
          />
            <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
              <div className="layout-content-btm-sec position-static pe-0 ps-0">
                <div className="mt-0 pt-0 mb-0 titleText">
                  How would you like to create your Want-Ad Listing?
                </div>
              </div>
              <div className="title-selection-wrap ">
                <a
                  href={void 0}
                  onClick={()=>setSelectedOption(1)}
                  className={selectedOption===1 ? "createAd-content-wrap mb-3 d-flex align-items-center active" : "createAd-content-wrap mb-3 d-flex align-items-center"}
                >
                  <span className="labelTxt">
                    {" "}
                    Have my buyer fill out my branded Buyer Listing Form Link{" "}
                    <br />
                    <span className="normalTxt">(Like a DocuSign) </span>
                  </span>
                </a>

                <div className="text-center">
                  <div className="mt-3 mb-3">
                    <img src={BuyerList} className="wdImgMob" />
                  </div>
                  <div className="buyerListxt m-auto mb-3 ">
                    {" "}
                    After your buyer submits their criteria to you via your
                    custom link, we notify you to review and post it from your
                    drafts. (Buy only)
                  </div>
                </div>

                <SegmentDivider />

                <a
                  href={void 0}
                  onClick={() => setSelectedOption(2)}
                  className={selectedOption===2 ? "createAd-content-wrap mb-3 d-flex align-items-center active" : "createAd-content-wrap mb-3 d-flex align-items-center"}
                >
                  <span className="labelTxt"> Fill out myself</span>
                </a>
              </div>
            </div>
          </div>
          <div className="inner-layout-footer-new">
            <div className="inner-btm-fixed-nav position-static">
              <div className="btn-wrap text-center pb-0 pt-0">
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-3"
                  disabled={selectedOption != null ? false : true}
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div> 
        </div>
      </>
    );
  };

  const renderUI = () => {
    switch (uiKey) {
      case "Client":
        return <ClientSelection
        completedPercentage={completedPercentage}
        setUIKey={setUIKey}
        setCompletedPercentage={setCompletedPercentage}
         />;
      default:
        return <CreateWantAdOptionsScreen />;
    }
  };
  return <>{renderUI()}</>;
};

export default CreateAdForm;
