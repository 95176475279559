
/**
 * @file   src\components\AgentDashboard\InventoryListing.js
 * @brief  This file is responsible for Inventory listing in agent dashboard section
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import {
  formateDate,
  loadPropertTypeImage,
  setLocalStorage,
  truncate,
} from "../../helpers/common";
import { ADTYPE_BUY, ADTYPE_LTR, INVENTORY_KEY } from "../../constants/common";
import Table from "react-bootstrap/Table";
import ActionDropdown from "./ActionDropdown";
import TableHeader from "./TableHeader";
import addressImage from "../../images/address-img.png";
import noImage from "../../images/no-image-placeholder.png";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const InventoryListing = (props) => {

  const navigate = useNavigate();
  const {
    showNoResult,
    handleSort,
    itemList,
    onItemEdit,
    onItemDelete,
    sort,
    emptyItemHtml,
    fromInvestorTab,
    loading
  } = props;
  const columns =fromInvestorTab ?  [
    { label: "Address ", sorting: "Yes", sortKey: "address" },
    { label: "Type", sorting: "Yes", sortKey: "type" },
    { label: "Sell/Rent-out", sorting: "Yes", sortKey: "sell_rent" },
    { label: "Intros sent", sorting: "Yes", sortKey: "intros" },
    { label: "Date added", sorting: "Yes", sortKey: "date" },
  ] : [
    { label: "Address ", sorting: "Yes", sortKey: "address" },
    // { accessor: 'email', label: 'Email', sorting:'Yes'  },
    { label: "Client", sorting: "Yes", sortKey: "client" },
    { label: "Type", sorting: "Yes", sortKey: "type" },
    { label: "Sell/Rent-out", sorting: "Yes", sortKey: "sell_rent" },
    { label: "Intros sent", sorting: "Yes", sortKey: "intros" },
    { label: "Date added", sorting: "Yes", sortKey: "date" },
  ];

  const handleInventoryItemClick = (inventoryId) => {
    setLocalStorage(INVENTORY_KEY, {
      inventoryId: inventoryId,
      isFromAgentDashboard: true,
    });
    navigate(pageURLs.memberInventoryDetails);
  };
  return (
    <div className="dashboard-table-wrap">
      <div className="table-responsive">
        {showNoResult && itemList !== null && itemList?.length > 0 ? (
          <Table className="table">
            <TableHeader
              columns={columns}
              handleSort={handleSort}
              sort={sort}
            />
        
              <tbody>
                {itemList.map((inventry, i) => {
                  const invPhoto = _.get(inventry, 'photos', null) || noImage;
                  const clientName = (_.get(inventry, "FirstName", "") ? _.get(inventry, "FirstName", "") : "") +
                  " " +
                  (_.get(inventry, "LastName", "") ?  _.get(inventry, "LastName", "") : "");
                  const InventoryForUsername = _.get(inventry, "Represent_as_Seller", "") == 1 ? "Agent": clientName;
                  return (
                    <tr key={i}>
                      {/* <td>
                        <span className="d-inline-block client-name-style txt-overflow-style">
                          {truncate(_.get(inventry, "Add_Details", ""), 30)}
                        </span>
                      </td> */}
                      <td>
                        {_.get(inventry, "Location", "") ? (
                          <a
                            href={void 0}
                            role="button"
                            className="txt-no-underline client-name-style"
                            onClick={() => {
                              _.get(inventry, "IsPublished", 0) == 1 ?  handleInventoryItemClick(_.get(inventry, "Inv_Id", "")) : '';
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="table-address-img">
                                <img
                                  src={invPhoto}
                                  alt="inventoryImage"
                                ></img>
                              </div>
                              <span className="d-inline-block client-name-style txt-overflow-style">
                                {truncate(_.get(inventry, "Location", ""), 30)}
                              </span>
                            </div>{" "}
                          </a>
                        ) : (
                          ""
                        )}
                      </td>

                      {!fromInvestorTab && <td>
                        {InventoryForUsername}
                      </td>}
                      <td>
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="property-location-icon">
                            {loadPropertTypeImage(
                              _.get(inventry, "Sub_PropertyTypeId", "") ||
                                _.get(inventry, "PropertyTypeId", "")
                            )}
                          </div>
                          <span className="d-inline-block client-property-style">
                            {_.get(inventry, "Type", "")}
                          </span>
                        </div>
                      </td>

                      <td>{_.get(inventry, "Sell_Rent", "")}</td>
                      {_.get(inventry, "IsSold", "")===1 ? 
                      <td>
                      <span className="table-badge-orange me-2">
                         closed
                      </span> </td>
                      : 
                      _.get(inventry, "IsSavedDraft", "") == 1 ?
                      <td>
                        <div className="d-flex align-items-center justify-content-start">
                              <div className="property-edit-icon">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.4697 2.46967C17.7626 2.17678 18.2374 2.17678 18.5303 2.46967L21.5303 5.46967C21.8232 5.76256 21.8232 6.23744 21.5303 6.53033L12.5303 15.5303C12.3897 15.671 12.1989 15.75 12 15.75H9C8.58579 15.75 8.25 15.4142 8.25 15V12C8.25 11.8011 8.32902 11.6103 8.46967 11.4697L17.4697 2.46967ZM9.75 12.3107V14.25H11.6893L19.9393 6L18 4.06066L9.75 12.3107Z"
                                    fill="#333A48"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.2197 4.71967C15.5126 4.42678 15.9874 4.42678 16.2803 4.71967L19.2803 7.71967C19.5732 8.01256 19.5732 8.48744 19.2803 8.78033C18.9874 9.07322 18.5126 9.07322 18.2197 8.78033L15.2197 5.78033C14.9268 5.48744 14.9268 5.01256 15.2197 4.71967Z"
                                    fill="#333A48"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.43934 3.43934C3.72064 3.15804 4.10217 3 4.5 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75C13.5 4.16421 13.1642 4.5 12.75 4.5L4.5 4.5L4.5 19.5H19.5V11.25C19.5 10.8358 19.8358 10.5 20.25 10.5C20.6642 10.5 21 10.8358 21 11.25V19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72065 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10217 3.15804 3.72064 3.43934 3.43934Z"
                                    fill="#333A48"
                                  />
                                </svg>
                              </div>
                              <span className="d-inline-block client-property-style">
                                Draft
                              </span>
                            </div>
                      
                        
                      </td>
                      :

                      <td>
                        <span className="table-badge-grey me-2">
                          {_.get(inventry, "IntroPending", "")} pending
                        </span>
                        <span className="table-badge-green">
                          <span className="d-inline-block align-text-bottom badge-tick-icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.6844 3.62814C11.8552 3.799 11.8552 4.076 11.6844 4.24686L5.55936 10.3719C5.3885 10.5427 5.1115 10.5427 4.94064 10.3719L1.87814 7.30936C1.70729 7.1385 1.70729 6.8615 1.87814 6.69064C2.049 6.51979 2.326 6.51979 2.49686 6.69064L5.25 9.44378L11.0656 3.62814C11.2365 3.45729 11.5135 3.45729 11.6844 3.62814Z"
                                fill="#219653"
                              />
                            </svg>
                          </span>
                          {_.get(inventry, "IntroAccepted", "")} accepted
                        </span>
                      </td>}
                      <td>
                        <div className="d-flex align-items-center justify-content-start">
                          <span>
                            {formateDate(
                              _.get(inventry, "CreatedOn", ""),
                              "shortMonth"
                            )}
                          </span>
                          <div className="social-btns-wrap no-bg-toggle ms-1">
                            <ActionDropdown
                              onItemEdit={onItemEdit}
                              onItemDelete={onItemDelete}
                              editText="Edit Inventory"
                              deleteText="Delete Inventory"
                              itemId={_.get(inventry, "Inv_Id", "")}
                              hideEdit={_.get(inventry, "IsSold", "") ===1}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            
          </Table>
        ) : (
          !loading && showNoResult && (itemList == null || itemList?.length == 0) &&  (
            <div className="no-result-wrap no-result-padding">
              <h3>No results found</h3>
              <p>
                We couldn't find any listings that match your filters and search
                terms. Try a different search or reset all filters.
              </p>
              {/*<button className="btn btn-primary">Browse Ad Feed</button>*/}
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default InventoryListing;
