import React, { useState, useEffect } from "react";
import { getTimeline } from "../../../services/commonServices";
import { handleRadioButonChange } from "../../../actions/common";
import { INVENTORY_TYPE } from "../../../constants/common";

const InventoryTimeLine = (props) => {
  const { handleContinueButtonState, handleFormValues, timeLine , isEdit} = props;
  const [timeLineList, setTimeLineList] = useState([]);

  useEffect(() => {
    if(!isEdit){
      if (timeLine) {
        handleContinueButtonState();
      } else {
        const isDisabled = true
        handleContinueButtonState(isDisabled)
      }
    }

    getTimeline(INVENTORY_TYPE)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const timeLines = _.get(response, "result", null);
          setTimeLineList(timeLines);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const getSelectedValue = (e, timeLineText) => {
    const value = handleRadioButonChange(e);
    if (value) {      
      handleFormValues({ timeLine: value, tlText : timeLineText });
      if(!isEdit){
        handleContinueButtonState()
      }
    }
  };

  const loadTimeLines = () => {
    const timeLines =
      timeLineList &&
      timeLineList.map((tl) => {
        const timeLineId = _.get(tl, "TimeLineId");
        const timeLineText = _.get(tl, "TimeLineText", "");
        return (
            <label className="radio-wrap mb-3" key={timeLineId}>
              <input
                type="radio"
                name="radio_timing"
                value={timeLineId}
                onChange={(e) => getSelectedValue(e, timeLineText)}
                checked={timeLine && timeLine == timeLineId}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>{timeLineText}</h2>
                </div>
              </span>
            </label>
        );
      });

    return timeLines;
  };
  return (
    <div className={!isEdit ? "center-content-sec mw-444" : ""}>
      <div className="wantad-timing-wrap">{loadTimeLines()}</div>
    </div>
  );
};
export default InventoryTimeLine;
