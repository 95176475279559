/**
 * @file   src\components\AgentWantAd\WantAdType.js
 * @brief  This file is responsible for handling property types for buy ad in want-ad creation.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { getPropertyTypes } from "../../services/commonServices";
import { handleRadioButonChange } from "../../actions/common";
import { usePrevious } from "../../hooks/usePrevious";
import { WANT_AD_BUY_PROPERTY_ID } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";

const WantAdType = (props) => {
  const { handleFormValues, propertyType, adType, clearPreviousStates, propertyTypeError, prptySubTypes } =
    props;
    
  const [propertyList, setPropertyList] = useState([]);


  useEffect(() => {
    const typeOfAd = 'buy'//adType === BUY ? 'buy' : 'rent'
    getPropertyTypes(typeOfAd)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const previousValue = usePrevious(propertyType);

  /**
   * To handle property type selection
   * @param {*} e 
   * @param {*} typeText 
   */
  const getSelectedValue = (e, typeText) => {
    const value = handleRadioButonChange(e);
    if (value) {
      clearPreviousSelectedValues(value);
        handleFormValues({ prprtyType: convertToNumber(value),  prptyTypeTxt: typeText, prptySubTypes: [convertToNumber(value)], focusOut: true});
           
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    clearPreviousStates({ type: "clearErrorStateOnPropertySelection"})
  };

  /**
   * To handle multipe selection property types
   * @param {*} e 
   */
  const handlePropertyMultipleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      if (_.includes(prptySubTypes, convertToNumber(value))) {
       
        subItemArray = prptySubTypes.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {

          subItemArray = prptySubTypes.filter(
            (e) => e !== 4 &&  e !== 6
          );
         
         subItemArray = [...subItemArray, convertToNumber(value)];
      }
      handleFormValues({ prptySubTypes: subItemArray,  prprtyType: WANT_AD_BUY_PROPERTY_ID, prptyTypeTxt: 'BUY', focusOut: true });
    }
  };

/**
 * To load property types in page initialization
 * @returns 
 */
  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const isChecked =  prptySubTypes && _.includes(prptySubTypes, typeId);
        const radioOrCheckBox =  _.get(pt, "Buy_IsMultiSelect", 0) == 0  ? true : false;
        return (
          <label className={radioOrCheckBox ? "radio-wrap mb-3" : "check-wrap mb-3"} key={typeId}>
            {
              radioOrCheckBox ?
              (
                <input
                type="radio"
                name="property_radio"
                value={typeId ? typeId : ""}
                checked={propertyType && propertyType == typeId || ''}
                onChange={(e) => getSelectedValue(e, typeText)}
              />

              ) : (
                <input
                type="checkbox"
                name="property_checkbox"
                id={typeId}
                value={typeId ? typeId : ""}
                checked={isChecked}
                onChange={(e) => handlePropertyMultipleItemClick(e)}
              />

              
              )

            }
            <span className={propertyTypeError && "red-marked-span"}>
              <div className="wantad-type-info">
                <h2 className={propertyTypeError ? "pb-0 red-marked-subheading" : " pb-0 " }>{_.get(pt, "PropertyTypeText", "")}</h2>
              </div>             
            </span>
          </label>
        );
      });

    return List;
  };

  return (
    <>
      {loadPropertyTypes()}
    </>

  );
};
export default WantAdType;
