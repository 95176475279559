import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import { BUY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../constants/common";
import AddPropertyTypeBuy from "./AddPropertyTypeBuy";
import { setLocalStorage, getLocalStorage, displayPropertyType, setBodyBackgroundWhite } from "../../helpers/common";
import AddPropertyTypeRent from "./AddPropertyTypeRent";

const AddProperty = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isEdit,
    isFromBuyerListingService
  } = props;

  const [disableSave, setDisableSave] = useState(true);
  const propertyType = _.get(formValues, "prprtyType", []);
  const propertTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "prprtyType", []);

  useEffect(() => {
    setBodyBackgroundWhite()
   }, []);

  const renderPropertyTypesBasedOnAd = () => {
    if (adType === BUY) {
      return (
        <AddPropertyTypeBuy
          formValues={formValues}
          handleFormValues={handleFormValues}
          setDisableSave={setDisableSave}
          clearPreviousStates={clearPreviousStates}
          isEdit ={isEdit}
        />
      );
    } else {
      return <AddPropertyTypeRent
          formValues={formValues}
          handleFormValues={handleFormValues}
          setDisableSave={setDisableSave}
          clearPreviousStates={clearPreviousStates}
          isEdit ={isEdit}
      />
    }
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />

          <div className="inner-layout-content-new bg-white pb-0 position-relative">
            <div className="select-plan-wrap pl-0 pr-0 pt-0 position-relative">
            <div className="layout-content-btm-sec layout-content-btm-sec-stickyfix">
              <h6 className="">Property type</h6>
              <h2 className="">
                {isAgent ? "Which type of property is your client looking to " : "Which type of property are you looking to "} {displayPropertyType(adType)}?
              </h2>
            </div>

            <div className="title-selection-wrap pe-3 ps-3 pt-0">
              {renderPropertyTypesBasedOnAd()}
            </div>
          </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if(propertTypeInSession != propertyType){
                    if(isAgent){
                      setCompletedPercentage(40)
                    }
                    else{
                      setCompletedPercentage(50)
                    }
                  }
                  else{
                    setCompletedPercentage(completedPercentage)
                  }
                  setTimeout(()=>{

                    setLocalStorage(
                      WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );
                    setUIKey(null);

                  },500)                  
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProperty;
