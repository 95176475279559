import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageURLs } from "../../constants/pageURLs";
import { Button } from "react-bootstrap";
import { emailValidation } from "../../helpers/validations";
import { changeEmailAddress } from "../../services/userprofileService";
import { toast } from "react-toastify";
import TextBox from "../../components/Common/TextBox";
import { getLocalStorage } from "../../helpers/common";
import { USER_INFO_KEY } from "../../constants/common";
import { fetchUserDetails } from "../../actions/userprofile";

const SettingsEditEmail = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const userId = _.get(sessionData, "userInfo.userId", "");

  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    inputRef.current.focus();
    if (userId) {
      dispatch(
        fetchUserDetails()
      );
    }
  }, []);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setEmail(userDetailsFromStore[0]?.Email);
    } else {
      setEmail(null);
    }
  }, [userDetailsFromStore]);

  const handleEmailChange = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    validate(e.target.value);
  };

  const validate = (mail) => {
    setButtonDisable(true);
    let isValid = true;
    setEmailError("");
    if (mail.trim() === "") {
      isValid = false;
      setEmailError("Enter Email");
    } else if (!emailValidation(mail.trim())) {
      isValid = false;
      setEmailError("Enter Valid email");
    } else if (isValid) {
      setButtonDisable(false);
    }
    return isValid;
  };

  const handleSubmit = async () => {
    const data = {
      newEmail: email,
    };
    await changeEmailAddress(data)
      .then((response) => {
        if (response.errorCode === 0) {
          navigate(pageURLs.mobileEditEmailCheck, {
            state: { newEmailId: email },
          });
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-0">
        <div className="content-wrapper content-wrapper-h-78">
          <div className="inner-layout-header-new bg-white ">
            <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="back-link-btn"
                  href={void 0}
                  onClick={() => navigate(pageURLs.mobileAccountSettings)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>
                <div className="heading">Edit Email</div>
                <div class="p-0 toplinks-mobile">
                  <div className="topbar-links notif-delete-btn d-none"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="inner-layout-content-new h-100  ">
            <div className="pl-16 pr-16 pt-3 min-h-100 bg-grey-variant">
              <p className="fnt14">
                You will sign into your Gitcha account using this email address.
              </p>
              <div className="mb-3">
                {/* <Form.Label htmlFor="" className="fnt14">Email</Form.Label>
            <Form.Control type="" id="" aria-describedby="" ref={inputRef} /> */}
                <label className="fnt14">Email</label>
                <TextBox
                  id="mailId"
                  name="emailId"
                  value={email}
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  onChange={(e) => handleEmailChange(e)}
                  errorClass="error-validation-msg"
                  errorMessage={emailError}
                  txtRef={inputRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-3 pt-0">
              {/* <button type="button" className="btn btn-primary w-100 mb-4" disabled  >Confirm</button> */}
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={buttonDisable}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsEditEmail;
