import { createSlice } from "@reduxjs/toolkit";
import { fetchPaymentMethods } from "../actions/payments";
const initialState = {
  paymentMethodsLoading : false,
  paymentMethods : {},
  paymentMethodsError : "",
};

const paymentMethods = createSlice({
  name: "paymentMethods",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentMethods.pending, (state, action) => {
      state.paymentMethodsLoading = true;
      state.paymentMethods = [];
      state.paymentMethodsError = "";
    });

    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethods = action.payload.result;
      state.paymentMethodsError = "";
    });

    builder.addCase(fetchPaymentMethods.rejected, (state, action) => {
      state.paymentMethodsLoading = false;
      state.paymentMethods = {};
      state.paymentMethodsError = action?.error?.message;
    });


  },
  
});

export default paymentMethods.reducer;
