import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function verifyEmail(email) {
  return await apiService.apiCallWithoutToken(endPoints.VERIFY_EMAIL, "POST", email);
}

export async function signup(userInfo) {
    return await apiService.apiCall(endPoints.SIGNUP, "POST", userInfo);
}

export async function resendEmail(userInfo) {
    return await apiService.apiCall(endPoints.RESEND_EMAIL, "POST", userInfo);
}


export async function saveAgentInfo(agentInfo) {
  return await apiService.apiCall(endPoints.SAVE_AGENT_INFO, "POST", agentInfo);
}

export async function saveAgentPicture(agentInfo) {
  const {userId, width, height, profileImage } = agentInfo   
  const isUpload = true
  return await apiService.apiCall(endPoints.SAVE_AGENT_PICTURE+ `?width=${width}&height=${height}&profileImage=${profileImage}
  `, "POST", {}, "", "", isUpload);
}

export async function userSignUp(reqPayload) {
  return await apiService.apiCallWithoutToken(endPoints.USER_SIGN_UP, "POST", reqPayload);
}
export async function userLogin(reqPayload) {
  return await apiService.apiCallWithoutToken(endPoints.USER_LOGIN, "POST", reqPayload);
}

export async function userEmailVerification(reqPayload) {
  return await apiService.apiCallWithoutToken(endPoints.VERIFY_USER_EMAIL, "POST", reqPayload);
}

export async function saveUserType_Title(reqPayload) {
  return await apiService.apiCall(endPoints.SAVE_USERTYPE_AND_TITLE_API, "POST", reqPayload);
}

