import React from "react";
import NiceToHaveHelper from "./NiceToHaveHelper";

const Interior = (props) => {
    const {interior, handleItemClick, itemArray} = props
    return  <NiceToHaveHelper
       list={interior}
       selectedItems={itemArray}
       propertyClick={handleItemClick}
    />
};
export default Interior;
