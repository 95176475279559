import React from "react";
import Button from "react-bootstrap/Button";
import { getUserType } from "../../../../helpers/common";
import { USER_TYPE_MEMBER } from "../../../../constants/common";

const AnnualAgentPlan = (props) => {
  const {
    userDetails,
    handleUpgrade,
    upgradeUserToFreePlan,
    handleDowngrade,
    ContactModalShow,
  } = props;
 
  return (
    <>
      <div class="mob-plan-wraper w-100 plan-bg-blue mb-20">
        {/* {userDetails?.PlanName === "Pro Member" &&
  userDetails?.period !== 3 && (
    <span className="table-badge-green">Most popular</span>
  )} */}
        <h4 class="d-flex align-items-center plan-color-blue">
          Pro Member <span class="pro-blue-badge">PRO</span>
        </h4>
        <p class="pb-20 plan-color-blue">
        Specific features for agents and teams to create and manage deals.
        </p>
        <p class="short-para-blue">
          $12/month<span>Billed annually</span>
        </p>
        <div class="btn-sec btn-sec-btmbrder-blue">
          {/* <button type="button" class="plan-btn btn btn-primary">
    Select
  </button> */}
          {userDetails?.PlanName == null ? (
            <Button
              variant="primary"
              className="plan-btn btn btn-primary"
              onClick={() => handleUpgrade(3, "Pro Member")}
            >
              Select
            </Button>
          ) : (
            <Button
              variant="primary"
              className="plan-btn btn btn-primary"
              disabled={
                userDetails?.PlanName === "Pro Member" &&
                userDetails?.period === 3
              }
              onClick={() => handleUpgrade(3, "Pro Member")}
            >
              {userDetails?.PlanName === "Pro Member" &&
              userDetails?.period === 3
                ? "Your Current Plan"
                : "Upgrade"}
            </Button>
          )}
        </div>
        <ul class="plan-features-list plan-color-blue">           
          <li className="plan-features-listbg-blue">Client management dashboard</li>
          <li className="plan-features-listbg-blue">Unlimited want-ads</li>
          <li className="plan-features-listbg-blue">Unlimited properties in your inventory</li>
          <li className="plan-features-listbg-blue">10 free introductions per month</li>
          <li className="plan-features-listbg-blue">Custom agent profile and Pro badge</li>
          <li className="plan-features-listbg-blue">Branded Buyer Digital Listing Form</li>
          <li className="plan-features-listbg-blue">See other Pro Member's contact info</li>
        </ul>
      </div>

      <div class="mob-plan-wraper w-100 plan-bg-white plan-brdr-grey mb-20">
        <h4 class="d-flex align-items-center plan-color-black">Lite</h4>
        <p class="pb-20">
        Get a taste of what Gitcha can do with limited features.
        </p>
        <p class="short-para-black">Free</p>
        <div class="btn-sec btn-sec-btmbrder-blue">          
          {userDetails?.PlanName == null || getUserType() === USER_TYPE_MEMBER ? (
            <Button
              variant="primary"
              onClick={() => upgradeUserToFreePlan()}
              className="plan-btn btn btn-primar"
            >
              Select
            </Button>
          ) : userDetails?.PlanName === "Lite" ? (
            <Button
              variant="primary"
              className="plan-btn btn btn-secondary-blue"
              disabled
            >
              Your Current plan
            </Button>
          ) : (
            <Button
              variant="outline"
              className="btn-outline-primary plan-btn downgrade-btn"
              onClick={() => handleDowngrade()}
              
            >
              Downgrade
            </Button>
          )}
        </div>
        <ul class="plan-features-list plan-features-listtext-black">
          <li className="plan-features-listbg-black">Pay per introduction</li>
          <li className="plan-features-listbg-black">
            Up to 2 properties in your inventory
          </li>
          <li className="plan-features-listbg-black">Unlimited want-ads</li>
        </ul>
      </div>
      <div class="mob-plan-wraper w-100 plan-bg-blue mb-20">
        <h4 class="d-flex align-items-center plan-color-blue">Enterprise</h4>
        <p class="pb-20 plan-color-blue">
          Empowering your agency’s full team with Premium benefits
        </p>
        <p class="short-para-blue">Tiered pricing/seat</p>
        <div class="btn-sec btn-sec-btmbrder-blue">
          <Button
            variant="primary"
            className="plan-btn btn btn-primary"
            // onClick={()=>sendEmailSales("")}
            onClick={() => {
              ContactModalShow();
            }}
          >
            Contact us
          </Button>
        </div>
        <ul class="plan-features-list plan-color-blue">
          <li className="plan-features-listbg-blue">
            Custom agent profile and Premium badge
          </li>
          <li className="plan-features-listbg-blue">
            Unlimited properties in your inventory
          </li>
          <li className="plan-features-listbg-blue">
            Free introductions per month
          </li>
          <li className="plan-features-listbg-blue">
            Client management dashboard
          </li>
          <li className="plan-features-listbg-blue">
            Exclusive market analytics, real-time buyer demand and private
            inventory data reports
          </li>
          <li className="plan-features-listbg-blue">Unlimited want-ads</li>
        </ul>
      </div>
      <div class="mob-plan-wraper w-100 plan-bg-darkblue mb-20 p-0 border-0">
        <h4 class="d-flex align-items-center plan-color-white pt-20 pe-4 ps-4">
          Pro Preferred <span class="pro-gold-badge">PRO</span>
        </h4>
        <p class="pb-20 plan-color-mildblue pe-4 ps-4">
          This plan is currently not available but will be coming soon.
        </p>
        <p class="short-para-white pe-4 ps-4">
          Get notified when available for upgrade
        </p>
        <div class="btn-sec btn-sec-btmbrder-blue pe-4 ps-4 mb-0">
          <Button
            variant="outline"
            className="plan-btn btn btn-yellow-gradient"
          >
            Notify Me
          </Button>
          Notify me
        </div>
        <ul class="plan-features-list plan-color-white plan-feature-blur pe-4 ps-4 pt-20 pb-20">
          <li className="plan-features-listbg-white">Pay per introduction</li>
          <li className="plan-features-listbg-white">
            Up to 2 properties in your inventory
          </li>
          <li className="plan-features-listbg-white">Unlimited want-ads</li>
        </ul>
      </div>
    </>
  );
};

export default AnnualAgentPlan;
