import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import { thousandSeperatedValue } from "../../helpers/common";

const MarkAsSoldStep2Modal = (props) => {
  const {show, onHide,inventoryId, handleInventoryMarkAsSold} = props;
  const [isGitchaHelped, setIsGitchaHelped] = useState(null);
  const [salesPrice, setSalesPrice] = useState(null);

  return (
    <Modal show={show}  {...props} onHide={onHide} centered className="modalPopupstyle delete-popup sold-popup">
        <Modal.Body className="text-center">
        <h3>Did Gitcha help with this transaction?</h3>
        <div className="questions-block">
            <div className="options">
                <a href="#" className="blockBtn text-center">Yes</a>
                <a href="#" className="blockBtn text-center"> No </a>
            </div>
        </div>
        <div className="btn-wrap">
            <div className="btn-block">
              <button type="button" className="btn btn-primary btn-skip w-100" onClick={onHide}>Skip <i className="ic-arrow"></i></button>
            </div>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Back</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default MarkAsSoldStep2Modal;
