/**
 * @file   src\components\AgentWantAd\WantAdRentMustHaves.js
 * @brief  This file is responsible for handling Must haves for rent ad in want-ad creation.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormLabel } from "react-bootstrap";
import { handleRadioButonChange } from "../../actions/common";
import ButtonGroups from "../Common/ButtonGroups";
import SqareFeet from "./SqareFeet";

const WantAdRentMustHaves = (props) => {
  const {
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    SFMin,
    SFMax,
    garageSpace,
    alwLrgDogs,
    alwSmlDogs,
    alwCats,
    adaAccess,
    stories,
    mustHaveErrors,
    requirementBedroomsError,
    requirementBathroomsError
  } = props;

  const [gSpace, setGSpace] = useState("");

  const STORY_ANY = 1;
  const STORY_SINGLE = 2;

  useEffect(() => {
    clearGarrageLocalState();
  }, []);


  const clearGarrageLocalState = () => {
    setGSpace("");
  };

  /**
   * To handle bedroom count selection
   * @param {*} e 
   */
  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value, focusOut: true });
    clearGarrageLocalState();
  };

  /**
   * To handle bathrooms count selection
   * @param {*} e 
   */
  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value, focusOut: true });
    clearGarrageLocalState();
  };

  /**
   * To handle garage count selection
   * @param {*} e 
   */
  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    setGSpace(value);
    handleFormValues({ grgSpce: value, focusOut: true });
  };

  /**
   * To handle checkbox fields selection
   * @param {*} e 
   */
  const handleCheckBoxItemClick = (e) => {
    clearGarrageLocalState()
    const id = e.target.id
    switch (Number(id)) {
      case 200:
        handleFormValues({ alwLrgDogs: e.target.checked, focusOut: true });
        break;
      case 201:
        handleFormValues({ alwSmlDogs: e.target.checked, focusOut: true });
        break;
      case 203:
        handleFormValues({ alwCats: e.target.checked, focusOut: true });
        break;
      case 204:
        handleFormValues({ adaAccess: e.target.checked, focusOut: true });
        break;
    }
  };

  /**
   * To load checkbox items
   * @returns 
   */
  const loadCheckBoxItems = () => {
    const itemArray = [{ id: 204, text: 'ADA Accessible' },
    { id: 200, text: 'Allows large dogs' },
    { id: 201, text: 'Allows small dogs' },
    { id: 203, text: 'Allows cats' },]
    const List =
      itemArray.map((pt) => {
        const id = Number(_.get(pt, "id", ""));
        const text = _.get(pt, "text", "");
        let isChecked
        switch (id) {
          case 200:
            isChecked = alwLrgDogs
            break;
          case 201:
            isChecked = alwSmlDogs
            break;
          case 203:
            isChecked = alwCats
            break;
          case 204:
            isChecked = adaAccess
            break;
        }
        return (
          <div className="row">
            <div className="wantad-timing-wrap">
              <div className="col-md-12">
                <label className="check-wrap">
                  <input
                    type="checkbox"
                    name="chk-mh"
                    id={id}
                    checked={isChecked}
                    onChange={(e) => handleCheckBoxItemClick(e)}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0"> {text}</h2>
                    </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
        );
      });

    return List;
  };

  /**
   * To handle number of storeys selection
   * @param {*} selectedStory 
   */
  const handleStorySelection = (selectedStory) => {
    if (selectedStory == stories) {
      handleFormValues({ stories: "", focusOut: true });
    } else {
      handleFormValues({ stories: selectedStory, focusOut: true });
    }
  };

  return (
    <>
      <div className={requirementBedroomsError ? "btn-group-wrap red-marked-btngroup" : "btn-group-wrap"}>
        <FormLabel className={requirementBedroomsError  && "red-marked-formlabel"}>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
        />
      </div>
      <div className={requirementBathroomsError  ? "btn-group-wrap red-marked-btngroup" : "btn-group-wrap"}>
        <FormLabel className={requirementBathroomsError && "red-marked-formlabel"}>
          Bathrooms (min)<span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>
     
      <SqareFeet
      handleFormValues = {handleFormValues}
      SFMax ={SFMax}
      SFMin ={SFMin}
      errors= {mustHaveErrors}/>

      <div className="btn-group-btm-wrap">
        <FormLabel>Garage Spaces</FormLabel>

        <ButtonGroups
          variant="outline-primary"
          section="Garage"
          value={garageSpace}
          currentSelectedValue={gSpace}
          items={["Any", "1+", "2+", "3+"]}
          onClick={(e) => handleGarageClick(e)}
          handleFormValues={handleFormValues}
        />
      </div>
      <div className="btn-group-btm-wrap">
        <FormLabel>Stories</FormLabel>
        <ButtonGroup aria-label="Basic example">
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_ANY)}
            variant={
              stories === STORY_ANY
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Any
          </Button>
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_SINGLE)}
            variant={
              stories === STORY_SINGLE
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Single Story Only
          </Button>
        </ButtonGroup>
      </div>
      <div className="range-values-wrap">
        <label className="form-label">Additional Requirements</label>
        {loadCheckBoxItems()}

      </div>
    </>
  );
};
export default WantAdRentMustHaves;
