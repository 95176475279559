import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ArchiveModal = (props) => {
  const { show, handleConfirm, onHide  } = props;

 
  return (
    <Modal
      show ={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pb-32 pt-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        ></button>
        <h2 className="text-start f-size-24 mb-2 l-spacing">Are you sure you want to pause your want-ad?</h2>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-2 f-size-16 text-start">
            Pausing this ad will stop any further introductions from being sent to you, and it will be removed from the ad feed. 
            </p>
            <p className=" mb-4 f-size-16 text-start">
            You can make your paused want-ad public again when you’re ready to receive introductions.
            </p>
            
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
            <Button variant="" className="btn btn-tertiary me-3" onClick={onHide}>
           Cancel
            </Button>    
          <Button variant="primary" className="btn" onClick={handleConfirm}>
          Pause My Want-Ad
            </Button>
            
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default ArchiveModal;
