import React, { useState, useEffect } from "react";
import {
  getLocalStorage,
  getUserType,
  setBodyBackgroundGrey,
  setBodyBackgroundWhite,
  setLocalStorage,
} from "../../helpers/common";
import { usePrevious } from "../../hooks/usePrevious";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  WANT_AD_KEY,
  WANT_AD_KEY_TEMP,
} from "../../constants/common";
import ProgressBar from "./ProgressBar";
import RequestStayAvailability from "../../components/Common/RequestStayAvailability";

const SelectAdType = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isPremiumUser,
    isEdit,
  } = props;
  const [disableSave, setDisableSave] = useState(true);
  const userType = getUserType();
  const previousAdType = usePrevious(adType);
  const adTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "adType", null);
  const browserLocation = getLocalStorage("userLocation");
  const turnOffStay = process.env.REACT_APP_TURN_OFF_STAY ? true : false;
  const [showRequestStayPopuop, setShowRequestStayPopup] = useState(false);
  const handleStayRequestPopupOpen = () =>{
    setShowRequestStayPopup(true);
  }

  const handleStayRequestPopupClose = () => {
    setShowRequestStayPopup(false);
  }

  useEffect(() => {
    setBodyBackgroundWhite()
    let isDisable;
    if (adType && !isEdit) {
      isDisable = false;
    } else {
      isDisable = true;
    }
    setDisableSave(isDisable);
  }, []);

  const getSelectedValue = (typeofAd) => {
    if(typeofAd === SHORT_RENT && turnOffStay){
      handleStayRequestPopupOpen()
    }
    else {
      if(isEdit){
        if(typeofAd != adType){
          setDisableSave(false)
        }
        else{
          setDisableSave(true)
        }
      }
      else {setDisableSave(false);}
      const value = {
        adType: typeofAd,
        // adType: BUY,
      };
      clearPreviousSelectedValues(value);
      handleFormValues(value);
    }
  };

  const clearPreviousSelectedValues = (currentType) => {
    if (previousAdType && previousAdType !== currentType) {
      clearPreviousStates({ type: "clearAll" });
    }
    // if (isEdit) {
    //   clearPreviousStates({ type: "clearAll" });
    // }
  };

  return (
    <>
    <RequestStayAvailability
      show={showRequestStayPopuop}
      locationFromSTorage={browserLocation}
      onHide={handleStayRequestPopupClose}
      />
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isFromAdType={true}
            isPremiumUser={isPremiumUser}
            isEdit={isEdit}
          />

          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div
              className="layout-content-btm-sec position-static pe-0 ps-0 
"
            >
              <h6 className="">AD TYPE</h6>
              <h2 className="">Are you looking to buy or rent?</h2>
            </div>

            <div>
              <a
                href={void 0}
                className={
                  adType && adType === BUY
                    ? "createAd-content-wrap mb-3 active"
                    : "createAd-content-wrap mb-3"
                }
                onClick={() => getSelectedValue(BUY)}
              >
                <h4>Buy</h4>
                {userType == 1 ? (
                  <p>
                    You're looking to purchase property, whether it’s your
                    primary residence or investment property.{" "}
                  </p>
                ) : (
                  <p>Purchase property</p>
                )}
              </a>
              <a
                href={void 0}
                className={
                  adType && adType === LONG_RENT
                    ? "createAd-content-wrap mb-3 active"
                    : "createAd-content-wrap mb-3"
                }
                onClick={() => getSelectedValue(LONG_RENT)}
              >
                <h4>Rent (Long-term)</h4>
                {userType == 1 ? (
                  <p>
                    You're looking to rent a place to call home. Select this
                    option if you're planning to live here for more than 30
                    days.{" "}
                  </p>
                ) : (
                  <p>Renting property more than 30 days. </p>
                )}
              </a>
              <a
                href={void 0}
                className={
                  adType && adType === SHORT_RENT
                    ? "createAd-content-wrap mb-3 active"
                    : "createAd-content-wrap mb-3"
                }
                onClick={() => getSelectedValue(SHORT_RENT)}
              >
                <h4>Rent (Short-term)</h4>
                {userType == 1 ? (
                  <p>
                    You're looking to rent a place for a short stay or vacation.
                    Select this option if you're planning a stay for less than
                    30 days.
                  </p>
                ) : (
                  <p>Renting property less than 30 days. </p>
                )}
              </a>
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if(adTypeInSession != adType){
                    setCompletedPercentage(14);
                    handleFormValues({ cmpldPerctg: 14 })
                  }
                  else{
                    setCompletedPercentage(completedPercentage)
                  }
                  setTimeout(() => {
                    setLocalStorage(
                      WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );
                    setUIKey(null);
                  }, 500);
                }}
              >
                {isEdit ? "Update" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectAdType;
