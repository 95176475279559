import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HouseImgIcon from "../../images/house-img.png";
import PropertyModal from "./PropertyModal";
import moment from "moment";
import { getInitials, getLocalStorage, getUserId, loggedInUser, setLocalStorage } from "../../helpers/common";
import ClientModal from "./ClientModal";
import { saveAgentClient } from "../../services/agentClientsService";
import AddPropertyIcon from "../../images/add-property-plus-icon.svg";
import { useNavigate } from "react-router-dom";
import AddNewClientModal from "../MemberWantAd/AddNewClientModal";
import { pageURLs } from "../../constants/pageURLs";
import noImage from "../../images/no-image-placeholder.png";
import { fetchUserStatistics, fetchUserDetails } from "../../actions/userprofile";
import PremiumPlanModal from "../../components/PlanUpgrade/PremiumPlanModal";
import { USER_INFO_KEY } from "../../constants/common";

const AgentPropertySelection = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedProperty = props.selectedProperty;
    const propertyList = props.propertyList;
    const [showPropertyModal, setPropertySelectionModal] = useState(false);
    const PropertyModalClose = () => setPropertySelectionModal(false);
    const PropertyModalShow = () => setPropertySelectionModal(true);

    const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
    const PremiumPlanModalClose = () => setPremiumPlanModal(false);
    const PremiumPlanModalShow = () => setPremiumPlanModal(true);
    const userDetailsFromStore = useSelector(
        (state) => state.userProfile.userDetails
    );
    const userStatictics = useSelector((state)=>state?.userProfile?.userStatictics)
    const userPrivileges = useSelector((state)=>state?.userProfile?.userPrivileges)
    const [userCount, setUserCount] = useState({})
    const [allowedCount, setAllowedCount] = useState({})
    const [userDetails, setUserDetails] = useState(null);

    const clientList = props.clientList;
    const [showClientModal, setClientSelectionModal] = useState(false);
    const ClientModalClose = () => setClientSelectionModal(false);
    const ClientModalShow = () => setClientSelectionModal(true);

    const [showAddClientModal, setAddClientModal] = useState(false);
    const AddClientModalClose = () => setAddClientModal(false);
    const AddClientModalShow = () => setAddClientModal(true);

    const userId = getUserId();
    const [initials, setInitials] = useState('');
    const [reload, setReload] = useState(0)
    const wantAdId = props.wantAdId;

    useEffect(()=>{
    if(userId){  
        dispatch(fetchUserDetails())    
    }
    },[reload])

    const updateReloadParam = () => {
        setReload(reload + 1);
      };

    useEffect(() => {
        if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
          setUserDetails(userDetailsFromStore[0]);
        } else {
          setUserDetails(null);
        }
      }, [userDetailsFromStore]);

    useEffect(() => {
        let name = selectedProperty?.selectedClient ? (
            selectedProperty?.selectedClient?.FirstName + ' ' + selectedProperty?.selectedClient?.LastName
        ) : '';
      
        setInitials(getInitials(name));
    }, [selectedProperty?.selectedClient])

    useEffect(()=>{
        const payload ={
          UserPlanId : userDetails?.User_Plan_Id
        }
    
        dispatch(fetchUserStatistics(payload))
      }, [userDetails])


    useEffect(() => {
        if (userPrivileges !== null && userPrivileges.length > 0) {
          setAllowedCount(userPrivileges[0]);
        } else {
          setAllowedCount({});
        }
      }, [userPrivileges]);
    
    
      useEffect(() => {
        if (userStatictics !== null && userStatictics.length > 0) {
          setUserCount(userStatictics[0]);
        } else {
          setUserCount({});
        }
      }, [userStatictics]);       

    const AddNewClient = (isFromClientModal = false) => {
        setClientSelectionModal(false);
        if (isFromClientModal) {
            setClientSelectionModal(false)
        }
        setAddClientModal(true);
    }

    const addClientData = async (data) => {
        var param = {
            ...data,
        }
        await saveAgentClient(param)
            .then((response) => {
                if (response.errorCode === 0) {
                    const userInfo = getLocalStorage(USER_INFO_KEY);
                    userInfo.userInfo["ClientCount"] =  userInfo.userInfo?.ClientCount ? userInfo.userInfo?.ClientCount + 1 : 1;
                    setLocalStorage(USER_INFO_KEY, userInfo); 

                    props.updateClientReload();
                    setAddClientModal(false);
                    setClientSelectionModal(false);
                } else {
                    return;
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });
    }

    return (
        <>
            <div className="add-property-outerwrap">
                <a   href={void 0} className="add-your-property-wrap link-wrap mb-0" onClick={() => {
                    ClientModalShow()
                }}>
                    <div className="left-prof-wrap-outer me-2">
                        <div className={"left-prof-wrap me-0 " + initials?.backgroundColor}>
                            {initials?.initials}
                        </div>
                    </div>
                    <div className="modal-wrap-info long-txt-fix">
                        <h2 className="pb-0 long-txt-fix">
                            { selectedProperty?.selectedClient ? (
                                selectedProperty?.selectedClient?.FirstName + ' ' + selectedProperty?.selectedClient?.LastName
                            ) : ''}</h2>

                    </div>
                    <span
                        role="button"
                        class="right-arrow-link"
                        href={void 0}
                       
                        >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                                fill="#000022"
                            />
                        </svg>
                    </span>
                </a>
                {props.propertyListExist  ? (
                    <a   href={void 0} onClick={() => {
                        PropertyModalShow()
                    }} className="add-your-property-wrap link-wrap" id="propertySelection">
                        {/* <div className={"add-your-property-wrap " + intoSentClassName + " " + intoDeclinedClassName}> */}
                        <div className="make-intro-img">
                            <img className="display-thumb-img" src={selectedProperty?.selectedProperty?.photo ? selectedProperty?.selectedProperty?.photo : noImage}  />
                        </div>
                        <div className="make-intro-details">
                            <h3 className="long-txt-fix">{selectedProperty?.selectedProperty?.Location}</h3>
                            {/* <p>Eugene, OR</p> */}

                            {/* {(selectedProperty?.selectedProperty?.IsIntroSent ==1 && selectedProperty?.selectedProperty?.IsCancelled == 0) && */}
                            {(selectedProperty?.selectedProperty?.IsIntroSent == 1 && 
                            selectedProperty?.selectedProperty?.IsCancelled == 0 
                            && selectedProperty?.selectedProperty?.IsDecline != 1 && 
                            selectedProperty?.selectedProperty?.NotInterested != 1 
                            && selectedProperty?.selectedProperty?.Not_A_Fit != 1) ?
                                                        <div className="modal-wrap-label-grey">SENT {moment(
                                                            new Date(selectedProperty?.selectedProperty?.IntroSentDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :
                                                    selectedProperty?.selectedProperty?.IsDecline == 1 ?
                                                        <div className="modal-wrap-label-orange">DECLINED {moment(
                                                            new Date(selectedProperty?.selectedProperty?.IntroDeclineDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :

                                                    selectedProperty?.selectedProperty?.NotInterested == 1 ?
                                                        <div className="modal-wrap-label-orange">NOT INTERESTED {moment(
                                                            new Date(selectedProperty?.selectedProperty?.IntroDeclineDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :

                                                    selectedProperty?.selectedProperty?.Not_A_Fit == 1 ?
                                                        <div className="modal-wrap-label-orange">NOT A FIT {moment(
                                                            new Date(selectedProperty?.selectedProperty?.IntroDeclineDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :

                                                    !selectedProperty?.selectedProperty?.photo &&
                                                        <div className="modal-wrap-label-blue">MUST ADD A PHOTO TO INTRODUCE</div>

                                                    }
                           
                            {/* {( selectedProperty?.selectedProperty?.IsIntroSent == 1 && 
                        selectedProperty?.selectedProperty?.IsCancelled == 0 &&  
                        selectedProperty?.selectedProperty?.IsDecline != 1  &&
                         selectedProperty?.selectedProperty?.NotInterested != 1  &&  
                         selectedProperty?.selectedProperty?.Not_A_Fit != 1 ) &&
                            <div className="modal-wrap-label-grey">SENT {moment(
                                new Date(selectedProperty?.selectedProperty?.IntroSentDate)
                            ).format("MMM Do, YYYY")}</div>

                        }
                        {selectedProperty?.selectedProperty?.IsDecline == 1 &&
                            <div className="modal-wrap-label-orange">DECLINED {moment(
                                new Date(selectedProperty?.selectedProperty?.IntroDeclineDate)
                            ).format("MMM Do, YYYY")}</div>

                        }

                        {selectedProperty?.selectedProperty?.NotInterested == 1 &&
                            <div className="modal-wrap-label-orange">Not Interested {moment(
                                new Date(selectedProperty?.selectedProperty?.IntroDeclineDate)
                            ).format("MMM Do, YYYY")}</div>

                        }

                        {selectedProperty?.selectedProperty?.Not_A_Fit == 1 &&
                            <div className="modal-wrap-label-orange">Not A Fit {moment(
                                new Date(selectedProperty?.selectedProperty?.IntroDeclineDate)
                            ).format("MMM Do, YYYY")}</div>

                        } */}

                        
                            {/* {selectedProperty?.selectedProperty?.IntroSentDate &&
                                <div className="modal-wrap-label-grey">SENT {moment(
                                    new Date(selectedProperty?.selectedProperty?.IntroSentDate)
                                ).format("MMM Do, YYYY")}</div>

                            }
                            {selectedProperty?.selectedProperty?.IntroDeclineDate &&
                                <div className="modal-wrap-label-orange">DECLINED {moment(
                                    new Date(selectedProperty?.selectedProperty?.IntroSentDate)
                                ).format("MMM Do, YYYY")}</div>

                            } */}
                        </div>

                        <span
                            role="button"
                            class="right-arrow-link"
                            href={void 0}
                            // onClick={() => {
                            //     PropertyModalShow()
                            // }}
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                                    fill="#000022"
                                />
                            </svg>
                        </span>
                    </a>
                ) : (

                    <a href={void(0)} onClick={() => {
                        if(userCount?.Inventory >= allowedCount?.Inventory){                            
                              PremiumPlanModalShow()
                        }
                        else {navigate(pageURLs.agentCreateInventory,  { state: { isFromIntroduction: true,  wantAdId: wantAdId } })}
                    }} className="add-your-property-wrap link-wrap" id="propertySelection">
                        <div className="add-property-btn">
                            <img src={AddPropertyIcon} alt={AddPropertyIcon} />
                        </div>
                        <div className="make-intro-details">
                            <h3 className="mb-0">Add your property</h3>
                        </div>

                        <span
                            role="button"
                            class="right-arrow-link"
                            href={void 0}
                            onClick={() => { navigate(pageURLs.agentCreateInventory, {
                                state: {isFromIntroduction: true, wantAdId: wantAdId },
                              }) }}
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                                    fill="#000022"
                                />
                            </svg>
                        </span>
                    </a>

                )}
            </div>
            <PremiumPlanModal
                userDetails={userDetails}
                updateReloadParam={updateReloadParam}
                show={showPremiumPlanModal}
                onHide={PremiumPlanModalClose}
                fromInventoryListing={true}
                showPlanModal={PremiumPlanModalShow}
            />
            <ClientModal
                show={showClientModal}
                clientList={clientList}
                handleClientChange={(client) => { props.handleClientChange(client) }}
                selectedClientId={selectedProperty?.clientId}
                AddNewClient={(isFromClientModal) => { AddNewClient(isFromClientModal) }}
                onHide={ClientModalClose}
            />
            <PropertyModal
                show={showPropertyModal}
                propertyList={propertyList}
                handlePropertyChange={(property) => { props.handlePropertyChange(property) }}
                selectedPropertyId={selectedProperty?.inventoryId}
                userCount={userCount}
                allowedCount={allowedCount}
                userDetails={userDetails}
                onHide={PropertyModalClose}
                updateReloadParam={updateReloadParam}
                userType={props.userType} 
                wantAdId={wantAdId}/>


            <AddNewClientModal show={showAddClientModal}
                onHide={AddClientModalClose}
                addClient={addClientData} />
        </>
    );

};
export default AgentPropertySelection;
