import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function getClientsList(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_CLIENTS_LISTING,
    "GET",
    requestPayload
  );
}

export async function getAgentDashboard(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_AGENT_DASHBOARD,
    "GET",
    requestPayload
  );
}

export async function saveAgentClient(requestPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_AGENT_CLIENT,
    "POST",
    requestPayload
  );
}

export async function getAgentWantAdList(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_AGENT_DASHBOARD_WANT_AD_LIST,
    "GET",
    requestPayload
  );
}

export async function getAgentInventoryList(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_AGENT_DASHBOARD_INVENTORY_LIST,
    "GET",
    requestPayload
  );
}

export async function getClientDetails(requestPayload) {
  const { clientId, isClientInfoReq, type, status, sortBy, page, limit } = requestPayload
  return await apiService.apiCall(
    endPoints.GET_CLIENT_DETAILS +
    `?clientId=${clientId}&isClientInfoReq=${isClientInfoReq}&type=${type}&status=${status}&sortBy=${sortBy}&page=${page}&limit=${limit}`,
    "GET",
    {}
  );
}

export async function getClientDetailsMobile(clientId) {
  return await apiService.apiCall(
    endPoints.GET_CLIENT_DETAILS_MOBILE +
    `?clientId=${clientId}`,
    "GET",
    {}
  );
}


export async function getClientInfo(clientId) {
  return await apiService.apiCall(
    endPoints.GET_CLIENT_INFO +
    `?clientId=${clientId}`,
    "GET",
    {}
  );
}

export async function getAgentDetails(agentId) {
  return await apiService.apiCall(
    endPoints.GET_AGENT_DETAILS +
    `?agentId=${agentId}`,
    "GET",
    {}
  );
}



export async function deleteClient(clientId) {
  return await apiService.apiCall(
    endPoints.DELETE_CLIENT +
    `?clientId=${clientId}`,
    "PUT",
    {}
  );
}

export async function getClientNotes(reqPayload) {
  const { clientId, page, limit } = reqPayload
  return await apiService.apiCall(
    endPoints.GET_CLIENT_NOTES + `?clientId=${clientId}&page=${page}&limit=${limit}`,
    "GET",
    {}
  );
}

export async function deleteClientNote(reqPayload) {
  const { clientId, noteId } = reqPayload
  return await apiService.apiCall(
    endPoints.DELETE_CLIENT_NOTE + `?clientId=${clientId}&noteId=${noteId}`,
    "PUT",
    {}
  );
}

export async function saveClientNote(reqPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_CLIENT_NOTE,
    "POST",
    reqPayload
  );
}


export async function getIntroClientList(userId, search= null) {
  return await apiService.apiCall(
    endPoints.GET_INTRO_CLIENT_LIST + `?search=${search}`,
    "GET",
    {}
  );
}

export async function getMatchClientList(userId, search= null) {
  return await apiService.apiCall(
    endPoints.GET_MATCH_CLIENT_LIST + `?search=${search}`,
    "GET",
    {}
  );
}


export async function getClientNoteListForExport(clientId) {
  return await apiService.apiCall(
    endPoints.GET_CLIENT_NOTES_FOR_EXPORT + `?clientId=${clientId}`,
    "GET",
    {}
  );
}


export async function getClientNoteListPdfExport(reqPayload) {
  return await apiService.apiCall(
    endPoints.GET_CLIENT_NOTES_FOR_PDF_EXPORT + `?clientId=${reqPayload?.clientId}`,
    "GET",
    {}
  );
}

export async function saveClientDetails(requestPayload) {
  return await apiService.apiCallWithoutToken(
    endPoints.SAVE_BUYER_LIST_AGENT_CLIENT,
    "POST",
    requestPayload
  );
}



