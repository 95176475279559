import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { getWantAdReportingReasons } from "../../services/commonServices";
import { USER_INFO_KEY } from "../../constants/common";
import { getLocalStorage } from "../../helpers/common";
import { reportWantAd } from "../../services/wantAdService";
import { toast } from "react-toastify";

const ReportWantAdModal = (props) => {
  const { show, onHide, wantAdId } = props;
  const [reportReasons, setReportReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const sessionData = getLocalStorage(USER_INFO_KEY);  
  const userId = _.get(sessionData, 'userInfo.userId', '')

  useEffect(() => {
    fetchReportingReasons();
  }, []);

  const fetchReportingReasons = async () => {
    await getWantAdReportingReasons().then((response) => {
      if (response?.errorCode === 0) {
        setReportReasons(response?.result);
      } else {
        setReportReasons([]);
      }
    });
  };

  const handleReportWantAd = async () => {
    const params = {
      "wantAdId" : wantAdId,
      "reasonId" : selectedReason
    }

    await reportWantAd(params).then((response)=>{
      props.onHide()
      if(response?.errorCode===0){
        toast.dismiss()
        setSelectedReason(null)
        toast.success("Report was successfully submitted")
      }
      else{
        toast.dismiss()
        setSelectedReason(null)
        toast.error(response?.message)
      }

    }).catch((err)=>{
      toast.dismiss()
      toast.error(err)
    })
  }

  const handleCancel = () =>{
    setSelectedReason(null)
    props.onHide()
  }

  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pb-32 pt-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        ></button>
        <h3 className="text-start mb-12">Report this want-ad</h3>
        <p className="f-size-16 mb-32">
          Your report is always anonymous. Once the report is made we'll
          investigate the situation and determine what to do next.
        </p>
        <p className="f-size-16 f-bold mb-3">Reason for reporting:</p>

        <div className="modal-controls-wrap mb-32">
          {reportReasons.length > 0 &&
            reportReasons.map((reason) => {
              return (
                <label className="radio-wrap mb-3">
                  <input 
                  type="radio" 
                  name="radio-ad-option" 
                  onChange={()=>{setSelectedReason(reason?.ReasonId)}}
                  checked={reason?.ReasonId === selectedReason} />
                  <span>{reason?.Reason_Text}</span>
                </label>
              );
            })}          
        </div>
        <Row>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
              <Button
                variant="tertiary"
                className="btn btn-tertiary me-3 btn-margin"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button variant="primary" className="btn" disabled={selectedReason===null} onClick={()=>handleReportWantAd()}>
                Report Want-Ad
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default ReportWantAdModal;
