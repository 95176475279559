import React, { useState, useEffect } from "react";
import "../../styles/clients.scss";
import {getUserType, setLocalStorage } from "../../../helpers/common";
import { AGENT_INTRODUCTION, MEMBER_INTRODUCTION } from "../../../constants/common";

import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { SortablePhoto } from "./SortablePhoto";
import { Photo } from "./Photo";
import { CUSTOMIZE_INTRO } from "../../../constants/onBoarding";

const IntroEditPhotos = (props) => {

    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));


    useEffect(() => {

        document.body.classList.add('bg-offcanvas-fix');

    }, []);

    const userType = getUserType();

    let session = userType == 2 ? AGENT_INTRODUCTION : MEMBER_INTRODUCTION;

    const { selectedProperty } = props
    const [selectedDatas, setSelectedDatas] = useState(selectedProperty);
    const inventoryPhotosForPreview = selectedProperty?.photosForPreview;
    const [inventoryPhotos, setInventoryPhotos] = useState([]);

    useEffect(() => {
        if (inventoryPhotosForPreview && inventoryPhotosForPreview.length > 0) {
            setInventoryPhotos(inventoryPhotosForPreview)
        }
        else {
            setInventoryPhotos([])
        }
        // setPropertyFeatureReorder(false);

    }, [inventoryPhotosForPreview])
    
    useEffect(() => {
        setLocalStorage(session, selectedDatas);
    }, [selectedDatas])


    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        let sourceIndex = active.data?.current?.sortable?.index;
        let destinationIndex = over.data?.current?.sortable?.index;
        if (sourceIndex !== destinationIndex) {

            let newInventoryPhotos = inventoryPhotos;
            newInventoryPhotos.splice(destinationIndex, 0, newInventoryPhotos.splice(sourceIndex, 1));

            newInventoryPhotos = newInventoryPhotos.map(item => Array.isArray(item) ? item[0] : item);
            newInventoryPhotos = newInventoryPhotos.map((feature, index) => {
                return { ...feature, order: index + 1, isCover: index > 2 ? 0 : 1 };
            });

            let orderdImages = newInventoryPhotos.sort((a, b) => a.order - b.order);
            setInventoryPhotos(orderdImages);

            let newArray = { photosForPreview: orderdImages }

            setSelectedDatas(selectedDatas => ({ ...selectedDatas, ...newArray }));
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    return (

        <div className="gitcha-main-wrapper my-client-wrap">

            <div className="content-wrapper content-wrapper-h-102-percent">
                <div className="inner-layout-header-new bg-white">
                    <div className="review-plan-header header-brdr-btm w-100">
                        <div className="signup-login-header-top signup-header-txt clearfix">
                            <a className="back-link-small" href={void (0)} onClick={() => { props.setUIKey(CUSTOMIZE_INTRO) }}><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                            </svg></a>
                            <span>Edit Photos</span>
                        </div>
                    </div>
                </div>

                <div className="inner-layout-content-new bg-white">
                    <div className="reorder-wrap">
                        <h4>Drag to change your photos for this introduction</h4>
                        <p className="m-0">You can show up to three photos in your introduction. They will be able to see all photos when they accept. </p>


                        <section className="uploaded-file-list-mob w-100 position-relative" >
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                                onDragCancel={handleDragCancel}
                            >
                                <SortableContext items={inventoryPhotos} strategy={rectSortingStrategy}>

                                    {
                                        inventoryPhotos.sort((a, b) => a.order - b.order).map((photo, index) => {
                                            return (
                                                <SortablePhoto key={index} url={photo} index={index} />
                                            )
                                        })}
                                </SortableContext>

                                <DragOverlay adjustScale={true}>
                                    {/* {activeId ? (
                                        <Photo url={activeId} index={inventoryPhotos.indexOf(activeId)} />
                                    ) : null} */}
                                    {null}
                                </DragOverlay>
                            </DndContext>

                        </section>

                    </div>
                </div>


            </div>
        </div>
    );
};

export default IntroEditPhotos;
