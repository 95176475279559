import React, { useState, useEffect } from "react";
import { getPropertyTypes } from "../../services/commonServices";
import { usePrevious } from "../../hooks/usePrevious";
import { WANT_AD_BUY_PROPERTY_ID, BUY, WANT_AD_KEY } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";
import { handleRadioButonChange } from "../../actions/common";
import { checkArrayisEqual, getLocalStorage } from "../../helpers/common";
const AddPropertyTypeBuy = (props) => {
  const { handleFormValues, formValues, setDisableSave, clearPreviousStates, isEdit } = props;

  const [propertyList, setPropertyList] = useState([]);
  const propertyType = _.get(formValues, "prprtyType", null);
  const prptySubTypes = _.get(formValues, "prptySubTypes", []);

  const propertyTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "prprtyType", null);
  const prptySubTypesInSession = _.get(getLocalStorage(WANT_AD_KEY), "prptySubTypes", []);

  useEffect(() => {
    if(!isEdit){
      if (propertyType) {
        setDisableSave(false);
      } else {
        const isDisable = true;
        setDisableSave(isDisable);
      }
    }
    const typeOfAd = BUY;
    getPropertyTypes(typeOfAd)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const previousValue = usePrevious(propertyType);

  const getSelectedValue = (e, typeText, typeId) => {
    if (typeId) {
      e.stopPropagation();
      if(!isEdit){setDisableSave(false);}
      if(isEdit && typeId === propertyTypeInSession){
        setDisableSave(true);
      }
      else{
        setDisableSave(false);
      }
      clearPreviousSelectedValues(typeId);
      handleFormValues({ prprtyType: convertToNumber(typeId),  prptyTypeTxt: typeText, prptySubTypes: [convertToNumber(typeId)]});
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    if (previousValue) {
      const isClearPrevious =
        (convertToNumber(previousValue) === 6 &&
          convertToNumber(currentSelectedValue) !== 6) ||
        (convertToNumber(previousValue) !== 6 &&
          convertToNumber(currentSelectedValue) === 6);
      if (isClearPrevious) {
        clearPreviousStates();
      }
    }
  };

  const handlePropertyMultipleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      if(!isEdit){setDisableSave(false);}
      if (_.includes(prptySubTypes, convertToNumber(value))) {
        subItemArray = prptySubTypes.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {
        subItemArray = prptySubTypes.filter((e) => e !== 4 && e !== 6);
        subItemArray = [...subItemArray, convertToNumber(value)];
      }
      if(subItemArray.length<=0){
        setDisableSave(true);
      }
      else if(isEdit && checkArrayisEqual(subItemArray, prptySubTypesInSession))
      {        
        setDisableSave(true);
      }
      else{
        setDisableSave(false)
      }
      handleFormValues({
        prptySubTypes: subItemArray,
        prprtyType: WANT_AD_BUY_PROPERTY_ID,
        prptyTypeTxt: "BUY",
      });
    }
  };

  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const imgPath = _.get(pt, "Img");
        const isChecked = prptySubTypes && _.includes(prptySubTypes, typeId);
        const radioOrCheckBox =
          _.get(pt, "Buy_IsMultiSelect", 0) == 0 ? true : false;
        return (
          <>
            {!radioOrCheckBox ? (
              // <a href={void 0} className="createAd-content-wrap mb-3">
              <div className={isChecked ? "addproperty-type-mob active" : "addproperty-type-mob"}>
                <label class="form-check-label checkbox-label " key={typeId}>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={typeId}
                    value={typeId ? typeId : ""}
                    checked={isChecked}
                    onChange={(e) => handlePropertyMultipleItemClick(e)}
                  />
                  <span className="">
                    <div className="addproperty-type-info">
                      <h6>{typeText}</h6>
                    </div></span>
                </label>
              </div>
              // </a>
            ) : (
              <a
                href={void 0}
                className={isChecked ? "createAd-content-wrap mb-4 active" : "createAd-content-wrap mb-4"}
                onClick={(e) => getSelectedValue(e, typeText, typeId)}
              >
                <label class="form-check-label checkbox-label " key={typeId}>
                  <span className="labelTxt">{typeText}</span>
                </label>
              </a>
            )}
          </>
        );
      });

    return List;
  };

  return <>{loadPropertyTypes()}</>;
};

export default AddPropertyTypeBuy;
