import { createSlice } from "@reduxjs/toolkit";
import { fetchMessages } from "../actions/messages";
const initialState = {
  messageLoading : false,
  message : {},
  messageError : "",
};

const messages = createSlice({
  name: "messages",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state, action) => {
      state.messageLoading = true;
      state.message = [];
      state.messageError = "";
    });

    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      state.messageLoading = false;
      state.message = action.payload.result;
      state.messageError = "";
    });

    builder.addCase(fetchMessages.rejected, (state, action) => {
      state.messageLoading = false;
      state.message = {};
      state.messageError = action?.error?.message;
    });


  },
  
});

export default messages.reducer;
