/**
 * @file   src\components\Common\howItWorksTutorial.js
 * @brief  This file is responsible for showing How it works section in default search screen
 * @date   June, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import Button from "react-bootstrap/Button";

const HowItWorksTutorial = (props) => {
  const { isAgent } = props;
  return (
    <div className="search_wrap_btm d-flex justify-content-start">
      <div>
        <h2>Want an overview of Gitcha?</h2>
        {isAgent ? (
          <h4>View our library of tutorials & get the most out of Gitcha.</h4>
        ) : (
          <h4>See how it works & get the most out of Gitcha.</h4>
        )}
      </div>
      {isAgent ? (
        <Button
          variant="primary"
          className="btn btn-primary"
          onClick={() =>
            window.open(
              "https://loom.com/share/folder/e70fe7a5f7764334a30b557ccb9e7e6a",
              "_blank"
            )
          }
        >
          Watch Tutorials
        </Button>
      ) : (
        <Button
          variant="primary"
          className="btn btn-primary"
          onClick={() =>
            window.open("https://www.gitcha.com/#Howitworks", "_blank")
          }
        >
          How It Works
        </Button>
      )}
    </div>
  );
};
export default HowItWorksTutorial;
