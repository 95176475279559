import React, { useState , useEffect} from "react";
import ProgressBarInventory from "./ProgressBarInventory";
import { handleTextBoxChange } from "../../../actions/common";
import { textValidations } from "../../../helpers/validations";
import { checkIsNull, getLocalStorage, setBodyBackgroundWhite, setLocalStorage, trimString } from "../../../helpers/common";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP } from "../../../constants/common";
import ConfirmrepresentationModal from "./ConfirmRepresentationModal";


const AddDescription = (props) => {
  const { completedPercentage , setUIKey, handleFormValues, formValues, setCompletedPercentage, isAgent, isEdit} = props;
  const invtryDetails = _.get(formValues, "invtryDetails", "");
  const invtryDetailsInSession = _.get(getLocalStorage(INVENTORY_KEY), "invtryDetails", "");
  const [detailCharCount, setDetailCharCount] = useState(0);
  const [descError, setDescError] = useState('');
  const [disableSave, setDisableSave] = useState(true);

  const [confirmRepresentation, setConfirmRepresentation] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleConfirmClose = () =>{
    setShowConfirmModal(false);
  }

  useEffect(() => {
    setDetailCharCount(invtryDetails && invtryDetails.length ? invtryDetails.length : 0);
    if(invtryDetails){
    if(descError){
      disableContinueButton()
    }
    else if (trimString(invtryDetails) !== "" && textValidations(invtryDetails)) {
      if(isEdit && invtryDetails===invtryDetailsInSession){
        setDisableSave(true)
      }
      else{  setDisableSave(false);}
    } else {
      setDescError(ERROR_TEXT)
      disableContinueButton()
    }
  } else {
    if(isEdit && invtryDetails===invtryDetailsInSession){
      setDisableSave(true)
    }
    else{  setDisableSave(false);}
  }

  }, [invtryDetails, descError]);


  useEffect(() => {
    setBodyBackgroundWhite()
  }, []);

  const disableContinueButton = () => {
    const isDisabled = true
    setDisableSave(isDisabled)
  }


  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDetailsChange = (e) => {
    setDescError(null)
    const value = capitalizeFirstString(handleTextBoxChange(e));
    if (!checkIsNull(value)) {
      if(!textValidations(value)){
        setDescError(ERROR_TEXT)
      } 
      handleFormValues({ invtryDetails: value });
      setDetailCharCount(value.length);
    } else {
      handleFormValues({ invtryDetails: "" });
    }
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isEdit={isEdit}
          />
          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div className="layout-content-btm-sec position-static pe-0 ps-0 ">
              <h6 className="">DESCRIPTION</h6>
              <h2 className="">What should people know about your {isAgent ? "client's " : ""}property?</h2>
              <p className="titleTextpara mt-2">
                Providing a description about your property will make your
                introductions stronger.
              </p>
            </div>

            <div className="row">
              <div className="col-12 mb-3">
                <div className="">
                  <div className="mob-fld-wrap">
                    <label className="form-label">
                      Additional Details
                    </label>
                  </div>
                  <div className="search_field_input  mb-4 input-group">
                    <textarea
                      class="form-control txtAreaStyle"
                      errorMessage ={descError}
                      maxLength={2500}
                      value={invtryDetails ? invtryDetails : ''}
                      placeholder="Ex. We bought this house in 2000 and are considering selling it with the right offer. We've taken very good care of various maintenance...."
                      onChange={(e) => {
                      handleDetailsChange(e);
                      }}
                    ></textarea>
                    <div className="text-end fnt10 pt-1 des-count-mob des-count-304">{detailCharCount}/2500</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button type="button" 
              disabled={disableSave}
              className="btn btn-primary w-100 mb-0"
              onClick={()=>{
                setCompletedPercentage(100);
                if(isAgent && !confirmRepresentation){
                  setShowConfirmModal(true)
                }
                else {setTimeout(()=>{         
                    setLocalStorage(
                      INVENTORY_KEY,
                      getLocalStorage(INVENTORY_KEY_TEMP)
                    );
                    if(isEdit){
                      setUIKey(null);
                    }
                    else {setUIKey("readyToPost")}
                  },500);
              }}}
              >
               {isEdit ? "Update" : confirmRepresentation ? "Review & Post": "Review & Add"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmrepresentationModal
      show={showConfirmModal}
      onHide={handleConfirmClose}
      setConfirmRepresentation={setConfirmRepresentation}
      />
    </>
  );
};

export default AddDescription;
