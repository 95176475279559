import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import VisaIcon from "../../images/pm-visa-icon.svg";
// import MastercardIcon from "../../images/pm-mastercard-icon.svg";
// import PaypalIcon from "../../images/pm-paypal-icon.svg";
import { getCardIconForIntroduction, isDateInThePast } from "../../../../helpers/common";


const PaymentMethodsModal = (props) => {
  const userPaymentMethods = props.paymentMethods;
  const selectedCardId = props.selectedCardId;

  const handleRadioButtonChange = (paymentMethod) =>{
    props.updateCardParam (paymentMethod);
    props.onHide()
  }

  return (
    <Modal
      show
      centered
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"

      className="modal-right-aligned"

    >
      <Modal.Body className="">

        <div className="sub-heading-small position-relative mb-27">
          <a role="button" className="sub-heading-backbtn" href={void 0}
            onClick={props.onHide}>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z" fill="#1B1C1E" />
            </svg>

          </a>
          Choose Payment Method</div>
        <div className="position-relative">
          <div className="modal-choose-property modal-v-scroll">

          { userPaymentMethods && userPaymentMethods.length>0 &&
          userPaymentMethods?.map((paymentMethod)=>{
            let expired = isDateInThePast(paymentMethod?.Exp_Month, paymentMethod?.Exp_Year);
            return(

            <label className="property-radio">
              {!expired && (
              <input type="radio" name="check-box-option" 
               id={paymentMethod?.CardId}
               value={paymentMethod?.CardId}
               checked={paymentMethod?.Payment_Mode_Id == selectedCardId ? true : false}
               onClick={() => handleRadioButtonChange(paymentMethod)}
               onChange={() => { }}/>
               ) }
              <span className="mh-72">
                <div className="payment-icon-wrap bg-white">
                <img src={getCardIconForIntroduction(paymentMethod?.Brand)}/>
                </div>
                <div className="modal-wrap-info">
                  <h2>{paymentMethod?.Brand} •••••••••••••{paymentMethod?.Last4}</h2>
                  {expired ?
                 ( <p className="card-expired-txt">Expired {String(paymentMethod?.Exp_Month).padStart(2, '0')}/{String(paymentMethod?.Exp_Year).slice(-2)}</p>) : 
                ( <p>Exp. {String(paymentMethod?.Exp_Month).padStart(2, '0')}/{String(paymentMethod?.Exp_Year).slice(-2)}</p>)
                  
                }
                 
                </div>


              </span>
            </label>
          )})}
      </div>

          <div className="modal-choose-property-btnwrap">
            <button className="btn btn-tertiary w-100" onClick={()=>props.AddNewPayment(true)}>Add Payment Method</button>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PaymentMethodsModal;
