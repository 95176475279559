import React, { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { getAgentsList } from "../../../services/wantAdService";
import { getInitials, getLocalStorage, getUserId, loggedInUser, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";
import { renameKey, combineKey } from "../../../helpers/common";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import AddNewClient from "../../commonComponents/ClientPopup";
import { convertToNumber } from "../../../helpers/validations";
import ProgressBarInventory from "./ProgressBarInventory";

const SelectClient = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    clearPreviousStates,
    setCompletedPercentage,
    isEdit,
    uiKey,
    isPremiumUser,
  } = props;
  const sessionData = loggedInUser();

  const clientId = _.get(formValues, "clientId", null);
  const clientIdInSession = _.get(getLocalStorage(INVENTORY_KEY), "clientId", null);
  const isAgentAsSeller = _.get(formValues, "isAgentAsSeller", null);
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", null));
  const locationAddress =_.get(formValues, "locAddress", null);


  const [show, setShow] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  const [clientsList, setClientsList] = useState([]);

  const [searchText, setSearchText] = useState("")

  const handleClose = () => {setShow(false);getClientList();};
  const handleShow = () => setShow(true);

  useEffect(() => {
    getClientList(); // to load client list on page load
    isAgentAsSeller && !isEdit ? setDisableSave(false) : setDisableSave(true); //
  }, [searchText]);

  useEffect(() => {
    setBodyBackgroundWhite()
   }, []);

  const getClientList = async () => {
    let query = {
      search : searchText ? searchText : null

    };
    await getAgentsList(query)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          responseResult.forEach((obj) => renameKey(obj, "ClientId", "value"));
          responseResult.forEach((obj) =>
            combineKey(obj, "FirstName", "LastName", "label")
          );
          if(clientId) {
            const filteredList = responseResult.filter((client)=>clientId===client.value)
            const remainingList = responseResult.filter((client)=>clientId!==client.value)
            setClientsList(filteredList.concat(remainingList))
            if(isEdit){
              setDisableSave(true)
            }
            else{  setDisableSave(false)}
          }
          else {setClientsList(responseResult);}
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleChangeClient = (label, value) => {
    handleFormValues({ isAgentAsSeller: "" });
    if (value == 0) {
      handleShow();
    } else {
      handleFormValues({
        clientId: value,
        clientName: label,
        clientData: {
          value: value,
          label: label,
        },
      });
    }
    if(isEdit){
      if(value!==clientIdInSession){
        setDisableSave(false);
      }

      else {
        setDisableSave(true);
      }
    }
    else{setDisableSave(false);}
  };

  const handleCheckBox = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      if(isEdit){
        if(isAgentAsSeller === isChecked){
          setDisableSave(true);
        }
        else{
          setDisableSave(false);
        }
      }
      else {setDisableSave(false);}
      handleFormValues({
        isAgentAsSeller: true,
        clientId: "",
        clientName: sessionData.userInfo.name,
        clientData: {
          value: "",
          label: "",
        },
      });
    } else {
      if(isEdit){
        if(isAgentAsSeller === !isChecked){
          setDisableSave(true);
        }
        else{
          setDisableSave(false);
        }
      }
      else{
        setDisableSave(true);
      }
      handleFormValues({ isAgentAsSeller: "" });
    }
  };
  return (
    <>
      <div className={clientsList.length===0 ? "gitcha-main-wrapper pb-118 bg-white": "gitcha-main-wrapper pb-168 bg-white"}>
        <div className={clientsList.length===0 ? "content-wrapper content-wrapper-h-118" : "content-wrapper content-wrapper-h-168"}>
          <ProgressBarInventory
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            uiKey={uiKey}
            isPremiumUser={isPremiumUser}
          />

          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div
              className="layout-content-btm-sec position-static pe-0 ps-0"
            >
              <h6 className="">Client</h6>
              <div className="mt-3 pt-0 mb-0 titleText">
              Select the client you’re representing for this property
              </div>
              <div className="titleTextpara">Your client’s name is just for organization and hidden to everyone else but you.</div>
            </div>           

            <div className="row">
              <div className="col-12 mb-3">
                <div className=" position-relative">
                  <div className="search_field_input position-relative mb-0 input-group d-flex">
                    <input
                      aria-label="Example"
                      aria-describedby="basic-addon3"
                      placeholder="Client name"
                      class="form-control pr-0"
                      value={searchText}
                      onChange={(e)=>setSearchText(e.target.value)}
                    ></input>

                    <button
                      type="button"
                      id="button-addon3"
                      class="search-icon"
                    ></button>
                  </div>                  
                </div>
              </div>
            </div>

            <div className="checkbox-wrap mb-3">
              <label
                class="form-check-label checkbox-label "
                for="IAmRepresenting1"
              >
                <input
                  checked={isAgentAsSeller}
                  className="form-check-input"
                  type="checkbox"
                  id="IAmRepresenting"
                  onChange={(e) => {
                    handleCheckBox(e);
                  }}
                />
                <span className="labelTxt">
                  {" "}
                  I’m representing myself as the buyer
                </span>
              </label>
            </div>
            {clientsList.length===0 && <div className="add-first-clientwrap">
              <p>Let’s add your first client!</p>
              <button className="btn btn-primary" onClick={handleShow}>Add New Client</button>
            </div>}

            <div className={clientsList.length==0 ? "title-selection-wrap d-none" : "title-selection-wrap "}>
              {clientsList && clientsList.length > 0 ? (
                clientsList.map((client) => {
                  return (
                    <a
                      href={void 0}
                      className={clientId===client?.value ? "createAd-content-wrap mb-3 d-flex align-items-center active" :"createAd-content-wrap mb-3 d-flex align-items-center"}
                      onClick={() =>
                        handleChangeClient(client?.label, client?.value)
                      }
                    >
                      <span
                        className={
                          "profileIcnDiv me-2 align-items-center " +
                          getInitials(
                            client?.FirstName + " " + client?.LastName
                          )?.backgroundColor
                        }
                      >
                        {
                          getInitials(
                            client?.FirstName + " " + client?.LastName
                          )?.initials
                        }
                      </span>
                      <span className="labelTxt"> {client?.label}</span>
                    </a>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav position-static">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-3"
                disabled={disableSave}
                onClick={() => {                    
                    if(completedPercentage>10){
                      setCompletedPercentage(completedPercentage)
                    }
                    else{
                      handleFormValues({ cmpldPerctg: 10 })
                      setCompletedPercentage(10)
                    }
                    setTimeout(() => {
                      setLocalStorage(
                        INVENTORY_KEY,
                        getLocalStorage(INVENTORY_KEY_TEMP)
                      );
                      if(!propertyType || !locationAddress){setUIKey("propertyType");}
                      else{setUIKey(null)}
                    }, 500);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
              <button type="button " className={clientsList.length==0 ? "btn btn-secondary w-100 d-none " : "btn btn-secondary w-100"}onClick={handleShow} >Add New Client</button>
            </div>
          </div>
        </div>

        <AddNewClient show={show} onHide={handleClose} clientDetails={null} isEdit={false}/>
      </div>
    </>
  );
};

export default SelectClient;
