import { useSelector } from "react-redux";
import ProfileImageCropper from "../../mobile/components/Onboarding/ProfileImageCropper";
import EditProfileModal from "../MemberWantAd/EditProfileModal"
import { useEffect, useState } from "react";

const ShowEditProfileModal = (props) => {    
    const {userDetails, selectedPlan} = props;
    const [image, setImage] = useState(null);
    const [showPofileImageUploadModal, setShowPofileImageUploadModal] = useState(false);
    const ShowPofileImageUploadModalClose = () => setShowPofileImageUploadModal(false);
    const ShowPofileImageUploadModalShow = () => setShowPofileImageUploadModal(true);

    const [reRenderEditProfileModel, setReRenderEditProfileModel] = useState(0);
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");

    const renderEditProfileModel = (uploadedImageUrl) => {
        setUploadedImageUrl(uploadedImageUrl)
        setReRenderEditProfileModel(reRenderEditProfileModel + 1)
    
      }
      

    return(
        <>
        <EditProfileModal
        userDetails={userDetails}
        show={props.editProfileModal}
        onHide={props.EditProfileModalClose}
        onClickChangePhoto={(image) => { ShowPofileImageUploadModalShow(); setImage(image) }}
        reRenderEditProfileModel={reRenderEditProfileModel}
        uploadedImageUrl={uploadedImageUrl}
        isFromUpgradeToAgent={true}
        selectedPlan={selectedPlan}
      />
      <ProfileImageCropper
        userId={userDetails?.UserId}
        image={image}
        show={showPofileImageUploadModal}
        onHide={() => { ShowPofileImageUploadModalClose(); }}
        updateImageAfterCrop={(imageUrl) => { renderEditProfileModel(imageUrl); ShowPofileImageUploadModalClose(); }}
      />
      </>
    )

}

export default ShowEditProfileModal;