import React, { useState, useEffect, useRef } from "react";
import { FormLabel } from "react-bootstrap";
import RangeSliderComponent from "../Common/RangeSlider";
import TextBox from "../Common/TextBox";
import "react-range-slider-input/dist/style.css";
import { replaceComma, checkIsNull } from "../../helpers/common";
import { BUY, BUY_STRENGTHEN, LONG_RENT, SHORT_RENT } from "../../constants/common";
import { thousandSeperatedValue } from "../../helpers/common";
import { removeNegativeInput } from "../../helpers/validations";
import { handleTextBoxChange } from "../../actions/common";
import { usePrevious } from "../../hooks/usePrevious";
import { budgetMaxError} from "../../constants/createWantAd";
import { getFinanceType } from "../../services/wantAdService";
import { handleRadioButonChange } from "../../actions/common";
import ConfirmApprovalModal from "../MemberWantAd/ConfirmApprovalModal";

const WantAdInvestorBudget = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    budgetMin,
    budgetMax,
    isEdit = false,
    financeType,
    isPreApprLoan,
    adType,
    budgetErrors,
    errorUpdatedAt
  } = props;
  const [budgetRange, setBudgetRange] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");

  const [budgetMinCursorPos, setBudgetMinCursorPos] = useState(0);
  const [budgetMaxCursorPos, setBudgetMaxCursorPos] = useState(0);
  const [financeList, setFinanceList] = useState([]);
  const [isConventionalMortage, setConventionalMortage] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const minBudgetRef = useRef();
  const maxBudgetRef = useRef();

  

  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;

  if (adType === BUY || adType === BUY_STRENGTHEN) {
    DEFAULT_MAX_BUDGET = 5000000;
    DEFAULT_STEP_VALUE = 25000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
    DEFAULT_STEP_VALUE = 100;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
    DEFAULT_STEP_VALUE = 50;
  }

  let label = isEdit ? "Budget" : "Price";
  if (adType == SHORT_RENT) {
    label = isEdit ? "Budget (per night)" : "Price (per night)";
  } else if (adType == LONG_RENT) {
    label = isEdit ? "Budget (per month)" : "Price (per month)";
  }

  useEffect(() => {
    setMaxError("");
    setMinError("")
    if (budgetErrors === budgetMaxError) {
      setMaxError("Max budget should greater than minimum budget.");      
    }     
    handleContinueButtonState();
  }, [budgetErrors, errorUpdatedAt]);

  let isUnCheckItem = false;
  useEffect(() => {
    if (financeType && (financeType === "2" || financeType == 2)) {
      if (
        isPreApprLoan === "Yes" ||
        (isPreApprLoan == true && isEdit == false)
      ) {
        handleContinueButtonState();
        // setShowConfirmPopup(true);
      } else if (!isPreApprLoan) {
        const isDisable = true;
        handleContinueButtonState(isDisable);
      }
      setConventionalMortage(true);
    } else if (financeType) {
      handleContinueButtonState();
    }
    getFinanceType()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const financeList = _.get(response, "result", null);
          setFinanceList(financeList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const closeConfirmPopup = () => {
    const isDisableButton = true;
    handleContinueButtonState(isDisableButton);
    setShowConfirmPopup(false);
    handleFormValues({ isPreApprLoan: "" });
  };

  const handleAdjustBudgetClick = () => {
    setShowConfirmPopup(false);
    setTimeout(()=>{
      maxBudgetRef.current.focus();
      maxBudgetRef.current.setSelectionRange(thousandSeperatedValue(budgetMax)?.length+1, thousandSeperatedValue(budgetMax)?.length+1);
    },300)    
  };

  const prevMinBdgt = usePrevious(budgetRange[0]);
  const prevMaxBdgt = usePrevious(budgetRange[1]);

  useEffect(() => {
    let DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET];
    if (budgetMin && !budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, DEFAULT_MAX_BUDGET];
    } else if (!budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, budgetMax];
    } else if (budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, budgetMax];
    }
    setBudgetRange(DEFAULT_BUDGET_RANGE);
    handleContinueButtonState();
  }, []);

  useEffect(() => {
    if (prevMinBdgt !== budgetRange[0]) {
      handleFormValues({ minBdgt: budgetRange[0] });
    }
  }, [budgetRange[0]]);

  useEffect(() => {
    const mxBdgt = budgetRange[1] === DEFAULT_MAX_BUDGET ? "" : budgetRange[1];
    if (prevMaxBdgt && prevMaxBdgt !== budgetRange[1]) {
      handleFormValues({ minBdgt: budgetMin, maxBdgt: mxBdgt });
    }
  }, [budgetRange[1]]);

  
  useEffect(() => {
    minBudgetRef.current.setSelectionRange(budgetMinCursorPos, budgetMinCursorPos);
  }, [budgetMinCursorPos]);

  useEffect(() => {
    maxBudgetRef.current.setSelectionRange(budgetMaxCursorPos, budgetMaxCursorPos);
  }, [budgetMaxCursorPos]);


  const handleBudgetMinChange = (e) => {
    const budgetMax = budgetRange && budgetRange[1];
    let value = removeNegativeInput(handleTextBoxChange(e));
    const budgetMin = budgetRange && budgetRange[0]

    if (value) {
      value = replaceComma(value);
    }
    if (!checkIsNull(value)) {
      if(!value){
        setBudgetRange([DEFAULT_MIN_BUDGET, budgetMax]);
      } else {
        handleFormValues({ minBdgt: value });
        setMinError("");
        setBudgetRange([value, budgetMax]);
        if( (thousandSeperatedValue(budgetMin)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMin)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMinCursorPos(e.target.selectionStart)
        }
      }
    } else {
      setBudgetRange([DEFAULT_MIN_BUDGET, budgetMax]);
      handleFormValues({ minBdgt: "" });
    }
  };

  const handleBudgetMaxChange = (e) => {
    const budgetMin = budgetRange && budgetRange[0];
    const budgetMax = budgetRange && budgetRange[1];
    let value = removeNegativeInput(handleTextBoxChange(e));
    if (value) {
      value = replaceComma(value);
    }
    if (!checkIsNull(value)) {
      if(!value){
        setBudgetRange([budgetMin, DEFAULT_MAX_BUDGET]);
      } else {
        handleFormValues({ maxBdgt: value });
        setMaxError("");
        setBudgetRange([budgetMin, value]);
        if( (thousandSeperatedValue(budgetMax)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMaxCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMax)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMaxCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMaxCursorPos(e.target.selectionStart)
        }
      }
    } else {
      setBudgetRange([budgetMin, DEFAULT_MAX_BUDGET]);
      handleFormValues({ maxBdgt: "" });
    }
  };

  const handleItemClick = (e, FinanceText) => {
    setConventionalMortage(false);
    const isDisable = true;
    const value = handleRadioButonChange(e);
    if (value === financeType) {
      handleFormValues({ pFinance: "", pFinanceText: "", isPreApprLoan: "" });
      setConventionalMortage(false);
      handleContinueButtonState(isDisable);
      isUnCheckItem = true;
    } else {
      if (value === "2") {
        setConventionalMortage(true);
        handleContinueButtonState(isDisable);
      } else {
        handleFormValues({ isPreApprLoan: "" });
        setConventionalMortage(false);
        handleContinueButtonState();
      }
      handleFormValues({ pFinance: value, pFinanceText: FinanceText });
    }
  };
  const handlePreApproveClick = (e) => {
    const selectedValue = handleRadioButonChange(e);
    if (selectedValue === "Yes") setShowConfirmPopup(true);
    handleFormValues({ isPreApprLoan: selectedValue });
    handleContinueButtonState();
  };

  const confirmPreApprovalStatus = () => {
    setShowConfirmPopup(false);
  };

  const loadFinanceTypes = () => {
    const financeLists =
      financeList &&
      financeList.map((tl) => {
        const FinanceTypeId = _.get(tl, "FinanceTypeId");
        const FinanceTypeText = _.get(tl, "FinanceTypeText", "");
        return (
          <label className="radio-wrap mb-3" key={FinanceTypeId}>
            <input
              type="radio"
              name="pf-radio"
              value={FinanceTypeId}
              onClick={(e) => handleItemClick(e, FinanceTypeText)}
              onChange={(e) => handleItemClick(e, FinanceTypeText)}
              checked={
                financeType && financeType == FinanceTypeId
              }
            />
            <span>
              <div className="timing-wrap-info">
                <h2>{FinanceTypeText}</h2>
              </div>
            </span>
          </label>
        );
      });

    return financeLists;
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-524" : ""}>
      <div className="square-footage-wrap">
        <FormLabel>
          {label}
          <span>*</span>
        </FormLabel>
        {!isEdit && (
          <div className="rs-pd">
            <RangeSliderComponent
              min={DEFAULT_MIN_BUDGET}
              max={DEFAULT_MAX_BUDGET}
              defaultValue={budgetRange}
              value={budgetRange}
              stepValue={DEFAULT_STEP_VALUE}
              onInput={setBudgetRange}
            />
          </div>
        )}
        <ul className="range-values-list rs-pb-37">
          <li className="position-relative">
            { thousandSeperatedValue(budgetMin) && <span className="dollar-sign-icon">$</span>}
            <TextBox
              type="text"
              class= {minError ? "pad-left-25 validation-txtfld ranges-txt-color" : "pad-left-25 ranges-txt-color"}
              errorMessage={minError}
              value={thousandSeperatedValue(budgetMin) || ""}
              placeholder="No min"
              tabIndex="1"
              onChange={(e) => {
                handleBudgetMinChange(e);
              }}
              txtRef={minBudgetRef}
            ></TextBox>
          </li>
          <li className="text-center">
            <span className="middle-separator">-</span>
          </li>
          <li className="position-relative">
            {thousandSeperatedValue(budgetMax) && <span className="dollar-sign-icon">$</span>}
            <TextBox
              type="text"
              class= {maxError ? "pad-left-25 validation-txtfld ranges-txt-color" : "pad-left-25 ranges-txt-color"}
              errorMessage={maxError}
              value={thousandSeperatedValue(budgetMax) || ""}
              placeholder="No max"
              tabIndex="2"
              onChange={(e) => {
                handleBudgetMaxChange(e);
              }}
              txtRef={maxBudgetRef}
            ></TextBox>
          </li>
        </ul>
      </div>
      <div className="wantad-timing-wrap">
      <FormLabel>
      How are you financing this purchase?
        </FormLabel>
        {loadFinanceTypes()}
        {isConventionalMortage && (
          <div className="row">
            <div>
              <h6 className="">Are you pre-approved for a loan?</h6>
            </div>
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio-preApprove"
                value="Yes"
                checked={
                  isPreApprLoan &&
                  (isPreApprLoan === "Yes" || isPreApprLoan == true)
                }
                onClick={(e) => handlePreApproveClick(e)}
                onChange={(e) => handlePreApproveClick(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>Yes</h2>
                </div>
              </span>
            </label>

            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio-preApprove"
                value="No"
                checked={
                  isPreApprLoan &&
                  (isPreApprLoan === "No" || isPreApprLoan == false)
                }
                onClick={(e) => handlePreApproveClick(e)}
                onChange={(e) => handlePreApproveClick(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>No</h2>
                </div>
              </span>
            </label>
          </div>
        )}
      </div>
      <ConfirmApprovalModal
        show={showConfirmPopup}
        onHide={closeConfirmPopup}
        confirmPreApprovalStatus={confirmPreApprovalStatus}
        handleAdjustBudgetClick={handleAdjustBudgetClick}
      />
    </div>
  );
};
export default WantAdInvestorBudget;
