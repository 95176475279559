import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import TextBox from "../../../components/Common/TextBox";
import FldCloseIcon from "../../images/round-close-icon.svg";
import PwdToggleOpen from "../../../images/password-toggle-icon.svg";
import PwdToggleClosed from "../../../images/password-toggle-icon-closed.svg";
import GoogleLogo from "../../images/google-logo.svg";
import FbLoginBannerDesktop from "../../../images/fb-login-banner-desktop.png";
import ValidationAlertIcon from "../../images/validation-alert-icon.svg";
import {
  convertToNumber,
  emailValidation,
  passwordValidation,
} from "../../../helpers/validations";
import {
  userSignUp,
  userLogin,
  verifyEmail,
} from "../../../services/signupService";
import { useNavigate } from "react-router-dom";
import { marketingSiteURL } from "../../../constants/endPoints";
import {
  HOME,
  LOGIN,
  POST_WANT_AD,
  SIGNUP,
  USER_TYPE,
  resetModalemailOnly,
} from "../../../constants/onBoarding";
import { RESET_PWD_EMAIL_VERIFICATION, USER_INFO_KEY, USER_TYPE_AGENT } from "../../../constants/common";
import _ from "lodash";
import {
  getLocalStorage,
  handleMobileUserLogin,
  setLocalStorage,
} from "../../../helpers/common";
import { pageURLs } from "../../../constants/pageURLs";
import { siteBaseURL } from "../../../constants/endPoints";
const CreateAccount = (props) => {
  const { handleBackButtonClick, handlePageToggle, activePage, googleLogin, stateValue } =
    props;
  const navigate = useNavigate();
  const isLogin = activePage === LOGIN;
  const pwdRef = useRef(null);
  const initialFormData = {
    email: "",
    pwd: "",
    confPwd: "",
  };
  const initialErrors = {
    email: "",
    pwd: "",
    confPwd: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [loginError, setLoginError] = useState(null);
  const [isShowPassWord, setPassWordShow] = useState(false);
  const [passwordType, setPassWordType] = useState("password");



  const onTextChange = (e) => {
    setLoginError(null);
    const textChangeField = e.target.name;
    setFormErrors((formErrors) => ({
      ...formErrors,
      [textChangeField]: "",
    }));
    const value = e.target.value;
    setFormData((formData) => ({ ...formData, [e.target.name]: value }));
  };

  useEffect(() => {
    const email = _.get(formData, "email", null);
    const pwd = _.get(formData, "pwd", null);
    const confPwd = _.get(formData, "confPwd", null);
    let disableButton;
    if (isLogin) {
      if (email && pwd) {
        disableButton = false;
      } else {
        disableButton = true;
      }
    } else {
      if (email && pwd && confPwd) {
        disableButton = false;
      } else {
        disableButton = true;
      }
    }

    setButtonDisable(disableButton);
  }, [formData]);

  useEffect(() => {
    if (isShowPassWord) {
      setPassWordType("text");
    } else {
      setPassWordType("password");
    }
  }, [isShowPassWord]);

  useEffect(() => {
    setPassWordShow(false);
    setFormErrors(initialErrors);
    setFormData(initialFormData);
    setLoginError(null);
  }, [activePage]);

  const checkEmailExist = async (email) => {
    const userEmail = {
      Email: email,
    };
    return await verifyEmail(userEmail).then((response) => {
      return response?.result;
    });
  };

  const validate = async () => {
    setFormErrors(initialErrors);
    let isValid = true;
    const email = _.get(formData, "email", null).trim();
    const pwd = _.get(formData, "pwd", null).trim();

    if (!emailValidation(email)) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        email: "InValid Email Address",
      }));
      isValid = false;
    } else {
      if (!isLogin) {
        const passWordError = passwordValidation(pwd);
        const confPwd = _.get(formData, "confPwd", null).trim();
        if (passWordError) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            pwd: passWordError,
          }));
          isValid = false;
        } else if (pwd !== confPwd) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            confPwd: "Password not matching",
          }));
          isValid = false;
        } else {
          const res = await checkEmailExist(email);
          const userExist = _.get(res, "UserExists", false);
          if (userExist) {
            setFormErrors((formErrors) => ({
              ...formErrors,
              email: "Email already exists",
            }));
            isValid = false;
          }
        }
      }
    }

    return isValid;
  };
  const handleSubmit = async () => {
    const isValid = await validate();
    let reqPayload;
    if (isValid) {
      if (!isLogin) {
        let utmDtata = getLocalStorage("utmData");
        reqPayload = {
          email: _.get(formData, "email", ""),
          password: _.get(formData, "pwd", ""),
          confirmPassword: _.get(formData, "confPwd", ""),
          //userTypeId:USER_TYPE_AGENT

          utmSource:  utmDtata?.utm_source,
          utcMedium: utmDtata?.utm_medium,
          utcCampaign: utmDtata?.utm_campaign
        };
        await userSignUp(reqPayload).then((response) => {
          if (_.get(response, "errorCode", "") === 0) {
            updateUserDetailsToStorage(response);
            handlePageToggle({ activePage: USER_TYPE });
          } else {
            setFormErrors((formErrors) => ({
              ...formErrors,
              email: _.get(response, "message", ""),
            }));
          }
        });
      } else {
        reqPayload = {
          email: _.get(formData, "email", ""),
          password: _.get(formData, "pwd", ""),
        };
        await userLogin(reqPayload).then((response) => {
          if (_.get(response, "errorCode", "") === 0) {
            updateUserDetailsToStorage(response);
            // console.log(`####res`, response)
            // const userLoginStatus = handleMobileUserLogin()
              if(stateValue?.IsFromMarketing){
                const userActionCreateWantAd = "POSTWANTAD";
                if (stateValue?.action === userActionCreateWantAd) {
                  return navigate(pageURLs.createWantAdResponsive);
                } else{
                  navigate(0);
                }
              } else{
                navigate(0);
              }
          
          } else {
            // const resResult = _.get(response, "result", null);
            // const userResult = _.get(resResult, "user", null);
            // const ErrorStatus = _.get(userResult, "Status", "");
            // const passWordErrorStatus = 6;
            // if (ErrorStatus === passWordErrorStatus) {
            //   setFormErrors((formErrors) => ({
            //     ...formErrors,
            //     pwd: _.get(response, "message", ""),
            //   }));
            // }
            setLoginError(_.get(response, "message", ""));
          }
        });
      }
    }
  };

  const updateUserDetailsToStorage = (userResponse) => {
    const resResult = _.get(userResponse, "result", null);
    const userResult = _.get(resResult, "user", null);
    const userSession = _.get(resResult, "userSession", "");
    const refreshToken = _.get(resResult, "refreshToken", "");
    const firstName = _.get(userResult, "FirstName", "") || "";
    const lastName = _.get(userResult, "LastName", "") || "";
    const name = firstName + " " + lastName;
    const tutorialStatus = _.get(resResult, "tutorialStatus", "");
    const userTypeId = _.get(userResult, "UserTypeId", "");

    if (userSession) {
      const userDetails = {
        token: {
          accessToken: userSession,
          refreshToken: refreshToken,
          googleLogin: false,
        },
        userInfo: {
          userId: _.get(userResult, "UserId", ""),
          email: _.get(userResult, "Email", ""),
          name: name,
          firstName: firstName,
          lastName: lastName,
          userIsVerified: _.get(userResult, "IsVerified", ""),
          userEmailVerified: _.get(userResult, "IsEmailVerified", ""),
          planStatus: _.get(userResult, "PlanStatus", ""),
          userStatus: _.get(userResult, "UserStatus", ""),
          userType: userTypeId,
          //userPreference: isPostWantAd ? POST_WANT_AD : null,
          image: _.get(userResult, "Image", ""),

        },
      };
      if (convertToNumber(userTypeId === USER_TYPE_AGENT)) {
        const agentTitle = _.get(userResult, "Agent_Title", "");
        userDetails.userInfo.agentTitle = agentTitle;
      }

      setLocalStorage(USER_INFO_KEY, userDetails);
      //Saving tutorial displayed status page
      if (tutorialStatus && tutorialStatus.length) {
        let tutorialDisplayedPages = [];
        for (let i = 0; i < tutorialStatus.length; i++) {
          if (
            tutorialStatus[i].PageNo &&
            tutorialStatus[i].Status == 1 &&
            !tutorialDisplayedPages.includes(tutorialStatus[i].PageNo)
          ) {
            tutorialDisplayedPages.push(tutorialStatus[i].PageNo);
          }
        }
        setLocalStorage("tutorialDisplayedPages", tutorialDisplayedPages);
      }
    }
  };

  const handlePassWordShow = () => {
    if (pwdRef.current) {
      pwdRef.current.focus();
    }
    
    setPassWordShow(!isShowPassWord);
  };

  const clearEmail = () => {
    setFormData((formData) => ({ ...formData, email: "" }));
  };
  return (
    <div className="gitcha-main-wrapper pb-0">
      <div className="content-wrapper vh-100">
        <div className="inner-layout-header-new bg-grey-variant">
          <div className="review-plan-header w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              <a
                className="back-link"
                role="button"
                href={void 0}
                onClick={() =>{!isLogin? window.location.href = marketingSiteURL: handleBackButtonClick({ prevPage: SIGNUP })} }
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new h-100">
          <div className="get-agent-wrap clearfix">
          {
!isLogin ?
  <>
          <h2 class="mob-style-heading text-center mb-0">Welcome to Gitcha!</h2>
          <div className="fb-login-banner-desktop">
        <img src={FbLoginBannerDesktop} alt="Login Banner" />
        
        </div>
        <div className="fb-login-tagline-mobile mb-2">
        Create your account to get started, it’s free!
        </div>
        </>
        :
        <>
         <h2>
          {/* Sign {isLogin ? 'in' : 'up'}  */}
         Sign in</h2>
         {_.get(formErrors, "email", null) ?
          <p className="mb-3">Confirm your email to continue.</p>
         :

            <p className="mb-3">Enter your email and password to continue.</p>
          }
        </>
          }
           

            <div className="mob-fld-wrap position-relative mb-3">
              <label className="form-label">Email</label>

              <TextBox
                name="email"
                type="email"
                tabIndex="1"
                autoFocus={true}
                notShowError = {true}
                value={_.get(formData, "email", "")}
                placeholder="Email"
                class={
                  _.get(formErrors, "email", null)
                    ? "form-control mob-pr-44 validation-txtfld"
                    : "form-control mob-pr-44"
                }
                onChange={(e) => {
                  onTextChange(e);
                }}
              />
              {_.get(formData, "email", "") &&
                !_.get(formErrors, "email", null) && (
                  <Button variant="" className="fld-close-btn">
                    <img src={FldCloseIcon} onClick={() => clearEmail()} />
                  </Button>
                )}
              {_.get(formErrors, "email", null) && (
                <>
                  <div className="validation-alert">
                    <img src={ValidationAlertIcon} />
                  </div>

                  <div className="mob-error-msg">{_.get(formErrors, "email", null)}</div>
                </>
              )}
            </div>

            <div className="mob-fld-wrap position-relative mb-3">
              <label className="form-label">Password</label>

              <TextBox
                name="pwd"
                type={passwordType}
                tabIndex="2"
                txtRef={pwdRef}
                //errorMessage={_.get(formErrors, "pwd", "")}
                value={_.get(formData, "pwd", "")}
                placeholder={isLogin ? "Password" : "Create a Password"}
                notShowError = {true}
                class={
                  _.get(formErrors, "pwd", null)
                    ? "form-control mob-pr-44 validation-txtfld"
                    : "form-control mob-pr-44"
                }
                onChange={(e) => {
                  onTextChange(e);
                }}
              />
              {/* <Button variant="" className="fld-close-btn">
              <img src={FldCloseIcon} />
            </Button> */}
              {!_.get(formErrors, "pwd", null) && (
                <Button
                  variant=""
                  className="btn-pwd-mob-toggle"
                  onClick={() => handlePassWordShow()}
                >
                  <img
                    src={isShowPassWord ? PwdToggleOpen : PwdToggleClosed}
                    alt="pwdicon"
                  />
                </Button>
              )}

              {_.get(formErrors, "pwd", null) && (
                <>
                  <div className="validation-alert">
                    <img src={ValidationAlertIcon} />
                  </div>
                  <div className="mob-error-msg">
                    {_.get(formErrors, "pwd", null)}
                  </div>
                </>
              )}
              {loginError && (
                <>
                  <div className="mob-error-msg">
                    You have entered a wrong email or password
                  </div>
                </>
              )}
            </div>

            {!isLogin && (
              <div className="mob-fld-wrap position-relative mb-4">
                <label className="form-label">Confirm Password</label>

                <TextBox
                  name="confPwd"
                  type="password"
                  tabIndex="3"
                  notShowError = {true}
                  //errorMessage={_.get(formErrors, "confPwd", "")}
                  value={_.get(formData, "confPwd", "")}
                  placeholder="Confirm Password"
                  class={
                    _.get(formErrors, "confPwd", null)
                      ? "form-control mob-pr-44 validation-txtfld"
                      : "form-control mob-pr-44"
                  }
                  onChange={(e) => {
                    onTextChange(e);
                  }}
                />
                {/* <Button variant="" className="fld-close-btn">
              <img src={FldCloseIcon} />
            </Button> */}
                {/* <Button variant="" className="btn-pwd-mob-toggle">
                <img src={PwdToggleClosed} />
              </Button> */}
                {_.get(formErrors, "confPwd", null) && (
                  <>
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                    <div className="mob-error-msg">
                      {_.get(formErrors, "confPwd", null)}
                    </div>
                  </>
                )}
              </div>
            )}
            {isLogin && (
              <a href= {void 0} role="button" onClick={()=> navigate(pageURLs.changePassword, 
                { state: { activePage: RESET_PWD_EMAIL_VERIFICATION }})}
              className="fgt-psd-link d-inline-block mb-4">
                Forgot your password?
              </a>
            )}

            <div className="btn-wrap pb-0">
              <Button
                variant="primary"
                tabIndex="4"
                className="btn btn-primary w-100 mb-4"
                disabled={buttonDisable}
                onClick={() => handleSubmit()}
              >
                Continue
              </Button>
            
                <button
                  type="button"
                  class="btn btn-outline-primary w-100 mb-4"
                  onClick={() => googleLogin()}
                >
                  <img src={GoogleLogo} alt={GoogleLogo} className="me-2" />{" "}
                  <span className="text-placement">Continue with Google</span>
                </button>
              

              {isLogin && (
                <button
                  type="button"
                  class="btn btn-secondary-blue w-100 mb-4"
                  onClick={() => handlePageToggle({ activePage: SIGNUP })}
                >
                  Sign up
                </button>
              )}
            </div>
       
              <div className="signup-btm-wrap pb-28">
                <p className="mb-0">
                  By signing {isLogin ? "in" : "up"}, you agree to <a  role="button" 
                 href="/terms-of-service"  target="_blank">Terms</a> and{" "}
                  <a  role="button" target="_blank" href="/privacy-policy">Privacy Policy.</a>
                </p>
              </div>
            

            {!isLogin ?
            <>
              <p className="text-center pb-4 f-size-14">
              Already have an account?{" "}
              <a href={void 0} className="blue-mob-link" role="button" onClick={() => handleBackButtonClick({ prevPage: LOGIN })}>
                Login here
              </a>
            </p>
            </>
            :
            <>
            <p className="text-center pb-4 f-size-14">
            Don’t have an account yet?{" "}
            <a href={void 0} className="blue-mob-link" role="button" onClick={() => handleBackButtonClick({ prevPage: SIGNUP })}>
            Sign up here
            </a>
          </p>
          </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
