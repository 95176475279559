import { convertToNumber } from "../../../helpers/validations";
import AddLocation from "./AddLocation";
import AddLocationLotLand from "./AddLocationLotLand";
const AddLocationAndDetails = (props) => {
  const {
    formValues,
    handleFormValues,
    setUIKey,
    completedPercentage,
    setCompletedPercentage,
    isAgent,
    uiKey,
    isEdit,
  } = props;
  const propertyType = _.get(formValues, "prprtyType", null);
  const renderUiBasedOnPropertyType = () => {
    if (convertToNumber(propertyType != 6)) {
      return (
        <AddLocation
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          completedPercentage={completedPercentage}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          uiKey={uiKey}
          isEdit={isEdit}
        />
      );
    }else{
       return <AddLocationLotLand
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          completedPercentage={completedPercentage}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          uiKey={uiKey}
          isEdit={isEdit}
        />
    }
  };

  return <>{renderUiBasedOnPropertyType()}</>;
};

export default AddLocationAndDetails;
