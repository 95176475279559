import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import BottomStepperMob from "../BottomStepperMob";
import RangeSliderMobile from "../RangeSliderMobile";
import { checkInventoryType } from "../../../helpers/inventory";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { INVENTORY_ALL_TYPE, INVENTORY_KEY, INVENTORY_KEY_TEMP, INVENTORY_LTR, INVENTORY_LTR_STR, INVENTORY_SELL, INVENTORY_SELL_LTR, INVENTORY_SELL_STR, INVENTORY_STR , YES_NO_OPTIONS} from "../../../constants/common";
import { convertToNumber, removeNegativeInput } from "../../../helpers/validations";
import { getLocalStorage, replaceComma, setBodyBackgroundWhite, setLocalStorage, thousandSeperatedValue } from "../../../helpers/common";
import ProgressBarInventory from "./ProgressBarInventory";
const AddAskingPriceAgent = (props) => {
  const { completedPercentage, handleFormValues, formValues, setCompletedPercentage, setUIKey, isEdit } = props;
        
  const sellAskngPrice= _.get(formValues, "sellAskngPrice", "");
  const LTRAskngPrce=_.get(formValues, "LTRAskngPrce", "");
  const STRAskngPrce=_.get(formValues, "STRAskngPrce", "");
  const isCompnsteAgent=_.get(formValues, "isCompnsteAgent", "");
  const  isCompnsteAgentLTR=_.get(formValues, "isCompnsteAgent_LTR", "");
  const isCompnsteAgentSTR=_.get(formValues, "isCompnsteAgent_STR", "");
  const propertyPurpose=_.get(formValues, "proprtyPurpose", "");
  const salesPercnt=_.get(formValues, "salesPercnt", null);
  const salesPercntLTR=_.get(formValues, "salesPercnt_LTR", null);
  const salesPercntSTR=_.get(formValues, "salesPercnt_STR", null);

  const sellAskngPriceInSession = _.get(getLocalStorage(INVENTORY_KEY), "sellAskngPrice", "");
  const LTRAskngPrceInSession =_.get(getLocalStorage(INVENTORY_KEY), "LTRAskngPrce", "");
  const STRAskngPrceInSession =_.get(getLocalStorage(INVENTORY_KEY), "STRAskngPrce", "");
  const isCompnsteAgentInSession =_.get(getLocalStorage(INVENTORY_KEY), "isCompnsteAgent", "");
  const  isCompnsteAgentLTRInSession =_.get(getLocalStorage(INVENTORY_KEY), "isCompnsteAgent_LTR", "");
  const isCompnsteAgentSTRInSession =_.get(getLocalStorage(INVENTORY_KEY), "isCompnsteAgent_STR", "");
  const propertyPurposeInSession =_.get(getLocalStorage(INVENTORY_KEY), "proprtyPurpose", "");
  const salesPercntInSession =_.get(getLocalStorage(INVENTORY_KEY), "salesPercnt", null);
  const salesPercntLTRInSession =_.get(getLocalStorage(INVENTORY_KEY), "salesPercnt_LTR", null);
  const salesPercntSTRInSession =_.get(getLocalStorage(INVENTORY_KEY), "salesPercnt_STR", null);

  const [disableSave, setDisableSave] = useState(true);

  const invType = checkInventoryType(propertyPurpose);
  const isSellOnly = invType === INVENTORY_SELL;
  const isLTROnly = invType === INVENTORY_LTR;
  const isSTROnly = invType === INVENTORY_STR;
  const isSell_And_LTR = invType === INVENTORY_SELL_LTR;
  const isSell_And_STR = invType === INVENTORY_SELL_STR;
  const isSTR_And_LTR = invType === INVENTORY_LTR_STR;
  const isAllType = invType === INVENTORY_ALL_TYPE;
  let askingPrice = sellAskngPrice;
  if (isSTROnly) {
    askingPrice = STRAskngPrce;
  } else if (isLTROnly) {
    askingPrice = LTRAskngPrce;
  }
  
  const SLIDER_MIN_VALUE = 1;
  const SLIDER_MAX_VALUE = 10;
  const SLIDER_DEFAULT_VALUE = 10;
  const SLIDER_STEP_VALUE = 0.25;
  const SELL = "SELL";
  const LTR = "LTR";
  const STR = "STR";

  const [agentPercentage, setAgentPercentage] = useState("");
  const [agentPercentageLTR, setAgentPercentageLTR] = useState("");
  const [agentPercentageSTR, setAgentPercentageSTR] = useState("");

  const SELL_ASKING_PRICE = "sellAskngPrice";
  const LTR_ASKING_PRICE = "LTRAskngPrce";
  const STR_ASKING_PRICE = "STRAskngPrce";

  const [sellCursorPos, setSellCursorPos] = useState(0);
  const [lTRCursorPos, setLTRCursorPos] = useState(0);
  const [sTRCursorPos, setSTRCursorPos] = useState(0);

  const sellRef = useRef();
  const ltrRef = useRef();
  const strRef = useRef();

  const renderTooltip = (params) => (
    <Tooltip id="button-tooltip" {...params}>
      {params}
    </Tooltip>
  );
  useEffect(() => {
    console.log("sellRef.current", sellRef.current)
    if(sellRef.current){
      sellRef.current.setSelectionRange(sellCursorPos, sellCursorPos);
    }
    
  }, [sellCursorPos]);

  useEffect(() => {
    if(ltrRef.current){
    ltrRef.current.setSelectionRange(lTRCursorPos, lTRCursorPos);
    }
  }, [lTRCursorPos]);

  useEffect(() => {
    if(strRef.current){
    strRef.current.setSelectionRange(sTRCursorPos, sTRCursorPos);
    }
  }, [sTRCursorPos]);

  const handleAskngPriceChange = (e, section) => {
    let value = removeNegativeInput(e.target.value);
    console.log("value", e.target.value)
    if (value === "0") {
      value = 1;
    }
    switch (section) {
      case SELL_ASKING_PRICE:
        if (isLTROnly) {
          handleFormValues({ [LTR_ASKING_PRICE]: replaceComma(value) });
          if( (thousandSeperatedValue(LTRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos(e.target.selectionStart)
           } else if((thousandSeperatedValue(LTRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos((e.target.selectionStart + 1) )
          } else{
            setSellCursorPos(e.target.selectionStart)
          }
        } else if (isSTROnly) {
          handleFormValues({ [STR_ASKING_PRICE]: replaceComma(value) });
          if( (thousandSeperatedValue(STRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos(e.target.selectionStart)
           } else if((thousandSeperatedValue(STRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos((e.target.selectionStart + 1) )
          } else{
            setSellCursorPos(e.target.selectionStart)
          }
        } else {
          handleFormValues({ [SELL_ASKING_PRICE]: replaceComma(value) });
          if( (thousandSeperatedValue(sellAskngPrice)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos(e.target.selectionStart)
           } else if((thousandSeperatedValue(sellAskngPrice)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos((e.target.selectionStart + 1) )
          } else{
            setSellCursorPos(e.target.selectionStart)
          }
        }
        break;
      case LTR_ASKING_PRICE:
        handleFormValues({ [LTR_ASKING_PRICE]: replaceComma(value) });
        if( (thousandSeperatedValue(LTRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setLTRCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(LTRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setLTRCursorPos((e.target.selectionStart + 1) )
        } else{
          setLTRCursorPos(e.target.selectionStart)
        }
        break;
      case STR_ASKING_PRICE:
        handleFormValues({ [STR_ASKING_PRICE]: replaceComma(value) });
        if( (thousandSeperatedValue(STRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setSTRCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(STRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setSTRCursorPos((e.target.selectionStart + 1) )
        } else{
          setSTRCursorPos(e.target.selectionStart)
        }
        break;
    }
  };

  useEffect(() => {
    setBodyBackgroundWhite()
    if(isSellOnly || isSell_And_LTR ||isSell_And_STR || isAllType){
      setAgentPercentage([0,salesPercnt ? salesPercnt : SLIDER_DEFAULT_VALUE])
    } 
    if(isLTROnly || isSell_And_LTR || isSTR_And_LTR || isAllType){
      setAgentPercentageLTR([0,salesPercntLTR ? salesPercntLTR : SLIDER_DEFAULT_VALUE])
    }   
  }, []);

  useEffect(() => {
    agentPercentage[1] && handleFormValues({ salesPercnt: agentPercentage[1], isCompnsteAgent: true });
  }, [agentPercentage]);

  useEffect(() => {
    agentPercentageLTR[1] &&
      handleFormValues({ salesPercnt_LTR: agentPercentageLTR[1], isCompnsteAgent_LTR: true });
  }, [agentPercentageLTR]);

  useEffect(() => {
    agentPercentageSTR &&
      handleFormValues({ salesPercnt_STR: agentPercentageSTR[1], isCompnsteAgent_STR: true });
  }, [agentPercentageSTR]);

  const handleContinueButton = () =>{
    let isDisable = true;
    if (isSellOnly) {
      if (sellAskngPrice) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }

    if (isLTROnly) {
      if (LTRAskngPrce) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }

    if (isSTROnly) {
      if (STRAskngPrce) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }

    if (isSell_And_LTR) {
      if (sellAskngPrice && LTRAskngPrce) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }

    if (isSell_And_STR) {
      if (sellAskngPrice && STRAskngPrce) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }

    if (isSTR_And_LTR) {
      if (LTRAskngPrce && STRAskngPrce) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }

    if (isAllType) {
      if (LTRAskngPrce && STRAskngPrce && sellAskngPrice) {
        isDisable = false;
        setDisableSave(isDisable);
      } else {
        isDisable = true;
        setDisableSave(isDisable);
      }
    }
  }

  useEffect(() => {
   if(isEdit && 
      convertToNumber(sellAskngPrice) === convertToNumber(sellAskngPriceInSession) &&
      convertToNumber(STRAskngPrce) === convertToNumber(STRAskngPrceInSession) &&
      convertToNumber(LTRAskngPrce) === convertToNumber(LTRAskngPrceInSession) &&
      convertToNumber(salesPercnt) === convertToNumber(salesPercntInSession) &&
      convertToNumber(salesPercntLTR) === convertToNumber(salesPercntLTRInSession) &&
      convertToNumber(salesPercntSTR) === convertToNumber(salesPercntSTRInSession)
      ){
        setDisableSave(true)
      }
    else{
      handleContinueButton();
    }
  }, [
    isCompnsteAgent,
    sellAskngPrice,
    STRAskngPrce,
    LTRAskngPrce,
    salesPercnt,
    salesPercntLTR,
    salesPercntSTR,
  ]);


  const handleAgentPercentage = (e, section) => {
    let value = removeNegativeInput(e.target.value);
    if (value && /^\d*\.?\d*$/.test(value)) { // pattern to prevent multiple one dot enter
      if (value === '.' || (value.length === 1 && value[0] === '.')) {
        // If the first character is a dot, set the value to min value
        value = SLIDER_MIN_VALUE;
      } else if(value === '10.' || (value.length === 3 && value[3] === '.')){
        // If the the enterd value > 10 eg: 10.1, set the value to max value
        value = SLIDER_MAX_VALUE;
      } else {
          if (value > SLIDER_MAX_VALUE) {
            value = SLIDER_MAX_VALUE;
          } else if (value < SLIDER_MIN_VALUE) {
            value = SLIDER_MIN_VALUE;
          }
      }
    }


      switch (section) {
        case SELL:
          setAgentPercentage([0, value]);
          handleFormValues({ salesPercnt: value });
          break;
        case LTR:
          setAgentPercentageLTR([0, value]);
          handleFormValues({ salesPercnt_LTR: value });
          break;
        case STR:
          setAgentPercentageSTR([0, value]);
          handleFormValues({ salesPercnt_STR: value });
          break;
      }
    
  };

  const handleFoucsOutAgentPercentage = (section)=>{
    switch (section) {
      case SELL:
        setAgentPercentage([0, SLIDER_MIN_VALUE]);
        handleFormValues({ salesPercnt: SLIDER_MIN_VALUE });
        break;
      case LTR:
        setAgentPercentageLTR([0, SLIDER_MIN_VALUE]);
        handleFormValues({ salesPercnt_LTR: SLIDER_MIN_VALUE });
        break;
      case STR:
        setAgentPercentageSTR([0, SLIDER_MIN_VALUE]);
        handleFormValues({ salesPercnt_STR: SLIDER_MIN_VALUE });
        break;
    }

  }

  function getTitleText() {
    let title;
    if(isSellOnly){
      title = "How much would your client sell their property for?"
    }
    else if(isLTROnly){
      title = "How much would your client rent their property for each month?"
    }
    else if(isSTROnly){
      title = "How much would your client rent their property per night on average?"
    }
    else{
      title="How much is your client willing to price their property at?"
    }
    return title
  } 

  function getDescription() {
    let title;
    if(isSellOnly){
      title = "Set an asking price for your property so we can match you to want-ads. This can be adjusted when you send an introduction."
    }
    else if(isLTROnly){
      title = "You can adjust your asking price when you send each introduction. Our goal is to match your property to want-ads."
    }
    else if(isSTROnly){
      title = "You can consider requested dates and adjust the price accordingly before introducing this property to potential renters. "
    }
    else{
      title="You can adjust your asking price when you send each introduction. Our goal is to match your property to want-ads. "
    }
    return title
  } 

  


  const agentCompensationSlider = (params) => {
    const { salesPercent, type} = params;
    let title;
    let toolTitle;
    switch (type) {
      case SELL:
        toolTitle =
          "This is the percentage of the contract price that the seller is offering to pay a professional representing a buyer.";
        title = "Offering buyer’s agent compensation of: ";
        break;
      case LTR:
        toolTitle =
          "Percentage of total contract price offered to renter's agent.";
        title = "Offering renter’s agent compensation of: ";
        break;
      case STR:
        toolTitle =
          "Percentage of total cost of stay  offered to renter's agent.";
        title = "Percentage of total cost of stay  offered to renter's agent";
        break;
    }
    return (
      <>
        <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">

          <h4 className="d-inline-block mb-2 me-4 align-top">
            {title}
            <span>*</span>
          </h4>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(toolTitle)}
          >
            <span className="price-info-icon top-pos-2">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99967 1.8335C4.32634 1.8335 1.33301 4.82683 1.33301 8.50016C1.33301 12.1735 4.32634 15.1668 7.99967 15.1668C11.673 15.1668 14.6663 12.1735 14.6663 8.50016C14.6663 4.82683 11.673 1.8335 7.99967 1.8335ZM7.49967 5.8335C7.49967 5.56016 7.72634 5.3335 7.99967 5.3335C8.27301 5.3335 8.49967 5.56016 8.49967 5.8335V9.16683C8.49967 9.44016 8.27301 9.66683 7.99967 9.66683C7.72634 9.66683 7.49967 9.44016 7.49967 9.16683V5.8335ZM8.61301 11.4202C8.57967 11.5068 8.53301 11.5735 8.47301 11.6402C8.40634 11.7002 8.33301 11.7468 8.25301 11.7802C8.17301 11.8135 8.08634 11.8335 7.99967 11.8335C7.91301 11.8335 7.82634 11.8135 7.74634 11.7802C7.66634 11.7468 7.59301 11.7002 7.52634 11.6402C7.46634 11.5735 7.41967 11.5068 7.38634 11.4202C7.3521 11.34 7.33397 11.254 7.33301 11.1668C7.33301 11.0802 7.35301 10.9935 7.38634 10.9135C7.41967 10.8335 7.46634 10.7602 7.52634 10.6935C7.59301 10.6335 7.66634 10.5868 7.74634 10.5535C7.90865 10.4868 8.0907 10.4868 8.25301 10.5535C8.33301 10.5868 8.40634 10.6335 8.47301 10.6935C8.53301 10.7602 8.57967 10.8335 8.61301 10.9135C8.64634 10.9935 8.66634 11.0802 8.66634 11.1668C8.66634 11.2535 8.64634 11.3402 8.61301 11.4202Z"
                  fill="#4A4C4F"
                />
              </svg>
            </span>
          </OverlayTrigger>
          <div className="row">
            <div className="col-8 mt-3">
              {/* <div className="rs-pd"> */}
                <RangeSliderMobile
                  sliderClass="single-thumb"
                  min={SLIDER_MIN_VALUE}
                  max={SLIDER_MAX_VALUE}
                  defaultValue={[0, salesPercent]}
                  value={[0, salesPercent || 0]}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                  stepValue={SLIDER_STEP_VALUE}
                  onInput={
                    type === SELL
                      ? setAgentPercentage
                      : type === LTR
                      ? setAgentPercentageLTR
                      : setAgentPercentageSTR
                  }
                />
              {/* </div> */}
            </div>

            <div className="col-4">
              <div className="common-input-wrap position-relative mb-0">
                <div className="percent-icon-wrap txt-input-color">%</div>
                <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                <Form.Control
                  class="form-control text-center pad-right-25"
                  // type="text"
                  pattern="[0-9.]*" 
                  inputmode="decimal"
                  placeholder=""
                  value={
                    thousandSeperatedValue(salesPercent) || ""
                  }
                  onChange={(e) => {
                    handleAgentPercentage(e, type);
                  }}
                  onBlur={(e) => {
                    salesPercent == "" &&
                    handleFoucsOutAgentPercentage(type);
                  }}
                />
                </Form.Group>
                <div className="error-msg"></div>
              </div>
            </div>
          </div>{" "}
          </div>
        </div>
        {/* {showBottomLineBreak && <hr className="sec-brdr"></hr>} */}
      </>
    );
  };

  

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory
          setUIKey={setUIKey}
          completedPercentage={completedPercentage}
          isEdit={isEdit}
          />

          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 ">
            <div className="layout-content-btm-sec position-static pe-0 ps-0">
              <h6 className="">Asking price</h6>
              <h2 className="">
                {getTitleText()}
              </h2>
              <p className="titleTextpara mt-2">
              {getDescription()}
              </p>
            </div>

            {(isSellOnly ||
            isAllType ||
            isSell_And_LTR ||
            isSell_And_STR ||
            isLTROnly ||
            isSTROnly
            ) && (
            <>
            <div className="stay-timing-wrap mb-4">
              <div className="lp-btn-group-wrap">
                <h4> 
                {isAllType || isSell_And_LTR || isSell_And_STR
                          ? "Selling Asking Price"
                          : "Asking Price"}*
                </h4>
                <div className="row">
                  <div className="col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        name="askingPrice"
                        // type="text"
                        pattern="[0-9]*" 
                        inputmode="numeric"
                        value={
                          askingPrice ? thousandSeperatedValue(askingPrice) : ""
                        }
                        placeholder="Asking Price"
                        onChange={(e) => {
                          handleAskngPriceChange(e, SELL_ASKING_PRICE);
                        }}
                        ref={sellRef}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="stay-timing-wrap mb-4">            
              {!isSTROnly &&
                agentCompensationSlider({
                  salesPercent: isLTROnly
                    ? salesPercntLTR
                    : isSTROnly
                    ? salesPercntSTR
                    : salesPercnt,
                  type: isLTROnly ? LTR : isSTROnly ? STR : SELL,
                  showBottomLineBreak: true,
                })}                
                </div>
            
              </>
            )}

            {(isAllType || isSell_And_LTR || isSTR_And_LTR) && (
            <>
            <div className="stay-timing-wrap mb-4">
              <div className="lp-btn-group-wrap">
                <div className="row">
                  <div className="col-12">
                    {/* <div className="common-input-wrap mb-2 position-relative"> */}
                      {/* <div className="innerdollar-sign-icon-second">$</div> */}
                      <h4>
                        Long-term Renting Asking Price (Monthly)&nbsp;*
                      </h4>
                      <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        class="form-control pad-left-25"
                        name="LTRaskingPrice"
                        // type="text"
                        pattern="[0-9]*" 
                        inputmode="numeric"
                        value={
                          LTRAskngPrce
                            ? thousandSeperatedValue(LTRAskngPrce)
                            : ""
                        }
                        placeholder="Long-term Renting Asking Price"
                        onChange={(e) => {
                          handleAskngPriceChange(e, LTR_ASKING_PRICE);
                        }}
                        ref={ltrRef}
                      />
                      </Form.Group>
                      <div className="error-msg"></div>
                    </div>
                  </div>
                </div>
                </div>

              
                {agentCompensationSlider({
                  salesPercent: salesPercntLTR,
                  type: LTR,
                  showBottomLineBreak: true,
                })}
            </>
          )}
          {(isAllType || isSell_And_STR || isSTR_And_LTR) && (
            <>
              <div className="stay-timing-wrap mb-4">
              <div className="lp-btn-group-wrap">
                <div className="row">
                  <div className="col-12">
                    {/* <div className="common-input-wrap mb-4 position-relative">
                      <div className="innerdollar-sign-icon-second">$</div> */}
                      <h4>
                        Short-term Renting Asking Price (Nightly) <span>*</span>
                      </h4>
                      <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        class="form-control pad-left-25"
                        name="LTRaskingPrice"
                        // type="text"
                        pattern="[0-9]*" 
                        inputmode="numeric"
                        value={
                          STRAskngPrce
                            ? thousandSeperatedValue(STRAskngPrce)
                            : ""
                        }
                        placeholder="Short-term Renting Asking Price"
                        onChange={(e) => {
                          handleAskngPriceChange(e, STR_ASKING_PRICE);
                        }}
                        ref={strRef}
                      />
                      </Form.Group>
                      <div className="error-msg"></div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>              
            </>
          )}
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={()=>{
                  if(completedPercentage>=70){
                      setCompletedPercentage(completedPercentage)
                  }
                  else{
                    setCompletedPercentage(70)
                  }
                    setTimeout(()=>{         
                        setLocalStorage(
                          INVENTORY_KEY,
                          getLocalStorage(INVENTORY_KEY_TEMP)
                        );
                        setUIKey(null)
                      },500)
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAskingPriceAgent;
