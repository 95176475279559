/**
 * @file   src\components\AgentWantAd\WantAdDescription.js
 * @brief  This file is responsible for handling wantad description in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from "react";
import { FormLabel } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import TextArea from "../Common/TextArea";
import { handleTextBoxChange } from "../../actions/common";
import { ERROR_TEXT } from "../../constants/common";
import ToolTip from "../Common/ToolTip";
import { trimString } from "../../helpers/common";

const WantAdDescription = (props) => {
  const {
    handleFormValues,
    adTitle,
    adDescription,
    errors,
    titleTextBoxError,
    descriptionError,
    isFromBuyersListingService
  } = props;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descError, setDescError] = useState(null);
  const [titleError, setTitleError] = useState(null);
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const [titleCursorPos, setTitleCursorPos] = useState(0);
  const [descCursorPos, setDescCursorPos] = useState(0);

  const titleRef = useRef();
  const descriptionRef = useRef();

  useEffect(() => {
    setTitle(adTitle);
    setDescription(adDescription);
    setTitleCharCount(adTitle && adTitle.length ? adTitle.length : 0);
    setDescCharCount(adDescription && adDescription.length ? adDescription.length : 0);
  }, [adTitle, adDescription, titleError, descError]);

  // to show error message
  useEffect(() => {
    
   
    if (errors && errors.length > 0) {
      if(errors[0] == 'adTitle'){
        titleRef.current.focus();
  
      } else if(errors[0] == 'adDesc'){
        descriptionRef.current.focus();
      }

      errors.map((item) => {
        if (item === 'adTitle')
          setTitleError(ERROR_TEXT);
        if (item === 'adDesc')
          setDescError(ERROR_TEXT);
      })
    } else{
      setTitleError(null);
      setDescError(null)
    }
  }, [errors]);

  useEffect(() => {
    if(!isFromBuyersListingService){titleRef.current.setSelectionRange(titleCursorPos, titleCursorPos);}
  }, [titleCursorPos]);

  useEffect(() => {
    descriptionRef.current.setSelectionRange(descCursorPos, descCursorPos);
  }, [descCursorPos]);

  const handleTitleChange = (e) => {
    setTitleError("")
    const value = capitalizeFirstString(handleTextBoxChange(e));
    setTitle(value);
    setTitleCharCount(value.length);
    if (trimString(value) !== "") {
      handleFormValues({ adTitle: value });
      setTitleCursorPos(e.target.selectionStart);
    } else {
      handleFormValues({ adTitle: "" });
    }
  };

  const sentenceCase = (input, lowercaseBefore) => {
    setDescError("")
    input = input === undefined || input === null ? "" : input;
    if (lowercaseBefore) {
      input = input.toLowerCase();
    }
    return input
      .toString()
      .replace(/(^|\. *)([a-z])/g, function (match, separator, char) {
        return separator + char.toUpperCase();
      });
  };

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDescriptionChange = (e) => {
    let value = capitalizeFirstString(handleTextBoxChange(e));
    value = sentenceCase(value);
    setDescCharCount(value.length);
    setDescription(value);
    if (trimString(value) !== "") {
      handleFormValues({ adDesc: value });
      setDescCursorPos(e.target.selectionStart)
    } else {
      handleFormValues({ adDesc: "" });
    }
  };

  return (
    <div className={titleTextBoxError || descriptionError ? "wantad-des-wrap select-type-wrap red-marked-wrap" : "wantad-des-wrap select-type-wrap "}>
      {isFromBuyersListingService ? <></> :<div className="position-relative">
        <FormLabel className={titleTextBoxError && "red-marked-label"}>
          Title <span>*</span>
        </FormLabel>
        <ToolTip
          title={
            "In accordance with the Federal Fair Housing Act, we recommend not including personally identifiable information, such as race, gender, sexual preference or age. To learn more, <a href='https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations' target='_blank'>read about the Fair Housing Act here.</a>"
          }
        />
        <TextBox
          id="title"
          name="title"
          type="text"
          class={titleTextBoxError && "red-marked-formcontrol"}
          errorMessage={titleError}
          value={title ? title : ''}
          maxLength={50}
          placeholder="Ex. Looking for dream home in city"
          onChange={(e) => {
            handleTitleChange(e);
          }}
          txtRef={titleRef}
          onBlur={()=>{handleFormValues({focusOut: true})}}
        />
        <div className={titleTextBoxError ? "des-count mb-4 des-count-fix red-marked-count" : "des-count mb-4 des-count-fix"} >
          <span>{titleCharCount}</span>/<span>50</span>
        </div>
      </div>}
      <div className="position-relative">
        <FormLabel className={descriptionError && "red-marked-label"}>Description</FormLabel>
        <TextArea
          className= {descriptionError ? "form-control txt-area red-marked-formcontrol" : "form-control txt-area "}
          errorMessage={descError}
          value={description ? description : ''}
          maxLength={500}
          placeholder="Ex. My family and I have been on the hunt for our dream home in a walkable neighborhood..."
          onChange={(e) => {
            handleDescriptionChange(e);
          }}
          txtRef={descriptionRef}
          onBlur={()=>{handleFormValues({focusOut: true})}}
        />

        <div className={descriptionError ? "des-count mb-4 red-marked-count" : "des-count mb-4"}>
          <span>{descCharCount}</span>/<span>500</span>
        </div>
      </div>
    </div>
  );
};
export default WantAdDescription;
