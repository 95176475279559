import React, { useState, useEffect } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
import SegmentDivider from "../components/MemberWantAd/SegmentDivider";
import MyDrafts from "../components/Common/MyDrafts";
import { useNavigate } from "react-router-dom";
import {
  AGENT_INVENTORY_CREATE_KEY,
  AGENT_INVENTORY_EDIT_KEY
} from "../constants/common";
import CreatePlusIcon from "../images/create-plus-icon.svg";
import { pageURLs } from "../constants/pageURLs";
import { getInventoryMyDrafts, getInventoryById } from "../services/inventoryService";


const AgentCreateOrEditInventoryDraftListing = (props) => {
  const navigate = useNavigate();
  const [myDrafts, setMyDrafts] = useState();

  useEffect(() => {
    localStorage.removeItem(AGENT_INVENTORY_EDIT_KEY);
    localStorage.removeItem(AGENT_INVENTORY_CREATE_KEY);
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));
    const userId = _.get(userDetails, "userInfo.userId", null);
    getInventoryMyDrafts()
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (responseResult && responseResult.length !== 0) {
          setMyDrafts(responseResult);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleDraftItemClick = async (requestPayLoad) => {
    let inventoryId = requestPayLoad.selectedId;
    if (inventoryId) {
      await getInventoryById(inventoryId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            localStorage.setItem(AGENT_INVENTORY_EDIT_KEY, JSON.stringify(responseResult));
            navigate(pageURLs.agentCreateInventory, { state: { inventoryId: responseResult.inventoryId } })
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const loadDrafts = () => {
    const myDraftList =
      myDrafts &&
      myDrafts.map((md) => {
        const locAddress = _.get(md, "Location", '');
        const inventoryId = _.get(md, "Inv_Id");
        const CompletedPercentage = _.get(md, "CompletedPercentage", "");
        const Current_Step = _.get(md, "Current_Step", "");
        var lastUpdatedDate = new Date(_.get(md, "LastUpdatedTS", ""));
        var formattedDate = [('0' + (lastUpdatedDate.getMonth() + 1)).slice(-2),
        ('0' + lastUpdatedDate.getDate()).slice(-2),
        lastUpdatedDate.getFullYear()].join('/');

        return (
          <MyDrafts
            key={inventoryId}
            itemId={inventoryId}
            CompletedPercentage={CompletedPercentage}
            locationAddress={locAddress}
            Current_Step={Current_Step}
            setDraftSelectedItem={handleDraftItemClick}
            latUpdatedDate={formattedDate}
          />
        );
      });

    return myDraftList;
  };

  return (
    <div className="outer-wrap position-relative mw-1168">
      <CommonHeader />
      <div className="row">
        <div className="col-md-12">

          <div className="edit-want-ad-wrap mw-640">
            <a role="button"
              href={void 0} className="create-new-btn mb-3" onClick={() => navigate(pageURLs.agentCreateInventory)}>
              <span className="create-plus-icon">
                <img src={CreatePlusIcon} />
              </span>
              Add new property
            </a>
            {myDrafts &&
              <>
                <SegmentDivider />
                <div className="my-drafts-sec">
                  <h2 className="mt-3">My Drafts</h2>
                  {loadDrafts()}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentCreateOrEditInventoryDraftListing;
