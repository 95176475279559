import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import HouseImgIcon from "../../images/house-img.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import noImage from "../../images/no-image-placeholder.png";
import PremiumPlanModal from "../../components/PlanUpgrade/PremiumPlanModal";
import BasicPlanModal from "../../components/PlanUpgrade/BasicPlanModal";
import { getUserType } from "../../helpers/common";
import { USER_TYPE_AGENT } from "../../constants/common";
import { getInventoryById } from "../../services/inventoryService";

const PropertyModal = (props) => {
    const navigate = useNavigate();
    const propertyList = props.propertyList;
    const selectedPropertyId = props.selectedPropertyId;
    const { userCount, allowedCount, userDetails, updateReloadParam } = props;
    const wantAdId = props.wantAdId;
    const userType = getUserType();
    const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
    const PremiumPlanModalClose = () => setPremiumPlanModal(false);
    const PremiumPlanModalShow = () => setPremiumPlanModal(true);
    const [showBasicPlanModal, setBasicPlanModal] = useState(false);
    const BasicPlanModalClose = () => setBasicPlanModal(false);
    const BasicPlanModalShow = () => setBasicPlanModal(true);

    const handleRadioButtonChange = (property) => {
        if(property?.photo){
            props.handlePropertyChange(property);
            props.onHide()
        } else{
            getInventoryDetails(property?.Inv_Id)
        }
       
    }

    const getInventoryDetails = async (inventoryId) => {
        if (inventoryId) {
          await getInventoryById(inventoryId)
            .then((response) => {
              const responseResult = _.get(response, "result", null);
              if (response.errorCode === 0 && responseResult) {
                if (userType === USER_TYPE_AGENT) {
                    navigate(pageURLs.agentCreateInventory, {
                      state: {
                        data: responseResult,
                        inventoryId: inventoryId,
                        isFromIntroduction: true,
                        wantAdId:props.wantAdId
                      },
                    });
                  } else {
                    navigate(pageURLs.memberEditInventory, {
                      state: { data: responseResult,  isFromIntroduction: true, wantAdId: props.wantAdId },
                    });
                  }
               
              } else {
                console.log("Something went wrong");
              }
            })
            .catch((err) => {
              console.log("Error", err);
            });
        } else {
          userDetails?.userType == 1 ? navigate(pageURLs.memberCreateInventory) : navigate(pageURLs.agentCreateInventory) ;
        }
      };

    const addNewpropertyNavigation = () => {
        console.log("props.usertype", props.userType);
        if (userCount?.Inventory >= allowedCount?.Inventory) {
            if (userType === USER_TYPE_AGENT) {
                PremiumPlanModalShow()
            }
            else {
                BasicPlanModalShow()
            }
        }
        else { userType === USER_TYPE_AGENT ? navigate(pageURLs.agentCreateInventory, { state: { isFromIntroduction: true, wantAdId: wantAdId } }) : navigate(pageURLs.memberCreateInventory) }
    }

    return (
        <>
            <Modal
                show
                centered
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-right-aligned"
            >
                <Modal.Body className="">
                    <div className=" position-relative">
                        <div className="sub-heading-small position-relative mb-4">
                            <a role="button" className="sub-heading-backbtn" href={void 0}
                                onClick={props.onHide}>

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z" fill="#1B1C1E" />
                                </svg>

                            </a>
                            Choose property</div>
                        <div className="modal-choose-property modal-v-scroll">
                            {propertyList &&
                                propertyList.map((property) => {

                                    let intoSentClassName = property?.IntroSentDate ? 'intro-sent' : '';
                                    let intoDeclinedClassName = property?.IntroDeclineDate ? 'intro-declined' : '';

                                    return (
                                        <label className={"property-radio " + intoSentClassName + " " + intoDeclinedClassName}>
                                            {(((property?.IsIntroSent == 1 && property?.IsCancelled == 1) || (property?.IsIntroSent == 0 || !property?.IsIntroSent)) && (property?.IsDecline == 0 || !property?.IsDecline) && (property?.NotInterested == 0 || !property?.NotInterested) && (property?.Not_A_Fit == 0 || !property?.Not_A_Fit)) &&
                                                <input type="radio"
                                                    name="check-box-option"
                                                    id={property?.Inv_Id}
                                                    value={property?.Inv_Id}
                                                    checked={property?.Inv_Id == selectedPropertyId ? true : false}
                                                    onClick={() => handleRadioButtonChange(property)}
                                                    onChange={() => { }} />
                                            }
                                            <span>
                                                <div className="left-img-wrap">
                                                    <img className="display-thumb-img" src={property.photo ? property?.photo : noImage} />
                                                </div>
                                                <div className="modal-wrap-info">
                                                    <h2>{property?.Location}</h2>
                                                    {/* <p>Eugene, OR</p> */}

                                                    {/* {(property?.IsIntroSent == 1 && property?.IsCancelled == 0) && */}
                                                    {(property?.IsIntroSent == 1 && property?.IsCancelled == 0 && property?.IsDecline != 1 && property?.NotInterested != 1 && property?.Not_A_Fit != 1) ?
                                                        <div className="modal-wrap-label-grey">SENT {moment(
                                                            new Date(property?.IntroSentDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :
                                                    property?.IsDecline == 1 ?
                                                        <div className="modal-wrap-label-orange">DECLINED {moment(
                                                            new Date(property?.IntroDeclineDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :

                                                    property?.NotInterested == 1 ?
                                                        <div className="modal-wrap-label-orange">NOT INTERESTED {moment(
                                                            new Date(property?.IntroDeclineDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :

                                                    property?.Not_A_Fit == 1 ?
                                                        <div className="modal-wrap-label-orange">NOT A FIT {moment(
                                                            new Date(property?.IntroDeclineDate)
                                                        ).format("MMM Do, YYYY")}</div>

                                                    :

                                                    !property?.photo &&
                                                        <div className="modal-wrap-label-blue">MUST ADD A PHOTO TO INTRODUCE</div>

                                                    }
                                                    {/* {property?.IntroSentDate &&
                                                <div className="modal-wrap-label-grey mt-1">SENT {moment(
                                                    new Date(property?.IntroSentDate)
                                                  ).format("MMM Do, YYYY") }</div>

                                                }
                                                 {property?.IntroDeclineDate &&
                                                <div className="modal-wrap-label-orange mt-1">DECLINED {moment(
                                                    new Date(property?.IntroSentDate)
                                                  ).format("MMM Do, YYYY") }</div>

                                                } */}
                                                </div>
                                            </span>
                                        </label>
                                    )
                                })}
                        </div>
                        <div className="modal-choose-property-btnwrap">
                            <button className="btn btn-secondary w-100" onClick={() => { addNewpropertyNavigation() }}>Add Property</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <BasicPlanModal
                userDetails={userDetails}
                show={showBasicPlanModal}
                updateReloadParam={updateReloadParam}
                onHide={BasicPlanModalClose}
                fromInventoryListing={true}
                showPlanModal={BasicPlanModalShow}
            />
            <PremiumPlanModal
                userDetails={userDetails}
                show={showPremiumPlanModal}
                updateReloadParam={updateReloadParam}
                onHide={PremiumPlanModalClose}
                fromInventoryListing={true}
                showPlanModal={PremiumPlanModalShow}
            />
        </>
    );
};
export default PropertyModal;
