
/**
 * @file   src\mobile\components\Onboarding\ProfileImageCropper.js
 * @brief  This file is responsible for handling profile image crop.
 * @date   June, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { getPreSignedUrl } from '../../../services/fileUploadService';
import { s3ImageBaseFolder } from '../../../helpers/common';
import { toast } from "react-toastify";
import Loader from '../../../components/Common/Loader';

const ProfileImageCropper = (props) => {
    const { image, userId } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const s3ProfileImageFolder = s3ImageBaseFolder() + "/profileImage/Images/";
    const [isUploading, setIsUploading] = useState(false);

    useEffect(()=>{
        setZoom(1);
        setCrop({ x: 0, y: 0 })
        setCroppedAreaPixels(null)
    }, [props?.show])

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleSave = () => {
        setIsUploading(true)
        if (!croppedAreaPixels) {
            return;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imageRef = new Image();
        imageRef.src = image;
        imageRef.onload = async () => {
            canvas.width = croppedAreaPixels.width;
            canvas.height = croppedAreaPixels.height;

            ctx.drawImage(
                imageRef,
                croppedAreaPixels.x,
                croppedAreaPixels.y,
                croppedAreaPixels.width,
                croppedAreaPixels.height,
                0,
                0,
                croppedAreaPixels.width,
                croppedAreaPixels.height
            );

            const croppedImage = canvas.toDataURL('image/jpeg');
            const base64Data = croppedImage.split(',')[1];
            const blob = base64ToBlob(base64Data, 'image/jpeg');
            const reqPayload = {
                key:
                    s3ProfileImageFolder + userId + ".jpg",
            };

            await getPreSignedUrl(reqPayload)
                .then((response) => {
                    if (response.errorCode === 0) {
                        try {
                            const headersValue = new Headers({ "Content-Type": 'image/jpeg', });
                            fetch(response.result, {
                                method: "PUT",
                                headers: headersValue,
                                body: blob,
                            })
                                .then((response) => {
                                    const status = _.get(response, "status", "");
                                    const fileFullUrl = _.get(response, "url", "");
                                    const fileS3Url = fileFullUrl
                                        ? fileFullUrl?.split("?")[0]
                                        : ""; // For excluding Aws credentials from the url and access s3 image Url.

                                    if (status === 200) {
                                        setIsUploading(false)
                                        props.updateImageAfterCrop(fileS3Url);
                                        toast.success("Profile image changed successfully");
                                        // props.onHide();
                                    } else{
                                        setIsUploading(false)
                                        toast.success("Profile image change failed");
                                        props.onHide();
                                    }
                                })
                                .catch((error) => {
                                    setIsUploading(false)
                                    toast.error(error);
                                    props.onHide();
                                });
                        } catch (error) {
                            setIsUploading(false)
                            toast.error(error);
                            props.onHide();
                        }
                    } else {
                        setIsUploading(false)
                        toast.dismiss();
                        toast.error(response.errorMessage);
                        props.onHide();
                    }
                })
                .catch((err) => {
                    props.onHide();
                    toast.dismiss();
                    toast.error(err);
                    props.onHide();
                });

            // Here you can save or use the croppedImage data URL
        };
    };

    const base64ToBlob = (base64, type = 'application/octet-stream') => {
        const binary = atob(base64);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type });
    };

    return (
        <>
            {isUploading ? <Loader /> : ""}
        
            <Modal
                show
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-settings"
                backdrop="static"
            >
                <Modal.Body className="pt-32 pb-32">
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        onClick={() => { props.onHide() }}
                    ></button>
                   
                    <Row>
                        <Col xl={12}>
                            <div className="textbox-wrap mt-3 mb-3 mb-sm-3 position-relative">
                                {/* <input type="file" accept="image/*" onChange={onFileChange} /> */}
                                {image && (
                                    <>
                                    <div style={{ position: 'relative', width: '100%', height: 400 }}>
                                        <Cropper
                                            image={image}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={1} // Aspect ratio (optional)
                                            cropShape={"round"}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={onCropComplete}
                                        />                                        
                                    </div>
                                    <div className='modalImageSlider'>
                                    <input type="range" 
                                        className="form-range" 
                                        id="customRange1"
                                        value={zoom}                                        
                                        min={1}
                                        max={3}
                                        step={0.01}
                                        onChange={(e) => setZoom(e.target.value)}
                                        style={{"--track-fg-width":"32%"}} />
                                     </div>
                                    </>
                                   
                                )}
                            </div>
                        </Col>
                    </Row>

                    <div className="modal-btn-wrap mt-2">
                        <Button variant="" className="btn btn-tertiary btn-margin-fix me-3" onClick={() => { props.onHide(); }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            //   disabled={checkValid()}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>



    );
};

export default ProfileImageCropper;