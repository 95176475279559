import React, { useState, useEffect } from "react";
import Select from "react-select";
import { components } from "react-select";
import searchIccon from "../../../images/search-icon.svg";
import { getAgentsList } from "../../../services/wantAdService";
import { renameKey, combineKey, getUserId } from "../../../helpers/common";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={searchIccon} />
    </components.DropdownIndicator>
  );
};
const userId = getUserId();
const ClientListing = (props) => {
  const { handleChangeClient, clientId } = props;

  const [clientsList, setClientsList] = useState([]);

  useEffect(() => {
    getClientList(); // to load client list on page load
  }, []);

  /**
   * to set the selected option on load
   */
  const getSelectedClient = (clientId, mainList) => {
    if (clientId && mainList) {
      var selectedValue = mainList.filter(function (list) {
        if (list.value == clientId) {
          return list;
        }
      });
      return selectedValue;
    }
    return null;
  };

  const getClientList = async () => {
    let query = {
    };
    await getAgentsList(query)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          responseResult.forEach((obj) => renameKey(obj, "ClientId", "value"));
          responseResult.forEach((obj) =>
            combineKey(obj, "FirstName", "LastName", "label")
          );
          setClientsList(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  4

  const customStyles = {
      option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "red" : "blue",
      };
    }
  };

  return (
    <>
      <Select
        components={{ DropdownIndicator }}
        name="colors1"
        value={getSelectedClient(clientId, clientsList)}
        options={(clientsList)}
        className="basic-multi-select mob-select mb-4 w-100"
        classNamePrefix="select"
        placeholder="Client Name"
        onChange={(e) => {
          handleChangeClient(e);
        }}
        createOptionPosition="first"
        isValidNewOption={(_) => true}
        isClearable={true}
      
      />
    </>
  );
};
export default ClientListing;
