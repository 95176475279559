import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Tab, Tabs } from "react-bootstrap";
import CancelPlanModal from "./CancelPlanModal";
import PlanReviewModalWrapper from "../Payments/StripeElementsWrapper";
import {getLocalStorage, getUserId, sendEmailSales, setLocalStorage, getUserType, checkPlanCanbeUpgraded} from "../../helpers/common"
import { toast } from "react-toastify";
import { createSubscriptionPlan, createSubscriptionPlanOnSignup, getActivePromoCodeDetails } from "../../services/PlanService";
import { AGENT_USER_FREE_PLAN, USER_INFO_KEY, USER_TYPE_AGENT, USER_TYPE_MEMBER } from "../../constants/common";
import { useSelector } from "react-redux";
import ContactModal from "../Onboarding/ContactModal";
import PlanUpgradeRestrictionModal from "./PlanUpgradeRestrictionModal";
import ShowEditProfileModal from "./ShowEditProfileModal";
import { useNavigate } from "react-router-dom";
const PremiumPlanModal = (props) => {

  const {userDetails, updateReloadParam, handleShowAgentModal, show, handleAgentTitleModal, UpgradeToAgent} = props
  const [showPlanReviewModal, setPlanReviewModal] = useState(false);
  const PlanReviewModalClose = () => setPlanReviewModal(false);
  const PlanReviewModalShow = () => setPlanReviewModal(true);
  const [selectedPeriod, setSelectedPeriod] = useState("")
  const [selectedPlan, setSelectedPlan] = useState("")
  const [key, setKey] = useState('annual');
  const [fromSignUp, setFromSignUp] = useState(false);
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const [showCancelPlanModal, setCancelPlanModal] = useState(false);
  const CancelPlanModalClose = () => setCancelPlanModal(false);
  const CancelPlanModalShow = () => setCancelPlanModal(true);
  const userType = getUserType();
  const [currentActivePlan, setCurrentActivePlan] = useState(null);
   // contact modal
   const [contactModalState, setContactModalState] = useState(false);
   const ContactModalClose = () => setContactModalState(false);
   const ContactModalShow = () => setContactModalState(true);
   const [enablePromocode, setEnablePromocode] = useState(false);
   const [enablePromocodePeriod, setEnablePromoCodePeriod] = useState([]);
   const [showPlanRestrictionModal, setShowPlanRestrictionModal] = useState(false);
   const [continueButtonClicked, setContinueButtonClicked] = useState(false);

   const [editProfileModal, setEditProfileModal] = useState(false);
    const EditProfileModalClose = () => setEditProfileModal(false);
    const EditProfileModalShow = () => setEditProfileModal(true);
    const navigate = useNavigate();
   useEffect(()=>{
      if(show) {
        getActivePromoCodeDetails().then((response)=>{
          let temp = [];
          const res = _.get(response, "result", [])
          if(res?.length>0){
            const result = res.filter((planDetails)=>planDetails?.UserType==2 || planDetails?.UserType==3)
            if(result && result.length > 0){
              setEnablePromocode(true)
            }
            result.forEach((data)=>{
              temp.push(data?.Period)
            })
            setEnablePromoCodePeriod(temp);
          }
        })
      }      
   }, [show])

   useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      const planCurrent = userDetailsFromStore.filter((pl)=>pl?.Is_Upcoming===0 && pl?.IsActive === 1)
      setCurrentActivePlan(planCurrent[0])
    }
  }, [userDetailsFromStore]);  

  useEffect(()=>{
    if(userDetails?.PlanName == null) {
      setFromSignUp(true)
    }
    else {
      setFromSignUp(false)
    }
    setSelectedPeriod(userDetails?.period);
    setSelectedPlan(userDetails?.PlanName)
  }, [userDetails])
  
 

  const handleUpgrade = (period, plan, continueClicked=false) => {
    setSelectedPeriod(period)
    setSelectedPlan(plan)
    if(!continueClicked){
      setContinueButtonClicked(false)
    }
    if (checkPlanCanbeUpgraded(currentActivePlan, plan, period)?.canPlanUpgrade == false && !continueClicked) {
      setShowPlanRestrictionModal(true);      
    } else {      
      PlanReviewModalShow()
    }    
    props.onHide()
  }

  useEffect(()=>{
    if(selectedPeriod===3) {
      setKey('annual')
    }
    else if(selectedPeriod===2){
      setKey('monthly')
    }

  }, [selectedPeriod])

  const handleDowngrade =()=>{
    props.onHide()
    CancelPlanModalShow()
  }

  const handleCancel = () => {
    if(userDetails?.period===2){
      setKey('monthly')
    }
    else{
      setKey('annual')
    }
    props.onHide()
  }

  const upgradeUserToFreePlan = async(continueClicked=false) => {
    setSelectedPeriod(1)
    setSelectedPlan("Lite")
    if(!continueClicked){
      setContinueButtonClicked(false)
    }
    if (userType === USER_TYPE_MEMBER && checkPlanCanbeUpgraded(currentActivePlan, 'Lite', 1)?.canPlanUpgrade == false && !continueClicked) {
      setShowPlanRestrictionModal(true);        
      props.onHide();
    }    
    else {
      const data = {
      PlanName: "Lite",
      PlanPeriod: 1,
      UserType: userType ,
      PopupStatus : continueClicked? true : false    
    };     
    
    if(UpgradeToAgent===true){
      data.changeUserType = 2     
      await createSubscriptionPlan(data)
      .then(async (response) => {
        if (response.errorCode === 0) {          
          props.onHide();
          toast.success("You've selected Lite!");
          if(UpgradeToAgent===true){ EditProfileModalShow()}
          else{
            props.updateReloadParam();
          }                 
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      }); 
    }
    
    else {
      await createSubscriptionPlanOnSignup(data)
      .then(async (response) => {
        if (response.errorCode === 0) {          
          props.onHide();
          props.updateReloadParam();
          toast.dismiss();
          toast.success("You've selected Lite!");
          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["planStatus"] = true;
          setLocalStorage(USER_INFO_KEY, userInfo);
          handleShowAgentModal({showState: true });
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      }); 
    }}
  }

  const handleContinueClick = () => {
    setContinueButtonClicked(true)
    if(selectedPlan===AGENT_USER_FREE_PLAN){
      upgradeUserToFreePlan(true)
    }
    else {
      handleUpgrade(selectedPeriod, selectedPlan, true); 
      
    }
  }


  
  return (
    <>
    <Modal
      show
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-plan premium-plan-modal"
      backdrop="static"
    >
      <Modal.Body>
        {(!fromSignUp && !props.fromInventoryCreation) && <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={handleCancel}
        ></button>}{
          fromSignUp && !_.get(getLocalStorage(USER_INFO_KEY), 'userInfo.planStatus') &&   
          <a role="button" href={void 0} className="bottom-back-btn" onClick={()=>{handleAgentTitleModal({showModal: true}); props.onHide()}}>
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                fill="#205ECA"
              />
            </svg>
          </span>
          Back
        </a>
        }
        {UpgradeToAgent ? <h3 className="text-center">Switch to an agent profile</h3> : 
        userDetails?.PlanName== null ? <h3 className="text-center">Select your plan</h3> : <h3 className="text-center">{props?.fromInventoryListing ? "Upgrade to Premium to add more properties":"Upgrade your professional plan"}</h3>}        
        <p className="desc text-center">{ UpgradeToAgent ? "Select your plan to make your switch. Please be aware that if you currently have an investor profile, you’ll lose access to investor specific features & want criteria but gain agent specific features.":
        props?.fromInventoryListing ? "Your free plan is limited to 2 properties in your private inventory. Upgrade to our Premium plan to gain more that Gitcha has to offer." : "Subscribe to save time and create more deals with unlimited access to all of Gitcha’s features."} </p>
        <div className="tab-wrap">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="annual" title="Annual">
            <div className="plan-container">
              <div className="plan-wraper member w-100">
                <h4>Lite</h4>
                <p className="info">Get a taste of what Gitcha can do with limited features.</p>
                <p className="price">Free</p>
                <div className="btn-sec">
                {userDetails?.PlanName == null || userType === USER_TYPE_MEMBER ? <Button variant="primary" onClick={()=>upgradeUserToFreePlan()} className="plan-btn">Select</Button>
                :userDetails?.PlanName==='Lite'? 
                  <Button variant="primary" className="plan-btn" disabled>Your current plan</Button> :
                  <Button variant="outline" className="btn-outline-primary plan-btn" onClick={handleDowngrade}>Downgrade</Button> 

                }
                </div>
                <ul className="plan-features">
                  <li>Unlimited want-ads</li>
                  <li>Up to 2 properties in your inventory</li>
                  <li>Pay per introduction</li>
                </ul>
              </div>
              <div className="plan-wraper premium w-100 pad-lr-21">
              {userDetails?.PlanName != 'Pro Member' &&<span className="table-badge-green">Most popular</span>}
                <h4 className="d-flex align-items-center">Pro Member <span className="pro-blue-badge">PRO</span></h4>
                <p className="info">Specific features for agents and teams to create and manage deals.</p>
                <p className="price">$12/month<span>Billed annually</span></p>
                <div className="btn-sec">
                  {userDetails?.PlanName== null ? <Button variant="primary" className="plan-btn"  onClick={()=>handleUpgrade(3,'Pro Member')}>Select</Button> :
                  <Button variant="primary" className="plan-btn" disabled={userDetails?.PlanName==='Pro Member' && userDetails?.period===3}  onClick={()=>handleUpgrade(3,'Pro Member')}>{userDetails?.PlanName==='Pro Member' && userDetails?.period===3 ? "Your Current Plan" : "Upgrade to Pro Member"}</Button>}
                </div>
                  <ul className="plan-features">                    
                    <li>Client management dashboard</li>
                    <li>Unlimited want-ads</li>
                    <li>Unlimited properties in your inventory</li>
                    <li>10 free introductions per month</li>
                    <li>Custom agent profile and Pro badge</li>
                    <li>Branded Buyer Digital Listing Form</li>
                    <li>See other Pro Member's contact info</li>
                  </ul>
              </div>
              <div className="plan-wraper preferred w-100">
                <h4 className="d-flex align-items-center">Pro Preferred <span className="pro-gold-badge">PRO</span></h4>
                <p className="info">We’re working on new features that will be a game changer for agents.</p>
                <p className="price">Coming soon</p>
                <div className="btn-sec"><Button variant="outline" className="btn-outline-blue plan-btn">Notify Me</Button></div>
                <div className="plan-outer">
                  <ul className="plan-features">
                    <li>Unlimited want-ads</li>
                    <li>Custom agent profile and Premium badge</li>
                    <li>Unlimited properties in your inventory</li>
                    <li>10 free introductions per month</li>
                    <li>Client management dashboard </li>
                  </ul>
                </div>
              </div>
              {/* <div className="plan-wraper preferred">
                <h4 className="d-flex align-items-center">Pro Preferred <span className="pro-gold-badge">PRO</span></h4>
                <p className="info">Access all the agent features Gitcha has to offer.</p>
                <p className="price">$60/month<span>Billed annually</span></p>
                <div className="btn-sec"><Button variant="outline" className="btn-outline-primary plan-btn" onClick={()=>handleUpgrade(3,'Pro Preferred')}>Upgrade To Preferred</Button></div>
                <p className="info">Everything included in Premium, plus:</p>
                  <ul className="plan-features">
                    <li>Custom agent profile and Preferred badge</li>
                    <li>50 free introductions per month</li>
                    <li>Exclusive market analytics, real-time buyer demand and private inventory data reports</li>
                    <li>10 free introductions per month</li>
                    <li>5-10 leads per month </li>
                  </ul>
              </div> */}
              <div className="plan-wraper preferred w-100">
                <h4>Enterprise</h4>
                <p className="info">Empowering your Agency's full team with Premium benefits.</p>
                <p className="price">Tiered pricing/seat</p>
                <div className="btn-sec"><Button variant="primary" className="plan-btn" 
                // onClick={()=>sendEmailSales("")}
                onClick={()=>{ContactModalShow()}}
                >Contact us</Button></div>
                <ul className="plan-features">
                  <li>Unlimited want-ads</li>
                  <li>Custom agent profile and Premium badge</li>
                  <li>Unlimited properties in your inventory</li>
                  <li>Free introductions per month</li>
                  <li>Client management dashboard</li>
                  <li>Exclusive market analytics, real-time buyer demand and private inventory data reports</li>
                </ul>
              </div>
            </div>
          </Tab>

          <Tab eventKey="monthly" title="Monthly">
          <div className="plan-container">
              <div className="plan-wraper member w-100">
                <h4>Lite</h4>
                <p className="info">Get a taste of what Gitcha can do with limited features.</p>
                <p className="price">Free</p>
                <div className="btn-sec">
                  {userDetails?.PlanName==null || userType === USER_TYPE_MEMBER ? <Button variant="primary" className="plan-btn" onClick={()=>upgradeUserToFreePlan()}>Select</Button>:
                  userDetails?.PlanName==='Lite'? 
                    <Button variant="primary" className="plan-btn" disabled>Your current plan</Button> :
                    <Button variant="outline" className="btn-outline-primary plan-btn" onClick={handleDowngrade}>Downgrade</Button> 

                }
                </div>
                <ul className="plan-features">
                  <li>Unlimited want-ads</li>
                  <li>Up to 2 properties in your inventory</li>
                  <li>Pay per introduction</li>
                </ul>
              </div>
              <div className="plan-wraper premium w-100 pad-lr-21">
              {userDetails?.PlanName != 'Pro Member' &&<span className="table-badge-green">Most popular</span>}
                <h4 className="d-flex align-items-center">Pro Member <span className="pro-blue-badge">PRO</span></h4>
                <p className="info">Specific features for agents and teams to create and manage deals.</p>
                <p className="price">$15/month<span>Billed monthly</span></p>
                <div className="btn-sec">
                  {userDetails?.PlanName==null ? <Button variant="primary" className="plan-btn" onClick={()=>handleUpgrade(2, 'Pro Member')}>Select</Button>:
                  <Button variant="primary" className="plan-btn" disabled={userDetails?.PlanName==='Pro Member' && (userDetails?.period===2)} onClick={()=>handleUpgrade(2, 'Pro Member')}>{userDetails?.PlanName==='Pro Member' && (userDetails?.period===2) ? "Your Current Plan" : "Upgrade to Pro Member"}</Button>}
                </div>
                <ul className="plan-features">                  
                  <li>Client management dashboard</li>
                  <li>Unlimited want-ads</li>
                  <li>Unlimited properties in your inventory</li>
                  <li>10 free introductions per month</li>
                  <li>Custom agent profile and Pro badge</li>
                  <li>Branded Buyer Digital Listing Form</li>
                  <li>See other Pro Member's contact info</li>
                </ul>
              </div>
              <div className="plan-wraper preferred w-100">
                <h4 className="d-flex align-items-center">Pro Preferred <span className="pro-gold-badge">PRO</span></h4>
                <p className="info">We’re working on new features that will be a game changer for agents.</p>
                <p className="price">Coming soon</p>
                <div className="btn-sec"><Button variant="outline" className="btn-outline-blue plan-btn">Notify Me</Button></div>
                <div className="plan-outer">
                  <ul className="plan-features">
                    <li>Unlimited want-ads</li>
                    <li>Custom agent profile and Premium badge</li>
                    <li>Unlimited properties in your inventory</li>
                    <li>10 free introductions per month</li>
                    <li>Client management dashboard </li>
                  </ul>
                </div>
              </div>
              {/* <div className="plan-wraper preferred">
                <h4 className="d-flex align-items-center">Pro Preferred <span className="pro-gold-badge">PRO</span></h4>
                <p className="info">Access all the agent features Gitcha has to offer.</p>
                <p className="price">$75/month<span>Billed monthly</span></p>
                <div className="btn-sec"><Button variant="outline" className="btn-outline-primary plan-btn" onClick={()=>handleUpgrade(2,'Pro Preferred')}>Upgrade To Preferred</Button></div>
                <p className="info" >Everything included in Premium, plus:</p>
                  <ul className="plan-features">
                    <li>Custom agent profile and Preferred badge</li>
                    <li>50 free introductions per month</li>
                    <li>Exclusive market analytics, real-time buyer demand and private inventory data reports</li>
                    <li>10 free introductions per month</li>
                    <li>5-10 leads per month </li>
                  </ul>
              </div> */}
              <div className="plan-wraper preferred w-100">
                <h4>Enterprise</h4>
                <p className="info">Empowering your Agency's full team with Premium benefits.</p>
                <p className="price">Tiered pricing/seat</p>
                <div className="btn-sec"><Button variant="primary" className="plan-btn"   onClick={()=>{ContactModalShow()}}>Contact us</Button></div>
                <ul className="plan-features">
                  <li>Unlimited want-ads</li>
                  <li>Custom agent profile and Premium badge</li>
                  <li>Unlimited properties in your inventory</li>
                  <li>Free introductions per month</li>
                  <li>Client management dashboard</li>
                  <li>Exclusive market analytics, real-time buyer demand and private inventory data reports</li>
                </ul>
              </div>
            </div>
          </Tab>
        </Tabs>
        </div>
      </Modal.Body>
    </Modal>
    <PlanReviewModalWrapper
    period={selectedPeriod}
    setPeriod={setSelectedPeriod}
    selectedPlan={selectedPlan}
    show={showPlanReviewModal}
    onHide={PlanReviewModalClose}
    updateReloadParam={updateReloadParam}
    fromSignUp={fromSignUp}
    handleShowAgentModal={handleShowAgentModal}
    enablePromocode={enablePromocode}
    enablePromocodePeriod={enablePromocodePeriod}
    userDetails={userDetails}
    showPlanModal={props.showPlanModal}
    UpgradeToAgent={UpgradeToAgent}
    currentActivePlan={currentActivePlan}
    continueButtonClicked={continueButtonClicked}
    setContinueButtonClicked={setContinueButtonClicked}
    />
    {showCancelPlanModal && <CancelPlanModal
    show={showCancelPlanModal}
    onHide={CancelPlanModalClose}
    updateReloadParam={updateReloadParam}
    userDetails={userDetails}
    />}
    <PlanUpgradeRestrictionModal
      show={showPlanRestrictionModal}
      onHide={() => {setShowPlanRestrictionModal(false); setContinueButtonClicked(false)}}
      currentActivePlan={currentActivePlan}
      selectedPeriod={selectedPeriod}
      handleContinueButton={()=>handleContinueClick()}
    />
     <ContactModal
        show={contactModalState}
        onHide={ContactModalClose}
        sourceType={2} // Enterprise
      />
      <ShowEditProfileModal
        editProfileModal={editProfileModal}
        EditProfileModalClose={EditProfileModalClose}
        userDetails={currentActivePlan}
        selectedPlan={selectedPlan}
    />
    </>
    
  );
};
export default PremiumPlanModal;
