import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CommonHeader from "../components/Onboarding/CommonHeader";
import InventoryPropertyTypes from "../components/Inventory/EditInventory/InventoryPropertyTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  SELL,
  USER_INFO_KEY,
  INVENTORY_KEY,
  IS_LAND,
  INVENTORY_ALL_TYPE,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  AGENT_INVENTORY_CREATE_KEY,
  AGENT_INVENTORY_EDIT_KEY,
  DEFAULT_MAP_ZOOM,
  YEAR_BUILT_MAX,
  YEAR_BUILT_MIN,
  SHORT_RENT,
  USER_TYPE_AGENT,
} from "../constants/common";

import { getLocalStorage, removeLocalStorage, setLocalStorage, loggedInUser, getUserId, createStaticMapImage, createPreSignedUrl, removeDecimalZeros, checkTutorialDisplayStatus, getUserType } from "../helpers/common";
import { convertToNumber, textValidations } from "../helpers/validations";
import { saveMemberInventoryData, saveInventoryStrengthenData } from "../services/inventoryService";
import { pageURLs } from "../constants/pageURLs";
import { checkInventoryType } from "../helpers/inventory";

import InventoryLotLandLocation from "../components/Inventory/EditInventory/InventoryLotLandLocation";
import InventoryLocationWithMustHaves from "../components/Inventory/EditInventory/InventoryLocationWithMustHaves";
import InventoryPropertyPurpose from "../components/Inventory/EditInventory/InventoryPropertyPurpose";
import InventoryBudget from "../components/Inventory/EditInventory/InventoryBudget";
import InventoryPropertyDetails from "../components/Inventory/EditInventory/InventoryPropertyDetails";
import NiceToHave from "../components/Common/NiceToHave/NiceToHave";
import ClientSelection from "../components/Common/AgentClientSelection/ClientSelection";
import InventoryDocuments from "../components/Inventory/StengthenInventory/InventoryDocuments";
import InventoryTimeLine from "../components/Inventory/StengthenInventory/InventoryTimeLine";
import InventoryFileUpload from "../components/Inventory/EditInventory/InventoryFileUpload";
import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";
import { fetchUserDetails, fetchUserStatistics } from "../actions/userprofile";
import PremiumPlanModal from "../components/PlanUpgrade/PremiumPlanModal";




const AgentCreateOrEditInventory = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);
  
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const userStatictics = useSelector(
    (state) => state?.userProfile?.userStatictics
  );
  const userPrivileges = useSelector(
    (state) => state?.userProfile?.userPrivileges
  );
  const [userCount, setUserCount] = useState({});
  const [allowedCount, setAllowedCount] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [reload, setReload] = useState(0);

  const sessionKey = location.state && location.state['inventoryId'] ? AGENT_INVENTORY_EDIT_KEY : AGENT_INVENTORY_CREATE_KEY;
  const isFromIntroduction = location.state && location.state['isFromIntroduction'];
  const wantAdId = location.state && location.state['wantAdId'];
  const userType = getUserType();
  const isAgent = userType=== USER_TYPE_AGENT ? true : false;

  const [isPremiumUser , setIsPremiumUser] = useState(null);

  const [formValues, setFormValues] = useState(getLocalStorage(sessionKey) ? getLocalStorage(sessionKey) : location.state && location.state['data'] ? location.state['data']
    : getLocalStorage(sessionKey));

  const PhotosAfterDelete = getLocalStorage("PhotosAfterDelete") // to update photos data in session


  const propertyType = convertToNumber(_.get(formValues, "prprtyType", null));
  const propertySubType = convertToNumber(
    _.get(formValues, "prptySubType", null)
  );

  const [disableButton, setDisableButton] = useState(false);
  const [draftButtonDisable, setDraftButtonDisable] = useState(false);
  const dispatch = useDispatch();

  const sessionData = loggedInUser();
  const invType = checkInventoryType(_.get(formValues, "proprtyPurpose", ''));
  const isSellOnly = invType === INVENTORY_SELL;
  const isLTROnly = invType === INVENTORY_LTR;
  const isSTROnly = invType === INVENTORY_STR;
  const isSell_And_LTR = invType === INVENTORY_SELL_LTR;
  const isSell_And_STR = invType === INVENTORY_SELL_STR;
  const isSTR_And_LTR = invType === INVENTORY_LTR_STR;
  const isAllType = invType === INVENTORY_ALL_TYPE;

  const [selectedniceToHavesList, setSelectedNiceToHavesList] = useState([]);

  // error tracking
  const [clientSelectionError, setClientSelectionError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [subPropertyTypeError, setSubPropertyTypeError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [askingPriceError, setAskingPriceError] = useState(false);
  const [longTermPriceError, setLongTermPriceError] = useState(false);
  const [shortTermPriceError, setShortTermPriceError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [financeTitleTextColor, setFinanceTitleTextColor] = useState(false);

  const ClientRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const locationRef = useRef(null);
  const adTypeRef = useRef(null);
  const BudgetRef = useRef(null);
  const DescriptionRef = useRef(null); 

  useEffect(() => {
    if (PhotosAfterDelete) {
      handleFormValues({ photos: PhotosAfterDelete});
      removeLocalStorage("PhotosAfterDelete")
    }
  }, [PhotosAfterDelete]);


  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } 
  }, [userDetailsFromStore]);


  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[0]);
    } else {
      setUserDetails(null);
    }
  }, [userDetailsFromStore]);

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);

  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  // to list my inventory

  useEffect(() => {
    if (_.get(sessionData, "userInfo.userId", "")) {
      dispatch(
        fetchUserDetails()
      );
    }
  }, [reload]);

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id,
    };

    dispatch(fetchUserStatistics(payload));
  }, [userDetails]);

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  useEffect(()=>{
    if (userCount?.Inventory >= allowedCount?.Inventory) {
      if(!formValues?.inventoryId || (formValues?.inventoryId && formValues?.isPublished==0)){
        PremiumPlanModalShow();
      }
    } 
  },[userCount, allowedCount])

  useEffect(() => { //Need to show inventory onboard very first time
    let DisplayInventoryAddOnboard = checkTutorialDisplayStatus(7); //Inventory onboard display
    if (DisplayInventoryAddOnboard) {
      navigate(pageURLs.gitchaInvetoryOnBoard, { state: { isFromIntroduction: true, wantAdId: wantAdId } })
    }
  }, []);

  useEffect(() => {

    formValues?.nice2Have ? setSelectedNiceToHavesList(formValues?.nice2Have) : setSelectedNiceToHavesList([]);

    if (formValues) {
      setLocalStorage(sessionKey, formValues);


      if (formValues?.clientId || formValues?.isAgentAsSeller) {
        setClientSelectionError(false)
      }

      if (formValues?.prprtyType) {
        setPropertyTypeError(false)
      }
      if (formValues?.prprtyType != IS_LAND && formValues?.prptySubType) {
        setSubPropertyTypeError(false)

      }
      if (formValues?.locAddress) {
        setLocationError(false)
      }
      if (formValues?.proprtyPurpose?.length > 0) {
        setTypeError(false)
      }
      if (isAllType) {
        if (formValues?.sellAskngPrice && formValues?.LTRAskngPrce && formValues?.STRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
        }
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
        }
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
        }
      } else if (isSellOnly) {
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
          setFinanceTitleTextColor(false)
        }
      } else if (isLTROnly) {
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
          setFinanceTitleTextColor(false)
        }
      } else if (isSTROnly) {
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
          setFinanceTitleTextColor(false)
        }
      } else if (isSell_And_LTR) {
        if (formValues?.sellAskngPrice && formValues?.LTRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
        }
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
        }
      } else if (isSell_And_STR) {
        if (formValues?.sellAskngPrice && formValues?.STRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.sellAskngPrice) {
          setAskingPriceError(false)
        }
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
        }
      } else if (isSTR_And_LTR) {
        if (formValues?.LTRAskngPrce && formValues?.STRAskngPrce) {
          setFinanceTitleTextColor(false)
        }
        if (formValues?.LTRAskngPrce) {
          setLongTermPriceError(false)
        }
        if (formValues?.STRAskngPrce) {
          setShortTermPriceError(false)
        }
      }

      if (formValues?.invtryDetails && textValidations(formValues?.invtryDetails)) {
        setDescriptionError(false)
      } else if (formValues?.invtryDetails && !textValidations(formValues?.invtryDetails)) {
        setDescriptionError(true)
      } else {
        setDescriptionError(false)
      }
    }

  }, [formValues]);

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearSell") {
        const array = { ...formValues }; // make a separate copy of the state
        ["sellAskngPrice", "isCompnsteAgent", "salesPercnt", "nice2Have"]
        .forEach((e) => delete array[e]);
        setFormValues(array);
      }
      if (type === "clearPropertyPurposeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwLrgDogs", "alwSmlDogs", "alwCats", "LTRAskngPrce", "isCompnsteAgent_LTR", "salesPercnt_LTR", "nice2Have" ].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "clearPropertyPurposeSTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwPets", "accsPvtBthRm", "pvtEntrnce", "noOfBeds", "STRAskngPrce", "isCompnsteAgent_STR", "salesPercnt_STR", "nice2Have"  ].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "locAddress",
        "noBedRm",
        "noBathRm",
        "sqFt",
        "stories",
        "lotSize",
        "grgeSpots",
        "adaAccess",
        "acreage",
        "lotType",
        "ltTypeTxt",
        "roadType",
        "rdTypeTxt",
        "isPowerAccess",
        "waterSuply",
        "wSText",
        "wasteWtrSys",
        "WstWatrSys",
        "watrWtrText",
        "isApprovedStrctr",
        "isInFloodPlain",
        "proprtyPurpose",
        "alwLrgDogs",
        "alwSmlDogs",
        "alwCats",
        "alwPets",
        "accsPvtBthRm",
        "isConfirmOwner",
        "isConfirmPropLoc",
        "nice2Have",
        "sellAskngPrice",
        "LTRAskngPrce",
        "STRAskngPrce",
        "isCompnsteAgent",
        "salesPercnt",
        "isCompnsteAgent_LTR",
        "isCompnsteAgent_STR",
        "salesPercnt_LTR",
        "salesPercnt_STR",
        "photos",
        "invtryDetails",
        "prptySubTypes",
        "prptySubType",
        "prptySubTypeTxt",
        "prptyTypeTxt",
        "IsNewConstruction",
        "isPrvtDrInst",
        "isDrldWelInst",
        "isDrldWelAppr",
        "isSepTankInst",
        "isSepTankAppr",
        "pvtEntrnce",
        "neighborHood",
        "highSchool",
        "yearBuilt",
        "noOfBeds",
        "lat",
        "lng",
        "mapZoom",
        "mapType",
        "street",
        "city",
        "county",
        "state",
        "zip",
        "invalidLocationExist",



      ].forEach((e) => delete array[e]);

      setFormValues(array);
    }
  };

  const handleSave = async (type) => {
    setDisableButton(true)
    setDraftButtonDisable(true);
    let error = [];
    if (isPremiumUser && (!formValues?.clientId && !formValues?.isAgentAsSeller)) {
      setClientSelectionError(true);
      error.push("ClientRef", "clientId")

    }

    if (!formValues?.prprtyType) {
      setPropertyTypeError(true)
      error.push("propertyTypeRef", "prprtyType")
    }

    if (formValues?.prprtyType != IS_LAND && !formValues?.prptySubType) {
      setSubPropertyTypeError(true)
      error.push("propertyTypeRef", "prprtyType")
    }
    if (!formValues?.locAddress) {
      setLocationError(true)
      error.push("locationRef", "locAddress")
    }
    if (formValues.yearBuilt) {
      if ((convertToNumber(formValues.yearBuilt) < YEAR_BUILT_MIN ||
        convertToNumber(formValues.yearBuilt) > YEAR_BUILT_MAX)) {
        error.push("locationRef", "yearBuiltRange");

      }

    }
    if (!formValues?.proprtyPurpose || formValues?.proprtyPurpose?.length == 0) {
      setTypeError(true)
      error.push("adTypeRef", "adType")
    }
    const is_STR_Selected = _.includes(formValues.proprtyPurpose, SHORT_RENT);
    if (is_STR_Selected && formValues.prprtyType != IS_LAND) {
      if (convertToNumber(formValues.noOfBeds) < 1) {
        error.push("adTypeRef", "noOfBeds");

      }
    }


    if (isAllType) {

      if (!formValues?.sellAskngPrice || !formValues?.LTRAskngPrce || !formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.sellAskngPrice) {
        setAskingPriceError(true)
        error.push("BudgetRef", "sellAskngPrice")
      }
      if (!formValues?.LTRAskngPrce) {
        setLongTermPriceError(true)
        error.push("BudgetRef", "LTRAskngPrce")
      }
      if (!formValues?.STRAskngPrce) {
        setShortTermPriceError(true)
        error.push("BudgetRef", "STRAskngPrce")
      }
    } else if (isSellOnly) {

      if (!formValues?.sellAskngPrice) {
        setFinanceTitleTextColor(true)
        setAskingPriceError(true)
        error.push("BudgetRef", "sellAskngPrice")
      }
    } else if (isLTROnly) {
      if (!formValues?.LTRAskngPrce) {
        setFinanceTitleTextColor(true)
        setLongTermPriceError(true)
        error.push("BudgetRef", "LTRAskngPrce")
      }
    } else if (isSTROnly) {

      if (!formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
        setShortTermPriceError(true)
        error.push("BudgetRef", "STRAskngPrce")
      }
    } else if (isSell_And_LTR) {

      if (!formValues?.sellAskngPrice || !formValues?.LTRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.sellAskngPrice) {
        setAskingPriceError(true)
        error.push("BudgetRef", "sellAskngPrice")
      }
      if (!formValues?.LTRAskngPrce) {
        setLongTermPriceError(true)
        error.push("BudgetRef", "LTRAskngPrce")
      }
    } else if (isSell_And_STR) {

      if (!formValues?.sellAskngPrice || !formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.sellAskngPrice) {
        setAskingPriceError(true)
        error.push("BudgetRef", "sellAskngPrice")
      }
      if (!formValues?.STRAskngPrce) {
        setShortTermPriceError(true)
        error.push("BudgetRef", "STRAskngPrce")
      }
    } else if (isSTR_And_LTR) {
      if (!formValues?.LTRAskngPrce || !formValues?.STRAskngPrce) {
        setFinanceTitleTextColor(true)
      }
      if (!formValues?.LTRAskngPrce) {
        setLongTermPriceError(true)
        error.push("BudgetRef", "LTRAskngPrce")
      }
      if (!formValues?.STRAskngPrce) {
        setShortTermPriceError(true)
        error.push("BudgetRef", "STRAskngPrce")
      }
    }



    if (formValues?.invtryDetails && !textValidations(formValues?.invtryDetails)) {
      setDescriptionError(true)
      error.push("DescriptionRef", "invtryDetails")
    }

    if (error.length == 0) {
      const proprtyPurpose = _.get(formValues, 'proprtyPurpose', [])
      const is_Sell_Selected = _.includes(proprtyPurpose, SELL);
      if (!is_Sell_Selected) {
        handleFormValues({ sellAskngPrice:"" });
      }

      if (type == 'draft') {
        saveInventoryData()
      } else {
        let imageUrl = await handleMapImageUpload();
       

        handleFormValues({
          mapImg: imageUrl,
          cmpldPerctg: 100,
          actvStep: 9,
          isEdit: _.get(formValues, "inventoryId", null) ? true : false,
          isAgentCreated: true,
          isFromIntroduction: isFromIntroduction,
          wantAdId: wantAdId
        });


        setTimeout(() => {

          navigate("/inventory-confirmation",
            {
              state:
                { inventoryId: _.get(formValues, "inventoryId", null), isAgentCreated: true }
            })
        }, 10);
      }
    } else {
      if (error[0] == 'ClientRef') {
        window.scrollTo(0, ClientRef.current.offsetTop);
      } else if (error[0] == 'adTypeRef') {
        window.scrollTo(0, adTypeRef.current.offsetTop);
      } else if (error[0] == 'locationRef') {
        window.scrollTo(0, locationRef.current.offsetTop);
      } else if (error[0] == 'propertyTypeRef') {
        window.scrollTo(0, propertyTypeRef.current.offsetTop);
      } else if (error[0] == 'BudgetRef') {
        window.scrollTo(0, BudgetRef.current.offsetTop);
      } else if (error[0] == 'DescriptionRef') {
        window.scrollTo(0, DescriptionRef.current.offsetTop);
      }

      handleFormValues({ validationErrors: error, updatedAt: Date.now() });
      setDisableButton(false)
      setDraftButtonDisable(false);
    }
  }

  const handleMapImageUpload = async () => {
    const lat = _.get(formValues, "lat", "");
    const lng = _.get(formValues, "lng", "");
    const mapZoom = _.get(formValues, 'mapZoom', DEFAULT_MAP_ZOOM)
    const mapResponse = await createStaticMapImage(lat, lng, mapZoom)
    const preSignedUrlRes = mapResponse ? await createPreSignedUrl(false, _.get(formValues, "s3ImageFolder", "")) : alert('Something went wrong while fetching map image.');
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl
          ? s3FullUrl.split("?")[0]
          : "";
        return imageUrl;
        // handleFormValues({ mapImg: imageUrl })
      }

    }
  };

  function calculatePercentageofCompletion() {
    let percentage = 0;
    if (formValues?.LTRAskngPrce || formValues?.STRAskngPrce || formValues?.sellAskngPrice) {
      if (isSellOnly) {
        if (formValues?.sellAskngPrice) {
          percentage = 100;
        }

      } else if (isSTROnly) {
        if (formValues?.STRAskngPrce) {
          percentage = 100;
        }

      } else if (isLTROnly) {
        if (formValues?.LTRAskngPrce) {
          percentage = 100;
        }

      } else if (isSTR_And_LTR) {
        if (formValues?.STRAskngPrce && formValues.LTRAskngPrce) {
          percentage = 100;
        } else {
          percentage = 80;
        }

      } else if (isSell_And_LTR) {
        if (formValues?.sellAskngPrice && formValues.LTRAskngPrce) {
          percentage = 100;
        } else {
          percentage = 80;
        }

      } else if (isSell_And_STR) {
        if (formValues?.sellAskngPrice && formValues.STRAskngPrce) {
          percentage = 100;
        } else {
          percentage = 80;
        }

      }
    } else {
      percentage = 70;
    }

    return percentage;


  }

  const saveInventoryData = async (isAddInventry) => {
    let userId = getUserId();
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(sessionKey);
    }
    let mapImg = await handleMapImageUpload();
    requestPayLoad.mapImg = mapImg;
    requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
    requestPayLoad.userId = userId;
    if(isAgent && !isPremiumUser){
      requestPayLoad.isAgentAsSeller = true;
    }

    if (isAddInventry) {
      requestPayLoad.IsSavedDraft = false;
      requestPayLoad.IsPublished = true;
      delete requestPayLoad.tlText;
      delete requestPayLoad.prptyTypeTxt;
      delete requestPayLoad.wantAdValErrors;
    } else {
      requestPayLoad.IsSavedDraft = true;
      requestPayLoad.IsPublished = false;
      requestPayLoad.actvStep = 9;
      requestPayLoad.cmpldPerctg = calculatePercentageofCompletion();
      requestPayLoad.isAgentCreated = true
    }

    saveMemberInventoryData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if (requestPayLoad.apprOnFile || requestPayLoad.insReport || requestPayLoad.sellerFin || requestPayLoad.floorPlan || requestPayLoad.floodCert || requestPayLoad.tlText) {
            saveStrengthenData(responseResult.inventoryId);
          } else {
            if (isAddInventry) {
              setLocalStorage(INVENTORY_KEY, {
                inventoryId: _.get(responseResult, "inventoryId", ""),
                showSuccessModal: true // This key-value is for showing the success pop up for the first time in details page after save 
              });
              navigate(pageURLs.inventoryListing, { state: { showDraft: true } })
            } else {
              removeLocalStorage(sessionKey);
              removeLocalStorage("PhotosAfterDelete")
              navigate(pageURLs.inventoryListing, { state: { showDraft: true } })
            }
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

  };

  const saveStrengthenData = async (InventoryId) => {
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(sessionKey);
    }
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const userId = _.get(userDetails, "userInfo.userId", null);
    requestPayLoad.inventoryId = InventoryId;
    requestPayLoad.isStrengthen = true;
    await saveInventoryStrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setLocalStorage(INVENTORY_KEY, {
            inventoryId: _.get(responseResult, "inventoryId", ""),
            showSuccessModal: true // This key-value is for showing the success pop up for the first time in details page after save 
          });
          removeLocalStorage(sessionKey);
          removeLocalStorage("PhotosAfterDelete")
          navigate(pageURLs.inventoryListing, { state: { showDraft: true } })
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };


  return (
    <>
      <div className="outer-wrap position-relative mw-1168" ref={ClientRef}>
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">

            <div className="edit-want-ad-wrap mw-640">
              <h2>{_.get(formValues, "inventoryId", null) ? "Edit" : "Add"} property</h2>
              <h6 className="mb-40">Start introducing your client's inventory to people looking for whatcha have.{isPremiumUser ?  " Any personal information about your client can only been seen by you." : "" }</h6>

              {isPremiumUser && <ClientSelection
                clientId={formValues?.clientId}
                isAgentAsSeller={formValues?.isAgentAsSeller}
                sessionData={sessionData}
                handleFormValues={handleFormValues}
                clientSelectionError={clientSelectionError}
              />}
              <div className={(propertyTypeError || subPropertyTypeError) ? "wantad-type-wrap select-type-wrap red-marked-wrap" :
                "wantad-type-wrap select-type-wrap"} ref={propertyTypeRef}>

                <h6 className={(propertyTypeError) && "red-marked-subheading"}>Property type<span>*</span></h6>

                <InventoryPropertyTypes
                  handleFormValues={handleFormValues}
                  propertyType={propertyType}
                  adType={_.get(formValues, "adType", null)}
                  clearPreviousStates={clearPreviousStates}
                  prptySubType={propertySubType}
                  propertyTypeError={propertyTypeError}
                  subPropertyTypeError={subPropertyTypeError}
                />

              </div>
              {propertyType &&
                <div className={locationError ? "wantad-location-wrap mb-3 red-marked-wrap" : "wantad-location-wrap mb-3"} ref={locationRef}>
                  <h6 className={locationError && "red-marked-heading"}>Location *</h6>
                  <h6 className={locationError ? "sub-head red-marked-subhead" : "sub-head"}> If your property doesn't have an address, enter a nearby street name and adjust the pin on the map.</h6>


                  {(propertyType == IS_LAND) ? (
                    <InventoryLotLandLocation
                      handleFormValues={handleFormValues}
                      locationAddress={_.get(formValues, "locAddress", null)}
                      acreage={_.get(formValues, "acreage", null)}
                      lotType={convertToNumber(_.get(formValues, "lotType", null))}
                      roadType={convertToNumber(_.get(formValues, "roadType", null))}
                      isPowerAccess={_.get(formValues, "isPowerAccess", null)}
                      waterSuply={convertToNumber(
                        _.get(formValues, "waterSuply", null)
                      )}
                      wasteWtrSys={convertToNumber(
                        _.get(formValues, "wasteWtrSys", null)
                      )}
                      isApprovedStrctr={_.get(formValues, "isApprovedStrctr", null)}
                      isInFloodPlain={_.get(formValues, "isInFloodPlain", null)}
                      isAgentCreated={true}
                      s3Imagefolder={_.get(formValues, "s3ImageFolder", false)}
                      lat={_.get(formValues, "lat", null)}
                      lng={_.get(formValues, "lng", null)}
                      mapZoom={_.get(formValues, "mapZoom", null)}
                      isPrvtDrInst={_.get(formValues, "isPrvtDrInst", null)}
                      isDrldWelInst={_.get(formValues, "isDrldWelInst", null)}
                      isDrldWelAppr={_.get(formValues, "isDrldWelAppr", null)}
                      isSepTankInst={_.get(formValues, "isSepTankInst", null)}
                      isSepTankAppr={_.get(formValues, "isSepTankAppr", null)}
                      // lotsize = {_.get(formValues, "lotsize", null)}
                      acre_Sqft={_.get(formValues, "acre_Sqft", null)}
                      validationErrors={_.get(formValues, 'validationErrors', null)}
                      mapType={_.get(formValues, "mapType", null)}
                      locationError={locationError}
                      sessionKey={sessionKey}

                    />
                  ) : (
                    <InventoryLocationWithMustHaves
                      handleFormValues={handleFormValues}
                      locationAddress={_.get(formValues, "locAddress", null)}
                      noBedRm={removeDecimalZeros(_.get(formValues, "noBedRm", null))}
                      noBathRm={removeDecimalZeros(_.get(formValues, "noBathRm", null))}
                      sqFt={removeDecimalZeros(_.get(formValues, "sqFt", null))}
                      stories={_.get(formValues, "stories", null)}
                      lotSize={_.get(formValues, "lotSize", null)}
                      grgeSpots={_.get(formValues, "grgeSpots", null)}
                      adaAccess={_.get(formValues, "adaAccess", false)}
                      IsNewConstruction={_.get(formValues, "IsNewConstruction", false)}
                      propertyType={propertyType}
                      propertySubType={propertySubType}
                      isAgentCreated={true}
                      s3Imagefolder={_.get(formValues, "s3ImageFolder", false)}
                      lat={_.get(formValues, "lat", null)}
                      lng={_.get(formValues, "lng", null)}
                      mapZoom={_.get(formValues, "mapZoom", null)}
                      neighborHood={_.get(formValues, "neighborHood", null)}
                      highSchool={_.get(formValues, "highSchool", null)}
                      yearBuilt={_.get(formValues, "yearBuilt", null)}
                      validationErrors={_.get(formValues, 'validationErrors', null)}
                      // errorUpdatedAt = {_.get(formValues, "updatedAt", null)}
                      isFromAgentPage={true}
                      mapType={_.get(formValues, "mapType", null)}
                      locationError={locationError}
                      sessionKey={sessionKey}
                    />
                  )}

                </div>
              }
              {propertyType &&
                <div className={typeError ? "wantad-type-wrap select-type-wrap label-pad-adjust red-marked-wrap"
                  : "wantad-type-wrap select-type-wrap label-pad-adjust"} ref={adTypeRef}>

                  <h6 className={typeError && "red-marked-subheading"}>Type <span>*</span></h6>
                  <InventoryPropertyPurpose
                    handleFormValues={handleFormValues}
                    propertyPurpose={_.get(formValues, "proprtyPurpose", '')}
                    alwLrgDogs={_.get(formValues, "alwLrgDogs", null)}
                    alwSmlDogs={_.get(formValues, "alwSmlDogs", null)}
                    alwCats={_.get(formValues, "alwCats", null)}
                    alwPets={_.get(formValues, "alwPets", null)}
                    accsPvtBthRm={_.get(formValues, "accsPvtBthRm", null)}
                    pvtEntrnce={_.get(formValues, "pvtEntrnce", null)}
                    clearPreviousStates={clearPreviousStates}
                    propertyType={propertyType}
                    propertySubType={propertySubType}
                    isConfirmOwner={_.get(formValues, "isConfirmOwner", null)}
                    isConfirmPropLoc={_.get(formValues, "isConfirmPropLoc", null)}
                    noOfBeds={_.get(formValues, "noOfBeds", null)}
                    errors={_.get(formValues, 'validationErrors', null)}
                    typeError={typeError}
                  />

                </div>
              }

              {(propertyType && invType) && (
                <NiceToHave
                  selectedNiceToHave={formValues?.nice2Have}
                  selectedniceToHavesList={selectedniceToHavesList}
                  propertyType={formValues?.prprtyType}
                  adType={formValues?.adType}
                  proprtyPurpose={formValues?.proprtyPurpose}
                  isFromInventory={true}
                  handleFormValues={handleFormValues}
                  title="Features" />
              )}
              {(propertyType && invType) &&
                <div className={financeTitleTextColor ? "finance-wrap mb-2 red-marked-wrap" : "finance-wrap mb-2"} ref={BudgetRef}>
                  <h6 className={financeTitleTextColor && "red-marked-subhead"}>Finance</h6>
                  <h6 className={financeTitleTextColor ? "sub-head red-marked-subhead" :
                    "sub-head"}>You can adjust your asking price when you send each introduction. Our goal is to match your property to want-ads.</h6>

                  <InventoryBudget
                    handleFormValues={handleFormValues}
                    sellAskngPrice={_.get(formValues, "sellAskngPrice", "")}
                    LTRAskngPrce={_.get(formValues, "LTRAskngPrce", "")}
                    STRAskngPrce={_.get(formValues, "STRAskngPrce", "")}
                    isCompnsteAgent={_.get(formValues, "isCompnsteAgent", "")}
                    isCompnsteAgentLTR={_.get(formValues, "isCompnsteAgent_LTR", "")}
                    isCompnsteAgentSTR={_.get(formValues, "isCompnsteAgent_STR", "")}
                    propertyPurpose={_.get(formValues, "proprtyPurpose", '')}
                    salesPercnt={_.get(formValues, "salesPercnt", null)}
                    salesPercntLTR={_.get(formValues, "salesPercnt_LTR", null)}
                    salesPercntSTR={_.get(formValues, "salesPercnt_STR", null)}
                    isAgentCreated={true}
                    askingPriceError={askingPriceError}
                    shortTermPriceError={shortTermPriceError}
                    longTermPriceError={longTermPriceError}
                  />
                </div>
              }


              {(propertyType && invType) &&
                <InventoryFileUpload
                    handleFormValues={handleFormValues}
                    photos={_.get(formValues, "photos", [])}
                    s3Imagefolder={_.get(formValues, "s3ImageFolder", '')}
                    isFolderCreated={_.get(formValues, "isFolderCreated", '')}
                    inventoryId={_.get(formValues, "inventoryId", '')}
                    sessionKey={sessionKey}

                  />

              }

              {(isSellOnly ||
                isAllType ||
                isSell_And_LTR ||
                isSell_And_STR || isLTROnly) &&
                <>
                  <h6>Timeline</h6>
                  <InventoryTimeLine
                    handleFormValues={handleFormValues}
                    timeLine={_.get(formValues, "timeLine", null)}
                    isEdit={true}
                  />
                  <h6>Additional details </h6>
                  <InventoryDocuments
                    handleFormValues={handleFormValues}
                    apprOnFile={_.get(formValues, "apprOnFile", null)}
                    insReport={_.get(formValues, "insReport", null)}
                    sellerFin={_.get(formValues, "sellerFin", null)}
                    floorPlan={_.get(formValues, "floorPlan", null)}
                    floodCert={_.get(formValues, "floodCert", null)}
                    assMortgage={_.get(formValues, "assMortgage", null)}
                    invType={invType}
                    isEdit={true}
                  />
                </>

              }

              {(propertyType && invType) &&
                <div className={descriptionError ? "description-wrap red-marked-wrap" : "description-wrap"} ref={DescriptionRef}>
                  <h6 className={descriptionError && "red-marked-subheading"}>Description</h6>
                  <InventoryPropertyDetails
                    handleFormValues={handleFormValues}
                    invtryDetails={_.get(formValues, "invtryDetails", "")}
                    descriptionError={descriptionError}
                  />
                  {/* <div className={confirmationColor ? "field-wrap red-marked-field-wrap mt-4 mb-0" : "field-wrap  mt-4 mb-0"}>
                    {_.get(formValues, "proprtyPurpose", '') && _.includes(_.get(formValues, "proprtyPurpose", ''), SELL) && (
                      <label
                        className={confirmationColor ? "form-check-label checkbox-label d-flex align-items-center red-marked-fldlabel" : "form-check-label checkbox-label d-flex align-items-center"}
                        htmlFor={confirmOwnerChkBx}
                      >
                        <input
                          className="form-check-input flex-shrink-0"
                          type="checkbox"
                          id={confirmOwnerChkBx}
                          checked={_.get(formValues, "isConfirmOwner", '')}
                          onChange={(e) => handleCheckBoxItemClick(e)}
                        />
                        You are acknowledging you are in contract to represent the seller of this property and are approved to introduce this property to potential buyers
                      </label>
                    )}
                  </div> */}
                </div>
              }
            </div>

            <div className="edit-want-ad-btnwrap mt-4">

              {!_.get(formValues, "inventoryId", null) &&
                <Button variant="" className="btn-tertiary me-2" disabled={draftButtonDisable} onClick={() => handleSave("draft")}>
                  Save As Draft
                </Button>
              }

              <Button variant="primary" className="btn" disabled={disableButton} onClick={() => handleSave("save")} >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
      <PremiumPlanModal
        userDetails={userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}
        fromInventoryListing={true}
        fromInventoryCreation={true}
        showPlanModal={PremiumPlanModalShow}
      />
    </>
  );
};

export default AgentCreateOrEditInventory;
