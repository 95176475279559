import { useEffect, useState } from "react";
import InputGroupControl from "./Common/InputGroup";
import { capitalizeString } from "../actions/common";
import { getGeoLocation } from "../services/commonServices";
import { getLocalStorage, getUserId, getUserType, removeLocalStorage } from "../helpers/common";
import { Button } from "react-bootstrap";
import HowItWorksTutorial from "./Common/howItWorksTutorial";
import { BUY, USER_TYPE_AGENT } from "../constants/common";
const SearchLocationScreen = (props) => {
  const {setUserLocation, checkGitchaAvailableInLoc, adType} = props;
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("")
  const userLocation = getLocalStorage("userLocation");
  const [formattedAddress, setFormattedAddress] = useState()
  const locationString = getLocalStorage("LocationString");
  const userId = getUserId();
  const userType = getUserType();
  const isAgent = userType===USER_TYPE_AGENT ? true : false;
  useEffect(()=>{
  if(locationString != null && userId ){
    const reqPayload = `address=${capitalizeString(locationString)}&components=country:US`;
    getAddresseDetails({ reqPayload: reqPayload });   
  }
  }, [locationString, userId])
  const handleLocationChange = (val) => { 
    const value = capitalizeString(val);
    const reqPayload = `address=${value}&components=country:US`;
    if (value.trim() !== "") {
      getAddresseDetails({ reqPayload: reqPayload });
    } else {
      clearAddressList();
    }
    if (value && value.trim() !== "") {
      setLocation(value);
  } else {
      clearLocationStates();
    }     
  };

  const clearLocationStates = () => {
   
    if(!locationString){
      removeLocalStorage("searchedLocation")
      // if(gitchaNotAvailble){
      //   setUserLocation(null)  
      // }
      // else {
          setFormattedAddress(_.get(userLocation, "formatted_address", null))
          setUserLocation(userLocation)
        // }
      setLocation("")
      }
    };

    const getAddresseDetails = async ({ eventType, reqPayload }) => {
      await getGeoLocation(reqPayload).then((data) => {
        const results = _.get(data, "results");
        if (results?.length > 0) {
          const formatted_address = _.get(results?.[0], 'formatted_address', '')
            if(results?.length > 0 && formatted_address!== "United States"){
              if(locationString){
                handleItemSelection(results[0]);
                removeLocalStorage("LocationString")
              }
              else {
                setLocationList(results);
              }
            }
        } 
      });
    };    

    const clearAddressList = () => {
      setLocationList([]);
      clearLocationStates();
    };

    const handleItemSelection = async (address) => {
      let city, state, county;
      removeLocalStorage("searchedLocation")
      const geometry = _.get(address, "geometry", "");
      const locBounds = _.get(geometry, "location", "");      
      const formatted_address = _.get(address, "formatted_address", "");
      const firstAddressComponent = _.get(address, "address_components", "");
      const stateSearched = firstAddressComponent.filter(function (ca) {
        return ca.types?.[0] === "administrative_area_level_1";
      });
      if (stateSearched.length) {
         state = _.get(stateSearched?.[0], "short_name", "");
      }
      const locality = firstAddressComponent.filter(function (ca) {
        return ca.types?.[0] === "locality";
      });
      if (locality.length) {
         city = _.get(locality?.[0], "long_name", "");
      }
      const countyList = firstAddressComponent.filter(function (ca) {
        return ca.types?.[0] === "administrative_area_level_2";
      });
    
      if (countyList.length) {
        county = _.get(countyList?.[0], "short_name", "");
      }
      if(city!= undefined){setFormattedAddress(city+", "+state)}
      else{setFormattedAddress(formatted_address)}
      setLocation(city!=undefined ? city+", "+state : formatted_address);  
      const locationObject = {latitude:_.get(locBounds, "lat", ""), longitude:_.get(locBounds, "lng", ""),city:city,state:state, formatted_address:city!=undefined ? city+", "+state : formatted_address}
      checkGitchaAvailableInLoc(city, state, county,formatted_address, locationObject);
      clearAddressList();
    };

  return (
    <>
      <div className="agent-home-bg">
        <div className="search_wrap">
          <h2>See what {adType===BUY ? "buyers" : "renters"} are looking for near you.</h2>

          <div>
            <div class="search_field_input  input-group position-relative">     

              {location != "" ? (
                    <Button
                      variant=""
                      className="search-clear-btn"
                      onClick={() => clearAddressList()}
                    ></Button>
                  ) : (
                    ""
                  )}
                  <InputGroupControl
                    inputGroupClass="form-control pr-0"
                    ariaLabel="Example"
                    ariaDescribedBy="basic-addon3"
                    placeholder="Enter an address, city, or ZIP code"
                    errorMessage=""
                    value={location ? location : ""}
                    iconClass={"search-icon"}
                    isIconEnable={true}
                    iconId="button-addon3"
                    onChange={(e) => {
                      handleLocationChange(e.target.value);
                    }}
                  />
                  {locationList.length > 0 ? (
                    <div className="location-result-wrap mw-100">
                      <ul>
                        {locationList?.map((address, i) => {
                          return (
                            <li key={i}>
                              <a
                                role="button"
                                href={void 0}
                                onClick={(e) => {
                                  handleItemSelection(address);
                                }}
                              >
                                {_.get(address, "formatted_address", "")}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}  
            </div>
          </div>
        </div>
        <HowItWorksTutorial
        isAgent={isAgent}
        />         
      </div>
    </>
  );
};

export default SearchLocationScreen;
