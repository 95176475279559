import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ToolTip = (props) => {
  const { title } = props;
  const renderTooltip = (params) => (
    <Tooltip id="button-tooltip" container="body" boundary="window" tooltip-append-to-body="true" {...params}>
     <div dangerouslySetInnerHTML={{ __html: params}} />
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 2000 }}
      overlay={renderTooltip( title )}
      
      boundary="window"
      
      
    >
      <span className="price-info-icon top-pos-5">
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99967 1.8335C4.32634 1.8335 1.33301 4.82683 1.33301 8.50016C1.33301 12.1735 4.32634 15.1668 7.99967 15.1668C11.673 15.1668 14.6663 12.1735 14.6663 8.50016C14.6663 4.82683 11.673 1.8335 7.99967 1.8335ZM7.49967 5.8335C7.49967 5.56016 7.72634 5.3335 7.99967 5.3335C8.27301 5.3335 8.49967 5.56016 8.49967 5.8335V9.16683C8.49967 9.44016 8.27301 9.66683 7.99967 9.66683C7.72634 9.66683 7.49967 9.44016 7.49967 9.16683V5.8335ZM8.61301 11.4202C8.57967 11.5068 8.53301 11.5735 8.47301 11.6402C8.40634 11.7002 8.33301 11.7468 8.25301 11.7802C8.17301 11.8135 8.08634 11.8335 7.99967 11.8335C7.91301 11.8335 7.82634 11.8135 7.74634 11.7802C7.66634 11.7468 7.59301 11.7002 7.52634 11.6402C7.46634 11.5735 7.41967 11.5068 7.38634 11.4202C7.3521 11.34 7.33397 11.254 7.33301 11.1668C7.33301 11.0802 7.35301 10.9935 7.38634 10.9135C7.41967 10.8335 7.46634 10.7602 7.52634 10.6935C7.59301 10.6335 7.66634 10.5868 7.74634 10.5535C7.90865 10.4868 8.0907 10.4868 8.25301 10.5535C8.33301 10.5868 8.40634 10.6335 8.47301 10.6935C8.53301 10.7602 8.57967 10.8335 8.61301 10.9135C8.64634 10.9935 8.66634 11.0802 8.66634 11.1668C8.66634 11.2535 8.64634 11.3402 8.61301 11.4202Z" fill="#4A4C4F"/>
</svg>
      </span>
    </OverlayTrigger>
  );
};
export default ToolTip;
