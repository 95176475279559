import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import { getNotificationSettings } from "../actions/userprofile";
import { saveNotifications } from "../services/userprofileService";
import { getUserId } from "../helpers/common";


const Notifications = ({...props}) => {
    const dispatch = useDispatch();
    const {userDetails} = props
    const userNotificationSettings = useSelector((state)=>state.userProfile.notificationSettings)
    const [introductionNotification, setIntroductionNotification] = useState(true)
    const [accountActivityNotification , setAccountActivityNotification] = useState(false)
    const [messageNotification, setMessageNotification] = useState(false)
    const [featureNotification , setFeatureNotification] = useState( false)
    const [matchNotification, setMatchNotification] = useState(false)
    const userId = getUserId();
    
    useEffect(()=>{
        dispatch(getNotificationSettings())
    },[userDetails?.UserId])

    useEffect(()=>{
        if(userNotificationSettings){
        setAccountActivityNotification(userNotificationSettings[0]?.AccountActivity===1 ? true : false)
        setMessageNotification(userNotificationSettings[0]?.Messages===1? true : false)
        setFeatureNotification(userNotificationSettings[0]?.ProductAnnouncements===1 ? true : false)
        setMatchNotification(userNotificationSettings[0]?.Matches===1 ? true : false)
    }
    },[userNotificationSettings])


    const handleSubmit = async (data) => {   
        await saveNotifications(data).then((response)=>{
            if (response.errorCode == 0) {
                toast.dismiss()
                toast.success("Notification settings saved successfully.")                
              } else {
                console.log("Something went wrong");
              }
        }).catch((err) => {
            console.log("Error", err);
          }); 
    }

    return (
        <div className="notification-wrap">
        <div className="title-sec">
          <h4>Email notifications</h4>
        </div>
        <div className="field-wrap">
        <label className="form-check-label checkbox-label">
            <p>Introductions</p>
            <span>Get notified when another member sends me an introduction</span>
            <input
            className="form-check-input"
            type="checkbox"
            checked={introductionNotification}
            disabled={true}
            id="introductioncheck"
            />
        </label>
        </div>
        <div className="field-wrap">
        <label className="form-check-label checkbox-label">
            <p>Account activity</p>
            <span>Get notified about important account activity</span>
            <input
            className="form-check-input"
            type="checkbox"
            checked={accountActivityNotification}
            onChange={()=>{
                setAccountActivityNotification(!accountActivityNotification);
                const data = {
                    "Introductions" : true,
                    "AccountActivity" : !accountActivityNotification,            
                    "Messages" : messageNotification,
                    "ProductAnnouncements" : featureNotification,
                    "Matches" : matchNotification
                }
                 handleSubmit(data)
            }}
            id="activitycheck"
            />
        </label>
        </div>
        <div className="field-wrap">
        <label className="form-check-label checkbox-label">
            <p>Messages</p>
            <span>Get notified when another member messages me directly</span>
            <input
            className="form-check-input"
            type="checkbox"
            onChange={()=>{
                setMessageNotification(!messageNotification);
                const data = {
                    "Introductions" : true,
                    "AccountActivity" : accountActivityNotification,            
                    "Messages" : !messageNotification,
                    "ProductAnnouncements" : featureNotification,
                    "Matches" : matchNotification
                }
                 handleSubmit(data)
            }}
            checked={messageNotification}
            id="messagecheck"
            />
        </label>
        </div>
        <div className="field-wrap">
        <label className="form-check-label checkbox-label">
            <p>Product announcements</p>
            <span>Get notified about new features on Gitcha</span>
            <input
            className="form-check-input"
            type="checkbox"
            onChange={()=>{
                setFeatureNotification(!featureNotification);
                const data = {
                    "Introductions" : true,
                    "AccountActivity" : accountActivityNotification,            
                    "Messages" : messageNotification,
                    "ProductAnnouncements" : !featureNotification,
                    "Matches" : matchNotification
                }
                 handleSubmit(data)
            }}
            checked={featureNotification}
            id="productcheck"
            />
        </label>
        </div>
        <div className="field-wrap">
        <label className="form-check-label checkbox-label">
            <p>Matches</p>
            <span>Get notified about new matches to your property</span>
            <input
            className="form-check-input"
            type="checkbox"
            onChange={()=>{
                setMatchNotification(!matchNotification);
                const data = {
                    "Introductions" : true,
                    "AccountActivity" : accountActivityNotification,            
                    "Messages" : messageNotification,
                    "ProductAnnouncements" : featureNotification,
                    "Matches" : !matchNotification
                }
                 handleSubmit(data)
            }}
            checked={matchNotification}
            id="productcheck"
            />
        </label>
        </div>
    </div>
    )

}

export default Notifications