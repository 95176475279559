import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import DeletionEmailResentModal from "./DeletionEmailResentModal";
import _ from "lodash";
import { resendEmail } from "../../../services/signupService";

const CheckEmailDeletionModal = (props) => {
  const { email, process } = props;
  const [showResentPopup, setResentPopup] = useState(false);
  const [showCheckEmailPopup, setShowCheckEmailPopup] = useState(true);

  const resentEmail = () => {
    const userInfo = {
      Email: email,
      Process: process,
    };
    resendEmail(userInfo).then((response) => {
      if (response) {
        if (_.get(response, "errorCode", false) === 0) {
          showResetSuccessPopup();
        }
      }
    });
  };


  const showResetSuccessPopup = () => {
    setShowCheckEmailPopup(false);
    setResentPopup(true);
  };

  return (
    <>
      <Modal
        show={showCheckEmailPopup}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >       
        <Modal.Body className="pt-32 pb-32">          
          <h2 className="text-center">Check your email</h2>
          <Row>
            <Col xl={12}>
              <p className="mb-2 text-center">
                We've sent links to{" "} {process==='deletion' ? 'your email account ': <strong>{email}.</strong>}
                {" "}
                where you can confirm {process === 'deletion' ? 'account deletion' : 'email address change'}.
              </p>
              {process !== 'deletion' &&  <p className="text-center">
                Didn't get an email?{" "}
                <strong>
                  <a href="#" onClick={resentEmail}>
                    {" "}
                    {"Resend email."}
                  </a>
                </strong>
              </p>}
            </Col>
            {process==='deletion' && <Button variant="primary" className="w-100 mb-4" onClick={()=>props.hide()}>
          OK
        </Button>}
          </Row>
        </Modal.Body>        
      </Modal>
      <DeletionEmailResentModal
        show={showResentPopup}
        centered
        email={email}        
      ></DeletionEmailResentModal>
    </>
  );
};
export default CheckEmailDeletionModal;
