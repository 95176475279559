import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useSearchParams,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import "./styles/settings.scss";
import "./styles/allintroductions.scss";
import "./styles/style-payment.css";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import CreateWantAd from "./pages/CreateWantAd";
import WantadDetails from "./pages/WantadDetails";
import StrengthenMemberWantAd from "./pages/StrengthenMemberWantAd";
import StrengthenInventory from "./pages/StrengthenInventory";
import "./App.css";
// import { Provider } from "react-redux";
// import setupStore from "./store";
import { pageURLs } from "./constants/pageURLs";
import {
  getCookie,
  getLocalStorage,
  getUserId,
  loggedInUser,
  removeHtmlClassName,
  removeLocalStorage,
  s3ImageBaseFolder,
  setHtmlClassName,
  setLocalStorage,
} from "./helpers/common";
import ShowWantAd from "./public/ShowWantAd";
import ShowWantAdMobile from "./public/ShowWantAdMobile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AgentCreateOrEditWantAd from "./pages/AgentCreateOrEditWantAd";
import CreateInventory from "./pages/CreateInventory";
import ViewClientDetails from "./pages/ViewClientDetails";
import AgentDashboard from "./pages/AgentDashboard";
import MemberEditWantAd from "./pages/MemberEditWantAd";
import HomeLoadingSkeleton from "./pages/HomeLoadingSkeleton";
import GitchaPrivacyPolicy from "./pages/GitchaPrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";

import MyWantAdListing from "./pages/MyWantAdListing";
import AgentWantAdConfirmation from "./pages/AgentWantAdConfirmation";
import AgentCreateOrEditWantAdDraftListing from "./pages/AgentCreateOrEditWantAdDraftListing";
import MemberInventoryDetails from "./pages/MemberInventoryDetails";
import MyFavorites from "./pages/MyFavorites";
import FavoriteClients from "./pages/FavoriteClients";
import ViewInventory from "./pages/ViewInventory";
import MyInventoryListing from "./pages/MyInventoryListing";
import Settings from "./pages/Settings";
import MemberEditInventory from "./pages/MemberEditInventory";
import InventoryConfirmation from "./pages/InventoryConfirmation";
import AgentCreateOrEditInventory from "./pages/AgentCreateOrEditInventory";
import AgentCreateOrEditInventoryDraftListing from "./pages/AgentCreateOrEditInventoryDraftListing";
import GitchaMemberWelcome from "./pages/GitchaMemberWelcome";
import AllIntroductions from "./pages/AllIntroductions.js";
import ReceiveIntroduction from "./pages/ReceiveIntroduction";
import PassCodeRequest from "./pages/PassCodeRequest";
import InventoryAllSentIntroductions from "./pages/InventoryAllSentIntroductions";
import WantadDetailsSkeleton from "./pages/WantadDetailsSkeleton";
import Messaging from "./pages/Messaging";
import GitchaInventoryAddOnboard from "./pages/GitchaInventoryAddOnboard";
import "./mobile/styles/mobile-variables.scss";
import "./mobile/styles/mobile-style.scss";
import "./mobile/styles/mobile-style02.scss";
import "./mobile/styles/clients.scss";
import CreateAWantAdNext from "./mobile/pages/CreateAWantAdNext.js";
//import ReviewPlanPremium from "./mobile/components/common/UserPlan/ReviewPlan.js";
// import Mobile_Home from "./mobile/pages/Home_old.js";
// import SelectPlan from "./mobile/components/Common/UserPlan/AgentPlan.js";
import WelcomeToPremiumSetup from "./mobile/components/Onboarding/AgentProfile.js";
// import PlansAndPricing from "./mobile/components/common/UserPlan/MemberPlan.js";
import CompleteYourProfile from "./mobile/components/Onboarding/MemberProfile.js";
//import WelcomeToGithaMobileFlow from "./mobile/components/onboarding/UserType.js";
import WelcomeToGitchaMobileFlowFirst from "./mobile/components/Onboarding/AgentTitle.js";
// import ViewAd from "./mobile/pages/ViewAd.js";
import ResetPassword from "./mobile/pages/ResetPassword.js";
import WantAdFavorites from "./mobile/pages/WantAdFavorites.js";

import StrengthenWantAd from "./mobile/pages/StrengthenWantAd.js";
import UserRegister from "./mobile/pages/UserRegister.js";

import ProDashboard from "./mobile/pages/ProDashboard";
import AgentClientsListing from "./mobile/pages/AgentClientListing";

// import ClientManagement from "./mobile/pages/ClientManagement.js";
// import MyClients from "./mobile/components/clientManagement/ClientsListing.js";
import ViewClient from "./mobile/pages/ViewClient.js";
import ViewClientWantAd from "./mobile/pages/ViewClientWantAd.js";
import ViewInventoryClient from "./mobile/pages/ViewInventoryClient.js";
import WantAdListing from "./mobile/pages/WantAdListing";
import ClientNotesListing from "./mobile/pages/ClientNotesListing.js";
import MobileHome from "./mobile/pages/Home.js";

import ViewAd from "./mobile/pages/ViewAd.js";
import SettingsMob from "./mobile/pages/Settings.js";

import MemberViewProperty from "./mobile/pages/MemberViewProperty.js";

import InventoryMemberAddProperty from "./mobile/pages/InventoryMemberAddProperty.js";

import ClientFavorites from "./mobile/pages/ClientFavorites";
import AccountSettings from "./mobile/pages/AccountSettings.js";
import ProfileAgentUserPremiumEdit from "./mobile/pages/ProfileAgentUserPremiumEdit.js";

import SettingsEditName from "./mobile/pages/SettingsEditName.js";
import SettingsEditEmail from "./mobile/pages/SettingsEditEmail.js";
import SettingsCheckEmail from "./mobile/pages/SettingsCheckEmail.js";

import AddPaymentMethod from "./mobile/pages/AddPaymentMode.js";
import SettingsDeleteAccount from "./mobile/pages/SettingsDeleteAccount.js";

import PaymentHistory from "./mobile/pages/PaymentHistory.js";

import EmailNotification from "./mobile/pages/EmailNotification.js";

import TutorialProfileSetup from "./mobile/pages/TutorialProfileSetup.js";
import InventoryTutorialSetup from "./mobile/pages/InventoryTutorialSetup.js";
import InventoryAllIntroductions from "./mobile/pages/InventoryIntroductions.js";

import {
  ACESS_CODE,
  AGENT_INTRODUCTION,
  AGENT_INVENTORY_CREATE_KEY,
  AGENT_INVENTORY_EDIT_KEY,
  AGENT_WANTAD_KEY,
  INVENTORY_KEY,
  MEMBER_INTRODUCTION,
  MEMBER_INVENTORY_EDIT_KEY,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
  WANT_AD_KEY_EDIT,
} from "./constants/common";
import {
  EMAIL_VERIFICATION,
  RESET_PWD_ACTION,
} from "./constants/onBoarding.js";
import { convertToNumber } from "./helpers/validations.js";
import Inapp from "./mobile/pages/Inapp.js";
import ReceiveIntroductionNotification from "./mobile/pages/ReceiveIntroductionNotification.js";
import ManageInventory from "./mobile/pages/ManageInventory.js";
import HandleViewWantAd from "./mobile/pages/HandleViewWantAd.js";
import AddPropertyInInventory from "./mobile/pages/AddPropertyInInventory.js";
import StrengthenInventoryResponsive from "./mobile/pages/StrengthenInventory.js";
import HandleMessagesScreens from "./mobile/pages/HandleMessagesScreens.js";
import ImagesFullGallery from "./mobile/commonComponents/ImagesFullGallery.js";
import {
  initializeChatMessageCountValue,
  setChatMessageCount,
  setChatMessages,
  setInappNotificationCount,
  setReceiveIntroCount,
} from "./reducers/unReadStatus.js";
import { db } from "./firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  or,
  doc,
  getDoc,
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import { loginOrSignUp } from "./helpers/message.js";
import { getInAppNotications, getIntroductions } from "./services/introductionService.js";
import { setsseData } from "./reducers/sseData.js";
import BuyerListingCreateWantAdForm from "./form/BuyerListingCreateWantAdWeb.js";
import CreateAdForm from "./mobile/pages/CreateAdForm.js";
import BuyerListingCreateWantAdFormMob from "./form/BuyerListingCreateWantAdMob.js";
import FormSubmissionSuccess from "./form/FormSumbissionSuccess.js";
import FormSubmissionSuccessMob from "./form/FormSubmissionSuccessMob.js";
import InvestorDashboard from "./pages/InvestorDashboard.js";
import ShareIntroductionWeb from "./form/ShareIntroductionWeb.js";
import ShareIntroductionMob from "./form/ShareIntroductionMob.js";

function removeLocalStorageData() {
  const wantAdORInventoryPages = [
    "/edit-want-ad",
    "/agent-create-want-ad",
    "/want-ad-details",
    "/agent-want-ad-confirmation",
    "/member-edit-Inventory",
    "/agent-create-inventory",
    "/inventory-confirmation",
  ];
  // console.log(!wantAdORInventoryPages.includes(window.location.pathname))
  if (!wantAdORInventoryPages.includes(window.location.pathname)) {
    removeLocalStorage(WANT_AD_KEY_EDIT);
    removeLocalStorage(AGENT_WANTAD_KEY);
    removeLocalStorage(MEMBER_INVENTORY_EDIT_KEY);
    removeLocalStorage(AGENT_INVENTORY_CREATE_KEY);
    removeLocalStorage(AGENT_INVENTORY_EDIT_KEY);
    removeLocalStorage("PhotosAfterDelete");
  }

  // to clear introduction session data on entering home page
  if (
    window.location.pathname == pageURLs.home ||
    window.location.pathname == pageURLs.mobileHome
  ) {
    removeLocalStorage(AGENT_INTRODUCTION);
    removeLocalStorage(MEMBER_INTRODUCTION);
  }
}

const urlRouting = [
  {
    webUrl: pageURLs.agentCreatewantad,
    mobUrl: pageURLs.createWantAdResponsive,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.wantAdPubicDetailsPage,
    mobUrl: pageURLs.wantAdPubicMobileDetailsPage,
    isCheckValidUser: false,
  },
  {
    webUrl: pageURLs.agentDashboard,
    mobUrl: pageURLs.proDashboard,
    isCheckValidUser: true,
  },

  {
    webUrl: pageURLs.createwantad,
    mobUrl: pageURLs.createWantAdResponsive,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.wantAdListing,
    mobUrl: pageURLs.myWantAds,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.inventoryStrengthen,
    mobUrl: pageURLs.strengthenInventoryReponsive,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.home,
    mobUrl: pageURLs.mobileHome,
  },
  {
    webUrl: pageURLs.mobileBaseRoot,
    mobUrl: pageURLs.mobileHome,
  },
  {
    webUrl: pageURLs.mobileRoot,
    mobUrl: pageURLs.mobileHome,
  },
  {
    webUrl: pageURLs.match,
    mobUrl: pageURLs.mobileMatch,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.wantAdDetails,
    mobUrl: pageURLs.viewAd,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.settings,
    mobUrl: pageURLs.mobileSettings,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.messages,
    mobUrl: pageURLs.mobileMessages,
    isCheckValidUser: true,
  },
  {
    webUrl: pageURLs.editWantAdForAgents,
    mobUrl: pageURLs.mobileEditWantAd,
    isCheckValidUser: true
  },
  {
    webUrl : pageURLs.addNewCard,
    mobUrl : pageURLs.mobileAddPaymentMethod,
    isCheckValidUser: true
  }
];

// const store = setupStore();
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const user = loggedInUser();
  const location = useLocation();
  const passCodeStatus = process.env.REACT_APP_PASSCODE_STATUS;
  const pathName = _.get(location, "pathname", null)?.toLowerCase();
  const basicUrl = pathName.split("/")[1];

  const checkUser = (userInfo, targetUrl = null) => {
    const userType = _.get(userInfo, "userType", null);
    const agentTitle = _.get(userInfo, "agentTitle", null);
    const isVerified = _.get(userInfo, "userIsVerified", null);
    const planStatus = _.get(userInfo, "planStatus", null);
    const userStatus = _.get(userInfo, "userStatus", null);
    let isValidUser = true;
    if (userInfo) {
      if (userType) {
        if (isVerified && planStatus && userStatus) {
          isValidUser = true;
        } else {
          if (convertToNumber(userType) === USER_TYPE_AGENT) {
            if (!agentTitle) {
              isValidUser = false;
            } else if (!planStatus) {
              isValidUser = false;
            } else if (!userStatus) {
              isValidUser = false;
            }
          } else {
            if (!planStatus) {
              isValidUser = false;
            } else if (!userStatus) {
              isValidUser = false;
            }
          }
        }
      } else {
        isValidUser = false;
      }
    } else {
      isValidUser = false;
    }
    if (isValidUser) {
      targetUrl && navigate(targetUrl);
    } else {
      if (
        pathName &&
        pathName !== "/privacy-policy" &&
        pathName !== "/terms-of-service" &&
        basicUrl !== "form" && pathName !== pageURLs?.buyerListingConfirmation
      ) {
        navigate(pageURLs.mobileSignIn);
      }
    }
  };

  useEffect(() => {
    const userInfo = _.get(user, "userInfo", null);
    const wantAdId = searchParams.get("wantAdId");
    const InvId = searchParams.get("InvId");
    const ClientId = searchParams.get("ClientId");
    const AdType = searchParams.get("AdType");
    const IsFromMarketing = searchParams.get("IsFromMarketing");
    const Tab = searchParams.get("Tab");
    removeLocalStorageData(); // to remove local storage data
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      if (pathName) {
        // add a class name for messages screen html tag and remove the class name in other pages
        if (pathName == pageURLs.mobileMessages) {
          setHtmlClassName("h-m-100");
        } else {
          removeHtmlClassName("h-m-100");
        }
        const action = searchParams.get("action");

        //For handling email link clicking

        if (action) {
          const userId = searchParams.get("Id");
          const gCode = searchParams.get("g_code");
          if (convertToNumber(action) === RESET_PWD_ACTION) {
            navigate(
              pageURLs.changePassword +
              `?action=${RESET_PWD_ACTION}&Id=${userId}&g_code=${gCode}`
            );
          } else if (convertToNumber(action) === EMAIL_VERIFICATION) {
            navigate(
              pageURLs.mobileSignIn +
              `?action=${EMAIL_VERIFICATION}&Id=${userId}&g_code=${gCode}`
            );
          }
        } // end of email link clicking condition

        //  Redirection to mobile pages if a web url is coming
        else {
          const filteredArray = urlRouting.filter(function (ca) {
            return ca.webUrl === pathName;
          });
          if (filteredArray.length > 0) {
            const mobUrl = _.get(filteredArray[0], "mobUrl", null);
            if (wantAdId && pathName === pageURLs.wantAdPubicDetailsPage) {
              navigate(mobUrl + `?wantAdId=${wantAdId}`);
            } else if (wantAdId && pathName === pageURLs.wantAdDetails) {
              checkUser(userInfo, mobUrl + `?wantAdId=${wantAdId}`);
            }else if(wantAdId && pathName === pageURLs.editWantAdForAgents){
              checkUser(userInfo, mobUrl + `?wantAdId=${wantAdId}`);
            } else if(Tab && pathName === pageURLs.wantAdListing){ // my want ad listing page redirection to mobile view
              checkUser(userInfo, mobUrl + `?Tab=${Tab}`);
            }
             else if (
              AdType &&
              InvId &&
              ClientId &&
              pathName === pageURLs.match
            ) {
              checkUser(
                userInfo,
                mobUrl + `?InvId=${InvId}&ClientId=${ClientId}&AdType=${AdType}`
              );
            } else {
              const isCheckValidUser = _.get(
                filteredArray[0],
                "isCheckValidUser",
                null
              );
              if (mobUrl) {
                if (isCheckValidUser) {
                  checkUser(userInfo, mobUrl);
                } else {
                  if (!IsFromMarketing) {
                    navigate(mobUrl);
                  }
                }
              }
            }
          } else {
            if (
              pathName !== pageURLs.changePassword &&
              pathName !== pageURLs.wantAdPubicMobileDetailsPage &&
              basicUrl !== "form" && pathName !== pageURLs?.buyerListingConfirmation
            ) {
              checkUser(userInfo);
            }
          }
        }
      }
    } else {
      // Public want ad link routing to web url if mobile url is coming
      if (wantAdId && pathName === pageURLs.wantAdPubicMobileDetailsPage) {
        navigate(pageURLs.wantAdPubicDetailsPage + `?wantAdId=${wantAdId}`);
      } else if (wantAdId && pathName === pageURLs.viewAd) {
        checkUser(userInfo, pageURLs.wantAdDetails + `?wantAdId=${wantAdId}`);
      } else if (
        AdType &&
        InvId &&
        ClientId &&
        pathName === pageURLs.mobileMatch
      ) {
        checkUser(
          userInfo,
          pageURLs.match +
          `?InvId=${InvId}&ClientId=${ClientId}&AdType=${AdType}`
        );
      } else {
        if (!userInfo) {
          if (passCodeStatus == 2) {
            const accessToken = getCookie(ACESS_CODE); // to check page redirection if access code enterd or not
            if (
              pathName &&
              pathName !== "/privacy-policy" &&
              pathName !== "/terms-of-service" &&
              basicUrl !== "form" && pathName !== pageURLs?.buyerListingConfirmation
            ) {
              if (!wantAdId) {
                if (accessToken) {
                  navigate(pageURLs.home);
                } else {
                  navigate(pageURLs.accessCodePage);
                }
              }
            }
          }
        }
      }
    }
  }, [pathName]);

  function RequireAuth({ children, roles }) {
    const user = loggedInUser();
    const userInfo = _.get(user, "userInfo", null);
    const usertype = parseInt(_.get(userInfo, "userType", null));
    const planStatus = _.get(userInfo, "planStatus", null);
    const userStatus = _.get(userInfo, "userStatus", null);
    let location = useLocation();

    const userHasRequiredRole = user && roles.includes(usertype) ? true : false;

    if (!user) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    if (!userHasRequiredRole) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    if (!planStatus || !userStatus) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }

  // to check web home page to load mobile / web view
  function CheckMobileView({ children }) {
    const user = loggedInUser();
    const userInfo = _.get(user, "userInfo", null);
    const pathName = _.get(location, "pathname", null)?.toLowerCase();
    const userType = _.get(userInfo, "userType", null);
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      //For handling users from marketing page
      const IsFromMarketing = searchParams.get("IsFromMarketing");
      const action = searchParams.get("action");
      if (IsFromMarketing && pathName == "/home") {
        const userAction = searchParams.get("userAction");
        const utm_source = searchParams.get("utm_source");
        const utm_medium = searchParams.get("utm_medium");
        const utm_campaign = searchParams.get("utm_campaign");

        if (utm_source || utm_medium || utm_campaign) {
          setLocalStorage("utmData", {
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
          });
        }

        const userActionLogin = "LOGIN";
        const userActionSignUp = "SIGNUP";
        const userActionCreateWantAd = "POSTWANTAD";
        const location = searchParams.get("location");
        if (location) {
          setLocalStorage("LocationString", location);
        }

        if (!userType) {
          return (
            <Navigate
              to={pageURLs.mobileSignIn}
              state={{ IsFromMarketing: IsFromMarketing, action: userAction }}
              replace
            />
          );
        } else {
          if (userAction === userActionCreateWantAd) {
            return <Navigate to={pageURLs.createWantAdResponsive} replace />;
          } else if (
            userAction == userActionLogin ||
            userAction == userActionSignUp
          ) {
            return <Navigate to={pageURLs.mobileHome} replace />;
          } else {
            return <Navigate to={pageURLs.mobileHome} replace />;
          }
        }
      } else {
        if (!userType) {
          return <Navigate to={pageURLs.mobileSignIn} replace />;
        } else {
          return <Navigate to={pageURLs.mobileHome} replace />;
        }
      }
    } else {
      return children;
    }
  }

  const UserId =
    _.get(user, "userInfo.userId", null) ||
    _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", null);

  // SSE integration
  useEffect(() => {
    let mount = true;
    let events;
    let timer;
    let createEvents = () => {
      // Close connection if open
      if (events) {
        events.close();
      }
      // Establishing an SSE connection
      events = new EventSource(
        process.env.REACT_APP_SSE_HOST + "connect?userId=" + UserId
      );
      events.onmessage = (event) => {
        if (mount) {
          const parsedData = JSON.parse(event.data);
          const formatedParsedData = JSON.parse(parsedData);
          const clientId = formatedParsedData?.subjectId;
          dispatch(setsseData(null));
          if (formatedParsedData?.messageType == "in-app") {
            dispatch(setsseData(formatedParsedData));

            getNotificationList(); // to set the unread in app message count
            if(formatedParsedData?.subjectType === 12){
              const pathName = _.get(location, "pathname", null);
            if (pathName && pathName === pageURLs.memberInventoryDetails) {
              const inventoryId = _.get(
                getLocalStorage(INVENTORY_KEY),
                "inventoryId",
                null
              );
              if (inventoryId) {
                dispatch(setsseData(formatedParsedData));
                // updateInventoryState(inventoryId)
              }
            }
            if (
              pathName &&
              pathName === pageURLs.mobilememberInventoryDetails
            ) {
              const inventoryId = _.get(
                getLocalStorage(INVENTORY_KEY),
                "inventoryId",
                null
              );
              if (inventoryId) {
                dispatch(setsseData(formatedParsedData));
                // updateInventoryState(inventoryId)
              }
            }
            }
          } else if (formatedParsedData?.messageType == "receiveIntroduction") {
            dispatch(setsseData(formatedParsedData));
            const wantAdId = null;
            let page = 1;
            getIntroductionList(page, wantAdId);
          } else if (formatedParsedData?.messageType == "exportNotes") {
            window.open(
              "https://gitcha-uploads.s3.us-east-1.amazonaws.com/" +
                s3ImageBaseFolder() +
                "/ClientNotes/" +
                clientId +
                ".pdf",
              "_blank"
            );
          } 
          // else if (
          //   formatedParsedData?.messageType === "inventory_Image_Rekognition"
          // ) {
            // toast.error("Oops! You have uploaded an invalid image");
            // toast.error(
            //   "Something went wrong. Try uploading a .jpg or .png image without any text or overlays."
            // );
            // const pathName = _.get(location, "pathname", null);
            // if (pathName && pathName === pageURLs.memberInventoryDetails) {
            //   const inventoryId = _.get(
            //     getLocalStorage(INVENTORY_KEY),
            //     "inventoryId",
            //     null
            //   );
            //   if (inventoryId) {
            //     dispatch(setsseData(formatedParsedData));
            //     // updateInventoryState(inventoryId)
            //   }
            // }
            // if (
            //   pathName &&
            //   pathName === pageURLs.mobilememberInventoryDetails
            // ) {
            //   const inventoryId = _.get(
            //     getLocalStorage(INVENTORY_KEY),
            //     "inventoryId",
            //     null
            //   );
            //   if (inventoryId) {
            //     dispatch(setsseData(formatedParsedData));
            //     // updateInventoryState(inventoryId)
            //   }
            // }
          // }
        }
      };
      events.onerror = (err) => {
        console.log("err", err);
        // timer = setTimeout(() => {
        //   createEvents();
        // }, 1000);
      };
    };

    createEvents();
    return () => {
      mount = false;
      clearTimeout(timer);
      events.close();
    };
  }, [UserId]);

  /**
   * To set the unread inapp notidication count
   */
  const getNotificationList = async () => {
    const reqPayload = {};
    await getInAppNotications(reqPayload).then((response) => {
      if (response) {
        if (response.errorCode === 0) {
          dispatch(
            setInappNotificationCount(
              _.get(response, "result.unReadMessageCount", 0)
            )
          );
        }
      }
    });
  };


  // receive Intro list
  const getIntroductionList = async (page, wantAdId) => {
    const userId = _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", "");
    const reqPayload = {
      userId: userId,
      wantAdId: wantAdId,
      page: page,
      limit: 1,
      isAccept: "1",
    };
    await getIntroductions(reqPayload).then((response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        if (response.errorCode === 0) {
          dispatch(
            setReceiveIntroCount(
              _.get(response, "result.unReadMessageCount", 0)
            )
          );
        }
      }
    });
  };

  // api call for chat messages

  useEffect(() => {
    const userDetails = loggedInUser();
    let email = _.get(userDetails, "userInfo.email", null);
    if(email){
      let login = loginOrSignUp(email);
      if (!login) {
        // navigate(pageURLs.home);
      } else {
        // api call for get receive intro count
        const wantAdId = null;
        let page = 1;
        getIntroductionList(page, wantAdId);
        getNotificationList();
      }
    }

    
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "Introduction"),
      or(
        where("inventroyCreatedId", "==", UserId),
        where("wantAdCreatedId", "==", UserId)
      )
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let fetchedMessages = [];
      let orderd = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });

      dispatch(setChatMessages(fetchedMessages));

      orderd = fetchedMessages?.filter(
        (item) => item.isaccepted == true && item.isdeleted == false
      );

      if (orderd && orderd.length > 0) {
        dispatch(initializeChatMessageCountValue());
        orderd.forEach((msg) => {
          if (msg?.wantAdCreatedId == UserId) {
            if (msg?.isWantAdOwnerHasUnreadMessage == true) {
              dispatch(setChatMessageCount());
            }
          } else {
            if (msg?.isInventoryOwnerHasUnreadMessage == true) {
              dispatch(setChatMessageCount());
            }
          }
        });
      }
    });

    return () => unsubscribe;
  }, [UserId]);

  const checkIsMobileView = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else false;
  };

  return (
    // <Provider store={store}>
    <>
      <ToastContainer position="bottom-center" className="toast-message" />
      {window.location.origin.includes(process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL) ?
        <Routes>
          {/* BuyerListing Form */}

          <Route
            path="/form/:id"
            element={
              checkIsMobileView() ? (
                <BuyerListingCreateWantAdFormMob />
              ) : (
                <BuyerListingCreateWantAdForm />
              )
            }
            exact
          />
          <Route
            path="/confirmation"
            element={
              checkIsMobileView() ? (
                <FormSubmissionSuccessMob />
              ) : (
                <FormSubmissionSuccess />
              )
            }
            exact
          />
           <Route
            path="/form/introduction/:id"
            element={
              checkIsMobileView() ? (
                <ShareIntroductionMob />
              ) : (
                <ShareIntroductionWeb />
              )
            }
            exact
          />
          <Route path="*" element={<NoPage />} />
        </Routes>

        : <Routes>
          {/* passcode barrier route */}
          <Route
            path="/"
            element={passCodeStatus == 2 ? <PassCodeRequest /> : <Home />}
            exact
          />

          <Route
            path="/home"
            element={
              <CheckMobileView>
                <Home
                //  sseData={sseData}
                />
              </CheckMobileView>
            }
            exact
          />

          <Route path="/match" element={<Home />} exact />
          <Route
            path="/create-want-ad"
            element={
              <RequireAuth roles={[1]}>
                <CreateWantAd />
              </RequireAuth>
            }
            exact
          />
          <Route path="*" element={<NoPage />} />
          <Route
            path="/want-ad-details"
            element={
              <RequireAuth roles={[1, 2]}>
                <WantadDetails />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/strengthen-want-ad"
            element={
              <RequireAuth roles={[1, 2]}>
                <StrengthenMemberWantAd />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/agent-dashboard"
            element={
              <RequireAuth roles={[2]}>
                <AgentDashboard />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/investor-dashboard"
            element={
              <RequireAuth roles={[1]}>
                <InvestorDashboard />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/view-client"
            element={
              <RequireAuth roles={[2]}>
                <ViewClientDetails />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/loading-skeleton"
            element={<HomeLoadingSkeleton />}
            exact
          />
          <Route
            path="/edit-want-ad"
            element={
              <RequireAuth roles={[1]}>
                <MemberEditWantAd />
              </RequireAuth>
            }
            exact
          />
          <Route path="/privacy-policy" element={<GitchaPrivacyPolicy />} exact />
          <Route path="/terms-of-service" element={<TermsOfService />} exact />
          <Route path="/want-ad" element={<ShowWantAd />} exact />
          <Route
            path={pageURLs.wantAdPubicMobileDetailsPage}
            element={<ShowWantAdMobile />}
            exact
          />

          <Route path="/404-not-found" element={<NoPage />} exact />
          <Route
            path="/my-want-ad"
            element={
              <RequireAuth roles={[1, 2]}>
                <MyWantAdListing />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/agent-want-ad-creation"
            element={<AgentCreateOrEditWantAdDraftListing />}
            exact
          />
          <Route
            path="/agent-create-want-ad"
            element={
              <RequireAuth roles={[2]}>
                <AgentCreateOrEditWantAd />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/agent-edit-want-ad"
            element={
              <RequireAuth roles={[2]}>
                <AgentCreateOrEditWantAd />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/agent-want-ad-confirmation"
            element={
              <RequireAuth roles={[2]}>
                <AgentWantAdConfirmation />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/wantad-details-skeleton"
            element={<WantadDetailsSkeleton />}
            exact
          />

          <Route
            path="/create-inventory"
            element={
              <RequireAuth roles={[1]}>
                <CreateInventory />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/strengthen-inventory"
            element={
              <RequireAuth roles={[1, 2]}>
                <StrengthenInventory />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/member-inventory-details"
            element={
              <RequireAuth roles={[1, 2]}>
                <MemberInventoryDetails />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/my-favorites"
            element={
              <RequireAuth roles={[1, 2]}>
                <MyFavorites />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/favorite-clients"
            element={
              <RequireAuth roles={[2]}>
                <FavoriteClients />
              </RequireAuth>
            }
            exact
          />

          <Route path="/view-inventory" element={<ViewInventory />} exact />
          <Route
            path="/my-inventory-listing"
            element={
              <RequireAuth roles={[1, 2]}>
                <MyInventoryListing />
              </RequireAuth>
            }
            exact
          />
          {/* Inventory */}
          <Route
            path="/member-edit-Inventory"
            element={
              <RequireAuth roles={[1]}>
                <MemberEditInventory />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/inventory-confirmation"
            element={
              <RequireAuth roles={[1, 2]}>
                <InventoryConfirmation />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/agent-draft-inventory"
            element={<AgentCreateOrEditInventoryDraftListing />}
            exact
          />

          <Route path="/enter-passcode" element={<PassCodeRequest />} exact />

          <Route
            path="/wantadd-onboard"
            element={
              <RequireAuth roles={[1, 2]}>
                <GitchaMemberWelcome />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/agent-create-inventory"
            element={
              <RequireAuth roles={[2]}>
                <AgentCreateOrEditInventory />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/settings"
            element={
              <RequireAuth roles={[1, 2]}>
                <Settings />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/add-new-card"
            element={
              <RequireAuth roles={[1, 2]}>
                <Settings addNewCard={true}/>
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/edit-agent-profile"
            element={
              <RequireAuth roles={[1, 2]}>
                <Settings editAgentProfile={true}/>
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/all-introductions"
            element={
              <RequireAuth roles={[1, 2]}>
                <AllIntroductions />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/view-introduced-property"
            element={
              <RequireAuth roles={[1, 2]}>
                <ReceiveIntroduction />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/view-all-introductions"
            element={
              <RequireAuth roles={[1, 2]}>
                <InventoryAllSentIntroductions />
              </RequireAuth>
            }
            exact
          />
          <Route
            path="/messages"
            element={
              <RequireAuth roles={[1, 2]}>
                <Messaging />
              </RequireAuth>
            }
            exact
          />

          <Route
            path="/inventory-onboard"
            element={<GitchaInventoryAddOnboard />}
            exact
          />
          <Route
            path={pageURLs.createWantAdResponsive}
            element={<CreateAWantAdNext />}
            exact
          />
          <Route
            path={pageURLs.strengthenAdResponsive}
            element={<StrengthenWantAd />}
            exact
          />

          <Route
            path={pageURLs.mobileWantAdFavorites}
            element={<WantAdFavorites />}
            exact
          />
          <Route
            path={pageURLs.clientFavoriteSummary}
            element={<ClientFavorites />}
            exact
          />
          <Route
            path={pageURLs.mobileEditWantAd}
            element={<CreateAWantAdNext />}
            exact
          />
          <Route
            path={pageURLs.strengthenInventoryReponsive}
            element={<StrengthenInventoryResponsive />}
            exact
          />
          <Route
            path={pageURLs.mobileCreateInventory}
            element={<AddPropertyInInventory />}
            exact
          />
          <Route
            path={pageURLs.mobileEditInventory}
            element={<AddPropertyInInventory />}
            exact
          />
          <Route
            path={pageURLs.viewFullGallery}
            element={<ImagesFullGallery />}
            exact
          />
          <Route
            path={pageURLs.wantAdTutorialResponsive}
            element={<TutorialProfileSetup />}
            exact
          />
          <Route
            path={pageURLs.inventoryTutorialResponsive}
            element={<InventoryTutorialSetup />}
            exact
          />

          {/* <Route path="/mobile/pages/review-plan" element={< ReviewPlanPremium />} exact /> */}

          {/* <Route path="/mobile/pages/select-plan" element={<SelectPlan />} exact /> */}

          <Route path={pageURLs.mobileSignIn} element={<UserRegister />} exact />
          <Route path="/mobile/update-plan" element={<UserRegister />} exact />
          {/* <Route path="/mobile/pages/view-ad" element={<ViewAd  />} exact />  */}
          {/* <Route path={pageURLs.viewAd} element={<ViewAd />} exact /> */}
          <Route path={pageURLs.viewAd} element={<HandleViewWantAd />} exact />

          <Route
            path={pageURLs.changePassword}
            element={<ResetPassword />}
            exact
          />

          {/* inapp notifications */}
          <Route path={pageURLs.mobileInapp} element={<Inapp />} exact />

          {/* receive intro  notifications */}
          <Route
            path={pageURLs.mobileReceiveIntroNotification}
            element={<ReceiveIntroductionNotification />}
            exact
          />

          <Route path={pageURLs.proDashboard} element={<ProDashboard />} exact />
          <Route
            path={pageURLs.agentClientListing}
            element={<AgentClientsListing />}
            exact
          />

          {/* <Route
          path={pageURLs.clientManagement}
          element={<ClientManagement />}
          exact
        />
        <Route path="/mobile/myclients" element={<MyClients />} exact /> */}
          <Route path="/mobile/view-client" element={<ViewClient />} exact />
          <Route
            path="/mobile/view-client-wantad"
            element={<ViewClientWantAd />}
            exact
          />
          <Route
            path="/mobile/view-client-inventory"
            element={<ViewInventoryClient />}
            exact
          />

          <Route path={pageURLs.myWantAds} element={<WantAdListing />} exact />

          <Route
            path="/mobile/client-notes-list"
            element={<ClientNotesListing />}
            exact
          />
          <Route
            path={pageURLs.mobileHome}
            element={
              // <CheckMobileView>
              <MobileHome />
              // </CheckMobileView>
            }
            exact
          />
          <Route path={pageURLs.mobileMatch} element={<MobileHome />} exact />

          <Route path={pageURLs.mobileSettings} element={<SettingsMob />} exact />
          <Route
            path="/mobile/member-view-property"
            element={<MemberViewProperty />}
            exact
          />

          {/* <Route
          path="/mobile/pages/inventory-member-ad-property"
          element={<InventoryMemberAddProperty />}
          exact
        />             */}

          {/* manage Inventory */}
          <Route
            path={pageURLs.mobileManageInventory}
            element={<ManageInventory />}
            exact
          />
          <Route
            path={pageURLs.mobileAccountSettings}
            element={<AccountSettings />}
            exact
          />
          <Route
            path={pageURLs.mobilePremiumAgentProfileEdit}
            element={<ProfileAgentUserPremiumEdit />}
            exact
          />
          <Route
            path={pageURLs.mobileEditSettingsEmail}
            element={<SettingsEditEmail />}
            exact
          />
          <Route
            path={pageURLs.mobileEditEmailCheck}
            element={<SettingsCheckEmail />}
            exact
          />
          <Route
            path={pageURLs.mobileDeleteAccountCheck}
            element={<SettingsDeleteAccount />}
            exact
          />
          <Route
            path={pageURLs.mobilesettingsEditName}
            element={<SettingsEditName />}
            exact
          />          
          <Route
            path={pageURLs.mobileAddPaymentMethod}
            element={<AddPaymentMethod />}
            exact
          />          
          <Route
            path={pageURLs.mobilePaymentHistory}
            element={<PaymentHistory />}
            exact
          />
          <Route
            path={pageURLs.mobileEmailNotification}
            element={<EmailNotification />}
            exact
          />
          <Route
            path={pageURLs.mobileMessages}
            element={<HandleMessagesScreens />}
            exact
          />

          <Route
            path={pageURLs.mobileInventoryIntroduction}
            element={<InventoryAllIntroductions />}
            exact
          />

          <Route
            path={pageURLs.mobilePremiumAgentCreateWantAd}
            element={<CreateAdForm />}
            exact
          />



          {/* <Route path="/form-mob" element={} exact /> */}
        </Routes>
      }
    </>
    // </Provider>
  );
}

export default App;
