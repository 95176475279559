
import { createSlice } from '@reduxjs/toolkit'
import {fetchWantAds, fetchMyWantAds, fetchFavouriteWantAds, fetchFavouriteClients, fetchAdById} from '../actions/wantAds'

const initialState = {
  loading: false,
  wantAds: [],
  error: '',
  myWantAdsList: [],
  favouriteWantAdsList: [],
  favouriteClientsList: [],
  wantAdById: {},
}

const wantAds = createSlice({
  name: 'wantAds',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchWantAds.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchWantAds.fulfilled, (state, action) => {
      state.loading = false
      state.wantAds = action.payload.result
      state.error = ''
    })
    builder.addCase(fetchWantAds.rejected, (state, action) => {
      state.loading = false
      state.wantAds = []
      state.error = action.error.message
    })

    builder.addCase(fetchMyWantAds.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchMyWantAds.fulfilled, (state, action) => {
      state.loading = false
      state.myWantAdsList = action.payload.result
      state.error = ''
    })
    builder.addCase(fetchMyWantAds.rejected, (state, action) => {
      state.loading = false
      state.myWantAdsList = []
      state.error = action.error.message
    })

    builder.addCase(fetchFavouriteWantAds.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchFavouriteWantAds.fulfilled, (state, action) => {
      state.loading = false
      state.favouriteWantAdsList = action.payload.result
      state.error = ''
    })
    builder.addCase(fetchFavouriteWantAds.rejected, (state, action) => {
      state.loading = false
      state.favouriteWantAdsList = []
      state.error = action.error.message
    })

    builder.addCase(fetchFavouriteClients.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchFavouriteClients.fulfilled, (state, action) => {
      state.loading = false
      state.favouriteClientsList = action.payload.result
      state.error = ''
    })
    builder.addCase(fetchFavouriteClients.rejected, (state, action) => {
      state.loading = false
      state.favouriteClientsList = []
      state.error = action.error.message
    })

    builder.addCase(fetchAdById.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchAdById.fulfilled, (state, action) => {
      state.loading = false
      state.wantAdById = action.payload.result
      state.error = ''
    })
    builder.addCase(fetchAdById.rejected, (state, action) => {
      state.loading = false
      state.wantAdById = []
      state.error = action.error.message
    })

  
  }

  
})

export default wantAds.reducer
//export const { getWantAds } = wantAds.actions