

/**
 * @file   src\components\AgentWantAd\WantAdBudget.js
 * @brief  This file is responsible for handling wantad budget in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from "react";
import SelectDropDown from "../Common/SelectDropDown";
import { getSquareFeetList } from "../../services/wantAdService";
import {
  arraySplice,
  checkIsNumber,
  filterArrayBasedOnKey,
  removeDecimalZeros,
} from "../../helpers/common";
import { convertToNumber } from "../../helpers/validations";
import _ from "lodash";


const SqareFeet = (props) => {
  const {
    handleFormValues,
    SFMin,
    SFMax,
    errors } = props


  const MIN_SF_DROPDOWN = "minSFDrop";
  const MAX_SF_DROPDOWN = "maxSFDrop";

  const [squareFootageMinError, setSquareFootageMinError] = useState(null);
  const [squareFootageMaxError, setSquareFootageMaxError] = useState(null);
  const [squareFeetMinList, setSqareFeetMinList] = useState([]);
  const [squareFeetMaxList, setSqareFeetMaxList] = useState([]);
  const squareFeetRef = useRef(null);
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const INDEX_TO_BE_SLICE = 14;


  useEffect(() => {
    getSquareFeetList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          let newList = [];
          list.map((lst) => {
            const value = _.get(lst, "Sqft_Value");
            const text = _.get(lst, "Sqft_Value_Text", "");
            newList.push({ value: value, label: text });
          });
          const minOptions = {
            value: removeDecimalZeros(convertToNumber(SFMin)),
            label: SFMin && removeDecimalZeros(SFMin).toString(),
          };
          addNewOptionsToMinList(newList, minOptions);
          const maxOptions = {
            value: removeDecimalZeros(convertToNumber(SFMax)),
            label: SFMax && removeDecimalZeros(SFMax).toString(),
          };
          addNewOptionsToMaxList(newList, maxOptions);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  useEffect(() => {
    if(SFMin){
      const options = { value: removeDecimalZeros(convertToNumber(SFMin)), label: removeDecimalZeros(SFMin) };
      addNewOptionsToMinList(
        arraySplice(squareFeetMinList, INDEX_TO_BE_SLICE),
        options
      );
    }

    if(SFMax){
      const options = { value: removeDecimalZeros(convertToNumber(SFMax)), label: removeDecimalZeros(SFMax) };
      addNewOptionsToMaxList(
        arraySplice(squareFeetMaxList, INDEX_TO_BE_SLICE),
        options
      );
    }
    
  }, [SFMin, SFMax]);
// Hanlde selection from dropdown
  const handleSqareFeetSelection = (e, dropSection) => {
    setSquareFootageMaxError(null);
    setSquareFootageMinError(null);
    const value = _.get(e, "value", "");
    if (dropSection === MIN_SF_DROPDOWN) {
      handleFormValues({ sqFtMin: value });
    } else {
      handleFormValues({
        sqFtMax: value,
      });
    }
  };
//Add new options in list based on the values typed in min dropdown
  const addNewOptionsToMinList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMinList(customList);
  };

  //Add new options in list based on the values typed in max dropdown
  const addNewOptionsToMaxList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMaxList(customList);
  };
// Function to add new values in dropdown list
  const buildCustomList = (list, newOption) => {
    const filteredArray = filterArrayBasedOnKey({
      list: list,
      filterKey: "value",
      value: _.get(newOption, "value", null),
    });
    let customOptions = [...list];
    if (filteredArray?.length === 0 && _.get(newOption, "value", null)) {
      customOptions = [...list, newOption];
    }
    return customOptions;
  };

// Function to handle input change in dropdowns
  const handleOnInputChange = (e, dropSection) => {
    setSquareFootageMaxError(null);
    setSquareFootageMinError(null);
    if(e){
      if (dropSection === MIN_SF_DROPDOWN) {
        if (minRef.current && minRef.current.contains(document.activeElement)) {
          if (checkIsNumber(e)) {
            const options = { value: convertToNumber(e), label: e };
            addNewOptionsToMinList(
              arraySplice(squareFeetMinList, INDEX_TO_BE_SLICE),
              options
            );
            handleFormValues({ sqFtMin: e });
          }
        }
      } else {
        if (maxRef.current && maxRef.current.contains(document.activeElement)) {
          if (checkIsNumber(e)) {
            const options = { value: convertToNumber(e), label: e };
            addNewOptionsToMaxList(
              arraySplice(squareFeetMaxList, INDEX_TO_BE_SLICE),
              options
            );
            handleFormValues({ sqFtMax: e });
          }
        }
      }
    }
  };

  useEffect(() => {

    if (errors && errors.length > 0) {
      if (errors[0] == 'sqftMaxLesser') {
        squareFeetRef.current.focus();
      }

      errors.map((item) => {

        if (item == 'sqftMaxLesser') {

          setSquareFootageMaxError('Value Must be greater than minimum');
        } 
      })
    }

  }, [errors]);

  return (
    <div className={squareFootageMinError ? "wantad-timing-wrap pb-0  red-marked-wrap" : "wantad-timing-wrap pb-0"}>
      <div className="lotsize-selection-wrap">
        <h6 className={(squareFootageMinError) && "red-marked-subheading"}>
          {" "}
          Square Footage<span>*</span>
        </h6>
        <div className="row">
          <div className="col-md-6 pe-md-6">
            <label className={squareFootageMinError ? "lot-select-label red-marked-lot-label" : "lot-select-label"}>Min</label>
            <div className="lot-select-wrap" ref={minRef}>
              <SelectDropDown
                id={MIN_SF_DROPDOWN}
                name="colors3"
                options={[
                  { value: null, label: "No min sqft" },
                  ...squareFeetMinList,
                ]}
                className={squareFootageMinError ? "lotsize-select custom-lotsize-color red-marked-select" : "lotsize-select custom-lotsize-color"}
                classNamePrefix="select"
                placeholder="No min sqft"
                value={filterArrayBasedOnKey({
                  list: squareFeetMinList,
                  filterKey: "value",
                  value: removeDecimalZeros(SFMin) ,
                })}
                onInputChange={(e) => handleOnInputChange(e, MIN_SF_DROPDOWN)}
                onChange={(e) => handleSqareFeetSelection(e, MIN_SF_DROPDOWN)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                  }),
                }}
                errorClass="error-msg error-msg-height"
                errorMessage={squareFootageMinError}
                onBlur={()=>{handleFormValues({focusOut: true})}}
              />
            </div>
          </div>
          <div className="col-md-6 ps-md-6">
            <label className={squareFootageMaxError ? "lot-select-label red-marked-lot-label" : "lot-select-label"}>Max</label>
            <div className="lot-select-wrap" ref={maxRef}>
              <SelectDropDown
               selectRef={squareFeetRef}
                id={MAX_SF_DROPDOWN}
                name="colors5"
                options={[
                  { value: null, label: "No max sqft" },
                  ...squareFeetMaxList,
                ]}
                className={squareFootageMaxError ? "lotsize-select custom-lotsize-color red-marked-select" : "lotsize-select custom-lotsize-color"}
                classNamePrefix="select"
                placeholder="No max sqft"
                value={filterArrayBasedOnKey({
                  list: squareFeetMaxList,
                  filterKey: "value",
                  value: removeDecimalZeros(SFMax),
                })}
                onInputChange={(e) => handleOnInputChange(e, MAX_SF_DROPDOWN)}
                onChange={(e) => handleSqareFeetSelection(e, MAX_SF_DROPDOWN)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                  }),
                }}
               
                errorClass="error-msg error-msg-height"
                errorMessage={squareFootageMaxError}
                onBlur={()=>{handleFormValues({focusOut: true})}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SqareFeet;


