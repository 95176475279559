import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function updateIntroReplyStatus(introId) {
  return await apiService.apiCall(
    endPoints.UPDATE_INTRO_REPLY_STATUS+
    `?introductionId=${introId}`,
    "PUT",
  );
}

export async function getIntroMessageDetailsByUserId(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_INTRODUCTION_MESSAGE_DETAILS +
      `?conversationType=${requestPayload.filter}`,
    "GET",
    {}
  );
}


export async function saveChatEmail(payload) {
  return await apiService.apiCall( endPoints.CHAT_EMAIL, "POST", payload);
}