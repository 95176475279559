import { BUDGET_NOMIN_NOMAX } from "../constants/common"
import { durationForFlexibleDate, formateDate, monthNameForFlexibleDate } from "./common"
import { setLotSizeValue, wantAdBudgetValue, wantAdSqftValue } from "./createWantAd"

export const getAdTypeTextShare = (adType) => {
    if(adType==='BUY'){
        return "Looking to buy a"
    }
    else if(adType==="LTR"){
        return "Looking to rent a"
    }
    else if(adType==="STR"){
        return "Stay in a"
    }
}


export const getPropertyTextShare = (adDetails) => {
    if(adDetails?.prprtyType === 6){
        return adDetails.prptyTypeTxt?.toLowerCase()
    }
    else{
        return adDetails?.prptySubTypesTxt[0]?.toLowerCase()
    }    
}

export const getDescriptionText = (adDetails) => {
    const {prprtyType, lotType, adType} = adDetails;
    let budget = wantAdBudgetValue(_.get(adDetails, "minBdgt", ""),_.get(adDetails, "maxBdgt", "") )
    if(budget != BUDGET_NOMIN_NOMAX){
      if (adType === 'LTR') {
        budget = budget + "/Month";
      } else if (adType === 'STR') {
        budget = budget + "/Night";
      }
    }
    let ltSize = "";
    ltSize = setLotSizeValue( _.get(adDetails, "minLtSz", null), _.get(adDetails, "maxLtSz", null));
    let sqFt = wantAdSqftValue(_.get(adDetails, "sqFtMin", ""), _.get(adDetails, "sqFtMax", "") )
    let noBathRoom = _.get(adDetails, "noBathRm", "") || "Any";
    let noBedRoom = _.get(adDetails, "noBedRm", "") || "Any";
    if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
    if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
    let timeLine = "";
      if (
        _.get(adDetails, "startDate", "") &&
        _.get(adDetails, "endDate", "")
      ) {
        timeLine =
          formateDate(_.get(adDetails, "startDate", ""), "mm/dd") +
          " - " +
          formateDate(_.get(adDetails, "endDate", ""), "mm/dd");
      } else {
        timeLine =
          durationForFlexibleDate(_.get(adDetails, "duration", "")) +
          " in " +
          monthNameForFlexibleDate(_.get(adDetails, "month", ""));
      }
    const lotTypeText = lotType ? (lotType === 1 ? 'Rural' : 'In city limits') : 'Any';
    if(adType==='STR'){
        if(prprtyType==6){
            return "Lot type: "+lotTypeText+" | Lot size: "+ltSize+" | Budget: "+budget
        }
        else if(prprtyType==11){
          return "Dates: "+timeLine+" | Budget: "+budget
        }
        else{
            return "Beds: "+noBedRoom +" | "+"Baths: "+noBathRoom +" | Dates: "+timeLine+" | Budget: "+budget
        } 
    }
    else {
        if(prprtyType==6){
            return "Lot type: "+lotTypeText+" | Lot size: "+ltSize+" | Budget: "+budget
        }
        else if(prprtyType==11){
          return "Budget: "+budget
        }
        else{
            return "Beds: "+noBedRoom +" | "+"Baths: "+noBathRoom +" | Sqft: "+sqFt+" | Budget: "+budget
        }
    }
    
} 