import React, { useState, useEffect } from "react";

import { getPropertyTypes } from "../../services/commonServices";
import { handleRadioButonChange } from "../../actions/common";
import { usePrevious } from "../../hooks/usePrevious";
import { BUY, WANT_AD_BUY_PROPERTY_ID } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";

const WantAdType = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    propertyType,
    clearPreviousStates,
    prptySubTypes,
    isEdit = false,
  } = props;
  const [propertyList, setPropertyList] = useState([]);
  useEffect(() => {
    if (propertyType) {
      handleContinueButtonState();
    } else {
      const isDisable = true;
      handleContinueButtonState(isDisable);
    }
    const typeOfAd = BUY;
    getPropertyTypes(typeOfAd)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);
  const previousValue = usePrevious(propertyType);
  const getSelectedValue = (e, typeText) => {
    const value = handleRadioButonChange(e);
    if (value) {
      handleContinueButtonState();
      clearPreviousSelectedValues(value);
      handleFormValues({ prprtyType: convertToNumber(value),  prptyTypeTxt: typeText, prptySubTypes: [convertToNumber(value)]});
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    if (previousValue) {
      const isClearPrevious =
        (convertToNumber(previousValue) === 6 &&
          convertToNumber(currentSelectedValue) !== 6) ||
        (convertToNumber(previousValue) !== 6 &&
          convertToNumber(currentSelectedValue) === 6);
      if (isClearPrevious) {
        clearPreviousStates();
      }
    }
  };

  const handlePropertyMultipleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      handleContinueButtonState();
      if (_.includes(prptySubTypes, convertToNumber(value))) {
       
        subItemArray = prptySubTypes.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {

          subItemArray = prptySubTypes.filter(
            (e) => e !== 4 &&  e !== 6
          );
         
         subItemArray = [...subItemArray, convertToNumber(value)];
      }
      handleFormValues({ prptySubTypes: subItemArray,  prprtyType: WANT_AD_BUY_PROPERTY_ID, prptyTypeTxt: 'BUY' });
    }
  };


  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const imgPath = _.get(pt, "Img");
        const isChecked =  prptySubTypes && _.includes(prptySubTypes, typeId);
        const radioOrCheckBox =  _.get(pt, "Buy_IsMultiSelect", 0) == 0  ? true : false;
        return (
             <label className={radioOrCheckBox ? "radio-wrap mb-3" : "check-wrap mb-3"} key={typeId}>
            
            {
              radioOrCheckBox ?
              (
                <input
                type="radio"
                name="property_radio"
                value={typeId ? typeId : ""}
                checked={propertyType && propertyType == typeId || ''}
                onChange={(e) => getSelectedValue(e, typeText)}
              />

              ) : (
                <input
                type="checkbox"
                name="property_checkbox"
                id={typeId}
                value={typeId ? typeId : ""}
                checked={isChecked}
                onChange={(e) => handlePropertyMultipleItemClick(e)}
              />

              
              )

            }
            <span>
              <div className="timing-wrap-info">
                <h2>{_.get(pt, "PropertyTypeText", "")}</h2>
              </div>
            </span>
          </label>
        );
      });

    return List;
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-444" : ""}>
      <div className="wantad-timing-wrap">{loadPropertyTypes()}</div>
    </div>
  );
};
export default WantAdType;
