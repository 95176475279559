import React from "react";

const PropertyTypeBtns = (props) => {
  const { list, updateFilter, section, filterQuery, onClick } = props;
  
  const selectedIemArray  = _.get(filterQuery, [section], [])

  const handleSelection = ({section, value}) => {
    
    if (value) {
      let itemArray;      
      if (_.includes(selectedIemArray, value)) {
        itemArray = selectedIemArray.filter((e) => e !== value);
      } else {
        itemArray = [...selectedIemArray, value];
      }
      updateFilter({[section] : itemArray });
    }
  };

  function PropertyTypes(props)  {
    const List =
      list &&
      list.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const isChecked =
        selectedIemArray && _.includes(selectedIemArray, typeId);
        return (
          <button type="button" id= {typeId} key={typeId} className={isChecked ? "btn btn-primary select" : "btn btn-primary" } 
          onClick={() => onClick(typeId)}>
          {typeText} {isChecked && <span className="close"></span>}
        </button>
        );
      });

    return List;
  };
  return (
    <div className="btnPills">
      <div className=" marginBtn mb-3" role="group" aria-label="Basic example">
        <PropertyTypes/>
      </div>
    </div>
  );
};

export default PropertyTypeBtns;
