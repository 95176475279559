import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import {
  clearLocalStorageOnLogout,
  getLocalStorage,
  getUserType,
  loadTimeLineString,
  removeLocalStorage,
  setLocalStorage,
} from "../../helpers/common";
import NxtArrow from "../components/nextArrow";
import BottomStepperMob from "../components/BottomStepperMob";
import CheckIcn from "../images/checkIcn.svg";
import { getAdTypeText, truncate } from "../../helpers/common";
import { getNiceToHaves } from "../../services/commonServices";
import {
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  BUY_LAND,
  BUY,
  IS_LAND,
  WANT_AD_KEY_TEMP,
  WANT_AD_KEY,
  USER_TYPE_AGENT,
  BUY_STRENGTHEN,
  STRENGTHEN_WANT_AD_KEY,
  USER_INFO_KEY,
  LTR_STRENGTHEN,
} from "../../constants/common";
import { wantAdBudgetValue } from "../../helpers/createWantAd";
import {
  getCombinedText,
  getMustHavesText,
} from "../../helpers/getPropertyIcons";
import AddPurchaseType from "../components/createWantAd/AddPurchaseType";
import AddFinancing from "../components/createWantAd/AddFinancing";
import AddCreditScore from "../components/createWantAd/AddcreditScore";
import AddBudget from "../components/createWantAd/AddBudget";
import { saveMemberWantAdstrengthenData } from "../../services/wantAdService";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userLogout } from "../../reducers/login";

const StrengthenWantAd = (props) => {
  const [formValues, setFormValues] = useState(null);
  const navigate = useNavigate();
  let adAmenities = null;
  const [uiKey, setUIKey] = useState(null);
  const [completedPercentage, setCompletedPercentage] = useState(7)

  useEffect(() => {    
    adAmenities = getLocalStorage(STRENGTHEN_WANT_AD_KEY);
    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), "userInfo", null);
    if (!userInfo) {
      handleStateClear();
    }    
    setFormValues(adAmenities);    
  }, [uiKey===null]);

  useEffect(() => {
    setLocalStorage(WANT_AD_KEY_TEMP, formValues);

  }, [formValues]);

  useEffect(()=>{
    handleFormValues({ cmpldPerctg: completedPercentage });
  }, [completedPercentage])

  const handleStateClear = () => {
    // alert("Your session has expired. Please login to continue.");
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate('/mobile/home');
  };  
  const adType = _.get(formValues, "adType", null);
  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const propertyType = _.get(formValues, "prprtyType", null);
  const purchaseText = _.get(formValues, "purchaseText", null);
  const purchaseType = _.get(formValues, "purchaseText", null);
  const pFinance = _.get(formValues, "pFinance", null);
  const pFinanceText = _.get(formValues, "pFinanceText", null);
  const creditScore = _.get(formValues, "creditScore", null);
  const creditScoreDesc = _.get(formValues, "creditScoreDesc", null);
  const creditScoreText = _.get(formValues, "creditScoreText", null);
  const isAgent = getUserType()===USER_TYPE_AGENT ? true : false

  let isDisableSave =true;

  if(adType===BUY_STRENGTHEN){ 
    if(purchaseType && pFinance){
      isDisableSave=false
    }
    else{
      isDisableSave=true
    }
  }else{
    if(creditScore){
      isDisableSave=false
    }
    else{
      isDisableSave=true
    }
  }



  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  useEffect(()=>{
    handleStrengthenWantAdUi()
  },[uiKey])

  const saveStrengthenData = async () => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const strengthenData = getLocalStorage(STRENGTHEN_WANT_AD_KEY);
    const wantAdId = _.get(wantAd, "wantAdId", null);

    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = wantAd;
    }
    let isApproveLoan = "";
    if (_.get(formValues, "isPreApprLoan", null) === "Yes") {
      isApproveLoan = true;
    } else if (_.get(formValues, "isPreApprLoan", null) === "No") {
      isApproveLoan = false;
    }
    requestPayLoad.isPreApprLoan === isApproveLoan;
    const is1031Exchange =
      requestPayLoad.is1031Exchange === "Yes" ? true : false;
    requestPayLoad.wantAdId = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan;
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.maxBdgt = _.get(strengthenData, "maxBdgt", wantAd.maxBdgt);
    requestPayLoad.minBdgt = _.get(strengthenData, "minBdgt", wantAd.minBdgt);
    requestPayLoad.completedStep = _.get(formValues, "actvStep", 1);
    delete requestPayLoad.completedPercentage;
    delete requestPayLoad.isAdjustBdget;
    delete requestPayLoad.pFinanceText;
    delete requestPayLoad.purchaseText;
    delete requestPayLoad.userId;
    delete requestPayLoad.pTypeTxt;

    let updatedObjectTolocalStorage = { wantAdId: wantAdId };
    if (adType === LTR_STRENGTHEN) { 
      updatedObjectTolocalStorage.showSuccess = true;
    }
    await saveMemberWantAdstrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
          removeLocalStorage(WANT_AD_KEY_TEMP)
          setLocalStorage(WANT_AD_KEY, updatedObjectTolocalStorage);
          toast.success("You’ve strengthened your want-ad!")
          navigate(pageURLs.viewAd, { state: { fromStrengthenWantAd: true }});
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleStrengthenWantAdUi = () => {
    switch (uiKey) {      
      case "purchaseType" : 
      return <AddPurchaseType
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        adType={adType}
        completedPercentage={completedPercentage}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent}
        isStrengthenWantAd={true}
      />
      case "financing" : 
      return <AddFinancing
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        adType={adType}
        completedPercentage={completedPercentage}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent}
        isStrengthenWantAd={true}
      />
      case "creditScore" : 
      return <AddCreditScore
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        adType={adType}
        completedPercentage={completedPercentage}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent}
        isStrengthenWantAd={true}
      />
      case "budget" : 
      return <AddBudget
        handleFormValues={handleFormValues}
        formValues={formValues}
        setUIKey={setUIKey}
        adType={adType}
        completedPercentage={completedPercentage}
        setCompletedPercentage={setCompletedPercentage}
        isAgent={isAgent} 
        isStrengthenWantAd={true}
      />
         
    }
  };


  const handleSaveAndExit = () => {
    removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
    removeLocalStorage(WANT_AD_KEY_TEMP);
    navigate(pageURLs.viewAd);
  };


  return (
    <>
      {uiKey===null? <div className="gitcha-main-wrapper pb-154">
        <div className="content-wrapper content-wrapper-h-154">
          <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 flex-column">
              <div className="top-three-comp-header-inner w-100">
                <a className="d-block back-link-big me-4" href={void 0} onClick={()=>handleSaveAndExit()}>
                  back
                </a>
                <div className="progress-wrap-mob">
                  <BottomStepperMob completedPercentage={completedPercentage} />
                </div>

                <button className="btn top-exit-btn ms-4">&nbsp;</button>
              </div>
            </div>
          </div>
          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div className="">
              <div className="mt-3 pt-0 mb-0 titleText">Strengthen Want-Ad</div>
              <p className="titleTextpara">
                Tap on the section to add your response. Items marked with an
                asterisk have required fields.{" "}
              </p>
            </div>
            {adType === BUY_STRENGTHEN && (
              <>
                <div
                  className="selectBoxWrap select d-flex mb-3"
                  onClick={() => setUIKey("purchaseType")}
                >
                  <div className="flex-grow-1">
                    <h4>
                      Purchase Type{" "}
                      {purchaseType ? <img src={CheckIcn} /> : <></>}
                    </h4>
                    <h5>{purchaseType ? purchaseText : "Select type"}</h5>
                  </div>
                  <div className="align-self-center ">
                    <Button variant="" id="button-addon2">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>
                <div
                  className="selectBoxWrap select d-flex mb-3"
                  onClick={() => setUIKey("financing")}
                >
                  <div className="flex-grow-1">
                    <h4>
                      Financing {pFinance ? <img src={CheckIcn} /> : <></>}
                    </h4>
                    <h5>{pFinance ? pFinanceText : "Select financing"}</h5>
                  </div>
                  <div className="align-self-center ">
                    <Button variant="" id="button-addon2">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>
              </>
            )}
            {adType === LTR_STRENGTHEN && (
              <div
                className="selectBoxWrap select d-flex mb-3"
                onClick={() => setUIKey("creditScore")}
              >
                <div className="flex-grow-1">
                  <h4>
                    Credit Score {creditScore ? <img src={CheckIcn} /> : <></>}
                  </h4>
                  <h5>
                    {creditScore
                      ? creditScoreText + " (" + creditScoreDesc + ")"
                      : "Select range"}
                  </h5>
                </div>
                <div className="align-self-center ">
                  <Button variant="" id="button-addon2">
                    <NxtArrow />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-20"
                disabled={isDisableSave}
                onClick={() => saveStrengthenData()}
              >
                Update Ad
              </button>
              <button
                type="button"
                onClick={() => handleSaveAndExit()}
                class="btn btn-no-bg btn-no-bg-fix w-100"
              >
                Save & Exit
              </button>
            </div>
          </div>
        </div>
      </div>: handleStrengthenWantAdUi()}
    </>
  );
};

export default StrengthenWantAd;
