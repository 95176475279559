import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import RadioButtonGroup from "../../Common/RadioButtonGroup";
import { USER_TYPE_AGENT, USER_TYPE_MEMBER } from "../../../constants/common";

const CheckAgentModal = (props) => {
  const {show, handleUserTypeContinueClick, handleUserTypeSelection, isDisabled, selectedType } = props;
  const itemArray = [
    { text: "Yes", id: USER_TYPE_AGENT },
    { text: "No", id: USER_TYPE_MEMBER }
  ];
  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
      className="modal-custom-width"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pb-32 pt-32">
        {/*<button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>onHide(false)}
    ></button>*/}
        <h3 className="text-start">Let’s create your profile!</h3>
        <p className="mb-30 ln-24 f-size-16 para-color-first">
          We're here to help you meet you and your real estate goals.
          Let's get started by setting up your profile.{" "}
        </p>

        <p className="f-size-16 f-medium mb-3 para-color-second">Are you a licensed real estate agent or property manager? </p>

        <div className="wantad-timing-wrap">
          <RadioButtonGroup
            list={itemArray}
            onChange={(e) => handleUserTypeSelection(e)}
            selectedValue={selectedType}
          />
        </div>

        <Button
          disabled ={isDisabled}
          variant="primary"
          className= {isDisabled ? "btn w-100 mb-2 disabled" : "btn w-100 mb-2"}
          onClick={()=> handleUserTypeContinueClick()}
        >
          Continue
        </Button>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default CheckAgentModal;
