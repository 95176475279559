import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { PROPERTY_AD, USER_INFO_KEY, USER_TYPE_AGENT, WANT_AD } from "../../../constants/common";
import { getLocalStorage } from "../../../helpers/common";

const AgentLiketoStartModal = (props) => {
 
  const {
    onHide,
    show,
    backClick,
    handleAgentPreferenceContinue,
    preferenceSelection,
    agentPreference,
  } = props;

  const userDetails = getLocalStorage(USER_INFO_KEY)
  const userType = _.get(userDetails, 'userInfo.userType', null)
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mw-548"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>handleAgentPreferenceContinue('browse_feed')}
        ></button>

        {/* <a
          role="button"
          href={void 0}
          className="bottom-back-btn d-inline-block mt-2"
          onClick={() => backClick()}
        >
          <span className="left-arrow">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                fill="#205ECA"
              />
            </svg>
          </span>
          Back
        </a> */}
        <h3 className="text-start pt-3">How would you like to start?</h3>

        <Row>
          <Col xl={12}>
            <p className=" mb-32 text-start f-size-16">
              To begin, select whether you would like to create a want-ad or add
              property.
            </p>
            <div className="wantad-timing-wrap pb-2">
              <label className="radio-wrap mb-4">
                <input
                  type="radio"
                  name="radio"
                  value={WANT_AD}
                  checked={agentPreference === WANT_AD}
                  onClick={(e)=>preferenceSelection(e)}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-1">Create a want-ad</h2>
                    <p>
                      {userType === USER_TYPE_AGENT ?
                      "You have a client who is looking to buy, rent, or stay in a property. You want to share what they’re looking for to receive introductions from people with matching property." 
                    : "You’re looking to buy, rent, or stay in a property. You want to share what you’re looking for to receive introductions from people with matching property. "}
                    
                    </p>
                  </div>
                </span>
              </label>
              <label className="radio-wrap mb-4">
                <input
                  type="radio"
                  name="radio"
                  value={PROPERTY_AD}
                  checked={agentPreference === PROPERTY_AD}
                  onClick={(e)=>preferenceSelection(e)}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-1">Add property to your inventory</h2>
                    <p>
                      {userType === USER_TYPE_AGENT?
                      "You have a client who has a property they’re considering selling or renting-out (long or short-term). You want to gauge demand or send a preview of their property to matching want-ads. "
                    :"You own a property you’re considering selling or renting-out (long or short-term). You want to gauge demand or send a preview of your property to matching want-ads. "}
                    
                   
                    </p>
                  </div>
                </span>
              </label>
            </div>

            <div className="modal-btn-wrap flex-column">
              <Button variant="primary" className="btn w-100 l-spacing-0" disabled = {agentPreference ? false : true}
              onClick={()=>handleAgentPreferenceContinue('Continue')}>
                Continue
              </Button>

              <a
                role="button"
                href={void 0}
                className="bottom-back-btn d-inline-block mt-4 mx-auto"
                onClick={()=>handleAgentPreferenceContinue('browse_feed')}
              >
                Browse Want-Ad Feed
                <span className="right-arrow">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.76661 3.59982C9.96187 3.40455 10.2785 3.40455 10.4737 3.59982L14.5204 7.64648C14.6142 7.74025 14.6668 7.86743 14.6668 8.00004C14.6668 8.13264 14.6142 8.25982 14.5204 8.35359L10.4737 12.4003C10.2785 12.5955 9.96187 12.5955 9.76661 12.4003C9.57135 12.205 9.57135 11.8884 9.76661 11.6931L12.9597 8.50004H2.8335C2.55735 8.50004 2.3335 8.27618 2.3335 8.00004C2.3335 7.72389 2.55735 7.50004 2.8335 7.50004H12.9597L9.76661 4.30692C9.57135 4.11166 9.57135 3.79508 9.76661 3.59982Z"
                      fill="#205ECA"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default AgentLiketoStartModal;
