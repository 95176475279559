/**
 * @file   src\appState\userProfile.js
 * @brief  This file is responsible for fetching userprofile data from redux
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../actions/userprofile";
import {useEffect } from "react";
import { getUserId } from "../helpers/common";

function userProfile() {
  const dispatch = useDispatch();
  useEffect(() => {
    const userId = getUserId();
    if (userId) {
      dispatch(
        fetchUserDetails({
          userId: userId,
        })
      );
    }
  }, []);
    const userDetails = useSelector((state) => state.userProfile.userDetails);
    return userDetails;
}
export default userProfile