import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import SearchCloseIcon from "../../components/searchCloseIcn";
// import Button from "react-bootstrap/Button";
import { InputGroup, Button } from "react-bootstrap";
import SearchImg from "../../images/search-icn.svg";
import BuildingIcon from "../../images/citybuildingsIcn.svg";
import EducationIcon from "../../images/schoolIcn.svg";
import LocationIcon from "../../images/locationIcn.svg";
import { getLocalStorage, sortItems } from "../../../helpers/common";
import { RECENT_SEARCHES, USER_LOCATION_KEY } from "../../../constants/common";

const Search = (props) => {
  const {
    setSearchView,
    isSearchView,
    handleSelection,
    location,
    locationList,
    handleResentSelect
  } = props;

  const recentSearches = getLocalStorage(RECENT_SEARCHES)
  const browserLocation = getLocalStorage(USER_LOCATION_KEY)
  
  return (
    <>
      {/* <InputGroup className="" onChange={setSearchView(true)}>
        <Form.Control placeholder="Search location or keyword" />
        <Button variant="outline-secondary" id="button-addon2">
          <SearchCloseIcon />
        </Button>
      </InputGroup> */}

      <div className="search_wrap_mobile_dpmenu">
        {browserLocation != null ? <div
          className="d-flex current-location-info align-items-center pb-3"
          style={{ cursor: "pointer" }}
          onClick={() => handleSelection({ section: "currentLoc" })}
        >
          <img src={LocationIcon} />
          <span className="ps-1"> Use Current Location</span>
        </div> : <></>}
        {recentSearches?.length>0 && (
          <div className="pb-3">
            <div className="fnt12 txtCaps text-capitalize recent-search-txt">
              Recent Searches
            </div>

            <ul className="searchResultListing">
            {recentSearches.sort(sortItems("desc", "index")).map((rs, i) => {
              const searchKey = _.get(rs, 'key', null)
              const section = _.get(rs, 'section', null)
              const isKeyWordSearch = section === "keyword"
              
                return (
                  <li
                    key={i}
                    onClick={(e) => {
                      handleResentSelect(rs);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={isKeyWordSearch? SearchImg:  BuildingIcon} />{" "}
                    <span className="ps-1">
                      {" "}
                      {searchKey}
                    </span>
                  </li>
                );
              })}
            
            </ul>
          </div>
        )}
        {location && (
          <div
            className="pb-3"
            style={{ cursor: "pointer" }}
            onClick={() => handleSelection({ section: "keyword" })}
          >
            <div className="keyword-search-txt">keyword</div>

            <ul className="searchResultListing">
              <li>
                {" "}
                <img src={SearchImg} />{" "}
                <span className="ps-1"> "{location}"</span>
              </li>
            </ul>
          </div>
        )}

        {location && locationList.length > 0 && (
          <div className="pb-0">
            <div className="keyword-search-txt">city</div>

            <ul className="searchResultListing">
              {locationList?.map((address, i) => {
                return (
                  <li
                    key={i}
                    onClick={(e) => {
                      handleSelection({
                        section: "locSearch",
                        selectedLoc: address,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={BuildingIcon} />{" "}
                    <span className="ps-1">
                      {" "}
                      {_.get(address, "formatted_address", "")}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      {/* <div className="pb-3">
                  <div className="fnt12 txtCaps">keyword</div>

                  <ul className="searchResultListing">
                      <li> <img src={SearchImg}/>  <span className="ps-1"> "Eugene"</span></li>
                     
                  </ul>
                </div>

                <div className="pb-0">
                  <div className="fnt12 txtCaps">city</div>

                  <ul className="searchResultListing searchResultScrolling">
                      <li> <img src={BuildingIcon}/>  <span className="ps-1"> Eugene, OR</span></li>
                      <li> <img src={EducationIcon}/>  <span className="ps-1">  Eugene, TX</span></li>
                  </ul>
                </div> */}

      {/*<div className="pb-3">
                  <div className="fnt12 txtCaps">school district</div>

                  <ul className="searchResultListing">
                      <li> <img src={BuildingIcon}/>  <span className="ps-1"> Eugene, ISD</span></li>
                      
                  </ul>
                </div>*/}
    </>
  );
};

export default Search;
