/**
 * @file   src\components\AgentWantAd\WantAdPropertyPurchase.js
 * @brief  This file is responsible for handling purchase type selection in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from "react";
import { getPurchaseTypes } from "../../services/wantAdService";
import { handleRadioButonChange } from "../../actions/common";

const WantAdPropertyPurchase = (props) => {
  const {
    handleFormValues,
    propertyPurchase,
    is1031Exchange,
  } = props;

  const [propertyList, setPropertyList] = useState([]);
  const [show1031Exchange, setShow1031Exchange] = useState(false);
  let isUnCheckItem = false;
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => { 
    if (propertyPurchase) {
      if (propertyPurchase == "3") {
        setShow1031Exchange(true);
      }
    }

    getPurchaseTypes()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyList = _.get(response, "result", null);
          setPropertyList(propertyList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleItemClick = (e, propertyText) => {
    setShow1031Exchange(false);
    const value = handleRadioButonChange(e);
    if (value === propertyPurchase) {
      handleFormValues({
        purchaseType: "",
        purchaseText: "",
        is1031Exchange: "",
        focusOut: true
      });
      setSelectedValue("")
      setShow1031Exchange(false);
      isUnCheckItem = true;
    } else {
      if (value === "3") {
        setShow1031Exchange(true);
      } else {
        setShow1031Exchange(false);
        handleFormValues({ is1031Exchange: "",  focusOut: true });
      }
      handleFormValues({ purchaseType: value, purchaseText: propertyText,  focusOut: true });
      setSelectedValue(value)
    }
  };

  const getSelectedExchangeValue = (e) => {
    handleFormValues({ is1031Exchange: e.target.value,  focusOut: true });
  };

  const loadPurchaseTypes = () => {
    const purchaseTypes =
      propertyList &&
      propertyList.map((tl) => {
        const PurchaseTypeId = _.get(tl, "PurchaseTypeId");
        const PurchaseTypeText = _.get(tl, "PurchaseTypeText", "");
        return (
            <label className="radio-wrap mb-3" key={PurchaseTypeId}>
            
              <input
                
                type="radio"
                name="purchase_radio"
                value={PurchaseTypeId}
                checked={
                  isUnCheckItem
                    ? false
                    : selectedValue ? ( selectedValue == PurchaseTypeId) : (propertyPurchase && propertyPurchase == PurchaseTypeId)
                }
                onClick={(e) => handleItemClick(e, PurchaseTypeText)}
                onChange = {(e) => handleItemClick(e, PurchaseTypeText)}
              />
              <span>
                <div className="timing-wrap-info pb-0">
                  <h2>{PurchaseTypeText}</h2>
                </div>
              </span>
            </label>
        
        );
      });

    return purchaseTypes;
  };

  return (
    <>
      <div className="wantad-timing-wrap pb-0">
        {loadPurchaseTypes()}
        {show1031Exchange && (
          <div className="inner-select-type-wrap">
            <div>
              <h6 className="form-label">Will this be a 1031 Exchange?</h6>
            </div>
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="exchange_radio"
                value="Yes"
                checked={is1031Exchange && (is1031Exchange === "Yes" || is1031Exchange == 1)}
                onClick={(e) => getSelectedExchangeValue(e)}
                onChange = {(e) => getSelectedExchangeValue(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>Yes</h2>
                </div>
              </span>
            </label>

            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="exchange_radio"
                value="No"
                checked={(is1031Exchange === "No" || is1031Exchange == 0)}
                onClick={(e) => getSelectedExchangeValue(e)}
                onChange = {(e) => getSelectedExchangeValue(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>No</h2>
                </div>
              </span>
            </label>
          </div>
        )}
      </div>
    </>
  );
};
export default WantAdPropertyPurchase;
