import React, { useState, useEffect, useRef } from "react";
import RangeSliderMobile from "../RangeSliderMobile";
import TextBox from "../../../components/Common/TextBox";
import ProgressBar from "../ProgressBar";
import { usePrevious } from "../../../hooks/usePrevious";
import {
  thousandSeperatedValue,
  replaceComma,
  checkIsNull,
  setLocalStorage,
  getLocalStorage,
  setBodyBackgroundWhite,
} from "../../../helpers/common";
import { convertToNumber } from "../../../helpers/validations";
import { BUY, BUY_STRENGTHEN, DISPLAT_RENTAL, DISPLAT_STR, IS_LAND, LONG_RENT, STRENGTHEN_WANT_AD_KEY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import { removeNegativeInput } from "../../../helpers/validations";
import { handleTextBoxChange } from "../../../actions/common";
import { getCombinedText } from "../../../helpers/getPropertyIcons";
import AgentCompensation from "./AgentCompensation";
import InfoIcon from '../../images/icnInfo.svg';

const AddBudget = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    // clearPreviousStates,  
    setCompletedPercentage,
    isAgent,
    isStrengthenWantAd,
    isEdit,
    isFromBuyerListingService
  } = props;

  const budgetMin = _.get(formValues, "minBdgt", null);
  const budgetMax = _.get(formValues, "maxBdgt", null);
  const isAdjustBdget = _.get(formValues, "isAdjustBdget", null);

  const budgetMinInSession = _.get(getLocalStorage(WANT_AD_KEY), "minBdgt", null);
  const budgetMaxInSession = _.get(getLocalStorage(WANT_AD_KEY), "maxBdgt", null);
  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const [budgetRange, setBudgetRange] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  const [budgetMinCursorPos, setBudgetMinCursorPos] = useState(0);
  const [budgetMaxCursorPos, setBudgetMaxCursorPos] = useState(0);

  const minBudgetRef = useRef();
  const maxBudgetRef = useRef();

  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;

  if (adType === BUY || adType === BUY_STRENGTHEN) {
    DEFAULT_MAX_BUDGET = 5000000;
    DEFAULT_STEP_VALUE = 25000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
    DEFAULT_STEP_VALUE = 100;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
    DEFAULT_STEP_VALUE = 50;
  }
  useEffect(() => {
    if(isAdjustBdget){
    setTimeout(()=>{
      maxBudgetRef.current.focus();
      maxBudgetRef.current.setSelectionRange(thousandSeperatedValue(budgetMax)?.length+1, thousandSeperatedValue(budgetMax)?.length+1);
    },300)}    
  },[isAdjustBdget]);

  useEffect(()=>{
   if(isEdit && formValues?.budgetMax != undefined && formValues?.budgetMin != undefined) {
   const budhetMaxTemp = budgetMax == '' ? null : budgetMax
    const budhetMinTemp = budgetMin == 0 ? null : budgetMin    
    if(_.differenceWith([budhetMaxTemp, budhetMinTemp], [budgetMaxInSession, budgetMinInSession], _.isEqual).length <=0){
      setDisableSave(true)
    }
    else{
      setDisableSave(false)
    }}
    else{
      setDisableSave(false);
    }

  },[budgetMax, budgetMin])

  const prevMinBdgt = usePrevious(budgetRange[0]);
  const prevMaxBdgt = usePrevious(budgetRange[1]);

  const displayTitle = () =>{
    if(adType === BUY){
      return getCombinedText(prptySubTypes)?.toLowerCase()
    }
    else if(adType===LONG_RENT){
      return DISPLAT_RENTAL
    }
    else{
      return DISPLAT_STR
    }
  }  

  useEffect(() => {
    setBodyBackgroundWhite()
    let DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET];
    if (budgetMin && !budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, DEFAULT_MAX_BUDGET];
    } else if (!budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, budgetMax];
    } else if (budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, budgetMax];
    }
    setBudgetRange(DEFAULT_BUDGET_RANGE);
    if(!isEdit){setDisableSave(false);}
  }, []);

  useEffect(() => {
    if (prevMinBdgt !== budgetRange[0]) {
      handleFormValues({ minBdgt: budgetRange[0] });
    }
  }, [budgetRange[0]]);

  useEffect(() => {
    const mxBdgt = budgetRange[1] === DEFAULT_MAX_BUDGET ? "" : budgetRange[1];
    if (prevMaxBdgt && prevMaxBdgt !== budgetRange[1]) {
      handleFormValues({ minBdgt: budgetMin, maxBdgt: mxBdgt });
    }
  }, [budgetRange[1]]);

  useEffect(() => {    
    if(budgetRange[0]!=null){
      minBudgetRef.current.setSelectionRange(budgetMinCursorPos, budgetMinCursorPos);
    }
  }, [budgetMinCursorPos]);

  useEffect(() => {
    if(budgetRange[1]!=null){
      maxBudgetRef.current.setSelectionRange(budgetMaxCursorPos, budgetMaxCursorPos);
    }
  }, [budgetMaxCursorPos]);

  const handleBudgetMinChange = (e) => {
    const budgetMax = budgetRange && budgetRange[1];
    let value = removeNegativeInput(handleTextBoxChange(e));
    const budgetMin = budgetRange && budgetRange[0]

    if (value) {
      value = replaceComma(value);
    }
    if (!checkIsNull(value)) {
      if (!value) {
        setBudgetRange([DEFAULT_MIN_BUDGET, budgetMax]);
      } else {
        handleFormValues({ minBdgt: value });
        setMinError("");
        setBudgetRange([value, budgetMax]);
        if( (thousandSeperatedValue(budgetMin)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMin)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMinCursorPos(e.target.selectionStart)
        }
      }
    } else {
      setBudgetRange([DEFAULT_MIN_BUDGET, budgetMax]);
      handleFormValues({ minBdgt: "" });
    }
  };

  const handleBudgetMaxChange = (e) => {
    const budgetMin = budgetRange && budgetRange[0];
    const budgetMax = budgetRange && budgetRange[1];
    let value = removeNegativeInput(handleTextBoxChange(e));
    if (value) {
      value = replaceComma(value);
    }
    if (!checkIsNull(value)) {
      if (!value) {
        setBudgetRange([budgetMin, DEFAULT_MAX_BUDGET]);
      } else {
        handleFormValues({ maxBdgt: value });
        setMaxError("");
        setBudgetRange([budgetMin, value]);
        if( (thousandSeperatedValue(budgetMax)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMaxCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMax)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMaxCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMaxCursorPos(e.target.selectionStart)
        }
      }
    } else {
      setBudgetRange([budgetMin, DEFAULT_MAX_BUDGET]);
      handleFormValues({ maxBdgt: "" });
    }
  };

  const validate = () => {
    setMaxError("");
    setMinError("")
    let errorExist = false;
    if (budgetMin && budgetMax) {
      if (convertToNumber(budgetMin) >= convertToNumber(budgetMax)) {
        setMaxError("Max budget should greater than minimum budget.");
        errorExist = true;
      } 
    } 
    

    if (errorExist) {
      return false;
    }
    return true;
  }

  return (
    <>
    <div className="gitcha-main-wrapper pb-102 bg-white">
      <div className="content-wrapper content-wrapper-h-100-percent">
        <ProgressBar
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isEdit={isEdit}
          isFromBuyerListingService={isFromBuyerListingService}
        />
        <div className="inner-layout-content h-100 bg-white">
          <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white h-100">
            <div className="top-three-comp-btm-sec mt-0 mb-4">
              <h6>Budget</h6>
              <h2>{isAgent ? 
              adType===LONG_RENT ? "What’s your client’s monthly budget for their " : "What’s your client’s budget for their "
              :
              adType===LONG_RENT ? "What’s the monthly budget for your " : "What’s the budget for your "}{displayTitle()}?</h2>
            </div>

            <div className="square-footage-wrap-mob">
              <div className="rs-pd-mob">
                <RangeSliderMobile
                  min={DEFAULT_MIN_BUDGET}
                  max={DEFAULT_MAX_BUDGET}
                  defaultValue={budgetRange}
                  value={budgetRange}
                  stepValue={DEFAULT_STEP_VALUE}
                  onInput={setBudgetRange}
                />
              </div>

              <div className="range-values-list-mob">
                <div className="row">
                  <div className="col-6 pe-2">
                    <label>Min</label>
                    <div className="position-relative">
                      {thousandSeperatedValue(budgetMin) && <span class="dollar-sign-icon-mob">$</span>}
                      <TextBox
                      pattern="[0-9]*" 
                      inputmode="numeric"
                      class={
                        minError
                          ? "pad-left-25 validation-txtfld ranges-txt-color"
                          : "pad-left-25 ranges-txt-color"
                      }
                      errorMessage={minError}
                      value={thousandSeperatedValue(budgetMin) || ""}
                      placeholder="No min"
                      onChange={(e) => {
                        handleBudgetMinChange(e);
                      }}
                      txtRef={minBudgetRef}
                    ></TextBox>
                    </div>
                  
                  </div>
                  <div className="col-6 ps-2">
                    <label>Max</label>
                    <div className="position-relative">
                      {thousandSeperatedValue(budgetMax) && <span class="dollar-sign-icon-mob">$</span>}
                      <TextBox
                      pattern="[0-9]*" 
                      inputmode="numeric"
                      class={
                        maxError
                          ? "pad-left-25 validation-txtfld ranges-txt-color"
                          : "pad-left-25 ranges-txt-color"
                      }
                      errorMessage={maxError}
                      value={thousandSeperatedValue(budgetMax) || ""}
                      placeholder="No max"
                      onChange={(e) => {
                        handleBudgetMaxChange(e);
                      }}
                      txtRef={maxBudgetRef}
                    ></TextBox>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>

     
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav ">
          <div className="btn-wrap text-center pb-40 pt-0">
            <button
              type="button"
              className="btn btn-primary w-100 mb-0"
              disabled={disableSave}
              onClick={() => {
                if(validate()){
                  if(isAgent){
                    if(completedPercentage>70){
                      setCompletedPercentage(completedPercentage)
                    }
                    else {
                      setCompletedPercentage(70)
                    }
                  }
                  else {
                    if(completedPercentage>85){
                    setCompletedPercentage(completedPercentage)
                    }
                    else {
                      setCompletedPercentage(85)
                    }
                  }
                  setTimeout(()=>{

                    if(isStrengthenWantAd){
                      setLocalStorage(STRENGTHEN_WANT_AD_KEY, getLocalStorage(WANT_AD_KEY_TEMP))
                    }
                    else {setLocalStorage(WANT_AD_KEY, getLocalStorage(WANT_AD_KEY_TEMP))}
                    setUIKey(null)

                  }, 500)
                  

                }                
              }}
            >
              {isEdit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddBudget;
