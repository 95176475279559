import React from 'react';

const TextAreaMob = props => {
    const getLength = () => {
        let length = 0;
        if (props.value) {
            length = props.value.length;
        }
        return length;
    }
    return (
        <div className="mob-fld-wrap position-relative">
            {/* <div className="count">{getLength()}/{props.maxLength}</div> */}
            {props.label &&
                <label>{props.label}</label>}
            <textarea
                className= {props.className ? `form-control ${props.className}` : "form-control"}
                id={props.id}
                name={props.name}
                type={props.type}
                value={props.value}
                error={props.error}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
                onChange={props.onChange}
                minLength={props.minLength}
                maxLength={props.maxLength}
                disabled={props.disabled}
                background-color={props.bgColor}
                ref={props.txtRef}
                onKeyDown={props.onKeyDown}
            />
            {props.errorMessage && props.errorMessage !== '' && <span className='mob-error-msg'>{props.errorMessage}</span>}
        </div>
    );
}

export default TextAreaMob;