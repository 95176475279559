import React, { useState, useEffect } from "react";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import GitchaLogoMobile from "../../images/gitchaLogomobile.svg";
import { getLocalStorage, getUserType } from "../../../helpers/common";
import { USER_INFO_KEY, USER_TYPE_AGENT } from "../../../constants/common";
import { useSelector } from "react-redux";

const HeaderMenu = (props) => {
  const { setSearchView, isFrom, isPremiumUser , redirectToHomePage} = props;

  let {unreadInappNotificationCount} = useSelector((state)=>state.unreadCount);
  unreadInappNotificationCount = unreadInappNotificationCount?.payload


  const navigate = useNavigate();
  const userDetails = getLocalStorage(USER_INFO_KEY);
  let userName = _.get(userDetails, "userInfo.name", null);
  let profileImage = _.get(userDetails, "userInfo.image", null);
  const userType = getUserType();

  if (userName) {
    userName = userName.replace(/-/g, "").match(/\b(\w)/g);
  }
  return (
    <>
      <div className="top-three-comp-header w-100 flex-column top-three-custom-pad">
        <div className="top-three-comp-header-inner w-100">
          <div class="p-0 toplinks-mobile top-three-equal-width">
            <div
              className="topbar-links bell-icon-header position-relative"
              onClick={() => {
                navigate(pageURLs.mobileInapp);
              }}
            >
              {unreadInappNotificationCount > 0 && <div className="bell-icon-count">{unreadInappNotificationCount}</div>}
              {" "}
            </div>
          </div>
          <div class="p-0 top-three-equal-width text-center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(pageURLs.mobileHome)
              if (isFrom == 'Home') { setSearchView(false);redirectToHomePage() }
            }}>
            <img src={GitchaLogoMobile} alt={GitchaLogoMobile} />
          </div>
          <div class="p-0 toplinks-mobile top-three-equal-width text-end">
            {/* <div className="topbar-links profile-icon-header"></div> */}
            {userName ? (
              (profileImage &&  userType ==
              USER_TYPE_AGENT && isPremiumUser) ?
                (
                  <>
                    <figure className="brdr-rs-200 profile-image-figure"
                      onClick={() => navigate(pageURLs.mobileSettings)}>
                      <img className="brdr-rs-200" src={profileImage + "?" + Math.random().toString(36)} />
                    </figure>
                  </>
                ) :
                (<div
                  className="profile-user-info profile-bg-meroon ms-auto"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(pageURLs.mobileSettings)}
                >

                  {userName}
                </div>)


            ) : (
              <button
                variant=""
                className="btn signin-btn-small"
                onClick={() => navigate(pageURLs.mobileSignIn)}
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-mobile-tagline">
        {userType == USER_TYPE_AGENT ? <>The Buyer Listing Service<sup>TM</sup></> : "The “Want” Marketplace for Real Estate"}

      </div>
    </>
  );
};
export default HeaderMenu;
