import React, { useState, useEffect, useRef } from "react";
import { FormLabel } from "react-bootstrap";
import RangeSliderComponent from "../Common/RangeSlider";
import TextBox from "../Common/TextBox";
import "react-range-slider-input/dist/style.css";
import { replaceComma, checkIsNull } from "../../helpers/common";
import { BUY, BUY_STRENGTHEN, LONG_RENT, SHORT_RENT } from "../../constants/common";
import { thousandSeperatedValue } from "../../helpers/common";
import { removeNegativeInput } from "../../helpers/validations";
import { handleTextBoxChange } from "../../actions/common";
import { usePrevious } from "../../hooks/usePrevious";
import { budgetMaxError, budgetMaxMissing, budgetMinMissing } from "../../constants/createWantAd";

const WantAdBudget = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    budgetMin,
    budgetMax,
    isEdit = false,
    adType,
    budgetErrors,
    errorUpdatedAt,
    isAdjustBudget
  } = props;
  const [budgetRange, setBudgetRange] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");

  const [budgetMinCursorPos, setBudgetMinCursorPos] = useState(0);
  const [budgetMaxCursorPos, setBudgetMaxCursorPos] = useState(0);

  const minBudgetRef = useRef();
  const maxBudgetRef = useRef();

  

  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;

  if (adType === BUY || adType === BUY_STRENGTHEN) {
    DEFAULT_MAX_BUDGET = 5000000;
    DEFAULT_STEP_VALUE = 25000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
    DEFAULT_STEP_VALUE = 100;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
    DEFAULT_STEP_VALUE = 50;
  }

  let label = isEdit ? "Budget" : "Price";
  if (adType == SHORT_RENT) {
    label = isEdit ? "Budget (per night)" : "Price (per night)";
  } else if (adType == LONG_RENT) {
    label = isEdit ? "Budget (per month)" : "Price (per month)";
  }

  useEffect(() => {
    setMaxError("");
    setMinError("")
    if (budgetErrors === budgetMaxError) {
      setMaxError("Max budget should greater than minimum budget.");
      
    } 
    // else if(budgetErrors === budgetMinMissing){
    //   setMinError("Enter min budget");
    // } else if(budgetErrors === budgetMaxMissing){
    //   setMaxError("Enter max budget");
    // }
    handleContinueButtonState();
  }, [budgetErrors, errorUpdatedAt]);

  const prevMinBdgt = usePrevious(budgetRange[0]);
  const prevMaxBdgt = usePrevious(budgetRange[1]);

  useEffect(()=>{
    if(isAdjustBudget===true){
      setTimeout(()=>{
        maxBudgetRef.current.focus();
        maxBudgetRef.current.setSelectionRange(thousandSeperatedValue(budgetMax)?.length+1, thousandSeperatedValue(budgetMax)?.length+1);
      },300) 
    }
  },[isAdjustBudget])

  useEffect(() => {
    let DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET];
    if (budgetMin && !budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, DEFAULT_MAX_BUDGET];
    } else if (!budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, budgetMax];
    } else if (budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, budgetMax];
    }
    setBudgetRange(DEFAULT_BUDGET_RANGE);
    handleContinueButtonState();
  }, []);

  useEffect(() => {
    if (prevMinBdgt !== budgetRange[0]) {
      handleFormValues({ minBdgt: budgetRange[0] });
    }
  }, [budgetRange[0]]);

  useEffect(() => {
    const mxBdgt = budgetRange[1] === DEFAULT_MAX_BUDGET ? "" : budgetRange[1];
    if (prevMaxBdgt && prevMaxBdgt !== budgetRange[1]) {
      handleFormValues({ minBdgt: budgetMin, maxBdgt: mxBdgt });
    }
  }, [budgetRange[1]]);

  
  useEffect(() => {    
    minBudgetRef.current.setSelectionRange(budgetMinCursorPos, budgetMinCursorPos);
  }, [budgetMinCursorPos]);

  useEffect(() => {
    maxBudgetRef.current.setSelectionRange(budgetMaxCursorPos, budgetMaxCursorPos);
  }, [budgetMaxCursorPos]);


  const handleBudgetMinChange = (e) => {
    const budgetMax = budgetRange && budgetRange[1];
    let value = removeNegativeInput(handleTextBoxChange(e));
    const budgetMin = budgetRange && budgetRange[0]

    if (value) {
      value = replaceComma(value);
    }
    if (!checkIsNull(value)) {
      if(!value){
        setBudgetRange([DEFAULT_MIN_BUDGET, budgetMax]);
      } else {
        handleFormValues({ minBdgt: value });
        setMinError("");
        setBudgetRange([value, budgetMax]);
        if( (thousandSeperatedValue(budgetMin)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMin)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMinCursorPos(e.target.selectionStart)
        }
      }
    } else {
      setBudgetRange([DEFAULT_MIN_BUDGET, budgetMax]);
      handleFormValues({ minBdgt: "" });
    }
  };

  const handleBudgetMaxChange = (e) => {
    const budgetMin = budgetRange && budgetRange[0];
    const budgetMax = budgetRange && budgetRange[1];
    let value = removeNegativeInput(handleTextBoxChange(e));
    if (value) {
      value = replaceComma(value);
    }
    if (!checkIsNull(value)) {
      if(!value){
        setBudgetRange([budgetMin, DEFAULT_MAX_BUDGET]);
      } else {
        handleFormValues({ maxBdgt: value });
        setMaxError("");
        setBudgetRange([budgetMin, value]);
        if( (thousandSeperatedValue(budgetMax)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMaxCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMax)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMaxCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMaxCursorPos(e.target.selectionStart)
        }
      }
    } else {
      setBudgetRange([budgetMin, DEFAULT_MAX_BUDGET]);
      handleFormValues({ maxBdgt: "" });
    }
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-524" : ""}>
      <div className="square-footage-wrap">
        <FormLabel>
          {label}
          <span>*</span>
        </FormLabel>
        {!isEdit && (
          <div className="rs-pd">
            <RangeSliderComponent
              min={DEFAULT_MIN_BUDGET}
              max={DEFAULT_MAX_BUDGET}
              defaultValue={budgetRange}
              value={budgetRange}
              stepValue={DEFAULT_STEP_VALUE}
              onInput={setBudgetRange}
            />
          </div>
        )}
        <ul className="range-values-list rs-pb-37">
          <li className="position-relative">
            {thousandSeperatedValue(budgetMin) && <span className="dollar-sign-icon">$</span>}
            <TextBox
              type="text"
              class= {minError ? "pad-left-25 validation-txtfld ranges-txt-color" : "pad-left-25 ranges-txt-color"}
              errorMessage={minError}
              value={thousandSeperatedValue(budgetMin) || ""}
              placeholder="No min"
              onChange={(e) => {
                handleBudgetMinChange(e);
              }}
              txtRef={minBudgetRef}
            ></TextBox>
          </li>
          <li className="text-center">
            <span className="middle-separator">-</span>
          </li>
          <li className="position-relative">
            {thousandSeperatedValue(budgetMax) && <span className="dollar-sign-icon">$</span>}
            <TextBox
              type="text"
              class= {maxError ? "pad-left-25 validation-txtfld ranges-txt-color" : "pad-left-25 ranges-txt-color"}
              errorMessage={maxError}
              value={thousandSeperatedValue(budgetMax) || ""}
              placeholder="No max"
              onChange={(e) => {
                handleBudgetMaxChange(e);
              }}
              txtRef={maxBudgetRef}
            ></TextBox>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default WantAdBudget;
