import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import { emailValidation } from "../../helpers/validations";
import { changeEmailAddress } from "../../services/userprofileService";
import { toast } from "react-toastify";
import CheckEmailDeletionModal from "../Onboarding/UserRegister/CheckEmailDeletionModal";


const EditEmailidModal = (props) => {
  const [email, setEmail] = useState(props.Email)
  const [emailError, setEmailError] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [checkEmailPopup, setCheckEmailPopup] = useState(false)

  useEffect(()=>{
    setEmail(props.Email)
  },[props.Email])

  const handleEmailChange = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    validate(e.target.value);
  };
  const validate = (mail) => {
    setButtonDisable(true);
    let isValid = true;
    setEmailError("");
    if (mail.trim() === "") {
      isValid = false;
      setEmailError("Enter Email");
    } else if (!emailValidation(mail.trim())) {
      isValid = false;
      setEmailError("Enter Valid email");
    } else if (isValid) {
      setButtonDisable(false);
    }
    return isValid;
  };
  const handleCancel =() =>{
    setEmail(props.Email)
    setEmailError("");
    props.onHide()
  }


  const handleSubmit = async () => {
    const data = {
      newEmail : email
    }
  await changeEmailAddress(data).then((response)=>{
    if(response.errorCode===0){
      setCheckEmailPopup(true)
      props.onHide()
    }
    else{
      toast.error(response.message)
    }
  })
  .catch((error)=>{
    toast.error(error)
  })

  }

  return (
    <>
    {checkEmailPopup ? (
        <CheckEmailDeletionModal process="email_change" email={email} oldEmail={props.Email} />
      ) : (
        ""
      )}
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings"
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={props.onHide}
        ></button>
        <h3 className="text-start">Email address</h3>
        <p className="f-size-16 mb-4">You can sign into Gitcha using this email address.</p>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Email</label> 
                <TextBox
                    id="mailId"
                    name="emailId"
                    value={email}
                    type="text"
                    placeholder="Email"
                    className="form-control"
                    onChange={(e)=>handleEmailChange(e)}
                    errorClass = "error-validation-msg"
                    errorMessage={emailError}
                />
            </div>
          </Col>
        </Row>        

        <div className="modal-btn-wrap">
            <Button className="btn btn-tertiary me-3" onClick={handleCancel} >Cancel</Button>
            <Button 
            variant="primary" 
            onClick={handleSubmit}
            disabled={buttonDisable} 
            >Save Changes</Button>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};
export default EditEmailidModal;
