import React, { useState, useEffect } from "react";
import 'react-swipeable-list/dist/styles.css';
import MessageTopDropdown from "./MessageTopDropdown";
import MenuBottomBar from "../menuBottomMobile";
import { classesBasedOnScroll, getInitials, getLocalStorage, getUserId, isIphone, setBodyBackgroundGrey, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";
import Contact from "./Contact";
import NoMessagesMob from "./NoMessagesMob";
import MessagesLoadingSkeleton from "./MessagesLoadingSkeleton";
import { SCROLL_DIRECTION_DOWN, SCROLL_DIRECTION_UP, UNREADCHATMESSAGESCOUNT } from "../../../constants/common";
import HandleScroll from "../../commonComponents/HandleScroll";
import { usePrevious } from "../../../hooks/usePrevious";


const ContactList = (props) => {
    const {setUIKey, filterList, handleFilter, selectedFilterValue} = props;
    const messages = props.messages;

    const [loading, setLoading] = useState(true);
    const userId = getUserId();


    useEffect(() => {
        setLoading(false)
        
    },[messages])

    useEffect(() => {
        document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
       setBodyBackgroundGrey()
       window.addEventListener("scroll", handleScroll); // attaching scroll event listener
    },[])

    const [scrollDirection, setScrollDirection] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const prevScrollPosition = usePrevious(scrollPosition);
  
  
  
    const handleScroll = () => {
      const isBottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;
  
      if (!isBottom && window.scrollY > 5) {
        setScrollPosition(window.scrollY);
      }
    };
  
    useEffect(() => {
      if(messages){
      if (
        scrollPosition > prevScrollPosition &&
        scrollDirection !== SCROLL_DIRECTION_DOWN
      ) {
        //setHeight(0);
        //setShowFooter(true)
        setScrollDirection(SCROLL_DIRECTION_DOWN);
      } else if (
        scrollPosition < prevScrollPosition &&
        scrollDirection !== SCROLL_DIRECTION_UP
      ) {
       
        setScrollDirection(SCROLL_DIRECTION_UP);
      }
    }
    }, [scrollPosition]);

  let mainWrapperClass ="gitcha-main-wrapper windowscroll-fix"
  let contentWrapperClass = "content-wrapper content-wrapper-h-102-percent windowscroll-fix"
  if(scrollDirection === SCROLL_DIRECTION_DOWN){
    if(isIphone()){
      mainWrapperClass ="gitcha-main-wrapper pad-btm-0 windowscroll-fix"
    } else{
      mainWrapperClass ="gitcha-main-wrapper pad-btm-0 windowscroll-fix"
    }
     contentWrapperClass = "content-wrapper content-wrapper-h-102-percent windowscroll-fix"
  }
   
    return (
        <>
            {messages && messages.length > 0 ?
                (
                    <div className= {mainWrapperClass}>
                        <div className={contentWrapperClass}>
                            <div className="inner-layout-header-new bg-white ">
                                <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
                                    <div className="top-three-comp-header-inner w-100">
                                        <div></div>
                                        <div className="heading">Messages</div>
                                        <div class="p-0 toplinks-mobile">
                                            <a className=" social-btns-wrap" href={void(0)}>
                                              
                                            {
                                                selectedFilterValue[0].value != 'all' && 
                                                <div className="dot-indicator dot-bg-blue"></div>
                                            }
                                               
                                                <MessageTopDropdown filterList={filterList} handleFilter={handleFilter}/>

                                            </a>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="h-100 inner-layout-content-new position-relative">
                                <div className=" ">
                                    {messages.map((msg, index) => (
                                      <Contact key={msg.IntroductionId}
                                      id={msg.IntroductionId}
                                      msgData={msg}
                                      index={index}
                                      selectedIntroductionId={props.selectedIntroductionId}
                                      userId={props.userId}
                                      handleWantAdSelection={(msgData) => { props.handleWantAdSelection(msgData) }}
                                      setUIKey={setUIKey} />
                              ))}
                                  
                                </div>
                            </div>
                        </div>
                        {scrollDirection !== SCROLL_DIRECTION_DOWN &&
                           <div className="inner-layout-footer-new inner-layout-footer-topfix" >
                           <div className="bottomMenu bottom-menu-index">
                               <MenuBottomBar scrollDirection = {scrollDirection} noScrollBounce = {true} />
                           </div>
                       </div>}
                     
        
                    </div>
                )
            :
            (
             <NoMessagesMob filterList={filterList} handleFilter={handleFilter} />
            )}



        </>
    );
};

export default ContactList;
