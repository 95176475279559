import React, { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { getCombinedText } from "../../../helpers/getPropertyIcons";
import NiceToHavesProperties from "./NiceToHavesProperties";
import NiceToHavesLand from "./NiceToHavesLand";
import { WANT_AD_KEY, WANT_AD_KEY_TEMP,LOT_LAND, INVENTORY_KEY, INVENTORY_KEY_TEMP, IS_LAND} from "../../../constants/common";
import { setLocalStorage, getLocalStorage } from "../../../helpers/common";
import ProgressBarInventory from "../CreateInventory/ProgressBarInventory";
const NiceToHaves = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    isInventory,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isEdit,
    isFromBuyerListingService
  } = props;

  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const MAX_COUNT = prptySubTypes?.includes(6) ? 10 : 16;
  const propertyType = _.get(formValues, "prprtyType", null);

  const [disableSave, setDisableSave] = useState(true);



  const renderNiceToHaves = () => {
    const isLotLand = (isInventory ? propertyType=== IS_LAND : prptySubTypes?.includes(6) )? true : false;
    if (!isLotLand) {
      return (
        <NiceToHavesProperties
          isInventory={isInventory}
          formValues={formValues}
          handleFormValues={handleFormValues}
          setDisableSave={setDisableSave}
          clearPreviousStates={clearPreviousStates}
          adType={adType}
          isEdit={isEdit}
        />
      );
    } else {
      return (
        <NiceToHavesLand
          formValues={formValues}
          isInventory={isInventory}
          handleFormValues={handleFormValues}
          setDisableSave={setDisableSave}
          clearPreviousStates={clearPreviousStates}
          adType={adType}
          isEdit={isEdit}
        />
      );
    }
  };

  return (
    <div className="gitcha-main-wrapper pb-102 bg-white h-100">
      <div className="content-wrapper content-wrapper-h-102-percent">
       {isInventory ? 
          <ProgressBarInventory
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isEdit={isEdit}
          /> :<ProgressBar
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isEdit={isEdit}
          isFromBuyerListingService={isFromBuyerListingService}
        />}
        <div className="inner-layout-content-new bg-white h-100 pe-3 ps-3">
          <div className="top-three-comp-btm-sec mt-0 mb-4">
           { isInventory ? <h6>FEATURES</h6> : <h6>NICE-TO-HAVES</h6>}
            {isInventory ? <h2>{ isAgent ? "What are the features about their property that stand out?" :
              "What are the features about your property that stand out?"}
            </h2>: <h2>
              {isAgent ? "What would be “Nice-to-have” in your client’s " : "What would be “Nice-to-have” in your "}
              {getCombinedText(prptySubTypes).toLowerCase()}?
            </h2>}
            <h4>Select up to {MAX_COUNT}.</h4>
          </div>
          {renderNiceToHaves()}
        </div>
      </div>
      <div className="inner-layout-footer-new">
      <div className="inner-btm-fixed-nav">
        <div className="btn-wrap text-center pb-40 pt-0">
        <button
          type="button"
          className="btn btn-primary w-100 mb-0"
          disabled={disableSave}
          onClick={() => {
            if(isAgent){
              if(completedPercentage>60){
                setCompletedPercentage(completedPercentage)
              }
              else {
                setCompletedPercentage(60)
              }
            }
            else{
              if(completedPercentage>72) {
                setCompletedPercentage(completedPercentage)
              }
              else {
                setCompletedPercentage(72)
              }
            }

            setTimeout(()=>{
              if(isInventory){
                setLocalStorage(INVENTORY_KEY, getLocalStorage(INVENTORY_KEY_TEMP));
              }
              else {setLocalStorage(WANT_AD_KEY, getLocalStorage(WANT_AD_KEY_TEMP));}
              setUIKey(null);
            },500)
            
          }
        }
        >
         {isEdit ? "Update" : "Save"}
        </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default NiceToHaves;
