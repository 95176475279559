import React, { useState, useEffect } from "react";
import { getInvestmentCriteriaCapList, getInvestmentCriteriaList } from "../../../services/commonServices";
import { handleRadioButonChange } from "../../../actions/common";
import ProgressBar from "../ProgressBar";
import { convertToNumber } from "../../../helpers/validations";
import SelectDropDown from "../../../components/Common/SelectDropDown";
import { checkArrayisEqual, filterArrayBasedOnKey, getLocalStorage, renameKey, setLocalStorage } from "../../../helpers/common";
import { WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import MobileDropdownSelect from "../MobileDropdownSelect";

const InvestmentCriteria = (props) => {
  const { completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isEdit,
    isFromBuyerListingService
  } = props;

  const investmentId = _.get(formValues, "investmentId", null);
  const capRateId = _.get(formValues, "capRateId", null);
  const [criteriaList, setCriteriaList] = useState([]);
  const [minCapList, setMinCapList] = useState([]);
  const investmentIdInSession = _.get(getLocalStorage(WANT_AD_KEY), "investmentId", []);
  const [disableSave, setDisableSave] = useState(true);
  const capRateIdInSession = _.get(getLocalStorage(WANT_AD_KEY), "capRateId", []);

  useEffect(() => {
    if (!isEdit) {
        setDisableSave(false);      
    }
    getInvestmentCriteriaList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setCriteriaList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

    getInvestmentCriteriaCapList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          list?.forEach((obj) =>
            renameKey(obj, "CapRateId", "value")
          );
          list?.forEach((obj) =>
            renameKey(obj, "CapRate_Text", "label")
          );
          list.map((rate)=>rate.label = rate.label+"%")
          setMinCapList([{ value: null, label: "Select min rate" },...list]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handlePropertyMultipleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      if (_.includes(investmentId, convertToNumber(value))) {

        subItemArray = investmentId.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {
        subItemArray = investmentId ? investmentId : [];
        subItemArray = [...subItemArray, convertToNumber(value)];
      }
      
      if (isEdit && checkArrayisEqual(subItemArray, investmentIdInSession)) {
        setDisableSave(true);
      }
      else {
        setDisableSave(false)
      }

      handleFormValues({ investmentId: subItemArray });
    }
  };

  const handleMinCapSelection = (e) => {
    if (capRateIdInSession != e.value) {

      handleFormValues({ capRateId: e.value });
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }

  }


  const loadInvestmentCriteria = () => {
    const List =
      criteriaList &&
      criteriaList.map((pt) => {
        const typeId = _.get(pt, "Inv_Cr_Id", "");
        const typeText = _.get(pt, "Inv_Cr_Text", "");
        const isChecked = investmentId && _.includes(investmentId, typeId);
        const radioOrCheckBox =
          _.get(pt, "Buy_IsMultiSelect", 0) == 0 ? true : false;
        return (
          <>

            <div className={isChecked ? "addproperty-type-mob active" : "addproperty-type-mob"}>
              <label class="form-check-label checkbox-label " key={typeId}>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id={typeId}
                  value={typeId ? typeId : ""}
                  checked={isChecked}
                  onChange={(e) => handlePropertyMultipleItemClick(e)}
                />
                <span className="">
                  <div className="addproperty-type-info">
                    <h6>{typeText}</h6>
                  </div></span>
              </label>
            </div>

          </>
        );
      });

    return List;
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />

          <div className="inner-layout-content-new bg-white pb-0 position-relative">
            <div className="select-plan-wrap pl-0 pr-0 pt-0 position-relative">
              <div className="layout-content-btm-sec layout-content-btm-sec-stickyfix">
                <h6 className="">Investment Criteria</h6>
                <h2 className="">
                  What kind of investment criteria are you open to to?
                </h2>
              </div>

              <div className="title-selection-wrap pe-3 ps-3 pt-0">
                {loadInvestmentCriteria()}



                <div className="stay-timing-wrap mb-4 pad-btm-2">
                  <div className="lp-btn-group-wrap">
                    <h4>
                      {" "}
                      Minimum cap rate %
                    </h4>
                    <div className="row">
                      <div className="col-md-12 pe-md-12">
                        <div className="mob-fld-wrap position-relative ms-0 me-0">
                          <label className="form-label">Min</label>

                          <MobileDropdownSelect
                            name="colors3"
                            pattern="[0-9]*" 
                            inputmode="numeric"
                            options={minCapList}
                            //className="lotsize-select custom-lotsize-color"
                            className="mobile-select"
                            classNamePrefix="select"
                            placeholder="Select min rate"
                            value={filterArrayBasedOnKey({
                              list: minCapList,
                              filterKey: "value",
                              value: convertToNumber(capRateId),
                            })}
                            // onInputChange={(e) => handleMinCapSelection(e)}
                            onChange={(e) => handleMinCapSelection(e)}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            styles={{
                              dropdownIndicator: (provided, state) => ({
                                ...provided,
                                transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                              }),
                            }}
                            errorClass="error-msg error-msg-height"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if (isAgent) {
                    if (completedPercentage > 85) {
                      setCompletedPercentage(completedPercentage)
                    }
                    else {
                      setCompletedPercentage(85)
                    }
                  }
                  else {
                    if (completedPercentage > 50) {
                      setCompletedPercentage(completedPercentage)
                    }
                    else {
                      setCompletedPercentage(50)
                    }
                  }
                  setTimeout(() => {

                    setLocalStorage(
                      WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );
                    setUIKey(null);

                  }, 500)
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentCriteria;
