import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import CommonHeader from "../components/Onboarding/CommonHeader";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from "react-redux";
import { fetchFavouriteClients } from "../actions/wantAds";
import { useNavigate } from "react-router-dom"
import { unfavouriteAllWantAd } from "../services/wantAdService";
import { getLocalStorage, setLocalStorage } from "../helpers/common";
import { USER_INFO_KEY, WANT_AD_KEY } from "../constants/common";
import moment from "moment";
import DeleteModal from "../components/Common/DeleteModal";
import { toast } from "react-toastify"
import Select, { components } from 'react-select';
import SelectDropDown from "../components/Common/SelectDropDown";

const FavoriteClients = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pagecount, setPageCount] = useState(10)
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [favoriteClientList, setFavoriteClientList] = useState([]); // storing list
  const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
  const myWantAdList = useSelector((state) => state.wantAds.favouriteClientsList);
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const [sortBy, setSortBy] = useState('recently added')
  const [deleteStatus, setDeleteStatus] = useState(false); //
  const [deleteModalShow, setDeleteModalShow] = useState(false); //
  const [clientId, setClientId] = useState(''); //
  const [showNoResult, setShowNoResult] = useState(true)

  const options = [
    { value: "recently added", label: "Recently Favourited" },
    { value: "name", label: "Name" },
  ];

  // to list favorite client list
  useEffect(() => {
    if (currPage == 0) {
      setFavoriteClientList([])
    }
    if (!wasLastList && prevPage !== currPage) {
      const data = {
        sortBy: sortBy,
        sortDir: 'asc',
        page: currPage,
        limit: pagecount,
      }

      dispatch(fetchFavouriteClients(data))
    }
  }, [currPage, wasLastList, sortBy, deleteStatus])



  // to list favorite clients 
  useEffect(() => {
    setShowNoResult(true)
    if (myWantAdList?.length > 0) {
      setFavoriteClientList([...favoriteClientList, ...myWantAdList]);
      setPrevPage(currPage);
      setWasLastList(false);

    } else {
      setWasLastList(true);
      setShowNoResult(false)
    }
  }, [myWantAdList])

  /**
  * To handle scroll for pagination
  */
  const handleScroll = () => {
    let userScrollHeight = window.innerHeight + window.scrollY;
    let windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      setCurrPage(prevState => prevState + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); // attaching scroll event listener
    if (currPage == 1) {
      setFavoriteClientList([])
    }
  }, []);


  /**
   * To handle sort
   * @param {*} e 
   */
  const handleSortChange = (e) => {
    setShowNoResult(true)
    const value = e.value;
    setSortBy(value)
    setCurrPage(1);
    setPrevPage(0)
    setFavoriteClientList([])
    setWasLastList(false);
  }

  /**
  * want ad  delete button click action
  * @param Id string
  */
  const handleDelete = (id) => {
    setDeleteModalShow(true)
    setClientId(id)
  }

  /**
   * Delete action
   */
  const onDelete = async () => {
    setShowNoResult(true)
    let data = {
      clientId: clientId ? clientId : null

    }
    await unfavouriteAllWantAd(data)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.success("All want-ads of this client has been removed from your favorites.")
          setDeleteModalShow(false)
          setCurrPage(1);
          setPrevPage(0)
          setFavoriteClientList([])
          setWasLastList(false);
          setDeleteStatus(!deleteStatus)
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  /**
   * To list favorite client list
   * @param props 
   * @returns HTML
   */
  function Clients(props) {
    const clients = props.clients

    return (
      <div className="col-md-4 mb-4">
        <div className="my-favorites-wrapper" onClick={() => navigate("/my-favorites", { state: { clientId: clients.ClientId, name: clients.ClientId == null ? 'Unassigned' : clients.FirstName + " " + clients.LastName, count: clients.FavCount } })}>
          <div className="more-info-wrap position-absolute" onClick={(e) => {
            e.stopPropagation();
          }}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic6" className="more-notes-link" variant="" align="end">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 14C13.6046 14 14.5 13.1046 14.5 12C14.5 10.8954 13.6046 10 12.5 10C11.3954 10 10.5 10.8954 10.5 12C10.5 13.1046 11.3954 14 12.5 14Z" fill="#333A48" />
                  <path d="M18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 13.1046 16.8954 14 18 14Z" fill="#333A48" />
                  <path d="M7 14C8.10457 14 9 13.1046 9 12C9 10.8954 8.10457 10 7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14Z" fill="#333A48" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dpdown-menu-style" align={"end"}>
                <Dropdown.Item onClick={() => handleDelete(clients.ClientId)}>Delete folder</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="fav-icn-wrap"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.05018 3.66395C3.33148 3.38265 3.71301 3.22461 4.11084 3.22461H20.6108C21.0087 3.22461 21.3902 3.38264 21.6715 3.66395C21.9528 3.94525 22.1108 4.32679 22.1108 4.72461V19.7246C22.1108 19.9845 21.9762 20.2259 21.7551 20.3626C21.534 20.4993 21.2579 20.5117 21.0254 20.3954L18.3608 19.0631L15.6963 20.3954C15.4851 20.501 15.2366 20.501 15.0254 20.3954L12.3608 19.0631L9.69625 20.3954C9.4851 20.501 9.23658 20.501 9.02543 20.3954L6.36084 19.0631L3.69625 20.3954C3.46376 20.5117 3.18765 20.4993 2.96654 20.3626C2.74543 20.2259 2.61084 19.9845 2.61084 19.7246V4.72461C2.61084 4.32678 2.76888 3.94525 3.05018 3.66395ZM20.6108 4.72461L4.11084 4.72461L4.11084 18.5111L6.02543 17.5538C6.23658 17.4482 6.4851 17.4482 6.69625 17.5538L9.36084 18.8861L12.0254 17.5538C12.2366 17.4482 12.4851 17.4482 12.6962 17.5538L15.3608 18.8861L18.0254 17.5538C18.2366 17.4482 18.4851 17.4482 18.6963 17.5538L20.6108 18.5111V4.72461Z" fill="#1C52B0" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1108 9.97461C13.1108 9.5604 13.4466 9.22461 13.8608 9.22461H18.3608C18.7751 9.22461 19.1108 9.5604 19.1108 9.97461C19.1108 10.3888 18.7751 10.7246 18.3608 10.7246H13.8608C13.4466 10.7246 13.1108 10.3888 13.1108 9.97461Z" fill="#1C52B0" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1108 12.9746C13.1108 12.5604 13.4466 12.2246 13.8608 12.2246H18.3608C18.7751 12.2246 19.1108 12.5604 19.1108 12.9746C19.1108 13.3888 18.7751 13.7246 18.3608 13.7246H13.8608C13.4466 13.7246 13.1108 13.3888 13.1108 12.9746Z" fill="#1C52B0" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.61084 8.47461C5.61084 8.0604 5.94663 7.72461 6.36084 7.72461H10.8608C11.2751 7.72461 11.6108 8.0604 11.6108 8.47461V14.4746C11.6108 14.8888 11.2751 15.2246 10.8608 15.2246H6.36084C5.94663 15.2246 5.61084 14.8888 5.61084 14.4746V8.47461ZM7.11084 9.22461V13.7246H10.1108V9.22461H7.11084Z" fill="#1C52B0" />
          </svg>
          </div>
          <h4>{clients.ClientId == null ? 'Unassigned' : clients.FirstName + " " + clients.LastName} {clients.FavCount > 1 && '(' + clients.FavCount + ')'}</h4>
          <p>Last added {clients.CreatedOn ? moment(new Date(clients.CreatedOn)).format("MM/DD/YY") : ""}</p>

        </div>
      </div>
    )
  }


  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">

            <div className="my-want-ad-wrap">
              <div className="top-sec mb-24 md-mt-24">
                <div className="row">
                  <div className="col">
                    <h5>My Favorites</h5>
                  </div>
                  <div
                    col="12"
                    className="d-flex d-sm-block flex-column col-sm-auto"
                  >
                    <div className="d-flex select-wrap">
                      <Form.Label className="">Sort By:</Form.Label>
                      <SelectDropDown

                          id="multi34"
                          name="colors34"
                          options={options}
                          className="basic-single my-favorites-select mb-0 ms-2"
                          classNamePrefix="select"
                          onChange={(e) => handleSortChange(e)}
                          defaultValue={options[0]}
                          styles={{
                            dropdownIndicator: (provided, state) => ({
                              ...provided,
                              transform:
                                state.selectProps.menuIsOpen && "rotate(180deg)",
                            }),
                          }}
                          errorClass="error-msg error-msg-height"
                        />
                    </div>
                  </div>
                </div>
              </div>

              <div className="favorites-wrap">

                <div className="row">

                  {showNoResult && favoriteClientList !== null && favoriteClientList?.length > 0 ? (
                    favoriteClientList?.map((clients, i) => {
                      return <Clients key={i} clients={clients} ival={i} />
                    })

                  ) : ((!showNoResult) && <div className="no-result-wrap ">
                    <h3>You don't have any favorited want-ads</h3>
                    <p>When you find a want-ad on the feed you want to favorite, use the Heart icon in the top right corner.</p>
                    <button className="btn btn-primary" onClick={() => navigate("/home")}>Browse Ad Feed</button>
                  </div>)}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      {/*  Delete confirmation Modal */}
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        title={"Remove this folder from your favorited want-ads?"}
        message={"Deleting this folder will remove all of the favorited want-ads stored in it."}
        buttonText={"Confirm"}
        handleConfirm={() => onDelete()}
      />
    </>
  );
};

export default FavoriteClients;
