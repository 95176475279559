/**
 * @file   src\form\ShareIntroductionWeb.js
 * @brief  This file is responsible for shared inroductions view
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import BuyerlistingHeader from "./BuyerListingHeader";
import { useParams } from "react-router-dom";
import { getShareIntroductionInventoryDetailsById } from "../services/introductionService";
import PhotoGallery from "../components/Common/PhotoGallery";
import {
  AGENT_USER_MONTHLY_PRO_MEMBER,
  AGENT_USER_YEARLY_PRO_MEMBER,
  BUY,
  INTRODUCTION_EXPIRATION_TIME,
  INVENTORY_KEY,
  INVESTOR_MEMBER_PLAN,
  IS_LAND,
  LOT_TYPES,
  PRO_MEMBER_PLAN,
  ROAD_TYPES,
  USER_TYPE_AGENT,
  WANT_AD_KEY,
  WASTE_WATER_SYSTEM,
  WATER_SUPPLY,
} from "../constants/common";
import {
  convertToCurrencySystem,
  formatTimeLeft,
  removeDecimalZeros,
  toTitleCase,
} from "../helpers/common";
import { convertToNumber } from "../helpers/validations";
import { buildSchoolNeigborhoodHtml } from "../helpers/inventory";
import CheckCircleIcon from "../images/check-circle-icon.svg";
import moment from "moment";
import ErrorMessage from "../components/Common/ErrorMessage";
import CountdownTimer from "../components/Common/CountdownTimer";

const ShareIntroductionWeb = (props) => {
  const { id } = useParams();
  const [inventoryDetails, setInventoryDetails] = useState();

  const photos = _.get(inventoryDetails, "photos", []).filter(
    (item) => item.IsValid != 0
  );
  const photoClass = "inventory-banner position-relative mb-0";

  const propertyType = convertToNumber(
    _.get(inventoryDetails, "Sub_PropertyTypeId", "")
  );
  const isLand = propertyType === IS_LAND;

  const nice2Have = _.get(inventoryDetails, "nice2Have", []);
  const askingPrice = _.get(inventoryDetails, "AskingPrice", "");

  const noBedRm = removeDecimalZeros(_.get(inventoryDetails, "Bedrooms", ""));
  const noBathRm = removeDecimalZeros(_.get(inventoryDetails, "Bathrooms", ""));
  const sqFt = removeDecimalZeros(_.get(inventoryDetails, "Sqft", ""));
  const mapImg = _.get(inventoryDetails, "Location_Img", "");
  const highSchool = _.get(inventoryDetails, "HighSchool", null);
  const neighborHood = _.get(inventoryDetails, "NeighborHood", null);
  const stories =
    _.get(inventoryDetails, "Stories", "") === 0
      ? null
      : _.get(inventoryDetails, "Stories", "");

  const grgeSpots =
    _.get(inventoryDetails, "GarageSpots", "") === 0
      ? null
      : _.get(inventoryDetails, "GarageSpots", "");
  const lotSize =
    _.get(inventoryDetails, "LotSz", "") === 0
      ? null
      : _.get(inventoryDetails, "LotSz", "");
  const description = _.get(inventoryDetails, "Add_Details", "");
  const salesPercnt = _.get(inventoryDetails, "Compensate_Percent", "");
  const isAgent = _.get(inventoryDetails, "UserTypeId", "") === USER_TYPE_AGENT;
  const firstName = _.get(inventoryDetails, "FirstName", "");
  const lastName = _.get(inventoryDetails, "LastName", "");
  const fullName = firstName + " " + lastName;
  const introAcceptedDate = new Date(
    _.get(inventoryDetails, "Accept_Date", "")
  );
  const isNiceToHaveExist = nice2Have.length > 0;
  const AgentInfo = {
    userImage: inventoryDetails?.Img,
    userFirstName: inventoryDetails?.FirstName,
    userLastName: inventoryDetails?.LastName,
    isPremiumAgent: inventoryDetails?.PlanName == PRO_MEMBER_PLAN,
    userCompany: inventoryDetails?.Company,
    Phone: inventoryDetails?.Phone,
  };

  const [isValidId, setIsValidId] = useState(null);
  const [linkExpired, setLinkExpired] = useState(false);
  const [introductionExpired, setIntroductionExpired] = useState(false);

  const [introShareLinkCreatedDate, setIntroShareLinkCreatedDate] =
    useState("");

  useEffect(() => {
    getIntroductionDetails();
  }, [id]);

  const getIntroductionDetails = async () => {
    const splitId = id.split("-");
    const introductionId = splitId[splitId.length - 1];
    if (introductionId) {
      await getShareIntroductionInventoryDetailsById(introductionId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
            setIntroShareLinkCreatedDate(responseResult?.Intro_Unq_ExpDt);
            if (responseResult?.PlanId != 1 || responseResult?.PlanId != 4) {
              // planId 1 and for Lite users
              setIsValidId(1);
            } else {
              setIsValidId(0);
            }
          } else {
            setIsValidId(0);
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  useEffect(() => {
    const checkShareLinkExpired = () => {
      if (introShareLinkCreatedDate) {
        const updatedIntroShareLinkCreatedDate = moment.utc(
          introShareLinkCreatedDate
        ); // Replace with your UTC date string

        // Calculate expiration date
        const introShareLinkCreatedDate72 = moment
          .utc(updatedIntroShareLinkCreatedDate)
          .add(INTRODUCTION_EXPIRATION_TIME, "hours")
          .toISOString();

        // Compare expiration date with current time
        if (new Date(introShareLinkCreatedDate72) - new Date() <= 0) {
          setLinkExpired(true);
        } else {
          setLinkExpired(false);
        }
      }
      if (introAcceptedDate) {
        const updatedIntroAcceptedDate = moment.utc(introAcceptedDate); // Replace with your UTC date string

        // Calculate expiration date
        const introAccepted72 = moment
          .utc(updatedIntroAcceptedDate)
          .add(INTRODUCTION_EXPIRATION_TIME, "hours")
          .toISOString();

        // Compare expiration date with current time
        if (new Date(introAccepted72) - new Date() == 0) {
          setIntroductionExpired(true);
        } else {
          setIntroductionExpired(false);
        }
      }
    };

    // Initial check
    checkShareLinkExpired();

    // Set an interval to check visibility every second
    const interval = setInterval(() => {
      checkShareLinkExpired();
    }, 1000);

    return () => clearInterval(interval);
  }, [introShareLinkCreatedDate, introAcceptedDate]);

  const loadDetails = () => {
    if (isLand) {
      const acreage = removeDecimalZeros(
        _.get(inventoryDetails, "TotalAcre", "")
      );
      if (acreage === 0 || acreage === "0") {
        acreage = "";
      }
      let lotSizeUnit = _.get(inventoryDetails, "Acre_Sqft", "");

      if (lotSizeUnit && lotSizeUnit !== 0) {
        lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft";
      }

      const lotType = _.get(inventoryDetails, "LotType_Rural_City", "");
      const roadType = _.get(inventoryDetails, "RoadType_City_Ptivate", "");
      let isPowerAccess = _.get(inventoryDetails, "Electricity_Yes_No", "");
      const waterSuply = _.get(
        inventoryDetails,
        "Water_Domestic_DrilledWell",
        ""
      );
      const wasteWtrSys = _.get(inventoryDetails, "Waste_Sewer_Septic", "");
      let isApprovedStrctr = _.get(
        inventoryDetails,
        "Property_Approved_Structure",
        ""
      );
      let isInFloodPlain = _.get(inventoryDetails, "Floor_Plan", "");
      if (isPowerAccess || isPowerAccess === 0) {
        if (isPowerAccess === "true" || isPowerAccess === 1) {
          isPowerAccess = "Yes";
        } else {
          isPowerAccess = "No";
        }
      }
      if (isApprovedStrctr || isApprovedStrctr === 0) {
        if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
          isApprovedStrctr = "Yes";
        } else {
          isApprovedStrctr = "No";
        }
      }
      if (isInFloodPlain || isInFloodPlain === 0) {
        if (isInFloodPlain === "true" || isInFloodPlain === 1) {
          isInFloodPlain = "Yes";
        } else {
          isInFloodPlain = "No";
        }
      }
      return (
        <>
          <ul>
            {askingPrice && (
              <li>
                <span className="musthave-label"> Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(askingPrice)}
                </span>
              </li>
            )}

            {acreage && (
              <li>
                <span className="musthave-label">Acreage</span>
                <span className="musthave-value">{acreage + lotSizeUnit}</span>
              </li>
            )}

            {lotType && (
              <li>
                <span className="musthave-label">Lot Type</span>
                <span className="musthave-value">
                  {filterArray(LOT_TYPES, "value", lotType)}
                </span>
              </li>
            )}

            {roadType && (
              <li>
                <span className="musthave-label">Road Type</span>
                <span className="musthave-value">
                  {filterArray(ROAD_TYPES, "value", roadType)}
                </span>
              </li>
            )}
            {isPowerAccess && (
              <li>
                <span className="musthave-label">Power in Property</span>
                <span className="musthave-value">{isPowerAccess}</span>
              </li>
            )}

            {waterSuply && (
              <li>
                <span className="musthave-label">Water Supply</span>
                <span className="musthave-value">
                  {" "}
                  {filterArray(WATER_SUPPLY, "value", waterSuply)}
                </span>
              </li>
            )}
            {wasteWtrSys && (
              <li>
                <span className="musthave-label">Waste Water System</span>
                <span className="musthave-value">
                  {" "}
                  {filterArray(WASTE_WATER_SYSTEM, "value", wasteWtrSys)}
                </span>
              </li>
            )}

            {isApprovedStrctr && (
              <li>
                <span className="musthave-label">Approved for structure</span>
                <span className="musthave-value"> {isApprovedStrctr}</span>
              </li>
            )}
            {isInFloodPlain && (
              <li>
                <span className="musthave-label">Flood plain</span>
                <span className="musthave-value"> {isInFloodPlain}</span>
              </li>
            )}
          </ul>
        </>
      );
    } else {
      let adaAccess = _.get(inventoryDetails, "ADA_Accessible", null);
      if (adaAccess || adaAccess === 0) {
        if (adaAccess === "true" || adaAccess === 1) {
          adaAccess = "Yes";
        } else {
          adaAccess = "No";
        }
      }
      return (
        <>
          <ul>
            {askingPrice && (
              <li>
                <span className="musthave-label"> Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(askingPrice)}
                </span>
              </li>
            )}

            {noBedRm && (
              <li>
                <span className="musthave-label">Beds</span>
                <span className="musthave-value">{noBedRm}</span>
              </li>
            )}
            {noBathRm && (
              <li>
                <span className="musthave-label">Baths</span>
                <span className="musthave-value">{noBathRm}</span>
              </li>
            )}

            {sqFt && (
              <li>
                <span className="musthave-label">Sqft</span>
                <span className="musthave-value">{sqFt}</span>
              </li>
            )}

            {stories && (
              <li>
                <span className="musthave-label">Stories</span>
                <span className="musthave-value">{stories}</span>
              </li>
            )}

            {grgeSpots && (
              <li>
                <span className="musthave-label">Garage Spaces</span>
                <span className="musthave-value">{grgeSpots}</span>
              </li>
            )}
            {lotSize && (
              <li>
                <span className="musthave-label">Lot size</span>
                <span className="musthave-value">{lotSize + " Acres"}</span>
              </li>
            )}

            {adaAccess && (
              <li>
                <span className="musthave-label">ADA Accessible</span>
                <span className="musthave-value">{adaAccess}</span>
              </li>
            )}
          </ul>
        </>
      );
    }
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };

  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueType}>
            <div className="nicetohaves-tag-icon">
              <img src={icon ? require("../images/" + icon) : LakeViewIcon} />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  return (
    <>
      {isValidId === 0 ? (
        <ErrorMessage
          message="The url is not valid"
          wantAdData={AgentInfo}
          isFromSharePublicPage={true}
        />
      ) : linkExpired ? (
        <ErrorMessage
          message="The URL cannot be accessed as the link has expired."
          wantAdData={AgentInfo}
          isFromSharePublicPage={true}
        />
      ) : (introductionExpired && inventoryDetails?.IsReplied !== 1) ||
        inventoryDetails?.Not_A_Fit == 1 ? (
        <ErrorMessage
          message="The URL cannot be accessed as the introduction has expired."
          wantAdData={AgentInfo}
          isFromSharePublicPage={true}
        />
      ) : isValidId === 1 ? (
        <div
          className="outer-wrap outer-wrap-padding position-relative mw-1168 pb-0"
          id="pageTop"
        >
          <BuyerlistingHeader wantAdData={AgentInfo} />
          <div className="row">
            <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-8 ps-xl-2">
              <div className="inventory-confirmation-wrap boxShadowStyle">
                <div className="confirmation-top-sec ">
                  <div className="subTitle">
                    <h2 className="long-txt-fix">
                      {_.get(inventoryDetails, "Location", "")}
                    </h2>
                    {/* <h6>Eugene, OR 99999</h6> */}
                  </div>

                  <div>
                    <PhotoGallery
                      photos={photos}
                      photoClass={photoClass}
                      isFromDetailsPage={true}
                    />

                    <div className="musthave-wrap">
                      <h2>Details</h2>
                      {loadDetails()}
                    </div>

                    {description && (
                      <div className="musthave-wrap">
                        <h2>Description</h2>
                        <p className="fnt-14">{description}</p>
                      </div>
                    )}

                    {isNiceToHaveExist ? (
                      <div className="nicetohaves-wrap">
                        <h2>Features</h2>
                        <p className="fnt-14 pb-2 nicetohaves-inner-wrap">
                          {finalNiceToHaveList()}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {buildSchoolNeigborhoodHtml({
                      mapImg: mapImg,
                      highSchool: highSchool,
                      neighborHood: neighborHood,
                    })}

                    {salesPercnt ||
                    _.get(inventoryDetails, "TimeLineText", null) ||
                    _.get(inventoryDetails, "Appraisal_on_File", false) ||
                    _.get(inventoryDetails, "Inspection_Report", false) ||
                    _.get(inventoryDetails, "Seller_Finance", false) ||
                    _.get(inventoryDetails, "Flood_Certificate", false) ||
                    _.get(inventoryDetails, "Floor_Plan", false) ||
                    _.get(inventoryDetails, "Assumable_mortgage", false) ? (
                      <div className="buyer-info">
                        <h2 className="mb-16">Additional details</h2>
                        <ul>
                          {salesPercnt && (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              <div>
                                <span className="f-bold">
                                  {salesPercnt}% compensation&nbsp;
                                </span>{" "}
                                to buyers agent
                              </div>
                            </li>
                          )}

                          {_.get(inventoryDetails, "TimeLineText", null) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Timeline:{" "}
                              {_.get(inventoryDetails, "TimeLineText", null)}
                            </li>
                          ) : (
                            ""
                          )}

                          {_.get(
                            inventoryDetails,
                            "Appraisal_on_File",
                            false
                          ) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Appraisal on file
                            </li>
                          ) : (
                            ""
                          )}

                          {_.get(
                            inventoryDetails,
                            "Inspection_Report",
                            false
                          ) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Inspection on file
                            </li>
                          ) : (
                            ""
                          )}

                          {_.get(inventoryDetails, "Seller_Finance", false) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Seller finance available
                            </li>
                          ) : (
                            ""
                          )}
                          {_.get(
                            inventoryDetails,
                            "Flood_Certificate",
                            false
                          ) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Flood certification
                            </li>
                          ) : (
                            ""
                          )}

                          {_.get(inventoryDetails, "Floor_Plan", false) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Floor plan on file
                            </li>
                          ) : (
                            ""
                          )}

                          {_.get(
                            inventoryDetails,
                            "Assumable_mortgage",
                            false
                          ) ? (
                            <li className="f-size-16">
                              <img
                                src={CheckCircleIcon}
                                className="align-text-bottom pe-2 mt-1"
                              />
                              Assumable mortgage
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2">
              <div className="want-to-know-wrap mb-0 mb-sm-4 boxShadowStyle">
                <h4>Want to know more?</h4>
                <p>
                  Let {toTitleCase(inventoryDetails?.FirstName)} know if you’re
                  interested in this property or any questions you may have.
                </p>
                <CountdownTimer
                  startDate={introShareLinkCreatedDate}
                  isFromSharePublicPage={true}
                />
                {/* <div className="linkExpiry">
                <img src={clock} className="pe-2" /> This link will expire in <b>{timeAgo?.time} {timeAgo?.type}</b>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ShareIntroductionWeb;
