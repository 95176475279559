import React, { useState, useEffect } from "react";
import ResetPassword from "../Onboarding/UserRegister/ResetPassword";
import CheckResetEmailModal from "../Onboarding/UserRegister/CheckResetEmailModal";
import { resetModalemailOnly } from "../../constants/onBoarding";

import { resetPassword, updatePassword } from "../../services/loginService";
import { getUserId } from "../../helpers/common";

const AccountResetPassword = (props) => {
  const { isGoogleUser } = props;
  const [resetPwdModal, setResetPwdModal] = useState(false);
  const [resetPwdModalType, setResetModalType] = useState(resetModalemailOnly);
  const [resetEmailModalState, setResetEmailState] = useState(false);
  const [email, setEmail] = useState("");

  const handleResetPwdModal = ({ showResetModal, resetPwdModalType }) => {
    setResetModalType(resetPwdModalType);
    setResetPwdModal({ showResetModal: showResetModal, isFromSettings: true , userId : getUserId()});
  };

  const handleResetEmailModal = ({ modalState }) => {
    setResetEmailState(modalState);
  };

  const changePassWord = async (params) => {
    const email = _.get(params, "email", null);
    if (email) {
      return await resetPassword(params).then((response) => {
        if (response) {
          const resResult = _.get(response, "result", null);
          if (_.get(response, "errorCode", null) === 0) {
            if (_.get(resResult, "IsEmailVerified", null) !== 0) {
              setResetEmailState(true);
              setResetPwdModal({ showResetModal: false });
              setEmail(email);
              return { isVerified: true };
            } else {
              return {
                isVerified: false,
                message:
                  "Email id is not verified. Please contact support team.",
              };
            }
          } else {
            return { isVerified: false, message: "something went wrong." };
          }
        }
        return { isVerified: false, message: "something went wrong." };
      });
    } else {
      return await updatePassword(params).then((response) => {
        if (response) {
          if (_.get(response, "errorCode", "") === 0) {
            setResetPwdModal({ showResetModal: false });
            toast.success("Password changed successfully.");
          } else {
            return response;
          }
        }
        return null;
      });
    }
  };

  return (
    <>
      {isGoogleUser!== 1 && (
        <>
          <div
            className="field-horizontal link-cursor"
            onClick={() =>
              handleResetPwdModal({
                showResetModal: true,
                resetPwdModalType: resetModalemailOnly,
              })
            }
          >
            <label>Password</label>
            <p>*********</p>
          </div>

          <ResetPassword
            resetPwdModal={resetPwdModal}
            handleResetPwdModal={handleResetPwdModal}
            resetPwdModalType={resetPwdModalType}
            resetPassWord={changePassWord}
          />
          <CheckResetEmailModal
            show={resetEmailModalState}
            email={email}
            onHide={handleResetEmailModal}
            process="ResetPwdFromSetting"
          />
        </>
      )}
    </>
  );
};
export default AccountResetPassword;
