import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import NxtArrow from "../components/nextArrow";
import BottomStepperMob from "../components/BottomStepperMob";
import CheckIcn from "../images/checkIcn.svg";
import { convertToNumber } from "../../helpers/validations";
import {
  convertToCurrencySystem,
  getLocalStorage,
  getUserName,
  removeLocalStorage,
  setLocalStorage,
  truncate,
} from "../../helpers/common";
import { getNiceToHaves } from "../../services/commonServices";
import {
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  INVENTORY_LAND,
  IS_LAND,
} from "../../constants/common";
import { checkInventoryType } from "../../helpers/inventory";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
const InventoryMemberAddProperty = (props) => {
  const {
    setUIKey,
    formValues,
    completedPercentage,
    uiKey,
    handleSaveAndExit,
    isPremiumUser,
    isAgent,
    isEdit,
    handleSave,
  } = props;
  const navigate = useNavigate();
  const propertyType = _.get(formValues, "prprtyType", null);
  const propertySubType = _.get(formValues, "prptySubType", null);
  const prptyTypeTxt = _.get(formValues, "prptyTypeTxt", null);
  const subTypeText =
    _.get(formValues, "subTypeText", null) ||
    _.get(formValues, "prptySubTypeTxt", null);
  const locationAddress = _.get(formValues, "locAddress", null);
  const noBedRm = _.get(formValues, "noBedRm", null);
  const noBathRm = _.get(formValues, "noBathRm", null);
  const proprtyPurpose = _.get(formValues, "proprtyPurpose", []);
  const nice2Have = _.get(formValues, "nice2Have", null);
  const isDisabled = proprtyPurpose && proprtyPurpose.length > 0 ? false : true;
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const isLand = propertyType === IS_LAND ? true : false;
  const sellAskngPrice = _.get(formValues, "sellAskngPrice", null);
  const LTRAskngPrce = _.get(formValues, "LTRAskngPrce", null);
  const STRAskngPrce = _.get(formValues, "STRAskngPrce", null);
  const isCompnsteAgent = _.get(formValues, "isCompnsteAgent", null);
  const isCompnsteAgentLTR = _.get(formValues, "isCompnsteAgent_LTR", null);
  const salesPercnt = _.get(formValues, "salesPercnt", null);
  const salesPercntLTR = _.get(formValues, "salesPercnt_LTR", null);
  const isAgentAsSeller = _.get(formValues, "isAgentAsSeller", null);
  const photos = _.get(formValues, "photos", []);
  const imageCount = photos && photos.length > 0 ? photos.length : 0;
  const clientName = isAgentAsSeller
    ? getUserName()
    : _.get(formValues, "clientName", null);
  const apprOnFile = _.get(formValues, "apprOnFile", null);
  const insReport = _.get(formValues, "insReport", null);
  const sellerFin = _.get(formValues, "sellerFin", null);
  const floorPlan = _.get(formValues, "floorPlan", null);
  const floodCert = _.get(formValues, "floodCert", null);
  const assMortgage = _.get(formValues, "assMortgage", null);
  const timeLine = _.get(formValues, "timeLine", null);
  const tlText =
    _.get(formValues, "tlText", null) || _.get(formValues, "timeLineTxt", null);
  const description = _.get(formValues, "invtryDetails", "");

  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [proprtyPurposeError, setproprtyPurposeError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [photosError, setPhotosError] = useState(false);
  const [clientError, setClientError] = useState(false);

  const getAdditiionalDetailsText = () => {
    let resultText = "";
    if (apprOnFile) {
      resultText = "Appraisal on file";
    }
    if (insReport) {
      resultText =
        resultText.length > 0
          ? resultText + ", Inspection report on file"
          : "Inspection report on file";
    }
    if (sellerFin) {
      resultText =
        resultText.length > 0
          ? resultText + ", Seller finance available"
          : "Seller finance available";
    }
    if (floorPlan) {
      resultText =
        resultText.length > 0
          ? resultText + ", Floor plan on file"
          : "Floor plan on file";
    }
    if (floodCert) {
      resultText =
        resultText.length > 0
          ? resultText + ", Flood certificate available"
          : "Flood certificate available";
    }
    if (assMortgage) {
      resultText =
        resultText.length > 0
          ? resultText + ", Assumable mortgage"
          : "Assumable mortgage";
    }

    return truncate(resultText, 30);
  };

  const getBudgetText = () => {
    let resultText = "";
    if (sellAskngPrice) {
      resultText = "$" + convertToCurrencySystem(sellAskngPrice);
    }
    if (salesPercnt) {
      resultText =
        resultText + " | Offering " + salesPercnt + "% to buyer's agent";
    }
    if (LTRAskngPrce) {
      if (resultText.length > 0) {
        resultText =
          resultText +
          ", " +
          "$" +
          convertToCurrencySystem(LTRAskngPrce) +
          "/month";
      } else {
        resultText = "$" + convertToCurrencySystem(LTRAskngPrce) + "/month";
      }
    }
    if (salesPercntLTR) {
      resultText =
        resultText + " | Offering " + salesPercntLTR + "% to renter's agent";
    }
    if (STRAskngPrce) {
      if (resultText.length > 0) {
        resultText =
          resultText +
          ", " +
          "$" +
          convertToCurrencySystem(STRAskngPrce) +
          "/night";
      } else {
        resultText = "$" + convertToCurrencySystem(STRAskngPrce) + "/night";
      }
    }
    return resultText;
  };

  const getPropertyText = () => {
    if (propertyType) {
      if (convertToNumber(propertyType) === 6) {
        return prptyTypeTxt;
      } else {
        return prptyTypeTxt + " - " + subTypeText;
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    const invType = checkInventoryType(proprtyPurpose);

    if (nice2Have && nice2Have?.length > 0 === true) {
      getNiceToHaves(isLand ? INVENTORY_LAND : invType)
        ?.then((response) => {
          if (response.errorCode === 0 && _.get(response, "result", null)) {
            const list = _.get(response, "result", null);
            setNiceToHavesList(list);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, [uiKey === null, nice2Have]);

  const getLocationAndDetails = () => {
    if (convertToNumber(propertyType) !== 6) {
      if (convertToNumber(propertyType) != 11) {
        if (locationAddress && noBedRm && noBathRm) {
          return truncate(
            locationAddress +
              " / " +
              parseInt(noBedRm) +
              " Beds" +
              ", " +
              parseInt(noBathRm) +
              " Baths",
            30
          );
        } else {
          return "";
        }
      } else {
        if (locationAddress) {
          return truncate(locationAddress, 30);
        } else {
          return "";
        }
      }
    } else {
      if (locationAddress) {
        return truncate(locationAddress, 30);
      } else {
        return "";
      }
    }
  };

  const getPropertyPurposeText = () => {
    let resultText = "";
    if (proprtyPurpose && proprtyPurpose.length > 0) {
      if (proprtyPurpose.includes("SELL")) {
        resultText = "Find Buyers";
      }
      if (proprtyPurpose.includes("LTR") || proprtyPurpose.includes("STR")) {
        if (resultText.length === 0) {
          resultText = "Find Renters";
        } else {
          resultText = resultText + " , Find Renters";
        }
      }
    }
    return resultText;
  };

  const loadNiceToHaveList = (masterList, selectedItems) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        return itemText;
      })
    );
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);

    const masterArray = _.get(niceToHavesList, section, []);

    if (selectedItems?.length) {
      return loadNiceToHaveList(masterArray, selectedItems);
    } else {
      return "";
    }
  };

  const getNiceToHavesDisplaytext = () => {
    let resultText = "";
    const niceToHaveMasterArray = isLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    const combinedArray =
      finalList && finalList.length > 0
        ? finalList?.filter((item) => item != "")
        : [];
    let merged =
      combinedArray && combinedArray.length > 0
        ? combinedArray.reduce(function (prev, next) {
            return prev.concat(next);
          })
        : [];

    merged = merged?.filter((item) => item != "");
    if (merged?.length > 0) {
      resultText = merged[0];
      if (merged?.length > 1) {
        for (let i = 1; i < merged?.length - 1; i++) {
          resultText = resultText + ", " + merged[i];
        }
        resultText = resultText + " or " + merged[merged?.length - 1];
      }
      return truncate(resultText, 30);
    } else {
      return "";
    }
  };

  const getNiceToHaveText = () => {
    if (isDisabled) {
      return "Select property use first";
    } else if (nice2Have && nice2Have?.length > 0) {
      return getNiceToHavesDisplaytext();
    } else {
      return "Select features";
    }
  };

  const validate = () => {
    let error = []
    let isValid = true;
    if (isPremiumUser && !clientName) {
      error.push("client")
      setClientError(true);
      isValid = false;
    }
    if (getPropertyText() == "") {
      error.push("propertyType")
      setPropertyTypeError(true);
      isValid = false;
    }
    if (!getLocationAndDetails() != "") {
      error.push("location")
      setLocationError(true);
      isValid = false;
    }
    if (!proprtyPurpose || (proprtyPurpose && proprtyPurpose.length == 0)) {
      error.push("propertyPurpose")
      setproprtyPurposeError(true);
      isValid = false;
    }
    if (getBudgetText() == "") {
      error.push("budget")
      setPriceError(true);
      isValid = false;
    }

    if (imageCount === 0) {
      error.push("photos");
      setPhotosError(true);
      isValid = false;
    }

    if (error.length != 0) {
      if (error[0] == 'client') {
        document.getElementById('client-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'propertyType') {
        document.getElementById('property-type-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'location') {
        document.getElementById('location-id')?.scrollIntoView({ behavior: 'smooth' });
      }  else if (error[0] == 'propertyPurpose') {
        document.getElementById('property-purpose-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'budget') {
        document.getElementById('budget-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'photos') {
        document.getElementById('photos-id')?.scrollIntoView({ behavior: 'smooth' });
      } 
    }  
    return isValid;
  };

  const validateSaveAndExit = () => {
    let error = [];
    let isValid = true;
    if (isPremiumUser && !clientName) {
      setClientError(true);
      error.push("client")
      isValid = false;
    }
    if (getPropertyText() == "") {
      error.push("propertyType")
      setPropertyTypeError(true);
      isValid = false;
    }
    if (!getLocationAndDetails() != "") {
      setLocationError(true);
      error.push("location")
      isValid = false;
    }
    if (!proprtyPurpose || (proprtyPurpose && proprtyPurpose.length == 0)) {
      setproprtyPurposeError(true);
      error.push("propertyPurpose")
      isValid = false;
    }
    if (getBudgetText() == "") {
      setPriceError(true);
      error.push("budget")
      isValid = false;
    }
    if (error.length != 0) {
      if (error[0] == 'client') {
        document.getElementById('client-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'propertyType') {
        document.getElementById('property-type-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'location') {
        document.getElementById('location-id')?.scrollIntoView({ behavior: 'smooth' });
      }  else if (error[0] == 'propertyPurpose') {
        document.getElementById('property-purpose-id')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'budget') {
        document.getElementById('budget-id')?.scrollIntoView({ behavior: 'smooth' });
      } 
    } 
    return isValid;
  };

  const getClassName = (condition, error) => {
    if (error) {
      return "selectBoxWrap d-flex mb-3 error-brdr";
    } else if (condition) {
      return "selectBoxWrap select d-flex mb-3";
    } else {
      return "selectBoxWrap d-flex mb-3";
    }
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-0 h-100">
        <div className="content-wrapper vh-100 android-h-fix">
          <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 flex-column">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="d-block back-link-big me-4"
                  href={void 0}
                  onClick={() => {
                    if (isEdit) {
                      setLocalStorage(INVENTORY_KEY, {
                        inventoryId: _.get(formValues, "inventoryId", null),
                        isFromHomeFeed: true,
                      });
                      removeLocalStorage(INVENTORY_KEY_TEMP);
                      navigate(pageURLs.mobilememberInventoryDetails);
                    } else {
                      setUIKey("location");
                    }
                  }}
                >
                  back
                </a>
                {!isEdit && (
                  <div className="progress-wrap-mob">
                    <BottomStepperMob
                      completedPercentage={completedPercentage}
                    />
                  </div>
                )}
                <button className="btn top-exit-btn ms-4">&nbsp;</button>
              </div>
            </div>
          </div>
          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div className="layout-content-btm-sec position-static pe-0 ps-0">
              {isEdit ?<>
                <h2>Edit Property</h2>
                <p className="titleTextpara mt-2">
                Confirm the details about your property. Select an item to edit it.
                </p>
                </> : <>
                <h2>Add property to inventory</h2>
                <p className="titleTextpara mt-2">
                  Tap on the section to add your response. Items marked with an
                  asterisk have required fields.
                </p>
              </>}
            </div>
            {isPremiumUser && (
              <div
                className={getClassName(clientName, clientError)}
                onClick={() => setUIKey("client")}
                id="client-id"
              >
                <div className="flex-grow-1">
                  <h4>
                    Client * {clientName ? <img src={CheckIcn} /> : <></>}
                  </h4>
                  <h5>{clientName ? clientName : "Select client"}</h5>
                </div>
                <div className="align-self-center ">
                  <Button variant="" id="button-addon2">
                    <NxtArrow />
                  </Button>
                </div>
              </div>
            )}
            <div
              className={getClassName(
                getPropertyText() != "",
                propertyTypeError
              )}
              onClick={() => setUIKey("propertyType")}
              id="property-type-id"
            >
              <div className="flex-grow-1">
                <h4>
                  Property Type *{" "}
                  {getPropertyText() != "" ? <img src={CheckIcn} /> : <></>}
                </h4>
                <h5>
                  {getPropertyText() != ""
                    ? getPropertyText()
                    : "Select property type"}
                </h5>
              </div>
              <div className="align-self-center">
                <Button variant="">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            <div
              className={getClassName(
                getLocationAndDetails() != "",
                locationError
              )}
              // getLocationAndDetails()!="" ? "selectBoxWrap d-flex select mb-3" : "selectBoxWrap d-flex mb-3"
              // }
              onClick={() => setUIKey("location")}
              id="location-id"
            >
              <div className="flex-grow-1">
                <h4>
                  Location & Details *{" "}
                  {getLocationAndDetails() != "" ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>
                  {getLocationAndDetails() != ""
                    ? getLocationAndDetails()
                    : "Select location and details"}
                </h5>
              </div>
              <div className="align-self-center">
                <Button variant="">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            <div
              className={
                getClassName(
                  proprtyPurpose && proprtyPurpose.length > 0,
                  proprtyPurposeError
                )
                // proprtyPurpose && proprtyPurpose.length>0 ? "selectBoxWrap select d-flex mb-3" : "selectBoxWrap d-flex mb-3"
              }
              onClick={() => setUIKey("propertyUse")}
              id="property-purpose-id"
            >
              <div className="flex-grow-1">
                <h4>
                  Property Use *{" "}
                  {proprtyPurpose && proprtyPurpose.length > 0 ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>
                  {proprtyPurpose && proprtyPurpose.length > 0
                    ? getPropertyPurposeText()
                    : "Select use"}
                </h5>
              </div>
              <div className="align-self-center">
                <Button variant="">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            <div
              className={
                !isDisabled
                  ? nice2Have && nice2Have?.length > 0 != ""
                    ? "selectBoxWrap d-flex mb-3 select"
                    : "selectBoxWrap d-flex mb-3"
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("niceToHaves");
                }
              }}
            >
              <div className="flex-grow-1">
                <h4>
                  Features{" "}
                  {nice2Have && nice2Have?.length > 0 && !isDisabled && (
                    <img src={CheckIcn} />
                  )}
                </h4>
                <h5>{getNiceToHaveText()}</h5>
              </div>
              <div className="align-self-center">
                <Button variant="">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            <div
              className={
                !isDisabled
                  ? getClassName(getBudgetText() != "", priceError)
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              id="budget-id"
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("askingPrice");
                }
              }}
            >
              <div className="flex-grow-1">
                <h4>
                  Asking Price *{" "}
                  {getBudgetText() != "" ? <img src={CheckIcn} /> : <></>}
                </h4>
                <h5>
                  {!isDisabled
                    ? getBudgetText() != ""
                      ? truncate(getBudgetText(), 30)
                      : "Select asking price"
                    : "Select property use first"}
                </h5>
              </div>
              <div className="align-self-center">
                <Button variant="">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            <div
              className={
                getClassName(imageCount > 0, photosError)
                // imageCount > 0 ? "selectBoxWrap select mb-3 d-flex" : "selectBoxWrap mb-3 d-flex"
              }
              onClick={() => setUIKey("photos")}
              id="photos-id"
            >
              <div className="flex-grow-1">
                <h4>
                  Photos * {imageCount > 0 ? <img src={CheckIcn} /> : <></>}
                </h4>
                <h5>
                  {imageCount > 0
                    ? imageCount === 1
                      ? imageCount + " photo uploaded"
                      : imageCount + " photos uploaded"
                    : "Upload photos"}{" "}
                </h5>
              </div>
              <div className="align-self-center">
                <Button variant="">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            {isEdit && (
              <div
                className={
                  description != "" && description != null
                    ? "selectBoxWrap select mb-3 d-flex"
                    : "selectBoxWrap mb-3 d-flex"
                }
                onClick={() => setUIKey("description")}
              >
                <div className="flex-grow-1">
                  <h4>
                    Description{" "}
                    {description != "" && description != null ? (
                      <img src={CheckIcn} />
                    ) : (
                      <></>
                    )}
                  </h4>
                  <h5>
                    {description != "" && description != null
                      ? description
                      : "Add description"}{" "}
                  </h5>
                </div>
                <div className="align-self-center">
                  <Button variant="">
                    <NxtArrow />
                  </Button>
                </div>
              </div>
            )}
            {isAgent ? (
              <>
                <div
                  className={
                    getAdditiionalDetailsText() != ""
                      ? "selectBoxWrap mb-3 d-flex select"
                      : "selectBoxWrap mb-3 d-flex"
                  }
                  onClick={() => setUIKey("additionalInformation")}
                >
                  <div className="flex-grow-1">
                    <h4>
                      Additional Information{" "}
                      {getAdditiionalDetailsText() != "" ? (
                        <img src={CheckIcn} />
                      ) : (
                        ""
                      )}
                    </h4>
                    <h5>
                      {getAdditiionalDetailsText() != ""
                        ? getAdditiionalDetailsText()
                        : "Add information"}{" "}
                    </h5>
                  </div>
                  <div className="align-self-center">
                    <Button variant="">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>
                <div
                  className={
                    timeLine
                      ? "selectBoxWrap mb-3 d-flex select"
                      : "selectBoxWrap mb-3 d-flex"
                  }
                  onClick={() => setUIKey("timeline")}
                >
                  <div className="flex-grow-1">
                    <h4>
                      Timeline {timeLine ? <img src={CheckIcn} /> : <></>}
                    </h4>
                    <h5>{timeLine ? tlText : "Select timeline"} </h5>
                  </div>
                  <div className="align-self-center">
                    <Button variant="">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="inner-btm-fixed-nav shadow-none pe-0 ps-0">
              <div className="btn-wrap text-center pb-40 pt-0">
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-20"
                  onClick={() => {
                    const valid = validate();
                    if (valid) {
                      if (isEdit) {
                        handleSave();
                      } else {
                        setLocalStorage(
                          INVENTORY_KEY,
                          getLocalStorage(INVENTORY_KEY_TEMP)
                        );
                        setUIKey("description");
                      }
                    }
                  }}
                >
                  {isEdit ? "Update Changes" : "Next"}
                </button>
                {!isEdit && (
                  <button
                    type="button"
                    className="btn btn-no-bg btn-no-bg-fix w-100"
                    onClick={() => {
                      const valid = validateSaveAndExit();
                      if (valid) {
                        handleSaveAndExit();
                      }
                    }}
                  >
                    Save & Exit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryMemberAddProperty;
