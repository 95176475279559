import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Carousel } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InventoryLocationMap from "../../images/inventory-location-map.png";
import Gasfireicon from "../../images/gas-firepalce-icon.svg";
import Riverviewicon from "../../images/river-view.svg";
import Basementicon from "../../images/basement.svg";
import BonusRoomicon from "../../images/bonus-room.svg";
import Fenceyardicon from "../../images/fence-icon.svg";
import BedIcon from "../../images/bed-icon-big.svg";
import BathIcon from "../../images/bath-icon-big.svg";
import LotSizeIcon from "../../images/lot-size-icon.svg"
import SqftIcon from "../../images/sqft-icon-big.svg";
import Sliderimg1 from "../../images/img-house-1.png";
import Sliderimg2 from "../../images/img-house-2.png";
import Sliderimg3 from "../../images/img-house-3.png";
import IntroPreviewEye from "../../images/intro-preview-eyeicon.svg";
import { filterArray, loggedInUser, removeDecimalZeros, thousandSeperatedValue } from "../../helpers/common";
import moment from "moment";
import noImage from "../../images/no-image-placeholder.png";
import { IS_LAND, LOT_TYPES, USER_TYPE_AGENT, USER_TYPE_MEMBER } from "../../constants/common";
import { getGeoLocation } from "../../services/commonServices";
import { convertToNumber } from "../../helpers/validations";
import GoogleMaps from "./GoogleMap";


const IntroductionPreviewModal = (props) => {
    const { show, onHide, selectedProperty } = props;
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip2" {...props}>
          Close
        </Tooltip>
      );

    const inventoryPhoto = selectedProperty?.photosForPreview;
    const coverPhotos = inventoryPhoto?.filter((item) => item.isCover == 1) ?? [];
    const askingPrice = selectedProperty?.askingPrice ? selectedProperty?.askingPrice : selectedProperty?.defaultAskingPrice;
    const noBedRm = selectedProperty?.noBedRm;
    const noBathRm = selectedProperty?.noBathRm;
    const sqFt = selectedProperty?.sqFt;
    const lotSize = selectedProperty?.lotSize;
    const location = selectedProperty?.selectedProperty?.Location;
    const featuredProperty = selectedProperty?.propertyFeaturesForPreview;
    const userDetails = loggedInUser();
    const userType = userDetails?.userInfo?.userType;
    const currentDate = moment(Date.now()).format("MM/DD/YYYY");
    const salesPercnt = selectedProperty?.salesPercnt;
    const insReport = selectedProperty?.insReport;
    const apprOnFile = selectedProperty?.apprOnFile;
    const floodCert = selectedProperty?.floodCert;
    const isLand = selectedProperty?.propertyTypeId === IS_LAND;
    const acreage = selectedProperty?.acreage;
    const lotType = selectedProperty?.lotType;
    const timeLineTxt = selectedProperty?.timeLineTxt;
    const floorPlan = selectedProperty?.floorPlan;
    const sellerFin = selectedProperty?.sellerFin;
    const city = selectedProperty?.city;
    const state = selectedProperty?.state;

    const [userCoords, setUserCoords] = useState({})
    const handleUserCoords = (lat, lng) => {
      setUserCoords({lat : lat, lng: lng })
    }
   //const userLocation = getLocalStorage(USER_LOCATION_KEY)
    const defaultProps = {
      center: {
        lat: convertToNumber(_.get(userCoords, 'lat', '')),
        lng:  convertToNumber(_.get(userCoords, 'lng', '')),
      },
      zoom: 12,
    };
   
    {/*const directionButtons = (direction) => {
        return (
          <span
            aria-hidden="true"
            className={direction === "Next" ? "button-next" : "button-prev"}
            
          >
            {direction}
          </span>
        );
      };*/}

      {/*const corousalRef = useRef(null);
      const handleSlideLeft = () => {
         corousalRef.current.prev();
       };
       const handleSlideRight = () => {
         corousalRef.current.next();
       };
       const handleSelect = (selectedIndex) => {
         setIndex(selectedIndex);
       };*/}

    // useEffect(() => {
    //     // const reqPayload = `address=${selectedProperty?.city}, +${selectedProperty?.state}`
  
    //     // getGeoLocation(reqPayload).then(data => {
    //     //  const results = _.get(data, 'results')
    //     //  if(results?.length> 0) {
    //     //   const firstLocationResult =  _.get(results[0], 'geometry.location', '')  
    //     //   const lat = _.get(firstLocationResult, 'lat', '')
    //     //   const lng = _.get(firstLocationResult, 'lng', '')
    //     //   handleUserCoords(lat, lng)
    //     //  } else{
  
    //     //  }
    //     // });
    // }, [selectedProperty?.city, selectedProperty?.state]);

    useEffect(() => {
        handleUserCoords(selectedProperty?.lat, selectedProperty?.lng)
    }, [selectedProperty?.lat, selectedProperty?.lng]);

    return (
        
        <Modal

            show={show}
            {...props}

            aria-labelledby="accept-modal-title-vcenter"
            

            className="client-notes-modal accept-intro-modal right"
        >
            <Modal.Header>
                <div className="intro-preview-heading">
                    <img src={IntroPreviewEye} className="me-2" />

                    Introduction preview
                </div>
                <Button variant="" className="btn btn-tertiary" onClick={onHide}>Exit preview</Button>

            </Modal.Header>
            <Modal.Body className="modal-body-padtop modal-body-padbtm modal-pad-btm-fix">
                <div className="client-notes-modal-top">
                    <div className="flex-grow-1">
                        <h3>{isLand ? "Lot/Land" : "House"} in {city}, {state}</h3>
                        {/* <p>introduced {currentDate}</p> */}
                        <h5>${thousandSeperatedValue(askingPrice)}</h5>
                    </div>
                    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
                    <button type="button" class="btn btn-slide-right flex-shrink-0" aria-label="Close" onClick={onHide}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.781 12.781L5.28104 20.281C5.14031 20.4218 4.94944 20.5008 4.75042 20.5008C4.55139 20.5008 4.36052 20.4218 4.21979 20.281C4.07906 20.1403 4 19.9494 4 19.7504C4 19.5514 4.07906 19.3605 4.21979 19.2198L11.1901 12.2504L4.21979 5.28104C4.07906 5.14031 4 4.94944 4 4.75042C4 4.55139 4.07906 4.36052 4.21979 4.21979C4.36052 4.07906 4.55139 4 4.75042 4C4.94944 4 5.14031 4.07906 5.28104 4.21979L12.781 11.7198C12.8508 11.7894 12.9061 11.8722 12.9438 11.9632C12.9816 12.0543 13.001 12.1519 13.001 12.2504C13.001 12.349 12.9816 12.4466 12.9438 12.5376C12.9061 12.6287 12.8508 12.7114 12.781 12.781ZM20.281 11.7198L12.781 4.21979C12.6403 4.07906 12.4494 4 12.2504 4C12.0514 4 11.8605 4.07906 11.7198 4.21979C11.5791 4.36052 11.5 4.55139 11.5 4.75042C11.5 4.94944 11.5791 5.14031 11.7198 5.28104L18.6901 12.2504L11.7198 19.2198C11.5791 19.3605 11.5 19.5514 11.5 19.7504C11.5 19.9494 11.5791 20.1403 11.7198 20.281C11.8605 20.4218 12.0514 20.5008 12.2504 20.5008C12.4494 20.5008 12.6403 20.4218 12.781 20.281L20.281 12.781C20.3508 12.7114 20.4061 12.6287 20.4438 12.5376C20.4816 12.4466 20.501 12.349 20.501 12.2504C20.501 12.1519 20.4816 12.0543 20.4438 11.9632C20.4061 11.8722 20.3508 11.7894 20.281 11.7198Z" fill="black" />
                        </svg>
                    </button>
                    </OverlayTrigger>
                </div>

                {coverPhotos.length > 0 &&
                    <div className="property-slider">
                      
                        <Carousel
                        
                          //nextLabel={"Next"}
                          //prevLabel={"Previous"}
                          
                         // nextIcon={directionButtons("")}
                //prevIcon={directionButtons("")}
                        >
                        
                            {
                                coverPhotos.sort((a, b) => a.order - b.order).map((photo) => {
                                    return (
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={photo?.image}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                    )
                                })}

                        </Carousel>

                        {/* // <img src={noImage} /> */}


                    </div>
                }

                <div className="musthave-wrap modal-musthave-wrap">
                    <h6>Property details</h6>
                    {isLand ? (
                        <ul>
                            <li>
                                <div className="list-icns">
                                    <img src={LotSizeIcon} />
                                </div>
                                <span className="list-txt">{acreage} acres</span>
                            </li>
                            <li>
                                <div className="list-icns">
                                    <img src={SqftIcon} />
                                </div>
                                <span className="list-txt"> {filterArray(
                                    LOT_TYPES,
                                    "value",
                                    lotType
                                )}</span>

                            </li>
                        </ul>
                    ) : (
                        <ul>
                            {noBedRm &&
                                <li>
                                    <div className="list-icns">
                                        <img src={BedIcon} />
                                    </div>
                                    <span className="list-txt">{noBedRm > 1 ? removeDecimalZeros(noBedRm) + ' Beds' : removeDecimalZeros(noBedRm) + ' Bed'} </span>
                                </li>
                            }
                            {noBathRm &&
                                <li>
                                    <div className="list-icns">
                                        <img src={BathIcon} />
                                    </div>
                                    <span className="list-txt">{noBathRm > 1 ? removeDecimalZeros(noBathRm) + ' Baths' : removeDecimalZeros(noBathRm) + ' Bath'} </span>
                                </li>
                            }
                            {sqFt &&
                                <li>
                                    <div className="list-icns">
                                        <img src={SqftIcon} />
                                    </div>
                                    <span className="list-txt">{removeDecimalZeros(sqFt)} sqft</span>
                                </li>
                            }
                            {lotSize &&
                                <li>
                                    <div className="list-icns">
                                        <img src={LotSizeIcon} />
                                    </div>
                                    <span className="list-txt">{lotSize} Acres</span>
                                </li>
                            }
                        </ul>
                    )}


                </div>
                <div className="Inventory-Location-wrap">
                    <h6>Location</h6>
                    <p>You will see the exact address upon accepting.</p>
                    <div className="Inventory-Location-map-wrap mb-3">
                    <div className="googlemap-responsive">
                <div className="map-outer">
                  <GoogleMaps
                    defaultProps={defaultProps}
                    mapHeight="100%"
                    mapWidth="100%"
                  />
                </div>
              </div>
                        {/* <img src={InventoryLocationMap} alt={InventoryLocationMap} /> */}
                    </div>
                </div>
                {featuredProperty?.length !== 0 && (
                    <div className="nicetohaves-wrap">
                        <h6>Property features</h6>
                        <div className="nicetohaves-inner-wrap">

                            {featuredProperty &&
                                featuredProperty.sort((a, b) => a.order - b.order).map((feature) => {
                                    return (
                                        <div class="nicetohaves-tag">
                                            <div class="nicetohaves-tag-icon">
                                                <img src={feature?.icon ? require("../../images/" + feature?.icon) : ''} alt='' />
                                            </div>
                                            <span>{feature?.text}</span>
                                        </div>
                                    )
                                })}

                        </div>
                    </div>
                )}
               
                    <div class="nicetohaves-wrap">
                        <div className="add-profile-wrap">
                            <div className="prof-img mr-12">
                                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z" fill="#A0A9BA" />
                                </svg>
                            </div>

                            {userType == USER_TYPE_AGENT && <h6>Represented by Agent <span class="pro-blue-badge">PRO</span></h6>  }
                            {userType == USER_TYPE_MEMBER && <h6>Property Owner</h6>  }
                        </div>
                    </div>
               

                <div class="buyer-info">
                    <h6>Other details</h6>
                    <ul>
                        {
                            (salesPercnt != 0 && salesPercnt != null) &&

                            <li>
                                {userType != USER_TYPE_AGENT ? ("Seller is offering " + salesPercnt + "% to buyer's agent") : (salesPercnt + "% compensation to buyers agent")}
                            </li>
                        }


                        {
                            (insReport != 0 && insReport != null) &&
                            <li>Inspection on file</li>
                        }
                        {
                            (apprOnFile != null && apprOnFile != 0) &&
                            <li>Appraisal</li>
                        }
                        {
                            (floodCert != null && floodCert != 0) &&
                            <li>Flood certification</li>

                        }
                        {
                            timeLineTxt != null &&
                            <li> Timeline: {timeLineTxt}</li>
                        }

                        {
                            (floorPlan != null && floorPlan != 0) &&
                            <li>   Floor plan on file</li>
                        }

                        {
                            (sellerFin != null && sellerFin != 0) &&
                            <li>  Seller finance available</li>
                        }




                    </ul>
                </div>
            </Modal.Body>
            {/*<Modal.Footer>
            <div className="modal-btn-wrap">
                <Button variant="" className="btn btn-tertiary me-3" onClick={props.onHide}>Not a fit</Button>
                <Button variant="primary" onClick={props.onHide}>Accept to view more </Button>
            </div>
            <p><a role="button">Learn more</a> about introductions</p>
      </Modal.Footer>*/}
        </Modal>
    );
};
export default IntroductionPreviewModal;
