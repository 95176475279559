/**
 * @file   src\components\Messages\ReceiveIntroMessage.js
 * @brief  This file is responsible for handling receive intro message screen.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import SendIcon from "../../images/sent-icon.svg";
import { updateIntroReplyStatus } from '../../services/messagesService';
import { addMessage, loginOrSignUp, updateIntroduction } from '../../helpers/message';
import { loggedInUser } from '../../helpers/common';
import CheckIcon from "../../images/checkmark-black.svg";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { INTRODUCTION_EXPIRATION_TIME } from '../../constants/common';

function ReceiveIntroMessage(props) {
    const navigate = useNavigate();
    const [isMessageSent, setMessageSent] = useState(false);
    const [message, SetMessage] = useState('');
    const [disabledStatus, SetDisabled] = useState(false);
    const [then, SetThen] = useState('');
    const userDetails = loggedInUser();
    const introAcceptedDate = props.introAcceptedDate;
    const firstName = props.firstName;

    useEffect(() => {
        setMessageSent(props.IsReplied == 1 ? true : false)
    }, [props.IsReplied]);


    const countDown = (introAccepted72 = '') => {
        let datevalue = introAccepted72 ? introAccepted72 : then;

        var ms = moment(moment(new Date(datevalue), "DD/MM/YYYY HH:mm:ss").diff(moment(new Date(), "DD/MM/YYYY HH:mm:ss")));
        var d = moment.duration(ms);
        var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
        if (Math.floor(d.asHours()) > 1) {
            return moment.utc(ms).format("mm") > 0 ? { time: Math.floor(d.asHours()) + 1, type: 'hours', timeInNumber: Math.floor(d.asHours()) + 1 } : { time: Math.floor(d.asHours()), type: 'hours', timeInNumber: Math.floor(d.asHours()) + 1 };
        } else if (moment.utc(ms).format("mm") > 0) {
            return { time: moment.utc(ms).format("mm"), type: 'minutes', timeInNumber: moment.utc(ms).format("mm") };
        } else if (moment.utc(ms).format("ss") > 0) {
            return { time: moment.utc(ms).format("ss"), type: 'seconds', timeInNumber: moment.utc(ms).format("ss") };
        } else {
            return { time: 0, type: '', timeInNumber: 0 };
        }
    }

    const [timeAgo, setTimeAgo] = useState();

    useEffect(() => {
        // if(timeAgo){
        if (timeAgo?.timeInNumber && (timeAgo?.timeInNumber == 0 || timeAgo?.timeInNumber == '00')) {
            props.timeOutStatus(true)
        } else {
            const interval = setInterval(() => setTimeAgo(countDown()), 1000);
            return () => clearInterval(interval);
        }
        // }


    }, [timeAgo, then]);

    useEffect(() => {
        let introAccepted72 = moment.utc(introAcceptedDate).add(INTRODUCTION_EXPIRATION_TIME, 'hours').toISOString();
        // let introAccepted72 = moment.utc(introAcceptedDate).add(5, 'hours').toISOString();
        // let introAccepted72 = moment.utc(introAcceptedDate).add(25, 'minutes').toISOString();
        // let introAccepted72 = moment.utc(introAcceptedDate).add(25, 'minutes').toISOString();

        if (introAccepted72) {
            if (((new Date(introAccepted72) - new Date()) > 0)) {
                SetThen(introAccepted72);
                countDown(introAccepted72);

            } else {

                props.timeOutStatus(true)
            }
        }


    }, [introAcceptedDate]);

    const handleMessageChange = (e) => {
        SetMessage(e.target.value);
    }

    const sendMessage = async () => {
        SetDisabled(true);
        let email = _.get(userDetails, "userInfo.email", null);
        let data = {
            introductionId: props.IntroductionId,
            isrepiled: true,
            latestMessage: message,
            lastestMessageSentUserId: _.get(userDetails, "userInfo.userId", null),
        }

        let messageDoc = {
            createdUserId: _.get(userDetails, "userInfo.userId", null),
            uniqueId: props.IntroductionId,
            text: message,
        };
        
        let response = await addMessage(props.IntroductionId, messageDoc);
        let updateresponse = await updateIntroduction(props.IntroductionId, data);
        console.log("response", response, "updateresponse", updateresponse)
        if (response == 1 || updateresponse == 1) {
            await updateIntroReplyStatus(props.IntroductionId)
                .then(async (response) => {
                    if (response.errorCode == 0) {
                        // let login = loginOrSignUp(email);
                        // if (login) {


                        // }

                        setMessageSent(true);
                        SetDisabled(false);
                        props.msgSentStatus(true);

                    } else {
                        console.log("Something went wrong");
                    }
                })
                .catch((err) => {
                    console.log("Error", err);
                });

        }


    }

    return (
        <>
            {
                ((timeAgo?.timeInNumber > 0) && !isMessageSent) &&
                (
                    <>
                        <div className="addyour-property-details-wrap">
                            <h5>Are you interested?</h5>
                            <div className="timer-wrap">
                                <i className="ico-clock flex-shrink-0"></i>
                                <div className="flex-grow-1">
                                    You have <span className="time m-0"> {timeAgo?.time} {timeAgo?.type}</span> left to
                                    reply.
                                </div>
                            </div>
                            <textarea
                                class="form-control"
                                placeholder={props.messagePlaceHolderText}
                                onChange={(e) => { handleMessageChange(e) }}
                                maxLength={2000}
                            ></textarea>
                        </div>

                        <Button
                            variant="primary"
                            className="w-100"
                            disabled={(message.length > 0 && !disabledStatus) ? false : true}
                            onClick={() => { sendMessage() }}
                        >
                            Send <img src={SendIcon} />
                        </Button>
                    </>
                )
            }

            {
                ((timeAgo?.timeInNumber <= 0) && !isMessageSent) &&
                (
                    <>
                        <div className="addyour-property-details-wrap">
                            {/* <h5>Are you interested?</h5> */}
                            <div className="timer-wrap">
                                <i className="ico-clock flex-shrink-0"></i>
                                <div className="flex-grow-1">
                                    Time out.
                                </div>
                            </div>
                        </div>
                    </>

                )

            }

            {
                isMessageSent &&
                (
                    <div className="message-sent">
                        <h5>
                            Message sent{" "}
                            <span className="done-icon">
                                <img src={CheckIcon} />
                            </span>
                        </h5>
                        <p>
                            You sent a message to {firstName}. Go to{" "}
                            <a role="button" onClick={() => navigate('/messages')}>
                                your messages
                            </a>{" "}
                            to keep the conversation going.
                        </p>
                    </div>
                )
            }
        </>
    )
}

export default ReceiveIntroMessage;