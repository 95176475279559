import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import TextBox from "../../components/Common/TextBox";
import { Modal } from "react-bootstrap";
import { emailValidation } from "../../helpers/validations";
import { deleteUser } from "../../services/userprofileService";
import { toast } from "react-toastify";
import { pageURLs } from "../../constants/pageURLs";
import { clearLocalStorageOnLogout } from "../../helpers/common";
import Form from "react-bootstrap/Form";
const CheckDeleteAccountModal = (props) => {
  const { show, onHide } = props;


  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="modalPopupstyle delete-popup"
    >
      <Modal.Body className="text-center">
        <h3>Check your email</h3>
        <p>
          We've sent links to your email account where you can confirm account
          deletion.
        </p>
        <div></div>
        <div className="btn-wrap">
          <Button
            variant="primary"
            className="btn btn-primary w-100"
            onClick={() => onHide()}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CheckDeleteAccountModal;
