import React, { useState, useEffect } from "react";
import { Offcanvas, FormControl, Row, Col, Button } from "react-bootstrap";
import { convertToNumber } from "../../../helpers/validations";
import { filterArray, loggedInUser, removeDecimalZeros, thousandSeperatedValue } from "../../../helpers/common";
import moment from "moment";
import GoogleMaps from "../../../components/Introduction/GoogleMap";
import { IS_LAND, LOT_TYPES, USER_TYPE_AGENT, USER_TYPE_MEMBER } from "../../../constants/common";


const PreviewIntroductionOffcanvas = (props) => {
    const { show, onHide, selectedProperty } = props;

    const inventoryPhoto = selectedProperty?.photosForPreview;
    const coverPhotos = inventoryPhoto?.filter((item) => item.isCover == 1) ?? [];
    const askingPrice = selectedProperty?.askingPrice ? selectedProperty?.askingPrice : selectedProperty?.defaultAskingPrice;
    const noBedRm = selectedProperty?.noBedRm;
    const noBathRm = selectedProperty?.noBathRm;
    const sqFt = selectedProperty?.sqFt;
    const lotSize = selectedProperty?.lotSize;
    const location = selectedProperty?.selectedProperty?.Location;
    const featuredProperty = selectedProperty?.propertyFeaturesForPreview;
    const userDetails = loggedInUser();
    const userType = userDetails?.userInfo?.userType;
    const currentDate = moment(Date.now()).format("MM/DD/YYYY");
    const salesPercnt = selectedProperty?.salesPercnt;
    const insReport = selectedProperty?.insReport;
    const apprOnFile = selectedProperty?.apprOnFile;
    const floodCert = selectedProperty?.floodCert;
    const isLand = selectedProperty?.propertyTypeId === IS_LAND;
    const acreage = selectedProperty?.acreage;
    const lotType = selectedProperty?.lotType;
    const timeLineTxt = selectedProperty?.timeLineTxt;
    const floorPlan = selectedProperty?.floorPlan;
    const sellerFin = selectedProperty?.sellerFin;
    const city = selectedProperty?.city;
    const state = selectedProperty?.state;

    const [userCoords, setUserCoords] = useState({})
    const handleUserCoords = (lat, lng) => {
        setUserCoords({ lat: lat, lng: lng })
    }
    //const userLocation = getLocalStorage(USER_LOCATION_KEY)
    const defaultProps = {
        center: {
            lat: convertToNumber(_.get(userCoords, 'lat', '')),
            lng: convertToNumber(_.get(userCoords, 'lng', '')),
        },
        zoom: 12,
    };

    useEffect(() => {
        handleUserCoords(selectedProperty?.lat, selectedProperty?.lng)
    }, [selectedProperty?.lat, selectedProperty?.lng]);
    return (

        <Offcanvas
            className="mobile-offcanvas offcanvas-fixed preview-intro-offcanvas"
            show={show}
            onHide={onHide}
            placement='bottom'
            {...props}>
            <Offcanvas.Header>
                <a class="back-link-btn" href={void (0)} onClick={() => { onHide() }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z" fill="#1B1C1E" />
                    </svg>
                </a>
                <Offcanvas.Title>Preview Introduction</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="premium-content-wrapper bg-white">
                    <div className="confirmation-top-sec">
                        <div className="long-txt-fix">
                            {/* <div className="confirm-sub-title mb-1">Sent on 05/01/2023</div> */}
                            <h2>{isLand ? "Lot/Land" : "House"} in {city}, {state}</h2>
                        </div>
                        <div className="property-details-mob mb-4">
                            {isLand ? (
                                <>
                                    {acreage} <span className="details-text-mob">acres</span>
                                    <span className="vertical-divider-mob">|</span>
                                    {filterArray(
                                        LOT_TYPES,
                                        "value",
                                        lotType
                                    )}<span className="details-text-mob">Beds</span>
                                </>
                            ) : (
                                <>
                                    {noBedRm &&
                                        <>
                                            {removeDecimalZeros(noBedRm)} <span className="details-text-mob">{removeDecimalZeros(noBedRm) > 1 ? "Beds" : "Bed"}</span>
                                        </>
                                    }
                                    {noBathRm &&
                                        <>
                                            <span className="vertical-divider-mob">|</span>
                                            {removeDecimalZeros(noBathRm)} <span className="details-text-mob">{removeDecimalZeros(noBathRm) > 1 ? "Baths" : "Bath"} </span>
                                        </>
                                    }
                                    {sqFt &&
                                        <>
                                            <span className="vertical-divider-mob">|</span>
                                            <span>{sqFt}</span>
                                            <span className="details-text-mob"> sqft</span>
                                        </>
                                    }
                                </>
                            )

                            }

                        </div>
                    </div>
                    {coverPhotos.length > 0 &&
                        <div className="inventory-banner-small position-relative">
                            <div className="banner-row">
                                {coverPhotos.length == 1 &&
                                    <div className="banner-column-full  banner-single  h-200">
                                        <div className="full-col-img-wrap h-100">
                                            <img className="brdr-top-10 col-img-style" src={coverPhotos[0]?.image} />
                                        </div>
                                    </div>
                                }
                                {coverPhotos.length == 2 &&
                                    (
                                        <>
                                            <div className="banner-column-full  h-200">
                                                <div className="full-col-img-wrap h-100">
                                                    <img className="brdr-top-10 col-img-style" src={coverPhotos[0]?.image} />
                                                </div>
                                            </div>


                                            <div className="banner-column-full h-200">
                                                <div className="full-col-img-wrap h-100">
                                                    <img className="brdr-top-10 col-img-style" src={coverPhotos[1]?.image} />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                {coverPhotos.length == 3 && (
                                    <>
                                        <div className="banner-column-full  h-200">
                                            <div className="full-col-img-wrap h-100">
                                                <img className="brdr-top-10 col-img-style" src={coverPhotos[0]?.image} />
                                            </div>
                                        </div>
                                        <div className="banner-column-full d-flex h-98">

                                            <div className="quarter-col-img-wrap-left w-50-percent">
                                                <img className="brdr-btm-left-10 col-img-style" src={coverPhotos[1]?.image} />
                                            </div>

                                            <div className="quarter-col-img-wrap-right w-50-percent">
                                                <img className="brdr-btm-right-10 col-img-style" src={coverPhotos[2]?.image} />
                                            </div>

                                        </div>
                                    </>
                                )
                                }
                            </div>
                        </div>
                    }

                    <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                        <h3>Details</h3>
                        <div className="row">
                            <div className="col-6 pe-2">
                                <div className="musthave-content-mobwrap mb-3">
                                    <div className="musthave-content-label">Price</div>
                                    <div className="musthave-content-text">${thousandSeperatedValue(askingPrice)}</div>

                                </div>
                            </div>
                            {
                                lotSize &&
                                <div className="col-6 ps-2">
                                    <div className="musthave-content-mobwrap mb-3">
                                        <div className="musthave-content-label">Lot Size</div>
                                        <div className="musthave-content-text">{lotSize} acres</div>

                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                    <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                        <h3>Location</h3>
                        <p>Exact address will be visible to you after you accept their introduction.</p>
                        <div className="property-listing-ad-mob">
                            <div className="property-img-wrapad-mob h-200">
                                {/* <img src={WantadMapImage} /> */}
                                <GoogleMaps
                                    defaultProps={defaultProps}
                                    mapHeight="100%"
                                    mapWidth="100%"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="represent-agent brdr-top-common-wrap">
                        <div className="d-flex align-items-center">
                            <div className="profileIcnDiv d-flex align-items-center justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C9.10051 3.75 6.75 6.1005 6.75 9C6.75 11.8995 9.10051 14.25 12 14.25C14.8995 14.25 17.25 11.8995 17.25 9C17.25 6.1005 14.8995 3.75 12 3.75ZM5.25 9C5.25 5.27208 8.27208 2.25 12 2.25C15.7279 2.25 18.75 5.27208 18.75 9C18.75 12.7279 15.7279 15.75 12 15.75C8.27208 15.75 5.25 12.7279 5.25 9Z" fill="#4A4C4F" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 15.7492C10.2884 15.7492 8.60689 16.1998 7.12461 17.0557C5.64233 17.9115 4.41149 19.1426 3.55581 20.625C3.34873 20.9837 2.89005 21.1066 2.53131 20.8996C2.17257 20.6925 2.04963 20.2338 2.2567 19.8751C3.24402 18.1646 4.66423 16.7442 6.37455 15.7566C8.08488 14.7691 10.025 14.2492 12 14.2492C13.975 14.2492 15.9151 14.7691 17.6255 15.7566C19.3358 16.7442 20.756 18.1646 21.7433 19.8751C21.9504 20.2338 21.8274 20.6925 21.4687 20.8996C21.11 21.1066 20.6513 20.9837 20.4442 20.625C19.5885 19.1426 18.3577 17.9115 16.8754 17.0557C15.3931 16.1998 13.7116 15.7492 12 15.7492Z" fill="#4A4C4F" />
                                </svg>
                            </div>

                            {userType == USER_TYPE_AGENT && <h5 className="m-0 d-flex align-items-center">Represented by Agent <span class="pro-blue-badge">PRO</span></h5>}
                            {userType == USER_TYPE_MEMBER && <h5 className="m-0 d-flex align-items-center">Property Owner</h5>}
                        </div>
                    </div>
                    {featuredProperty?.length !== 0 && (
                        <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                            <h3>Nice-to-haves</h3>
                            {
                                featuredProperty?.sort((a, b) => a.order - b.order).map((feature, index) => {
                                    return (
                                        <div class="property-tag-mobile mb-2">
                                            <div class="tag-icon-mobile">
                                                <img src={feature?.icon ? require("../../../images/" + feature?.icon) : ''} />

                                            </div>
                                            <span>{feature?.text}</span>
                                        </div>
                                    )

                                })}

                        </div>
                    )}

                    <div className="buyer-info-mob other-details brdr-top-common-wrap">
                        <h3>Other Details</h3>
                        <ul>
                            {
                                timeLineTxt != null &&
                                <li>
                                    <span className="list-icn-wrap-mob"></span>
                                    Timeline to sell: {timeLineTxt}
                                </li>
                            }
                            {
                                (salesPercnt != 0 && salesPercnt != null) &&

                                <li>
                                    <span className="list-icn-wrap-mob"></span>
                                    {userType != USER_TYPE_AGENT ? ("Seller is offering " + salesPercnt + "% to buyer's agent") : (salesPercnt + "% compensation to buyers agent")}
                                </li>
                            } {
                                (insReport != 0 && insReport != null) &&
                                <li>
                                    <span className="list-icn-wrap-mob"></span>
                                    Inspection on file
                                </li>
                            }
                            {
                                (apprOnFile != null && apprOnFile != 0) &&
                                <li>
                                    <span className="list-icn-wrap-mob"></span>
                                    Appraisal
                                </li>
                            }
                            {
                                (floodCert != null && floodCert != 0) &&
                                <li>
                                    <span className="list-icn-wrap-mob"></span>
                                    Flood certificate
                                </li>
                            }
                            {
                                (floorPlan != null && floorPlan != 0) &&
                                <li>    <span className="list-icn-wrap-mob"></span> Floor plan on file</li>
                            }

                            {
                                (sellerFin != null && sellerFin != 0) &&
                                <li>   <span className="list-icn-wrap-mob"></span> Seller finance available</li>
                            }

                        </ul>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas >




    );
};
export default PreviewIntroductionOffcanvas;
