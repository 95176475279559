/**
 * @file   src\components\Common\CreateYouCustomLink.js
 * @brief  This file is responsible for creating form link for want ad creation to be shared with clients 
 * @date   April, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import {
  combineKey,
  getLocalStorage,
  loggedInUser,
  renameKey,
  setLocalStorage,
} from "../../helpers/common";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import searchIccon from "../../images/search-icon.svg";
import { getAgentsList, saveMemberWantAd } from "../../services/wantAdService";
import AddNewClientModal from "../MemberWantAd/AddNewClientModal";
import { saveAgentClient } from "../../services/agentClientsService";
import { BUY, USER_INFO_KEY } from "../../constants/common";
import CustomLinkModal from "./CustomLinkModal";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={searchIccon} />
    </components.DropdownIndicator>
  );
};

const CreateYourCustomLink = (props) => {
  const { show, onHide } = props;
  const [showAddClient, setShowAddClient] = useState(false);
  const handleClose = () => setShowAddClient(false);
  const handleShow = () => setShowAddClient(true);

  const [showCustomLinkPopuop, setShowCustomLinkPopup] = useState(false);
  const handleCustomLinkPopupOpen = () => {
    setShowCustomLinkPopup(true);
  };
  const handleCustomLinkPopupClose = () => {
    setShowCustomLinkPopup(false);
  };

  const [clientsList, setClientsList] = useState([]);

  const [clientId, setClientId] = useState(null);
  const [linkToShare, setLinkToShare] = useState("")

  const agent = loggedInUser();


  useEffect(() => {
    // if(show){
    getClientList();
    // }
  }, []);

  const getClientList = async () => {
    await getAgentsList()
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          responseResult.forEach((obj) => renameKey(obj, "ClientId", "value"));
          responseResult.forEach((obj) =>
            combineKey(obj, "FirstName", "LastName", "label")
          );
          setClientsList(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleChangeClient = (e) => {
    if (e.value == 0 || e.__isNew__) {
      handleShow();
    } else {
      setClientId({
        clientId: e.value,
        clientName: e.label,
        clientData: {
          value: e.value,
          label: e.label,
        },
        focusOut: true,
      });
    }
  };

  const addClientData = async (data) => {
    var param = {
      ...data,
    };
    await saveAgentClient(param)
      .then((response) => {
        if (response.errorCode === 0) {
          getClientList();
          let value = {
            label: data.firstName + " " + data.lastName,
            value: response.result.clientId,
          };
          setClientId({
            clientData: value,
            clientName: data.firstName + " " + data.lastName,
            clientId: response.result.clientId,
          });
          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["ClientCount"] = userInfo.userInfo?.ClientCount
            ? userInfo.userInfo?.ClientCount + 1
            : 1;
          setLocalStorage(USER_INFO_KEY, userInfo);
          handleClose();
        } else {
          return;
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getSelectedClient = (clientId, mainList) => {
    if (clientId && mainList) {
      var selectedValue = mainList.filter(function (list) {
        if (list.value == clientId?.clientId) {
          return list;
        }
      });
      return selectedValue;
    }
    return null;
  };

  const handleSubmit = () => {
    const requestPayLoad = {...
      clientId,
      isFilledByClient : true,
      isAgentAsSeller : false,
      IsSavedDraft : true,
      IsPublished : false,
      cmpldStep : 1,
      cmpldPerctg : 7,
      adType : BUY,
      isAgentCreated : true
    }

    saveMemberWantAd(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setLinkToShare(process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL+"/form/"+agent?.userInfo?.firstName+"-"+agent?.userInfo?.lastName+"-"+responseResult?.client_Want_ad_Id)
          onHide();
          handleCustomLinkPopupOpen();
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });    
  };

  
  return (
    <>
      <CustomLinkModal
        show={showCustomLinkPopuop}
        onHide={handleCustomLinkPopupClose}
        linkToShare={linkToShare}
        isFromCreateWantAd={true}
      />
      <AddNewClientModal
        show={showAddClient}
        onHide={handleClose}
        addClient={addClientData}
      />
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-custom-width"
        backdrop="static"
        show={show}
      >
        
        <Modal.Body className="pt-32 pb-32">
          <button type="button" class="btn-close" aria-label="Close"onClick={()=>{props.onHide(); setClientId(null)}}
            ></button>
          <h3 className="text-start">Create your custom link</h3>
          <p className="f-size-16 mb-4">
            All client information is private to you only.
          </p>

          <Row>
            <Col xl={12}>
              <div className="wantad-location-wrap select-type-wrap">
                <h6>
                  Client <span>*</span>
                </h6>
                <CreatableSelect
                  components={{ DropdownIndicator }}
                  id="multi1"
                  name="colors1"
                  value={getSelectedClient(clientId, clientsList)}
                  options={clientsList}
                  className="basic-multi-select mb-4"
                  classNamePrefix="select"
                  placeholder="Client name"
                  onChange={(e) => {
                    handleChangeClient(e);
                  }}
                  formatCreateLabel={(_) => (
                    <div className="">
                      <span className="clientsearch-icon-wrap mr-6">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z"
                            fill="#15181E"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.25 10C6.25 9.65482 6.52982 9.375 6.875 9.375H13.125C13.4702 9.375 13.75 9.65482 13.75 10C13.75 10.3452 13.4702 10.625 13.125 10.625H6.875C6.52982 10.625 6.25 10.3452 6.25 10Z"
                            fill="#15181E"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V13.125C10.625 13.4702 10.3452 13.75 10 13.75C9.65482 13.75 9.375 13.4702 9.375 13.125V6.875C9.375 6.52982 9.65482 6.25 10 6.25Z"
                            fill="#15181E"
                          />
                        </svg>
                      </span>
                      Create new client
                    </div>
                  )}
                  createOptionPosition="first"
                  isValidNewOption={(_) => true}
                  async
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="modal-btn-wrap">
                <Button
                  variant="primary"
                  className={clientId ? " btn w-100" : "btn w-100 disabled"}
                  onClick={()=>handleSubmit()}
                >
                  Confirm
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateYourCustomLink;
