import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import { thousandSeperatedValue } from "../../helpers/common";
const HelpFeedbackModal = (props) => {
  const { inventoryId, handleInventoryMarkAsSold } = props;
  const [isGitchaHelped, setIsGitchaHelped] = useState(null);
  const [salesPrice, setSalesPrice] = useState(null);

  const re = /^[0-9, $]+$/;

  const handleChangeSalesPrice = (value) => {
    if (re.test(value)) {
      const newValue = thousandSeperatedValue(value);
      setSalesPrice(newValue ? "$"+newValue : "");
    }
  };

  const clearStates = () =>{
    setIsGitchaHelped(null);
    setSalesPrice(null);
  }

  return (
    <>
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="modal-settings"
      >
        <Modal.Body className="pt-32 pb-32">
          <h3 className="text-start pb-32 mb-0">
            Let us know how Gitcha helped you!
          </h3>

          <div className="wantad-timing-wrap">
            <div className="land-radio-wrap pb-0">
              <Row>
                <Col xl={12}>
                  <label className="custom-label-style mb-3">
                    Did Gitcha help with this transaction?
                  </label>
                </Col>
                <Col xl={6}>
                  <label className="radio-wrap mb-32">
                    <input
                      name="lt-radio"
                      checked={isGitchaHelped === true}
                      onChange={() => setIsGitchaHelped(true)}
                      type="radio"
                    />
                    <span>
                      <div className="timing-wrap-info">
                        <h2 className="pb-0">Yes</h2>
                      </div>
                    </span>
                  </label>
                </Col>

                <Col xl={6}>
                  <label className="radio-wrap mb-32">
                    <input
                      name="lt-radio"
                      checked={isGitchaHelped === false}
                      onChange={() => setIsGitchaHelped(false)}
                      type="radio"
                    />
                    <span>
                      <div className="timing-wrap-info">
                        <h2 className="pb-0">No</h2>
                      </div>
                    </span>
                  </label>
                </Col>
              </Row>
              {isGitchaHelped && (
                <Row>
                  <Col xl={12}>
                    <label className="custom-label-style mb-3">
                      What was the sales price of this transaction?
                    </label>
                  </Col>
                  <Col xl={12}>
                    <div className="textbox-wrap custom-fc-padding mb-0">
                      <TextBox
                        id="sales-price"
                        name="salesprice"
                        type="text"
                        placeholder="$0"
                        className="form-control custom-fc-padding"
                        onChange={(e) => handleChangeSalesPrice(e.target.value)}
                        value={salesPrice}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
+
          <div className="modal-btn-wrap">
            <Button variant="tertiary" className="btn me-3" onClick={()=>{handleInventoryMarkAsSold(inventoryId, isGitchaHelped,salesPrice); clearStates()}}>
              Skip
            </Button>
            {isGitchaHelped !== null && (
              <Button variant="primary" className="btn" onClick={()=>{handleInventoryMarkAsSold(inventoryId, isGitchaHelped,salesPrice); clearStates()}}>
                Submit
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default HelpFeedbackModal;
