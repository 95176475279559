import React, { useState, useEffect, useRef } from "react";
import SelectDropDown from "./SelectDropDown";
import { getSquareFeetList } from "../../services/wantAdService";
import {
  arraySplice,
  checkIsNumber,
  checkIsNumberWithDecimal,
  filterArrayBasedOnKey,
} from "../../helpers/common";
import { convertToNumber } from "../../helpers/validations";
import _ from "lodash";
import { sqFtMaxMissing, sqFtMinMissing, sqftMaxLesser } from "../../constants/createWantAd";

const WantAdSquareFeet = (props) => {
  const { handleFormValues, SFMin, SFMax, sqFtError, errorUpdatedAt } = props;
  const MIN_SF_DROPDOWN = "minSFDrop";
  const MAX_SF_DROPDOWN = "maxSFDrop";
  const [squareFootageMaxError, setSquareFootageMaxError] = useState("");
  const [squareFootageMinError, setSquareFootageMinError] = useState("");
  const [squareFeetMinList, setSqareFeetMinList] = useState([]);
  const [squareFeetMaxList, setSqareFeetMaxList] = useState([]);
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const INDEX_TO_BE_SLICE = 14;

  useEffect(() => {
    getSquareFeetList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          let newList = [];
          list.map((lst) => {
            const value = _.get(lst, "Sqft_Value");
            const text = _.get(lst, "Sqft_Value_Text", "");
            newList.push({ value: value, label: text });
          });
          const minOptions = {
            value: convertToNumber(SFMin),
            label: SFMin && SFMin.toString(),
          };
          addNewOptionsToMinList(newList, minOptions);
          const maxOptions = {
            value: convertToNumber(SFMax),
            label: SFMax && SFMax.toString(),
          };
          addNewOptionsToMaxList(newList, maxOptions);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleSqareFeetSelection = (e, dropSection) => {
    setSquareFootageMaxError("");
    setSquareFootageMinError("");
    const value = _.get(e, "value", "");
    if (dropSection === MIN_SF_DROPDOWN) {
      handleFormValues({ sqFtMin: value });
    } else {
      handleFormValues({
        sqFtMax: value,
      });
    }
  };

  const addNewOptionsToMinList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMinList(customList);
  };

  const addNewOptionsToMaxList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMaxList(customList);
  };

  const buildCustomList = (list, newOption) => {
    const filteredArray = filterArrayBasedOnKey({
      list: list,
      filterKey: "value",
      value: _.get(newOption, "value", null),
    });
    let customOptions = [...list];
    if (filteredArray?.length === 0 && _.get(newOption, "value", null)) {
      customOptions = [...list, newOption];
    }
    return customOptions;
  };


  const handleOnInputChange = (e, dropSection) => {
    setSquareFootageMaxError("");
    setSquareFootageMinError("");
    if (dropSection === MIN_SF_DROPDOWN) {
      if (minRef.current && minRef.current.contains(document.activeElement)) {
        if (checkIsNumberWithDecimal(e)) {
          const options = { value: convertToNumber(e), label: e };
          addNewOptionsToMinList(
            arraySplice(squareFeetMinList, INDEX_TO_BE_SLICE),
            options
          );
          handleFormValues({ sqFtMin: e });
        }
      }
    } else {
      if (maxRef.current && maxRef.current.contains(document.activeElement)) {
        if (checkIsNumberWithDecimal(e)) {
          const options = { value: convertToNumber(e), label: e };
          addNewOptionsToMaxList(
            arraySplice(squareFeetMaxList, INDEX_TO_BE_SLICE),
            options
          );
          handleFormValues({ sqFtMax: e });
        }
      }
    }
  };

  useEffect(() => {
    if(sqFtError){
      window.scrollTo(0, 0)
    }
    if (sqFtError === sqftMaxLesser) {
      setSquareFootageMaxError("Value Must be greater than minimum");
    }
    // if (sqFtError === sqFtMinMissing) {
    //   setSquareFootageMinError("Select min Square footage");
    // }
    // if (sqFtError === sqFtMaxMissing) {
    //   setSquareFootageMaxError("Select max Square footage");
    // }
  }, [sqFtError, errorUpdatedAt]);

  return (
    <div className="wantad-timing-wrap">
      <div className="lotsize-selection-wrap">
        <h6>
          {" "}
          Square Footage<span>*</span>
        </h6>
        <div className="row">
          <div className="col-md-6 pe-md-6">
            <label className="lot-select-label">Min</label>
           
            <div className={squareFootageMinError ? "lotsize-selection-wrap red-marked-wrap" : "lotsize-selection-wrap"} ref={minRef}>
              <SelectDropDown
                id={MIN_SF_DROPDOWN}
                name="colors3"
                options={[
                  { value: null, label: "No min sqft" },
                  ...squareFeetMinList,
                ]}
                className="lotsize-select custom-lotsize-color"
                classNamePrefix="select"
                placeholder="No min sqft"
                value={filterArrayBasedOnKey({
                  list: squareFeetMinList,
                  filterKey: "value",
                  value: SFMin,
                })}
                onInputChange={(e) => handleOnInputChange(e, MIN_SF_DROPDOWN)}
                onChange={(e) => handleSqareFeetSelection(e, MIN_SF_DROPDOWN)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                  }),
                }}
                errorClass="error-msg error-msg-height"
                errorMessage={squareFootageMinError}
              />
            </div>
          </div>
          <div className="col-md-6 ps-md-6">
            <label className="lot-select-label">Max</label>
            {/* <div className="lot-select-wrap" ref={maxRef}> */}
            <div className={squareFootageMaxError ? "lotsize-selection-wrap red-marked-wrap" : "lotsize-selection-wrap"} ref={maxRef}>
              <SelectDropDown
                id={MAX_SF_DROPDOWN}
                name="colors5"
                options={[
                  { value: null, label: "No max sqft" },
                  ...squareFeetMaxList,
                ]}
                className="lotsize-select custom-lotsize-color"
                classNamePrefix="select"
                placeholder="No max sqft"
                value={filterArrayBasedOnKey({
                  list: squareFeetMaxList,
                  filterKey: "value",
                  value: SFMax,
                })}
                onInputChange={(e) => handleOnInputChange(e, MAX_SF_DROPDOWN)}
                onChange={(e) => handleSqareFeetSelection(e, MAX_SF_DROPDOWN)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                  }),
                }}
                errorClass="error-msg error-msg-height"
                errorMessage={squareFootageMaxError}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantAdSquareFeet;
