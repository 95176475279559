import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  classCardNumber,
} from "@stripe/react-stripe-js";
import TextBox from "../Common/TextBox";
import { savePaymentDetails } from "../../services/paymentsService";
import { getUserId, toTitleCase } from "../../helpers/common";

const getCardElementOptions = (elem)=>{
  const CARD_ELEMENT_OPTIONS = {
    placeholder:elem,
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "#15181E",
        fontSize: "16px",
        fontFamily: '"Supreme-Regular", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#797E86",
        },
      },
      invalid: {
        color: "#e74c3c",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  return CARD_ELEMENT_OPTIONS
}



const CardInputWrapper = styled.div`
  border: 2px solid #cfd4dd;
  border-radius: 12px;
  padding: 12px;
  height: 47px;
`;

const PaymentComponent = ({ ...props }) => {
  const dispatch = useDispatch();  
  const userDetails = useSelector((state) => state.userProfile.userDetails);
  const paymentMethods = useSelector((state)=> state.paymentMethods.paymentMethods)
  const [cardSave, setCardSave] = useState(false);
  const [name, setName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [nameError, setNameError] = useState("")
  const [zipCodeError, setZipCodeError] = useState("")
  const [errorMessages, setErrorMessages] = useState({
    cardNumberError : "",
    cvvError : "",
    expiryError : "",
  })  

  const [cardNumberComplete, setCardNumberCOmplete] = useState(false)
  const [cardExpiryComplete, setCardExpiryComplete] =  useState(false)
  const [cardCVCComplete, setCardCVCComplete] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)

  const stripe = useStripe();
  const elements = useElements();

  const re = /^[A-Za-z0-9 -]+$/;
  const handleNameChange = (value) => {
      setNameError("");
      if (value === "" || re.test(value)) {
        setName(value);
      }
      if (value.trim() === "") {
        setNameError("Name on card is incomplete");
      } 
      
  }

  const handleZipCodeChange = (value) => {
      setZipCodeError("")
      if (value === "" || re.test(value)) {
        setZipCode(value);
      }
      if (value.trim() === "") {
        setZipCodeError("ZipCode is incomplete");
      }
  }

  const handleCardNumberChange = (e) => {
    setErrorMessages({...errorMessages,cardNumberError : ""});
    if(e.complete){
      setCardNumberCOmplete(true)
    }
    else {
      setCardNumberCOmplete(false)
    }
    if(e?.error?.message !== "") {
      setErrorMessages({...errorMessages,cardNumberError : e?.error?.message});
    }
    
  }

  const handleCardExpiryChange = (e) => {
    
    setErrorMessages({...errorMessages,expiryError : ""});
    if(e.complete){
      setCardExpiryComplete(true)
    }
    else {
      setCardExpiryComplete(false)
    }
    if(e?.error?.message !== "") {
      setErrorMessages({...errorMessages,expiryError : e?.error?.message});
    }
    
  }

  const handleCVCChange = (e) => {
    setErrorMessages({...errorMessages,cvvError : ""});
    if(e.complete){
      setCardCVCComplete(true)
    }
    else {
      setCardCVCComplete(false)
    }
    if(e?.error?.message !== "") {
      setErrorMessages({...errorMessages,cvvError : e?.error?.message});
    }   
  }

  const checkValid = () =>{   
    if(name !== "" && zipCode !=="" && cardNumberComplete && cardCVCComplete  && cardExpiryComplete && !submitClicked){
          return false
    }
    else {
      return true
    }      
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitClicked(true)
    if (!stripe && !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(
      "cardNumber",
      "cardExpiry",
      "cardCvc"
    );    
      const { token, error } = await stripe.createToken(cardNumberElement, {
        name: name,
        address_zip: zipCode,
      });
      if (error) {
        setCardSave(false);
        toast.error(error.message)
      } else {
        if (token?.id) {             
          const data = {
            Name: name,
            ZipCode: zipCode,
            TokenId: token?.id,
            CardId: token?.card?.id,
            Brand: token?.card?.brand,
            CVC_Check: token?.card?.cvc_check,
            Exp_month: token?.card?.exp_month,
            Exp_year: token?.card?.exp_year,
            Funding: token?.card?.funding,
            Last4: token.card.last4,
            IsDefault: paymentMethods?.length > 0 ? 0 : 1
          };   
          
          await savePaymentDetails(data)
          .then((response) => {
            props.onHide();
            if (response.errorCode===0) {
              toast.success("Payment method successfully added!")              
              props.updateReloadParam();
              setSubmitClicked(false);
            } else {
              toast.error(response.message);
              setSubmitClicked(false);
            }
          })
          .catch((err) => {
            toast.error(err);
          });     
          
        }        
      }
    
  };

  const handleCancel = () => {
    props.onHide()
  }

  return (
    <form>
      {props.fromSubscriptionComponent && 
      <a role="button" className="bottom-back-btn" onClick={()=>props.onHide()}>
            <span class="left-arrow">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z" fill="#205ECA">
                    </path>
                </svg>
      </span>Back</a>}
      <h3 className="text-start">Add a payment method</h3>
      {props.fromSubscriptionComponent && <p className="desc">We’ll use this card to bill your subscription plan.</p>}
      <Row>
        <Col xl={12}>
          <div className="textbox-wrap">
            <label className="form-label">Name on Card</label>
            <TextBox
              id="cardname"
              name="cardname"
              type="text"
              maxLength={50}
              value={toTitleCase(name)}
              placeholder="John Doe"
              onChange={(e) => handleNameChange(e.target.value)}
              className="form-control"
              errorClass = "error-validation-msg h-10"
              errorMessage={nameError}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="textbox-wrap">
            <label className="form-label">Card Number</label>
            <CardInputWrapper className="stripe-field mb-0">
              <CardNumberElement
                onChange={(e) => handleCardNumberChange(e)}
                placeholder= 'Custom card number placeholder'
                className={"stripe-control " + classCardNumber}
                options={getCardElementOptions('**** **** **** ****')}
              />              
            </CardInputWrapper>
            {<div className='error-msg'>{errorMessages?.cardNumberError}</div>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-md" xs={12}>
          <div className="textbox-wrap">
            <label className="form-label">Date of Expiration</label>
            <CardInputWrapper className="stripe-field mb-0">
              <CardExpiryElement
                onChange={(e) => handleCardExpiryChange(e)}
                className={"stripe-control " + classCardNumber}
                options={getCardElementOptions('')}
              />              
            </CardInputWrapper>
            {<span className='error-msg'>{errorMessages?.expiryError}</span>}
          </div>
        </Col>
        <Col className="col-md" xs={12}>
          <div className="textbox-wrap">
            <label className="form-label">CVC</label>
            <CardInputWrapper className="stripe-field mb-0">
              <CardCvcElement
                onChange={(e) => handleCVCChange(e)}
                className={"stripe-control " + classCardNumber}
                options={getCardElementOptions('')}
              />              
            </CardInputWrapper>
            {<span className='error-msg'>{errorMessages?.cvvError}</span>}
          </div>
        </Col>
        <Col className="col-md" xs={12}>
          <div className="textbox-wrap">
            <label className="form-label">ZIP Code</label>
            <TextBox
              id="zipcode"
              name="zipcode"
              type="text"
              maxLength={10}
              value={zipCode}
              onChange={(e) => handleZipCodeChange(e.target.value)}
              className="form-control"
              errorClass = "error-validation-msg h-10"
              errorMessage={zipCodeError}
            />
            
          </div>
        </Col>
      </Row>
      <div className="modal-btn-wrap">
        <Button className="btn btn-tertiary me-3" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={checkValid()} onClick={handleSubmit}>
          Done
        </Button>
      </div>
    </form>
  );
};

export default PaymentComponent;
