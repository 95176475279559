import WantAdLocation from "./WantAdLocation";
import WantAdTiming from "./WantAdTiming";
import WantAdType from "./WantAdType";
import WantAdMustHaves from "./WantAdMustHaves";
import WantAdNiceToHaves from "./WantAdNiceToHaves";
import WantAdConfirmation from "./WantAdConfirmation";
import WantAdBudget from "./WantAdBudget";
import WantAdDescription from "./WantAdDescription";
import { removeDecimalZeros } from "../../helpers/common";
import WantAdInvestorBudget from "./WantAdInvestorBudget";
import WantAdPropertyPurchase from "./WantAdPropertyPurchase";
import WantAdInvestmentCriteria from "./WantAdInvestmentCriteria";

const WantAdInvestorBuyInvestment = (props) => {
  const {
    step,
    handleContinueButtonState,
    handleFormValues,
    formValues,
    clearPreviousStates,
    targetRef,
    userPlan
  } = props;
  switch (step) {
    case 3:
      return (
        <WantAdLocation
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          location={_.get(formValues, "location", null)}
          lat={_.get(formValues, "lat", null)}
          lng={_.get(formValues, "lng", null)}
          mapZoom={_.get(formValues, "mapZoom", null)}
          mapType={_.get(formValues, "mapType", null)}
          targetRef={targetRef}
          mapRadius={_.get(formValues, "mapRadius", null)}
          neighborHood={_.get(formValues, "neighborHood", null)}
          highSchool={_.get(formValues, "highSchool", null)}
          zip={_.get(formValues, "zip", null)}
          adType={_.get(formValues, "adType", null)}
          city={_.get(formValues, "city", null)}
          state={_.get(formValues, "state", null)}
          county={_.get(formValues, "county", null)}
          isGitchaAvailable={_.get(formValues, "isGitchaAvailable", null)}
          zipCode={_.get(formValues, "zipCode", null)}
          zipIdValues={_.get(formValues, "zipIdValues", null)}
        />
      );
    case 4:
      return (
        <WantAdTiming
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          timeLine={_.get(formValues, "timeLine", null)}
          adType={_.get(formValues, "adType", null)}
        />
      );
    case 5:
      return (
        <WantAdType
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          propertyType={_.get(formValues, "prprtyType", null)}
          adType={_.get(formValues, "adType", null)}
          clearPreviousStates={clearPreviousStates}
          prptySubTypes={_.get(formValues, "prptySubTypes", [])}
        />
      );
    case 6:
      return (
        <WantAdMustHaves
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          noOfBedrooms={_.get(formValues, "noBedRm", null)}
          noOfBathrooms={_.get(formValues, "noBathRm", null)}
          SFMin={removeDecimalZeros(_.get(formValues, "sqFtMin", null))}
          SFMax={removeDecimalZeros(_.get(formValues, "sqFtMax", null))}
          garageSpace={_.get(formValues, "grgSpce", null)}
          yearBuiltMin={_.get(formValues, "yrBltMin", null)}
          yearBuildMax={_.get(formValues, "yrBltMax", null)}
          mustHaveErrors={_.get(formValues, "wantAdValErrors", null)}
          errorUpdatedAt={_.get(formValues, "updatedAt", null)}
          stories={_.get(formValues, "stories", null)}
          yearBuiltOption={_.get(formValues, "yearBuiltOption", null)}
          minLtSz={_.get(formValues, "minLtSz", null)}
          maxLtSz={_.get(formValues, "maxLtSz", null)}
          adaAccess={_.get(formValues, "adaAccess", "")}
        />
      );
    case 7:
      return (
        <WantAdNiceToHaves
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          propertyType={_.get(formValues, "prprtyType", null)}
          adType={_.get(formValues, "adType", null)}
          nice2Have={_.get(formValues, "nice2Have", [])}
        />
      );
    case 8:
      return (
        <WantAdInvestorBudget
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          budgetMin={_.get(formValues, "minBdgt", "")}
          budgetMax={_.get(formValues, "maxBdgt", "")}
          adType={_.get(formValues, "adType", "")}
          budgetErrors={_.get(formValues, "wantAdValErrors", null)}
          errorUpdatedAt={_.get(formValues, "updatedAt", null)}
          financeType={_.get(formValues, "pFinance", null)}
          isPreApprLoan={_.get(formValues, "isPreApprLoan", null)}
        />
      );
    case 9 : 
      return (
        <WantAdPropertyPurchase
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          propertyPurchase={_.get(formValues, "purchaseType", null)}
          is1031Exchange={_.get(formValues, "is1031Exchange", null)}
        />
    );
    
    case 10:
      return (
        <WantAdInvestmentCriteria
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          investmentId={_.get(formValues, "investmentId", "")}
          capRateId={_.get(formValues, "capRateId", "")}
        />
      );
      case 11:
        return (
          <WantAdDescription
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            adTitle={_.get(formValues, "adTitle", "")}
            adDescription={_.get(formValues, "adDesc", "")}
          />
        );
    case 12:
      return (
        <WantAdConfirmation
          handleContinueButtonState={handleContinueButtonState}
          formValues={formValues}
          userPlan={userPlan}
        />
      );
  }
};
export default WantAdInvestorBuyInvestment;
