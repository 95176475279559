import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroupControl from "../Common/InputGroup";
import { handleTextBoxChange, capitalizeString } from "../../actions/common";
import GitchaNotAvailableModal from "../Common/GitchaNotAvailableModal";

import {
  clearLocationStatesExceptLatLng,
  createS3Folder,
  filterArrayBasedOnKey,
  getLocalStorage,
  getLocalityFromZipCode,
  getUserId,
} from "../../helpers/common";

import {
  DEFAULT_WANT_AD_MAP_ZOOM,
  MAP_EVENT_CLICK,
  MAP_EVENT_LOCATION_CHANGE,
  MAP_EVENT_MARKER_DRAG,
  SHORT_RENT,
  WANT_AD_KEY,
} from "../../constants/common";
import GeoLocation from "../Common/GeoLocation";

import {
  getGeoLocation,
  checkGitchaAvailable,
} from "../../services/commonServices";

import { convertToNumber } from "../../helpers/validations";
import WantAdGoogleMap from "./WantAdGoogleMap";
import { usePrevious } from "../../hooks/usePrevious";
import SelectDropDown from "../Common/SelectDropDown";
import TextBox from "../Common/TextBox";
import { getRadiusList } from "../../services/wantAdService";
import ZipCloseIcon from "../../images/zipcode-close-icon.svg";

const WantAdLocation = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    location,
    isEdit = false,
    lat,
    lng,
    mapZoom,
    mapType,
    targetRef,
    mapRadius,
    neighborHood,
    highSchool,
    zip,
    adType,
    city,
    state,
    county,
    isGitchaAvailable,
    zipCode,
    zipIdValues,
    zipCodeError
  } = props;

  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_WANT_AD_MAP_ZOOM,
  };
  const [showMarker, setShowMarker] = useState(true);
  const [isClickOrDrag, setClickDrag] = useState(false);
  const [eventType, setEventType] = useState(null);
  const HandleMapClickOrDrag = (status) => {
    setClickDrag(status);
  };
  const handleShowMarker = (markerStatus) => {
    setShowMarker(markerStatus);
  };

  const [radiusList, setRadiusList] = useState([]);
  const [locationError, setLocationError] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [gitchaModalShow, setGitchaModal] = useState(false);
  const handleGitchaAvailableModal = (state) => {
    if(state===false){
      clearLocationStates();
    }
    handleShowMarker(true);
    setGitchaModal(state);
  };

  const [zipList, setZipList] = useState([]);
  const [zipCodeArray, setZipCodeArray] = useState(zipCode?.length > 0 ? zipCode : []);
  const [zipCodeEnterdValue, setZipCodeEnterdValue] = useState(null)
  const [showPolygon, setShowPolygon] = useState(zipCode?.length > 0 ? true : false);
  const wantAdDetails = getLocalStorage(WANT_AD_KEY);

  useEffect(()=>{
    if(zipCode && zipCode?.length>0){
      setZipCodeArray(zipCode)
    }
    else{
      setZipCodeArray([])
    }

  },[zipCode])

  const getAddresseDetails = async ({ eventType, reqPayload }) => {
    setEventType(eventType);
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      let locArray = [];
      if (results?.length > 0) {
        const formatted_address = _.get(results?.[0], "formatted_address", "");
        if (
          eventType === MAP_EVENT_LOCATION_CHANGE &&
          results?.length > 0  &&
          formatted_address !== "United States"
        ) {
          locArray.push(results[0]);          
          setLocationList(locArray);
        }

        const firstLocationResult = _.get(results[0], "geometry.location", "");
        const firstAddressComponent = _.get(
          results[0],
          "address_components",
          ""
        );

        const lat = _.get(firstLocationResult, "lat", "");
        const lng = _.get(firstLocationResult, "lng", "");
        let addressObject = { lat: lat, lng: lng };
        if (
          eventType === MAP_EVENT_CLICK ||
          eventType === MAP_EVENT_MARKER_DRAG
        ) {
          try {
            if (firstAddressComponent && firstAddressComponent.length > 0) {
              const street_no = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "street_number";
              });

              if (street_no.length) {
                addressObject.street_no = _.get(
                  street_no?.[0],
                  "short_name",
                  ""
                );
              }

              const route = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "route";
              });

              if (route.length) {
                addressObject.route = _.get(route?.[0], "short_name", "");
                addressObject.street = _.get(route?.[0], "short_name", "");
              }
              const locality = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "locality";
              });
              if (locality.length) {
                addressObject.city = _.get(locality?.[0], "long_name", "");
              }
              const county = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "administrative_area_level_2";
              });

              if (county.length) {
                addressObject.county = _.get(county?.[0], "short_name", "");
              }
              const state = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "administrative_area_level_1";
              });
              if (state.length) {
                addressObject.state = _.get(state?.[0], "short_name", "");
              }
              const zip = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "postal_code";
              });
              if (zip.length) {
                addressObject.zip = _.get(zip?.[0], "short_name", "");
              }
            }
          } catch { }
          isGitchaAvailableLocation(addressObject);
          const city = _.get(addressObject, "city", null);
          const state = _.get(addressObject, "state", null);
          if (city && state) {
            addressObject.location = `${city}, ${state}`;
          }
          handleFormValues(addressObject);
        } else {
          handleFormValues(addressObject);
        }
      } else {
        const isDisable = true;
        handleContinueButtonState(isDisable);
      }
    });
  };

  const getRadiusLists = async () => {
    await getRadiusList().then((data) => {
      const results = _.get(data, "result");
      if (results?.length > 0) {
        setRadiusList(results);
      } else {
      }
    });
  };

  const checkGitchAvailability = async ({ zip, state, city, county }) => {
    const reqPayLoad = { county: county, state: state };
    return await checkGitchaAvailable(reqPayLoad).then((data) => {
      return data;
    });
  };

  useEffect(() => {
    if ((eventType === MAP_EVENT_LOCATION_CHANGE && isGitchaAvailable && prevMapRadius === mapRadius && !mapRadius) &&
      (zipCode?.length == 0 && !zipCodeEnterdValue)) {
      handleFormValues({ mapRadius: 5, mapZoom: 10 });
    }
  }, [location, mapRadius, isGitchaAvailable, eventType, wantAdDetails?.wantAdId]);
  
  useEffect(() => {
    if (
      ((location &&
        location !== "" &&
        mapRadius &&
        county) || (zipCode?.length > 0)) &&
        (!wantAdDetails?.wantAdId ? isGitchaAvailable : true) // if its edit isGitchaAvailable true deafult
    ) {
      handleContinueButtonState();
    } else {
      const isDisable = true;
      handleContinueButtonState(isDisable);
    }
  }, [location, mapRadius, isGitchaAvailable, eventType, zipCode, wantAdDetails?.wantAdId]);

  useEffect(() => {
    getRadiusLists();
    if (!location) {
      const wantAdDetails = getLocalStorage(WANT_AD_KEY);
      const s3Folder = _.get(wantAdDetails, "s3ImageFolder", null);
      if (!s3Folder) {
        getFolderName();
      }
    }
  }, []);

  const getFolderName = async () => {
    const isWantAd = true;
    await createS3Folder(isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };

  const previousLocation = usePrevious(location);
  const prevMapRadius = usePrevious(mapRadius);

  const handleLocationChange = (e) => {
    handleFormValues({
      city: "",
      state: "",
      zip: "",
      county: "",
      isGitchaAvailable: false,
      zipCode: [],
      zipIdValues: [],
      zipId:[],
      forGitchaAvailablePopup : {}
    });
    setZipCodeArray([]);
    setZipCodeEnterdValue(null)
    const value = capitalizeString(handleTextBoxChange(e));
    const reqPayload = `address=${value}&components=country:US`;
    if (value.trim() !== "" && value.length > 3) {
      getAddresseDetails({
        reqPayload: reqPayload,
        eventType: MAP_EVENT_LOCATION_CHANGE,
      });
    } else {
      clearAddressList();
    }
    const isDisableContinueButton = value && value.trim() !== "" ? false : true;
    //handleContinueButtonState(isDisableContinueButton);
    if (value && value.trim() !== "") {
      //  handleContinueButtonState(isDisableContinueButton);
      handleFormValues({ location: value });
    } else {
      clearLocationStates();
    }
  };

  const clearLocationStates = () => {
    handleFormValues({
      location: "",
      city: "",
      state: "",
      zip: "",
      mapRadius:"",
      lat: "",
      lng: "",
      isGitchaAvailable: null,
      forGitchaAvailablePopup : {}
    });
  };

  const isGitchaAvailableLocation = async (addressObject) => {
    const state = _.get(addressObject, "state", "");
    const city = _.get(addressObject, "city", "");
    let county = _.get(addressObject, "county", "");

    if (!county) {
      const reqPayload = `address=${city + ", " + state}`;
      await getGeoLocation(reqPayload).then((data) => {
        const results = _.get(data, "results");

        if (results?.length > 0) {
          const firstAddressComponent = _.get(
            results[0],
            "address_components",
            ""
          );

          try {
            if (firstAddressComponent && firstAddressComponent.length > 0) {
              const countyArray = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "administrative_area_level_2";
              });

              if (countyArray.length) {
                county = _.get(countyArray?.[0], "short_name", "");
              }
            }
          } catch { }
          handleFormValues({ county: county });
        }
      });
    }
    if (!county) {
      handleFormValues({ isGitchaAvailable: false });
      handleGitchaAvailableModal(true);
      clearZipCodeList();
      setZipCodeEnterdValue("")
    } else {
      checkGitchAvailability({
        county: county,
        state: state,
      }).then((response) => {
        const isGitchaAvailable = _.get(response, "result", "");
        handleFormValues({ isGitchaAvailable: isGitchaAvailable });
        if (!isGitchaAvailable) {
          handleGitchaAvailableModal(true);
          clearZipCodeList();
          setZipCodeEnterdValue("")
        }
      });
    }
  };

  const handleItemSelection = async (address) => {
    clearLocationStatesExceptLatLng(handleFormValues);
    handleShowMarker(true);
    HandleMapClickOrDrag(false);
    const formatted_address = _.get(address, "formatted_address", "");
    const firstAddressComponent = _.get(address, "address_components", "");
    const latitude = _.get(address, "geometry.location.lat", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lat", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});
    const longitude = _.get(address, "geometry.location.lng", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lng", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});

    let addressObject = {
      lat: latitude,
      lng: longitude,
      location: formatted_address?.trim(),
      zipCode: [],
      zipIdValues: [],
      zipId:[]
    };
    const locality = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "locality";
    });
    const zip = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "postal_code";
    });
    if (zip.length) {
      addressObject.zip = _.get(zip?.[0], "short_name", "");
    }
    if (locality.length) {
      addressObject.city = _.get(locality?.[0], "long_name", "");
    }
    else{
      await getLocalityFromZipCode(addressObject)
    }

    const county = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_2";
    });

    if (county.length) {
      addressObject.county = _.get(county?.[0], "long_name", "");
    }

    const state = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_1";
    });
    if (state.length) {
      addressObject.state = _.get(state?.[0], "short_name", "");
    }
    if(addressObject?.city== null || addressObject?.city == ""){
      setLocationError("Please enter a valid city and state. ")
      const isDisable = true;
      handleContinueButtonState(isDisable);
      clearAddressList();
    }
    else{
      setLocationError("")
      isGitchaAvailableLocation(addressObject);
      handleFormValues(addressObject);
      setZipCodeArray([]);
      setShowPolygon(false)
      clearAddressList();
    }
  };

  const clearAddressList = () => {
    setLocationList([]);
  };

  const handleMapRadiusChanges = (e) => {
    const value = _.get(e, "value", "");
    let zoomLvelBasedOnRadius;

    switch (value) {
      case 1:
        zoomLvelBasedOnRadius = 12;
        break;
      case 5:
        zoomLvelBasedOnRadius = 10;
        break;
      case 10:
        zoomLvelBasedOnRadius = 9;
        break;
      case 15:
        zoomLvelBasedOnRadius = 8;
        break;
      case 20:
        zoomLvelBasedOnRadius = 8;
        break;
      case 25:
        zoomLvelBasedOnRadius = 8;
        break;
      case 30:
        zoomLvelBasedOnRadius = 7;
        break;
      case 50:
        zoomLvelBasedOnRadius = 7;
        break;
    }
    handleFormValues({
      mapRadius: value,
      mapZoom: zoomLvelBasedOnRadius,
    });
  };

  const handleNeighborhoodChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    handleFormValues({ neighborHood: value });
  };

  const handleHighSchoolChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    handleFormValues({ highSchool: value });
  };


  const handleZipCodeChange = (e) => {
    setLocationError("")
    setZipCodeEnterdValue(e.target.value)
    handleFormValues({
      location: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      mapRadius: "",
      // neighborHood: "",
      // highSchool: "",
      isGitchaAvailable: false,
      zipCode: zipCode
    });
    const value = capitalizeString(e.target.value);
    const reqPayload = `address=${value}&components=country:US`;

    if (value.trim() !== "" && value?.length > 4) {
      getZipDetails({ reqPayload: reqPayload });
    } else {
      clearAddressList();
    }
    // if (value && value.trim() !== "") {
    //   setLocation(value);

    // }
    // else {
    //   clearLocationStates();
    // }
  };

  const getZipDetails = async ({ eventType, reqPayload }) => {
    clearZipCodeList();
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");

      if (results?.length > 0) {
        const formatted_address = _.get(results?.[0], 'formatted_address', '')
        // if(results?.length === 1 && formatted_address!== "United States"){
        //   setLocationList(results);
        // }
        const firstLocationResult = _.get(
          results[0],
          "geometry.location",
          ""
        );
        const firstAddressComponent = _.get(
          results[0],
          "address_components",
          ""
        );

        const lat = _.get(firstLocationResult, "lat", "");
        const lng = _.get(firstLocationResult, "lng", "");
        let addressObject = { lat: lat, lng: lng };


        try {

          if (firstAddressComponent && firstAddressComponent.length > 0) {
            const street_no = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "street_number";
            });

            if (street_no.length) {
              addressObject.street_no = _.get(street_no?.[0], "short_name", "");
            }

            const route = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "route";
            });

            if (route.length) {
              addressObject.route = _.get(route?.[0], "short_name", "");
              addressObject.street = _.get(route?.[0], "short_name", "");
            }
            const locality = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "locality";
            });
            if (locality.length) {
              addressObject.city = _.get(locality?.[0], "long_name", "");
            }
            const county = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "administrative_area_level_2";
            });
            if (county.length) {
              addressObject.county = _.get(county?.[0], "short_name", "");
            }

            const state = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "administrative_area_level_1";
            });
            if (state.length) {
              addressObject.state = _.get(state?.[0], "short_name", "");
            }
            const zip = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "postal_code";
            });
            if (zip.length) {
              addressObject.zip = _.get(zip?.[0], "short_name", "");
              results[0].zipCode = _.get(zip?.[0], "short_name", "");
              setZipList(results)
              handleFormValues({ forGitchaAvailablePopup: addressObject });
              isGitchaAvailableLocation(addressObject)
            }
          }
        } catch { }


      } else {
        const isDisable = true;
        // handleFormValues({ invalidLocation: true })
      }
    });
  };

  const handleZipCodeItemSelection = async (address) => {
    const exist = zipCodeArray.find(
      (loc) =>
        loc?.zipCode == address?.zipCode
    );

    if (!exist) {
      clearZipCodeList();
      setZipCodeArray([...zipCodeArray, { zipCode: address?.zipCode, zipId: null }])
      setZipCodeEnterdValue("")
      const formatted_address = _.get(address, "formatted_address", "");
      const firstAddressComponent = _.get(address, "address_components", "");
      const latitude = _.get(address, "geometry.location.lat", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lat", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});
      const longitude = _.get(address, "geometry.location.lng", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lng", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});

      let addressObject = {
        lat: "",
        lng: "",
        location: "",
        mapRadius: null,
        zipCode: [...zipCodeArray, { zipCode: address?.zipCode, zipId: null }]
      };


      handleFormValues(addressObject);
      clearZipCodeList();
      setShowPolygon(true)
      setShowMarker(false)
    } else {
      setZipCodeEnterdValue("")
      clearZipCodeList();
    }

  };

  const handleRemoveLocation = (removeLocation) => {
    const someArray = zipIdValues.filter(loc => loc?.zipCode != removeLocation?.zipCode);
    handleFormValues({ zipCode: someArray, zipIdValues: someArray, focusOut: true });
    setZipCodeArray(someArray);
  }

  const clearZipCodeList = () => {
    setZipList([]);
  };

  return (
    <>
      {!location && !lat && !lng && (
        <GeoLocation handleFormValues={handleFormValues} />
      )}
      {/* <img src={mapImg} style={mapSTyle} ></img> */}
      <div className={!isEdit ? "center-content-sec mw-100 mb-12" : ""}>
        <div className="wantad-location-wrap mb-0">
          <div className="row">
            <div className="col-md-12">
              <div className="common-input-wrap mb-0">
                {!isEdit && (
                  <Form.Label>
                    City and State<span>*</span>
                  </Form.Label>
                )}
                <div className="position-relative mb-10">
                  {locationList.length > 0 ? (
                    <Button
                      variant=""
                      className="search-clear-btn"
                      onClick={() => clearAddressList()}
                    ></Button>
                  ) : (
                    ""
                  )}
                  <InputGroupControl
                    disabled={!lat && !lng ? true : false}
                    inputGroupClass="wantad-location-search position-relative mb-0"
                    ariaLabel="Example"
                    ariaDescribedBy="basic-addon3"
                    placeholder="City and state"
                    errorMessage={locationError}
                    value={location ? location : ""}
                    iconClass="search-icon"
                    isIconEnable={true}
                    iconId="button-addon3"
                    onChange={(e) => {
                      handleLocationChange(e);
                    }}
                  />
                  {locationList.length > 0 ? (
                    <div className="location-result-wrap">
                      <ul>
                        {locationList?.map((address, i) => {
                          return (
                            <li key={i}>
                              <a
                                role="button"
                                href={void 0}
                                onClick={(e) => {
                                  handleItemSelection(address);
                                }}
                              >
                                {_.get(address, "formatted_address", "")}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="common-input-wrap mb-0">
                <div className="lot-select-wrap mb-10">
                  <Form.Label>
                    Radius<span>*</span>
                  </Form.Label>
                  <SelectDropDown
                    name="colors3"
                    options={radiusList || []}
                    className="lotsize-select"
                    classNamePrefix="select"
                    placeholder="Select mile radius"
                    value={filterArrayBasedOnKey({
                      list: radiusList,
                      filterKey: "value",
                      value: mapRadius,
                    })}
                    onChange={(e) => handleMapRadiusChanges(e)}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        transform:
                          state.selectProps.menuIsOpen && "rotate(180deg)",
                      }),
                    }}
                    errorClass="error-msg error-msg-height"
                  //errorMessage={lotSizeMinError}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="segment-divider mb-24">OR</div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-3">
              <Form.Label >
                ZIP Code&#40;s&#41;<span>*</span>
              </Form.Label>
              <div className="textbox-wrap pr-42 position-relative mb-1">

                {/* {zipList.length > 0 ? (
                  <Button
                    variant=""
                    className="search-clear-btn"
                    onClick={() => clearAddressList()}
                  ></Button>
                ) : (
                  ""
                )} */}
                <InputGroupControl
                  inputGroupClass="wantad-location-search position-relative mb-0"
                  ariaLabel="Example"
                  ariaDescribedBy="basic-addon3"
                  placeholder="34567"
                  errorMessage=""
                  value={zipCodeEnterdValue ? zipCodeEnterdValue : ""}
                  iconClass="search-icon"
                  isIconEnable={true}
                  iconId="button-addon3"
                  onChange={(e) => {
                    handleZipCodeChange(e);
                  }}
                  // onBlur={()=>{handleFormValues({focusOut: true})}}
                  disabled={(!lat && !lng) ? true : false}
                />
                {zipList?.length > 0 ? (
                  <div className="location-result-wrap">
                    <ul>
                      {zipList?.map((address, i) => {
                        return (
                          <li key={i}>
                            <a
                              role="button"
                              href={void 0}
                              onClick={(e) => {
                                handleZipCodeItemSelection(address);
                              }}
                            >
                              {address.zipCode}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}

              </div>
              {zipCodeArray.length > 0 ? (

                <div className="zipcode-tag-wrap pb-3">
                  {zipCodeArray.map((loc) => {
                    return (
                      <div className="zipcode-tag me-3">
                        <button className="btn zipcode-close-btn me-1" onClick={() => handleRemoveLocation(loc)}>
                          <img src={ZipCloseIcon} alt={ZipCloseIcon} className="" />
                        </button>
                        {loc?.zipCode}
                      </div>
                    );
                  })}
                </div>
              ) : ""}
            </div>
          </div>

          <div className="googlemap-responsive">
            <div className="map-outer" ref={targetRef}>
              <WantAdGoogleMap
                defaultProps={defaultProps}
                mapHeight="100%"
                mapWidth="100%"
                handleFormValues={handleFormValues}
                mapType={mapType}
                mapRadius={mapRadius}
                handleShowMarker={handleShowMarker}
                HandleMapClickOrDrag={HandleMapClickOrDrag}
                isClickOrDrag={isClickOrDrag}
                showMarker={showMarker}
                getAddresseDetails={getAddresseDetails}
                showPolygon={showPolygon}
                zipCode={zipCode}
                zipIdValues={zipIdValues}
              />
            </div>
          </div>
          <div className="google-map-label mb-24">
            Drag location pin to your desired area
          </div>
          <div className="row">

            <div className="col-md-6">
              <div className="common-input-wrap mb-2">
                <Form.Label>Preferred Neighborhood</Form.Label>
                <TextBox
                  type="text"
                  value={neighborHood}
                  onChange={(e) => {
                    handleNeighborhoodChange(e);
                  }}
                  placeholder="Neighborhood"
                ></TextBox>
              </div>
            </div>
            {adType !== SHORT_RENT && (
              <div className="col-md-6">
                <div className="common-input-wrap mb-2">
                  <Form.Label>Preferred High School</Form.Label>
                  <TextBox
                    type="text"
                    value={highSchool}
                    onChange={(e) => {
                      handleHighSchoolChange(e);
                    }}
                    placeholder="High School"
                  ></TextBox>
                </div>
              </div>
            )}
          </div>
        </div>
        <GitchaNotAvailableModal
          show={gitchaModalShow}
          session={WANT_AD_KEY}
          clearLocationFields={clearLocationStates}
          onHide={() => handleGitchaAvailableModal(false)}
          title={"Gitcha isn't currently available in this location yet."}
          desc={
            "We are diligently working to open up your area! Please type in the cities where you need us, and we'll take it up with the bosses."
          }
        />
      </div>
    </>
  );
};
export default WantAdLocation;
