/**
 * @file   Source/gitcha_Web/src/mobile/components/common/UserPlan/MemberPlan.js
 * @brief  This file is responsible for selecting subscription plans for member user in mobile view
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";

import { Button, Tab, Tabs } from "react-bootstrap";
import CancelPlanModal from "../UserPlan/CancelPlanModal";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  getUserId,
  getLocalStorage,
  setLocalStorage,
  getUserType,
  checkPlanCanbeUpgraded,
} from "../../../../helpers/common";
import {
  createSubscriptionPlanOnSignup,
  getActivePromoCodeDetails,
} from "../../../../services/PlanService";
import { USER_INFO_KEY } from "../../../../constants/common";
import { MEMBER_PROFILE, REVIEW_PLAN, USER_TYPE } from "../../../../constants/onBoarding";
import AnnualMemberPlan from "./AnnualMemberPlan";
import MonthlyMemberPlan from "./MonthlyMemberPlan";
import { useNavigate } from "react-router-dom";
import ContactModal from "../ContactModal";
import PlanUpgradeRestrictionModal from "../../../../components/PlanUpgrade/PlanUpgradeRestrictionModal";

const MemberPlan = (props) => {
  const {
    userDetails,    
    handlePageToggle,
    handleBackButtonClick,
    currentActivePlan,
    setContinueButtonClicked
  } = props;
  const navigate = useNavigate()  
  const [selectedPeriod, setSelectedPeriod] = useState(
    userDetails?.period ? userDetails?.period : ""
  );
  const [selectedPlan, setSelectedPlan] = useState(
    userDetails?.PlanName ? userDetails?.PlanName : ""
  );
  const [key, setKey] = useState("annual");
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const [showCancelPlanModal, setCancelPlanModal] = useState(false);
  const CancelPlanModalClose = () => setCancelPlanModal(false);
  const CancelPlanModalShow = () => setCancelPlanModal(true);
  const [isCurrentTrial, setIsCurrentTrial] = useState(false);
  const userId = getUserId();
  const [fromSignUp, setFromSignUp] = useState(false);
  const [enablePromocode, setEnablePromocode] = useState(false);
  const [enablePromocodePeriod, setEnablePromoCodePeriod] = useState([]);
  const userType = getUserType();
  const [contactModalState, setContactModalState] = useState(false);
  const ContactModalClose = () => setContactModalState(false);
  const ContactModalShow = () => setContactModalState(true);
  const [showPlanRestrictionModal, setShowPlanRestrictionModal] = useState(false);
  useEffect(() => {
    getActivePromoCodeDetails().then((response) => {
      let temp = [];
      const res = _.get(response, "result", []);
      if (res?.length > 0) {
        const result = res.filter(
          (planDetails) =>
            planDetails?.UserType == 1 || planDetails?.UserType == 3
        );
        if (result && result.length > 0) {
          setEnablePromocode(true);
        }
        result.forEach((data) => {
          temp.push(data?.Period);
        });
        setEnablePromoCodePeriod(temp);
      }
    });
  }, []);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if (userDetailsFromStore[0].PlanName === "Trial") {
        setIsCurrentTrial(true);
      } else {
        setIsCurrentTrial(false);
      }
    }
  }, [userDetailsFromStore]);

  useEffect(() => {
    if (userDetails?.PlanName == null) {
      setFromSignUp(true);
    } else {
      setFromSignUp(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (selectedPeriod === 2) {
      setKey("monthly");
    } else {
      setKey("annual");
    }
  }, [selectedPeriod]);


  const handleUpgrade = (period, plan, continueClicked=false) => {    
      setSelectedPeriod(period);
      setSelectedPlan(plan);
      if(!continueClicked){
        setContinueButtonClicked(false);
      }
      if (checkPlanCanbeUpgraded(currentActivePlan, plan, period)?.canPlanUpgrade == false  && !continueClicked) {
        setShowPlanRestrictionModal(true);      
      } else {     
          handlePageToggle({
            activePage: REVIEW_PLAN,
            planDetails: {
              period: period,
              selectedPlan: plan,
              fromSignUp: fromSignUp,
              enablePromocode: enablePromocode,
              enablePromocodePeriod: enablePromocodePeriod,
            },
          });  
      }    
    };

  const handleDowngrade = () => {
       CancelPlanModalShow()
  }

  const upgradeUserToFreePlan = async () => {
    const data = {
      PlanName: "Lite",
      PlanPeriod: 1,
      UserType: userType,
    };

    await createSubscriptionPlanOnSignup(data)
      .then(async (response) => {
        if (response.errorCode === 0) {
          toast.dismiss();
          toast.success("You've selected Lite!");
          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["planStatus"] = true;
          setLocalStorage(USER_INFO_KEY, userInfo);
          handlePageToggle({ activePage: MEMBER_PROFILE });
          // }
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleClose = () => {
    if (userDetails?.period === 2) {
      setKey("monthly");
    } else {
      setKey("annual");
    }
    props.onHide();
  };

  function BackButton () {
    let isShow =  true
    if(fromSignUp){
      if(_.get(getLocalStorage(USER_INFO_KEY), 'userInfo.planStatus')){
        isShow = false
      }
    }
  if(isShow){
    return    <a className="back-link-small" href={void 0} role="button" 
    onClick={()=> fromSignUp? handleBackButtonClick({prevPage: USER_TYPE }): navigate(-1)}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
        fill="#1B1C1E"
      />
    </svg>
  </a>
  }
  }

  return (
    <>
      <div className="gitcha-main-wrapper pb-0">
        <div className="content-wrapper vh-100">
          <div className="inner-layout-header-new bg-white">
            <div className="review-plan-header header-brdr-btm w-100">
              <div className="signup-login-header-top signup-header-txt clearfix">
                <BackButton/>
                <span>Plans and Pricing</span>
              </div>
            </div>
          </div>

          <div className="inner-layout-content-new h-100 bg-grey-variant">
            <div className="select-plan-wrap pl-16 pr-16 pt-4 min-h-100 clearfix">
              <Tabs
                className="mb-20 common-navtab"
                id="controlled-tab-example"
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="annual" title="Annual">
                  <AnnualMemberPlan
                    userDetails={userDetails}
                    handleUpgrade={handleUpgrade}
                    upgradeUserToFreePlan={upgradeUserToFreePlan}
                    handleDowngrade={handleDowngrade}
                    ContactModalShow={ContactModalShow}
                  />
                </Tab>
                <Tab eventKey="monthly" title="Monthly">
                  <MonthlyMemberPlan
                    userDetails={userDetails}
                    handleUpgrade={handleUpgrade}
                    upgradeUserToFreePlan={upgradeUserToFreePlan}
                    handleDowngrade={handleDowngrade}
                    ContactModalShow={ContactModalShow}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <CancelPlanModal
    show={showCancelPlanModal}
    onHide={CancelPlanModalClose}
    userDetails={userDetails}
    />
    <ContactModal
        show={contactModalState}
        onHide={ContactModalClose}
        sourceType={2} // Enterprise
      />
      <PlanUpgradeRestrictionModal
      show={showPlanRestrictionModal}
      onHide={() => {setShowPlanRestrictionModal(false); setContinueButtonClicked(false)}}
      currentActivePlan={currentActivePlan}
      selectedPeriod={selectedPeriod}
      handleContinueButton={()=>{handleUpgrade(selectedPeriod, selectedPlan, true); setContinueButtonClicked(true)}}
      />    
      </div>
    </>
  );
};

export default MemberPlan;
