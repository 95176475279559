/**
 * @file   src\mobile\components\ReceiveIntroduction\IntroductionDetails.js
 * @brief  This file is responsible for showing introduction details in mobile home
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import NotAFitModal from "./NotAFitModal";
import AgentProfileInfoModal from "./AgentProfileInfoModal";
import ExpiredModal from "./ExpiredModal";
import IntroductionActionDropdown from "./IntroductionActionDropdown";
import "../../styles/clients.scss";
import { AGENT_USER_MONTHLY_PRO_MEMBER, AGENT_USER_YEARLY_PRO_MEMBER, BUY, INTRODUCTION_EXPIRATION_TIME, INVENTORY_LAND, INVENTORY_LTR, INVENTORY_LTR_STR, INVENTORY_PURPOSE, INVENTORY_SELL, INVENTORY_SELL_LTR, INVENTORY_SELL_STR, INVENTORY_STR, IS_LAND, PRO_MEMBER_PLAN, USER_TYPE_AGENT } from "../../../constants/common";
import { createIntroductionShareLink, getIntroductionInventoryDetailsById, updateIntroNotFit } from "../../../services/introductionService";
import { getPropertyPurpose, getUserId, getUserType, loggedInUser, removeDecimalZeros, setBodyBackgroundWhite } from "../../../helpers/common";
import { convertToNumber } from "../../../helpers/validations";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";
import { buildReceiveIntroMustHaveHtmlForMobile, buildSchoolNeigborhoodHtmlForMobile, checkInventoryType } from "../../../helpers/inventory";
import { getReceiveIntroDescriptionText } from "../../../helpers/getPropertyIcons";
import PhotoGalleryMob from "../../../components/Common/PhotoGalleryMob";
import ReasonNotAFitModal from "./ReasonNotAFitModal";
import { updateIntroduction } from "../../../helpers/message";
import MessageSentSuccessOffcanvas from "./MessageSentSuccessOffcanvas";
import SendMessageOffcanvas from "./SendMessageOffcanvas";
import NeedAnAgentModal from "../../../components/Onboarding/NeedAnAgentModal";
import { VIEW_RECEIVED_INTRODUCTIONS } from "../../../constants/onBoarding";
import ShareLinkOffCanvas from "./ShareLinkOffCanvas";
import userProfile from "../../../appState/userProfile";

const IntroductionDetails = (props) => {

  const { setUIKey, setIntroductionPhotos, wantadAdType } = props;

  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add('bg-offcanvas-fix');
  }, []);

  const [showNotAFitModal, setShowNotAFitModal] = useState(false);
  const NotAFitModalClose = () => setShowNotAFitModal(false);
  const NotAFitModalShow = () => setShowNotAFitModal(true);

  const [showAgentProfileInfoModal, setShowAgentProfileInfoModal] = useState(false);
  const AgentProfileInfoModalClose = () => setShowAgentProfileInfoModal(false);
  const AgentProfileInfoModalShow = () => setShowAgentProfileInfoModal(true);

  const [showMessageSentOffcanvas, setShowMessageSentOffcanvas] = useState(false);
  const MessageSentOffcanvasClose = () => setShowMessageSentOffcanvas(false);

  const [showSendMessageOffcanvas, setShowSendMessageOffcanvas] = useState(false);
  const SendMessageOffcanvasClose = () => setShowSendMessageOffcanvas(false);
  const SendMessageOffcanvasShow = () => setShowSendMessageOffcanvas(true);

  const [showExpireModal, setExpireModal] = useState(false);
  const expireModalClose = () => setExpireModal(false);

  const [timeOutStatus, setTimeOutStatus] = useState(false);

  const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);
  const markFitReasonModalClose = () => setNotFitReasonPopup(false);

  const [showMarkFitConfirmModal, setMarkFitConfirmModal] = useState(false);
  const markFitConfirmModalClose = () => setMarkFitConfirmModal(false);

  const [isMessageSent, setMessageSent] = useState(false);


  const [inventoryDetails, setInventoryDetails] = useState();
  const photos = _.get(inventoryDetails, "photos", []).filter(
    (item) => item.IsValid != 0
  );
  const Sub_PropertyTypeId = convertToNumber(
    _.get(inventoryDetails, "Sub_PropertyTypeId", "")
  );
  const propertyPurpose = getPropertyPurpose(
    _.get(inventoryDetails, "Is_Seller", ""), _.get(inventoryDetails, "Is_ShortTerm_Renter", ""), _.get(inventoryDetails, "Is_LongTerm_Renter", "")
  );
  const userType = getUserType();
  const invType = checkInventoryType(propertyPurpose);
  const nice2Have = _.get(inventoryDetails, "nice2Have", []);
  const mapImg = _.get(inventoryDetails, "Location_Img", "");
  const highSchool = _.get(inventoryDetails, "HighSchool", null);
  const neighborHood = _.get(inventoryDetails, "NeighborHood", null);
  const description = _.get(inventoryDetails, "Add_Details", "");
  const salesPercnt = _.get(inventoryDetails, "Compensate_Percent", "");
  const isAgent = _.get(inventoryDetails, "UserTypeId", "") === USER_TYPE_AGENT;
  const firstName = _.get(inventoryDetails, "FirstName", "");
  const lastName = _.get(inventoryDetails, "LastName", "");
  const fullName = firstName + " " + lastName;
  const introductionId = props.introductionId;
  const introAcceptedDate = _.get(inventoryDetails, "Accept_Date", "");
  const userId = getUserId();
  const userDeleted = location.state && location.state['userDeleted'];
  const isNiceToHaveExist = nice2Have.length > 0;
  const is_Seller = _.get(inventoryDetails, "Is_Seller", 0) === 1 ? true : false;
  const typeBasedOnProperty = inventoryDetails?.PropertyTypeId == IS_LAND ? INVENTORY_LAND :
    Sub_PropertyTypeId === IS_LAND ? INVENTORY_LAND : invType;
  const [showNeedAnAgentModal, setShowNeedAnAgemtModal] = useState(false);
  const needAnAgentModalOpen = () => {
    setShowNeedAnAgemtModal(true)
  }
  const needAnAgentModalClose = () => {
    setShowNeedAnAgemtModal(false)
  }

  const [showCustomLinkPopuop, setShowCustomLinkPopup] = useState(false);
  const handleCustomLinkPopupOpen = () => {
    setShowCustomLinkPopup(true);
  };
  const handleCustomLinkPopupClose = () => {
    setShowCustomLinkPopup(false);
  };

  const agent = loggedInUser();
  const [linkToShare, setLinkToShare] = useState("")
  const [introShareLinkCreatedDate, setIntroShareLinkCreatedDate] = useState("")
  const [shareLinkExpired, setShareLinkExpired] = useState(false)

  // const introAcceptedDate =  new Date(_.get(inventoryDetails, "Accept_Date", ""));


  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("")
  const [isPremiumUser, setIsPremiumUser] = useState(null);


  let titleText = "";
  switch (invType) {
    case INVENTORY_SELL:
      titleText = " selling";
      break;
    case INVENTORY_LTR:
      titleText = " renting";
      break;
    case INVENTORY_STR:
      titleText = " renting";
      break;
    case INVENTORY_LTR_STR:
      titleText = " renting";
      break;
    case INVENTORY_SELL_LTR:
      titleText = " selling, renting";
      break;
    case INVENTORY_SELL_STR:
      titleText = " selling, renting";
      break;
    default:
      titleText = " selling, renting";
      break;
  }

  useEffect(() => {
    setBodyBackgroundWhite()
    if (!introductionId) {
      navigate(pageURLs.home);
    }
    getInventoryDetails(introductionId);
  }, []);

  const getInventoryDetails = async (introductionId) => {
    if (introductionId) {
      await getIntroductionInventoryDetailsById(introductionId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
            setIntroductionPhotos(responseResult?.photos);
            setLinkToShare((process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL + "/form/introduction/" + agent?.userInfo?.firstName + "-" + agent?.userInfo?.lastName + "-" + responseResult?.Intro_Unique_Id))
            setIntroShareLinkCreatedDate(responseResult?.Intro_Unq_ExpDt != null ? (responseResult?.Intro_Unq_ExpDt) : null)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.home);
    }
  };


  useEffect(() => {
    if (userDetails) {
      if (userDetails?.length === 1) {
        if (userDetails[0]?.PlanName) {
          setUserPlan(userDetails[0]?.PlanName)
        }
      } else {
        if (userDetails[0]?.PlanName === 'Trial') {
          setUserPlan(userDetails[1]?.PlanName)
        } else {
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }
  }, [userDetails]);


  useEffect(() => {
    if (userPlan) {
      if (userPlan === PRO_MEMBER_PLAN) {
        setIsPremiumUser(true)
      }
      else {
        setIsPremiumUser(false)
      }
    }

  }, [userPlan])



  useEffect(() => {
    const checkShareLinkExpired = () => {
      if (introShareLinkCreatedDate) {
        const uniqueKeyDate = moment.utc(introShareLinkCreatedDate); // Replace with your UTC date string

        // Calculate expiration date
        const uniqueKeyDate72 = moment.utc(uniqueKeyDate).add(INTRODUCTION_EXPIRATION_TIME, 'hours').toISOString();
        // Compare expiration date with current time
        if ((new Date(uniqueKeyDate72) - new Date()) <= 0) {
          setShareLinkExpired(true);
        } else {
          setShareLinkExpired(false);
        }

      }


    };

    // Initial check
    checkShareLinkExpired();

    // Set an interval to check visibility every second
    const interval = setInterval(() => {
      checkShareLinkExpired();
    }, 1000);

    return () => clearInterval(interval);
  }, [introShareLinkCreatedDate]);



  const createShareLink = async () => {
    let reqPayload = {
      introductionId: introductionId
    }
    if (reqPayload) {
      await createIntroductionShareLink(reqPayload)
        .then(async (response) => {
          const responseResult = _.get(response, "result", null);
          if (responseResult && responseResult.length !== 0) {
            setLinkToShare((process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL + "/form/introduction/" + agent?.userInfo?.firstName + "-" + agent?.userInfo?.lastName + "-" + responseResult?.Intro_Unique_Id))
            setIntroShareLinkCreatedDate(responseResult?.Intro_Unq_ExpDt != null ? (responseResult?.Intro_Unq_ExpDt) : null)
            handleCustomLinkPopupOpen();
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }

  const [then, SetThen] = useState('');
  const countDown = (introAccepted72 = '') => {
    let datevalue = introAccepted72 ? introAccepted72 : then;
    var ms = moment(moment(new Date(datevalue), "DD/MM/YYYY HH:mm:ss").diff(moment(new Date(), "DD/MM/YYYY HH:mm:ss")));
    var d = moment.duration(ms);

    var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
    if (Math.floor(d.asHours()) > 1) {
      return moment.utc(ms).format("mm") > 0 ? { time: Math.floor(d.asHours()) + 1, type: 'hours', timeInNumber: Math.floor(d.asHours()) + 1 } : { time: Math.floor(d.asHours()), type: 'hours', timeInNumber: Math.floor(d.asHours()) + 1 };
    } else if (moment.utc(ms).format("mm") > 0) {
      return { time: moment.utc(ms).format("mm"), type: 'minutes', timeInNumber: moment.utc(ms).format("mm") };
    } else if (moment.utc(ms).format("ss") > 0) {
      return { time: moment.utc(ms).format("ss"), type: 'seconds', timeInNumber: moment.utc(ms).format("ss") };
    } else {
      return { time: 0, type: '', timeInNumber: 0 };
    }
  }

  const [timeAgo, setTimeAgo] = useState();

  useEffect(() => {
    // if(timeAgo){
    if (timeAgo?.timeInNumber && (timeAgo?.timeInNumber == 0 || timeAgo?.timeInNumber == '00')) {
      props.timeOutStatus(true)
    } else {
      const interval = setInterval(() => setTimeAgo(countDown()), 1000);
      return () => clearInterval(interval);
    }
    // }


  }, [timeAgo, then]);

  useEffect(() => {
    let introAccepted72 = moment.utc(introAcceptedDate).add(INTRODUCTION_EXPIRATION_TIME, 'hours').toISOString();
    // let introAccepted72 = moment.utc(introAcceptedDate).add(5, 'hours').toISOString();
    // let introAccepted72 = moment.utc(introAcceptedDate).add(25, 'minutes').toISOString();
    // let introAccepted72 = moment.utc(introAcceptedDate).add(25, 'minutes').toISOString();

    if (introAccepted72) {
      if (((new Date(introAccepted72) - new Date()) > 0)) {
        SetThen(introAccepted72);
        countDown(introAccepted72);

      } else {
        setTimeOutStatus(true)
      }
    }
  }, [introAcceptedDate]);

  const loadDetails = () => {
    return buildReceiveIntroMustHaveHtmlForMobile({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: inventoryDetails,
    });
  };

  const loadNiceToHaveList = (niceToHave) => {
    return (
      niceToHave.map((n2h, index) => {
        const icon = _.get(n2h, "icon", "");

        return (
          <div class="property-tag-mobile mb-2" key={index}>
            <div class="tag-icon-mobile">
              <img src={
                icon
                  ? require("../../../images/" + icon)
                  : ''
              } />

            </div>
            <span>{_.get(n2h, "text", "")}</span>
          </div>
        )
      })

    )
  };

  const updateToNotFit = async (reqPayload, isSkip) => {
    if (reqPayload) {
      await updateIntroNotFit(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isdeleted: true,
            }

            let updateresponse = await updateIntroduction(reqPayload?.IntroductionId, data);
            setUIKey(VIEW_RECEIVED_INTRODUCTIONS);
            // navigate(pageURLs.allIntroductions)

          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const handleNotFitConfirm = () => {
    console.log("dsfd")
    setNotFitReasonPopup(true)

  }

  return (

    <div className="gitcha-main-wrapper view-client-wrap intro-preview-wrap pb-182 bg-white">
      <div className="content-wrapper content-wrapper-h-102-percent">
        <div className="inner-layout-header-new bg-white">
          <div className="top-three-comp-header w-100 top-three-comp-headerborder">
            <div className="top-three-comp-header-inner w-100">
              <a className="back-link-btn" href={void (0)} onClick={() => { setUIKey(VIEW_RECEIVED_INTRODUCTIONS) }}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                </svg>
              </a>
              <div className="heading">Introduction</div>
              <div className="right-mobbtn-wrap">
                <div className="social-btns-wrap"><IntroductionActionDropdown
                  IntroductionId={introductionId}
                  inventoryDetails={inventoryDetails}
                  reportedUserId={_.get(inventoryDetails, 'Agent_User_Id', '')} /></div>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new">
          <div className="premium-content-wrapper bg-white">
            <div className="confirmation-top-sec">
              <div className="long-txt-fix">
                <h2>{_.get(inventoryDetails, "Location", "")}</h2>
              </div>
              <div className="property-details-mob mb-4">
                {getReceiveIntroDescriptionText(inventoryDetails)}
              </div>
            </div>
            <PhotoGalleryMob photos={photos} setUIKey={setUIKey} />

            <div className="represent-agent brdr-top-common-wrap">
              <h3> {isAgent ? 'Agent Information' : 'Owner Information'}</h3>
              <div className="agent-profile-information">
                {isAgent ? (
                  <div className="profile-details mb-0">
                    <div className="d-flex align-items-center">
                      {_.get(inventoryDetails, 'Img', '') ?
                        <span className="profile-pic"><img src={_.get(inventoryDetails, 'Img', '') ?? noImage} /></span>
                        :
                        <div className="profile-placeholder-wrap profile-bg-orange flex-shrink-0">
                          {fullName.replace(/-/g, "").match(/\b(\w)/g)}
                        </div>
                      }

                      <div className="profile-name">
                        <div className="title d-flex align-items-center">
                          <h2> {fullName}</h2>
                          {_.get(inventoryDetails, 'PlanId', '') === AGENT_USER_MONTHLY_PRO_MEMBER
                            || _.get(inventoryDetails, 'PlanId', '') === AGENT_USER_YEARLY_PRO_MEMBER &&
                            <span className="pro-blue-badge ms-2">PRO</span>}

                        </div>
                        <p className="m-0">Agent @  {_.get(inventoryDetails, "Company", "")}</p>
                      </div>
                    </div>


                    {!userDeleted &&
                      <button type="button" className="btn btn-link" onClick={AgentProfileInfoModalShow}>View Profile</button>
                    }
                  </div>
                ) :
                  (<div className="profile-details mb-0">
                    <div className="d-flex align-items-center">
                      {_.get(inventoryDetails, 'Img', '') ?
                        <span className="profile-pic"><img src={_.get(inventoryDetails, 'Img', '') ?? noImage} /></span>
                        :
                        <div className="profile-placeholder-wrap profile-bg-orange flex-shrink-0">
                          {fullName.replace(/-/g, "").match(/\b(\w)/g)}
                        </div>
                      }
                      <div className="profile-name">
                        <div className="title d-flex flex-column mb-0">
                          <h2> {fullName}</h2>
                          <p className="m-0">Property Owner</p>
                        </div>

                      </div>
                    </div>
                    {(!isAgent && userType != USER_TYPE_AGENT && wantadAdType == BUY) && <div className="confirm-top-right flex-shrink-0">
                      <button className="btn btn-link" onClick={needAnAgentModalOpen}>
                        Need An Agent?
                      </button>
                    </div>}
                  </div>
                  )}
                {_.get(inventoryDetails, "Message", "") &&
                  <div className="agent-bio">
                    <p className="m-0">  {_.get(inventoryDetails, "Message", "")}</p>
                  </div>
                }
              </div>
            </div>

            <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
              <h3>Details</h3>
              <div className="row">
                {loadDetails()}

              </div>

            </div>
            {description &&
              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h3>Description</h3>
                <p>{description}</p>
              </div>
            }

            {isNiceToHaveExist ? (
              <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                <h2>Features</h2>
                {loadNiceToHaveList(nice2Have)}
              </div>
            ) : (
              <></>
            )}

            {buildSchoolNeigborhoodHtmlForMobile({
              mapImg: mapImg,
              highSchool: highSchool,
              neighborHood: neighborHood,
            })}


            {salesPercnt ||_.get(inventoryDetails, "TimeLineText", null) || 
              _.get(inventoryDetails, "Appraisal_on_File", false) || _.get(inventoryDetails, "Inspection_Report", false) ||
              _.get(inventoryDetails, "Seller_Finance", false) || _.get(inventoryDetails, "Flood_Certificate", false) ||
              _.get(inventoryDetails, "Floor_Plan", false) || _.get(inventoryDetails, "Assumable_mortgage", false)

             ? <div className="buyer-info-mob other-details brdr-top-common-wrap mb-4">
              <h3>Other Details</h3>
              <ul>
                {salesPercnt && (
                  <li>
                    <span className="list-icn-wrap-mob"></span>
                    {salesPercnt}% compensation to buyers agent
                  </li>
                )}

                {_.get(inventoryDetails, "TimeLineText", null) ? (
                  <li>
                    <span className="list-icn-wrap-mob"></span>
                    Timeline: {_.get(inventoryDetails, "TimeLineText", null)}
                  </li>
                ) : (
                  ""
                )}



                {_.get(inventoryDetails, "Appraisal_on_File", false) ? (
                  <li >
                    <span className="list-icn-wrap-mob"></span>
                    Appraisal on file
                  </li>
                ) : (
                  ""
                )}

                {_.get(inventoryDetails, "Inspection_Report", false) ? (
                  <li >
                    <span className="list-icn-wrap-mob"></span>
                    Inspection report on file
                  </li>
                ) : (
                  ""
                )}

                {_.get(inventoryDetails, "Seller_Finance", false) ? (
                  <li >
                    <span className="list-icn-wrap-mob"></span>
                    Seller finance available
                  </li>
                ) : (
                  ""
                )}
                {_.get(inventoryDetails, "Flood_Certificate", false) ? (
                  <li >
                    <span className="list-icn-wrap-mob"></span>
                    Flood certificate available
                  </li>
                ) : (
                  ""
                )}

                {_.get(inventoryDetails, "Floor_Plan", false) ? (
                  <li >
                    <span className="list-icn-wrap-mob"></span>
                    Floor plan on file
                  </li>
                ) : (
                  ""
                )}

                {_.get(inventoryDetails, "Assumable_mortgage", false) ? (
                  <li >
                    <span className="list-icn-wrap-mob"></span>
                    Assumable mortgage
                  </li>
                ) : (
                  ""
                )}
              </ul>

            </div> : <></>}

            {isPremiumUser && shareLinkExpired == false && _.get(inventoryDetails, "ClientId", null) &&
              <>
                <div className="share-wrap-style  brdr-top-common-wrap ">
                  <h3>
                    Share Property
                  </h3>
                  <p className="">
                    Want to share this property with your client? Once you create your private viewing link, it will expire after 72 hours.
                    {" "}<a role="button" className="btnStyle" onClick={() => { createShareLink() }} >Create share link</a>
                  </p>

                </div>
              </>
            }


          </div>
        </div>
      </div>

      {(_.get(inventoryDetails, "Agent_User_Id", "") != userId && _.get(inventoryDetails, "IsReplied", "") != 1 && !isMessageSent) ?
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="reply-time d-flex align-items-center justify-content-center">
              <i className="ico-timer"></i><p className="m-0">You have <span>{timeAgo?.time} {timeAgo?.type}</span> left to reply</p>
            </div>
            <div className="btn-wrap">

              {(_.get(inventoryDetails, "Agent_User_Id", "") != userId && _.get(inventoryDetails, "IsReplied", "") != 1) &&
                <button type="button" className="btn btn-primary w-100" onClick={SendMessageOffcanvasShow}>Message {firstName}</button>
              }

              {(_.get(inventoryDetails, "Accept_Date", "") &&
                _.get(inventoryDetails, "Agent_User_Id", "") != userId) &&
                (_.get(inventoryDetails, "IsReplied", "") == 0) &&

                <button type="button" className="btn btn-link w-100" onClick={NotAFitModalShow}>Not a Fit</button>
              }
            </div>
          </div>
        </div>
        :
        (
          <div className="inner-layout-footer-new">
            <div className="inner-btm-fixed-nav">
              <div className="reply-time d-flex align-items-center justify-content-center">
                <p className="m-0">  You sent a message to {firstName}. Go to{" "}
                  <a role="button" className="link" onClick={() => navigate(pageURLs.mobileMessages)}>
                    your messages
                  </a>{" "}
                  to keep the conversation going.</p>
              </div>
            </div>
          </div>
        )

      }
      <NotAFitModal
        show={showNotAFitModal}
        onHide={NotAFitModalClose}
        titleText="Mark not a fit and end conversation."
        description="This action will end the introduction and associated conversation. Once you delete this it cannot be undone."
        updateToNotFit={updateToNotFit}
        introductionId={introductionId}
        showNotFirReasnPopup={showNotFirReasnPopup}
        handleNotFitConfirm={handleNotFitConfirm}
        markFitReasonModalClose={markFitReasonModalClose}
        name={firstName}
      />
      {/* <ReasonNotAFitModal
        show={showMarkFitConfirmModal}
        onHide={markFitConfirmModalClose}
      /> */}
      <AgentProfileInfoModal
        show={showAgentProfileInfoModal}
        onHide={AgentProfileInfoModalClose}
        userId={_.get(inventoryDetails, 'Agent_User_Id', '')}
        isTitleShow={true}
      />

      <ExpiredModal
        show={(!_.get(inventoryDetails, 'IsReplied', '') && timeOutStatus) || _.get(inventoryDetails, 'IsExpired', '') == 1}
        onHide={expireModalClose}
      />
      <MessageSentSuccessOffcanvas
        show={showMessageSentOffcanvas}
        onHide={MessageSentOffcanvasClose}
      />
      <SendMessageOffcanvas
        show={showSendMessageOffcanvas}
        onHide={SendMessageOffcanvasClose}
        timeAgo={timeAgo}
        messageFromSendIntro={_.get(inventoryDetails, "Message", "")}
        IntroductionId={introductionId}
        msgSentStatus={(status) => { setMessageSent(status) }}
      />
      <NeedAnAgentModal
        show={showNeedAnAgentModal}
        onHide={needAnAgentModalClose}
      />

      <ShareLinkOffCanvas
        show={showCustomLinkPopuop}
        onHide={() => handleCustomLinkPopupClose()}
        image={photos.length > 0 ? photos[0]?.image : ''}
        url={linkToShare} />
    </div>
  );
};

export default IntroductionDetails;
