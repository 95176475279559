import HouseTagIcon from "../../../images/house-img.png";
import noImage from "../../../images/no-image-placeholder.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pageURLs } from "../../../constants/pageURLs";
import { useState, useEffect } from "react";
import { getUserId, getUserType } from "../../../helpers/common";
import { fetchUserStatistics } from "../../../actions/userprofile";
import { USER_TYPE_AGENT } from "../../../constants/common";
import PremiumPlanModal from "../../../components/PlanUpgrade/PremiumPlanModal";
import BasicPlanModal from "../../../components/PlanUpgrade/BasicPlanModal";

const SelectProperty = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    propertyList,
    selectedProperty,
    setSelectedProperty,
    userType,
    selectedClient,
    userDetails,
    updateReloadParam
  } = props;

  const userId = getUserId();
  const userStatictics = useSelector((state)=>state?.userProfile?.userStatictics)
  const userPrivileges = useSelector((state)=>state?.userProfile?.userPrivileges)
  const [userCount, setUserCount] = useState({})
  const [allowedCount, setAllowedCount] = useState({})
  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);
  const [showBasicPlanModal, setBasicPlanModal] = useState(false);
  const BasicPlanModalClose = () => setBasicPlanModal(false);
  const BasicPlanModalShow = () => setBasicPlanModal(true);


  useEffect(()=>{
    const payload ={
      UserPlanId : userDetails?.User_Plan_Id
    }

    dispatch(fetchUserStatistics(payload))
  }, [userDetails])

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);


  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);   
  
  
  const handleAddProperty = () => {    
    if(userCount?.Inventory >= allowedCount?.Inventory){
      if(userType === USER_TYPE_AGENT){
        PremiumPlanModalShow()
      }
      else {
        BasicPlanModalShow()
      }
    }
    else{
    if (userType === USER_TYPE_AGENT) {
      navigate(pageURLs.agentCreateInventory,  { state: { data: {clientId: selectedClient?.value, clientName: selectedClient?.label , isAgentAsSeller : selectedClient?.AgentAsSeller} } })
    } else {
      navigate(pageURLs.memberCreateInventory);
    }
    }
  };

  return (
    <div className="position-relative">
      <div className="sub-heading-small position-relative mb-4 f-size-16 f-regular">
        <a
          role="button"
          className="sub-heading-backbtn"
          onClick={() => props.setShowSelectProperty(false)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z"
              fill="#1B1C1E"
            ></path>
          </svg>
        </a>
        Choose property
      </div>
      <div className="modal-choose-property modal-v-scroll">
        {propertyList?.map((property) => {
          return (
            <label className="property-radio">
              <input
                type="radio"
                name="check-box-option"
                id="670"
                onChange={() => {
                  setSelectedProperty(property);
                  props.setShowSelectProperty(false);
                }}
                checked={selectedProperty?.Inv_Id === property?.Inv_Id}
                value={property?.Inv_Id}
              />
              <span>
                <div className="left-img-wrap">
                  <img
                    className="display-thumb-img"
                    src={property?.photo ? property?.photo : noImage}
                  />
                </div>
                <div className="modal-wrap-info">
                  <h2>{property?.Location}</h2>
                  <p>{property?.City}, {property?.State}</p>
                </div>
              </span>
            </label>
          );
        })}
        {/* <label className="property-radio">
            <input type="radio" name="check-box-option" id="578" value="578" />
            <span>
              <div className="left-img-wrap">
                <img className="display-thumb-img" src={HouseTagIcon} />
              </div>
              <div className="modal-wrap-info">
                <h2>Test introduction</h2>
                <p>Eugene, OR</p>
              </div>
            </span>
          </label> */}
      </div>
      <div className="modal-choose-property-btnwrap">
        <button className="btn btn-secondary w-100" onClick={handleAddProperty}>
          Add Property
        </button>
      </div>
      <PremiumPlanModal
        userDetails={userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}
        fromInventoryListing={true}
        showPlanModal={PremiumPlanModalShow}
      />
      <BasicPlanModal
        userDetails={userDetails}
        show={showBasicPlanModal}
        updateReloadParam={updateReloadParam}
        onHide={BasicPlanModalClose}
        fromInventoryListing={true}
        showPlanModal={BasicPlanModalShow}
      />
    </div>
  );
};

export default SelectProperty;
