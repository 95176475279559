import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextArea from "../../../components/Common/TextArea";
import { capitalizeFirstString, getLocalStorage, trimString } from "../../../helpers/common";
import { USER_INFO_KEY } from "../../../constants/common";
import { handleTextBoxChange } from "../../../actions/common";

const ReasonNotAFitModal = (props) => {
  const { show, onHide, introductionId,
    updateToNotFit, name } = props;


  const intitalCheckListStates = {
    locNotFit: false,
    notMyStyle: false,
    price: false,
    size: false,
    other: false
  }
  const [notFitReasons, setNotFitReasons] = useState(intitalCheckListStates);
  const [isSubmitDisabled, setSubmitDisable] = useState(true);
  const [updateMarkNotFitAPICalled, setupdateMarkNotFitAPICalled] = useState(false);
  const [detailsCount, setDetailsCount] = useState(0);
  const [reasonDetails, setDetails] = useState('');
  useEffect(() => {
    if (!updateMarkNotFitAPICalled) {
      if (
        _.get(notFitReasons, "locNotFit", false) ||
        _.get(notFitReasons, "notMyStyle", false) ||
        _.get(notFitReasons, "price", false) ||
        _.get(notFitReasons, "size", false) ||
        _.get(notFitReasons, "other", false) ||
        reasonDetails
      ) {
        setSubmitDisable(false);
      } else {
        setSubmitDisable(true);
      }
    }

    setDetailsCount(reasonDetails && reasonDetails.length ? reasonDetails.length : 0);
  }, [notFitReasons, reasonDetails]);

  const handleDescriptionChange = (e) => {
    const value = capitalizeFirstString(handleTextBoxChange(e));
    if (trimString(value) !== "") {
      setDetailsCount(value.length);
      setDetails(value);
    } else {
      setDetails('');
    }
  };
  const checkListOnchnge = (e) => {
    const key = e.target.id;
    setNotFitReasons((prevState) => ({
      ...prevState,
      [key]: e.target.checked,
    }));
  };



  const buildPayload = (isSkip) => {
    const checkLists = isSkip ? intitalCheckListStates : notFitReasons
    const description = isSkip ? '' : reasonDetails
    return {
      IntroductionId: introductionId,
      Location_Not_Right: _.get(checkLists, 'locNotFit', ''),
      Not_my_Style: _.get(checkLists, 'notMyStyle', ''),
      Price: _.get(checkLists, 'price', ''),
      Size: _.get(checkLists, 'size', ''),
      Other: _.get(checkLists, 'other', ''),
      Reason_Text: description
    }
  }

  const handleButtonClick = async (isSkip = false) => {
    const reqPayload = buildPayload(isSkip)
    setSubmitDisable(true);
    setupdateMarkNotFitAPICalled(true)
    await updateToNotFit(reqPayload, isSkip)
  };
  const checkListArray = [
    { text: "Location wasn’t right for me", id: "locNotFit" },
    { text: "Not my style", id: "notMyStyle" },
    { text: "Price", id: "price" },
    { text: "Size", id: "size" },
    { text: "Other", id: "other" },
  ];

  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup not-fit-modal">
      <Modal.Body className="text-center">
        {/* <h3>Tell them why their introduction wasn’t a good fit</h3> */}
        <h3>Tell {name ? name : 'them'} why their introduction wasn’t a good fit</h3>
        <p>This will help them send better, more accurate introductions in the future.</p>
        <p className="note">Reason for ending conversation:</p>
        <div className="modal-controls-wrap">
          {checkListArray.map((item, index) => {
            return (
              <label className="check-wrap mb-3">
                <input
                  type="checkbox"
                  name="check-confirm-option"
                  id={_.get(item, "id", "")}
                  onChange={checkListOnchnge}
                  disabled={updateMarkNotFitAPICalled}
                />
                <span>{_.get(item, "text", "")}</span>
              </label>
            );
          })}
        </div>
        <div className="textbox-wrap">
          <TextArea
            className="form-control txt-area"
            errorMessage=""
            value={reasonDetails}
            maxLength={500}
            placeholder="Enter reason here"
            onChange={(e) => {
              handleDescriptionChange(e);
            }}
            disabled={!_.get(notFitReasons, "other", false)}
          />
        </div>

        <div className="btn-wrap">
          <button type="button" className="btn btn-primary w-100" onClick={() => handleButtonClick()}
            disabled={isSubmitDisabled}>Submit</button>
          <button type="button" className="btn btn-secondary w-100" onClick={() => handleButtonClick()}>Skip</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ReasonNotAFitModal;
