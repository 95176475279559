import React from "react";
import WantAdFeatures from "./WantAdFeatures";



const PublishedWantAds = () => {
  return (
   <WantAdFeatures/>
  );
};

export default PublishedWantAds;
