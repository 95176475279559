import { getInitials, removeLocalStorage } from "../../../helpers/common";

const SelectClient = (props) => {
  const { selectedClient, setSelectedClient, clientList } = props;
  return (
    <div className="position-relative">
      <div className="sub-heading-small position-relative mb-4 f-size-16 f-regular">
        <a
          role="button"
          className="sub-heading-backbtn"
          onClick={() => props.setShowSelectClients(false)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z"
              fill="#1B1C1E"
            ></path>
          </svg>
        </a>
        Choose client
      </div>
      <div className="modal-choose-property modal-v-scroll">
        {clientList?.map((client) => {
          return (
            <label className="property-radio">
              <input
                type="radio"
                name="check-box-option"
                id="0"
                onChange={() => {
                  setSelectedClient(client);
                  removeLocalStorage("selectedPropertyId")
                  props.setShowSelectClients(false);
                }}
                checked={selectedClient?.value === client?.value}
                value={client?.value}
              />
              <span>
                <div
                  className={
                    "left-prof-wrap " +
                    getInitials(client?.FirstName + " " + client?.LastName)
                      ?.backgroundColor
                  }
                >
                  {
                    getInitials(client?.FirstName + " " + client?.LastName)
                      ?.initials
                  }
                </div>
                <div className="modal-wrap-info">
                  <h2 className="pb-0">
                    {client?.FirstName + " " + client?.LastName}
                  </h2>
                </div>
              </span>
            </label>
          );
        })}
      </div>
      {/* <div className="modal-choose-property-btnwrap">
        <button className="btn btn-secondary w-100">Add Client</button>
      </div> */}
    </div>
  );
};

export default SelectClient;
