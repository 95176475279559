import React, { useState, useEffect } from "react";
import MustHaveBtnGroupMob from "../MustHaveBtnGroupMob";
import { handleRadioButonChange } from "../../../actions/common";
import WantAdSquareFeet from "../../commonComponents/WantAdSquareFeet";
import { getLocalStorage, removeDecimalZeros } from "../../../helpers/common";
import { STORY_ANY, STORY_SINGLE, WANT_AD_KEY } from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
const MustHavesPropertiesPvtRoom = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setDisableSave,
    isEdit
  } = props;  

  const accsPvtBthRm= _.get(formValues, "accsPvtBthRm", false);
  const accsPvtBthRmInSession= _.get(getLocalStorage(WANT_AD_KEY), "accsPvtBthRm", false);
  const alwPets= _.get(formValues, "alwPets", false);
  const alwPetsInSession= _.get(getLocalStorage(WANT_AD_KEY), "alwPets", false);
  const adaAccess= _.get(formValues, "adaAccess", false);
  const adaAccessInSession= _.get(getLocalStorage(WANT_AD_KEY), "adaAccess", false);
  const pvtEntrnce= _.get(formValues, "pvtEntrnce", false);
  const pvtEntrnceInSession = _.get(getLocalStorage(WANT_AD_KEY), "pvtEntrnce", false);

  useEffect(() => {
    if(isEdit){
      let isDisable = true;
      if(adaAccess != adaAccessInSession){
        isDisable = false
      }
      if(accsPvtBthRm != accsPvtBthRmInSession){
        isDisable = false
      }
      if(pvtEntrnce != pvtEntrnceInSession){
        isDisable =  false
      }
      if(alwPets != alwPetsInSession){
        isDisable = false
      }
      setDisableSave(isDisable)
    }
    else{  setDisableSave(false); }
  }, [adaAccess, accsPvtBthRm, pvtEntrnce, alwPets]); 

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ adaAccess: e.target.checked });
        break;
      case 2:
        handleFormValues({ alwPets: e.target.checked });
        break;
      case 3:
        handleFormValues({ accsPvtBthRm: e.target.checked });
        break;
      case 4:
        handleFormValues({ pvtEntrnce: e.target.checked });
        break;
    }
  };

  const loadCheckBoxItems = () => {
    const itemArray = [
        { id: 1, text: "ADA Accessible" },
        { id: 2, text: "Allows Pets" },
        { id: 3, text: "Access to private bathroom" },
        { id: 4, text: "Access to Private Entrance" },
      ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 1:
          isChecked = adaAccess;
          break;
        case 2:
          isChecked = alwPets;
          break;
        case 3:
          isChecked = accsPvtBthRm;
          break;
        case 4:
          isChecked = pvtEntrnce;
          break;
      }
      return (
        <div className="row">
          <div className="col-12">
            <a
              href={void 0}
              className="createAd-content-wrap  formCheckBoxwrap mb-3"
            >
              <label className="form-check-label checkbox-label w-100" key={id}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="chkbox"
                  id={id}
                  checked={isChecked}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span className=""> {text}</span>
              </label>
            </a>
          </div>
        </div>
      );
    });

    return List;
  };

  return (
    <>      
      <div className="stay-timing-wrap mb-4">{loadCheckBoxItems()}</div>
    </>
  );
};

export default MustHavesPropertiesPvtRoom;
