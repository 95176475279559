import { getInitials, } from "../../../helpers/common";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from "react";


const SelectClient = (props) => {
  const { selectedClient, setSelectedClient, clientList, AddNewClient, setShowSelectClients, showSelectClients, reloadClientList, handleIntroductionPopup } = props;

  const [inputSearchValue, setInputSearchValue] = useState('');

  const handleSearchChange = (event) => {
    const searchtext = event.target.value;
    setInputSearchValue(searchtext);
    reloadClientList(searchtext)

  };

  return (
    <>
      <Offcanvas
        className="mobile-offcanvas offcanvas-fixed property-offcanvas"
        show={showSelectClients}
        onHide={setShowSelectClients}
        placement='bottom'
        {...props}>
        <Offcanvas.Header className="filter-offcanvas-header">
          <a class="back-link-btn" href={void(0)} onClick={()=>{handleIntroductionPopup()}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z" fill="#1B1C1E" />
            </svg>
          </a>
          <Offcanvas.Title>Select client</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-client-search">


            <div className="popupStyle">
              <div className="search-sec">
                <div className="search_field_input position-relative mb-0 input-group d-flex ">
                  <input aria-label="Example" aria-describedby="basic-addon3" placeholder="Search Clients" class="form-control pr-0" onChange={(e) => { handleSearchChange(e) }}></input>
                  <button type="button" id="button-addon3" class="search-icon "></button>
                </div>
              </div>
              <div>
                <div class="modal-choose-property mb-0 ">
                  {clientList && clientList?.map((client) => {
                    return (

                      <label class="property-radio">
                        <input type="radio" name="check-box-option" id="0"
                          onChange={() => {
                            setSelectedClient(client);
                            handleIntroductionPopup();
                          }}
                          checked={selectedClient?.ClientId == client?.ClientId}
                          value={client?.value} />
                        <span className="bdrRadius-0 ps-3 pe-3 ">
                          <div class="d-flex align-items-center w-100">
                            <div className={" profileIcnDiv d-flex justify-content-center align-items-center " +
                              getInitials(client?.FirstName + " " + client?.LastName)
                                ?.backgroundColor
                            }> {
                                getInitials(client?.FirstName + " " + client?.LastName)
                                  ?.initials
                              }</div>
                            <div className="labelTxtName">  {client?.FirstName + " " + client?.LastName}</div>
                          </div>
                        </span>
                      </label>
                    )
                  })}


                </div>
              </div>
              </div>

              <div className="inner-btm-fixed-nav">
                <div className="btn-wrap text-center">
                  <button type="button" className="btn btn-secondary addNew w-100" onClick={AddNewClient}>Add New Client <i className="add-ico"></i></button>

                </div>
              </div>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>


  );
};

export default SelectClient;
