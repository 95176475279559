import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
const DocumentationTutorialMob = (props) => {
  const {
    index,
    step,
    backProps,
    continuous,
    primaryProps,
    skipProps,
    size,
    isLastStep,
    tooltipProps,
  } = props;
  let finalStepBtn = props.finalStepBtn ? props.finalStepBtn : "Got It !";
  let hideBtns = props.hideBtns ? props.hideBtns : false;
  let hideCounter = props.hideCounter ? props.hideCounter : false;
  return (
    <>
      <div className="joyride-left-introsec">
        {!hideCounter && (
          <div className="joyride-left-count">
            {parseInt(index) + 1} of {size}
          </div>
        )}
        <div className="joyride-step-title">
          {step.title}{" "}
          {step.icon && (
            <span className="joyride-step-icon">
              <img src={step.icon} />
            </span>
          )}
        </div>

        <div className="joyride-step-para">{step.content}</div>

        <Button
          variant=""
          className={
            step.showSkipButton && !hideBtns
              ? "btn-secondary btn-joyride-mob"
              : "btn-secondary invisible"
          }
          {...skipProps}
        >
          Skip all
          <span className="ms-1">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.94984 2.69992C7.09628 2.55348 7.33372 2.55348 7.48017 2.69992L10.5152 5.73492C10.5855 5.80525 10.625 5.90063 10.625 6.00009C10.625 6.09954 10.5855 6.19493 10.5152 6.26525L7.48017 9.30025C7.33372 9.4467 7.09628 9.4467 6.94984 9.30025C6.80339 9.15381 6.80339 8.91637 6.94984 8.76992L9.34467 6.37509H1.75C1.54289 6.37509 1.375 6.20719 1.375 6.00009C1.375 5.79298 1.54289 5.62509 1.75 5.62509H9.34467L6.94984 3.23025C6.80339 3.08381 6.80339 2.84637 6.94984 2.69992Z"
                fill="#0089C6"
              />
            </svg>
          </span>
        </Button>

        {continuous && !hideBtns && (
          <Button
            variant=""
            className="btn-primary float-end"
            {...primaryProps}
          >
            {isLastStep ? finalStepBtn : "Next"}
          </Button>
        )}
      </div>
    </>
  );
};
export default DocumentationTutorialMob;
