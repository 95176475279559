import React, { useState, useEffect } from "react";
import "../styles/clients.scss";
import ValidationAlertIcon from "../images/validation-alert-icon.svg";
import PaymentActionDropdown from "../components/Settings/PaymentActionDropdown";
import { useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import { getNotificationSettings } from "../../actions/userprofile";
import { fetchUserDetails } from "../../actions/userprofile";
import { saveNotifications } from "../../services/userprofileService";
import { getLocalStorage, setBodyBackgroundWhite } from "../../helpers/common";
import { USER_INFO_KEY } from "../../constants/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";

const EmailNotification = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {userDetails} = props
    const sessionData = getLocalStorage(USER_INFO_KEY); 
    const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
    const userId = _.get(sessionData, 'userInfo.userId', '')
    const [userDetails, setUserDetails] = useState(null);
    const userNotificationSettings = useSelector((state)=>state.userProfile.notificationSettings)
    const [introductionNotification, setIntroductionNotification] = useState(true)
    const [accountActivityNotification , setAccountActivityNotification] = useState(false)
    const [messageNotification, setMessageNotification] = useState(false)
    const [featureNotification , setFeatureNotification] = useState( false)
    const [matchNotification, setMatchNotification] = useState(false)
    const [reload, setReload] = useState(0)
    // const userId = getUserId();
    useEffect(()=>{
        if(userId){  
        dispatch(getNotificationSettings({
            "userId" : userId,
          } ))}
    },[reload])

    console.log(userId)

    useEffect(()=>{
        if(userNotificationSettings){
        setAccountActivityNotification(userNotificationSettings[0]?.AccountActivity===1 ? true : false)
        setMessageNotification(userNotificationSettings[0]?.Messages===1? true : false)
        setFeatureNotification(userNotificationSettings[0]?.ProductAnnouncements===1 ? true : false)
        setMatchNotification(userNotificationSettings[0]?.Matches===1 ? true : false)
    }
    },[userNotificationSettings])


    const handleSubmit = async (data) => {   
        await saveNotifications(data).then((response)=>{
            if (response.errorCode == 0) {
                toast.dismiss()
                toast.success("Notification preference updated.")                
              } else {
                console.log("Something went wrong");
              }
        }).catch((err) => {
            console.log("Error", err);
          }); 
    }

    
  useEffect(() => {
    setBodyBackgroundWhite()
    document.body.classList.add('bg-offcanvas-fix');
   
  }, []);

  const [showInventorySort, setShowInventorySort] = useState(false);
  const InventorySortClose = () => setShowInventorySort(false);
  const InventorySortShow = () => setShowInventorySort(true);

  return (
    
    <div className="gitcha-main-wrapper pb-0 bg-white">

        <div className="content-wrapper h-100">
            <div className="inner-layout-header-new bg-white">
                <div className="review-plan-header header-brdr-btm w-100">
                    <div className="signup-login-header-top signup-header-txt clearfix">
                        <a className="back-link-small" href={void(0)} onClick={ () => navigate(pageURLs.mobileSettings)}><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                        </svg></a>
                        <span>Notifications</span>
                    </div>
                </div>
            </div>
            <div className="inner-layout-content-new">
                <div className="pro-premium-wrap mb-0">
                    <div class="justify-content-between d-flex mb-2"><span className="titleSubhead">Email Notifications</span></div>
                
                </div>


                <div className="billing-cycle-wrap pt-0">
           

                    <ul className="historyList">
                        <li className="d-flex justify-content-between">
                            <div>
                                <h3>Introductions</h3>
                                <span className="emailTxt">Get notified when another member sends me an introduction</span>
                            </div>
                            <div>
                            <label class="form-check-label" for="IAmRepresenting15">
                                <input class="form-check-input" type="checkbox" 
                                checked={introductionNotification}
                                disabled={true}
                                id="introductioncheck" />
                            </label>
                            </div>
                        </li>

                        <li className="d-flex justify-content-between">
                            <div>
                                <h3>Account activity</h3>
                                <span className="emailTxt">Get notified about important account activity</span>
                            </div>
                            <div>
                            <label class="form-check-label" for="IAmRepresenting14">
                                <input class="form-check-input"
                                 type="checkbox"
                                 checked={accountActivityNotification}
                                 onChange={()=>{
                                     setAccountActivityNotification(!accountActivityNotification);
                                     const data = {
                                         "UserId" : userId,
                                         "Introductions" : true,
                                         "AccountActivity" : !accountActivityNotification,            
                                         "Messages" : messageNotification,
                                         "ProductAnnouncements" : featureNotification,
                                         "Matches" : matchNotification
                                     }
                                     console.log(data)
                                      handleSubmit(data)
                                 }}
                                 id="activitycheck"
                                 />
                            </label>
                            </div>
                        </li>

                        <li className="d-flex justify-content-between">
                            <div>
                                <h3>Messages</h3>
                                <span className="emailTxt">Get notified when another member messages me directly</span>
                            </div>
                            <div>
                            <label class="form-check-label" for="IAmRepresenting13">
                                <input class="form-check-input"
                                 type="checkbox"
                                 onChange={()=>{
                                    setMessageNotification(!messageNotification);
                                    const data = {
                                        "UserId" : userId,
                                        "Introductions" : true,
                                        "AccountActivity" : accountActivityNotification,            
                                        "Messages" : !messageNotification,
                                        "ProductAnnouncements" : featureNotification,
                                        "Matches" : matchNotification
                                    }
                                     handleSubmit(data)
                                }}
                                checked={messageNotification}
                                id="messagecheck" />
                            </label>
                            </div>
                        </li>

                        <li className="d-flex justify-content-between">
                            <div>
                                <h3>Product announcements</h3>
                                <span className="emailTxt">Get notified about new features on Gitcha</span>
                            </div>
                            <div>
                            <label class="form-check-label" for="IAmRepresenting11">
                                <input class="form-check-input" 
                                type="checkbox" onChange={()=>{
                                    setFeatureNotification(!featureNotification);
                                    const data = {
                                        "UserId" : userId,
                                        "Introductions" : true,
                                        "AccountActivity" : accountActivityNotification,            
                                        "Messages" : messageNotification,
                                        "ProductAnnouncements" : !featureNotification,
                                        "Matches" : matchNotification
                                    }
                                     handleSubmit(data)
                                }}
                                checked={featureNotification}
                                id="productcheck" />
                            </label>
                            </div>
                        </li>
                        <li className="d-flex justify-content-between">
                            <div>
                                <h3>Matches</h3>
                                <span className="emailTxt">Get notified about new matches to your property</span>
                            </div>
                            <div>
                            <label class="form-check-label" for="IAmRepresenting10">
                                <input class="form-check-input" 
                                type="checkbox"
                                onChange={()=>{
                                    setMatchNotification(!matchNotification);
                                    const data = {
                                        "UserId" :userId,
                                        "Introductions" : true,
                                        "AccountActivity" : accountActivityNotification,            
                                        "Messages" : messageNotification,
                                        "ProductAnnouncements" : featureNotification,
                                        "Matches" : !matchNotification
                                    }
                                     handleSubmit(data)
                                }}
                                checked={matchNotification}
                                id="productcheck"
                                 />
                            </label>
                            </div>
                        </li>
                     </ul> 

                </div>

            </div>
        </div>
     
    </div>
  );
};

export default EmailNotification;
