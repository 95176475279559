import { useEffect, useState } from "react";
import {
  getGeoLocation,
  getGitchaAvailableStates,
} from "../services/commonServices";
import {
  toTitleCase,
  setLocalStorage,
  removeLocalStorage,
} from "../helpers/common";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import { fetchGitchaAvailableCities } from "../actions/locations";
import { useSelector } from "react-redux";

const GitchaAvailableLocations = (props) => {
  const [availableStates, setAvailableStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const availableCities = useSelector(
    (state) => state.locations.availableCities
  );

  const dispatch = useDispatch();
  useEffect(() => {
    fetchGitchaAvailableStates();
  }, []);

  const fetchGitchaAvailableStates = async () => {
    await getGitchaAvailableStates()
      .then((response) => {
        const res = _.get(response, "result", []);
        setAvailableStates(res);
      })
      .catch((error) => console.log(error));
  };

  const handleLocationSelect = async (city, state) => {
    const reqPayload = `address=${city},${state}`;
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      if (results?.length > 0) {
        const address = results[0];
        const geometry = _.get(address, "geometry", "");
        const locBounds = _.get(geometry, "location", "");
        const lat = _.get(locBounds, "lat", "");
        const long = _.get(locBounds, "lng", "");
        const formatted_address = _.get(address, "formatted_address", "");
        setLocalStorage("searchedLocation", {
          latitude: lat,
          longitude: long,
          city : city,
          state: state,
          formatted_address: toTitleCase(city) + ", " + toTitleCase(state),
          isFromAvailableCities: true,
        });
        props.setUserLocation({
          latitude: lat,
          longitude: long,
          city : city,
          state: state,
          formatted_address: toTitleCase(city) + ", " + toTitleCase(state),
          isFromAvailableCities: true,
        });
      }
    });
  };

  const handleLocationState = (stateCode) => {
    if (stateCode) {
      const stateCodeSelected = selectedStates.indexOf(stateCode);
      if (stateCodeSelected == -1) {
        setSelectedStates([...selectedStates, stateCode]);
        dispatch(fetchGitchaAvailableCities({ state: stateCode }));
      }
    }
  };

  const loadCities = (stateCode) => {
    const selectedState = availableCities?.find(
      (cities) => cities.state === stateCode
    );
    const cities = selectedState?.cities;
    return (
      cities &&
      cities.length > 0 &&
      cities.map((loc) => {
        return (
          <a
            href={void 0}
            onClick={() => handleLocationSelect(loc.City, stateCode)}
          >
            {toTitleCase(loc.City)}, {toTitleCase(stateCode)}
          </a>
        );
      })
    );
  };

  return (
    <>      
      <h3>Gitcha isn’t operating in this area (yet!)</h3>
      <p>
        We’re coming to your area soon. Here are the areas we’re currently
        operating in:
      </p>
      <ul className="operating-areas-list">
        <Accordion defaultActiveKey={["0"]}>
          {availableStates?.length > 0 &&
            availableStates.map((loc, index) => {
              return (
                <li>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header
                      onClick={() => handleLocationState(loc.StateCode)}
                    >
                      <li>{loc.State}</li>
                    </Accordion.Header>
                    <Accordion.Body>{loadCities(loc.StateCode)}</Accordion.Body>
                  </Accordion.Item>
                </li>
              );
            })}
        </Accordion>
      </ul>
    </>
  );
};

export default GitchaAvailableLocations;
