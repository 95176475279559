import React, { useState, useEffect } from "react";

import ProgressBar from "../ProgressBar";
import { getLocalStorage, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";
import { STRENGTHEN_WANT_AD_KEY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import { getFinanceType } from "../../../services/wantAdService";
import ConfirmApprovalModal from "../../../components/MemberWantAd/ConfirmApprovalModal";

const AddFinancing = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isStrengthenWantAd,isEdit,
    isFromBuyerListingService,
    isPremiumUser
  } = props;

  const financeType =
    _.get(formValues, "finTypeId", null) || _.get(formValues, "pFinance", null);
  const finTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "finTypeId", null)
  const isPreApprLoan = _.get(formValues, "isPreApprLoan", null);
  const isPreApprLoanInSession = _.get(formValues, "isPreApprLoan", null);
  const [disableSave, setDisableSave] = useState(true);
  const [financeList, setFinanceList] = useState([]);
  const [isConventionalMortage, setConventionalMortage] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const prptySubTypes = _.get(formValues, "prptySubTypes", null);

  useEffect(()=>{
    if(isEdit){ 
      if(financeType===finTypeInSession){
        setDisableSave(true)
      }
      // else if((financeType === "2" || financeType == 2) && (isPreApprLoan === isPreApprLoanInSession)){
      //   setDisableSave(true)
      // }      
    }
  },[financeType, isPreApprLoan, isEdit])

  useEffect(() => {
    setBodyBackgroundWhite()
    if (financeType && (financeType === "2" || financeType == 2)) {
      if (
        isPreApprLoan === "Yes" ||
        (isPreApprLoan == true)
      ) {
        setDisableSave(false);
        // setShowConfirmPopup(true);
      } else if (!isPreApprLoan) {
        const isDisable = true;
        setDisableSave(isDisable);
      }
      setConventionalMortage(true);
    } else if (financeType) {
      setDisableSave(false);
    }
    getFinanceType()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const financeList = _.get(response, "result", null);
          setFinanceList(financeList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const closeConfirmPopup = () => {
    const isDisableButton = true;
    setDisableSave(isDisableButton);
    setShowConfirmPopup(false);
    handleFormValues({ isPreApprLoan: "" });
  };

  const handleAdjustBudgetClick = () => {
    handleFormValues({ isAdjustBdget: true });
    if(isStrengthenWantAd){
      setUIKey("budget");
    }
    else{
      if(prptySubTypes){
        setUIKey("budget");
      }else{
        setUIKey(null)
      }
    }
    
  };

  const handleItemClick = (value, FinanceText) => {
    setConventionalMortage(false);
    const isDisable = true;
    // if (value === financeType) {
    //   handleFormValues({ pFinance: "", pFinanceText: "", isPreApprLoan: "" });
    //   setConventionalMortage(false);
    //   setDisableSave(isDisable);
    // } else {
      if (value === 2) {
        setConventionalMortage(true);
        setDisableSave(isDisable);
      } else {
        handleFormValues({ isPreApprLoan: "" });
        setConventionalMortage(false);
        setDisableSave(false);
      }
      handleFormValues({ pFinance: value, pFinanceText: FinanceText, finTypeId: null,finTypeTxt: null });
    // }
  };
  const handlePreApproveClick = (selectedValue) => {
    if (selectedValue === "Yes") setShowConfirmPopup(true);
    handleFormValues({ isPreApprLoan: selectedValue });
    setDisableSave(false);
  };

  const confirmPreApprovalStatus = () => {
    setShowConfirmPopup(false);
  };

  const loadFinanceTypes = () => {
    const financeLists =
      financeList &&
      financeList.map((tl) => {
        const FinanceTypeId = _.get(tl, "FinanceTypeId");
        const FinanceTypeText = _.get(tl, "FinanceTypeText", "");
        return (
          <a
            href={void 0}
            className={
              financeType && financeType == FinanceTypeId
                ? "createAd-content-wrap mb-4 active"
                : "createAd-content-wrap mb-4"
            }
            onClick={(e) => {
              e.stopPropagation();
              handleItemClick(FinanceTypeId, FinanceTypeText);
            }}
          >
            <span className="labelTxt">{FinanceTypeText}</span>
          </a>
        );
      });

    return financeLists;
  };




  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />

          <div className="inner-layout-content-new bg-white pb-0 position-relative">
            <div
              className="layout-content-btm-sec pe-3 ps-3 position-static"
            >
              <h6 className="">Financing</h6>
              <h2 className="">How is your client financing this purchase?</h2>
            </div>

            <div className="title-selection-wrap pe-3 ps-3 pt-0">
              {loadFinanceTypes()}
              {isConventionalMortage && (
              <div className="stay-timing-wrap mb-4">
                <div className="lp-btn-group-wrap mb-2">
                  <h4> Are you pre-approved for a loan?</h4>
                  <div className="row">
                    <div className="col-6">
                      <a
                        href={void 0}
                        className={isPreApprLoan &&
                            (isPreApprLoan === "Yes" || isPreApprLoan == true) ? "blockBtn text-center active" : " blockBtn text-center"}
                            onClick={(e) => handlePreApproveClick('Yes')}
                      >
                        {" "}
                        Yes{" "}
                      </a>
                    </div>
                    <div className="col-6">
                      <a href={void 0} 
                      className={isPreApprLoan &&
                        (isPreApprLoan === "No" || isPreApprLoan == false) ? "blockBtn text-center active" : "blockBtn text-center"}
                        onClick={(e) => handlePreApproveClick('No')}
                      >
                        {" "}
                        No{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if(isAgent){
                    if(completedPercentage>90){
                      setCompletedPercentage(completedPercentage)
                    }
                    else{
                      setCompletedPercentage(90)
                    }
                  }
                  else{                   
                      setCompletedPercentage(100)                    
                  }
                  setTimeout(() => {
                    if(isStrengthenWantAd){
                    setLocalStorage(
                      STRENGTHEN_WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );
                    setUIKey(null);}
                    else{
                      setLocalStorage(
                        WANT_AD_KEY,
                        getLocalStorage(WANT_AD_KEY_TEMP)
                      );
                      setUIKey(null);
                    }
                  }, 500);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmApprovalModal
        show={showConfirmPopup}
        onHide={closeConfirmPopup}
        confirmPreApprovalStatus={confirmPreApprovalStatus}
        handleAdjustBudgetClick={() => handleAdjustBudgetClick()}
        isMobileView={true}
        isPremiumUser={isPremiumUser}
      />
    </>
  );
};

export default AddFinancing;
