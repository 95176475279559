import noImage from "../../../images/no-image-placeholder.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pageURLs } from "../../../constants/pageURLs";
import { useState, useEffect } from "react";
import { getUserId, removeLocalStorage } from "../../../helpers/common";
import { fetchUserStatistics } from "../../../actions/userprofile";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP, USER_TYPE_AGENT } from "../../../constants/common";
import Offcanvas from 'react-bootstrap/Offcanvas';
import moment from "moment";
import AddPhotosModal from "./AddPhotosModal";

const SelectProperty = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    propertyList,
    selectedProperty,
    setSelectedProperty,
    userType,
    selectedClient,
    userDetails,
    setShowSelectProperty,
    showSelectProperty,
    handleIntroductionPopup,
    setUIKey
  } = props;


  const userId = getUserId();
  const userStatictics = useSelector((state) => state?.userProfile?.userStatictics)
  const userPrivileges = useSelector((state) => state?.userProfile?.userPrivileges)
  const [userCount, setUserCount] = useState({})
  const [allowedCount, setAllowedCount] = useState({})
  const [reload, setReload] = useState(0)
  const [showAddPhotosModal, setShowAddPhotosModal] = useState(false);
  const AddPhotosModalClose = () => setShowAddPhotosModal(false);
  const AddPhotosModalShow = () => setShowAddPhotosModal(true);
  const [currentInventoryIdForEdit, setCurrentInventoryIdForEdit] = useState(null)

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id
    }

    dispatch(fetchUserStatistics(payload))
  }, [userDetails])

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);


  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  const handleRadioButonChange = (property) => {
    setCurrentInventoryIdForEdit(property?.Inv_Id)
    if (!property?.photo) {

      setShowAddPhotosModal(true)
    } else {
      setSelectedProperty(property);
      handleIntroductionPopup()
    }
  }

  const handleAddProperty = () => {
    if (userCount?.Inventory >= allowedCount?.Inventory) {
      navigate(pageURLs.updatePlan, {
        state: { isFromSettings: true },
      })
    }
    else {
      removeLocalStorage(INVENTORY_KEY);
      removeLocalStorage(INVENTORY_KEY_TEMP);
      navigate(pageURLs.mobileCreateInventory,{
        state: { isFromSendIntroduction: true },
      });
    }
  };


  return (
    <>
      <Offcanvas
        className="mobile-offcanvas offcanvas-fixed property-offcanvas"
        show={showSelectProperty}
        onHide={setShowSelectProperty}
        placement='bottom'
        {...props}>
        <Offcanvas.Header>
          <a class="back-link-btn" href={void (0)} onClick={() => { handleIntroductionPopup() }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z" fill="#1B1C1E" />
            </svg>
          </a>
          <Offcanvas.Title>Select your property</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div className="offcanvas-sharead-wrap ">
            <div className="popupStyle">
              <div>
                <div class="modal-choose-property mb-0 ">
                  {propertyList?.map((property) => {
                    return (
                      <label class="property-radio">
                        <input
                          type="radio"
                          name="check-box-option"
                          id="670"
                          onChange={() => {
                            handleRadioButonChange(property)

                          }}
                          checked={selectedProperty?.Inv_Id === property?.Inv_Id}
                          value={property?.Inv_Id}
                        />
                        <span className="bdrRadius-0 ps-3 pe-3 ">
                          <div className="add-your-property-mob propPopup">
                            <div className="add-property-img-mob">
                              <img src={property?.photo ? property?.photo : noImage} />
                            </div>
                            <div className="add-property-details-mob ">
                              <div className="title d-flex align-items-center">
                                <h3>{property?.Location}</h3>
                              </div>
                              {/* <p>{loadInventoryLocation({
                                city: property?.City,
                                state: property?.State,
                                zip: property?.ZIP,
                              })}</p> */}

                              {(property?.IsIntroSent == 1 &&
                                property?.IsCancelled == 0
                                && property?.IsDecline != 1 &&
                                property?.NotInterested != 1
                                && property?.Not_A_Fit != 1) ?
                                <div className="modal-wrap-label-grey">SENT {moment(
                                  new Date(property?.IntroSentDate)
                                ).format("MMM Do, YYYY")}</div>

                                :
                                property?.IsDecline == 1 ?
                                  <div className="modal-wrap-label-orange">DECLINED {moment(
                                    new Date(property?.IntroDeclineDate)
                                  ).format("MMM Do, YYYY")}</div>

                                  :

                                  property?.NotInterested == 1 ?
                                    <div className="modal-wrap-label-orange">NOT INTERESTED {moment(
                                      new Date(property?.IntroDeclineDate)
                                    ).format("MMM Do, YYYY")}</div>

                                    :

                                    property?.Not_A_Fit == 1 ?
                                      <div className="modal-wrap-label-orange">NOT A FIT {moment(
                                        new Date(property?.IntroDeclineDate)
                                      ).format("MMM Do, YYYY")}</div>

                                      :

                                      !property?.photo &&
                                      <div className="modal-wrap-label-blue">MUST ADD A PHOTO TO INTRODUCE</div>

                              }

                            </div>
                          </div>
                        </span>
                      </label>
                    )
                  })}

                </div>
              </div>
            </div>
            <div className="inner-btm-fixed-nav">
              <div className="btn-wrap text-center">
                <button type="button" className="btn btn-secondary addNew w-100" onClick={handleAddProperty}>Add New Property <i className="add-ico"></i></button>

              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>

      <AddPhotosModal
        show={showAddPhotosModal}
        onHide={AddPhotosModalClose}
        inventoryId={currentInventoryIdForEdit} />
    </>
  );
};

export default SelectProperty;
