import WantAdLocation from "./WantAdLocation";
import WantAdRentPropertyTypes from "./WantAdRentPropertyTypes";
import WantAdNiceToHaves from "./WantAdNiceToHaves";
import WantAdCalendar from "./WantAdCalendar";
import WantAdRentMustHaves from "./WantAdRentMustHaves";
import WantAdRentMustHavePrivate from "./WantAdRentMustHavePrivate";
import WantAdConfirmation from "./WantAdConfirmation";
import WantAdBudget from "./WantAdBudget";
import WantAdDescription from "./WantAdDescription";
import { IS_PRIVATE_ROOM } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";
import { removeDecimalZeros } from "../../helpers/common";

const WantAdMemberLTR = (props) => {
  const {
    step,
    handleContinueButtonState,
    handleFormValues,
    formValues,
    clearPreviousStates,
    targetRef,
    userPlan
  } = props;
  const propertyType = _.get(formValues, "prprtyType", "");
  switch (step) {
    case 3:
      return (
        <WantAdLocation
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          location={_.get(formValues, "location", null)}
          lat={_.get(formValues, "lat", null)}
          lng={_.get(formValues, "lng", null)}
          mapZoom={_.get(formValues, "mapZoom", null)}
          mapRadius={_.get(formValues, "mapRadius", null)}
          neighborHood={_.get(formValues, "neighborHood", null)}
          highSchool={_.get(formValues, "highSchool", null)}
          zip={_.get(formValues, "zip", null)}
          adType={_.get(formValues, "adType", null)}
          targetRef={targetRef}
          city={_.get(formValues, "city", null)}
          state={_.get(formValues, "state", null)}
          county={_.get(formValues, "county", null)}
          isGitchaAvailable={_.get(formValues, "isGitchaAvailable", null)}
          zipCode={_.get(formValues, "zipCode", null)}
          zipIdValues={_.get(formValues, "zipIdValues", null)}
        />
      );
    case 4:
      return (
        <WantAdCalendar
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          adType={_.get(formValues, "adType", null)}
          moveInTimeLine={convertToNumber(
            _.get(formValues, "moveInTimeLine", null)
          )}
          moveInDate={_.get(formValues, "startDate", null)}
          clearPreviousStates={clearPreviousStates}
          dateError={_.get(formValues, "wantAdValErrors", null)}
          errorUpdatedAt={_.get(formValues, "updatedAt", null)}
        />
      );
    case 5:
      return (
        <WantAdRentPropertyTypes
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          propertyType={_.get(formValues, "prprtyType", null)}
          adType={_.get(formValues, "adType", null)}
          clearPreviousStates={clearPreviousStates}
          prptySubTypes={_.get(formValues, "prptySubTypes", [])}
        />
      );
    case 6:
      if (convertToNumber(propertyType) === IS_PRIVATE_ROOM) {
        //render this component if Property type --> private room
        return (
          <WantAdRentMustHavePrivate
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            accsPvtBthRm={_.get(formValues, "accsPvtBthRm", "")}
            alwPets={_.get(formValues, "alwPets", "")}
            adaAccess={_.get(formValues, "adaAccess", "")}
            pvtEntrnce={_.get(formValues, "pvtEntrnce", "")}
          />
        );
      } else {
        return (
          <WantAdRentMustHaves
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            noOfBedrooms={_.get(formValues, "noBedRm", null)}
            noOfBathrooms={_.get(formValues, "noBathRm", null)}
            SFMin={removeDecimalZeros(_.get(formValues, "sqFtMin", null))}
            SFMax={removeDecimalZeros(_.get(formValues, "sqFtMax", null))}
            garageSpace={_.get(formValues, "grgSpce", null)}
            entreSpceMstHve={_.get(formValues, "entreSpceMstHve", [])}
            alwLrgDogs={_.get(formValues, "alwLrgDogs", "")}
            alwSmlDogs={_.get(formValues, "alwSmlDogs", "")}
            alwCats={_.get(formValues, "alwCats", "")}
            adaAccess={_.get(formValues, "adaAccess", "")}
            stories={_.get(formValues, "stories", "")}
            mustHaveErrors={_.get(formValues, "wantAdValErrors", null)}
            errorUpdatedAt={_.get(formValues, "updatedAt", null)}
          />
        );
      }

    case 7:
      return (
        <WantAdNiceToHaves
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          nice2Have={_.get(formValues, "nice2Have", [])}
          adType={_.get(formValues, "adType", [])}
        />
      );
    case 8:
      return (
        <WantAdBudget
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          budgetMin={_.get(formValues, "minBdgt", "")}
          budgetMax={_.get(formValues, "maxBdgt", "")}
          adType={_.get(formValues, "adType", "")}
          budgetErrors={_.get(formValues, "wantAdValErrors", null)}
          errorUpdatedAt={_.get(formValues, "updatedAt", null)}
        />
      );
    case 9:
      return (
        <WantAdDescription
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          adTitle={_.get(formValues, "adTitle", "")}
          adDescription={_.get(formValues, "adDesc", "")}
        />
      );
    case 10:
      return (
        <WantAdConfirmation
          handleContinueButtonState={handleContinueButtonState}
          formValues={formValues}
          userPlan={userPlan}
        />
      );
  }
};
export default WantAdMemberLTR;
