import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import InputGroupControl from "../../components/Common/InputGroup";
import { USER_INFO_KEY } from "../../constants/common";
import { getLocalStorage, setLocalStorage } from "../../helpers/common";
import AddNewClientModal from "./AddNewClientModal";
import { saveAgentClient } from "../../services/agentClientsService";



const AddToClientWantAdModal = ({ handleConfirm, handleSkip, onHide, data, onChangeText, clientSearchText, onAddClient, showAddToClientWantAdModal, ...props }) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => setShowPopup(false);
  const handleShow = () => setShowPopup(true);
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const [agentIds, setAgentIds] = useState([]);

  /**
   * add client
   * @param {json} data 
   */
  const addClientData = async (data) => {
    var updatedData = {
      ...data,
    }

    await saveAgentClient(updatedData)
      .then((response) => {
        if (response.errorCode == 0) {

          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["ClientCount"] =  userInfo.userInfo?.ClientCount ? userInfo.userInfo?.ClientCount + 1 : 1;
          setLocalStorage(USER_INFO_KEY, userInfo); 

          setShowPopup(false)
          showAddToClientWantAdModal();
          onAddClient();
        } else {
          return;
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

  }

  /**
   * To handle checkbox selection
   * @param {*} e 
   */
  const handleAgents = (e) => {
    let selection = e.target.checked;
    let value = e.target.value;
    if (selection == true) {
      setAgentIds([...agentIds, value])
    } else {
      var filtered = agentIds.filter(function (id, index) {
        return id != value;
      });
      setAgentIds(filtered);
    }
  }

  /**
   * To list Agents
   * @param props 
   * @returns HTML
   */
  function Agents(props) {
    const agent = props.agent
    return (
      <label className="check-wrap mb-3">
        <input type="checkbox" name="chk-mh-11" id="11" value={agent.ClientId} onChange={handleAgents} checked={agentIds.includes(String(agent.ClientId)) ? true : false} />
        <span>
          <div className="timing-wrap-info">
            <h2 class="pb-0">{agent.FirstName + " " + agent.LastName}</h2>
          </div>
        </span>
      </label>
    )
  }

  return (
    <>
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-custom-width"

      >
        <Modal.Body className="pt-32 pb-32">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={()=>{onHide();  onChangeText();  handleSkip();}}
          ></button>
          <h3 className="text-start">Want-Ad has been added to your favorites</h3>
          <p className="f-size-16 mb-4">Would you like to add it for a specific client?</p>

          <Row>
            <Col xl={12}>
              <div className="add-myclients-wrap mb-3">
                <h6>My Clients</h6>
                <button className="btn add-to-client-btn" onClick={() => { onHide(); setShowPopup(true) }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#15181E" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H8.25C7.83579 12.75 7.5 12.4142 7.5 12Z" fill="#15181E" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z" fill="#15181E" />
                </svg>

                </button>
              </div>
              <InputGroupControl
                inputGroupClass="wantad-location-search position-relative mb-2"
                ariaLabel="Example"
                ariaDescribedBy="basic-addon4"
                name="search"
                errorMessage=""
                onChange={(e) => {
                  onChangeText(e);
                }}
                iconClass="search-icon"
                isIconEnable={true}
                iconId="button-addon4"
              />
              <div className="wantad-timing-wrap pb-0 scroll-wrap">
                {data !== null && data?.length > 0 ? (
                  data?.map((agent, i) => {
                    return <Agents key={i} agent={agent} ival={i} />
                  })
                ) : (<p>No results for "{props.searchText}"</p>)}

              </div>
            </Col>
            <Col xl={12}>
              <div className="modal-btn-wrap pt-3">
                <Button
                  variant=""
                  className="btn link-btn me-3"
                  onClick={() => {
                    handleSkip();
                  }}>
                  Skip
                </Button>
                <Button
                  variant="primary"
                  className="btn"
                  disabled={agentIds.length > 0 ? false : true}
                  onClick={() => {
                    handleConfirm(agentIds);
                  }}>
                  Save favorites
                </Button>

              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Add new client popup */}
      <AddNewClientModal show={showPopup}
        showAddToClientWantAdModal={showAddToClientWantAdModal}
        fromAddToClientWantAdModal = {true}
        onHide={handleClose}
        addClient={addClientData} />
    </>
  );
};
export default AddToClientWantAdModal;
