import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import WantAdMoreIcon from "../../images/want-ad-more-icon.svg";
import _ from "lodash";
import IntroductionData from "./IntroductionData";
import { useNavigate } from "react-router";

const AcceptedSendIntroductionList = (props) => {
  const { list, count, updateIntroPageOnScroll, AcceptIntroductionModalShow, ViewWantAd } =
    props;
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    const el = scrollRef.current;
    const scrollHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const clientHeight = el.clientHeight;
    if (
      scrollHeight !== clientHeight &&
      scrollTop !== 0 &&
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
    ) {
      const section = "accepted";
      updateIntroPageOnScroll(section);
    } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
      //setCurrPage(prevState => prevState -1);
    }
  };

  return (
    <>
      {count > 0 && (
        <div className="intro-list-sec">
          <div className="title-sec">
            <h5>
              Accepted <span>({count})</span>
            </h5>
          </div>
          <div
            className="property-block-scroll"
            onScroll={handleScroll}
            ref={scrollRef}
          >
            {list.map((intro) => {
              const introId = _.get(intro, "IntroductionId", "")
              return (
                <div className="property-block" key={introId}>
                  <div className="property-info property-info-hoverstyling" onClick={() => {  
                    _.get(intro, "IsExpired", "") != 1 ? ViewWantAd(introId) : '' }}>
                    <IntroductionData introList={intro} />
                  </div>
                  {
                    _.get(intro, "IsExpired", "") != 1 ?
                      <div className="property-action flex-shrink-0">
                        <Button
                          variant="outline"
                          className="btn-outline-primary"
                          onClick={() => navigate('/messages')}
                        >
                          Message
                        </Button>

                        {/* <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        variant=""
                        align="end"
                      >
                        <img src={WantAdMoreIcon} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dpdown-menu-style"
                        align={"end"}
                      >
                        <Dropdown.Item>Mark not interested</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                      </div>
                      :
                      <div className="property-action drop-pos-fix flex-shrink-0 modal-wrap-label-orange f-size-14 me-0">
                        Expired
                      </div>
                  }


                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default AcceptedSendIntroductionList;
