/**
 * @file   src\components\Common\GitchaZipCodeSubmiModal.js
 * @brief  This file is responsible for popup shown after gitcha not available locations request is submitted
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";


const GitchaZipCodeSubmiModal = (props) => {
  const { title, desc, show, onHide, handleSaveExit, clearLocationFields, isFromBuyersListingService} = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
      show={show}
    >
      <Modal.Body className="pt-32 pb-32">        
        <h3 className="text-start">
          {title || "We’ve received your request"}
        </h3>
        <p className="f-size-16 mb-4">
          {desc}          
        </p>

     
        <div className="modal-btn-wrap">
          <Button variant="tertiary" className="btn me-3" onClick={()=>{ 
            handleSaveExit()
            if(isFromBuyersListingService){
              onHide();
            }
            }}>
            Save Draft And Exit
          </Button>

          <Button variant="primary" className="btn" onClick={()=> {clearLocationFields();onHide()}}>
            Close
          </Button>
        </div>
      </Modal.Body>      
    </Modal>
  );
};
export default GitchaZipCodeSubmiModal;
