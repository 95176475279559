import React, { useState, useEffect } from "react";
import EditUsernameModal from "../components/MemberWantAd/EditUsernameModal";
import EditEmailidModal from "../components/MemberWantAd/EditEmailidModal";
import DeleteAccountModal from "../components/MemberWantAd/DeleteAccountModal";
import PremiumPlanModal from "../components/PlanUpgrade/PremiumPlanModal";
import { getPeriod, formateDate, getPlanPeriodText } from "../helpers/common";
import AccountResetPassword from "../components/Settings/AccountResetPassword";

const ProfileAgentUser = ({ ...props }) => {
  const [showEditUsernameModal, setEditUsernameModal] = useState(false);
  const EditUsernameModalClose = () => setEditUsernameModal(false);
  const EditUsernameModalShow = () => setEditUsernameModal(true);

  const [showEditEmailidModal, setEditEmailidModal] = useState(false);
  const EditEmailidModalClose = () => setEditEmailidModal(false);
  const EditEmailidModalShow = () => setEditEmailidModal(true);

  const [showDeleteAccountModal, setDeleteAccountModal] = useState(false);
  const DeleteAccountModalClose = () => setDeleteAccountModal(false);
  const DeleteAccountModalShow = () => setDeleteAccountModal(true);

  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);

  const updateReloadParam = () => {
    props.updateReloadParam();
  };

  const { userDetails , upComingPlanDetails, isFromNotification} = props;
  const showResubscribeText = userDetails?.PlanName === upComingPlanDetails?.PlanName ? false : true;

  useEffect(() => {
    if (isFromNotification == true) {
      PremiumPlanModalShow();
    }
  }, [isFromNotification])


  return (
    <>
      <div className="standard-account">
        <div className="content-block">
          <div className="title-sec">
            <h4>Your profile</h4>
          </div>
          <div className="field-group">
            <div className="field-horizontal link-cursor" onClick={EditUsernameModalShow}>
              <label>Full name</label>
              <p>
                {userDetails?.FirstName} {userDetails?.LastName}
              </p>
            </div>
            <div className="field-horizontal">
              <label>Location</label>
              <p>{userDetails?.Location}</p>
            </div>
            <div className="field-horizontal">
              <label>Agency</label>
              <p>{userDetails?.Company}</p>
            </div>
            <div className="field-horizontal">
              <label>License number</label>
              <p>{userDetails?.LicenseNo}</p>
            </div>
          </div>
        </div>
        <div className="content-block subscription-block">
          <div className="title-sec">
            <h4>Your subscription</h4>
            <a role="button" onClick={() => PremiumPlanModalShow()}>
              View plans
            </a>
          </div>
          {upComingPlanDetails?.Is_Upcoming == 1 && (
            <div className="subscription-info-wrap mb-3">
              <div className="subscription-info-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99967 1.3335C4.32634 1.3335 1.33301 4.32683 1.33301 8.00016C1.33301 11.6735 4.32634 14.6668 7.99967 14.6668C11.673 14.6668 14.6663 11.6735 14.6663 8.00016C14.6663 4.32683 11.673 1.3335 7.99967 1.3335ZM7.49967 5.3335C7.49967 5.06016 7.72634 4.8335 7.99967 4.8335C8.27301 4.8335 8.49967 5.06016 8.49967 5.3335V8.66683C8.49967 8.94016 8.27301 9.16683 7.99967 9.16683C7.72634 9.16683 7.49967 8.94016 7.49967 8.66683V5.3335ZM8.61301 10.9202C8.57967 11.0068 8.53301 11.0735 8.47301 11.1402C8.40634 11.2002 8.33301 11.2468 8.25301 11.2802C8.17301 11.3135 8.08634 11.3335 7.99967 11.3335C7.91301 11.3335 7.82634 11.3135 7.74634 11.2802C7.66634 11.2468 7.59301 11.2002 7.52634 11.1402C7.46634 11.0735 7.41967 11.0068 7.38634 10.9202C7.3521 10.84 7.33397 10.754 7.33301 10.6668C7.33301 10.5802 7.35301 10.4935 7.38634 10.4135C7.41967 10.3335 7.46634 10.2602 7.52634 10.1935C7.59301 10.1335 7.66634 10.0868 7.74634 10.0535C7.90865 9.98682 8.0907 9.98682 8.25301 10.0535C8.33301 10.0868 8.40634 10.1335 8.47301 10.1935C8.53301 10.2602 8.57967 10.3335 8.61301 10.4135C8.64634 10.4935 8.66634 10.5802 8.66634 10.6668C8.66634 10.7535 8.64634 10.8402 8.61301 10.9202Z"
                    fill="#4A4C4F"
                  />
                </svg>
              </div>
              {showResubscribeText ? <p>
                Your {getPlanPeriodText(userDetails?.period)} {userDetails?.PlanName} subscription will end on {formateDate(userDetails?.ToDate, "shortMonth")}. To keep
                your {userDetails?.PlanName} features, you can{" "}
                <a
                  href={void 0}
                  role="button"
                  onClick={() => PremiumPlanModalShow()}
                >
                  resubscribe here.
                </a>{" "}
              </p> : 
              <p>
              Your {getPlanPeriodText(userDetails?.period)} {userDetails?.PlanName} subscription will end on {formateDate(userDetails?.ToDate, "shortMonth")}.
              </p>
              }
            </div>
          )}
          {upComingPlanDetails?.Is_Upcoming == 1 ?
          <div className="field-group">
            <div className="field-vertical">
              <label>Plan</label>
              <p>
                {upComingPlanDetails?.PlanName}                  
              </p>
            </div>
            <div className="field-vertical">
              <label>Price</label>
              <p>
              {upComingPlanDetails?.period === 1
                  ? "Free"
                  : upComingPlanDetails?.Price > 0 ? "$" +
                    parseInt(upComingPlanDetails?.Price) +
                    "/" +
                    getPeriod(upComingPlanDetails?.period) : "-"}
              </p>
            </div>
            <div className="field-vertical">
              <label>Billing date</label>
              <p>
                {upComingPlanDetails?.period !== 1 && upComingPlanDetails?.Billing_Date
                  ? formateDate(upComingPlanDetails?.Billing_Date, "mm/dd/yyyy")
                  : "-"}
              </p>
            </div>
            <div className="field-vertical">
              <label>Payment</label>
              <p>
                {upComingPlanDetails?.period !== 1
                  ? upComingPlanDetails?.Brand + " ending in " + upComingPlanDetails?.Last4
                  : "-"}
              </p>
            </div>
          </div>
           :
           <div className="field-group">
            <div className="field-vertical">
              <label>Plan</label>
              <p>
                {userDetails?.PlanName}
              </p>
            </div>
            <div className="field-vertical">
              <label>Price</label>
              <p>
                {userDetails?.period === 1
                  ? "Free"
                  : (parseInt(userDetails?.Price) > 0 ? "$" +
                    parseInt(userDetails?.Price) +
                    "/" +
                    getPeriod(userDetails?.period) : "-")}
              </p>
            </div>
            <div className="field-vertical">
              <label>Billing date</label>
              <p>
                {userDetails?.period !== 1 && userDetails?.Billing_Date
                  ? formateDate(userDetails?.Billing_Date, "mm/dd/yyyy")
                  : "-"}
              </p>
            </div>
            <div className="field-vertical">
              <label>Payment</label>
              <p>
                {userDetails?.period !== 1 && userDetails?.period != null
                  ? userDetails?.Brand + " ending in " + userDetails?.Last4
                  : "-"}
              </p>
            </div>
          </div>}
        </div>
        <div className="content-block">
          <div className="title-sec">
            <h4>Account information</h4>
          </div>
          <div className="field-group">
            <div
              className="field-horizontal link-cursor"
              onClick={() => EditEmailidModalShow()}
            >
              <label>Email address</label>
              <p>{userDetails?.Email}</p>
            </div>
            <AccountResetPassword isGoogleUser = {userDetails?.IsGoogleVerified}/>
          </div>
          
          <div className="delete-account">
            <div className="field-delete">
              <a role="button" onClick={DeleteAccountModalShow}>
                Delete account
              </a>
              <p>Permanently delete your Gitcha account</p>
            </div>
          </div>
        </div>
      </div>
      <EditUsernameModal
        FirstName={userDetails?.FirstName}
        LastName={userDetails?.LastName}
        userId={userDetails?.UserId}
        show={showEditUsernameModal}
        updateReloadParam={updateReloadParam}
        onHide={EditUsernameModalClose}
      />
      <EditEmailidModal
        Email={userDetails?.Email}
        userId={userDetails?.UserId}
        show={showEditEmailidModal}
        onHide={EditEmailidModalClose}
      />
      <DeleteAccountModal
        Email={userDetails?.Email}
        show={showDeleteAccountModal}
        onHide={DeleteAccountModalClose}
      />
      <PremiumPlanModal
        userDetails={upComingPlanDetails?.Is_Upcoming==1 ? upComingPlanDetails : userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}  
        showPlanModal={PremiumPlanModalShow}      
      />
    </>
  );
};

export default ProfileAgentUser;
