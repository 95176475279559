/**
 * @file   Source/gitcha_Web/src/mobile/components/common/UserPlan/ReviewPlan.js
 * @brief  This file is responsible for handling final step in subscription process in mobile view
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import paymentMethodState from "../../../../appState/paymentMethodState";
import PaymentMethodsModal from "./PaymentMethodsModal";
import AddPaymentModalStripe from "./AddPaymentModalStripe";
import DowngradeModal from "./DowngradeModal";
import CancelPlanModal from "./CancelPlanModal";
import {
  getUserId,
  getCardIcon,
  getUserType,
  getLocalStorage,
  setLocalStorage,
  checkPlanCanbeUpgraded,
} from "../../../../helpers/common";
import {
  FREE_PLAN,
  NOT_FREE_OR_MEMBER_PERIOD,
  PLAN_TYPES,
  PlanDetails,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
  USER_TYPE_MEMBER,
} from "../../../../constants/common";
import {
  createSubscriptionPlan,
  createSubscriptionPlanOnSignup,
  getPreviousPlanBalanceAmount,
  getPromoCodeDetails,
} from "../../../../services/PlanService";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AGENT_PLAN,
  AGENT_PROFILE,
  EDIT_PAYMENT,
  MEMBER_PLAN,
  MEMBER_PROFILE,
} from "../../../../constants/onBoarding";
import TextBox from "../../../../components/Common/TextBox";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../../constants/pageURLs";
import PlanUpgradeRestrictionModal from "../../../../components/PlanUpgrade/PlanUpgradeRestrictionModal";

const ReviewPlan = (props) => {
  const {
    period,
    selectedPlan,
    fromSignUp,
    enablePromocode,
    enablePromocodePeriod,
    handlePageToggle,
    handleBackButtonClick,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    userDetails,
    isEditPlan,
    currentActivePlan,
    UpgradeToAgent,
    continueButtonClicked,
    setContinueButtonClicked
  } = props;

  const [amount, setAmount] = useState(0);
  const payModeId = _.get(userDetails, "Payment_Mode_Id", null);
  const navigate = useNavigate();
  const [reload, setReload] = useState(0);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);
  const PaymentMethodsModalClose = () => setShowPaymentMethodsModal(false);
  const PaymentMethodsModalShow = () => {
    setReload((prevState) => prevState + 1);
    setShowPaymentMethodsModal(true);
  };

  const [showDownGradeModal, setDownGradeModal] = useState(false);
  const [showAddPaymentModal, setAddPaymentModal] = useState(false);
  const [showCancelPlanModal, setCancelPlanModal] = useState(false);
  const CancelPlanModalClose = () => setCancelPlanModal(false);
  const CancelPlanModalShow = () => setCancelPlanModal(true);
  const AddPaymentModalClose = () => setAddPaymentModal(false);
  const AddPaymentModalShow = () => setAddPaymentModal(true);
  const userId = getUserId();
  const userType = getUserType();
  const [plan, setPlan] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(period);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const date = moment().format("MMMM Do");
  const dateOnly = date.split(" ");
  const paymentMethods = paymentMethodState({ reload: reload });
  const currentPeriod = _.get(userDetails, "period", null);
  const selectedPlanDetails = PlanDetails.find((plan)=>plan.planName===selectedPlan)
  const isDowngrade = currentActivePlan?.PlanName==="Investor Member" && selectedPlan==="Member" ? true : false;
  const [balancePlanAmount, setBalancePlanAmount] = useState(null);
  const [promocodeAppliedAmount, setPromocodeAppliedAmount] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  let samePlan = false;
  if(currentActivePlan?.PlanName==="Trial"){
    if(userDetails?.PlanName === selectedPlan){
      samePlan = true
    }
  }
  else{
    if(currentActivePlan?.PlanName===selectedPlan){
      samePlan = true;
    }
  }
  const [showPlanRestrictionModal, setShowPlanRestrictionModal] = useState(false);

  useEffect(() => {
    setSelectedPeriod(period);
  }, [period]);

  const AddNewPayment = (isFromPaymentMethodsModal = false) => {
    if (isFromPaymentMethodsModal) {
      setShowPaymentMethodsModal(false);
    }
    AddPaymentModalShow();
  };

  useEffect(() => {
    let defaultPaymentMethod;
    if (paymentMethods && paymentMethods.length > 0) {
      if (payModeId) {
        defaultPaymentMethod = paymentMethods.find(
          (paymentMethod) => paymentMethod.Payment_Mode_Id === payModeId
        );
      } else {
        defaultPaymentMethod = paymentMethods.find(
          (paymentMethod) => paymentMethod.IsDefault == 1
        );
      }
      setSelectedPaymentMethod(defaultPaymentMethod);
    }
  }, [paymentMethods, payModeId]);

  useEffect(()=>{
    setDisableSave(false);
    toast.dismiss();
    setBalancePlanAmount(null);
    if (currentActivePlan && currentActivePlan?.PlanName !="Trial" && !continueButtonClicked && !samePlan && !isDowngrade) {
      getPreviousPlanAmount();
    }

  },[currentActivePlan, continueButtonClicked,  samePlan, selectedPeriod, selectedPlan])

  const getPreviousPlanAmount = async() =>{
    const payload = {      
      newPlanId : 11,
      userPlanId:currentActivePlan?.User_Plan_Id,
      planName:selectedPlan,
      planPeriod:selectedPeriod,
      userType: UpgradeToAgent ? USER_TYPE_AGENT : userType,
    }
    await getPreviousPlanBalanceAmount(payload).then((response)=>{
      if (response.errorCode === 0) {
        const result = _.get(response, "result", {});
        setBalancePlanAmount(result?.UnUsed_Amount)
      }
    }).catch((error)=>{
      console.log("error", error)
    })
  }

  useEffect(() => {
    if (selectedPlan) {
      setPlan(PLAN_TYPES.find((item) => item.name === selectedPlan));
    }
  }, [selectedPlan]);

  useEffect(() => {
    //document.body.classList.add("bg-offcanvas-fix");
  }, []);

  useEffect(() => {
    if (selectedPeriod === 3) {
      setAmount(12 * plan?.amount?.annual);
    } else {
      setAmount(plan?.amount?.monthly);
    }

    if (enablePromocode && enablePromocodePeriod?.includes(selectedPeriod)) {
      setShowPromoCode(true);
    } else {
      setShowPromoCode(false);
    }
  }, [selectedPeriod, enablePromocode, enablePromocodePeriod, plan]);

  const handleConfirmSubscription = async () => {
    setSubmitClicked(true);
    const data = {
      PlanName: selectedPlan,
      PlanPeriod: selectedPeriod,
      PaymentMethodId: selectedPaymentMethod?.CardId,
      PaymentModeId: selectedPaymentMethod?.Payment_Mode_Id,
      PromoCode: promoCode,
      UserType: userType,
      PopupStatus : continueButtonClicked
    };
    if (fromSignUp) {
      await createSubscriptionPlanOnSignup(data)
        .then(async (response) => {
          // props.onHide();
          setSubmitClicked(false);
          if (response.errorCode === 0) {
            // props.updateReloadParam();
            toast.dismiss();
            toast.success("You've selected " + selectedPlan + "!");
            const userInfo = getLocalStorage(USER_INFO_KEY);
            userInfo.userInfo["planStatus"] = true;
            setLocalStorage(USER_INFO_KEY, userInfo);
            if (userType == 1) {
              handlePageToggle({ activePage: MEMBER_PROFILE });
            } else {
              handlePageToggle({ activePage: AGENT_PROFILE });
            }
            // }
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      if(UpgradeToAgent===true){
        data.changeUserType  = USER_TYPE_AGENT
        }
      await createSubscriptionPlan(data)
        .then(async (response) => {
          setSubmitClicked(false);
          if (response.errorCode === 0) {           
            toast.dismiss();
            const text = isDowngrade ? "downgraded" : "upgraded";
            toast.success("You've "+text+" to " + selectedPlan + "!");
            if(UpgradeToAgent===true){
              const userInfo = getLocalStorage(USER_INFO_KEY);            
              userInfo.userInfo["userType"] = USER_TYPE_AGENT;
              setLocalStorage(USER_INFO_KEY, userInfo);
              navigate(pageURLs.mobilePremiumAgentProfileEdit)} 
            else {navigate(pageURLs.mobileAccountSettings);}
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const handleCancel = () => {
    setSelectedPeriod(period);
    //props.onHide();
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value.toUpperCase());
    setPromoCodeError(null);
  };

  const handlePromoCode = async () => {
    const payload = {
      promoCode: promoCode,
      userType: userType,
      period: selectedPeriod,
    };
    if (!promoCode?.trim()) {
      setPromoCodeError("Enter Promo code. ");
    } else {
      await getPromoCodeDetails(payload).then((response) => {
        if (response.errorCode === 0) {
          const result = _.get(response, "result", {});
          if (result.status === false) {
            setPromoCode("");
            toast.error(response.message);
            setPromoCode("");
          } else {
            const data = result.data;
            if (data && data?.Percentage_Off != null) {
              const newAmount = (amount * data?.Percentage_Off) / 100;
              setPromocodeAppliedAmount(amount - newAmount);
            } else if (data?.Amount_Off != null) {
              setPromocodeAppliedAmount(amount - data.Amount_Off);
            }
          }
        }
      });
    }
  };

  function BackButton() {
    return (
      <a
        className="back-link-small"
        role="button"
        href={void 0}
        onClick={() =>
          fromSignUp
            ? handleBackButtonClick({
                prevPage:
                  userType === USER_TYPE_AGENT ? AGENT_PLAN : MEMBER_PLAN,
              })
            : navigate(-1)
        }
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
            fill="#1B1C1E"
          />
        </svg>
      </a>
    );
  }

  const handleUpdatePlanClick = () => {
    if (userType === USER_TYPE_AGENT) {
      handlePageToggle({ activePage: AGENT_PLAN });
    } else if (userType === USER_TYPE_MEMBER) {
      handlePageToggle({ activePage: MEMBER_PLAN });
    }
  };

  const handleUpdatePlanTitle = () => {    
      return "Change";    
  };

  function LoadUpdatePlanSection() {
    if (currentPeriod) {
      if (currentPeriod > FREE_PLAN) {
        return (
          <div class="justify-content-between d-flex mb-2">
            <span className="titleSubhead">Your Plan</span>
            <button
              type="button"
              class="btn btnTop"
              onClick={() => handleUpdatePlanClick()}
            >
              {handleUpdatePlanTitle()}
            </button>
          </div>
        );
      }
    }
    return null;
  }

  const handleDownGradeClick = () => {
    setDownGradeModal(false);
    setCancelPlanModal(true);
  };

  const getFinalAmount = () =>{
    return promocodeAppliedAmount === 0
    ? 0
    : balancePlanAmount
    ? (promocodeAppliedAmount
        ? promocodeAppliedAmount
        : amount + parseFloat(balancePlanAmount)
      ).toFixed(2)
    : promocodeAppliedAmount
    ? promocodeAppliedAmount
    : amount?.toFixed(2)
  }

  return (
    <>
      <div className="gitcha-main-wrapper pb-102">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <div className="inner-layout-header-new bg-white">
            <div className="review-plan-header header-brdr-btm w-100">
              <div className="signup-login-header-top signup-header-txt clearfix">
                <BackButton />
                <span>{fromSignUp ? "Review Plan" : "Edit Plan"}</span>
              </div>
            </div>
          </div>    

          <div className="inner-layout-content-new bg-white pb-0 position-relative ">
            <div className="premium-content-wrapper">
              <div className="pro-premium-wrap">
                {!fromSignUp && <LoadUpdatePlanSection />}

                <div className="pro-premium-innerwrap">                 

                    <h4 className="d-flex align-items-center">
                    <span>{plan?.name==="Investor Member" ? "Investor" : plan?.name}</span>{selectedPlanDetails?.badge && <span className={selectedPlanDetails?.isGoldBadge ? "pro-gold-badge" : "pro-blue-badge"}>{selectedPlanDetails?.badgeText}</span>}
                    </h4>

                  <p>{selectedPlanDetails?.description}</p>
                </div>
              </div>
              <div className="billing-cycle-wrap">
                <h4 className="mb-4">Billing Cycle</h4>
                <div className="billing-cycle-top border-bottom-0 pb-0">
                  <label className="radio-wrap mb-1">
                    <input
                      type="radio"
                      name="chk-mh56"
                      disabled={samePlan && userDetails?.period===3}
                      id="bc21"
                      onChange={() => {
                        setPromoCode("");
                        setPromocodeAppliedAmount(null);
                        setSelectedPeriod(3);
                        setContinueButtonClicked(false);
                        if (checkPlanCanbeUpgraded(currentActivePlan, selectedPlan, 3)?.canPlanUpgrade == false) {
                          setShowPlanRestrictionModal(true);
                          setDisableSave(true);
                        } 
                        else{
                          setDisableSave(false);
                        }
                      }}
                      checked={selectedPeriod === 3}
                    />
                    <span>
                      <div className="billing-cycle-info">
                        <h2 className="pb-0">
                          Annual (${plan?.amount?.annual}/month){samePlan && userDetails?.period===3 ? <span className="current-plan-txt">Your current plan</span> : ""}
                        </h2>
                      </div>
                    </span>
                  </label>
                  <div className="bill-txt mb-32">
                    ${plan?.amount?.annual && plan?.amount?.annual * 12} due
                    today, then billed every year on {date}
                  </div>
                  <label className="radio-wrap mb-1">
                    <input
                      type="radio"
                      name="chk-mh56"
                      disabled={samePlan && userDetails?.period===2}
                      id="bc11"
                      onChange={() => {
                        setPromoCode("");
                        setPromocodeAppliedAmount(null);
                        setSelectedPeriod(2);
                        setContinueButtonClicked(false);
                        if (checkPlanCanbeUpgraded(currentActivePlan, selectedPlan, 2)?.canPlanUpgrade == false) {
                          setShowPlanRestrictionModal(true);
                          setDisableSave(true);
                        } 
                        else{
                          setDisableSave(false);
                        }
                      }}
                      checked={selectedPeriod === 2}
                    />
                    <span>
                      <div className="billing-cycle-info">
                        <h2 className="pb-0">
                          Monthly (${plan?.amount?.monthly}/month) {samePlan && userDetails?.period===2 ? <span className="current-plan-txt">Your current plan</span> : ""}
                        </h2>
                      </div>
                    </span>
                  </label>
                  <div className="bill-txt mb-1">
                    ${plan?.amount?.monthly} due today, then billed every month
                    on the {dateOnly[1]}
                  </div>
                </div>
              </div>
              <div className="payment-method-wrap">
                <h4 className="mb-3">Payment Method</h4>
                {selectedPaymentMethod !== null ? (
                  <div className="add-your-property-wrap mh-auto mb-12">
                    <div className="add-property-btn">
                      <img src={getCardIcon(selectedPaymentMethod?.Brand)} />
                    </div>
                    <div className="make-intro-details">
                      <h4>
                        {selectedPaymentMethod?.Brand} •••••••••••••
                        {selectedPaymentMethod?.Last4}
                      </h4>
                      <h5>
                        Exp.{" "}
                        {selectedPaymentMethod?.Exp_Month < 10
                          ? "0" + selectedPaymentMethod?.Exp_Month
                          : "" + selectedPaymentMethod?.Exp_Month}
                        /{String(selectedPaymentMethod?.Exp_Year).slice(-2)}
                      </h5>
                    </div>

                    <a
                      role="button"
                      class="right-arrow-link"
                      href={void 0}
                      onClick={() => {
                        fromSignUp
                          ? PaymentMethodsModalShow()
                          : handlePageToggle({
                              activePage: EDIT_PAYMENT,
                              selectedPaymentMethod: selectedPaymentMethod,
                              paymentMethods: paymentMethods,
                            });
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                          fill="#000022"
                        />
                      </svg>
                    </a>
                  </div>
                ) : (
                  <a
                    className="txt-no-underline link-cursor"
                    href={void 0}
                    role="button"
                    onClick={() => AddPaymentModalShow()}
                  >
                    <div className="add-your-property-wrap mh-auto mb-0 brdr-rds-10 add-property-bg brdr-add-property">
                      <div className="add-property-btn">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
                            fill="#15181E"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H8.25C7.83579 12.75 7.5 12.4142 7.5 12Z"
                            fill="#15181E"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z"
                            fill="#15181E"
                          />
                        </svg>
                      </div>
                      <div className="make-intro-details pl-14">
                        <h4 className="mb-0">Add payment method</h4>
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                          fill="#000022"
                        ></path>
                      </svg>
                    </div>
                  </a>
                )}
                <br />
                {showPromoCode && (
                  <div className="promo-code-wrap position-relative mb-12">
                    <TextBox
                      type="text"
                      class="form-control"
                      value={promoCode}
                      placeholder="Add promo code"
                      onChange={(e) => handlePromoCodeChange(e)}
                      name="promo-code"
                      errorMessage={promoCodeError}
                    ></TextBox>
                    <button
                      className="btn apply-btn"
                      onClick={() => handlePromoCode()}
                    >
                      Apply
                    </button>
                  </div>
                )}
              </div>

              <div className="payment-method-wrap">
                <div className="premium-plan-wrap mb-0 order-summary-update brdr-rds-10">
                  <ul>
                    <li>
                      <span className="premium-plan-description">
                        {selectedPlan} Plan (Billed{" "}
                        {selectedPeriod == 2 ? "monthly" : "annually"})
                      </span>
                      <span className="premium-plan-amount">
                        {" "}
                        ${amount?.toFixed(2)}
                      </span>
                    </li>
                    {balancePlanAmount ? <li>
                        <span className="premium-plan-description">
                          Your current paid plan
                        </span>
                        <span className="premium-plan-amount">
                          -${Math.abs(balancePlanAmount).toFixed(2)}
                        </span>
                      </li> : <></>}
                    <li>
                      <span className="premium-plan-description">Tax</span>
                      <span className="premium-plan-amount">-</span>
                    </li>
                    <li>
                      <span className="premium-plan-description">
                        You'll pay
                      </span>
                      <span className="premium-plan-amount">
                      {Math.sign(getFinalAmount())== -1 ? "-$" : "$"}
                      {Math.abs(getFinalAmount()).toFixed(2)}
                        </span>
                    </li>
                  </ul>
                </div>
              </div>

              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={
                  selectedPaymentMethod === null || submitClicked ? true : false || disableSave ||
                  isEditPlan && userDetails?.PlanName===selectedPlan && userDetails?.period === selectedPeriod &&
                  userDetails?.Payment_Mode_Id === selectedPaymentMethod?.Payment_Mode_Id ? true : false
                }
                onClick={() => handleConfirmSubscription()}
              >
                {fromSignUp ? "Confirm Subscription" : "Save Changes"}
              </button>
            </div>
          </div>
        </div>

        {/* <div className="btm-fixed-nav"><button type="button" class="btn btn-primary w-100 mb-0" disabled>Confirm</button></div>*/}
        {showAddPaymentModal && (
          <AddPaymentModalStripe
            show={showAddPaymentModal}
            onHide={() => {
              AddPaymentModalClose();
            }}
            setReload={setReload}
            fromSubscriptionComponent={true}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
        )}

        {showPaymentMethodsModal && (
          <PaymentMethodsModal
            show={showPaymentMethodsModal}
            updateCardParam={(paymentMethod) => {
              setSelectedPaymentMethod(paymentMethod);
            }}
            paymentMethods={paymentMethods}
            selectedCardId={selectedPaymentMethod?.Payment_Mode_Id}
            onHide={() => {
              PaymentMethodsModalClose();
            }}
            AddNewPayment={(isFromPaymentMethodsModal) => {
              AddNewPayment(isFromPaymentMethodsModal);
            }}
          />
        )}
        {showDownGradeModal && (
          <DowngradeModal
            show={showDownGradeModal}
            expiryDate={
              selectedPaymentMethod?.Exp_Month +
              "/" +
              selectedPaymentMethod?.Exp_Month +
              "/" +
              String(selectedPaymentMethod?.Exp_Year).slice(-2)
            }
            setDownGradeModal={setDownGradeModal}
            handleDownGradeClick={handleDownGradeClick}
          />
        )}
        {showCancelPlanModal && (
          <CancelPlanModal
            show={showCancelPlanModal}
            onHide={CancelPlanModalClose}
            userDetails={userDetails}
          />
        )}
        <PlanUpgradeRestrictionModal
        show={showPlanRestrictionModal}
        onHide={() => {setShowPlanRestrictionModal(false); setContinueButtonClicked(false)}}
        currentActivePlan={currentActivePlan}
        selectedPeriod={selectedPeriod}
        handleContinueButton={()=>{setShowPlanRestrictionModal(false); setDisableSave(true); setContinueButtonClicked(true)}}
        />
      </div>
    </>
  );
};

export default ReviewPlan;
