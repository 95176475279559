import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import GoogleLogo from "../../../images/google-logo.svg";
import FbLoginBannerDesktop from "../../../images/fb-login-banner-desktop.png";
import passwordIcon from "../../../images/password-toggle-icon.svg";
import passwordClosedIcon from "../../../images/password-toggle-icon-closed.svg";
import { useNavigate } from "react-router-dom";
import {
  convertToNumber,
  emailValidation,
  passwordValidation,
} from "../../../helpers/validations";
import TextBox from "../../Common/TextBox";
import { userSignUp, userLogin, verifyEmail } from "../../../services/signupService";
import { LOGIN, POST_WANT_AD, SIGNUP, resetModalemailOnly } from "../../../constants/onBoarding";
import { USER_INFO_KEY, USER_TYPE_AGENT } from "../../../constants/common";
import _ from "lodash";
import { setLocalStorage } from "../../../helpers/common";
import { pageURLs } from "../../../constants/pageURLs";

const AgentOnboardingModal = (props) => {
  const {
    handleUserTypeModal,
    googleLogin,
    handleAgentOnboardingModal,
    agentOnboardingPopup,
    handleResetPwdModal,
    handleUserLogin,
  } = props;
  const navigate = useNavigate();
  const isShowPopup = _.get(agentOnboardingPopup, "params.showPopup", "");
  const userAction = _.get(agentOnboardingPopup, "params.userAction", "");
  const utm_source =  _.get(agentOnboardingPopup, "params.utm_source", "");
  const utm_medium =  _.get(agentOnboardingPopup, "params.utm_medium", "");
  const utm_campaign =  _.get(agentOnboardingPopup, "params.utm_campaign", "");
  const isLogin = userAction === LOGIN;
  const isPostWantAd = _.get(agentOnboardingPopup, 'params.userPreference', null) === POST_WANT_AD

  const initialFormData = {
    email: "",
    pwd: "",
    confPwd: "",
  };
  const initialErrors = {
    emailError: "",
    pwdError: "",
    confPwdError: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [buttonDisable, setButtonDisable] = useState(true);
 
  const [isShowPassWord, setPassWordShow] = useState(false);
  const [passwordType, setPassWordType] = useState("password");

  const [loginError, setLoginError] = useState(null);

  const onTextChange = (e) => {
    setLoginError(null)
    const value = e.target.value;
    setFormData((formData) => ({ ...formData, [e.target.name]: value }));
  };

  useEffect(() => {
    const email = _.get(formData, "email", null);
    const pwd = _.get(formData, "pwd", null);
    const confPwd = _.get(formData, "confPwd", null);
    let disableButton;
    if (isLogin) {
      if (email && pwd) {
        disableButton = false;
      } else {
        disableButton = true;
      }
    } else {
      if (email && pwd && confPwd) {
        disableButton = false;
      } else {
        disableButton = true;
      }
    }
    setButtonDisable(disableButton);
  }, [formData]);

  useEffect(() => {
    if (isShowPassWord) {
      setPassWordType("text")
    } else {
      setPassWordType("password")
    }
  }, [isShowPassWord]);

  useEffect(() => {
    setPassWordShow(false)
    setFormErrors(initialErrors);
    setFormData(initialFormData);
  }, [userAction]);

  const checkEmailExist = async (email) => {
    const userEmail = {
      Email: email,
    };
    return await verifyEmail(userEmail).then((response) => {
      return response?.result;
    });
  };

  const validate = async () => {
    setFormErrors(initialErrors);
    let isValid = true;
    const email = _.get(formData, "email", null).trim();
    const pwd = _.get(formData, "pwd", null).trim();
    const passWordError = passwordValidation(pwd);

    if (!emailValidation(email)) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        emailError: "InValid Email",
      }));
      isValid = false;
    } else if (!isLogin && passWordError) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        pwdError: passWordError,
      }));
      isValid = false;
    } else {
      const res = await checkEmailExist(email);
      const userExist = _.get(res, "UserExists", false);
      const userVerified = _.get(res, "IsVerified", false);
      const isDeleted = _.get(res, "IsDeleted", false);

      if (isLogin) {
        if (userExist && userVerified && !isDeleted) {
          isValid = true;
        } else {
          setFormErrors((formErrors) => ({
            ...formErrors,
            emailError: "Invalid user",
          }));
          isValid = false;
        }
      } else {
        if (userExist) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            emailError: "Email already exists",
          }));
          isValid = false;
        } else {
          const confPwd = _.get(formData, "confPwd", null).trim();
          if (pwd !== confPwd) {
            setFormErrors((formErrors) => ({
              ...formErrors,
              confPwdError: "Password not matching",
            }));
            isValid = false;
          } else {
            isValid = true;
          }

        }
      }
    }



    return isValid;
  };
  const handleSubmit = async () => {
    // const isValid = await validate();
    let reqPayload
    // if (isValid) {
    if (!isLogin) {
      const isValid = await validate();

      if (isValid) {
        reqPayload = {
          email: _.get(formData, 'email', ''),
          password: _.get(formData, 'pwd', ''),
          confirmPassword: _.get(formData, 'confPwd', ''),
          //userTypeId:USER_TYPE_AGENT
          
          utmSource: utm_source,
          utcMedium: utm_medium,
          utcCampaign: utm_campaign
        }
        await userSignUp(reqPayload).then((response) => {
          if (_.get(response, 'errorCode', '') === 0) {
            updateUserDetailsToStorage(response)
            handleAgentOnboardingModal({ showPopup: false, userAction: null });
            handleUserTypeModal({ showModal: true })
          } else {
            setFormErrors((formErrors) => ({
              ...formErrors,
              emailError: _.get(response, 'message', ''),
            }));
          }
        });
      }
    } else {
      reqPayload = {
        email: _.get(formData, 'email', ''),
        password: _.get(formData, 'pwd', '')
      }
      await userLogin(reqPayload).then((response) => {
        if (_.get(response, 'errorCode', '') === 0) {
          updateUserDetailsToStorage(response)
          handleAgentOnboardingModal({ showPopup: false, userAction: null });
          handleUserLogin()
        } else {
          // const resResult = _.get(response, 'result', null)
          // const userResult = _.get(resResult, 'user', null)
          // const ErrorStatus = _.get(userResult, "Status", "") 
          // const passWordErrorStatus = 6
          // if (ErrorStatus === passWordErrorStatus){
          setLoginError(_.get(response, 'message', ''))
          // setFormErrors((formErrors) => ({
          //   ...formErrors,
          //   pwdError: _.get(response, 'message', ''),
          // }));
          //  }
        }
      });

    }
    // }
  };

  const updateUserDetailsToStorage = (userResponse) => {
    const resResult = _.get(userResponse, 'result', null)
    const userResult = _.get(resResult, 'user', null)
    const userSession = _.get(resResult, 'userSession', '')
    const refreshToken = _.get(resResult, 'refreshToken', '')
    const firstName = _.get(userResult, "FirstName", "") || ""
    const lastName = _.get(userResult, "LastName", "") || ""
    const name = firstName + " " + lastName;
    const tutorialStatus = _.get(resResult, 'tutorialStatus', '')
    const userTypeId = _.get(userResult, "UserTypeId", "")


    if (userSession) {
      const userDetails = {
        token: {
          accessToken: userSession,
          refreshToken: refreshToken,
          googleLogin: false,
        },
        userInfo: {
          userId: _.get(userResult, "UserId", ""),
          email: _.get(userResult, "Email", ""),
          name: name,
          firstName: firstName,
          lastName: lastName,
          userIsVerified: _.get(userResult, "IsVerified", ""),
          userEmailVerified: _.get(userResult, "IsEmailVerified", ""),
          planStatus: _.get(userResult, "PlanStatus", ""),
          userStatus: _.get(userResult, "UserStatus", ""),
          userType: userTypeId,
          userPreference: isPostWantAd ? POST_WANT_AD : null,
          image: _.get(userResult, "Image", ""),
        },
      };
      if (convertToNumber(userTypeId === USER_TYPE_AGENT)) {
        const agentTitle = _.get(userResult, "Agent_Title", "")
        userDetails.userInfo.agentTitle = agentTitle
      }

      setLocalStorage(USER_INFO_KEY, userDetails);
      //Saving tutorial displayed status page
      if (tutorialStatus && tutorialStatus.length) {
        let tutorialDisplayedPages = [];
        for (let i = 0; i < tutorialStatus.length; i++) {
          if (tutorialStatus[i].PageNo && tutorialStatus[i].Status == 1 && !tutorialDisplayedPages.includes(tutorialStatus[i].PageNo)) {
            tutorialDisplayedPages.push(tutorialStatus[i].PageNo)
          }
        }
        setLocalStorage('tutorialDisplayedPages', tutorialDisplayedPages);
      }
    }

  }
  const handleToggle = (action) => {
    handleAgentOnboardingModal({ showPopup: true, userAction: action });
  };

  const handlePassWordShow = () => {
    setPassWordShow(!isShowPassWord)
  };

  return (
    <Modal
      show={isShowPopup}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal-width-548"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        {/* <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {
            handleAgentOnboardingModal({ showPopup: false, userAction: null });
          }}
        ></button> */}
       



{
 !isLogin ? 
  <>
  <h2 class="mob-style-heading text-center mb-0">Welcome to Gitcha!</h2>
    <div className="fb-login-banner-desktop mt-18">
        <img src={FbLoginBannerDesktop} alt="Login Banner" />
        
        </div>
        <div className="fb-login-tagline-desktop">
        Create your account to get started, it’s free!
        </div>
  </>
  :
   <>
    <h2 className="text-center mb-0">
          {/* {isLogin ? "Sign in to Gitcha" : "Sign up to get started!"} */}
          {/* <p className="short-heading">(License agents only)</p> */}
          Sign in to Gitcha
        </h2>
   <div>
        <p className="text-center mb-32 pt-32">
          {/* {isLogin ? "By continuing,  " : "By signing up,  "} you agree to Gitcha's{" "} */}
          By continuing, you agree to Gitcha's{" "}
          <a role="button" target="_blank" href="terms-of-service"> Terms</a> and
          <a role="button" target="_blank" href="privacy-policy"> Privacy Policy.</a>
        </p>
        <Button
          variant="outline-primary"
          className="btn outline-btn w-100 mb-32"
          onClick={() => googleLogin()}
          
        >
          <img src={GoogleLogo} className="me-2" />
          <span>Continue With Google</span>
        </Button>
        <div className="segment-divider">OR</div>
        </div>
  </>
}
        {/* <div className="fb-login-banner-desktop mt-18">
        <img src={FbLoginBannerDesktop} alt="Login Banner" />
        
        </div>
        <div className="fb-login-tagline-desktop">
        Create your account to get started, it’s free!
        </div> */}

        <div className="textbox-wrap mb-4 mb-sm-4 position-relative">
          <label className="form-label">Email</label>
          <TextBox
            name="email"
            type="text"
            tabIndex="1"
            autoFocus={true}
            errorMessage={_.get(formErrors, "emailError", "")}
            value={_.get(formData, "email", "")}
            placeholder="yourname@email.com"
            class={_.get(formErrors, "emailError", null) ? "form-control validation-txtfld" : "form-control"}
            onChange={(e) => {
              onTextChange(e);
            }}
          />
        </div>

        <div className="textbox-wrap mb-0 mb-sm-0 position-relative">
          <label className="form-label">Password</label>
          <TextBox
            name="pwd"
            type={passwordType}
            tabIndex="2"
            errorMessage={_.get(formErrors, "pwdError", "")}
            value={_.get(formData, "pwd", "")}
            placeholder={isLogin ? "Password" : "Create a Password"}
            errorClass="error-msg error-msg-height"
            class={_.get(formErrors, "pwdError", null) ? "pad-right-40 validation-txtfld" : "pad-right-40"}
            onChange={(e) => {
              onTextChange(e);
            }}
          />
          {loginError ? <div className="error-msg error-msg-height">{loginError}</div> :
            <div className='error-msg'>
              {loginError && loginError !== '' && <span className='error-msg'>{loginError}</span>}
            </div>}

          <Button variant="" className="btn btn-pwd-toggle" onClick={() => handlePassWordShow()}>
            <img src={isShowPassWord ? passwordClosedIcon : passwordIcon} alt="pwdicon" />
          </Button>
        </div>
        {isLogin && (
          <p className="text-start mb-32">
            <a href={void 0}
              role="button"
              onClick={() => handleResetPwdModal({ showResetModal: true, resetPwdModalType: resetModalemailOnly })}>Forgot your password?</a>
          </p>
        )}

        {!isLogin && (
          <>
            <br />
            <div className="textbox-wrap mb-4 mb-sm-4 position-relative">
              <label className="form-label">Confirm Password</label>
              <TextBox
                name="confPwd"
                type="password"
                tabIndex="3"
                errorMessage={_.get(formErrors, "confPwdError", "")}
                value={_.get(formData, "confPwd", "")}
                placeholder="Confirm Password"
                class={_.get(formErrors, "confPwdError", null) ? "form-control validation-txtfld" : "form-control"}
                onChange={(e) => {
                  onTextChange(e);
                }}
              />
            </div>
          </>
        )}

        <Button
          variant="primary"
          tabIndex="4"
          className="w-100 mb-4"
          disabled={buttonDisable}
          onClick={() => handleSubmit()}
        >
          Continue
        </Button>
        {
         !isLogin &&
          <>
          <div className="segment-divider mb-24">OR</div>
        <Button
          variant="outline-primary"
          className="btn outline-btn w-100 mb-20"
          onClick={() => googleLogin()}
          
        >
          <img src={GoogleLogo} className="me-2" />
          <span>Continue With Google</span>
        </Button>
        <p className="text-center mb-20">
        By signing up, you agree to Gitcha’s
          <a role="button" target="_blank" href="terms-of-service"> Terms</a> and
          <a role="button" target="_blank" href="privacy-policy"> Privacy Policy.</a>
        </p>
        </>

        }
        

        {isLogin ? (
          <p className="text-center">
            Don't have an account yet?{" "}
            <a href={void 0} role="button" onClick={() => handleToggle(SIGNUP)}>
              Sign up here
            </a>
          </p>
        ) : (
          <p className="text-center">
            Already have an account?{" "}
            <a href={void 0} role="button" onClick={() => handleToggle(LOGIN)}>
              Login here
            </a>
          </p>
        )}
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default AgentOnboardingModal;
