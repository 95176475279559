/**
 * @file   src\components\Messages\IntroDetails.js
 * @brief  This file is responsible for handling introduction details screen.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Carousel } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";
import InventoryLocationMap from "../../images/inventory-location-map.png";
import Riverviewicon from "../../images/river-view.svg";
import noImageProfile from "../../images/no-image-small.png";
import noImage from "../../images/no-image-placeholder.png";
import ExtLinkIcon from "../../images/external-link-icn.svg";

import {
    filterArray,
    formateDate,
    getInitials,
    getpropertyName,
    removeDecimalZeros,
    setLocalStorage,
    thousandSeperatedValue,
    truncate,
} from "../../helpers/common";
import {
    AGENT_USER_MONTHLY_PRO_MEMBER,
    AGENT_USER_YEARLY_PRO_MEMBER,
    INVENTORY_KEY,
    IS_LAND,
    LOT_TYPES,
} from "../../constants/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";

const IntroDetails = (props) => {
    const navigate = useNavigate()
    const { show, onHide, details, userDeleted } = props;
    let { initials, backgroundColor } = details?.Full_Name ? getInitials(details?.Full_Name) : '';

    const askingPrice = _.get(details, "AskingPrice", "");
    const introDate = _.get(details, "Accept_Date", "");
    const photos = _.get(details, "Photos", []);
    const noPhotosExist = photos?.length == 0;
    const isLand = _.get(details, "PropertyTypeId", "") === IS_LAND;
    const LotSz = _.get(details, "LotSz", "");
    const niceToHave = _.get(details, "N2H_Properties", []);
    const compensteAgent =
        _.get(details, "Compensate_Agent", null) === 0
            ? null
            : _.get(details, "Compensate_Agent", null);
    const agentPercent =
        _.get(details, "Compensate_Percent", null) === 0
            ? null
            : _.get(details, "Compensate_Percent", null);
    const insReport =
        _.get(details, "Inspection_Report", null) === 0
            ? null
            : _.get(details, "Inspection_Report", null);
    const appraisalRpt =
        _.get(details, "Appraisal_on_File", null) === 0
            ? null
            : _.get(details, "Appraisal_on_File", null);
    const floodCert =
        _.get(details, "Flood_Certificate", null) === 0
            ? null
            : _.get(details, "Flood_Certificate", null);

    const highSchool = _.get(details, "highSchool", null);
    const neighborHood = _.get(details, 'neighborHood', null);
    const AdditionalDetails = details?.Add_Details ? truncate(details?.Add_Details, 100) : '';
    const [displayMoreDetails, setDisplayMoreDetails] = useState(false);

    const handleViewInventoryClick = (introductionId) => {
        setLocalStorage(INVENTORY_KEY, { introductionId: introductionId })
        // window.open(pageURLs.viewIntroducedInventory, '_blank',  { state: { userDeleted: userDeleted, fromMessagesScreen: true }});
        navigate(pageURLs.viewIntroducedInventory, { state: { userDeleted: userDeleted, fromMessagesScreen: true } })
    }

    useEffect(() => {
        setLocalStorage(INVENTORY_KEY, { introductionId: _.get(details, "IntroductionId", "") })
    }, [details])

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip1" {...props}>
            Close
        </Tooltip>
    );

    return (
        <>
            <Modal
                show={show}
                {...props}
                backdrop="static"
                aria-labelledby="accept-modal-title-vcenter"
                className="client-notes-modal accept-intro-modal right"
            >
                <Modal.Body className="pt-32 pb-0">
                    <div className="client-notes-modal-top">
                        <div className="flex-grow-1">
                            <h3 className="long-txt-fix">
                                {/* {getpropertyName(details)} in {_.get(details, "City", "")}, {_.get(details, "State", "")} */}
                                {_.get(details, "Location", "")}
                            </h3>
                            {
                                _.get(details, "IsAccept", "") == 0 ?
                                    (
                                        _.get(details, "CreatedOn", "") &&
                                        <p>
                                            Introduced {formateDate(_.get(details, "CreatedOn", ""), "mm/dd/yyyy")}
                                        </p>
                                    )
                                    :
                                    (
                                        introDate &&
                                        <p>
                                            Accepted {formateDate(introDate, "mm/dd/yyyy")}
                                        </p>
                                    )
                            }

                            <h5>${askingPrice && thousandSeperatedValue(askingPrice)}</h5>
                        </div>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <button
                                type="button"
                                class="btn btn-slide-right flex-shrink-0"
                                aria-label="Close"
                                onClick={() => onHide(false)}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.781 12.781L5.28104 20.281C5.14031 20.4218 4.94944 20.5008 4.75042 20.5008C4.55139 20.5008 4.36052 20.4218 4.21979 20.281C4.07906 20.1403 4 19.9494 4 19.7504C4 19.5514 4.07906 19.3605 4.21979 19.2198L11.1901 12.2504L4.21979 5.28104C4.07906 5.14031 4 4.94944 4 4.75042C4 4.55139 4.07906 4.36052 4.21979 4.21979C4.36052 4.07906 4.55139 4 4.75042 4C4.94944 4 5.14031 4.07906 5.28104 4.21979L12.781 11.7198C12.8508 11.7894 12.9061 11.8722 12.9438 11.9632C12.9816 12.0543 13.001 12.1519 13.001 12.2504C13.001 12.349 12.9816 12.4466 12.9438 12.5376C12.9061 12.6287 12.8508 12.7114 12.781 12.781ZM20.281 11.7198L12.781 4.21979C12.6403 4.07906 12.4494 4 12.2504 4C12.0514 4 11.8605 4.07906 11.7198 4.21979C11.5791 4.36052 11.5 4.55139 11.5 4.75042C11.5 4.94944 11.5791 5.14031 11.7198 5.28104L18.6901 12.2504L11.7198 19.2198C11.5791 19.3605 11.5 19.5514 11.5 19.7504C11.5 19.9494 11.5791 20.1403 11.7198 20.281C11.8605 20.4218 12.0514 20.5008 12.2504 20.5008C12.4494 20.5008 12.6403 20.4218 12.781 20.281L20.281 12.781C20.3508 12.7114 20.4061 12.6287 20.4438 12.5376C20.4816 12.4466 20.501 12.349 20.501 12.2504C20.501 12.1519 20.4816 12.0543 20.4438 11.9632C20.4061 11.8722 20.3508 11.7894 20.281 11.7198Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                        </OverlayTrigger>
                    </div>
                    <div className="property-slider">
                        <Carousel>
                            {noPhotosExist ? (
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={noImage}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            ) : (
                                photos.map((index) => {

                                    return (
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={index}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                    );
                                })
                            )}
                        </Carousel>
                    </div>

                    <div className="musthave-wrap">
                        <h6>Property details</h6>
                        {isLand ? (
                            <ul>
                                <li>
                                    <span class="musthave-label">Acreage</span>
                                    <span class="musthave-value">
                                        {_.get(details, "TotalAcre", "")}
                                    </span>
                                </li>
                                <li>
                                    <span class="musthave-label">Lot Type</span>
                                    <span class="musthave-value">
                                        {filterArray(
                                            LOT_TYPES,
                                            "value",
                                            _.get(intro, "LotType_Rural_City", "")
                                        )}
                                    </span>
                                </li>
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    <span class="musthave-label">Beds</span>
                                    <span class="musthave-value">
                                        {removeDecimalZeros(_.get(details, "Bedrooms", ""))}
                                    </span>
                                </li>
                                <li>
                                    <span class="musthave-label">Baths</span>
                                    <span class="musthave-value">
                                        {removeDecimalZeros(_.get(details, "Bathrooms", ""))}
                                    </span>
                                </li>
                                <li>
                                    <span class="musthave-label">Sqft</span>
                                    <span class="musthave-value">
                                        {removeDecimalZeros(_.get(details, "Sqft", ""))}
                                    </span>
                                </li>
                                {LotSz && (
                                    <li>
                                        <span class="musthave-label">Lot size</span>
                                        <span class="musthave-value">{LotSz} Acres</span>
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>

                    {(details?.UserTypeId == 2) &&
                        <>
                            <div class="nicetohaves-wrap">
                                <div className="profile-pic-sec mb-0">
                                    <figure className="brdr-rs-200"><img src={details?.Img ? details?.Img : noImageProfile} className="brdr-rs-200" /></figure>
                                    <div className="profile-name">
                                        <h6 className="d-flex mb-1">{details?.Full_Name}{_.get(details, 'PlanId', '') === AGENT_USER_MONTHLY_PRO_MEMBER
                                            || _.get(details, 'PlanId', '') === AGENT_USER_YEARLY_PRO_MEMBER &&
                                            <span class="pro-blue-badge">PRO</span>}
                                        </h6>
                                        <p className="position">Agent @ {details?.Company}</p>
                                        {userDeleted == false &&
                                            <a role="button" onClick={() => { props.ViewProfileModal(details?.Agent_User_Id) }}>View bio</a>
                                        }
                                    </div>
                                </div>
                            </div>

                        </>
                    }
                    {(details?.UserTypeId == 1) &&

                        <div class="nicetohaves-wrap border-bottom-0">

                            <div className="profile-pic-sec mb-0">
                                <div className={"profile-placeholder-wrap " + backgroundColor}>
                                    {initials}
                                </div>
                                <div className="profile-name">
                                    <h6 className="d-flex mb-0">{details?.Full_Name} </h6>
                                    <p className="position">
                      Property Owner
                      </p>
                                </div>
                            </div>
                        </div>
                    }
                    {_.get(details, "Message", "") &&
                        <div className="agent-testimonial pb-20">
                            <p>{details?.Full_Name} said</p>
                            <div className="content-block">
                                "{_.get(details, "Message", "")}"
                            </div>
                        </div>
                    }
                    {details?.Add_Details && (
                        <div className="additional-details-wrap pt-20 pb-20">
                            <h6>Description</h6>
                            {!displayMoreDetails ?
                                <p className="long-txt-fix pb-2"> {AdditionalDetails}</p>
                                :
                                <p className="long-txt-fix">{details?.Add_Details}</p>

                            }
                            {AdditionalDetails.length > 100 && !displayMoreDetails &&


                                <a href={void 0} className="read-more-link" onClick={() => { setDisplayMoreDetails(true) }}>Read more</a>
                            }


                        </div>
                    )}


                    <div className="Inventory-Location-wrap">
                        <h6>Location</h6>
                        <div className="Inventory-Location-map-wrap location-overlayfix mb-3">
                            <img src={details?.Location_Img ?? InventoryLocationMap} alt={InventoryLocationMap} />
                        </div>
                        <ul>
                            {highSchool
                                && <li>
                                    <span className="Inventory-icn-wrap">
                                        <img src={SchoolSmallIcon} />
                                    </span>
                                    {highSchool}
                                </li>}
                            {neighborHood
                                &&
                                <li>
                                    <span className="Inventory-icn-wrap">
                                        <img src={NeighborhoodIcon} />
                                    </span>
                                    {neighborHood}
                                </li>}
                        </ul>
                    </div>
                    {niceToHave?.length !== 0 && (
                        <div className="nicetohaves-wrap">
                            <h6>Property features</h6>
                            <div className="nicetohaves-inner-wrap">
                                {niceToHave.map((n2h, index) => {
                                    const icon = _.get(n2h, "Img_Icon", "");
                                    return (
                                        <div class="nicetohaves-tag">
                                            <div class="nicetohaves-tag-icon" key={index}>
                                                <img
                                                    src={
                                                        icon
                                                            ? require("..//../images/" + icon)
                                                            : Riverviewicon
                                                    }
                                                    alt={icon ? icon : Riverviewicon}
                                                />
                                            </div>
                                            <span>{_.get(n2h, "N2H_CharText", "")}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {compensteAgent || insReport || appraisalRpt || floodCert? <div class="buyer-info buyer-info-btm-brdr">
                        <h6>Other details</h6>
                        <ul className="pb-3">
                            {compensteAgent && (
                                <li>{agentPercent}% compensation to buyers agent</li>
                            )}
                            {insReport && <li>Inspection report on file</li>}
                            {appraisalRpt && <li>Appraisal on file</li>}
                            {floodCert && <li>Flood certificate available</li>}
                        </ul>
                    </div> : <></>}
                </Modal.Body>
                <Modal.Footer className="position-static px-36 border-top-0">
                    <div className="modal-btn-wrap">
                        <a className="w-100 btn-fix btn btn-primary" href={pageURLs.viewIntroducedInventory + "?userDeleted=" + userDeleted + "&fromMessagesScreen=" + true} target="_new" >
                            Open In New Tab
                            <img src={ExtLinkIcon} alt={ExtLinkIcon} /></a>
                        {/* <Button variant="primary" className="w-100 btn-fix" onClick={() => { handleViewInventoryClick(_.get(details, "IntroductionId", "")) }}>
                            Open In New Tab
                            <img src={ExtLinkIcon} alt={ExtLinkIcon} />
                        </Button>  */}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default IntroDetails;
