import React, { useState, useEffect } from "react";
import WantAdLotSize from "../../commonComponents/WantAdLotSize";
import { IN_CITY_LIMITS, WANT_AD_KEY } from "../../../constants/common";
import { getLocalStorage } from "../../../helpers/common";
const MustHavesLand = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setDisableSave,
    isEdit
  } = props;

  const lotType = _.get(formValues, "lotType", null);
  const lotTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "lotType", null);
  const roadType = _.get(formValues, "roadType", null);
  const roadTypeInSession = _.get(getLocalStorage(WANT_AD_KEY), "roadType", null);
  const isPrivtDrilInstld=_.get(formValues, "isPrvtDrInst", null);
  const isPrivtDrilInstldInSession=_.get(getLocalStorage(WANT_AD_KEY), "isPrvtDrInst", null);
  const onSiteElec=_.get(formValues, "onSiteElec", null);
  const onSiteElecInSession=_.get(getLocalStorage(WANT_AD_KEY), "onSiteElec", null);
  const waterSupply=_.get(formValues, "waterSupply", null);
  const waterSupplyInSession=_.get(getLocalStorage(WANT_AD_KEY), "waterSupply", null);
  const isDrldWelInstld=_.get(formValues, "isDrldWelInst", null);
  const isDrldWelInstldInSession=_.get(getLocalStorage(WANT_AD_KEY), "isDrldWelInst", null);
  const wasteWtrSys=_.get(formValues, "wasteWtrSys", null);
  const wasteWtrSysInSession=_.get(getLocalStorage(WANT_AD_KEY), "wasteWtrSys", null);
  const isSepTankInstld=_.get(formValues, "isSepTankInst", null);
  const isSepTankInstldInSession=_.get(getLocalStorage(WANT_AD_KEY), "isSepTankInst", null);
  const isSepTankApprvd=_.get(formValues, "isSepTankAppr", null);
  const isSepTankApprvdInSession=_.get(getLocalStorage(WANT_AD_KEY), "isSepTankAppr", null);
  const minLtSz=_.get(formValues, "minLtSz", null);
  const maxLtSz=_.get(formValues, "maxLtSz", null);
  const mustHaveErrors=_.get(formValues, "wantAdValErrors", null);
  const errorUpdatedAt=_.get(formValues, "errorUpdatedAt", null);

  const [roadTypePrivteInstalled, setRoadTypePrivteInstalled] = useState(false);
  const [waterSupplyDrilledWell, setWaterSupplyDrilledWell] = useState(false);
  const [septicTankInstalled, setSepticTankInstalled] = useState(false); 

  useEffect(() => {
    roadType && handleRoadTypePrivteInstalled(roadType);
    waterSupply && handleWaterSupplyDrilledWellInstalled(waterSupply);
    wasteWtrSys && handleSepticTankInstalled(wasteWtrSys);
    // setDisableSave(false);
  }, []);

  useEffect(()=>{
    if(isEdit){
      let isDisable = true; 
      if(lotType != lotTypeInSession){
        isDisable = false;
      }
      if(roadType != roadTypeInSession){       
        isDisable = false;
      }
      if(isPrivtDrilInstld != isPrivtDrilInstldInSession){
        isDisable = false;
      }
      if(onSiteElec !=onSiteElecInSession){
        isDisable = false;
      }
      if(waterSupply != waterSupplyInSession){
        isDisable = false;
      }
      if(isDrldWelInstld != isDrldWelInstldInSession){
        isDisable = false;
      }
      if(wasteWtrSys != wasteWtrSysInSession){
        isDisable = false;
      }
      if(isSepTankInstld != isSepTankInstldInSession){
        isDisable = false;
      }
      if(isSepTankApprvd != isSepTankApprvdInSession){
        isDisable = false;
      }      
      setDisableSave(isDisable);
    }
    else{
          setDisableSave(false);
      
    }

  }, [lotType, roadType, isPrivtDrilInstld, onSiteElec, waterSupply, isDrldWelInstld, wasteWtrSys, isSepTankInstld, isSepTankApprvd])


  const handleLotTypeSelection = (selectedValue, selectedText) => {
    if (selectedValue === lotType) {
      handleFormValues({
        lotType: "",
        lotTypeText: "",
      });
    } else {
      handleFormValues({ lotType: selectedValue, lotTypeText: selectedText });
    }
  };

  const handleRoadTypePrivteInstalled = (rt) => {
    setRoadTypePrivteInstalled(false);
    if (rt === "2") {
      setRoadTypePrivteInstalled(true);
    } else {
      handleFormValues({ isPrvtDrInst: "" });
    }
  };

  const handleRoadTypeSelection = (selectedValue, selectedText) => {
    if (selectedValue === roadType) {
      handleFormValues({
        roadType: "",
        rdTypText: "",
        isPrvtDrInst: "",
      });
      setRoadTypePrivteInstalled(false);
    } else {
      handleRoadTypePrivteInstalled(selectedValue);
      handleFormValues({ roadType: selectedValue, rdTypText: selectedText });
    }
  };

  const handlePrivateDriveInstall = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isPrvtDrInst: selection });
  };

  const handleElectricityOnsiteSelection = (selection) => {
    if (onSiteElec === selection) {
      handleFormValues({ onSiteElec: "" });
    } else {
      handleFormValues({ onSiteElec: selection });
    }
  }; 

  const handleWaterSupplyDrilledWellInstalled = (ws) => {
    setWaterSupplyDrilledWell(false);
    if (ws === "2") {
      setWaterSupplyDrilledWell(true);
    } else {
      handleFormValues({ isDrldWelInst: "" });
    }
  };

  const handleWaterSupplySelection = (selectedValue, selectedText) => {
    if (selectedValue === waterSupply) {
      handleFormValues({
        waterSupply: "",
        wSText: "",
        isDrldWelInst: "",
      });
      setWaterSupplyDrilledWell(false);
    } else {
      handleWaterSupplyDrilledWellInstalled(selectedValue);
      handleFormValues({ waterSupply: selectedValue, wSText: selectedText });
    }
  };

  const handleDrilledWellInstall = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isDrldWelInst: selection });
  };

  const handleSepticTankInstalled = (rt) => {
    setSepticTankInstalled(false);
    if (rt === "2") {
      setSepticTankInstalled(true);
    } else {
      handleFormValues({ isSepTankInst: "", isSepTankAppr: "" });
    }
  };

  const handleWasteWaterSystemSelection = (selectedValue, selectedText) => {
    if (selectedValue === wasteWtrSys) {
      handleFormValues({
        wasteWtrSys: "",
        wWSysText: "",
        isSepTankInst: "",
        isSepTankAppr: "",
      });
      setSepticTankInstalled(false);
    } else {
      handleSepticTankInstalled(selectedValue);
      handleFormValues({ wasteWtrSys: selectedValue, wWSysText: selectedText });
    }
  };

  const handleSepticTankInstalledStatus = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isSepTankInst: selection });
  };

  const handleSepticTankApprovedStatus = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isSepTankAppr: selection });
  };

  
  return (
    <>
      <div className="stay-timing-wrap mb-4">
        <WantAdLotSize
          handleFormValues={handleFormValues}
          mnLotSze={minLtSz}
          mxLotSze={maxLtSz}
          lotSizeError={mustHaveErrors}
          errorUpdatedAt={errorUpdatedAt}
        />        
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4>Lot Type</h4>
          <div className="row">
            <div className="col-6">
              <a href={void 0}
               className={lotType == "1" ? "blockBtn text-center active" : "blockBtn text-center" }
               onClick={(e)=>{
                e.stopPropagation();
                handleLotTypeSelection("1", "Rural")
               }}   
               >
                {" "}
                Rural{" "}
              </a>
            </div>
            <div className="col-6">
            <a href={void 0}
               className={lotType == "2" ? "blockBtn text-center active" : "blockBtn text-center" }
               onClick={(e)=>{
                e.stopPropagation();
                handleLotTypeSelection("2", IN_CITY_LIMITS)
               }}   
               >
                {" "}
                In City Limits{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Road Type</h4>
          <div className="row">
            <div className="col-6">
              <a href={void 0} 
              className={roadType == "1" ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e) => {
                e.stopPropagation();
                handleRoadTypeSelection("1", "City roads")
                }}
              >
                {" "}
                City roads{" "}
              </a>
            </div>
            <div className="col-6">
            <a href={void 0} 
              className={roadType == "2" ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e) => {
                e.stopPropagation();
                handleRoadTypeSelection("2", "Private drive")
                }}
              >
                {" "}
                Private drive{" "}
              </a>
            </div>
          </div>

          {roadTypePrivteInstalled && (<div className="row">
            <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="road-installed"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="road-installed"
                  onChange={handlePrivateDriveInstall}
                  checked={isPrivtDrilInstld}
                />
                <span className=""> Installed</span>
              </label>
            </div>
          </div>)}
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Electricity On-site</h4>
          <div className="row">
            <div className="col-6">
              <a href={void 0 } 
              className={onSiteElec === true || onSiteElec === 1 ? "blockBtn text-center active" : "blockBtn text-center"}
              onChange={() => {}}
              onClick={(e)=>{
                e.stopPropagation();
                handleElectricityOnsiteSelection(true)
                }}
              >
                {" "}
                Yes{" "}
              </a>
            </div>
            <div className="col-6">
            <a href={void 0 } 
              onChange={() => {}}
              className={onSiteElec === false || onSiteElec === 0 ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e)=>{
                e.stopPropagation();
                handleElectricityOnsiteSelection(false)
                }}
              >
                {" "}
                No{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Water Supply</h4>
          <div className="row">
            <div className="col-6">
              <a href={void 0}
              className={waterSupply && waterSupply === "1" ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e) =>{
                e.stopPropagation();
                handleWaterSupplySelection("1", "Domestic water supply")}
              }
              >
                Domestic{" "}
              </a>
            </div>
            <div className="col-6">
            <a href={void 0}
              className={waterSupply && waterSupply === "2" ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e) =>{
                e.stopPropagation();
                handleWaterSupplySelection("2", "Drilled well")
              }}
              >
                {" "}
                Drilled Water{" "}
              </a>
            </div>
          </div>

          {waterSupplyDrilledWell && <div className="row">
            <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="drilled-well"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="drilled-well"
                  onChange={handleDrilledWellInstall}
                  checked={isDrldWelInstld}
                />
                <span className=""> Installed</span>
              </label>
            </div>
            {/* <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="IAmRepresenting2"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IAmRepresenting2"
                />
                <span className=""> Approved</span>
              </label>
            </div> */}
          </div>
          }
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Wastewater System</h4>
          <div className="row">
            <div className="col-6">
              <a href={void 0} 
              className={wasteWtrSys && wasteWtrSys === "1" ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e) =>{ 
                e.stopPropagation();
                handleWasteWaterSystemSelection("1", "Sewer")
            }}
              >
                Sewer{" "}
              </a>
            </div>
            <div className="col-6">
            <a href={void 0} 
              className={wasteWtrSys && wasteWtrSys === "2" ? "blockBtn text-center active" : "blockBtn text-center"}
              onClick={(e) =>{ 
                e.stopPropagation();
                handleWasteWaterSystemSelection("2", "Septic system")
            }}
              >
                {" "}
                Septic{" "}
              </a>
            </div>
          </div>

          {septicTankInstalled && (<div className="row">
            <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="sep-installed"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="sep-installed"
                  onChange={handleSepticTankInstalledStatus}
                  checked={isSepTankInstld}
                />
                <span className=""> Installed</span>
              </label>
            </div>
            <div className="col-12 pt-4 ps-3">
              <label
                class="form-check-label checkbox-label"
                for="aprvd"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="aprvd"
                  onChange={handleSepticTankApprovedStatus}
                  checked={isSepTankApprvd}
                />
                <span className=""> Approved</span>
              </label>
            </div>
          </div>)}
        </div>
      </div>
    </>
  );
};

export default MustHavesLand;
