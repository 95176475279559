/**
 * @file   src\components\Messages\MessageLeftSection.js
 * @brief  This file is responsible for handling messaging/chat property listing in left section screen.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import SideBarChat from './SideBarChat';

function MessageLeftSection(props) {
    const messages = props.messages;
    return (
        <>
            {messages && messages.length > 0 ?
                (
                    <div className="messaging-top-content">
                        {messages.map((msg, index) => (
                            <SideBarChat key={msg.IntroductionId}
                                id={msg.IntroductionId}
                                msgData={msg}
                                index={index}
                                selectedIntroductionId={props.selectedIntroductionId}
                                userId={props.userId}
                                handleWantAdSelection={(msgData) => { props.handleWantAdSelection(msgData) }} 
                                introIdFromURL={props?.introIdFromURL}/>
                        ))}
                    </div>
                )
                :
                (
                    <div className="messaging-skeleton-loading">
                        <div className="messaging-skeleton-wrapper">
                            <div class="flex-shrink-0">
                                <div className="intro-skeleton-prof-wrap">prof image</div>
                            </div>
                            <div class="flex-grow-1 ml-10">
                                <div className="messaging-skeleton-content">
                                    <div className="messaging-skeleton-content-top">top</div>
                                    <div className="messaging-skeleton-content-btm">btm</div>
                                </div>
                            </div>
                        </div>

                        <div className="messaging-skeleton-wrapper">
                            <div class="flex-shrink-0">
                                <div className="intro-skeleton-prof-wrap">prof image</div>
                            </div>
                            <div class="flex-grow-1 ml-10">
                                <div className="messaging-skeleton-content">
                                    <div className="messaging-skeleton-content-top">top</div>
                                    <div className="messaging-skeleton-content-btm">btm</div>
                                </div>


                            </div>
                        </div>

                        <div className="messaging-skeleton-wrapper">
                            <div class="flex-shrink-0">
                                <div className="intro-skeleton-prof-wrap">prof image</div>
                            </div>
                            <div class="flex-grow-1 ml-10">
                                <div className="messaging-skeleton-content">
                                    <div className="messaging-skeleton-content-top">top</div>
                                    <div className="messaging-skeleton-content-btm">btm</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default MessageLeftSection;