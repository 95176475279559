/**
 * @file   src\actions\login.js
 * @brief  This file is responsible for redux actions related to login
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGoogleUserInfo } from "../services/loginService";

export const getGoogleUser = createAsyncThunk("google/userInfo", () => {
  return getGoogleUserInfo();
});


