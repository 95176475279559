import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import WantAdLocation from "../components/AgentWantAd/WantAdLocation";
import { useRef } from "react";
import {
  AGENT_WANTAD_KEY,
  BUY,
  IMG_JPEG_FORMAT,
  IS_LAND,
} from "../constants/common";
import WantAdTiming from "../components/AgentWantAd/WantAdTiming";
import WantAdType from "../components/AgentWantAd/WantAdType";
import WantAdMustHaves from "../components/AgentWantAd/WantAdMustHaves";
import WantAdLandMustHaves from "../components/AgentWantAd/WantAdLandMustHaves";
import NiceToHave from "../components/Common/NiceToHave/NiceToHave";
import WantAdBudget from "../components/AgentWantAd/WantAdBudget";
import WantAdPropertyPurchase from "../components/AgentWantAd/WantAdPropertyPurchase";
import WantAdPurchaseFinance from "../components/AgentWantAd/WantAdPurchaseFinance";
import WantAdDescription from "../components/AgentWantAd/WantAdDescription";
import {
  convertToNumber,
  nameValidation,
  textValidations,
} from "../helpers/validations";
import {
  getBuyerListWantAdById,
  saveBuyerListStrengthenWantAd,
  saveBuyerListingWantAd,
} from "../services/wantAdService";
import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";
import TextBox from "../components/Common/TextBox";
import {
  createPreSignedUrl,
  getLocalStorage,
  toTitleCase,
} from "../helpers/common";
import html2canvas from "html2canvas";
import { dataURLToBlob } from "../helpers/createWantAd";
import { saveClientDetails } from "../services/agentClientsService";
import ErrorMessage from "../components/Common/ErrorMessage";
import AgentCompensation from "../components/AgentWantAd/AgentCompensation";
import { pageURLs } from "../constants/pageURLs";
import BuyerlistingHeader from "./BuyerListingHeader";

const BuyerListingCreateWantAdForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [locationCityError, setLocationCityError] = useState(false);
  const [locationRadiusError, setLocationRadiusError] = useState(false);
  const [timeLineError, setTimeLineError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [requirementBedroomsError, setRequirementBedroomsError] =
    useState(false);
  const [requirementBathroomsError, setRequirementBathroomsError] =
    useState(false);
  const [requirementTextColor, setRequirementTextColor] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [titleTextBoxError, setTitleError] = useState(false);
  const [compensationError, setCompensationError] = useState(false);
  const [selectedniceToHavesList, setSelectedNiceToHavesList] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  // const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [isValidId, setIsValidId] = useState(null);
  const YEAR_BUILT_MIN = 1900;
  const YEAR_BUILT_MAX = new Date().getFullYear();
  const [showUrlExpired, setShowUrlExpired] = useState(false)
  const [zipCodeError, setZipCodeError] = useState(false);
  const [locationError, setLocationError] = useState("");

  const [wantAdData, setWantAdData] = useState(
    JSON.parse(localStorage.getItem(AGENT_WANTAD_KEY))
  ); // to set the want-ad data

  const locationRef = useRef(null);
  const targetRef = useRef(null);
  const timeLineRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const mustHaveRef = useRef(null);
  const BudgetRef = useRef(null);
  const DescriptionRef = useRef(null);
  const CompensationRef = useRef(null);

  useEffect(() => {
    getWantAdDetails();
  }, [id]);

  const getWantAdDetails = async () => {
    const splitId = id.split("-");
    const wantAdId = splitId[splitId.length - 1];
    if (wantAdId) {
      await getBuyerListWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setWantAdData(responseResult);
            if (responseResult?.isReadyToReview) {
              if(responseResult?.isPublished){
                setShowUrlExpired(true);
              }
              else {navigate(pageURLs?.buyerListingConfirmation, {state : {wantAdData : responseResult}});}
            } else {
              setIsValidId(1);
              setFirstName(responseResult?.clientFirstName);
              setLastName(responseResult?.clientLastName);
            }
          } else {
            setIsValidId(0);
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  useEffect(() => {
    wantAdData?.nice2Have
      ? setSelectedNiceToHavesList(wantAdData?.nice2Have)
      : setSelectedNiceToHavesList([]);
    localStorage.setItem(AGENT_WANTAD_KEY, JSON.stringify(wantAdData));
    if (wantAdData) {
      handleSaveToDraft();
    }
    if ((wantAdData?.location && wantAdData?.county) || (wantAdData?.zipCode?.length > 0)) {
      setLocationCityError(false)
      setZipCodeError(false)
    }
    if (wantAdData?.mapRadius) {
      setLocationRadiusError(false);
    }

    if (wantAdData?.adType == BUY && wantAdData?.timeLine) {
      setTimeLineError(false);
    }

    if (wantAdData?.prprtyType != IS_LAND && wantAdData?.prprtyType) {
      if (wantAdData?.prptySubTypes && wantAdData?.prptySubTypes.length > 0) {
        setPropertyTypeError(false);
      }
    }

    if (wantAdData?.noBedRm) {
      setRequirementBedroomsError(false);
    }

    if (wantAdData?.noBathRm) {
      setRequirementBathroomsError(false);
    }

    // if (wantAdData?.adTitle && textValidations(wantAdData?.adTitle)) {
    //   setTitleError(false);
    // }
    if (wantAdData?.noBedRm && wantAdData?.noBathRm) {
      setRequirementTextColor(false);
    }

    if (textValidations(wantAdData?.adDesc)) {
      setDescriptionError(false);
    }
    // if(wantAdData?.compenationType && wantAdData?.agentCompenation){
    //   setCompensationError(false)
    // }
  }, [wantAdData]);

  useEffect(() => {
    if (firstName && lastName) {
      if (
        (firstName != wantAdData?.clientFirstName ||
          lastName != wantAdData?.clientLastName) &&
        firstNameError == "" &&
        lastNameError == ""
      ) {
        const payload = {
          client_Want_ad_Id: wantAdData?.client_Want_ad_Id,
          firstName: firstName,
          lastName: lastName,
        };
        saveClientDetails(payload)
          .then((response) => {
            const responseResult = _.get(response, "result", null);
            if (response.errorCode === 0 && responseResult) {
              getWantAdDetails();
            } else {
              console.log("Something went wrong");
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  }, [firstName, lastName]);

  const handleCaptureMapImage = (type, onkeyupSave = false) => {
    const fullScreenDiv = document.querySelector(".gm-fullscreen-control");
    const zoomIcons = document.querySelectorAll(".gmnoprint");
    const googleIcon = document.querySelector('img[alt="Google"]');
    const buttons = document.querySelectorAll(".gm-style-cc");
    const gmStyle = document.querySelector(".gm-style-moc");

    if (gmStyle) {
      gmStyle.style.display = "none";
    }

    if (fullScreenDiv) {
      fullScreenDiv.style.display = "none";
    }

    if (googleIcon) {
      googleIcon.style.display = "none";
    }

    zoomIcons.forEach((element) => {
      element.style.display = "none";
    });

    buttons.forEach((element) => {
      element.style.display = "none";
    });

    if (targetRef.current) {
      html2canvas(targetRef.current, { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          let dataURL = canvas.toDataURL(IMG_JPEG_FORMAT);
          const binaryData = dataURLToBlob(dataURL);
          handleMapImageUpload(binaryData, type, onkeyupSave);
        }
      );
    }
  };

  const calculatePercentageofCompletion = () => {
    let percentage = 0;
    let count = 0;
    if (wantAdData?.clientId) {
      count++;
    }
    if (wantAdData?.adType) {
      count++;
    }
    if (wantAdData?.timeLine) {
      count++;
    }
    if (wantAdData?.location && wantAdData?.location.trim() != "") {
      count++;
    }

    if (
      (wantAdData?.startDate && wantAdData?.endDate) ||
      (wantAdData?.month && wantAdData?.duration)
    ) {
      count++;
    }

    if (wantAdData?.prprtyType) {
      count++;
    }
    if (
      wantAdData?.noBathRm &&
      wantAdData?.noBedRm &&
      wantAdData?.sqFtMax &&
      wantAdData?.sqFtMin
    ) {
      count++;
    }
    if (wantAdData?.maxBdgt && wantAdData?.minBdgt) {
      count++;
    }
    if (wantAdData?.acreageMin && wantAdData?.acreageMin) {
      count++;
    }
    if (wantAdData?.adTitle) {
      count++;
    }

    percentage = count >= 8 ? 100 : 11 * count;
    return percentage;
  };

  const handleMapImageUpload = async (image, type, onkeyupSave) => {
    const mapResponse = image;
    const preSignedUrlRes = mapResponse
      ? await createPreSignedUrl(true, _.get(wantAdData, "s3ImageFolder", ""))
      : alert("Something went wrong while fetching map image.");
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl ? s3FullUrl.split("?")[0] : "";
        // if (type == "draft") {
        saveMemberWantAdData(imageUrl, onkeyupSave, type);
        // } else {
        //   saveMemberWantAdData(imageUrl, onkeyupSave, type);
        // }
      } else {
        setDisableButton(false);
      }
    } else {
      setDisableButton(false);
    }
  };

  const saveMemberWantAdData = async (imageUrl, onkeyupSave = false, type) => {
    let requestPayLoad = wantAdData;
    if (!requestPayLoad) {
      requestPayLoad = JSON.parse(localStorage.getItem(AGENT_WANTAD_KEY));
    }
    requestPayLoad.mapImg = imageUrl;
    requestPayLoad.cmpldStep = _.get(wantAdData, "actvStep", 1);
    requestPayLoad.IsSavedDraft = true;
    requestPayLoad.IsPublished = false;
    requestPayLoad.cmpldPerctg = calculatePercentageofCompletion();
    if (type === "save") {
      requestPayLoad.isReadyToReview = true;
    }

    let arrayValue = [];
    if(requestPayLoad?.zipIdValues){
      requestPayLoad?.zipIdValues.map((zip)=>{
        arrayValue.push(zip?.zipId)
        })
        requestPayLoad.zipId = arrayValue;
    }  else if(requestPayLoad?.zipCode){
      requestPayLoad?.zipCode.map((zip)=>{
        if(zip?.zipId && zip?.zipId != null){
          arrayValue.push(zip?.zipId)
        }
      })
      requestPayLoad.zipId = arrayValue;
  }
    requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
    saveBuyerListingWantAd(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if (
            wantAdData.purchaseType ||
            wantAdData.prchTypeId ||
            wantAdData.pFinance
          ) {
            saveStrengthenData(
              responseResult.client_Want_ad_Id,
              onkeyupSave,
              type
            );
          } else {
            if (type == "save") {
              localStorage.removeItem(AGENT_WANTAD_KEY);
              navigate(pageURLs?.buyerListingConfirmation, {state : {wantAdData : wantAdData}});
              // setShowThankyouModal(true);
              // document
              //   .getElementById("pageTop")
              //   ?.scrollIntoView({ behavior: "smooth" });
              // getWantAdDetails()
            } else {
              handleFormValues({
                client_Want_ad_Id: responseResult.client_Want_ad_Id,
              });
            }
          }
        } else {
          console.log("Something went wrong");
          setIsValidId(0);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const saveStrengthenData = async (wantAdIdValue, onkeyupSave, type) => {
    const wantAd = getLocalStorage(AGENT_WANTAD_KEY);
    const wantAdId = wantAdIdValue;
    let requestPayLoad = {};
    let isApproveLoan = "";
    if (
      _.get(wantAdData, "isPreApprLoan", null) === "Yes" ||
      _.get(wantAdData, "isPreApprLoan", null) == true
    ) {
      isApproveLoan = true;
    } else if (
      _.get(wantAdData, "isPreApprLoan", null) === "No" ||
      _.get(wantAdData, "isPreApprLoan", null) == true
    ) {
      isApproveLoan = false;
    }
    requestPayLoad.isPreApprLoan = isApproveLoan;
    const is1031Exchange = wantAd?.is1031Exchange === "Yes" ? true : false;
    requestPayLoad.client_Want_ad_Id = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan;
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.completedStep = 1;
    requestPayLoad.adType =
      wantAdData.adType == BUY ? "BUY_STRENGTHEN" : "LTR_STRENGTHEN";
    // requestPayLoad.creditScore = wantAdData.creditScore;
    requestPayLoad.maxBdgt = wantAdData.maxBdgt;
    requestPayLoad.minBdgt = wantAdData.minBdgt;
    requestPayLoad.pFinance = wantAdData.pFinance || wantAdData.finTypeId;
    requestPayLoad.purchaseType =
      wantAdData.purchaseType || wantAdData.prchTypeId;

    await saveBuyerListStrengthenWantAd(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if (type == "save") {
            localStorage.removeItem(AGENT_WANTAD_KEY);
            navigate(pageURLs?.buyerListingConfirmation, {state : {wantAdData : wantAdData}});
            // setShowThankyouModal(true);
            // document
            //   .getElementById("pageTop")
            //   ?.scrollIntoView({ behavior: "smooth" });
          } else {
            handleFormValues({ client_Want_ad_Id: wantAdId });
          }
        }
        else{
          setIsValidId(0);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleSaveToDraft = () => {
    if (wantAdData?.focusOut == true && wantAdData?.isPublished != 1) {
      if (wantAdData?.location && wantAdData?.county) {
        handleCaptureMapImage("draft", true);
        handleFormValues({ focusOut: false });
      } else {
        saveMemberWantAdData(null, true, "draft"); // true for checking on key up save or not for navigation
        handleFormValues({ focusOut: false });
      }
    }
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setWantAdData((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const handleFirstName = (name) => {
    setFirstNameError("");
    setFirstName(name);
    if (name.trim() === "") {
      setFirstNameError("Enter First Name");
    } else if (!nameValidation(name.trim())) {
      setFirstNameError("Invalid input");
    }
  };

  const handleLastName = (name) => {
    setLastNameError("");
    setLastName(name);
    if (name.trim() === "") {
      setLastNameError("Enter Last Name");
    } else if (!nameValidation(name.trim())) {
      setLastNameError("Invalid input");
    }
  };

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearAll") {
        setWantAdData({
          actvStep: 1,
          cmpldPerctg: 2,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          isPublished: wantAdData?.isPublished,
          client_Want_ad_Id : wantAdData?.client_Want_ad_Id,
          isFilledByClient : wantAdData?.isFilledByClient,
          clientData: wantAdData?.clientData,
          clientId: wantAdData?.clientId,          
          clientName: wantAdData?.clientName,
          isAgentCreated : wantAdData?.isAgentCreated,
          wantAdId: wantAdData?.wantAdId,
        });
      } else if (type === "clearFlexDates") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["duration", "month"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type === "dateRange") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["startDate", "endDate"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type === "moveInTypeChangeLTR") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["startDate"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type == "clearAllExceptClient") {
        setWantAdData({
          actvStep: 10,
          cmpldPerctg: 2,
          clientId: wantAdData?.clientId,
          isAgentAsSeller: wantAdData?.isAgentAsSeller,
          wantAdId: wantAdData?.wantAdId,
          s3ImageFolder: wantAdData?.s3ImageFolder,
          userId: wantAdData?.userId,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          isPublished: wantAdData?.isPublished,
          isEdit: wantAdData?.isEdit,
          client_Want_ad_Id : wantAdData?.client_Want_ad_Id,
          isFilledByClient : wantAdData?.isFilledByClient,
          clientFirstName : wantAdData?.clientFirstName,
          clientLastName : wantAdData?.clientLastName,
          isAgentCreated : wantAdData?.isAgentCreated,
        });
        setLocationCityError(false);
        setLocationRadiusError(false);
        setPropertyTypeError(false);
        setRequirementBathroomsError(false);
        setRequirementBedroomsError(false);
        setBudgetError(false);
        setTimeLineError(false);
        setDescriptionError(false);
        setZipCodeError(false)
      } else if (type == "clearErrorStateOnPropertySelection") {
        setWantAdData({
          isAgentAsSeller: wantAdData?.isAgentAsSeller,
          wantAdId: wantAdData?.wantAdId,
          s3ImageFolder: wantAdData?.s3ImageFolder,
          userId: wantAdData?.userId,
          isZipcodeBased : wantAdData?.isZipcodeBased,
          zipCode : wantAdData?.zipCode,
          zipId : wantAdData?.zipId,
          zipIdValues : wantAdData?.zipIdValues,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          city: wantAdData?.city,
          state: wantAdData?.state,
          county: wantAdData?.county,
          location: wantAdData?.location,
          mapRadius: wantAdData?.mapRadius,
          mapZoom: wantAdData?.mapZoom,
          timeLine: wantAdData?.timeLine,
          tlText: wantAdData?.tlText,
          dateType: wantAdData?.dateType,
          duration: wantAdData?.duration,
          month: wantAdData?.month,
          moveInTimeLine: wantAdData?.moveInTimeLine,
          moveInTxt: wantAdData?.moveInTxt,
          startDate: wantAdData?.startDate,
          neighborHood: wantAdData?.neighborHood,
          highSchool: wantAdData?.highSchool,
          prprtyType: wantAdData?.prprtyType,
          prptyTypeTxt: wantAdData?.prptyTypeTxt,
          adType: wantAdData?.adType,
          validationErrors: [],
          isPublished: wantAdData?.isPublished,
          isEdit: wantAdData?.isEdit,
          client_Want_ad_Id : wantAdData?.client_Want_ad_Id,
          isFilledByClient : wantAdData?.isFilledByClient,
          clientData: wantAdData?.clientData,
          clientId: wantAdData?.clientId,
          clientName: wantAdData?.clientName,
          isAgentCreated : wantAdData?.isAgentCreated,
        });

        setRequirementBathroomsError(false);
        setRequirementBedroomsError(false);
        setBudgetError(false);
        setTimeLineError(false);
        setDescriptionError(false);
      }
    }
  };

  async function handleSave(type) {
    let error = [];
    setDisableButton(true);

    if ((!wantAdData?.location || !wantAdData?.county) && (!wantAdData?.zipCode || wantAdData?.zipCode.length == 0 )) {
      setLocationCityError(true)
      setZipCodeError(true)
      error.push("locationRef", "city")
    } else{
      if(wantAdData?.location || wantAdData?.location!= "" || !wantAdData?.zipCode.length>0){
        if (!wantAdData?.mapRadius) {
          setLocationRadiusError(true)
          error.push("locationRef", "mapRadius")
        }
      } 
    }

    if (wantAdData?.adType == BUY && !wantAdData?.timeLine) {
      setTimeLineError(true);
      error.push("timeLineRef", "timeLine");
    }

    if (!wantAdData?.prprtyType) {
      setPropertyTypeError(true);
      error.push("propertyTypeRef", "prprtyType");
    } else {
      if (
        (wantAdData?.prprtyType != IS_LAND && !wantAdData?.prptySubTypes) ||
        wantAdData?.prptySubTypes?.length == 0
      ) {
        setPropertyTypeError(true);
        error.push("propertyTypeRef", "prprtyType");
      }
    }

    if (wantAdData?.prprtyType != IS_LAND) {
      if (!wantAdData?.noBedRm) {
        setRequirementBedroomsError(true);
        error.push("mustHaveRef", "noBedRm");
      }
      if (!wantAdData?.noBathRm) {
        setRequirementBathroomsError(true);
        error.push("mustHaveRef", "noBathRm");
      }
      if (!wantAdData?.noBedRm || !wantAdData?.noBathRm) {
        setRequirementTextColor(true);
      }
    }

    if (wantAdData?.sqFtMax && wantAdData?.sqFtMin) {
      if (
        convertToNumber(wantAdData?.sqFtMin) >=
        convertToNumber(wantAdData?.sqFtMax)
      ) {
        error.push("mustHaveRef", "sqftMaxLesser");
        setRequirementTextColor(true);
      }
    }

    if (wantAdData?.minLtSz && wantAdData?.maxLtSz) {
      if (
        convertToNumber(wantAdData?.minLtSz) >=
        convertToNumber(wantAdData?.maxLtSz)
      ) {
        error.push("mustHaveRef", "lotSizeMaxLesser");
        setRequirementTextColor(true);
      }
    }

    if (wantAdData?.yearBuiltOption == 1) {
      if (wantAdData.yrBltMin && wantAdData.yrBltMax) {
        if (
          convertToNumber(wantAdData.yrBltMax) < YEAR_BUILT_MIN ||
          convertToNumber(wantAdData.yrBltMax) > YEAR_BUILT_MAX ||
          convertToNumber(wantAdData.yrBltMin) < YEAR_BUILT_MIN ||
          convertToNumber(wantAdData.yrBltMin) > YEAR_BUILT_MAX
        ) {
          (convertToNumber(wantAdData.yrBltMax) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMax) > YEAR_BUILT_MAX) &&
            error.push("yearBuiltMaxRange");
          (convertToNumber(wantAdData.yrBltMin) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMin) > YEAR_BUILT_MAX) &&
            error.push("yearBuiltMinRange");
        } else if (
          convertToNumber(wantAdData.yrBltMax) <
          convertToNumber(wantAdData.yrBltMin)
        ) {
          error.push("mustHaveRef", "yearBuiltError");
        } else if (
          convertToNumber(wantAdData.yrBltMin) <
          convertToNumber(wantAdData.yrBltMin)
        ) {
          error.push("mustHaveRef", "yearBuiltError");
        }
      } else if (!wantAdData.yrBltMin || !wantAdData.yrBltMax) {
        !wantAdData.yrBltMin && error.push("mustHaveRef", "yearBuiltMinDate");
        !wantAdData.yrBltMax && error.push("mustHaveRef", "yearBuiltMaxDate");
      }
    }

    if (wantAdData?.minBdgt && wantAdData?.maxBdgt) {
      if (
        convertToNumber(wantAdData?.minBdgt) >=
        convertToNumber(wantAdData?.maxBdgt)
      ) {
        error.push("BudgetRef", "maxBdgt");
        setBudgetError(true);
      }
    }

    // if(wantAdData?.compenationType == 1 || wantAdData?.compenationType == 2){
    //   if (!wantAdData?.agentCompenation) {
    //     error.push("CompensationRef", "agentCompenation")
    //   }
    //   setCompensationError(true);
    // }

    // if (!wantAdData?.adTitle) {
    //   setTitleError(true);
    //   error.push("DescriptionRef", "adTitleTextBox");
    // }

    // if (wantAdData?.adTitle && !textValidations(wantAdData?.adTitle)) {
    //   error.push("DescriptionRef", "adTitle");
    //   setTitleError(true);
    // }

    if (wantAdData?.adDesc && !textValidations(wantAdData?.adDesc)) {
      error.push("DescriptionRef", "adDesc");
      setDescriptionError(true);
    }

    if (
      (wantAdData?.isPreApprLoan == 1 || wantAdData?.isPreApprLoan == "Yes") &&
      !wantAdData?.agreePreApprovedLoan
    ) {
      error.push("DescriptionRef", "agreePreApprovedLoan");
    }

    if (wantAdData?.agentCompenation == 0) {
      handleFormValues({ agentCompenation: "" });
    }

    if (error.length == 0) {
      handleFormValues({ validationErrors: [], updatedAt: Date.now() });
      handleCaptureMapImage(type);
      // saveMemberWantAdData(null, true, type);
    } else {
      if (error[0] == "ClientRef") {
        window.scrollTo(0, ClientRef.current.offsetTop);
      } else if (error[0] == "adTypeRef") {
        window.scrollTo(0, adTypeRef.current.offsetTop);
      } else if (error[0] == "locationRef") {
        window.scrollTo(0, locationRef.current.offsetTop);
      } else if (error[0] == "timeLineRef") {
        window.scrollTo(0, timeLineRef.current.offsetTop);
      } else if (error[0] == "propertyTypeRef") {
        window.scrollTo(0, propertyTypeRef.current.offsetTop);
      } else if (error[0] == "mustHaveRef") {
        window.scrollTo(0, mustHaveRef.current.offsetTop);
      } else if (error[0] == "BudgetRef") {
        window.scrollTo(0, BudgetRef.current.offsetTop);
      } else if (error[0] == 'CompensationRef') {
        window.scrollTo(0, CompensationRef.current.offsetTop);
      }else if (error[0] == "DescriptionRef") {
        window.scrollTo(0, DescriptionRef.current.offsetTop);
      }
      setDisableButton(false);
      handleFormValues({ validationErrors: error, updatedAt: Date.now() });
    }
  }

  return (
    <>
      {showUrlExpired ? 
        <ErrorMessage
          message = "The URL cannot be accessed as the link has expired."
          formValues={wantAdData}
        />
      :isValidId === 0 ? (
        <ErrorMessage message="The url is not valid" wantAdData={wantAdData} />
      ) : isValidId === 1 ? (
        <div
          class="outer-wrap outer-wrap-padding position-relative  pb-0"
          id="pageTop"
        >
         <BuyerlistingHeader
         wantAdData = {wantAdData}
         />

          <Row>
            <Col md={12}>
              <div className="edit-want-ad-wrap mw-626">
                <h2>
                  Let {toTitleCase(wantAdData?.userFirstName)} know what you’re
                  looking to buy:
                </h2>
                <h6 className="mb-40">
                  {" "}
                  The more details you provide, the better!
                </h6>

                <div className="">
                  <h6>Your Name</h6>
                  <div className="center-content-sec mw-100 mt-0 mb-48">
                    <div className=" wantad-location-wrap mb-3">
                      <Row>
                        <Col md={6}>
                          <div className="common-input-wrap mb-2">
                            <label className="form-label">
                              First Name<span>*</span>
                            </label>
                            <TextBox
                              id="fName"
                              name="firstName"
                              type="text"
                              maxLength={30}
                              value={toTitleCase(firstName)}
                              placeholder="First name"
                              className="form-control"
                              onChange={(e) => handleFirstName(e.target.value)}
                              errorClass="error-validation-msg"
                              errorMessage={firstNameError}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="common-input-wrap mb-2">
                            <label className="form-label">
                              Last Name<span>*</span>
                            </label>
                            <TextBox
                              id="lName"
                              name="lastName"
                              maxLength={30}
                              value={toTitleCase(lastName)}
                              type="text"
                              placeholder="Last name"
                              className="form-control"
                              errorClass="error-validation-msg"
                              onChange={(e) => handleLastName(e.target.value)}
                              errorMessage={lastNameError}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className={
                        locationRadiusError &&
                        "wantad-location-wrap select-type-wrap red-marked-wrap"
                      }
                      ref={locationRef}
                    >
                      <h6
                        className={
                          (locationCityError || locationRadiusError) &&
                          "red-marked-heading"
                        }
                      >
                        Location{" "}
                      </h6>
                      <WantAdLocation
                        handleFormValues={handleFormValues}
                        location={_.get(wantAdData, "location", null)}
                        s3Imagefolder={_.get(
                          wantAdData,
                          "s3Imagefolder",
                          false
                        )}
                        lat={_.get(wantAdData, "lat", null)}
                        lng={_.get(wantAdData, "lng", null)}
                        mapZoom={_.get(wantAdData, "mapZoom", null)}
                        mapRadius={_.get(wantAdData, "mapRadius", null)}
                        neighborHood={_.get(wantAdData, "neighborHood", null)}
                        highSchool={_.get(wantAdData, "highSchool", null)}
                        zip={_.get(wantAdData, "zip", null)}
                        adType={_.get(wantAdData, "adType", null)}
                        city={_.get(wantAdData, "city", null)}
                        state={_.get(wantAdData, "state", null)}
                        county={_.get(wantAdData, "county", null)}
                        session={AGENT_WANTAD_KEY}
                        targetRef={targetRef}
                        isGitchaAvailable={_.get(
                          wantAdData,
                          "isGitchaAvailable",
                          null
                        )}
                        locationCityError={locationCityError}
                        locationRadiusError={locationRadiusError}
                        isFromBuyersListingService={true}
                        client_Want_ad_Id={_.get(
                          wantAdData,
                          "client_Want_ad_Id",
                          null
                        )}
                        zipCode={_.get(wantAdData, "zipCode", null)}
                        zipIdValues={_.get(wantAdData, "zipIdValues", null)}
                        zipCodeError={zipCodeError}
                        setLocationCityError={setLocationCityError}
                        setLocationError={setLocationError}
                        locationError={locationError}
                      />
                    </div>

                    <div
                      className="wantad-type-wrap select-type-wrap red-marked-wrap"
                      ref={timeLineRef}
                    >
                      <h6 className={timeLineError && "red-marked-heading"}>
                        Timeline <span>*</span>
                      </h6>
                      <WantAdTiming
                        handleFormValues={handleFormValues}
                        timeLine={_.get(wantAdData, "timeLine", null)}
                        adType={_.get(wantAdData, "adType", null)}
                        timeLineError={timeLineError}
                      />
                    </div>

                    <div
                      className={
                        propertyTypeError
                          ? "wantad-type-wrap select-type-wrap red-marked-wrap"
                          : "wantad-type-wrap select-type-wrap"
                      }
                      ref={propertyTypeRef}
                    >
                      <h6 className={propertyTypeError && "red-marked-heading"}>
                        Property type <span>*</span>
                      </h6>
                      <WantAdType
                        handleFormValues={handleFormValues}
                        propertyType={_.get(wantAdData, "prprtyType", "")}
                        adType={_.get(wantAdData, "adType", "")}
                        clearPreviousStates={clearPreviousStates}
                        propertyTypeError={propertyTypeError}
                        prptySubTypes={_.get(wantAdData, "prptySubTypes", [])}
                      />
                    </div>

                    {wantAdData?.prprtyType && (
                      <div
                        className={
                          requirementTextColor
                            ? "requirements-wrap red-marked-wrap mb-3"
                            : "requirements-wrap mb-3"
                        }
                        ref={mustHaveRef}
                      >
                        <h6>Requirements</h6>

                        {wantAdData?.prprtyType != IS_LAND ? (
                          <WantAdMustHaves
                            handleFormValues={handleFormValues}
                            noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                            noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                            SFMin={_.get(wantAdData, "sqFtMin", null)}
                            SFMax={_.get(wantAdData, "sqFtMax", null)}
                            garageSpace={_.get(wantAdData, "grgSpce", null)}
                            yearBuiltMin={_.get(wantAdData, "yrBltMin", null)}
                            yearBuildMax={_.get(wantAdData, "yrBltMax", null)}
                            mustHaveErrors={_.get(
                              wantAdData,
                              "validationErrors",
                              null
                            )}
                            stories={_.get(wantAdData, "stories", null)}
                            yearBuiltOption={_.get(
                              wantAdData,
                              "yearBuiltOption",
                              null
                            )}
                            minLtSz={_.get(wantAdData, "minLtSz", null)}
                            maxLtSz={_.get(wantAdData, "maxLtSz", null)}
                            adaAccess={_.get(wantAdData, "adaAccess", null)}
                            requirementBedroomsError={requirementBedroomsError}
                            requirementBathroomsError={
                              requirementBathroomsError
                            }
                            propertyType={wantAdData?.prprtyType}
                          />
                        ) : (
                          <WantAdLandMustHaves
                            handleFormValues={handleFormValues}
                            lotType={_.get(wantAdData, "lotType", null)}
                            roadType={_.get(wantAdData, "roadType", null)}
                            isPrivtDrilInstld={_.get(
                              wantAdData,
                              "isPrvtDrInst",
                              null
                            )}
                            onSiteElec={_.get(wantAdData, "onSiteElec", null)}
                            waterSupply={_.get(wantAdData, "waterSupply", null)}
                            isDrldWelInstld={_.get(
                              wantAdData,
                              "isDrldWelInst",
                              null
                            )}
                            wasteWtrSys={_.get(wantAdData, "wasteWtrSys", null)}
                            isSepTankInstld={_.get(
                              wantAdData,
                              "isSepTankInst",
                              null
                            )}
                            isSepTankApprvd={_.get(
                              wantAdData,
                              "isSepTankAppr",
                              null
                            )}
                            minLtSz={_.get(wantAdData, "minLtSz", null)}
                            maxLtSz={_.get(wantAdData, "maxLtSz", null)}
                            mustHaveErrors={_.get(
                              wantAdData,
                              "validationErrors",
                              null
                            )}
                            propertyType={wantAdData?.prprtyType}
                          />
                        )}
                      </div>
                    )}

                    {/* ------------------------------------------Nice-to-haves------------------------------------------ */}
                    {wantAdData?.prprtyType && (
                      <NiceToHave
                        selectedNiceToHave={wantAdData?.nice2Have}
                        // niceToHavesList={niceToHavesList}
                        selectedniceToHavesList={selectedniceToHavesList}
                        propertyType={wantAdData?.prprtyType}
                        adType={wantAdData?.adType}
                        handleFormValues={handleFormValues}
                        title="Nice-to-haves"
                      />
                    )}

                    {/* ------------------------------------------Finance/Purchase type ------------------------------------------ */}
                    {wantAdData?.prprtyType && (
                      <div
                        className={
                          budgetError
                            ? "finance-wrap red-marked-wrap pb-24"
                            : "finance-wrap pb-24"
                        }
                        ref={BudgetRef}
                      >
                        {/* <h6 className={budgetError && "red-marked-heading"}>Finance</h6> */}
                        <h6>Finance</h6>
                        <WantAdBudget
                          handleFormValues={handleFormValues}
                          budgetMin={_.get(wantAdData, "minBdgt", null)}
                          budgetMax={_.get(wantAdData, "maxBdgt", null)}
                          isAdjustBdget={_.get(
                            wantAdData,
                            "isAdjustBdget",
                            null
                          )}
                          isPreApprLoan={_.get(
                            wantAdData,
                            "isPreApprLoan",
                            null
                          )}
                          adType={wantAdData?.adType}
                          agentCompenation={_.get(
                            wantAdData,
                            "agentCompenation",
                            null
                          )}
                          errors={_.get(wantAdData, "validationErrors", null)}
                        />
                        {/* {(wantAdData?.adType == BUY ||
                          wantAdData?.prprtyType == IS_LAND) && (
                          <div
                            className={
                              compensationError
                                ? "finance-wrap red-marked-wrap pb-24"
                                : "finance-wrap pb-24"
                            }
                            ref={CompensationRef}
                          >
                            <AgentCompensation
                              handleFormValues={handleFormValues}
                              agentCompenation={_.get(
                                wantAdData,
                                "agentCompenation",
                                null
                              )}
                              compenationType={parseInt(
                                _.get(wantAdData, "compenationType", null)
                              )}
                              errors={_.get(
                                wantAdData,
                                "validationErrors",
                                null
                              )}
                            />
                          </div>
                        )} */}

                        <div className="wantad-type-wrap select-type-wrap pb-2">
                          {/* {(wantAdData?.adType == BUY) && */}
                          <>
                            <label className="form-label mb-12">
                              Purchase Type
                            </label>
                            <WantAdPropertyPurchase
                              handleFormValues={handleFormValues}
                              propertyPurchase={
                                _.get(wantAdData, "prchTypeId", null) ||
                                _.get(wantAdData, "purchaseType", null)
                              }
                              is1031Exchange={_.get(
                                wantAdData,
                                "is1031Exchange",
                                null
                              )}
                              isEdit="true"
                            />
                          </>
                          {/* }                     */}
                          {/* {(wantAdData?.adType == BUY) && */}
                          <>
                            <label className="form-label">Financing</label>
                            <WantAdPurchaseFinance
                              handleFormValues={handleFormValues}
                              timeLine={_.get(wantAdData, "timeLine", null)}
                              isFromBuyersListingService={true}
                              financeType={
                                _.get(wantAdData, "finTypeId", null) ||
                                _.get(wantAdData, "pFinance", null)
                              }
                              isPreApprLoan={_.get(
                                wantAdData,
                                "isPreApprLoan",
                                null
                              )}
                              agreePreApprovedLoan={_.get(
                                wantAdData,
                                "agreePreApprovedLoan",
                                null
                              )}
                              errors={_.get(
                                wantAdData,
                                "validationErrors",
                                null
                              )}
                            />
                          </>
                          {/* } */}
                        </div>
                      </div>
                    )}
                    {/* ------------------------------------------/Finance/Purchase type------------------------------------------ */}

                    {/* ------------------------------------------Description------------------------------------------ */}

                    {wantAdData?.prprtyType && (
                      <div
                        className={
                          titleTextBoxError || descriptionError
                            ? "description-wrap red-marked-wrap"
                            : "description-wrap"
                        }
                        ref={DescriptionRef}
                      >
                        <h6
                          className={
                            titleTextBoxError ||
                            (descriptionError && "red-marked-heading")
                          }
                        >
                          Description
                        </h6>
                        <WantAdDescription
                          handleFormValues={handleFormValues}
                          adTitle={_.get(wantAdData, "adTitle", "")}
                          adDescription={_.get(wantAdData, "adDesc", "")}
                          errors={_.get(wantAdData, "validationErrors", null)}
                          titleTextBoxError={titleTextBoxError}
                          descriptionError={descriptionError}
                          isFromBuyersListingService={true}
                        />
                      </div>
                    )}
                    {/* ------------------------------------------/Description------------------------------------------ */}
                  </div>
                </div>

                <div className="edit-want-ad-btnwrap  edit-want-ad-btnwrap01 mw-626 mt-4">
                  <button
                    type="button"
                    className="btn btn btn-primary"
                    disabled={disableButton}
                    onClick={() => handleSave("save")}
                  >
                    Send to my agent
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BuyerListingCreateWantAdForm;
