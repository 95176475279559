import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import TextArea from "../Common/TextArea";
import { handleTextBoxChange } from "../../actions/common";
import AdIntroIcon from "../../images/ad-intro-icon.svg";
import VisaIcon from "../../images/visa-icon.svg";
import MastercardIcon from "../../images/master-card-icon.svg";
import PaypalIcon from "../../images/paypal-icon.svg";
import AddPropertyIcon from "../../images/add-property-plus-icon.svg";
import HouseImgIcon from "../../images/house-img.png";
import FireWorkImgTop from "../../images/party-popper.png";
import FireWorkImg from "../../images/fireworks-overly.svg";
import CrackersImg from "../../images/crackers-overlay.png";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";


const SuccessModal = (props) => {
  const navigate = useNavigate();
  
  return (
    <Modal
      show
      centered
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      
      className="modal-width-372"
      
    >
      
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="pt-100 pb-32 pl-30 pr-30">
        
        <div className="fireworks-overlay-top top-82">
           <div class="pyro pyro-left-top">
             <div class="before"></div>
             <div class="after"></div>
           </div>
          <img className="mw-48" src={FireWorkImgTop} width={'48px'} height={'48px'}/>
        </div>
        {/*<div
          className="fireworks-overlay top-65">
            
        
          <img src={CrackersImg} />
        </div>*/}
        
        
        <h3 className="text-center mt-5">
         
          Introduction sent
           
        </h3>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-5 f-size-16 text-center">
              
            We'll keep you updated on your introduction status. When they accept it, they'll have 72 hours to send you a message. Good luck!
                
              
            </p>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
            <Button
               variant="primary"
               className="btn w-100"
               onClick={()=>{ navigate(pageURLs.dashboard)}} 
             >
              Done
               
             </Button>
             
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default SuccessModal;
