import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { handleTextBoxChange } from "../../../actions/common";
import { getLocalStorage, setBodyBackgroundWhite, setLocalStorage, trimString } from "../../../helpers/common";
import { textValidations } from "../../../helpers/validations";
import { BUY, ERROR_TEXT, IS_LAND, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import ProgressBar from "../ProgressBar";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfoIcon from "../../components/infoIcn";
import Tooltip from 'react-bootstrap/Tooltip';
import TextAreaMob from "../TextAreaMob";
import TextBoxMob from "../TextBoxMob";
const AddTitle = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isEdit,
    isFromBuyerListingService,
    isAgent
  } = props;
  const adTitle = _.get(formValues, "adTitle", "");
  const adTitleInSession = _.get(getLocalStorage(WANT_AD_KEY), "adTitle", "");
  const adDescription = _.get(formValues, "adDesc", "");
  const adDescriptionInSession = _.get(getLocalStorage(WANT_AD_KEY), "adDesc", "");
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const [titleError, setTitleError] = useState(null);
  const [descError, setDescError] = useState(null);

  const [disableSave, setDisableSave] = useState(true)

  useEffect(() => {
    setTitleCharCount(adTitle && adTitle.length ? adTitle.length : 0);
    setDescCharCount(
      adDescription && adDescription.length ? adDescription.length : 0
    );
    let isTitleValid = true;
    let isDescValid = true;
    if (!adTitle) {
      isTitleValid = false;
    }
    if (trimString(adTitle) !== "") {
      isTitleValid = textValidations(adTitle);
      if (!isTitleValid) {
        setTitleError(ERROR_TEXT);
      }
    }
    if (trimString(adDescription) !== "") {
      isDescValid = textValidations(adDescription);
      if (!isDescValid) {
        setDescError(ERROR_TEXT);
      }
    }

    if (isDescValid && isTitleValid) {
      if(isEdit && adDescription === adDescriptionInSession && adTitle === adTitleInSession){
        disableContinueButton();
      }
      else {setDisableSave(false);}
    } else {
      disableContinueButton();
    }
  }, [adTitle, adDescription, titleError, descError]);

  const disableContinueButton = () => {
    const isDisabled = true;
    setDisableSave(isDisabled);
  };

  const handleTitleChange = (e) => {
    setTitleError(null);
    const value = capitalizeFirstString(handleTextBoxChange(e));
    setTitleCharCount(value.length);
    if (trimString(value) !== "") {
      handleFormValues({ adTitle: value });
    } else {
      handleFormValues({ adTitle: "" });
    }
  };

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDescriptionChange = (e) => {
    setDescError(null);
    const value = capitalizeFirstString(handleTextBoxChange(e));

    setDescCharCount(value.length);
    if (trimString(value) !== "") {
      handleFormValues({ adDesc: value });
    } else {
      handleFormValues({ adDesc: "" });
    }
  };
  useEffect(() => {
    setBodyBackgroundWhite()
   }, []);

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-100-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 h-100">
            <div className="content-btm-sec-title">
              <h6 className="">Description</h6>
              <h2 className="">Give your ad some personality</h2>
              <p className="fnt14 pt-3 ">
                Users who add a description to their ads are more likely to have
                higher quality introductions.
              </p>
            </div>

            <div className="mb-4 ">
              <div className="row">
                <div className="col-12 ">
                  <div className="mb-4 position-relative">
                    <div className="mob-fld-wrap">
                      <label className="form-label">
                        Title <span>*</span>
                      </label>

                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip placement="bottom">
                            "In accordance with the Federal Fair Housing Act, we recommend not including personally identifiable information, such as race, gender, sexual preference or age. To learn more, <a href='https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations' target='_blank'>read about the Fair Housing Act here.</a>"
                          </Tooltip>
                        }
                      >
                        <a href={void 0}>
                          <InfoIcon />
                        </a>
                      </OverlayTrigger>
                    </div>
                      <TextBoxMob
                        placeholder="Ex. Looking for dream home in city"
                        value={adTitle ? adTitle : ""}
                        errorMessage={titleError}
                        onChange={(e) => {
                            handleTitleChange(e);
                          }}
                          maxLength={50}

                      ></TextBoxMob>
                    <div className="text-end fnt10 pt-0 des-count-mob des-count-74">{titleCharCount}/50</div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="mb-4 position-relative">
                    <div className="mob-fld-wrap">
                      <label className="form-label">
                        Description
                      </label>
                    </div>
                    {/* <div className="search_field_input  mb-0 input-group"> */}
                      <TextAreaMob
                        // class="form-control txtAreaStyle"
                        value={adDescription ? adDescription : ""}
                        errorMessage={descError}
                        maxLength={500}
                        placeholder="Ex. My family and I have been on the hunt for our dream home in a walkable neighborhood..."
                        onChange={(e) => {
                        handleDescriptionChange(e);
                        }}
                      ></TextAreaMob>
                    {/* </div> */}
                    <div className="text-end fnt10 pt-1 des-count-mob des-count-157">{descCharCount}/500</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                onClick={()=>{
                  setCompletedPercentage(100);
                  setTimeout(()=>{
                    setLocalStorage(WANT_AD_KEY, getLocalStorage(WANT_AD_KEY_TEMP));
                    if(!isEdit){                      
                        if(isAgent &&  (( _.get(formValues, "adType", "") == BUY ||  _.get(formValues, "prprtyType", "") == IS_LAND))){
                          setUIKey("compensation")
                        } 
                         else{
                          setUIKey("readyToPost")
                        }
                    }
                    else { setUIKey(null)}
                  },500)
                  
                }}
                disabled={disableSave}
              >
                {isEdit ? "Update" : isAgent ? "Next" : "Review & Post"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTitle;
