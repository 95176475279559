import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import TextArea from "../Common/TextArea";
import { handleTextBoxChange } from "../../actions/common";
import { ERROR_TEXT } from "../../constants/common";
import { emailValidation, textValidations } from "../../helpers/validations";


const AddNewClientModal = ({ addClient, onHide, showAddToClientWantAdModal, fromAddToClientWantAdModal, ...props }) => {
  const initialData = {
    firstName: "",
    lastName: "",
    info: ''
  };

  //form States
  const [formData, setFormData] = useState(initialData);
  const [formErrorMessage, setFormErrorMessage] = useState(initialData);
  const [disableButton, setDisableButton] = useState(true);
  const [descError, setDescError] = useState(null);

  useEffect(()=>{
    document.body.classList.add("bg-modal-fix");
  },[])
  /**
  * To handle textbox value change
  * @param {any} event 
  */
  const onChangeText = (event) => {
    const value = event.target.name === 'email' ? handleTextBoxChange(event) :
      capitalizeFirstString(handleTextBoxChange(event))
    setFormData(formData => ({ ...formData, [event.target.name]: value }));    
    if (event.target.name == 'firstName') {
      delete formErrorMessage.firstName;
    }
    if (event.target.name == 'lastName') {
      delete formErrorMessage.lastName;
    }
    if (event.target.name == 'info') {
      setDescError(null)

    }
    // setFormErrorMessage({...formErrorMessageData})
    checkSubmitButtonEnableStatus({ ...formData, [event.target.name]: value })
  }

  const checkSubmitButtonEnableStatus = (data) => {
    if ((data.firstName || data.firstName.trim() != "") ) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }


  /**
   * to validate add client form
   * @param {json} data 
   * @returns json
   */
  const validateForm = (data) => {
    let errors = {};
    if (!data.firstName || data.firstName.trim() == "") {
      errors.firstName = 'Enter First Name';
    }
    
    if (data.email) {

      if (!emailValidation(data.email.trim())) {
        errors.email = 'Enter Valid Email';
      }

    }

    const bio = data.info
    if (bio) {
      if (!textValidations(bio)) {
        errors.info = ERROR_TEXT
        setDescError(ERROR_TEXT)
      }
    }

    return errors;
  };

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const submitForm = (formData) => {
    let error = validateForm({ ...formData })
    setFormErrorMessage(error);
    if (Object.keys(error).length == 0) {
      setDisableButton(false)
      addClient(formData)
    } else {
      setDisableButton(true)
    }
  }


  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={() => {
        setFormData(initialData)
        setFormErrorMessage('')
        setDisableButton(true)
        setDescError(null)
      }}
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            onHide();
            if(fromAddToClientWantAdModal){ 
              showAddToClientWantAdModal()}
          }
          }

        ></button>
        <h3 className="text-start">Add new client</h3>
        <p className="f-size-16 mb-4">All client information is private to you only.</p>
        <Row>
          <Col xl={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">First name<span>*</span></label>
              {/* <input
                placeholder="First name"
                type="text"
                className="form-control"
                onBlur={handleFirstNamelOnBlur}
              ></input> */}

              <TextBox
                id="fName"
                name="firstName"
                type="text"
                value={formData.firstName}
                placeholder="First"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.firstName}
                maxLength={35}

              />

            </div>
          </Col>

          <Col xl={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Last name</label>              

              <TextBox
                id="lName"
                name="lastName"
                type="text"
                value={formData.lastName}
                placeholder="Last"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.lastName}
                maxLength={35}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Client's Email</label>
              <TextBox
                id="email"
                name="email"
                type="text"
                value={formData.email}
                placeholder="Email"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.email}
                maxLength={35}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-4 mb-sm-4">
              <label className="form-label">Client Bio</label>
              <TextArea
                name='info'
                className="form-control txt-area"
                errorMessage={descError}
                value={formData.info}
                maxLength={5000}
                placeholder="Additional info about client"
                onChange={(e) => {
                  onChangeText(e);
                }}
              />

              {/* <textarea class="form-control modal-txtarea" placeholder="Additional info about client"></textarea> */}
              {/* <div className="des-count align-self-end">0/5000</div> */}
            </div>
          </Col>
        </Row>



        <Button
          variant="primary"
          className="btn w-100"
          onClick={() => {
            submitForm(formData)
            // addClient(formData)
          }}
          disabled={disableButton} >
          Confirm
        </Button>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default AddNewClientModal;
