
import InventoryTimeLine from "./InventoryTimeLine";
import InventoryDocuments from "./InventoryDocuments";
import { checkInventoryType } from "../../../helpers/inventory";

const InventoryMemberStrengthen = (props) => {
  const {
    step,
    handleContinueButtonState,
    handleFormValues,
    formValues
  } = props;
  const selectedPurposes = _.get(formValues, 'selectedPurposes', [])
  const invType = checkInventoryType(selectedPurposes);
  switch (step) {
    case 1:
      return (
        <InventoryDocuments
        handleContinueButtonState={handleContinueButtonState}
        handleFormValues={handleFormValues}
        apprOnFile={_.get(formValues, "apprOnFile", null)}
        insReport={_.get(formValues, "insReport", null)}
        sellerFin={_.get(formValues, "sellerFin", null)}
        floorPlan={_.get(formValues, "floorPlan", null)}
        floodCert={_.get(formValues, "floodCert", null)}
        assMortgage={_.get(formValues, "assMortgage", null)}
        invType = {invType}
      />
      );

    case 2:
      return (
        <InventoryTimeLine
        handleContinueButtonState={handleContinueButtonState}
        handleFormValues={handleFormValues}
        timeLine={_.get(formValues, "timeLine", null)}
        invType = {invType}
      />
      
      );
  }
};
export default InventoryMemberStrengthen;
