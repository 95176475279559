/**
 * @file   src\components\MemberWantAd\EditWantAd\WantAdMustHaves.js
 * @brief  This file is responsible for handling wantad must haves in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, FormLabel } from "react-bootstrap";
import { handleTextBoxChange, handleRadioButonChange } from "../../../actions/common";
import {
  checkCharLength
} from "../../../helpers/validations";
import TextBox from "../../Common/TextBox";
import ButtonGroups from "../../Common/ButtonGroups";
import SqareFeet from "../../AgentWantAd/SqareFeet";
import WantAdLotSize from "../../Common/WantAdLotSize";

const WantAdMustHaves = (props) => {
  const {
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    SFMin,
    SFMax,
    garageSpace,
    yearBuiltMin,
    yearBuildMax,
    mustHaveErrors,
    stories,
    yearBuiltOption,
    minLtSz,
    maxLtSz,
    adaAccess,
    focusTextBox,
  requirementBedroomsError,
   requirementBathroomsError,
   propertyType
  } = props;

  const [yrBuiltMin, setYearBuiltMin] = useState();
  const [yearBuiltMinError, setYearBuiltMinError] = useState("");
  const [yrBuiltMax, setYearBuiltMax] = useState();
  const [yearBuiltMaxError, setYearBuiltMaxError] = useState("");
  const [isDisableYearBuildMin, setMinYearDisable] = useState(false);
  const [isDisableYearBuildMax, setMaxYearDisable] = useState(false);
  const [gSpace, setGSpace] = useState("");

  const YEAR_BUILT_MIN = 1900;
  const YEAR_BUILT_MAX = new Date().getFullYear();

  const STORY_ANY = 1;
  const STORY_SINGLE = 2;
  const YEAR_BUILT_RANGE = 1;
  const NEW_CONSTRUCTION = 2;

  const yearBuiltMinDateRef = useRef();
  const yearBuiltMaxDateRef = useRef();
  // const lotSizeMaxLesserRef = useRef();

  useEffect(() => {
    setYearBuiltMin(yearBuiltMin);
    setYearBuiltMax(yearBuildMax);
    clearGarrageLocalState()
  }, []);


  useEffect(() => {
   
    if (mustHaveErrors && mustHaveErrors.length > 0) {
      if(mustHaveErrors[0] == 'yearBuiltMinDate' || mustHaveErrors[0] == 'yearBuiltMaxDate'){
   
        mustHaveErrors[0] == 'yearBuiltMinDate' && yearBuiltMinDateRef.current.focus();
        mustHaveErrors[0] == 'yearBuiltMaxDate' && yearBuiltMaxDateRef.current.focus();
      } else if(mustHaveErrors[0] === 'yearBuiltMaxRange' || mustHaveErrors[0] === 'yearBuiltMinRange'){
        mustHaveErrors[0] == 'yearBuiltMaxRange' && yearBuiltMaxDateRef.current.focus();
        mustHaveErrors[0] == 'yearBuiltMinRange' && yearBuiltMinDateRef.current.focus();
      } else if(mustHaveErrors[0] == 'yearBuiltError'){
        yearBuiltMaxDateRef.current.focus();
      } 
      else if(mustHaveErrors[1] == 'yearBuiltError'){
        window.scrollTo(0, yearBuiltMinDateRef.current.offsetTop);
      }
      // else if(mustHaveErrors[0] == 'lotSizeMaxLesser'){
      //   lotSizeMaxLesserRef.current.focus();
      // }

      
      mustHaveErrors.map((item) => {
        if (item == 'yearBuiltMinDate' || item == 'yearBuiltMaxDate') {
        
          item == 'yearBuiltMinDate' && setYearBuiltMinError('Enter Min Year')
          item == 'yearBuiltMaxDate' && setYearBuiltMaxError('Enter Max Year')
        } else if (item === 'yearBuiltMaxRange' || item === 'yearBuiltMinRange') {
         
          item === 'yearBuiltMaxRange' && setYearBuiltMaxError(`Year should be between ${YEAR_BUILT_MIN} and ${YEAR_BUILT_MAX}`);
          item === 'yearBuiltMinRange' && setYearBuiltMinError(`Year should be between ${YEAR_BUILT_MIN} and ${YEAR_BUILT_MAX}`);
        } else if (item == 'yearBuiltError') {
        
          setYearBuiltMaxError(`Value Must be greater than minimum`);
        }
      //   if (item === 'lotSizeMaxLesser') {
         
      //     setLotSizeMaxError('Value Must be greater than minimum');
      //   }
      })
    }

  }, [mustHaveErrors]);


  const clearGarrageLocalState = () => {
    setGSpace("")
  }

  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
    clearGarrageLocalState();
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
    clearGarrageLocalState();
  };

  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    setGSpace(value)
    handleFormValues({ grgSpce: value });
  };

  const handleYearBuiltMinchange = (e) => {

    setYearBuiltMinError("")
    const value = handleTextBoxChange(e);
    if (checkCharLength(value, 4)) {
      setYearBuiltMin(value);
      handleFormValues({ yrBltMin: value });
    }
    clearGarrageLocalState()
  };

  const handleYearBuiltMaxchange = (e) => {
    setYearBuiltMaxError('')
    const value = handleTextBoxChange(e);
    if (checkCharLength(value, 4)) {
      setYearBuiltMax(value);
      handleFormValues({ yrBltMax: value });
    }
    clearGarrageLocalState()
  };

  const handleStorySelection = (selectedStory) => {
    if (selectedStory == stories) {
      handleFormValues({ stories: "" });
    } else {
      handleFormValues({ stories: selectedStory });
    }
  };


  const handleYearBuiltSelection = (selectedValue) => {
    setYearBuiltMinError("")
    setYearBuiltMaxError("")
    if (selectedValue === yearBuiltOption) {
      handleFormValues({
        yearBuiltOption: "",
        yearBuiltMin: "",
        yearBuildMax: "",
      });
    } else {
      handleFormValues({
        yearBuiltOption: selectedValue,
        yearBuiltMin: "",
        yearBuildMax: "",
      });
    }
  };

  const handleCheckBoxItemClick = (e) => {
    handleFormValues({ adaAccess: e.target.checked });
  };


  return (
    <>
      <div className={requirementBedroomsError ? "btn-group-wrap select-type-wrap red-marked-btngroup" : "btn-group-wrap select-type-wrap" }>
        <FormLabel className={requirementBedroomsError && "red-marked-formlabel"}>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
        />
      </div>
      <div className={requirementBathroomsError ? "btn-group-wrap select-type-wrap red-marked-btngroup" : "btn-group-wrap select-type-wrap"}>
        <FormLabel className={requirementBathroomsError  && "red-marked-formlabel"}>
          Bathrooms (min) <span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>

      <SqareFeet
        handleFormValues={handleFormValues}
        SFMax={SFMax}
        SFMin={SFMin}
        errors={mustHaveErrors} />


      <div className="btn-group-wrap select-type-wrap">
        <FormLabel>Garage Spaces</FormLabel>

        <ButtonGroups
          variant="outline-primary"
          section="Garage"
          value={garageSpace}
          currentSelectedValue={gSpace}
          items={["Any", "1+", "2+", "3+"]}
          onClick={(e) => handleGarageClick(e)}
          handleFormValues={handleFormValues}
        />
      </div>

      <div className="btn-group-btm-wrap mb-24">
        <FormLabel>Stories</FormLabel>
        <ButtonGroup aria-label="Basic example">
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_ANY)}
            variant={
              stories === STORY_ANY
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Any
          </Button>
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_SINGLE)}
            variant={
              stories === STORY_SINGLE
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Single Story Only
          </Button>
        </ButtonGroup>
      </div>


      <div className="wantad-timing-wrap pb-0">
      <WantAdLotSize
          handleFormValues={handleFormValues}
          mnLotSze={minLtSz}
          mxLotSze={maxLtSz}
          lotSizeError={mustHaveErrors}
          isFromLongForm={true}
          propertyType = {propertyType}
          // errorUpdatedAt = { errorUpdatedAt}
          />

        {/* <div className="lotsize-selection-wrap">
          <h6>Lot Size</h6>
          <div className="row">
            <div className="col-md-6 pe-md-6">
              <label className="lot-select-label">Min</label>
              <div className="lot-select-wrap">
                <Select
                  id={MIN_LT_DROPDOWN}
                  name="colors3"
                  options={[{ value: null, label: "No min" }, ...lotSizeList]}
                  className="lotsize-select"
                  classNamePrefix="select"
                  placeholder="No min lot size"
                  value={filterLotSizeArray(mnLtSzId + "_" + mnLtSzTxt)}
                  onChange={(e) => handleLotSizeSelection(e, MIN_LT_DROPDOWN)}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform:
                        state.selectProps.menuIsOpen && "rotate(180deg)",
                    }),
                  }}
                />
                {/* <span className='error-msg'>{lotSizeMinError}</span> */}
              {/* </div> */}
            {/* </div>
            <div className="col-md-6 ps-md-6">
              <label className="lot-select-label">Max</label>
              <div className="lot-select-wrap">
                <Select
                  id={MAX_LT_DROPDOWN}
                  name="colors5"
                  options={[{ value: null, label: "No max" }, ...lotSizeList]}
                  className="lotsize-select"
                  classNamePrefix="select"
                  placeholder="No max lot size"
                  value={filterLotSizeArray(mxLtSzId + "_" + mxLtSzTxt)}
                  onChange={(e) => handleLotSizeSelection(e, MAX_LT_DROPDOWN)}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform:
                        state.selectProps.menuIsOpen && "rotate(180deg)",
                    }),
                  }}
                  ref={lotSizeMaxLesserRef}
                />
                <span className='error-msg'>{lotSizeMaxError}</span>
              </div>
            </div>
          </div> */}
        {/* </div> */} 
      </div>

      <div className="range-values-wrap">
        <label className="form-label mb-12">Year Built</label>
        <div className="row">
          <div className="wantad-timing-wrap pb-2">
            <div className="col-md-12">
              <label className="radio-wrap mb-3">
                <input
                  type="radio"
                  name="radio_yearBuilt"
                  value=""
                  onClick={(e) => handleYearBuiltSelection(YEAR_BUILT_RANGE)}
                  checked={
                    yearBuiltOption && yearBuiltOption == YEAR_BUILT_RANGE
                  }
                  onChange={() => { }} //Avoid console warning
                  
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2>Set range</h2>
                  </div>
                </span>
              </label>
            </div>
            {yearBuiltOption === YEAR_BUILT_RANGE && (
              <div className="square-footage-wrap mb-1">
                <ul className="range-values-list">
                  <li>
                    <TextBox
                      type="number"
                      minValue={YEAR_BUILT_MIN}
                      maxValue={YEAR_BUILT_MAX}
                      errorMessage={yearBuiltMinError}
                      value={yrBuiltMin}
                      placeholder="Min year"
                      disabled={isDisableYearBuildMin}
                      className="form-control"
                      onChange={(e) => {
                        handleYearBuiltMinchange(e);
                      }}
                      txtRef={yearBuiltMinDateRef}
                    />
                  </li>

                  <li className="text-center">
                    <span className="middle-separator">-</span>
                  </li>
                  <li className="position-relative">
                    <TextBox
                      type="number"
                      minValue={YEAR_BUILT_MIN}
                      maxValue={YEAR_BUILT_MAX}
                      errorMessage={yearBuiltMaxError}
                      value={yrBuiltMax}
                      disabled={isDisableYearBuildMax}
                      errorClass="error-validation-msg"
                      placeholder="Max year"
                      className="form-control"
                      onChange={(e) => {
                        handleYearBuiltMaxchange(e);
                      }}
                      txtRef={yearBuiltMaxDateRef}
                    />
                  </li>
                </ul>
              </div>
            )}

            <div className="col-md-12">
              <label className="radio-wrap mb-3">
                <input
                  type="radio"
                  name="radio_yearBuilt"
                  value=""
                  onClick={(e) => handleYearBuiltSelection(NEW_CONSTRUCTION)}
                  checked={
                    yearBuiltOption && yearBuiltOption == NEW_CONSTRUCTION
                  }
                  onChange={() => { }} //Avoid console warning
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2>New construction only</h2>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="range-values-wrap">
        <label className="form-label mb-12">Additional Requirements</label>
        <div className="row">
          <div className="wantad-timing-wrap pb-0">
            <div className="col-md-12">
              <label className="check-wrap mb-3">
                <input
                  type="checkbox"
                  name="chk-mh"
                  // id={id}
                  checked={adaAccess ? true : false}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">ADA Accessible</h2>
                  </div>
                </span>
              </label>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WantAdMustHaves;
