import React, { useState, useEffect } from "react";
import NotesDeleteDropdown from "./NotesDeleteDropdown";
import { formateDate } from "../../../helpers/common";

const ClientNotesInfo = (props) => {
  const { clientId, item, deleteNote }= props;
  return (
    <div className="notes-block bg-white d-flex align-items-center justify-content-between">
        <div className="notes-content">
            <p className="date">{formateDate(_.get(item, 'CreatedOn', ''), 'mm/dd/yy')}</p>
            <p className="note m-0">
                {item.Client_Notes}
            </p>
        </div>
        <div className="social-btns-wrap">
          <NotesDeleteDropdown
            clientId={clientId}
            deleteNote={deleteNote}
            noteId={item.NoteId}
          />
        </div>
    </div>
    
  );
};
export default ClientNotesInfo;
