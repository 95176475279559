/**
 * @file   src\actions\userprofile.js
 * @brief  This file is responsible for redux actions related to userdetails
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGitchaAvailableCities } from "../services/commonServices";

export const fetchGitchaAvailableCities = createAsyncThunk(
    "locations/fetchGitchaAvailableCities",
    async (requestPayload) => {
      let resultObj = {}
      return await getGitchaAvailableCities(requestPayload).then((response)=>{
        const state = requestPayload?.state
        resultObj =  {state : state , cities : response.result};
        return resultObj;
      });
    }
  ); 