import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import BottomStepperMob from "../../components/BottomStepperMob";
import ConfirmStatusModal from "../../components/ConfirmStatusModal";
import ProgressBarInventory from "./ProgressBarInventory";
import {
  convertToNumber,
  removeNegativeInput,
} from "../../../helpers/validations";
import {
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  IS_LAND,
  IS_PRIVATE_ROOM,
  LONG_RENT,
  SELL,
  SHORT_RENT,
} from "../../../constants/common";
import TextBoxMob from "../TextBoxMob";
import {
  checkIsNumber,
  getLocalStorage,
  setBodyBackgroundWhite,
  setLocalStorage,
} from "../../../helpers/common";

const AddPropertyUse = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    isAgent,
    completedPercentage,
    setCompletedPercentage,
    clearPreviousStates,
    isEdit,
  } = props;

  const [showEditClient, setShowEditClient] = useState(false);
  const EditClientClose = () => setShowEditClient(false);
  const EditClientShow = () => setShowEditClient(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const DeleteModalClose = () => setShowDeleteModal(false);
  const DeleteModalShow = () => setShowDeleteModal(true);
  const PauseModalClose = () => setShowPauseModal(false);
  const PauseModalShow = () => setShowPauseModal(true);
  const propertyPurpose = _.get(formValues, "proprtyPurpose", "");
  const propertyPurposeInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "proprtyPurpose",
    ""
  );
  const alwLrgDogs = _.get(formValues, "alwLrgDogs", null);
  const alwLrgDogsInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "alwLrgDogs",
    null
  );
  const alwSmlDogs = _.get(formValues, "alwSmlDogs", null);
  const alwSmlDogsInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "alwSmlDogs",
    null
  );
  const alwCats = _.get(formValues, "alwCats", null);
  const alwCatsInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "alwCats",
    null
  );
  const alwPets = _.get(formValues, "alwPets", null);
  const alwPetsInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "alwPets",
    null
  );
  const accsPvtBthRm = _.get(formValues, "accsPvtBthRm", null);
  const accsPvtBthRmInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "accsPvtBthRm",
    null
  );
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", null));
  const isConfirmAll = _.get(formValues, "isConfirmAll", false);

  const isConfirmOwner = _.get(formValues, "isConfirmOwner", null);
  const isConfirmPropLoc = _.get(formValues, "isConfirmPropLoc", null);
  const pvtEntrnce = _.get(formValues, "pvtEntrnce", null);
  const pvtEntrnceInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "pvtEntrnce",
    null
  );
  const noOfBeds = _.get(formValues, "noOfBeds", null);
  const noOfBedsInSession = _.get(
    getLocalStorage(INVENTORY_KEY),
    "pvtEntrnce",
    null
  );
  const [disableSave, setDisableSave] = useState(true);
  const [enableConfirmButton, setConfirmButtonState] = useState(false);
  const [showConfirmStatuModal, setConfirmStatus] = useState(false);

  const alwLrgDogsChkBx = "alwLrgDogs";
  const alwsmlDogsChkBx = "alwSmlDogs";
  const alwCatsChkBx = "alwCats";
  const alwPetsChkBx = "alwPets";
  const accsPvtBthRmchkBx = "accsPvtBthRm";
  const pvtEntrnceChkBx = "pvtEntrnce";
  const confirmOwnerChkBx = "isConfirmOwner";
  const confirmPropLocChkBx = "isConfirmPropLoc";

  const is_Sell_Selected = _.includes(propertyPurpose, SELL);
  const is_LTR_Selected = _.includes(propertyPurpose, LONG_RENT);
  const is_STR_Selected = _.includes(propertyPurpose, SHORT_RENT);

  const LTR_OPTION_DETAILS = [
    { id: alwLrgDogsChkBx, text: "Allows large dogs" },
    { id: alwsmlDogsChkBx, text: "Allows small dogs" },
    { id: alwCatsChkBx, text: "Allows cats" },
  ];
  const STR_OPTION_DETAILS = [
    { id: alwPetsChkBx, text: "Allows pets" },
    { id: accsPvtBthRmchkBx, text: "Access to private bath" },
    { id: pvtEntrnceChkBx, text: "Private entrance" },
  ];
  const STR_OPTIONS_PVT_ROOM = [{ id: alwPetsChkBx, text: "Allows pets" }];

  const handleConfirmationModal = (modalState) => {
    setConfirmStatus(modalState);
  };

  const handleContinueButton = () => {
    let isDisable = true;
    if (propertyPurpose && propertyPurpose.length > 0) {
      if (is_STR_Selected && propertyType != 6) {
        if (noOfBeds) {
          isDisable = false;
        } else {
          isDisable = true;
        }
      } else {
        isDisable = false;
      }
    } else {
      isDisable = true;
    }
    setDisableSave(isDisable);
  };

  useEffect(() => {
    const condition =
      propertyPurpose.length > propertyPurposeInSession.length
        ? _.differenceWith(propertyPurpose, propertyPurposeInSession, _.isEqual)
            .length <= 0
        : _.differenceWith(propertyPurposeInSession, propertyPurpose, _.isEqual)
            .length <= 0;
    if (
      isEdit &&
      condition &&
      convertToNumber(noOfBeds) === convertToNumber(noOfBedsInSession) &&
      convertToNumber(alwLrgDogs) == convertToNumber(alwLrgDogsInSession) &&
      convertToNumber(alwSmlDogs) == convertToNumber(alwSmlDogsInSession) &&
      convertToNumber(alwCats) == convertToNumber(alwCatsInSession) &&
      convertToNumber(alwPets) == convertToNumber(alwPetsInSession) &&
      convertToNumber(accsPvtBthRm) == convertToNumber(accsPvtBthRmInSession) &&
      convertToNumber(pvtEntrnce) == convertToNumber(pvtEntrnceInSession)
    ) {
      setDisableSave(true);
    } else {
      handleContinueButton();
    }
  }, [
    propertyPurpose,
    noOfBeds,
    alwLrgDogs,
    alwSmlDogs,
    alwCats,
    alwPets,
    accsPvtBthRm,
    pvtEntrnce,
  ]);

  // useEffect(() => {
  //   if(!isEdit){handleContinueButton()}
  // }, [propertyPurpose, noOfBeds]);

  useEffect(() => {
    let isDisable = true;
    if (_.includes(propertyPurpose, SHORT_RENT)) {
      if (isConfirmOwner && isConfirmPropLoc) {
        isDisable = false;
        setConfirmButtonState(isDisable);
      } else {
        isDisable = true;
        setConfirmButtonState(isDisable);
      }
    } else {
      if (isConfirmOwner) {
        isDisable = false;
        setConfirmButtonState(isDisable);
      } else {
        isDisable = true;
        setConfirmButtonState(isDisable);
      }
    }
  }, [isConfirmOwner, isConfirmPropLoc]);

  const handlePropertyPurposeSelection = (e) => {
    const selectedValue = e.target.value;
    if (!e.target.checked && selectedValue === SELL) {
      clearPreviousStates({ type: "clearSell" });
    }
    if (!e.target.checked && selectedValue === LONG_RENT) {
      clearPreviousStates({ type: "clearPropertyPurposeLTR" });
    } else if (!e.target.checked && selectedValue === SHORT_RENT) {
      clearPreviousStates({ type: "clearPropertyPurposeSTR" });
    }

    const purposeArray = propertyPurpose || [];
    if (selectedValue) {
      let itemArray;
      if (_.includes(purposeArray, selectedValue)) {
        itemArray = purposeArray.filter((e) => e !== selectedValue);
      } else {
        itemArray = [...purposeArray, selectedValue];
      }
      handleFormValues({
        proprtyPurpose: itemArray,
        isConfirmAll: false,
        isConfirmOwner: false,
        isConfirmPropLoc: false,
      });
    }
    setConfirmButtonState(true);
  };

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (id) {
      case alwLrgDogsChkBx:
        handleFormValues({ alwLrgDogs: e.target.checked });
        break;
      case alwsmlDogsChkBx:
        handleFormValues({ alwSmlDogs: e.target.checked });
        break;
      case alwCatsChkBx:
        handleFormValues({ alwCats: e.target.checked });
        break;
      case alwPetsChkBx:
        handleFormValues({ alwPets: e.target.checked });
        break;
      case accsPvtBthRmchkBx:
        handleFormValues({ accsPvtBthRm: e.target.checked });
        break;
      case confirmOwnerChkBx:
        handleFormValues({ isConfirmOwner: e.target.checked });
        break;
      case confirmPropLocChkBx:
        handleFormValues({ isConfirmPropLoc: e.target.checked });
        break;
      case pvtEntrnceChkBx:
        handleFormValues({ pvtEntrnce: e.target.checked });
        break;
    }
  };

  const loadCheckBoxItems = (list) => {
    const List = list.map((pt) => {
      const id = _.get(pt, "id", "");
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case alwLrgDogsChkBx:
          isChecked = alwLrgDogs;
          break;
        case alwsmlDogsChkBx:
          isChecked = alwSmlDogs;
          break;
        case alwCatsChkBx:
          isChecked = alwCats;
          break;
        case alwPetsChkBx:
          isChecked = alwPets;
          break;
        case accsPvtBthRmchkBx:
          isChecked = accsPvtBthRm;
          break;
        case pvtEntrnceChkBx:
          isChecked = pvtEntrnce;
          break;
      }
      return (
        <label className="form-check-label" htmlFor={id} key={id}>
          <input
            className="form-check-input"
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <div className="label-inner-wrap">
            <p>{text}</p>
          </div>
        </label>
      );
    });

    return List;
  };

  const handleNoOfBedsChange = (e) => {
    let value = removeNegativeInput(e.target.value);
    if (value === "0") {
      value = "";
    } else if (value > 100) {
      alert("Maximum limit is 100");
      value = 100;
    }

    if (checkIsNumber(value)) {
      handleFormValues({ noOfBeds: value });
    }
  };

  const handleModalConfirmClick = () => {
    handleConfirmationModal(false);
    setDisableSave(false);
    handleFormValues({ isConfirmAll: true });
  };

  useEffect(() => {
    setBodyBackgroundWhite()
   
  }, []);

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
          />

          <div className="inner-layout-content-new  bg-white h-100 pl-16 pr-16 position-relative">
            <div className="layout-content-btm-sec position-static pe-0 ps-0">
              <h6 className="">Property use</h6>
              {isAgent ? (
                <h2 className="mb-2">
                  What does your client want to do with their property?
                </h2>
              ) : (
                <h2 className="mb-2">
                  What do you want to do with your property?
                </h2>
              )}
              <p className="titleTextpara mt-2">
                Select all that apply. Please note that you’re not publicly
                listing {isAgent ? "the" : "your"} property while using Gitcha.{" "}
              </p>
            </div>

            {propertyType != 11 && <div>
              <div
                className={
                  is_Sell_Selected
                    ? "find-renters-wrap member-ad mb-3 active"
                    : "find-renters-wrap member-ad mb-3  "
                }
              >
                <label class="form-check-label" for="IAmRepresenting10">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IAmRepresenting10"
                    name="pst-checkbox"
                    value={SELL}
                    checked={is_Sell_Selected}
                    onChange={(e) => handlePropertyPurposeSelection(e)}
                  />
                  <div className="label-wrap">
                    <h6>Find Buyers</h6>
                    <p>
                      With the right offer, you’d consider selling your
                      property.
                    </p>
                  </div>
                </label>
              </div>
            </div>}

            <div>
              <div
                className={
                  is_LTR_Selected
                    ? "find-renters-wrap member-ad mb-3  active"
                    : "find-renters-wrap member-ad mb-3"
                }
              >
                <label class="form-check-label" for="IAmRepresenting11">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IAmRepresenting11"
                    name="pst-checkbox"
                    value={LONG_RENT}
                    checked={is_LTR_Selected}
                    onChange={(e) => handlePropertyPurposeSelection(e)}
                  />
                  <div className="label-wrap">
                    <h6>Find Renters</h6>
                    <p>
                      You’d rent out your property to someone for greater than
                      30 days.
                    </p>
                  </div>
                </label>

                {is_LTR_Selected && (
                  <div className="find-renters-inner-wrap">
                    <h6>Optional details:</h6>
                    {loadCheckBoxItems(LTR_OPTION_DETAILS)}
                  </div>
                )}
              </div>
            </div>

            <div>
              <div
                className={
                  is_STR_Selected
                    ? "find-renters-wrap member-ad mb-3  active"
                    : "find-renters-wrap member-ad mb-3"
                }
              >
                <label class="form-check-label" for="IAmRepresenting12">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IAmRepresenting12"
                    name="pst-checkbox"
                    value={SHORT_RENT}
                    checked={is_STR_Selected}
                    onChange={(e) => handlePropertyPurposeSelection(e)}
                  />
                  <div className="label-wrap">
                    <h6>Find Short-term Renters</h6>
                    <p>
                      {" "}
                      You’d rent out your property to someone for less than 30
                      days.
                    </p>
                  </div>
                </label>

                {is_STR_Selected && (
                  <>
                    {propertyType != 6 && <div className="find-renters-inner-wrap">
                      <h6>Number of beds *</h6>
                      <label className="inputClass w-25" for="">
                        <Form.Control
                          class="form-control"
                          name="noOfBeds"
                          type="text"
                          value={noOfBeds || ""}
                          placeholder="0"
                          onChange={(e) => {
                            handleNoOfBedsChange(e);
                          }}
                        />
                      </label>
                    </div>}
                    <div className="find-renters-inner-wrap">
                      <h6>Optional details:</h6>
                      {loadCheckBoxItems(
                        propertyType === IS_PRIVATE_ROOM || propertyType === IS_LAND
                          ? STR_OPTIONS_PVT_ROOM
                          : STR_OPTION_DETAILS
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-3 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-4"
                disabled={disableSave}
                onClick={() => {
                  if (!isConfirmAll) {
                    // To show confirmation modal popup.
                    const isShow = true;
                    handleConfirmationModal(isShow);
                  } else {
                    if (propertyPurpose != propertyPurposeInSession) {
                      setCompletedPercentage(40);
                    } else {
                      setCompletedPercentage(completedPercentage);
                    }
                    setTimeout(() => {
                      setLocalStorage(
                        INVENTORY_KEY,
                        getLocalStorage(INVENTORY_KEY_TEMP)
                      );
                      setUIKey(null);
                    }, 500);
                  }
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmStatusModal
        show={showConfirmStatuModal}
        isShortRentChecked={
          propertyPurpose && _.includes(propertyPurpose, SHORT_RENT)
        }
        onHide={handleConfirmationModal}
        isConfirmOwner={isConfirmOwner}
        isConfirmPropLoc={isConfirmPropLoc}
        handleCheckBoxItemClick={handleCheckBoxItemClick}
        handleModalConfirmClick={handleModalConfirmClick}
        isDisabled={enableConfirmButton}
        confirmOwnerChkBx={confirmOwnerChkBx}
        confirmPropLocChkBx={confirmPropLocChkBx}
      ></ConfirmStatusModal>
    </>
  );
};

export default AddPropertyUse;
