import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { getWantAdReportingReasons } from "../../services/commonServices";
import { USER_INFO_KEY } from "../../constants/common";
import { getLocalStorage } from "../../helpers/common";
import { reportWantAd } from "../../services/wantAdService";
import { toast } from "react-toastify";

const ReportWantAdModal = (props) => {
  const { show, onHide, wantAdId } = props;
  const [reportReasons, setReportReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const sessionData = getLocalStorage(USER_INFO_KEY);  
  const userId = _.get(sessionData, 'userInfo.userId', '')


  useEffect(() => {
    fetchReportingReasons();
  }, []);
 

  const fetchReportingReasons = async () => {
    await getWantAdReportingReasons().then((response) => {
      if (response?.errorCode === 0) {
        setReportReasons(response?.result);
      } else {
        setReportReasons([]);
      }
    });
  };

  const handleReportWantAd = async () => {
    const params = {
      "userId": userId,
      "wantAdId" : wantAdId,
      "reasonId" : selectedReason
    }

    await reportWantAd(params).then((response)=>{
      props.onHide()
      if(response?.errorCode===0){
        toast.dismiss()
        setSelectedReason(null)
        toast.success("Report was successfully submitted")
      }
      else{
        toast.dismiss()
        setSelectedReason(null)
        toast.error(response?.message)
      }

    }).catch((err)=>{
      toast.dismiss()
      toast.error(err)
    })
  }

  const handleCancel = () =>{
    setSelectedReason(null)
    props.onHide()
  }

  return (
    <Modal show={show} 

    
      onHide={onHide} centered className="modalPopupstyle delete-popup"
            {...props}>
        <Modal.Body>
        <div className="text-center mb-3">
          <h3>Report this want-ad</h3>
          <p>Your report is always anonymous. Once the report is made we’ll investigate the situation and determine what to do next.</p>
        </div>
        <p className="modal-small-heading">Reason for reporting:</p>
        {/* <div className="modal-controls-wrap mb-3 mt-3"> */}
        <div className="modal-controls-wrap mb-32">
        {reportReasons.length > 0 &&
            reportReasons.map((reason) => {
              return (
                // <div className="modal-controls-wrap mb-0">
                <label class="radio-wrap mb-3 ">

                  <input 
                  type="radio"
                  // class="form-check-input" 
                  name="radio-ad-option"
                  onChange={()=>{setSelectedReason(reason?.ReasonId)}}
                  checked={reason?.ReasonId === selectedReason} />
                  <span className="mob-radio-span">{reason?.Reason_Text}</span>
                </label>
                //  </div>
              );
            })}   
        </div>

        {/* <div className="checkbox-wrap mb-3 mt-3">
          <label class="form-check-label checkbox-label " for="IAmRepresenting1">
            <input class="form-check-input" type="checkbox" id="IAmRepresenting1"  />
            <span className="labelTxt fnt14">This want-ad is spam</span>
          </label>  
        </div>
        <div className="checkbox-wrap mb-3">
          <label class="form-check-label checkbox-label " for="IAmRepresenting11">
            <input class="form-check-input" type="checkbox" id="IAmRepresenting11"  />
            <span className="labelTxt fnt14">Inappropriate content</span>
          </label>  
        </div>
        <div className="checkbox-wrap mb-3">
          <label class="form-check-label checkbox-label " for="IAmRepresenting2">
            <input class="form-check-input" type="checkbox" id="IAmRepresenting2"  />
            <span className="labelTxt fnt14">Fraud</span>
          </label>  
        </div>
        <div className="checkbox-wrap mb-3">
          <label class="form-check-label checkbox-label " for="IAmRepresenting3">
            <input class="form-check-input" type="checkbox" id="IAmRepresenting3"  />
            <span className="labelTxt fnt14">Hateful</span>
          </label>  
        </div>
        <div className="checkbox-wrap mb-3">
          <label class="form-check-label checkbox-label " for="IAmRepresenting13">
            <input class="form-check-input" type="checkbox" id="IAmRepresenting13"  />
            <span className="labelTxt fnt14">Other</span>
          </label>  
        </div> */}

        <div>
        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        
        <Form.Control as="textarea" rows={3} placeholder="Enter Reason Here" disabled />
      </Form.Group> */}
        </div>
       
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" disabled={selectedReason===null} onClick={()=>handleReportWantAd()}>Report Want-Ad</button>
            <button type="button" className="btn btn-secondary w-100" onClick={handleCancel}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default ReportWantAdModal;
