import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import CommonHeader from "../components/Onboarding/CommonHeader";
import MyWantadMap from "../images/my-wantad-map.png";
import ShareIconSmall from "../images/share-icon-small.svg";
import FavoriteIconSmall from "../images/favorite-icon-small.svg";
import FavoritesFilterUp from "../images/favorites-filter-icon-up.svg";
import FavoritesFilterDown from "../images/favorites-filter-icon-down.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchFavouriteWantAds } from "../actions/wantAds";
import { useNavigate, useLocation } from "react-router-dom";
import BedIcon from "../images/bed-icon.svg";
import BathIcon from "../images/bath-icon.svg";
import SqftIcon from "../images/sqft-icon.svg";
import MoneyIcon from "../images/money-icon.svg";
import { LOT_LAND, USER_INFO_KEY, WANT_AD_KEY } from "../constants/common";
import { convertToCurrencySystem, getLocalStorage, getUserId, locationDisplay, locationDisplayInListing, setLocalStorage } from "../helpers/common";
import { favouriteWantAd, unFavouriteWantAd, getWantAdById } from "../services/wantAdService";
import { toast } from "react-toastify";
import LoadingSkeleton from "./LoadingSkeleton";
import { pageURLs } from "../constants/pageURLs";
import SelectDropDown from "../components/Common/SelectDropDown";
import { getPropertyIcon, getTooltipText, getCombinedText } from "../helpers/getPropertyIcons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { wantAdSqftValue } from "../helpers/createWantAd";
import ShareYourAdModal from "../components/MemberWantAd/ShareYourAdModal";

const MyFavorites = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [pagecount, setPageCount] = useState(10)
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [wantAdList, setWantAdList] = useState([]); // storing list
  const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
  const myWantAdList = useSelector((state) => state.wantAds.favouriteWantAdsList);
  const [favoriteStatus, setFavoriteStatus] = useState(false);
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const [sortBy, setSortBy] = useState('recently favorited')
  const [sortOrder, setSortOrder] = useState(true)
  const [showNoResult, setShowNoResult] = useState(false)
  const [favoriteCount, setFavoriteCount] = useState(location.state && location.state['count'] ? location.state['count'] : 0)
  const [showShareModal, setShareModal] = useState(false);
  const handleClose = () => setShareModal(false);
  const [selectedWantAd, setSelectedWantAd] = useState({})
  const loggedInUserId = getUserId();
  const toastId = React.useRef(null);
  const options = [
    { value: "recently favorited", label: "Recently Favorited" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "price", label: "Price" },
  ];

  const [reload, setReload] = useState(0)

  // to list favorite want-ad
  useEffect(() => {
    if (currPage == 0) {
      setWantAdList([])
    }
    if (!wasLastList && prevPage !== currPage) {
      const data = {
        clientId: location.state ? location.state['clientId'] : null,
        sortBy: sortBy,
        sortDir: sortOrder == true ? 'asc' : 'desc',
        page: currPage,
        limit: pagecount,
      }

      dispatch(fetchFavouriteWantAds(data))
    }
  }, [currPage, wasLastList, favoriteStatus, sortBy, sortOrder, reload])

  // to list favorite want-ad
  useEffect(() => {
    setShowNoResult(true)
    if (myWantAdList?.length > 0) {
      setWantAdList([...wantAdList, ...myWantAdList]);
      setPrevPage(currPage);
      setWasLastList(false);
      setShowNoResult(false)
    } else {
      setWasLastList(true);
      // setShowNoResult(false)
    }
  }, [myWantAdList])

  /**
  * To handle scroll for pagination
  */
  const handleScroll = () => {
    let userScrollHeight = window.innerHeight + window.scrollY;
    let windowBottomHeight = document.documentElement.offsetHeight;
    if (userScrollHeight >= windowBottomHeight) {
      setCurrPage(prevState => prevState + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); // attaching scroll event listener

    if (currPage == 1) {
      setWantAdList([])
    }
  }, []);

  /**
   * Toast message
   * @param {*} Want_ad_Id 
   * @param {*} IsFavourite 
   * @returns 
   */
  const MyMsg = (Want_ad_Id, IsFavourite, clientId) => {
    let message = IsFavourite == true ? 'Want-Ad has been removed from your favorites' : 'Want-Ad has been added to your favorites';
    return (
      <div className="d-flex align-items-center justify-content-between">

        <span className="d-flex flex-grow-1">{message}</span>

        <button className="btn-primary text-nowrap me-2" onClick={(e) => handleFavoriteWantAd(!IsFavourite, Want_ad_Id, clientId, e)}> Undo</button>

      </div>)
  }

  /**
   * To handle the want-ad favorite
   */
  const handleFavoriteWantAd = async (IsFavourite, Want_ad_Id, clientId, e) => {
    e.stopPropagation();
    if (Want_ad_Id) {
      let data = {
        userId: sessionData.userInfo.userId,
        clientId: clientId ? [clientId] : IsFavourite == false ? [] : null,
        wantAdId: Want_ad_Id
      }

      await (IsFavourite == false ? favouriteWantAd(data) : unFavouriteWantAd(data))
        .then((response) => {
          if (response.errorCode == 0) {

            toast.dismiss(toastId.current);
            toast.success(MyMsg(Want_ad_Id, IsFavourite, clientId ), { toastId: response.result.wantAdId })

            if (IsFavourite) {
              setFavoriteCount(prevState => prevState - 1)
            } else {
              setFavoriteCount(prevState => prevState + 1)
            }
            setCurrPage(1);
            setPrevPage(0);
            setWantAdList([]);
            setWasLastList(false);
            setFavoriteStatus(IsFavourite);
            setReload(prevState => prevState + 1)

            // }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }

  const handleWantAdItemClick = (wantAdId) => {
    setLocalStorage(WANT_AD_KEY, { wantAdId: wantAdId, isFromHomeFeed: true });
    navigate(pageURLs.wantAdDetails);
  };

  const renderTooltip = (PropertyTypeId, subPropertyTypeId, Buy_or_Rent) => (
    <Tooltip id="button-tooltip" placement="bottom-start" className="custom-tooltip" {...props}>
      {
        subPropertyTypeId && subPropertyTypeId.length > 0 &&
        getCombinedText(subPropertyTypeId)
      }
    </Tooltip>
  );

  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setSelectedWantAd(responseResult);
            setShareModal(true);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const handleShare = (e, wantAd) => {
    e.stopPropagation();
    getWantAdDetails(wantAd?.Want_ad_Id)
  }

  /**
   * To list favorite want-ad
   * @param props 
   * @returns HTML
   */
  function WantAd(props) {
    const wantAd = props.wantAd
    const mapImg = _.get(wantAd, 'Location_Img', '')
    const PropertyTypeId = _.get(wantAd, 'PropertyTypeId', '')
    const subPropertyTypeId = _.get(wantAd, 'subPropertyTypeId', '')
    const Buy_or_Rent = _.get(wantAd, 'Buy_or_Rent', 1)
    let sqFt = wantAdSqftValue(_.get(wantAd, 'Sqft_mn', ''), _.get(wantAd, 'Sqft_mx', ''));
    return (
      <div className="col-md-4" >
        <a
          href={void 0}

          className="txt-no-underline link-cursor"
          onClick={() => handleWantAdItemClick(_.get(wantAd, 'Want_ad_Id', ''))}
        >
          <div className="my-wantad-display mb-0">
            <div className="my-wantad-map h-164">
              <div className="modal-property-tile tile-loation">

                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(PropertyTypeId, subPropertyTypeId, Buy_or_Rent)}
                >
                  <div className="property-tile w-auto">
                    {
                      subPropertyTypeId && subPropertyTypeId.length > 0 &&
                      subPropertyTypeId.map((subtype) => { return <img src={getPropertyIcon(subtype)} alt={getPropertyIcon(subtype)} className="property-icon-img" /> })

                    }

                  </div>
                </OverlayTrigger>
              </div>
              <img src={mapImg + "?" + Math.random().toString(36) || MyWantadMap} className="wantad-map-img" />
            </div>
            <div className="my-wantad-details">
              <div className="my-wantad-details-top d-flex justify-content-between">
                <div>
                  <div className="want-ad-tag mb-2 grey-tag-bg align-top">
                    <span className="tag-icon grey-color-icon text-uppercase me-0">


                      {wantAd.Buy_or_Rent == 1 ? 'Buy' : 'Rent'}
                    </span>
                  </div>
                  <h2>{wantAd?.Title_Text}</h2>
                  <h6>
                  {locationDisplayInListing(wantAd)}
                    {/* Within {wantAd?.mapRadius} mi of{" "}
                    {wantAd?.City}
                    {", "}
                    {wantAd?.State} */}
                    </h6>
                </div>
                <div className="wantad-sidenav-wrap">
                  <a role="button" className="social-action-btn mr-12" onClick={(e) => handleShare(e, wantAd)}><img src={ShareIconSmall} /></a>
                  <a role="button" className="social-action-btn"
                    onClick={(e) => {
                      handleFavoriteWantAd(true, wantAd?.Want_ad_Id, wantAd?.ClientId, e);
                    }}><img src={FavoriteIconSmall} /></a>
                </div>
              </div>
              <ul className="mt-2">
                {wantAd.Bedrooms ?
                  <li>
                    <div className="list-icns"><img src={BedIcon} /></div>
                    <span className="list-txt">{wantAd.Bedrooms && (wantAd.Bedrooms + (wantAd.Bedrooms == 'any' ? "" : '+'))}</span>
                  </li>
                  : ''
                }
                {wantAd.Bathrooms ?
                  <li>
                    <div className="list-icns"><img src={BathIcon} /></div>
                    <span className="list-txt">{wantAd.Bathrooms && wantAd.Bathrooms + (wantAd.Bathrooms == 'any' ? "" : '+')}</span>
                  </li>
                  : ''
                }
                {sqFt ?
                  <li>
                    <div className="list-icns"><img src={SqftIcon} /></div>
                    <span className="list-txt">{sqFt} sqft</span>
                  </li>
                  : ''
                }
                {(wantAd?.Budget_mn || wantAd?.Budget_mx) ?
                  <li>
                    <div className="list-icns"><img src={MoneyIcon} /></div>
                    <span className="list-txt">{wantAd.Budget_mn && "$" + (convertToCurrencySystem(wantAd.Budget_mn))} - {wantAd.Budget_mx && (convertToCurrencySystem(wantAd.Budget_mx))} {wantAd?.Buy_or_Rent == 2 && "/month"} {wantAd?.Buy_or_Rent == 3 && '/night'}</span>
                  </li>
                  : ''
                }
              </ul>
            </div>
          </div>
        </a>
      </div>
    )
  }

  /**
   * To handle filter
   * @param {*} e 
   */
  const handleSortChange = (selectedParam) => {
    const value = selectedParam.value;
    console.log(selectedParam.value)
    setSortBy(value)
    setCurrPage(1);
    setPrevPage(0)
    setWantAdList([])
    setWasLastList(false)
  }

  /**
   * To handle sort icon
   */
  const handleSortOrderChange = () => {
    setSortOrder(!sortOrder)
    setCurrPage(1);
    setPrevPage(0)
    setWantAdList([])
    setWasLastList(false)
  }


  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">
            {location.state && location.state['name'] &&
              <a role="button" className="bottom-back-btn d-inline-block mb-4" href={void (0)} onClick={() => navigate("/favorite-clients")}>
                <span className="left-arrow">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z" fill="#205ECA"></path></svg>
                </span>
                Back to favorites
              </a>
            }
            <div className="my-want-ad-wrap">
              <div className="top-sec mb-24 md-mt-24">
                <div className="row">
                  <div className="col">

                    {location.state && location.state['name'] ? (
                      <h5>{location.state['name']} favorites ({favoriteCount || 0})</h5>
                    ) : (<h5>My Favorites</h5>)
                    }
                  </div>
                  <div
                    col="12"
                    className="d-flex d-sm-block col-sm-auto"
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex select-wrap">
                        <Form.Label className="">Sort by:</Form.Label>
                        <SelectDropDown

                          id="multi3"
                          name="colors3"
                          options={options}
                          className="basic-single-inventory inventory-select mb-0 ms-2"
                          classNamePrefix="select"
                          dropDownIndicatorStatus={false}
                          onChange={(e) => handleSortChange(e)}
                          defaultValue={options[0]}
                          errorClass="error-msg error-msg-height"
                        />
                      </div>
                      <div className="more-info-wrap">
                        <button className="btn sort-btn" onClick={() => handleSortOrderChange()}>
                          {sortOrder == true ? (<img src={FavoritesFilterUp} />) : (<img src={FavoritesFilterDown} />)
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clearfix">
                <div className="row g-5">

                  {(showNoResult && wantAdList && wantAdList.length === 0) ? (
                    <div className="no-result-wrap ">
                      <h3>You don't have any favorited want-ads</h3>
                      <p>When you find a want-ad on the feed you want to favorite, use the Heart icon in the top right corner.</p>
                      <button className="btn btn-primary" onClick={() => navigate("/home")}>Browse Ad Feed</button>
                    </div>
                  ) :
                    (!showNoResult && wantAdList && wantAdList.length === 0) &&
                    <LoadingSkeleton />
                  }

                  {wantAdList &&
                    wantAdList?.map((wantAd, i) => {
                      return <WantAd key={i} wantAd={wantAd} ival={i} />
                    })}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {showShareModal && <ShareYourAdModal
        show={showShareModal}
        onHide={handleClose}
        adDetails={selectedWantAd}
        isWantAdCreated={false}
        AdType={_.get(selectedWantAd, "adType", "") === "BUY" ? 1 : 2}
        isAdTypeIsLand={_.get(selectedWantAd, "prprtyType", "") === 6}
        adTypeBasedOnProperty={_.get(selectedWantAd, "prprtyType", "") === 6 ? LOT_LAND : _.get(selectedWantAd, "adType", "")}
      />}

    </>
  );
};

export default MyFavorites;
