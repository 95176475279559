import React, { useEffect, useRef, useState } from "react";
import mapImg from "../images/MobileMap.png";
import BedSmallIcn from "../images/bed-small-icn.svg";
import BathSmallIcn from "../images/bath-small-icn.svg";
import SqftSmallIcn from "../images/sqft-small-icn.svg";
import LakeViewImage from "../../images/lake-view-icon.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HearttIcon from "../../images/mob-like-outline-btn.svg";
import HearttIconFilled from "../../images/mob-like-filled-btn.svg";
import ViewAddClientsModal from "./Favorites/ViewAddClientsModal";
import {
  loggedInUser,
  getLocalStorage,
  formateDate,
  durationForFlexibleDate,
  monthNameForFlexibleDate,
  getUserId,
  removeDecimalZeros,
  getUserType,
  locationDisplay,
} from "../../helpers/common";
import { useSearchParams } from "react-router-dom";
import {
  ADTYPE_LTR,
  ADTYPE_STR,
  BUDGET_NOMIN_NOMAX,
  IN_CITY_LIMITS,
  RURAL,
  USER_INFO_KEY
} from "../../constants/common";
import Tooltip from "react-bootstrap/Tooltip";
import { setLotSizeValue } from "../../helpers/createWantAd";
import {
  getPropertyIcon,
  getCombinedText,
} from "../../helpers/getPropertyIcons";
import { wantAdBudgetValue } from "../../helpers/createWantAd";
import { useSelector } from "react-redux";


const PropertyListingMob = ({
  handleFavoriteOrUnfavoriteWantAd,
  onChangeText,
  onAddClient,
  handleWantAdItemClick,
  key,
  ...props
}) => {
  const [verifyUser, setVerifyUser] = useSearchParams();
  const isUserVerified = verifyUser.get("verify");
  const [isFavourite, setIsFavourite] = useState(0);
  const [wantAdId, setWantAdId] = useState(0);
  const [modalShow, setModalShow] = useState(false); //
  const user = loggedInUser();
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const {
    Title_Text,
    Location,
    Bedrooms,
    Bathrooms,
    Budget_mn,
    Budget_mx,
    Sqft_mn,
    Sqft_mx,
    N2H,
    isLotLand,
    LotType_Rural_City,
    TotalAcre_mn,
    TotalAcre_mx,
    LotSz_mn,
    LotSz_mx,
    LotSz_ValTxt_Mx,
    LotSz_ValTxt_Mn,
    AdType,
    Want_ad_Id,
    IsFavourite,
    Location_Img,
    PropertyTypeId,
    subPropertyTypeId,
    Date_From,
    Date_To,
    DateType,
    Duration,
    Month,
    City,
    State,
    mapRadius,
    Latitude,
    CreatedBy,
   
  } = props && props.list;


  const [showShareModal, setShareModal] = useState(false);
  const [selectedWantAd, setSelectedWantAd] = useState({});
  const loggedInUserId = getUserId();
  const [isPremiumUser , setIsPremiumUser] = useState(null);
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const userType = getUserType();
  


  let budget = wantAdBudgetValue(Budget_mn, Budget_mx);
  if(budget != BUDGET_NOMIN_NOMAX){
    if (AdType === ADTYPE_LTR) {
      budget = budget + " /Month";
    } else if (AdType === ADTYPE_STR) {
      budget = budget + " /Night";
    }
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loadNiceToHaveList = () => {
    let count = 0;
    let nice = N2H.slice(0, 3);
    return (
      nice &&
      nice.map((nh, index) => {
        //   if (count) {

        count++;
        const id = _.get(nh, "N2H_Id");
        const itemText = _.get(nh, "Text", "");
        const icon = _.get(nh, "Icon", "");
        return (
          <>
            <div
              className="property-tag-viewad"
              key={index + "_" + _.get(nh, "Want_ad_Id", "")}
            >
              <div className="tag-icon-mobile-viewad">
                <img
                  src={icon ? require("../../images/" + icon) : LakeViewImage}
                />
              </div>
              <span>{itemText}</span>
            </div>
          </>
        );
      })
    );
  };

    /**
   * to handle clients favorites action
   * @param {*} agentIds
   */
    const handleClientFavoriteWantAd = (agentIds) => {
      handleFavoriteOrUnfavoriteWantAd(isFavourite, wantAdId, agentIds);
    };

      /**
   * handle agents favorite action, to open client list popup
   */
  const handleAgentsFavoriteAction = (IsFavourite, Want_ad_Id) => {
    setIsFavourite(IsFavourite);
    setWantAdId(Want_ad_Id);
    setModalShow(true);
  };

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } 
  }, [userDetailsFromStore]);


  const loadMustHaves = () => {
    if (isLotLand) {
      if (LotType_Rural_City) {
        return (
          <>
            <li>
              <span className="list-txt-btm">
                {LotType_Rural_City === 1 ? RURAL : IN_CITY_LIMITS}{" "}
              </span>
              <span className="list-txt-btm">lot type</span>
              <li className="sep-v-line">|</li>
              <span className="list-txt-btm">
                {setLotSizeValue(
                  removeDecimalZeros(LotSz_mn),
                  removeDecimalZeros(LotSz_mx)
                )}
              </span>{" "}
              {setLotSizeValue(removeDecimalZeros(LotSz_mn), removeDecimalZeros(LotSz_mx))==='Any' ? <span className="list-txt-btm">lot size</span> : <></>}
            </li>
          </>
        );
      } else {
        return (
          <>
            <li>
            <span className="list-txt-btm">
                {setLotSizeValue(
                  removeDecimalZeros(LotSz_mn),
                  removeDecimalZeros(LotSz_mx)
                )}
              </span>{" "}    
              {setLotSizeValue(removeDecimalZeros(LotSz_mn), removeDecimalZeros(LotSz_mx))==='Any' ? <span className="list-txt-btm">lot size</span> : <></>}       
            </li>
          </>         
        );
      }
    } else {
      let bedRm;
      let bathRm;
      let sqFt;
      let timeLine;
      if (Bedrooms) {
        if (Bedrooms === 99) {
          bedRm = "Any ";
        } else {
          bedRm = Bedrooms + "+";
        }
      }
      if (Bathrooms) {
        if (Bathrooms === 99) {
          bathRm = "Any ";
        } else {
          bathRm = Bathrooms + "+";
        }
      }
      const sqFtMn = Sqft_mn && Sqft_mn * 1; //Multiplying by 1 for  avoiding zeros after the decimal part (eg: if value is 100.00 then it should show 100)
      let sqFtMx = Sqft_mx && Sqft_mx * 1;
      if (sqFtMx == 5000) {
        sqFtMx = "5000+";
      }
      if (sqFtMn && sqFtMx) {
        sqFt = `${sqFtMn} - ${sqFtMx}`;
      } else if (!sqFtMn && sqFtMx) {
        sqFt = `No min - ${sqFtMx}`;
      } else if (sqFtMn && !sqFtMx) {
        sqFt = `${sqFtMn} - No max`;
      } else {
        //sqFt = `No min - No max`;
        sqFt = `Any`;
      }
      if (AdType === ADTYPE_STR) {
        if (DateType === 1 || DateType == null) {
          timeLine =
            formateDate(Date_From, "mm/dd/yyyy") +
            " - " +
            formateDate(Date_To, "mm/dd/yyyy");
        } else if (DateType === 2) {
          timeLine =
            durationForFlexibleDate(Duration) +
            " - " +
            monthNameForFlexibleDate(Month);
        }
      }

      return (
        <>
          <li>
            <span className="list-txt-btm">{bedRm}</span>
            <span className="list-icns-btm">
              <img src={BedSmallIcn} />
            </span>
          </li>
          <li className="sep-v-line">|</li>
          <li>
            <span className="list-txt-btm">{bathRm}</span>
            <span className="list-icns-btm">
              <img src={BathSmallIcn} />
            </span>
          </li>
          <li className="sep-v-line">|</li>
          <li>
            <span className="list-txt-btm">{sqFt}</span>
            <span className="list-icns-btm">
              <img src={SqftSmallIcn} />
            </span>
          </li>
        </>

        // <div className="property-details">
        // {bedRm && bedRm} {bedRm && <span className="details-text">beds</span>}
        // {bedRm ? <span className="vertical-divider">|</span> : ""}
        // {bathRm && bathRm}{" "}
        // {bathRm && <span className="details-text">baths</span>}
        // {sqFt ? <span className="vertical-divider">|</span> : ""}
        // {sqFt && AdType!==ADTYPE_STR && (
        //   <>
        //     <span>{sqFt}</span> <span className="details-text"> sqft</span>
        //   </>
        // )}
        // {timeLine && AdType===ADTYPE_STR && (
        //   <>
        //     <span className="details-text">{timeLine}</span>
        //   </>
        // )}

        // </div>
      );
    }
  };

  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {subPropertyTypeId &&
        subPropertyTypeId?.length > 0 &&
        getCombinedText(subPropertyTypeId)}
    </Tooltip>
  );

  return (
    <>
      <a
        role="button"
        className="property-listing-link-mob"
        href={void 0}
        onClick={(e) => {
          handleWantAdItemClick(Want_ad_Id);
        }}
     
      >
        <div className="property-listing-mob" key={key} >
      
          <div onClick={(e) => {
          e.stopPropagation();
        }}>
               {(user || isUserVerified) && (CreatedBy != loggedInUserId)  ? (
                  IsFavourite ? (
                    <a
                      role="button"
                      className="mobile-like-btn"
                      href={void 0}
                      onClick={() => {
                        handleFavoriteOrUnfavoriteWantAd(true, Want_ad_Id);
                      }}
                     
                    >
                      <img src={HearttIconFilled} />
                    </a>
                  ) : (
                    <a
                      role="button"
                      href={void 0}
                      onClick={() => {
                        sessionData?.userInfo?.userType == 2 && isPremiumUser
                          ? handleAgentsFavoriteAction(false, Want_ad_Id)
                          : handleFavoriteOrUnfavoriteWantAd(false, Want_ad_Id);
                      }}
                      className="mobile-like-btn"
                    >
                      <img src={HearttIcon} />
                    </a>
                  )
                ) : (
                  ""
                )}
                </div>
          <div className="property-type-wrap-mob">
            <OverlayTrigger
              placement="bottom-start"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div className="property-tile-mob w-auto">
                {/* <img src={PropIconHouse} /> */}
                {
                  <>
                    {subPropertyTypeId &&
                      subPropertyTypeId?.length > 0 &&
                      subPropertyTypeId.slice(0, 3).map((subtype) => {
                        return (
                          <img
                            src={getPropertyIcon(subtype)}
                            alt={getPropertyIcon(subtype)}
                            className="property-icon-img"
                          />
                        );
                      })}
                    {subPropertyTypeId?.length > 3 ? (
                      <span> +{subPropertyTypeId?.length - 3}</span>
                    ) : (
                      <></>
                    )}
                  </>
                }
              </div>
            </OverlayTrigger>
          </div>

          <div className="property-img-wrap-mob h-120 overflow-x-hidden">
            {/* <img src={mapImg}/> */}
            <img src={Location_Img + "?" + Latitude || mapImg} alt={mapImg} />
          </div>
          <div className="property-info-outer-mob">
            <div className="property-top-mob">
              <div className="property-info-wrap-mob">
                <div className="prize-range-badge-mob">{budget}</div>
                <h6 className="long-txt-fix">{Title_Text} </h6>
                <ul className="want-ad-btm-list">
                  <li>
                    <span className="list-txt-btm me-0">
                      {locationDisplay(props.list)}
                      {/* {mapRadius} mi {City}
                      {", "}
                      {State}{" "} */}
                    </span>
                  </li>
                  <li className="sep-v-line">|</li>
                  {loadMustHaves()}
              
                </ul>
            
           
            
                <div className="property-tag-viewad-wrap">
                  {loadNiceToHaveList()}

                  {N2H.length > 3 && (
                    <div className="property-tag-count propery-tag-count-btmmargin">
                      +{N2H.length - 3}
                    </div>
                  )}

                  
                </div>

              </div>
              <div className="social-btns-wrap rmt-12"></div>
            </div>
          </div>
          <ViewAddClientsModal
        show={modalShow}
        showAddToClientWantAdModal={() => setModalShow(true)}
        onHide={() => setModalShow(false)}
        data={props.results}
        handleSkip={() =>
          handleFavoriteOrUnfavoriteWantAd(isFavourite, wantAdId)
        }
        handleConfirm={handleClientFavoriteWantAd}
        searchText={props.clientSearchText}
        onChangeText={(e) => onChangeText(e)}
        onAddClient={() => onAddClient()}
      />
        </div>
      </a>
    </>
  );
};

export default PropertyListingMob;
