import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const MessageSentSuccessOffcanvas = (props) => {
  const {show, onHide} = props;
  const navigate = useNavigate();

  return (

    <Offcanvas
     className="mobile-offcanvas offcanvas-fixed message-success-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}> 
        <Offcanvas.Body>
            <div className="title-block">
                <div className="error">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.8153 10.4736C17.2149 10.0923 17.2298 9.45934 16.8486 9.05973C16.4673 8.66012 15.8343 8.64522 15.4347 9.02645L10.6225 13.6174L8.56589 11.652C8.1666 11.2705 7.5336 11.2848 7.15204 11.6841C6.77047 12.0834 6.78483 12.7164 7.18411 13.098L9.93099 15.723C10.3173 16.0921 10.9255 16.0924 11.3121 15.7236L16.8153 10.4736Z" fill="#4A4C4F" />
                    </svg>
                </div>
                <h3>Message Sent</h3>
                <p>We’ll notify you when Peter responds. Go to your Messages to keep the conversation going.</p>
            </div>
            <div className="btn-wrap text-center">
                <button type="button" className="btn btn-primary w-100" onClick={()=>{navigate(pageURLs.mobileMessages)}}>Go to messages</button>
                <button type="button" className="btn btn-link w-100" onClick={onHide}>Close and View Property</button>
            </div>
              
        </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default MessageSentSuccessOffcanvas;
