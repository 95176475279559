export const LOGIN = 'LOGIN'
export const POST_WANT_AD = 'POSTWANTAD'
export const HOME = 'HOME'
export const SIGNUP = 'SIGNUP'
export const resetModalemailOnly = 'emailOnly' 
export const resetModalpwdOnly = 'pwdOnly' 
export const RESET_PWD_ACTION = 11 
export const EMAIL_VERIFICATION = 12 
export const USER_TYPE = 'USER_TYPE' 
export const AGENT_TITLE = 'AGENT_TITLE' 
export const MEMBER_PLAN = 'MEMBER_PLAN' 
export const AGENT_PLAN = 'AGENT_PLAN' 
export const MEMBER_PROFILE = 'MEMBER_PROFILE' 
export const AGENT_PROFILE = 'AGENT_PROFILE' 
export const EDIT_PAYMENT = 'EDIT_PAYMENT'
export const ADD_PAY_METHOD = 'ADD_PAY_METHOD'
export const REVIEW_PLAN = 'REVIEW_PLAN' 
export const USER_PREFERENCE = 'USER_PREFERENCE' 
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT' 


// introduction
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const PAYMENT_LIST = 'PAYMENT_LIST'
export const REVIEW_ORDER = 'REVIEW_ORDER'
export const CUSTOMIZE_INTRO= 'CUSTOMIZE_INTRO'
export const REORDER_N2H ='REORDER_N2H'
export const REORDER_PHOTOS ='REORDER_PHOTOS'
export const VIEW_RECEIVED_INTRODUCTIONS ='VIEW_RECEIVED_INTRODUCTIONS'
export const INTRODUCTION_PREVIEW = 'INTRODUCTION_PREVIEW'
export const INTRODUCTION_DETAILS = 'INTRODUCTION_DETAILS'
export const IMAGES_FULL_VIEW = 'IMAGES_FULL_VIEW'

// messages
export const MESSAGES_VIEW= 'MESSAGES_VIEW'
export const VIEW_CLIENT_WANTAD_DETAILS = 'VIEW_CLIENT_WANTAD_DETAILS'
