import React, { useState, useEffect } from "react";

import ProgressBar from "../ProgressBar";
import { getPurchaseTypes } from "../../../services/wantAdService";
import { getLocalStorage, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";
import { STRENGTHEN_WANT_AD_KEY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";

const AddPurchaseType = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    // clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isStrengthenWantAd,
    isEdit,
    isFromBuyerListingService
  } = props;
  const [disableSave, setDisableSave] = useState(true);
  const propertyPurchase =
    _.get(formValues, "prchTypeId", null) ||
    _.get(formValues, "purchaseType", null);

  const propertyPurchaseInSession = _.get(getLocalStorage(WANT_AD_KEY), "prchTypeId", null)
  const is1031Exchange = _.get(formValues, "is1031Exchange", null);
  const is1031ExchangeInSession = _.get(getLocalStorage(WANT_AD_KEY), "is1031Exchange", null);

  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    setBodyBackgroundWhite()
    if (propertyPurchase && !isEdit) {
      setDisableSave(false)
    }

    getPurchaseTypes()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyList = _.get(response, "result", null);
          setPropertyList(propertyList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleItemClick = (value, propertyText) => {
    // if (value === propertyPurchase) {
    //   handleFormValues({
    //     purchaseType: "",
    //     purchaseText: "",
    //     is1031Exchange: "",
    //   });
    // } else {
      
        if (value === "3") {
        } else {
          handleFormValues({ is1031Exchange: "" });
        }
        if(isEdit && value===propertyPurchaseInSession){
          setDisableSave(true);
        }
        else {setDisableSave(false);}
        handleFormValues({prchTypeId : value, purchaseType: value, purchaseText: propertyText });
      
    
    // }
  };

  const getSelectedExchangeValue = (value) => {
    if(isEdit && value === is1031ExchangeInSession){
      setDisableSave(true)
    }
    else{
      setDisableSave(false)
    }
    handleFormValues({ is1031Exchange: value });
  };

  const loadPurchaseTypes = () => {
    const purchaseTypes =
      propertyList &&
      propertyList.map((tl) => {
        const PurchaseTypeId = _.get(tl, "PurchaseTypeId");
        const PurchaseTypeText = _.get(tl, "PurchaseTypeText", "");
        return (
          <a
            href={void 0}
            className={
              propertyPurchase && propertyPurchase == PurchaseTypeId
                ? "createAd-content-wrap mb-4 active"
                : "createAd-content-wrap mb-4"
            }
            onClick={(e) => {
              e.stopPropagation();
              handleItemClick(PurchaseTypeId, PurchaseTypeText);
            }}
          >
            <span className="labelTxt">{PurchaseTypeText}</span>
          </a>
        );
      });

    return purchaseTypes;
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isStrengthenWantAd={isStrengthenWantAd}
            isFromBuyerListingService={isFromBuyerListingService}
          />

          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div
              className="top-three-comp-btm-sec mt-0 pt-3 pb-4"
            >
              <h6 className="">Purchase type</h6>
              <h2 className="">What type of property will this be?</h2>
            </div>

            <div className="title-selection-wrap pt-0">
              {loadPurchaseTypes()}

              {propertyPurchase == "3" && (
                <div className="stay-timing-wrap mb-4">
                  <div className="lp-btn-group-wrap mb-2">
                    <h4> Will this be a 1031 Exchange? *</h4>
                    <div className="row">
                      <div className="col-6">
                        <a
                          href={void 0}
                          className={
                            is1031Exchange &&
                            (is1031Exchange === "Yes" || is1031Exchange == 1)
                              ? "blockBtn text-center active"
                              : "blockBtn text-center"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            getSelectedExchangeValue("Yes")}
                        }
                        >
                          {" "}
                          Yes{" "}
                        </a>
                      </div>
                      <div className="col-6">
                        <a
                          href={void 0}
                          className={
                          (is1031Exchange === "No" || is1031Exchange == 0)
                              ? "blockBtn text-center active"
                              : "blockBtn text-center"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            getSelectedExchangeValue("No")}
                        }
                        >
                          {" "}
                          No{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if(isAgent){
                    if(completedPercentage>80){
                      setCompletedPercentage(completedPercentage)
                    }
                    else{
                      setCompletedPercentage(80)
                    }
                  } 
                  else{
                    if(completedPercentage>50){
                      setCompletedPercentage(completedPercentage)
                    }
                    else{
                      setCompletedPercentage(50)
                    }
                  }                 
                  setTimeout(() => {
                    if(isStrengthenWantAd){
                      setLocalStorage(
                        STRENGTHEN_WANT_AD_KEY,
                        getLocalStorage(WANT_AD_KEY_TEMP)
                      );
                      setUIKey(null);
                    }
                    else {setLocalStorage(
                      WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );
                    setUIKey(null);}
                  }, 500);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPurchaseType;
