/**
 * @file   src\components\AgentDashboard\ClientDeleteModal.js
 * @brief  This file is responsible for deletion of client
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ClientDeleteModal = (props) => {
  const { handleConfirm, show, onHide, clientName } = props;

  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
      backdrop="static"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => onHide()}
        ></button>
        <h2 className="text-start f-size-24 mb-2 l-spacing">
          Are you sure you want to delete your client {clientName}?
        </h2>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-start">
              By continuing you'll be permanently deleting the client and
              associated, want ads, inventory, and records.
            </p>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
              <Button variant="tertiary" className="btn me-3" onClick={()=> onHide()}>
                Cancel
              </Button>
              <Button variant="primary" className="btn btn-delete" onClick={()=> handleConfirm()}>
                Delete Client
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default ClientDeleteModal;
