import React from "react";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const ErrorMessage = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="gitcha-main-wrapper pb-102">
        <div className="content-wrapper content-wrapper-h-102">
        <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 flex-column ">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="d-block back-link-big me-4"
                  href={void 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(pageURLs?.mobileHome)
                  }}
                >
                  back
                </a>                

                <button className="btn top-exit-btn ms-4">&nbsp;</button>
              </div>
            </div>
          </div>

          <div className="inner-layout-content-new bg-white pb-0 position-relative ">
            <div
              className="layout-content-btm-sec position-static"
            >
              <h2 className="text-center">Want-Ad already published</h2>
            </div>            
          </div>
        </div>        
      </div>      
    </>
  );
};

export default ErrorMessage;
