/**
 * @file   src\components\Common\AddPropertyPlanChecking.js
 * @brief  This file is responsible for handling add property section based on current user plan
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import PremiumPlanModal from "../PlanUpgrade/PremiumPlanModal";
import BasicPlanModal from "../PlanUpgrade/BasicPlanModal";
import { pageURLs } from "../../constants/pageURLs";
import { fetchUserStatistics } from "../../actions/userprofile";
import { USER_TYPE_AGENT } from "../../constants/common";
import { getUserType } from "../../helpers/common";

const AddPropertyPlanChecking = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStatictics = useSelector((state)=>state?.userProfile?.userStatictics)
  const userPrivileges = useSelector((state)=>state?.userProfile?.userPrivileges)
  const [userCount, setUserCount] = useState({})
  const [allowedCount, setAllowedCount] = useState({})
  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);
  const [showBasicPlanModal, setBasicPlanModal] = useState(false);
  const BasicPlanModalClose = () => setBasicPlanModal(false);
  const BasicPlanModalShow = () => setBasicPlanModal(true);
  const [reload, setReload] = useState(0)
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const [userDetails, setUserDetails] = useState(null)
  const userType = getUserType();

  useEffect(()=>{    
    if(userDetailsFromStore !== null && userDetailsFromStore?.length>0){
      setUserDetails(userDetailsFromStore[0])   
    }
    else{
      setUserDetails(null)
    }
  },[userDetailsFromStore])

  useEffect(()=>{
    const payload ={
      UserPlanId : userDetails?.User_Plan_Id
    }

    dispatch(fetchUserStatistics(payload))
  }, [userDetails])

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);


  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]); 

  const updateReloadParam = () => {
    setReload(reload + 1);
  };
  
  /**
   * To handle add property
   */
  const handleAddProperty = () => {    
    if(userCount?.Inventory >= allowedCount?.Inventory){
      if(userType === USER_TYPE_AGENT){
        PremiumPlanModalShow()
      }
      else {
        BasicPlanModalShow()
      }
    }
    else{
    if (userType === USER_TYPE_AGENT) {
      navigate(pageURLs.agentCreateInventory)
    } else {
      navigate(pageURLs.memberCreateInventory);
    }
    }
  };

  return (
<>
<a role="button" className="time-stamp-blue" onClick={handleAddProperty}>Add a property</a>
       
      
      <PremiumPlanModal
        userDetails={userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}
        fromInventoryListing={true}
        showPlanModal={PremiumPlanModalShow}
      />
      <BasicPlanModal
        userDetails={userDetails}
        show={showBasicPlanModal}
        updateReloadParam={updateReloadParam}
        onHide={BasicPlanModalClose}
        fromInventoryListing={true}
        showPlanModal={BasicPlanModalShow}
      />
    </>
  );
};

export default AddPropertyPlanChecking;
