import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import TextArea from "../Common/TextArea";
import {
  toTitleCase,
  getFileType,
  upperCaseEachSentenceFirst,
  s3ImageBaseFolder,
  getLocalStorage,
  setLocalStorage,
  trimString,
  formatPhoneNumber
} from "../../helpers/common";
import { nameValidation, alphaNumericValidation, textValidations, phoneNumberValidation } from "../../helpers/validations";
import { editProfilePremiumAgent } from "../../services/userprofileService";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import CheckBox from "../Common/CheckBox";
import { AGENT_USER_FREE_PLAN, BOTH_TITLE, PROPERTY_MANAGER, REAL_ESTATE_AGENT, USER_INFO_KEY, USER_TYPE_AGENT } from "../../constants/common";
import { useNavigate } from "react-router-dom";
const BIO_MAX_COUNT = 500;
const supportedImageFormats = ["jpg", "jpeg", "png"];


const EditProfileModal = (props) => {
  const { userDetails, editAgentProfile, isFromUpgradeToAgent, selectedPlan } = props;
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(userDetails?.FirstName);
  const [lastName, setLastName] = useState(userDetails?.LastName);
  const [company, setCompany] = useState(userDetails?.Company);
  const [location, setLocation] = useState(userDetails?.Location);
  const [bio, setBio] = useState(userDetails?.Bio);
  const [imgError, setImgError] = useState("");
  const [bioCount, setBioCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(userDetails?.Img);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [bioError, setBioError] = useState("");
  const [locationError, setLocationError] = useState("")
  const [isUploading, setIsUploading] = useState(false);
  const [license, setLicense] = useState(userDetails?.LicenseNo);
  const [licenseError, setLicenseError] = useState("");
  const [isRealEstateAgent, setIsRealEstateAgent] = useState(userDetails?.Agent_Title === REAL_ESTATE_AGENT || userDetails?.Agent_Title === BOTH_TITLE ? true : false)
  const [isPropertyManager, setIsPropertyManager] = useState(userDetails?.Agent_Title === PROPERTY_MANAGER || userDetails?.Agent_Title === BOTH_TITLE ? true : false)


  const [bioCursorPos, setBioCursorPos] = useState(0);
  const bioRef = useRef();

  const [phone, setPhone] = useState(userDetails?.Phone);
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    setPhone(userDetails?.Phone)
    setFirstName(userDetails?.FirstName)
    setLastName(userDetails?.LastName);
    setCompany(userDetails?.Company);
    setLocation(userDetails?.Location);
    setBio(userDetails?.Bio);
    setLicense(userDetails?.LicenseNo)
  }, [userDetails])
 
  useEffect(()=>{
    setImageUrl(props?.uploadedImageUrl ? props?.uploadedImageUrl :  userDetails?.Img)
  }, [props?.uploadedImageUrl, props?.reRenderEditProfileModel])

  const handleFirstName = (name) => {
    setFirstNameError("");
    setFirstName(name);
    if (name.trim() === "") {
      setFirstNameError("Enter First Name");
    } else if (!nameValidation(name.trim())) {
      setFirstNameError("Invalid input");
    }
  };

  const handleLastName = (name) => {
    setLastNameError("");
    setLastName(name);
    if (name.trim() === "") {
      setLastNameError("Enter Last Name");
    } else if (!nameValidation(name.trim())) {
      setLastNameError("Invalid input");
    }
  };

  const handleCompany = (name) => {
    setCompanyError("");
    setCompany(name);
    if (name.trim() === "") {
      setCompanyError("Enter Company");
    } else if (!alphaNumericValidation(name.trim())) {
      setCompanyError("Invalid input");
    }
  };

  useEffect(() => {
    if (bioRef.current) {
      bioRef.current.setSelectionRange(bioCursorPos, bioCursorPos);
    }

  }, [bioCursorPos]);

  const handleBio = (e) => {
    let text = e.target.value;
    text = upperCaseEachSentenceFirst(text);
    setBioError("");
    if (trimString(text) != "") {
      setBioCursorPos(e.target.selectionStart)
    }
    setBio(text);
    setBioCount(text.length);
  };

  const handleLocationChange = (name) => {
    setLocationError("");
    setLocation(name);
    if (name.trim() === "") {
      setLocationError("Enter Location");
    } else if (!textValidations(name.trim())) {
      setLocationError("Invalid input");
    }
  };

  const handleLicenseChange = (name) => {
    setLicenseError("");
    setLicense(name);
    if (name.trim() === "") {
      setLicenseError("Enter License Number");
    } else if (!alphaNumericValidation(name.trim())) {
      setLicenseError("Invalid input");
    }
  };


  const handlePhone = (value) => {

    setPhoneError("");
    if (trimString(value) !== "") {
      value = formatPhoneNumber(value);
      setPhone(value);
    } else {

      setPhoneError("Enter Phone Number");
      setPhone("");
    }
  };

  const validatePhoneNumber = () => {
    setPhoneError("");
    if (trimString(phone) !== "") {
      if (!phoneNumberValidation(phone.trim())) {
        setPhoneError("Enter Valid Phone Number");
      } 
    } else {
      setPhoneError("Enter Phone Number");
      setPhone("");
    }

  }


  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const selectedFileType = getFileType(_.get(e.target.files[0], "name"));
    let isValid = true;
    if (!_.includes(supportedImageFormats, selectedFileType)) {
      isValid = false;
      // setIsUploading(false);
      setImgError("Select Valid Image");
    }
    if (isValid) {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          props.onClickChangePhoto(reader.result)
        };
        reader.readAsDataURL(file);
        // Reset the file input
        e.target.value = null;
      }
    }
  };

  const handleCancel = () => {
    setFirstName(userDetails?.FirstName);
    setLastName(userDetails?.LastName);
    setCompany(userDetails?.Company);
    setLicense(userDetails?.LicenseNo);
    setLocation(userDetails?.Location);
    setBio(userDetails?.Bio);
    setImageUrl(userDetails?.Img);
    setIsPropertyManager(userDetails?.Agent_Title === PROPERTY_MANAGER || userDetails?.Agent_Title === BOTH_TITLE ? true : false)
    setIsRealEstateAgent(userDetails?.Agent_Title === REAL_ESTATE_AGENT || userDetails?.Agent_Title === BOTH_TITLE ? true : false)
    setPhone(userDetails?.Phone)
    setPhoneError("")
    setLicenseError("")
    setBioError("")
    setFirstNameError("")
    setLastNameError("")
    setCompanyError("")
    setLocationError("")
    props.onHide();
  };

  const getTitleOf = () => {

    if (isPropertyManager && isRealEstateAgent) {
      return 3
    }
    else if (isPropertyManager) {
      return 2
    }
    else {
      return 1
    }

  }

  const handleSubmit = async () => {
    const data = {
      FirstName: firstName,
      LastName: lastName,
      Company: company,
      About: bio,
      Img: imageUrl,
      Title: getTitleOf(),
      Location: location,
      LicenseNo: license,
      Phone: phone
    };
    await editProfilePremiumAgent(data)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.success("User's profile updated successfully.");                 
          let userInfo = getLocalStorage(USER_INFO_KEY);  
          userInfo.userInfo["firstName"] = firstName;
          userInfo.userInfo["lastName"] = lastName;
          userInfo.userInfo["name"] = firstName + " " + lastName;
          userInfo.userInfo["image"] = imageUrl;         
          userInfo.userInfo["userType"] = USER_TYPE_AGENT;
          setLocalStorage(USER_INFO_KEY, userInfo);
          navigate("/settings")

          props.onHide();
        } else {
          toast.error(response.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const checkValid = () => {
    if(userDetails?.PlanName===AGENT_USER_FREE_PLAN || selectedPlan==="Lite"){
      if(location && license && company && firstName && lastName && (isPropertyManager === true || isRealEstateAgent === true)){
        if(locationError || licenseError || companyError || firstNameError|| lastNameError){
          return true
        }
        return false
      } else{
        return true
      }
    }
    else {
      if (!phoneNumberValidation(phone)) {
        return true
      } else if (
        firstName === userDetails?.FirstName &&
        lastName === userDetails?.LastName &&
        bio === userDetails?.Bio &&
        phone === userDetails?.phone &&
        company === userDetails?.Company &&
        imageUrl === userDetails?.Img &&
        license === userDetails?.LicenseNo &&
        location === userDetails?.Location &&
        (isPropertyManager === (userDetails?.Agent_Title === PROPERTY_MANAGER || userDetails?.Agent_Title === BOTH_TITLE) ? true : false) &&
        (isRealEstateAgent === (userDetails?.Agent_Title === REAL_ESTATE_AGENT || userDetails?.Agent_Title === BOTH_TITLE) ? true : false)

      ) {
        return true;
      } else if (
        firstNameError !== "" ||
        lastNameError !== "" ||
        companyError !== "" ||
        bioError !== "" ||
        phoneError !== "" ||
        imgError !== "" ||
        locationError !== "" ||
        licenseError !== ""
      ) {
        return true;
      } else if (
        firstName?.trim() === "" ||
        lastName?.trim() == "" ||
        company?.trim() == "" ||
        location?.trim() == "" ||
        (isPropertyManager === false && isRealEstateAgent === false)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {isUploading ? <Loader /> : ""}
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-settings"
        backdrop="static"
      >
        <Modal.Body className="pt-32 pb-32">
          {editAgentProfile || isFromUpgradeToAgent ? <></> : <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleCancel}
          ></button>}
          <h3 className="text-start">Edit profile</h3>

          <div className="profile-info">
            <div className="profile-pic-sec mb-3 mb-sm-0">
              <div className="prof-img mr-12 mr-sm-12 mb-3 mb-sm-0">
                {imageUrl != null && imageUrl != "" ? (
                  <img src={imageUrl + "?" + Math.random().toString(36)} />
                ) : (
                  <svg
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                      fill="#A0A9BA"
                    />
                  </svg>
                )}
              </div>
              <div className="profile-name">
                <p className="title">
                  {userDetails?.FirstName} {userDetails?.LastName}
                </p>
                {userDetails?.Company ? <p className="position">Agent @ {userDetails?.Company}</p> : ""}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="upload-btn-innerwrap">
                <input
                  type="file"
                  name="profileImage"
                  id="profileImage"
                  onChange={(e) => handleImageUpload(e)}
                  className=" "
                  disabled={userDetails?.period===1}
                />
                <label className={userDetails?.PlanName===AGENT_USER_FREE_PLAN || selectedPlan==="Lite" ? "float-none float-md-end disabled-label" : "active float-none float-md-end"} >Change photo</label>
                <span className="error-msg w-104 h-auto error-wordbreak">{imgError}</span>
                
              </div>
              {userDetails?.PlanName===AGENT_USER_FREE_PLAN || selectedPlan==='Lite' && <div className="pro-badge ms-2">pro</div>}
            </div>
            
          </div>
          <Row>
            <Col md={6}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label f-medium">First name <span>*</span></label>
                <TextBox
                  id="fName"
                  name="firstName"
                  type="text"
                  maxLength={30}
                  value={toTitleCase(firstName)}
                  placeholder="First name"
                  className="form-control"
                  onChange={(e) => handleFirstName(e.target.value)}
                  errorClass="error-validation-msg"
                  errorMessage={firstNameError}
                />
              </div>
            </Col>

            <Col md={6}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label f-medium">Last name <span>*</span></label>
                <TextBox
                  id="lName"
                  name="lastName"
                  maxLength={30}
                  value={toTitleCase(lastName)}
                  type="text"
                  placeholder="Last name"
                  className="form-control"
                  errorClass="error-validation-msg"
                  onChange={(e) => handleLastName(e.target.value)}
                  errorMessage={lastNameError}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3 d-inline-flex me-4">
                <label
                  className="form-check-label checkbox-label"
                  htmlFor="flexCheckDefault78"
                >
                  <CheckBox
                    class="form-check-input margin-top-zero"
                    type="checkbox"
                    id='realestate_agent'
                    checked={isRealEstateAgent}
                    onChange={(e) => setIsRealEstateAgent(!isRealEstateAgent)}
                  />{" "}
                  Real Estate Agent
                </label>
              </div>
              <div className="textbox-wrap mb-3 mb-sm-3 d-inline-flex">

                <label
                  className="form-check-label checkbox-label"
                  htmlFor="flexCheckDefault78"
                >
                  <CheckBox
                    class="form-check-input margin-top-zero"
                    type="checkbox"
                    id='property_manager'
                    checked={isPropertyManager}
                    onChange={(e) => setIsPropertyManager(!isPropertyManager)}
                  />{" "}
                  Property Manager
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label f-medium">Phone Number {userDetails?.PlanName===AGENT_USER_FREE_PLAN || selectedPlan==="Lite" ? <div className="pro-badge d-inline-flex align-items-center ms-1">pro</div>: <span>*</span>}</label>
                <TextBox
                  id="phone"
                  name="phone"
                  disabled={userDetails?.PlanName===AGENT_USER_FREE_PLAN  || selectedPlan==="Lite" ? true :false }
                  type="text"
                  value={phone}
                  className="form-control"
                  errorClass="error-validation-msg"
                  onChange={(e) => handlePhone(e.target.value)}
                  onBlur={() => { validatePhoneNumber() }}
                  errorMessage={phoneError}
                  placeholder="321-456-7891"
                  maxLength={14}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label f-medium">City, State <span>*</span></label>
                <TextBox
                  id="location"
                  name="location"
                  type="text"
                  value={toTitleCase(location)}
                  className="form-control"
                  errorClass="error-validation-msg"
                  onChange={(e) => handleLocationChange(e.target.value)}
                  errorMessage={locationError}
                  placeholder="City, State"
                  maxLength={40}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label f-medium">Company <span>*</span></label>
                <TextBox
                  id="company"
                  name="company"
                  type="text"
                  value={toTitleCase(company)}
                  className="form-control"
                  errorClass="error-validation-msg"
                  onChange={(e) => handleCompany(e.target.value)}
                  errorMessage={companyError}
                  placeholder="Company name"
                  maxLength={40}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label f-medium">License Number <span>*</span></label>
                <TextBox
                  id="license"
                  name="license"
                  type="text"
                  value={license}
                  className="form-control"
                  errorClass="error-validation-msg"
                  onChange={(e) => handleLicenseChange(e.target.value)}
                  errorMessage={licenseError}
                  placeholder="License Number"
                  maxLength={40}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3 position-relative">
                  <label className="form-label f-medium">About
                  {userDetails?.PlanName===AGENT_USER_FREE_PLAN || selectedPlan==="Lite" && <div className="pro-badge d-inline-flex align-items-center ms-1">pro</div>}
                  </label>
                  
                <TextArea
                  className="form-control txt-area"
                  maxLength={500}
                  value={bio}
                  errorMessage={bioError}
                  placeholder="A few sentences about yourself..."
                  errorClass="error-validation-msg"
                  onChange={(e) => handleBio(e)}
                  txtRef={bioRef}
                  disabled={userDetails?.PlanName===AGENT_USER_FREE_PLAN || selectedPlan==="Lite"}
                />
                <div className="des-count mb-4">
                  <span>{bioCount}</span>/<span>{BIO_MAX_COUNT}</span>
                </div>
              </div>
            </Col>
          </Row>

          <div className="modal-btn-wrap">
            {editAgentProfile || isFromUpgradeToAgent? <></> :
            <Button variant="" className="btn btn-tertiary btn-margin-fix me-3" onClick={handleCancel}>
              Cancel
            </Button>}
            <Button
              variant="primary"
              disabled={checkValid()}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditProfileModal;
