/**
 * @file   src\components\Common\GitchaNotAvailableModal.js
 * @brief  This file is responsible for showing gitcha not available popup
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PlusMinusIcon from "../../images/plus-minus-circle-icon.svg";
import ZipCloseIcon from "../../images/zipcode-close-icon.svg";
import {
  clearLocalStorage,
  clearLocalStorageOnLogout,
  getLocalStorage,
  getUserId,
  loggedInUser,
  removeLocalStorage,
  getLocalityFromZipCode
} from "../../helpers/common";
import { capitalizeString } from "../../actions/common";
import { BUY, USER_TYPE_AGENT, WANT_AD_KEY } from "../../constants/common";
import { pageURLs } from "../../constants/pageURLs";
import { saveToDraft } from "../../helpers/createWantAd";
import {
  getGeoLocation,
  saveGitchaAvailableLocationRequest,
} from "../../services/commonServices";
import GitchaZipCodeSubmiModal from "../Common/GitchaZipCodeSubmiModal";
import { saveMemberWantAdstrengthenData } from "../../services/wantAdService";
import InputGroupControl from "./InputGroup";
import { userLogout } from "../../reducers/login";

const GitchaNotAvailableModal = (props) => {
  const navigate = useNavigate();
  const {
    title,
    desc,
    show,
    onHide,
    session = WANT_AD_KEY,
    isFromMobile,
    clearLocationFields,
    isFromBuyersListingService,
  } = props;
  const [isDisabled, setDisabled] = useState(true);
  const [submitModalShow, setSubmitModalShow] = useState(false);
  const userId = getUserId();
  const userData = loggedInUser();
  const requestPayLoad = getLocalStorage(session);
  const [errorMessage, setErrorMessage] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState(null);
  const [locationArray, setLocationArray] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [sessionUpdateStatus, setSessionUpdateStatus] = useState(true);

  useEffect(() => {
    if(requestPayLoad?.forGitchaAvailablePopup?.city){
      setLocationArray([{
        city: requestPayLoad?.forGitchaAvailablePopup?.city,
        state: requestPayLoad?.forGitchaAvailablePopup?.state,
        county: requestPayLoad?.forGitchaAvailablePopup?.County
      }])
      setSessionUpdateStatus(false)
    } else{
      setLocationArray([{
        city: requestPayLoad?.city,
        state: requestPayLoad?.state,
        county: requestPayLoad?.County
      }])
    }
   

  }, [requestPayLoad?.city, requestPayLoad?.state, requestPayLoad?.County,requestPayLoad?.forGitchaAvailablePopup?.city])

  useEffect(() => {
    if (locationArray.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [locationArray]);

  /**
   * To handle save and exit functionality
   */
  const handleSaveExit = async () => {
    if (isFromBuyersListingService) {
      buyerListingServiceSaveandExit();
    } else {
      const requestPayLoad = getLocalStorage(session);
      if (userId && requestPayLoad) {
        requestPayLoad.isAgentCreated =
          _.get(userData, "userInfo.userType", null) == USER_TYPE_AGENT ? 1 : 0;
        await saveToDraft({ requestPayLoad: requestPayLoad }).then(
          (response) => {
            const responseResult = _.get(response, "result", null);
            if (response.errorCode === 0 && responseResult) {
              if (
                requestPayLoad.purchaseType ||
                requestPayLoad.creditScore ||
                requestPayLoad.prchTypeId ||
                requestPayLoad.pFinance
              ) {
                saveStrengthenData(responseResult.wantAdId);
              } else {
                removeLocalStorage(session);
                removeLocalStorage(WANT_AD_KEY);
                if (isFromMobile) {
                  navigate(pageURLs.myWantAds, { state: { showDraft: true } });
                } else {
                  navigate(pageURLs.wantAdListing, {
                    state: { showDraft: true },
                  });
                }
              }
            } else {
              console.log("Something went wrong");
            }
          }
        );
      } else {
        clearLocalStorage();
        navigate(pageURLs.home);
      }
    }
  };

  const handleStateClear = () => {
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate(pageURLs.home);
  };

  /**
   * To save strengthen want ad details
   * @param {*} wantAdIdValue 
   */
  const saveStrengthenData = async (wantAdIdValue) => {
    const wantAd = getLocalStorage(session);
    const wantAdId = wantAdIdValue;

    if (wantAd) {
      let requestPayLoad = {};
      let isApproveLoan = "";

      if (
        _.get(wantAd, "isPreApprLoan", null) === "Yes" ||
        _.get(wantAd, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = true;
      } else if (
        _.get(wantAd, "isPreApprLoan", null) === "No" ||
        _.get(wantAd, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = false;
      }
      requestPayLoad.isPreApprLoan === isApproveLoan;
      const is1031Exchange = wantAd.is1031Exchange === "Yes" ? true : false;
      requestPayLoad.wantAdId = wantAdId;
      requestPayLoad.isPreApprLoan = isApproveLoan;
      requestPayLoad.is1031Exchange = is1031Exchange;
      requestPayLoad.completedStep = 1;
      requestPayLoad.adType =
        wantAd.adType == BUY ? "BUY_STRENGTHEN" : "LTR_STRENGTHEN";
      requestPayLoad.creditScore = wantAd.creditScore;
      requestPayLoad.maxBdgt = wantAd.maxBdgt;
      requestPayLoad.minBdgt = wantAd.minBdgt;
      requestPayLoad.pFinance = wantAd.pFinance || wantAd.finTypeId;
      requestPayLoad.purchaseType = wantAd.purchaseType || wantAd.prchTypeId;

      await saveMemberWantAdstrengthenData(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            removeLocalStorage(WANT_AD_KEY);
            removeLocalStorage(session);            
            if (isFromMobile) {
              navigate(pageURLs.myWantAds, { state: { showDraft: true } });
            } else {
              navigate(pageURLs.wantAdListing, { state: { showDraft: true } });
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const clearAddressList = () => {
    setLocationList([]);
  };
/**
 * To get details of searched location from google api
 * @param {*} param0 
 */
  const getAddresseDetails = async ({ eventType, reqPayload }) => {
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      if (results?.length > 0) {
        setLocationList(results);
      }
    });
  };

  /**
   * To handle location field changes
   * @param {*} e 
   */
  const handleLocationChange = (e) => {
    setErrorMessage("");
    const value = capitalizeString(e.target.value);
    const reqPayload = `address=${value}`;

    if (value.trim() !== "") {
      getAddresseDetails({ reqPayload: reqPayload });
    } else {
      clearAddressList();
    }
    if (value && value.trim() !== "") {
      setLocation(value);
    } else {
      clearLocationStates();
    }
  };

  const clearLocationStates = () => {
    setLocation("");
  };

  /**
   * To handle selection from locations dropdown
   * @param {*} address 
   */
  const handleItemSelection = async (address) => {
    let loc = {};
    const formatted_address = _.get(address, "formatted_address", "");
    const firstAddressComponent = _.get(address, "address_components", "");
    const state = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_1";
    });
    if (state.length) {
      loc.state = _.get(state?.[0], "short_name", "");
    }
    const locality = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "locality";
    });
    const zip = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "postal_code";
    });
    if (zip.length) {
      loc.zip = _.get(zip?.[0], "short_name", "");
    }
    if (locality.length) {
      loc.city = _.get(locality?.[0], "long_name", "");
    }else{
      await getLocalityFromZipCode(loc)
    }
    const county = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_2";
    });

    if (county.length) {
      loc.county = _.get(county?.[0], "short_name", "");
    }
    if(!loc.city){
      setErrorMessage("Please enter a valid city and state. ")
      setLocation("")
    }
    else{
      setLocation(loc.city + ", " + loc.state);
      setSelectedLocation({
        city: loc?.city,
        state: loc?.state,
        county: loc?.county,
      });
    }    
    clearAddressList();
  };
/**
 * To add selected location to requested locations list
 */
  const addToLocationArray = () => {
    setErrorMessage("");

    if (!selectedLocation) {
      setErrorMessage("Enter City, State");
    } else {
      const someArray = locationArray.find(
        (loc) =>
          loc.state == selectedLocation.state && loc.zip == selectedLocation.zip
      );
      if (someArray) {
        setErrorMessage("City, State already added to the list.");
      } else {
        setErrorMessage("");
        setLocationArray([...locationArray, selectedLocation]);
        setLocation("");
        setSelectedLocation(null);
      }
    }
  };

  /**
   * To remove location from requested locations list
   * @param {*} removeLocation 
   */
  const handleRemoveLocation = (removeLocation) => {
    const someArray = locationArray.filter(
      (loc) =>
        loc.city != removeLocation.city && loc.state != removeLocation.state
    );
    setLocationArray(someArray);
  };

  const handleSubmit = async () => {
    const payload = {
      locations: locationArray,
    };
    await saveGitchaAvailableLocationRequest(payload)
      .then((response) => {
        props.onHide();
        setSubmitModalShow(true);
        setLocationArray([]);
      })
      .catch((error) => {
        props.onHide();
        console.log(error);
      });
  };

  const buyerListingServiceSaveandExit = () => {
    clearLocationFields();
    onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-custom-width"
        show={show}
        backdrop="static"
      >        
        <Modal.Body className="pt-32 pb-32">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => onHide()}
          ></button>
          <h3 className="text-start">
            {title || "Gitcha isn't currently available in this location yet"}
          </h3>
          <p className="f-size-16 mb-4">{desc}</p>

          <Row>
            <Col xl={12}>
              <div className="textbox-wrap pr-42 position-relative mb-1">
                {locationList.length > 0 ? (
                  <Button
                    variant=""
                    className="search-clear-btn"
                    onClick={() => clearAddressList()}
                  ></Button>
                ) : (
                  ""
                )}
                <InputGroupControl
                  inputGroupClass="wantad-location-search position-relative"
                  ariaLabel="Example"
                  ariaDescribedBy="basic-addon3"
                  placeholder="City, State"
                  errorMessage={errorMessage}
                  value={location ? location : ""}
                  iconClass="location-icon"
                  isIconEnable={false}
                  iconId="button-addon3"
                  onChange={(e) => {
                    handleLocationChange(e);
                  }}
                />
                {locationList.length > 0 ? (
                  <div className="location-result-wrap">
                    <ul>
                      {locationList?.map((address, i) => {
                        return (
                          <li key={i}>
                            <a
                              role="button"
                              href={void 0}
                              onClick={(e) => {
                                handleItemSelection(address);
                              }}
                            >
                              {_.get(address, "formatted_address", "")}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
                <button
                  className="btn add-zipcode-btn"
                  onClick={() => addToLocationArray()}
                >
                  <img
                    src={PlusMinusIcon}
                    alt={PlusMinusIcon}
                    className=""
                    width={24}
                    height={24}
                  />
                </button>
              </div>
              <div className="zipcode-tag-wrap pb-32">
                {locationArray.map((loc) => {
                  return (
                    <div className="zipcode-tag me-3">
                      <button
                        className="btn zipcode-close-btn me-1"
                        onClick={() => handleRemoveLocation(loc)}
                      >
                        <img
                          src={ZipCloseIcon}
                          alt={ZipCloseIcon}
                          className=""
                        />
                      </button>
                      {loc.city}, {loc.state}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="modal-btn-wrap">
            <Button
              variant="tertiary"
              className="btn me-3 btn-override modal-btn-pad-override"
              onClick={() => {
                clearLocationFields()
                setTimeout(()=>{
                  handleSaveExit()
                },300)
              }}
            >
              Save draft and exit
            </Button>

            <Button
              variant="primary"
              className={isDisabled ? "btn disabled" : "btn"}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>        
      </Modal>
      <GitchaZipCodeSubmiModal
        show={submitModalShow}
        onHide={() => setSubmitModalShow(false)}
        handleSaveExit={handleSaveExit}
        clearLocationFields={clearLocationFields}
        isFromBuyersListingService={isFromBuyersListingService}
        title={"We've received your request."}
        desc={
          "In the mean time, you can save your property to your drafts. We'll notify you via email when we're available in your area. We appreciate your patience as we continue to expand!."
        }
      />
    </>
  );
};
export default GitchaNotAvailableModal;
