import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MarkAsSoldModal = (props) => {
  const {handleInventoryMarkAsSold, show, onHide, inventoryId } = props;
  return (
    <Modal show={show} {...props} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Ready to mark this property as sold?</h3>
        <p>Marking this property as sold will remove any pending introductions sent by you.</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100"  onClick={() => handleInventoryMarkAsSold(inventoryId)}>Mark as sold</button>
            <button type="button" className="btn btn-secondary w-100"  onClick={() => onHide(false)}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default MarkAsSoldModal;
