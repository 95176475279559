import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";



const AcceptIntroductionModal = (props) => {
  const {fullName, handleViewInventoryClick, introductionId} = props
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings modal-accept"
      backdrop = "static"
    >
      <Modal.Body className="text-center">
        <svg width="32" height="32" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6677 7.2323C14.9536 7.532 14.9424 8.00674 14.6427 8.29266L9.13958 13.5427C8.84962 13.8193 8.39343 13.8191 8.10371 13.5422L5.35684 10.9172C5.05737 10.631 5.0466 10.1563 5.33278 9.85684C5.61895 9.55737 6.0937 9.5466 6.39316 9.83278L8.62232 11.963L13.6073 7.20734C13.907 6.92142 14.3817 6.93259 14.6677 7.2323Z" fill="#4a4c4f"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75ZM0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10Z" fill="#4a4c4f"/>
        </svg>
        <h3>Introduction accepted</h3>
        <p className="f-size-16">
            You have <span>72 hours</span> to start a conversation with {fullName} before the introduction expires. 
            You can view all of the property details and send your first message. 
        </p>
        {/* onClick={()=> alert('Not implemeted')} */}
        {/* onClick={()=> handleViewInventoryClick(introductionId)} */}
        <div className="modal-btn-wrap">
            <Button variant="primary" onClick={()=> handleViewInventoryClick(introductionId)}  >View Property Details</Button>
            <Button variant="light" className="btn-decline" onClick={props.onHide}>Close</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AcceptIntroductionModal;
