import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageURLs } from "../../constants/pageURLs";
import { Button } from "react-bootstrap";
import ProfImgPlaceholder from "../images/prof-img-placeholder.svg";
import TextBox from "../../components/Common/TextBox";
import {
  toTitleCase,
  getFileType,
  upperCaseEachSentenceFirst,
  s3ImageBaseFolder,
  reSizeImage,
  getLocalStorage,
  trimString,
  formatPhoneNumber,
  setLocalStorage,
} from "../../helpers/common";
import {
  BOTH_TITLE,
  PROPERTY_MANAGER,
  REAL_ESTATE_AGENT,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
} from "../../constants/common";
import CheckBox from "../../components/Common/CheckBox";
import TextArea from "../../components/Common/TextArea";
import { editProfilePremiumAgent } from "../../services/userprofileService";
import {
  nameValidation,
  alphaNumericValidation,
  textValidations,
  phoneNumberValidation,
} from "../../helpers/validations";
import { toast } from "react-toastify";
import { fetchUserDetails } from "../../actions/userprofile";
import { getPreSignedUrl } from "../../services/fileUploadService";
import SparkleBigIcon from "../images/sparkle-big-icon.svg";
import ProfileImageCropper from "../components/Onboarding/ProfileImageCropper";

const MAX_FILE_SIZE = 25000; // In Bytes
const MAX_WIDTH = 100;
const MAX_HEIGHT = 100;
const BIO_MAX_COUNT = 500;
const supportedImageFormats = ["jpg", "jpeg", "png"];
const MIN_WIDHT = 700;
const MIN_HEIGHT = 400;

const ProfileAgentUserPremiumEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const [userDetails, setUserDetails] = useState(null);
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const userId = _.get(sessionData, "userInfo.userId", "");

  const [firstName, setFirstName] = useState(userDetails?.FirstName);
  const [lastName, setLastName] = useState(userDetails?.LastName);
  const [license, setLicense] = useState(userDetails?.LicenseNo);
  const [company, setCompany] = useState(userDetails?.Company);
  const [location, setLocation] = useState(userDetails?.Location);
  const [bio, setBio] = useState(userDetails?.Bio);
  const [isDisableButton, setDisable] = useState(true);
  const [bioCount, setBioCount] = useState(0);
  const [imageUrl, setImageUrl] = useState(userDetails?.Img);
  const [isUploading, setIsUploading] = useState(false);
  const [imgError, setImgError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [licenseError, setLicenseError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [bioError, setBioError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [isRealEstateAgent, setIsRealEstateAgent] = useState(
    userDetails?.Agent_Title === REAL_ESTATE_AGENT ||
      userDetails?.Agent_Title === BOTH_TITLE
      ? true
      : false
  );
  const [isPropertyManager, setIsPropertyManager] = useState(
    userDetails?.Agent_Title === PROPERTY_MANAGER ||
      userDetails?.Agent_Title === BOTH_TITLE
      ? true
      : false
  );

  const s3ProfileImageFolder = s3ImageBaseFolder() + "/profileImage/Images/";

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const DeleteModalClose = () => setShowDeleteModal(false);
  const DeleteModalShow = () => setShowDeleteModal(true);

  
  const [bioCursorPos, setBioCursorPos] = useState(0);
  const bioRef = useRef();

  const [phone, setPhone] = useState(userDetails?.Phone);
  const [phoneError, setPhoneError] = useState("");

  const [changedImage, setChangedImage] = useState(null);
  const [showPofileImageUploadModal, setShowPofileImageUploadModal] = useState(false);
  const ShowPofileImageUploadModalClose = () => setShowPofileImageUploadModal(false);
  const ShowPofileImageUploadModalShow = () => setShowPofileImageUploadModal(true);

  useEffect(()=>{
    setPhone(userDetails?.Phone)
  }, [userDetails?.Phone])

  const handleFirstName = (name) => {
    setFirstNameError("");
    setFirstName(name);
    setDisable(false);
    if (name.trim() === "") {
      setFirstNameError("Enter First Name");
      setDisable(true);
    } else if (!nameValidation(name.trim())) {
      setDisable(true);
      setFirstNameError("Invalid input");
    }
  };

  const handleLastName = (name) => {
    setLastNameError("");
    setLastName(name);
    setDisable(false);
    if (name.trim() === "") {
      setLastNameError("Enter Last Name");
      setDisable(true);
    } else if (!nameValidation(name.trim())) {
      setLastNameError("Invalid input");
      setDisable(true);
    }
  };

  const handleLicenseChange = (name) => {
    setLicenseError("");
    setLicense(name);
    setDisable(false);
    if (name.trim() === "") {
      setDisable(true);
      setLicenseError("Enter License Number");
    } else if (!alphaNumericValidation(name.trim())) {
      setLicenseError("Invalid input");
      setDisable(true);
    }
  };

  const handleCompany = (name) => {
    setCompanyError("");
    setCompany(name);
    setDisable(false);
    if (name.trim() === "") {
      setDisable(true);
      setCompanyError("Enter Company");
    } else if (!alphaNumericValidation(name.trim())) {
      setCompanyError("Invalid input");
      setDisable(true);
    }
  };

  useEffect(() => {
    if(bioRef.current){
      bioRef.current.setSelectionRange(bioCursorPos, bioCursorPos);
    }
   
  }, [bioCursorPos]);

  const handleBio = (e) => {
    let text = e.target.value;
    text = upperCaseEachSentenceFirst(text);
    setBioError("");
    // setBio(upperCaseEachSentenceFirst(text));
    
    if(trimString(text) != ""){
      setBioCursorPos(e.target.selectionStart)
    }  
    setBio((text));
    setDisable(false);
    setBioCount(text.length);
  };

  const handleLocationChange = (name) => {
    setLocationError("");
    setLocation(name);
    if (name.trim() === "") {
      setLocationError("Enter Location");
      setDisable(true);
    } else if (!textValidations(name.trim())) {
      setLocationError("Invalid input");
      setDisable(true);
    }
  };

  
  const handlePhone = (value) => {
    setDisable(false);
    setPhoneError("");
    if (trimString(value) !== "") {
      value = formatPhoneNumber(value);
        setPhone(value);
    } else {
      setDisable(true);
      setPhoneError("Enter Phone Number");
      setPhone("");
    }
  };

  const validatePhoneNumber = ()=>{
    setDisable(false);
    setPhoneError("");
    if (trimString(phone) !== "") {
      if(!phoneNumberValidation(phone.trim())){
        setPhoneError("Enter Valid Phone Number");
        setDisable(true);
      } else {
        setDisable(false);
        setPhone(phone);
      }
    } else {
      setPhoneError("Enter Phone Number");
      setPhone("");
    }

  }

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchUserDetails()
      );
    }
  }, []);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[0]);
      setFirstName(userDetailsFromStore[0]?.FirstName);
      setLastName(userDetailsFromStore[0]?.LastName);
      setLicense(userDetailsFromStore[0]?.LicenseNo);
      setCompany(userDetailsFromStore[0]?.Company);
      setLocation(userDetailsFromStore[0]?.Location);
      setBio(userDetailsFromStore[0]?.Bio);
      setImageUrl(userDetailsFromStore[0]?.Img);
      setIsRealEstateAgent(
        userDetailsFromStore[0]?.Agent_Title === REAL_ESTATE_AGENT ||
          userDetailsFromStore[0]?.Agent_Title === BOTH_TITLE
          ? true
          : false
      );
      setIsPropertyManager(
        userDetailsFromStore[0]?.Agent_Title === PROPERTY_MANAGER ||
          userDetailsFromStore[0]?.Agent_Title === BOTH_TITLE
          ? true
          : false
      );
    } else {
      setUserDetails(null);
    }
  }, [userDetailsFromStore]);

  const getTitleOf = () => {
    if (isPropertyManager && isRealEstateAgent) {
      return 3;
    } else if (isPropertyManager) {
      return 2;
    } else {
      return 1;
    }
  };

  const handleImageUpload = async (e) => {
  // setIsUploading(true);
  const selectedFileType = getFileType(_.get(e.target.files[0], "name"));
  const file = e.target.files[0];

  setImgError("");
  let isValid = true;
  if (!_.includes(supportedImageFormats, selectedFileType)) {
    isValid = false;
    // setIsUploading(false);
    setImgError("Select Valid Image");
  }
  if (isValid) {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setChangedImage(reader.result)
        ShowPofileImageUploadModalShow();
      };
      reader.readAsDataURL(file);
      // Reset the file input
      e.target.value = null;

    }
  }

    // setIsUploading(true);
    // const selectedFileType = getFileType(_.get(e.target.files[0], "name"));
    // const fileSize = _.get(e.target.files[0], "size");
    // const file = e.target.files[0];
    // setImgError("");
    // let isValid = true;
    // if (!_.includes(supportedImageFormats, selectedFileType)) {
    //   isValid = false;
    //   setIsUploading(false);
    //   setImgError("Select Valid Image");
    // }
    // if (isValid) {
    //   const resizedImage =
    //     fileSize > MAX_FILE_SIZE
    //       ? await reSizeImage({
    //           file: file,
    //           width: MIN_WIDHT,
    //           height: MIN_HEIGHT,
    //           fileType: selectedFileType,
    //         })
    //       : file;
    //   const reqPayload = {
    //     key:
    //       s3ProfileImageFolder + userDetails?.UserId + "." + selectedFileType,
    //   };

    //   await getPreSignedUrl(reqPayload)
    //     .then((response) => {
    //       if (response.errorCode === 0) {
    //         try {
    //           const headersValue = new Headers({
    //             "Content-Type": resizedImage.type,
    //           });
    //           fetch(response.result, {
    //             method: "PUT",
    //             headers: headersValue,
    //             body: resizedImage,
    //           })
    //             .then((response) => {
    //               const status = _.get(response, "status", "");
    //               const fileFullUrl = _.get(response, "url", "");
    //               const fileS3Url = fileFullUrl
    //                 ? fileFullUrl?.split("?")[0]
    //                 : ""; // For excluding Aws credentials from the url and access s3 image Url.

    //               if (status === 200) {
    //                 setImageUrl(fileS3Url);
    //                 if (imageUrl == fileS3Url) {
    //                   toast.success("Profile image changed successfully");
    //                 }
    //                 setDisable(false);
    //                 setIsUploading(false);
    //               }
    //             })
    //             .catch((error) => {
    //               setIsUploading(false);
    //               toast.error(error);
    //             });
    //         } catch (error) {
    //           setIsUploading(false);
    //           toast.error(error);
    //         }
    //       } else {
    //         setIsUploading(false);
    //         toast.dismiss();
    //         toast.error(response.errorMessage);
    //       }
    //     })
    //     .catch((err) => {
    //       setIsUploading(false);
    //       toast.dismiss();
    //       toast.error(err);
    //     });
    // }
  };

  const handleSubmit = async () => {
    const data = {
      FirstName: firstName,
      LastName: lastName,
      Company: company,
      About: bio,
      Phone: phone,
      Img: imageUrl,
      Title: getTitleOf(),
      Location: location,
      LicenseNo: license,
    };
    await editProfilePremiumAgent(data)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.success("User's profile updated successfully.");
            const userInfo = getLocalStorage(USER_INFO_KEY);
            userInfo.userInfo["firstName"] = firstName;
            userInfo.userInfo["lastName"] = lastName;
            userInfo.userInfo["name"] =firstName+" "+lastName;
            userInfo.userInfo["image"] = imageUrl;
            userInfo.userInfo["userType"] = USER_TYPE_AGENT;
            setLocalStorage(USER_INFO_KEY, userInfo);

          if (userId) {
            dispatch(fetchUserDetails());
          }
          navigate(pageURLs.mobileAccountSettings);
        } else {
          toast.error(response.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-78">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <div className="inner-layout-header-new bg-white ">
            <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="back-link-btn"
                  href={void 0}
                  onClick={() => navigate(pageURLs.mobileAccountSettings)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>
                <div className="heading">Edit Profile</div>
                <div class="p-0 toplinks-mobile">
                  <div className="topbar-links notif-delete-btn d-none"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="inner-layout-content-new h-100 ">
            { userDetails?.UserTypeId === 2 && userDetails?.period === 1 &&
              <div className="">
                <div className="profile-pro-premium-innerwrap text-center">
                  <p>Want to add a profile photo and bio to your profile? </p>
                  <h4 className="">
                    Upgrade to <span>Gitcha Pro </span>Member   <img className="align-text-baseline mb-1" src={SparkleBigIcon} />
                  </h4>
                </div>
              </div>
            } 
            <div className="settings-wrap-mob min-h-100 bg-grey-variant pl-16 pr-16">
              <div className="profile-wrap">
                { (userDetails?.UserTypeId === 2 && userDetails?.period === 1) ?
                  <div className="profile-wrap-img">
                    <figure>
                      <img src={ProfImgPlaceholder} />
                    </figure>
                    <div className="file-upload">
                      {/* <input type="file" name="file-3[]" id="file-3" className="inputfile inputfile-3" data-multiple-caption="{count} files selected" multiple="" /> */}
                      <label for="file-3" className="bgGreen">
                        <img className="align-text-baseline mb-1" src={SparkleBigIcon} />
                      </label>
                    </div>
                  </div>
                :
                  <div className="profile-wrap-img">
                    <figure className="d-flex align-items-center justify-content-center">
                      {/* <img src={ProfilePhoto} /> */}
                      {imageUrl !== "" ? (
                        <img src={imageUrl + "?" + Math.random().toString(36)} />
                      ) : (
                        <svg
                          width="24"
                          height="23"
                          viewBox="0 0 24 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                            fill="#A0A9BA"
                          />
                        </svg>
                      )}
                    </figure>
                    <div className="file-upload">
                      <input
                        type="file"
                        name="file-3[]"
                        id="file-3"
                        className="inputfile inputfile-3"
                        data-multiple-caption="{count} files selected"
                        multiple=""
                        onChange={(e) => handleImageUpload(e)}
                      />
                      <label for="file-3">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.6464 1.64645C11.8417 1.45118 12.1583 1.45118 12.3536 1.64645L14.3536 3.64645C14.5488 3.84171 14.5488 4.15829 14.3536 4.35355L8.35355 10.3536C8.25979 10.4473 8.13261 10.5 8 10.5H6C5.72386 10.5 5.5 10.2761 5.5 10V8C5.5 7.86739 5.55268 7.74021 5.64645 7.64645L11.6464 1.64645ZM6.5 8.20711V9.5H7.79289L13.2929 4L12 2.70711L6.5 8.20711Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.1464 3.14645C10.3417 2.95118 10.6583 2.95118 10.8536 3.14645L12.8536 5.14645C13.0488 5.34171 13.0488 5.65829 12.8536 5.85355C12.6583 6.04882 12.3417 6.04882 12.1464 5.85355L10.1464 3.85355C9.95118 3.65829 9.95118 3.34171 10.1464 3.14645Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8.5C8.77614 2 9 2.22386 9 2.5C9 2.77614 8.77614 3 8.5 3L3 3L3 13H13V7.5C13 7.22386 13.2239 7 13.5 7C13.7761 7 14 7.22386 14 7.5V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289Z"
                            fill="white"
                          />
                        </svg>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="translate(-2 -2)"><path d="M2,8a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,0,1H2.5A.5.5,0,0,1,2,8Z" fill="#fff" fill-rule="evenodd"/><path d="M8,2a.5.5,0,0,1,.5.5v11a.5.5,0,0,1-1,0V2.5A.5.5,0,0,1,8,2Z" fill="#fff" fill-rule="evenodd"/></g></svg>
                        */}
                      </label>
                    </div>
                  </div>
                }
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <label className="form-label fnt14">First Name</label>
                  {/* <input type="email" className="form-control mob-pr-44"/> */}
                  <TextBox
                    id="fName"
                    name="firstName"
                    type="text"
                    maxLength={30}
                    value={toTitleCase(firstName)}
                    placeholder="First name"
                    className="form-control mob-pr-44"
                    onChange={(e) => handleFirstName(e.target.value)}
                    errorClass="error-validation-msg"
                    errorMessage={firstNameError}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label fnt14">Last Name</label>
                  {/* <input type="email" className="form-control mob-pr-44"/> */}
                  <TextBox
                    id="lName"
                    name="lastName"
                    maxLength={30}
                    value={toTitleCase(lastName)}
                    type="text"
                    placeholder="Last name"
                    className="form-control mob-pr-44"
                    errorClass="error-validation-msg"
                    onChange={(e) => handleLastName(e.target.value)}
                    errorMessage={lastNameError}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  {/* <input type="checkbox" class="form-check-input" id="exampleCheck1"/> */}
                  <CheckBox
                    class="form-check-input"
                    type="checkbox"
                    id="realestate_agent"
                    checked={isRealEstateAgent}
                    onChange={(e) => setIsRealEstateAgent(!isRealEstateAgent)}
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Real Estate agent
                  </label>
                </div>


                <div className="col-6">
                  {/* <input type="checkbox" class="form-check-input" id="exampleCheck2"/> */}
                  <CheckBox
                    class="form-check-input"
                    type="checkbox"
                    id="property_manager"
                    checked={isPropertyManager}
                    onChange={(e) => setIsPropertyManager(!isPropertyManager)}
                  />
                  <label class="form-check-label" for="exampleCheck2">
                    Property Manager
                  </label>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                {userDetails?.UserTypeId === 2 && userDetails?.period === 1 ?
                    <label className="form-label fnt14">Phone Number{" "}<img className="align-text-baseline mb-1" src={SparkleBigIcon} /></label>
                    :
                    <label className="form-label fnt14">Phone Number</label>
                  }
                  <TextBox
                    id="phone"
                    name="phone"
                    type="text"
                    pattern="[0-9]*" 
                      inputmode="numeric"
                    value={phone}
                    className="form-control mob-pr-44"
                    errorClass="error-validation-msg"
                    onChange={(e) => handlePhone(e.target.value)}
                    onBlur={()=>{validatePhoneNumber()}}
                    errorMessage={phoneError}
                    placeholder="321-456-7891"
                    maxLength={14}
                    disabled={userDetails?.UserTypeId === 2 && userDetails?.period === 1}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <label className="form-label fnt14">City, State</label>
                  {/* <input type="email" className="form-control mob-pr-44" value="Eugene, OR"/> */}
                  <TextBox
                    id="location"
                    name="location"
                    type="text"
                    value={toTitleCase(location)}
                    className="form-control mob-pr-44"
                    errorClass="error-validation-msg"
                    onChange={(e) => handleLocationChange(e.target.value)}
                    errorMessage={locationError}
                    placeholder="City, State"
                    maxLength={40}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <label className="form-label fnt14">Company/Agency</label>
                  {/* <input type="email" className="form-control mob-pr-44" value="DC Real Estate"/> */}
                  <TextBox
                    id="company"
                    name="company"
                    type="text"
                    value={toTitleCase(company)}
                    className="form-control mob-pr-44"
                    errorClass="error-validation-msg"
                    onChange={(e) => handleCompany(e.target.value)}
                    errorMessage={companyError}
                    placeholder="Company name"
                    maxLength={40}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <label className="form-label fnt14">License Number</label>
                  {/* <input type="email" className="form-control mob-pr-44" value="123456789"/> */}
                  <TextBox
                    id="license"
                    name="license"
                    type="text"
                    value={license}
                    className="form-control mob-pr-44"
                    errorClass="error-validation-msg"
                    onChange={(e) => handleLicenseChange(e.target.value)}
                    errorMessage={licenseError}
                    placeholder="License Number"
                    maxLength={40}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="textbox-wrap col-12">
                  {userDetails?.UserTypeId === 2 && userDetails?.period === 1 ?
                    <label className="form-label fnt14">Bio{" "}<img className="align-text-baseline mb-1" src={SparkleBigIcon} /></label>
                    :
                    <label className="form-label fnt14">Bio</label>
                  }
                  {/* <textarea class="form-control txtAreaStyle" id="exampleFormControlTextarea1" rows="6" value="Lorem ipsum dolor sit amet consectetur. Bibendum tristique ultricies et amet at posuere nulla senectus. Quis diam rhoncus nunc elementum. Feugiat in pretium sem accumsan libero est. Sed amet ultrices vestibulum lectus." ></textarea> */}
                  <TextArea
                    className="form-control txtAreaStyle"
                    maxLength={500}
                    value={bio}
                    errorMessage={bioError}
                    placeholder="A few sentences about yourself..."
                    errorClass="error-validation-msg"
                    onChange={(e) => handleBio(e)}
                    disabled={userDetails?.UserTypeId === 2 && userDetails?.period === 1}
                    txtRef={bioRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-primary w-100"
                disabled={isDisableButton}
                onClick={() => handleSubmit()}
              >
                Update
              </button>
            </div>
          </div>
        </div> */}
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-3 pt-0">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isDisableButton}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ProfileImageCropper
        userId={userId}
        image={changedImage}
        show={showPofileImageUploadModal}
        onHide={() => { ShowPofileImageUploadModalClose(); }}
        updateImageAfterCrop={(imageUrl) => { setImageUrl(imageUrl); setDisable(false); ShowPofileImageUploadModalClose();}}
      />
    </>
  );
};

export default ProfileAgentUserPremiumEdit;
