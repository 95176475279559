import { getInitials, removeLocalStorage } from "../../../helpers/common";
import Offcanvas from 'react-bootstrap/Offcanvas';
import AddIcon from "../addIcn";
import { useState } from "react";
import AddNewClient from "../../commonComponents/ClientPopup";


const SelectClient = (props) => {
  const { selectedClient, setSelectedClient, clientList, setShowSelectClients, showSelectClients, reloadClientList } = props;


  const [inputSearchValue, setInputSearchValue] = useState('');
  const [showAddNewClient, setAddNewClientShow] = useState(false);

  const handleSearchChange = (event) => {
      const searchtext = event.target.value;
      setInputSearchValue(searchtext);
      reloadClientList(searchtext)
    
  };

 
  const handleAddNewClientClose = () => { 
      setAddNewClientShow(false);
     reloadClientList()
  }
  const handleAddNewClientShow = () => setAddNewClientShow(true);

  return (
<>
    <Offcanvas
     className="mobile-offcanvas zIndexPopup offcanvas-selectclient-height-fix offcanvas-filter-share-fix"
      show={showSelectClients}
      onHide={setShowSelectClients}
      placement='bottom'
      {...props}> 
    <Offcanvas.Header className="filter-offcanvas-header">
    <div className="share-yourad-topsec justify-content-center w-100 pt-32 mb-20">
        <div className="share-yourad-heading w-100 mt-0 mb-0 ">Select Client</div>     
      </div>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <div className="offcanvas-sharead-wrap ">
        
          
          <div className="popupStyle">
 
            <div className="ps-3 pe-3 pb-3">
           
              <div className="search_field_input position-relative mb-0 input-group d-flex ">

                <input aria-label="Example" aria-describedby="basic-addon3" placeholder="Search clients" class="form-control pr-0" 
                onChange={(e)=>{handleSearchChange(e)}}
                ></input> 
              
                <button type="button" id="button-addon3" class="search-icon "></button>            
                
              </div>
            </div>
           
            <div>
            <div class="modal-choose-property mb-0 ">
           
            {clientList && clientList?.map((client) => {
                      return (

                        <label class="property-radio">
                          <input type="radio" name="check-box-option" id="0"
                            onChange={() => {
                              setSelectedClient(client);
                              removeLocalStorage("selectedPropertyId")
                              props.setShowSelectClients(false);
                            }}
                            checked={selectedClient?.value === client?.value}
                            value={client?.value} />

                          <span className="bdrRadius-0 ps-3 pe-3 ">
                            <div class="d-flex w-100">
                              <div className={" profileIcnDiv me-2 align-items-center " +
                                getInitials(client?.FirstName + " " + client?.LastName)
                                  ?.backgroundColor
                              }> {
                                  getInitials(client?.FirstName + " " + client?.LastName)
                                    ?.initials
                                }</div>
                              <div className="labelTxtName">  {client?.FirstName + " " + client?.LastName}</div>
                            </div>
                          </span>
                        </label>
                      )
                    })}
            </div>
            </div> 
         </div>
           
        
          <div className="inner-btm-fixed-nav filter-btm-fixed-btnswrap">
            <div className="btn-wrap text-center pt-0">
              {/* <button type="button" className="btn btn-primary addNew  w-100 mb-4 ">Add New Client <AddIconWhite/></button> */}

              <button type="button" className="btn btn-primary addNew w-100 mb-40 add-new-client-btn" onClick={handleAddNewClientShow}>Add New Client <AddIcon/></button>
             
            </div>
          </div>
      </div>
    </Offcanvas.Body>
  </Offcanvas>

  <AddNewClient show={showAddNewClient} onHide={handleAddNewClientClose} clientDetails={null} isEdit={false} />

  </>


    // <Offcanvas
    //   className="mobile-offcanvas zIndexPopup ps-0 pe-0"
    //   // show={show}
    //   // onHide={onHide}
    //   placement='bottom'
    //   // {...props}
    //   >
    //   <Offcanvas.Header className="d-none" closeButton>
    //     <Offcanvas.Title>Offcanvas</Offcanvas.Title>
    //   </Offcanvas.Header>
    //   <Offcanvas.Body>

    //     <Offcanvas
    //       className="mobile-offcanvas zIndexPopup ps-0 pe-0"
    //       show={show}
    //       onHide={onHide}
    //       placement='bottom'
    //       {...props}>
    //       <Offcanvas.Header className="d-none" closeButton>
    //         <Offcanvas.Title>Offcanvas</Offcanvas.Title>
    //       </Offcanvas.Header>
    //       <Offcanvas.Body>
    //         <div className="offcanvas-sharead-wrap ">
    //           <div className="share-yourad-topsec mb-4">
    //             <div className="share-yourad-heading mt-2 mb-2">Select Client</div>
    //           </div>

    //           <div className="popupStyle">

    //             <div className="ps-3 pe-3 pb-3">

    //               <div className="search_field_input position-relative mb-0 input-group d-flex ">

    //                 <input aria-label="Example" aria-describedby="basic-addon3" placeholder="Enter zip code or city and state" class="form-control pr-0" value="Search Clients"></input>

    //                 <button type="button" id="button-addon3" class="search-icon "></button>

    //               </div>
    //             </div>

    //             <div>
    //               <div class="modal-choose-property mb-0 ">
    //                 {clientList?.map((client) => {
    //                   return (

    //                     <label class="property-radio">
    //                       <input type="radio" name="check-box-option" id="0"
    //                         onChange={() => {
    //                           setSelectedClient(client);
    //                           removeLocalStorage("selectedPropertyId")
    //                           props.setShowSelectClients(false);
    //                         }}
    //                         checked={selectedClient?.value === client?.value}
    //                         value={client?.value} />

    //                       <span className="bdrRadius-0 ps-3 pe-3 ">
    //                         <div class="d-flex w-100">
    //                           <div className={" profileIcnDiv me-2 align-items-center" +
    //                             getInitials(client?.FirstName + " " + client?.LastName)
    //                               ?.backgroundColor
    //                           }> {
    //                               getInitials(client?.FirstName + " " + client?.LastName)
    //                                 ?.initials
    //                             }</div>
    //                           <div className="labelTxtName">  {client?.FirstName + " " + client?.LastName}</div>
    //                         </div>
    //                       </span>
    //                     </label>
    //                   )
    //                 })}
    //               </div>


    //             </div>



    //           </div>


    //           <div className="inner-btm-fixed-nav">
    //             <div className="btn-wrap text-center  pt-3">
    //               {/* <button type="button" className="btn btn-primary addNew  w-100 mb-4 ">Add New Client <AddIconWhite/></button> */}

    //               <button type="button" className="btn btn-primary addNew disabled w-100 mb-4 ">Add New Client <AddIcon /></button>

    //             </div>
    //           </div>


    //         </div>


    //       </Offcanvas.Body>
    //     </Offcanvas>

        );
};

        export default SelectClient;
