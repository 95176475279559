import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const AcceptIntroModal = (props) => {
  const {show, onHide} = props;
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup not-fit-modal">
        <Modal.Body className="text-center">
        <i className="success-icon"></i>
        <h3>Introduction Accepted!</h3>
        <p>You have <span>72 hours</span> to start a conversation with Peter before the introduction expires. 
            You can view all of the property details and send your first message. </p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={()=>{props.onClickViewDetails()}}>Send Message</button>
            <button type="button" className="btn btn-secondary w-100" onClick={()=>{props.onClickViewDetails()}}>View Property Details</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default AcceptIntroModal;
