/**
 * @file   Source/gitcha_Web/src/mobile/components/GitchaContentHeaderMob.js
 * @brief  This file is responsible for handling tab contents in General and Match view
 * @date   April, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import GitchaHeaderTabMob from "../components/GitchaHeaderTabMob";

import useScrollDirection from "../components/useScrollDirection";
import { SCROLL_DIRECTION_DOWN, SCROLL_DIRECTION_UP } from "../../constants/common";

const GitchaContentHeaderMob = (props) => {
  const {
    updateFilter,
    filterQuery,
    clearFilterQuery,
    updateFilterGitchaGenie,
    handleGitchGenieFilter,
    handleFilterApply,
    setSearchView,
    isSearchView,
    showFilterPopup,
    handleFilterPopUpClose,
    handleFilterPopUpShow,
    setUserLocation,
    userLocation,
    gitchaNotAvailble,
    searchedLocation,
    setFilterQueryForApply,
    filterQueryForApply,
    scrollDirection,
    setHeight,
    wantAdCount,
    setAdType,
    divRef,
    showNotificationIcon,
    setNotificationIcon,
    setShowNoPropertyState,
    handleStayRequestPopupOpen,
    handleGitchaNotAvailableLocation,
  } = props;
  


  // useLayoutEffect(() => {
  //   setHeight(divRef.current.clientHeight);
  // }, [setHeight]);
  let className = "tab-cover-wrap"
  if(wantAdCount >0){
    if(scrollDirection === SCROLL_DIRECTION_DOWN){
      className = "tab-cover-wrap hide-top"
    } else if(scrollDirection === SCROLL_DIRECTION_UP) {
      className = "tab-cover-wrap"
    }
  }

  
  
  return (
    <div ref={divRef} className={className+" match-tutorial top-87"}>
      {showNotificationIcon && <div className="ring-container-mob">
            <div className="circlebtn">
              <div className="ringring"></div>
            </div>
           </div>}
      {/*<div className={'tab-cover-wrap ${ scrollDirection === "down" ? "hide" : "show"}'}>*/}

      <GitchaHeaderTabMob
        updateFilter={updateFilter}
        filterQuery={filterQuery}
        clearFilterQuery={clearFilterQuery}
        updateFilterGitchaGenie={updateFilterGitchaGenie}
        handleFilterApply={handleFilterApply}
        handleGitchGenieFilter={handleGitchGenieFilter}
        setSearchView = {setSearchView}
        isSearchView  = {isSearchView}
        showFilterPopup = {showFilterPopup}
        handleFilterPopUpClose = {handleFilterPopUpClose}
        handleFilterPopUpShow = {handleFilterPopUpShow}
        setUserLocation={setUserLocation}
        userLocation={userLocation}
        gitchaNotAvailble={gitchaNotAvailble}
        searchedLocation={searchedLocation}
        setFilterQueryForApply = {setFilterQueryForApply}
        filterQueryForApply = {filterQueryForApply}
        divRef = {divRef}
        setHeight = {setHeight}
        setAdType = {setAdType}
        showNotificationIcon={showNotificationIcon}
        setNotificationIcon={setNotificationIcon}
        setShowNoPropertyState={setShowNoPropertyState}
        handleStayRequestPopupOpen={handleStayRequestPopupOpen}
        handleGitchaNotAvailableLocation={handleGitchaNotAvailableLocation}
      />
      {/*<div className={'tab-cover-wrap ${ scrollDirection === "down" ? "hide" : "show"}'}>*/}
    </div>
  );
};

export default GitchaContentHeaderMob;
