import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { capitalizeString, handleTextBoxChange } from "../../../actions/common";
import RadioButtonList from "../../Common/RadioButtonList";
import {
  createS3Folder,
  getLocalStorage,
  getUserId,
  itemList,
  replaceComma,
  thousandSeperatedValue,
  trimString,
} from "../../../helpers/common";

import { convertToNumber } from "../../../helpers/validations";
import InventoryLocation from "./InventoryLocation";
import {
  DEFAULT_MAP_ZOOM,
  INVENTORY_KEY,
  INVENTORY_RT_PRVT_DRIVE,
  INVENTORY_WS_DRLLD_WELL,
  INVENTORY_WWS_SEPTIC_SYS,
  LOT_TYPES,
  ROAD_TYPES,
  WASTE_WATER_SYSTEM,
  WATER_SUPPLY,
  YES_NO_OPTIONS,
} from "../../../constants/common";
import TextBox from "../../Common/TextBox";
import { getGeoLocation } from "../../../services/commonServices";
import GeoLocation from "../../Common/GeoLocation";
import { usePrevious } from "../../../hooks/usePrevious";
import SelectDropDown from "../../Common/SelectDropDown";

const InventoryLotLandLocation = (props) => {
  const {
    handleFormValues,
    locationAddress,
    acreage,
    lotType,
    roadType,
    isPowerAccess,
    waterSuply,
    wasteWtrSys,
    isPrvtDrInst,
    isDrldWelInst,
    isDrldWelAppr,
    isSepTankInst,
    isSepTankAppr,
    isApprovedStrctr,
    isInFloodPlain,
    isEdit = false,
    lat,
    lng,
    mapZoom,
    isAgentCreated = false,
    s3Imagefolder,
    acre_Sqft,
    lotsize,
    validationErrors,
    mapType,
    locationError,
    sessionKey
  } = props;

  const RT_PVT_DRIVE_INSTALL = "isPrvtDrInst";
  const WS_DRILLED_WELL_INSTALL = "isDrldWelInst";
  const WS_DRILLED_WELL_APPRRV = "isDrldWelAppr";
  const WWS_SEPT_TANK_INSTALL = "isSepTankInst";
  const WWS_SEPT_TANK_APPROV = "isSepTankAppr";
  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_MAP_ZOOM,
  };

  const poweAccessSection = "isPowerAccess";
  const approvedStrctrSection = "isApprovedStrctr";
  const floodPlainSection = "isInFloodPlain";

  const [isPropertyDataExist, setDataExistFlag] = useState(false);

  useEffect(() => {
    if (!locationAddress) {
      const inventoryDetails = getLocalStorage(INVENTORY_KEY);
      const s3Folder = s3Imagefolder;
      const userId = getUserId();
      if (!s3Folder) {
        getFolderName();
      }
    }
    
    if(acre_Sqft && acreage){
      setDataExistFlag(true)
    }

  }, []);

  const getFolderName = async (userId) => {
    const isWantAd = false;
    await createS3Folder(isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };

  const updateValuesToState = (key, value) => {
    handleFormValues({ [key]: value });
  };

  const handleAcreageChanges = (e) => {
    const value = handleTextBoxChange(e);
    updateValuesToState("acreage", replaceComma(value));
  };

  const handleLotSizeChanges = (e) => {
    const value = handleTextBoxChange(e);
    updateValuesToState("acreage", replaceComma(value));
    updateValuesToState("acre_Sqft", acre_Sqft ? acre_Sqft : 1);
  };

  const handleLotSizeTypeSelection = (e) =>{
    const values = _.get(e, "value", "");
    updateValuesToState("acre_Sqft", values);
  }
  const handleLotTypeChange = (e) => {
    const selectedValue = convertToNumber(e.target.value);
    updateValuesToState("lotType", selectedValue);
  };

  const handleRoadTypeChange = (e) => {
    const selectedValue = convertToNumber(e.target.value);
    let stateToBeUpdated = {};
    if (selectedValue === roadType) {
      stateToBeUpdated = { roadType: "", isPrvtDrInst: "" };
    } else {
      stateToBeUpdated = { roadType: selectedValue, isPrvtDrInst: "" };
    }
    handleFormValues(stateToBeUpdated);
  };

  const handleWaterSupplyChange = (e) => {
    const selectedValue = convertToNumber(e.target.value);
    let stateToBeUpdated = {};
    if (selectedValue === waterSuply) {
      stateToBeUpdated = {
        waterSuply: "",
        isDrldWelInst: "",
        isDrldWelAppr: "",
      };
    } else {
      stateToBeUpdated = {
        waterSuply: selectedValue,
        isDrldWelInst: "",
        isDrldWelAppr: "",
      };
    }
    handleFormValues(stateToBeUpdated);
  };
  const handleWasteWaterChange = (e) => {
    const selectedValue = convertToNumber(e.target.value);
    let stateToBeUpdated = {};
    if (selectedValue === wasteWtrSys) {
      stateToBeUpdated = {
        wasteWtrSys: "",
        isSepTankAppr: "",
        isSepTankInst: "",
      };
    } else {
      stateToBeUpdated = {
        wasteWtrSys: selectedValue,
        isSepTankAppr: "",
        isSepTankInst: "",
      };
    }
    handleFormValues(stateToBeUpdated);
  };

  const handleBooleanOptionSelection = (e, section) => {
    const selectedValue = e.target.value;
    switch (section) {
      case poweAccessSection:
        if (selectedValue === isPowerAccess) {
          updateValuesToState(poweAccessSection, "");
        } else {
          updateValuesToState(poweAccessSection, selectedValue);
        }
        break;
      case approvedStrctrSection:
        if (selectedValue === isApprovedStrctr) {
          updateValuesToState(approvedStrctrSection, "");
        } else {
          updateValuesToState(approvedStrctrSection, selectedValue);
        }
        break;
      case floodPlainSection:
        if (selectedValue === isInFloodPlain) {
          updateValuesToState(floodPlainSection, "");
        } else {
          updateValuesToState(floodPlainSection, selectedValue);
        }
        break;
    }
  };

  const loadBooleanOptioLists = (section) => {
    const itemList = YES_NO_OPTIONS;
    let isCheckedYes = false;
    let isCheckedNo = false;
    switch (section) {
      case poweAccessSection:
        if (isPowerAccess === "true" || isPowerAccess === 1) {
          isCheckedYes = true;
        } else if (isPowerAccess === "false" || isPowerAccess === 0) {
          isCheckedNo = true;
        }
        break;
      case approvedStrctrSection:
        if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
          isCheckedYes = true;
        } else if (isApprovedStrctr === "false" || isApprovedStrctr === 0) {
          isCheckedNo = true;
        }
        break;
      case floodPlainSection:
        if (isInFloodPlain === "true" || isInFloodPlain === 1) {
          isCheckedYes = true;
        } else if (isInFloodPlain === "false" || isInFloodPlain === 0) {
          isCheckedNo = true;
        }
        break;
    }

    return (
      <>
        <div className="col-md-6">
          <label className="radio-wrap mb-3">
            <input
              name={`${section}-radio`}
              type="radio"
              value={_.get(itemList[0], "value", "")}
              checked={isCheckedYes}
              onClick={(e) => handleBooleanOptionSelection(e, section)}
            />
            <span>
              <div className="timing-wrap-info">
                <h2 className="pb-0">{_.get(itemList[0], "text", "")}</h2>
              </div>
            </span>
          </label>
        </div>

        <div className="col-md-6">
          <label className="radio-wrap mb-3">
            <input
              name={`${section}-radio`}
              type="radio"
              value={_.get(itemList[1], "value", "")}
              checked={isCheckedNo}
              onClick={(e) => handleBooleanOptionSelection(e, section)}
            />
            <span>
              <div className="timing-wrap-info">
                <h2 className="pb-0">{_.get(itemList[1], "text", "")}</h2>
              </div>
            </span>
          </label>
        </div>
      </>
    );
  };

  const handleCheckBoxSelection = (e) => {
    const key = e.target.name;
    handleFormValues({ [key]: e.target.checked });
  };

  const options = [
    {value: 1,  label: 'Acres'},
    {value: 2,  label: 'SQFT'}

  ]

  const filterLotSizeArray = (value) => {
    if (options && value) {
      const filterValue = options.filter(function (lst) {
        return lst.value === value;
      });
      return filterValue;
    }
    return null;
  };

  const handlePropertyExist = ({isExist}) => {
    setDataExistFlag(isExist)
    handleFormValues({ invalidLocationExist: true });
  }

  return (
    <>
      {(!locationAddress &&!lat&&!lng) && <GeoLocation handleFormValues={handleFormValues} />}

      <>
        <div className="wantad-location-wrap mb-3">
          <InventoryLocation
            defaultProps={defaultProps}
            locationAddress={locationAddress}
            handleFormValues={handleFormValues}
            updateValuesToState={updateValuesToState}
            isLot_Land =  {true}
            isAgentCreated={isAgentCreated}
            handlePropertyExist  ={handlePropertyExist}
            validationErrors={validationErrors}
            mapType={mapType}
            locationError={locationError}
            sessionKey={sessionKey}
          ></InventoryLocation>
          <div className="row">
            <div className="col-md-12">
            <div className="common-input-wrap mb-0">
                <Form.Label className="mb-2">
                 Lot size
                </Form.Label>
                </div>
            </div>
            <div className="col-md-6">
              <div className="common-input-wrap mb-0">
                {/* <Form.Label className="mb-2">
                 Lot size
                </Form.Label> */}
                <TextBox
                  type="text"
                  class="form-control"
                  value={thousandSeperatedValue(acreage) || ""}
                  onChange={(e) => {
                    handleLotSizeChanges(e);
                  }}
                  placeholder="Total"
                  // disabled={isPropertyDataExist ? false : true}
                ></TextBox>
                {/* <div className="error-msg"></div> */}
              </div>
            </div>

            <div className="col-md-6">
              <div className="common-input-wrap mb-0">
                {/* <Form.Label className="mb-2">
                  Total acreage <span>*</span>
                </Form.Label> */}
                <SelectDropDown
                
                  name="colors3"
                  options={options||[]}
                  className="lotsize-select"
                  classNamePrefix="select"
                  value={filterLotSizeArray(acre_Sqft  ? acre_Sqft  : 1)}
                  onChange={(e) => handleLotSizeTypeSelection(e)}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform:
                        state.selectProps.menuIsOpen && "rotate(180deg)",
                    }),
                  }}
                  errorClass="error-msg error-msg-height"
                  // errorMessage={lotSizeMinError}
                  // disabled={isPropertyDataExist ? false : true}
                />
                {/* <div className="error-msg"></div> */}
              </div>
            </div>


          </div>
          <div className="wantad-timing-wrap">
            <div className="land-radio-wrap pb-4">
              <h6 className="red-marked-heading">
                Lot type <span>*</span>
              </h6>
              <div className="row">
                <RadioButtonList
                  list={itemList(LOT_TYPES)}
                  name="lt-radio"
                  seletedValue={lotType}
                  onClick={(e) => handleLotTypeChange(e)}
                />
              </div>
            </div>

            <div className="land-radio-wrap pb-4">
              <h6>What type of road leads to your property?</h6>
              <div className="row">
                <RadioButtonList
                  list={itemList(ROAD_TYPES)}
                  name="rt-radio"
                  seletedValue={roadType}
                  onClick={(e) => handleRoadTypeChange(e)}
                />
                {roadType === INVENTORY_RT_PRVT_DRIVE && (
                  <div className="col-md-6 offset-md-6">
                    <label
                      className={
                        !isEdit ? "check-wrap mb-0" : "check-wrap mt-3"
                      }
                    >
                      <input
                        type="checkbox"
                        name={RT_PVT_DRIVE_INSTALL}
                        onChange={handleCheckBoxSelection}
                        checked={isPrvtDrInst}
                      />
                      <span>
                        <div className="timing-wrap-info">
                          <h2 className="pb-0">Installed</h2>
                        </div>
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="land-radio-wrap pb-4">
              <h6>Do you have power within 100' of your property?</h6>
              <div className="row">
                {loadBooleanOptioLists(poweAccessSection)}
              </div>
            </div>

            <div className="land-radio-wrap pb-4">
              <h6>Water Supply</h6>
              <div className="row">
                <RadioButtonList
                  list={itemList(WATER_SUPPLY)}
                  name="ws-radio"
                  seletedValue={waterSuply}
                  onClick={(e) => handleWaterSupplyChange(e)}
                />
                {waterSuply === INVENTORY_WS_DRLLD_WELL && (
                  <>
                    <div className="col-md-6 offset-md-6">
                      <label
                        className={
                          !isEdit ? "check-wrap mb-3" : "check-wrap mt-3"
                        }
                      >
                        <input
                          type="checkbox"
                          name={WS_DRILLED_WELL_INSTALL}
                          onChange={handleCheckBoxSelection}
                          checked={isDrldWelInst}
                        />
                        <span>
                          <div className="timing-wrap-info">
                            <h2 className="pb-0">Installed</h2>
                          </div>
                        </span>
                      </label>
                    </div>

                    <div className="col-md-6 offset-md-6">
                      <label
                        className={
                          !isEdit ? "check-wrap mb-3" : "check-wrap mt-3"
                        }
                      >
                        <input
                          type="checkbox"
                          name={WS_DRILLED_WELL_APPRRV}
                          onChange={handleCheckBoxSelection}
                          checked={isDrldWelAppr}
                        />
                        <span>
                          <div className="timing-wrap-info">
                            <h2 className="pb-0">Approved</h2>
                          </div>
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="land-radio-wrap pb-4">
              <h6>Wastewater System </h6>
              <div className="row">
                <RadioButtonList
                  list={itemList(WASTE_WATER_SYSTEM)}
                  name="wws-radio"
                  seletedValue={wasteWtrSys}
                  onClick={(e) => handleWasteWaterChange(e)}
                />
                {wasteWtrSys === INVENTORY_WWS_SEPTIC_SYS && (
                  <>
                    <div className="col-md-6 offset-md-6">
                      <label
                        className={
                          !isEdit ? "check-wrap mb-3" : "check-wrap mt-3"
                        }
                      >
                        <input
                          type="checkbox"
                          name={WWS_SEPT_TANK_INSTALL}
                          onChange={handleCheckBoxSelection}
                          checked={isSepTankInst}
                        />
                        <span>
                          <div className="timing-wrap-info">
                            <h2 className="pb-0">Installed</h2>
                          </div>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 offset-md-6">
                      <label
                        className={
                          !isEdit ? "check-wrap mb-0" : "check-wrap mt-3"
                        }
                      >
                        <input
                          type="checkbox"
                          name={WWS_SEPT_TANK_APPROV}
                          onChange={handleCheckBoxSelection}
                          checked={isSepTankAppr}
                        />
                        <span>
                          <div className="timing-wrap-info">
                            <h2 className="pb-0">Approved</h2>
                          </div>
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="land-radio-wrap pb-4">
              <h6>Is your property approved for a structure?</h6>
              <div className="row">
                {" "}
                {loadBooleanOptioLists(approvedStrctrSection)}
              </div>
            </div>

            <div className="land-radio-wrap pb-4">
              <h6>Is your property on a flood plain?</h6>
              <div className="row">
                {loadBooleanOptioLists(floodPlainSection)}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default InventoryLotLandLocation;
