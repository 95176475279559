import React, { useState, useEffect } from "react";

import { FormControl, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import Dropdown from "react-bootstrap/Dropdown";
import CommonHeader from "../components/Onboarding/CommonHeader";

import InventoryImgFirst from "../images/Inventory-img-first.png";
import TimelineIcon from "../images/timeline-icon.svg";
import LakeViewIcon from "../images/lake-view-icon.svg";
import ConsumerIcon from "../images/consumer-icon.svg";
import InventoryLocationMap from "../images/inventory-location-map.png";
import SchoolSmallIcon from "../images/school-icon.svg";
import NeighborhoodIcon from "../images/neighborhood-icon.svg";
import ShareIcon from "../images/share-icon.svg";
import MoreIcon from "../images/more-icon.svg";
import InventorySuccessModal from "../components/Inventory/CreateInventory/InventorySuccessModal";
import { getInventoryById } from "../services/inventoryService";
import StrengthenIcon from "../images/ad-strengthen-icon.svg";
import AdIntroIcon from "../images/ad-intro-icon.svg";
import {
  INVENTORY_ALL_TYPE,
  INVENTORY_KEY,
  INVENTORY_LAND,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  IS_LAND,
  LOT_TYPES,
  ROAD_TYPES,
  WASTE_WATER_SYSTEM,
  WATER_SUPPLY,
} from "../constants/common";
import { pageURLs } from "../constants/pageURLs";
import { checkInventoryType } from "../helpers/inventory";
import { useNavigate } from "react-router-dom";
import {
  convertToCurrencySystem,
  filterArray,
  getLocalStorage,
  loggedInUser,
  setLocalStorage,
} from "../helpers/common";
import { convertToNumber } from "../helpers/validations";
import { propertyDocuments } from "../constants/createInventory";

const ViewInventory = (props) => {
  const navigate = useNavigate();
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showStrengthen, setShowStrengthen] = useState(false);
  const [inventoryDetails, setInventoryDetails] = useState();
  const propertyType = convertToNumber(
    _.get(inventoryDetails, "prprtyType", "")
  );
  const proprtyPurpose = _.get(inventoryDetails, "proprtyPurpose", []);
  const invType = checkInventoryType(proprtyPurpose);
  const nice2Have = _.get(inventoryDetails, "nice2Have", []);
  const typeBasedOnProperty =
    propertyType === IS_LAND ? INVENTORY_LAND : invType;
  const sellAskingPrice = _.get(inventoryDetails, "sellAskngPrice", "");
  const LTRAskngPrce = _.get(inventoryDetails, "LTRAskngPrce", "");
  const STRAskngPrce = _.get(inventoryDetails, "STRAskngPrce", "");
  const noBedRm = _.get(inventoryDetails, "noBedRm", "");
  const noBathRm = _.get(inventoryDetails, "noBathRm", "");
  const sqFt = _.get(inventoryDetails, "sqFt", "");
  const stories = _.get(inventoryDetails, "stories", "") === 0 ? null : _.get(inventoryDetails, "stories", "");

  const grgeSpots = _.get(inventoryDetails, "grgeSpots", "") === 0 ? null : _.get(inventoryDetails, "grgeSpots", "");
  const lotSize = _.get(inventoryDetails, "lotSize", "") === 0 ? null : _.get(inventoryDetails, "lotSize", "");
  const description = _.get(inventoryDetails, "invtryDetails", "");
  const salesPercnt = _.get(inventoryDetails, "salesPercnt", "");

  const handleSuccessModalClose = (isClosePopUp) => {
    const isStrengthen = getStateValues("showStrengthenSuccessModal");
    if (isStrengthen && !isClosePopUp) {
      alert("Not implemented");
    }
    setSuccessModal(false);
    updateLocalStorage(); // This is for removing the 'showSuccessModal' key from the state to avoid showing the popup again in page refresh.
  };

  const updateLocalStorage = () => {
    const inventoryId = getStateValues("inventoryId");
    setLocalStorage(INVENTORY_KEY, { inventoryId: inventoryId });
  };

  const getStateValues = (key) => {
    const inventory = getLocalStorage(INVENTORY_KEY);
    if (key) {
      return _.get(inventory, key, null);
    } else {
      return inventory;
    }
  };

  useEffect(() => {
    const inventory = getStateValues();
    const inventoryId = _.get(inventory, "inventoryId", null);
    const showSuccess = _.get(inventory, "showSuccessModal", null);
    const showStrengthenSuccess = _.get(
      inventory,
      "showStrengthenSuccessModal",
      null
    );
    if (showSuccess || showStrengthenSuccess) {
      setSuccessModal(true);
    } else {
      // showStrengthen && setShareModal(true);
      setSuccessModal(false);
    }
    getInventoryDetails(inventoryId);
  }, []);

  useEffect(() => {
    if (
      (invType === INVENTORY_SELL ||
        invType === INVENTORY_ALL_TYPE ||
        invType === INVENTORY_SELL_LTR ||
        invType === INVENTORY_SELL_STR) &&
      !_.get(inventoryDetails, "isStrengthen", false)
    ) {
      setShowStrengthen(true);
    } else {
      setShowStrengthen(false);
    }
  }, [inventoryDetails]);

  const getInventoryDetails = async (inventoryId) => {
    if (inventoryId) {
      await getInventoryById(inventoryId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.memberCreateInventory);
    }
  };
  let titleText = "";
  switch (invType) {
    case INVENTORY_SELL:
      titleText = " selling";
      break;
    case INVENTORY_LTR:
      titleText = " renting";
      break;
    case INVENTORY_STR:
      titleText = " renting";
      break;
    case INVENTORY_LTR_STR:
      titleText = " renting";
      break;
    case INVENTORY_SELL_LTR:
      titleText = " selling, renting";
      break;
    case INVENTORY_SELL_STR:
      titleText = " selling, renting";
      break;
    default:
      titleText = " selling, renting";
      break;
  }
  const handleStrengthenDismiss = () => {
    setShowStrengthen(false);
  };
  

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      typeBasedOnProperty === INVENTORY_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };

  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueType}>
            <div className="nicetohaves-tag-icon">
              <img src={icon ? require("../images/" + icon) : LakeViewIcon} />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const isNiceToHaveExist = nice2Have.length > 0;

  const loadRentingPricesHTML = () => {
    let rentingPrice
    if(LTRAskngPrce && STRAskngPrce){
      return  <li>
      <span className="musthave-label">Renting Price</span>
      <span className="musthave-value">
       {'$' + convertToCurrencySystem(LTRAskngPrce) + '/Month'}
      </span>
      <span className="musthave-value">
       {'$' + convertToCurrencySystem(STRAskngPrce) + '/Night'}
       </span>
    </li>
    
    } else if (LTRAskngPrce && !STRAskngPrce){
      return  <li>
      <span className="musthave-label">Renting Price</span>
      <span className="musthave-value">
       {'$' + convertToCurrencySystem(LTRAskngPrce) + '/Month'}
      </span>
    
    </li>
    } else if (STRAskngPrce && !LTRAskngPrce){
      return  <li>
      <span className="musthave-label">Renting Price</span>
      <span className="musthave-value">
       {'$' + convertToCurrencySystem(STRAskngPrce) + '/Night'}
      </span>
    
    </li>
    } else{
      return  ''
    } 
  }

  const loadDetails = () => {
    if (typeBasedOnProperty === INVENTORY_LAND) {
      const acreage = _.get(inventoryDetails, "acreage", "");
      const lotType = _.get(inventoryDetails, "lotType", "");
      const roadType = _.get(inventoryDetails, "roadType", "");
      let isPowerAccess = _.get(inventoryDetails, "isPowerAccess", "");
      const waterSuply = _.get(inventoryDetails, "waterSuply", "");
      const wasteWtrSys = _.get(inventoryDetails, "wasteWtrSys", "");
      let isApprovedStrctr = _.get(inventoryDetails, "isApprovedStrctr", "");
      let isInFloodPlain = _.get(inventoryDetails, "isInFloodPlain", "");
      if (isPowerAccess) {
        if (isPowerAccess === "true" || isPowerAccess === 1) {
          isPowerAccess = "Yes";
        } else {
          isPowerAccess = "No";
        }
      }
      if (isApprovedStrctr) {
        if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
          isApprovedStrctr = "Yes";
        } else {
          isApprovedStrctr = "No";
        }
      }
      if (isInFloodPlain) {
        if (isInFloodPlain === "true" || isInFloodPlain === 1) {
          isInFloodPlain = "Yes";
        } else {
          isInFloodPlain = "No";
        }
      }
      return (
        <>
          <ul>
            {sellAskingPrice && (
              <li>
                <span className="musthave-label">Selling Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(sellAskingPrice)}
                </span>
              </li>
            )}
 {loadRentingPricesHTML()}
            {/* {LTRAskngPrce && (
              <li>
                <span className="musthave-label">Long Renting Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(LTRAskngPrce)+'/Month'}
                </span>
              </li>
            )}

{STRAskngPrce && (
              <li>
                <span className="musthave-label">Short Renting Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(STRAskngPrce)+'/Night'}
                </span>
              </li>
            )} */}

            {acreage && (
              <li>
                <span className="musthave-label">Acreage</span>
                <span className="musthave-value">{acreage + " Acres"}</span>
              </li>
            )}

            {lotType && (
              <li>
                <span className="musthave-label">Lot Type</span>
                <span className="musthave-value">
                  {filterArray(LOT_TYPES, "value", lotType)}
                </span>
              </li>
            )}

            {roadType && (
              <li>
                <span className="musthave-label">Road Type</span>
                <span className="musthave-value">
                  {filterArray(ROAD_TYPES, "value", roadType)}
                </span>
              </li>
            )}
            {isPowerAccess && (
              <li>
                <span className="musthave-label">Power in Property</span>
                <span className="musthave-value">{isPowerAccess}</span>
              </li>
            )}
          </ul>
          <ul>
            {waterSuply && (
              <li>
                <span className="musthave-label">Water Supply</span>
                <span className="musthave-value">
                  {" "}
                  {filterArray(WATER_SUPPLY, "value", waterSuply)}
                </span>
              </li>
            )}
            {wasteWtrSys && (
              <li>
                <span className="musthave-label">Waste Water System</span>
                <span className="musthave-value">
                  {" "}
                  {filterArray(WASTE_WATER_SYSTEM, "value", wasteWtrSys)}
                </span>
              </li>
            )}

            {isApprovedStrctr && (
              <li>
                <span className="musthave-label">Approved for strecture</span>
                <span className="musthave-value"> {isApprovedStrctr}</span>
              </li>
            )}
            {isInFloodPlain && (
              <li>
                <span className="musthave-label">Flood plain</span>
                <span className="musthave-value"> {isInFloodPlain}</span>
              </li>
            )}
          </ul>
        </>
      );
    } else {
      let adaAccess = _.get(inventoryDetails, "adaAccess", null);
      if (adaAccess) {
        if (adaAccess === true || adaAccess === 1) {
          adaAccess = "Yes";
        } else {
          adaAccess = "No";
        }
      }
      return (
        <>
          <ul>
            {sellAskingPrice && (
              <li>
                <span className="musthave-label">Selling Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(sellAskingPrice)}
                </span>
              </li>
            )}
             {loadRentingPricesHTML()}

            {/* {LTRAskngPrce && (
              <li>
                <span className="musthave-label">Long Renting Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(LTRAskngPrce)+'/Month'}
                </span>
              </li>
            )}
{STRAskngPrce && (
              <li>
                <span className="musthave-label">Short Renting Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(STRAskngPrce)+'/Night'}
                </span>
              </li>
            )} */}
            {noBedRm && (
              <li>
                <span className="musthave-label">Beds</span>
                <span className="musthave-value">{noBedRm}</span>
              </li>
            )}
            {noBathRm && (
              <li>
                <span className="musthave-label">Baths</span>
                <span className="musthave-value">{noBathRm}</span>
              </li>
            )}

            {sqFt && (
              <li>
                <span className="musthave-label">Sqft</span>
                <span className="musthave-value">{sqFt}</span>
              </li>
            )}
          </ul>
          <ul>
            {stories && (
              <li>
                <span className="musthave-label">Stories</span>
                <span className="musthave-value">{stories}</span>
              </li>
            )}

            {grgeSpots && (
              <li>
                <span className="musthave-label">Garage Spaces</span>
                <span className="musthave-value">{grgeSpots}</span>
              </li>
            )}
            {lotSize && (
              <li>
                <span className="musthave-label">Lot size</span>
                <span className="musthave-value">{lotSize + " Acres"}</span>
              </li>
            )}

            {adaAccess && (
              <li>
                <span className="musthave-label">ADA Accessible</span>
                <span className="musthave-value">{adaAccess}</span>
              </li>
            )}
          </ul>
        </>
      );
    }
  };
  return (
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
        <InventorySuccessModal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        showStrengthenSuccessModal={getStateValues(
          "showStrengthenSuccessModal"
        )}
      />
          <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-8 ps-xl-2">
            <div className="inventory-confirmation-wrap">
              <div className="confirmation-top-sec d-md-flex justify-content-between">
                <div>
                  <div className="want-ad-tag align-top grey-tag-bg mb-2 f-size-10">
                    Open to {titleText}
                  </div>
                  <h2>{_.get(inventoryDetails, "locAddress", "")}</h2>
                  {/* <h6>Eugene, OR 99999</h6> */}
                </div>
                <div className="social-btns-wrap">
                  {/*<a
                    role="button"
                    className="social-content-btn mr-12"
                    
                  >
                    <img src={ShareIcon} />
  </a>*/}

                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic1">
                      <img src={MoreIcon} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item onClick={() => alert("Not implemented")}>
                        Share
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => alert("Not implemented")}>
                        Report
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="inventory-banner position-relative mt-30">
                <div className="upload-cover-btn upload-cover-btn-pos">+4</div>
                <div class="banner-row">
                  <div className="banner-column-half">
                    <div className="half-col-img-wrap">
                      <img
                        className="brdr-left-8 brdr-t-r-8 no-brder-b-l-0"
                        src={InventoryImgFirst}
                      />
                    </div>
                  </div>
                  <div className="banner-column-quarter">
                    <div className="quarter-col-img-wrap-top">
                      <img src={InventoryImgFirst} />
                    </div>
                    <div className="quarter-col-img-wrap-btm">
                      <img src={InventoryImgFirst} />
                    </div>
                  </div>
                  <div className="banner-column-quarter">
                    <div className="quarter-col-img-wrap-top">
                      <img
                        className="brdr-top-right-8 no-brdr-t-r-0"
                        src={InventoryImgFirst}
                      />
                    </div>
                    <div className="quarter-col-img-wrap-btm">
                      <img
                        className="brdr-btm-right-8 brdr-b-l-8"
                        src={InventoryImgFirst}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="musthave-wrap">
                <h2>Details</h2>
                {loadDetails()}
              </div>

              <div className="Inventory-Location-wrap pb-32">
                <h2>Location</h2>
                <div className="Inventory-Location-map-wrap mb-3">
                  <img src={InventoryLocationMap} alt={InventoryLocationMap} />
                </div>
                <ul>
                  <li>
                    <span className="Inventory-icn-wrap">
                      <img src={SchoolSmallIcon} />
                    </span>
                    Columbia School District
                  </li>
                  <li>
                    <span className="Inventory-icn-wrap">
                      <img src={NeighborhoodIcon} />
                    </span>
                    Sellwood Neighborhood
                  </li>
                </ul>
              </div>

              {isNiceToHaveExist ? (
                <div className="nicetohaves-wrap">
                  <h2>Features</h2>
                  <div className="nicetohaves-inner-wrap">
                    {finalNiceToHaveList()}
                  </div>
                </div>
              ) : (
                ""
              )}

              {description && (
                <div className="additional-details-wrap">
                  <h2>Description</h2>
                  <p>{description}</p>
                </div>
              )}

              <div className="buyer-info">
                {/* <h2>Owner Information</h2> */}
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap">
                      <img src={ConsumerIcon} />
                    </span>
                    Consumer
                  </li>
                  {salesPercnt && (
                    <li>
                      <span className="list-icn-wrap">
                        <img src={ConsumerIcon} />
                      </span>
                      Seller is offering {salesPercnt}% to buyer's agent
                    </li>
                  )}
                  {_.get(inventoryDetails, "timeLineTxt", "") && (
                     <li>
                     <span className="list-icn-wrap">
                       <img src={TimelineIcon} />
                     </span>
                     Timeline: {_.get(inventoryDetails, "timeLineTxt", "")}
                   </li>
                  )}
                  {_.get(inventoryDetails, "apprOnFile", false) ? (
                    <li>Appraisal on file</li>
                  ) : (
                    ""
                  )}

                  {_.get(inventoryDetails, "insReport", false) ? (
                    <li>Inspection report on file</li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "sellerFin", false) ? (
                    <li>Seller finance available</li>
                  ) : (
                    ""
                  )}

                  {_.get(inventoryDetails, "floorPlan", false) ? (
                    <li>Floor plan on file</li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "floodCert", false) ? (
                    <li>Flood certificate available</li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2">
            {showStrengthen && (
              <div className="ad-strengthen-wrap mb-4">
                <div className="icon-wrap">
                  <img src={StrengthenIcon} alt={StrengthenIcon} />
                </div>
                <h4>Want to get more successful introductions?</h4>
                <p className="mb-4">
                  Adding additional information about your property gives people
                  a better idea of what you have.
                </p>
                <Button
                  variant="primary"
                  className="w-100 mb-4 l-spacing-0"
                  onClick={() => navigate(pageURLs.inventoryStrengthen)}
                >
                  Strengthen my Property
                </Button>
                <a
                  href={void 0}
                  role="button"
                  className="dismiss-btn d-block"
                  onClick={() => handleStrengthenDismiss()}
                >
                  Dismiss
                </a>
              </div>
            )}

            <div className="ad-strengthen-wrap mb-0 mb-sm-4">
              <div className="icon-wrap">
                <img src={AdIntroIcon} alt={AdIntroIcon} />
              </div>
              <h6>
              When you send an introduction to a want-ad, it will appear here
              </h6>
              <p className="mb-4">
              Use the Match filter in the Ad Feed to see the want-ads that match your property. 
              </p>
              <Button
                variant=""
                className="btn btn-secondary l-spacing-0 w-100 mb-0"
              >
                View want ads
              </Button>
            </div>
          </div>
        </div>
      </div>
  
  );
};

export default ViewInventory;
