import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClientProfileData from "../components/ClientProfileData";
import ViewClientMenu from "../components/clientManagement/ViewClientMenu";
import ClientActionDropdown from "../components/ClientActionDropdown";
import "../styles/clients.scss";
import { getClientDetailsMobile } from "../../services/agentClientsService";
import { pageURLs } from "../../constants/pageURLs";
import { getLocalStorage, setBodyBackgroundGrey } from "../../helpers/common";
import { CLIENT_DETAILS_KEY } from "../../constants/common";
const PAGE_LIMIT = 10;


const ViewClient = (props) => {

  const location = useLocation()
  const clientId = location.state && location.state['clientId'] 
  console.log('--------- clientId: ', clientId)

  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const pageRefreshOnEditClient = async() => {
    setIsRefresh(true);
  }

  useEffect(() => {
    setBodyBackgroundGrey()
    document.body.classList.add('bg-offcanvas-fix');
   
  }, []);

  useEffect(() => {
    loadClientDetails();
  }, [isRefresh]);


  const loadClientDetails = async () => {
    setIsRefresh(false);
    // const clientId = _.get(getLocalStorage(CLIENT_DETAILS_KEY), "id", "");
    // const clientId = 422;
    await getClientDetailsMobile(clientId)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setClientDetails(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };


  return (
    
    <div className="gitcha-main-wrapper client-management my-client-wrap">

        <div className="content-wrapper">
              <div className="inner-layout-header-new bg-white">
                  <div className="top-three-comp-header w-100 top-three-comp-headerborder">
                      <div className="top-three-comp-header-inner w-100">
                          <a className="back-link-btn" href={void(0)} onClick={ () => navigate(pageURLs.agentClientListing)} >
                              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                              </svg>
                          </a>
                          <div className="heading">View Client</div>
                          <div className="right-mobbtn-wrap">
                              
                              <div className="social-btns-wrap">
                                <ClientActionDropdown
                                  clientDetails = {clientDetails.clientDetails}
                                  onEditComplete = {pageRefreshOnEditClient}
                                />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            <div className="inner-layout-content-new">
                <ClientProfileData
                    clientDetails = {clientDetails.clientDetails}
                />
                <ViewClientMenu
                    wantAdCount = {clientDetails.wantAdCount}
                    inventoryCount = {clientDetails.inventoryCount}
                    favoriteCount = {clientDetails.favorite_Count}
                    clientId = {clientId}
                    clientName={clientDetails.clientDetails?.FirstName + ' ' + clientDetails.clientDetails?.LastName}
                />

            </div>
        </div>
        
    </div>
  );
};

export default ViewClient;
