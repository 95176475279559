import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import PremiumSparkleIcon from "../../images/premium-sparkle-icon.svg";
import { pageURLs } from "../../constants/pageURLs";
import { fetchUserDetails } from "../../actions/userprofile";
import { getLocalStorage } from "../../helpers/common";
import { NOT_FREE_OR_MEMBER_PERIOD, USER_INFO_KEY } from "../../constants/common";
import ProfileAgentUserPremium from "./ProfileAgentUserPremium";
import ProfileNormalUser from "./ProfileNormalUser";

const AccountSettings = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const userId = _.get(sessionData, "userInfo.userId", "");
  const [userDetails, setUserDetails] = useState(null);
  const [upComingPlanDetails, setupComingPlanDetails] = useState(null);
  const userType = userDetails?.UserTypeId;
  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchUserDetails()
      );
    }
  }, [reload]);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[0]);
      if (userDetailsFromStore?.length > 1) {
        setupComingPlanDetails(
          userDetailsFromStore[userDetailsFromStore?.length - 1]
        );
      } else {
        setupComingPlanDetails(null);
      }
    } else {
      setUserDetails(null);
    }
  }, [userDetailsFromStore]);

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  return (
    <>
      <div className="gitcha-main-wrapper pb-0">
        <div className="content-wrapper h-100">
          <div className="inner-layout-header-new bg-white ">
            <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="back-link-btn"
                  href={void 0}
                  onClick={() => navigate(pageURLs.mobileSettings)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>
                <div className="heading">Account</div>
                <div class="p-0 toplinks-mobile">
                  <div className="topbar-links notif-delete-btn d-none"></div>
                </div>
              </div>
            </div>
          </div>

          {userType === 1 ? (
            <ProfileNormalUser
              userDetails={userDetails}
              updateReloadParam={updateReloadParam}
              upComingPlanDetails={upComingPlanDetails}
            />
          ) : userType === 2 &&
            (userDetails?.period > NOT_FREE_OR_MEMBER_PERIOD) ? (
            <ProfileAgentUserPremium
              userDetails={userDetails}
              updateReloadParam={updateReloadParam}
              upComingPlanDetails={upComingPlanDetails}
            />
          ) : (
            <ProfileAgentUserPremium
              userDetails={userDetails}
              updateReloadParam={updateReloadParam}
              upComingPlanDetails={upComingPlanDetails}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AccountSettings;