import React, { useState, useEffect } from "react";

const BottomStepperMob = (props) => {
  const { completedPercentage, isInventory } = props;
  const customStyle = completedPercentage + '%';

  const activeProgressIndications = () => {
    const circleClass = isInventory ? "circle-green" : "circle";
    return (
      // <div className= {isInventory ? "progress-container-green" : "progress-container"}>
      //   <div className={isInventory ? "progress-green" : "progress"}  id="progress" style={{width:customStyle}}></div>
        <div className= "progress-container-mob">
        <div className="progress-mob"   id="progress-mob" style={{width:customStyle}}></div>
        <div className={circleClass + " active"}></div>
        {isInventory ?   //This is for inventory flow
        <>
         <div
          className={completedPercentage >= 15 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 30 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 45 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 60 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 75 ? circleClass + " active" : circleClass}
        ></div>
         <div
          className={completedPercentage >= 90 ? circleClass + " active" : circleClass}
        ></div>
         <div
          className={completedPercentage >= 100 ? circleClass + " active" : circleClass}
        ></div>
        </> : // This is for want ad flow
        <>   
         <div
          className={completedPercentage >= 20 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 40 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 60 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 80 ? circleClass + " active" : circleClass}
        ></div>
        <div
          className={completedPercentage >= 100 ? circleClass + " active" : circleClass}
        ></div>
        </>}
       
      </div>
    );
  };

  return <>{activeProgressIndications()}</>;
};
export default BottomStepperMob;
