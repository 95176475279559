import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InvTutsImg from "../../images/inventory-tuts-image.png";




const MatchTutoral = (props) => {
  const navigate = useNavigate();
  
  return (
    <Modal
      show
      centered
      backdropClassName='white-backdrop'
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      
      className="modal-left-aligned modal-match-tutorial left"
      
    >
      
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="pt-24 pb-24 pl-24 pr-24">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {
            props.onHide()
          }}
        ></button>
        
       
        {/*<div
          className="fireworks-overlay top-65">
            
        
          <img src={CrackersImg} />
        </div>*/}
        
        


         <h6>You've got a new match ⚡️</h6>
         <p className="mb-2">It looks like people are looking for properties that are similar to yours. See which want ads you're matching to.</p>

        
         <img src={InvTutsImg} />             
          
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default MatchTutoral;
