import { WANT_AD_KEY } from "../../constants/common";
import { getLocalStorage } from "../../helpers/common";
import { getTimeline } from "../../services/commonServices";
import { useState, useEffect } from "react";
const SelectTimeLineBuy = (props) =>{

    const {setDisableSave, handleFormValues, formValues, adType, isEdit} = props;
    const timeLine = _.get(formValues, "timeLine", null);
    const [timeLineList, setTimeLineList] = useState([]);
    const timeLineInSession = _.get(getLocalStorage(WANT_AD_KEY), "timeLine", null);

    useEffect(() => { 
      if(timeLine && !isEdit){
        setDisableSave(false);
      }
        getTimeline(adType)
          .then((response) => {
            if (response.errorCode === 0 && _.get(response, "result", null)) {
              const timeLines = _.get(response, "result", null);
              setTimeLineList(timeLines);
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }, []);


      const getSelectedValue = (timeLineId, timeLineText) => {
        if(isEdit){
          if(timeLineId != timeLineInSession){
            setDisableSave(false);
          }
          else{
            setDisableSave(true);
          }
        }
        else {setDisableSave(false);}
        handleFormValues({ timeLine: timeLineId, tlText: timeLineText });
    };


    const loadTimeLines = () => {
        const timeLines =
          timeLineList &&
          timeLineList.map((tl) => {
            const timeLineId = _.get(tl, "TimeLineId");
            const timeLineText = _.get(tl, "TimeLineText", "");
            return (
                <a href={void 0} className={timeLine===timeLineId ? "createAd-content-wrap mb-4 active" : "createAd-content-wrap mb-4"} onClick={(e)=>{
                  e.stopPropagation();
                  getSelectedValue(timeLineId, timeLineText)
                }}>
                <span className="labelTxt">{timeLineText}</span>
              </a>            
            );
          });
    
        return timeLines;
      };

    return(
        <div className="title-selection-wrap pt-0 pe-3 ps-3">
           {loadTimeLines()}
          </div>
    )

}

export default SelectTimeLineBuy;