import React from "react";
import Select, { components } from "react-select";
import CaratIcon from "../../images/carat-icon-new.svg";

const SelectDropDown = (props) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={CaratIcon} />
      </components.DropdownIndicator>
    );
  };

  const {
    id,
    name,
    options,
    className,
    classNamePrefix,
    placeholder,
    defaultValue,
    value,
    onChange,
    styles,
    errorMessage,
    onFocus,
    inputValue,
    errorClass,
    onInputChange,
    selectRef,
    dropDownIndicatorStatus = true,
    disabled,
    onBlur
  } = props;

  return (
    <>
      <Select
        inputValue={inputValue}
        onInputChange={onInputChange}
        disabled={disabled}
        ref={selectRef}
        id={id}
        name={name}
        options={options}
        className={className}
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onFocus = {onFocus}
        onChange={onChange}
        styles={styles}
        isDisabled={props.disabled}
        components={
          dropDownIndicatorStatus
            ? { DropdownIndicator }
            : { DropdownIndicator: () => null, IndicatorSeparator: () => null }
        }
        onBlur={onBlur}
      />
      <span className={errorClass || "error-msg error-msg-height"}>
        {errorMessage}
      </span>
    </>
  );
};

export default SelectDropDown;
