/**
 * @file   src\components\AgentDashboard\ActionDropdown.js
 * @brief  This file is responsible for dropdown actions in dashboard
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Dropdown } from "react-bootstrap";
import React from "react";
import TableMoreIconVertical from "../../images/table-more-vertical-icon.svg";
const ActionDropdown = (props) => {
  const { onItemEdit, onItemDelete, editText, deleteText, itemId, disableEdit, buyerListLinkText, showBuyerListingFormUrl, client_Want_ad_Id, hideEdit } = props;
  return (
    <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic1111">
        <img src={TableMoreIconVertical} />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" >
        {!hideEdit ?
        disableEdit ?
          <Dropdown.Item onClick={() => showBuyerListingFormUrl(client_Want_ad_Id)}>{buyerListLinkText}</Dropdown.Item>
          :
          <Dropdown.Item onClick={() => onItemEdit(itemId)}>{editText}</Dropdown.Item>
        : <></>}
        <Dropdown.Item onClick={() => onItemDelete(itemId)}>
          {deleteText}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default ActionDropdown;
