/**
 * @file   Source/gitcha_Web/src/components/PlanUpgrade/PlanReviewModal.js
 * @brief  This file is responsible for handling final step in subscription process
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import VisaIcon from "../../images/visa-icon.svg";
import PaymentMethodsModal from "../Introduction/PaymentMethodsModal";
import AddPaymentModalStripe from "../Payments/AddPaymentModalStripe";
import { fetchPaymentMethods } from "../../actions/payments";
import {
  getUserId,
  getCardIcon,
  getUserType,
  getLocalStorage,
  setLocalStorage,
  checkPlanCanbeUpgraded,
  getCurrentPlanText,
} from "../../helpers/common";
import {
  PLAN_TYPES,
  USER_INFO_KEY,
  PlanDetails,
  USER_TYPE_AGENT,
} from "../../constants/common";
import {
  createSubscriptionPlan,
  createSubscriptionPlanOnSignup,
  getPreviousPlanBalanceAmount,
  getPromoCodeDetails,
} from "../../services/PlanService";
import { toast } from "react-toastify";
import moment from "moment";
import PlanUpgradeRestrictionModal from "./PlanUpgradeRestrictionModal";
import ShowEditProfileModal from "./ShowEditProfileModal";
import { fetchUserDetails } from "../../actions/userprofile";
import { useNavigate } from "react-router-dom";

const PlanReviewModal = (props) => {
  const dispatch = useDispatch();
  const {
    period,
    selectedPlan,
    fromSignUp,
    handleShowAgentModal,
    handleMemberProfileModal,
    enablePromocode,
    enablePromocodePeriod,
    userDetails,
    showPlanModal,
    setPeriod,
    currentActivePlan,
    UpgradeToAgent,
    show,
    continueButtonClicked,
    setContinueButtonClicked
  } = props;

  const [amount, setAmount] = useState(0);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);
  const PaymentMethodsModalClose = () => setShowPaymentMethodsModal(false);
  const PaymentMethodsModalShow = () => setShowPaymentMethodsModal(true);
  const [showPlanRestrictionModal, setShowPlanRestrictionModal] = useState(false);

  const [showAddPaymentModal, setAddPaymentModal] = useState(false);
  const AddPaymentModalClose = () => setAddPaymentModal(false);
  const AddPaymentModalShow = () => setAddPaymentModal(true);
  const userId = getUserId();
  const userType = getUserType();
  const [reload, setReload] = useState(0);
  const [plan, setPlan] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(period);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [balancePlanAmount, setBalancePlanAmount] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const date = moment().format("MMMM Do");
  const dateOnly = date.split(" ");
  const selectedPlanDetails = PlanDetails.find(
    (plan) => plan.planName === selectedPlan
  );
  const isDowngrade =
    currentActivePlan?.PlanName === "Investor Member" && selectedPlan === "Member"
      ? true
      : false;
  const [promocodeAppliedAmount, setPromocodeAppliedAmount] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  let samePlan = false;
  if(currentActivePlan?.PlanName==="Trial"){
    if(userDetails?.PlanName === selectedPlan){
      samePlan = true
    }
  }
  else{
    if(currentActivePlan?.PlanName===selectedPlan){
      samePlan = true;
    }
  }
  const navigate = useNavigate();
  const [editProfileModal, setEditProfileModal] = useState(false);
    const EditProfileModalClose = () => setEditProfileModal(false);
    const EditProfileModalShow = () => setEditProfileModal(true);
  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, [userId, reload]);

  useEffect(() => {
    setDisableSave(false);
    toast.dismiss();
    setBalancePlanAmount(null);  
    if (currentActivePlan && currentActivePlan?.PlanName !="Trial" && show && !continueButtonClicked && !samePlan && !isDowngrade) {
      getPreviousPlanAmount();
    }
  }, [currentActivePlan, show, continueButtonClicked, samePlan, selectedPeriod, selectedPlan]);

  const getPreviousPlanAmount = async () => {
    const payload = {
      newPlanId: 11,
      userPlanId: currentActivePlan?.User_Plan_Id,
      planName: selectedPlan,
      planPeriod: selectedPeriod,
      userType: UpgradeToAgent ? USER_TYPE_AGENT : userType,
    };
    await getPreviousPlanBalanceAmount(payload)
      .then((response) => {
        if (response.errorCode === 0) {
          const result = _.get(response, "result", {});
          setBalancePlanAmount(result?.UnUsed_Amount);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    setSelectedPeriod(period);
  }, [period]);

  const AddNewPayment = (isFromPaymentMethodsModal = false) => {
    if (isFromPaymentMethodsModal) {
      setShowPaymentMethodsModal(false);
    }
    AddPaymentModalShow();
  };

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      const defaultPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.IsDefault === 1
      );
      setSelectedPaymentMethod(defaultPaymentMethod);
    } else {
      setSelectedPaymentMethod(null);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (selectedPlan) {
      setPlan(PLAN_TYPES.find((item) => item.name === selectedPlan));
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (selectedPeriod === 3) {
      setAmount(12 * plan?.amount?.annual);
    } else {
      setAmount(plan?.amount?.monthly);
    }

    if (enablePromocode && enablePromocodePeriod?.includes(selectedPeriod)) {
      setShowPromoCode(true);
    } else {
      setShowPromoCode(false);
    }
  }, [selectedPeriod, enablePromocode, enablePromocodePeriod, plan]);

  const handleConfirmSubscription = async () => {
    setSubmitClicked(true);
    const data = {
      PlanName: selectedPlan,
      PlanPeriod: selectedPeriod,
      PaymentMethodId: selectedPaymentMethod?.CardId,
      PaymentModeId: selectedPaymentMethod?.Payment_Mode_Id,
      PromoCode: promoCode,
      UserType: userType,
      PopupStatus : continueButtonClicked
    };    
    if (fromSignUp) {
      await createSubscriptionPlanOnSignup(data)
        .then(async (response) => {
          setSubmitClicked(false);
          if (response.errorCode === 0) {
            props.updateReloadParam();
            toast.dismiss();
            toast.success("You've selected " + selectedPlan + "!");
            props.onHide();
            const userInfo = getLocalStorage(USER_INFO_KEY);
            userInfo.userInfo["planStatus"] = true;
            setLocalStorage(USER_INFO_KEY, userInfo);
            if (userType == 1) {
              handleMemberProfileModal({ showModal: true });
            } else {
              handleShowAgentModal({ showState: true });
            }
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {      
      if(UpgradeToAgent===true){
      data.changeUserType  = USER_TYPE_AGENT
      }
      await createSubscriptionPlan(data)
        .then(async (response) => {
          setSubmitClicked(false);
          if (response.errorCode === 0) {
            toast.dismiss();
            const text = isDowngrade ? "downgraded" : "upgraded";
            setTimeout(()=>{
              toast.success("You've " + text + " to " + selectedPlan + "!"); 
            }, 300)
            props.onHide();
            if(UpgradeToAgent===true){
              navigate("/edit-agent-profile")
            }
            else {
              props.updateReloadParam();
            }   
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const handleCancel = () => {
    setSelectedPeriod(period);
    setShowPlanRestrictionModal(false);
    props.onHide();
  };

  const handlePromoCode = async () => {
    const payload = {
      promoCode: promoCode,
      userType: userType,
      period: selectedPeriod,
    };

    await getPromoCodeDetails(payload).then((response) => {
      if (response.errorCode === 0) {
        const result = _.get(response, "result", {});
        if (result.status === false) {
          toast.error(response.message);
          setPromoCode("");
        } else {
          const data = result.data;
          if (data && data?.Percentage_Off != null) {
            const newAmount = (amount * data?.Percentage_Off) / 100;
            setPromocodeAppliedAmount(amount - newAmount);
          } else if (data?.Amount_Off != null) {
            setPromocodeAppliedAmount(amount - data.Amount_Off);
          }
        }
      }
    });
  };  

  const getFinalAmount = () =>{
    return promocodeAppliedAmount === 0
    ? 0
    : balancePlanAmount
    ? (promocodeAppliedAmount
        ? promocodeAppliedAmount
        : amount + parseFloat(balancePlanAmount)
      ).toFixed(2)
    : promocodeAppliedAmount
    ? promocodeAppliedAmount
    : amount?.toFixed(2)
  }

  return (
    <>
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="cms-766"
        backdrop="static"
      >
        <Modal.Body className="pt-32 pb-32">
          <a
            role="button"
            href={void 0}
            className="bottom-back-btn"
            onClick={() => {
              handleCancel();
              showPlanModal();
            }}
          >
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                  fill="#205ECA"
                />
              </svg>
            </span>
            Back
          </a>
          {!fromSignUp && (
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={handleCancel}
            ></button>
          )}
          <h3 className="text-start mt-15">Review</h3>
          <p className="f-size-16 mb-30">
            {UpgradeToAgent ? "Please be aware that you’ll lose access to "+getCurrentPlanText(currentActivePlan)+" specific features & want criteria but you’ll gain agent specific features and new capabilities."
            : "Confirm the details of your new subscription plan."}
          </p>
          <Row>
            <Col sm={selectedPlan === "Pro Member" ? 5 : 6}>
              <>
                <div className="upgrade-card upgrade-card-border-grey">
                  <div className="upgrade-card-top">
                    <h3>
                      <span>{plan?.name}</span>
                      {selectedPlanDetails?.badge && (
                        <span
                          className={
                            selectedPlanDetails?.isGoldBadge
                              ? "pro-gold-badge"
                              : "pro-blue-badge"
                          }
                        >
                          {selectedPlanDetails?.badgeText}
                        </span>
                      )}
                    </h3>
                    <p className="btm-margin-0">
                      {selectedPlanDetails?.description}
                    </p>
                  </div>
                  <div className="upgrade-card-btm">
                    <ul className="upgrade-card-btm-list">
                      {selectedPlanDetails &&
                        selectedPlanDetails?.features.map((feature) => {
                          return (
                            <li className="upgrade-list-bg-blue">{feature}</li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </>
            </Col>
            {/* } */}

            <Col sm={selectedPlan === "Pro Member" ? 7 : 6}>
              <div className="billing-cycle-section">
                <h6>Billing cycle</h6>
                <div className="billing-cycle-top">
                  <label className="radio-wrap mb-1">
                    <input
                      type="radio"
                      disabled={samePlan && userDetails?.period===3}
                      onChange={() => {
                        setPromoCode("");
                        setPromocodeAppliedAmount(null);
                        setSelectedPeriod(3);
                        setContinueButtonClicked(false);
                        setPeriod(3);
                        if (checkPlanCanbeUpgraded(currentActivePlan, selectedPlan, 3)?.canPlanUpgrade == false) {
                          setShowPlanRestrictionModal(true);
                          setDisableSave(true);
                        } 
                        else{
                          setDisableSave(false);
                        }
                      }}
                      checked={selectedPeriod === 3}
                      name="chk-mh1"
                      id="bc2"
                    />
                    <span>
                      <div className="billing-cycle-info">
                        <h2 className="pb-0">
                          Annual (${plan?.amount?.annual}/month) {samePlan && userDetails?.period===3 ? <span className="current-plan-txt">Your current plan</span> : ""}
                        </h2>
                      </div>
                    </span>
                  </label>
                  <div className="bill-txt mb-3">
                    ${12 * plan?.amount?.annual} due today, then billed every
                    year on {date}
                  </div>

                  <label className="radio-wrap mb-1">
                    <input
                      type="radio"
                      name="chk-mh1"
                      disabled={samePlan && userDetails?.period===2}
                      onChange={() => {
                        setPromoCode("");
                        setContinueButtonClicked(false);
                        setPromocodeAppliedAmount(null);
                        setSelectedPeriod(2);
                        if (checkPlanCanbeUpgraded(currentActivePlan, selectedPlan, 2)?.canPlanUpgrade == false) {
                          setShowPlanRestrictionModal(true);
                          setDisableSave(true);
                        } 
                        else{
                          setDisableSave(false);
                        }
                        setPeriod(2);
                      }}
                      checked={selectedPeriod === 2}
                      id="bc1"
                    />
                    <span>
                      <div className="billing-cycle-info">
                        <h2 className="pb-0">
                          Monthly (${plan?.amount?.monthly}/month) {samePlan && userDetails?.period===2 ? <span className="current-plan-txt">Your current plan</span> : ""}
                        </h2>
                      </div>
                    </span>
                  </label>
                  <div className="bill-txt mb-3">
                    ${plan?.amount?.monthly} due today, then billed every month
                    on the {dateOnly[1]}
                  </div>
                </div>

                <div className="billing-cycle-btm">
                  <h6>Paying with</h6>
                  {selectedPaymentMethod !== null ? (
                    <div className="add-your-property-wrap mh-auto mb-12">
                      <div className="add-property-btn">
                        <img src={getCardIcon(selectedPaymentMethod?.Brand)} />
                      </div>
                      <div className="make-intro-details">
                        <h4>
                          {selectedPaymentMethod?.Brand} •••••••••••••
                          {selectedPaymentMethod?.Last4}
                        </h4>
                        <h5>
                          Exp.{" "}
                          {selectedPaymentMethod?.Exp_Month < 10
                            ? "0" + selectedPaymentMethod?.Exp_Month
                            : "" + selectedPaymentMethod?.Exp_Month}
                          /{String(selectedPaymentMethod?.Exp_Year).slice(-2)}
                        </h5>
                      </div>

                      <a
                        role="button"
                        class="right-arrow-link"
                        href={void 0}
                        onClick={() => {
                          PaymentMethodsModalShow();
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                            fill="#000022"
                          />
                        </svg>
                      </a>
                    </div>
                  ) : (
                    <a
                      href={void 0}
                      className="txt-no-underline link-cursor"
                      onClick={() => AddPaymentModalShow()}
                    >
                      <div className="add-your-property-wrap mh-auto mb-12">
                        <div className="add-property-btn">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3 12C3 11.5858 3.33579 11.25 3.75 11.25H20.25C20.6642 11.25 21 11.5858 21 12C21 12.4142 20.6642 12.75 20.25 12.75H3.75C3.33579 12.75 3 12.4142 3 12Z"
                              fill="#15181E"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 3C12.4142 3 12.75 3.33579 12.75 3.75V20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25V3.75C11.25 3.33579 11.5858 3 12 3Z"
                              fill="#15181E"
                            />
                          </svg>
                        </div>
                        <div className="make-intro-details">
                          <h4 className="mb-0">Add payment method</h4>
                        </div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                            fill="#000022"
                          />
                        </svg>
                      </div>
                    </a>
                  )}
                  {showPromoCode && (
                    <div className="promo-code-wrap position-relative mb-12">
                      <input
                        type="text"
                        className="form-control"
                        value={promoCode}
                        placeholder="Add promo code"
                        onChange={(e) =>
                          setPromoCode(e.target.value.toUpperCase())
                        }
                        name="promo-code"
                      />
                      <button
                        className="btn apply-btn"
                        onClick={() => handlePromoCode()}
                      >
                        Apply
                      </button>
                    </div>
                  )}
                  <div className="premium-plan-wrap mb-32">
                    <ul>
                      <li>
                        <span className="premium-plan-description">
                          {selectedPlan} Plan (Billed{" "}
                          {selectedPeriod == 2 ? "monthly" : "annually"})
                        </span>
                        <span className="premium-plan-amount">
                          ${amount?.toFixed(2)}
                        </span>
                      </li>
                      {balancePlanAmount ? (
                        <li>
                          <span className="premium-plan-description">
                            Your current paid plan
                          </span>
                          <span className="premium-plan-amount">
                            -${Math.abs(balancePlanAmount).toFixed(2)}
                          </span>
                        </li>
                      ) : (
                        <></>
                      )}
                      <li>
                        <span className="premium-plan-description">Tax</span>
                        <span className="premium-plan-amount">-</span>
                      </li>
                      <li>
                        <span className="premium-plan-description">
                          You'll pay
                        </span>
                        <span className="premium-plan-amount">
                          {Math.sign(getFinalAmount())== -1 ? "-$" : "$"}
                          {Math.abs(getFinalAmount()).toFixed(2)}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="premium-btn-wrap">
                    <button
                      className="btn btn-tertiary flex-shrink-0 l-space-0 me-1"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary flex-grow-1 l-space-0 ms-1"
                      disabled={
                        selectedPaymentMethod === null || submitClicked || disableSave
                          ? true
                          : false
                      }
                      onClick={handleConfirmSubscription}
                    >
                      Confirm Subscription
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <AddPaymentModalStripe
        show={showAddPaymentModal}
        updateReloadParam={() => {
          setReload(reload + 1);
        }}
        onHide={() => {
          AddPaymentModalClose();
        }}
        fromSubscriptionComponent={true}
      />
      <PaymentMethodsModal
        show={showPaymentMethodsModal}
        updateCardParam={(paymentMethod) => {
          setSelectedPaymentMethod(paymentMethod);
        }}
        paymentMethods={paymentMethods}
        selectedCardId={selectedPaymentMethod?.Payment_Mode_Id}
        onHide={() => {
          PaymentMethodsModalClose();
        }}
        AddNewPayment={(isFromPaymentMethodsModal) => {
          AddNewPayment(isFromPaymentMethodsModal);
        }}
      />
      <PlanUpgradeRestrictionModal
      show={showPlanRestrictionModal}
      onHide={() => {setShowPlanRestrictionModal(false); setContinueButtonClicked(false)}}
      currentActivePlan={currentActivePlan}
      selectedPeriod={selectedPeriod}
      handleContinueButton={()=>{setShowPlanRestrictionModal(false); setDisableSave(true); setContinueButtonClicked(true)}}
      />
      <ShowEditProfileModal
        editProfileModal={editProfileModal}
        EditProfileModalClose={EditProfileModalClose}
        userDetails={currentActivePlan}
    />
    </>
  );
};
export default PlanReviewModal;
