import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const ConfirmrepresentationModal = (props) => {
  const {
    onHide,
    show,
    setConfirmRepresentation
    } = props;

    const [isChecked, setIsChecked] = useState(false);
    const [disable, setDisable] = useState(true);
    useEffect(()=>{
      if(isChecked){
        setDisable(false);
      }else{
        setDisable(true)
      }
    },[isChecked])

    const handleModalConfirmClick =() =>{
      setConfirmRepresentation(true);
      onHide();
    }
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Confirm representation</h3>
        <div className="text-start find-renters-wrap modalStyle">
        <label className="form-check-label" for="confirmCheckbox">
            <input 
            className="form-check-input" 
            type="checkbox"
            id="confirmCheckbox"
            checked={isChecked}
            onChange={(e) =>{ setIsChecked(true)}} 
            />
            <div className="label-inner-wrap">
              <p>You are acknowledging you are in contract to represent the owner of this property and are approved to introduce this property to potential buyers or renters</p>
            </div>
        </label>
        </div>
        
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" disabled={disable} onClick={() => handleModalConfirmClick()}>Confirm</button>
            <button type="button" className="btn btn-secondary w-100" onClick={() => {setIsChecked(false);onHide()}}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default ConfirmrepresentationModal;