import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import GitchaWhiteLogo from "../../../images/gitcha-logo-white.svg";
//import BottomStepper from "../components/BottomStepper";
import BottomStepper from '../../Common/BottomStepper'
import { useLocation, useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";
import { getLocalStorage, removeLocalStorage } from "../../../helpers/common";
import { INVENTORY_KEY, INVENTORY_STRENGTHEN } from "../../../constants/common";

const InventoryLeftSection = (props) => {
  const navigate = useNavigate();
  const logoClick = () => {
    const inventoryDetails = getLocalStorage(INVENTORY_KEY)
    const prprtyType = _.get(inventoryDetails, 'prprtyType' ,'')
    if(prprtyType) {
      if(confirm('Are you sure you want to move before save ad details')) {
       removeLocalStorage(INVENTORY_KEY);
       removeLocalStorage(INVENTORY_STRENGTHEN);
       navigate(pageURLs.home)
      } 
    } else {
      removeLocalStorage(INVENTORY_KEY);
       removeLocalStorage(INVENTORY_STRENGTHEN);
      navigate(pageURLs.home)
    } 
  }
  const { title, description, completedPercentage } = props;
  return (
    <div className="col-lg-5 h-100 position-lg-absolute left-pane-bg left-half">
      <div className="left-col-wrap h-100">
        <div className="left-col-content h-100">
          <div className="mb-0"> 
            <a href={void 0} role="button" onClick={()=>logoClick()}>
              <img src={GitchaWhiteLogo} />
            </a>
          </div>
          <div className="left-middle-sec pt-5 pb-5">
            <h2 className="ow">{title}</h2>
            <p  className="ow">{description}</p>
          </div>
          <div>
            <BottomStepper
              completedPercentage={completedPercentage}
              isInventory = {true}
  />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InventoryLeftSection;
