/**
 * @file   src\pages\AgentCreateOrEditWantAd.js
 * @brief  This file is responsible for handling create or edit agent want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import CommonHeader from "../components/Onboarding/CommonHeader";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import WantAdLandMustHaves from "../components/AgentWantAd/WantAdLandMustHaves";
import WantAdBudget from "../components/AgentWantAd/WantAdBudget";
import WantAdType from "../components/AgentWantAd/WantAdType";
import WantAdDescription from "../components/AgentWantAd/WantAdDescription";
import WantAdTiming from "../components/AgentWantAd/WantAdTiming";
import WantAdLocation from "../components/AgentWantAd/WantAdLocation";
import WantAdOption from "../components/AgentWantAd/WantAdOption";
import WantAdCalendar from "../components/AgentWantAd/WantAdCalendar";
import WantAdRentPropertyTypes from "../components/AgentWantAd/WantAdRentPropertyTypes";
import WantAdRentMustHavePrivate from "../components/AgentWantAd/WantAdRentMustHavePrivate";
import WantAdMustHaves from "../components/AgentWantAd/WantAdMustHaves";
import WantAdShortRentMstHves from "../components/AgentWantAd/WantAdShortRentMstHves";
import WantAdRentMustHaves from "../components/AgentWantAd/WantAdRentMustHaves";
import WantAdPropertyPurchase from "../components/AgentWantAd/WantAdPropertyPurchase";
import WantAdRentCreditScore from "../components/AgentWantAd/WantAdRentCreditScore";
import WantAdPurchaseFinance from "../components/AgentWantAd/WantAdPurchaseFinance";
import { LONG_RENT, SHORT_RENT, BUY, AGENT_WANTAD_KEY, IS_PRIVATE_ROOM, IS_LAND, IMG_JPEG_FORMAT, USER_INFO_KEY, USER_TYPE_AGENT, DATE_FORMAT_MM_DD_YYYY } from '../constants/common'
import { getWantAdById, saveMemberWantAd, saveMemberWantAdstrengthenData } from "../services/wantAdService";
import { pageURLs } from "../constants/pageURLs";
import { getLocalStorage, loggedInUser, getUserId, createPreSignedUrl, checkTutorialDisplayStatus, clearLocalStorage, getUserType, removeLocalStorage, isValidDate } from "../helpers/common";
import { convertToNumber, textValidations } from "../helpers/validations";
import NiceToHave from "../components/Common/NiceToHave/NiceToHave";
import ClientSelection from "../components/Common/AgentClientSelection/ClientSelection";
import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";
import { EXACT_MOVE_DATE_TYPE } from "../constants/createWantAd";
import html2canvas from 'html2canvas';
import { dataURLToBlob, getEditAction } from "../helpers/createWantAd";
import GitchaNotAvailableModal from "../components/Common/GitchaNotAvailableModal";
import { checkGitchaAvailable } from "../services/commonServices";
import RequestStayAvailability from "../components/Common/RequestStayAvailability";
import { useDispatch } from "react-redux";
import { userLogout } from "../reducers/login";
import { useSelector } from "react-redux";
import AgentCompensation from "../components/AgentWantAd/AgentCompensation";
import InfoIcon from "../images/info-icon.svg";
import base64 from "base-64";
import utf8 from "utf8";
import Loader from "../components/Common/Loader";
import { FormLabel } from "react-bootstrap";
import ToolTip from "../components/Common/ToolTip";
const AgentCreateOrEditWantAd = (props) => {
  const targetRef = useRef(null);
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch();
  const browserLocation = getLocalStorage("userLocation");

  const [searchParams] = useSearchParams();
  searchParams.get("wantAdId");
  const id = searchParams.get("wantAdId");
  let decodedId;
  let idFromURL;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
    if (decodedId) {
      let decodedData = decodedId.split("-");
      idFromURL = decodedData.length > 0 ? decodedData[1] : null;
    }
  } catch { }

  const wantAdId = idFromURL;
  const [wantAdData, setWantAdData] = useState(!wantAdId ? location.state && location.state['data'] ? location.state['data']
    : JSON.parse(localStorage.getItem(AGENT_WANTAD_KEY)) : null) // to set the want-ad data

    const isFromNotification =  location.state && location.state['isFromNotification'];
    const isFromUnPause =  location.state && location.state['isFromUnPause'];


 

  const [disableButton, setDisableButton] = useState(false);
  const [selectedniceToHavesList, setSelectedNiceToHavesList] = useState([]);

  const YEAR_BUILT_MIN = 1900;
  const YEAR_BUILT_MAX = new Date().getFullYear();
  const sessionData = loggedInUser();

  const [gitchaModalShow, setGitchaModal] = useState(false);

  // error tracking
  const [clientSelectionError, setClientSelectionError] = useState(false);
  const [typeSelectionError, setTypeSelectionError] = useState(false);
  const [locationCityError, setLocationCityError] = useState(false);
  const [locationRadiusError, setLocationRadiusError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [timeLineError, setTimeLineError] = useState(false);
  const [timelineCalenderError, setTimeCalenderError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [subPropertyTypeError, setSubPropertyTypeError] = useState(false);
  const [requirementBedroomsError, setRequirementBedroomsError] = useState(false);
  const [requirementBathroomsError, setRequirementBathroomsError] = useState(false);
  const [requirementSquareFootageError, setRequirementSquareFootageError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [titleTextBoxError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [exactMoveDateError, setExactMoveDateError] = useState(false);
  const [requirementTextColor, setRequirementTextColor] = useState(false);
  const [dateTextBoxError, setDateTextBoxError] = useState(false);
  const [compensationError, setCompensationError] = useState(false);
  const [showWantadPublished, setShowWantadPublished] = useState(null); 
  const [locationError, setLocationError] = useState("");
  const isFromConfirmationPage = location.state && location.state['isFromConfirmationPage'] ? true : false;
  

  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const [isPremiumUser , setIsPremiumUser] = useState(null);

  const [showRequestStayPopuop, setShowRequestStayPopup] = useState(false);
  const handleStayRequestPopupOpen = () =>{
    setShowRequestStayPopup(true);
  }

  const handleStayRequestPopupClose = () => {
    setShowRequestStayPopup(false);
  }

  const ClientRef = useRef(null);
  const adTypeRef = useRef(null);
  const locationRef = useRef(null);
  const timeLineRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const mustHaveRef = useRef(null);
  const BudgetRef = useRef(null);
  const DescriptionRef = useRef(null);
  const CompensationRef = useRef(null);

  const userType = getUserType();
  const isAgent = userType=== USER_TYPE_AGENT ? true : false;

  const getWantAdDetails = async (id) => {
      await getWantAdById(id)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) { 
            if(responseResult?.isPublished === 1){
                setShowWantadPublished(true)
            }
             else{ 
              setShowWantadPublished(false)
              setWantAdData(responseResult)
            }            
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });    
  };

  useEffect(()=>{
   if(wantAdId ) getWantAdDetails(wantAdId)
    else {setShowWantadPublished(false)}
  }, [wantAdId])

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } 
  }, [userDetailsFromStore]);

  useEffect(() => {
   const isArchiveValue =  getEditAction(isFromUnPause, isFromNotification); // pass the url parameters to the function
    if(isArchiveValue == 0){ // unpause/ unarchive action occured
      handleFormValues({isArchived : 0})
      if(wantAdData?.adType == SHORT_RENT || wantAdData?.adType == LONG_RENT){
        handleFormValues({startDate: null, endDate: null, duration: null, month: null, moveInTimeLine: null,  moveInDate: null})
      }
     
    } 

    if(isFromUnPause || isFromNotification){
      if(isFromUnPause == true || isFromNotification == true){
        handleFormValues({ExpiryStatus : true}) // for make the notification table entry marked as renewed is the edit is from renew/unpause action
      } else{
        handleFormValues({ExpiryStatus : false})
      }
    }

  }, [isFromUnPause, isFromNotification]);


  

  useEffect(() => { //Need to show want add onboard very first time
    let DisplayWantAddOnboard = checkTutorialDisplayStatus(6); //Want add onboard display
    if(DisplayWantAddOnboard) {
      navigate(pageURLs.gitchaMemberWelcome)
    }

    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), "userInfo", null);
    if (!userInfo) {
      handleStateClear();
    }

    // to set isedit to show title
    if(location.state && location.state['data']){
      if(location.state['data'] && location.state['data']?.isEdit == false){
        handleFormValues({isEdit: false})
      } else{
        handleFormValues({isEdit: true})
      }
     

    }
    if(location.state && location.state['isFromConfirmationPage'] == true){
      handleFormValues({isEdit: true})
    }
    removeLocalStorage("userAction")
  }, []);

  const handleStateClear = () => {
    // alert("Your session has expired. Please login to continue.");
    dispatch(userLogout());
    clearLocalStorage();
    navigate(pageURLs.home);
  };


  useEffect(() => {
    wantAdData?.nice2Have ? setSelectedNiceToHavesList(wantAdData?.nice2Have) : setSelectedNiceToHavesList([]);


    localStorage.setItem(AGENT_WANTAD_KEY, JSON.stringify(wantAdData));
    if(wantAdData){
      handleSaveToDraft();
    }
  
    // checkButtonDisableOrNot(); // to check required fileds are entered or not

    if (wantAdData?.clientId || wantAdData?.isAgentAsSeller) {
      setClientSelectionError(false)
    }

    if (wantAdData?.adType) {
      setTypeSelectionError(false)
    }

    if (wantAdData?.compenationType) {
      setCompensationError(false)
    }

    if ((wantAdData?.location && wantAdData?.county) || (wantAdData?.zipCode?.length > 0)) {
      setLocationCityError(false)
      setZipCodeError(false)
    }
    if (wantAdData?.mapRadius) {
      setLocationRadiusError(false)
    }

    if (wantAdData?.adType == BUY && wantAdData?.timeLine) {
      setTimeLineError(false)
      setTimeCalenderError(false)
    }

    if (wantAdData?.adType == LONG_RENT && wantAdData?.moveInTimeLine) {
      setTimeLineError(false)
      setTimeCalenderError(false)
    }
    if (wantAdData?.adType == LONG_RENT && wantAdData?.moveInTimeLine == EXACT_MOVE_DATE_TYPE && wantAdData?.startDate) {
      const isDateValid = isValidDate({dateString : wantAdData?.startDate, format : DATE_FORMAT_MM_DD_YYYY});
      if (!isDateValid) {
      }else{
        setExactMoveDateError(false)
      }
     
    }
    if (wantAdData?.adType == SHORT_RENT && wantAdData?.dateType) {
      setTimeCalenderError(false)
      setTimeLineError(false)
    }
    if (wantAdData?.adType == SHORT_RENT && wantAdData?.dateType && ((wantAdData?.startDate && wantAdData?.endDate) || (wantAdData?.duration && wantAdData?.month))) {
      setDateTextBoxError(false)
    }
    if (wantAdData?.prprtyType != IS_LAND && wantAdData?.prprtyType) {

     
      if (wantAdData?.prptySubTypes && wantAdData?.prptySubTypes.length > 0) {
        if(wantAdData?.adType == BUY){
          setPropertyTypeError(false)
        } else{
          setSubPropertyTypeError(false)
        }
       
      }
    }


    if (wantAdData?.noBedRm) {
      setRequirementBedroomsError(false)
    }

    if (wantAdData?.noBathRm) {
      setRequirementBathroomsError(false)
    }

    if (wantAdData?.adTitle && textValidations(wantAdData?.adTitle)) {
      setTitleError(false)
    }
    if (wantAdData?.noBedRm && wantAdData?.noBathRm) {
      setRequirementTextColor(false)
    }

    if (textValidations(wantAdData?.adDesc)) {
      setDescriptionError(false)
    }

    if(wantAdData?.compenationType && wantAdData?.agentCompenation){
      setCompensationError(false)
    }

  }, [wantAdData])


  const handleSaveToDraft = () =>{
      if(wantAdData?.focusOut == true && wantAdData?.isPublished != 1){
        if(wantAdData?.location && wantAdData?.county){
          handleCaptureMapImage("draft", true);
          handleFormValues({focusOut: false})
        } else{
          saveMemberWantAdData(null, true) // true for checking on key up save or not for navigation 
          handleFormValues({focusOut: false})
        }
      }
  }

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearAll") {
        setWantAdData({ actvStep: 1, cmpldPerctg: 2, lat: wantAdData?.lat, lng: wantAdData?.lng,  isPublished: wantAdData?.isPublished });
      } else if (type === "clearFlexDates") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["duration", "month"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type === "dateRange") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["startDate", "endDate"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type === "moveInTypeChangeLTR") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["startDate"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type == 'clearAllExceptClient') {
        setWantAdData({
          actvStep: 10,
          cmpldPerctg: 2,
          clientData: wantAdData?.clientData,
          clientId: wantAdData?.clientId,
          clientName: wantAdData?.clientName,
          isAgentAsSeller: wantAdData?.isAgentAsSeller, wantAdId: wantAdData?.wantAdId,
          s3ImageFolder: wantAdData?.s3ImageFolder, userId: wantAdData?.userId,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          isPublished: wantAdData?.isPublished,
          isEdit: wantAdData?.isEdit

        });
        setLocationCityError(false)
        setLocationRadiusError(false)
        setZipCodeError(false)
        setPropertyTypeError(false)
        setRequirementBathroomsError(false)
        setRequirementBedroomsError(false)
        setRequirementSquareFootageError(false)
        setBudgetError(false)
        setTimeLineError(false)
        setTimeCalenderError(false)
        setDescriptionError(false)
      } else if (type == 'clearErrorStateOnPropertySelection') {
        setWantAdData({
          clientData: wantAdData?.clientData,
          clientId: wantAdData?.clientId,
          clientName: wantAdData?.clientName,
          isAgentAsSeller: wantAdData?.isAgentAsSeller, wantAdId: wantAdData?.wantAdId,
          s3ImageFolder: wantAdData?.s3ImageFolder, userId: wantAdData?.userId,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          city: wantAdData?.city,
          isZipcodeBased : wantAdData?.isZipcodeBased,
          zipCode : wantAdData?.zipCode,
          zipId : wantAdData?.zipId,
          zipIdValues : wantAdData?.zipIdValues,
          state: wantAdData?.state,
          county: wantAdData?.county,
          location: wantAdData?.location,
          mapRadius: wantAdData?.mapRadius,
          mapZoom: wantAdData?.mapZoom,
          timeLine: wantAdData?.timeLine,
          tlText: wantAdData?.tlText,
          dateType: wantAdData?.dateType,
          duration: wantAdData?.duration,
          month: wantAdData?.month,
          moveInTimeLine: wantAdData?.moveInTimeLine,
          moveInTxt: wantAdData?.moveInTxt,
          startDate: wantAdData?.startDate,
          neighborHood: wantAdData?.neighborHood,
          highSchool: wantAdData?.highSchool,
          prprtyType: wantAdData?.prprtyType,
          prptyTypeTxt: wantAdData?.prptyTypeTxt,
          adType: wantAdData?.adType,
          validationErrors: [],
          isPublished: wantAdData?.isPublished,
          isEdit: wantAdData?.isEdit
        });

        setRequirementBathroomsError(false)
        setRequirementBedroomsError(false)
        setRequirementSquareFootageError(false)
        setBudgetError(false)
        setTimeLineError(false)
        setTimeCalenderError(false)
        setDescriptionError(false)
      }
    }
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setWantAdData((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });

  };

  const calculatePercentageofCompletion = () => {
    let percentage = 0;
    let count = 0
    if (wantAdData?.clientId) {
      count++;
    }
    if (wantAdData?.adType) {
      count++;
    }
    if (wantAdData?.timeLine) {
      count++;
    }
    if (wantAdData?.location && wantAdData?.location.trim() != "") {
      count++;
    }

    if ((wantAdData?.startDate && wantAdData?.endDate)
      || (wantAdData?.month && wantAdData?.duration)
    ) {
      count++;
    }

    if (wantAdData?.prprtyType) {
      count++;
    }
    if (wantAdData?.noBathRm
      && wantAdData?.noBedRm
      && wantAdData?.sqFtMax
      && wantAdData?.sqFtMin) {
      count++;
    }
    if (wantAdData?.maxBdgt
      && wantAdData?.minBdgt) {
      count++;
    }
    if (wantAdData?.acreageMin
      && wantAdData?.acreageMin) {
      count++;
    }
    if (wantAdData?.adTitle) {
      count++;
    }

    percentage = count >= 8 ? 100 : 11 * count;
    return percentage;
  }


  async function handleSave(type) {
    let error = [];
    setDisableButton(true)
    if (type == 'draft') {
      if (!wantAdData?.clientId && !wantAdData?.isAgentAsSeller) {
        setClientSelectionError(true);
        error.push("ClientRef", "clientId")

      }

      if (!wantAdData?.adType) {
        setTypeSelectionError(true)
        error.push("adType")
      }
    } else {
      if (!wantAdData?.clientId && !wantAdData?.isAgentAsSeller) {
        setClientSelectionError(true);
        error.push("ClientRef", "clientId")

      }

      if (!wantAdData?.adType) {
        setTypeSelectionError(true)
        error.push("adTypeRef", "adType")
      }
      
     

      if ((!wantAdData?.location || !wantAdData?.county) && (!wantAdData?.zipCode || wantAdData?.zipCode.length == 0 )) {
        setLocationCityError(true)
        setZipCodeError(true)
        error.push("locationRef", "city")
      } else{
        if(wantAdData?.location|| wantAdData?.location!= "" || !wantAdData?.zipCode.length>0){
          if (!wantAdData?.mapRadius) {
            setLocationRadiusError(true)
            error.push("locationRef", "mapRadius")
          }
        } 
      }
     


      if (wantAdData?.adType == BUY && !wantAdData?.timeLine) {
        setTimeLineError(true)
        error.push("timeLineRef", "timeLine")
      }

      if (wantAdData?.adType == LONG_RENT && !wantAdData?.moveInTimeLine) {
        setTimeLineError(true)
        error.push("timeLineRef", "timeLine")
      }
      if (wantAdData?.adType == LONG_RENT && wantAdData?.moveInTimeLine == EXACT_MOVE_DATE_TYPE && !wantAdData?.startDate) {
        setExactMoveDateError(true)
        error.push("timeLineRef", "startDate")
      }
      if(wantAdData?.adType == LONG_RENT && wantAdData?.moveInTimeLine == EXACT_MOVE_DATE_TYPE && wantAdData?.startDate){
      
            const isDateValid = isValidDate({dateString : wantAdData?.startDate, format : DATE_FORMAT_MM_DD_YYYY});
            if (!isDateValid) {
              setExactMoveDateError(true)
              error.push("timeLineRef", "startDate")
            }
      }
      if (wantAdData?.adType == SHORT_RENT && !wantAdData?.dateType) {
        setTimeCalenderError(true)
        error.push("timeLineRef", "dateType")
      }
      if (wantAdData?.adType == SHORT_RENT && wantAdData?.dateType == 1 && (!wantAdData?.startDate || !wantAdData?.endDate)) {
        setDateTextBoxError(true)
        error.push("timeLineRef", "dateType")
      }

      if (wantAdData?.adType == SHORT_RENT && wantAdData?.dateType == 2 && (!wantAdData?.duration || !wantAdData?.month)) {
        setDateTextBoxError(true)
        error.push("timeLineRef", "dateType")
      }
      if (!wantAdData?.prprtyType) {
        setPropertyTypeError(true)
        error.push("propertyTypeRef", "prprtyType")
      } else {
        if (wantAdData?.prprtyType != IS_LAND && !wantAdData?.prptySubTypes || wantAdData?.prptySubTypes?.length == 0) {
         
          if(wantAdData?.adType == BUY ){
            setPropertyTypeError(true)
            error.push("propertyTypeRef", "prprtyType")
          } else{
            setSubPropertyTypeError(true)
            error.push("propertyTypeRef", "prptySubTypes")
          }
        
        }
      }

      if (wantAdData?.prprtyType != IS_PRIVATE_ROOM && wantAdData?.prprtyType != IS_LAND) {
        if (!wantAdData?.noBedRm) {
          setRequirementBedroomsError(true)
          error.push("mustHaveRef", "noBedRm")
        }

        if (!wantAdData?.noBathRm) {
          setRequirementBathroomsError(true)
          error.push("mustHaveRef", "noBathRm")
        }
        if (!wantAdData?.noBedRm || !wantAdData?.noBathRm) {
          setRequirementTextColor(true)
        }
      }

      if(!wantAdData?.compenationType && wantAdData?.adType===BUY){
        error.push("CompensationRef", "agentCompenation")      
        setCompensationError(true);
      }
      if(wantAdData?.compenationType) {
        if(wantAdData?.compenationType == 1 || wantAdData?.compenationType == 2){
          if (!wantAdData?.agentCompenation) {
            error.push("CompensationRef", "agentCompenation")   
          }
        }      
      }

    }


    if (wantAdData?.sqFtMax && wantAdData?.sqFtMin) {
      if (convertToNumber(wantAdData?.sqFtMin) >= convertToNumber(wantAdData?.sqFtMax)) {
        error.push("mustHaveRef", "sqftMaxLesser")
        setRequirementTextColor(true)
      }
    }   
    
    if (wantAdData?.minLtSz && wantAdData?.maxLtSz) {
      if (convertToNumber(wantAdData?.minLtSz) >= convertToNumber(wantAdData?.maxLtSz)) {
        error.push("mustHaveRef", "lotSizeMaxLesser");
        setRequirementTextColor(true)
      }
    } 

    if (wantAdData?.yearBuiltOption == 1) {

      if (wantAdData.yrBltMin && wantAdData.yrBltMax) {
        if ((convertToNumber(wantAdData.yrBltMax) < YEAR_BUILT_MIN ||
          convertToNumber(wantAdData.yrBltMax) > YEAR_BUILT_MAX) ||
          (convertToNumber(wantAdData.yrBltMin) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMin) > YEAR_BUILT_MAX)) {
          (convertToNumber(wantAdData.yrBltMax) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMax) > YEAR_BUILT_MAX) && error.push("yearBuiltMaxRange");
          (convertToNumber(wantAdData.yrBltMin) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMin) > YEAR_BUILT_MAX) && error.push("yearBuiltMinRange");
        } else if (convertToNumber(wantAdData.yrBltMax) <
          convertToNumber(wantAdData.yrBltMin)) {
          error.push("mustHaveRef", "yearBuiltError");
        } else if (convertToNumber(wantAdData.yrBltMin) <
          convertToNumber(wantAdData.yrBltMin)) {
          error.push("mustHaveRef", "yearBuiltError");
        }
      } else if (!wantAdData.yrBltMin || !wantAdData.yrBltMax) {
        !wantAdData.yrBltMin && error.push("mustHaveRef", "yearBuiltMinDate");
        !wantAdData.yrBltMax && error.push("mustHaveRef", "yearBuiltMaxDate");
      }

    }



    if (wantAdData?.minBdgt && wantAdData?.maxBdgt) {
      if (convertToNumber(wantAdData?.minBdgt) >= convertToNumber(wantAdData?.maxBdgt)) {
        error.push("BudgetRef", "maxBdgt")
        setBudgetError(true)
      }
    } 
    

    if (type == 'save') {
      if (!wantAdData?.adTitle) {
        setTitleError(true)
        error.push("DescriptionRef", "adTitleTextBox")
      }
    }
    if (wantAdData?.adTitle && !textValidations(wantAdData?.adTitle)) {
      error.push("DescriptionRef", "adTitle")
      setTitleError(true)
    }

    if (wantAdData?.adDesc && !textValidations(wantAdData?.adDesc)) {
      error.push("DescriptionRef", "adDesc")
      setDescriptionError(true)
    }

    if (wantAdData?.otherConcessions && !textValidations(wantAdData?.otherConcessions)) {
      error.push("OtherConcessionRef", "otherConcession")
    }

    if ((wantAdData?.isPreApprLoan == 1 || wantAdData?.isPreApprLoan == 'Yes') && !wantAdData?.agreePreApprovedLoan) {

      error.push("DescriptionRef", "agreePreApprovedLoan")
    }

    
    if (wantAdData?.agentCompenation == 0) {
      handleFormValues({ agentCompenation: "" });
    }

    if (error.length == 0) {
      handleFormValues({ validationErrors: [], updatedAt: Date.now() });
      handleCaptureMapImage(type);
    } else {

      if (error[0] == 'ClientRef') {
        window.scrollTo(0, ClientRef.current.offsetTop);
      } else if (error[0] == 'adTypeRef') {
        window.scrollTo(0, adTypeRef.current.offsetTop);
      } else if (error[0] == 'locationRef') {
        window.scrollTo(0, locationRef.current.offsetTop);
      } else if (error[0] == 'timeLineRef') {
        window.scrollTo(0, timeLineRef.current.offsetTop);

      } else if (error[0] == 'propertyTypeRef') {
        window.scrollTo(0, propertyTypeRef.current.offsetTop);
      } else if (error[0] == 'mustHaveRef') {
        window.scrollTo(0, mustHaveRef.current.offsetTop);
      } else if (error[0] == 'BudgetRef') {
        window.scrollTo(0, BudgetRef.current.offsetTop);
      } else if (error[0] == 'CompensationRef') {
        window.scrollTo(0, CompensationRef.current.offsetTop);
      }else if (error[0] == 'DescriptionRef') {
        window.scrollTo(0, DescriptionRef.current.offsetTop);
      }
      setDisableButton(false)
      handleFormValues({ validationErrors: error, updatedAt: Date.now() });

    }
  }


  const handleCaptureMapImage = (type, onkeyupSave = false) => {
    const fullScreenDiv = document.querySelector('.gm-fullscreen-control');
    const zoomIcons = document.querySelectorAll('.gmnoprint');
    const googleIcon = document.querySelector('img[alt="Google"]');
    const buttons = document.querySelectorAll('.gm-style-cc');
    const gmStyle = document.querySelector('.gm-style-moc');

    if (gmStyle) {
      gmStyle.style.display = 'none';
    }
  
    if (fullScreenDiv) {
      fullScreenDiv.style.display = 'none';
    }

    if (googleIcon) {
      googleIcon.style.display = 'none';
    }

    zoomIcons.forEach((element) => {
      element.style.display = 'none';
    });

    buttons.forEach((element) => {
      element.style.display = 'none';
    });

    if (targetRef.current) {
      html2canvas(targetRef.current, { allowTaint: true, useCORS: true }
      ).then((canvas) => {
        let dataURL = canvas
          .toDataURL(IMG_JPEG_FORMAT)
        const binaryData = dataURLToBlob(dataURL)
        handleMapImageUpload(binaryData, type, onkeyupSave)
      });
    }
  };



  const handleMapImageUpload = async (image, type, onkeyupSave) => {
    const mapResponse = image;
    const preSignedUrlRes = mapResponse
      ? await createPreSignedUrl(true, _.get(wantAdData, "s3ImageFolder", ""))
      : alert("Something went wrong while fetching map image.");
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl ? s3FullUrl.split("?")[0] : "";
        if (type == 'draft') {
          saveMemberWantAdData(imageUrl, onkeyupSave )
        } else {
          handleFormValues({
            mapImg: imageUrl,
            cmpldPerctg: 100,
            actvStep: 10,
            // isEdit: true,
            isAgentCreated: true
          });

          if (!wantAdData?.isGitchaAvailable && wantAdData?.zipCode?.length == 0) {
            checkGitchAvailability({
              state: _.get(wantAdData, "state", ""),
              county: _.get(wantAdData, "county", ""),
            }).then((response) => {
              const isGitchaAvailable = _.get(response, "result", "");
              handleFormValues({ isGitchaAvailable: isGitchaAvailable })
              if (!isGitchaAvailable) {
                setGitchaModal(true);
                setDisableButton(false);
              } else {
                setTimeout(() => {
                  navigate("/agent-want-ad-confirmation")
                }, 10);
              }
            });


          } else {
            setTimeout(() => {
              navigate("/agent-want-ad-confirmation")
            }, 10);

          }
        }
      } else {
        setDisableButton(false)
      }
    } else {
      setDisableButton(false)
    }
  };

  const checkGitchAvailability = async ({ zip, state, city, county }) => {
    const reqPayLoad = { county: county, state: state };
    return await checkGitchaAvailable(reqPayLoad).then((data) => {
      return data;
    });
  };

  const saveMemberWantAdData = async (imageUrl, onkeyupSave = false) => {
    let userId = getUserId();

    let requestPayLoad = wantAdData;
    if (!requestPayLoad) {
      requestPayLoad = JSON.parse(localStorage.getItem(AGENT_WANTAD_KEY));
    }
    let arrayValue = [];
    if(requestPayLoad?.zipIdValues){
      requestPayLoad?.zipIdValues.map((zip)=>{
        arrayValue.push(zip?.zipId)
        })
        requestPayLoad.zipId = arrayValue;
       
    } else if(requestPayLoad?.zipCode){
      requestPayLoad?.zipCode.map((zip)=>{
        if(zip?.zipId && zip?.zipId != null){
          arrayValue.push(zip?.zipId)
        }
      })
      requestPayLoad.zipId = arrayValue;
  }
   
    requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;

    requestPayLoad.mapImg = imageUrl;
    requestPayLoad.cmpldStep = _.get(wantAdData, "actvStep", 1);
    requestPayLoad.IsSavedDraft = true;
    requestPayLoad.IsPublished = false;

    // requestPayLoad.IsSavedDraft = wantAdData?.isPublished && wantAdData?.isPublished == 0 ? true : false ;
    // requestPayLoad.IsPublished = wantAdData?.isPublished && wantAdData?.isPublished == 0 ? false : true ;

    requestPayLoad.isAgentCreated = true;
    if(isAgent && !isPremiumUser){
      requestPayLoad.isAgentAsSeller = true;
    }
    requestPayLoad.cmpldPerctg = calculatePercentageofCompletion();

    saveMemberWantAd(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if (wantAdData.purchaseType || wantAdData.creditScore || wantAdData.prchTypeId || wantAdData.pFinance) {
            saveStrengthenData(responseResult.wantAdId, onkeyupSave);
          } else {
            if(!onkeyupSave){
              localStorage.removeItem(AGENT_WANTAD_KEY);

              navigate(pageURLs.wantAdListing,  {state: { showDraft: true }})
            } else{
              handleFormValues({wantAdId: responseResult.wantAdId })
            }
           
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const saveStrengthenData = async (wantAdIdValue, onkeyupSave) => {
    const wantAd = getLocalStorage(AGENT_WANTAD_KEY);
    const wantAdId = wantAdIdValue;
    let requestPayLoad = {};
    let isApproveLoan = ""
    if (_.get(wantAdData, "isPreApprLoan", null) === 'Yes' || _.get(wantAdData, "isPreApprLoan", null) == true) {
      isApproveLoan = true
    } else if (_.get(wantAdData, "isPreApprLoan", null) === 'No' || _.get(wantAdData, "isPreApprLoan", null) == true) {
      isApproveLoan = false
    }
    requestPayLoad.isPreApprLoan = isApproveLoan;
    const is1031Exchange = (wantAd?.is1031Exchange == "Yes" ||  wantAd?.is1031Exchange == 1) ? true : false
    requestPayLoad.wantAdId = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.completedStep = 1;
    requestPayLoad.adType = wantAdData.adType == BUY ? 'BUY_STRENGTHEN' : 'LTR_STRENGTHEN';
    requestPayLoad.creditScore = wantAdData.creditScore;
    requestPayLoad.maxBdgt = wantAdData.maxBdgt;
    requestPayLoad.minBdgt = wantAdData.minBdgt;
    requestPayLoad.pFinance = wantAdData.pFinance || wantAdData.finTypeId;
    requestPayLoad.purchaseType = wantAdData.purchaseType || wantAdData.prchTypeId;

    await saveMemberWantAdstrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if(!onkeyupSave){
            localStorage.removeItem(AGENT_WANTAD_KEY);
            navigate(pageURLs.wantAdListing,  {state: { showDraft: true }})
          }  else{
            handleFormValues({wantAdId: wantAdId})
          }
          // localStorage.removeItem(AGENT_WANTAD_KEY);
          // navigate(pageURLs.wantAdListing,  {state: { showDraft: true }})
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };



  return (
    <>
    <RequestStayAvailability
      show={showRequestStayPopuop}
      locationFromSTorage={browserLocation}
      onHide={handleStayRequestPopupClose}
      />
      <div className="outer-wrap position-relative mw-1168" ref={ClientRef}>
        <CommonHeader />
        <div className="row">
          {showWantadPublished === true? 
          <h2 className="text-center">Want-Ad already published</h2>
          :
          showWantadPublished === false ? <div className="col-md-12">
            <div className="edit-want-ad-wrap mw-626">
              {(wantAdData?.isFilledByClient && !wantAdData?.isPublished) ? 
              <>
              <h2 className="pt-3">Review, Title, and Post!</h2>
              <h6 className="mb-40">Make sure everything looks good, especially the description. Once you 
title it and select your compensation from the seller, you’re ready to post!</h6>
              </> :
              <>
              <h2 className="pt-3">{(( wantAdData?.isEdit) ) ? 'Edit Want-Ad' : 'Create Want-Ad'}</h2>
              <h6 className="mb-40">Help your clients find exactly what they’re looking for.{isPremiumUser ?"  Your client’s name is just for organization and hidden to everyone else but you." : ""}</h6>
              </>}

             {isPremiumUser && <ClientSelection
                clientId={wantAdData?.clientId}
                isAgentAsSeller={wantAdData?.isAgentAsSeller}
                sessionData={sessionData}
                handleFormValues={handleFormValues}
                isfromWantAdPage={true}
                clientSelectionError={clientSelectionError}
                handleSaveToDraft={handleSaveToDraft}               
                isFilledByClient={wantAdData?.isFilledByClient}
              />}
              {/* ------------------------------------------TYPE------------------------------------------ */}
              {(wantAdData?.isFilledByClient) ? <></> : <div className="wantad-type-wrap select-type-wrap red-marked-wrap" ref={adTypeRef}>
                <h6 className={typeSelectionError && "red-marked-heading"}>Type <span>*</span></h6>
                <WantAdOption
                  handleFormValues={handleFormValues}
                  adType={wantAdData?.adType}
                  clearPreviousStates={clearPreviousStates}
                  typeSelectionError={typeSelectionError}
                  handleStayRequestPopupOpen={handleStayRequestPopupOpen}
                />
              </div>}
              {/* ------------------------------------------/TYPE------------------------------------------ */}

              {/* ------------------------------------------LOCATION------------------------------------------ */}
              {wantAdData?.adType &&
                <div className={(locationRadiusError) && "wantad-location-wrap select-type-wrap red-marked-wrap"} ref={locationRef}>
                  <h6 className={(locationCityError || locationRadiusError) && "red-marked-heading"}>Location </h6>
                  <WantAdLocation
                    handleFormValues={handleFormValues}
                    location={_.get(wantAdData, "location", null)}
                    s3Imagefolder={_.get(wantAdData, "s3ImageFolder", false)}
                    lat={_.get(wantAdData, "lat", null)}
                    lng={_.get(wantAdData, "lng", null)}
                    mapZoom={_.get(wantAdData, "mapZoom", null)}
                    mapRadius={_.get(wantAdData, "mapRadius", null)}
                    neighborHood={_.get(wantAdData, "neighborHood", null)}
                    highSchool={_.get(wantAdData, "highSchool", null)}
                    zip={_.get(wantAdData, "zip", null)}
                    adType={_.get(wantAdData, "adType", null)}
                    city={_.get(wantAdData, "city", null)}
                    state={_.get(wantAdData, "state", null)}
                    county={_.get(wantAdData, "county", null)}
                    session={AGENT_WANTAD_KEY}
                    targetRef={targetRef}
                    isGitchaAvailable={_.get(wantAdData, "isGitchaAvailable", null)}
                    locationCityError={locationCityError}
                    locationRadiusError={locationRadiusError}
                    zipCode={_.get(wantAdData, "zipCode", [])}
                    zipIdValues={_.get(wantAdData, "zipIdValues", null)}
                    zipCodeError={zipCodeError}
                    setLocationCityError={setLocationCityError}
                    setLocationError={setLocationError}
                    locationError={locationError}
                    isFromConfirmationPage={isFromConfirmationPage}
                  />
                </div>
              }
              {/* ------------------------------------------/LOCATION------------------------------------------ */}

              {/* ------------------------------------------TIMELINE------------------------------------------ */}
              {wantAdData?.adType &&
                <div className="wantad-type-wrap select-type-wrap red-marked-wrap" ref={timeLineRef}>
                  <h6 className={(timeLineError || timelineCalenderError) && "red-marked-heading"}>Timeline <span>*</span></h6>
                  {wantAdData?.adType == LONG_RENT || wantAdData?.adType == SHORT_RENT ? (
                    <WantAdCalendar
                      handleFormValues={handleFormValues}
                      sDate={_.get(wantAdData, "startDate", null)}
                      eDate={_.get(wantAdData, "endDate", null)}
                      adType={_.get(wantAdData, "adType", null)}
                      dateType={_.get(wantAdData, "dateType", null)}
                      duration={_.get(wantAdData, "duration", null)}
                      month={_.get(wantAdData, "month", null)}
                      moveInTimeLine={convertToNumber(_.get(wantAdData, "moveInTimeLine", null))}
                      moveInDate={_.get(wantAdData, "startDate", null)}
                      clearPreviousStates={clearPreviousStates}
                      timeLineError={timeLineError}
                      timelineCalenderError={timelineCalenderError}
                      exactMoveDateError={exactMoveDateError}
                      dateTextBoxError={dateTextBoxError}
                    />
                  ) : (
                    <WantAdTiming
                      handleFormValues={handleFormValues}
                      timeLine={_.get(wantAdData, "timeLine", null)}
                      adType={_.get(wantAdData, "adType", null)}
                      timeLineError={timeLineError}
                    />
                  )}
                </div>
              }
              {/* ------------------------------------------/TIMELINE------------------------------------------ */}

              {/* ------------------------------------------Property type------------------------------------------ */}
              {wantAdData?.adType &&
                <div className={(propertyTypeError || subPropertyTypeError) ? "wantad-type-wrap select-type-wrap red-marked-wrap" :
                  "wantad-type-wrap select-type-wrap"
                } ref={propertyTypeRef}>
                  <h6 className={propertyTypeError && "red-marked-heading"}>Property type <span>*</span></h6>
                  {wantAdData?.adType == LONG_RENT || wantAdData?.adType == SHORT_RENT ? (
                    <WantAdRentPropertyTypes
                      handleFormValues={handleFormValues}
                      propertyType={_.get(wantAdData, "prprtyType", null)}
                      adType={_.get(wantAdData, "adType", null)}
                      clearPreviousStates={clearPreviousStates}
                      prptySubTypes={_.get(wantAdData, "prptySubTypes", [])}
                      propertyTypeError={propertyTypeError}
                      subPropertyTypeError={subPropertyTypeError}
                    />
                  ) : (
                    <WantAdType
                      handleFormValues={handleFormValues}
                      propertyType={_.get(wantAdData, "prprtyType", "")}
                      adType={_.get(wantAdData, "adType", "")}
                      clearPreviousStates={clearPreviousStates}
                      propertyTypeError={propertyTypeError}
                      prptySubTypes={_.get(wantAdData, "prptySubTypes", [])}
                    />
                  )}
                </div>
              }
              {/* ------------------------------------------/Property type------------------------------------------ */}

              {/* ------------------------------------------Requirements------------------------------------------ */}
              {wantAdData?.adType && wantAdData?.prprtyType &&
                // <div className={requirementTextColor ? "requirements-wrap red-marked-wrap" : "requirements-wrap "}>
                //   <h6 className={requirementTextColor && "red-marked-heading"}>Requirements</h6>
                <div className={requirementTextColor ? "requirements-wrap red-marked-wrap mb-3" : "requirements-wrap mb-3"} ref={mustHaveRef}>
                  <h6 >Requirements</h6>
                  {(wantAdData?.adType == SHORT_RENT)
                    && wantAdData?.prprtyType != IS_LAND
                    && wantAdData?.prprtyType != IS_PRIVATE_ROOM &&

                    <WantAdShortRentMstHves
                      handleFormValues={handleFormValues}
                      noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                      noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                      noOfBeds={_.get(wantAdData, "noOfBeds", null)}
                      alwPets={_.get(wantAdData, "alwPets", "")}
                      adaAccess={_.get(wantAdData, "adaAccess", "")}
                      requirementBedroomsError={requirementBedroomsError}
                      requirementBathroomsError={requirementBathroomsError}

                    />
                  }
                  {(wantAdData?.adType == LONG_RENT)
                    && wantAdData?.prprtyType != IS_LAND
                    && wantAdData?.prprtyType != IS_PRIVATE_ROOM &&

                    <WantAdRentMustHaves
                      handleFormValues={handleFormValues}
                      noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                      noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                      SFMin={_.get(wantAdData, "sqFtMin", null)}
                      SFMax={_.get(wantAdData, "sqFtMax", null)}
                      // SFMin={_.get(wantAdData, "sqFtMin", null)}
                      // SFMax={_.get(wantAdData, "sqFtMax", null)}
                      garageSpace={_.get(wantAdData, "grgSpce", null)}
                      entreSpceMstHve={_.get(wantAdData, "entreSpceMstHve", [])}
                      alwLrgDogs={_.get(wantAdData, "alwLrgDogs", '')}
                      alwSmlDogs={_.get(wantAdData, "alwSmlDogs", '')}
                      alwCats={_.get(wantAdData, "alwCats", '')}
                      adaAccess={_.get(wantAdData, "adaAccess", '')}
                      stories={_.get(wantAdData, 'stories', null)}
                      mustHaveErrors={_.get(wantAdData, 'validationErrors', null)}
                      requirementBedroomsError={requirementBedroomsError}
                      requirementBathroomsError={requirementBathroomsError}
                    />
                  }

                  {((wantAdData?.prprtyType != IS_LAND && wantAdData?.adType == BUY)
                    || (convertToNumber(wantAdData?.prprtyType) != IS_PRIVATE_ROOM
                      && convertToNumber(wantAdData?.prprtyType) != IS_LAND
                      && wantAdData?.adType != LONG_RENT
                      && wantAdData?.adType != SHORT_RENT)) &&

                    <WantAdMustHaves
                      handleFormValues={handleFormValues}
                      noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                      noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                      SFMin={_.get(wantAdData, "sqFtMin", null)}
                      SFMax={_.get(wantAdData, "sqFtMax", null)}
                      garageSpace={_.get(wantAdData, "grgSpce", null)}
                      yearBuiltMin={_.get(wantAdData, "yrBltMin", null)}
                      yearBuildMax={_.get(wantAdData, "yrBltMax", null)}
                      mustHaveErrors={_.get(wantAdData, 'validationErrors', null)}
                      stories={_.get(wantAdData, 'stories', null)}
                      yearBuiltOption={_.get(wantAdData, 'yearBuiltOption', null)}
                      minLtSz={_.get(wantAdData, 'minLtSz', null)}
                      maxLtSz={_.get(wantAdData, 'maxLtSz', null)}
                      adaAccess={_.get(wantAdData, 'adaAccess', null)}
                      requirementBedroomsError={requirementBedroomsError}
                      requirementBathroomsError={requirementBathroomsError}
                      propertyType={wantAdData?.prprtyType}
                    />
                  }
                  {
                    (convertToNumber(wantAdData?.prprtyType) === IS_PRIVATE_ROOM) &&

                    <WantAdRentMustHavePrivate
                      handleFormValues={handleFormValues}
                      accsPvtBthRm={_.get(wantAdData, "accsPvtBthRm", '')}
                      alwPets={_.get(wantAdData, "alwPets", '')}
                      adaAccess={_.get(wantAdData, "adaAccess", '')}
                      pvtEntrnce={_.get(wantAdData, "pvtEntrnce", '')}
                    />
                  }
                  {
                    convertToNumber(wantAdData?.prprtyType) == IS_LAND &&

                    <WantAdLandMustHaves
                      handleFormValues={handleFormValues}
                      lotType={_.get(wantAdData, "lotType", null)}
                      roadType={_.get(wantAdData, "roadType", null)}
                      isPrivtDrilInstld={_.get(wantAdData, "isPrvtDrInst", null)}
                      onSiteElec={_.get(wantAdData, "onSiteElec", null)}
                      waterSupply={_.get(wantAdData, "waterSupply", null)}
                      isDrldWelInstld={_.get(wantAdData, "isDrldWelInst", null)}
                      wasteWtrSys={_.get(wantAdData, "wasteWtrSys", null)}
                      isSepTankInstld={_.get(wantAdData, "isSepTankInst", null)}
                      isSepTankApprvd={_.get(wantAdData, "isSepTankAppr", null)}
                      // mnLtSzId={_.get(wantAdData, "mnLtSzId", null)}
                      // mnLtSzTxt={_.get(wantAdData, "mnLtSzTxt", null)}
                      // mxLtSzId={_.get(wantAdData, "mxLtSzId", null)}
                      // mxLtSzTxt={_.get(wantAdData, "mxLtSzTxt", null)}
                      minLtSz={_.get(wantAdData, 'minLtSz', null)}
                      maxLtSz={_.get(wantAdData, 'maxLtSz', null)}
                      mustHaveErrors={_.get(wantAdData, 'validationErrors', null)}
                      propertyType={wantAdData?.prprtyType}
                    />
                  }
                </div>
              }
              {/* ------------------------------------------/Requirements------------------------------------------ */}

              {/* ------------------------------------------Nice-to-haves------------------------------------------ */}
              {wantAdData?.adType && wantAdData?.prprtyType && (
                <NiceToHave
                  selectedNiceToHave={wantAdData?.nice2Have}
                  // niceToHavesList={niceToHavesList}
                  selectedniceToHavesList={selectedniceToHavesList}
                  propertyType={wantAdData?.prprtyType}
                  adType={wantAdData?.adType}
                  handleFormValues={handleFormValues}
                  title="Nice-to-haves" />
              )}
              {/* ------------------------------------------/Nice-to-haves------------------------------------------ */}
              {/* ------------------------------------------Finance/Purchase type ------------------------------------------ */}
              {wantAdData?.adType && wantAdData?.prprtyType &&
                <div className={budgetError ? "finance-wrap red-marked-wrap pb-24" : "finance-wrap pb-24"} ref={BudgetRef}>
                  {/* <h6 className={budgetError && "red-marked-heading"}>Finance</h6> */}
                  <h6 >Finance</h6>
                  <WantAdBudget
                    handleFormValues={handleFormValues}
                    budgetMin={_.get(wantAdData, "minBdgt", null)}
                    budgetMax={_.get(wantAdData, "maxBdgt", null)}
                    isAdjustBdget={_.get(wantAdData, "isAdjustBdget", null)}
                    isPreApprLoan={_.get(wantAdData, "isPreApprLoan", null)}
                    adType={wantAdData?.adType}
                    // agentCompenation={_.get(wantAdData, "agentCompenation", null)}
                    errors={_.get(wantAdData, 'validationErrors', null)}

                  />   


                  <div className="wantad-type-wrap select-type-wrap pb-2">
                    {(wantAdData?.adType == BUY) &&
                      <>
                        <label className="form-label mb-12">Purchase Type</label>
                        <WantAdPropertyPurchase
                          handleFormValues={handleFormValues}
                          propertyPurchase={_.get(wantAdData, "purchaseType", null) || _.get(wantAdData, "prchTypeId", null) }
                          is1031Exchange={_.get(wantAdData, "is1031Exchange", null)}
                          isEdit='true'

                        />
                      </>
                    }
                    {
                      (wantAdData?.adType == LONG_RENT) &&
                      <>
                        <label className="form-label">Credit score range</label>
                        <WantAdRentCreditScore
                          handleFormValues={handleFormValues}
                          creditScore={_.get(wantAdData, "creditScore", null)}

                        />
                      </>

                    }
                    {(wantAdData?.adType == BUY) &&
                      <>
                        <label className="form-label">Financing</label>
                        <WantAdPurchaseFinance
                          handleFormValues={handleFormValues}
                          timeLine={_.get(wantAdData, "timeLine", null)}
                          financeType={_.get(wantAdData, "finTypeId", null) || _.get(wantAdData, "pFinance", null)}
                          isPreApprLoan={_.get(wantAdData, "isPreApprLoan", null)}
                          agreePreApprovedLoan={_.get(wantAdData, "agreePreApprovedLoan", null) ??  _.get(wantAdData, "isPreApprLoan", null)}
                          errors={_.get(wantAdData, 'validationErrors', null)}
                        />
                      </>
                    }
                  </div>
                </div>
              }
              {/* ------------------------------------------/Finance/Purchase type------------------------------------------ */}
              {/* ------------------------------------------Description------------------------------------------ */}

              {wantAdData?.adType && wantAdData?.prprtyType &&
                <div className={titleTextBoxError || descriptionError ? "description-wrap red-marked-wrap" : "description-wrap"} ref={DescriptionRef}>
                  <h6 className={titleTextBoxError || descriptionError && "red-marked-heading"}>Description</h6>
                  <WantAdDescription
                    handleFormValues={handleFormValues}
                    adTitle={_.get(wantAdData, "adTitle", "")}
                    adDescription={_.get(wantAdData, "adDesc", "")}
                    errors={_.get(wantAdData, 'validationErrors', null)}
                    titleTextBoxError={titleTextBoxError}
                    descriptionError={descriptionError}
                  />
                </div>
              }
              {/* ------------------------------------------/Description------------------------------------------ */}

              {(wantAdData?.adType == BUY || wantAdData?.prprtyType == IS_LAND) && (wantAdData?.prprtyType)  && 
                  <div className={compensationError ? "finance-wrap red-marked-wrap pb-24" : "finance-wrap pb-24"} ref={CompensationRef}>
                  <h6 className={compensationError ? "red-marked-heading" : ""}>Requested Concessions From Seller</h6>
                   <div className="position-relative">
                   <FormLabel className={titleTextBoxError && "red-marked-label"}>
                   Buyer’s agent compensation <span>*</span>
                    </FormLabel>
                    <ToolTip
                      title={
                        "In accordance with the Federal Fair Housing Act, we recommend not including personally identifiable information, such as race, gender, sexual preference or age. To learn more, <a href='https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations' target='_blank'>read about the Fair Housing Act here.</a>"
                      }
                    />
                    </div>
                    <AgentCompensation 
                    handleFormValues={handleFormValues}
                    agentCompenation={(_.get(wantAdData, "agentCompenation", null))}
                    compenationType={parseInt(_.get(wantAdData, "compenationType", null))}
                    errors={_.get(wantAdData, 'validationErrors', null)}
                    otherConcessions={(_.get(wantAdData, "otherConcessions", null))}
                    />
                  </div>  
                 }

            </div>

            <div className="edit-want-ad-btnwrap mt-4">
              {(_.get(wantAdData, "isPublished", '') == 0) && (
                <Button variant="" className="btn-tertiary me-2" disabled={disableButton} onClick={() => handleSave("draft")}>
                  Save As Draft
                </Button>
              )}
              <Button variant="primary" className="btn" disabled={disableButton} onClick={() => handleSave("save")}>
                Confirm
              </Button>
            </div>
          </div> : <Loader />}
        </div>
      </div>
      <GitchaNotAvailableModal
        show={gitchaModalShow}
        session={AGENT_WANTAD_KEY}
        onHide={() => setGitchaModal(false)}
        title={"Gitcha isn't currently available in this location yet."}
        desc={
          "We are diligently working to open up your area! Please type in the cities where you need us, and we'll take it up with the bosses."
        }
      />
    </>
  );
};

export default AgentCreateOrEditWantAd;

