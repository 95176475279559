import React, { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, FormLabel } from "react-bootstrap";
import {
  handleTextBoxChange,
  handleRadioButonChange,
} from "../../actions/common";
import TextBox from "../Common/TextBox";

import ButtonGroups from "../Common/ButtonGroups";
import WantAdSquareFeet from "../Common/WantAdSquareFeet";

import {
  NEW_CONSTRUCTION,
  STORY_ANY,
  STORY_SINGLE,
  YEAR_BUILT_RANGE,
} from "../../constants/common";
import {
  yearBuiltMaxMissing,
  yearBuiltMinMissing,
  yearBuiltRangeMissing,
  yrBltMaxLesser,
  yrBltMinGreater,
  yrMaxInValid,
  yrMinInValid,
} from "../../constants/createWantAd";
import WantAdLotSize from "../Common/WantAdLotSize";
const WantAdMustHaves = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    SFMin,
    SFMax,
    garageSpace,
    yearBuiltMin,
    yearBuildMax,
    mustHaveErrors,
    stories,
    yearBuiltOption,
    minLtSz,
    maxLtSz,
    errorUpdatedAt,
    adaAccess,
  } = props;

  const [yearBuiltMinError, setYearBuiltMinError] = useState("");
  const [yearBuiltMaxError, setYearBuiltMaxError] = useState("");
  const YEAR_BUILT_MIN = 1900;
  const YEAR_BUILT_MAX = new Date().getFullYear();
  const yearBuildRef = useRef(null);

  useEffect(() => {
    handleContinueButton();
  }, []);

  useEffect(() => {
    setYearBuiltMinError("");
    setYearBuiltMaxError("");
    if (mustHaveErrors) {
      switch (mustHaveErrors) {
        case yearBuiltRangeMissing:
          setYearBuiltMinError("Enter Min Year");
          setYearBuiltMaxError("Enter Max Year");
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
        case yearBuiltMaxMissing:
          setYearBuiltMaxError("Enter Max Year");
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
        case yearBuiltMinMissing:
          setYearBuiltMinError("Enter Min Year");
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
        case yrMinInValid:
          setYearBuiltMinError(
            "Enter Valid Year. Minimum year should be 1900."
          );
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
        case yrMaxInValid:
          setYearBuiltMaxError("Enter Valid Year");
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
        case yrBltMaxLesser:
          setYearBuiltMaxError("Max year must be greater than min year");
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
        case yrBltMinGreater:
          setYearBuiltMaxError("Min year must be less than max year");
          window.scrollTo(0, yearBuildRef.current.offsetTop);
          break;
      }
      handleContinueButtonState();
    }
  }, [mustHaveErrors, errorUpdatedAt]);

  useEffect(() => {
    handleContinueButton();
  }, [noOfBedrooms, noOfBathrooms]);

  const handleContinueButton = () => {
    let isDisable = false;
    if (noOfBedrooms && noOfBathrooms) {
      handleContinueButtonState(isDisable);
    } else {
      isDisable = true;
      handleContinueButtonState(isDisable);
    }
  };

  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
    //handleContinueButton()
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
    //handleContinueButton()
  };

  const handleStorySelection = (selectedStory) => {
    if (selectedStory === stories) {
      handleFormValues({ stories: "" });
    } else {
      handleFormValues({ stories: selectedStory });
    }
  };

  const handleYearBuiltMinchange = (e) => {
    const value = handleTextBoxChange(e);
    handleFormValues({ yrBltMin: value });
  };

  const handleYearBuiltMaxchange = (e) => {
    const value = handleTextBoxChange(e);
    handleFormValues({ yrBltMax: value });
  };

  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ grgSpce: value });
  };

  const handleYearBuiltSelection = (selectedValue) => {
    if (selectedValue === yearBuiltOption) {
      handleFormValues({
        yearBuiltOption: "",
        yrBltMin: "",
        yrBltMax: "",
      });
    } else {
      handleFormValues({
        yearBuiltOption: selectedValue,
        yrBltMin: "",
        yrBltMax: "",
      });
    }
  };

  const handleCheckBoxItemClick = (e) => {
    handleFormValues({ adaAccess: e.target.checked });
  };

  return (
    <div className="center-content-sec mw-524">
      <div className="btn-group-wrap">
        <FormLabel>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
        />
      </div>
      <div className="btn-group-wrap">
        <FormLabel>
          Bathrooms<span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>
      <div className="wantad-timing-wrap">
        <WantAdSquareFeet
          handleFormValues={handleFormValues}
          SFMax={SFMax}
          SFMin={SFMin}
          sqFtError={mustHaveErrors}
          errorUpdatedAt={errorUpdatedAt}
        />
      </div>
      <div className="btn-group-btm-wrap">
        <FormLabel>Garage Spaces</FormLabel>

        <ButtonGroups
          variant="outline-primary"
          section="Garage"
          value={garageSpace}
          items={["Any", "1+", "2+", "3+"]}
          onClick={(e) => handleGarageClick(e)}
          handleFormValues={handleFormValues}
        />
      </div>

      <div className="btn-group-btm-wrap">
        <FormLabel>Stories</FormLabel>
        <ButtonGroup aria-label="Basic example">
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_ANY)}
            variant={
              stories === STORY_ANY
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Any
          </Button>
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_SINGLE)}
            variant={
              stories === STORY_SINGLE
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Single Story Only
          </Button>
        </ButtonGroup>
      </div>

      <div className="wantad-timing-wrap">
        <WantAdLotSize
          handleFormValues={handleFormValues}
          mnLotSze={minLtSz}
          mxLotSze={maxLtSz}
          lotSizeError={mustHaveErrors}
          errorUpdatedAt={errorUpdatedAt}
        />
      </div>

      <div className="range-values-wrap">
        <label className="form-label">Year Built</label>
        <div className="row">
          <div className="wantad-timing-wrap">
            <div className="col-md-12">
              <label className="radio-wrap mb-3">
                <input
                  type="radio"
                  name="radio_yearBuilt"
                  value=""
                  onClick={(e) => handleYearBuiltSelection(YEAR_BUILT_RANGE)}
                  checked={
                    yearBuiltOption && yearBuiltOption == YEAR_BUILT_RANGE
                  }
                  onChange={() => {}} //Avoid console warning
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2>Set range</h2>
                  </div>
                </span>
              </label>
            </div>
            {yearBuiltOption === YEAR_BUILT_RANGE && (
              <div className="square-footage-wrap mb-0" ref={yearBuildRef}>
                <ul className="range-values-list range-value-adjust">
                  <li>
                    <TextBox
                      type="number"
                      minValue={YEAR_BUILT_MIN}
                      maxValue={YEAR_BUILT_MAX}
                      errorMessage={yearBuiltMinError}
                      errorClass="error-msg error-msg-height"
                      value={yearBuiltMin}
                      placeholder="Min year"
                      class={
                        yearBuiltMinError
                          ? "form-control validation-txtfld"
                          : "form-control"
                      }
                      onChange={(e) => {
                        handleYearBuiltMinchange(e);
                      }}
                    />
                  </li>

                  <li className="text-center d-none d-md-block">
                    <span className="middle-separator">-</span>
                  </li>
                  <li className="position-relative">
                    <TextBox
                      type="number"
                      minValue={YEAR_BUILT_MIN}
                      maxValue={YEAR_BUILT_MAX}
                      errorMessage={yearBuiltMaxError}
                      errorClass="error-msg error-msg-height"
                      value={yearBuildMax}
                      placeholder="Max year"
                      class={
                        yearBuiltMaxError
                          ? "form-control validation-txtfld"
                          : "form-control"
                      }
                      onChange={(e) => {
                        handleYearBuiltMaxchange(e);
                      }}
                    />
                  </li>
                </ul>
              </div>
            )}

            <div className="col-md-12">
              <label className="radio-wrap mb-3">
                <input
                  type="radio"
                  name="radio_yearBuilt"
                  value=""
                  onClick={(e) => handleYearBuiltSelection(NEW_CONSTRUCTION)}
                  checked={
                    yearBuiltOption && yearBuiltOption == NEW_CONSTRUCTION
                  }
                  onChange={() => {}} //Avoid console warning
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2>New construction only</h2>
                  </div>
                </span>
              </label>
            </div>
            <br />
            <div className="range-values-wrap">
              <label className="form-label">Additional Requirements</label>
              <div className="row">
                <div className="wantad-timing-wrap">
                  <div className="col-md-12">
                    <label className="check-wrap mb-3">
                      <input
                        type="checkbox"
                        name="chk-mh"
                        // id={id}
                        checked={adaAccess ? true : false}
                        onChange={(e) => handleCheckBoxItemClick(e)}
                      />
                      <span>
                        <div className="timing-wrap-info">
                          <h2 className="pb-0">ADA Accessible</h2>
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantAdMustHaves;
