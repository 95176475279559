import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import IntroductionDetail from "./IntroductionDetail";
import MarkFitModal from "./MarkFitModal";
import { updateIntroduction } from "../../helpers/message";
import { updateIntroNotFit } from "../../services/introductionService";
import { INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST } from "../../constants/common";

const PendingIntroductionList = (props) => {
  const {
    list,
    count,
    updateIntroPageOnScroll,
    markNotFitModelShow,
    ViewIntroductionModalShow
  } = props;

  

  const scrollRef = useRef(null);

  const handleScroll = () => {
    const el = scrollRef.current;
    const scrollHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const clientHeight = el.clientHeight;
    if (
      scrollHeight !== clientHeight &&
      scrollTop !== 0 &&
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
    ) {
      const section = "pending";
      updateIntroPageOnScroll(section);
    } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
      //setCurrPage(prevState => prevState -1);
    }
  };

 

  return (
    <>
      {count > 0 && (
        <div className="intro-list-sec">
          <div className="title-sec">
            <h5>
              Pending <span>({count})</span>
            </h5>
          </div>
          <div
            className="property-block-scroll"
            onScroll={handleScroll}
            ref={scrollRef}
          >
            {list.map((intro) => {
              const introId = _.get(intro, "IntroductionId", "")
              return (
                <div className="property-block" key={introId}>
                  <div
                    className="property-info"
                    onClick={() => ViewIntroductionModalShow(introId)}
                  >
                    <IntroductionDetail introDetails={intro} />
                  </div>
                  <div className="property-action flex-shrink-0">
                    <Button
                      variant="light"
                      className="btn-decline"
                      onClick={() => markNotFitModelShow(introId, INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST)}
                    >
                      Mark not a fit
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => ViewIntroductionModalShow(introId)}
                    >
                      View
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

    </>
  );
};
export default PendingIntroductionList;
