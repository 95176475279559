import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const ConfirmStatusModal = (props) => {
  const {
    onHide,
    show,
    isConfirmOwner,
    isConfirmPropLoc,
    handleCheckBoxItemClick,
    handleModalConfirmClick,
    isShortRentChecked,
    isDisabled,
    confirmOwnerChkBx,
    confirmPropLocChkBx} = props;
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Confirm Status</h3>
        <div className="text-start find-renters-wrap modalStyle">
        <label className="form-check-label" for="status1">
            <input 
            className="form-check-input" 
            type="checkbox"
            id={confirmOwnerChkBx}
            checked={isConfirmOwner}
            onChange={(e) => handleCheckBoxItemClick(e)} 
            />
            <div className="label-inner-wrap">
              <p>I confirm that I am the owner of this property and/or have authorization from the owner to rent/sub-lease this property.</p>
            </div>
        </label>
        </div>

        {isShortRentChecked && <div className="text-start find-renters-wrap modalStyle">
        <label className="form-check-label" for="status2">
            <input 
            type="checkbox"
            className="form-check-input" 
            id={confirmPropLocChkBx}
            checked={isConfirmPropLoc}
            onChange={(e) => handleCheckBoxItemClick(e)}
              />
            <div className="label-inner-wrap">
              <p>I confirm that the property is located in an area that does not have restrictions of Short-term Rentals OR I have a license to rent my property out for short-term stays.</p>
            </div>
        </label>
        </div>}
     
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" disabled={isDisabled} onClick={() => handleModalConfirmClick()}>Confirm</button>
            <button type="button" className="btn btn-secondary w-100" onClick={() => onHide(false)}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default ConfirmStatusModal;