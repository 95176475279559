import React, { useState, useEffect, useRef, createContext } from "react";
import Form from "react-bootstrap/Form";
import { Stack, Popover, OverlayTrigger, CloseButton } from "react-bootstrap";
import Overlay from "react-bootstrap/Overlay";
import LeftpanelTab from "../components/Onboarding/Dashboard/LeftpanelTab";
import CommonHeader from "../components/Onboarding/CommonHeader";
import SiteFooter from "../components/Onboarding/SiteFooter";
import PropertyListing from "../components/Onboarding/Dashboard/PropertyListing";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select, { components } from "react-select";
import SelectDropDown from "../components/Common/SelectDropDown";
import InvTutsImg from "../images/inventory-tuts-image.png";
import MatchTutorial from "../components/Tutorial/MatchTutorialModal";

import base64 from "base-64";
import utf8 from "utf8";
import {
  BUY,
  WANT_AD_KEY,
  WANT_AD_PAGE_LIMIT,
  USER_INFO_KEY,
  AGENT_WANTAD_KEY,
  HOME_PAGE,
  INVENTORY_KEY,
  STRENGTHEN_WANT_AD_KEY,
  STRENGTHEN_INVENTORY_KEY,
  USER_LOCATION_KEY,
  CLIENT_DETAILS_KEY,
  WANT_AD_ARRAY_KEY,
  LONG_RENT,
  SHORT_RENT,
  USER_TYPE_AGENT,
  USER_TYPE_MEMBER,
} from "../constants/common";
import {
  getGitachGenieMatchedWantAds,
  getWantAds,
} from "../services/wantAdService";
import HomeLoadingSkeleton from "./HomeLoadingSkeleton";
import {
  getLocalStorage,
  setLocalStorage,
  loggedInUser,
  removeMultipleLocalStorageItems,
  removeLocalStorage,
  checkTutorialDisplayStatus,
  saveTutorialStatusToLocal,
  getUserType,
  getTitleText,
} from "../helpers/common";
import RequestGitchaAvailability from "../components/Common/RequestGitchaAvailability";
import { favouriteWantAd, unFavouriteWantAd } from "../services/wantAdService";
import { toast } from "react-toastify";
import {
  getAgentsList,
  getUserInventoryCount,
} from "../services/wantAdService";
import { handleTextBoxChange } from "../actions/common";
import { pageURLs } from "../constants/pageURLs";
import { useNavigate, useSearchParams } from "react-router-dom";
import GeoLocation from "../components/Common/GeoLocation";
import wantAds from "../reducers/wantAds";
import { usePrevious } from "../hooks/usePrevious";
import { saveTutorialStatus } from "../services/commonServices";

import { checkGitchaAvailable } from "../services/commonServices";
import GitchaAvailableLocations from "../components/GitchaAvailableLocationsScreen";
import RequestStayAvailability from "../components/Common/RequestStayAvailability";
import AddPropertyPlanChecking from "../components/Common/AddPropertyPlanChecking";
import SearchLocationScreen from "../components/SearchLocationScreen";
import PreLaunchCityRequestModal from "../components/Common/PreLauchCityRequestModal";
export const filterContext = createContext();
const Home = (props) => {
  const [searchParams] = useSearchParams();
  const AdTypeFromUrl = searchParams.get("AdType");
  const tutorialPage = 1;
  const myRef = useRef(null);
  const navigate = useNavigate();
  const filterQueryFromStorage = getLocalStorage("filterQuery");
  const browserLocation = getLocalStorage("userLocation");
  const searchedLocation = getLocalStorage("searchedLocation");

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "highPrice", label: "Highest price" },
    { value: "lowPrice", label: "Lowest price" },
    // { value: "Most Relevant", label: "Most Relevant" },
  ];

  const [showMatchTutorial, setMatchTutorial] = useState(false);
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [adList, setAdList] = useState([]);
  const [adListGitchaGenie, setAdListgitchaGenie] = useState([]);
  const [wasLastPage, setLastPage] = useState(false);
  const [adType, setAdType] = useState(
    AdTypeFromUrl
      ? AdTypeFromUrl
      : getLocalStorage("FilterAdtype")
      ? getLocalStorage("FilterAdtype")
      : BUY
  );
  const filterTabKey = getLocalStorage("FilterTabKey");
  const previousBudgetMin = getLocalStorage("priceRangeMatch");
  const [totalCount, setTotalCount] = useState(null);
  const [totalCountMatch, setTotalCountMatch] = useState(null);
  const user = loggedInUser();
  const userType = getUserType();
  const [sessionData, setSessionData] = useState(
    getLocalStorage(USER_INFO_KEY)
  );

  const [results, setResults] = useState([]);
  //form States on  agent client search
  const [formData, setFormData] = useState("");
  const [reloadAgents, setReloadAgents] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showNoResult, setShowNoResult] = useState(false);
  const [offCanvaShow, setOffCanvaShow] = useState(false);
  const handleCanvasClose = () => setOffCanvaShow(false);
  const handleCanvasShow = () => setOffCanvaShow(true);
  const [userLocation, setUserLocation] = useState(null);
  const [isGitchaGenieFilter, setIsGitchaGenieFilter] = useState(
    filterTabKey === "match" ? true : false
  );
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [reload, setReload] = useState(false);
  const [sortParam, setSortParam] = useState(options[0]);
  const [wantAdClickCounter, setWantAdClickCounter] = useState(0);
  const [showGitchaNotAvailablePopup, setShowGitchaNotAvailablePopup] =
    useState(false);
  const [gitchaNotAvailble, setGitchaNotAvailable] = useState(
    userLocation?.isFromAvailableCities ? true : false
  );
  const wantAdCount = adList?.length;
  const wantAdCountGitchaGenie = adListGitchaGenie?.length;
  const locationString = getLocalStorage("LocationString");
  const [isCityInPreLaunch, setIsCityInPreLaunch] = useState(false);
  const [PreLaunchStateName, setPreLaunchStateName] = useState(null);
  const [showGitchaGeineLabel, setShowGitchaGeineLabel] = useState(false);

  const [
    gitchaAvailableCheckingCompleted,
    setGitchaAvailableCheckingCompleted,
  ] = useState(false);
  const turnOffStay = process.env.REACT_APP_TURN_OFF_STAY ? true : false;
  const [showRequestStayPopuop, setShowRequestStayPopup] = useState(false);
  const handleStayRequestPopupOpen = () => {
    setShowRequestStayPopup(true);
  };

  const handleStayRequestPopupClose = () => {
    setShowRequestStayPopup(false);
  };

  const handleUserLocation = async (location) => {
    setLocalStorage("userLocation", location);
    if (location) {
      const gitchaAvailablePopupHandled = getLocalStorage(
        "gitchaAvailablePopupHandled"
      );
      const reqPayLoad = {
        county: location.county,
        state: location.state,
      };
      await checkGitchaAvailable(reqPayLoad).then((data) => {
        if (_.get(data, "result") == true) {
          location["isFromAvailableCities"] = true;
          setGitchaAvailableCheckingCompleted(true);
          setGitchaNotAvailable(false);
          if (!locationString || locationString == "") {
            if (searchedLocation) {
              setUserLocation(searchedLocation);
            } else {
              setUserLocation(location);
            }
          }
        } else {
          setGitchaAvailableCheckingCompleted(true);
          if (!locationString || locationString == "") {
            if (searchedLocation) {
              setUserLocation(searchedLocation);
            } else {
              setUserLocation(null);
            }
          }
          setGitchaNotAvailable(true);
          setTimeout(() => {
            if (
              !gitchaAvailablePopupHandled &&
              sessionData &&
              searchedLocation == null &&
              (!locationString || locationString == "")
            ) {
              setShowGitchaNotAvailablePopup(true);
            }
          }, 300);
        }
      });
    } else {
      if (searchedLocation) {
        setUserLocation(searchedLocation);
      } else {
        setUserLocation(null);
      }
    }
  };

  let showNotification = checkTutorialDisplayStatus(tutorialPage);
  const [showNotificationIcon, setNotificationIcon] = useState(false);
  const [gitchaNotAvailableLocation, setGitchaNotAvailableLocation] =
    useState(null);
  const [fromLocationSearch, setFromLocationSearch] = useState(false);
  const adCount = adList && adList.length;
  const PRICE_MIN = 0;
  const PRICE_MAX = null;
  const SQFT_MIN = null;
  const SQFT_MAX = null;
  const initialQuery =
    adType == "STR"
      ? {
          adType: adType,
          // location: null,
          propertyType: [],
          alwpets: null,
          adaAccess: null,
          pvtBath: null,
          pvtEntrance: null,
          typeOfPlace: [10, 11, 6],
          priceMin: PRICE_MIN,
          priceMax: PRICE_MAX,
          sortType: sortParam?.value,
          bedrooms: "",
          beds: "",
          baths: "",
          sqrftMin: SQFT_MIN,
          sqrftMax: SQFT_MAX,
          lat: _.get(userLocation, "latitude", ""),
          long: _.get(userLocation, "longitude", ""),
          city: _.get(userLocation, "city", ""),
          state: _.get(userLocation, "state", ""),
          search: "",
          page: currPage,
          limit: WANT_AD_PAGE_LIMIT,
          fromDate: null,
          toDate: null,
        }
      : adType == "LTR"
      ? {
          adType: adType,
          // location: null,
          propertyType: [],
          typeOfPlace: [10, 11, 6],
          priceMin: PRICE_MIN,
          priceMax: PRICE_MAX,
          sortType: sortParam?.value,
          bedrooms: "",
          baths: "",
          sqrftMin: SQFT_MIN,
          sqrftMax: SQFT_MAX,
          search: "",
          lat: _.get(userLocation, "latitude", ""),
          long: _.get(userLocation, "longitude", ""),
          city: _.get(userLocation, "city", ""),
          state: _.get(userLocation, "state", ""),
          page: currPage,
          limit: WANT_AD_PAGE_LIMIT,
        }
      : {
          adType: adType,
          // location: null,
          propertyType: [],
          priceMin: PRICE_MIN,
          priceMax: PRICE_MAX,
          sortType: sortParam?.value,
          bedrooms: "",
          baths: "",
          sqrftMin: SQFT_MIN,
          sqrftMax: SQFT_MAX,
          lat: _.get(userLocation, "latitude", ""),
          long: _.get(userLocation, "longitude", ""),
          city: _.get(userLocation, "city", ""),
          state: _.get(userLocation, "state", ""),
          search: "",
          page: currPage,
          limit: WANT_AD_PAGE_LIMIT,
        };

  const initialQueryGitchaGenie = {
    inventoryId: selectedProperty?.Inv_Id,
    adType: adType,
    city: selectedProperty?.City,
    state: selectedProperty?.State,
    priceMin: PRICE_MIN,
    priceMax: PRICE_MAX,
    sortType: sortParam?.value,
    page: currPage,
    limit: WANT_AD_PAGE_LIMIT,
  };
  const [filterQuery, setFilterQuery] = useState(initialQuery);
  const [filterQueryGitchaGenie, setFilterQueryGitchaGenie] = useState(
    initialQueryGitchaGenie
  );

  let DEFAULT_MAX_BUDGET;
  if (adType === BUY) {
    DEFAULT_MAX_BUDGET = 5000000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
  }

  const priceMinGitchaGenieFromStorage = previousBudgetMin
    ? previousBudgetMin[0]
    : null;
  const priceMaxGitchaGenieFromStorage = previousBudgetMin
    ? previousBudgetMin[1] == DEFAULT_MAX_BUDGET
      ? ""
      : previousBudgetMin[1]
    : null;

  const previousUserLocation = usePrevious(userLocation);

  const previousPriceMin = usePrevious(filterQueryGitchaGenie["priceMin"]);
  const previousPriceMax = usePrevious(filterQueryGitchaGenie["priceMax"]);

  const previousFilterQuery = usePrevious(filterQuery);
  useEffect(() => {
    if (isGitchaGenieFilter === true) {
      fetchWantAdsMatchingGitchaGenie(filterQueryGitchaGenie);
    }
  }, [isGitchaGenieFilter]);

  useEffect(() => {
    if (
      !isGitchaGenieFilter &&
      !_.isEqual(previousUserLocation, userLocation)
    ) {
      updateQuery({
        lat: _.get(userLocation, "latitude", null),
        long: _.get(userLocation, "longitude", null),
        city: _.get(userLocation, "city", null),
        state: _.get(userLocation, "state", null),
      });
      setCurrPage(1);
      setAdList([]);
    }
  }, [userLocation]);

  useEffect(() => {
    if (
      !isGitchaGenieFilter && userLocation?.isFromAvailableCities) {
      setLocalStorage("filterQuery", filterQuery);
      fetchWantAds(filterQuery);
    }
  }, [filterQuery]);

  useEffect(() => {
    if (isGitchaGenieFilter) {
      removeLocalStorage("filterQuery");
      fetchWantAdsMatchingGitchaGenie(filterQueryGitchaGenie);
    }
  }, [
    isGitchaGenieFilter,
    filterQueryGitchaGenie.inventoryId,
    filterQueryGitchaGenie.priceMin,
    filterQueryGitchaGenie.sortType,
    filterQueryGitchaGenie.priceMax,
    filterQueryGitchaGenie.page,
    filterQueryGitchaGenie.adType,
    reload == true,
  ]);

  useEffect(() => {
    updateQuery(filterQueryFromStorage || initialQuery);
    setCurrPage(1);
    setAdList([]);
    let showNotification = checkTutorialDisplayStatus(tutorialPage);
    // setNotificationIcon(showNotification)
  }, [sessionData?.userInfo?.userId]);

  useEffect(() => {
    setTimeout(() => {
      setSessionData(getLocalStorage(USER_INFO_KEY));
    }, 1500);
  }, [user?.token?.accessToken]);

  useEffect(() => {
    if (isGitchaGenieFilter) {
      updateQueryGitchaGenie({ page: currPage });
    } else {
      updateQuery({ page: currPage });
    }
  }, [currPage]);

  useEffect(() => {
    if (isGitchaGenieFilter) {
      updateQueryGitchaGenie({ sortType: sortParam?.value });
    } else {
      updateQuery({ sortType: sortParam?.value });
    }
    setCurrPage(1);
    setAdList([]);
    setAdListgitchaGenie([]);
  }, [sortParam]);

  useEffect(() => {
    updateQueryGitchaGenie({
      inventoryId: selectedProperty?.Inv_Id,
      adType: adType,
      priceMin: priceMinGitchaGenieFromStorage || PRICE_MIN,
      priceMax: priceMaxGitchaGenieFromStorage || PRICE_MAX,
      city: selectedProperty?.City,
      state: selectedProperty?.State,
      sortType: sortParam?.value,
      page: currPage,
      limit: WANT_AD_PAGE_LIMIT,
    });
    if (_.get(filterQueryFromStorage, "adType") !== adType) {
      updateQuery(initialQuery);
    } else {
      const filterQueryStorage = filterQueryFromStorage;
      filterQueryStorage["page"] = 1;
      if (filterQueryFromStorage["sortType"] === "newest") {
        setSortParam(options[0]);
      } else if (filterQueryFromStorage["sortType"] === "oldest") {
        setSortParam(options[1]);
      } else if (filterQueryFromStorage["sortType"] === "highPrice") {
        setSortParam(options[2]);
      } else if (filterQueryFromStorage["sortType"] === "lowPrice") {
        setSortParam(options[3]);
      }
      if (!userLocation) {
        filterQueryStorage["lat"] = null;
        filterQueryStorage["long"] = null;
        filterQueryStorage["city"] = null;
        filterQueryStorage["state"] = null;
      }
      updateQuery(filterQueryStorage);
    }
    setCurrPage(1);
    setAdList([]);
    setAdListgitchaGenie([]);
  }, [adType]);

  const clearFilterQuery = () => {
    removeLocalStorage("searchedLocation");
    removeLocalStorage("filterQuery");
    removeLocalStorage("FilterAdtype");
    if (!browserLocation?.isFromAvailableCities) {
      setUserLocation(null);
      setAdList([]);
      initialQuery["lat"] = null;
      initialQuery["long"] = null;
      initialQuery["city"] = null;
      initialQuery["state"] = null;
    } else {
      setUserLocation(browserLocation);
    }
    setCurrPage(1);
    setAdType(BUY);
    setFilterQuery(initialQuery);
  };

  const clearFilterGitchaGenie = () => {
    setFilterQueryGitchaGenie(initialQueryGitchaGenie);
  };

  /**
   * function to call Ad list
   * @param {*} query
   */
  const fetchWantAds = async (query) => {
    if (_.get(query, "lat", "") && _.get(query, "long", "")) {
      setShowNoResult(false);
      await getWantAds(query)
        .then((response) => {
          if (response.errorCode === 0) {
            const isPreLauch = _.get(response, "result.isPrelaunch", null);
            const StateName = _.get(response, "result.StateName", null);
            if (isPreLauch) {
              setIsCityInPreLaunch(true);
              setPreLaunchStateName(StateName);
            } else {
              const responseResult = _.get(response, "result.data", null);
              setLastPage(responseResult?.length === 0);
              setTotalCount(_.get(response, "result.totalCount", "0"));
              if (responseResult.length > 0) {
                setAdList([...adList, ...responseResult]);
              } else if (
                responseResult &&
                responseResult.length === 0 &&
                currPage === 1
              ) {
                setShowNoResult(true);
                setAdList([]);
              }
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
    // else{
    //   setShowNoResult(true)
    // }
  };

  const fetchWantAdsMatchingGitchaGenie = async (query) => {
    if (query.inventoryId) {
      setShowNoResult(false);
      await getGitachGenieMatchedWantAds(query)
        .then((response) => {
          const responseResult = _.get(response, "result.data", null);
          setTotalCountMatch(_.get(response, "result.totalCount", "0"));
          if (response.errorCode === 0) {
            const isPreLauch = _.get(response, "result.isPrelaunch", null);
            const StateName = _.get(response, "result.StateName", null);
            if (isPreLauch) {
              setIsCityInPreLaunch(true);
              setPreLaunchStateName(StateName);
            } else {
            setShowGitchaGeineLabel(true);
            if (responseResult?.length > 0) {
              if (
                query.priceMin != previousPriceMin ||
                query.priceMax != previousPriceMax
              ) {
                setAdListgitchaGenie([...responseResult]);
              } else {
                setAdListgitchaGenie([...adListGitchaGenie, ...responseResult]);
              }
            } else if (
              responseResult &&
              responseResult.length === 0 &&
              currPage === 1
            ) {
              setAdListgitchaGenie([]);
              setShowNoResult(true);
            }
          }} else {
            setShowGitchaGeineLabel(true);
            setAdListgitchaGenie([]);
            setShowNoResult(true);
          }
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(err);
        });
    } else {
      setShowNoResult(true);
      setTotalCountMatch(null);
    }
  };

  const handleAdTypeClick = (type) => {
    if (turnOffStay && type === SHORT_RENT) {
      handleStayRequestPopupOpen();
    } else {
      removeLocalStorage("FilterAdtype");
      removeLocalStorage("filterQuery");
      removeLocalStorage("selectedPropertyId");
      removeLocalStorage("priceRangeMatch");
      setAdType(type);
      if (adType != type) {
        setSelectedProperty(null);
      }
      setLocalStorage("FilterAdtype", type);
    }
  };

  const updateFilter = (stateObject) => {
    setAdList([]);
    setCurrPage(1);
    updateQuery(stateObject);
  };

  const updateFilterGitchaGenie = (stateObject) => {
    if (isGitchaGenieFilter) {
      setAdListgitchaGenie([]);
      setCurrPage(1);
      updateQueryGitchaGenie(stateObject);
    }
  };

  const updateQuery = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFilterQuery((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const updateQueryGitchaGenie = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFilterQueryGitchaGenie((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const prevScrollPosition = usePrevious(scrollPosition);
  const handleScroll = () => {
    const el = myRef.current;
    const scrollHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const clientHeight = el.clientHeight;

    if (
      scrollHeight !== clientHeight &&
      scrollTop !== 0 &&
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
    ) {
      //setCurrPage((prevState) => prevState + 1);
      setShowFooter(true);
      setScrollDirection("down");
    } else if (scrollTop < prevScrollPosition && scrollDirection !== "up") {
      setShowFooter(false);
      setScrollDirection("up");
    }
    // else if (scrollTop === 0 && scrollHeight !== clientHeight) {
    //   //setCurrPage(prevState => prevState -1);
    // }
    setScrollPosition(scrollTop);
  };

  const handleShowMoreClick = () => {
    setShowFooter(false);
    setCurrPage((prevState) => prevState + 1);
  };

  // const updateList = () =>{
  //    setAdList([])
  //   setCurrPage(1)
  // }

  /**
   * To handle the want-ad favorite/unfavorite
   */

  const handleFavoriteOrUnfavoriteWantAd = async (
    IsFavourite,
    Want_ad_Id,
    clientId = []
  ) => {
    if (Want_ad_Id) {
      setReload(false);
      let data = {
        clientId: IsFavourite == false ? clientId : null,
        wantAdId: Want_ad_Id,
      };
      await (IsFavourite == false
        ? favouriteWantAd(data)
        : unFavouriteWantAd(data)
      )
        .then((response) => {
          if (response.errorCode == 0) {
            let message = "";
            if (clientId.length > 0) {
              message =
                "Want-Ad has been favorited for " +
                clientId.length +
                (clientId.length > 1 ? " clients" : " client");
            } else {
              message =
                IsFavourite == true
                  ? "Want-Ad has been removed from your favorites"
                  : "Want-Ad has been added to your favorites";
            }

            toast.success(message);
            setCurrPage(1);
            setAdList([]);
            setAdListgitchaGenie([]);
            if (isGitchaGenieFilter) {
              setReload(true);
            } else {
              updateQuery(initialQuery);
            }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  //Remove existing all localStorage keys except userInfo.
  useEffect(() => {
    removeMultipleLocalStorageItems([
      WANT_AD_KEY,
      INVENTORY_KEY,
      STRENGTHEN_INVENTORY_KEY,
      STRENGTHEN_WANT_AD_KEY,
      USER_LOCATION_KEY,
      CLIENT_DETAILS_KEY,
    ]);
  }, []);

  /**
   *
   */
  useEffect(() => {
    getAgentsClientsList();
  }, [formData, reloadAgents, sessionData?.userInfo?.userId]);

  /**
   * To handle textbox value change on agent client search
   * @param {any} event
   */
  const onChangeText = (event) => {
    let value = "";
    if (event) {
      value = handleTextBoxChange(event);
    }

    setFormData((formData) => value);
  };

  /**
   * To get agents clients list
   * @param {*} query
   */
  const getAgentsClientsList = async (query) => {
    let a = {
      search: formData,
      sortBy: "createdOn",
      sortDir: "desc",
    };
    await getAgentsList(a)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setResults(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleWantAdItemClick = (wantAdId) => {
    const sessionUserInfo = getLocalStorage(USER_INFO_KEY);
    if (sessionUserInfo?.userInfo?.userId) {
      setLocalStorage(WANT_AD_KEY, {
        wantAdId: wantAdId,
        isFromHomeFeed: true,
      });
      navigate(pageURLs.wantAdDetails);
    } else {
      setWantAdClickCounter((prevState) => prevState + 1);
      const wantAdArray = getLocalStorage(WANT_AD_ARRAY_KEY) || [];
      if (wantAdArray && wantAdArray.length < 3) {
        const bytes = utf8.encode(
          `abcdefghijklmnopqrstuvwxyz123456789abdefghijklmnopqrstuvwxyz-${wantAdId}-abcdefghijklmnopqrstuvwxyz123456789abdefghijklmnopqrstuvwxyz`
        );
        const encodedId = base64.encode(bytes);
        navigate(`/want-ad?wantAdId=${encodedId}`, {
          state: { isFromHomeFeed: true },
        });
      }
      if (!wantAdArray.includes(wantAdId) && wantAdArray.length < 3) {
        wantAdArray.push(wantAdId);
      }
      setLocalStorage(WANT_AD_ARRAY_KEY, wantAdArray);
    }
  };

  const showSkeleton = () => {
    return <HomeLoadingSkeleton></HomeLoadingSkeleton>;
  };

  const handleGitchGenieFilter = (value) => {
    if (searchedLocation) {
      setUserLocation(searchedLocation);
    } else {
      if (!browserLocation?.isFromAvailableCities) {
        setUserLocation(null);
      } else {
        setUserLocation(browserLocation);
      }
    }
    setAdList([]);
    setAdListgitchaGenie([]);
    setCurrPage(1);
    setIsGitchaGenieFilter(value);
  };

  const handleSortParam = (selectedParam) => {
    setSortParam(selectedParam);
  };
  const getLocationText = () => {
    if (isGitchaGenieFilter) {      
      return totalCountMatch != null && totalCountMatch > 0
        ? `${totalCountMatch} Matches to Want-Ads`
        : "";
    } else {
      return totalCount != null
        ? `Real Time Home ${
            _.get(filterQueryFromStorage, "adType", "") == BUY
              ? " Buyers"
              : "Renters"
          }   In/Near ${_.get(userLocation, "formatted_address", "")}`
        : "";
    }
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const handleClick = async (event) => {
    setShow(!show);
    setTarget(event.target);
    if (show) {
      let tutorialDisplayedPages = saveTutorialStatusToLocal(1);
      setNotificationIcon(false);
      if (sessionData && sessionData?.userInfo?.userId) {
        await saveTutorialStatus(1, 1); //Save status
      }
    }
  };
  const handleOnMouseEnter = () => {
    // setShow(true);
    setMatchTutorial(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };
  const handleToggle = async () => {
    let tutorialDisplayedPages = getLocalStorage("tutorialDisplayedPages");
    tutorialDisplayedPages = tutorialDisplayedPages
      ? tutorialDisplayedPages
      : [];
    tutorialDisplayedPages.push(1); //
    setLocalStorage("tutorialDisplayedPages", tutorialDisplayedPages);
    setNotificationIcon(false);
    if (sessionData && sessionData?.userInfo?.userId) {
      await saveTutorialStatus(1, 1); //Save status
    }
    setShow(false);
  };
  const notificationClass = () => {
    let className = "btn red-hotspot-btn position-absolute";
    if (!showNotificationIcon) {
      className = "btn position-absolute";
    }
    return className;
  };

  useEffect(() => {
    if (
      showNotification === true &&
      sessionData &&
      sessionData?.userInfo?.userId
    ) {
      checkUserInventoryCount();
    }
  }, []);

  const checkUserInventoryCount = async () => {
    let inventoryCount = await getUserInventoryCount();
    const responseResult = _.get(inventoryCount, "result", null);
    setNotificationIcon(false);
    if (inventoryCount.errorCode == 0 && responseResult) {
      if (responseResult[0].count > 0) {
        setNotificationIcon(true);
      }
    }
  };

  const checkGitchaAvailableInLoc = async (
    city,
    state,
    county,
    formatted_address,
    locationObject
  ) => {
    const reqPayLoad = {
      county: county,
      state: state,
    };
    await checkGitchaAvailable(reqPayLoad).then((data) => {
      if (_.get(data, "result") == true) {
        locationObject["isFromAvailableCities"] = true;
        setLocalStorage("searchedLocation", locationObject);
        setGitchaAvailableCheckingCompleted(true);
        setUserLocation(locationObject);
        setGitchaNotAvailable(false);
      } else {
        setLocalStorage("searchedLocation", locationObject);
        setGitchaAvailableCheckingCompleted(true);
        setUserLocation(locationObject);
        setGitchaNotAvailable(true);
        handleGitchaNotAvailableLocation({
          city: city,
          state: state,
          county: county,
          formatted_Address: formatted_address,
        });
      }
    });
  };

  const redirectToHomePage = () =>{
    removeLocalStorage("searchedLocation");
    setLocalStorage("FilterTabKey", "General");
    setLocalStorage("FilterAdtype", "BUY");
    if (!browserLocation?.isFromAvailableCities) {
      setUserLocation(null);
    } else {
      setUserLocation(browserLocation);
    }
  }

  const selectedClient = getLocalStorage("MatchSelectedClient");
  const currentSelectedProperty = getLocalStorage("selectedPropertyId");
  const isPremiumUser = getLocalStorage("isPremiumUser");
  const renderResult = () => {
    if (isGitchaGenieFilter) {
      if (
        showNoResult &&
        adListGitchaGenie &&
        adListGitchaGenie.length === 0 &&
        currentSelectedProperty?.Inv_Id == null
      ) {
        if (
          (userType == USER_TYPE_AGENT && isPremiumUser == 1) ||
          (userType == USER_TYPE_AGENT && selectedClient) ||
          userType == USER_TYPE_MEMBER
        ) {
          return (
            <div className="no-result-wrap">
              <>
                <h3>
                  <AddPropertyPlanChecking /> to your private inventory to start
                  seeing matches!
                </h3>
                <p>
                  This allows you to privately gauge demand and we’ll send you
                  matches. Simply upload your client's property to your private
                  inventory. No one can see your property details but you,
                  unless you introduce it.
                </p>
              </>
            </div>
          );
        }
      } else if (
        showNoResult &&
        adListGitchaGenie &&
        adListGitchaGenie.length === 0 &&
        currentSelectedProperty?.Inv_Id
      ) {
        if (
          (userType == USER_TYPE_AGENT && isPremiumUser == 1) ||
          (userType == USER_TYPE_AGENT && selectedClient) ||
          userType == USER_TYPE_MEMBER
        ) {
          return (
            <div className="no-result-wrap">
              <>
                <h3>
                  It looks like your property doesn’t have any matches yet, but
                  we’ll notify you as soon as you have a match!
                </h3>
                <p>
                  In the meantime, you can add another property or post a
                  want-ad.
                </p>
              </>
            </div>
          );
        }
      } else if (
        !showNoResult &&
        adListGitchaGenie &&
        adListGitchaGenie.length === 0
      ) {
        return (
          <>
            <div
              className={
                !showFooter
                  ? "property-listing-outer "
                  : "property-listing-outer"
              }
              onScroll={handleScroll}
              ref={myRef}
            >
              <HomeLoadingSkeleton />
            </div>
          </>
        );
      } else {
        return (
          <div
            className={
              !showFooter ? "property-listing-outer " : "property-listing-outer"
            }
            onScroll={handleScroll}
            ref={myRef}
          >
            {/*<SimpleBarReact style={{ maxHeight: 800 }} forceVisible="y" autoHide={false} onScroll={handleScroll} >*/}
            {adListGitchaGenie &&
              adListGitchaGenie.map((wa, index) => (
                <PropertyListing
                  list={wa}
                  key={index}
                  handleFavoriteOrUnfavoriteWantAd={
                    handleFavoriteOrUnfavoriteWantAd
                  }
                  results={results}
                  clientSearchText={formData}
                  onChangeText={onChangeText}
                  onAddClient={() => setReloadAgents(!reloadAgents)}
                  handleWantAdItemClick={handleWantAdItemClick}
                />
              ))}
            {showGitchaGeineLabel &&
              wantAdCountGitchaGenie < totalCountMatch && (
                <div className="text-center pt-3 pb-3 w-100">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleShowMoreClick()}
                  >
                    Show More
                  </button>
                </div>
              )}
          </div>
        );
      }
    } else {
      if (!userLocation?.isFromAvailableCities) {
        return (
          <>
            <div className="text-start">
              <a
                role="button"
                href={void 0}
                className="bottom-back-btn mb-24 d-inline-block"
                onClick={() => {
                  redirectToHomePage()
                }}
              >
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                      fill="#205ECA"
                    />
                  </svg>
                </span>
                Back
              </a>
            </div>
            <div className="no-result-wrap">
              <GitchaAvailableLocations setUserLocation={setUserLocation} />
            </div>
          </>
        );
      } else if (showNoResult && adList && adList.length === 0) {
        return (
          <div className="no-result-wrap">
            <>
              <h3>There aren't any want-ads in your location yet</h3>
              <p>In the mean time, try broadening your location search</p>
            </>
          </div>
        );
      } else if (!showNoResult && adList && adList.length === 0) {
        return (
          <>
            <div
              className={
                !showFooter
                  ? "property-listing-outer "
                  : "property-listing-outer"
              }
              onScroll={handleScroll}
              ref={myRef}
            >
              <HomeLoadingSkeleton />
            </div>
          </>
        );
      } else {
        return (
          <div
            className={
              !showFooter ? "property-listing-outer " : "property-listing-outer"
            }
            onScroll={handleScroll}
            ref={myRef}
          >
            {/*<SimpleBarReact style={{ maxHeight: 800 }} forceVisible="y" autoHide={false} onScroll={handleScroll} >*/}
            {adList &&
              adList.map((wa, index) => (
                <PropertyListing
                  list={wa}
                  key={index}
                  handleFavoriteOrUnfavoriteWantAd={
                    handleFavoriteOrUnfavoriteWantAd
                  }
                  results={results}
                  clientSearchText={formData}
                  onChangeText={onChangeText}
                  onAddClient={() => setReloadAgents(!reloadAgents)}
                  handleWantAdItemClick={handleWantAdItemClick}
                />
              ))}
            {wantAdCount < totalCount && (
              <div className="text-center pt-3 pb-3 w-100">
                <button
                  className="btn btn-primary"
                  onClick={() => handleShowMoreClick()}
                >
                  Show More
                </button>
              </div>
            )}
          </div>
        );
      }
    }
  };

  const handleGitchaNotAvailableLocation = (location) => {
    setGitchaNotAvailableLocation(location);
    setFromLocationSearch(true);
  };



  return (
    <>
      {sessionData ? <PreLaunchCityRequestModal
        show={isCityInPreLaunch}
        onHide={() => {setIsCityInPreLaunch(false);
          setPreLaunchStateName(null);
        }}
        setUserLocation={setUserLocation}
        userLocation={userLocation}
        PreLaunchStateName={PreLaunchStateName}
      /> : <></>}
      <RequestGitchaAvailability
        show={showGitchaNotAvailablePopup}
        locationFromSTorage={
          gitchaNotAvailableLocation
            ? gitchaNotAvailableLocation
            : browserLocation
        }
        onHide={() => {
          setShowGitchaNotAvailablePopup(false);
          setGitchaNotAvailableLocation(null);
        }}
      />
      <MatchTutorial
        show={showMatchTutorial}
        onHide={() => {
          handleToggle();
          setMatchTutorial(false);
        }}
      />
      <RequestStayAvailability
        show={showRequestStayPopuop}
        locationFromSTorage={browserLocation}
        onHide={handleStayRequestPopupClose}
      />
      <GeoLocation handleUserLocation={handleUserLocation} page={HOME_PAGE} />
      <filterContext.Provider
        value={{
          handleAdTypeClick,
          adType: adType,
          clearFilterQuery,
        }}
      >       
        <div
          className={
            !showFooter
              ? "outer-wrap outer-wrap-padding position-relative  pb-0"
              : "outer-wrap outer-wrap-padding position-relative"
          }
        >
          <CommonHeader
            fromHome={true}
            redirectToHomePage={redirectToHomePage}
            handleCanvasShow={handleCanvasShow}
            wantAdClickCounter={wantAdClickCounter}
          />
          <div className="row">
            <Offcanvas
              show={offCanvaShow}
              onHide={handleCanvasClose}
              placement="start"
              className="pushmenu"
            >
              <Offcanvas.Header closeButton>
                <h2>Filter</h2>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="left-panel">
                  <LeftpanelTab
                    updateFilter={updateFilter}
                    filterQuery={filterQuery}
                    handleGitchGenieFilter={handleGitchGenieFilter}
                    clearFilterGitchaGenie={clearFilterGitchaGenie}
                    updateFilterGitchaGenie={updateFilterGitchaGenie}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                    setUserLocation={setUserLocation}
                    userLocation={userLocation}
                    gitchaNotAvailble={gitchaNotAvailble}
                    searchedLocation={searchedLocation}
                    checkGitchaAvailableInLoc={checkGitchaAvailableInLoc}
                  />
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-3 pe-xl-4 d-none d-md-block">
              <div className="left-panel position-relative">
                {showNotificationIcon && (
                  <div className="ring-container">
                    <div className="circlebtn">
                      <div className="ringring"></div>
                    </div>
                  </div>
                )}

                <div ref={ref} className="w-100 position-relative">
                  {showNotificationIcon && (
                    <button
                      className="btn red-hotspot-btn position-absolute"
                      onClick={() => setMatchTutorial(true)}
                      onMouseEnter={handleOnMouseEnter}
                    ></button>
                  )}
                </div>

                <LeftpanelTab
                  updateFilter={updateFilter}
                  filterQuery={filterQuery}
                  handleGitchGenieFilter={handleGitchGenieFilter}
                  clearFilterGitchaGenie={clearFilterGitchaGenie}
                  updateFilterGitchaGenie={updateFilterGitchaGenie}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  setUserLocation={setUserLocation}
                  userLocation={userLocation}
                  gitchaNotAvailble={gitchaNotAvailble}
                  searchedLocation={searchedLocation}
                  checkGitchaAvailableInLoc={checkGitchaAvailableInLoc}
                />
              </div>
            </div>
            <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-9 ps-xl-4">
              {(userLocation === null &&
              !isGitchaGenieFilter &&
              !searchedLocation) ? (
                <SearchLocationScreen
                  setUserLocation={setUserLocation}
                  handleGitchaNotAvailableLocation={
                    handleGitchaNotAvailableLocation
                  }
                  gitchaNotAvailble={gitchaNotAvailble}
                  checkGitchaAvailableInLoc={checkGitchaAvailableInLoc}
                  adType={adType}
                />
              ) : (
                <div className="right-panel">
                  <div className="top-sec mb-24 md-mt-24">
                    <div className="row">
                      <div className="col">
                        {getTitleText(userLocation, isGitchaGenieFilter, isCityInPreLaunch, getLocationText)}
                      </div>
                      <div
                        col="12"
                        className="d-flex d-sm-block flex-column col-sm-auto"
                      >
                        <div className="d-flex select-wrap position-relative">
                          <Form.Label className="d-inline-block">
                            Sort By:
                          </Form.Label>
                          <SelectDropDown
                            id="multi3"
                            name="colors3"
                            options={options}
                            className="basic-single home-select d-inline-block ms-2 mb-0"
                            classNamePrefix="select"
                            placeholder="Select"
                            value={sortParam}
                            onChange={handleSortParam}
                            styles={{
                              dropdownIndicator: (provided, state) => ({
                                ...provided,
                                transform:
                                  state.selectProps.menuIsOpen &&
                                  "rotate(180deg)",
                              }),
                            }}
                            errorClass="error-msg error-msg-height"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {renderResult()}
                </div>
              )}
            </div>
          </div>
        </div>
        <SiteFooter show={showFooter} />
      </filterContext.Provider>
    </>
  );
};

export default Home;
