import React from "react";
import { getCurrentPath } from "../../helpers/common";

const MessageMenu = (props) => {
  const {handleNavigation, unReadChatMessagesCount} = props
  const currentLocation = getCurrentPath();
  return (
    <a
      href={void 0}
      role="button"
     
      className={currentLocation == "/mobile/messages"  ? "text-center select" : "text-center " }
      onClick={() =>handleNavigation('message')}
    >
      {unReadChatMessagesCount > 0 &&
      <div className="message-notification">{unReadChatMessagesCount}</div>
}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="All - Messages">
          <path
            id="Vector"
            d="M20 7.5H17V4.5C17 4.10218 16.842 3.72064 16.5607 3.43934C16.2794 3.15804 15.8978 3 15.5 3H3.5C3.10218 3 2.72064 3.15804 2.43934 3.43934C2.15804 3.72064 2 4.10218 2 4.5V16.5C2.00044 16.6411 2.04068 16.7792 2.11608 16.8985C2.19149 17.0177 2.29901 17.1133 2.42629 17.1742C2.55358 17.2351 2.69546 17.2589 2.83564 17.2428C2.97581 17.2266 3.1086 17.1713 3.21875 17.0831L6.5 14.4375V17.25C6.5 17.6478 6.65804 18.0294 6.93934 18.3107C7.22064 18.592 7.60218 18.75 8 18.75H16.7741L20.2812 21.5831C20.414 21.6905 20.5793 21.7493 20.75 21.75C20.9489 21.75 21.1397 21.671 21.2803 21.5303C21.421 21.3897 21.5 21.1989 21.5 21V9C21.5 8.60218 21.342 8.22064 21.0607 7.93934C20.7794 7.65804 20.3978 7.5 20 7.5ZM5.98906 12.9169L3.5 14.9297V4.5H15.5V12.75H6.46063C6.28897 12.75 6.12252 12.8089 5.98906 12.9169ZM20 19.4297L17.5109 17.4169C17.3782 17.3095 17.2129 17.2507 17.0422 17.25H8V14.25H15.5C15.8978 14.25 16.2794 14.092 16.5607 13.8107C16.842 13.5294 17 13.1478 17 12.75V9H20V19.4297Z"
            fill="#636569"
          />
        </g>
      </svg>

      <span>Messages</span>
    </a>
  );
};

export default MessageMenu;
