import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import TutorialCarouselImage1 from "../images/tutorial_carousel_img_1.png";
import TutorialCarouselImage2 from "../images/tutorial_carousel_img_2.png";
import TutorialCarouselImage3 from "../images/tutorial_carousel_img_3.png";
import TutorialCarouselImage4 from "../images/tutorial_carousel_img_4.png";
import TutorialCarouselImageAgent1 from "../images/agent-tutorial-img1.png";
import TutorialCarouselImageAgent3 from "../images/agent-tutorial-img2.png";
import { Autoplay, Pagination } from "swiper/modules";
import { useLocation, useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { getLocalStorage, getUserType, removeLocalStorage, saveTutorialStatusToLocal, setBodyBackgroundDarkGrey } from "../../helpers/common";
import { saveTutorialStatus } from "../../services/commonServices";
import { USER_TYPE_AGENT } from "../../constants/common";
const TutorialProfileSetup = () => {
  const pagination = {
    clickable: true,
  };
  const location = useLocation();
  const tutorialPage = 6;
  const isAgent = getUserType() === USER_TYPE_AGENT ? true : false;
  const navigate = useNavigate();
  let tutorialOnSignUp = getLocalStorage('tutorialOnSignUp');
  const isPremiumUser = location && location?.state && location.state?.isPremiumUser

  useEffect(() => {
    saveTutorialStatusToLocal(tutorialPage);
    saveTutorialToDb();
    // if(tutorialOnSignUp == 1) {
    //   setWelcomeMessage(true)
    // }

  }, []);
  const saveTutorialToDb = async () => {
    await saveTutorialStatus(tutorialPage, 1); //Save status
  }

  const redirectToPage = async (pageName) => {
    removeLocalStorage('tutorialOnSignUp')
    if(pageName == 'wantadd') {
      if(isPremiumUser){
        navigate(pageURLs.mobilePremiumAgentCreateWantAd)
      }
      else {navigate(pageURLs.createWantAdResponsive);}    
    } else {
      navigate(pageURLs.mobileHome)
    }
  }

  useEffect(() => {
    setBodyBackgroundDarkGrey()
  }, []);

  return (
    <div className="tutorial-slider">
      <div className="signup-login-header w-100">
        <div className="signup-login-header-top clearfix">
          <a className="back-link" href={void 0} onClick={()=>redirectToPage('home')}>
            Back
          </a>
        </div>
      </div>
      <div className="inner-layout-content">
        <div className="welcome-header">
          <h2>Let’s create your first want-ad</h2>
        </div>
        <div>
          <Swiper
            pagination={pagination}
            modules={[Pagination]}
            className="mySwiper"
            rewind={true}
            autoplay={{
              delay: 800,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <img src={isAgent ? TutorialCarouselImageAgent1 : TutorialCarouselImage1} alt={isAgent ? TutorialCarouselImageAgent1 : TutorialCarouselImage1} />
              <div className="slider-content">
                <h6>Share {isAgent ? "their" : "your"} wants and needs</h6>
                <p className="m-0">
                  From location and price, to property specs and
                  'nice-to-haves', this is your place to be specific about what
                  you’re looking for.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={TutorialCarouselImage2} alt={TutorialCarouselImage2} />
              <div className="slider-content">
                <h6>Sit back and relax</h6>
                <p className="m-0">
                  Property owners and {isAgent ? "fellow " : ""}agents come to you! Your want-ad will
                  appear in their feed, and if their property is a good fit,
                  Gitcha will notify them of a match.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={isAgent ? TutorialCarouselImageAgent3 : TutorialCarouselImage3} alt={isAgent ? TutorialCarouselImageAgent3 : TutorialCarouselImage3} />
              <div className="slider-content">
                <h6>Get introduced to property</h6>
                <p className="m-0">
                  Owners and {isAgent ? "fellow " : ""}agents will propose what they have, and you get to
                  quickly preview the property details and a few photos.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={TutorialCarouselImage4} alt={TutorialCarouselImage4} />
              <div className="slider-content">
                <h6>Connect on your terms</h6>
                <p className="m-0">
                  {isAgent ? "Choose to accept or decline introductions after determining whether it’s a good fit. Once you accept an introduction, you’ll see more details and can initiate a private conversation.":
                  "Choose to accept or decline introductions after determining the fit. Once you accept an intro, you'll see more details & can initiate a private conversation."}
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="btn-wrap">
          <button type="button" class="btn btn-primary w-100" onClick={()=>redirectToPage('wantadd')}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialProfileSetup;
