/**
 * @file   src\components\Common\DeleteModal.js
 * @brief  This file is responsible for delete functionalities from dashboard
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const DeleteModal = (props) => {
  const { show, handleConfirm, title, message, buttonText, onHide } = props;


  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >      
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        ></button>
        <h2 className="text-start f-size-24 mb-2 l-spacing">{title}</h2>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-start">
              {message}
            </p>

          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
              <Button variant="" className="btn btn-tertiary me-3" onClick={onHide}>
                Cancel
              </Button>
              <Button variant="" className="btn btn-delete" onClick={handleConfirm}>
                {buttonText}

              </Button>

            </div>
          </Col>
        </Row>
      </Modal.Body>      
    </Modal>
  );
};
export default DeleteModal;
