import PropertyDisplay from "./PropertyDisplay";
import {  useContext } from "react";
import {niceToHaveContext} from './NiceToHavesProperties'

 const NiceToHaveHelper = (props ) => {
  const niceToHave = useContext(niceToHaveContext);
  const {handleItemClick, selectedItems} = niceToHave
    const {list} = props
    const itemList =
    list &&
    list.map((pl) => {
        const name = _.get(pl, "Text");
        const id = _.get(pl, "Id");
        const imgPath = _.get(pl, "Img");
        const webPImg = _.get(pl, "Webp_Img");
        const Icon = _.get(pl, "Icon");
        let isSelected = false;
      
        const filteredArray = selectedItems.filter(function (co) {
          return co.id == id;
        });
       
        const order = _.get(filteredArray[0], "order", null);
        if(order) {
          isSelected = true;
        }
        return (
          <div key={id}
            className="col-6 pe-2"
            id={id}
            name={name}
            onClick={handleItemClick}
          >
            <PropertyDisplay
              name={name}
              id={id}
              isSelected={isSelected}
              imgPath={imgPath}
              webPImg={webPImg}
              order={order}
              icon={Icon}
            />
          </div>
        );
      });
    return itemList;
  };

  export default NiceToHaveHelper