import React, { useState, useEffect } from "react";
import TopRightBtn from "./TopRightBtn";
import BottomNavBar from "./BottomNavBar";
import WantAdMemberBuy from "./WantAdMemberBuy";
import WantAdMemberLTR from "./WantAdMemberLTR";
import WantAdMemberSTR from "./WantAdMemberSTR";
import WantAdMemberLand from "./WantAdMemberLand";
import CreateWantAdSec from "./CreateWantAdSec";
import WantAdOption from "./WantAdOption";
import WantAdMemberStrengthen from "./WantAdMemberStrengthen";
import { getMyDraft } from "../../services/wantAdService";
import {
  BUY,
  SHORT_RENT,
  LONG_RENT,
  BUY_STRENGTHEN,
  LTR_STRENGTHEN,
  USER_INFO_KEY,
  WANT_AD_KEY,
  INVESTOR_MEMBER_PLAN,
} from "../../constants/common";
import { getLocalStorage } from "../../helpers/common";
import { useSelector } from "react-redux";
import WantAdInvestorBuy from "./WantAdInvestorBuy";
import WantAdInvestorBuyInvestment from "./WantAdInvestorBuyInvestment";
import { convertToNumber } from "../../helpers/validations";

const WantadRightSection = (props) => {
  const {
    skipButtonEnable,
    setActiveStep,
    exitButtonText,
    backButtonEnable,
    handleContinueClick,
    handleBackButtonClick,
    handleContinueButtonState,
    isDisabled,
    handleSaveAndExit,
    handleFormValues,
    handleSkipButtonClick,
    step,
    formValues,
    continueButtonText,
    className,
    bottomNavClass,
    clearPreviousStates,
    exitButtonDisable,
    backButtonText,
    targetRef,
    setDraftSelectedItem,
    draftSelectedItem,
    checkDraft,
    hideContinueButton,
    userPlan,
    isFinalStep
  } = props;
  const adType = _.get(formValues, "adType", null);
  const propertyType = _.get(formValues, "prprtyType", null);
  const purchaseType = convertToNumber(_.get(formValues, "purchaseType", null)) || convertToNumber(_.get(formValues, "prchTypeId", null));
  const [myDrafts, setMyDrafts] = useState();
  const isStrengthen = adType === BUY_STRENGTHEN || adType === LTR_STRENGTHEN;

  useEffect(() => {
    if (!isStrengthen && step === 1) {
      const userDetails = getLocalStorage(USER_INFO_KEY);

      const userId = _.get(userDetails, "userInfo.userId", null);

      getMyDraft()
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (responseResult && responseResult.length !== 0) {
            setMyDrafts(responseResult);
          } else {
            !checkDraft && setActiveStep(2);
            setMyDrafts({});
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, []);
  const loadWantAdOptions = () => {
    if (!isStrengthen) {
      const memberBuy = getLocalStorage(WANT_AD_KEY);

      if (step === 1) {
        if (myDrafts) {
          if (myDrafts.length > 0) {
            return (
              <CreateWantAdSec
                handleContinueClick={handleContinueClick}
                handleContinueButtonState={handleContinueButtonState}
                myDraft={myDrafts}
                setDraftSelectedItem={setDraftSelectedItem}
                draftSelectedItem={draftSelectedItem}
              />
            );
          } else {
            return (
              <WantAdOption
                handleContinueButtonState={handleContinueButtonState}
                handleFormValues={handleFormValues}
                adType={adType}
                clearPreviousStates={clearPreviousStates}
              />
            );
          }
        }
      } else if (
        (_.get(memberBuy, "actvStep", "") ||
          _.get(memberBuy, "cmpldStep", "")) &&
        step === 2
      ) {
        return (
          <WantAdOption
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            adType={adType}
            clearPreviousStates={clearPreviousStates}
          />
        );
      }
    }
  };

  const selectRenderFlowBasedOnAdTypeAndPropertType = () => {
    if ((propertyType === "6" || propertyType === 6) && step >= 6) {
      return (
        <WantAdMemberLand
          formValues={formValues}
          step={step}
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          handleContinueClick={handleContinueClick}
          targetRef={targetRef}
        />
      );
    } else {
      switch (adType) {
        case BUY:
          return (
            userPlan===INVESTOR_MEMBER_PLAN ? (purchaseType===3 ? 
            <WantAdInvestorBuyInvestment
              formValues={formValues}
              step={step}
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              handleContinueClick={handleContinueClick}
              clearPreviousStates={clearPreviousStates}
              targetRef={targetRef}
              userPlan={userPlan}/>
            : <WantAdInvestorBuy
              formValues={formValues}
              step={step}
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              handleContinueClick={handleContinueClick}
              clearPreviousStates={clearPreviousStates}
              targetRef={targetRef}
              userPlan={userPlan}
            />):<WantAdMemberBuy
              formValues={formValues}
              step={step}
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              handleContinueClick={handleContinueClick}
              clearPreviousStates={clearPreviousStates}
              targetRef={targetRef}
              userPlan={userPlan}
            />
          );

        case LONG_RENT:
          return (
            <WantAdMemberLTR
              formValues={formValues}
              step={step}
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              handleContinueClick={handleContinueClick}
              clearPreviousStates={clearPreviousStates}
              targetRef={targetRef}
              userPlan={userPlan}
            />
          );

        case SHORT_RENT:
          return (
            <WantAdMemberSTR
              formValues={formValues}
              step={step}
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              handleContinueClick={handleContinueClick}
              clearPreviousStates={clearPreviousStates}
              targetRef={targetRef}
            />
          );

        case LTR_STRENGTHEN:
        case BUY_STRENGTHEN:
          return (
            <WantAdMemberStrengthen
              formValues={formValues}
              step={step}
              handleContinueButtonState={handleContinueButtonState}
              handleFormValues={handleFormValues}
              handleContinueClick={handleContinueClick}
            />
          );
      }
    }
  };

  return (
    <div className="col-lg-7 offset-lg-5 right-pane-bg">
      <div
        className={
          className ? "right-pane-content " + className : "right-pane-content"
        }
      >
        <TopRightBtn
          exitButtonText={exitButtonText}
          handleSaveAndExit={handleSaveAndExit}
          exitButtonDisable={exitButtonDisable}
          step={step}
          isFinalStep={isFinalStep}
          continueButtonText={continueButtonText}
          handleContinueClick={handleContinueClick}
          hideContinueButton={hideContinueButton}
          isDisabled={isDisabled}
        />
        {loadWantAdOptions()}
        {selectRenderFlowBasedOnAdTypeAndPropertType()}

        <BottomNavBar
          skipButtonEnable={skipButtonEnable}
          backButtonEnable={
            _.get(formValues, "isPublished", "") == 1 ? false : backButtonEnable
          }
          handleContinueClick={handleContinueClick}
          handleBackButtonClick={handleBackButtonClick}
          isDisabled={isDisabled}
          handleSkipButtonClick={handleSkipButtonClick}
          step={step}
          continueButtonText={continueButtonText}
          bottomNavClass={bottomNavClass}
          backButtonText={backButtonText}
          hideContinueButton={hideContinueButton}
          isFinalStep={isFinalStep}
          exitButtonText={exitButtonText}
          handleSaveAndExit={handleSaveAndExit}
          exitButtonDisable={exitButtonDisable}
        />
      </div>
    </div>
  );
};
export default WantadRightSection;
