/**
 * @file   src\pages\Settings.js
 * @brief  This file is responsible for handling account settings.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../components/Onboarding/CommonHeader";
import { fetchUserDetails } from "../actions/userprofile";
import { NOT_FREE_OR_MEMBER_PERIOD, USER_INFO_KEY } from "../constants/common";
import { getLocalStorage } from "../helpers/common";
import ProfileNormalUser from "./ProfileNormalUser";
import ProfileAgentUser from "./ProfileAgentUser";
import ProfileAgentUserPremium from "./ProfileAgentUserPremium";
import Notifications from "./Notifications";
import Payments from "./Payments";
import { useLocation } from "react-router-dom";

const Settings = (props) => {
  const {addNewCard, editAgentProfile} = props;
  const dispatch = useDispatch()
  const sessionData = getLocalStorage(USER_INFO_KEY);  
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const [userDetails, setUserDetails] = useState(null)
  const [upComingPlanDetails, setupComingPlanDetails] = useState(null)
  const [reload, setReload] = useState(0)
  const userType =  userDetails?.UserTypeId
  const userId = _.get(sessionData, 'userInfo.userId', '')
  const location = useLocation();
  const isFromNotification = location.state && location.state['isFromNotification']
  const [key, setKey] = useState(addNewCard ? "payments" : "account")
  useEffect(()=>{ 
    if(userId){   
      dispatch(fetchUserDetails())
    }
  },[reload])

  useEffect(()=>{    
    if(userDetailsFromStore !== null && userDetailsFromStore?.length>0){
      setUserDetails(userDetailsFromStore[0])
      if(userDetailsFromStore?.length>1) {
        setupComingPlanDetails(userDetailsFromStore[userDetailsFromStore?.length-1])
      }
      else{
        setupComingPlanDetails(null)
      }
    }
    else{
      setUserDetails(null)
    }
  },[userDetailsFromStore])
  
  
  const updateReloadParam = () => {
    setReload(reload+1)
  }

  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">

            <div className="edit-want-ad-wrap settings-wrap mw-626">
              <h3>Settings</h3>
              
              <div className="horizontal-tab-wrap">
            <Tabs
              id="controlled-tab-example1"
              className="mb-0"
              activeKey={key}
              onSelect={(k) => {    
                setKey(k); 
                }
              }
            >
              <Tab
                eventKey="account" 
                title="Account"
              >
                {userType===1 ? 
                <ProfileNormalUser 
                  userDetails= {userDetails} 
                  updateReloadParam={updateReloadParam}
                  upComingPlanDetails={upComingPlanDetails}
                  isFromNotification={isFromNotification}
                  /> :
                  userType===2 && (userDetails?.period > NOT_FREE_OR_MEMBER_PERIOD)?
                <ProfileAgentUserPremium 
                  userDetails= {userDetails} 
                  updateReloadParam={updateReloadParam}
                  upComingPlanDetails={upComingPlanDetails}
                  isFromNotification={isFromNotification}
                  editAgentProfile={editAgentProfile}
                  />: 
                  <ProfileAgentUser 
                  userDetails= {userDetails} 
                  updateReloadParam={updateReloadParam}
                  upComingPlanDetails={upComingPlanDetails}
                  isFromNotification={isFromNotification}
                  />                     
                  }                          

              </Tab>

              <Tab
                eventKey="payments" 
                title="Payments"
              >
                <Payments 
                currentTab={key}
                addNewCard={addNewCard}
                />
              </Tab>

              <Tab
                eventKey="notifications" 
                title="Notifications"
              >
                 <Notifications userDetails={userDetails}/>
              </Tab>
            </Tabs>
          </div>


            </div>

          </div>


        </div>
      </div>    
    
    </>
  );
};

export default Settings;

