import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import HouseImgIcon from "../../images/house-img.png";
import { getCardIconForIntroduction, getLocalStorage, getUserId, getUserType, isDateInThePast, truncate } from "../../helpers/common";
import { MEMBER_INTRODUCTION, USER_TYPE_AGENT } from "../../constants/common";
// import { useDispatch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentMethods } from "../../actions/payments";
import { fetchUserDetails, fetchUserStatistics, fetchUserPrivileges } from "../../actions/userprofile";
import AddPaymentModalStripe from "../Payments/AddPaymentModalStripe";
import PaymentMethodsModal from "./PaymentMethodsModal";
import CustomizeIntroModal from "./CustomizeIntroModal";
import ChangeCoverPhotosModal from "./ChangeCoverPhotosModal";
import noImage from "../../images/no-image-placeholder.png";
import PremiumSparkleIcon from "../../images/premium-sparkle-icon.svg";
import ProSparkleIcon from "../../images/pro-sparkle-icon.svg";
import BasicPlanModal from "../PlanUpgrade/BasicPlanModal";
import PremiumPlanModal from "../PlanUpgrade/PremiumPlanModal";
import { getActivePromoCodeDetails } from "../../services/PlanService";
import Loader from "../Common/Loader";
import { loadInventoryLocation } from "../../helpers/inventory";

const ReviewOrderModal = (props) => {
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);
  const [userPaymentMethods, setUserPaymentmethods] = useState(paymentMethods);

  const [deafaultPaymentMethod, setDeafaultPaymentMethod] = useState("");
  const userId = getUserId();
  const userType = getUserType();

  const selectedProperty = props.selectedProperty;
  const introductionDetails = getLocalStorage(MEMBER_INTRODUCTION);

  const [showAddPaymentModal, setAddPaymentModal] = useState(false);
  const AddPaymentModalClose = () => setAddPaymentModal(false);
  const AddPaymentModalShow = () => setAddPaymentModal(true);

  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);
  const PaymentMethodsModalClose = () => setShowPaymentMethodsModal(false);
  const PaymentMethodsModalShow = () => setShowPaymentMethodsModal(true);

  const [showCustomizeIntroModal, setCustomizeIntroModal] = useState(false);
  const CustomizeIntroModalClose = () => setCustomizeIntroModal(false);
  const CustomizeIntroShow = () => setCustomizeIntroModal(true);

  const [showChangeCoverPhotosModal, setChangeCoverPhotosModal] = useState(false);
  const ChangeCoverPhotosModalClose = () => setChangeCoverPhotosModal(false);
  const ChangeCoverPhotosModalShow = () => setChangeCoverPhotosModal(true);

  const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);
  const [reload, setReload] = useState(0);
  const [showId, setShowId] = useState(0)
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const userStatictics = useSelector((state)=>state?.userProfile?.userStatictics)
  const userPrivileges = useSelector((state)=>state?.userProfile?.userPrivileges)
  const [userCount, setUserCount] = useState({})
  const [allowedCount, setAllowedCount] = useState(null)
  const [userDetails, setUserDetails] = useState(null);
  const [remainingIntroduction, setRemainingIntroduction] = useState(allowedCount)
  const {setIsFree, show} = props;
  const [showBasicPlanModal, setBasicPlanModal] = useState(false);
  const BasicPlanModalClose = () => setBasicPlanModal(false);
  const BasicPlanModalShow = () => setBasicPlanModal(true);
  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);
  const [freeIntroApplicable, setIsFreeIntroApplicable] = useState(false);
  // const [isLoding, setIsLoading] = useState(false);

  // const getPlanUpdradeText = () => {
  //   if(userDetails?.period === 1) {
  //     return "Get 10 free introductions per month when you Upgrade to Premium"
  //   }
  //   else if(userDetails?.PlanName === "Pro Premium" && remainingIntroduction===5) {
  //     return " Get 50 free introductions per month when you Upgrade to Preferred Pro"
  //   }
  // }

  useEffect(()=>{
    if(show) {
      getActivePromoCodeDetails().then((response)=>{
        let temp = [];
        const res = _.get(response, "result", [])
        if(res?.length>0){
          const result = res.filter((planDetails)=>planDetails?.UserType==2 || planDetails?.UserType==3)
          if(result && result.length > 0){
            result.forEach((planDetails)=>{
              if(planDetails?.Is_Free_Intro===1){
                temp.push(parseInt(planDetails?.Id))
              }
            })
            if(temp.includes(userDetails?.PromoCode_Id)){
              setIsFreeIntroApplicable(true)
            }
            else{
              setIsFreeIntroApplicable(false)
            }
          }          
        }
      })
    }      
 }, [show])

  useEffect(() => {
    dispatch(fetchPaymentMethods())
    if(userId){
    dispatch(fetchUserDetails())   }
  }, [userId, reload, selectedProperty?.clientId]);

  useEffect(() => {
    checkButtonDisabledStatus();
    setDeafaultPaymentMethod(selectedProperty?.cardData);
  }, [selectedProperty])

  useEffect(()=>{
    setRemainingIntroduction(parseInt(allowedCount)-parseInt(userCount?.Introduction))
  },[allowedCount, userCount])

  useEffect(()=>{
    if(remainingIntroduction > 0) {
      setIsFree(true)
    }
    else{
      setIsFree(false)
    }
  },[remainingIntroduction])


  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      const defaultArray = paymentMethods?.filter((item) => item.IsDefault === 1)
      const OtherMethods = paymentMethods?.filter((item) => item.IsDefault !== 1)
      if (reload == 0) {
        setDeafaultPaymentMethod(defaultArray[0])
        props.updateCardParam(defaultArray[0])
      } else {
        if (OtherMethods.length > 0) {
          setDeafaultPaymentMethod(OtherMethods[OtherMethods.length - 1])
          props.updateCardParam(OtherMethods[OtherMethods.length - 1])
        } else if (defaultArray.length > 0) {
          setDeafaultPaymentMethod(defaultArray[0])
          props.updateCardParam(defaultArray[0])
        }
      }
      setUserPaymentmethods(defaultArray.concat(OtherMethods))
    }
    else {
      setUserPaymentmethods([])
    }
  }, [paymentMethods, reload])  

    useEffect(() => {
        if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
          setUserDetails(userDetailsFromStore[0]);
        } else {
          setUserDetails(null);
        }
      }, [userDetailsFromStore]);    

    useEffect(()=>{
        const payload ={
          UserPlanId : userDetails?.User_Plan_Id
        }
    
        dispatch(fetchUserStatistics(payload))
      }, [userDetails])


    useEffect(() => {      
        if (userPrivileges !== null && userPrivileges.length > 0) {
          if(freeIntroApplicable){
            setAllowedCount(parseInt(userPrivileges[0]?.Introduction) + parseInt(userCount?.WandAd));
          }
          else{
            setAllowedCount(userPrivileges[0]?.Introduction);
          }
        } else {
          setAllowedCount(null);
        }
      }, [userPrivileges, userCount, freeIntroApplicable]);
    
    
      useEffect(() => {
        if (userStatictics !== null && userStatictics.length > 0) {
          setUserCount(userStatictics[0]);
        }
      }, [userStatictics]); 


  const AddNewPayment = (isFromPaymentMethodsModal = false) => {
    props.onHide();
    if (isFromPaymentMethodsModal) {
      setShowPaymentMethodsModal(false)
    }
    setAddPaymentModal(true);
  }

  const PaymentMethodModalShow = () => {
    props.onHide();
    setShowPaymentMethodsModal(true);
  }

  const hideAddPaymentModel = () => {
    props.updateReloadParam();
    setAddPaymentModal(false);
  }

  const hidePaymentMethodModel = () => {
    props.updateReloadParam();
    setShowPaymentMethodsModal(false);
  }

  // const updateCardParam = (paymentMethod) => {
  //   setDeafaultPaymentMethod(paymentMethod);
  //   props.updateCardParam(paymentMethod);
  // }

  const checkButtonDisabledStatus = () => {
    let expired = isDateInThePast(selectedProperty?.cardData?.Exp_Month, selectedProperty?.cardData?.Exp_Year);
    let isRepresentOwnerCheck= userType == USER_TYPE_AGENT ? selectedProperty?.isRepresentOwner == true ? true : false : true;
    if (selectedProperty?.cardId && selectedProperty?.isAdhereFFHAct  == true && isRepresentOwnerCheck == true && !expired ) {
      setButtonDisabledStatus(false);
    } else {
      setButtonDisabledStatus(true);
      
    }


  }

  const CustomizeIntroModalShow = () => {
    props.onHide();
    setCustomizeIntroModal(true);
  }

  const onCoverPhotoEdit = () =>{
    setCustomizeIntroModal(false);
    setChangeCoverPhotosModal(true);
  }

  const onSavePropertyFeatureOrder = (orderdProperty) =>{
    props.onSavePropertyFeatureOrder(orderdProperty);
  }

  const SavePhotoOrder = (photoOrder) =>{
    setCustomizeIntroModal(true)
    props.SavePhotoOrder(photoOrder)
  }

  const onClickClose = () =>{
    setShowId(showId+1)
    setChangeCoverPhotosModal(false);
    setCustomizeIntroModal(true);
  }

  const onClickCustizeIntroClose = () =>{
    setShowId(showId+1)
    setCustomizeIntroModal(false);
    props.viewReviewModal();
    
  }

  const updateReloadParam = () =>{
    setReload(reload+1);
  }

  const loadPlanUpgradeUI = () => {
    if(userType == 2 && userDetails?.period == 1){
      return(
        <div className="upgrade-plan-wrap upgrade-plan-bg-grey mb-4 link-cursor" onClick={()=>PremiumPlanModalShow()}>
                <h2 className="text-center">
                  <span className="f-extrabold">Gitcha</span> <span className="f-medium">Pro Member</span> <img src={PremiumSparkleIcon} />
                </h2>
                <p className="para-color-blue">Save up to 50% on introductions - Learn more about upgrading your Pro account</p>
        </div>        
      )
    }
    else if(userType == 1 && userDetails?.period == 1) {
      return(
        <div className="upgrade-plan-wrap upgrade-plan-bg-grey mb-4 link-cursor" onClick={()=>BasicPlanModalShow()}>
        <h2 className="text-center">
          <span className="f-extrabold">Gitcha</span> <span className="f-medium">Member</span> <img src={PremiumSparkleIcon} />
        </h2>
        <p className="para-color-blue pl-20 pr-20">Upgrade and get 10 free introductions per month</p>
      </div>
      )
    }
    else if(userType == 2 && (userDetails?.PlanName == "Pro Member")){
      return(
        <div className="upgrade-plan-wrap upgrade-plan-bg-blue mb-4 link-cursor" onClick={()=>PremiumPlanModalShow()}>
          <h2 className="text-center">
            <span className="f-extrabold para-color-white">Gitcha</span> <span className="f-medium para-color-white">Pro Preferred</span> <img src={ProSparkleIcon} />
          </h2>
          <p className="para-color-white">Get 50 free introductions per month when you upgrade to Pro Preferred</p>
        </div> 
      )
    }

  }

  return (
    <>
     {props.isLoding ? <Loader /> : ""}
      <Modal
        show
        centered
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-right-aligned"
      >

        <Modal.Body className="">

          <div className="sub-heading-small position-relative mb-32">
            <a role="button" className="sub-heading-backbtn" href={void 0}
              onClick={props.onHide}>

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z" fill="#1B1C1E" />
              </svg>

            </a>
            Review Order</div>

          <div className="review-order-top pb-32">
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
              {/* <h6 className="long-txt-fix">Introduction to {props.WantCreatedUserName}</h6> */}
              <h6 className="long-txt-fix">Introduction Summary</h6>
              <a href={void 0}
                onClick={() => {
                  CustomizeIntroModalShow()
                }} className="review-txt-link">Customize intro</a>
            </div>
            <div className="add-your-property-wrap border-white review-order-greybg mb-2">
              <div className="make-intro-img">
                <img className="display-thumb-img" src={selectedProperty?.selectedProperty?.photo ? selectedProperty?.selectedProperty?.photo : noImage} />
              </div>
              <div className="make-intro-details">
                <h3 className="long-txt-fix">{selectedProperty?.selectedProperty?.Location}</h3>
             
                {/* <p>{loadInventoryLocation({
                      city: selectedProperty?.city,
                      state: selectedProperty?.state,
                      zip: selectedProperty?.zip,
                    })}</p> */}
              </div>
            </div>
            {selectedProperty?.message && (
              <div className="review-msg-preview long-txt-fix">
                {truncate(selectedProperty?.message, 60)}
              </div>
            )}
          </div>

          <div className="review-order-btm">
            <div className="billing-cycle-btm">
              {deafaultPaymentMethod ? (
                <>
                  <h6 className="mb-3">Payment method</h6>
                  <p className="payment-para mb-2">You will only be charged if they accept your introduction. </p>
                  <a
                    role="button"
                    class="text-decoration-none"
                    href={void 0}
                    onClick={() => { PaymentMethodModalShow() }}
                  >
                    <div className="add-your-property-wrap mh-auto mb-2">
                      <div className="add-property-btn">
                        <img src={getCardIconForIntroduction(deafaultPaymentMethod?.Brand)} />
                      </div>
                      <div className="make-intro-details">
                        <h4>{deafaultPaymentMethod?.Brand} •••••••••••••{deafaultPaymentMethod?.Last4}</h4>{" "}
                        {isDateInThePast(deafaultPaymentMethod?.Exp_Month, deafaultPaymentMethod?.Exp_Year) ? (
                          <h5 className="card-expired-txt">Expired {String(deafaultPaymentMethod?.Exp_Month).padStart(2, '0')}/{String(deafaultPaymentMethod?.Exp_Year).slice(-2)}</h5>
                        ) :
                          (<h5>Exp. {String(deafaultPaymentMethod?.Exp_Month).padStart(2, '0')}/{String(deafaultPaymentMethod?.Exp_Year).slice(-2)}</h5>)}



                        {/* <h4>Visa •••••••••••••4563</h4>
                <h5>Exp. 10/27/2025</h5> */}
                      </div>

                      <span
                        role="button"
                        class="right-arrow-link"

                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                            fill="#000022"
                          />
                        </svg>
                      </span>
                    </div>
                  </a>
                </>
              ) : (
                <>

                  <h6 className="mb-3">Paying with</h6>
                  <p className="payment-para mb-2">You will only be charged if they accept your introduction. </p>
                  <a
                    role="button"
                    className="text-decoration-none"
                    href={void 0}
                    onClick={() => { AddNewPayment() }}
                  >
                    <div className="add-your-property-wrap mh-auto mb-2">
                      <div className="add-property-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 11.5858 3.33579 11.25 3.75 11.25H20.25C20.6642 11.25 21 11.5858 21 12C21 12.4142 20.6642 12.75 20.25 12.75H3.75C3.33579 12.75 3 12.4142 3 12Z" fill="#15181E" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.4142 3 12.75 3.33579 12.75 3.75V20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25V3.75C11.25 3.33579 11.5858 3 12 3Z" fill="#15181E" />
                        </svg>
                      </div>
                      <div className="make-intro-details">
                        <h4 className="mb-0">Add payment method</h4>
                      </div>

                      <span
                        role="button"
                        class="right-arrow-link"

                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                            fill="#000022"
                          />
                        </svg>
                      </span>
                    </div>
                  </a>
                </>
              )}

              <div className="premium-plan-wrap mb-3">

                <ul>
                  <li>
                    <div className="premium-plan-description d-flex flex-column">
                      <span>Introduction x 1</span>
                      {userDetails?.period !=1 && <span className="premium-plan-info">{remainingIntroduction>0 ? remainingIntroduction : 0}/{allowedCount} free introductions remaining</span>}
                      
                    
                    </div>
                    <span className="premium-plan-amount">{remainingIntroduction>0 ? "Free" : "$3.00"}</span>
                  </li>                  
                  <li>
                    <span className="premium-plan-description">Tax</span>
                    <span className="premium-plan-amount">-</span>
                  </li>
                  <li>
                    <span className="premium-plan-description">You'll pay</span>
                    <span className="premium-plan-amount">{remainingIntroduction>0 ? "$0.00" : "$3.00"}</span>
                  </li>
                </ul>                
              </div>        
             {loadPlanUpgradeUI()}
            </div>
            {
              userType == USER_TYPE_AGENT && 
              <div className="field-wrap mt-0 mb-24">
              <label className="form-check-label checkbox-label d-flex align-items-center" for="isRepresentOwner" >
                <input className="form-check-input flex-shrink-0" type="checkbox"
                  onClick={(e) => props.handleIsRepresentOwnerCheckBoxItemClick(e)}
                  id="isRepresentOwner"
                  checked={selectedProperty?.isRepresentOwner} />
                  <span>I’m in contract to represent the owner of this property</span>
              </label>
            </div>
            }

          

            <div className="field-wrap mt-0 mb-24">
                                <label className="form-check-label checkbox-label d-flex align-items-center" for="isAdhereFFHAct " >
                                    <input className="form-check-input flex-shrink-0" type="checkbox"
                                        onClick={(e) => props.handleCheckBoxItemClick(e)}
                                        id="isAdhereFFHAct "
                                        checked={selectedProperty?.isAdhereFFHAct } />
                                    <span>I agree to adhere to the <a href="https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations" target="blank">Federal Fair Housing Act</a> and the <a href="https://gitcha-uploads.s3.amazonaws.com/Gitcha_images/pdf_mls_handbook-2023-08-11.pdf" target="blank">Clear Cooperation Policy</a></span>
                                </label>
                            </div>


            {/* <div className="field-wrap mt-0 mb-24">
              <label className="form-check-label checkbox-label d-flex align-items-center" for="isConfirmTerms" >
                <input className="form-check-input flex-shrink-0" type="checkbox"
                  onClick={(e) => props.handleCheckBoxItemClick(e)}
                  id="isConfirmTerms"
                  checked={selectedProperty?.isConfirmTerms} />
                  <span>I acknowledge that my property introduction and resulting correspondences will adhere to the Federal Fair Housing Act. For complete details, <a href="https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations" target="blank">click here.</a></span>
              </label>
            </div>
              {
                userType == USER_TYPE_AGENT && 
                <div className="field-wrap mt-0 mb-24">
                  <label className="form-check-label checkbox-label d-flex align-items-center" for="contractTerms" >
                    <input className="form-check-input flex-shrink-0" type="checkbox"
                      onClick={(e) => props.handleContractTermsClick(e)}
                      id="contractTerms"
                      checked={selectedProperty?.contractTerms} />
                      <span>I acknowledge that I am under contract to represent the owner of this property and the property is either actively listed in MLS, is listed as ‘coming soon’ in MLS, or will be submitted to MLS within 1 business day of any accepted introduction on Gitcha.</span>
                  </label>
                </div>
            } */}

          </div>
          <div className="modal-choose-property-btnwrap position-static h-100">
            <button className="btn btn-primary w-100 mt-0 l-spacing-0" disabled={buttonDisabledStatus} onClick={() => { setButtonDisabledStatus(true);  props.SendIntroduction()}}>Send introduction</button>
          </div>
          {/*<a href={void 0}
           onClick={(e) => {
              e.preventDefault();
           }}
            className="add-your-property-wrap link-wrap">
                <div className="make-intro-img">
                  <img src={HouseImgIcon} alt={HouseImgIcon} />
                </div>
                <div className="make-intro-details">
                  <h3 className="mb-1">1234 Gitcha Street</h3>
                  <p>Eugene, OR</p>
                </div>

                <span
                  
                  class="right-arrow-link"
                           
                >
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.3897 7.69358C14.628 7.94334 14.6187 8.33895 14.3689 8.57722L9.78298 12.9522C9.54135 13.1827 9.16119 13.1826 8.91976 12.9519L6.6307 10.7644C6.38115 10.5259 6.37217 10.1302 6.61065 9.8807C6.84913 9.63115 7.24475 9.62217 7.4943 9.86065L9.35193 11.6359L13.5061 7.67278C13.7558 7.43451 14.1515 7.44383 14.3897 7.69358Z" fill="#022964"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 3.125C6.70304 3.125 3.625 6.20304 3.625 10C3.625 13.797 6.70304 16.875 10.5 16.875C14.297 16.875 17.375 13.797 17.375 10C17.375 6.20304 14.297 3.125 10.5 3.125ZM2.375 10C2.375 5.51269 6.01269 1.875 10.5 1.875C14.9873 1.875 18.625 5.51269 18.625 10C18.625 14.4873 14.9873 18.125 10.5 18.125C6.01269 18.125 2.375 14.4873 2.375 10Z" fill="#022964"/>
</svg>
                </span>
              </a> */}

          {/* <a href={void 0}
           onClick={(e) => {
              e.preventDefault();
           }}
            className="add-your-property-wrap link-wrap">
                <div className="make-intro-img">
                  <img src={HouseImgIcon} alt={HouseImgIcon} />
                </div>
                <div className="make-intro-details">
                  <h3 className="mb-1">1234 Gitcha Street</h3>
                  <p>Eugene, OR</p>
                </div>

                <span
                  
                  class="right-arrow-link"
                           
                >
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.3897 7.69358C14.628 7.94334 14.6187 8.33895 14.3689 8.57722L9.78298 12.9522C9.54135 13.1827 9.16119 13.1826 8.91976 12.9519L6.6307 10.7644C6.38115 10.5259 6.37217 10.1302 6.61065 9.8807C6.84913 9.63115 7.24475 9.62217 7.4943 9.86065L9.35193 11.6359L13.5061 7.67278C13.7558 7.43451 14.1515 7.44383 14.3897 7.69358Z" fill="#022964"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 3.125C6.70304 3.125 3.625 6.20304 3.625 10C3.625 13.797 6.70304 16.875 10.5 16.875C14.297 16.875 17.375 13.797 17.375 10C17.375 6.20304 14.297 3.125 10.5 3.125ZM2.375 10C2.375 5.51269 6.01269 1.875 10.5 1.875C14.9873 1.875 18.625 5.51269 18.625 10C18.625 14.4873 14.9873 18.125 10.5 18.125C6.01269 18.125 2.375 14.4873 2.375 10Z" fill="#022964"/>
</svg>
                </span>
          </a>*/}

          {/*<a href={void 0}
           onClick={(e) => {
              e.preventDefault();
           }}
            className="add-your-property-wrap link-wrap">
                <div className="make-intro-img">
                  <img src={HouseImgIcon} alt={HouseImgIcon} />
                </div>
                <div className="make-intro-details">
                  <h3 className="mb-1">1234 Gitcha Street</h3>
                  <p>Eugene, OR</p>
                </div>

                <span
                  
                  class="right-arrow-link"
                           
                >
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.3897 7.69358C14.628 7.94334 14.6187 8.33895 14.3689 8.57722L9.78298 12.9522C9.54135 13.1827 9.16119 13.1826 8.91976 12.9519L6.6307 10.7644C6.38115 10.5259 6.37217 10.1302 6.61065 9.8807C6.84913 9.63115 7.24475 9.62217 7.4943 9.86065L9.35193 11.6359L13.5061 7.67278C13.7558 7.43451 14.1515 7.44383 14.3897 7.69358Z" fill="#022964"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 3.125C6.70304 3.125 3.625 6.20304 3.625 10C3.625 13.797 6.70304 16.875 10.5 16.875C14.297 16.875 17.375 13.797 17.375 10C17.375 6.20304 14.297 3.125 10.5 3.125ZM2.375 10C2.375 5.51269 6.01269 1.875 10.5 1.875C14.9873 1.875 18.625 5.51269 18.625 10C18.625 14.4873 14.9873 18.125 10.5 18.125C6.01269 18.125 2.375 14.4873 2.375 10Z" fill="#022964"/>
</svg>
                </span>
          </a>*/}









        </Modal.Body>
        {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
      </Modal>

      <AddPaymentModalStripe
        show={showAddPaymentModal}
        updateReloadParam={() => { setReload(reload + 1); props.updateReloadParam() }}
        onHide={() => { hideAddPaymentModel() }}
      />

      <PaymentMethodsModal
        show={showPaymentMethodsModal}
        updateCardParam={(paymentMethod) => { props.updateCardParam(paymentMethod) }}
        paymentMethods={userPaymentMethods}
        selectedCardId={deafaultPaymentMethod?.Payment_Mode_Id}
        onHide={() => { hidePaymentMethodModel() }}
        AddNewPayment={(isFromPaymentMethodsModal) => { AddNewPayment(isFromPaymentMethodsModal) }}
      />
      <CustomizeIntroModal show={showCustomizeIntroModal}
        selectedProperty={selectedProperty}
        onHide={CustomizeIntroModalClose} 
        onEditClick={()=>{onCoverPhotoEdit()}}
        onSavePropertyFeatureOrder ={(featuredProperty)=>{onSavePropertyFeatureOrder(featuredProperty)}}
        onClickCustizeIntroClose = {()=>{onClickCustizeIntroClose()}}
        savePropertyFeaturesforPreview={(featuredProperty)=>{props.savePropertyFeaturesforPreview(featuredProperty)}}
        reload={showId+1}
        />

         <ChangeCoverPhotosModal 
         show={showChangeCoverPhotosModal}
         selectedProperty={selectedProperty}
         onClickClose={()=>{onClickClose()}}
         onHide={ChangeCoverPhotosModalClose}
         onSave={(photoOrder)=>{SavePhotoOrder(photoOrder)}}
         showId={showId+1}
         />
         <BasicPlanModal
        userDetails={userDetails}
        show={showBasicPlanModal}
        updateReloadParam={updateReloadParam}
        onHide={BasicPlanModalClose}
        showPlanModal={BasicPlanModalShow}
      />
      <PremiumPlanModal
        userDetails={userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}     
        showPlanModal={PremiumPlanModalShow}   
      />

    </>

   
  );
};
export default ReviewOrderModal;
