import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function getS3Details(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_S3_DETAILS +
      `?folderName=${_.get(
        requestPayload,
        "folderName"
      )}`,
    "GET",
    {}
  );
}

export async function uploadFileToS3(preSignedUrl, method, fileData, header) {
  const userId = null;
  const token = null;
  const isFileUpload = true;

  return await apiService.apiCall(
    preSignedUrl,
    method,
    fileData,
    userId,
    token,
    header,
    isFileUpload
  );
}
export async function getPreSignedUrls(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_PRESIGNED_URLS,
    "POST",
    requestPayload
  );
}

export async function deleteFile(requestPayload) {
  return await apiService.apiCall(
    endPoints.DELETE_S3_FILES,
    "POST",
    requestPayload
  );
}

export async function getPreSignedUrl(requestPayload) {
  return await apiService.apiCall(
    endPoints.GET_PRESIGNED_URL,
    "POST",
    requestPayload
  );
}

export async function deleteSingleFile(requestPayload) {
  return await apiService.apiCall(
    endPoints.DELETE_S3_FILE,
    "POST",
    requestPayload
  );
}