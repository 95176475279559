import React, { useState, useEffect, useRef } from "react";
import MobileDropdownSelect from "../components/MobileDropdownSelect";
import { getSquareFeetList } from "../../services/wantAdService";
import {
  arraySplice,
  checkIsNumber,
  checkIsNumberWithDecimal,
  filterArrayBasedOnKey,
  getLotSizeLists,
} from "../../helpers/common";
import { convertToNumber } from "../../helpers/validations";
import _ from "lodash";
import {
  lotSizeMaxLesser,
  lotSizeMaxMissing,
  lotSizeMinGreater,
  lotSizeMinMissing,
} from "../../constants/createWantAd";
import { IS_LAND } from "../../constants/common";

const WantAdLotSize = (props) => {
  const {
    handleFormValues,
    mnLotSze,
    mxLotSze,
    lotSizeError,
    errorUpdatedAt,
    isFromLongForm = false,
    propertyType,
  } = props;
  const MIN_LT_DROPDOWN = "minLTDrop";
  const MAX_LT_DROPDOWN = "maxLTDrop";
  const [lotSizeMaxError, setLotSizeMaxError] = useState("");
  const [lotSizeMinError, setLotSizeMinError] = useState("");
  const [lotSizeMinList, setMinList] = useState([]);
  const [lotSizeMaxList, setMaxList] = useState([]);
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const INDEX_TO_BE_SLICE = 13;
  const lotSizeMaxLesserRef = useRef();

  useEffect(() => {
    getLotSizeLists().then((list) => {
      const minOptions = {
        value: mnLotSze,
        label: mnLotSze && mnLotSze.toString(),
      };
      addNewOptionsToMinList(list, minOptions);
      const maxOptions = {
        value: mxLotSze,
        label: mxLotSze && mxLotSze.toString(),
      };
      addNewOptionsToMaxList(list, maxOptions);
    });
  }, []);

  useEffect(() => {
    if (mxLotSze) {
      const options = { value: convertToNumber(mxLotSze), label: mxLotSze };
      addNewOptionsToMaxList(
        arraySplice(lotSizeMaxList, INDEX_TO_BE_SLICE),
        options
      );
    }

    if (mnLotSze) {
      const options = { value: convertToNumber(mnLotSze), label: mnLotSze };
      addNewOptionsToMinList(
        arraySplice(lotSizeMinList, INDEX_TO_BE_SLICE),
        options
      );
    }
  }, [mnLotSze, mxLotSze]);

  const handleLotSizeSelection = (e, dropSection) => {
    setLotSizeMaxError("");
    setLotSizeMinError("");
    const value = _.get(e, "value", "");
    if (dropSection === MIN_LT_DROPDOWN) {
      handleFormValues({ minLtSz: value });
      handleFormValues({ maxLtSz: mxLotSze });
    } else {
      handleFormValues({
        maxLtSz: value,
      });
      handleFormValues({ minLtSz: mnLotSze });
    }
  };

  const addNewOptionsToMinList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setMinList(customList);
  };

  const addNewOptionsToMaxList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setMaxList(customList);
  };

  const buildCustomList = (list, newOption) => {
    const value = _.get(newOption, "value", "");
    if (convertToNumber(value) > 1) {
      newOption.label = _.get(newOption, "label", "") + " acres";
    } else {
      newOption.label = _.get(newOption, "label", "") + " acre";
    }
    const filteredArray = filterArrayBasedOnKey({
      list: list,
      filterKey: "value",
      value: _.get(newOption, "value", null),
    });
    let customOptions = [...list];
    if (filteredArray?.length === 0 && _.get(newOption, "value", null)) {
      customOptions = [...list, newOption];
    }
    return customOptions;
  };

  const handleOnInputChange = (e, dropSection) => {
    if (e) {
      setLotSizeMaxError("");
      setLotSizeMinError("");
      if (dropSection === MIN_LT_DROPDOWN) {
        if (minRef.current && minRef.current.contains(document.activeElement)) {
          if (checkIsNumberWithDecimal(e)) {
            const options = { value: convertToNumber(e), label: e };
            addNewOptionsToMinList(
              arraySplice(lotSizeMinList, INDEX_TO_BE_SLICE),
              options
            );
            handleFormValues({ minLtSz: e });
          }
        }
      } else {
        if (maxRef.current && maxRef.current.contains(document.activeElement)) {
          if (checkIsNumberWithDecimal(e)) {
            const options = { value: convertToNumber(e), label: e };
            addNewOptionsToMaxList(
              arraySplice(lotSizeMaxList, INDEX_TO_BE_SLICE),
              options
            );
            handleFormValues({ maxLtSz: e });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isFromLongForm && lotSizeError) {
      if (lotSizeError[0] == "lotSizeMaxLesser") {
        lotSizeMaxLesserRef.current.focus();
      }

      lotSizeError.map((item) => {
        if (item === "lotSizeMaxLesser") {
          setLotSizeMaxError("Value Must be greater than minimum");
        }
        // else if (item == 'lotSizeMinMissing') {
        //   setLotSizeMinError('Select a value');
        // } else if (item == 'lotSizeMaxMissing') {
        //   setLotSizeMaxError('Select a value');
        // }
      });
    } else {
      if (lotSizeError === lotSizeMaxLesser) {
        setLotSizeMaxError("Value Must be greater than minimum");
      }
      // if (lotSizeError === lotSizeMinMissing) {
      //   setLotSizeMinError("Select min lot size");
      // }
      // if (lotSizeError === lotSizeMaxMissing) {
      //   setLotSizeMaxError("Select max lot size");
      // }
    }
  }, [lotSizeError, errorUpdatedAt]);

  return (
    // <div className={isFromLongForm && lotSizeMinError ? "wantad-timing-wrap pb-0  red-marked-wrap" : "wantad-timing-wrap pb-0"}>
    <div className="lp-btn-group-wrap">
      <h4
        className={isFromLongForm && lotSizeMinError && "red-marked-subheading"}
      >
        {" "}
        Lot Size
        {isFromLongForm && propertyType == IS_LAND && <span>*</span>}
      </h4>
      <div className="row">
        <div className="col-6 pe-2">
          <div className="mob-fld-wrap position-relative ms-0 me-0">
            <label
              className="form-label"
            >
              Min
            </label>
            <div className="" ref={minRef}>
            <MobileDropdownSelect
              id={MIN_LT_DROPDOWN}
              name="colors3"
              pattern="[0-9]*" 
              inputmode="numeric"
              options={[
                { value: null, label: "No min" },
                ...lotSizeMinList,
              ]}
              className="mobile-select"
              classNamePrefix="select"
              placeholder="No min"
              value={filterArrayBasedOnKey({
                list: lotSizeMinList,
                filterKey: "value",
                value: mnLotSze,
              })}
              onInputChange={(e) => handleOnInputChange(e, MIN_LT_DROPDOWN)}
              onChange={(e) => handleLotSizeSelection(e, MIN_LT_DROPDOWN)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                }),
              }}
              errorClass="error-msg error-msg-height"
              errorMessage={lotSizeMinError}
            />
            </div>
          </div>
        </div>
        <div className="col-6 ps-2">
          <div className="mob-fld-wrap position-relative ms-0 me-0">
            <label
              className="form-label"
            >
              Max
            </label>
            <div
              className={
                lotSizeMaxError ? "form-label red-marked-wrap" : "form-label"
              }
              ref={maxRef}
            >
              <MobileDropdownSelect
                id={MAX_LT_DROPDOWN}
                pattern="[0-9]*" 
                inputmode="numeric"
                name="colors5"
                options={[
                  { value: null, label: "No max" },
                  ...lotSizeMaxList,
                ]}
                className={
                  isFromLongForm && lotSizeMaxError
                    ? "mobile-select custom-lotsize-color red-marked-select"
                    : "mobile-select"
                }
                classNamePrefix="select"
                placeholder="No max"
                value={filterArrayBasedOnKey({
                  list: lotSizeMaxList,
                  filterKey: "value",
                  value: mxLotSze,
                })}
                onInputChange={(e) => handleOnInputChange(e, MAX_LT_DROPDOWN)}
                onChange={(e) => handleLotSizeSelection(e, MAX_LT_DROPDOWN)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                  }),
                }}
                errorClass="error-msg error-msg-height"
                errorMessage={lotSizeMaxError}
                selectRef={lotSizeMaxLesserRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default WantAdLotSize;
