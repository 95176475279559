import React, { useState, useEffect } from "react";
import VisaIcon from "../../images/visa-icon.svg";
import GPayIcon from "../../images/gpay-icon.svg";
import ApplePayIcon from "../../images/applepay-icon.svg";
import "../../styles/clients.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCardIconForIntroduction, getLocalStorage, getUserId, getUserType, isDateInThePast, setBodyBackgroundGrey, setLocalStorage } from "../../../helpers/common";
import { AGENT_INTRODUCTION, MEMBER_INTRODUCTION } from "../../../constants/common";
import { redirect } from "react-router-dom";
import { ADD_PAYMENT, REVIEW_ORDER } from "../../../constants/onBoarding";


const SelectPaymentMethod = (props) => {
    const { updateCardParam, setUIKey, setReload } = props
    useEffect(() => {
        setBodyBackgroundGrey()
        document.body.classList.add('bg-offcanvas-fix');

    }, []);

    const userPaymentMethods = props.paymentMethods;
    const selectedCardId = props.selectedCardId;

    const handleRadioButtonChange = (paymentMethod) => {
        updateCardParam(paymentMethod);
        setUIKey(REVIEW_ORDER)
    }

    return (

        <div className="gitcha-main-wrapper view-client-wrap select-payment-wrap pb-102">

            <div className="content-wrapper content-wrapper-h-102-percent">
                <div className="inner-layout-header-new bg-white">
                    <div className="review-plan-header header-brdr-btm w-100">
                        <div className="signup-login-header-top signup-header-txt clearfix">
                            <a className="back-link-small" href={void(0)}
                                onClick={() => { props.setUIKey(REVIEW_ORDER) }}><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                                </svg></a>
                            <span>Select Payment Method</span>
                        </div>
                    </div>
                </div>
                <div className="inner-layout-content-new bg-white">
                    <div className="modal-choose-property mb-0">
                        {userPaymentMethods && userPaymentMethods.length > 0 &&
                            userPaymentMethods?.map((paymentMethod) => {
                                let expired = isDateInThePast(paymentMethod?.Exp_Month, paymentMethod?.Exp_Year);
                                return (
                                    <label class="property-radio">
                                        {!expired && (
                                            <input type="radio" name="check-box-option"
                                                id={paymentMethod?.CardId}
                                                value={paymentMethod?.CardId}
                                                checked={paymentMethod?.Payment_Mode_Id == selectedCardId ? true : false}
                                                onClick={() => handleRadioButtonChange(paymentMethod)}
                                            />
                                        )}
                                        <span className="bdrRadius-0">
                                            <div className="card-icon">  <img src={getCardIconForIntroduction(paymentMethod?.Brand)} /></div>
                                            <div class="card-name">
                                                <h4>{paymentMethod?.Brand} •••••••••••••{paymentMethod?.Last4} </h4>
                                                {expired ?
                                                    (<p className="m-0">Expired {String(paymentMethod?.Exp_Month).padStart(2, '0')}/{String(paymentMethod?.Exp_Year).slice(-2)}</p>) :
                                                    (<p className="m-0">Exp. {String(paymentMethod?.Exp_Month).padStart(2, '0')}/{String(paymentMethod?.Exp_Year).slice(-2)}</p>)

                                                }

                                            </div>
                                        </span>
                                    </label>
                                )
                            })}


                    </div>
                </div>
            </div>
            <div className="inner-layout-footer-new">
                <div className="inner-btm-fixed-nav">
                    <div className="btn-wrap">
                        <button type="button" className="btn btn-light w-100 btn-ico-flex" onClick={() => { setUIKey(ADD_PAYMENT) }}>Add Debit/Credit Card <i className="add-ico"></i></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectPaymentMethod;
