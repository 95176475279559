import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { removeNegativeInput } from "../../../helpers/validations";
import RangeSliderComponent from "../../Common/RangeSlider";
import TextBox from "../../Common/TextBox";
import { checkInventoryType } from "../../../helpers/inventory";

import {
  INVENTORY_ALL_TYPE,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  YES_NO_OPTIONS,
} from "../../../constants/common";
import {
  replaceComma,
  replaceString,
  thousandSeperatedValue,
} from "../../../helpers/common";

const InventoryBudget = (props) => {
  const {
    sellAskngPrice,
    LTRAskngPrce,
    STRAskngPrce,
    isCompnsteAgent,
    isCompnsteAgentLTR,
    isCompnsteAgentSTR,
    propertyPurpose,
    salesPercnt,
    salesPercntLTR,
    salesPercntSTR,
    handleContinueButtonState,
    handleFormValues,
  } = props;

  const invType = checkInventoryType(propertyPurpose);
  const isSellOnly = invType === INVENTORY_SELL;
  const isLTROnly = invType === INVENTORY_LTR;
  const isSTROnly = invType === INVENTORY_STR;
  const isSell_And_LTR = invType === INVENTORY_SELL_LTR;
  const isSell_And_STR = invType === INVENTORY_SELL_STR;
  const isSTR_And_LTR = invType === INVENTORY_LTR_STR;
  const isAllType = invType === INVENTORY_ALL_TYPE;
  let askingPrice = sellAskngPrice;
  const [sellCursorPos, setSellCursorPos] = useState(0);
  const [lTRCursorPos, setLTRCursorPos] = useState(0);
  const [sTRCursorPos, setSTRCursorPos] = useState(0);

  const sellRef = useRef();
  const ltrRef = useRef();
  const strRef = useRef();

  const renderTooltip = (params) => (
    <Tooltip id="button-tooltip" {...params}>
      {params}
    </Tooltip>
  );
  const [agentPercentage, setAgentPercentage] = useState("");
  const [agentPercentageLTR, setAgentPercentageLTR] = useState("");
  const [agentPercentageSTR, setAgentPercentageSTR] = useState("");
  const SLIDER_MIN_VALUE = 1;
  const SLIDER_MAX_VALUE = 10;
  const SLIDER_DEFAULT_VALUE = 10;
  const SLIDER_STEP_VALUE = 0.25;
  const SELL = "SELL";
  const LTR = "LTR";
  const STR = "STR";

  useEffect(() => {
    if(sellRef.current){
      sellRef.current.setSelectionRange(sellCursorPos, sellCursorPos);
    }
    
  }, [sellCursorPos]);

  useEffect(() => {
    if(ltrRef.current){
    ltrRef.current.setSelectionRange(lTRCursorPos, lTRCursorPos);
    }
  }, [lTRCursorPos]);

  useEffect(() => {
    if(strRef.current){
    strRef.current.setSelectionRange(sTRCursorPos, sTRCursorPos);
    }
  }, [sTRCursorPos]);

  const handleAskngPriceChange = (e, section) => {
    let value = removeNegativeInput(e.target.value);
    if (value === "0") {
      value = 1;
    }
    switch (section) {
      case SELL_ASKING_PRICE:
        if (isLTROnly) {
          handleFormValues({ [LTR_ASKING_PRICE]: replaceComma(value) });
          handleFormValues({ [LTR_ASKING_PRICE]: replaceComma(value) });
          
          if( (thousandSeperatedValue(LTRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos(e.target.selectionStart)
           } else if((thousandSeperatedValue(LTRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos((e.target.selectionStart + 1) )
          } else{
            setSellCursorPos(e.target.selectionStart)
          }
        } else if (isSTROnly) {
          handleFormValues({ [STR_ASKING_PRICE]: replaceComma(value) });
          if( (thousandSeperatedValue(STRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos(e.target.selectionStart)
           } else if((thousandSeperatedValue(STRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos((e.target.selectionStart + 1) )
          } else{
            setSellCursorPos(e.target.selectionStart)
          }
        } else {
          handleFormValues({ [SELL_ASKING_PRICE]: replaceComma(value) });
          if( (thousandSeperatedValue(sellAskngPrice)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos(e.target.selectionStart)
           } else if((thousandSeperatedValue(sellAskngPrice)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
            setSellCursorPos((e.target.selectionStart + 1) )
          } else{
            setSellCursorPos(e.target.selectionStart)
          }
        }
        break;
      case LTR_ASKING_PRICE:
        handleFormValues({ [LTR_ASKING_PRICE]: replaceComma(value) });
        if( (thousandSeperatedValue(LTRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setLTRCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(LTRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setLTRCursorPos((e.target.selectionStart + 1) )
        } else{
          setLTRCursorPos(e.target.selectionStart)
        }
        break;
      case STR_ASKING_PRICE:
        handleFormValues({ [STR_ASKING_PRICE]: replaceComma(value) });
        if( (thousandSeperatedValue(STRAskngPrce)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setSTRCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(STRAskngPrce)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setSTRCursorPos((e.target.selectionStart + 1) )
        } else{
          setSTRCursorPos(e.target.selectionStart)
        }
        break;
    }
  };

  useEffect(() => {
    agentPercentage && handleFormValues({ salesPercnt: agentPercentage[1] });
  }, [agentPercentage]);

  useEffect(() => {
    agentPercentageLTR &&
      handleFormValues({ salesPercnt_LTR: agentPercentageLTR[1] });
  }, [agentPercentageLTR]);

  useEffect(() => {
    agentPercentageSTR &&
      handleFormValues({ salesPercnt_STR: agentPercentageSTR[1] });
  }, [agentPercentageSTR]);

  useEffect(() => {
    let isDisable = true;
    if (isSellOnly) {
      if (sellAskngPrice) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
      //handleContinueButtonBasedOnSalesPercentage()
    }

    if (isLTROnly) {
      if (LTRAskngPrce) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
    }

    if (isSTROnly) {
      if (STRAskngPrce) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
    }

    if (isSell_And_LTR) {
      if (sellAskngPrice && LTRAskngPrce) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
      // handleContinueButtonBasedOnSalesPercentage()
    }

    if (isSell_And_STR) {
      if (sellAskngPrice && STRAskngPrce) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
      //handleContinueButtonBasedOnSalesPercentage()
    }

    if (isSTR_And_LTR) {
      if (LTRAskngPrce && STRAskngPrce) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
    }

    if (isAllType) {
      if (LTRAskngPrce && STRAskngPrce && sellAskngPrice) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
      //handleContinueButtonBasedOnSalesPercentage()
    }
  }, [
    isCompnsteAgent,
    sellAskngPrice,
    STRAskngPrce,
    LTRAskngPrce,
    salesPercnt,
    salesPercntLTR,
    salesPercntSTR,
  ]);

  const handleContinueButtonBasedOnSalesPercentage = () => {
    let isDisable;
    if (isCompnsteAgent === "true" || isCompnsteAgent === 1) {
      if (salesPercnt) {
        isDisable = false;
        handleContinueButtonState(isDisable);
      } else {
        isDisable = true;
        handleContinueButtonState(isDisable);
      }
    }
  };

  const handleAgentPercentage = (e, section) => {
    let value = removeNegativeInput(e.target.value);
    if (value && /^\d*\.?\d*$/.test(value)) { // pattern to prevent multiple one dot enter
      if (value === '.' || (value.length === 1 && value[0] === '.')) {
        // If the first character is a dot, set the value to min value
        value = SLIDER_MIN_VALUE;
      } else if(value === '10.' || (value.length === 3 && value[3] === '.')){
        // If the the enterd value > 10 eg: 10.1, set the value to max value
        value = SLIDER_MAX_VALUE;
      } else {
          if (value > SLIDER_MAX_VALUE) {
            value = SLIDER_MAX_VALUE;
          } else if (value < SLIDER_MIN_VALUE) {
            value = SLIDER_MIN_VALUE;
          }
      }
    }

      switch (section) {
        case SELL:
          setAgentPercentage([0, value]);
          handleFormValues({ salesPercnt: value });
          break;
        case LTR:
          setAgentPercentageLTR([0, value]);
          handleFormValues({ salesPercnt_LTR: value });
          break;
        case STR:
          setAgentPercentageSTR([0, value]);
          handleFormValues({ salesPercnt_STR: value });
          break;
      }
    
  };

  const handleFoucsOutAgentPercentage = (section)=>{
    switch (section) {
      case SELL:
        setAgentPercentage([0, SLIDER_MIN_VALUE]);
        handleFormValues({ salesPercnt: SLIDER_MIN_VALUE });
        break;
      case LTR:
        setAgentPercentageLTR([0, SLIDER_MIN_VALUE]);
        handleFormValues({ salesPercnt_LTR: SLIDER_MIN_VALUE });
        break;
      case STR:
        setAgentPercentageSTR([0, SLIDER_MIN_VALUE]);
        handleFormValues({ salesPercnt_STR: SLIDER_MIN_VALUE });
        break;
    }

  }

  const handleBooleanOptionSelection = (e, section) => {
    let selectedValue = e.target.value;
    if (selectedValue && typeof selectedValue === "string") {
      if (selectedValue === "true") selectedValue = true;
      if (selectedValue === "false") selectedValue = false;
    }
    switch (section) {
      case SELL:
        if (selectedValue === isCompnsteAgent) {
          handleFormValues({ isCompnsteAgent: "", salesPercnt: "" });
        } else {
          if (selectedValue === false || selectedValue === 0) {
            handleFormValues({
              isCompnsteAgent: selectedValue,
              salesPercnt: "",
            });
          } else {
            handleFormValues({
              isCompnsteAgent: selectedValue,
              salesPercnt: SLIDER_DEFAULT_VALUE,
            });
          }
        }
        break;
      case LTR:
        if (selectedValue === isCompnsteAgentLTR) {
          handleFormValues({ isCompnsteAgent_LTR: "", salesPercnt_LTR: "" });
        } else {
          if (selectedValue === false || selectedValue === 0) {
            handleFormValues({
              isCompnsteAgent_LTR: selectedValue,
              salesPercnt_LTR: "",
            });
          } else {
            handleFormValues({
              isCompnsteAgent_LTR: selectedValue,
              salesPercnt_LTR: SLIDER_DEFAULT_VALUE,
            });
          }
        }
        break;
      case STR:
        if (selectedValue === isCompnsteAgentSTR) {
          handleFormValues({ isCompnsteAgent_STR: "", salesPercnt_STR: "" });
        } else {
          if (selectedValue === false || selectedValue === 0) {
            handleFormValues({
              isCompnsteAgent_STR: selectedValue,
              salesPercnt_STR: "",
            });
          } else {
            handleFormValues({
              isCompnsteAgent_STR: selectedValue,
              salesPercnt_STR: SLIDER_DEFAULT_VALUE,
            });
          }
        }
        break;
    }
  };

  const SELL_ASKING_PRICE = "sellAskngPrice";
  const LTR_ASKING_PRICE = "LTRAskngPrce";
  const STR_ASKING_PRICE = "STRAskngPrce";
  const loadAgentCompensation = (params) => {
    const itemList = YES_NO_OPTIONS;
    let isCheckedYes = false;
    let isCheckedNo = false;
    const section = _.get(params, "section", "");
    const selectedValue = _.get(params, "value", "");
    if (selectedValue === true || selectedValue === 1) {
      isCheckedYes = true;
    } else if (selectedValue === false || selectedValue === 0) {
      isCheckedNo = true;
    }
    return (
      <>
        <div className="col-md-6 pe-md-2">
          <label className="radio-wrap mb-3">
            <input
              name={section}
              type="radio"
              value={_.get(itemList[0], "value", "")}
              checked={isCheckedYes}
              onChange={() => {}}
              onClick={(e) => handleBooleanOptionSelection(e, section)}
            />
            <span>
              <div className="timing-wrap-info">
                <h2 className="pb-0">{_.get(itemList[0], "text", "")}</h2>
              </div>
            </span>
          </label>
        </div>

        <div className="col-md-6 ps-md-2">
          <label className="radio-wrap mb-3">
            <input
              name={section}
              type="radio"
              value={_.get(itemList[1], "value", "")}
              checked={isCheckedNo}
              onChange={() => {}}
              onClick={(e) => handleBooleanOptionSelection(e, section)}
            />
            <span>
              <div className="timing-wrap-info">
                <h2 className="pb-0">{_.get(itemList[1], "text", "")}</h2>
              </div>
            </span>
          </label>
        </div>
      </>
    );
  };
  if (isSTROnly) {
    askingPrice = STRAskngPrce;
  } else if (isLTROnly) {
    askingPrice = LTRAskngPrce;
  }

  function AgentOptions(props) {
    const { value, section } = props;
    let title;
    switch (section) {
      case SELL:
        title = "Would you compensate an agent if they brought you a buyer?";
        break;
      case LTR:
        if (isLTROnly) {
          title = "Would you compensate an agent if they brought you a renter?";
        } else {
          title =
            "Would you compensate an agent if they brought you a long-term renter?";
        }
        break;
      case STR:
        if (isSTROnly) {
          title = "Would you compensate an agent if they brought you a renter?";
        } else {
          title =
            "Would you compensate an agent if they brought you a short-term renter?";
        }
        break;
    }
    return (
      <div className="land-radio-wrap pb-4">
        <h6>
          {title}
          {/* <span>*</span> */}
        </h6>
        <div className="row">
          {loadAgentCompensation({ value: value, section: section })}
        </div>
      </div>
    );
  }

  const agentCompensationSlider = (params) => {
    const { salesPercent, type, showBottomLineBreak } = params;
    let title;
    let toolTitle;
    switch (type) {
      case SELL:
        toolTitle =
          "This is the percentage of the contract price that the seller is offering to pay a professional representing a buyer.";
        title = "Percentage of total contract price offered to buyer's agent";
        break;
      case LTR:
        toolTitle =
          "Percentage of total contract price offered to renter's agent.";
        title = "Percentage of total contract price offered to renter's agent";
        break;
      case STR:
        toolTitle =
          "Percentage of total cost of stay  offered to renter's agent.";
        title = "Percentage of total cost of stay  offered to renter's agent";
        break;
    }
    return (
      <>
        <div className="land-radio-wrap position-relative pb-0">
          <h6 className="d-inline-block mb-2 me-4 align-top">
            {title}
            <span>*</span>
          </h6>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(toolTitle)}
          >
            <span className="price-info-icon top-pos-2">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99967 1.8335C4.32634 1.8335 1.33301 4.82683 1.33301 8.50016C1.33301 12.1735 4.32634 15.1668 7.99967 15.1668C11.673 15.1668 14.6663 12.1735 14.6663 8.50016C14.6663 4.82683 11.673 1.8335 7.99967 1.8335ZM7.49967 5.8335C7.49967 5.56016 7.72634 5.3335 7.99967 5.3335C8.27301 5.3335 8.49967 5.56016 8.49967 5.8335V9.16683C8.49967 9.44016 8.27301 9.66683 7.99967 9.66683C7.72634 9.66683 7.49967 9.44016 7.49967 9.16683V5.8335ZM8.61301 11.4202C8.57967 11.5068 8.53301 11.5735 8.47301 11.6402C8.40634 11.7002 8.33301 11.7468 8.25301 11.7802C8.17301 11.8135 8.08634 11.8335 7.99967 11.8335C7.91301 11.8335 7.82634 11.8135 7.74634 11.7802C7.66634 11.7468 7.59301 11.7002 7.52634 11.6402C7.46634 11.5735 7.41967 11.5068 7.38634 11.4202C7.3521 11.34 7.33397 11.254 7.33301 11.1668C7.33301 11.0802 7.35301 10.9935 7.38634 10.9135C7.41967 10.8335 7.46634 10.7602 7.52634 10.6935C7.59301 10.6335 7.66634 10.5868 7.74634 10.5535C7.90865 10.4868 8.0907 10.4868 8.25301 10.5535C8.33301 10.5868 8.40634 10.6335 8.47301 10.6935C8.53301 10.7602 8.57967 10.8335 8.61301 10.9135C8.64634 10.9935 8.66634 11.0802 8.66634 11.1668C8.66634 11.2535 8.64634 11.3402 8.61301 11.4202Z"
                  fill="#4A4C4F"
                />
              </svg>
            </span>
          </OverlayTrigger>
          <div className="row">
            <div className="col-md-9 pe-md-2">
              <div className="rs-pd">
                <RangeSliderComponent
                  sliderClass="single-thumb"
                  min={SLIDER_MIN_VALUE}
                  max={SLIDER_MAX_VALUE}
                  defaultValue={[0, salesPercent]}
                  value={[0, salesPercent || 0]}
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                  stepValue={SLIDER_STEP_VALUE}
                  onInput={
                    type === SELL
                      ? setAgentPercentage
                      : type === LTR
                      ? setAgentPercentageLTR
                      : setAgentPercentageSTR
                  }
                />
              </div>
            </div>

            <div className="col-md-3 ps-md-2">
              <div className="common-input-wrap position-relative mb-0">
                <div className="percent-icon-wrap">%</div>
                <TextBox
                  class="form-control text-center pad-right-25"
                  type="text"
                  placeholder=""
                  value={
                    thousandSeperatedValue(salesPercent) || ""
                  }
                  onChange={(e) => {
                    handleAgentPercentage(e, type);
                  }}
                  onBlur={(e) => {
                    salesPercent == "" &&
                    handleFoucsOutAgentPercentage(type);
                  }}
                />
                <div className="error-msg"></div>
              </div>
            </div>
          </div>{" "}
        </div>
        {showBottomLineBreak && <hr className="sec-brdr"></hr>}
      </>
    );
  };

  let isShowCompensateAgentSection = isCompnsteAgent;
  if ((isLTROnly && isCompnsteAgentLTR) || isCompnsteAgentLTR === 1) {
    isShowCompensateAgentSection = isCompnsteAgentLTR;
  }
  return (
    <div className="center-content-sec mw-444">
      <div className="wantad-location-wrap mb-3">
        <div className="wantad-timing-wrap">
          {(isSellOnly ||
            isAllType ||
            isSell_And_LTR ||
            isSell_And_STR ||
            isLTROnly ||
            isSTROnly) && (
            <>
              <div className="land-radio-wrap position-relative pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="common-input-wrap mb-4 position-relative">
                      <div className="innerdollar-sign-icon-first">$</div>
                      <Form.Label className="mb-2">
                        {isAllType || isSell_And_LTR || isSell_And_STR
                          ? "Selling Asking Price"
                          : "Asking Price"}
                        <span>*</span>
                      </Form.Label>

                      <TextBox
                        class="form-control pad-left-25"
                        name="askingPrice"
                        type="text"
                        value={
                          askingPrice ? thousandSeperatedValue(askingPrice) : ""
                        }
                        placeholder="Asking Price"
                        onChange={(e) => {
                          handleAskngPriceChange(e, SELL_ASKING_PRICE);
                        }}
                        txtRef={sellRef}
                      />
                      <div className="error-msg"></div>
                    </div>
                  </div>
                </div>
              </div>

              {!isSTROnly && (
                <AgentOptions
                  value={
                    isLTROnly
                      ? isCompnsteAgentLTR
                      : isSTROnly
                      ? isCompnsteAgentSTR
                      : isCompnsteAgent
                  }
                  section={isLTROnly ? LTR : isSTROnly ? STR : SELL}
                />
              )}
              {(!isSTROnly &&
                isShowCompensateAgentSection) ?
                agentCompensationSlider({
                  salesPercent: isLTROnly
                    ? salesPercntLTR
                    : isSTROnly
                    ? salesPercntSTR
                    : salesPercnt,
                  type: isLTROnly ? LTR : isSTROnly ? STR : SELL,
                  showBottomLineBreak: true,
                }) : <></>}
            </>
          )}

          {(isAllType || isSell_And_LTR || isSTR_And_LTR) && (
            <>
              <div className="land-radio-wrap position-relative pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="common-input-wrap mb-2 position-relative">
                      <div className="innerdollar-sign-icon-second">$</div>
                      <Form.Label className="mb-2">
                        Long-term Renting Asking Price (Monthly) <span>*</span>
                      </Form.Label>

                      <TextBox
                        class="form-control pad-left-25"
                        name="LTRaskingPrice"
                        type="text"
                        value={
                          LTRAskngPrce
                            ? thousandSeperatedValue(LTRAskngPrce)
                            : ""
                        }
                        placeholder="Long-term Renting Asking Price"
                        onChange={(e) => {
                          handleAskngPriceChange(e, LTR_ASKING_PRICE);
                        }}
                        txtRef={ltrRef}
                      />
                      <div className="error-msg"></div>
                    </div>
                  </div>
                </div>
              </div>

              <AgentOptions value={isCompnsteAgentLTR} section={LTR} />
              {(isCompnsteAgentLTR || isCompnsteAgentLTR === 1) ?
                agentCompensationSlider({
                  salesPercent: salesPercntLTR,
                  type: LTR,
                  showBottomLineBreak: true,
                }) : <></>}
            </>
          )}

          {(isAllType || isSell_And_STR || isSTR_And_LTR) && (
            <>
              <div className="land-radio-wrap position-relative pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="common-input-wrap mb-4 position-relative">
                      <div className="innerdollar-sign-icon-second">$</div>
                      <Form.Label className="mb-2">
                        Short-term Renting Asking Price (Nightly) <span>*</span>
                      </Form.Label>
                      <TextBox
                        class="form-control pad-left-25"
                        name="LTRaskingPrice"
                        type="text"
                        value={
                          STRAskngPrce
                            ? thousandSeperatedValue(STRAskngPrce)
                            : ""
                        }
                        placeholder="Short-term Renting Asking Price"
                        onChange={(e) => {
                          handleAskngPriceChange(e, STR_ASKING_PRICE);
                        }}
                        txtRef={strRef}
                      />
                      <div className="error-msg"></div>
                    </div>
                  </div>
                </div>
              </div>              
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryBudget;
