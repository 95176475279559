/**
 * @file   Source/gitcha_Web/src/components/Onboarding/Dashboard/FilterLocationSearch.js
 * @brief  This file is responsible for location search in home page
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import InputGroupControl from "../../Common/InputGroup";
import { capitalizeString } from "../../../actions/common";
import { getGeoLocation } from "../../../services/commonServices";
import { getLocalStorage, getUserId, removeLocalStorage} from "../../../helpers/common";

const FilterLocationSearch = (props) => {
const {setUserLocation, gitchaNotAvailble, searchedLocation, checkGitchaAvailableInLoc} = props
const [locationList, setLocationList] = useState([]);
const [location, setLocation] = useState("")
const userLocation = getLocalStorage("userLocation");
const locationString = getLocalStorage("LocationString");
const userId = getUserId();

useEffect(()=>{
if(locationString != null && userId ){//handle location search from webflow page
  const reqPayload = `address=${capitalizeString(locationString)}&components=country:US`;
  getAddresseDetails({ reqPayload: reqPayload });
}
else {
  if(!searchedLocation){
    clearLocationStates()
  }
  else{    
    setLocation(_.get(searchedLocation,"formatted_address", ""))
  }
}
}, [searchedLocation, gitchaNotAvailble, locationString, userId])

//clear searched address list
const clearAddressList = () => {
  setLocationList([]);
};

//Handle text change in location field
const handleLocationChange = (val) => { 
  const value = capitalizeString(val);
  const reqPayload = `address=${value}&components=country:US`;
  if (value.trim() !== "") {
    getAddresseDetails({ reqPayload: reqPayload });
  } else {
    clearAddressList();
  }
  if (value && value.trim() !== "") {
    setLocation(value);
} else {
    clearLocationStates();
  }   
};

//clear searched location states
const clearLocationStates = () => {
if(!locationString){
  removeLocalStorage("searchedLocation")
  if (!userLocation?.isFromAvailableCities) {
    setUserLocation(null);
  } else {
    setUserLocation(userLocation);
  }
  setLocation("")
  }
};

//fetch details of location based on entered location
const getAddresseDetails = async ({ eventType, reqPayload }) => {
  await getGeoLocation(reqPayload).then((data) => {
    const results = _.get(data, "results");
    if (results?.length > 0) {
      const formatted_address = _.get(results?.[0], 'formatted_address', '')
        if(results?.length > 0 && formatted_address!== "United States"){
          if(locationString){
            handleItemSelection(results[0]);
            removeLocalStorage("LocationString")
          }
          else {
            setLocationList(results);
          }
        }
    } 
  });
};

//Handle location select from the list. Based on this location want ads will be listed
const handleItemSelection = async (address) => {
  let city, state, county;
  removeLocalStorage("searchedLocation")
  const geometry = _.get(address, "geometry", "");
  const locBounds = _.get(geometry, "location", "");
  // setLat(_.get(locBounds, "lat", ""))
  // setLong(_.get(locBounds, "lng", ""))
  const formatted_address = _.get(address, "formatted_address", "");
  const firstAddressComponent = _.get(address, "address_components", "");
  const stateSearched = firstAddressComponent.filter(function (ca) {
    return ca.types?.[0] === "administrative_area_level_1";
  });
  if (stateSearched.length) {
     state = _.get(stateSearched?.[0], "short_name", "");
  }
  const locality = firstAddressComponent.filter(function (ca) {
    return ca.types?.[0] === "locality";
  });
  if (locality.length) {
     city = _.get(locality?.[0], "long_name", "");
  }
  const countyList = firstAddressComponent.filter(function (ca) {
    return ca.types?.[0] === "administrative_area_level_2";
  });

  if (countyList.length) {
    county = _.get(countyList?.[0], "short_name", "");
  }
  // if(city!= undefined){setFormattedAddress(city+", "+state)}
  // else{setFormattedAddress(formatted_address)}
  setLocation(city!=undefined ? city+", "+state : formatted_address);  
  const locationObject = {latitude:_.get(locBounds, "lat", ""), longitude:_.get(locBounds, "lng", ""),city:city,state:state, formatted_address:city!=undefined ? city+", "+state : formatted_address}
  checkGitchaAvailableInLoc(city, state, county,formatted_address, locationObject);
  clearAddressList();
};


  return (
    // <InputGroup className="search_field">
    <>
    <div className="position-relative">
      {locationList.length > 0 ? (
                    <Button
                      variant=""
                      className="search-clear-btn"
                      onClick={() => clearAddressList()}
                    ></Button>
                  ) : (
                    ""
                  )}
                  <InputGroupControl
                    inputGroupClass="wantad-location-search position-relative"
                    ariaLabel="Example"
                    ariaDescribedBy="basic-addon3"
                    placeholder="Enter ZIP code or city and state"
                    errorMessage=""
                    value={location ? location : ""}
                    iconClass="location-icon"
                    isIconEnable={true}
                    iconId="button-addon3"
                    onChange={(e) => {
                      handleLocationChange(e.target.value);
                    }}
                  />
                  {locationList.length > 0 ? (
                    <div className="location-result-wrap">
                      <ul>
                        {locationList?.map((address, i) => {
                          return (
                            <li key={i}>
                              <a
                                role="button"
                                href={void 0}
                                onClick={(e) => {
                                  handleItemSelection(address);
                                }}
                              >
                                {_.get(address, "formatted_address", "")}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}      
    </div>
    </>
  );
};
export default FilterLocationSearch;
