import React from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

const FeedSortPopup = (props) => {
  const { show, onHide, handleSortParam, sortParam, options } = props;
  const sortOptions = options || [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "highPrice", label: "Highest price" },
    { value: "lowPrice", label: "Lowest price" },
    // { value: "Most Relevant", label: "Most Relevant" },
  ];

  return (

    <Offcanvas
      className="mobile-offcanvas zIndexPopup offcanvas-filter-height-fix offcanvas-filter-share-fix"
      show={show}
      onHide={onHide}
      placement='bottom'
      {...props}>
      <Offcanvas.Header className="filter-offcanvas-header">
      <div className="share-yourad-topsec justify-content-center w-100 pt-32 mb-20">
            <div className="share-yourad-headingw-100 mt-0 mb-0">Sort</div>
          </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="offcanvas-sharead-wrap ">
          

          <div className="sortItems ">
            <div class="modal-choose-property mb-0">
              {
                sortOptions.map((item) => {
                  return (
                    <label class="property-radio">
                      <input type="radio" name="check-box-option" id={item?.value} value={item?.value}
                        onClick={() => { handleSortParam(item) }}
                        checked={sortParam?.value == item?.value}
                      />
                      <span className="heightWrap">
                        <div class="modal-wrap-info">
                          <h2 class="pb-0">{item?.label}</h2>
                        </div>
                      </span>
                    </label>
                  )

                })
              }

            </div>

          </div>

        </div>

      </Offcanvas.Body>
    </Offcanvas>

  );
};
export default FeedSortPopup;
