import React, { useEffect, useState } from "react";
import PaymentComponent from './PaymentComponent'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Modal from "react-bootstrap/Modal";

const AddPaymentModalStripe = (props) => {
  const [stripePromise, setStripePromise] = useState(() => loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`))  
return (
  <Modal
    show
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="modal-settings modal-payment plan-payment"
  >
    <Modal.Body className="pt-32 pb-32">
      <button
        type="button" 
        class="btn-close"
        aria-label="Close"
        onClick={props.onHide}
      ></button>
      {/* {stripePromise &&  */}
      <Elements stripe={stripePromise}>
        <PaymentComponent {...props}/>
      </Elements> 
      {/* }      */}
    </Modal.Body>
  </Modal>
);
};
export default AddPaymentModalStripe;
