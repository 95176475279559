import React, { useState, useEffect } from "react";
import MustHaveBtnGroupMob from "../MustHaveBtnGroupMob";
import { handleRadioButonChange } from "../../../actions/common";
import WantAdSquareFeet from "../../commonComponents/WantAdSquareFeet";
import { getLocalStorage, removeDecimalZeros } from "../../../helpers/common";
import { STORY_ANY, STORY_SINGLE, WANT_AD_KEY } from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
const MustHavesPropertiesLTR = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setDisableSave,
    isEdit,
  } = props;

  const noOfBedrooms = _.get(formValues, "noBedRm", null);
  const noOfBedroomsinSession = _.get(getLocalStorage(WANT_AD_KEY), "noBedRm", null);
  const noOfBathrooms = _.get(formValues, "noBathRm", null);
  const noOfBathroomsinSession = _.get(getLocalStorage(WANT_AD_KEY), "noBathRm", null);
  const SFMin = removeDecimalZeros(_.get(formValues, "sqFtMin", null));
  const SFMinInSession = removeDecimalZeros(_.get(getLocalStorage(WANT_AD_KEY), "sqFtMin", null));
  const SFMax = removeDecimalZeros(_.get(formValues, "sqFtMax", null));
  const SFMaxinSession = removeDecimalZeros(_.get(getLocalStorage(WANT_AD_KEY), "sqFtMax", null));
  const garageSpace = _.get(formValues, "grgSpce", null);
  const garageSpaceInSession = _.get(getLocalStorage(WANT_AD_KEY), "grgSpce", null);
  const mustHaveErrors = _.get(formValues, "wantAdValErrors", null);
  const errorUpdatedAt = _.get(formValues, "updatedAt", null);
  const stories = _.get(formValues, "stories", null);
  const storiesInSession = _.get(getLocalStorage(WANT_AD_KEY), "stories", null);
  const alwLrgDogs = _.get(formValues, "alwLrgDogs", false);
  const alwLrgDogsInSession = _.get(getLocalStorage(WANT_AD_KEY), "alwLrgDogs", false);
  const alwSmlDogs = _.get(formValues, "alwSmlDogs", false);
  const alwSmlDogsInSession = _.get(getLocalStorage(WANT_AD_KEY), "alwSmlDogs",false);
  const alwCats = _.get(formValues, "alwCats", false);
  const alwCatsInSession = _.get(getLocalStorage(WANT_AD_KEY), "alwCats", false);
  const adaAccess = _.get(formValues, "adaAccess", false);
  const adaAccessInSession = _.get(getLocalStorage(WANT_AD_KEY), "adaAccess", false);

  const [gSpace, setGSpace] = useState("");

  useEffect(() => {
    // handleContinueButton();
    clearGarrageLocalState();
  }, []);
  
  useEffect(() => {
    
    if(isEdit){
      let isDisable = true; 
      if(noOfBedrooms != noOfBedroomsinSession){
        isDisable = false;
      }
      if(noOfBathrooms != noOfBathroomsinSession){   
        isDisable = false;
      }
      if(SFMin != SFMinInSession){
        isDisable = false;
      }
      if(SFMax !=SFMaxinSession){
        isDisable = false;
      }
      if(garageSpace != garageSpaceInSession){   
        isDisable = false;
      }

      if(stories != storiesInSession){
        isDisable = false;
      }
      if(adaAccess != adaAccessInSession){
        isDisable = false
      }      
      if(alwCats != alwCatsInSession){
        isDisable = false
      }
      if(alwLrgDogs != alwLrgDogsInSession){
        isDisable = false
      }
      if(alwSmlDogs != alwSmlDogsInSession){
        isDisable = false
      }
      console.log(isDisable)
      setDisableSave(isDisable);
    }
    else{
      if(noOfBedrooms && noOfBathrooms){
          handleContinueButton();
      }
    }
  }, [noOfBedrooms, noOfBathrooms, SFMin, SFMax, garageSpace, stories, adaAccess, alwCats, alwLrgDogs, alwSmlDogs]);

  const handleContinueButton = () => {
    clearGarrageLocalState();
    let isDisable = false;

    if (noOfBedrooms && noOfBathrooms) {
      setDisableSave(isDisable);
    } else {
      isDisable = true;
      setDisableSave(isDisable);
    }
  };

  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
  };

  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    setGSpace(value);
    handleFormValues({ grgSpce: value });
  };

  const handleStorySelection = (selectedStory) => {
    if (selectedStory == stories) {
      handleFormValues({ stories: "" });
    } else {
      handleFormValues({ stories: selectedStory });
    }
  };

  const clearGarrageLocalState = () => {
    setGSpace("");
  };

  const handleCheckBoxItemClick = (e) => {
    clearGarrageLocalState();
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ alwLrgDogs: e.target.checked });
        break;
      case 2:
        handleFormValues({ alwSmlDogs: e.target.checked });
        break;
      case 3:
        handleFormValues({ alwCats: e.target.checked });
        break;
      case 4:
        handleFormValues({ adaAccess: e.target.checked });
        break;
    }
  };

  const loadCheckBoxItems = () => {
    const itemArray = [
      { id: 1, text: "Allows large dogs" },
      { id: 2, text: "Allows small dogs" },
      { id: 3, text: "Allows cats" },
      { id: 4, text: "ADA Accessible" },
    ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 1:
          isChecked = alwLrgDogs;
          break;
        case 2:
          isChecked = alwSmlDogs;
          break;
        case 3:
          isChecked = alwCats;
          break;
        case 4:
          isChecked = adaAccess;
          break;
      }
      return (
        <div className="row">
          <div className="col-12">
            <a
              href={void 0}
              className="createAd-content-wrap  formCheckBoxwrap mb-3"
            >
              <label className="form-check-label checkbox-label w-100" key={id}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="chkbox"
                  id={id}
                  checked={isChecked}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span className=""> {text}</span>
              </label>
            </a>
          </div>
        </div>
      );
    });

    return List;
  };

  return (
    <>
      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-10">
          <h4>Bedrooms *</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBedrooms}
            items={["Any", "1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBedRoomClick(e)}
          />
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4>Bathrooms *</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBathrooms}
            items={["Any", "1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBathRoomClick(e)}
          />
        </div>
      </div>

      <WantAdSquareFeet
        handleFormValues={handleFormValues}
        SFMax={SFMax}
        SFMin={SFMin}
        sqFtError={mustHaveErrors}
        errorUpdatedAt={errorUpdatedAt}
      />

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4> Garage Spaces</h4>
          <MustHaveBtnGroupMob
            section="Garage"
            value={garageSpace}
            currentSelectedValue={gSpace}
            items={["Any", "1+", "2+", "3+"]}
            onClick={(e) => handleGarageClick(e)}
            handleFormValues={handleFormValues}
          />
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Stories</h4>
          <div className="row">
            <div className="col-6">
              <a
                href={void 0}
                className={
                  stories === STORY_ANY
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleStorySelection(STORY_ANY);
                }}
              >
                {" "}
                Any{" "}
              </a>
            </div>
            <div className="col-6">
              <a
                href={void 0}
                className={
                  stories === STORY_SINGLE
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleStorySelection(STORY_SINGLE);
                }}
              >
                {" "}
                Single Story Only{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4>Additional Requirements</h4>
          {loadCheckBoxItems()}
        </div>
      </div>
    </>
  );
};

export default MustHavesPropertiesLTR;
