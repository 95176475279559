import { BUY_STRENGTHEN, LTR_STRENGTHEN } from "../../constants/common";

import WantAdBudget from "./WantAdBudget";
import WantAdPropertyPurchase from "./WantAdPropertyPurchase";
import WantAdPurchaseFinance from "./WantAdPurchaseFinance";
import WantAdRentCreditScore from "./WantAdRentCreditScore";

const WantAdMemberStrengthen = (props) => {
  const {
    step,
    handleContinueButtonState,
    handleFormValues,
    formValues,
    handleContinueClick,
  } = props;
  const adType = _.get(formValues, "adType", null);
  switch (step) {
    case 1:
      if (adType === BUY_STRENGTHEN) {
        return (
          <WantAdPropertyPurchase
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            propertyPurchase={_.get(formValues, "purchaseType", null)}
            is1031Exchange={_.get(formValues, "is1031Exchange", null)}
          />
        );
      } else if (adType === LTR_STRENGTHEN) {
        return (
          <WantAdRentCreditScore
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            creditScore={_.get(formValues, "creditScore", null)}
          />
        );
      }

    case 2:
      const financeType = _.get(formValues, "pFinance", null);
      const isAdjustBdget = _.get(formValues, "isAdjustBdget", null);
      if (financeType === "2" && isAdjustBdget) {
        return (
          <WantAdBudget
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            budgetMin={_.get(formValues, "minBdgt", null)}
            budgetMax={_.get(formValues, "maxBdgt", null)}
            adType={_.get(formValues, "adType", "")}
            budgetErrors={_.get(formValues, "wantAdValErrors", null)}
            errorUpdatedAt={_.get(formValues, "updatedAt", null)}
            isAdjustBudget={true}
          />
        );
      } else {
        return (
          <WantAdPurchaseFinance
            handleContinueButtonState={handleContinueButtonState}
            handleFormValues={handleFormValues}
            timeLine={_.get(formValues, "timeLine", null)}
            financeType={_.get(formValues, "pFinance", null)}
            isPreApprLoan={_.get(formValues, "isPreApprLoan", null)}
            handleContinueClick={handleContinueClick}
          />
        );
      }
  }
};
export default WantAdMemberStrengthen;
