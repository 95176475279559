import React from "react";

const searchCloseIcn = (props) => {
  const {handleCloseClick} = props
  return (
    <span onClick={()=>handleCloseClick()}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z"
        fill="#636569"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9419 7.05806C13.186 7.30214 13.186 7.69786 12.9419 7.94194L7.94194 12.9419C7.69786 13.186 7.30214 13.186 7.05806 12.9419C6.81398 12.6979 6.81398 12.3021 7.05806 12.0581L12.0581 7.05806C12.3021 6.81398 12.6979 6.81398 12.9419 7.05806Z"
        fill="#636569"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.05806 7.05806C7.30214 6.81398 7.69786 6.81398 7.94194 7.05806L12.9419 12.0581C13.186 12.3021 13.186 12.6979 12.9419 12.9419C12.6979 13.186 12.3021 13.186 12.0581 12.9419L7.05806 7.94194C6.81398 7.69786 6.81398 7.30214 7.05806 7.05806Z"
        fill="#636569"
      />
    </svg>
    </span>
  );
};

export default searchCloseIcn;
