/**
 * @file   src\components\Common\AgentClientSelection\ClientSelection.js
 * @brief  This file is responsible for client selection for agents
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import searchIccon from "../../../images/search-icon.svg";
import { getAgentsList } from "../../../services/wantAdService";
import { renameKey, combineKey, setLocalStorage, getLocalStorage } from "../../../helpers/common";
import { saveAgentClient } from "../../../services/agentClientsService";
import AddNewClientModal from "../../MemberWantAd/AddNewClientModal";
import { USER_INFO_KEY } from "../../../constants/common";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={searchIccon} />
        </components.DropdownIndicator>
    );
};

const ClientSelection = (props) => {
    const {
        clientId,
        isAgentAsSeller,
        sessionData,
        handleFormValues,
        isfromWantAdPage = false,
        clientSelectionError,
        isFilledByClient,
    } = props;

    const [clientSelectDisableStatus, setClientSelectDisableStatus] = useState(false);
    const [clientsList, setClientsList] = useState([]);

    const [showAddClient, setShowAddClient] = useState(false);
    const handleClose = () => setShowAddClient(false);
    const handleShow = () => setShowAddClient(true);
    

    useEffect(() => {
        getClientList(); // to load client list on page load
        isAgentAsSeller ? setClientSelectDisableStatus(true) : setClientSelectDisableStatus(false); // 
    }, []);


    /**
      * to set the selected option on load
      */
    const getSelectedClient = (clientId, mainList) => {
        if (clientId && mainList) {
            var selectedValue = mainList.filter(function (list) {
                if (list.value == clientId) {
                    return list;
                }
            })
            return selectedValue
        }
        return null;

    }

    /**
     * To handle change client
     * @param {*} e 
     */
    const handleChangeClient = (e) => {
        if (e.value == 0 || e.__isNew__) {
            handleShow()
        } else {
            handleFormValues({
                clientId: e.value,
                clientName: e.label,
                clientData: {
                    value: e.value,
                    label: e.label,
                },
                focusOut: true
            });

        }
    }

    /**
     * To handle agent checkbox selection
     * @param {*} e 
     */
    const handleCheckBox = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setClientSelectDisableStatus(true)
            handleFormValues({
                isAgentAsSeller: true,
                clientId: '',
                clientName: sessionData.userInfo.name,
                clientData: {
                    value: '',
                    label: '',
                },
                focusOut: true
            });


        } else {
            handleFormValues({ isAgentAsSeller: '',  focusOut: true });
            setClientSelectDisableStatus(false);
        }

    }

    /**
     * To fetch clients list
     */
    const getClientList = async () => {
        let query = {
            agentId: sessionData.userInfo.userId
        }
        await getAgentsList()
            .then((response) => {
                const responseResult = _.get(response, "result", null);
                if (response.errorCode === 0 && responseResult) {
                    responseResult.forEach((obj) => renameKey(obj, "ClientId", "value"))
                    responseResult.forEach((obj) => combineKey(obj, "FirstName", "LastName", 'label'))
                    setClientsList(responseResult)
                } else {
                    console.log("Something went wrong");
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    /**
     * To handle add new client 
     * @param {*} data 
     */
    const addClientData = async (data) => {
        var param = {
            ...data,
        }
        await saveAgentClient(param)
            .then((response) => {
                if (response.errorCode === 0) {
                    getClientList()
                    let value = {
                        label: data.firstName + ' ' + data.lastName,
                        value: response.result.clientId
                    }
                    handleFormValues({
                        clientData: value,
                        clientName: data.firstName + ' ' + data.lastName,
                        clientId: response.result.clientId
                    })
                    const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["ClientCount"] =  userInfo.userInfo?.ClientCount ? userInfo.userInfo?.ClientCount + 1 : 1;
          setLocalStorage(USER_INFO_KEY, userInfo); 
                    handleClose();
                } else {
                    return;
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });

    }

    return (
        <>
            <div className={clientSelectionError ? "wantad-location-wrap select-type-wrap red-marked-wrap" : "wantad-location-wrap select-type-wrap" }>

                <h6 className={clientSelectionError  && "red-marked-heading" }>Client <span>*</span></h6>
                <CreatableSelect
                    components={{ DropdownIndicator }}
                    id="multi1"
                    name="colors1"
                    value={getSelectedClient(clientId, clientsList)}
                    options={(clientsList)}
                    className="basic-multi-select mb-4"
                    classNamePrefix="select"
                    placeholder="Client name"
                    onChange={(e) => { handleChangeClient(e) }}
                    isDisabled={clientSelectDisableStatus || isFilledByClient}
                    formatCreateLabel={(_) =>
                        <div className="">
                            <span className="clientsearch-icon-wrap mr-6">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z" fill="#15181E" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 10C6.25 9.65482 6.52982 9.375 6.875 9.375H13.125C13.4702 9.375 13.75 9.65482 13.75 10C13.75 10.3452 13.4702 10.625 13.125 10.625H6.875C6.52982 10.625 6.25 10.3452 6.25 10Z" fill="#15181E" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V13.125C10.625 13.4702 10.3452 13.75 10 13.75C9.65482 13.75 9.375 13.4702 9.375 13.125V6.875C9.375 6.52982 9.65482 6.25 10 6.25Z" fill="#15181E" />
                                </svg>
                            </span>
                            Create new client
                        </div>}
                    createOptionPosition="first"
                    isValidNewOption={(_) => true}
                    async
                />

                {!isFilledByClient && <div className="field-wrap mt-0 mb-0">
                    <label
                        className="form-check-label checkbox-label"
                        htmlFor="IAmRepresenting">
                        <input
                            checked={isAgentAsSeller}
                            className="form-check-input"
                            type="checkbox"
                            id="IAmRepresenting"
                            onChange={(e) => { handleCheckBox(e) }}
                        />
                        I'm representing myself as the {isfromWantAdPage ? 'buyer' : 'seller'}
                    </label>
                </div>}
            </div>

            <AddNewClientModal show={showAddClient}
                onHide={handleClose}
                addClient={addClientData} />
        </>
    );
};
export default ClientSelection;
