/**
 * @file   Source/gitcha_Web/src/mobile/components/GitchaHeaderTabMob.js
 * @brief  This file is responsible for handling Tabs switching between Genral and Match view in mobile view
 * @date   April, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect, useLayoutEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import GitchaMobileFilter from "./GitchaMobileFilter";
import GitchaHeaderRightTabContent from "../components/GitchaHeaderRightTabContent";
import {
  getLocalStorage,
  getUserId,
  removeLocalStorage,
  saveTutorialStatusToLocal,
  setLocalStorage,
} from "../../helpers/common";
import JoyRide, { STATUS } from "react-joyride";
import DocumentationTutorial from "../../components/Tutorial/DocumentationTutorial";
import { saveTutorialStatus } from "../../services/commonServices";
import { useLocation } from "react-router-dom";
const GitchaHeaderTabMob = (props) => {
  const [key, setKey] = useState(null);
  const userId = getUserId();

  const {
    updateFilter,
    filterQuery,
    clearFilterQuery,
    updateFilterGitchaGenie,
    handleFilterApply,
    handleGitchGenieFilter,
    setSearchView,
    isSearchView,
    showFilterPopup,
    handleFilterPopUpClose,
    handleFilterPopUpShow,
    setUserLocation,
    userLocation,
    gitchaNotAvailble,
    searchedLocation,
    setFilterQueryForApply,
    filterQueryForApply,
    divRef,
    setHeight,
    setAdType,
    showNotificationIcon,
    setNotificationIcon,
    setShowNoPropertyState,
    handleStayRequestPopupOpen,
    handleGitchaNotAvailableLocation,
  } = props;
  const tutorialPage = 1;
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (path === "/mobile/match") {//handle navigation to match from notification
      setKey("match");
      setLocalStorage("FilterTabKey", "match");
      removeLocalStorage("selectedPropertyId");
      removeLocalStorage("MatchSelectedClient");
    } else if (getLocalStorage("FilterTabKey")) {      
      setKey(getLocalStorage("FilterTabKey"));
    } else {
      setKey("General");
    }
  }, [getLocalStorage("FilterTabKey")]);

  useEffect(() => {
    if (key === "match") {
      handleGitchGenieFilter(true);
    } else {
      handleGitchGenieFilter(false);
    }
  }, [key]);

  useLayoutEffect(() => {
    if (divRef.current && key != "match") {
      setHeight(divRef.current.clientHeight);
    }
  }, [key]);
// To show match tutorial
  const [tutorialSteps, setTutorialSteps] = useState([
    {
      target: ".match-tutorial",
      disableBeacon: true,
      placement: "left",
      title: "You've got a new match ⚡️",
      content:
        "It looks like people are looking for properties that are similar to yours. See which want ads you're matching to.",

      hideCloseButton: true,
      showProgress: true,
    },
  ]);
  //Tutorial tooltip
  const TutorialToolTip = ({
    index,
    step,
    backProps,
    primaryProps,
    tooltipProps,
    size,
    isLastStep,
    skipProps,
  }) => (
    <>
      <div {...tooltipProps}>
        <DocumentationTutorial
          index={index}
          step={step}
          backProps={backProps}
          skipProps={skipProps}
          primaryProps={primaryProps}
          isLastStep={isLastStep}
          size={size}
          hideCounter={true}
        />
      </div>
    </>
  );
  //Tutorial status call back
  const handleJoyrideCallback = async (data) => {
    const { status, lifecycle } = data;
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle == "complete"
    ) {
      saveTutorialStatusToLocal(tutorialPage);
      await saveTutorialStatus(tutorialPage, 1); //Save status
      setNotificationIcon(false);
    }
  };

  return (
    <>
      {showNotificationIcon && key == "match" ? (
        <JoyRide
          steps={tutorialSteps}
          tooltipComponent={TutorialToolTip}
          continuous
          callback={handleJoyrideCallback}
          disableScrolling={true}
        />
      ) : (
        ""
      )}
      <Tabs
        className={
          isSearchView
            ? "adfeed-navtab navtab-fix me-3 ms-3 mb-3 d-none"
            : "adfeed-navtab navtab-fix me-3 ms-3 mb-3"
        }
        id="controlled-tab-example3"
        activeKey={key}
        onSelect={(k) => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          setKey(k);
          setLocalStorage("FilterTabKey", k);
        }}
      >
        <Tab eventKey="General" title="General" className="position-relative">
          <GitchaMobileFilter
            filterQuery={filterQuery}
            updateFilter={updateFilter}
            clearFilterQuery={clearFilterQuery}
            handleFilterApply={handleFilterApply}
            setSearchView={setSearchView}
            isSearchView={isSearchView}
            showFilterPopup={showFilterPopup}
            handleFilterPopUpClose={handleFilterPopUpClose}
            handleFilterPopUpShow={handleFilterPopUpShow}
            setUserLocation={setUserLocation}
            userLocation={userLocation}
            gitchaNotAvailble={gitchaNotAvailble}
            searchedLocation={searchedLocation}
            setFilterQueryForApply={setFilterQueryForApply}
            filterQueryForApply={filterQueryForApply}
            setAdType={setAdType}
            handleStayRequestPopupOpen={handleStayRequestPopupOpen}
            handleGitchaNotAvailableLocation={handleGitchaNotAvailableLocation}
          />
        </Tab>

        <Tab
          eventKey="match"
          title="Match"
          className="position-relative"
          disabled={userId == null}
        >
          <GitchaHeaderRightTabContent
            key={key}
            setHeight={setHeight}
            divRef={divRef}
            updateFilterGitchaGenie={updateFilterGitchaGenie}
            setShowNoPropertyState={setShowNoPropertyState}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default GitchaHeaderTabMob;
