import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

const TopRightBtn = (props) => {
  const {exitButtonText, handleSaveAndExit, step, exitButtonDisable, isFinalStep, hideContinueButton, handleContinueClick, continueButtonText, isDisabled} = props
  return (
     <div className="align-self-end">
      {!exitButtonDisable && !isFinalStep &&
      <Button variant="primary" className="btn-grey" onClick={()=>handleSaveAndExit(step)}>
      {exitButtonText}
    </Button>}
    {!hideContinueButton && isFinalStep &&  <Button
          variant="primary"
          className="ms-0 me-0"
          disabled={isDisabled}
          onClick={() => handleContinueClick(step)}
        >
          {continueButtonText || "Continue"}
        </Button>}
    </div>
  );
};
export default TopRightBtn;
