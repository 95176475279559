import React from 'react';
import RadioButton from "./RadioButton";
import { convertToNumber } from '../../helpers/validations';

const RadioButtonList = (props) => {
  const {list, name, seletedValue, onClick} = props
  const List =
  list &&
  list.map((lst) => {
     const value = convertToNumber(_.get(lst, "value", ""));
     const text = _.get(lst, "text", "");
     
     return (
       <div className="col-md-6" key={`${name}_${value}`}>
         <label className="radio-wrap mb-3">
           <RadioButton
             name={name}
             value={value}
             checked={value === seletedValue}
             onChange={()=>{}}
             onClick={(e) => onClick(e)}
           />
           <span className="red-marked-span">
             <div className="timing-wrap-info">
               <h2 className="pb-0">{text}</h2>
             </div>
           </span>
         </label>
       </div>
     );
   });
   return List
}
export default RadioButtonList;
