import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

const Sortoffcanvas = (props) => {
  const {show, onHide, handleSortParam,options, sortParam} = props;

  return (

    <Offcanvas
     className="mobile-offcanvas sort-filter-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}>
    <Offcanvas.Body>
      <div className="offcanvas-sort-wrap">
        <div className="sort-heading">Sort</div>
        <div class="modal-choose-property mb-0">
        {
                options.map((item, index) => {
                  return (
                    <label class="property-radio" key={index}>
                      <input type="radio" name="check-box-option" id={item?.value} value={item?.value}
                        onClick={() => { handleSortParam(item) }}
                        checked={sortParam?.value == item?.value}
                      />
                      <span className="heightWrap">
                        <div class="modal-wrap-info">
                          <h2 class="pb-0">{item?.label}</h2>
                        </div>
                      </span>
                    </label>
                  )

                })
              }
        </div>
      </div>
    </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default Sortoffcanvas;
