import React from "react";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { convertToNumber } from "../../helpers/validations";
import { usePrevious } from "../../hooks/usePrevious";

const MustHaveBtnGroupMob = (props) => {
  const { variant, items, value, onClick, handleFormValues, section } = props;
  const previousValue = usePrevious(value);
  const [isDeselectItem, setDeSelect] = useState(false); // for deselect an item

  useEffect(() => {
    // This useeffet for clear the state value for garragespace(buy) and beds(short-term-rent) in want ad must haves when deselecting item.
    if (previousValue && previousValue === value) {
      if (section === "Garage") {
        handleFormValues({ grgSpce: "" });
      } else if (section === "Beds") {
        handleFormValues({ noOfBeds: "" });
      }
    }
  }, [isDeselectItem]);

  const loadButtons = () => {
    const list =
      items &&
      items.map((itm, index) => {
        const itemValue =
          itm !== "Any" ? convertToNumber(itm.replace("+", "")) : itm;
        const selectedValue =
          value && value !== "Any" ? convertToNumber(value) : value;

        if (section === "Garage" || section === "Beds") {
          return (
            <Button
              className={
                selectedValue === itemValue
                  ? "btn btn-primary active"
                  : "btn btn-primary"
              }
              key={index}
              value={itemValue}
              onClick={() => setDeSelect(!isDeselectItem)} // for manage deselecting an item
              // variant={
              //   selectedValue === itemValue ? variant + " active" : variant
              // }
            >
              {itm}
            </Button>
          );
        } else {
          return (
            <Button
              key={index}
              value={itemValue}
              className={
                selectedValue === itemValue
                  ? "btn btn-primary active"
                  : "btn btn-primary"
              }
              // variant={
              //   selectedValue === itemValue ? variant + " active" : variant
              // }
            >
              {itm}
            </Button>
          );
        }
      });
    return list;
  };

  return (
    <div
      className="btn-group marginBtn"
      role="group"
      aria-label="Basic example-btngp"
      onClick={onClick}
    >
      {loadButtons()}
    </div>
  );
};

export default MustHaveBtnGroupMob;
