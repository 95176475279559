import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
// import MenuBottomBar from "../components/menuBottomMobile";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import LakeViewImage from "../images/lake-view-icon.svg";
import ConsumerIconImage from "../images/consumer-icon.svg";
import PrResIconImage from "../images/primary-residence-icon.svg";
import FinanceIconImage from "../images/financing-icon.svg";
import GitchaLogoMobile from "../mobile/images/gitchaLogomobile.svg";
import MinCapIcon from "../images/minimum-icn.svg";
import { useRef } from "react";
import { getWantAdDetailsById } from "../services/wantAdService";
import { pageURLs } from "../constants/pageURLs";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  WANT_AD_KEY,
  IS_LAND,
  IS_MULTI_FAMILY,
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
  USER_TYPE_AGENT,
  USER_INFO_KEY,
} from "../constants/common";
import base64 from "base-64";
import utf8 from "utf8";
import {
  getPropertyIcon,
  getTooltipText,
  getCombinedText,
  getDescriptionText,
} from "../helpers/getPropertyIcons";
import {
  buildWantAdMustHavesHTML,
} from "../helpers/WantAdDetails";
import {
  loadTimeLineHtml,
  getLocalStorage,
  getUserId,
  getUserType,
  loadPreferredLocationHtml,
  isIphone,
  loadCompensationText,
  locationDisplay,
} from "../helpers/common";

import { usePrevious } from "../hooks/usePrevious";
import _ from "lodash";
import { useSelector } from "react-redux";
import { loadInvestmentCriteria } from "../helpers/createWantAd";

const ShowWantAdMobile = (props) => {
  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
    document.body.classList.add("bg-modal-fix");
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const wantAd = getLocalStorage(WANT_AD_KEY);
  const [searchParams] = useSearchParams();
  searchParams.get("wantAdId");
  const id = searchParams.get("wantAdId");
  const userType = getUserType();
  let decodedId;
  let idFromURL;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
    if (decodedId) {
      let decodedData = decodedId.split("-");
      idFromURL = decodedData.length > 0 ? decodedData[1] : null;
    }
  } catch { }
  // const isFromHomeFeed =
  //   location.state && location?.state["isFromHomeFeed"] ? true : false;
  searchParams.get("wantAdId");
  const [skeltonLoad, setSkeltonLoad] = useState(true); //
  const isFromHomeFeed = idFromURL
    ? true
    : _.get(wantAd, "isFromHomeFeed", false);



  const wantAdId = idFromURL ? idFromURL : _.get(wantAd, "wantAdId", null);
  const [adDetails, setAdDetails] = useState();
  const adType = _.get(adDetails, "adType", "");
  const propertyType = Number(_.get(adDetails, "prprtyType", ""));

  const adTypeBasedOnProperty = propertyType === 6 ? LOT_LAND : adType;
  const isAdTypeIsLand = adTypeBasedOnProperty === LOT_LAND;
  const nice2Have = _.get(adDetails, "nice2Have", []);
  const mapImage = _.get(adDetails, "mapImg", null);
  const consumerType = adType == BUY ? "Buyer" : "Renter";
  const propertySubTypes = _.get(adDetails, "prptySubTypes", []);
  const WantCreatedUserId = _.get(adDetails, "userId", false);
  const loggedInUserId = getUserId();
  const pageTopRef = useRef(null);
  const scrollDivRef = useRef(null);

  const [isPremiumUser, setIsPremiumUser] = useState(null);
  const userDetailsFromStore = useSelector((state) => state.userProfile.userDetails)

  const userDetails = getLocalStorage(USER_INFO_KEY);
  let userName = _.get(userDetails, "userInfo.name", null);
  if (userName) {
    userName = userName.replace(/-/g, "").match(/\b(\w)/g);
  }
  let profileImage = _.get(userDetails, "userInfo.image", null);

  useEffect(() => {
    getWantAdDetails(wantAdId);
  }, []);

  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdDetailsById(wantAdId, loggedInUserId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setSkeltonLoad(false);
            setAdDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      // navigate(pageURLs.createwantad);
      navigate(pageURLs.mobileHome);
    }
  };

  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }
    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );
  };

  const loadStrengthenDetails = () => {
    if (adType === BUY) {
      return (
        <>
          {" "}
          {(_.get(adDetails, "prchTypeTxt", "") ||
            _.get(adDetails, "purchaseText", "")) && (
              <li>
                <span className="list-icn-wrap-mob">
                  <img src={PrResIconImage} alt={PrResIconImage} />
                </span>
                {_.get(adDetails, "prchTypeTxt", "") ||
                  _.get(adDetails, "purchaseText", "")}
                {(_.get(adDetails, "is1031Exchange", "") == "Yes" || _.get(adDetails, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
              </li>
            )}
          {(_.get(adDetails, "finTypeTxt", "") ||
            _.get(adDetails, "pFinanceText", "")) && (
              <li>
                <span className="list-icn-wrap-mob">
                  <img src={FinanceIconImage} alt={FinanceIconImage} />
                </span>
                Financing:{" "}
                {_.get(adDetails, "finTypeTxt", "") ||
                  _.get(adDetails, "pFinanceText", "")}
                {(_.get(adDetails, "isPreApprLoan", "") == "Yes" ||
                  _.get(adDetails, "isPreApprLoan", "") == 1) &&
                  " - pre approved"}
              </li>
            )}
        </>
      );
    } else if (adType === LONG_RENT) {
      return (
        <>
          {" "}
          {_.get(adDetails, "creditScoreText", "") && (
            <li>
              <span className="list-icn-wrap-mob">
                <img src={FinanceIconImage} alt={FinanceIconImage} />
              </span>
              Credit Score : {_.get(adDetails, "creditScoreText", "")}
            </li>
          )}
        </>
      );
    }
  };

  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(adDetails, "isMobileView");
  };

  const loadTimeLine = () => {
    return loadTimeLineHtml(
      adType,
      adTypeBasedOnProperty,
      adDetails,
      "isMobileView"
    );
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isAdTypeIsLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };
  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <>
            <div className="property-tag-mobile mb-2" key={id + uniqueType}>
              <div class="tag-icon-mobile">
                <img
                  src={icon ? require("../images/" + icon) : LakeViewImage}
                  alt={icon ? require("../images/" + icon) : LakeViewImage}
                />
              </div>
              <span>{itemText}</span>
            </div>
          </>
        );
      })
    );
  };

  const isNiceToHaveExist = nice2Have.length > 0;

  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {adType === BUY
        ? getTooltipText(propertyType)
        : propertySubTypes &&
        propertySubTypes.length > 0 &&
        getCombinedText(propertySubTypes)}
    </Tooltip>
  );

  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      formValues: adDetails,
    });
  };

  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix-new");
  }, []);

  let noBathRoom = _.get(adDetails, "noBathRm", "");
  let noBedRoom = _.get(adDetails, "noBedRm", "");
  if (noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
  if (noBedRoom !== "Any") noBedRoom = noBedRoom + "+";

  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const prevScrollPosition = usePrevious(scrollPosition);

  // const handleScroll = () => {
  //   const el = scrollDivRef.current;
  //   const scrollHeight = el.scrollHeight;
  //   const scrollTop = el.scrollTop;
  //   const clientHeight = el.clientHeight;

  //   if (
  //     scrollHeight !== clientHeight &&
  //     scrollTop !== 0 &&
  //     Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
  //   ) {
  //     //setCurrPage((prevState) => prevState + 1);
  //   } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
  //     //setCurrPage(prevState => prevState -1);
  //   }

  //   if (scrollTop !== 0 && scrollHeight !== scrollTop + clientHeight) {
  //     setScrollPosition(scrollTop);
  //   }
  // };

  useEffect(() => {
    if (
      scrollPosition > prevScrollPosition &&
      scrollDirection !== SCROLL_DIRECTION_DOWN
    ) {
      setScrollDirection(SCROLL_DIRECTION_DOWN);
    } else if (
      scrollPosition < prevScrollPosition &&
      scrollDirection !== SCROLL_DIRECTION_UP
    ) {
      setScrollDirection(SCROLL_DIRECTION_UP);
    }
  }, [scrollPosition]);

  let mainWrapperClass = "gitcha-main-wrapper pb-0 bg-white";
  let contentWrapperClass = "content-wrapper h-100";
  let innerLayoutClass = "inner-layout-header-new bg-white";
  let bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix";

  let isEnableIntoduceButton = false;
  let isEnableIntoductions = false;
  let introduceBtnClass = "";
  if (
    isFromHomeFeed &&
    WantCreatedUserId &&
    loggedInUserId &&
    WantCreatedUserId != loggedInUserId
  ) {
    isEnableIntoduceButton = true;
    introduceBtnClass = "pb-90";
  } else {
    isEnableIntoduceButton = false;

    if (
      WantCreatedUserId &&
      WantCreatedUserId == loggedInUserId &&
      loggedInUserId
    ) {
      isEnableIntoductions = true;
      introduceBtnClass = "pb-90";
    } else {
      isEnableIntoductions = false;
      introduceBtnClass = "pb-0";
    }
  }

  if (scrollDirection === SCROLL_DIRECTION_DOWN) {
    mainWrapperClass = "gitcha-main-wrapper pb-0 bg-white";
    contentWrapperClass = isIphone()
      ? `content-wrapper content-wrapper-h-180 iphone-h-fix ${introduceBtnClass}`
      : `content-wrapper content-wrapper-h-180 android-h-fix ${introduceBtnClass}`;
    //contentWrapperClass = "content-wrapper pad-top-0 h-100"
    innerLayoutClass = "inner-layout-header-new bg-white hide-layout-header";
    bottomMenuClass =
      "inner-layout-footer-new inner-layout-footer-topfix hide-bottom-footer";
  } else if (scrollDirection === SCROLL_DIRECTION_UP) {
    mainWrapperClass = "gitcha-main-wrapper pb-180 bg-white";
    contentWrapperClass = "content-wrapper content-wrapper-h-180";
    innerLayoutClass = "inner-layout-header-new bg-white";
    bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix";
  }

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if (userDetailsFromStore[0]?.period !== 1 && userType === 2) {
        setIsPremiumUser(true);
      }
      else {
        setIsPremiumUser(false);
      }
    }
  }, [userDetailsFromStore]);

  return (
    <>
      <span ref={pageTopRef}></span>
      <div className="gitcha-main-wrapper pb-0 bg-white">
        <div className="content-wrapper svh-100">
          <div className="inner-layout-header-new bg-white position-top-header">
            <div className="top-three-comp-header w-100 top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">

                <div className="p-0">
                  <a className="d-block brand-small-link" href={void 0}>
                    <img className="" src={GitchaLogoMobile} />
                    <div className="brand-small-tagline">{userType == USER_TYPE_AGENT ? <>The Buyer Listing Service<sup>TM</sup></> : "The “Want” Marketplace for Real Estate"}
                    </div>
                  </a>


                </div>
                <div class="toplinks-mobile text-end">
                  {userName ? (
                    (profileImage && userType ==
                      USER_TYPE_AGENT && isPremiumUser) ?
                      (
                        <>
                          <figure className="brdr-rs-200 profile-image-figure"
                            onClick={() => navigate(pageURLs.mobileSettings)}>
                            <img className="brdr-rs-200" src={profileImage} />
                          </figure>
                        </>
                      ) :
                      (
                        <div
                          className="profile-user-info profile-bg-meroon ms-auto"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(pageURLs.mobileSettings)}
                        >

                          {userName}
                        </div>
                      )


                  ) : (

                    <button variant="" className="btn signin-btn-small" onClick={() => { navigate(pageURLs.mobileSignIn) }}>Sign Up</button>
                  )}
                </div>
               
              </div>
            </div>
          </div>

          <div
            className="inner-layout-content-new h-100 bg-white"
          // onScroll={handleScroll}
          // ref={scrollDivRef}
          >
            <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white">
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    I'm looking to:{" "}
                    <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                      {" "}
                      {loadAdTitle()}
                    </span>
                  </div>
                  <h2>{_.get(adDetails, "adTitle", "")} </h2>
                  <span className="trailing-txt pb-1">
                    {locationDisplay(adDetails)}
                    {/* Within {_.get(adDetails, "mapRadius", "")} mi of{" "}
                    {_.get(adDetails, "city", "")}
                    {", "}
                    {_.get(adDetails, "state", "")} */}
                  </span>
                </div>
                <div className="property-details-mob f-regular mb-4">
                  {getDescriptionText(adDetails)}
                </div>
              </div>

              <div className="property-listing-ad-mob">
                <div className="property-type-wrap-mob top-pos-0 left-pos-0">
                  {/* <div className="property-tile w-auto"> */}
                  <OverlayTrigger
                    placement="bottom-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <div className="property-tile w-auto">
                      {propertyType === IS_LAND ||
                        propertyType === IS_MULTI_FAMILY ? (
                        <img
                          src={getPropertyIcon(propertyType)}
                          alt={getPropertyIcon(propertyType)}
                          className="property-icon-img"
                        />
                      ) : (
                        propertySubTypes &&
                        propertySubTypes.length > 0 &&
                        propertySubTypes.map((subtype) => {
                          return (
                            <img
                              src={getPropertyIcon(subtype)}
                              alt={getPropertyIcon(subtype)}
                              className="property-icon-img"
                            />
                          );
                        })
                      )}
                    </div>
                  </OverlayTrigger>
                  {/* </div> */}
                </div>
                <div className="property-img-wrapad-mob h-200">
                  <img
                    src={mapImage + "?" + _.get(adDetails, "lat", null)}
                    alt={mapImage}
                  />
                </div>
              </div>

              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h2>Must-haves</h2>
                <div className="row">{loadAdMustHaves()}</div>
              </div>
              {isNiceToHaveExist ? (
                <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                  <h2>Nice-to-haves</h2>
                  {finalNiceToHaveList()}
                </div>
              ) : (
                ""
              )}

              {_.get(adDetails, "adDesc", "") ? (
                <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                  <h2>Description</h2>
                  <p>{_.get(adDetails, "adDesc", "")}</p>
                </div>
              ) : (
                ""
              )}

              <div className="buyer-info-mob brdr-top-common-wrap pb-32">
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap-mob">
                      <img src={ConsumerIconImage} />
                    </span>
                    {_.get(adDetails, "isAgentCreated", "") == 1
                      ? " Represented by Agent"
                      : consumerType}
                  </li>       

                  {loadTimeLine()}
                  {loadPreferredLocation()}
                  {loadStrengthenDetails()}
                </ul>
              </div>

              {((adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&
                        _.get(adDetails, "isAgentCreated", "") == 1) && (_.get(adDetails, "compenationType", "")) && 
                        <div className="buyer-info-mob brdr-top-common-wrap pb-32">
                    <h2>Requested Concessions From Seller</h2>
                    <ul>                      
                      {_.get(adDetails, "compenationType", "") ?(
                          <li>
                            <span className="list-icn-wrap-mob">
                              <img src={FinanceIconImage} />
                            </span>
                            {loadCompensationText(parseInt(_.get(adDetails, "compenationType", "")),_.get(adDetails, "agentCompenation", ""))}                            
                          </li>
                        ) : ""}  
                        {_.get(adDetails, "otherConcessions", "") ?(
                          <li>
                            {_.get(adDetails, "otherConcessions", "")}                         
                          </li>
                        ) : ""}                    
                    </ul>
                  </div>}
                  {_.get(adDetails, "investmentId", [])?.length>0 || _.get(adDetails, "capRateId", null) ? 
                  <div className="buyer-info mt-0 pt-0">
                    <h2>What This Investor is Open to</h2>
                    <ul>
                      {loadInvestmentCriteria(adDetails)}
                      {_.get(adDetails, "capRateId", "") &&
                        <li>
                          <span className="list-icn-wrap">
                            <img src={MinCapIcon} />
                          </span>
                          Minimum cap rate of {_.get(adDetails, "capRateId", "")==15 ? "15+" : _.get(adDetails, "capRateId", "")}%
                        </li>
                      }
                    </ul>
                  </div> : <></>
                }

              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>

        {/* {scrollDirection !== SCROLL_DIRECTION_DOWN && (
          <div className="inner-layout-footer-new inner-layout-footer-topfix">
            <div className="bottomMenu bottom-menu-index">
              <MenuBottomBar />
            </div>;
          </div>
        )} */}
      </div>
    </>
  );
};

export default ShowWantAdMobile;
