import React, { useState, useEffect } from "react";
import InventoryLeftSection from "../components/Inventory/CreateInventory/InventoryLeftSection";
import InventoryRightSection from "../components/Inventory/CreateInventory/InventoryRightSection";
import { DESC_FOR_PROPERTY_DETAILS_LOADING, TITLE_FOR_PROPERTY_DETAILS_LOADING, inventoryPageContents, yrMaxInValid } from "../constants/createInventory";
import {
  saveMemberInventoryData,
  getInventoryById,
} from "../services/inventoryService";
import { pageURLs } from "../constants/pageURLs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_MAP_ZOOM,
  INVENTORY_KEY,
  IS_LAND,
  LONG_RENT,
  SELL,
  SHORT_RENT,
  USER_INFO_KEY,
} from "../constants/common";
import { convertToNumber } from "../helpers/validations";
import {
  checkTutorialDisplayStatus,
  clearLocalStorage,
  createPreSignedUrl,
  createStaticMapImage,
  getLocalStorage,
  getUserId,
  loggedInUser,
  removeLocalStorage,
  setLocalStorage,
  yearValidation,
} from "../helpers/common";
import { userLogout } from "../reducers/login";
import { useDispatch , useSelector} from "react-redux";
import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";
import { yrMinInValid } from "../constants/createWantAd";
import { fetchUserDetails, fetchUserStatistics, fetchUserPrivileges } from "../actions/userprofile";
import BasicPlanModal from "../components/PlanUpgrade/BasicPlanModal";

const CreateInventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const [activeStep, setActiveStep] = useState(1);
  const [draftSelectedItem, setDraftSelectedItem] = useState(null);
  let adAmenities = null;
  let isDisabled = true;
  const sessionData = loggedInUser();
  const [showBasicPlanModal, setBasicPlanModal] = useState(false);
  const BasicPlanModalClose = () => setBasicPlanModal(false);
  const BasicPlanModalShow = () => setBasicPlanModal(true);

  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const userStatictics = useSelector(
    (state) => state?.userProfile?.userStatictics
  );
  const userPrivileges = useSelector(
    (state) => state?.userProfile?.userPrivileges
  );
  const [userCount, setUserCount] = useState({});
  const [allowedCount, setAllowedCount] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [reload, setReload] = useState(0);

  const isFromIntroduction = location.state && location.state['isFromIntroduction'];
  const wantAdId = location.state && location.state['wantAdId'];

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[0]);
    } else {
      setUserDetails(null);
    }
  }, [userDetailsFromStore]);

  
  useEffect(() => {
    const payload = {
      PlanId: userDetails?.PlanId,
    };
    dispatch(fetchUserPrivileges(payload));
  }, [userDetails?.PlanId]);

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);

  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  // to list my inventory

  useEffect(() => {
    if (_.get(sessionData, "userInfo.userId", "")) {
      dispatch(
        fetchUserDetails()
      );
    }
  }, [reload]);

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id,
    };

    dispatch(fetchUserStatistics(payload));
  }, [userDetails]);

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  useEffect(()=>{
    if (userCount?.Inventory >= allowedCount?.Inventory) {
        BasicPlanModalShow();    
    } 
  },[userCount, allowedCount])

  useEffect(() => { //Need to show inventory onboard very first time
    let DisplayInventoryAddOnboard = checkTutorialDisplayStatus(7); //Inventory onboard display
    if(DisplayInventoryAddOnboard) {
      navigate(pageURLs.gitchaInvetoryOnBoard,  { state: { isFromIntroduction: true,  wantAdId: wantAdId } })
    }
  }, []); 

  useEffect(() => {
    adAmenities = getLocalStorage(INVENTORY_KEY);
    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), 'userInfo', null ) ;
    if (!userInfo) {
      handleStateClear()
    }
    const currentActiveStep = _.get(adAmenities, "actvStep", "");
    const cmpldPerctg = _.get(adAmenities, "cmpldPerctg", "");
    if (currentActiveStep) {
      setActiveStep(currentActiveStep);
      setCurrentCompletedPercentage(cmpldPerctg);
      setFormValues(adAmenities);
    } else {
      // adAmenities = _.get(wantAdAmenities, "memberBuy", "");
      //setActiveStep(1);
    }
  }, []);
  // to set is edit false
  useEffect(() => {
    // if (activeStep == 9) {
    //   handleFormValues({
    //     isEdit: false,
    //   });
    // }
    let statesToBeUpdated = { actvStep: activeStep}
    if (activeStep == 9) {
      statesToBeUpdated = {
        isEdit: false,
        actvStep: activeStep
      }
    }
    handleFormValues(statesToBeUpdated)
  }, [activeStep]);

  //adAmenities = _.get(wantAdAmenities, "memberBuy", "");
  const [continueButtonState, setContinueButtonState] = useState(isDisabled);
  const [formValues, setFormValues] = useState(adAmenities);
  const [showConfirmStatuModal, setConfirmStatus] = useState(false);
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const propertyPurpose = _.get(formValues, "proprtyPurpose", []);
  const pageContents = _.get(inventoryPageContents, `step_${activeStep}`, "");

  const {
    title,
    description,
    skipButtonEnable,
    exitButtonText,
    backButtonEnable,
    completedPercentage,
    continueButtonText,
    hideContinueButton
  } = pageContents;
  const [currentCompletedPercentage, setCurrentCompletedPercentage] =
    useState(completedPercentage);
   
  let newTitle = title;
  let newDescription = description;
  if(activeStep === 3  && _.get(formValues, 'isPropertyExist', false)){ // For show title/desc if property details loading in map.
     newTitle = TITLE_FOR_PROPERTY_DETAILS_LOADING;
     newDescription = DESC_FOR_PROPERTY_DETAILS_LOADING;
  } else{
    if (propertyPurpose) {
      if (propertyPurpose.length === 1) {
        if (_.includes(propertyPurpose, SELL)) {
          newTitle = newTitle.replace(
            "[TITLE]",
            "How much would you sell your property for?"
          );
          newDescription = newDescription.replace(
            "[DESC]",
            "Set an asking price for your property so we can match you to want-ads. This can be adjusted when you send an introduction. "
          );
        }
  
        if (_.includes(propertyPurpose, LONG_RENT)) {
          newTitle = newTitle.replace(
            "[TITLE]",
            "How much would you rent your property for each month?"
          );
          newDescription = newDescription.replace(
            "[DESC]",
            "You can adjust your asking price when you send each introduction. Our goal is to match your property to want-ads. "
          );
        }
  
        if (_.includes(propertyPurpose, SHORT_RENT)) {
          newTitle = newTitle.replace(
            "[TITLE]",
            "How much would you rent your property for per night on average?"
          );
          newDescription = newDescription.replace(
            "[DESC]",
            "You can consider requested dates and adjust the price accordingly before introducing this property to potential renters."
          );
        }
      } else if (propertyPurpose.length > 1) {
        newTitle = newTitle.replace(
          "[TITLE]",
          "How much are you willing to price your property at?"
        );
        newDescription = newDescription.replace(
          "[DESC]",
          "You can adjust your asking price when you send each introduction. Our goal is to match your property to want-ads"
        );
      }
    }
  }


  const handleStateClear = () => {
    // alert('Your session has expired. Please login to continue.')
    dispatch(userLogout());
    clearLocalStorage();
    navigate(pageURLs.home)
  }

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      actvStep: activeStep,
      cmpldPerctg: currentCompletedPercentage,
    }));
  }, [currentCompletedPercentage]);
  useEffect(() => {
    setLocalStorage(INVENTORY_KEY, formValues);
  }, [formValues]);

  const handleConfirmationModal = (modalState) => {
    setConfirmStatus(modalState);
  };

  

  const handleMapImageUpload = async () => {
    const lat = _.get(formValues, "lat", "");
    const lng = _.get(formValues, "lng", "");
    const mapZoom = _.get(formValues, 'mapZoom', DEFAULT_MAP_ZOOM)
    const mapResponse = await createStaticMapImage(lat, lng, mapZoom )
    const preSignedUrlRes = mapResponse ? await createPreSignedUrl() : alert('Something went wrong while fetching map image.');
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if(s3FullUrl){
        const imageUrl = s3FullUrl
        ? s3FullUrl.split("?")[0]
        : "";
        handleFormValues({mapImg : imageUrl})
      }
     
    }
  };

  const checkFormValidBeforeSave = async () => {
    //locAddress
  }

  const handleContinueClick = (currentStep) => {
    // to remove edit filed from session
    handleFormValues({ isEdit: false });
    const isConfirmAll = _.get(formValues, "isConfirmAll", false);
    let errorSection = null;
    if (currentStep === 1){
      handleDraftItemClick(draftSelectedItem)
    }
    if (currentStep === 3) {
      const yearBuilt = _.get(formValues, "yearBuilt", false);
      if (yearBuilt && !yearValidation(yearBuilt.toString())) {
        errorSection = yrMaxInValid;
      } else {
        handleMapImageUpload();
      }
    }
    if (currentStep === 9) {
      const isAddInventry = true;
      saveMemberInventory(isAddInventry);
    } else {
      if (currentStep === 4 && !isConfirmAll) {
        // To show confirmation modal popup.
        const isShow = true;
        handleConfirmationModal(isShow);
      } else {
        if (errorSection) {
          handleFormValues({
            invFormError: errorSection,
            updatedAt: Date.now(),
          }); // 'updatedAt' used for rerendering the component if the form values are not changing.
        } else {
          const errorSection = _.get(
            getLocalStorage(INVENTORY_KEY),
            "invFormError"
          );
          if (errorSection) {
            handleFormValues({ invFormError: "", updatedAt: "" }); // Clear errosSection from state.
          }
          if (currentCompletedPercentage <= completedPercentage) {
            setCurrentCompletedPercentage(completedPercentage);
          }
          currentStep!==1 && setActiveStep(activeStep + 1);
         }
      }
    }
   
    // if (currentStep === 10) {
    //   const isPostAd = true;
    //   saveMemberWantAdData(isPostAd);
    // } else {
    //   setActiveStep(activeStep+1)
    //   if (currentCompletedPercentage <= completedPercentage) {
    //     setCurrentCompletedPercentage(completedPercentage);
    //   }
    // }
  };

  const handleDraftItemClick = async (draftSelectedItem) => {
    const currentStep = _.get(draftSelectedItem, 'currentStep', null)
    const selectedId = _.get(draftSelectedItem, 'selectedId', null)
    if (selectedId) {
      await getInventoryById(selectedId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setFormValues(responseResult);
            if (currentStep === 1) setActiveStep(2);
            else setActiveStep(currentStep);
            setCurrentCompletedPercentage(_.get(responseResult, "cmpldPerctg"));
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };
  const saveMemberInventory = (isAddInventry) => {
    //////////////////// need to remove after edit want-ad flow completed (issue 169)
    handleFormValues({ isEdit: false });

    //let updatedState ={}
    const userId = getUserId();//_.get(user, "userInfo.userId", null);
    // if (!userId) {
    //   const userDetails = getLocalStorage(USER_INFO_KEY);
    //   userId = _.get(userDetails, "userInfo.userId", null);
    // }
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(INVENTORY_KEY);
    }
    const proprtyPurpose = _.get(requestPayLoad, 'proprtyPurpose', [])
    const is_Sell_Selected = _.includes(proprtyPurpose, SELL);
    if(!is_Sell_Selected){
      requestPayLoad.sellAskngPrice ="";
    }
    requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
    if (userId && requestPayLoad) {
    if (isAddInventry) {
      requestPayLoad.IsSavedDraft = false;
      requestPayLoad.IsPublished = true;
      delete requestPayLoad.tlText;
      delete requestPayLoad.prptyTypeTxt;
      delete requestPayLoad.step6Errors;
    } else {
      requestPayLoad.IsSavedDraft = true;
      requestPayLoad.IsPublished = false;
    }

    saveMemberInventoryData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if (isAddInventry) {
            if(!requestPayLoad.inventoryId){
              // for setting the match tab client data and property data
              setLocalStorage("MatchSelectedClient",  
              {FirstName: requestPayLoad.clientName, 
                LastName: requestPayLoad.clientName,
                AgentAsSeller: requestPayLoad.isAgentCreated,
                label: requestPayLoad.clientName,
                value:  requestPayLoad.clientId
              })
            setLocalStorage("selectedPropertyId", 
            {
              Inv_Id: _.get(responseResult, "inventoryId", ""),
              Location: requestPayLoad.locAddress,
              City: requestPayLoad.city,
              Is_LongTerm_Renter: _.includes(propertyPurpose, LONG_RENT),
              Is_Seller: _.includes(propertyPurpose, SELL),
              Is_ShortTerm_Renter: _.includes(propertyPurpose, SHORT_RENT),
              State: requestPayLoad.state,
              photo: requestPayLoad.photos && requestPayLoad.photos[0]

            });
            }
            //updatedState.inventoryId = _.get(responseResult, "inventoryId", "");
            // updatedState.minBdgt = _.get(formValues, "minBdgt", "");
            // updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");
            setLocalStorage(INVENTORY_KEY, {
              inventoryId: _.get(responseResult, "inventoryId", ""),
              showSuccessModal : true // This key-value is for showing the success pop up for the first time in details page after save 
            });
          } else {
            removeLocalStorage(INVENTORY_KEY);
           // navigate(pageURLs.memberInventoryDetails);
          }
          if(isAddInventry){
            navigate(pageURLs.memberInventoryDetails);
          } else{
            navigate(pageURLs.inventoryListing, {state: { showDraft: true }});
          }
          
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    } else {
      handleStateClear();
    }
  };

  const handleBackButtonClick = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkipButtonClick = (step) => {
    switch (step) {
      case 5:
        setFormValues((prevState) => ({
          // Clear data from states for nice to have
          ...prevState,
          nice2Have: [],
        }));
      break;
      case 7:
        setFormValues((prevState) => ({
          // Clear data from states for inventory Photos
          ...prevState,
          photos: [],
        }));
       break;
      case 8:
        setFormValues((prevState) => ({
          // Clear data from states for inventory Details
          ...prevState,
          invtryDetails: "",
        }));
        break; 
    }
    if (currentCompletedPercentage <= completedPercentage) {
      setCurrentCompletedPercentage(completedPercentage);
    }
    setActiveStep(activeStep + 1);
  };

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      // if(type === "clearBudget"){
      //   const array = { ...formValues }; // make a separate copy of the state
      //   ["sellAskngPrice", "LTRAskngPrce", "STRAskngPrce", "isCompnsteAgent", "isCompnsteAgent_LTR", "isCompnsteAgent_STR", "salesPercnt_LTR", "salesPercnt_STR", 
      //   "salesPercnt"]
      //   .forEach((e) => delete array[e]);
      //   setFormValues(array);
      // }
      if(type === "clearSell"){
        const array = { ...formValues }; // make a separate copy of the state
        ["sellAskngPrice", "isCompnsteAgent", "salesPercnt", "nice2Have"]
        .forEach((e) => delete array[e]);
        setFormValues(array);
      }
      if (type === "clearPropertyPurposeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwLrgDogs", "alwSmlDogs", "alwCats", "LTRAskngPrce", "isCompnsteAgent_LTR", "salesPercnt_LTR", "nice2Have" ].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "clearPropertyPurposeSTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["alwPets", "accsPvtBthRm", "pvtEntrnce", "noOfBeds", "STRAskngPrce", "isCompnsteAgent_STR", "salesPercnt_STR", "nice2Have"  ].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "locAddress",
        "noBedRm",
        "noBathRm",
        "sqFt",
        "stories",
        "lotSize",
        "grgeSpots",
        "adaAccess",
        "acreage",
        "lotType",
        "ltTypeTxt",
        "roadType",
        "rdTypeTxt",
        "isPowerAccess",
        "waterSuply",
        "wSText",
        "wasteWtrSys",
        "WstWatrSys",
        "watrWtrText",
        "isApprovedStrctr",
        "isInFloodPlain",
        "proprtyPurpose",
        "alwLrgDogs",
        "alwSmlDogs",
        "alwCats",
        "alwPets",
        "accsPvtBthRm",
        "isConfirmOwner",
        "isConfirmPropLoc",
        "nice2Have",
        "sellAskngPrice",
        "LTRAskngPrce",
        "STRAskngPrce",
        "isCompnsteAgent",
        "salesPercnt",
        "isCompnsteAgent_LTR", 
        "isCompnsteAgent_STR", 
        "salesPercnt_LTR", 
        "salesPercnt_STR",
        "photos",
        "invtryDetails",
        "prptySubTypes",
        "prptySubType",
        "prptySubTypeTxt",
         "prptyTypeTxt",
         "IsNewConstruction",
         "isPrvtDrInst",
         "isDrldWelInst",
         "isDrldWelAppr",
         "isSepTankInst",
         "isSepTankAppr",
         "pvtEntrnce",
         "neighborHood",
         "highSchool",
         "yearBuilt",
         "noOfBeds",
         "lat",
         "lng",
         "mapZoom",
         "mapType",
         "street",
         "city",
         "county",
         "state",
         "zip"
      ].forEach((e) => delete array[e]);

      setFormValues(array);
      setCurrentCompletedPercentage(completedPercentage);
    }
  };

  const handleContinueButtonState = (buttonState) => {
    isDisabled = false;
    setContinueButtonState(buttonState ? buttonState : isDisabled);
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const handleSaveAndExit = (step) => {
    if (step <= 4) {
      removeLocalStorage(INVENTORY_KEY)
      navigate(pageURLs.home);
    } else {
      const isAddInventory = false
      saveMemberInventory(isAddInventory)
      
    }
  };

  // const handleSaveExitModal = (state) => {
  //   setShowSaveExitModal(state);
  // };

  return (
    <div className="container-fluid position-relative half-fluid h-100 ps-0 pe-0">
      {/* <ReadyToSaveDraftModal
        handlePopUp={handleSaveExitModal}
        show={showSaveExitModal}
        formValues={formValues}
      /> */}
      <div className="container-fluid container-height ps-0 pe-0">
        <div className="row h-100 g-0">
          <InventoryLeftSection
            title={newTitle}
            description={newDescription}
            completedPercentage={currentCompletedPercentage}
          />
          <InventoryRightSection
            setActiveStep = {setActiveStep}
            setDraftSelectedItem = {setDraftSelectedItem}
            draftSelectedItem = {draftSelectedItem}
            handleFormValues={handleFormValues}
            handleSaveAndExit={handleSaveAndExit}
            handleContinueClick={handleContinueClick}
            handleBackButtonClick={handleBackButtonClick}
            handleContinueButtonState={handleContinueButtonState}
            isDisabled={continueButtonState}
            skipButtonEnable={skipButtonEnable}
            exitButtonText={exitButtonText}
            backButtonEnable={backButtonEnable}
            continueButtonText={continueButtonText}
            hideContinueButton ={hideContinueButton}
            formValues={formValues}
            step={activeStep}
            handleSkipButtonClick={handleSkipButtonClick}
            clearPreviousStates={clearPreviousStates}
            exitButtonDisable={
              _.get(formValues, "isPublished", "") == 1 ? true : false
            }
            handleConfirmationModal={handleConfirmationModal}
            showConfirmStatuModal={showConfirmStatuModal}
          />
        </div>
      </div>
      <BasicPlanModal
        userDetails={userDetails}
        show={showBasicPlanModal}
        updateReloadParam={updateReloadParam}
        onHide={BasicPlanModalClose}
        fromInventoryListing={true}
        fromInventoryCreation={true}
        showPlanModal={BasicPlanModalShow}

      />
    </div>
  );
};

export default CreateInventory;
