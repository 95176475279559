
/**
 * @file   src\components\AgentDashboard\EditClientModal.js
 * @brief  This file is responsible for Editing client details
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import TextArea from "../Common/TextArea";
import { handleTextBoxChange } from "../../actions/common";
import { ERROR_TEXT } from "../../constants/common";
import { emailValidation, nameValidation, textValidations } from "../../helpers/validations";


const EditClientModal = ({ show, onHide, handleSave, clientDetails, ...props }) => {
  const clientBio = _.get(clientDetails, 'Add_Info', '')
  const firstName = _.get(clientDetails, 'FirstName', '')
  const lastName = _.get(clientDetails, 'LastName', '')
  const email = _.get(clientDetails, 'Email', '')
  const clientId = _.get(clientDetails, 'ClientId', '')
  const agentId =_.get(clientDetails, 'AgentId', '')
  const initialData = {
    firstName: firstName,
    lastName:  lastName,
    info: clientBio,
    email : email,
  };

  //form States
  const [formData, setFormData] = useState(initialData);
  const [formErrorMessage, setFormErrorMessage] = useState(initialData);
  const [disableButton, setDisableButton] = useState(true);
  const [descError, setDescError] = useState(null);
  const [descCharCount, setDescCharCount] = useState(0);

  /**
  * To handle textbox value change
  * @param {any} event 
  */
  const onChangeText = (event) => {
    const value = event.target.name === 'email'? handleTextBoxChange(event) :
    capitalizeFirstString(handleTextBoxChange(event))
    if(event.target.name === "info"){
      setDescCharCount(value.length);
    }
   
    setFormData(formData => ({ ...formData, [event.target.name]: value }));
     setFormErrorMessage(validateForm({ ...formData, [event.target.name]: value }));
    validateForm({ ...formData, [event.target.name]: value })

  }

  useEffect(() => {
    setDescCharCount(clientBio && clientBio.length ? clientBio.length : 0);
    validateForm(initialData)
    setFormData(initialData)
  }, [clientDetails]);


  /**
 * to validate add brand form
 * @param {json} data 
 * @returns json
 */
  const validateForm = (data) => {
    let errors = {};
    if (!data.firstName || data.firstName.trim() == "") {
      errors.firstName = 'Enter First Name';
    }
    // if(!nameValidation(data.firstName)) {
    //   errors.firstName = 'Invalid input';
    // }
    // if (!data.lastName || data.lastName.trim() == "") {
    //   errors.lastName = 'Enter Last Name';
    // }
    // if(!nameValidation(data.lastName)) {
    //   errors.lastName = 'Invalid input';
    // }
    if (data.email) {

      if (!emailValidation(data.email.trim())) {
        errors.email = 'Enter Valid Email';
      }
    }

    const bio = data.info
    if (bio) {
      if(!textValidations(bio)){
        errors.info = ERROR_TEXT
        setDescError(ERROR_TEXT)
      } 
    }

    if (Object.keys(errors).length == 0) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
    return errors;
  };

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };


  return (
    <Modal
      show = {show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={() => {
        setFormData(initialData)
        setFormErrorMessage('')
        setDisableButton(true)
      }}
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => onHide() }

        ></button>
       <h3 className="text-start mb-32">Edit client</h3>
        <Row>
          <Col xl={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">First name<span>*</span></label>
              {/* <input
                placeholder="First name"
                type="text"
                className="form-control"
                onBlur={handleFirstNamelOnBlur}
              ></input> */}

              <TextBox
                id="fName"
                name="firstName"
                type="text"
                value={formData.firstName}
                placeholder="First"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.firstName}
                maxLength={35}

              />

            </div>
          </Col>

          <Col xl={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Last name</label>
              {/* <input
                placeholder="Last name"
                type="email"
                className="form-control"
                onBlur={handleLastNamelOnBlur}
              ></input> */}

              <TextBox
                id="lName"
                name="lastName"
                type="text"
                value={formData.lastName}
                placeholder="Last"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.lastName}
                maxLength={35}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Client's Email</label>
              <TextBox
                id="email"
                name="email"
                type="text"
                value={formData.email}
                placeholder="Email"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.email}
                maxLength={35}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-4 mb-sm-4">
              <label className="form-label">Client Bio</label>
              <TextArea
                name='info'
                className="form-control txt-area"
                errorMessage={descError}
                value={formData.info}
                maxLength={5000}
                placeholder="Additional info about client"
                onChange={(e) => {
                  onChangeText(e);
                }}
              />

              {/* <textarea class="form-control modal-txtarea" placeholder="Additional info about client"></textarea> */}
               <div className="des-count align-self-end">{descCharCount}/5000</div> 
            </div>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
            <Button variant="tertiary" className="btn btn-tertiary me-3" onClick={()=> onHide()}>
            Cancel
            </Button>    
          <Button variant="primary" className="btn" disabled={disableButton} onClick={()=> handleSave({clientId: clientId, agentId: agentId,
          firstName: formData.firstName, lastName : formData.lastName,
             email : formData.email, info: formData.info})}>
            Save Changes
            </Button>
            
            </div>
          </Col>
        </Row>


      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default EditClientModal;
