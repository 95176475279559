import React, { useState, useEffect } from 'react';

const CountdownTimer = (props) => {
  const [timer, setTimer] = useState(59); // Set initial timer value to 59 seconds
  const [showLink, setShowLink] = useState(false); // State to control link visibility

  useEffect(() => {
    const countdown = setInterval(() => {
      // Update the timer value every second
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the timer reaches 0
    if (timer === 0) {
      clearInterval(countdown);
      setShowLink(true); // Show the link when the timer reaches 0
    }

    return () => clearInterval(countdown); // Clean up the interval on unmount
  }, [timer]);

  return (
    <div>
      {timer > 0 ? (
        <div className="time">Resend {timer} sec</div>
      ) : (
        <></>
      )}
      {showLink && (
        <a href={void(0)} onClick={ () => props.resentEmail()}>Didn't get an email? Let's resend it</a>
      )}
    </div>
  );
};

export default CountdownTimer;
