import React, { useEffect, useRef, useState } from "react";
import CheckBox from "../../Common/CheckBox";
import {
  IS_APARTMENT_CONDO,
  IS_ENTIRE_PLACE,
  IS_ENTIRE_SPACE,
  IS_HOTEL,
  IS_HOUSE,
  IS_LAND,
  IS_MANUFACTURE,
  IS_MULTI_FAMILY,
  IS_TOWNHOME,
  LONG_RENT,
  SELL,
  SHORT_RENT,
  IS_PRIVATE_ROOM,
} from "../../../constants/common";
import PropertyPurposeConfirmModal from "./PropertyPurposeConfirmModal";
import { removeNegativeInput } from "../../../helpers/validations";
import { checkIsNumber } from "../../../helpers/common";
import TextBox from "../../Common/TextBox";
const InventoryPropertyPurpose = (props) => {
  const {
    propertyPurpose,
    alwLrgDogs,
    alwSmlDogs,
    alwCats,
    alwPets,
    accsPvtBthRm,
    pvtEntrnce,
    handleFormValues,
    clearPreviousStates,
    propertySubType,
    propertyType,
    isConfirmOwner,
    isConfirmPropLoc,
    isEdit = false,
    noOfBeds,
    errors,
    typeError
  } = props;

  // const [enableConfirmButton, setConfirmButtonState] = useState(false);
  const alwLrgDogsChkBx = "alwLrgDogs";
  const alwsmlDogsChkBx = "alwSmlDogs";
  const alwCatsChkBx = "alwCats";
  const alwPetsChkBx = "alwPets";
  const accsPvtBthRmchkBx = "accsPvtBthRm";
  const pvtEntrnceChkBx = "pvtEntrnce";
  const confirmOwnerChkBx = "isConfirmOwner";
  const confirmPropLocChkBx = "isConfirmPropLoc";
  const LTR_OPTION_DETAILS = [
    { id: alwLrgDogsChkBx, text: "Allows large dogs" },
    { id: alwsmlDogsChkBx, text: "Allows small dogs" },
    { id: alwCatsChkBx, text: "Allows cats" },
  ];
  const STR_OPTION_DETAILS = [
    { id: alwPetsChkBx, text: "Allows pets" },
   
  ];
  const STR_OPTIONS_PVT_ROOM = [
    { id: alwPetsChkBx, text: "Allows pets" },
    { id: accsPvtBthRmchkBx, text: "Access to private bathroom" },
    { id: pvtEntrnceChkBx, text: "Private entrance" },
  ];

  const noOfBedsRef = useRef();
  const [noOfBedsError, setNoOFBedsrror] = useState("");
  // to show error message
  useEffect(() => {
    if (errors && errors.length > 0) {
      if (errors[0] == 'noOfBeds') {
        noOfBedsRef.current.focus()
      }

      errors.map((item) => {
        if (item === 'noOfBeds')
          setNoOFBedsrror("Enter number of Beds.");
      })
    }
  }, [errors]);

  // const [showConfirmStatuModal, setConfirmStatus] = useState(false);
  // let proprtyPurpose = null;
  // let selectedValue = null;

  // useEffect(() => {
  //   let isDisable = true;
  //   if (_.includes(propertyPurpose, SHORT_RENT)) {
  //     if (isConfirmOwner && isConfirmPropLoc) {
  //       isDisable = false
  //       setConfirmButtonState(isDisable)
  //     } else {
  //       isDisable = true
  //       setConfirmButtonState(isDisable)
  //     }
  //   } else {
  //     if (isConfirmOwner) {
  //       isDisable = false
  //       setConfirmButtonState(isDisable)
  //     } else {
  //       isDisable = true;
  //       setConfirmButtonState(isDisable)
  //     }
  //   }

  // }, [isConfirmOwner, isConfirmPropLoc]);

  // const handleConfirmationModal = (modalState) => {
  //   setConfirmStatus(modalState);
  // };

  const handlePropertyPurposeSelection = (e) => {
    const selectedValue = e.target.value;
    if (!e.target.checked && selectedValue === SELL) {
      clearPreviousStates({ type: "clearSell" });
    }
    if (!e.target.checked && selectedValue === LONG_RENT) {
      clearPreviousStates({ type: "clearPropertyPurposeLTR" });
    } else if (!e.target.checked && selectedValue === SHORT_RENT) {
      clearPreviousStates({ type: "clearPropertyPurposeSTR" });
    }

    const purposeArray = propertyPurpose || [];
    if (selectedValue) {
      let itemArray;
      if (_.includes(purposeArray, selectedValue)) {
        itemArray = purposeArray.filter((e) => e !== selectedValue);
      } else {
        itemArray = [...purposeArray, selectedValue];
      }
      handleFormValues({
        proprtyPurpose: itemArray,
        isConfirmAll: false,
        isConfirmOwner: false,
        isConfirmPropLoc: false,
      });
    }
    // handleFormValues({isConfirmAll : false, isConfirmOwner : false, isConfirmPropLoc : false, askngPrice : '',
    // LTRAskngPrce: '',
    // STRAskngPrce: '',
    // isCompnsteAgent:'',
    // salesPercnt :'', })
    // setConfirmButtonState(true)
  };

  // const handleModalConfirmClick = () => {
  //   handleConfirmationModal(false)
  //   handleContinueButtonState()
  //   handleFormValues({isConfirmAll : true})
  // }

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (id) {
      case alwLrgDogsChkBx:
        handleFormValues({ alwLrgDogs: e.target.checked });
        break;
      case alwsmlDogsChkBx:
        handleFormValues({ alwSmlDogs: e.target.checked });
        break;
      case alwCatsChkBx:
        handleFormValues({ alwCats: e.target.checked });
        break;
      case alwPetsChkBx:
        handleFormValues({ alwPets: e.target.checked });
        break;
      case accsPvtBthRmchkBx:
        handleFormValues({ accsPvtBthRm: e.target.checked });
        break;
      case confirmOwnerChkBx:
        handleFormValues({ isConfirmOwner: e.target.checked });
        break;
      case confirmPropLocChkBx:
        handleFormValues({ isConfirmPropLoc: e.target.checked });
        break;
      case pvtEntrnceChkBx:
        handleFormValues({ pvtEntrnce: e.target.checked });
        break;
    }
  };

  const handleNoOfBedsChange = (e) => {
    setNoOFBedsrror("")
    let value = removeNegativeInput(e.target.value);
    if (value === "0") {
      value = "";
    } else if (value > 100) {
      alert("Maximum limit is 100")
      value = 100;
    }

    if (checkIsNumber(value)) {
      handleFormValues({ noOfBeds: value });
    }
  };

  const loadCheckBoxItems = (list) => {
    const List = list.map((pt) => {
      const id = _.get(pt, "id", "");
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case alwLrgDogsChkBx:
          isChecked = alwLrgDogs;
          break;
        case alwsmlDogsChkBx:
          isChecked = alwSmlDogs;
          break;
        case alwCatsChkBx:
          isChecked = alwCats;
          break;
        case alwPetsChkBx:
          isChecked = alwPets;
          break;
        case accsPvtBthRmchkBx:
          isChecked = accsPvtBthRm;
          break;
        case pvtEntrnceChkBx:
          isChecked = pvtEntrnce;
          break;
      }
      return (
        <label className="form-check-label" htmlFor={id}>
          <input
            className="form-check-input"
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <div className="label-inner-wrap">
            <p>{text}</p>
          </div>
        </label>
      );
    });

    return List;
  };
  const is_Sell_Selected = _.includes(propertyPurpose, SELL);
  const is_LTR_Selected = _.includes(propertyPurpose, LONG_RENT);
  const is_STR_Selected = _.includes(propertyPurpose, SHORT_RENT);

  const isShowSelling = propertySubType ? (
    (propertyType === IS_ENTIRE_PLACE &&
      (propertySubType === IS_HOUSE ||
        propertySubType === IS_APARTMENT_CONDO ||
        propertySubType === IS_TOWNHOME ||
        propertySubType === IS_MANUFACTURE ||
        propertySubType === IS_MULTI_FAMILY)) ||
    propertyType === IS_LAND
  ) : true;

  const isShowLTR = propertySubType ?
    ((propertySubType !== IS_MULTI_FAMILY && propertySubType !== IS_HOTEL) ||
      propertyType === IS_LAND) : true;
  const isShowSTR = propertySubType ?
    (propertySubType !== IS_MULTI_FAMILY || propertyType === IS_LAND) : true;
  return (
    <>
      {/* <PropertyPurposeConfirmModal
        show={showConfirmStatuModal}
        isShortRentChecked={propertyPurpose && _.includes(propertyPurpose, SHORT_RENT)}
        onHide={handleConfirmationModal}
        isConfirmOwner = {isConfirmOwner}
        isConfirmPropLoc ={isConfirmPropLoc}
        handleCheckBoxItemClick = {handleCheckBoxItemClick}
        handleModalConfirmClick = {handleModalConfirmClick}
        isDisabled = {enableConfirmButton}
        confirmOwnerChkBx = {confirmOwnerChkBx}
        confirmPropLocChkBx = {confirmPropLocChkBx}
      ></PropertyPurposeConfirmModal> */}
      <>
        {isShowSelling && (
          <div
            className={
              is_Sell_Selected 
                ? "find-renters-wrap mb-3 active"
                : typeError ? "find-renters-wrap mb-3 red-marked-seltypewrap" : "find-renters-wrap mb-3 "
            }
          >
            <label className="form-check-label">
              <CheckBox
                class="form-check-input"
                name="pst-checkbox"
                value={SELL}
                checked={is_Sell_Selected}
                onChange={(e) => handlePropertyPurposeSelection(e)}
              />
              <span>
                <div className="wantad-type-info">
                  <h6 className="pb-0">Open to selling</h6>
                  <p>You want to sell your property.</p>
                </div>
              </span>
            </label>
          </div>
        )}

        {isShowLTR && (
          <div
            className={
              is_LTR_Selected 
                ? "find-renters-wrap mb-3 active"
                : typeError ? "find-renters-wrap mb-3 red-marked-seltypewrap" : "find-renters-wrap mb-3"
            }
          >
            <label className="form-check-label" htmlFor="rent_box">
              <CheckBox
                class="form-check-input"
                name="pst-checkbox"
                value={LONG_RENT}
                id="rent_box"
                checked={is_LTR_Selected}
                onChange={(e) => handlePropertyPurposeSelection(e)}
              />
              <div className="label-wrap">
                <h6>Open to long-term rentals</h6>
                <p>You want to rent out your property to someone for greater than 30 days.</p>
              </div>
            </label>
            {is_LTR_Selected && (
              <div className="find-renters-inner-wrap">
                <h6>Details:</h6>
                {loadCheckBoxItems(LTR_OPTION_DETAILS)}
              </div>
            )}
          </div>
        )}

        {isShowSTR && (
          <div
            className={
              is_STR_Selected
                ? "find-renters-wrap mb-3 active"
                : typeError ? "find-renters-wrap mb-3 red-marked-seltypewrap" : "find-renters-wrap mb-3"
            }
          >
            <label className="form-check-label" htmlFor="str_box">
              <CheckBox
                class="form-check-input"
                name="pst-checkbox"
                value={SHORT_RENT}
                id="str_box"
                checked={is_STR_Selected}
                onChange={(e) => handlePropertyPurposeSelection(e)}
              />

              <div className="label-wrap">
                <h6>Open to short-term rentals</h6>
                <p>You want to rent out your property to someone for less than 30 days.</p>
              </div>
            </label>
            {is_STR_Selected && (
              <div className={noOfBedsError ? "find-renters-inner-wrap red-marked-inner-wrap" : "find-renters-inner-wrap"}>
                {propertyType != IS_LAND && (
                  <div className="st-rent-bedinfo mb-3">
                    <label className={noOfBedsError && "red-marked-inner-label" }>Number of beds: <span>*</span></label>
                    <TextBox
                      type="text"
                      name="noOfBeds"
                      errorMessage={noOfBedsError}
                      value={noOfBeds}
                      placeholder="0"
                      onChange={(e) => {
                        handleNoOfBedsChange(e);
                      }}
                      txtRef={noOfBedsRef}></TextBox>

                  </div>
                )}

                <h6>Details:</h6>
                {loadCheckBoxItems(propertyType === IS_PRIVATE_ROOM ? STR_OPTIONS_PVT_ROOM : STR_OPTION_DETAILS)}
              </div>
            )}
          </div>
        )}
      </>
    </>
  );
};

export default InventoryPropertyPurpose;
