import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import QuestionIcon from "../../components/QuestionIcon";
import "../../styles/clients.scss";
import noImage from "../../../images/no-image-placeholder.png";
import PreviewIntroductionOffcanvas from "./PreviewIntroductionOffcanvas";
import { AGENT_INTRODUCTION, MEMBER_INTRODUCTION } from "../../../constants/common";
import { getUserType, setBodyBackgroundWhite } from "../../../helpers/common";
import { REORDER_N2H, REORDER_PHOTOS } from "../../../constants/onBoarding";


const CustomizeIntro = (props) => {
    useEffect(() => {
        setBodyBackgroundWhite()
        document.body.classList.add('bg-offcanvas-fix');

    }, []);
    const userType = getUserType();

    let session = userType == 2 ? AGENT_INTRODUCTION : MEMBER_INTRODUCTION;
    const { selectedProperty, handleBackButtonClick } = props
    const propertyFeatures = selectedProperty?.propertyFeaturesForPreview;
    const inventoryPhotos = selectedProperty?.photosForPreview;
    const [coverPhotos, setCoverPhotos] = useState([]);

    const [showPreviewIntroduction, setShowPreviewIntroduction] = useState(false);
    const PreviewIntroductionClose = () => setShowPreviewIntroduction(false);
    const PreviewIntroductionShow = () => setShowPreviewIntroduction(true);
   

    useEffect(() => {
        if (inventoryPhotos && inventoryPhotos.length > 0) {
            const defaultArray = inventoryPhotos?.filter((item) => item.isCover == 1);
            setCoverPhotos(defaultArray);
            // if (inventoryPhotos.length <= 3) {
            //     setDisableEditButton(true);
            // }
        }
        else {
            setCoverPhotos([])
        }


    }, [inventoryPhotos])
   

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Pick up to 3 photos to show buyers in your introduction. Once accepted, they can view all your property photos.
        </Tooltip>
    );

    const renderTooltipForPropertyFeature = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Change the order of your property features to highlight what's most important for this want ad.
        </Tooltip>
    );
    return (

        <div className="gitcha-main-wrapper view-client-wrap intro-checkout-wrap bg-white">

            <div className="content-wrapper content-wrapper-h-102-percent">
                <div className="inner-layout-header-new bg-white">
                    <div className="review-plan-header header-brdr-btm w-100">
                        <div className="signup-login-header-top signup-header-txt clearfix">
                            <a className="back-link-small" href={void (0)} onClick={() => {
                                handleBackButtonClick()

                            }}><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                                </svg></a>
                            <span>Customize Intro</span>
                        </div>
                    </div>
                </div>
                <div className="inner-layout-content-new">
                    <div className="payment-method-wrap intro-summary-sec">
                        <div className="title-group d-flex align-items-center">
                            <div className="title-block">
                                <h4>Introduction Photos</h4>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <a href={void 0} className="tooltip-icon">
                                        <QuestionIcon />
                                    </a>
                                </OverlayTrigger>
                            </div>
                            <button type="button" className="btn btn-link" onClick={() => { props.setUIKey(REORDER_PHOTOS) }}>Edit</button>
                        </div>
                        <div className="inventory-banner-small position-relative">
                            <div className="banner-row">
                                {

                                    coverPhotos.length == 0 ?
                                    (
                                        <div className="banner-column-full banner-single h-200">
                                            <div className="full-col-img-wrap h-100">
                                                {/* <div className="upload-cover-btn-mob">Cover</div> */}
                                                <img className="brdr-top-10 col-img-style" src={noImage} />
                                            </div>
                                        </div>
                                     ) :
                                       ( coverPhotos &&

                                        <>
                                            {coverPhotos.length == 1 &&
                                                <div className="banner-column-full banner-single h-200">
                                                    <div className="full-col-img-wrap h-100">
                                                        <div className="upload-cover-btn-mob">Cover</div>
                                                        <img className="brdr-top-10 col-img-style" src={coverPhotos[0]?.image} />
                                                    </div>
                                                </div>
                                            }
                                            {coverPhotos.length == 2 && (
                                                <>

                                                    <div className="banner-column-full h-200">
                                                        <div className="full-col-img-wrap h-100">
                                                            <div className="upload-cover-btn-mob">Cover</div>
                                                            <img className="brdr-top-10 col-img-style" src={coverPhotos[0]?.image} />
                                                        </div>
                                                    </div>

                                                    <div className="banner-column-full h-200">
                                                        <div className="full-col-img-wrap h-100">
                                                            <div className="upload-cover-btn-mob">Cover</div>
                                                            <img className="brdr-top-10 col-img-style" src={coverPhotos[1]?.image} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {coverPhotos.length >= 3 && (
                                                <>
                                                    <div className="banner-column-full h-200">
                                                        <div className="full-col-img-wrap h-100">
                                                            <div className="upload-cover-btn-mob">Cover</div>
                                                            <img className="brdr-top-10 col-img-style" src={coverPhotos[0]?.image} />
                                                        </div>
                                                    </div>

                                                    <div className="banner-column-full d-flex h-148">

                                                        {coverPhotos[1]?.image &&
                                                            <div className="quarter-col-img-wrap-left w-50-percent">
                                                                <img className="brdr-btm-left-10 col-img-style" src={coverPhotos[1]?.image} />
                                                            </div>
                                                        }
                                                        {coverPhotos[2]?.image &&
                                                            <div className="quarter-col-img-wrap-right w-50-percent">
                                                                <img className="brdr-btm-right-10 col-img-style" src={coverPhotos[2]?.image} />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            )}

                                        </>

                                       )
                                }
                            </div>
                        </div>
                    </div>
                    {
                        propertyFeatures.length > 0 &&
                        <div className="payment-method-wrap">
                            <div className="title-group d-flex align-items-center">
                                <div className="title-block">
                                    <h4>Property Features</h4>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={renderTooltipForPropertyFeature}
                                    >
                                        <a href={void 0} className="tooltip-icon"><QuestionIcon /></a>
                                    </OverlayTrigger>
                                </div>
                                <button type="button" className="btn btn-link" onClick={() => { props.setUIKey(REORDER_N2H) }}>Reorder</button>
                            </div>
                            <div className="feature-list">
                                {
                                    propertyFeatures.sort((a, b) => a.order - b.order).map((feature, index) => {
                                        return (
                                            <div className="info-pill">
                                                <div className="feature-icon"> <img src={feature?.icon ? require("../../../images/" + feature?.icon) : ''} /></div>
                                                <p className="m-0">{feature?.text}</p>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="inner-layout-footer-new">
                <div className="inner-btm-fixed-nav">
                    <div className="btn-wrap">
                        <button type="button" className="btn btn-primary w-100" onClick={() => { props.saveCustomizeData() }}>Save Changes</button>
                        <button type="button" className="btn btn-link w-100" onClick={PreviewIntroductionShow}>Preview Introduction</button>
                    </div>
                    <PreviewIntroductionOffcanvas
                        show={showPreviewIntroduction}
                        onHide={PreviewIntroductionClose}
                        selectedProperty={selectedProperty}
                    />
                </div>
            </div>
        </div >
    );
};

export default CustomizeIntro;
