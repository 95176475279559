
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const WantAdRenewConfirmationModal = (props) => {
  const {show, onHide} = props;
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Are you sure you want to renew this Want ad?</h3>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={() => { props.onConfirmReniewWantAd() }}>Confirm</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Cancel</button>
        </div>
       
        </Modal.Body>
    </Modal>
  );
};
export default WantAdRenewConfirmationModal;


