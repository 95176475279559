import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";
import "../../styles/clients.scss";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  classCardNumber,
} from "@stripe/react-stripe-js";
import { savePaymentDetails } from "../../../services/paymentsService";
import { getUserId, toTitleCase } from "../../../helpers/common";
import TextBox from "../../../components/Common/TextBox";
import { fetchPaymentMethods } from "../../../actions/payments";
import { EDIT_PAYMENT, PAYMENT_LIST, REVIEW_ORDER } from "../../../constants/onBoarding";

const getCardElementOptions = (elem) => {
  const CARD_ELEMENT_OPTIONS = {
    placeholder: elem,
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "#15181E",
        fontSize: "16px",
        fontFamily: '"Supreme-Regular", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#797E86",
        },
      },
      invalid: {
        color: "#e74c3c",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  return CARD_ELEMENT_OPTIONS;
};

const CardInputWrapper = styled.div`
  border: 2px solid #cfd4dd;
  border-radius: 12px;
  padding: 12px;
  height: 47px;
`;

const PaymentComponent = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getUserId();
  const userDetails = useSelector((state) => state.userProfile.userDetails);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods
  );
  const [cardSave, setCardSave] = useState(false);
  const [name, setName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [nameError, setNameError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    cardNumberError: "",
    cvvError: "",
    expiryError: "",
  });

  const [cardNumberComplete, setCardNumberCOmplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCVCComplete, setCardCVCComplete] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const re = /^[A-Za-z0-9 -]+$/;
  const handleNameChange = (value) => {
    setNameError("");
    if (value === "" || re.test(value)) {
      setName(value);
    }
    if (value.trim() === "") {
      setNameError("Name on card is incomplete");
    }
  };

  const handleZipCodeChange = (value) => {
    setZipCodeError("");
    if (value === "" || re.test(value)) {
      setZipCode(value);
    }
    if (value.trim() === "") {
      setZipCodeError("ZipCode is incomplete");
    }
  };

  const handleCardNumberChange = (e) => {
    setErrorMessages({ ...errorMessages, cardNumberError: "" });
    if (e.complete) {
      setCardNumberCOmplete(true);
    } else {
      setCardNumberCOmplete(false);
    }
    if (e?.error?.message !== "") {
      setErrorMessages({
        ...errorMessages,
        cardNumberError: e?.error?.message,
      });
    }
  };

  const handleCardExpiryChange = (e) => {
    setErrorMessages({ ...errorMessages, expiryError: "" });
    if (e.complete) {
      setCardExpiryComplete(true);
    } else {
      setCardExpiryComplete(false);
    }
    if (e?.error?.message !== "") {
      setErrorMessages({ ...errorMessages, expiryError: e?.error?.message });
    }
  };

  const handleCVCChange = (e) => {
    setErrorMessages({ ...errorMessages, cvvError: "" });
    if (e.complete) {
      setCardCVCComplete(true);
    } else {
      setCardCVCComplete(false);
    }
    if (e?.error?.message !== "") {
      setErrorMessages({ ...errorMessages, cvvError: e?.error?.message });
    }
  };

  const checkValid = () => {
    if (
      name !== "" &&
      zipCode !== "" &&
      cardNumberComplete &&
      cardCVCComplete &&
      cardExpiryComplete &&
      !submitClicked
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitClicked(true);
    if (!stripe && !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(
      "cardNumber",
      "cardExpiry",
      "cardCvc"
    );
    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: name,
      address_zip: zipCode,
    });
    if (error) {
      setCardSave(false);
      toast.error(error.message);
    } else {
      if (token?.id) {
        const data = {
          Name: name,
          ZipCode: zipCode,
          TokenId: token?.id,
          CardId: token?.card?.id,
          Brand: token?.card?.brand,
          CVC_Check: token?.card?.cvc_check,
          Exp_month: token?.card?.exp_month,
          Exp_year: token?.card?.exp_year,
          Funding: token?.card?.funding,
          Last4: token.card.last4,
          IsDefault: paymentMethods?.length > 0 ? 0 : 1,
        };

        await savePaymentDetails(data)
          .then((response) => {
            if (response.errorCode===0) {
              toast.success("Payment method successfully added!");
              //   props.setUIKey("reviewOrder");
              //   props.setReload();
              if (userId) {
                dispatch(fetchPaymentMethods());
              }
              setSubmitClicked(false);
              if(props.isFromSendIntroductionPlan){
                props.handlePageToggle({activePage: REVIEW_ORDER})
              } else if(props.isFromReviewPlan){
                props.handlePageToggle({activePage: EDIT_PAYMENT})
              } else{
                navigate(pageURLs.mobilePaymentHistory);
              }
            } else {
              toast.error(response.message);
              setSubmitClicked(false);
            }
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    }
  };

  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
  }, []);

  return (
    <div className="gitcha-main-wrapper view-client-wrap select-payment-wrap pb-102">
      <div className="content-wrapper content-wrapper-h-102-percent">
        <div className="inner-layout-header-new bg-white">
          <div className="review-plan-header header-brdr-btm w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              <a
                className="back-link-small"
                href={void 0}
                role="button"
                onClick={() =>  props.isFromSendIntroductionPlan ? props.handlePageToggle({activePage: PAYMENT_LIST}) : props.isFromReviewPlan ? props.handlePageToggle({activePage: EDIT_PAYMENT}): 
                navigate(pageURLs.mobilePaymentHistory)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
              <span>Add Payment Method</span>
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new">
          <div className="payment-form">
            <div className="mob-fld-wrap position-relative field-spacer">
              <label className="form-label">Name on Card</label>
              {/* <input
                className="form-control mob-pr-44"
                placeholder="First and Last Name"
              /> */}
              <TextBox
                id="cardname"
                name="cardname"
                type="text"
                maxLength={50}
                value={toTitleCase(name)}
                placeholder="First and Last Name"
                onChange={(e) => handleNameChange(e.target.value)}
                className="form-control mob-pr-44"
                errorClass="error-validation-msg h-10"
                errorMessage={nameError}
              />
            </div>
            <div className="mob-fld-wrap position-relative field-spacer">
              <label className="form-label">Card Number</label>
              {/* <input
                className="form-control mob-pr-44 validation-alert-brdr"
                placeholder="••••••••••••0000"
              /> */}
              <CardInputWrapper className="stripe-field mb-0">
                <CardNumberElement
                  onChange={(e) => handleCardNumberChange(e)}
                  placeholder="Custom card number placeholder"
                  className={"stripe-control " + classCardNumber}
                  options={getCardElementOptions("••••••••••••0000")}
                />
              </CardInputWrapper>
              {
                <div className="mob-error-msg">
                  {errorMessages?.cardNumberError}
                </div>
              }
            </div>
            <div className="field-group d-grid">
              <div className="mob-fld-wrap position-relative">
                <label className="form-label">Exp Date</label>
                {/* <input
                  type="text"
                  className="form-control mob-pr-44"
                  placeholder="MM/YY"
                /> */}
                <CardInputWrapper className="stripe-field mb-0">
                  <CardExpiryElement
                    onChange={(e) => handleCardExpiryChange(e)}
                    className={"stripe-control " + classCardNumber}
                    options={getCardElementOptions("MM/YY")}
                  />
                </CardInputWrapper>
              </div>
              <div className="mob-fld-wrap position-relative">
                <label className="form-label">CVC</label>
                {/* <input
                  type="text"
                  className="form-control mob-pr-44"
                  placeholder="123"
                /> */}
                <CardInputWrapper className="stripe-field mb-0">
                  <CardCvcElement
                    onChange={(e) => handleCVCChange(e)}
                    className={"stripe-control " + classCardNumber}
                    options={getCardElementOptions("123")}
                  />
                </CardInputWrapper>
              </div>
            </div>
            <div className="mob-fld-wrap position-relative field-spacer">
              <label className="form-label">ZIP Code</label>
              {/* <input className="form-control mob-pr-44" placeholder="12345" /> */}
              <TextBox
                id="zipcode"
                name="zipcode"
                type="text"
                maxLength={10}
                value={zipCode}
                onChange={(e) => handleZipCodeChange(e.target.value)}
                className="form-control"
                errorClass="error-validation-msg h-10"
                errorMessage={zipCodeError}
                placeholder="12345"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav">
          <div className="btn-wrap">
            <button
              type="button"
              className="btn btn-primary w-100"
              disabled={checkValid()}
              onClick={(e)=>handleSubmit(e)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentComponent;
