import React, { useState, useEffect } from "react";
import MemberPropertyListCollapse from "../MemberPropertyListCollapse";
import ProgressBarInventory from "./ProgressBarInventory";
import { convertToNumber } from "../../../helpers/validations";
import { ENTIRE_SPACE_INVENTY_TYPE, INVENTORY_KEY, INVENTORY_KEY_TEMP, INVENTORY_TYPE, IS_ENTIRE_PLACE, IS_PRIVATE_ROOM, PRIVATE_ROOM_INVENTY_TYPE } from "../../../constants/common";
import { getPropertyTypes } from "../../../services/commonServices";
import { usePrevious } from "../../../hooks/usePrevious";
import { handleRadioButonChange } from "../../../actions/common";
import Accordion from "react-bootstrap/Accordion";
import { getLocalStorage, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";

const AddProperty = (props) => {
  const { completedPercentage, formValues,uiKey, clearPreviousStates, handleFormValues,isPremiumUser, setCompletedPercentage, isAgent, setUIKey, isEdit} = props;
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", null));
  const propertyTypeInSession = convertToNumber(_.get(getLocalStorage(INVENTORY_KEY), "prprtyType", null));
  const propertySubType = _.get(formValues, "prptySubType", null);
  const propertySubTypeInSession = _.get(getLocalStorage(INVENTORY_KEY), "prptySubType", null);
  const [propertyList, setPropertyList] = useState([]);
  const [propertySubItems, setPropertySubItems] = useState([]);
  const [propertySubItemsEP, setPropertySubItemsEP] = useState([]);
  const [propertySubItemsPS, setPropertySubItemsPS] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const previousValue = usePrevious(propertyType);
  const previousSubTypeValue = usePrevious(propertySubType);
  const propertTypeInSession = _.get(getLocalStorage(INVENTORY_KEY), "prprtyType", "");


  useEffect(() => {
    setBodyBackgroundWhite()
    if (propertyType) {
      if (propertyType !== IS_ENTIRE_PLACE && propertyType !== IS_PRIVATE_ROOM) {      
        setDisableSave(false)
      }
     else {
      const isDisabled = true
      setDisableSave(isDisabled);
    }
  }
    getProperiesSubItems(ENTIRE_SPACE_INVENTY_TYPE);
    getProperiesSubItems(PRIVATE_ROOM_INVENTY_TYPE);
    handlePropertySubTypes();
    getProperties(INVENTORY_TYPE);
  }, []);

  useEffect(()=>{
    if(isEdit){
      if(propertTypeInSession===propertyType && convertToNumber(propertySubType)===propertySubTypeInSession){
        setDisableSave(true)
      }
      else{
        handlePropertySubTypes()
      }
    }
  },[propertyType, propertySubType])

  useEffect(() => { 
    if(!isEdit){handlePropertySubTypes();}
  }, [propertySubItems, propertySubType]);

  const handlePropertySubTypes = () => {
    if (propertyType === IS_ENTIRE_PLACE || propertyType === IS_PRIVATE_ROOM) {
      if (propertySubType) {
        setDisableSave(false);
      } else {
          const isDisabled = true
          setDisableSave(isDisabled);
      }
  } 
};

const getProperties = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getProperiesSubItems = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          if (adType === ENTIRE_SPACE_INVENTY_TYPE) {
            setPropertySubItemsEP(propertyTypes);
          } else if (adType === PRIVATE_ROOM_INVENTY_TYPE) {
            setPropertySubItemsPS(propertyTypes);
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handlePropertyClick = (typeText, value) => {
    const isDisabled = true
    setDisableSave(isDisabled);
    if (value) {
      if (convertToNumber(value) === IS_ENTIRE_PLACE) {
        getProperiesSubItems(ENTIRE_SPACE_INVENTY_TYPE);
      } else if (convertToNumber(value) === IS_PRIVATE_ROOM) {
        getProperiesSubItems(PRIVATE_ROOM_INVENTY_TYPE);
      } else {
        setDisableSave(false);
        setPropertySubItems([]);
      }
      clearPreviousSelectedValues(value);
      handleFormValues({ prprtyType: value, prptyTypeTxt: typeText, prptySubType :'' });
    }
  };

  const handlePropertySubItemClick = (e, text) => {
    const value = handleRadioButonChange(e);
    if (value) {
      setDisableSave(false)
      // clearPreviousSelectedValues(value);
      handleFormValues({ prptySubType: value, subTypeText : text});
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    const isClearPrevious = (convertToNumber(previousValue) !== convertToNumber(currentSelectedValue) || convertToNumber(previousSubTypeValue) !== 
    convertToNumber(currentSelectedValue) )       
    if (isClearPrevious) {
      clearPreviousStates();
    }
  };

  const getSubTypes = (typeId) => {
    if (typeId === 10) {
      return propertySubItemsEP;
    } else if (typeId === 11) {
      return propertySubItemsPS;
    } else return null;
  };

  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const Desc = _.get(pt, "Description", "");
        const subTypes = getSubTypes(convertToNumber(typeId));
        return (
          <div className={propertyType && propertyType!==typeId ?"accordionWrap disabled" : "accordionWrap"}>
            <MemberPropertyListCollapse
                typeText={typeText}
                description={Desc}
                typeId={typeId}
                handlePropertyClick={handlePropertyClick}
                propertySubItems={subTypes}
                handlePropertySubItemClick={handlePropertySubItemClick}
                prptySubType={propertySubType}
                propertyType={propertyType}
                eventKey={typeId.toString()}
            />
          </div>
        );
      });

    return List;
  };
 
  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory 
          setUIKey={setUIKey}
          completedPercentage={completedPercentage} 
          uiKey={uiKey}
          isPremiumUser={isPremiumUser}
          isEdit={isEdit}
          />
          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div className="layout-content-btm-sec position-static pe-0 ps-0">
              <h6 className="">Property type</h6>
              {isAgent ? <h2>What type of property does your client have?</h2>:<h2 className="">What type of property do you have?</h2>}
            </div>

            <div>
                <Accordion 
                className="mb-3"
                defaultActiveKey={propertyType && [propertyType.toString()]}
                >
                   {loadPropertyTypes()}
                </Accordion>             
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button 
              type="button" 
              className="btn btn-primary w-100 mb-0"
              disabled={disableSave}
              onClick={() => {
                if(propertTypeInSession != propertyType){
                    setCompletedPercentage(15)                 
                }
                else{
                  setCompletedPercentage(completedPercentage)
                }
                setTimeout(()=>{
                  setLocalStorage(
                    INVENTORY_KEY,
                    getLocalStorage(INVENTORY_KEY_TEMP)
                  );
                  setUIKey('location');

                },500)                  
              }}
              >
                {isEdit ? "Update" : isAgent ? "Save" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProperty;
