import React, { useState, useEffect } from "react";
import {
  DATE_FORMAT_MM_DD_YYYY,
  LONG_RENT,
  SHORT_RENT,
  STR_DATE_RANGE,
  STR_FLEXIBLE_DATE,
  WANT_AD_KEY,
} from "../../constants/common";
import { getTimeline } from "../../services/commonServices";
import { EXACT_MOVE_DATE_TYPE, LTR_CAL } from "../../constants/createWantAd";
import { convertToNumber } from "../../helpers/validations";
import TextBox from "../../components/Common/TextBox";
import CalendarIcon from "../../images/black-calendar-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formateDate,
  monthNameForFlexibleDate,
  durationForFlexibleDate,
  isValidDate,
  getLocalStorage,
} from "../../helpers/common";

const SelectTimeLineLTR = (props) => {
  const {
    setDisableSave,
    handleFormValues,
    formValues,
    adType,
    clearPreviousStates,
    isEdit,
    dateValError,
    setDateError
  } = props;
  const [timeLineList, setTimeLineList] = useState([]);

  const moveInTimeLine = convertToNumber(
    _.get(formValues, "moveInTimeLine", null)
  );
  const moveInTimeLineInSession = convertToNumber(
    _.get(getLocalStorage((WANT_AD_KEY)), "moveInTimeLine", null)
  );
  
  const moveInDate = _.get(formValues, "startDate", null);

  const moveIndateInSession =  _.get(getLocalStorage(WANT_AD_KEY), "startDate", null);

  const sDate = _.get(formValues, "startDate", null);
  const sDateInSession  = _.get(getLocalStorage((WANT_AD_KEY)), "startDate", null);
  const eDate = _.get(formValues, "endDate", null);
  const eDateInSession  = _.get(getLocalStorage((WANT_AD_KEY)), "endDate", null);

  const dateType = _.get(formValues, "dateType", null);
  const dateTypeInSession  = _.get(getLocalStorage((WANT_AD_KEY)), "dateType", null);
  const duration = _.get(formValues, "duration", null);
  const durationInSession  = _.get(getLocalStorage((WANT_AD_KEY)), "duration", null);
  const month = _.get(formValues, "month", null);
  const monthInSession  = _.get(getLocalStorage((WANT_AD_KEY)), "month", null);
  const isDateRange = adType === SHORT_RENT && dateType === STR_DATE_RANGE;
  const isFlexibleDate =
    adType === SHORT_RENT && dateType === STR_FLEXIBLE_DATE;

  let maxEndDate = new Date();
  if (adType === SHORT_RENT) {
    maxEndDate.setMonth(maxEndDate.getMonth() + 18);
  }

  useEffect(() => {
    let isDisable = false;
    if (adType === LONG_RENT) {
      if(moveInTimeLine === EXACT_MOVE_DATE_TYPE){
        document.getElementById('calenderId')?.scrollIntoView({ behavior: 'smooth' });
      }
      if (!moveInTimeLine) {
        isDisable = true;
      } else {        
        if (moveInTimeLine === EXACT_MOVE_DATE_TYPE && !moveInDate) {
          isDisable = true;
        }        
        else if(isEdit && moveInTimeLine !== EXACT_MOVE_DATE_TYPE && moveInTimeLine === moveInTimeLineInSession){
          isDisable = true
        }
        else if(isEdit && moveInTimeLine === EXACT_MOVE_DATE_TYPE && moveInDate === moveIndateInSession ){
          isDisable = true;
        }
         else {
          isDisable = false;
        }
      }
    } else {
      if (dateType) {
        if(isEdit){
          if (dateType === STR_FLEXIBLE_DATE) {
            if(month && duration){
            if (month == monthInSession && duration == durationInSession) {
              isDisable = true;
            } else {
              isDisable = false;
            }
          }
          else{
            isDisable = true;
          }
          } else {
            if(sDate && eDate){
              if ((sDate == sDateInSession) && (eDate == eDateInSession)) {
                isDisable = true;
              } else {
                isDisable = false;
              }
            }
            else{
              isDisable = true;
            }
          }
        }
        else {
        if (dateType === STR_FLEXIBLE_DATE) {
          if (month && duration) {
            isDisable = false;
          } else {
            isDisable = true;
          }
        } else {
          if (sDate && eDate) {
            isDisable = false;
          } else {
            isDisable = true;
          }
        }}
      }else{
        isDisable= true;
      }
    }
    setDisableSave(isDisable);
  }, [sDate, eDate, moveInTimeLine, month, duration, dateType, moveInDate]);

  useEffect(() => {
    adType === LONG_RENT && getMoveInList();
  }, []);

  const handleDurationClick = (id) => {
    if (id) {
      handleFormValues({ duration: id });
    }
  };

  const handleMonthClick = (id) => {
    if (id) {
      handleFormValues({ month: id });
    }
  };

  const handleDateTypeChange = (value) => {
    if (value === STR_DATE_RANGE) {
      clearPreviousStates({ type: "clearFlexDates" });
    } else {
      clearPreviousStates({ type: "dateRange" });
    }
    handleFormValues({ dateType: value });
  };

  const loadMonths = () => {
    const itemArray = monthNameForFlexibleDate();
    const List = itemArray.map((pt) => {
      const mnth = _.get(pt, "month", "");
      const id = _.get(pt, "id", "");
      const isSelect = month === id;
      const m = new Date().getMonth() + 1; // adding by one because getmonth starts from 0;
      let className = "option-select-tag";
      if (convertToNumber(id) < convertToNumber(m)) {
        className = "option-select-tag disabled";
      } else {
        if (month === id) {
          className = "option-select-tag active";
        }
      }

      return (
        <a
          href={void 0}
          role="button"
          className={className}
          id={id}
          key={id}
          onClick={() => handleMonthClick(id)}
        >
          {mnth}
        </a>
      );
    });

    return List;
  };

  const loadDurations = () => {
    const itemArray = durationForFlexibleDate();
    const List = itemArray.map((pt) => {
      const durtn = _.get(pt, "duration", "");
      const id = _.get(pt, "id", "");
      const isSelect = duration === id;
      return (
        <a
          href={void 0}
          role="button"
          className={
            isSelect ? "option-select-tag active" : "option-select-tag"
          }
          id={id}
          key={id + "_dur"}
          onClick={() => handleDurationClick(id)}
        >
          {durtn}
        </a>
      );
    });

    return List;
  };

  const getMoveInList = () => {
    getTimeline(LTR_CAL)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const timeLines = _.get(response, "result", null);
          setTimeLineList(timeLines);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const moveInCalenderChange = (date) => {
    const formatedDate = date && formateDate(date, "mm/dd/yyyy");
    handleFormValues({
      startDate: formatedDate,
    });
  };

  const handleMoveInTimeLineSelection = (value, text) => {
    if (value !== EXACT_MOVE_DATE_TYPE) {
      clearPreviousStates({ type: "moveInTypeChangeLTR" });
    }
    handleFormValues({ moveInTimeLine: value, moveInTxt: text });
  };

  const loadTimeLines = () => {
    const timeLines =
      timeLineList &&
      timeLineList.map((tl) => {
        const timeLineId = _.get(tl, "TimeLineId");
        const timeLineText = _.get(tl, "TimeLineText", "");
        return (
          <a
            href={void 0}
            id={timeLineId===EXACT_MOVE_DATE_TYPE ? "calenderId" : ""}
            className={
              moveInTimeLine === timeLineId
                ? "createAd-content-wrap mb-4 active"
                : "createAd-content-wrap mb-4"
            }
            onClick={(e) => {
              e.stopPropagation();
              handleMoveInTimeLineSelection(timeLineId, timeLineText);
            }}
          >
            <span className="labelTxt">{timeLineText}</span>
          </a>
        );
      });

    return timeLines;
  };

  function LoadCalenderCustomHeader(props) {
    const {
      adType,
      monthDate,
      customHeaderCount,
      decreaseMonth,
      increaseMonth,
    } = props;
    if (adType === LONG_RENT) {
      return (
        <div>
          {new Date(monthDate).getMonth() + 1 === new Date().getMonth() + 1 &&
          new Date(monthDate).getFullYear() === new Date().getFullYear() ? (
            ""
          ) : (
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
              onClick={decreaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
              >
                {"<"}
              </span>
            </button>
          )}

          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
            onClick={increaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
        </div>
      );
    } else {
      return (
        <div>
          {new Date(monthDate).getMonth() + 1 === new Date().getMonth() + 1 &&
          new Date(monthDate).getFullYear() === new Date().getFullYear() ? (
            ""
          ) : (
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
              onClick={decreaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
              >
                {"<"}
              </span>
            </button>
          )}

          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
            onClick={increaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
        </div>
      );
    }
  }

  const handleMoveInDateChange = (e) => {
    setDateError("")
    const date = e.target.value;
    const validatedDate = date.replace(/[^0-9\-\/]/g, "");
    handleFormValues({ startDate: validatedDate });
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    const formatedStartDate = start && formateDate(start, "mm/dd/yyyy");
    const formatedEndDate = end && formateDate(end, "mm/dd/yyyy");
    handleFormValues({
      startDate: formatedStartDate,
      endDate: formatedEndDate,
    });
  };

  return (
    <div className="title-selection-wrap pe-3 ps-3 pt-0">
      {adType == LONG_RENT && loadTimeLines()}
      {adType == LONG_RENT && moveInTimeLine === EXACT_MOVE_DATE_TYPE && (
        <>
          <div className="wantad-calendar-top position-relative">
            <label>Type or select move-in date</label>

            <TextBox
              type="text"
              errorMessage={dateValError}
              errorClass="error-msg error-height-20"
              class={
                dateValError ? "form-control validation-txtfld" : "form-control"
              }
              value={moveInDate || ""}
              onChange={(e) => {
                handleMoveInDateChange(e);
              }}
              maxLength={10}
            ></TextBox>
            {/* <input type="text" className="form-control" name="" /> */}
            <div className="wantad-calendar-icon">
              <img src={CalendarIcon} alt={CalendarIcon} className="" />
            </div>
          </div>
          <div className="calendar-outer-wrap clearfix">
            <DatePicker
              calendarStartDay={1}
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              renderCustomHeader={({
                monthDate,
                customHeaderCount,
                decreaseMonth,
                increaseMonth,
              }) => (
                <LoadCalenderCustomHeader
                  adType={adType}
                  monthDate={monthDate}
                  customHeaderCount={customHeaderCount}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                />
              )}
              selected={
                moveInDate &&
                isValidDate({
                  dateString: moveInDate,
                  format: DATE_FORMAT_MM_DD_YYYY,
                }) &&
                new Date(moveInDate)
              }
              minDate={new Date()}
              inline
              monthsShown={2}
              onChange={moveInCalenderChange}
              disabledKeyboardNavigation
            />
          </div>
        </>
      )}

      {adType === SHORT_RENT && (
        <div className="date-selectoption-wrap mb-4">
          <div className="row">
            <div className="col-6 pe-2" onClick={(e) => handleDateTypeChange(STR_DATE_RANGE)}>
              <div className={dateType == STR_DATE_RANGE ? "date-selectoption-box active" : "date-selectoption-box"}>Choose Dates</div>
              {/* <label className="radio-wrap mb-3">
                  <input
                    name="SELLd1"
                    type="radio"
                    value={STR_DATE_RANGE}
                    onChange={(e) => handleDateTypeChange(e)}
                    checked={dateType === STR_DATE_RANGE}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0">Choose dates</h2>
                    </div>
                  </span>
                </label> */}
            </div>
            <div className="col-6 ps-2" onClick={(e) => handleDateTypeChange(STR_FLEXIBLE_DATE)}>
              <div className={dateType===STR_FLEXIBLE_DATE ? "date-selectoption-box active" : "date-selectoption-box" }>I'm Flexible</div>
            </div>
            {/* <div className="col-md-6">
                <label className="radio-wrap mb-3">
                  <input
                    name="SELLd1"
                    type="radio"
                    value={STR_FLEXIBLE_DATE}
                    onChange={(e) => handleDateTypeChange(e)}
                    checked={dateType === STR_FLEXIBLE_DATE}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0">I'm flexible</h2>
                    </div>
                  </span>
                </label>
              </div> */}
          </div>
        </div>
      )}
      {adType === SHORT_RENT && isDateRange && (
        <div className="calendar-outer-wrap clearfix">
          <div className="wantad-calendar-top position-relative">
            <ul className="wantad-calendar-list">
              <li>
                <label>Start</label>
                <div class="input-group position-relative">
                  <TextBox
                    type="text"
                    class="form-control"
                    disabled={true}
                    value={sDate ? formateDate(sDate, "mm/dd/yyyy") : ""}
                  ></TextBox>
                  <div className="wantad-calendar-icon">
                    <img src={CalendarIcon} alt={CalendarIcon} className="" />
                  </div>
                </div>
              </li>
              <li>
                <label>End</label>
                <div class="input-group position-relative">
                  <TextBox
                    type="text"
                    disabled={true}
                    class="form-control"
                    value={eDate ? formateDate(eDate, "mm/dd/yyyy") : ""}
                  ></TextBox>
                  <div className="wantad-calendar-icon">
                    <img src={CalendarIcon} alt={CalendarIcon} className="" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <DatePicker
            calendarStartDay={1}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
            }) => (
              <LoadCalenderCustomHeader
                adType={adType}
                monthDate={monthDate}
                customHeaderCount={customHeaderCount}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
              />
            )}
            selected={sDate && new Date(sDate)}
            selectsStart
            selectsEnd
            selectsRange
            startDate={sDate && new Date(sDate)}
            endDate={eDate && new Date(eDate)}
            minDate={new Date()}
            maxDate={maxEndDate}
            inline
            monthsShown={2}
            onChange={onChange}
            disabledKeyboardNavigation
          />
        </div>
      )}
      {adType === SHORT_RENT && isFlexibleDate && (
        <div className="calendar-outer-wrap clearfix">
          <div
            className="flexible-dates-wrap options-column"
            id="flexible-dates-id"
          >
            <h6>I want to stay for a:</h6>
            <div className="option-seletion-wrap mb-4">{loadDurations()}</div>

            <h6 className="mb-3">During the month of:</h6>
            <div className="option-seletion-wrap mw-460 mx-auto">
              {loadMonths()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectTimeLineLTR;
