import React, { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import { getCombinedText } from "../../../helpers/getPropertyIcons";
import MustHavesProperties from "./MustHavesProperties";
import MustHavesPropertiesLTR from "./MustHavePropertiesLTR";
import MustHavesPropertiesPvtRoom from "./MustHavesPropertiesPvtRoom";
import MustHavesPropertiesSTR from "./MustHavePropertiesSTR";
import MustHavesLand from "./MustHavesLand";
import { convertToNumber } from "../../../helpers/validations";
import {
  setLocalStorage,
  getLocalStorage,
  yearValidation,
} from "../../../helpers/common";
import {
  WANT_AD_KEY,
  WANT_AD_KEY_TEMP,
  BUY,
  LONG_RENT,
  DISPLAT_RENTAL,
  DISPLAT_STR,
  IS_ENTIRE_SPACE,
  IS_PRIVATE_ROOM,
  YEAR_BUILT_RANGE,
  IS_LAND,
} from "../../../constants/common";
import {
  lotSizeMaxLesser,
  sqftMaxLesser,
  yrBltMaxLesser,
  yrBltMinGreater,
  yrMaxInValid,
  yrMinInValid,
  yearBuiltRangeMissing,
  yearBuiltMaxMissing,
  yearBuiltMinMissing,
} from "../../../constants/createWantAd";

const AddMustHaves = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isEdit,
    isFromBuyerListingService
  } = props;

  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const propertyType = _.get(formValues, "prprtyType", []);
  const [disableSave, setDisableSave] = useState(true);

  const displayTitle = () => {
    if (adType === BUY) {
      return getCombinedText(prptySubTypes).toLowerCase();
    } else if (adType === LONG_RENT) {
      return DISPLAT_RENTAL;
    } else {
      return DISPLAT_STR;
    }
  };



  const validate = () => {
    let errorSection;
    const mnLotSize = _.get(formValues, "minLtSz", null);
    const mxLotSize = _.get(formValues, "maxLtSz", null);
    if(mnLotSize===null && mxLotSize === null){
      handleFormValues({minLtSz : null, maxLtSz : null});
    }
    const yrBltMin = _.get(formValues, "yrBltMin", "");
    const yrBltMax = _.get(formValues, "yrBltMax", "");
    const sFMin = _.get(formValues, "sqFtMin", "");
    const sFMax = _.get(formValues, "sqFtMax", null);
    const yearBuiltOption = _.get(formValues, "yearBuiltOption", null);
    if (sFMin && sFMax != null) {
      if (convertToNumber(sFMin) >= convertToNumber(sFMax)) {
        errorSection = sqftMaxLesser;
      }
    }
    if (adType === BUY || propertyType === IS_LAND) {
      if (mnLotSize && mxLotSize != null) {
        if (convertToNumber(mnLotSize) >= convertToNumber(mxLotSize)) {
          errorSection = lotSizeMaxLesser;
        }
      }
      if (adType === BUY) {
        if (yearBuiltOption === YEAR_BUILT_RANGE) {
          if (!yrBltMin && !yrBltMax) {
            errorSection = yearBuiltRangeMissing;
          }
          if (yrBltMin && !yrBltMax) {
            errorSection = yearBuiltMaxMissing;
          }
          if (!yrBltMin && yrBltMax) {
            errorSection = yearBuiltMinMissing;
          }

          if (yrBltMin && yrBltMax) {
            if (!yearValidation(yrBltMin.toString())) {
              errorSection = yrMinInValid;
            } else if (!yearValidation(yrBltMax.toString())) {
              errorSection = yrMaxInValid;
            }
            if (convertToNumber(yrBltMax) <= convertToNumber(yrBltMin)) {
              errorSection = yrBltMaxLesser;
            } else if (convertToNumber(yrBltMin) >= convertToNumber(yrBltMax)) {
              errorSection = yrBltMinGreater;
            }
          }
        }
      }
    }
    if (errorSection) {
      handleFormValues({
        wantAdValErrors: errorSection,
        updatedAt: Date.now(),
      }); // 'updatedAt' used for rerendering the component if the form values are not changing.
      return false;
    } else {
      const errorSection = _.get(
        getLocalStorage(WANT_AD_KEY_TEMP),
        "wantAdValErrors"
      );
      if (errorSection) {
        handleFormValues({ wantAdValErrors: "", updatedAt: "" }); // Clear errosSection from state.
      }
      return true;
    }
  };

  const renderMustHaves = () => {
    const isLotLand =
      prptySubTypes?.includes(6) || propertyType === 6 ? true : false;
    if (!isLotLand) {
      if (adType === BUY) {
        return (
          <MustHavesProperties
            formValues={formValues}
            handleFormValues={handleFormValues}
            setDisableSave={setDisableSave}
            clearPreviousStates={clearPreviousStates}
            validate={validate}
            adType={adType}
            isEdit={isEdit}
          />
        );
      } else if (convertToNumber(propertyType) === IS_PRIVATE_ROOM) {
        return (
          <MustHavesPropertiesPvtRoom
            formValues={formValues}
            handleFormValues={handleFormValues}
            setDisableSave={setDisableSave}
            clearPreviousStates={clearPreviousStates}
            adType={adType}
            isEdit={isEdit}
          />
        );
      } else if (adType === LONG_RENT) {
        return (
          <MustHavesPropertiesLTR
            formValues={formValues}
            handleFormValues={handleFormValues}
            setDisableSave={setDisableSave}
            clearPreviousStates={clearPreviousStates}
            adType={adType}
            isEdit={isEdit}
          />
        );
      } else {
        return (
          <MustHavesPropertiesSTR
            formValues={formValues}
            handleFormValues={handleFormValues}
            setDisableSave={setDisableSave}
            clearPreviousStates={clearPreviousStates}
            adType={adType}
            isEdit={isEdit}
          />
        );
      }
    } else {
      return (
        <MustHavesLand
          formValues={formValues}
          handleFormValues={handleFormValues}
          setDisableSave={setDisableSave}
          clearPreviousStates={clearPreviousStates}
          adType={adType}
          validate={validate}
          isEdit={isEdit}
        />
      );
    }
  };

  return (
    <div className="gitcha-main-wrapper pb-102 bg-white h-100">
      <div className="content-wrapper content-wrapper-h-102-percent">
        <ProgressBar
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isEdit={isEdit}
          isFromBuyerListingService={isFromBuyerListingService}
        />
        <div className="inner-layout-content-new bg-white h-100 pe-3 ps-3">
          <div className="top-three-comp-btm-sec mt-0 mb-4">
            <h6>Must-haves</h6>
            <h2>
              {isAgent
                ? "What are your client’s must-haves for their "
                : "What are the must-haves for your "}
              {displayTitle()}?
            </h2>
          </div>
          {renderMustHaves()}
        </div>
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav">
          <div className="btn-wrap text-center pb-40 pt-0">
            <button
              type="button"
              className="btn btn-primary w-100 mb-0"
              disabled={disableSave}
              onClick={() => {
                if (validate()) {
                  if (isAgent) {
                    if (completedPercentage > 50) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(50);
                    }
                  } else {
                    if (completedPercentage > 63) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(63);
                    }
                  }
                  setTimeout(() => {
                    setLocalStorage(
                      WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );                    
                    setUIKey(null);
                  }, 500);
                }
              }}
            >
              {isEdit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMustHaves;
