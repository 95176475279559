import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sseData : null,
};

export const sseDataSlice = createSlice({
    name: 'sseData',
    initialState: initialState,
    reducers: {
      setsseData: (state, payload) => {
        state.sseData = payload
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setsseData } = sseDataSlice.actions
  
  export default sseDataSlice.reducer

