import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import _ from "lodash";
import TextBox from "../../../components/Common/TextBox";
import { emailValidation, nameValidation, textValidations } from "../../../helpers/validations";
import TextArea from "../../../components/Common/TextArea";
import { handleTextBoxChange } from "../../../actions/common";
import { getLocalStorage } from "../../../helpers/common";
import { USER_INFO_KEY } from "../../../constants/common";
import { saveGitchaContactUs } from "../../../services/commonServices";
import { toast } from "react-toastify";

const ContactModal = (props) => {
  const { sourceType, show, process, onHide } = props;
  
  const initialData = {
    firstName: "",
    lastName: "",
    email:  getLocalStorage(USER_INFO_KEY)?.userInfo?.email,
    message: '',
  };

  //form States
  const [formData, setFormData] = useState(initialData);
  const [formErrorMessage, setFormErrorMessage] = useState(initialData);
  const [disableButton, setDisableButton] = useState(true);
  const [descError, setDescError] = useState(null);
  const [descCharCount, setDescCharCount] = useState(0);
  
/**
  * To handle textbox value change
  * @param {any} event 
  */
const onChangeText = (event) => {
  const value = event.target.name === 'email' ? handleTextBoxChange(event) :
    capitalizeFirstString(handleTextBoxChange(event))
    
  setFormData(formData => ({ ...formData, [event.target.name]: value }));
  //  setFormErrorMessage(validateForm({ ...formData, [event.target.name]: value }));
  // validateForm({ ...formData, [event.target.name]: value })
  if (event.target.name == 'firstName') {
    delete formErrorMessage.firstName;
  }
  if (event.target.name == 'lastName') {
    delete formErrorMessage.lastName;
  }
  if (event.target.name == 'message') {
    setDescError(null)
    setDescCharCount(value.length);

  }
  // setFormErrorMessage({...formErrorMessageData})
  checkSubmitButtonEnableStatus({ ...formData, [event.target.name]: value })
}

const checkSubmitButtonEnableStatus = (data) => {
  if ((data.firstName || data.firstName.trim() != "") && (data.lastName || data.lastName.trim() != "") &&
  (data.email || data.email.trim() != "") && (data.message || data.message.trim() != "")) {
    setDisableButton(false)
  } else {
    setDisableButton(true)
  }
}


/**
 * to validate add client form
 * @param {json} data 
 * @returns json
 */
const validateForm = (data) => {
  let errors = {};
  if (!data.firstName || data.firstName.trim() == "") {
    errors.firstName = 'Enter First Name';
  }
  if (!nameValidation(data.firstName)) {
    errors.firstName = 'Invalid input';
  }

  if (!data.lastName || data.lastName.trim() == "") {
    errors.lastName = 'Enter Last Name';
  }
  if (!nameValidation(data.lastName)) {
    errors.lastName = 'Invalid input';
  }
  if (!data.email || data.email.trim() == "") {
    errors.emailError = 'Enter Email';
  } else{
    if (!emailValidation(data.email.trim())) {
      errors.emailError = 'Enter Valid Email';
    }

  }

  const message = data.message;
  if (!message || message .trim() == "") {
    errors.message = 'Enter Message';
  } else {
    if (!textValidations(message)) {
      errors.message = ERROR_TEXT
      setDescError(ERROR_TEXT)
    }
  }
  return errors;
};

const capitalizeFirstString = (str) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

const submitForm = (formData) => {
  let error = validateForm({ ...formData })
  setFormErrorMessage(error);
  if (Object.keys(error).length == 0) {
    setDisableButton(false)
    saveGitchaContactUsData(formData)
  } else {
    setDisableButton(true)
  }
}

const saveGitchaContactUsData = async (formData) =>{
  var updatedData = {
    ...formData,
    sourceType: sourceType
  }
  await saveGitchaContactUs(updatedData)
    .then((response) => {
      if (response.errorCode == 0) {
        toast.success(response.message);
        onHide();
      } else {
        toast.error(response.message);
      }
    })
    .catch((err) => {
      console.log("Error", err);
    });


}

  return (
    <>
     <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={() => {
        setFormData(initialData)
        setFormErrorMessage('')
        setDisableButton(true)
      }}
     
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>onHide({modalState: false})}

        ></button>
        <h3 className="text-start">How can we help you?</h3>
        <Row>
          <Col xl={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">First name</label>
              {/* <input
                placeholder="First name"
                type="text"
                className="form-control"
                onBlur={handleFirstNamelOnBlur}
              ></input> */}

              <TextBox
                id="fName"
                name="firstName"
                type="text"
                value={formData.firstName}
                placeholder="First name"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.firstName}
                maxLength={35}

              />

            </div>
          </Col>

          <Col xl={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Last name</label>
              {/* <input
                placeholder="Last name"
                type="email"
                className="form-control"
                onBlur={handleLastNamelOnBlur}
              ></input> */}

              <TextBox
                id="lName"
                name="lastName"
                type="text"
                value={formData.lastName}
                placeholder="Last name"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.lastName}
                maxLength={35}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Email</label>
              <TextBox
                id="email"
                name="email"
                type="text"
                value={formData.email}
                placeholder="Email"
                className="form-control"
                onChange={(e) => {
                  onChangeText(e);
                }}
                errorMessage={formErrorMessage.emailError}
                maxLength={35}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-4 mb-sm-4">
              <label className="form-label">Message</label>
              <TextArea
                name='message'
                className="form-control txt-area"
                errorMessage={descError}
                value={formData.message}
                maxLength={250}
                onChange={(e) => {
                  onChangeText(e);
                }}
              />

              {/* <textarea class="form-control modal-txtarea" placeholder="Additional message about client"></textarea> */}
              <div className="des-count align-self-end">{descCharCount}/250</div>
            </div>
          </Col>
        </Row>



        <Button
          variant="primary"
          className="btn w-100"
          onClick={() => {
            submitForm(formData)
          }}
          disabled={disableButton} >
          Send message
        </Button>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
    </>
  );
};
export default ContactModal;
