import noImage from "../../../images/no-image-placeholder.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pageURLs } from "../../../constants/pageURLs";
import { useState, useEffect } from "react";
import { getUserId, removeLocalStorage } from "../../../helpers/common";
import { fetchUserStatistics } from "../../../actions/userprofile";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP } from "../../../constants/common";
import AddIcon from "../addIcn";
import Offcanvas from 'react-bootstrap/Offcanvas';

const SelectProperty = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    propertyList,
    selectedProperty,
    setSelectedProperty,
    userType,
    selectedClient,
    userDetails,
    setShowSelectProperty,
    showSelectProperty
  } = props;

  const userId = getUserId();
  const userStatictics = useSelector((state) => state?.userProfile?.userStatictics)
  const userPrivileges = useSelector((state) => state?.userProfile?.userPrivileges)
  const [userCount, setUserCount] = useState({})
  const [allowedCount, setAllowedCount] = useState({})

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id
    }
    dispatch(fetchUserStatistics(payload))
  }, [userDetails])

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);


  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  const handleAddProperty = () => {
    if (userCount?.Inventory >= allowedCount?.Inventory) {
      navigate(pageURLs.updatePlan, {
        state: { isFromSettings: true },
      })
    }
    else {
      removeLocalStorage(INVENTORY_KEY);
      removeLocalStorage(INVENTORY_KEY_TEMP);
      navigate(pageURLs.mobileCreateInventory,{
        state: { isFromMatch: true },
      });
    }
  };

  return (
    <>
      <Offcanvas
        className="mobile-offcanvas zIndexPopup offcanvas-selectclient-height-fix offcanvas-filter-share-fix"
        show={showSelectProperty}
        onHide={setShowSelectProperty}
        placement='bottom'
        {...props}>
        <Offcanvas.Header className="filter-offcanvas-header">
          <div className="share-yourad-topsec justify-content-center w-100 pt-32 mb-20">
            <div className="share-yourad-heading w-100 mt-0 mb-0">Choose Properties</div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-sharead-wrap ">
            <div className="popupStyle">
              <div className="ps-3 pe-3 pb-3">
              </div>
              <div>
                <div class="modal-choose-property mb-0 ">
                  {propertyList?.map((property) => {
                    return (
                      <label class="property-radio">
                        <input
                          type="radio"
                          name="check-box-option"
                          id="670"
                          onChange={() => {
                            setSelectedProperty(property);
                            props.setShowSelectProperty(false);
                          }}
                          checked={selectedProperty?.Inv_Id === property?.Inv_Id}
                          value={property?.Inv_Id}
                        />

                        <span className="bdrRadius-0 ps-3 pe-3 ">
                          <div className="add-your-property-mob propPopup">
                            <div className="add-property-img-mob">
                              <img src={property?.photo ? property?.photo : noImage} />
                            </div>
                            <div className="add-property-details-mob ">
                              <h3>{property?.Location}</h3>
                              <p>{property?.City}, {property?.State}</p>
                            </div>
                          </div>
                        </span>
                      </label>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="inner-btm-fixed-nav filter-btm-fixed-btnswrap">
              <div className="btn-wrap text-center pt-0">
                <button type="button" className="btn btn-primary addNew  w-100 mb-40 add-new-client-btn " onClick={handleAddProperty}>Add New Property <AddIcon /></button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SelectProperty;
