import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import RadioButtonGroup from "../../Common/RadioButtonGroup";
import {
  BOTH_TITLE,
  PROPERTY_MANAGER,
  REAL_ESTATE_AGENT,
  USER_INFO_KEY,
} from "../../../constants/common";
import { getLocalStorage } from "../../../helpers/common";

const AgentTitleModal = (props) => {
  const {
    name,
    show,
    handleAgentContinueClick,
    handleTitleClick,
    selectedTitle,
    titleContinueState,
    handleUserTypeModal,
    setAgentTitleModal
  } = props;
  const itemArray = [
    { text: "Real Estate Agent", id: REAL_ESTATE_AGENT },
    { text: "Property Manager", id: PROPERTY_MANAGER },
    { text: "Both", id: BOTH_TITLE },
  ];
 const title = selectedTitle || _.get(getLocalStorage(USER_INFO_KEY), 'userInfo.agentTitle')
  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal-custom-width"
    >
      <Modal.Body className="pb-32 pt-32">
        <a role="button" href={void 0} className="bottom-back-btn" onClick={()=>{handleUserTypeModal({showModal: true}); setAgentTitleModal(false)}}>
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                fill="#205ECA"
              />
            </svg>
          </span>
          Back
        </a>
        <br /> <br />
        <h3 className="text-start">Let’s create your profile!</h3>
        <p className="mb-30 ln-24 f-size-16 para-color-first">
        We’re here to help you meet your real estate goals. Let’s get started by setting up your profile. 
        </p>
        <p className="f-size-16 f-medium mb-3 para-color-second">
          What's your current title?
        </p>
        <div className="wantad-timing-wrap">
          <RadioButtonGroup
            list={itemArray}
            onChange={(e) => handleTitleClick(e)}
            selectedValue={title}
          />
        </div>
        <Button
          variant="primary"
          className={
            title ? "btn w-100 mb-2" : "btn w-100 mb-2 disabled"
          }
          onClick={() => handleAgentContinueClick()}
        >
          Continue
        </Button>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default AgentTitleModal;
