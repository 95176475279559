import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AddPropertyIcon from "../../../images/add-property-plus-icon.svg";
import PropertyIntroIcon from "../../../images/property-introduction-icon.svg";
import PropertyEyeIcon from "../../../images/property-eye-icon.svg";
import PropertyMsgIcon from "../../../images/property-msg-icon.svg";
import PropertyMatchedIcon from "../../../images/property-matched-icon.svg";
import PropertyPrivateIcon from "../../../images/property-private-icon.svg";
import { pageURLs } from "../../../constants/pageURLs";
import { getUserId, getUserType } from "../../../helpers/common";
import { fetchUserStatistics } from "../../../actions/userprofile";
import { USER_TYPE_AGENT } from "../../../constants/common";
import PremiumPlanModal from "../../../components/PlanUpgrade/PremiumPlanModal";
import BasicPlanModal from "../../../components/PlanUpgrade/BasicPlanModal";

const AddProperty = (props) => {
  const {userDetails, selectedClient} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getUserId();
  const userStatictics = useSelector(
    (state) => state?.userProfile?.userStatictics
  );
  const userPrivileges = useSelector(
    (state) => state?.userProfile?.userPrivileges
  );
  const [userCount, setUserCount] = useState({});
  const [allowedCount, setAllowedCount] = useState({});
  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);
  const [showBasicPlanModal, setBasicPlanModal] = useState(false);
  const BasicPlanModalClose = () => setBasicPlanModal(false);
  const BasicPlanModalShow = () => setBasicPlanModal(true);
  const [reload, setReload] = useState(0);
  const userType = getUserType();

  useEffect(() => {
    const payload = {
      UserPlanId: userDetails?.User_Plan_Id,
    };

    dispatch(fetchUserStatistics(payload));
  }, [userDetails]);

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);

  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  const handleAddProperty = () => {
    if (userCount?.Inventory >= allowedCount?.Inventory) {
      if (userType === USER_TYPE_AGENT) {
        PremiumPlanModalShow();
      } else {
        BasicPlanModalShow();
      }
    } else {
      if (userType === USER_TYPE_AGENT) {
        navigate(pageURLs.agentCreateInventory,  { state: { data: {clientId: selectedClient?.value, clientName: selectedClient?.label, isAgentAsSeller : selectedClient?.AgentAsSeller} } })
      } else {
        navigate(pageURLs.memberCreateInventory);
      }
    }
  };

  return (
    <>
      <a
       className="add-your-property-wrap link-wrap"
        href={void(0)}
        onClick={() => handleAddProperty()}
      >
        <div className="add-property-btn">
          <img src={AddPropertyIcon} alt={AddPropertyIcon} />
        </div>
        <div className="make-intro-details">
          <h3 className="mb-0">Add your property</h3>
        </div>
        <span role="button" class="right-arrow-link" href={void 0}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
              fill="#000022"
            />
          </svg>
        </span>
      </a>
      <div className="property-add-wrap">
        <h6>Why add your property to Gitcha?</h6>
        <ul className="property-add-list">
          <li>
            <div className="property-wrap-icn">
              <img src={PropertyPrivateIcon} />
            </div>
            <div className="property-wrap-text">
            Privately store in your dashboard, no one will see it unless you choose to introduce it
            </div>
          </li>
          <li>
            <div className="property-wrap-icn">
              <img src={PropertyEyeIcon} />
            </div>
            <div className="property-wrap-text">
              Not a live listing on the MLS
            </div>
          </li>
          <li>
            <div className="property-wrap-icn">
            <img src={PropertyMatchedIcon} />
             </div>
            <div className="property-wrap-text">
            Get matched to want-ads</div>
          </li>
          <li>
            <div className="property-wrap-icn">
              <img src={PropertyIntroIcon} />
            </div>
            <div className="property-wrap-text">
            Make private introductions to buyers and renters
            </div>
          </li>
        </ul>
       {/* <p>
          No one other than the recipient will see your property.{" "}
          <a href="#">Learn more</a>
  </p>*/}
      </div>
      <PremiumPlanModal
        userDetails={userDetails}
        updateReloadParam={updateReloadParam}
        show={showPremiumPlanModal}
        onHide={PremiumPlanModalClose}
        fromInventoryListing={true}
        showPlanModal={PremiumPlanModalShow}
      />
      <BasicPlanModal
        userDetails={userDetails}
        show={showBasicPlanModal}
        updateReloadParam={updateReloadParam}
        onHide={BasicPlanModalClose}
        fromInventoryListing={true}
        showPlanModal={BasicPlanModalShow}
      />
    </>
  );
};

export default AddProperty;
