import React, { useRef, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Circle,
  Polygon
} from "@react-google-maps/api";
import mapPinIcon from "../../images/map-icon.png";
import {
  MAP_EVENT_CLICK,
  MAP_EVENT_DRAG,
  MAP_EVENT_MARKER_DRAG,
  MAP_TYPE_ROADMAP,
} from "../../constants/common";
import { getMeters } from "../../helpers/common";
import { getBoundaryCoordinates } from "../../services/wantAdService";
import Loader from "../Common/Loader";

const WantAdGoogleMap = (props) => {
  const {
    defaultProps,
    mapHeight,
    mapWidth,
    handleFormValues,
    getAddresseDetails,
    handleShowMarker,
    showMarker,
    mapType,
    mapRadius,
    showPolygon,
    zipCode,
  } = props;

  const googleMapApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const mapRef = useRef(null);
  const radius = mapRadius && getMeters(mapRadius);

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // for checking map is loaded in the UI.
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapApiKey,
  });



  const circleOptions = {
    strokeColor: "#338BA8",
    strokeOpacity: 0,
    strokeWeight: 1,
    fillColor: "#338BA8",
    fillOpacity: 0.35,
  };

  const mapOptions = {
    mapTypeControl: false, // This will specifically hide the map type controls like satellite, road map etc
    streetViewControl: false, // Hide Pegman (Street View icon)
  };


  const mapContainerStyle = {
    width: mapWidth,
    height: mapHeight,
  };

  // Handles the map coords changes
  const handleMapCoordsChanges = async ({ coords, eventType = false }) => {
    const lat = coords.lat();
    const lng = coords.lng();
    if (eventType == MAP_EVENT_DRAG) {
      handleFormValues({ locAddress: "" });
    } else {
      await getAddresseDetails({
        eventType: eventType,
        reqPayload: `latlng=${lat},${lng}`,
        lat: lat,
        lng: lng,
      });
    }

    handleFormValues({ lat: lat, lng: lng });
  };

  //Handle zoom leveles while clicking zoom in/out buttons
  const handleZoomLevel = () => {
    const zoom = mapRef.current.getZoom();
    //HandleMapClickOrDrag(false);
    handleShowMarker(true);
    handleFormValues({ mapZoom: zoom });
  };


  // Handling cliks on the map for pointing the pin
  const handleMapClick = async (e) => {
    //HandleMapClickOrDrag(false);
    handleShowMarker(true);
    handleMapCoordsChanges({ coords: e.latLng, eventType: MAP_EVENT_CLICK });
  };

  // Map type click like satellight, road map. Currently these options are hided.
  const handleMapTypeClick = () => {
    const mapRefCurrentExist = _.get(mapRef, "current", null);
    if (mapRefCurrentExist) {
      const mapeTypeId = mapRef.current.getMapTypeId();
      handleFormValues({ mapType: mapeTypeId });
    }
  };

  // Handles marker dragging
  const handleMarkerDragEnd = (e) => {
    //HandleMapClickOrDrag(true);
    handleShowMarker(true);
    handleMapCoordsChanges({
      coords: e.latLng,
      eventType: MAP_EVENT_MARKER_DRAG,
    });
  };

  // Handles map dragging end
  const handleMapDragEnd = () => {
    handleShowMarker(false);
    handleFormValues({
      location: "",
      city: "",
      state: "",
      zip: "",
      mapRadius: "",
    });
    //HandleMapClickOrDrag(false);
    handleMapCoordsChanges({
      coords: mapRef.current.getCenter(),
      eventType: MAP_EVENT_DRAG,
    });
  };

  // Handles map dragging start
  const handleMapDragStart = () => {
    handleShowMarker(false);
  };

  useEffect(() => {
    if (zipCode?.length > 0) {
      setIsLoading(true)
      getBoundaryCoordinatesOnFocusOut();
    } else {
      setPolygonCoordinates([])
    }

  }, [zipCode])
  /**   
   * To get the boundary coordinates for ploting polygon
   */
  const getBoundaryCoordinatesOnFocusOut = () => {
    var payload = [];
    zipCode.map(function (item) {
      payload.push(item?.zipCode);
    });
    getBoundaryCoordinates({ zipCode: payload })
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          let coordinates = [];
          let zipValues = [];
          responseResult.map((result) => {
            zipValues.push({ zipCode: result?.ZipCode, zipId: result?.ZipId })



            coordinates.push([result?.coordinates])
          })
          handleFormValues({ zipIdValues: zipValues, focusOut: true })
          setPolygonCoordinates(coordinates)
          setIsLoading(false)

          // setShowPolygon(true)
          // setShowMarker(false)
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  // Function to create a path from coordinates
  const createPath = (coords) => {
    return coords.map((coord) => ({
      lat: coord.lat,
      lng: coord.lng,
    }));
  };


  useEffect(() => {
    if (polygonCoordinates?.length > 0 && showPolygon) {
      fitBoundsToPolygon();
    }

  }, [polygonCoordinates])
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 40.712, lng: -74.006 });
  // Function to fit the map to the polygon with optimal zoom
  const fitBoundsToPolygon = () => {
    if (mapRef.current && polygonCoordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      polygonCoordinates.forEach(polygon => {
        polygon[0].forEach(path => {
          bounds.extend(path);
        });
      });

      // Calculate the center and zoom for the bounds
      const newCenter = getCenter(bounds);
      const newZoom = calculateZoomLevel(bounds);

      setCenter(newCenter);
      setZoom(newZoom);
    }
  };

  // Calculate the center of the bounding box
  const getCenter = bounds => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    return {
      lat: (ne.lat() + sw.lat()) / 2,
      lng: (ne.lng() + sw.lng()) / 2
    };
  };

  // Calculate a suitable zoom level based on the polygon bounds
  const calculateZoomLevel = bounds => {
    const GLOBE_WIDTH = 256; // Width of the Google Maps globe
    const ZOOM_MAX = 19; // Maximum zoom level

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latDiff = ne.lat() - sw.lat();
    const lngDiff = ne.lng() - sw.lng();

    let zoom = Math.floor(Math.log2((GLOBE_WIDTH * 360) / lngDiff / 256));
    zoom = Math.min(Math.max(0, zoom), ZOOM_MAX);
    return zoom;
  };



  return (
    <>
      {isLoading ? <Loader /> : ""}
      {
        isLoaded &&
        _.get(defaultProps.center, "lat", "") && (
          <GoogleMap
            mapTypeId={mapType || MAP_TYPE_ROADMAP}
            mapContainerStyle={mapContainerStyle}
            center={showPolygon ? center : defaultProps.center}
            zoom={showPolygon ? zoom : defaultProps.zoom}
            onClick={handleMapClick}
            onDragEnd={handleMapDragEnd}
            //onDragStart={handleMapDragStart}
            onLoad={(map) => {
              mapRef.current = map;
              mapRef.current.addListener("zoom_changed", handleZoomLevel);
              fitBoundsToPolygon();
            }}
            mapContainerClassName="wantad-map-wrap overflow-hidden"
            options={mapOptions}
          >
            {showMarker && !showPolygon && <Marker
              position={defaultProps.center}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
              fillColor="#000000"
              icon={{
                url: mapPinIcon,
              }}
            />}
            {mapRadius && (
              <Circle
                center={defaultProps.center}
                radius={radius}
                options={circleOptions}
              />
            )}

            {/* Loop through polygons and render them */}
            {showPolygon && (
              polygonCoordinates.map((polygon, index) => (
                <Polygon
                  key={index}
                  path={createPath(polygon[0])}
                  options={{
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FFFFFF",
                    fillOpacity: 0
                  }}
                />
              ))
            )}


          </GoogleMap>


        )
      }
    </>
  );
};
export default WantAdGoogleMap;
