import React from "react";
import PwdToggleOpen from "../../../images/password-toggle-icon.svg";
import PwdToggleClosed from "../../../images/password-toggle-icon-closed.svg";
import {
  emailValidation,
  passwordValidation,
} from "../../../helpers/validations";
import Button from "react-bootstrap/Button";
import TextBox from "../../../components/Common/TextBox";
import ValidationAlertIcon from "../../images/validation-alert-icon.svg";
const ChangePassword = (props) => {
  const {
    formData,
    formErrors,
    onTextChange,
    handleSubmit,
    handleBackButtonClick,
    handlePassWordShow,
    isShowPassWord,
    passwordType,
  } = props;

  const password = _.get(formData, "pwd", "");
  const passwordError = _.get(formErrors, "pwd", "");
  const confPassword = _.get(formData, "confPwd", "");
  const confPwdError = _.get(formErrors, "confPwd", "");

  return (
    <div className="gitcha-main-wrapper pb-0 bg-grey-variant">
      <div className="content-wrapper vh-100">
        <div className="inner-layout-header-new bg-transparent">
          <div className="review-plan-header w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              <a
                className="back-link"
                href={void 0}
                role="button"
                onClick={() => handleBackButtonClick()}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new h-100">
          <div className="get-agent-wrap clearfix">
            <h2>Reset Password</h2>
            <p className="mb-3">Enter your new password to continue.</p>

            <div className="mob-fld-wrap position-relative mb-3">
              <label className="form-label">Password</label>

              <TextBox
                name="pwd"
                type={passwordType}
                value={password}
                notShowError = {true}
                placeholder="Create a Password"
                class={
                  passwordError
                    ? "form-control mob-pr-44 validation-alert-brdr"
                    : "form-control mob-pr-44"
                }
                onChange={(e) => {
                  onTextChange(e);
                }}
              />
              {password && !passwordError && (
                <Button
                  variant=""
                  className="btn-pwd-mob-toggle"
                  onClick={() => handlePassWordShow()}
                >
                  <img src={isShowPassWord ? PwdToggleClosed : PwdToggleOpen} />
                </Button>
              )}
              {passwordError && (
                <div className="validation-alert">
                  <img src={ValidationAlertIcon} />
                </div>
              )}

              <div className="mob-error-msg">{passwordError}</div>
            </div>

            <div className="mob-fld-wrap position-relative mb-3">
              <label className="form-label">Confirm Password</label>
            

              <TextBox
                name="confPwd"
                type="password"
                value={confPassword}
                placeholder="Confirm Password"
                notShowError = {true}
                class={
                  confPwdError
                    ? "form-control mob-pr-44 validation-alert-brdr"
                    : "form-control mob-pr-44"
                }
                onChange={(e) => {
                  onTextChange(e);
                }}
              />

              {confPwdError && (
                <div className="validation-alert">
                  <img src={ValidationAlertIcon} />
                </div>
              )}

              <div className="mob-error-msg">{confPwdError}</div>
            </div>

            {/*<a href="#" className="fgt-psd-link d-inline-block mb-4" >Forgot your password?</a>*/}

            <div className="btn-wrap pb-4 pt-2">
              <button
                type="button"
                class="btn btn-primary w-100 mb-0"
                disabled={password && confPassword ? false : true}
                onClick={() => handleSubmit()}
              >
                Continue
              </button>
              {/* <button type="button" class="btn btn-outline-primary w-100 mb-4"><img src={GoogleLogo} alt={GoogleLogo} className="me-2" /> <span className="text-placement">Continue with google</span></button>
                          <button type="button" class="btn btn-secondary-blue w-100">Sign up</button> */}
            </div>

            {/* <div className="signup-btm-wrap">
          <p>By signing up, you agree to <a href="#">Terms</a> and <a href="#">Privacy Policy.</a></p>
        </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
