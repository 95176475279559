import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

import GitchaFooterLogo from "../../images/gitcha-footer-logo.svg";
import { marketingSiteURL, siteBaseURL } from "../../constants/endPoints";

const SiteFooter = (props) => {
  const {show} = props
  return (
    <div className="footer-wrapper">
    <footer id="footer-container" className="footer-inner-wrapper">
        <div className="footer-sec">
          <div className="footer-left-sec">
            <div className="footer-logo">
              <a href={marketingSiteURL} target="_blank">
                <img src={GitchaFooterLogo} />
              </a>
            </div>
          </div>
          <ul class="social-btns-list">
            <li>
              <a href="https://www.facebook.com/gitchaofficial" target="_blank">
                <svg
                 width="8" height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.11653 14V7.43079H0.32959V5.06556H2.11653V3.04535C2.11653 1.45785 3.17208 0 5.6043 0C6.58906 0 7.31725 0.09177 7.31725 0.09177L7.25987 2.30049C7.25987 2.30049 6.51724 2.29347 5.70684 2.29347C4.82974 2.29347 4.68922 2.68638 4.68922 3.33851V5.06556H7.32959L7.21471 7.43079H4.68922V14H2.11653Z"
                    fill="#FDFDFD"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/GitchaOfficial" target="_blank">
                <svg
                 width="16" height="11"
                  viewBox="0 0 19 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_22571_88917)">
                    <path
                      d="M18.8296 1.70441C18.1556 1.98888 17.4406 2.17561 16.7085 2.25838C17.4711 1.82297 18.0566 1.13349 18.3323 0.311979C17.6074 0.721787 16.8143 1.01052 15.9873 1.16571C15.3136 0.481989 14.3539 0.0546875 13.2917 0.0546875C11.2522 0.0546875 9.59868 1.62992 9.59868 3.57277C9.59868 3.84855 9.63138 4.11703 9.69431 4.37459C6.62517 4.22782 3.90407 2.82722 2.08263 0.698687C1.76482 1.21829 1.58271 1.82271 1.58271 2.46738C1.58271 3.68799 2.23471 4.76478 3.22556 5.39572C2.63912 5.37822 2.06558 5.22732 1.55282 4.95562C1.55261 4.97036 1.55261 4.9851 1.55261 4.9999C1.55261 6.70448 2.82555 8.12645 4.51488 8.44966C3.97107 8.59056 3.40066 8.61118 2.84721 8.50995C3.31711 9.90767 4.68096 10.9248 6.29688 10.9532C5.03301 11.8968 3.44064 12.4593 1.71054 12.4593C1.41241 12.4593 1.1185 12.4426 0.82959 12.4101C2.46386 13.4083 4.40498 13.9908 6.49045 13.9908C13.2831 13.9908 16.9975 8.62985 16.9975 3.98078C16.9975 3.82819 16.994 3.67647 16.9868 3.52561C17.7098 3.0277 18.3338 2.41098 18.8296 1.70441Z"
                      fill="#FDFDFD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_22571_88917">
                      <rect
                        width="18"
                        height="14"
                        fill="white"
                        transform="translate(0.82959)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEAPfJAePbjuQAAAYutYiPgp3KT9D0kNOsB1CuF4HIT572ED_9JAFmKV5DD6JQvxeG6u57rcx7dtDjQ-BMZ6JWQZ-ctQOtH4XyM2Srr7RR8PBwKfIhcXPFzP0SVw_5i-MZ9vDo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fgitchaofficial"
                    target="_blank">
                <svg
                 width="16" height="15"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_22571_88920)">
                    <path
                      d="M4.46228 15.5V5.37953H1.03198V15.5H4.46228ZM2.74757 3.99692C3.94378 3.99692 4.68836 3.21978 4.68836 2.24862C4.66607 1.25556 3.94382 0.5 2.77027 0.5C1.5969 0.5 0.82959 1.25558 0.82959 2.24862C0.82959 3.21983 1.57399 3.99692 2.72518 3.99692H2.74746H2.74757ZM6.36094 15.5H9.79124V9.84824C9.79124 9.54576 9.81353 9.2436 9.90411 9.02738C10.1521 8.42304 10.7165 7.79712 11.6641 7.79712C12.9054 7.79712 13.402 8.7252 13.402 10.0857V15.4999H16.8321V9.69695C16.8321 6.58834 15.1397 5.14193 12.8828 5.14193C11.0323 5.14193 10.2198 6.15626 9.76842 6.84712H9.79132V5.37932H6.36102C6.40603 6.32897 6.36102 15.4998 6.36102 15.4998L6.36094 15.5Z"
                      fill="#FDFDFD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_22571_88920">
                      <rect
                        width="16"
                        height="15"
                        fill="white"
                        transform="translate(0.82959 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
            <li>
              <a  href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fgitchaofficial%2F"
                    target="_blank">
                <svg
                 width="16" height="17"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_22571_88923)">
                    <path
                      d="M8.83339 4.95732C6.56197 4.95732 4.72982 6.76084 4.72982 8.99677C4.72982 11.2327 6.56197 13.0362 8.83339 13.0362C11.1048 13.0362 12.937 11.2327 12.937 8.99677C12.937 6.76084 11.1048 4.95732 8.83339 4.95732ZM8.83339 11.6229C7.36554 11.6229 6.16554 10.4452 6.16554 8.99677C6.16554 7.54834 7.36197 6.3706 8.83339 6.3706C10.3048 6.3706 11.5012 7.54834 11.5012 8.99677C11.5012 10.4452 10.3012 11.6229 8.83339 11.6229ZM14.062 4.79209C14.062 5.31592 13.6334 5.73428 13.1048 5.73428C12.5727 5.73428 12.1477 5.3124 12.1477 4.79209C12.1477 4.27178 12.5762 3.8499 13.1048 3.8499C13.6334 3.8499 14.062 4.27178 14.062 4.79209ZM16.7798 5.74834C16.7191 4.48623 16.4262 3.36826 15.487 2.44717C14.5512 1.52607 13.4155 1.23779 12.1334 1.17451C10.812 1.10068 6.85125 1.10068 5.52982 1.17451C4.25125 1.23428 3.11554 1.52256 2.17625 2.44365C1.23697 3.36474 0.947684 4.48271 0.883398 5.74482C0.808398 7.0456 0.808398 10.9444 0.883398 12.2452C0.944113 13.5073 1.23697 14.6253 2.17625 15.5464C3.11554 16.4675 4.24768 16.7558 5.52982 16.819C6.85125 16.8929 10.812 16.8929 12.1334 16.819C13.4155 16.7593 14.5512 16.471 15.487 15.5464C16.4227 14.6253 16.7155 13.5073 16.7798 12.2452C16.8548 10.9444 16.8548 7.04912 16.7798 5.74834ZM15.0727 13.6409C14.7941 14.33 14.2548 14.8608 13.5512 15.1386C12.4977 15.5499 9.99768 15.455 8.83339 15.455C7.66911 15.455 5.16554 15.5464 4.11554 15.1386C3.41554 14.8643 2.87625 14.3335 2.59411 13.6409C2.17625 12.6038 2.27268 10.1429 2.27268 8.99677C2.27268 7.85068 2.17983 5.38623 2.59411 4.35263C2.87268 3.66357 3.41197 3.13271 4.11554 2.85498C5.16911 2.44365 7.66911 2.53857 8.83339 2.53857C9.99768 2.53857 12.5012 2.44717 13.5512 2.85498C14.2512 3.1292 14.7905 3.66006 15.0727 4.35263C15.4905 5.38974 15.3941 7.85068 15.3941 8.99677C15.3941 10.1429 15.4905 12.6073 15.0727 13.6409Z"
                      fill="#FDFDFD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_22571_88923">
                      <rect
                        width="16"
                        height="18"
                        fill="white"
                        transform="translate(0.82959)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" href={marketingSiteURL} target="_blank">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={siteBaseURL+"home"}>
                Marketplace
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={marketingSiteURL+"index.html#how-it-works-sec"} target="_blank">
                How It works
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={void 0} target="_blank">
                Contact Us
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/privacy-policy" target="_blank">
                Privacy policy
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/terms-of-service" target="_blank">
                Terms of service
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};
export default SiteFooter;
