import React from 'react';
import RadioButton from './RadioButton';
import { convertToNumber } from '../../helpers/validations';

const RadioButtonGroup = (props) => {
 const {labelClass, inputClass, divClass, list, onChange, selectedValue, name  }  = props
  
 return list && list.map((l) => {
        const id = _.get(l, "id");
        const text = _.get(l, "text", "");
        const isChecked = convertToNumber(selectedValue) === convertToNumber(id)
        return (
          <label className= {labelClass ?labelClass : "radio-wrap mb-3"} key={id}>
           <RadioButton name = {name? name : 'radio'} value={id} checked = {isChecked} onChange ={onChange} class= {inputClass} />
            <span>
              <div className= {divClass ? divClass : "timing-wrap-info"}>
                <h2 className="pb-0">{text}</h2>
              </div>
            </span>
          </label>
        );
      });
  
}
export default (RadioButtonGroup);
