import React, { useState, useEffect } from "react";
import ConfirmStatusModal from "../../components/ConfirmStatusModal";
import ProgressBarInventory from "./ProgressBarInventory";
import { checkInventoryType } from "../../../helpers/inventory";
import { getInventoryDocuments } from "../../../services/inventoryService";
import {
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_STRENGTHEN,
} from "../../../constants/common";
import CheckBox from "../../../components/Common/CheckBox";
import { convertToNumber } from "../../../helpers/validations";
import { getLocalStorage, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";

const AddAdditionalDetails = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    completedPercentage,
    setCompletedPercentage,
    isStrengthenInventory,
    isEdit
  } = props;
  const apprOnFile = _.get(formValues, "apprOnFile", null);
  const insReport = _.get(formValues, "insReport", null);
  const sellerFin = _.get(formValues, "sellerFin", null);
  const floorPlan = _.get(formValues, "floorPlan", null);
  const floodCert = _.get(formValues, "floodCert", null);
  const assMortgage = _.get(formValues, "assMortgage", null);
  const apprOnFileInSession = _.get(getLocalStorage(INVENTORY_KEY), "apprOnFile", null);
  const insReportInSession = _.get(getLocalStorage(INVENTORY_KEY), "insReport", null);
  const sellerFinInSession = _.get(getLocalStorage(INVENTORY_KEY), "sellerFin", null);
  const floorPlanInSession = _.get(getLocalStorage(INVENTORY_KEY), "floorPlan", null);
  const floodCertInSession = _.get(getLocalStorage(INVENTORY_KEY), "floodCert", null);
  const assMortgageInSession = _.get(getLocalStorage(INVENTORY_KEY), "assMortgage", null);
  const selectedPurposes = _.get(formValues, "selectedPurposes", []);
  const invType = isStrengthenInventory
    ? checkInventoryType(selectedPurposes)
    : checkInventoryType(_.get(formValues, "proprtyPurpose", ""));

  const [propertyDocuments, setDocumentList] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if(!isEdit){
      if (
        apprOnFile ||
        insReport ||
        sellerFin ||
        floorPlan ||
        floodCert ||
        assMortgage
      ) {
        setDisableSave(false);
      } else {
        const isDisabled = true;
        setDisableSave(isDisabled);
      }
    }
    else{
      if (
        (apprOnFile !== apprOnFileInSession||
        insReport !==  insReportInSession||
        sellerFin !== sellerFinInSession ||
        floorPlan !== floorPlanInSession ||
        floodCert !== floodCertInSession ||
        assMortgage !== assMortgageInSession)
      ) {
        setDisableSave(false);
      } else {
        const isDisabled = true;
        setDisableSave(isDisabled);
      }
    }
  }, [apprOnFile, insReport, sellerFin, floorPlan, floodCert, assMortgage]);

  useEffect(() => {
    setBodyBackgroundWhite()
    getInventoryDocuments()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const documentList = _.get(response, "result", null);
          setDocumentList(documentList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ apprOnFile: e.target.checked });
        break;
      case 2:
        handleFormValues({ insReport: e.target.checked });
        break;
      case 3:
        handleFormValues({ sellerFin: e.target.checked });
        break;
      case 4:
        handleFormValues({ floorPlan: e.target.checked });
        break;
      case 5:
        handleFormValues({ floodCert: e.target.checked });
        break;
      case 6:
        handleFormValues({ assMortgage: e.target.checked });
        break;
    }
  };

  let floorPlanItem = propertyDocuments.filter(function (list) {
    if (list.OptionId === 4) {
      return list;
    }
  });

  const floorPlanOptionId = _.get(floorPlanItem?.[0], "OptionId", "4");
  const floorPlanOptionText = _.get(
    floorPlanItem?.[0],
    "Option_Text",
    "Floor plan on file"
  );
  const loadDocuments = () => {
    const isShowFloodFileOnly =
      invType === INVENTORY_LTR || invType === INVENTORY_LTR_STR; //For showing only one option ie, 'floor plan on file' for Long-term renting strengthen.

    if (invType) {
      if (isShowFloodFileOnly) {
        return (
          <div>
            <div className="find-renters-wrap member-ad mb-3  ">
              <label className="form-check-label">
                <CheckBox
                  class="form-check-input"
                  name="pd-checkbox"
                  checked={floorPlan}
                  value={floorPlanOptionId}
                  id={floorPlanOptionId}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span>
                  <div className="label-wrap">
                    <h6>{floorPlanOptionText}</h6>
                  </div>
                </span>
              </label>
            </div>
          </div>
        );
      } else {
        const propertyDocs =
          propertyDocuments &&
          propertyDocuments.map((pd) => {
            const id = _.get(pd, "OptionId");
            const text = _.get(pd, "Option_Text", "");
            let isChecked;
            switch (id) {
              case 1:
                isChecked = apprOnFile;
                break;
              case 2:
                isChecked = insReport;
                break;
              case 3:
                isChecked = sellerFin;
                break;
              case 4:
                isChecked = floorPlan;
                break;
              case 5:
                isChecked = floodCert;
                break;
              case 6:
                isChecked = assMortgage;
                break;
            }
            return (
              <div>
                <div className="find-renters-wrap member-ad mb-3  ">
                  <label className="form-check-label" key={id}>
                    <CheckBox
                      class="form-check-input"
                      name="pd-checkbox"
                      checked={isChecked}
                      value={id}
                      id={id}
                      onChange={(e) => handleCheckBoxItemClick(e)}
                    />
                    <span>
                      <div className="label-wrap">
                        <h6>{text}</h6>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
            );
          });

        return propertyDocs;
      }
    }
  };
  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
          />

          <div className="inner-layout-content-new  bg-white h-100 pl-16 pr-16 ">
            <div className="layout-content-btm-sec position-static pe-0 ps-0">
              <h6 className="">Additional information</h6>
              <h2 className="">Tell us more about your client’s property.</h2>
              <p className="titleTextpara mt-2">
                Adding additional information gives people a better idea of what
                you have.{" "}
              </p>
            </div>

            {loadDocuments()}
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav">
            <div className="btn-wrap text-center pb-3 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-4"
                disabled={disableSave}
                onClick={() => {
                  if (isStrengthenInventory) {
                    if (completedPercentage > 50) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(50);
                    }
                  } else {
                    if (completedPercentage > 85) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(85);
                    }
                  }
                  setTimeout(() => {
                    if (isStrengthenInventory) {
                      setLocalStorage(
                        INVENTORY_STRENGTHEN,
                        getLocalStorage(INVENTORY_KEY_TEMP)
                      );
                    } else {
                      setLocalStorage(
                        INVENTORY_KEY,
                        getLocalStorage(INVENTORY_KEY_TEMP)
                      );
                    }
                    setUIKey(null);
                  }, 500);
                }}
              >
                { isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdditionalDetails;
