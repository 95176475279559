import { createSlice } from "@reduxjs/toolkit";
import { getGoogleUser } from "../actions/login";
import { saveGoogleLoginUser } from "../services/loginService";
import _ from "lodash";
import { setLocalStorage } from "../helpers/common";
import { USER_INFO_KEY } from "../constants/common";
const initialState = {
  loading: false,
  userInfo: {},
  error: "",
  isGoogleLogin: false,
};

const userInfo = createSlice({
  name: "userInfo",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGoogleUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGoogleUser.fulfilled, (state, action) => {
       const userDetails = {
        userInfo :  {
          email: _.get(action.payload, "email", ""),
          name:
          _.get(action.payload, "given_name", "") +
            " " +
            _.get(action.payload, "family_name", "")
        }
       }
      state.loading = false;
      state.userInfo = _.get(userDetails, "userInfo", {});
      state.isGoogleLogin = true;
      state.error = "";
      const reqPayLoad = {
        UserTypeId: 1,
        FirstName: _.get(action.payload, "given_name", ""),
        LastName: _.get(action.payload, "family_name", ""),
        Email: _.get(action.payload, "email", ""),
        IsVerified: true,
        IsGoogleVerified: _.get(action.payload, "email_verified", ""),
      };
      
      saveGoogleLoginUser(reqPayLoad).then((response) => {
        if (response) {
          const result = _.get(response, "result", null);
          const IsVerified = _.get(result, "IsVerified", "") === 1;
          if (response.errorCode === 0 && IsVerified) {
             const userDetails = {
              token: {
                accessToken: _.get(result, "sessionToken", ""),
                refreshToken: _.get(result, "refreshToken", ""),
                googleLogin: true,
              },
              userInfo: {
                userId : _.get(result, "UserId", ""),
                email: _.get(result, "Email", ""),
                name:
                  _.get(result, "FirstName", "") +
                  " " +
                  _.get(result, "LastName", ""),
                User_Preference: _.get(result, "User_Preference", ""),
                userType: 1,
                image :  _.get(result, "image", ""),
              },
            };
            setLocalStorage(USER_INFO_KEY, userDetails )
          }
        }
      });
      
    });
    builder.addCase(getGoogleUser.rejected, (state, action) => {
      state.loading = false;
      state.userInfo = [];
      state.error = action.error.message;
    });
  },
  reducers: {
    userLogin: (state, action) => {
      state.userInfo = action.payload;
      state.isGoogleLogin = false;
      state.error = "";
    },
    userLogout: (state, action) => {
      state.userInfo = {}
    },
  },
});

export default userInfo.reducer;
export const { userLogin, userLogout } = userInfo.actions;
