import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const ExpiredModal = (props) => {
  const {show, onHide} = props;
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup not-fit-modal">
        <Modal.Body className="text-center">
        <h3>Introduction Expired</h3>
        <p>Your opportunity to initiate a conversation related to this introduction has expired. </p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={() => navigate(pageURLs.mobileHome)}>Close</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default ExpiredModal;
