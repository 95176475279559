/**
 * @file   src\actions\userprofile.js
 * @brief  This file is responsible for redux actions related to userdetails
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetails, saveNotifications, getNotifications, getUserPrivileges, getUserStatistics} from "../services/userprofileService";

export const fetchUserDetails = createAsyncThunk(
    "userprofile/fetchUserDetails",
    (requestPayload) => {
      return getUserDetails(requestPayload);
    }
  ); 

  export const saveNotificationSettings = createAsyncThunk(
    "userprofile/saveNotificationSettings",
    (requestPayload) => {
      return saveNotifications(requestPayload);
    }
  );

  export const getNotificationSettings = createAsyncThunk(
    "userprofile/getNotificationSettings",
    (requestPayload) => {
      return getNotifications(requestPayload);
    }
  );

  export const fetchUserPrivileges = createAsyncThunk(
    "userprofile/fetchUserPrivileges",
    (requestPayload) => {
      return getUserPrivileges(requestPayload);
    }
  ); 

  export const fetchUserStatistics = createAsyncThunk(
    "userprofile/fetchUserStatistics",
    (requestPayload) => {
      return getUserStatistics(requestPayload);
    }
  ); 