/**
 * @file   src\components\AgentWantAd\AgentCompensation.js
 * @brief  This file is responsible for agent compensation section in create want ad
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect, useRef } from "react";
import { getPurchaseTypes } from "../../services/wantAdService";
import { handleRadioButonChange, handleTextBoxChange } from "../../actions/common";
import { removeNegativeInput } from "../../helpers/validations";
import { capitalizeFirstString, handleKeyDownToPreventCharacters, handleKeyDownToPreventMinusSign, removeDecimalZeros, thousandSeperatedValue, trimString } from "../../helpers/common";
import { FormLabel } from "react-bootstrap";
import TextArea from "../Common/TextArea";
import { ERROR_TEXT } from "../../constants/common";

const AgentCompensation = (props) => {
  const {
    handleFormValues,
    agentCompenation,
    compenationType,
    errors,
    otherConcessions
  } = props;

  const [compensationTypeList, setCompensationTypeList] = useState([]);
  let isUnCheckItem = false;
  const [selectedValue, setSelectedValue] = useState(compenationType);
  const [compensationError, setCompensationError] = useState("");
  const [otherConcessionError, setOtherConcessionError] = useState(false);
  const [descCursorPos, setDescCursorPos] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const [description, setDescription] = useState("");
  const OtherConcessionRef = useRef();


  useEffect(() => {
    setDescription(otherConcessions);
    setDescCharCount(otherConcessions && otherConcessions.length ? otherConcessions.length : 0);
  }, [otherConcessions, otherConcessionError]);
  useEffect(() => {
    let compensationTypeList = [
      { compensationTypeId: 1, compensationTypeText: 'Percentage' },
      { compensationTypeId: 2, compensationTypeText: 'Flat fee' },
      { compensationTypeId: 4, compensationTypeText: 'Negotiable' },
      { compensationTypeId: 3, compensationTypeText: 'Not requesting compensation' },
    ]
      setCompensationTypeList(compensationTypeList);
  }, []);

 

  useEffect(() => {
    OtherConcessionRef.current.setSelectionRange(descCursorPos, descCursorPos);
  }, [descCursorPos]);

  
  // to show error message
  useEffect(() => {

    if (errors && errors.length > 0) {
      if(errors[0] == 'otherConcession'){
        OtherConcessionRef.current.focus();
  
      }
      errors.map((item) => {
        if (item === 'agentCompenation') {
          setCompensationError(compenationType == 1 ? "Enter percentage." : "Enter flat fee.");
        }
        if (item === 'otherConcession') setOtherConcessionError(ERROR_TEXT);      
      })
    }
  }, [errors]);
  const sentenceCase = (input, lowercaseBefore) => {
    setOtherConcessionError("")
    input = input === undefined || input === null ? "" : input;
    if (lowercaseBefore) {
      input = input.toLowerCase();
    }
    return input
      .toString()
      .replace(/(^|\. *)([a-z])/g, function (match, separator, char) {
        return separator + char.toUpperCase();
      });
  };

  const handleOtherOncessionChange = (e) => {
    let value = capitalizeFirstString(handleTextBoxChange(e));
    value = sentenceCase(value);
    setDescCharCount(value.length);
    setDescription(value);
    if (trimString(value) !== "") {
      handleFormValues({ otherConcessions: value });
      setDescCursorPos(e.target.selectionStart)
    } else {
      handleFormValues({ otherConcessions: "" });
    }
  };

// To handle selection changes
  const handleItemClick = (e, compensationText) => {
    setCompensationError(null)
    const value = handleRadioButonChange(e);
    if (value === compenationType) {
      handleFormValues({
        compenationType: "",
        compenationText: "",
        agentCompenation: "",
      });
      setSelectedValue("")
      isUnCheckItem = true;
    } else {
      handleFormValues({ compenationType: value, compenationText: compensationText, agentCompenation: "" });
      setSelectedValue(value)
    }
  };

  const setCompensationValue = (e) => {
    setCompensationError(null)
    let value = removeNegativeInput(e.target.value);
   
    if(compenationType == 1 && value > 10){
      handleFormValues({ agentCompenation: 10 });
    }else if(value == 0){
    
      handleFormValues({ agentCompenation: "" });
    } else{
      handleFormValues({ agentCompenation: value.toString().replace(/,/g, '') });
      
    }
   
  };

 
  const loadCompensationTypes = () => {
    const compensationTypes =
      compensationTypeList &&
      compensationTypeList.map((tl) => {
        const compensationId = _.get(tl, "compensationTypeId");
        const compensationTypeId = _.get(tl, "compensationTypeText", "");
     
        return (

          <>
            <label className="radio-wrap mb-3" key={compensationId}>
              <input
                type="radio"
                name="compensation_radio"
                value={compensationId}
                checked={
                  isUnCheckItem
                    ? false
                    : selectedValue ? (selectedValue == compensationId) : false
                }
                onClick={(e) => handleItemClick(e, compensationTypeId)}
                onChange={(e) => handleItemClick(e, compensationTypeId)}
              />
              <span>
                <div className="timing-wrap-info pb-0">
                  <h2>{compensationTypeId}</h2>
                </div>
              </span>
            </label>
            {(compenationType == 1 || compenationType == 2) && selectedValue == compensationId &&

              loadTextBox()
            }
          </>
        );
      });

    return compensationTypes;
  };



  const loadTextBox = () => {
    return (
      <>

        {compenationType == 1 &&

          <div className="inner-select-type-wrap">
            <div class="w-100 position-relative compensation-list">
              <label class="d-none"></label>
              <span className="percent-sign-icon">%</span>
              <label className="form-label ">What’s your percentage?*</label>
              <input className={compensationError ? "pad-left-25 red-marked-formcontrol form-control" : "form-control pad-left-25"}
                limit="2" step="0.25" type="number" value={agentCompenation}
                onChange={(e) => {
                  setCompensationValue(e);
                }}
                onKeyDown={(e)=>{handleKeyDownToPreventMinusSign(e)}}
                onBlur={() => { handleFormValues({ focusOut: true }) }} />
              <div class="error-msg">{compensationError}</div>
            </div>
          </div>
        }
        {compenationType == 2 &&

          <div className="inner-select-type-wrap">
            <div class="w-100 position-relative compensation-list">
              <label class="d-none"></label>
              <span className="percent-sign-icon">$</span>
              <label className="form-label ">What’s your flat fee? *</label>
              <input className={compensationError ? "pad-left-25 red-marked-formcontrol form-control" : "form-control pad-left-25"}
              type="text" value={agentCompenation ? thousandSeperatedValue(removeDecimalZeros(agentCompenation)) : ""}
                onChange={(e) => {
                  setCompensationValue(e);
                }}
                onKeyDown={(e)=>{handleKeyDownToPreventCharacters(e)}}
                onBlur={() => { handleFormValues({ focusOut: true }) }} />
              <div class="error-msg">{compensationError}</div>
            </div>
          </div>
        }
      </>
    );
  };

  return (
    <>
      <div className="wantad-timing-wrap pb-0">
        {loadCompensationTypes()}
        <div className="wantad-des-wrap select-type-wrap ">

        <div className="position-relative">
        <FormLabel className={otherConcessionError != "" && "red-marked-label"}>Other requested concessions:</FormLabel>
        <TextArea
          className= {otherConcessionError != "" ? "form-control txt-area red-marked-formcontrol" : "form-control txt-area "}
          errorMessage={otherConcessionError}
          value={description ? description : ''}
          maxLength={500}
          placeholder="Optional: Rate buy down, closing costs, etc."
          onChange={(e) => {
            handleOtherOncessionChange(e);
          }}
          txtRef={OtherConcessionRef}
          onBlur={()=>{handleFormValues({focusOut: true})}}
        />

        <div className={otherConcessionError != "" ? "des-count mb-4 red-marked-count" : "des-count mb-4"}>
          <span>{descCharCount}</span>/<span>500</span>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};
export default AgentCompensation;
