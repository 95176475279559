/**
 * @file   src\components\MemberWantAd\EditWantAd\WantAdCalendar.js
 * @brief  This file is responsible for handling wantad calender in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DATE_FORMAT_MM_DD_YYYY,
  LONG_RENT,
  SHORT_RENT,
  STR_DATE_RANGE,
  STR_FLEXIBLE_DATE,
} from "../../../constants/common";
import TextBox from "../../Common/TextBox";
import CalendarIcon from "../../../images/black-calendar-icon.svg";
import {
  formateDate,
  monthNameForFlexibleDate,
  durationForFlexibleDate,
  isValidDate,
} from "../../../helpers/common";
import { convertToNumber } from "../../../helpers/validations";
import { getTimeline } from "../../../services/commonServices";
import {
  EXACT_MOVE_DATE_TYPE,
  LTR_CAL,
} from "../../../constants/createWantAd";

const WantAdCalendar = (props) => {
  const {
    adType,
    sDate,
    eDate,
    handleFormValues,
    dateType,
    duration,
    month,
    clearPreviousStates,
    moveInTimeLine,
    moveInDate,
    dateError,
    errorUpdatedAt,
    isEdit = false,
    timeLineError,
    timelineCalenderError,
    exactMoveDateError,
    dateTextBoxError

  } = props;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [timeLineList, setTimeLineList] = useState([]);
  const [dateValError, setDateError] = useState("");


  // const ValidateOnFocusOut = () =>{
  //   const isDateValid = isValidDate({dateString :moveInDate, format : DATE_FORMAT_MM_DD_YYYY});
  //           if (!isDateValid) {
  //             setDateError("Invalid Date.");
  //           } else{
  //             setDateError("");
  //           }
  // }
  useEffect(() => {
    adType === LONG_RENT && getMoveInList();
  }, [adType]);

  const getMoveInList = () => {
    getTimeline(LTR_CAL)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const timeLines = _.get(response, "result", null);
          setTimeLineList(timeLines);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {

    setDateError("");
    if (exactMoveDateError) {
      setDateError("Invalid Date.");
      // handleContinueButtonState();
    }
  }, [exactMoveDateError]);

  const handleStartDate = (date) => {
    const formatedDate = formateDate(date, "mm/dd/yyyy");
    handleFormValues({ startDate: formatedDate, endDate: "" });
    setStartDate(date);
    setEndDate("");
  };
  const handleEndDate = (date) => {
    const formatedDate = formateDate(date, "mm/dd/yyyy");
    handleFormValues({ endDate: formatedDate });
    setEndDate(date);
  };

  const handleDurationClick = (id) => {
    if (id) {
      handleFormValues({ duration: id });
    }
  };

  const handleMonthClick = (id) => {
    if (id) {
      handleFormValues({ month: id });
    }
  };

  const handleDateTypeChange = (e) => {
    const value = convertToNumber(e.target.value);
    if (value === STR_DATE_RANGE) {
      clearPreviousStates({ type: "clearFlexDates" });
    } else {
      clearPreviousStates({ type: "dateRange" });
    }
    handleFormValues({ dateType: value });
  };

  const handleMoveInTimeLineSelection = (e, text) => {
    const value = e.target.value;
    if (value !== EXACT_MOVE_DATE_TYPE) {
      clearPreviousStates({ type: "moveInTypeChangeLTR" });
    }
    handleFormValues({ moveInTimeLine: value, moveInTxt: text });
  };

  const loadDurations = () => {
    const itemArray = durationForFlexibleDate();
    const List = itemArray.map((pt) => {
      const durtn = _.get(pt, "duration", "");
      const id = _.get(pt, "id", "");
      const isSelect = duration === id;
      return (
        <a
          href={void 0}
          role="button"
          className={
            isSelect ? "option-select-tag active" : "option-select-tag"
          }
          id={id}
          key={id + "_dur"}
          onClick={() => handleDurationClick(id)}
        >
          {durtn}
        </a>
      );
    });

    return List;
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    const formatedStartDate = start && formateDate(start, "mm/dd/yyyy");
    const formatedEndDate = end && formateDate(end, "mm/dd/yyyy");
    handleFormValues({
      startDate: formatedStartDate,
      endDate: formatedEndDate,
    });
    setStartDate(start);
    setEndDate(end);

    // const formatedDate = formateDate(date, "mm/dd/yyyy");
    // handleFormValues({ endDate: formatedDate });
    // setEndDate(date);
    // setStartDate(start);
    // setEndDate(end);
  };

  const moveInCalenderChange = (date) => {
    const formatedDate = date && formateDate(date, "mm/dd/yyyy");
    handleFormValues({
      startDate: formatedDate,
    });
  };

  const loadMonths = () => {
    const itemArray = monthNameForFlexibleDate();
    const List = itemArray.map((pt) => {
      const mnth = _.get(pt, "month", "");
      const id = _.get(pt, "id", "");
      const isSelect = month === id;
      const m = new Date().getMonth() + 1; // adding by one because getmonth starts from 0;
      let className = "option-select-tag";
      if (convertToNumber(id) < convertToNumber(m)) {
        className = "option-select-tag disabled";
      } else {
        if (month === id) {
          className = "option-select-tag active";
        }
      }

      return (
        <a
          href={void 0}
          role="button"
          className={className}
          id={id}
          key={id}
          onClick={() => handleMonthClick(id)}
        >
          {mnth}
        </a>
      );
    });

    return List;
  };

  const loadTimeLines = () => {
    const timeLines =
      timeLineList &&
      timeLineList.map((tl) => {
        const timeLineId = _.get(tl, "TimeLineId");
        const timeLineText = _.get(tl, "TimeLineText", "");
        return (
          <label className="radio-wrap mb-3" key={timeLineId + "_md"}>
            <input
              type="radio"
              name="radio_timing"
              value={timeLineId}
              onChange={(e) => handleMoveInTimeLineSelection(e, timeLineText)}
              checked={moveInTimeLine && moveInTimeLine === timeLineId}
            />
            <span className={ timeLineError && "red-marked-span"}>
              <div className="timing-wrap-info">
                <h2 className={ timeLineError && "red-marked-subheading" }>{timeLineText}</h2>
              </div>
            </span>
          </label>
        );
      });

    return timeLines;
  };

  const isFlexibleDate =
    adType === SHORT_RENT && dateType === STR_FLEXIBLE_DATE;
  const isDateRange = adType === SHORT_RENT && dateType === STR_DATE_RANGE;

  let maxEndDate = new Date();
  if (adType === SHORT_RENT) {
    maxEndDate.setMonth(maxEndDate.getMonth() + 18);
  }

  const handleMoveInDateChange = (e) => {
    const date = e.target.value;
    const validatedDate = date.replace(/[^0-9\-\/]/g, "");
    handleFormValues({ startDate: validatedDate });
  };

  // This function is for restricting to select end date withtin 30 days of start date for long-term renting.
  const disabledDates = () => {
    const strtDate = sDate && new Date(sDate);
    const eDte = eDate && new Date(eDate);
    if (startDate && eDte) {
      return "";
    } else if (strtDate && !eDate) {
      return [
        {
          start: new Date(strtDate.setDate(strtDate.getDate() + 1)),
          end: new Date(strtDate.setDate(strtDate.getDate() + 29)),
        },
      ];
    } else {
      return "";
    }
  };

  function LoadCalenderCustomHeader(props) {
    const {
      adType,
      monthDate,
      customHeaderCount,
      decreaseMonth,
      increaseMonth,
    } = props;
    if (adType === LONG_RENT) {
      return (
        <div>
          {new Date(monthDate).getMonth() + 1 === new Date().getMonth() + 1 &&
          new Date(monthDate).getFullYear() === new Date().getFullYear() ? (
            ""
          ) : (
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
              onClick={decreaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
              >
                {"<"}
              </span>
            </button>
          )}

          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
            onClick={increaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
        </div>
      );
    } else {
      return (
        <div>
          {new Date(monthDate).getMonth() + 1 === new Date().getMonth() + 1 &&
          new Date(monthDate).getFullYear() === new Date().getFullYear() ? (
            ""
          ) : (
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
              onClick={decreaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
              >
                {"<"}
              </span>
            </button>
          )}

          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
            onClick={increaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
          {/* {(!sDate && !eDate) || (sDate && eDate) ? (
            <button
              aria-label="Next Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--next"
              }
              style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
              onClick={increaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }
              >
                {">"}
              </span>
            </button>
          ) : sDate &&
            !eDate &&
            new Date(sDate).getMonth() + 1 !==
              new Date(monthDate).getMonth() ? (
            <button
              aria-label="Next Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--next"
              }
              style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
              onClick={increaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }
              >
                {">"}
              </span>
            </button>
          ) : (
            ""
          )} */}
        </div>
      );
    }
  }

  return (
    <>
      <div className="wantad-calendar-wrap mw-100 mb-0">
        {adType == LONG_RENT && (
          <div className="wantad-timing-wrap pb-0">{loadTimeLines()}</div>
        )}
        {adType === SHORT_RENT && (
          <div className="wantad-timing-wrap pb-0">
            <div className="row">
              <div className="col-md-6">
                <label className="radio-wrap mb-3">
                  <input
                    name="SELLd1"
                    type="radio"
                    value={STR_DATE_RANGE}
                    onChange={(e) => handleDateTypeChange(e)}
                    checked={dateType === STR_DATE_RANGE}
                  />
                  <span className={timelineCalenderError && "red-marked-span"}>
                    <div className="timing-wrap-info">
                      <h2 className={timelineCalenderError ? "pb-0 red-marked-subheading" : " pb-0 "}>Choose dates</h2>
                    </div>
                  </span>
                </label>
              </div>
              <div className="col-md-6">
                <label className="radio-wrap mb-3">
                  <input
                    name="SELLd1"
                    type="radio"
                    value={STR_FLEXIBLE_DATE}
                    onChange={(e) => handleDateTypeChange(e)}
                    checked={dateType === STR_FLEXIBLE_DATE}
                  />
                  <span className={timelineCalenderError && "red-marked-span"}>
                    <div className="timing-wrap-info">
                      <h2 className={timelineCalenderError ? "pb-0 red-marked-subheading" : " pb-0 "}>I'm flexible</h2>
                    </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}
        {adType == LONG_RENT && moveInTimeLine === EXACT_MOVE_DATE_TYPE && (
          <>
            <div className="wantad-calendar-top position-relative">
              <label className={exactMoveDateError && "red-marked-label"}>Type or select move-in date</label>

              <TextBox
                type="text"
                errorMessage={dateValError}
                class={exactMoveDateError ? "form-control red-marked-formcontrol" : "form-control"}
                value={moveInDate || ""}
                onChange={(e) => {
                  handleMoveInDateChange(e);
                }}
                // onBlur={()=>{ValidateOnFocusOut()}}
                maxLength={10}
              ></TextBox>
              {/* <input type="text" className="form-control" name="" /> */}
              <div className="wantad-calendar-icon">
                <img src={CalendarIcon} alt={CalendarIcon} className="" />
              </div>
            </div>
            <div className="calendar-outer-wrap clearfix">
            <DatePicker
              calendarStartDay={1}
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              renderCustomHeader={({
                monthDate,
                customHeaderCount,
                decreaseMonth,
                increaseMonth,
              }) => (
                <LoadCalenderCustomHeader
                  adType={adType}
                  monthDate={monthDate}
                  customHeaderCount={customHeaderCount}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                />
              )}
              selected={
                moveInDate &&
                isValidDate({
                  dateString: moveInDate,
                  format: DATE_FORMAT_MM_DD_YYYY,
                }) &&
                new Date(moveInDate)
              }
              minDate={new Date()}
              inline
              monthsShown={2}
              onChange={moveInCalenderChange}
              disabledKeyboardNavigation
              //excludeDateIntervals={disabledDates()}
            />
            </div>
          </>
        )}
        
          {adType === SHORT_RENT && isDateRange && (
            <div className="calendar-outer-wrap clearfix">
              <div className="wantad-calendar-top position-relative">
                <ul className="wantad-calendar-list">
                  <li>
                    <label className={dateTextBoxError && "red-marked-label"}>Start</label>
                    <div class="input-group position-relative">
                      <TextBox
                        type="text"
                        class={dateTextBoxError ? "form-control red-marked-formcontrol" : "form-control"}
                        disabled={true}
                        value={sDate ? formateDate(sDate, "mm/dd/yyyy") : ""}
                      ></TextBox>
                      <div className="wantad-calendar-icon">
                        <img
                          src={CalendarIcon}
                          alt={CalendarIcon}
                          className=""
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <label className={dateTextBoxError && "red-marked-label"}>End</label>
                    <div class="input-group position-relative">
                      <TextBox
                        type="text"
                        disabled={true}
                        class={dateTextBoxError ? "form-control red-marked-formcontrol" : "form-control"}
                        value={eDate ? formateDate(eDate, "mm/dd/yyyy") : ""}
                      ></TextBox>
                      <div className="wantad-calendar-icon">
                        <img
                          src={CalendarIcon}
                          alt={CalendarIcon}
                          className=""
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <DatePicker
                calendarStartDay={1}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                renderCustomHeader={({
                  monthDate,
                  customHeaderCount,
                  decreaseMonth,
                  increaseMonth,
                }) => (
                  <LoadCalenderCustomHeader
                    adType={adType}
                    monthDate={monthDate}
                    customHeaderCount={customHeaderCount}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                  />
                )}
                selected={sDate && new Date(sDate)}
                selectsStart
                selectsEnd
                selectsRange
                startDate={sDate && new Date(sDate)}
                endDate={eDate && new Date(eDate)}
                minDate={new Date()}
                maxDate={maxEndDate}
                inline
                monthsShown={2}
                onChange={onChange}
                disabledKeyboardNavigation
              />
           </div>
          )}
          {adType === SHORT_RENT && isFlexibleDate && (
             <div className={dateTextBoxError ? "calendar-outer-wrap red-marked-outerwrap clearfix" :
             "calendar-outer-wrap clearfix"}>
            <div
              className="flexible-dates-wrap options-column"
              id="flexible-dates-id"
            >
              <h6>I want to stay for a:</h6>
              <div className="option-seletion-wrap mb-4">{loadDurations()}</div>

              <h6 className="mb-3">During the month of:</h6>
              <div className="option-seletion-wrap mw-460 mx-auto">
                {loadMonths()}
              </div>
            </div>
            </div>
          )}
       
      </div>
    </>
  );
};
export default WantAdCalendar;

