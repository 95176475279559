/**
 * @file   src\components\Common\AddInventoryPopup.js
 * @brief  This file is responsible for add inventory popup shown once gitcha not available popup is handled from home page
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { getUserType } from "../../helpers/common";
import { USER_TYPE_AGENT } from "../../constants/common";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";


const AddInventoryPopup = (props) => {  
    const navigate = useNavigate();
    const userType = getUserType();
    /**
     * To handle navigation to create want ad pages based on usertype on clicking add Inventory button
     */
    const handleSubmit = () => {
        if (userType === USER_TYPE_AGENT) {
            navigate(pageURLs.agentCreateInventory)
          } else {
            navigate(pageURLs.memberCreateInventory);
          }
    }

  return (
    <>
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >      
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={props.onHide}
        ></button>
        <h2 className="text-start f-size-24 mb-2 l-spacing">Thanks! Want to get a head start?</h2>
        <Row>            
          <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-start">
            In the mean time, you can start by adding your private inventory to gauge demand, privately introduce and connect with buyers. We’ll notify you via email when we’re available in your area. We appreciate your patience as we continue to expand!
            </p>

          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">              
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Add Inventory
              </Button>

            </div>
          </Col>
        </Row>
      </Modal.Body>      
    </Modal>   
    </>
  );
};
export default AddInventoryPopup;
