import React, { useState, useEffect } from "react";
import DeleteGitchaModal from "../components/DeleteGitchaAccountModal";
import {
  getPeriod,
  getPeriod_Mobile,
  getPlanName_Mobile,
  formateDate,
  getPlanPeriodText,
  getClientLogo,
} from "../../helpers/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { NOT_FREE_OR_MEMBER_PERIOD } from "../../constants/common";
import { RESET_PWD_EMAIL_VERIFICATION } from "../../constants/common";

const ProfileAgentUserPremium = ({ ...props }) => {
  const { userDetails, upComingPlanDetails } = props;
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const DeleteModalClose = () => setShowDeleteModal(false);
  const DeleteModalShow = () => setShowDeleteModal(true);

  let isPremiumPlan;
  if (_.get(upComingPlanDetails, "Is_Upcoming", null) == 1) {
    isPremiumPlan =
      upComingPlanDetails?.period &&
      upComingPlanDetails?.period > NOT_FREE_OR_MEMBER_PERIOD;
  } else {
    isPremiumPlan =
      userDetails?.period && userDetails?.period > NOT_FREE_OR_MEMBER_PERIOD;
  }

  console.log("isPremoumplan", isPremiumPlan)

  useEffect(()=>{
    document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
  },[])

  return (
    <>
      <div className="inner-layout-content-new h-100  ">
        <div className="settings-wrap-mob min-h-100 bg-grey-variant">
          <div className="">
            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Name</h3>
                <a
                  href={void 0}
                  className="link"
                  onClick={() =>
                    navigate(pageURLs.mobilePremiumAgentProfileEdit)
                  }
                >
                  Edit
                </a>
              </div>

              <div className="edit-profile ">
                <div className="sepLine pb-2">
                  <div className="d-flex ">
                    {/* <span className="profile-bg-meroon profileIcnDiv me-2 align-items-center">{getClientLogo(userDetails?.FirstName, userDetails?.LastName)}</span> */}

                    {userDetails?.UserTypeId === 2 &&
                    userDetails?.period === 1 ? (
                      <span className="profile-bg-meroon profileIcnDiv me-2 align-items-center">
                        PB
                      </span>
                    ) : userDetails?.Img !== "" ? (
                      <div className="account-settings-proimg me-2">
                        <img
                          src={
                            userDetails?.Img + "?" + Math.random().toString(36)
                          }
                        />
                      </div>
                    ) : (
                      <div className="prof-img-small me-2">
                        <svg
                          width="24"
                          height="23"
                          viewBox="0 0 24 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                            fill="#A0A9BA"
                          />
                        </svg>
                      </div>
                    )}

                    <div className="pt-1 ps-1">
                      <div className="d-flex">
                        <h2>
                          {userDetails?.FirstName} {userDetails?.LastName}
                        </h2>
                        {userDetails?.UserTypeId === 2 &&
                        userDetails?.period !== 1 ? (
                          <span className="pro-blue-badge ms-2">Pro</span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <p>Agent @ {userDetails?.Company}</p>
                    </div>
                  </div>
                  {userDetails?.UserTypeId === 2 &&
                    userDetails?.period !== 1 &&
                    userDetails?.Bio && (
                      <div className="smallTitle">
                        Bio
                        <p>{userDetails?.Bio}</p>
                      </div>
                    )}
                </div>

                <div className="profile-detail d-flex justify-content-between mt-3 mb-3">
                  <div>Work location</div>
                  <span>{userDetails?.Location}</span>
                </div>

                <div className="profile-detail d-flex justify-content-between mt-3 mb-3">
                  <div>Agency</div>
                  <span>{userDetails?.Company}</span>
                </div>
                {userDetails?.UserTypeId === 2 &&
                    userDetails?.period !== 1 &&
                   (
                <div className="profile-detail d-flex justify-content-between mt-3 mb-3">
                  <div>Phone number</div>
                  <span>{userDetails?.Phone}</span>
                </div>
                )}
                <div className="profile-detail d-flex justify-content-between mt-3 mb-3">
                  <div>License number</div>
                  <span>{userDetails?.LicenseNo}</span>
                </div>
              </div>
            </div>

            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Email</h3>
                <a
                  href={void 0}
                  className="link"
                  onClick={() => navigate(pageURLs.mobileEditSettingsEmail)}
                >
                  Edit
                </a>
              </div>
              <p className="ps-3 mb-0 txtLabel">{userDetails?.Email}</p>
            </div>

            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Password</h3>
                <a
                  href={void 0}
                  className="link"
                  onClick={() =>
                    navigate(pageURLs.changePassword, {
                      state: { activePage: RESET_PWD_EMAIL_VERIFICATION, isFromSettings: true},
                    })
                  }
                >
                  Reset
                </a>
              </div>
              <p className="ps-3 mb-0 txtLabel">*************</p>
            </div>

            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">Your Subscription</h3>
                <a
                  href={void 0}
                  role="button"
                  onClick={() =>
                    isPremiumPlan
                      ? navigate(pageURLs.updatePlan, {
                          state: { isFromSettings: true, isEditPlan: true },
                        })
                      : navigate(pageURLs.updatePlan, {
                          state: { isFromSettings: true },
                        })
                  }
                  className="link"
                >
                  {isPremiumPlan ? "Edit" : "View Plans"}
                </a>
              </div>

              {upComingPlanDetails?.Is_Upcoming == 1 && (
                <div className="subscription-alert-box mt-2 mb-3 d-flex">
                  <div className="align-self-center pe-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99967 1.33325C4.32634 1.33325 1.33301 4.32659 1.33301 7.99992C1.33301 11.6733 4.32634 14.6666 7.99967 14.6666C11.673 14.6666 14.6663 11.6733 14.6663 7.99992C14.6663 4.32659 11.673 1.33325 7.99967 1.33325ZM7.49967 5.33325C7.49967 5.05992 7.72634 4.83325 7.99967 4.83325C8.27301 4.83325 8.49967 5.05992 8.49967 5.33325V8.66658C8.49967 8.93992 8.27301 9.16658 7.99967 9.16658C7.72634 9.16658 7.49967 8.93992 7.49967 8.66658V5.33325ZM8.61301 10.9199C8.57967 11.0066 8.53301 11.0733 8.47301 11.1399C8.40634 11.1999 8.33301 11.2466 8.25301 11.2799C8.17301 11.3133 8.08634 11.3333 7.99967 11.3333C7.91301 11.3333 7.82634 11.3133 7.74634 11.2799C7.66634 11.2466 7.59301 11.1999 7.52634 11.1399C7.46634 11.0733 7.41967 11.0066 7.38634 10.9199C7.3521 10.8398 7.33397 10.7537 7.33301 10.6666C7.33301 10.5799 7.35301 10.4933 7.38634 10.4133C7.41967 10.3333 7.46634 10.2599 7.52634 10.1933C7.59301 10.1333 7.66634 10.0866 7.74634 10.0533C7.90865 9.98657 8.0907 9.98657 8.25301 10.0533C8.33301 10.0866 8.40634 10.1333 8.47301 10.1933C8.53301 10.2599 8.57967 10.3333 8.61301 10.4133C8.64634 10.4933 8.66634 10.5799 8.66634 10.6666C8.66634 10.7533 8.64634 10.8399 8.61301 10.9199Z"
                        fill="#4A4C4F"
                      />
                    </svg>
                  </div>
                  {userDetails?.PlanName == "Trial" ? (
                    <div>
                      Your {userDetails?.PlanName} subscription will end on{" "}
                      {formateDate(userDetails?.ToDate, "shortMonth")}.
                    </div>
                  ) : (
                    <div>
                      Your {getPlanPeriodText(userDetails?.period)}{" "}
                      {userDetails?.PlanName} subscription will end on{" "}
                      {formateDate(userDetails?.ToDate, "shortMonth")}. To keep
                      your {userDetails?.PlanName} features, you can{" "}
                      <a
                        href={void 0}
                        role="button"
                        onClick={() => PremiumPlanModalShow()}
                      >
                        resubscribe here.{" "}
                      </a>{" "}
                    </div>
                  )}
                </div>
              )}
              {upComingPlanDetails?.Is_Upcoming == 1 ? (
                <>
                  <p className="ps-3 mb-2 txtLabel">
                    {/* {getPlanName_Mobile(
                      upComingPlanDetails?.UserTypeId,
                      upComingPlanDetails?.period
                    )} */}
                    {upComingPlanDetails?.PlanName}{" "}
                    (
                      {/* {upComingPlanDetails?.period > NOT_FREE_OR_MEMBER_PERIOD ? "Pro, " : ""} */}
                    {getPeriod_Mobile(upComingPlanDetails?.period)})
                    {/* (Pro, Annual) - $120/year */}
                  </p>
                  {upComingPlanDetails?.period > 1 && <p className="ps-3 mb-0 txtLabel fnt12">
                    Next payment on{" "}
                    {upComingPlanDetails?.ToDate
                      ? formateDate(upComingPlanDetails?.ToDate, "mm/dd/yyyy")
                      : "-"}{" "}
                    to {upComingPlanDetails?.Brand} {upComingPlanDetails?.Last4}
                  </p>}
                </>
              ) : userDetails?.period === 1 ? (
                <p className="ps-3 mb-2 txtLabel">
                  {/* {userDetails?.UserTypeId === 2 ? "Pro " : ""}{" "}
                  {getPlanName_Mobile(
                    userDetails?.UserTypeId,
                    userDetails?.period
                  )} */}
                  {userDetails?.PlanName}{" "}
                  {/* ({getPeriod_Mobile(userDetails?.period)}) */}
                  {/* Pro Member (Free) */}
                </p>
              ) : (
                <>
                  <p className="ps-3 mb-2 txtLabel">
                    {/* {getPlanName_Mobile(
                      userDetails?.UserTypeId,
                      userDetails?.period
                    )} */}
                    {userDetails?.PlanName}{" "}
                    (
                    {getPeriod_Mobile(userDetails?.period)})
                  </p>
                  {userDetails?.period > 1 && <p className="ps-3 mb-0 txtLabel fnt12">
                    Next payment on{" "}
                    {userDetails?.ToDate
                      ? formateDate(userDetails?.ToDate, "mm/dd/yyyy")
                      : "-"}{" "}
                    to {userDetails?.Brand} {userDetails?.Last4}
                  </p>}
                </>
              )}
            </div>

            <div className="account-settings-list ">
              <div className="d-flex">
                <h3 className="flex-grow-1">
                  <a href={void 0} className="delete" onClick={DeleteModalShow}>
                    Delete My Account
                  </a>
                </h3>
              </div>
              <p className="ps-3 mb-0 txtLabel">
                Permanently delete your Gitcha Account
              </p>
            </div>
          </div>

          <DeleteGitchaModal
            show={showDeleteModal}
            onHide={DeleteModalClose}
            userEmail={userDetails?.Email}
          />
        </div>
      </div>
    </>
  );
};
export default ProfileAgentUserPremium;
