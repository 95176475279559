/**
 * @file   src\form\ShareIntroductionMob.js
 * @brief  This file is responsible for shared inroductions view
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import "../mobile/styles/clients.scss";
import {
  AGENT_USER_MONTHLY_PRO_MEMBER,
  AGENT_USER_YEARLY_PRO_MEMBER,
  BUY,
  INTRODUCTION_EXPIRATION_TIME,
  INVENTORY_LAND,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_PURPOSE,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  IS_LAND,
  PRO_MEMBER_PLAN,
  USER_TYPE_AGENT,
} from "../constants/common";
import {
  formatDisplayPhoneNumber,
  getPropertyPurpose,
  getUserId,
  getUserType,
  removeDecimalZeros,
  setBodyBackgroundWhite,
  toTitleCase,
} from "../helpers/common";
import { convertToNumber } from "../helpers/validations";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import {
  buildReceiveIntroMustHaveHtmlForMobile,
  buildSchoolNeigborhoodHtmlForMobile,
  checkInventoryType,
} from "../helpers/inventory";
import { getShareIntroductionInventoryDetailsById } from "../services/introductionService";
import PhotoGalleryMob from "../components/Common/PhotoGalleryMob";
import { getReceiveIntroDescriptionText } from "../helpers/getPropertyIcons";
import CountdownTimer from "../components/Common/CountdownTimer";
import ErrorMessage from "../mobile/commonComponents/ErrorMessage";

const ShareIntroductionMob = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [introductionPhotos, setIntroductionPhotos] = useState([]);
  const [timeOutStatus, setTimeOutStatus] = useState(false);
  const [inventoryDetails, setInventoryDetails] = useState();
  const photos = _.get(inventoryDetails, "photos", []).filter(
    (item) => item.IsValid != 0
  );
  const Sub_PropertyTypeId = convertToNumber(
    _.get(inventoryDetails, "Sub_PropertyTypeId", "")
  );
  const propertyPurpose = getPropertyPurpose(
    _.get(inventoryDetails, "Is_Seller", ""),
    _.get(inventoryDetails, "Is_ShortTerm_Renter", ""),
    _.get(inventoryDetails, "Is_LongTerm_Renter", "")
  );
  const userType = getUserType();
  const invType = checkInventoryType(propertyPurpose);
  const nice2Have = _.get(inventoryDetails, "nice2Have", []);
  const mapImg = _.get(inventoryDetails, "Location_Img", "");
  const highSchool = _.get(inventoryDetails, "HighSchool", null);
  const neighborHood = _.get(inventoryDetails, "NeighborHood", null);
  const description = _.get(inventoryDetails, "Add_Details", "");
  const salesPercnt = _.get(inventoryDetails, "Compensate_Percent", "");
  const isAgent = _.get(inventoryDetails, "UserTypeId", "") === USER_TYPE_AGENT;
  const firstName = _.get(inventoryDetails, "FirstName", "");
  const lastName = _.get(inventoryDetails, "LastName", "");
  const fullName = firstName + " " + lastName;
  const introductionId = props.introductionId;
  const userId = getUserId();
  const userDeleted = location.state && location.state["userDeleted"];
  const isNiceToHaveExist = nice2Have.length > 0;
  const is_Seller =
    _.get(inventoryDetails, "Is_Seller", 0) === 1 ? true : false;
  const typeBasedOnProperty =
    Sub_PropertyTypeId === IS_LAND ? INVENTORY_LAND : invType;
  const introAcceptedDate = new Date(
    _.get(inventoryDetails, "Accept_Date", "")
  );

  const [isValidId, setIsValidId] = useState(null);
  const [linkExpired, setLinkExpired] = useState(false);
  const [introductionExpired, setIntroductionExpired] = useState(false);

  const [introShareLinkCreatedDate, setIntroShareLinkCreatedDate] =
    useState("");

  let titleText = "";
  switch (invType) {
    case INVENTORY_SELL:
      titleText = " selling";
      break;
    case INVENTORY_LTR:
      titleText = " renting";
      break;
    case INVENTORY_STR:
      titleText = " renting";
      break;
    case INVENTORY_LTR_STR:
      titleText = " renting";
      break;
    case INVENTORY_SELL_LTR:
      titleText = " selling, renting";
      break;
    case INVENTORY_SELL_STR:
      titleText = " selling, renting";
      break;
    default:
      titleText = " selling, renting";
      break;
  }

  const AgentInfo = {
    userImage: inventoryDetails?.Img,
    userFirstName: inventoryDetails?.FirstName,
    userLastName: inventoryDetails?.LastName,
    isPremiumAgent: inventoryDetails?.PlanName == PRO_MEMBER_PLAN,
    userCompany: inventoryDetails?.Company,
    Phone: inventoryDetails?.Phone,
  };

  useEffect(() => {
    setBodyBackgroundWhite();
  }, []);

  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
    getIntroductionDetails();
  }, [id]);

  const getIntroductionDetails = async () => {
    const splitId = id.split("-");
    const introductionId = splitId[splitId.length - 1];
    if (introductionId) {
      await getShareIntroductionInventoryDetailsById(introductionId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
            setIntroductionPhotos(responseResult?.photos);
            setIntroShareLinkCreatedDate(responseResult?.Intro_Unq_ExpDt);

            if (responseResult?.PlanId != 1 || responseResult?.PlanId != 4) {
              // planId 1 and for Lite users
              setIsValidId(1);
            } else {
              setIsValidId(0);
            }
          } else {
            setIsValidId(0);
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.home);
    }
  };

  useEffect(() => {
    const checkShareLinkExpired = () => {
      if (introShareLinkCreatedDate) {
        const uniqueKeyDate = moment.utc(introShareLinkCreatedDate); // Replace with your UTC date string

        // Calculate expiration date
        const uniqueKeyDate72 = moment
          .utc(uniqueKeyDate)
          .add(INTRODUCTION_EXPIRATION_TIME, "hours")
          .toISOString();
        // Compare expiration date with current time
        if (new Date(uniqueKeyDate72) - new Date() <= 0) {
          setLinkExpired(true);
        } else {
          setLinkExpired(false);
        }
      }
      if (introAcceptedDate) {
        const updatedIntroAcceptedDate = moment.utc(introAcceptedDate); // Replace with your UTC date string

        // Calculate expiration date
        const introAccepted72 = moment
          .utc(updatedIntroAcceptedDate)
          .add(INTRODUCTION_EXPIRATION_TIME, "hours")
          .toISOString();

        // Compare expiration date with current time
        if (new Date(introAccepted72) - new Date() == 0) {
          setIntroductionExpired(true);
        } else {
          setIntroductionExpired(false);
        }
      }
    };

    // Initial check
    checkShareLinkExpired();

    // Set an interval to check visibility every second
    const interval = setInterval(() => {
      checkShareLinkExpired();
    }, 1000);

    return () => clearInterval(interval);
  }, [introShareLinkCreatedDate, introAcceptedDate]);

  const loadDetails = () => {
    return buildReceiveIntroMustHaveHtmlForMobile({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: inventoryDetails,
    });
  };

  const loadNiceToHaveList = (niceToHave) => {
    return niceToHave.map((n2h, index) => {
      const icon = _.get(n2h, "icon", "");

      return (
        <div class="property-tag-mobile mb-2" key={index}>
          <div class="tag-icon-mobile">
            <img src={icon ? require("../images/" + icon) : ""} />
          </div>
          <span>{_.get(n2h, "text", "")}</span>
        </div>
      );
    });
  };

  return (
    <>
      {isValidId === 0 ? (
        <ErrorMessage
          message="The url is not valid"
          wantAdData={AgentInfo}
          isFromSharePublicPage={true}
        />
      ) : linkExpired ? (
        <ErrorMessage
          message="The URL cannot be accessed as the link has expired."
          wantAdData={AgentInfo}
          isFromSharePublicPage={true}
        />
      ) : (introductionExpired && inventoryDetails?.IsReplied !== 1) ||
        inventoryDetails?.Not_A_Fit == 1 ? (
        <ErrorMessage
          message="The URL cannot be accessed as the introduction has expired."
          wantAdData={AgentInfo}
          isFromSharePublicPage={true}
        />
      ) : isValidId === 1 ? (
        <div
          className="gitcha-main-wrapper pb-3 bg-white  intro-preview-wrap"
          id="pageTop"
        >
          <div className="content-wrapper content-wrapper-h-102-percent pt-4">
            <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative premium-content-wrapper">
              <div className="layout-content-btm-sec position-static pe-0 ps-0 pt-0">
                <div className="mt-0 pt-0 mb-0 title01 mb-3">
                  <>
                    {" "}
                    The Buyer Listing Service<sup>TM</sup>
                  </>
                </div>

                <div class="buyerListProfile d-flex align-items-center ">
                  {inventoryDetails?.Img !== "" ? (
                    <div className="profile-img-wrap">
                      <img
                        src={
                          inventoryDetails?.Img +
                          "?" +
                          Math.random().toString(36)
                        }
                      />
                    </div>
                  ) : (
                    <div className="proflieNoImg">
                      <svg
                        width="24"
                        height="23"
                        viewBox="0 0 24 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                          fill="#A0A9BA"
                        />
                      </svg>
                    </div>
                  )}
                  <div>
                    <h5>
                      {inventoryDetails?.FirstName} {inventoryDetails?.LastName}{" "}
                      {inventoryDetails?.PlanName == PRO_MEMBER_PLAN ? (
                        <span className="pro-badge">PRO</span>
                      ) : (
                        <></>
                      )}
                    </h5>
                    Agent @ {inventoryDetails?.Company}
                    <br />
                    {formatDisplayPhoneNumber(inventoryDetails?.Phone)}
                  </div>
                </div>
                <h3 className="mt-3"> Want to know more?</h3>
                <p className="mt-3 mb-3 ProfilegreyTxt">
                  Let {toTitleCase(inventoryDetails?.FirstName)} know if you’re
                  interested in this property or any questions you may have.
                </p>

                <div className="reply-time d-flex align-items-center justify-content-center linkExpiryMob">
                  <CountdownTimer
                    startDate={introShareLinkCreatedDate}
                    isFromSharePublicPage={true}
                  />
                  {/* <i className="ico-timer"></i><p className="m-0">This link will expire in <span>df</span></p> */}
                </div>
              </div>

              <div className="bg-white">
                <div className="confirmation-top-sec">
                  <div className="long-txt-fix">
                    <h2>{_.get(inventoryDetails, "Location", "")}</h2>
                  </div>
                  <div className="property-details-mob mb-4">
                    {getReceiveIntroDescriptionText(inventoryDetails)}
                  </div>
                </div>
                <PhotoGalleryMob photos={photos} />
                <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                  <h2>Details</h2>
                  <div className="row">{loadDetails()}</div>
                </div>
                {description && (
                  <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                    <h2>Description</h2>
                    <p>{description}</p>
                  </div>
                )}

                {isNiceToHaveExist ? (
                  <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                    <h2>Features</h2>
                    {loadNiceToHaveList(nice2Have)}
                  </div>
                ) : (
                  <></>
                )}

                {buildSchoolNeigborhoodHtmlForMobile({
                  mapImg: mapImg,
                  highSchool: highSchool,
                  neighborHood: neighborHood,
                })}

                {salesPercnt ||
                _.get(inventoryDetails, "TimeLineText", null) ||
                _.get(inventoryDetails, "Appraisal_on_File", false) ||
                _.get(inventoryDetails, "Inspection_Report", false) ||
                _.get(inventoryDetails, "Seller_Finance", false) ||
                _.get(inventoryDetails, "Flood_Certificate", false) ||
                _.get(inventoryDetails, "Floor_Plan", false) ||
                _.get(inventoryDetails, "Assumable_mortgage", false) ? (
                  <div className="buyer-info-mob other-details brdr-top-common-wrap mb-4">
                    <h2>Other Details</h2>
                    <ul>
                      {salesPercnt && (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          {salesPercnt}% compensation to buyers agent
                        </li>
                      )}

                      {_.get(inventoryDetails, "TimeLineText", null) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Timeline:{" "}
                          {_.get(inventoryDetails, "TimeLineText", null)}
                        </li>
                      ) : (
                        ""
                      )}

                      {_.get(inventoryDetails, "Appraisal_on_File", false) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Appraisal on file
                        </li>
                      ) : (
                        ""
                      )}

                      {_.get(inventoryDetails, "Inspection_Report", false) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Inspection on file
                        </li>
                      ) : (
                        ""
                      )}

                      {_.get(inventoryDetails, "Seller_Finance", false) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Seller finance available
                        </li>
                      ) : (
                        ""
                      )}
                      {_.get(inventoryDetails, "Flood_Certificate", false) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Flood certification
                        </li>
                      ) : (
                        ""
                      )}

                      {_.get(inventoryDetails, "Floor_Plan", false) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Floor plan on file
                        </li>
                      ) : (
                        ""
                      )}

                      {_.get(inventoryDetails, "Assumable_mortgage", false) ? (
                        <li>
                          <span className="list-icn-wrap-mob"></span>
                          Assumable mortgage
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ShareIntroductionMob;
