import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import LakeViewIcon from "../../../images/lake-view-icon.svg";
import ConsumerIcon from "../../../images/consumer-icon.svg";
import FinanceIconImage from "../../../images/financing-icon.svg";
import ProgressBarInventory from "./ProgressBarInventory";
import TimelineIcon from "../../../images/timeline-icon.svg";
import {
  buildInventoryMustHaveHtmlForMobile,
  buildSchoolNeigborhoodHtmlForMobile,
  checkInventoryType,
} from "../../../helpers/inventory";
import {
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  INVENTORY_LAND,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  IS_LAND,
  LONG_RENT,
  SELL,
  SHORT_RENT,
} from "../../../constants/common";
import { getDescriptionTextForInventory } from "../../../helpers/getPropertyIcons";
import PhotoGalleryMob from "../../../components/Common/PhotoGalleryMob";
import { convertToNumber } from "../../../helpers/validations";
import { getNiceToHaves } from "../../../services/commonServices";
import { saveInventoryStrengthenData, saveMemberInventoryData } from "../../../services/inventoryService";
import {
  clearLocalStorageOnLogout,
  getInitials,
  getLocalStorage,
  getUserId,
  removeLocalStorage,
  setBodyBackgroundWhite,
  setLocalStorage,
} from "../../../helpers/common";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../../reducers/login";

const ReadyToPostInventory = (props) => {
  const { completedPercentage, formValues, setUIKey, uiKey, isPremiumUser, isAgent, handleSaveAndExit, isFromSendIntroduction, isFromMatch} = props;
  const navigate = useNavigate();
  const proprtyPurpose = _.get(formValues, "proprtyPurpose", []);
  const invType = checkInventoryType(proprtyPurpose);
  const city = _.get(formValues, "city", "");
  const state = _.get(formValues, "state", "");
  const zip = _.get(formValues, "zip", "");
  const photos = _.get(formValues, "photos", []);
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const mapImg =
    _.get(formValues, "mapImg", "") + "?" + Math.random().toString(36);
  const highSchool = _.get(formValues, "highSchool", null);
  const neighborHood = _.get(formValues, "neighborHood", null);
  const nice2Have = _.get(formValues, "nice2Have", []);
  const clientName = _.get(formValues, "clientName", []);
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  const description = _.get(formValues, "invtryDetails", "");
  const salesPercnt = _.get(formValues, "salesPercnt", "");
  const salesPercntLTR = _.get(formValues, "salesPercnt_LTR", "");
  const salesPercntSTR = _.get(formValues, "salesPercnt_STR", "");  
  const timeLineTxt = _.get(formValues, "timeLineTxt", "") || _.get(formValues, "tlText", "");

  const typeBasedOnProperty =
    propertyType === IS_LAND ? INVENTORY_LAND : invType;
  let titleText = "";
  switch (invType) {
    case INVENTORY_SELL:
      titleText = " selling";
      break;
    case INVENTORY_LTR:
      titleText = " renting";
      break;
    case INVENTORY_STR:
      titleText = " renting";
      break;
    case INVENTORY_LTR_STR:
      titleText = " renting";
      break;
    case INVENTORY_SELL_LTR:
      titleText = " selling, renting";
      break;
    case INVENTORY_SELL_STR:
      titleText = " selling, renting";
      break;
    default:
      titleText = " selling, renting";
      break;
  }

  useEffect(() => {
    setBodyBackgroundWhite()
    getNiceToHaves(typeBasedOnProperty)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    const isDisable = false;
    setDisableSave(isDisable);
  }, []);

  const loadDetails = () => {
    return buildInventoryMustHaveHtmlForMobile({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: formValues,
    });
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      typeBasedOnProperty === INVENTORY_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    return finalList;
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    const masterArray = _.get(niceToHavesList, section, []);
    if (selectedItems.length) {
      return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
    } else {
      return "";
    }
  };

  const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        const icon = _.get(masterItem[0], "Icon", "");
        return (
          <div className="property-tag-mobile mb-2" key={id + uniqueKey}>
            <div className="tag-icon-mobile">
              <img
                src={icon ? require("../../../images/" + icon) : LakeViewIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const handleStateClear = () => {
    // alert('Your session has expired. Please login to continue')
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate(pageURLs.mobileHome);
  };

  const saveStrengthenData = async (InventoryId, isAddInventry) => {
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(INVENTORY_KEY);
    }
    const userId = getUserId();
    requestPayLoad.inventoryId = InventoryId;
    requestPayLoad.userId = userId;
    requestPayLoad.isStrengthen = true;
    await saveInventoryStrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          if(isAddInventry){
            setLocalStorage(INVENTORY_KEY, {
              inventoryId: _.get(responseResult, "inventoryId", ""),
              showSuccessModal: true, // This key-value is for showing the success pop up for the first time in details page after save
              isFromSendIntroduction : isFromSendIntroduction,
              isFromMatch : isFromMatch
            });
            navigate(pageURLs.mobilememberInventoryDetails);
            removeLocalStorage(INVENTORY_KEY_TEMP);
          }
          else {
            removeLocalStorage(INVENTORY_KEY);
            removeLocalStorage(INVENTORY_KEY_TEMP);
            navigate(pageURLs.mobileManageInventory, { state: { showDraft: true } });} 
      }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const saveMemberInventory = (isAddInventry) => {
    //////////////////// need to remove after edit want-ad flow completed (issue 169)
    // handleFormValues({ isEdit: false });

    //let updatedState ={}
    const userId = getUserId(); //_.get(user, "userInfo.userId", null);
    // if (!userId) {
    //   const userDetails = getLocalStorage(USER_INFO_KEY);
    //   userId = _.get(userDetails, "userInfo.userId", null);
    // }
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(INVENTORY_KEY);
    }
    const proprtyPurpose = _.get(requestPayLoad, "proprtyPurpose", []);
    const is_Sell_Selected = _.includes(proprtyPurpose, SELL);
    if (!is_Sell_Selected) {
      requestPayLoad.sellAskngPrice = "";
    }
    requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
    requestPayLoad.actvStep = _.get(formValues, "actvStep", 1);
    requestPayLoad.userId = userId;
    if (userId && requestPayLoad) {
      if (isAddInventry) {
        requestPayLoad.IsSavedDraft = false;
        requestPayLoad.IsPublished = true;
        delete requestPayLoad.tlText;
        delete requestPayLoad.prptyTypeTxt;
        delete requestPayLoad.step6Errors;
        if(isAgent){
          requestPayLoad.isAgentCreated = true
        }
        if(isAgent && !isPremiumUser){
          requestPayLoad.isAgentAsSeller = true;
        }
      } else {
        requestPayLoad.IsSavedDraft = true;
        requestPayLoad.IsPublished = false;
      }

      saveMemberInventoryData(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (isAddInventry) {
              if(!requestPayLoad.inventoryId){
                // for setting the match tab client data
                setLocalStorage("MatchSelectedClient",  
                {FirstName: requestPayLoad.clientName, 
                  LastName: requestPayLoad.clientName,
                  AgentAsSeller: requestPayLoad.isAgentCreated,
                  label: requestPayLoad.clientName,
                  value:  requestPayLoad.clientId
                });

                let propertyPurpose = _.get(requestPayLoad, "proprtyPurpose", []);

                setLocalStorage("selectedPropertyId", 
                {
                  Inv_Id: _.get(responseResult, "inventoryId", ""),
                  Location: requestPayLoad.locAddress,
                  City: requestPayLoad.city,
                  Is_LongTerm_Renter: _.includes(propertyPurpose, LONG_RENT),
                  Is_Seller: _.includes(propertyPurpose, SELL),
                  Is_ShortTerm_Renter: _.includes(propertyPurpose, SHORT_RENT),
                  State: requestPayLoad.state,
                  photo:  requestPayLoad.photos && requestPayLoad.photos[0]
    
                });
                
              }
            }

            if (requestPayLoad.apprOnFile || requestPayLoad.insReport || requestPayLoad.sellerFin || requestPayLoad.floorPlan || requestPayLoad.floodCert || requestPayLoad.tlText) {
              saveStrengthenData(responseResult.inventoryId, isAddInventry);
            }
            else if (isAddInventry) {              
              setLocalStorage(INVENTORY_KEY, {
                inventoryId: _.get(responseResult, "inventoryId", ""),
                showSuccessModal: true, // This key-value is for showing the success pop up for the first time in details page after save
                isFromSendIntroduction : isFromSendIntroduction,
                isFromMatch : isFromMatch
              });
              navigate(pageURLs.mobilememberInventoryDetails);
              removeLocalStorage(INVENTORY_KEY_TEMP);
            } else {
              removeLocalStorage(INVENTORY_KEY);
              removeLocalStorage(INVENTORY_KEY_TEMP);
              
            }
              // if(isAddInventry){
              //   navigate(pageURLs.viewProperty);
              // }
              // else{
              //   navigate(pageURLs.inventoryListing, {state: { showDraft: true }});
              // }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const isNiceToHaveExist = nice2Have.length > 0;

  return (
    <div className="gitcha-main-wrapper pb-154 bg-white">
      <div className="content-wrapper content-wrapper-h-102-percent">
        <ProgressBarInventory
          setUIKey={setUIKey}
          completedPercentage={completedPercentage}
          uiKey={uiKey}
        />
        <div className="inner-layout-content-new h-100 bg-white">
          <div className="premium-content-wrapper pe-3 ps-3 pt-2 bg-white min-h-100">
            <div className="ready-to-post-mobheader mb-3">
              <h2>Ready to add to your private inventory?</h2>
              <p>Confirm the details about your property.</p>
            </div>

            {isPremiumUser && (
              <div className="readytopost-client-info mb-3">
                <div className="client-info-name">
                  Client <span>This information is only visible to you. </span>
                  <div className="client-profile-small ">
                    <div
                      className={
                        "client-profile-img-small " +
                        getInitials(clientName)?.backgroundColor
                      }
                    >
                      {getInitials(clientName)?.initials}
                    </div>
                    <div className="client-profile-txt">{clientName}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="ready-to-post-mob-wrap mb-3">
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    I'm open to:{" "}
                    <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                      {titleText}
                    </span>
                  </div>
                  <h2>{_.get(formValues, "locAddress", "")}</h2>
                  {/* <span className="trailing-txt pb-1">
                    {loadInventoryLocation({
                      city: city,
                      state: state,
                      zip: zip,
                    })}
                  </span> */}
                </div>
                <div className="property-details-mob mb-4">
                  {getDescriptionTextForInventory(formValues)}                  
                </div>
              </div>              
              <PhotoGalleryMob photos={photos} setUIKey={setUIKey}/>      

              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h2>Details</h2>
                <div className="row">{loadDetails()}</div>
              </div>

              {buildSchoolNeigborhoodHtmlForMobile({
                mapImg: mapImg,
                highSchool: highSchool,
                neighborHood: neighborHood,
              })}

              {isNiceToHaveExist ? (
                <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                  <h2>Features</h2>
                  {finalNiceToHaveList()}
                </div>
              ) : (
                <></>
              )}

              {description && (
                <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                  <h2>Description</h2>
                  <p>{description}</p>
                </div>
              )}

              <div className="buyer-info-mob brdr-top-common-wrap pb-0">
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap-mob">
                      <img src={ConsumerIcon} />
                    </span>
                    {isAgent ? "Represented by agent" : "Property Owner"}
                  </li>
                  {salesPercnt && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={FinanceIconImage} />
                      </span>
                      {isAgent ? `${salesPercnt}% compensation to buyer's agent` : `Seller is offering ${salesPercnt}% to buyer's agent`}
                    </li>
                  )}
                  {salesPercntLTR && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={FinanceIconImage} />
                      </span>
                     {isAgent ? `${salesPercntLTR}% compensation to renter's
                      agent` : `Long-term Renter is offering ${salesPercntLTR}% to renter's
                      agent`}
                    </li>
                  )}
                  {salesPercntSTR && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={FinanceIconImage} />
                      </span>
                      Short-term Renter is offering {salesPercntSTR}% to
                      renter's agent
                    </li>
                  )}
                  {timeLineTxt && (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={TimelineIcon} />
                      </span>
                      Timeline: {timeLineTxt}
                    </li>
                  )}
                  {_.get(formValues, "apprOnFile", false) ? (
                      <li>
                        <span className="list-icn-wrap-mob">
                          <img src={ConsumerIcon} />
                        </span>
                        Appraisal on file
                      </li>
                    ) : (
                      ""
                    )}

                    {_.get(formValues, "insReport", false) ? (
                      <li>
                        <span className="list-icn-wrap-mob">
                          <img src={ConsumerIcon} />
                        </span>
                        Inspection report on file
                      </li>
                    ) : (
                      ""
                    )}
                    {_.get(formValues, "sellerFin", false) ? (
                      <li>
                        <span className="list-icn-wrap-mob">
                          <img src={ConsumerIcon} />
                        </span>
                        Seller finance available
                      </li>
                    ) : (
                      ""
                    )}

                    {_.get(formValues, "floorPlan", false) ? (
                      <li>
                        <span className="list-icn-wrap-mob">
                          <img src={ConsumerIcon} />
                        </span>
                        Floor plan on file
                      </li>
                    ) : (
                      ""
                    )}
                    {_.get(formValues, "floodCert", false) ? (
                      <li>
                        <span className="list-icn-wrap-mob">
                          <img src={ConsumerIcon} />
                        </span>
                        Flood certificate available
                      </li>
                    ) : (
                      ""
                    )}
                     {_.get(formValues, "assMortgage", false) ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={ConsumerIcon} />
                      </span>
                      Assumable mortgage
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>

              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav">
          <div className="btn-wrap text-center pb-40 pt-0">
            <button
              type="button"
              disabled={disableSave}
              className="btn btn-primary w-100 mb-20"
              onClick={() => {
                const isAddInventry = true;
                saveMemberInventory(isAddInventry);
              }}
            >
              Upload
            </button>
            <button type="button"
             class="btn btn-no-bg btn-no-bg-fix w-100"
             onClick={()=>handleSaveAndExit()}
             >
              Save & Exit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyToPostInventory;
