import React, { useState, useEffect, useRef } from "react";
import { pageURLs } from "../../constants/pageURLs";
import noImage from "../../images/no-image-small.png";
import { getpropertyName, removeDecimalZeros, truncate } from "../../helpers/common";
import Button from "react-bootstrap/Button";
import { filterArray, thousandSeperatedValue } from "../../helpers/common";
import { IS_LAND, LOT_TYPES } from "../../constants/common";
import { useNavigate } from "react-router-dom";

const ViewWantAdIntroductions = (props) => {
  const { list, updateIntroPageOnScroll, getIntroductionDetails, introCount } =
    props;
  const navigate = useNavigate();
  const myRef = useRef(null);
  const handleScroll = () => {
    const el = myRef.current;
    const scrollHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const clientHeight = el.clientHeight;
    if (
      scrollHeight !== clientHeight &&
      scrollTop !== 0 &&
      Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
    ) {
      updateIntroPageOnScroll();
    } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
      //setCurrPage(prevState => prevState -1);
    }
  };

  return (
    <>
      {/* <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2"> */}
      <div className="ad-strengthen-wrap mb-0 mb-sm-4">
        <h6 className="strengthen-sub-head">
          Introduced to this want-ad (<span>{introCount}</span>)
        </h6>
        <div className="view-intro-wrap" onScroll={handleScroll} ref={myRef}>
          {list.map((intro, index) => {
            const askingPrice = _.get(intro, "AskingPrice", "");
            const lotType = _.get(intro, "LotType_Rural_City", "");
            // const inventoryImage =
            // (_.get(intro, "Photo_1", '')!== 'null' && !_.get(intro, "Photo_1", ''))
            //     ? _.get(intro, "Photo_1", "")
            //     : noImage;
            const inventoryImage =
              (_.get(intro, "Photo_1", '') !== 'null' && _.get(intro, "Photo_1", '') !== 'undefined' && _.get(intro, "Photo_1", '') !== null)
                ? _.get(intro, "Photo_1", "")
                : noImage;
            const city = _.get(intro, "City", '') ? _.get(intro, "City", '') : "";
            const state = _.get(intro, "State", '') ? (_.get(intro, "State", '')) : '';
            const location = (city) + (state && (", " + state));
            return (
              <div
                className="add-your-property-wrap flex-column mb-4"
                key={index}
              >
                <div className="d-flex w-100 mb-3">
                  <div className="make-intro-img">
                    <img src={inventoryImage} alt={inventoryImage} />
                  </div>
                  <div className="make-intro-details pe-0">
                    <h3 className="mb-0 long-txt-fix">
                      {getpropertyName(intro)}{" "}
                      <span className="f-regular long-txt-fix">in</span>{" "}
                      {/* {truncate(_.get(intro, "Location", ""), 20)} */}
                      {truncate(location, 20)}
                      {/* {truncate(_.get(intro, "Title_Text", ""), 25)} */}
                    </h3>

                    {_.get(intro, "PropertyTypeId", "") === IS_LAND ? (
                      <ul className="intro-list">
                        <li> {removeDecimalZeros(_.get(intro, "TotalAcre", ""))} Acres</li>

                        {lotType && (
                          <li>
                            <span>&bull;</span>
                            {filterArray(LOT_TYPES, "value", lotType)}
                          </li>
                        )}

                        {/* <li>
                          <span>&bull;</span>2600 sqft
                        </li> */}
                      </ul>
                    ) : (
                      <ul className="intro-list">
                         {(_.get(intro, "Bedrooms", "")) &&
                        <li>{removeDecimalZeros(_.get(intro, "Bedrooms", ""))} Beds</li>
                         }
                           {(_.get(intro, "Bathrooms", "")) &&
                        <li>
                          <span>&bull;</span>
                          {removeDecimalZeros(_.get(intro, "Bathrooms", ""))} Baths
                        </li>
          }
            {(_.get(intro, "Sqft", "")) &&
                        <li>
                          <span>&bull;</span>
                          {removeDecimalZeros(_.get(intro, "Sqft", ""))} sqft
                        </li>
          }
                      </ul>
                    )}


                    <div className="intro-amt">
                      ${askingPrice && thousandSeperatedValue(askingPrice)}
                    </div>
                    {
                      _.get(intro, "IsExpired", "") == 1 &&
                      <div className="modal-wrap-label-orange mt-2 f-size-14">
                        Expired
                      </div>
                    }
                  </div>
                </div>
                {
                   _.get(intro, "IsExpired", "") != 1 &&
                  <div className="d-flex w-100">
                    <button
                      type="button"
                      class="btn btn-outline-primary w-100 l-spacing-0"
                      onClick={() =>
                        getIntroductionDetails(_.get(intro, "IntroductionId", ""), _.get(intro, "IsAccept", ""))
                      }
                    >
                      View Details
                    </button>
                  </div>
              }
              </div>
            );
          })}
        </div>

        <Button
          variant="primary"
          className="w-100 mb-0 mt-3 l-spacing-0"
          onClick={() => navigate(pageURLs.allIntroductions)}
        >
          View All
        </Button>
      </div>
    </>
  );
};
export default ViewWantAdIntroductions;
