import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FilterCategory from "../Dashboard/FilterCategory";
import FilterLocationSearch from "../Dashboard/FilterLocationSearch";
import FilterKeywordSearch from "../Dashboard/FilterKeywordSearch";
import Accordion from "react-bootstrap/Accordion";
import Collapse from "react-bootstrap/Collapse";
import { filterContext } from "../../../pages/Home";
import { BUY, USER_TYPE_AGENT, WANT_AD_PAGE_LIMIT } from "../../../constants/common";
import HouseTagIcon from "../../../images/house-img.png";
import { getPropertyTypes } from "../../../services/commonServices";
import { handleRadioButonChange } from "../../../actions/common";
import RangeSliderComponent from "../../Common/RangeSlider";
import { USER_TYPE_MEMBER } from "../../../constants/common";
import TextBox from "../../Common/TextBox";
import {
  replaceComma,
  replaceString,
  thousandSeperatedValue,
  getLocalStorage,
  thousandSeperatedValueFilter,
  setLocalStorage,
  removeLocalStorage,
  decodeParam
} from "../../../helpers/common";
import { USER_INFO_KEY, LONG_RENT } from "../../../constants/common";
import { usePrevious } from "../../../hooks/usePrevious";
import SelectProperty from "./SelectProperty";
import SelectClient from "./SelectClient";
import { pageURLs } from "../../../constants/pageURLs";
import { fetchPropertyList } from "../../../actions/inventory";
import AddProperty from "./AddProperty";
import MemberProperties from "./MemberProperties";
import AgentProperties from "./AgentProperties";
import { getIntroClientList, getMatchClientList } from "../../../services/agentClientsService";
import { toast } from "react-toastify";
import { renameKey, combineKey } from "../../../helpers/common";
import { getInventoryListByUserId } from "../../../services/inventoryService";
import { handleNegativeInput } from "../../../helpers/validations";
import { fetchUserDetails } from "../../../actions/userprofile";
import { useSearchParams } from "react-router-dom";

const LeftpanelTabContentRight = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterCntxt = useContext(filterContext);
  const [searchParams] = useSearchParams();
  const invIdfromUrl = searchParams.get("InvId");
  const clientIdFromUrl = searchParams.get("ClientId");


  const decodedInvId = invIdfromUrl ? decodeParam(invIdfromUrl) : null;
  const decodedClientId = clientIdFromUrl ? decodeParam(clientIdFromUrl) : null;


  const previouslySelectedClient = getLocalStorage("MatchSelectedClient")
  const previouslySelectedProperty = getLocalStorage("selectedPropertyId")
  const { adType, handleAdTypeClick} = filterCntxt;
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const userType = _.get(sessionData, "userInfo.userType", "");
  const userId = _.get(sessionData, "userInfo.userId", "");
  const [showSelectProperty, setShowSelectProperty] = useState(false);
  const [showSelectClients, setShowSelectClients] = useState(false);
  const [propertyList, setPropertyList] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [budgetRangeGitchaGenie, setBudgetRangeGitchaGenie] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const [userDetails, setUserDetails] = useState(null)
  const {updateFilterGitchaGenie, selectedProperty, setSelectedProperty} = props;
  const key = getLocalStorage("FilterTabKey");
  const previousBudgetMin = getLocalStorage("priceRangeMatch")
  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;
  const [isPremiumUser , setIsPremiumUser] = useState(null);


  if (adType === BUY) {
    DEFAULT_MAX_BUDGET = 5000000;
    DEFAULT_STEP_VALUE = 25000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
    DEFAULT_STEP_VALUE = 100;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
    DEFAULT_STEP_VALUE = 50;
  }

  const budgetMin = previousBudgetMin && previousBudgetMin[0];
  const budgetMax = previousBudgetMin && previousBudgetMin[1];

  const prevMinBdgt = usePrevious(budgetRangeGitchaGenie[0]);
  const prevMaxBdgt = usePrevious(budgetRangeGitchaGenie[1]);
  const prevAdType = usePrevious(adType);
  const prevInventoryId = usePrevious(selectedProperty?.Inv_Id);
  const [reload, setReload] = useState(0)

  useEffect(() => {
    getClientList();
    if(userId){
    dispatch(fetchUserDetails())}
  }, [key, adType, reload]);

  useEffect(()=>{    
    if(userDetailsFromStore !== null && userDetailsFromStore?.length>0){
      setUserDetails(userDetailsFromStore[0])   
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
        setLocalStorage("isPremiumUser", 2) // 2 for  premium
      }
      else{
        setIsPremiumUser(false);
        setLocalStorage("isPremiumUser", 1) // 1 for non premium
      }   
    }
    else{
      setUserDetails(null)
    }
  },[userDetailsFromStore])

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  useEffect(() => {
    if(selectedClient){
      // removeLocalStorage("selectedPropertyId")
      setLocalStorage("MatchSelectedClient", selectedClient)
      getInventoryList();
    }    
    if(adType == prevAdType){
      let DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET];

        if (budgetMin && !budgetMax) {
          DEFAULT_BUDGET_RANGE = [budgetMin, DEFAULT_MAX_BUDGET];
        } else if (!budgetMin && budgetMax) {
          DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, budgetMax];
        } else if (budgetMin && budgetMax) {
          DEFAULT_BUDGET_RANGE = [budgetMin, budgetMax];
        }
        setBudgetRangeGitchaGenie(DEFAULT_BUDGET_RANGE);
      }
      else {
        setBudgetRangeGitchaGenie([DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET])
      }
    

  }, [adType, selectedClient]); 

  

  useEffect(() => {
    if (clientList && clientList?.length > 0) {
      if(decodedClientId){
        const clientFromUrl = clientList?.find((client)=>client.value == decodedClientId)
        if(clientFromUrl){
          setSelectedClient(clientFromUrl);
        }
        else{
          setSelectedClient(clientList[0]);
        }
      }
      else if(previouslySelectedClient){
        const previousClient = clientList?.find((client)=>client.value == previouslySelectedClient.value)
        if(previousClient){
          setSelectedClient(previousClient)
        }
        else{
          setSelectedClient(clientList[0]);
        }
      }
      else {
        setSelectedClient(clientList[0]);
      }
    }
  }, [clientList, decodedClientId]);

  useEffect(() => {
    if(prevInventoryId!=selectedProperty?.Inv_Id || adType != prevAdType){
      setLocalStorage("selectedPropertyId", selectedProperty);
      updateFilterGitchaGenie({ inventoryId: selectedProperty?.Inv_Id, city: selectedProperty?.City, state: selectedProperty?.State});  
    }  
  }, [selectedProperty]);

  useEffect(() => {
    if (propertyList && propertyList.length > 0) {

      if(decodedInvId != null){
        const invFromUrl = propertyList.find((property)=>property.Inv_Id==decodedInvId);
        if(invFromUrl){
          setSelectedProperty(invFromUrl)
        }
        else{
          setSelectedProperty(propertyList[0]);
        }
      }
      else if(previouslySelectedProperty){
        const previousProperty = propertyList.find((property)=>property.Inv_Id==previouslySelectedProperty.Inv_Id)
        if(previousProperty){
          setSelectedProperty(previousProperty)
        }
        else{
          setSelectedProperty(propertyList[0]);
        }
      }  
      else{ setSelectedProperty(propertyList[0]); }    
      
    }
    else {
      removeLocalStorage("selectedPropertyId");
      setSelectedProperty(null)
    }
  }, [propertyList, decodedInvId]);

  useEffect(() => {
   if(propertyList != null){ 
    if (prevMinBdgt && prevMinBdgt !== budgetRangeGitchaGenie[0]) {
      setLocalStorage("priceRangeMatch", budgetRangeGitchaGenie)
      updateFilterGitchaGenie({ priceMin: budgetRangeGitchaGenie[0]});
    }    
  }
},[budgetRangeGitchaGenie[0]]);

useEffect(() => {
  if(propertyList != null){ 
    const mxBdgt = budgetRangeGitchaGenie[1] === DEFAULT_MAX_BUDGET ? "" : budgetRangeGitchaGenie[1];
    if (prevMaxBdgt && prevMaxBdgt !== budgetRangeGitchaGenie[1]) {
      setLocalStorage("priceRangeMatch", budgetRangeGitchaGenie)
      updateFilterGitchaGenie({ priceMin : budgetMin, priceMax : mxBdgt});
    } 
 }
},[budgetRangeGitchaGenie[1]]);

// const updateRangeSliders = async () => {
//   updateFilterGitchaGenie({ priceMin: budgetRangeGitchaGenie[0], priceMax: budgetRangeGitchaGenie[1]===3001000 ? null : budgetRangeGitchaGenie[1]});
// };


const handleBudgetMinChange = (e) => {
  const budgetMax = budgetRangeGitchaGenie && budgetRangeGitchaGenie[1];
  const value = handleNegativeInput(e.target.value);
  if(parseInt(replaceComma(value))){
    setBudgetRangeGitchaGenie([parseInt(replaceComma(value)), budgetMax]);
  }
  else{
    setBudgetRangeGitchaGenie([null, budgetMax]);
  }
  // updateFilterGitchaGenie({ priceMin: replaceComma(value) });
};

const handleBudgetMaxChange = (e) => {
  const budgetMin = budgetRangeGitchaGenie && budgetRangeGitchaGenie[0];
  const value = handleNegativeInput(e.target.value);
  if(parseInt(replaceComma(value))){
    setBudgetRangeGitchaGenie([budgetMin, parseInt(replaceComma(value))]);
  }
  else{
    setBudgetRangeGitchaGenie([budgetMin, null]);
  }
  // updateFilterGitchaGenie({ priceMax: replaceComma(value) });  
};

const checkBudgetValues = () => {
  let isValid = true;
  setMinError("");
  setMaxError("");
  if (budgetRangeGitchaGenie && convertToNumber(budgetRangeGitchaGenie[0]) > convertToNumber(budgetRangeGitchaGenie[1])) {
    isValid = false;
    setMinError("Value Must be less than maximum");
  }
  if (budgetRangeGitchaGenie && convertToNumber(budgetRangeGitchaGenie[1]) < convertToNumber(budgetRangeGitchaGenie[0])) {
    isValid = false;
    setMaxError("Value Must be greater than minimum");
  }
};

  const getInventoryList = async () => {
    if (userId) {
      let query;
      if (userType == 2 && isPremiumUser) {
        query = {
          adType: adType,
          clientId: selectedClient?.value,
        };
      } else {
        query = {
          adType: adType,
        };
      }
      await getInventoryListByUserId(query)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (responseResult.length > 0) {
              setPropertyList(responseResult);
            } else {
              setPropertyList(null);
            }
          } else {
            // toast.error("Something went wrong");
          }
        })
        .catch((error) => toast.error(error));
    }
  };

  const getClientList = async () => {
    if (userId && key=='match') {      
      // await getIntroClientList()
      await getMatchClientList()
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            responseResult?.ClientList.forEach((obj) =>
              renameKey(obj, "ClientId", "value")
            );
            responseResult?.ClientList.forEach((obj) =>
              combineKey(obj, "FirstName", "LastName", "label")
            );
            setClientList(responseResult?.ClientList);
          } else {
            // toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          // toast.error("Error", err);
        });
    }
  };
  const clearFilter = () => {
    // clearFilterGitchaGenie();
    // handleAdTypeClick(BUY)
    removeLocalStorage("priceRangeMatch");
    setBudgetRangeGitchaGenie([DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET]);
  };

  return (
    <>
      {showSelectProperty && (
        <SelectProperty
          setShowSelectProperty={setShowSelectProperty}
          propertyList={propertyList}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          userType={userType}
          selectedClient={selectedClient}
          userDetails={userDetails}
          updateReloadParam={updateReloadParam}
        />
      )}
      {showSelectClients && (
        <SelectClient
          clientList={clientList}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          setShowSelectClients={setShowSelectClients}
        />
      )}
      {!showSelectClients && !showSelectProperty && (
        <>
          <FilterCategory />

          <div className="filter-properties-wrap pb-20">
            <h6>My Properties</h6>
            {userType === USER_TYPE_MEMBER || userType=== USER_TYPE_AGENT && !isPremiumUser  ? (
              <MemberProperties
                propertyList={propertyList}
                setShowSelectProperty={setShowSelectProperty}
                selectedProperty={selectedProperty}
                userDetails={userDetails}
              />
            ) : (
              <AgentProperties
                propertyList={propertyList}
                setShowSelectProperty={setShowSelectProperty}
                setShowSelectClients={setShowSelectClients}
                clientList={clientList}
                userId={userId}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                selectedProperty={selectedProperty}
                userDetails={userDetails}
              />
            )}
          </div>
          <div className="filter-properties-wrap mt-3 border-bottom-0">
            <h6>Price</h6>
            <div className="slider-sec">
              <div className="pb-4 pt-3">
              <RangeSliderComponent
                  min={DEFAULT_MIN_BUDGET}
                  max={DEFAULT_MAX_BUDGET}
                  // defaultValue={[BUDGET_MIN, BUDGET_MAX]}
                  value={budgetRangeGitchaGenie && [budgetRangeGitchaGenie[0]==null ? DEFAULT_MIN_BUDGET : budgetRangeGitchaGenie[0] ,budgetRangeGitchaGenie[1]==null ? DEFAULT_MAX_BUDGET : budgetRangeGitchaGenie[1]]}
                  stepValue={DEFAULT_STEP_VALUE}
                  onInput={setBudgetRangeGitchaGenie}
                />
              </div>
              <ul className="range-values-list rs-pb-37">
                <li className="position-relative">
                  <span className="dollar-sign-icon">$</span>
                  <label className="d-none"></label>
                  <TextBox
                    type="text"
                    class="form-control pad-left-25"
                    errorMessage={minError}
                    // maxValue={BUDGET_MIN}
                    value={
                      budgetRangeGitchaGenie && budgetRangeGitchaGenie[0]==="" ? "" : thousandSeperatedValueFilter(budgetRangeGitchaGenie[0])  
                    }
                    placeholder="0"
                    onChange={(e) => {
                      handleBudgetMinChange(e);
                    }}
                  ></TextBox>
                  <div className="error-msg"></div>
                </li>
                <li className="text-center">
                  <span className="middle-separator">-</span>
                </li>
                <li className="position-relative">
                  <span className="dollar-sign-icon">$</span>
                  <label className="d-none"></label>
                  <TextBox
                    type="text"
                    class="form-control pad-left-25"
                    errorMessage={maxError}
                    // maxValue={BUDGET_MAX}
                    value={
                      budgetRangeGitchaGenie && (budgetRangeGitchaGenie[1]===DEFAULT_MAX_BUDGET) ? "No max" : thousandSeperatedValue(budgetRangeGitchaGenie[1])                     
                    }
                    placeholder="No max"
                    onChange={(e) => {
                      handleBudgetMaxChange(e);
                    }}
                  ></TextBox>
                </li>
              </ul>

              {/*<Row>
        <Col md={6}>
        <input type="text" className="form-control mb-4 mb-sm-4 mb-md-0" />
       </Col>
       <Col md={6}>
       <input type="text" className="form-control" />
       </Col>
       </Row> */}
            </div>
          </div>
          {/*<div className="add-your-property-wrap">
                <div className="add-property-btn">
                  <img src={AddPropertyIcon} alt={AddPropertyIcon} />
                </div>
                <div className="make-intro-details">
                  <h3 className="mb-0">Add your property</h3>
                </div>

                <a
                  role="button"
                  class="right-arrow-link"
                  href={void 0}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                      fill="#000022"
                    />
                  </svg>
                </a>
                </div>*/}

          <div className="mt-2">
            <a
              role="button"
              className="reset-btn"
              onClick={() => clearFilter()}
            >
              Clear all
            </a>
          </div>
        </>
      )}
    </>
  );
};
export default LeftpanelTabContentRight;
