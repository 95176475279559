import React from 'react';

const RadioButton = props => {
  return (
      <input
        className={props.class}
        id={props.id}
        name={props.name}
        type= "radio"
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        onClick= {props.onClick}
        disabled = {props.disabled}
      />
  );
}
export default (RadioButton);
