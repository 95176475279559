import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import ClientNotesInfo from "../components/clientManagement/ClientNotesInfo";
import AddNoteoffcanvas from "../components/clientManagement/AddNoteoffcanvas";
import {
    deleteClientNote,
    getClientNotes,
    getClientNoteListPdfExport
  } from "../../services/agentClientsService";
import { getLocalStorage, setBodyBackgroundGrey } from "../../helpers/common";
import { CLIENT_DETAILS_KEY } from "../../constants/common";
import { toast } from "react-toastify";
import { getClientDetailsMobile } from "../../services/agentClientsService";
import "../styles/clients.scss";
import HandleScroll from "../commonComponents/HandleScroll";
const PAGE_LIMIT = 10;


const ClientNotesListing = (props) => {
  const scrollStates = HandleScroll()
  const isReachedBottom =  _.get(scrollStates, 'isReachedBottom', null)
  const location = useLocation()
  const clientId = location.state && location.state['clientId'] 
    const navigate = useNavigate();
    //  const clientId = _.get(getLocalStorage(CLIENT_DETAILS_KEY), "id", "");
  useEffect(() => {
    setBodyBackgroundGrey()
    document.body.classList.add('bg-offcanvas-fix');
   
  }, []);

  const myRef = useRef(null);
  const INITIAL_PAGE_INDEX = 1;
  const [clientDetails, setClientDetails] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [isListUpdated, setListUpdated] = useState(false);
  const [clientNotes, setClientNotes] = useState(null);
  const [showAddNote, setShowAddNote] = useState(false);
  const AddNoteClose = () => setShowAddNote(false);
  const AddNoteShow = () => setShowAddNote(true);

  const reloadList = () => {
    setListUpdated(true);
    setCurrPage(1);
    setPrevPage(0);
    setClientNotes(null);
    scrollToBottom();
  }

  const fetchData = async (reqPayload) => {
    await getClientNotes(reqPayload)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0) {
            if (currPage === INITIAL_PAGE_INDEX) {
                setClientNotes([...responseResult]);
            } else {
                setClientNotes([...clientNotes, ...responseResult]);
            }
            setPrevPage(currPage);
        }
        setListUpdated(false);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    if (currPage !== prevPage) {
        const data = {
            clientId: clientId,
            page: currPage,
            limit: PAGE_LIMIT,
        };
        fetchData(data);
    }
  }, [
    currPage,
    isListUpdated == true,
  ]);


  const loadClientDetails = async () => {
    setIsRefresh(false);
    await getClientDetailsMobile(clientId)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setClientDetails(responseResult?.clientDetails);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    loadClientDetails();
  }, [isRefresh]);

  const deleteClientNotes = async (reqPayload) => {
    return await deleteClientNote(reqPayload)
      .then((response) => {
        if (response.errorCode === 0) {
          // toast.success("Note added.", {
          //   position: toast.POSITION.BOTTOM_RIGHT
          // });
          reloadList();
          toast.success("Note deleted.");
          return response;
        } else {
          console.log("Something went wrong");
          return "";
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleExportClientNotes = async() => {
    const payload = {clientId : clientId}
    await getClientNoteListPdfExport(payload).then((response)=>{
      const result = _.get(response, "result", {});      
      if(!result.status){
        toast.error(result.message)
      }
    })
  } 

  // const handleScroll = () => {
  //   const el = myRef.current;
  //   if(el){
  //     const scrollHeight = el.scrollHeight;
  //     const scrollTop = el.scrollTop;
  //     const clientHeight = el.clientHeight;
  //     if (
  //       scrollHeight !== clientHeight &&
  //       scrollTop !== 0 &&
  //       Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
  //     ) {
  //       setCurrPage((prevState) => prevState + 1);
  //     } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
  //       //setCurrPage(prevState => prevState -1);
  //     }
  //   }
  // };

  useEffect(() => {
    if (isReachedBottom) {
      setCurrPage((prevState) => prevState + 1);
    }
  }, [isReachedBottom]);

const scrollToBottom = () => {
    const el = myRef.current;
    if (el) {
        el.scrollTop = el.scrollHeight - el.clientHeight;
    }
}

  return (
    
    <div className="gitcha-main-wrapper my-client-wrap view-client-wrap client-note-wrap">

        <div className="content-wrapper content-wrapper-h-102-percent">
              <div className="inner-layout-header-new bg-white">
                  <div className="top-three-comp-header w-100 top-three-comp-headerborder">
                      <div className="top-three-comp-header-inner w-100">
                          <a className="back-link-btn" href={void(0)} onClick={() => navigate(pageURLs.viewClientDetailsMob, { state: { clientId: clientId }})}>
                              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                              </svg>
                          </a>
                          <div className="heading">{clientDetails?.FirstName}'s Notes</div>
                          <div className="right-mobbtn-wrap">   
                            <a className="action-btn" href="#" onClick={(e) => { handleExportClientNotes() }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.5 11.875V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V11.875C2.5 11.7092 2.56585 11.5503 2.68306 11.4331C2.80027 11.3158 2.95924 11.25 3.125 11.25C3.29076 11.25 3.44973 11.3158 3.56694 11.4331C3.68415 11.5503 3.75 11.7092 3.75 11.875V16.25H16.25V11.875C16.25 11.7092 16.3158 11.5503 16.4331 11.4331C16.5503 11.3158 16.7092 11.25 16.875 11.25C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875ZM9.55781 12.3172C9.61586 12.3753 9.68479 12.4214 9.76066 12.4529C9.83654 12.4843 9.91787 12.5005 10 12.5005C10.0821 12.5005 10.1635 12.4843 10.2393 12.4529C10.3152 12.4214 10.3841 12.3753 10.4422 12.3172L13.5672 9.19219C13.6253 9.13412 13.6713 9.06518 13.7027 8.98931C13.7342 8.91344 13.7503 8.83212 13.7503 8.75C13.7503 8.66788 13.7342 8.58656 13.7027 8.51069C13.6713 8.43482 13.6253 8.36588 13.5672 8.30781C13.5091 8.24974 13.4402 8.20368 13.3643 8.17225C13.2884 8.14083 13.2071 8.12465 13.125 8.12465C13.0429 8.12465 12.9616 8.14083 12.8857 8.17225C12.8098 8.20368 12.7409 8.24974 12.6828 8.30781L10.625 10.3664V3.125C10.625 2.95924 10.5592 2.80027 10.4419 2.68306C10.3247 2.56585 10.1658 2.5 10 2.5C9.83424 2.5 9.67527 2.56585 9.55806 2.68306C9.44085 2.80027 9.375 2.95924 9.375 3.125V10.3664L7.31719 8.30781C7.19991 8.19054 7.04085 8.12465 6.875 8.12465C6.70915 8.12465 6.55009 8.19054 6.43281 8.30781C6.31554 8.42509 6.24965 8.58415 6.24965 8.75C6.24965 8.91585 6.31554 9.07491 6.43281 9.19219L9.55781 12.3172Z" fill="#1B1C1E" />
                                </svg>
                            </a>
                          </div>
                      </div>
                  </div>
              </div>
            { (clientNotes !== null && clientNotes?.length > 0) ? (  
            <div className="home-layout-content">
                <div className="notes-container d-flex flex-column">
                    {clientNotes?.map((notes, i) => {
                        return(
                            <ClientNotesInfo
                                clientId={clientId}
                                item={notes}
                                deleteNote={deleteClientNotes}
                                key={i}
                            />
                        );
                    })
                    }
                </div>
            </div>
            ) : (
            <div className="inner-layout-content-new">
                { (clientNotes !== null ) ? (
                    <div className="no-data no-data-client-height-174 d-flex align-items-center justify-content-center flex-column text-center">
                        <h4>You don’t have any notes for this client</h4>
                        <p className="m-0">Use this section to add any relevant information about this client. </p>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            )}
        </div>
        <div className="inner-layout-footer-new">
            <div className="inner-btm-fixed-nav">
                <div className="btn-wrap">
                    <button type="button" className="btn btn-primary w-100" onClick={AddNoteShow}>Add Note</button>
                </div>
                <AddNoteoffcanvas
                    show={showAddNote}
                    onHide={AddNoteClose}
                    clientId={clientId}
                    loadClientNotes={reloadList}
                />
            </div>
        </div>
    </div>
  );
};

export default ClientNotesListing;
