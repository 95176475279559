/**
 * @file   src\components\AgentDashboard\WantAdListing.js
 * @brief  This file is responsible for listing want ads in Agent dashboard
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { formateDate, getDisplayTextForClientFilledWantAds, loggedInUser, setLocalStorage, truncate } from "../../helpers/common";
import { ADTYPE_BUY, ADTYPE_LTR, WANT_AD_KEY } from "../../constants/common";
import Table from "react-bootstrap/Table";
import ActionDropdown from "./ActionDropdown";
import TableHeader from "./TableHeader";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { getWantAdById } from "../../services/wantAdService";
import CustomLinkModal from "../Common/CustomLinkModal";

const WantAdListing = (props) => {
  const navigate = useNavigate();
  const {
    showNoResult,
    handleSort,
    itemList,
    onItemEdit,
    onItemDelete,
    sort,
    emptyItemHtml,
    fromInvestorTab,
    loading
  } = props;
  const agent = loggedInUser();


  const [showCustomLinkPopuop, setShowCustomLinkPopup] = useState(false);
  const handleCustomLinkPopupOpen = () => {
    setShowCustomLinkPopup(true);
  };
  const handleCustomLinkPopupClose = () => {
    setShowCustomLinkPopup(false);
  };

  const [linkToShare, setLinkToShare] = useState("")



  const columns = fromInvestorTab ? [
    { label: "Title ", sorting: "Yes", sortKey: "title" },
    // { accessor: 'email', label: 'Email', sorting:'Yes'  },
    { label: "Location", sorting: "Yes", sortKey: "location" },
    { label: "Status", sorting: "Yes", sortKey: "status" },
    { label: "Buy/rent", sorting: "Yes", sortKey: "buy_rent" },
    { label: "Intros received", sorting: "Yes", sortKey: "intros" },
    { label: "Date added", sorting: "Yes", sortKey: "date" },
  ] : [
    { label: "Title ", sorting: "Yes", sortKey: "title" },
    // { accessor: 'email', label: 'Email', sorting:'Yes'  },
    { label: "Client", sorting: "Yes", sortKey: "client" },
    { label: "Location", sorting: "Yes", sortKey: "location" },
    { label: "Status", sorting: "Yes", sortKey: "status" },
    { label: "Buy/rent", sorting: "Yes", sortKey: "buy_rent" },
    { label: "Intros received", sorting: "Yes", sortKey: "intros" },
    { label: "Date added", sorting: "Yes", sortKey: "date" },
  ];

  /**
  * To handle navigation to details page
  * @param {any} wantAdId 
  */
  const handleWantAdItemClick = (wantAdId) => {
    setLocalStorage(WANT_AD_KEY, { wantAdId: wantAdId, introId: 1 });
    navigate(pageURLs.wantAdDetails);
  };


  const onEdit = (wantAdId) => {
    getWantAdDetails(wantAdId, { isEdit: true });
  };

  /**
   * get want-ad details for edit
   * @param {*} wantAdId
   */
  const getWantAdDetails = async (wantAdId, formData) => {
    if (wantAdId) {
      await getWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (responseResult.isAgentCreated === 1) {
              navigate(pageURLs.agentCreatewantad, {
                state: { data: responseResult, ...formData },
              });
            } else {
              navigate(pageURLs.agentEditwantad, {
                state: { data: responseResult },
              });
            }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.createwantad);
    }
  };

  const showBuyerListingFormUrl = (client_Want_ad_Id) => {
    setLinkToShare(process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL + "/form/" + agent?.userInfo?.firstName + "-" + agent?.userInfo?.lastName + "-" + client_Want_ad_Id);
    handleCustomLinkPopupOpen();
  }

  return (
    <>
      <CustomLinkModal
        show={showCustomLinkPopuop}
        onHide={handleCustomLinkPopupClose}
        linkToShare={linkToShare}
      />
      <div className="dashboard-table-wrap">
        <div className="table-responsive">
          {showNoResult && itemList !== null && itemList?.length > 0 ? (
            <Table className="table">
              <TableHeader
                columns={columns}
                handleSort={handleSort}
                sort={sort}
              />

              <tbody>
                {itemList.map((wantAds, i) => {
                  const clientName = (_.get(wantAds, "FirstName", "") ? _.get(wantAds, "FirstName", "") : "") +
                    " " +
                    (_.get(wantAds, "LastName", "") ? _.get(wantAds, "LastName", "") : "");
                  const wantAdForUsername = _.get(wantAds, "Represent_as_Seller", "") == 1 ? "Agent" : clientName;
                  return (
                    <tr key={i}>
                      <td>
                        <a
                          href={void 0}
                          role="button"
                          className="txt-no-underline client-name-style"
                          onClick={() => {
                            if (_.get(wantAds, "IsFilledByClient", 0) == 1 && _.get(wantAds, "IsReadyToReview", 0) == 0) {

                            }
                            else {
                              _.get(wantAds, "IsPublished", 0) == 0
                                ? onEdit(_.get(wantAds, "Want_ad_Id", ""))
                                : handleWantAdItemClick(
                                  _.get(wantAds, "Want_ad_Id", "")
                                );
                            }
                          }}
                        >
                          {_.get(wantAds, "Title_Text", "") != "" ? (
                            <span className="d-inline-block client-name-style txt-overflow-style">
                              {truncate(_.get(wantAds, "Title_Text", ""), 30)}
                            </span>
                          ) : (
                            <span
                              style={{ fontStyle: "italic" }}
                              className="d-inline-block client-name-style txt-overflow-style"
                            >
                              Untitled
                            </span>
                          )}{" "}
                          {_.get(wantAds, "IsFilledByClient", 0) == 1 && _.get(wantAds, "IsPublished", 0) == 0 && (
                            <span style={{ fontStyle: "italic" }} className="d-inline-block client-name-style txt-overflow-style">
                              {getDisplayTextForClientFilledWantAds(_.get(wantAds, "IsFilledByClient", 0), _.get(wantAds, "IsReadyToReview", 0))}
                            </span>
                          )}{" "}
                        </a>
                      </td>
                      {!fromInvestorTab && <td>
                        {wantAdForUsername}
                      </td>}
                      <td>
                        {(_.get(wantAds, "zipCode", "")?.length > 0 || _.get(wantAds, "Location", "")) &&
                          <div className="d-flex align-items-center justify-content-start">
                            <div className="property-location-icon">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12.25 8C11.0074 8 10 9.00736 10 10.25C10 11.4926 11.0074 12.5 12.25 12.5C13.4926 12.5 14.5 11.4926 14.5 10.25C14.5 9.00736 13.4926 8 12.25 8ZM8.5 10.25C8.5 8.17893 10.1789 6.5 12.25 6.5C14.3211 6.5 16 8.17893 16 10.25C16 12.3211 14.3211 14 12.25 14C10.1789 14 8.5 12.3211 8.5 10.25Z"
                                  fill="#15181E"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12.25 3.5C10.4598 3.5 8.7429 4.21116 7.47703 5.47703C6.21116 6.7429 5.5 8.45979 5.5 10.25C5.5 13.355 7.23389 16.1822 9.06595 18.2891C9.97283 19.332 10.8816 20.1729 11.5639 20.7528C11.8337 20.9821 12.0672 21.1699 12.25 21.3125C12.4328 21.1699 12.6663 20.9821 12.9361 20.7528C13.6184 20.1729 14.5272 19.332 15.434 18.2891C17.2661 16.1822 19 13.355 19 10.25C19 8.45979 18.2888 6.7429 17.023 5.47703C15.7571 4.21116 14.0402 3.5 12.25 3.5ZM12.25 22.25C11.8199 22.8644 11.8197 22.8643 11.8195 22.8641L11.8188 22.8637L11.8171 22.8624L11.8115 22.8585L11.7925 22.845C11.7763 22.8334 11.7531 22.8167 11.7236 22.7952C11.6644 22.752 11.5794 22.689 11.4723 22.6071C11.2581 22.4434 10.9549 22.2038 10.5924 21.8957C9.86838 21.2803 8.90217 20.3867 7.93405 19.2734C6.01611 17.0678 4 13.895 4 10.25C4 8.06196 4.86919 5.96354 6.41637 4.41637C7.96354 2.86919 10.062 2 12.25 2C14.438 2 16.5365 2.86919 18.0836 4.41637C19.6308 5.96354 20.5 8.06196 20.5 10.25C20.5 13.895 18.4839 17.0678 16.566 19.2734C15.5978 20.3867 14.6316 21.2803 13.9076 21.8957C13.5451 22.2038 13.2419 22.4434 13.0277 22.6071C12.9206 22.689 12.8356 22.752 12.7764 22.7952C12.7469 22.8167 12.7237 22.8334 12.7075 22.845L12.6885 22.8585L12.6829 22.8624L12.6812 22.8637L12.6805 22.8641C12.6803 22.8643 12.6801 22.8644 12.25 22.25ZM12.25 22.25L12.6805 22.8641C12.4223 23.0449 12.0777 23.0449 11.8195 22.8641L12.25 22.25Z"
                                  fill="#15181E"
                                />
                              </svg>
                            </div>
                            <span className="d-inline-block client-property-style" title={_.get(wantAds, "zipCode", "")?.length > 0 ? "Within ZIP code " + _.get(wantAds, "zipCode", "").join(', ') : _.get(wantAds, "Location", "")}>
                              {_.get(wantAds, "zipCode", "")?.length > 0 ? truncate("Within ZIP code " + _.get(wantAds, "zipCode", "").join(', '), 22) : _.get(wantAds, "Location", "")}
                            </span>
                          </div>
                        }
                      </td>
                      {_.get(wantAds, "IsArchived", "") === 1 ?
                        <td>
                          <span className="table-badge-orange me-2">
                            PAUSED
                          </span> </td>
                        :
                        <td>
                          {_.get(wantAds, "IsPublished", false) ? (
                            <>
                              <span className="table-badge-bg">
                                <span className="d-inline-block align-text-center badge-tick-icon">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M20.0303 6.21967C20.3232 6.51256 20.3232 6.98744 20.0303 7.28033L9.53033 17.7803C9.23744 18.0732 8.76256 18.0732 8.46967 17.7803L3.21967 12.5303C2.92678 12.2374 2.92678 11.7626 3.21967 11.4697C3.51256 11.1768 3.98744 11.1768 4.28033 11.4697L9 16.1893L18.9697 6.21967C19.2626 5.92678 19.7374 5.92678 20.0303 6.21967Z"
                                      fill="#3BB273"
                                    />
                                  </svg>
                                </span>
                                Active
                              </span>
                            </>
                          ) : (
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="property-edit-icon">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.4697 2.46967C17.7626 2.17678 18.2374 2.17678 18.5303 2.46967L21.5303 5.46967C21.8232 5.76256 21.8232 6.23744 21.5303 6.53033L12.5303 15.5303C12.3897 15.671 12.1989 15.75 12 15.75H9C8.58579 15.75 8.25 15.4142 8.25 15V12C8.25 11.8011 8.32902 11.6103 8.46967 11.4697L17.4697 2.46967ZM9.75 12.3107V14.25H11.6893L19.9393 6L18 4.06066L9.75 12.3107Z"
                                    fill="#333A48"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.2197 4.71967C15.5126 4.42678 15.9874 4.42678 16.2803 4.71967L19.2803 7.71967C19.5732 8.01256 19.5732 8.48744 19.2803 8.78033C18.9874 9.07322 18.5126 9.07322 18.2197 8.78033L15.2197 5.78033C14.9268 5.48744 14.9268 5.01256 15.2197 4.71967Z"
                                    fill="#333A48"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.43934 3.43934C3.72064 3.15804 4.10217 3 4.5 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75C13.5 4.16421 13.1642 4.5 12.75 4.5L4.5 4.5L4.5 19.5H19.5V11.25C19.5 10.8358 19.8358 10.5 20.25 10.5C20.6642 10.5 21 10.8358 21 11.25V19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72065 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10217 3.15804 3.72064 3.43934 3.43934Z"
                                    fill="#333A48"
                                  />
                                </svg>
                              </div>
                              <span className="d-inline-block client-property-style">
                                Draft
                              </span>
                            </div>
                          )}
                        </td>
                      }

                      <td>
                        {" "}
                        {_.get(wantAds, "Buy_or_Rent", "") === ADTYPE_BUY
                          ? "Buy"
                          : _.get(wantAds, "Buy_or_Rent", "") === ADTYPE_LTR
                            ? "Long-term rent"
                            : "Short-term rent"}
                      </td>
                      <td>{_.get(wantAds, "IntroReceived", "")}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-start">
                          <span>
                            {formateDate(
                              _.get(wantAds, "CreatedOn", ""),
                              "shortMonth"
                            )}
                          </span>
                          <div className="social-btns-wrap no-bg-toggle ms-1">
                            <ActionDropdown
                              onItemEdit={onItemEdit}
                              disableEdit={_.get(wantAds, "IsFilledByClient", 0) == 1 && _.get(wantAds, "IsReadyToReview", 0) == 0}
                              onItemDelete={onItemDelete}
                              editText="Edit Want-Ad"
                              deleteText="Delete Want-Ad"
                              itemId={_.get(wantAds, "Want_ad_Id", "")}
                              buyerListLinkText="BuyerListing Form"
                              client_Want_ad_Id={(_.get(wantAds, "Client_Want_ad_Id", ""))}
                              showBuyerListingFormUrl={showBuyerListingFormUrl}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            !loading && showNoResult &&
            (itemList == null || itemList?.length == 0) &&
            emptyItemHtml()
          )}
        </div>
      </div>
    </>
  );
};
export default WantAdListing;
