import React, { useState, useEffect } from "react";
import { handleRadioButonChange } from "../../../actions/common";
import {
  handleKeyDownToPreventCharacters,
  handleKeyDownToPreventMinusSign,
  removeDecimalZeros,
  thousandSeperatedValue,
} from "../../../helpers/common";
import { removeNegativeInput } from "../../../helpers/validations";

const AgentCompensation = (props) => {
  const {
    handleFormValues,
    agentCompenation,
    compenationType,
    compensationError,
  } = props;

  const [compensationTypeList, setCompensationTypeList] = useState([]);
  let isUnCheckItem = false;
  const [selectedValue, setSelectedValue] = useState(compenationType);
  const [error, setError] = useState(null);

  useEffect(() => {
    let compensationTypeList = [
      {
        compensationTypeId: 1,
        compensationTypeText: "Seller to pay percentage",
      },
      {
        compensationTypeId: 2,
        compensationTypeText: "Seller to pay a flat fee",
      },
      { compensationTypeId: 4, compensationTypeText: "Negotiable" },
      {
        compensationTypeId: 3,
        compensationTypeText: "Not requesting compensation from seller",
      },
    ];

    setCompensationTypeList(compensationTypeList);
  }, []);

  useEffect(() => {
    setError(compensationError);
  }, [compensationError]);

  const handleItemClick = (value, compensationText) => {
    setError(null);
    if (value === compenationType) {
      handleFormValues({
        compenationType: "",
        compenationText: "",
        agentCompenation: "",
      });
      setSelectedValue("");
      isUnCheckItem = true;
    } else {
      handleFormValues({
        compenationType: value,
        compenationText: compensationText,
        agentCompenation: "",
      });
      setSelectedValue(value);
    }
  };

  const setCompensationValue = (e) => {
    setError(null);
    let value = removeNegativeInput(e.target.value);
    if (compenationType == 1 && value > 10) {
      handleFormValues({ agentCompenation: 10 });
    } else if (value == 0) {
      handleFormValues({ agentCompenation: "" });
    } else {
      handleFormValues({
        agentCompenation: value.toString().replace(/,/g, ""),
      });
    }
  };

  const loadCompensationTypes = () => {
    const compensationTypes =
      compensationTypeList &&
      compensationTypeList.map((tl) => {
        const compensationId = _.get(tl, "compensationTypeId");
        const compensationTypeId = _.get(tl, "compensationTypeText", "");

        return (
          <>
            <a
              href={void 0}
              className={
                selectedValue == compensationId
                  ? "createAd-content-wrap mb-4 active"
                  : "createAd-content-wrap mb-4"
              }
              onClick={(e) => {
                e.stopPropagation();
                handleItemClick(compensationId, compensationTypeId)
              }}
            >
              <span className="labelTxt">{compensationTypeId}</span>
            </a>            
            {(compenationType == 1 || compenationType == 2) &&
              selectedValue == compensationId &&
              loadTextBox()}
          </>
        );
      });

    return compensationTypes;
  };

  const loadTextBox = () => {
    return (
      <>
        {compenationType == 1 && (
          <div className="inner-select-type-wrap01">
            <div class="w-100 position-relative compensation-list">
              <label class="d-none"></label>
              <span className="percent-sign-icon">%</span>
              <label className="form-label ">What’s your percentage?*</label>
              <input
                className={
                  error
                    ? " pad-left-25 validation-txtfld form-control"
                    : "width170 form-control pad-left-25 "
                }
                limit="2"
                step="0.25"
                type="number"
                value={agentCompenation}
                pattern="[0-9.]*"
                inputmode="decimal"
                onChange={(e) => {
                  setCompensationValue(e);
                }}
                onKeyDown={(e) => {
                  handleKeyDownToPreventMinusSign(e);
                }}
                onBlur={() => {
                  handleFormValues({ focusOut: true });
                }}
              />
              <div class="error-msg d-none">{error}</div>
            </div>
          </div>
        )}
        {compenationType == 2 && (
          <div className="inner-select-type-wrap01">
            <div class="w-100 position-relative compensation-list">
              <label class="d-none"></label>
              <span className="percent-sign-icon">$</span>
              <label className="form-label ">What’s your flat fee? *</label>
              <input
                className={
                  error
                    ? "pad-left-25 validation-txtfld form-control"
                    : "width170 form-control pad-left-25"
                }
                type="text"
                value={
                  agentCompenation
                    ? thousandSeperatedValue(
                        removeDecimalZeros(agentCompenation)
                      )
                    : ""
                }
                pattern="[0-9.]*"
                inputmode="decimal"
                onChange={(e) => {
                  setCompensationValue(e);
                }}
                onKeyDown={(e) => {
                  handleKeyDownToPreventCharacters(e);
                }}
                onBlur={() => {
                  handleFormValues({ focusOut: true });
                }}
              />
              <div class="error-msg d-none">{error}</div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="wantad-timing-wrap pb-0">{loadCompensationTypes()}</div>
    </>
  );
};
export default AgentCompensation;
