import React from "react";


const LoadingSkeleton = (props) => {

  return (

    <>
      <div className="col-md-4">
        <div className="favorites-skeleton-wrapper">
          <div className="favorites-skeleton-right-wrap">
            <div className="skeleton-btn-small"></div>
            <div className="skeleton-btn-small"></div>
          </div>
          <div className="favorites-skeleton-img-wrap">

          </div>
          <div className="favorites-skeleton-info-wrap">
            <div class="favorites-skelton-info-top"></div>
            <div class="favorites-skelton-info-second"></div>
            <div class="favorites-skelton-info-third"></div>

            <div class="favorites-skelton-info-bottom"></div>

          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="favorites-skeleton-wrapper">
          <div className="favorites-skeleton-right-wrap">
            <div className="skeleton-btn-small"></div>
            <div className="skeleton-btn-small"></div>
          </div>
          <div className="favorites-skeleton-img-wrap">

          </div>
          <div className="favorites-skeleton-info-wrap">
            <div class="favorites-skelton-info-top"></div>
            <div class="favorites-skelton-info-second"></div>
            <div class="favorites-skelton-info-third"></div>

            <div class="favorites-skelton-info-bottom"></div>

          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="favorites-skeleton-wrapper">
          <div className="favorites-skeleton-right-wrap">
            <div className="skeleton-btn-small"></div>
            <div className="skeleton-btn-small"></div>
          </div>
          <div className="favorites-skeleton-img-wrap">

          </div>
          <div className="favorites-skeleton-info-wrap">
            <div class="favorites-skelton-info-top"></div>
            <div class="favorites-skelton-info-second"></div>
            <div class="favorites-skelton-info-third"></div>

            <div class="favorites-skelton-info-bottom"></div>

          </div>
        </div>
      </div>

      
    </>
  );
};

export default LoadingSkeleton;
