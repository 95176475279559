import React, { useState, useEffect } from "react";
import LakeViewIcon from "../../../images/lake-view-icon.svg";
import ConsumerIcon from "../../../images/consumer-icon.svg";
import { getNiceToHaves } from "../../../services/commonServices";
import { convertToNumber } from "../../../helpers/validations";
import {
  INVENTORY_LAND,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  IS_LAND,
} from "../../../constants/common";
import {
  buildInventoryMustHaveHtml,
  buildSchoolNeigborhoodHtml,
  checkInventoryType,
  loadInventoryLocation
} from "../../../helpers/inventory";
import {
  loadPhotos,
} from "../../../helpers/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";
import PhotoGallery from "../../Common/PhotoGallery";

const InventoryConfirmation = (props) => {
  const navigate = useNavigate();
  const { formValues, handleContinueButtonState } = props;
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const proprtyPurpose = _.get(formValues, "proprtyPurpose", []);
  const description = _.get(formValues, "invtryDetails", "");
  const salesPercnt = _.get(formValues, "salesPercnt", "");
  const salesPercntLTR = _.get(formValues, "salesPercnt_LTR", "");
  const salesPercntSTR = _.get(formValues, "salesPercnt_STR", "");
  const city = _.get(formValues, "city", "");
  const state = _.get(formValues, "state", "");
  const zip = _.get(formValues, "zip", "");
  const mapImg = _.get(formValues, "mapImg", "")  + "?" + Math.random().toString(36);
  const nice2Have = _.get(formValues, "nice2Have", []);
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const invType = checkInventoryType(proprtyPurpose);
  const typeBasedOnProperty =
    propertyType === IS_LAND ? INVENTORY_LAND : invType;
  let titleText = "";
  switch (invType) {
    case INVENTORY_SELL:
      titleText = " selling";
      break;
    case INVENTORY_LTR:
      titleText = " renting";
      break;
    case INVENTORY_STR:
      titleText = " renting";
      break;
    case INVENTORY_LTR_STR:
      titleText = " renting";
      break;
    case INVENTORY_SELL_LTR:
      titleText = " selling, renting";
      break;
    case INVENTORY_SELL_STR:
      titleText = " selling, renting";
      break;
    default:
      titleText = " selling, renting";
      break;
  }


  useEffect(() => {
    getNiceToHaves(typeBasedOnProperty)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    const isDisable = false;
    handleContinueButtonState(isDisable);
  }, []);

  const loadDetails = () => {
    return buildInventoryMustHaveHtml({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: formValues,
    });
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    const masterArray = _.get(niceToHavesList, section, []);
    if (selectedItems.length) {
      return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
    } else {
      return "";
    }
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      typeBasedOnProperty === INVENTORY_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    return finalList;
  };

  const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        const icon = _.get(masterItem[0], "Icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueKey}>
            <div className="nicetohaves-tag-icon">
              <img
                src={icon ? require("../../../images/" + icon) : LakeViewIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const isNiceToHaveExist = nice2Have.length > 0;
  const photos = _.get(formValues, "photos", []);
  const highSchool = _.get(formValues, "highSchool", null);
  const neighborHood = _.get(formValues, "neighborHood", null);
  return (
    <>
      <div className="center-content-sec mw-100">
        <div className="inventory-confirmation-wrap">
          <div className="confirmation-top-sec d-md-flex justify-content-between">
            <div>
              {/*<div className="want-ad-tag align-top grey-tag-bg mb-2 f-size-10">
                Open to {titleText}
  </div>*/}
              <div className="confirm-sub-title mb-1">
                I'm open to:{" "}
                <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                  {titleText}
                </span>
              </div>
              <h2>{_.get(formValues, "locAddress", "")}</h2>
              <h6>{loadInventoryLocation({city: city, state: state, zip: zip})}</h6>
            </div>
            <button
              type="button"
              className="btn btn-outline-primary align-self-start mb-0 mt-2 mt-sm-2 mt-md-0"
              onClick={() =>
                navigate(pageURLs.memberEditInventory, {
                  state: { isCreated: true },
                })
              }
            >
              Edit
            </button>
          </div>
          <PhotoGallery photos={photos}/>
          {/* {loadPhotos(photos)} */}

          <div className="musthave-wrap">
            <h2>Details</h2>
            {loadDetails()}
          </div>

          {/* <div className="Inventory-Location-wrap pb-32">
            <h2>Location</h2>
            <div className="Inventory-Location-map-wrap mb-3 h-264">
              <img
                src={mapImg || InventoryLocationMap}
                alt={mapImg || InventoryLocationMap}
              />
            </div>
            <ul>
              {highSchool && (
                <li>
                  <span className="Inventory-icn-wrap">
                    <img src={SchoolSmallIcon} />
                  </span>
                  {highSchool}
                </li>
              )}
              {neighborHood && (
                <li>
                  <span className="Inventory-icn-wrap">
                    <img src={NeighborhoodIcon} />
                  </span>
                  {neighborHood}
                </li>
              )}
            </ul>
          </div> */}
          {buildSchoolNeigborhoodHtml({mapImg: mapImg, highSchool: highSchool, neighborHood: neighborHood })}

          {isNiceToHaveExist ? (
            <div className="nicetohaves-wrap">
              <h2>Features</h2>
              <div className="nicetohaves-inner-wrap">
                {finalNiceToHaveList()}
              </div>
            </div>
          ) : (
            ""
          )}

          {description && (
            <div className="additional-details-wrap">
              <h2>Description</h2>
              <p>{description}</p>
            </div>
          )}

          <div className="buyer-info">
            <h2>Additional Information</h2>
            <ul>
              <li>
                <span className="list-icn-wrap">
                  <img src={ConsumerIcon} />
                </span>
                Property Owner
              </li>
              {salesPercnt && (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ConsumerIcon} />
                  </span>
                  Seller is offering {salesPercnt}% to buyer's agent
                </li>
              )}
              {salesPercntLTR && (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ConsumerIcon} />
                  </span>
                  Long-term Renter is offering {salesPercntLTR}% to renter's
                  agent
                </li>
              )}
              {salesPercntSTR && (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ConsumerIcon} />
                  </span>
                  Short-term Renter is offering {salesPercntSTR}% to renter's
                  agent
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryConfirmation;
