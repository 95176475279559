/**
 * @file   src\components\Messages\SideBarChat.js
 * @brief  This file is responsible for handling messaging/chat property listing in leftside section screen.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react'
import { getInitials, truncate } from '../../helpers/common';
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import ModalMoreIcn from "../../images/modal-more-btn.svg";
import MarkFitModal from '../Introduction/MarkFitModal';
import { updateIntroduction } from '../../helpers/message';
import { updateIntroNotFit } from '../../services/introductionService';
import ReportUserModal from '../MemberWantAd/ReportUserModal';
import UserIcon from "../../images/intro-prof-placeholder.svg";

function SideBarChat(props) {
  const msgData = props.msgData;
  const selectedIntroductionId = props.selectedIntroductionId;
  const index = props.index;
  const userId = props.userId;

  const [userData, setUserData] = useState('');

  const [showMarkFitModal, setMarkFitModal] = useState(false);
  const markFitModalClose = () => setMarkFitModal(false);
  const markFitModalShow = () => setMarkFitModal(true);
  const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);
  const markFitReasonModalClose = () => setNotFitReasonPopup(false);

  const [showReportUserModal, setReportUserModal] = useState(false);
  const reportUserModalClose = () => setReportUserModal(false);
  const reportUserModalShow = () => setReportUserModal(true);


  useEffect(() => {
    let data = props?.introIdFromURL ? (props?.introIdFromURL == msgData?.introductionId ? msgData : '') :
    selectedIntroductionId != 0 ?
      (selectedIntroductionId == msgData?.introductionId ? msgData : '') :
      (index == 0 ? msgData : '');
    props.handleWantAdSelection(data)
  }, [selectedIntroductionId]);

  const calculateTimeAgo = () => {
    if (typeof msgData?.createdat == 'string') {
      let timeAgo = moment(new Date(msgData?.createdat).toUTCString()).fromNow(true);
      return timeAgo;
    } else {
      let timeAgo = moment(new Date(msgData?.createdat?.toDate()).toUTCString()).fromNow(true);
      return timeAgo;
    }
  }

  const [timeAgo, setTimeAgo] = useState(calculateTimeAgo());

  useEffect(() => {
    const interval = setInterval(() => setTimeAgo(calculateTimeAgo()), 1000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (msgData.introductionId) {
      if (msgData?.wantAdCreatedId == userId) {
        let nameSplit = msgData?.invetroyUserName.split(" ", 1)
        let { initials, backgroundColor } =
          getInitials(msgData?.invetroyUserName);
        setUserData({
          initials: initials,
          backgroundColor: backgroundColor,
          name: nameSplit[0],
          userId: msgData?.wantAdCreatedId,
          photo: msgData?.inventoryCreatedUserImage
        })
      } else if (msgData?.inventroyCreatedId == userId) {
        let { initials, backgroundColor } =
          getInitials(msgData?.wantAdCreatedUserFirstName + ' ' + msgData?.wantAdCreatedUserLastName);
        setUserData({
          initials: initials,
          backgroundColor: backgroundColor,
          name: msgData?.wantAdCreatedUserFirstName,
          userId: msgData?.inventroyCreatedId,
          photo: msgData?.wantAdCreatedUserImage
        })
      }
    }
  }, [msgData])

  const handleNotFitConfirm = () => {
    markFitModalClose()
    setNotFitReasonPopup(true)
  }

  const updateToNotFit = async (reqPayload, isSkip) => {
    if (reqPayload) {
      await updateIntroNotFit(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isdeleted: true,
            }
            let updateresponse = await updateIntroduction(reqPayload?.IntroductionId, data);
            setNotFitReasonPopup(false)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  return (
    <>
      <div className={
        (selectedIntroductionId == msgData?.introductionId) ?
          "msg-left-wrap msg-selected position-relative " :
          "msg-left-wrap position-relative "}
        onClick={() => { props.handleWantAdSelection(msgData) }}>

        {
          (msgData?.isrepiled == false && msgData?.wantAdCreatedId != userId)
            ?
            (
              <div className="intro-prof-wrap profile-bg-paleblue flex-shrink-0">
                <img className="prof-init-icon" src={UserIcon} />
              </div>

            )
            : (

              userData?.photo ?
                (
                  <div className={"msg-prof-wrap  flex-shrink-0 " + userData?.backgroundColor}>
                    <img src={userData?.photo} />
                  </div>
                ) : (
                  <div className={"msg-prof-wrap  flex-shrink-0 " + userData?.backgroundColor}>{userData?.initials}</div>
                )
            )
        }

        <div className="msg-details flex-grow-1">
          <div className="msg-main-heading">{(msgData?.isrepiled == false && msgData?.wantAdCreatedId != userId) ? "Waiting for a response" : userData?.name}
            {
              msgData?.badgeType && (msgData?.isrepiled == true) && <span class="pro-blue-badge d-inline-block align-middle ms-0">PRO</span>
            }
            <span> &bull; </span>
            {
              msgData?.inventroyCreatedId == userId ? msgData?.wantAdText && truncate(msgData?.wantAdText, 25) :
                msgData?.inventoryLocation && truncate(msgData?.inventoryLocation, 25)
            }
          </div>
          <div className="msg-main-text">
            {userId == msgData?.lastestMessageSentUserId ? "You: " : userData?.name + ": "}
            {msgData?.latestMessage && truncate((msgData?.latestMessage), 20)}
            <span> &bull; </span>
            {calculateTimeAgo() ?? timeAgo}
          </div>
        </div>
        {
          msgData?.wantAdCreatedId == userId &&
          <div className="message-dpdown-wrap">
            <Dropdown className="">
              <Dropdown.Toggle variant="" id="dropdown-basic133">
                <img src={ModalMoreIcn} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item href={void (0)} onClick={markFitModalShow}>Mark not interested</Dropdown.Item>
                <Dropdown.Item href={void (0)} onClick={reportUserModalShow}>Report user</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      </div>

      <MarkFitModal
        show={showMarkFitModal}
        onHide={markFitModalClose}
        titleText="Are you sure you want to mark this introduction as not fit?"
        description="This action will end the introduction and associated conversation. Once you delete this it cannot be undone."
        buttonTitle="Confirm & Delete"
        updateToNotFit={updateToNotFit}
        introductionId={msgData?.introductionId}
        showNotFirReasnPopup={showNotFirReasnPopup}
        handleNotFitConfirm={handleNotFitConfirm}
        markFitReasonModalClose={markFitReasonModalClose}
        name={userData?.name}
      />

      <ReportUserModal
        show={showReportUserModal}
        onHide={reportUserModalClose}
        IntroductionId={msgData?.introductionId}
        inventoryDetails={''}
        isFromMessage={true}
        reportedUserId={userData.userId}
      />

    </>
  )
}
export default SideBarChat