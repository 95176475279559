import React, { useState, useEffect } from "react";
import "../../styles/clients.scss";
import { getCardIconForIntroduction, getLocalStorage, getUserId, getUserType, isDateInThePast, removeLocalStorage, setLocalStorage, truncate } from "../../../helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { AGENT_INTRODUCTION, INTRODUCTION_AMOUNT, INTRODUCTION_TAX, MEMBER_INTRODUCTION, USER_TYPE_AGENT } from "../../../constants/common";
import noImage from "../../../images/no-image-placeholder.png";
import { getActivePromoCodeDetails } from "../../../services/PlanService";
import { fetchPaymentMethods } from "../../../actions/payments";
import { fetchUserDetails, fetchUserPrivileges, fetchUserStatistics } from "../../../actions/userprofile";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";
import { loadInventoryLocation } from "../../../helpers/inventory";
import { saveIntroductions } from "../../../services/introductionService";
import { convertToNumber } from "../../../helpers/validations";
import PreviewIntroductionOffcanvas from "./PreviewIntroductionOffcanvas";
import PremiumSparkleIcon from "../../../images/premium-sparkle-icon.svg";
import ProSparkleIcon from "../../../images/pro-sparkle-icon.svg";
import IntroductionSuccessOffcanvas from "./IntroductionSuccessOffcanvas";
import { toast } from "react-toastify";
import Loader from "../../../components/Common/Loader";
import { ADD_PAYMENT, CUSTOMIZE_INTRO, PAYMENT_LIST } from "../../../constants/onBoarding";


const ReviewOrderData = (props) => {
    useEffect(() => {
        document.body.classList.add('bg-offcanvas-fix');
    }, []);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deafaultPaymentMethod, setDeafaultPaymentMethod] = useState(null);
    const userId = getUserId();
    const userType = getUserType();
    let session = userType == 2 ? AGENT_INTRODUCTION : MEMBER_INTRODUCTION;
    // const selectedProperty = props.selectedProperty;
    const [showPreviewIntroduction, setShowPreviewIntroduction] = useState(false);
    const PreviewIntroductionClose = () => setShowPreviewIntroduction(false);
    const PreviewIntroductionShow = () => setShowPreviewIntroduction(true);
    const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);
    const [reload, setReload] = useState(0);
    const userDetailsFromStore = useSelector(
        (state) => state.userProfile.userDetails
    );
    const userStatictics = useSelector((state) => state?.userProfile?.userStatictics)
    const userPrivileges = useSelector((state) => state?.userProfile?.userPrivileges)
    const [userCount, setUserCount] = useState({})
    const [allowedCount, setAllowedCount] = useState(null)
    const [userDetails, setUserDetails] = useState(null);
    const [remainingIntroduction, setRemainingIntroduction] = useState(allowedCount)
    const [isFree, setIsFree] = useState(false);

    const [freeIntroApplicable, setIsFreeIntroApplicable] = useState(false);
    let selectedProperty = getLocalStorage(session);
    //form States
    const [formData, setFormData] = useState('');
    const [showSuccessModal, setSuccessModal] = useState(false);
    const SuccessModalClose = () => setSuccessModal(false);
    const SuccessModalShow = () => setSuccessModal(true);
    const [isLoding, setIsLoading] = useState(false);


    useEffect(() => {
        setDeafaultPaymentMethod(props.deafaultPaymentMethod)
    }, [props.deafaultPaymentMethod])

    useEffect(() => {
        getActivePromoCodeDetails().then((response) => {
            let temp = [];
            const res = _.get(response, "result", [])
            if (res?.length > 0) {
                const result = res.filter((planDetails) => planDetails?.UserType == 2 || planDetails?.UserType == 3)
                if (result && result.length > 0) {
                    result.forEach((planDetails) => {
                        if (planDetails?.Is_Free_Intro === 1) {
                            temp.push(parseInt(planDetails?.Id))
                        }
                    })
                    if (temp.includes(userDetails?.PromoCode_Id)) {
                        setIsFreeIntroApplicable(true)
                    }
                    else {
                        setIsFreeIntroApplicable(false)
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        checkButtonDisabledStatus();

    }, [selectedProperty]);

    useEffect(() => {
        setRemainingIntroduction(parseInt(allowedCount) - parseInt(userCount?.Introduction))
    }, [allowedCount, userCount])

    useEffect(() => {
        if (remainingIntroduction > 0) {
            setIsFree(true)
        }
        else {
            setIsFree(false)
        }
    }, [remainingIntroduction])


    useEffect(() => {
        const payload = {
            PlanId: userDetails?.PlanId,
        };
        dispatch(fetchUserPrivileges(payload));
    }, [userDetails?.PlanId]);

    useEffect(() => {
        dispatch(fetchPaymentMethods())
        if (userId) {
            dispatch(fetchUserDetails({
                "userId": userId,
            }))
        }
    }, [userId, reload, selectedProperty?.clientId]);

    useEffect(() => {
        if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
            setUserDetails(userDetailsFromStore[0]);
        } else {
            setUserDetails(null);
        }
    }, [userDetailsFromStore]);

    useEffect(() => {
        const payload = {
            UserPlanId: userDetails?.User_Plan_Id
        }

        dispatch(fetchUserStatistics(payload))
    }, [userDetails])


    useEffect(() => {
        if (userPrivileges !== null && userPrivileges.length > 0) {
            if (freeIntroApplicable) {
                setAllowedCount(parseInt(userPrivileges[0]?.Introduction) + parseInt(userCount?.WandAd));
            }
            else {

                setAllowedCount(userPrivileges[0]?.Introduction);
            }
        } else {
            setAllowedCount(null);
        }
    }, [userPrivileges, userCount, freeIntroApplicable]);


    useEffect(() => {
        if (userStatictics !== null && userStatictics.length > 0) {
            setUserCount(userStatictics[0]);
        }
    }, [userStatictics]);

    const SendIntroduction = async () => {
        setIsLoading(true);
        let selectedProperty = getLocalStorage(session);
        let data = {
            wantAdId: selectedProperty?.wantAdId,
            wantAd_clientId: selectedProperty?.wantAdDetails?.clientId,
            wantAd_UserId: selectedProperty?.wantAdDetails?.userId,
            inventoryId: selectedProperty?.inventoryId,
            clientId: selectedProperty?.clientId, // for agent
            askingPrice: selectedProperty?.askingPrice ? selectedProperty?.askingPrice : selectedProperty?.defaultAskingPrice,
            message: selectedProperty?.message,
            cardId: selectedProperty?.cardId,
            amount: INTRODUCTION_AMOUNT,
            tax: INTRODUCTION_TAX,
            total: convertToNumber(INTRODUCTION_AMOUNT) + convertToNumber(INTRODUCTION_TAX),
            coverPhotos: selectedProperty?.coverPhotos ? selectedProperty?.coverPhotos : selectedProperty?.originalPhotos,
            propertyFeatures: selectedProperty?.propertyFeatures ? selectedProperty?.propertyFeatures : selectedProperty?.originalpropertyFeatures,
            s3ImageFolder: selectedProperty?.s3ImageFolder,

            // data for firebase
            WantAdText: selectedProperty?.wantAdDetails?.adTitle,
            wantAdLocation: selectedProperty?.wantAdDetails?.location,
            wantAdClientName: selectedProperty?.wantAdDetails?.clientName ? selectedProperty?.wantAdDetails?.clientName : '',
            wantAdCreatedUserFirstName: selectedProperty?.wantAdDetails?.userFirstName,
            wantAdCreatedUserLastName: selectedProperty?.wantAdDetails?.userLastName,
            wantAdCreatedId: selectedProperty?.wantAdDetails?.userId,
            inventroyCreatedId: _.get(userDetails, "UserId", null),
            inventroyUserName: _.get(userDetails, "FirstName", null) + " " + _.get(userDetails, "LastName", null),
            lastestMessageSentUserId: _.get(userDetails, "UserId", null),
            wantAdPrprtyType: selectedProperty?.wantAdDetails?.prprtyType,
            isRepiled: false,
            isDeleted: false,
            isAccepted: false,
            acceptedTime: '',
            inventoryPhoto: selectedProperty?.selectedProperty.photo,
            inventoryLocation: selectedProperty?.locAddress,
            inventoryTypeId: selectedProperty?.propertyTypeId,
            isFree: isFree ? 1 : 0,
            isAdhereFFHAct: selectedProperty?.isAdhereFFHAct == true ? 1 : 0,
            isRepresentOwner: selectedProperty?.isRepresentOwner == true ? 1 : 0,

        }
        await saveIntroductions(data)
            .then((response) => {
                setIsLoading(false);
                if (response.errorCode == 0) {
                    if (response.result.status == "created") {
                        setSuccessModal(true)
                        removeLocalStorage(session)

                    } else {
                        toast.error(response.message)
                    }
                } else {
                    console.log("Something went wrong");
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });


    }

    useEffect(() => {
        setLocalStorage(session, { ...selectedProperty, ...formData });
    }, [formData])

    const handleCheckBoxItemClick = (e) => {
        let sessionData = getLocalStorage(session);
        setFormData({ ...sessionData, isAdhereFFHAct: e.target.checked });

    };

    const handleIsRepresentOwnerCheckBoxItemClick = (e) => {
        let sessionData = getLocalStorage(session);
        setFormData({...sessionData, isRepresentOwner: e.target.checked });

    };

    const checkButtonDisabledStatus = () => {
       

        let expired = isDateInThePast(deafaultPaymentMethod?.Exp_Month, deafaultPaymentMethod?.Exp_Year);
        let isRepresentOwnerCheck= userType == USER_TYPE_AGENT ? formData?.isRepresentOwner == true ? true : false : true;

        if (deafaultPaymentMethod?.CardId && isRepresentOwnerCheck == true && formData?.isAdhereFFHAct == true && !expired) {
            setButtonDisabledStatus(false);
        } else {
            setButtonDisabledStatus(true);

        }
    }

    const loadPlanUpgradeUI = () => {

        if (userType == 2 && userDetails?.period == 1) {
            return (
                <div className="upgrade-plan-wrap upgrade-plan-bg-grey mb-4" onClick={() => navigate(pageURLs.updatePlan, {
                    state: { isFromSettings: true },
                })}>
                    <h2 className="text-center">
                        <span className="f-extrabold">Gitcha</span> <span className="f-medium">Pro Member</span> <img src={PremiumSparkleIcon} />
                    </h2>
                    <p className="para-color-blue">Save up to 50% on introductions - Learn more about upgrading your Pro account</p>
                </div>
            )
        }
        else if (userType == 1 && userDetails?.period == 1) {

            return (
                <div className="upgrade-plan-wrap upgrade-plan-bg-grey mb-4" onClick={() => navigate(pageURLs.updatePlan, {
                    state: { isFromSettings: true },
                })}>
                    <h2 className="text-center">
                        <span className="f-extrabold">Gitcha</span> <span className="f-medium">Premium</span> <img src={PremiumSparkleIcon} />
                    </h2>
                    <p className="para-color-blue pl-20 pr-20">Upgrade and get 10 free introductions per month</p>
                </div>
            )
        }
        else if (userType == 2 && (userDetails?.PlanName == "Pro Member")) {
            return (
                <div className="upgrade-plan-wrap upgrade-plan-bg-blue mb-4" onClick={() => navigate(pageURLs.updatePlan, {
                    state: { isFromSettings: true },
                })}>
                    <h2 className="text-center">
                        <span className="f-extrabold para-color-white">Gitcha</span> <span className="f-medium para-color-white">Pro Preferred</span> <img src={ProSparkleIcon} />
                    </h2>
                    <p className="para-color-white">Get 50 free introductions per month when you upgrade to Pro Preferred</p>
                </div>
            )
        }

    }

    return (
        <>
            {isLoding ? <Loader /> : ""}
            <div className="gitcha-main-wrapper view-client-wrap intro-checkout-wrap">

                <div className="content-wrapper content-wrapper-h-102-percent">
                    <div className="inner-layout-header-new bg-white">
                        <div className="review-plan-header header-brdr-btm w-100">
                            <div className="signup-login-header-top signup-header-txt clearfix">
                                <a className="back-link-small" href={void (0)}
                                    onClick={() => { props.handleReviewOrderBackButtonClick() }}

                                ><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                                    </svg></a>
                                <span>Review Order</span>
                            </div>
                        </div>
                    </div>
                    <div className="inner-layout-content-new">
                        <div className="payment-method-wrap intro-summary-sec">
                            <div className="title-group d-flex align-items-center">
                                <h4>Introduction Summary</h4>
                                <button type="button" className="btn btn-link" onClick={() => {
                                    props.setUIKey(CUSTOMIZE_INTRO);
                                }}>Customize Intro</button>
                            </div>
                            <div className="summary-block">
                                <div className="add-your-property-mob">
                                    <div className="add-property-img-mob">
                                        <img src={selectedProperty?.selectedProperty?.photo ? selectedProperty?.selectedProperty?.photo : noImage} />
                                    </div>
                                    <div className="add-property-details-mob ">
                                        <div className="title d-flex align-items-center">
                                            <h3>{selectedProperty?.selectedProperty?.Location}</h3>
                                        </div>
                                        <p>{loadInventoryLocation({
                                            city: selectedProperty?.city,
                                            state: selectedProperty?.state,
                                            zip: selectedProperty?.zip,
                                        })}</p>
                                    </div>
                                </div>
                                {selectedProperty?.message &&
                                    <div className="info"><p>
                                        {truncate(selectedProperty?.message, 60)}
                                    </p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="payment-method-wrap">
                            {deafaultPaymentMethod ? (
                                <>

                                    <h4 className="mb-2">Payment Method</h4>
                                    <p className="mb-2">You will only be charged if they accept your introduction</p>
                                    <a className="txt-no-underline link-cursor d-block"
                                        href={void 0}
                                        onClick={() => { props.setUIKey(PAYMENT_LIST) }}>
                                        <div className="add-your-property-wrap mh-auto mb-0 brdr-rds-10 add-property-bg brdr-add-property">
                                            <div className="add-property-btn">
                                                <img src={getCardIconForIntroduction(deafaultPaymentMethod?.Brand)} />
                                            </div>
                                            <div className="make-intro-details pl-14">
                                                <h4 className="mb-2">{deafaultPaymentMethod?.Brand} •••••••••••••{deafaultPaymentMethod?.Last4} </h4>
                                                {isDateInThePast(deafaultPaymentMethod?.Exp_Month, deafaultPaymentMethod?.Exp_Year) ? (
                                                    <p className="card-expired-txt">Expired {String(deafaultPaymentMethod?.Exp_Month).padStart(2, '0')}/{String(deafaultPaymentMethod?.Exp_Year).slice(-2)}</p>
                                                ) :
                                                    (<p>Exp. {String(deafaultPaymentMethod?.Exp_Month).padStart(2, '0')}/{String(deafaultPaymentMethod?.Exp_Year).slice(-2)}</p>)}
                                            </div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z" fill="#000022"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <h4 className="mb-2">Paying with</h4>
                                    <p className="mb-2">You will only be charged if they accept your introduction.</p>
                                    <a className="txt-no-underline link-cursor d-block"
                                        href={void 0}
                                        onClick={() => { props.setUIKey(ADD_PAYMENT); }}
                                    >
                                        <div className="add-your-property-wrap mh-auto mb-0 brdr-rds-10 add-property-bg brdr-add-property">
                                            <div className="add-property-btn">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#15181E" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4142 16.1642 12.75 15.75 12.75H8.25C7.83579 12.75 7.5 12.4142 7.5 12Z" fill="#15181E" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.5C12.4142 7.5 12.75 7.83579 12.75 8.25V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V8.25C11.25 7.83579 11.5858 7.5 12 7.5Z" fill="#15181E" />
                                                </svg>
                                            </div>
                                            <div className="make-intro-details pl-14">
                                                <h4 className="mb-0">Add payment method</h4>
                                            </div>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z" fill="#000022"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </>
                            )}
                        </div>

                        <div className="payment-method-wrap">
                            <h4 className="mb-3">Total</h4>
                            <div className="premium-plan-wrap mb-0 order-summary-update brdr-rds-10">
                                <ul>
                                    <li>
                                        <div className="premium-plan-description"><span className="d-block">Introduction x 1</span>
                                            {userDetails?.period != 1 && <span className="premium-plan-info">{remainingIntroduction && remainingIntroduction > 0 ? remainingIntroduction : 0}/{allowedCount} free introductions remaining</span>}</div>

                                        <span className="premium-plan-amount">{remainingIntroduction && remainingIntroduction > 0 ? "Free" : "$3.00"}</span>
                                    </li>
                                    <li>
                                        <span className="premium-plan-description">Tax</span>
                                        <span className="premium-plan-amount">-</span>
                                    </li>
                                    <li>
                                        <span className="premium-plan-description">Total</span>
                                        <span className="premium-plan-amount">{remainingIntroduction && remainingIntroduction > 0 ? "$0.00" : "$3.00"}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-3">
                                {loadPlanUpgradeUI()}
                            </div>
                            {
                                userType == USER_TYPE_AGENT &&
                                <div className="field-wrap mt-0 mb-24">
                                    <label className="form-check-label checkbox-label d-flex align-items-center" for="isRepresentOwner" >
                                        <input className="form-check-input flex-shrink-0" type="checkbox"
                                            onClick={(e) => handleIsRepresentOwnerCheckBoxItemClick(e)}
                                            id="isRepresentOwner"
                                            checked={selectedProperty?.isRepresentOwner} />
                                        <span>I’m in contract to represent the owner of this property</span>
                                    </label>
                                </div>
                            }


                            <div className="field-wrap mt-0 mb-24">
                                <label className="form-check-label checkbox-label d-flex align-items-center" for="isAdhereFFHAct" >
                                    <input className="form-check-input flex-shrink-0" type="checkbox"
                                        onClick={(e) => handleCheckBoxItemClick(e)}
                                        id="isAdhereFFHAct"
                                        checked={selectedProperty?.isAdhereFFHAct} />
                                    <span>I agree to adhere to the <a href="https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_rights_and_obligations" target="blank">Federal Fair Housing Act</a> and the <a href="https://gitcha-uploads.s3.amazonaws.com/Gitcha_images/pdf_mls_handbook-2023-08-11.pdf" target="blank">Clear Cooperation Policy</a></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inner-layout-footer-new">
                    <div className="inner-btm-fixed-nav">
                        <div className="btn-wrap">
                            <button type="button" className="btn btn-primary w-100" onClick={SendIntroduction} disabled={buttonDisabledStatus}>Send Introduction {remainingIntroduction > 0 ? "" : "- $3"}</button>
                            <button type="button" className="btn btn-link w-100" onClick={PreviewIntroductionShow}>Preview Introduction</button>
                        </div>
                        <PreviewIntroductionOffcanvas
                            show={showPreviewIntroduction}
                            onHide={PreviewIntroductionClose}
                            selectedProperty={selectedProperty}
                        />

                    </div>
                </div>
            </div>

            <IntroductionSuccessOffcanvas
                show={showSuccessModal}
                onHide={SuccessModalClose}
            />
        </>
    );
};

export default ReviewOrderData;
