import React, { useState, useEffect } from "react";
import { getInventoryDocuments } from "../../../services/inventoryService";
import CheckBox from "../../Common/CheckBox";
import { convertToNumber } from "../../../helpers/validations";
import { INVENTORY_LTR, INVENTORY_LTR_STR } from "../../../constants/common";

const InventoryDocuments = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    apprOnFile,
    insReport,
    sellerFin,
    floorPlan,
    floodCert,
    assMortgage,
    isEdit,
    invType
  } = props;
  const [propertyDocuments, setDocumentList] = useState([]);
 
  useEffect(() => {
    if(!isEdit){
      if (apprOnFile || insReport || sellerFin || floorPlan || floodCert || assMortgage) {
        handleContinueButtonState();
      } else {
        const isDisabled = true;
        handleContinueButtonState(isDisabled);
      }
    }
  
  }, [apprOnFile, insReport, sellerFin, floorPlan, floodCert, assMortgage]);

  useEffect(() => {
    getInventoryDocuments()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const documentList = _.get(response, "result", null);
          setDocumentList(documentList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ apprOnFile: e.target.checked });
        break;
      case 2:
        handleFormValues({ insReport: e.target.checked });
        break;
      case 3:
        handleFormValues({ sellerFin: e.target.checked });
        break;
      case 4:
        handleFormValues({ floorPlan: e.target.checked });
        break;
      case 5:
        handleFormValues({ floodCert: e.target.checked });
        break;
        case 6:
          handleFormValues({ assMortgage: e.target.checked });
          break;
        
    }
  };
  var floorPlanItem = propertyDocuments.filter(function (list) {
    if (list.OptionId === 4) {
        return list;
    }
})
  const floorPlanOptionId = _.get(floorPlanItem?.[0], 'OptionId', '4')
  const floorPlanOptionText = _.get(floorPlanItem?.[0], 'Option_Text', 'Floor plan on file')
  const loadDocuments = () => {
    const isShowFloodFileOnly = invType === INVENTORY_LTR || invType === INVENTORY_LTR_STR //For showing only one option ie, 'floor plan on file' for Long-term renting strengthen.
    

    if(invType){
      if(isShowFloodFileOnly){
        return (
          <label className="check-wrap mb-3">
            <CheckBox
              class="form-check-input"
              name="pd-checkbox"
              checked={floorPlan}
              value= {floorPlanOptionId}
              id={floorPlanOptionId}
              onChange={(e) => handleCheckBoxItemClick(e)}
            />
            <span>
              <div className="timing-wrap-info">
                <h2 className="pb-0">{floorPlanOptionText}</h2>
              </div>
            </span>
          </label>
        );
     
    } else {
      const propertyDocs =
      propertyDocuments &&
      propertyDocuments.map((pd) => {
        const id = _.get(pd, "OptionId");
        const text = _.get(pd, "Option_Text", "");
        let isChecked;
        switch (id) {
          case 1:
            isChecked = apprOnFile;
            break;
          case 2:
            isChecked = insReport;
            break;
          case 3:
            isChecked = sellerFin;
            break;
          case 4:
            isChecked = floorPlan;
            break;
          case 5:
            isChecked = floodCert;
            break;
            case 6:
              isChecked = assMortgage;
              break;
        }
        return (
          <label className="check-wrap mb-3" key={id}>
            <CheckBox
              class="form-check-input"
              name="pd-checkbox"
              checked={isChecked}
              value={id}
              id={id}
              onChange={(e) => handleCheckBoxItemClick(e)}
            />
            <span>
              <div className="timing-wrap-info">
                <h2 className="pb-0">{text}</h2>
              </div>
            </span>
          </label>
        );
      });

    return propertyDocs;
    }
    }
   
    
  };
  return (
    <div className={!isEdit ? "center-content-sec mw-444" : ""}>
      <div className="wantad-timing-wrap">{loadDocuments()}</div>
    </div>
  );
};
export default InventoryDocuments;
