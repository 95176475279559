import React, { useState, useEffect } from "react";
import "../styles/clients.scss";
import { getLocalStorage, getUserId, getUserType, loggedInUser, removeLocalStorage, setLocalStorage } from "../../helpers/common";
import { useDispatch, useSelector } from "react-redux";
import IntroductionDetails from "../components/Messages/IntroductionDetails";
import ViewImagesFullGallery from "../commonComponents/ViewImagesFullGallery";
import ContactList from "../components/Messages/ContactList";
import { loginOrSignUp } from "../../helpers/message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
  or,
  doc,
  getDoc
} from "firebase/firestore";
import MessagesView from "../components/Messages/MessagesView";
import ViewWantAd from "../components/Messages/ViewWantAd";
import { IMAGES_FULL_VIEW, INTRODUCTION_DETAILS, MESSAGES_VIEW, VIEW_CLIENT_WANTAD_DETAILS } from "../../constants/onBoarding";

const HandleMessagesScreens = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const messages = useSelector((state) => state.messages.message);

  const [uiKey, setUIKey] = useState(null);
  const userId = getUserId();
  const userType = getUserType();
  const userDetails = loggedInUser();

  const [message, setMessages] = useState('');
  const [selectedData, setSelectedData] = useState('');
  const [selectedIntroductionId, setSelectedIntroductionId] = useState(0);
  const [filter, setFilter] = useState('all');
  const [filterList, setFilterList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [introIdFromURL, setIntroIDFromURL] = useState(searchParams.get("Id"));

  const [userDeleted, setUserDeleted] = useState(false);

  const chatMessages = useSelector((state) => state.unreadCount.chatMessages);
  const [introductionPhotos, setIntroductionPhotos] = useState([]);


  useEffect(() => {
    let email = _.get(userDetails, "userInfo.email", null);
    let login = loginOrSignUp(email);

    if (!login) {
      navigate(pageURLs.mobileHome)
    }

  }, []);

  const handleFilter = (e) => {

    setFilter(e.value);
  }


  const handleFilterValue = (value) => {
    if (filterList && value) {
      const filterValue = filterList.filter(function (lst) {
        return lst.value === value;
      });
      return filterValue;
    }
    return null;
  };



  // useEffect(() => {
  //   let count = 0;
  //   if(message && message.length > 0){
  //     message.map((msg) =>{
  //     if(msg?.isUnreadMessageExist == true){
  //        count = count +1 ;
  //     }
  //     })
  //    }
  //    setLocalStorage(UNREADCHATMESSAGESCOUNT, count)
  // }, [message]);


  // useEffect(() => {
  //   const q = query(collection(db, "Introduction"),
  //     or(where("inventroyCreatedId", "==", userId),
  //       where("wantAdCreatedId", "==", userId)
  //     )

  //   );

  //   const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
  //     let fetchedMessages = [];
  //     let orderd = [];

  //     QuerySnapshot.forEach((doc) => {
  //       fetchedMessages.push({ ...doc.data(), id: doc.id });
  //     });

  //     let assignedToMe = fetchedMessages?.filter((item) => item.wantAdCreatedId == userId && item.isaccepted == true && item.isdeleted == false);
  //     let assignedByMe = fetchedMessages?.filter((item) => item.inventroyCreatedId == userId && item.isaccepted == true && item.isdeleted == false)

  //     orderd = [...assignedToMe, ...assignedByMe];

  //     let allCountLabel = orderd.length > 0 ? "All conversations (" + orderd.length + ")" : "All conversations";
  //     let sentByMeCountLabel = assignedByMe.length > 0 ? "Introductions sent (" + assignedByMe.length + ")" : "Introductions sent";
  //     let sentToMeCountLabel = assignedToMe.length > 0 ? "Introduced to me (" + assignedToMe.length + ")" : "Introduced to me";

  //     const options = [
  //       { value: 'all', label: allCountLabel },
  //       { value: 'intro_sent', label: sentByMeCountLabel },
  //       { value: 'intro_to_me', label: sentToMeCountLabel }
  //     ];
  //     setFilterList(options)

  //     if (filter == "intro_to_me") {
  //       const doEmptyAction = assignedToMe.every(o => o.createdat)
  //       if (doEmptyAction) {
  //         orderd = assignedToMe.sort((a, b) => b.createdat - a.createdat);
  //         setMessages(orderd);
  //         setSelectedData(orderd[0]);
  //         setSelectedIntroductionId(orderd[0]?.introductionId);
  //       }
  //     } else if (filter == "intro_sent") {
  //       const doEmptyAction = assignedByMe.every(o => o.createdat)
  //       if (doEmptyAction) {
  //         orderd = assignedByMe.sort((a, b) => b.createdat - a.createdat);
  //         setMessages(orderd);
  //         setSelectedData(orderd[0]);
  //         setSelectedIntroductionId(orderd[0]?.introductionId);
  //       }
  //     } else {

  //       const doEmptyAction = orderd.every(o => o.createdat)
  //       if (doEmptyAction) {
  //         let orderdNew = orderd.sort((a, b) => (b.createdat) - (a.createdat));
  //         setMessages(orderdNew);
  //         setSelectedData(orderdNew[0]);
  //         setSelectedIntroductionId(orderdNew[0]?.introductionId);


  //       }

  //     }
  //   });

  //   return () => unsubscribe;

  // }, [filter]);

  useEffect(() => {

    let fetchedMessages = chatMessages?.payload;
    let orderd = [];

    // chatMessages?.payload.forEach((doc) => {
    //   fetchedMessages.push({ ...doc.data(), id: doc.id });
    // });

    if (fetchedMessages) {

console.log("fetchedMessages", fetchedMessages)
      let assignedToMe = fetchedMessages?.filter((item) => item.wantAdCreatedId == userId && item.isaccepted == true && item.isdeleted == false);
      let assignedByMe = fetchedMessages?.filter((item) => item.inventroyCreatedId == userId && item.isaccepted == true && item.isdeleted == false)
      console.log("assignedToMe", fetchedMessages)
      orderd = [...assignedToMe, ...assignedByMe];

      let allCountLabel = orderd.length > 0 ? "All conversations (" + orderd.length + ")" : "All conversations";
      let sentByMeCountLabel = assignedByMe.length > 0 ? "Introductions sent (" + assignedByMe.length + ")" : "Introductions sent";
      let sentToMeCountLabel = assignedToMe.length > 0 ? "Introduced to me (" + assignedToMe.length + ")" : "Introduced to me";

      const options = [
        { value: 'all', label: allCountLabel },
        { value: 'intro_sent', label: sentByMeCountLabel },
        { value: 'intro_to_me', label: sentToMeCountLabel }
      ];
      setFilterList(options)

      if (filter == "intro_to_me") {
        const doEmptyAction = assignedToMe.every(o => o.createdat)
        if (doEmptyAction) {
          orderd = assignedToMe.sort((a, b) => b.createdat - a.createdat);
          setMessages(orderd);
          setSelectedData(orderd[0]);
          setSelectedIntroductionId(orderd[0]?.introductionId);
        }
      } else if (filter == "intro_sent") {
        const doEmptyAction = assignedByMe.every(o => o.createdat)
        if (doEmptyAction) {
          orderd = assignedByMe.sort((a, b) => b.createdat - a.createdat);
          setMessages(orderd);
          setSelectedData(orderd[0]);
          setSelectedIntroductionId(orderd[0]?.introductionId);
        }
      } else {

        const doEmptyAction = orderd.every(o => o.createdat)
        if (doEmptyAction) {
          let orderdNew = orderd.sort((a, b) => (b.createdat) - (a.createdat));
          setMessages(orderdNew);
          setSelectedData(orderdNew[0]);
          setSelectedIntroductionId(orderdNew[0]?.introductionId);


        }

      }
    }  else{
      const options = [
        { value: 'all', label: 0 },
        { value: 'intro_sent', label: 0 },
        { value: 'intro_to_me', label: 0 }
      ];
      setFilterList(options)
      setMessages([]);
      setSelectedData(null);
      setSelectedIntroductionId(null);

    }

  }, [chatMessages, filter]);




  console.log(message)

  const handleWantAdSelection = async (msgData) => {
    // const IntroIdFromURL = searchParams.get("Id");
    if (introIdFromURL) {
      // if (msgData?.introductionId) {
      setSelectedIntroductionId(introIdFromURL.toString());
      const docRef = doc(db, "Introduction", introIdFromURL.toString());
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSelectedData(docSnap.data());
        setIntroIDFromURL(null)
      }

      // setSelectedData(msgData);
    } else {
      if (msgData?.introductionId) {
        setSelectedIntroductionId(msgData?.introductionId);
        setSelectedData(msgData);
      }
    }
  }


  const handleMessagesdUI = () => {

    switch (uiKey) {
      case MESSAGES_VIEW:
        return <MessagesView
          setUIKey={setUIKey}
          selectedId={selectedIntroductionId}
          userId={userId}
          selectedData={selectedData}
          getIntroductionDetails={(IntroductionId) => { getIntroductionDetails(IntroductionId) }}
          ViewProfileModal={(agentId) => { ViewProfile(agentId, true) }}
          userDeletedUpdate={(deleted) => { setUserDeleted(deleted) }}
        />;

      case VIEW_CLIENT_WANTAD_DETAILS:
        return <ViewWantAd
          setUIKey={setUIKey}
          wantAdId={selectedData?.wantAdId}
        />;

      case INTRODUCTION_DETAILS:
        return <IntroductionDetails
          introductionId={selectedIntroductionId}
          setUIKey={setUIKey}
          setIntroductionPhotos={setIntroductionPhotos}
          isFromMessagesIntroDetailsPage={true}
        />;

      case IMAGES_FULL_VIEW:
        return <ViewImagesFullGallery
          photos={introductionPhotos}
          setUIKey={setUIKey}
          uiKey={uiKey}
          isFromMessagesIntroDetailsPage={true}
        />

      default:
        return <ContactList
          setUIKey={setUIKey}
          userId={userId}
          messages={message}
          selectedIntroductionId={selectedIntroductionId}
          handleWantAdSelection={(msgData) => { handleWantAdSelection(msgData) }}
          filterList={filterList}
          handleFilter={handleFilter}
          selectedFilterValue={handleFilterValue(filter)}

        />;
    }
  };

  return (
    <>
      {handleMessagesdUI()}
    </>
  );
};

export default HandleMessagesScreens;
