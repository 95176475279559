import { pageURLs } from "./pageURLs"

export const BUY = 'BUY'
export const SHORT_RENT = 'STR'
export const LONG_RENT = 'LTR'
export const BUY_LAND = 'BUY_LAND'
export const LONG_RENT_LAND = 'LTR_LAND'
export const SHORT_RENT_LAND = 'STR_LAND'
export const BUY_STRENGTHEN = 'BUY_STRENGTHEN'
export const LTR_STRENGTHEN = 'LTR_STRENGTHEN'
export const INVENTORY_STRENGTHEN = "INVENTORY_STRENGTHEN"
export const LOT_LAND = 'LOT_LAND'
export const SELL = 'SELL'
export const USER_TYPE_MEMBER= 1 
export const USER_TYPE_AGENT = 2 
export const REAL_ESTATE_AGENT = 1 
export const PROPERTY_MANAGER = 2 
export const BOTH_TITLE = 3 
export const WANT_AD = 'WANT_AD'
export const RESET_PWD_EMAIL_VERIFICATION = 'RESET_PWD_EMAIL_VERIFICATION'
export const PROPERTY_AD = 'PROPERTY_AD'
export const IS_LAND = 6
export const IS_OTHER = 7
export const IS_ENTIRE_SPACE = 10
export const IS_ENTIRE_PLACE = 10
export const IS_PRIVATE_ROOM = 11
export const ADTYPE_BUY = 1
export const ADTYPE_LTR = 2
export const ADTYPE_STR = 3
export const WANT_AD_PAGE_LIMIT = 20
export const WANT_AD_KEY = "memberBuy"
export const WANT_AD_KEY_TEMP = "memberBuyTemp"
export const WANT_AD_ARRAY_KEY = "wantAdArray"
export const STRENGTHEN_WANT_AD_KEY = "strengthenAd"
export const INVENTORY_KEY = "memberInventory"
export const INVENTORY_KEY_TEMP = "memberInventoryTemp"
export const STRENGTHEN_INVENTORY_KEY = "strengthenInvntry"
export const USER_LOCATION_KEY = "userLocation"
export const RECENT_SEARCHES = "recent_searches"
export const SEARCHED_LOCATION = "searchedLocation"
export const FILTER_QUERY = "filterQuery"
export const SCROLL_DIRECTION_UP = "up"
export const SCROLL_DIRECTION_DOWN = "down"
export const USER_INFO_KEY = "userInfo"
export const CLIENT_DETAILS_KEY = "clientDetails"
export const IS_APARTMENT_CONDO = 2
export const IS_GUEST_HOUSE = 8
export const IS_HOUSE = 1
export const IS_TOWNHOME = 3
export const IS_MANUFACTURE = 5
export const IS_MULTI_FAMILY = 4
export const IS_VILLA = 9
export const IS_HOTEL = 12
export const ENTIRE_SPACE_TYPE = "entirespace"
export const PRIVATE_ROOM_TYPE = "privateroom"
export const ENTIRE_SPACE_INVENTY_TYPE = "Inv-es"
export const PRIVATE_ROOM_INVENTY_TYPE = "inv-pr"
export const INVENTORY_TYPE = "inventory"
export const IN_CITY_LIMITS = 'In city limits'
export const RURAL ='Rural'
export const LOT_TYPES = [{ value: 1, text: 'Rural' },{ value: 2, text: 'In city limits' }]
export const ROAD_TYPES = [{ value: 1, text: 'City roads' },{ value: 2, text: 'Private drive' }]
export const WATER_SUPPLY = [{ value: 1, text: 'Domestic water supply' },{ value: 2, text: 'Drilled well' }]
export const WASTE_WATER_SYSTEM = [{ value: 1, text: 'Sewer' },{ value: 2, text: 'Septic system' }]
export const YES_NO_OPTIONS = [{ value: true, text: 'Yes' },{ value: false, text: 'No' }]
export const INVENTORY_ALL_TYPE = 'inv'
export const INVENTORY_SELL = 'inv_sell'
export const INVENTORY_LTR = 'inv_ltr'
export const INVENTORY_STR = 'inv_str'
export const INVENTORY_SELL_LTR = 'inv_sell_ltr'
export const INVENTORY_SELL_STR = 'inv_sell_str'
export const INVENTORY_LTR_STR = 'inv_ltr_str'
export const INVENTORY_LAND = 'inv_land'
export const MEMBER_INVENTORY_EDIT_KEY = "memberInventoryEdit"
export const AGENT_INVENTORY_CREATE_KEY = "agentInventoryCreate"
export const AGENT_INVENTORY_EDIT_KEY = "agentInventoryEdit"
export const AGENT_WANTAD_KEY = "agentWantAdData"
export const MEMBER_INTRODUCTION = "memberIntroduction"
export const AGENT_INTRODUCTION = "agentIntroduction"
export const PUBLISHED = "PUBLISHED"
export const PAUSED = "PAUSED"
export const DRAFT = "DRAFT"
export const CLOSED_TAB = "Closed"
export const PAUSED_TAB = "Paused"
export const DRAFT_TAB = "Drafts"
export const PUBLISHED_TAB = "Published"
export const INTRODUCTION_ACCEPT = 1
export const INTRODUCTION_DECLINE = 2
export const INTRODUCTION_NOT_INTERESTED = 3
export const INTRODUCTION_NOT_A_FIT = 4
export const INTRODUCTION_CANCELLED = 5
export const INTRODUCTION_DECLINE_UNDO = 'INTRODUCTION_DECLINE_UNDO'
export const NORMAL_USER_FREE = 1
export const NORMAL_USER_PREMIUM_MONTHLY = 2
export const NORMAL_USER_PREMIUM_YEARLY = 3
export const AGENT_USER_FREE_MEMBER = 4
export const AGENT_USER_MONTHLY_PRO_MEMBER = 5
export const AGENT_USER_YEARLY_PRO_MEMBER = 6
export const ERROR_TEXT = 'Invalid input'
export const AGENT_USER_FREE_PLAN = "Lite"
export const MEMBER_USER_FREE_PLAN = "Lite"
export const AGENT_USER_PREMIUM_PLAN = "Pro Member"
export const NORMAL_USER_PREMIUM_PLAN = "Member"
export const HOME_PAGE = 'HOME'
export const DEFAULT_MAP_ZOOM = 13
export const DEFAULT_WANT_AD_MAP_ZOOM = 13
export const DEFAULT_INVENTORY_MAP_ZOOM = 13
export const INVENTORY_RT_CITY_ROADS = 1
export const INVENTORY_RT_PRVT_DRIVE = 2
export const INVENTORY_WS_DOMESTIC = 1
export const INVENTORY_WS_DRLLD_WELL = 2
export const INVENTORY_WWS_SEWER = 1
export const INVENTORY_WWS_SEPTIC_SYS= 2
export const STORY_ANY = 1;
export const STORY_SINGLE = 2;
export const YEAR_BUILT_RANGE = 1;
export const NEW_CONSTRUCTION = 2;
export const STR_MIN_BUDGET = 50
export const MIN_LT_DROPDOWN = "minLtSzDrop";
export const MAX_LT_DROPDOWN = "maxLtSzDrop";
export const NO_MIN_TEXT = "No min lot size"//"No min";
export const NO_MAX_TEXT = "No max lot size"//"No max";
export const INVENTORY_MAP_RADIUS = 0.3 // in miles
export const PAGE_LIMIT = 10;
export const DECIMAL_POINTS_IN_COORDICATES = 4;
export const MAP_EVENT_DRAG = 'MAP_EVENT_DRAG';
export const MAP_EVENT_MARKER_DRAG = 'MAP_EVENT_MARKER_DRAG';
export const MAP_EVENT_CLICK = 'MAP_EVENT_CLICK';
export const MAP_EVENT_LOCATION_CHANGE = 'MAP_EVENT_LOCATION_CHANGE';
export const DATE_FORMAT_MM_DD_YYYY = 'DATE_FORMAT_MM_DD_YYYY';
export const STR_DATE_RANGE = 1;
export const STR_FLEXIBLE_DATE = 2
export const MAP_TYPE_ROADMAP = 'roadmap'
export const MAP_ICON_S3_URL = 'https://s3.amazonaws.com/gitcha-uploads/Gitcha_images/map-icon-black.png'
export const IMG_JPEG_FORMAT = 'image/jpeg'
export const IMG_PNG_FORMAT = 'image/png'
export const MEMBER_PREFERENCE_WANTAD = 'wantad'
export const MEMBER_PREFERENCE_INVENTORY = 'inventory'
export const MEMBER_PREFERENCE_BROWSE = 'browse'
export const MEMBER_PREFERENCE_POST_WANTAD = 'post_want_ad'
export const INVESTOR_MEMBER_PLAN = 'Investor Member'
export const PRO_MEMBER_PLAN = 'Pro Member'
export const BUDGET_NOMIN_NOMAX = 'No min - No max'

export const PLAN_TYPES = [{
    name : 'Lite',
    amount : 'free'
},
// {
//     name : 'Pro Member',
//     amount : 'free'
// },
{
    name : 'Member',
    amount : {
        'monthly' : 13,
        'annual'  : 10
    }
},
{
    name : 'Investor Member',
    amount : {
        'monthly' : 15,
        'annual'  : 12
    }
},
{
    name : 'Pro Member',
    amount : {
        'monthly' : 15,
        'annual'  : 12
    }
},
{
    name : 'Pro Preferred',
    amount : {
        'monthly' : 75,
        'annual'  : 60
    }
}
]


export const PlanDetails = [
    {
        planName : 'Member',
        description : 'Unlock features to get the most out of Gitcha for less.',
        features : ['Unlimited want-ads', 'Unlimited properties in your inventory', '10 free introductions per month']        
    },
    {
        planName : 'Investor Member',
        description : 'Includes investor specific features to create more opportunities.',
        features : ['Property management dashboard', 'Additional buyer box criteria', 'Unlimited want-ads', 
        'Unlimited properties in your inventory', '10 free introductions per month', 'Custom Investor profile and badge'],
        badge : true,
        badgeText : 'INVESTOR',
        isGoldBadge : true

    },
    {
        planName : 'Pro Member',
        description : 'Specific features for agents and teams to create and manage deals.',
        features : ['Client management dashboard','Unlimited want-ads', 'Unlimited properties in your inventory', '10 free introductions per month' , 'Custom agent profile and Pro badge',
        "Branded Buyer Digital Listing Form", "See other Premium Agents’ contact info on want ads (coming soon)"],
        badge : true,
        badgeText : 'PRO',
        isGoldBadge : false
    }
]


export const INTRODUCTION_AMOUNT = 3;
export const INTRODUCTION_TAX = 0 ;

export const WANT_AD_KEY_EDIT = "memberWantAdEdit";
export const INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST = 1;
export const INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST = 2;

export const INTRODUCTION_EXPIRATION_TIME = 72;
// export const INTRODUCTION_EXPIRATION_TIME = 1; // for testing
export const  YEAR_BUILT_MIN = 1900;
export const YEAR_BUILT_MAX = new Date().getFullYear();

export const ACESS_CODE = 'accessCode';
export const INAPPNOTIFICATIONCOUNT = 'inappNotificationCount';
export const NOTIFICATIONCOUNT = 'notificationCount';

export const WANT_AD_BUY_PROPERTY_ID = 13

export const DISPLAY_TEXT_BUY = "Buy"
export const DISPLAY_TEXT_LTR = "Rent (Long-term)"
export const DISPLAY_TEXT_STR = "Rent (Short-term)"

export const DISPLAY_BUY = "buy"
export const DISPLAY_LTR = "rent"
export const DISPLAT_STR = "stay"
export const DISPLAY_LTR_TIMELINE = "move-in"
export const NOT_FREE_OR_MEMBER_PERIOD = 1
export const FREE_PLAN = 1
export const DISPLAY_STR_PPTY_TYPE = "stay in"

export const DISPLAT_RENTAL = 'rental'

export const dashBoardActivePageList =[
    pageURLs.proDashboard,
    pageURLs.myWantAds
  ]


  export const  INVENTORY_PURPOSE = {
    Seller : 'SELL',
    Long_Term_Rent : 'LTR', 
    Short_Term_Rent : 'STR'
}

export const UNREADCHATMESSAGESCOUNT='unreadChatMessagesCount';