
import { Modal } from "react-bootstrap";

const DeleteFavouriteModal = (props) => {
  const {show, onHide, handleDelete} = props;
  return (
    <Modal show={show}  centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Remove this folder from your favorited want-ads?</h3>
        <p>Deleting this folder will remove all of the favorited want-ads stored in it.</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-delete w-100" onClick={()=>handleDelete()}>Confirm</button>
            <button type="button" className="btn btn-secondary w-100" onClick={()=>onHide(false)}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default DeleteFavouriteModal;
