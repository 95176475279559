import React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { saveMemberWantAd } from "../../services/wantAdService";

import { pageURLs } from "../../constants/pageURLs";
import {
  clearLocalStorage,
  getLocalStorage,
  getUserId,
  removeLocalStorage,
} from "../../helpers/common";
import { WANT_AD_KEY } from "../../constants/common";

const ReadyToSaveDraftModal = (props) => {
  const { handlePopUp, show, formValues } = props;
  const mapImg = _.get(formValues, "mapImg", null);
  const currentActiveStep = _.get(formValues, "actvStep", null);
  const navigate = useNavigate();

  const saveToDraft = async () => {
    const userId = getUserId();
    const requestPayLoad = getLocalStorage(WANT_AD_KEY);
    if (userId && requestPayLoad) {
      let arrayValue = [];
      if(requestPayLoad?.zipIdValues){
        requestPayLoad?.zipIdValues.map((zip)=>{
          arrayValue.push(zip?.zipId)
          })
          requestPayLoad.zipId = arrayValue;
      }  else if(requestPayLoad?.zipCode){
        requestPayLoad?.zipCode.map((zip)=>{
          if(zip?.zipId && zip?.zipId != null){
            arrayValue.push(zip?.zipId)
          }
        })
        requestPayLoad.zipId = arrayValue;
    }
      requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
      requestPayLoad.IsSavedDraft = true;
      requestPayLoad.IsPublished = false;
      requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
      delete requestPayLoad.tlText;
      delete requestPayLoad.prptyTypeTxt;
      delete requestPayLoad.wantAdValErrors;
      await saveMemberWantAd(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            removeLocalStorage(WANT_AD_KEY);
            //navigate(pageURLs.createwantad);
            navigate(pageURLs.wantAdListing, { state: { showDraft: true } });
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      clearLocalStorage();
      navigate(pageURLs.home);
    }
  };

  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >
    
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => handlePopUp(false)}
        ></button>
        <h2 className="text-start f-size-24 mb-2 l-spacing">
          Ready to save this draft?
        </h2>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-start">
              Your dream property is waiting for you. Creating your want-ad gets
              you closer to finding it.
            </p>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
              <Button
                variant=""
                disabled={currentActiveStep >= 4 && !mapImg ? true : false}
                className="link-btn-primary-outline me-3"
                onClick={() => saveToDraft()}
              >
                Save & Exit
              </Button>
              <Button
                variant="primary"
                className="btn"
                onClick={() => handlePopUp(false)}
              >
                Stay and Build my Want-Ad
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default ReadyToSaveDraftModal;
