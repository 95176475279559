import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteClient } from "../../services/agentClientsService";
import { pageURLs } from "../../constants/pageURLs";

const DeleteClientModal = (props) => {
  const {show, onHide, clientDetails} = props;

  const navigate = useNavigate();

  const handleDeleteClient = async () => {
    const clientId = clientDetails?.ClientId;
    if (clientId) {
      await deleteClient(clientId)
        .then((response) => {
          if (response.errorCode === 0) {
            toast.success("Client deleted.");
            onHide();
            navigate(pageURLs.agentClientListing);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Are you sure you want to delete your client, {clientDetails?.FirstName + ' ' + clientDetails?.LastName}?</h3>
        <p>Deleting this client will permanently remove their want-ads, inventory, and records.</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-delete w-100" onClick={handleDeleteClient}>Confirm</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default DeleteClientModal;
