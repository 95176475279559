import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';

const SortClientoffcanvas = (props) => {
  const {show, onHide, handleSort} = props;

  const [selectedItem, setSelectedItem] = useState('recentlyActive');
  const handleItemClick = (event) => {
    const clickedItemId = event.target.id;
    setSelectedItem(clickedItemId);
    if (clickedItemId === 'recentlyActive') {
      handleSort({
        order: 'desc',
        orderBy: 'Recent_Action_On',
      });
    } else if (clickedItemId === 'newest') {
      handleSort({
        order: 'desc',
        orderBy: 'CreatedOn',
      });
    } else if (clickedItemId === 'oldest') {
      handleSort({
        order: 'asc',
        orderBy: 'CreatedOn',
      });
    } else if (clickedItemId === 'alphabeticalAsc') {
      handleSort({
        order: 'asc',
        orderBy: 'FirstName',
      });
    } else if (clickedItemId === 'alphabeticalDesc') {
      handleSort({
        order: 'desc',
        orderBy: 'FirstName',
      });
    }
  };

  return (

    <Offcanvas
     className="mobile-offcanvas sort-filter-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}>
    <Offcanvas.Body>
      <div className="offcanvas-sort-wrap">
        <div className="sort-heading">Sort</div>
        <div className="sort-fields">
            <ul>
                <li id="recentlyActive" className={selectedItem === 'recentlyActive' ? 'active' : ''} 
                  onClick={handleItemClick}>Recently Active <span className="check-icon"></span></li>
                <li id="newest" className={selectedItem === 'newest' ? 'active' : ''} 
                  onClick={handleItemClick}>Newest <span className="check-icon"></span></li>
                <li id="oldest" className={selectedItem === 'oldest' ? 'active' : ''} 
                  onClick={handleItemClick}>Oldest <span className="check-icon"></span></li>
                <li id="alphabeticalAsc" className={selectedItem === 'alphabeticalAsc' ? 'active' : ''} 
                  onClick={handleItemClick}>Alphabetical Ascending <span className="check-icon"></span></li>
                <li id="alphabeticalDesc" className={selectedItem === 'alphabeticalDesc' ? 'active' : ''} 
                  onClick={handleItemClick}>Alphabetical Descending <span className="check-icon"></span></li>
            </ul>
        </div>
      </div>
    </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default SortClientoffcanvas;
