import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import MatchImage from "../images/intro_match.svg";
import DashboardImage from "../../images/dashboard_img.svg"
import ReceiveIntroImage from "../images/receive-intro.svg"

const LearnIntroductionModal = (props) => {
  const {show, onHide, handleShowTutorial, isFromDashboard, isReceiveIntroduction, handleSkipAll} = props;
  const imageSrc = isFromDashboard ? DashboardImage : isReceiveIntroduction ? ReceiveIntroImage : MatchImage;
  const desc = isFromDashboard ? "We’re here to help make your job easier - and that starts with giving you the tools to keep you organized!" : isReceiveIntroduction ? "Ready to see if this property is a good match for you? Learn how introductions work in under 1 minute." : "Think your home might be a match? Learn how to introduce properties in under 1 minute."
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup not-fit-modal">
        <Modal.Body className="text-center">
        <h3>{isFromDashboard ? "Use your agent dashboard to organize and manage your client’s portfolio" : "Learn about introductions!"}</h3>
        <p>{desc}</p>
        <img src={imageSrc} className="mt-4"/>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={()=>{handleShowTutorial(); onHide()}}>Show Me How</button>
            <button type="button" className="btn btn-secondary w-100" onClick={()=>{handleSkipAll(); onHide()}}>Not right now</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default LearnIntroductionModal;
