import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CancelPlanModal from "../UserPlan/CancelPlanModal";
import AnnualAgentPlan from "./AnnualAgentPlan";
import MonthlyAgentPlan from "./MonthlyAgentPlan";
import {
  getLocalStorage,
  getUserId,
  sendEmailSales,
  setLocalStorage,
  getUserType,
  checkPlanCanbeUpgraded,
} from "../../../../helpers/common";
import { toast } from "react-toastify";
import {
  createSubscriptionPlan,
  createSubscriptionPlanOnSignup,
  getActivePromoCodeDetails,
} from "../../../../services/PlanService";
import { AGENT_USER_FREE_PLAN, USER_INFO_KEY, USER_TYPE_AGENT, USER_TYPE_MEMBER } from "../../../../constants/common";
import { useSelector } from "react-redux";
import ContactModal from "../ContactModal";
import { AGENT_PROFILE, AGENT_TITLE, REVIEW_PLAN } from "../../../../constants/onBoarding";
import { useNavigate } from "react-router-dom";
import PlanUpgradeRestrictionModal from "../../../../components/PlanUpgrade/PlanUpgradeRestrictionModal";
import { pageURLs } from "../../../../constants/pageURLs";

const AgentPlan = (props) => {
  const { userDetails, handlePageToggle, handleBackButtonClick , currentActivePlan, setContinueButtonClicked, UpgradeToAgent} = props;
  const navigate = useNavigate()
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [key, setKey] = useState("annual");
  const [fromSignUp, setFromSignUp] = useState(false);
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const userId = getUserId();
  const [showCancelPlanModal, setCancelPlanModal] = useState(false);
  const CancelPlanModalClose = () => setCancelPlanModal(false);
  const CancelPlanModalShow = () => setCancelPlanModal(true);
  const userType = getUserType();

  // contact modal
  const [contactModalState, setContactModalState] = useState(false);
  const ContactModalClose = () => setContactModalState(false);
  const ContactModalShow = () => setContactModalState(true);
  const [enablePromocode, setEnablePromocode] = useState(false);
  const [enablePromocodePeriod, setEnablePromoCodePeriod] = useState([]);
  const [showPlanRestrictionModal, setShowPlanRestrictionModal] = useState(false);  

  useEffect(() => {
    getActivePromoCodeDetails().then((response) => {
      let temp = [];
      const res = _.get(response, "result", []);
      if (res?.length > 0) {
        const result = res.filter(
          (planDetails) =>
            planDetails?.UserType == 2 || planDetails?.UserType == 3
        );
        if (result && result.length > 0) {
          setEnablePromocode(true);
        }
        result.forEach((data) => {
          temp.push(data?.Period);
        });
        setEnablePromoCodePeriod(temp);
      }
    });
  }, []);

  

  useEffect(() => {
    if (userDetails?.PlanName == null) {
      setFromSignUp(true);
    } else {
      setFromSignUp(false);
    }
    setSelectedPeriod(userDetails?.period);
    setSelectedPlan(userDetails?.PlanName);
  }, [userDetails]);

  useEffect(() => {
    if (selectedPeriod === 3) {
      setKey("annual");
    } else if (selectedPeriod === 2) {
      setKey("monthly");
    }
  }, [selectedPeriod]);
  

  const handleUpgrade = (period, plan,continueClicked=false) => {
      setSelectedPeriod(period);
      setSelectedPlan(plan);
      if(!continueClicked){
        setContinueButtonClicked(false)
      }
      if (checkPlanCanbeUpgraded(currentActivePlan, plan, period)?.canPlanUpgrade == false && !continueClicked) {
        setShowPlanRestrictionModal(true);      
      } else { 
      handlePageToggle({activePage: REVIEW_PLAN,
      planDetails:{
        period: period,
        selectedPlan: plan,
        fromSignUp: fromSignUp,
        enablePromocode: enablePromocode,
        enablePromocodePeriod: enablePromocodePeriod
      }})  
    }    
  };

  const handleDowngrade = () => {   
      CancelPlanModalShow()   
  };

  const handleCancel = () => {
    if (userDetails?.period === 2) {
      setKey("monthly");
    } else {
      setKey("annual");
    }   
  };

  const upgradeUserToFreePlan = async (continueClicked=false) => {
      setSelectedPeriod(1)
      setSelectedPlan("Lite")
    if (userType === USER_TYPE_MEMBER && checkPlanCanbeUpgraded(currentActivePlan, 'Lite', 1)?.canPlanUpgrade == false && !continueClicked) {
      setShowPlanRestrictionModal(true);
      
    }
    else {

      const data = {
        PlanName: "Lite",
        PlanPeriod: 1,
        UserType: userType,
        PopupStatus : continueClicked? true : false
      };

      if(UpgradeToAgent===true){
        data.changeUserType = 2
        await createSubscriptionPlan(data)
        .then(async (response) => {
          if (response.errorCode === 0) {          
            toast.success("You've selected Lite!");
            const userInfo = getLocalStorage(USER_INFO_KEY);            
            userInfo.userInfo["userType"] = USER_TYPE_AGENT;
            setLocalStorage(USER_INFO_KEY, userInfo);
            if(UpgradeToAgent===true){navigate(pageURLs.mobilePremiumAgentProfileEdit)}                          
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        }); 
      }
      else {
        await createSubscriptionPlanOnSignup(data)
          .then(async (response) => {
            if (response.errorCode === 0) {          
              toast.dismiss();
              toast.success("You've selected Lite!");
              const userInfo = getLocalStorage(USER_INFO_KEY);
              userInfo.userInfo["planStatus"] = true;
              setLocalStorage(USER_INFO_KEY, userInfo);
              handlePageToggle({activePage: AGENT_PROFILE})         
            } else {
              toast.error(response.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }

    } 
    
  };

  const handleContinueClick = () => {
    setContinueButtonClicked(true)
    if(selectedPlan===AGENT_USER_FREE_PLAN){
      upgradeUserToFreePlan(true)
    }
    else {
      handleUpgrade(selectedPeriod, selectedPlan, true); 
      
    }
  }

  function BackButton () {
    let isShow =  true
    if(fromSignUp){
      if(_.get(getLocalStorage(USER_INFO_KEY), 'userInfo.planStatus')){
        isShow = false
      }
    }
  if(isShow){
    return  <a className="back-link-small" href={void 0} role="button" onClick={()=> 
      fromSignUp? handleBackButtonClick({prevPage: AGENT_TITLE }): navigate(-1)}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
        fill="#1B1C1E"
      />
    </svg>
  </a>
  }
  }

  return (
    <>
      <div className="gitcha-main-wrapper pb-0">
        <div className="content-wrapper vh-100">
          <div className="inner-layout-header-new bg-white">
            <div className="review-plan-header header-brdr-btm w-100">
              <div className="signup-login-header-top signup-header-txt clearfix">
                <BackButton/>
                <span>Plans and Pricing</span>
              </div>
            </div>
          </div>

          <div className="inner-layout-content-new bg-grey-variant">
            <div className="select-plan-wrap pl-16 pr-16 pt-4">
              <Tabs className="mb-20 common-navtab" id="controlled-tab-example">
                <Tab eventKey="annual" title="Annual">
                  <AnnualAgentPlan
                    userDetails={userDetails}
                    handleUpgrade={handleUpgrade}
                    upgradeUserToFreePlan={upgradeUserToFreePlan}
                    handleDowngrade={handleDowngrade}
                    ContactModalShow={ContactModalShow}
                  />
                </Tab>
                <Tab eventKey="month" title="Monthly">
                  <MonthlyAgentPlan
                    userDetails={userDetails}
                    handleUpgrade={handleUpgrade}
                    upgradeUserToFreePlan={upgradeUserToFreePlan}
                    handleDowngrade={handleDowngrade}
                    ContactModalShow={ContactModalShow}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <CancelPlanModal
    show={showCancelPlanModal}
    onHide={CancelPlanModalClose}
    userDetails={userDetails}
    />
    <PlanUpgradeRestrictionModal
      show={showPlanRestrictionModal}
      onHide={() => {setShowPlanRestrictionModal(false); setContinueButtonClicked(false)}}
      currentActivePlan={currentActivePlan}
      selectedPeriod={selectedPeriod}
      handleContinueButton={()=>handleContinueClick()}
    />
      <ContactModal
      show={contactModalState}
      onHide={ContactModalClose}
      sourceType={2} // Enterprise
    />
      </div>
    </>
  );
};

export default AgentPlan;
