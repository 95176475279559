import React, { useState, useEffect } from "react";
import { BUY_LAND, INVENTORY_KEY, INVENTORY_LAND, WANT_AD_KEY } from "../../../constants/common";
import { getNiceToHaves } from "../../../services/commonServices";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { convertToNumber } from "../../../helpers/validations";
import PastrolViewIcon from "../../../images/pastoral-view-img.png";
import { getLocalStorage } from "../../../helpers/common";

const NiceToHavesLand = (props) => { 
  const viewsTab = "Views";
  const characteristicsTab = "Characteristics";
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setDisableSave,
    adType,
    clearPreviousStates,
    isInventory,
    isEdit
  } = props;

  const nice2Have = _.get(formValues, "nice2Have", []);
  nice2Have.map(function (item) {
    delete item.text;
    delete item.img;
    delete item.icon;
    return item;
  });
  const nice2HaveInSession = isInventory ? _.get(
    getLocalStorage(INVENTORY_KEY),
    "nice2Have",
    []
  ) : _.get(
    getLocalStorage(WANT_AD_KEY),
    "nice2Have",
    []
  );
  nice2HaveInSession.map(function (item) {
    delete item.text;
    delete item.img;
    delete item.icon;
    return item;
  });

  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const [activeTab, setActiveTab] = useState(viewsTab);
  const [order, setOrder] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState();
  const [itemArray, setItemarray] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [charctrCount, setCharctrCount] = useState(0);
  const MAX_SELECTION_COUNT = 10;

  useEffect(() => {
    getNiceToHaves(isInventory ? INVENTORY_LAND : BUY_LAND)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    if (nice2Have) {
      setItemarray(nice2Have);
      setOrder(nice2Have.length);
    }
  }, []);

  const handleItemOrdering = () => {
    const arrayOfCurrentSelectedItem = itemArray.filter(
      (item) =>
        item.id === convertToNumber(selectedItemId) && item.tab === activeTab
    );
    const selectedItemOrder = arrayOfCurrentSelectedItem
      ? _.get(arrayOfCurrentSelectedItem[0], "order", "")
      : "";

    if (arrayOfCurrentSelectedItem.length === 0) {
      const updatedArray = [
        ...itemArray,
        {
          id: convertToNumber(selectedItemId),
          order: convertToNumber(order),
          tab: activeTab,
        },
      ];
      if (selectedItemId) setItemarray(updatedArray);
    } else {
      const newArray = itemArray.filter(
        (item) => item.order !== convertToNumber(selectedItemOrder)
      );

      const reOrderedArray = newArray.map((ro) => {
        const currentOrder = convertToNumber(_.get(ro, "order", ""));
        if (currentOrder > selectedItemOrder) {
          return {
            id: convertToNumber(ro.id),
            order: convertToNumber(currentOrder) - 1,
            tab: ro.tab,
          };
        } else {
          return {
            id: convertToNumber(ro.id),
            order: convertToNumber(currentOrder),
            tab: ro.tab,
          };
        }
      });

      if (selectedItemId) setItemarray(reOrderedArray);
    }
  };
  useEffect(() => {
    handleItemOrdering();
  }, [selectedItemId, order]);

  useEffect(() => {
    setTotalCount(itemArray.length);
    handleFormValues({ nice2Have: itemArray });
      if (isEdit) {
        if (itemArray?.length > nice2HaveInSession.length) {
          if (
            _.differenceWith(itemArray, nice2HaveInSession, _.isEqual).length <=
            0
          ) {
            setDisableSave(true);
          }  else{
            setDisableSave(false);
          }
        } else{
          if (
            _.differenceWith(nice2HaveInSession, itemArray, _.isEqual).length <=
            0
          ) {
            setDisableSave(true);
          } else{
            setDisableSave(false);
          }
        }        
      }
      else{
        setDisableSave(false);
      }
    
    const views = itemArray.filter(function (co) {
      return co.tab === viewsTab;
    });
    const charctrsc = itemArray.filter(function (co) {
      return co.tab === characteristicsTab;
    });
    setViewCount(views && views.length);
    setCharctrCount(charctrsc && charctrsc.length);
  }, [itemArray]);

  const loadNiceToHaveCharacterisitcs = () => {
    const list = _.get(niceToHavesList, characteristicsTab, []);
    return loadNiceToHaveList(list, characteristicsTab);
  };
  const loadNiceToHaveViews = () => {
    const list = _.get(niceToHavesList, viewsTab, []);
    return loadNiceToHaveList(list, viewsTab);
  };

  const loadNiceToHaveList = (list, tab) => {
    const itemList =
      list &&
      list.map((pl) => {
        const name = _.get(pl, "Text");
        const id = _.get(pl, "Id");
        const imgPath = _.get(pl, "Img");
        const Icon = _.get(pl, "Icon");
        let selectedItems;
        if (tab === viewsTab) {
          selectedItems = itemArray.filter(function (co) {
            return co.tab === viewsTab;
          });
        } else {
          selectedItems = itemArray.filter(function (co) {
            return co.tab === characteristicsTab;
          });
        }

        const filteredArray = selectedItems.filter(function (co) {
          return co.id === id;
        });
        const order = _.get(filteredArray[0], "order", null);
        return (
          <div
            className="col-6 pe-2"
            id={id}
            key={id}
            onClick={handleItemClick}
          >
            <div
              className={
                order
                  ? "property-display mb-3 selected-property"
                  : "property-display mb-3"
              }
            >
              <div className="property-img-wrap">
                {order ? (
                  <div className="fence-icn-count">
                    <span>{order}</span>
                  </div>
                ) : (
                  <div className="fence-icn-wrap">
                    {Icon ? (
                      <img src={require("..//..//../images/" + Icon)} />
                    ) : (
                      <img src={FenceIcon} />
                    )}
                  </div>
                )}

                {imgPath ? (
                  <img src={require("..//..//../images/" + imgPath)} />
                ) : (
                  <img src={PastrolViewIcon} />
                )}
              </div>
              <div className="property-img-info">{name}</div>
            </div>
          </div>
        );
      });
    return itemList;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleItemClick = (e) => {
    const itemId = e.currentTarget.id;
    const isAlreadyExistedItem = itemArray.filter(
      (item) =>
        convertToNumber(item.id) === convertToNumber(itemId) &&
        item.tab === activeTab
    );

    if (
      totalCount + 1 <= MAX_SELECTION_COUNT ||
      isAlreadyExistedItem.length !== 0
    ) {
      setOrder(itemArray.length + 1);
      setSelectedItemId(itemId);
    } else {
      alert("Selection Up to 10 items only");
    }
  };

  return (
    <div className="wantad-nicetohaves-wrap-mobile">
      <Tabs
        id="niceto-have-tab1"
        className="mb-4 flex-row nav-justified"
        onSelect={(tab) => handleTabClick(tab)}
      >
        <Tab
          eventKey={viewsTab}
          title={viewCount && viewCount !== 0 ? `Views(${viewCount})` : "Views"}
        >
          <div className="row">{loadNiceToHaveViews()}</div>
        </Tab>
        <Tab
          eventKey={characteristicsTab}
          title={
            charctrCount && charctrCount !== 0
              ? `Characteristics(${charctrCount})`
              : "Characteristics"
          }
        >
          <div className="row">{loadNiceToHaveCharacterisitcs()}</div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default NiceToHavesLand;
