import React, { useState, useEffect } from "react";
import { getNiceToHaves } from "../../services/commonServices";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FenceIcon from "../../images/fence-icon.svg";
import PastrolViewIcon from "../../images/pastoral-view-img.png";
import { BUY_LAND } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";

const WantAdLandNiceToHaves = (props) => {
  const viewsTab = "Views";
  const characteristicsTab = "Characteristics";
  const { nice2Have, handleContinueButtonState, handleFormValues } = props;
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const [activeTab, setActiveTab] = useState(viewsTab);
  const [order, setOrder] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState();
  const [itemArray, setItemarray] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [charctrCount, setCharctrCount] = useState(0);
  const MAX_SELECTION_COUNT = 10;

  useEffect(() => {
    getNiceToHaves(BUY_LAND)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    if (nice2Have) {
      setItemarray(nice2Have);
      setOrder(nice2Have.length);
    }
  }, []);

  const handleItemOrdering = () => {
    const arrayOfCurrentSelectedItem = itemArray.filter(
      (item) =>
        item.id === convertToNumber(selectedItemId) && item.tab === activeTab
    );
    const selectedItemOrder = arrayOfCurrentSelectedItem
      ? _.get(arrayOfCurrentSelectedItem[0], "order", "")
      : "";

    if (arrayOfCurrentSelectedItem.length === 0) {
      const updatedArray = [
        ...itemArray,
        {
          id: convertToNumber(selectedItemId),
          order: convertToNumber(order),
          tab: activeTab,
        },
      ];
      if (selectedItemId) setItemarray(updatedArray);
    } else {
      const newArray = itemArray.filter(
        (item) => item.order !== convertToNumber(selectedItemOrder)
      );

      const reOrderedArray = newArray.map((ro) => {
        const currentOrder = convertToNumber(_.get(ro, "order", ""));
        if (currentOrder > selectedItemOrder) {
          return {
            id: convertToNumber(ro.id),
            order: convertToNumber(currentOrder) - 1,
            tab: ro.tab,
          };
        } else {
          return {
            id: convertToNumber(ro.id),
            order: convertToNumber(currentOrder),
            tab: ro.tab,
          };
        }
      });

      if (selectedItemId) setItemarray(reOrderedArray);
    }
  };
  useEffect(() => {
    handleItemOrdering();
  }, [selectedItemId, order]);

  useEffect(() => {
    setTotalCount(itemArray.length);
    handleFormValues({ nice2Have: itemArray });
    if (itemArray.length > 0) {
      handleContinueButtonState();
    } else {
      const isDisable = true;
      handleContinueButtonState(isDisable);
    }
    const views = itemArray.filter(function (co) {
      return co.tab === viewsTab;
    });
    const charctrsc = itemArray.filter(function (co) {
      return co.tab === characteristicsTab;
    });
    setViewCount(views && views.length);
    setCharctrCount(charctrsc && charctrsc.length);
  }, [itemArray]);

  const loadNiceToHaveCharacterisitcs = () => {
    const list = _.get(niceToHavesList, characteristicsTab, []);
    return loadNiceToHaveList(list, characteristicsTab);
  };
  const loadNiceToHaveViews = () => {
    const list = _.get(niceToHavesList, viewsTab, []);
    return loadNiceToHaveList(list, viewsTab);
  };

  const loadNiceToHaveList = (list, tab) => {
    const itemList =
      list &&
      list.map((pl) => {
        const name = _.get(pl, "Text");
        const id = _.get(pl, "Id");
        const imgPath = _.get(pl, "Img");
        const Icon = _.get(pl, "Icon");
        let selectedItems;
        if (tab === viewsTab) {
          selectedItems = itemArray.filter(function (co) {
            return co.tab === viewsTab;
          });
        } else {
          selectedItems = itemArray.filter(function (co) {
            return co.tab === characteristicsTab;
          });
        }

        const filteredArray = selectedItems.filter(function (co) {
          return co.id === id;
        });
        const order = _.get(filteredArray[0], "order", null);
        return (
          <div className="col-sm-3" id={id} key={id} onClick={handleItemClick}>
            <div
              className={
                order
                  ? "property-display position-relative mb-4 selected-property"
                  : "property-display position-relative mb-4"
              }
            >
              <div className="property-img-wrap">
                {order ? (
                  <div className="fence-icn-count">
                    <span>{order}</span>
                  </div>
                ) : (
                  <div className="fence-icn-wrap">
                    {Icon ? (
                      <img src={require("..//../images/" + Icon)} />
                    ) : (
                      <img src={FenceIcon} />
                    )}
                  </div>
                )}

                {imgPath ? (
                  <img src={require("..//../images/" + imgPath)} />
                ) : (
                  <img src={PastrolViewIcon} />
                )}
              </div>
              <div className="property-img-info">{name}</div>
            </div>
          </div>
        );
      });
    return itemList;
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleItemClick = (e) => {
    const itemId = e.currentTarget.id;
    const isAlreadyExistedItem = itemArray.filter(
      (item) =>
        convertToNumber(item.id) === convertToNumber(itemId) &&
        item.tab === activeTab
    );

    if (
      totalCount + 1 <= MAX_SELECTION_COUNT ||
      isAlreadyExistedItem.length !== 0
    ) {
      setOrder(itemArray.length + 1);
      setSelectedItemId(itemId);
    } else {
      alert("Selection Up to 10 items only");
    }
  };

  return (
    <div className="center-content-sec mw-100">
      <div className="wantad-nicetohaves-wrap navlink-blue">
        <Tabs
          id="controlled-tab-example1"
          className="mb-5 flex-column flex-sm-row nav-justified"
          onSelect={(tab) => handleTabClick(tab)}
        >
          <Tab
            className="me-0 sm-me-4"
            eventKey={viewsTab}
            title={
              viewCount && viewCount !== 0 ? `Views(${viewCount})` : "Views"
            }
          >
            <div className="row"> {loadNiceToHaveViews()}</div>
          </Tab>

          <Tab
            className="ms-0 sm-ms-4"
            eventKey={characteristicsTab}
            title={
              charctrCount && charctrCount !== 0
                ? `Characteristics(${charctrCount})`
                : "Characteristics"
            }
          >
            <div className="row"> {loadNiceToHaveCharacterisitcs()}</div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
export default WantAdLandNiceToHaves;
