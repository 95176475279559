import React, { useState, useEffect } from "react";

const ClientProfileData = (props) => {
  const { clientDetails} = props;

  const getClientLogo = (firstName, lastName) => {
    let clientName = lastName + ' ' + firstName;
    if (clientName) {
        clientName = clientName.replace(/-/g, "").match(/\b(\w)/g);
    }
    return clientName;
}

  return (
    <div className="client-info">
        <div className="profile-details mb-0">
            <div className="profile-img profile-bg-paleblue flex-shrink-0">{getClientLogo(clientDetails?.FirstName, clientDetails?.LastName)}</div>
            <div className="profile-name">
                <h5>{clientDetails ? (clientDetails?.FirstName + ' ' + clientDetails?.LastName) : ''}</h5>
                <p className="m-0">{clientDetails?.Email}</p>
            </div>
        </div>
        <div className="about">
            {clientDetails?.Add_Info}
        </div>
    </div>
  );
};
export default ClientProfileData;
