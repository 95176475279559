import React, { useState, useEffect } from "react";
import PasscodeLogo from "../images/passcode-logo.svg";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import { getCookie, getLocalStorage, setLocalStorage } from "../helpers/common";
import { ACESS_CODE } from "../constants/common";

const PassCodeRequest = (props) => {

  const [passCode, setPassCode] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate()

  // to check access code
  useEffect(() => {
    if (getCookie(ACESS_CODE) == 'true') {
      navigate(pageURLs.home)
    }
  }, []);

  const handlePassCode = (e) => {
    setErrorMessage('')
    setPassCode(e.target.value.toUpperCase());

  }

  const submitPassCode = () => {
    if (passCode == process.env.REACT_APP_ACCESS_CODE) {
      document.cookie = `${ACESS_CODE}=true`;
      navigate(pageURLs.dashboard)

    } else {
      setErrorMessage('Enter a valid Access Code.')
    }

  }
  return (
    <>
      <div className="outer-wrap position-relative d-flex flex-column mw-100 min-vh-100 mx-auto align-items-center pt-0 pb-0 pe-0 ps-0 passcode-bg">
        <div className="passcode-sec mt-auto mb-auto">
          <div className="passcode-sec-inner text-center">
            <img src={PasscodeLogo} className="mb-3" />
            <span className="error-msg mb-3">{errorMessage}</span>
            <input type="text" className="form-control mb-30" name="passcode" value={passCode}  maxLength={20} placeholder="Enter Access Code" onChange={(e) => handlePassCode(e)} />
            <button className="btn btn-primary w-100" onClick={() => submitPassCode()}>Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PassCodeRequest;
