import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import HouseImg from "../../images/house-img.png";
import noImage from "../../images/no-image-placeholder.png";
import { getLocalStorage } from "../../helpers/common";
import { INVENTORY_KEY, WANT_AD_KEY } from "../../constants/common";

const MyDrafts = (props) => {
  const {
    itemId,
    adType,
    CompletedPercentage,
    latUpdatedDate,
    locationAddress,
    Current_Step,
    clientName,
    isFromWantAd,
    setDraftSelectedItem,
    handleContinueButtonState,
    draftSelectedItem,
    InventoryPhoto,
    section,
    IsFilledByClient,
    IsReadyToReview,
  } = props;

  useEffect(() => {
    let details;
    if (section === "inventory") {
      details = getLocalStorage(INVENTORY_KEY);
    } else {
      details = getLocalStorage(WANT_AD_KEY);
    }

    const curentStep = _.get(details, "actvStep", null);
    if (curentStep === 1 && draftSelectedItem) {
      handleContinueButtonState();
    }
  }, [draftSelectedItem]);

  const getDisplayText = () =>{
    if(IsFilledByClient){
      if(IsReadyToReview){
        return "(Ready to Review)"
      }
      else{
        return "(Pending with Client)"
      }      
    }
    else{
       return CompletedPercentage+"% complete"
    }
  }
  return (
    <>
      {/* <div className="my-drafts-wrap mt-3">
      <a
        href={void 0}
        role="button"
        className="edit-draft-icon"
        onClick={() => {handleDraftItemClick(Current_Step, itemId)}}
      >
        edit
      </a>

      <div className="edit-draft-info" onClick={() => {handleDraftItemClick(Current_Step, itemId)}}>
        <h6>
          {locationAddress ? locationAddress : adType + ' Ad'}  ({CompletedPercentage}% complete)
        </h6>
        <p>Last edited {latUpdatedDate}</p>
      </div>
      <div className="edit-draft-img">
        <img src={HouseImg} />
      </div>
    </div> */}
      <div className="my-draftswrap-sec  mt-3">
        <label class="radio-wrap">
          <input
            name="SELL41"
            type="radio"
            value="false"
            disabled={IsFilledByClient && !IsReadyToReview}
            onChange={() =>
              setDraftSelectedItem({
                selectedId: itemId,
                currentStep: Current_Step,
              })
            }
          />
          <span>
            <div class="timing-wrap-info">
              <h6 class="pb-0">
                {" "}
                {locationAddress ? locationAddress : adType + " Ad"}
                {clientName && isFromWantAd ? ", " + clientName : ""} {getDisplayText()}
              </h6>
              {IsFilledByClient && !IsReadyToReview ? <p> </p>:<p>Last edited {latUpdatedDate}</p>}
            </div>
            {section === "inventory" && (
              <div className="right-img-wrap">
                <img src={InventoryPhoto || noImage} />
              </div>
            )}
          </span>
        </label>
      </div>
      {/* <div className="my-draftswrap-sec mb-3">
    <label class="radio-wrap">
                <input name="SELL41" type="radio" value="false"  />
                  <span>
                    <div class="timing-wrap-info">
                      <h6 class="pb-0">Buy Ad, Client Name (60% complete)</h6>
                      <p>Last edited 02/01/23</p>
                    </div>
                    <div className="right-img-wrap">
                      <img src={HouseImg} />
                    </div> 
                  </span>
               </label>
    </div> */}
    </>
  );
};
export default MyDrafts;
