import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const EmailVerifiedModal = (props) => {
  const { email } = props;
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h2 className="text-center">Email Verified</h2>

        <Row>
          <Col xl={12}>
            <p className="mb-2 text-center">
              Email change request is in progress. New email id has been verified. Please wait 24 hours or confirm the request from your old email.
            </p>
          </Col>
        </Row>
        <Button variant="primary" className="w-100 mb-4" onClick={props.onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default EmailVerifiedModal;
