import * as endPoints from "../constants/endPoints";
import { getUserId,getLocalStorage } from "../helpers/common";
import * as apiService from "./apiService";
import {

  USER_INFO_KEY
} from "../constants/common";
// export async function getWantAds() {
//   return await apiService.apiCall(endPoints.GET_WANT_ADS, "GET", {});
// }

export async function getWantAds(filterQuery) {
  return await apiService.apiCall(endPoints.GET_WANT_ADS, "GET", filterQuery);
}

export async function getPurchaseTypes() {
  return await apiService.apiCall(
    endPoints.GET_PURCHASE_TYPE,
    "GET",
    {}
  );
}

export async function getFinanceType() {
  return await apiService.apiCall(
    endPoints.GET_FINANCE_TYPE,
    "GET",
    {}
  );
}

export async function getRadiusList() {
  return await apiService.apiCall(
    endPoints.RADIUS_LIST_API,
    "GET",
    {}
  );
}


export async function saveMemberWantAd(requestPayload) {
   return await apiService.apiCall(
    endPoints.SAVE_MEMBER_WANT_AD,
    "POST",
    requestPayload
  );
}

export async function saveBuyerListingWantAd(requestPayload) {
  return await apiService.apiCallWithoutToken(
   endPoints.SAVE_BUYER_LISTING_WANTAD,
   "POST",
   requestPayload
 );
}

export async function saveMemberWantAdstrengthenData(requestPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_MEMBER_WANT_AD_STRENGTHN,
    "POST",
    requestPayload
  );
}

export async function saveBuyerListStrengthenWantAd(requestPayload) {
  return await apiService.apiCallWithoutToken(
    endPoints.SAVE_BUYER_LIST_STRENGTHEN_WANTAD,
    "POST",
    requestPayload
  );
}

export async function getWantAdById(wantAdId, userId = null) {
  return await apiService.apiCall(
    endPoints.GET_WANT_ID_BY_ID + `?id=${wantAdId}`,
    "GET",
    {}
  );
}

export async function getBuyerListWantAdById(wantAdId) {
  return await apiService.apiCallWithoutToken(
    endPoints.GET_BUYERLIST_WANTAD_BY_ID + `?id=${wantAdId}`,
    "GET",
    {}
  );
}

export async function getWantAdDetailsById(wantAdId, userId = null) {
  return await apiService.apiCallWithoutToken(
    endPoints.GET_WANT_AD_DETAILS_BY_ID + `?id=${wantAdId}`,
    "GET",
    {}
  );
}

export async function getMyDraft(userId) {
  return await apiService.apiCall(
    endPoints.GET_MY_DRAFT,
    "GET",
    {}
  );
}

export async function getCreditScores() {
  return await apiService.apiCall(
    endPoints.GET_CREDIT_SCORE,
    "GET",
    {}
  );
}

export async function getMyWantAds(requestPayload) {
  return await apiService.apiCall(endPoints.GET_MY_WANT_ADS, "GET", requestPayload);
}

export async function getAgentsList(requestPayload) {
  return await apiService.apiCall(endPoints.GET_AGENTS_LIST, "GET", requestPayload);
}

export async function deleteWantAd(wantAdId) {
  let userId = getUserId();
  return await apiService.apiCall(
    endPoints.DELETE_WANTAD + `?wantAdId=${wantAdId}`,
    "PUT",
    {}
  );
}

export async function archiveWantAd(wantAdId) {
  return await apiService.apiCall(
    endPoints.ARCHIVE_WANTAD + `?wantAdId=${wantAdId}`,
    "PUT",
    {}
  );
}

export async function unArchiveWantAd(wantAdId) {
  return await apiService.apiCall(
    endPoints.UNARCHIVE_WANTAD + `?wantAdId=${wantAdId}`,
    "PUT",
    {}
  );
}

export async function favouriteWantAd(requestPayload) {
  return await apiService.apiCall(
    endPoints.FAVORITE_WANTAD,
    "POST",
    requestPayload
  );
}

export async function unFavouriteWantAd(requestPayload) {
  return await apiService.apiCall(
    endPoints.UNFAVORITE_WANTAD,
    "DELETE",
    requestPayload
  );
}


export async function getFavouriteWantAdList(requestPayload) {
  return await apiService.apiCall(endPoints.FAVORITE_WANTAD_LIST, "GET", requestPayload);
}


export async function getFavClientList(requestPayload) {
  return await apiService.apiCall(endPoints.FAVOURITE_CLIENTS, "GET", requestPayload);
}

export async function unfavouriteAllWantAd(requestPayload) {
  return await apiService.apiCall(
    endPoints.UNFAVORITE_ALL_WANTAD,
    "DELETE",
    requestPayload
  );
}

export async function getGitachGenieMatchedWantAds(filterQuery) {
  return await apiService.apiCall(endPoints.GET_GITCHA_GENIE_MATCHED_WANTADS, "GET", filterQuery);
}

export async function reportWantAd(requestPayload) {
  return await apiService.apiCall(
    endPoints.REPORT_WANT_AD,
    "POST",
    requestPayload
  );
}

export async function getLotSizeList() {
  return await apiService.apiCall(
    endPoints.WANT_AD_LOT_SIZE_LIST_API,
    "GET",
    {}
  );
}

export async function getSquareFeetList() {
  return await apiService.apiCallWithoutToken(
    endPoints.WANT_AD_SQUARE_FEET_LIST_API,
    "GET",
    {}
  );
}

export async function getUserInventoryCount() {
  const userDetails = getLocalStorage(USER_INFO_KEY);
  const userId = _.get(userDetails, "userInfo.userId", null);
  return await apiService.apiCall(endPoints.GET_USER_INVENTORY_COUNT, "GET", {});
}

export async function shareWantAd(payload) {
  return await apiService.apiCall(
    endPoints.SHARE_WANTAD + `?wantAdId=${payload?.wantAdId}&fileName=${payload?.fileName}`,
    "GET",
    {}
  );
}

export async function getBoundaryCoordinates(payload) {
  return await apiService.apiCall(
    endPoints.GET_BOUNDARY_COORDINATES + `?zipcode=${payload?.zipCode}`,
    "GET",
    {}
  );
}
