import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const PropertyPurposeConfirmModal = (props) => {
  const {
    onHide,
    show,
    isConfirmOwner,
    isConfirmPropLoc,
    handleCheckBoxItemClick,
    handleModalConfirmClick,
    isShortRentChecked,
    isDisabled,
    confirmOwnerChkBx,
    confirmPropLocChkBx
  } = props;

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => onHide(false)}
        ></button>

        <h3 className="text-start mb-4">Confirm status</h3>

        <Row>
          <Col xl={12}>
            <div className="confirm-status-wrap pb-2">
              <label className="check-wrap mb-3">
                <input
                  type="checkbox"
                  id={confirmOwnerChkBx}
                  checked={isConfirmOwner}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span>
                  <div className="confirm-status-info">
                    <p>
                      I confirm that I am the owner of this property and/or have
                      authorization from the owner to rent/sub-lease this
                      property.
                    </p>
                  </div>
                </span>
              </label>
              {isShortRentChecked && (
                <label className="check-wrap mb-3">
                  <input
                    type="checkbox"
                    id={confirmPropLocChkBx}
                    checked={isConfirmPropLoc}
                    onChange={(e) => handleCheckBoxItemClick(e)}
                  />
                  <span>
                    <div className="confirm-status-info">
                      <p>
                        I confirm that the property is located in an area that
                        does not have restrictions of Short-term Rentals OR I
                        have a license to rent my property out for short-term
                        stays.
                      </p>
                    </div>
                  </span>
                </label>
              )}
            </div>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap pt-1">
              <Button
                variant=""
                className="btn btn-tertiary me-3"
                onClick={() => onHide(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="btn"
                onClick={() => handleModalConfirmClick()}
                disabled={isDisabled}
              >
                Confirm
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default PropertyPurposeConfirmModal;
