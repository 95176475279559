import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function savePaymentDetails(payload) {
  return await apiService.apiCall(endPoints.SAVE_PAYMENT_DETAILS, "POST", payload);
}

export async function getPaymentMethods(payload) {
    return await apiService.apiCall(endPoints.GET_PAYMENT_METHODS, "GET", payload);
  }

  export async function removePaymentMethod(payload) {
    return await apiService.apiCall(endPoints.REMOVE_PAYMENT_METHOD+ `?PaymentMethodId=${payload.PaymentMethodId}` , "PUT", {});
  }

  export async function updateDefaultPayment(payload) {
    return await apiService.apiCall(endPoints.UPDATE_DEFAULT_PAYMENT+ `?PaymentMethodId=${payload.PaymentMethodId}` , "PUT", {});
  }

  export async function getPaymentHistory(payload) {
    return await apiService.apiCall(endPoints.GET_PAYMENT_HISTORY, "GET", payload);
  }
  

  