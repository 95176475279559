import React, { useState, useEffect } from "react";
import "../../styles/clients.scss";
import Sortoffcanvas from "../../components/Common/Sortoffcanvas";
import { getPaymentHistory } from "../../../services/paymentsService";
import { formateDate, getUserId } from "../../../helpers/common";

const PaymentHistoryListing = (props) => {
  const userId = getUserId();
  const [options, setOptions] = useState([
    { value: "last_30", label: "Last 30 days" },
    { value: "last_quarter", label: "Last quarter" },
    { value: "last_year", label: "Last year" },
    { value: "all_time", label: "All time" },
  ]);
  const [showInventorySort, setShowInventorySort] = useState(false);
  const InventorySortClose = () => setShowInventorySort(false);
  const InventorySortShow = () => setShowInventorySort(true);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(options[0]);
  const [paymentHistory, setPaymenthistory] = useState([]);

  useEffect(() => {
    fetchPaymentHistory();
  }, [selectedTimePeriod, userId]);

  const fetchPaymentHistory = async () => {
    const payload = {
      timePeriod: selectedTimePeriod?.value,
    };
    await getPaymentHistory(payload)
      .then((response) => {
        if (response.errorCode === 0) {
          setPaymenthistory(response?.result);
        } else {
          setPaymenthistory([]);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSelectPeriod = (selectedParam) => {
    setSelectedTimePeriod(selectedParam);
  };

  return (
    <div className="billing-cycle-wrap">
      <Sortoffcanvas
        show={showInventorySort}
        onHide={InventorySortClose}
        sortParam={selectedTimePeriod}
        handleSortParam={handleSelectPeriod}
        options={options}
      />
      <div class="justify-content-between d-flex mb-3">
        <span className="titleSubhead">History</span>
        <button type="button" class="btn btnTop" onClick={InventorySortShow}>
          {selectedTimePeriod.label}{" "}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.64645 5.64645C2.84171 5.45118 3.15829 5.45118 3.35355 5.64645L8 10.2929L12.6464 5.64645C12.8417 5.45118 13.1583 5.45118 13.3536 5.64645C13.5488 5.84171 13.5488 6.15829 13.3536 6.35355L8.35355 11.3536C8.15829 11.5488 7.84171 11.5488 7.64645 11.3536L2.64645 6.35355C2.45118 6.15829 2.45118 5.84171 2.64645 5.64645Z"
              fill="#009CDA"
            />
          </svg>
        </button>
      </div>

      <ul className="historyList">
        {paymentHistory.length > 0 ? (
          paymentHistory.map((paymentData) => {
            return (
              <li className="d-flex justify-content-between">
                <div>
                  {paymentData?.Is_Subs_Intro === 1 ? (
                    <h3>Subscription – {paymentData?.PlanName}</h3>
                  ) : (
                    <h3>Introduction – {paymentData?.Location}</h3>
                  )}

                  <span className="date">
                    {formateDate(paymentData?.BillingDate, "shortMonth")}
                  </span>
                </div>
                <div>${paymentData?.Total}</div>
              </li>
            );
          })
        ) : (
          <p className="text-center mt-3"> 
          {selectedTimePeriod.value === options[0].value ? 'No payments were made in the last 30 days.' :
          selectedTimePeriod.value === options[1].value ? 'No payments were made in the last quarter' :
          selectedTimePeriod.value === options[2].value ? 'No payments were made in the last year.' : 
          'No payments were made.' }</p>
        )}
        {/* 

        <li className="d-flex justify-content-between">
          <div>
            <h3>Introduction - 1234 Gitcha Street, Portland, OR...</h3>
            <span className="date">07/01/2023</span>
          </div>
          <div>$12.99</div>
        </li>

        <li className="d-flex justify-content-between">
          <div>
            <h3>Gitcha Premium Monthly Subscription</h3>
            <span className="date">07/01/2023</span>
          </div>
          <div>$12.99</div>
        </li> */}
      </ul>
    </div>
  );
};

export default PaymentHistoryListing;
