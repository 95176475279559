import React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  EmailShareButton,
  EmailIcon,
  } from "react-share";

  import noImage from "../../images/no-image-placeholder.png";

const ShareLinkModal = (props) => {
  const { show, onHide, image, linkToShare } = props;

  const navigate = useNavigate();
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-custom-width"
        show={show}
        backdrop="static"
      >
        <Modal.Body className="pt-32 pb-32">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => {
              onHide();
            }}
          ></button>
          <h3 className="text-center">
            Your custom link is ready to share!
          </h3>
          <p className="f-size-16 mb-4 text-center">
            Once you create your private viewing link, it will expire after 72 hours. 
            This is to protect the privacy of property owners and their representatives. 
            After the link expires, only you can review the introduction and property details.
          </p>

          <div className=" mb-4 text-center">
            <img src={image ? image : noImage} className="searchImg" />
          </div>

          <Row>
            <Col xl={12} className="text-center">
              <Form.Control
                className="bluetextInput  mb-4"
                value={linkToShare}
              />
            </Col>
          </Row>
          <div className="share-yourad-top align-items-start pt-32 display-center">
            <CopyToClipboard text={linkToShare}>
              <a href={void 0} role="button" className="copy-link" onClick={() => { toast.success("Link copied to clipboard") }}>
                <div className="copy-link-icn">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.8384 5.38078L11.995 7.22414C11.7021 7.51704 11.2272 7.51704 10.9344 7.22414C10.6415 6.93125 10.6415 6.45638 10.9344 6.16348L12.7906 4.30723C12.7988 4.29905 12.8072 4.29106 12.8157 4.28326C13.7442 3.43699 14.9631 2.98102 16.219 3.0101C17.475 3.03918 18.6714 3.55106 19.5597 4.43939C20.4481 5.32772 20.9599 6.52417 20.989 7.78012C21.0181 9.03606 20.5621 10.2549 19.7159 11.1834C19.7078 11.1923 19.6995 11.201 19.691 11.2095L17.0398 13.8513C17.0394 13.8516 17.0391 13.8519 17.0388 13.8523C16.5869 14.3055 16.0501 14.6652 15.459 14.9107C14.8675 15.1564 14.2333 15.2828 13.5928 15.2828C12.9523 15.2828 12.3181 15.1564 11.7266 14.9107C11.1351 14.665 10.5979 14.305 10.1459 13.8513C9.85352 13.5578 9.85441 13.083 10.1478 12.7906C10.4413 12.4983 10.9162 12.4992 11.2085 12.7926C11.5212 13.1065 11.8928 13.3555 12.3019 13.5254C12.7111 13.6954 13.1498 13.7828 13.5928 13.7828C14.0358 13.7828 14.4745 13.6954 14.8837 13.5254C15.2928 13.3555 15.6644 13.1065 15.9771 12.7926L15.979 12.7907L18.6188 10.1603C19.1978 9.51854 19.5094 8.67937 19.4894 7.81483C19.4693 6.94459 19.1146 6.11557 18.4991 5.50005C17.8836 4.88453 17.0545 4.52984 16.1843 4.5097C15.3195 4.48968 14.4802 4.80151 13.8384 5.38078Z"
                      fill="#1272F6"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.4053 10.2173C9.9623 10.2173 9.52362 10.3048 9.11446 10.4747C8.7053 10.6446 8.33372 10.8936 8.02103 11.2075L8.01911 11.2094L5.37938 13.8398C4.80038 14.4816 4.48871 15.3207 4.50872 16.1853C4.52887 17.0555 4.88355 17.8845 5.49907 18.5001C6.11459 19.1156 6.94361 19.4703 7.81386 19.4904C8.6786 19.5104 9.51797 19.1986 10.1598 18.6193L12.0031 16.776C12.296 16.4831 12.7709 16.4831 13.0638 16.776C13.3567 17.0689 13.3567 17.5437 13.0638 17.8366L11.2075 19.6929C11.1994 19.7011 11.191 19.7091 11.1824 19.7169C10.2539 20.5631 9.03509 21.0191 7.77914 20.99C6.52319 20.9609 5.32674 20.449 4.43841 19.5607C3.55009 18.6724 3.0382 17.4759 3.00912 16.22C2.98005 14.964 3.43602 13.7452 4.28228 12.8167C4.29038 12.8078 4.29868 12.7991 4.3072 12.7907L6.95839 10.1488C6.95873 10.1485 6.95907 10.1482 6.95941 10.1478C7.41124 9.69455 7.94807 9.33488 8.53914 9.0894C9.13065 8.84374 9.76484 8.71729 10.4053 8.71729C11.0458 8.71729 11.68 8.84374 12.2715 9.0894C12.863 9.33506 13.4002 9.69509 13.8523 10.1488C14.1446 10.4423 14.1437 10.9171 13.8503 11.2095C13.5569 11.5018 13.082 11.501 12.7896 11.2075C12.477 10.8936 12.1054 10.6446 11.6962 10.4747C11.2871 10.3048 10.8484 10.2173 10.4053 10.2173Z"
                      fill="#1272F6"
                    />
                  </svg>
                </div>
                <span className="copy-link-text text-center">Copy Link</span>
              </a>
            </CopyToClipboard>
            <span className="email-btn-wrap">
              <EmailShareButton className="custom-email-btn" url={linkToShare}>
                <EmailIcon size={32} round resetButtonStyle={false} />
              </EmailShareButton>
            </span>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default ShareLinkModal;
