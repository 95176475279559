import React, { useEffect, useState } from "react";
import CheckBox from "../../Common/CheckBox";
import {
  IS_APARTMENT_CONDO,
  IS_ENTIRE_PLACE,
  IS_HOTEL,
  IS_HOUSE,
  IS_LAND,
  IS_MANUFACTURE,
  IS_MULTI_FAMILY,
  IS_PRIVATE_ROOM,
  IS_TOWNHOME,
  LONG_RENT,
  SELL,
  SHORT_RENT,
} from "../../../constants/common";
import PropertyPurposeConfirmModal from "./PropertyPurposeConfirmModal";
import TextBox from "../../Common/TextBox";
import { removeNegativeInput } from "../../../helpers/validations";
import { checkIsNumber } from "../../../helpers/common";

const InventoryPropertyPurpose = (props) => {
  const {
    propertyPurpose,
    alwLrgDogs,
    alwSmlDogs,
    alwCats,
    alwPets,
    accsPvtBthRm,
    pvtEntrnce,
    handleContinueButtonState,
    handleFormValues,
    clearPreviousStates,
    propertySubType,
    propertyType,
    handleConfirmationModal,
    showConfirmStatuModal,
    isConfirmOwner,
    isConfirmPropLoc,
    noOfBeds,
    isEdit = false,
  } = props;

  const [enableConfirmButton, setConfirmButtonState] = useState(false);
  const alwLrgDogsChkBx = "alwLrgDogs";
  const alwsmlDogsChkBx = "alwSmlDogs";
  const alwCatsChkBx = "alwCats";
  const alwPetsChkBx = "alwPets";
  const accsPvtBthRmchkBx = "accsPvtBthRm";
  const pvtEntrnceChkBx = "pvtEntrnce";
  const confirmOwnerChkBx = "isConfirmOwner";
  const confirmPropLocChkBx = "isConfirmPropLoc";

  const is_Sell_Selected = _.includes(propertyPurpose, SELL);
  const is_LTR_Selected = _.includes(propertyPurpose, LONG_RENT);
  const is_STR_Selected = _.includes(propertyPurpose, SHORT_RENT);

  const LTR_OPTION_DETAILS = [
    { id: alwLrgDogsChkBx, text: "Allows large dogs" },
    { id: alwsmlDogsChkBx, text: "Allows small dogs" },
    { id: alwCatsChkBx, text: "Allows cats" },
  ];
  const STR_OPTION_DETAILS = [
    { id: alwPetsChkBx, text: "Allows pets" },
    { id: accsPvtBthRmchkBx, text: "Access to private bathroom" },
    { id: pvtEntrnceChkBx, text: "Private entrance" },
  ];
  const STR_OPTIONS_PVT_ROOM = [{ id: alwPetsChkBx, text: "Allows pets" }];

  useEffect(() => {
    let isDisable = true;
    if (propertyPurpose && propertyPurpose.length > 0) {
      if (is_STR_Selected) {
        if (noOfBeds) {
          isDisable = false;
        } else {
          isDisable = true;
        }
      } else {
        isDisable = false;
      }
    } else {
      isDisable = true;
    }
    handleContinueButtonState(isDisable);
  }, [propertyPurpose, noOfBeds]);

  useEffect(() => {
    let isDisable = true;
    if (_.includes(propertyPurpose, SHORT_RENT)) {
      if (isConfirmOwner && isConfirmPropLoc) {
        isDisable = false;
        setConfirmButtonState(isDisable);
      } else {
        isDisable = true;
        setConfirmButtonState(isDisable);
      }
    } else {
      if (isConfirmOwner) {
        isDisable = false;
        setConfirmButtonState(isDisable);
      } else {
        isDisable = true;
        setConfirmButtonState(isDisable);
      }
    }
  }, [isConfirmOwner, isConfirmPropLoc]);

  const handlePropertyPurposeSelection = (e) => {
    //clearPreviousStates({ type: "clearBudget" });
    const selectedValue = e.target.value;
    if (!e.target.checked && selectedValue === SELL) {
      clearPreviousStates({ type: "clearSell" });
    }
    if (!e.target.checked && selectedValue === LONG_RENT) {
      clearPreviousStates({ type: "clearPropertyPurposeLTR" });
    } else if (!e.target.checked && selectedValue === SHORT_RENT) {
      clearPreviousStates({ type: "clearPropertyPurposeSTR" });
    }

    const purposeArray = propertyPurpose || [];
    if (selectedValue) {
      let itemArray;
      if (_.includes(purposeArray, selectedValue)) {
        itemArray = purposeArray.filter((e) => e !== selectedValue);
      } else {
        itemArray = [...purposeArray, selectedValue];
      }
      handleFormValues({
        proprtyPurpose: itemArray,
        isConfirmAll: false,
        isConfirmOwner: false,
        isConfirmPropLoc: false,
      });
    }
    setConfirmButtonState(true);
  };

  const handleModalConfirmClick = () => {
    handleConfirmationModal(false);
    handleContinueButtonState();
    handleFormValues({ isConfirmAll: true });
  };

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (id) {
      case alwLrgDogsChkBx:
        handleFormValues({ alwLrgDogs: e.target.checked });
        break;
      case alwsmlDogsChkBx:
        handleFormValues({ alwSmlDogs: e.target.checked });
        break;
      case alwCatsChkBx:
        handleFormValues({ alwCats: e.target.checked });
        break;
      case alwPetsChkBx:
        handleFormValues({ alwPets: e.target.checked });
        break;
      case accsPvtBthRmchkBx:
        handleFormValues({ accsPvtBthRm: e.target.checked });
        break;
      case confirmOwnerChkBx:
        handleFormValues({ isConfirmOwner: e.target.checked });
        break;
      case confirmPropLocChkBx:
        handleFormValues({ isConfirmPropLoc: e.target.checked });
        break;
      case pvtEntrnceChkBx:
        handleFormValues({ pvtEntrnce: e.target.checked });
        break;
    }
  };

  const handleNoOfBedsChange = (e) => {
    let value = removeNegativeInput(e.target.value);
    if (value === "0") {
      value = "";
    } else if (value > 100) {
      alert("Maximum limit is 100");
      value = 100;
    }

    if (checkIsNumber(value)) {
      handleFormValues({ noOfBeds: value });
    }
  };

  const loadCheckBoxItems = (list) => {
    const List = list.map((pt) => {
      const id = _.get(pt, "id", "");
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case alwLrgDogsChkBx:
          isChecked = alwLrgDogs;
          break;
        case alwsmlDogsChkBx:
          isChecked = alwSmlDogs;
          break;
        case alwCatsChkBx:
          isChecked = alwCats;
          break;
        case alwPetsChkBx:
          isChecked = alwPets;
          break;
        case accsPvtBthRmchkBx:
          isChecked = accsPvtBthRm;
          break;
        case pvtEntrnceChkBx:
          isChecked = pvtEntrnce;
          break;
      }
      return (
        <label className="form-check-label" htmlFor={id} key={id}>
          <input
            className="form-check-input"
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <div className="label-inner-wrap">
            <p>{text}</p>
          </div>
        </label>
      );
    });

    return List;
  };

  const isShowSelling =
    (propertyType === IS_ENTIRE_PLACE &&
      (propertySubType === IS_HOUSE ||
        propertySubType === IS_APARTMENT_CONDO ||
        propertySubType === IS_TOWNHOME ||
        propertySubType === IS_MANUFACTURE ||
        propertySubType === IS_MULTI_FAMILY)) ||
    propertyType === IS_LAND;

  const isShowLTR =
    (propertySubType !== IS_MULTI_FAMILY && propertySubType !== IS_HOTEL) ||
    propertyType === IS_LAND;
  const isShowSTR =
    propertySubType !== IS_MULTI_FAMILY || propertyType === IS_LAND;
  return (
    <>
      <PropertyPurposeConfirmModal
        show={showConfirmStatuModal}
        isShortRentChecked={
          propertyPurpose && _.includes(propertyPurpose, SHORT_RENT)
        }
        onHide={handleConfirmationModal}
        isConfirmOwner={isConfirmOwner}
        isConfirmPropLoc={isConfirmPropLoc}
        handleCheckBoxItemClick={handleCheckBoxItemClick}
        handleModalConfirmClick={handleModalConfirmClick}
        isDisabled={enableConfirmButton}
        confirmOwnerChkBx={confirmOwnerChkBx}
        confirmPropLocChkBx={confirmPropLocChkBx}
      ></PropertyPurposeConfirmModal>
      <div className="center-content-sec mw-444">
        {isShowSelling && (
          <div
            className={
              is_Sell_Selected
                ? "find-renters-wrap mb-3 active"
                : "find-renters-wrap mb-3"
            }
          >
            <label className="form-check-label">
              <CheckBox
                class="form-check-input"
                name="pst-checkbox"
                value={SELL}
                checked={is_Sell_Selected}
                onChange={(e) => handlePropertyPurposeSelection(e)}
              />
              <span>
                <div className="wantad-type-info">
                  <h6 className="pb-0">Find buyers</h6>
                  <p>
                    With the right offer, you'd consider selling your property.
                  </p>
                </div>
              </span>
            </label>
          </div>
        )}

        {isShowLTR && (
          <div
            className={
              is_LTR_Selected
                ? "find-renters-wrap mb-3 active"
                : "find-renters-wrap mb-3"
            }
          >
            <label className="form-check-label" htmlFor="flexCheckDefault718">
              <CheckBox
                class="form-check-input"
                name="pst-checkbox"
                value={LONG_RENT}
                id="flexCheckDefault718"
                checked={is_LTR_Selected}
                onChange={(e) => handlePropertyPurposeSelection(e)}
              />
              <div className="label-wrap">
                <h6>Find renters</h6>
                <p>
                  You'd rent out your property to someone for greater than 30
                  days.
                </p>
              </div>
            </label>
            {is_LTR_Selected && (
              <div className="find-renters-inner-wrap">
                <h6>Details:</h6>
                {loadCheckBoxItems(LTR_OPTION_DETAILS)}
              </div>
            )}
          </div>
        )}

        {isShowSTR && (
          <div
            className={
              is_STR_Selected
                ? "find-renters-wrap mb-3 active"
                : "find-renters-wrap mb-3"
            }
          >
            <label className="form-check-label" htmlFor="flexCheckDefault7189">
              <CheckBox
                class="form-check-input"
                name="pst-checkbox"
                value={SHORT_RENT}
                id="flexCheckDefault7189"
                checked={is_STR_Selected}
                onChange={(e) => handlePropertyPurposeSelection(e)}
              />
              <div className="label-wrap">
                <h6>Find short-term renters</h6>
                <p>
                  You'd rent out your property to someone for less than 30 days.
                </p>
              </div>
            </label>

            {is_STR_Selected && (
              <>
                <div className="st-rent-bedinfo pl-37 mt-15">
                  <label>
                    Number of beds: <span>*</span>
                  </label>

                  <TextBox
                    class="form-control"
                    name="noOfBeds"
                    type="text"
                    value={noOfBeds || ""}
                    placeholder="0"
                    onChange={(e) => {
                      handleNoOfBedsChange(e);
                    }}
                  />
                </div>
                <div className="find-renters-inner-wrap">
                  <h6>Details:</h6>
                  {loadCheckBoxItems(
                    propertyType === IS_PRIVATE_ROOM
                      ? STR_OPTIONS_PVT_ROOM
                      : STR_OPTION_DETAILS
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default InventoryPropertyPurpose;
