import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import TutorialCarouselImage1 from "../images/inventory_tutorial_carousel_img_1.png";
import TutorialCarouselImage2 from "../images/inventory_tutorial_carousel_img_2.png";
import TutorialCarouselImage3 from "../images/inventory_tutorial_carousel_img_3.png";
import TutorialCarouselImage4 from "../images/inventory_tutorial_carousel_img_4.png";
import TutorialCarouselImageAgent1 from "../images/addproperty-tutorial-img.png";
import { Autoplay, Pagination } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { getLocalStorage, getUserType, removeLocalStorage, saveTutorialStatusToLocal, setBodyBackgroundDarkGrey } from "../../helpers/common";
import { saveTutorialStatus } from "../../services/commonServices";
import { pageURLs } from "../../constants/pageURLs";
import { USER_TYPE_AGENT } from "../../constants/common";
const InventoryTutorialSetup = () => {
  const pagination = {
    clickable: true,
    
  };
  const tutorialPage = 7;

  const userType = getUserType();
  const isAgent = userType === USER_TYPE_AGENT ? true : false;
  let tutorialOnSignUp = getLocalStorage('tutorialOnSignUp')
  const navigate = useNavigate();
  useEffect(() => {
    saveTutorialStatusToLocal(tutorialPage);
    saveTutorialToDb();    
  }, []);
  const saveTutorialToDb = async () => {
      await saveTutorialStatus(tutorialPage, 1); //Save status
  }
const redirectToPage = async (pageName) => {
  removeLocalStorage('tutorialOnSignUp')
  if(pageName == 'inventoryadd') {
    navigate(pageURLs.mobileCreateInventory);
  } else {
    navigate(pageURLs.mobileHome);
  }
}

useEffect(() => {
  setBodyBackgroundDarkGrey()
}, []);

  return (
    <div className="tutorial-slider">
        <div className="signup-login-header w-100">
            <div className="signup-login-header-top clearfix">
                <a className="back-link" href={void 0} onClick={()=>redirectToPage('home')}>Back</a>
            </div>
        </div>
        <div className="inner-layout-content">
          <div className="welcome-header">
            <h2>Let’s add your property</h2>
          </div>
          <div>
              <Swiper
                  pagination={pagination}
                  modules={[Pagination]}
                  className="mySwiper"
                  rewind={true}
                  autoplay={{
                      delay: 800,
                      disableOnInteraction: false,
                  }}
              >
                  <SwiperSlide>
                      <img src={isAgent ? TutorialCarouselImageAgent1 : TutorialCarouselImage1} alt={isAgent ? TutorialCarouselImageAgent1 : TutorialCarouselImage1} />
                      <div className="slider-content">
                        <h6>Connect easily & effectively</h6>
                        <p className="m-0">
                            Avoid fruitless showings & days on market - directly connect with those that are looking for what you have!
                        </p>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <img src={TutorialCarouselImage2} alt={TutorialCarouselImage2} />
                      <div className="slider-content">
                        <h6>Measure market demand</h6>
                        <p className="m-0">
                            {isAgent ?
                            "Whether it’s on the public market or not, load your client’s property to gauge real-time interest, and Gitcha will send you want-ad matches." :
                            "Load your property to gauge interest, and we’ll send you want-ad matches. A public listing isn’t necessary!"}
                        </p>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <img src={TutorialCarouselImage3} alt={TutorialCarouselImage3} />
                      <div className="slider-content">
                        <h6>Enjoy some privacy</h6>
                        <p className="m-0">
                            Any property you load into your inventory is private and will only be seen by those you decide to introduce it to.
                        </p>
                      </div>
                  </SwiperSlide>
                  <SwiperSlide>
                      <img src={TutorialCarouselImage4} alt={TutorialCarouselImage4} />
                      <div className="slider-content">
                        <h6>Find the fit</h6>
                        <p className="m-0">
                            Want-Ads will often list additional details, like timeline, financing, and budget specifics - we’ll help you find the right fit{isAgent ? " for your client" : ""}!
                        </p>
                      </div>
                  </SwiperSlide>
              </Swiper>
          </div>
          <div className="btn-wrap">
              <button type="button" class="btn btn-primary w-100" onClick={()=>redirectToPage('inventoryadd')}>Get Started</button>
          </div>
        </div>
    </div>
  );
};

export default InventoryTutorialSetup;
