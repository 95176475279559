import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCardIcon, getUserId } from "../../../helpers/common";
import { removePaymentMethod } from "../../../services/paymentsService";

const RemoveVisaCardModal = (props) => {
  const { show, onHide, paymentMethod, updateReloadParam } = props;

  const userId = getUserId();
  const handleRemoveCard = async () => {
    const payload = {
      PaymentMethodId: paymentMethod?.Payment_Mode_Id,
    };
    await removePaymentMethod(payload)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.dismiss();
          toast.success("Payment method removed");
          onHide();
          setTimeout(() => {
            updateReloadParam();
          }, 500);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="modalPopupstyle delete-popup"
    >
      <Modal.Body className="text-center">
        <h3>
          Are you sure you want to remove {paymentMethod?.Brand} ending in{" "}
          {paymentMethod?.Last4}?
        </h3>
        <p>Once deleted, this action cannot be undone.</p>
        <div className="btn-wrap">
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={handleRemoveCard}
          >
            Remove Card
          </button>
          <button
            type="button"
            className="btn btn-secondary w-100"
            onClick={onHide}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RemoveVisaCardModal;
