import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const EmailChangeRevokedModal = (props) => {
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h2 className="text-center">Email Address Change Revoked</h2>
        <Row>
          <Col xl={12}>
            <p className="mb-2 text-center">
              Email address change has been revoked. Please login using the
              previous email address.
            </p>
          </Col>
        </Row>
        <Button variant="primary" className="w-100 mb-4" onClick={props.onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default EmailChangeRevokedModal;
