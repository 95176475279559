import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const EmailChangeConfirmedModal = (props) => {
  const { email, emailNotVerified } = props;
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h2 className="text-center">Email Address Change Confirmed</h2>
        <Row>
          <Col xl={12}>
            {emailNotVerified===true ?
            <p className="mb-2 text-center">
              We've sent a verification link to your new email account. Please verify to login using the new email address.
            </p> : <p className="mb-2 text-center">
              Email address change has been confirmed. Now you can login using
              the new email address.
            </p> }            
          </Col>
        </Row>
        <Button variant="primary" className="w-100 mb-4" onClick={props.onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default EmailChangeConfirmedModal;
