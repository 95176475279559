import React, { useState, useEffect, useRef } from "react";
import ConfirmMap from "../images/confirm-map.png";
import LakeViewIcon from "../images/lake-view-icon.svg";
import ConsumerIcon from "../images/consumer-icon.svg";
import { getUserId, setLocalStorage, loadTimeLineHtml, loadPreferredLocationHtml, clearLocalStorageOnLogout, loadCompensationText, locationDisplay } from "../helpers/common";
import { getNiceToHaves } from "../services/commonServices";
import { BUY, LONG_RENT, SHORT_RENT, LOT_LAND, BUY_LAND, AGENT_WANTAD_KEY, IS_LAND, WANT_AD_KEY, IS_MULTI_FAMILY } from "../constants/common";
import { useNavigate } from "react-router-dom"
import CommonHeader from "../components/Onboarding/CommonHeader";
import { saveMemberWantAd, saveMemberWantAdstrengthenData } from "../services/wantAdService";
import { pageURLs } from "../constants/pageURLs";
import { convertToNumber } from "../helpers/validations";
import FinancingIcon from "../images/financing-icon.svg";
import PrResIcon from "../images/primary-residence-icon.svg";
import { buildWantAdMustHavesHTML } from "../helpers/createWantAd";
import Tooltip from "react-bootstrap/Tooltip";
import { getCombinedText, getPropertyIcon } from "../helpers/getPropertyIcons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { userLogout } from "../reducers/login";
import MinCapIcon from "../images/minimum-icn.svg";

const AgentWantAdConfirmation = (props) => {
  const navigate = useNavigate()
  const formValues = JSON.parse(localStorage.getItem(AGENT_WANTAD_KEY)); // to set the want-ad data
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const adType = _.get(formValues, "adType", "");
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const wantAdId = convertToNumber(_.get(formValues, "wantAdId", ""));
  const mapImage = _.get(formValues, 'mapImg', null)
  const adTypeBasedOnProperty = propertyType === IS_LAND ? LOT_LAND : adType;
  const pageTopRef = useRef(null);
  const propertySubTypes = _.get(formValues, "prptySubTypes", []);

  useEffect(() => {
    window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section
    getNiceToHaves(adTypeBasedOnProperty === LOT_LAND ? BUY_LAND : adTypeBasedOnProperty)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const loadEachNiceToHave = (section) => {
    const nice2Have = _.get(formValues, "nice2Have", []);
    const selectedItems = nice2Have && nice2Have.filter((item) => item.tab === section);
    const masterArray = _.get(niceToHavesList, section, []);
    return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = (adTypeBasedOnProperty === LOT_LAND) ?
      ['Views', 'Characteristics']
      : ['Property', 'Layout', 'Interior', 'Exterior', 'Other']
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh)
      });
    return finalList
  };

  const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        const icon = _.get(masterItem[0], "Icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueKey}>
            <div className="nicetohaves-tag-icon">
              <img
                src={icon ? require("../images/" + icon) : LakeViewIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const niceToHaves = finalNiceToHaveList()
  const isItemNotExist = /^,+$/.test(niceToHaves);

  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }
    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );

  };

  const loadStrengthenDetails = () => {
    if (adType === BUY) {
      return (
        <>
          {" "}
          {(_.get(formValues, "prchTypeTxt", "") ||
            _.get(formValues, "purchaseText", "")) && (
              <li>
                <span className="list-icn-wrap">
                  <img src={PrResIcon} />
                </span>
                { _.get(formValues, "purchaseText", "") ||
                 _.get(formValues, "prchTypeTxt", "")}
                   {(_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
              </li>
            )}
          {(_.get(formValues, "finTypeTxt", "") ||
            _.get(formValues, "pFinanceText", "")) && (
              <li>
                <span className="list-icn-wrap">
                  <img src={FinancingIcon} />
                </span>
                Financing:{" "}
                {_.get(formValues, "finTypeTxt", "") ||
                  _.get(formValues, "pFinanceText", "")}
                {(_.get(formValues, "isPreApprLoan", "") == "Yes" || _.get(formValues, "isPreApprLoan", "") == 1) &&
                  " - pre approved"
                }
              </li>
            )}
        </>
      );
    } else if (adType === LONG_RENT) {
      return (
        <>
          {" "}
          {_.get(formValues, "creditScoreText", "") && (
            <li>
              <span className="list-icn-wrap">
                <img src={FinancingIcon} />
              </span>
              Credit Score : {_.get(formValues, "creditScoreText", "")}
            </li>
          )}
        </>
      );
    }
  };

  const loadTimeLine = () => {
    return loadTimeLineHtml(adType, adTypeBasedOnProperty, formValues);
  };

  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(formValues)
  }
  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({ adType: adType, adTypeBasedOnProperty: adTypeBasedOnProperty, formValues: formValues })
  };

  const handleStateClear = () => {
    // alert("Your session has expired. Please login to continue.");
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate(pageURLs.home);
  };

  const saveMemberWantAdData = async (isPostAd) => {
    let updatedState = { pTypeTxt: _.get(formValues, "prptyTypeTxt", ""), adType: _.get(formValues, "adType", "") + '_STRENGTHEN' };
    let userId = getUserId();
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = JSON.parse(localStorage.getItem("agentWantAdData"));
    }
    if (userId && requestPayLoad) {
      requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
      if (isPostAd) {
        requestPayLoad.IsSavedDraft = false;
        requestPayLoad.IsPublished = true;
        delete requestPayLoad.tlText;
        delete requestPayLoad.prptyTypeTxt;
        delete requestPayLoad.wantAdValErrors;
      } else {
        requestPayLoad.IsSavedDraft = true;
        requestPayLoad.IsPublished = false;
      }
      let arrayValue = [];
      if(requestPayLoad?.zipIdValues){
        requestPayLoad?.zipIdValues.map((zip)=>{
          arrayValue.push(zip?.zipId)
          })
          requestPayLoad.zipId = arrayValue;
      }  else if(requestPayLoad?.zipCode){
        requestPayLoad?.zipCode.map((zip)=>{
          if(zip?.zipId && zip?.zipId != null){
            arrayValue.push(zip?.zipId)
          }
        })
        requestPayLoad.zipId = arrayValue;
    }
      requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
      saveMemberWantAd(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (isPostAd) {
              if (requestPayLoad.purchaseType || requestPayLoad.creditScore || requestPayLoad.prchTypeId  || requestPayLoad.pFinance) {
                saveStrengthenData(_.get(responseResult, "wantAdId", "") ? _.get(responseResult, "wantAdId", "") : _.get(formValues, "wantAdId", ""), isPostAd);
              } else {
                updatedState.wantAdId = _.get(responseResult, "wantAdId", "") ? _.get(responseResult, "wantAdId", "") : _.get(formValues, "wantAdId", "");
                updatedState.minBdgt = _.get(formValues, "minBdgt", "");
                updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");

                setLocalStorage(WANT_AD_KEY, updatedState);
                localStorage.removeItem(AGENT_WANTAD_KEY);
                navigate(pageURLs.wantAdDetails);
              }


            } else {
              if (requestPayLoad.purchaseType || requestPayLoad.creditScore || requestPayLoad.prchTypeId || requestPayLoad.pFinance ) {
                saveStrengthenData(_.get(responseResult, "wantAdId", "") ? _.get(responseResult, "wantAdId", "") : _.get(formValues, "wantAdId", ""), isPostAd);
              } else {
                localStorage.removeItem(AGENT_WANTAD_KEY);
                navigate(pageURLs.wantAdListing, { state: { showDraft: true } })
              }
            }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };


  const saveStrengthenData = async (wantAdIdValue, isPostAd) => {
    const wantAdId = wantAdIdValue;
    let updatedState = {
      pTypeTxt: _.get(formValues, "prptyTypeTxt", ""),
      adType: _.get(formValues, "adType", "") + '_STRENGTHEN'
    };
    let requestPayLoad = {};
    let isApproveLoan = ""
    if (_.get(formValues, "isPreApprLoan", null) === 'Yes' || _.get(formValues, "isPreApprLoan", null) == true) {
      isApproveLoan = true
    } else if (_.get(formValues, "isPreApprLoan", null) === 'No' || _.get(formValues, "isPreApprLoan", null) == true) {
      isApproveLoan = false
    }
    requestPayLoad.isPreApprLoan = isApproveLoan;
    const is1031Exchange = (_.get(formValues, "is1031Exchange", "") == "Yes" ||  _.get(formValues, "is1031Exchange", "") == 1) ? true : false;
    requestPayLoad.wantAdId = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.completedStep = 1;
    requestPayLoad.adType = _.get(formValues, "adType", "") == BUY ? 'BUY_STRENGTHEN' : 'LTR_STRENGTHEN';
    requestPayLoad.creditScore = _.get(formValues, "creditScore", "");
    requestPayLoad.maxBdgt = _.get(formValues, "maxBdgt", "");
    requestPayLoad.minBdgt = _.get(formValues, "minBdgt", "");
    requestPayLoad.pFinance = _.get(formValues, "pFinance", "") || _.get(formValues, "finTypeId", "");
    requestPayLoad.purchaseType = _.get(formValues, "purchaseType", "") || _.get(formValues, "prchTypeId", "");
    await saveMemberWantAdstrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);

        if (response.errorCode === 0 && responseResult) {
          updatedState.wantAdId = wantAdId;
          if (isPostAd) {
            updatedState.wantAdId = wantAdId;
            updatedState.minBdgt = _.get(formValues, "minBdgt", "");
            updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");

            setLocalStorage(WANT_AD_KEY, updatedState);
            localStorage.removeItem(AGENT_WANTAD_KEY);
            navigate(pageURLs.wantAdDetails);
          } else {
            localStorage.removeItem(AGENT_WANTAD_KEY);
            navigate(pageURLs.wantAdListing, { state: { showDraft: true } })
          }

        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
{propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY  ? 
      getTooltipText(propertyType) 
      : propertySubTypes &&
        propertySubTypes.length > 0 &&
        getCombinedText(propertySubTypes)}
    </Tooltip>
  );

  return (
    <div className="outer-wrap position-relative" ref={pageTopRef}>
      <CommonHeader />
      <div className="row">
        <div className="col-md-12">

          <div className="ready-to-post-wrap mw-724">
            <div className="ready-to-post-top d-sm-flex justify-content-between">
              <div>
                {/* {wantAdId &&  _.get(formValues, "isEdit", '') == true ?
                  (
                    <h2>Ready to save changes?</h2>

                  ) : (
                    <h2>Ready to post?</h2>
                  )} */}
                   <h2>Ready to post?</h2>
                {

                  _.get(formValues, "isAgentAsSeller", '') == 'true' || _.get(formValues, "isAgentAsSeller", '') == 1 ?
                    <h6>Represented by Agent</h6>
                    
                    :
                    <h6 className="long-txt-fix">Client: {_.get(formValues, "clientName", '') ? _.get(formValues, "clientName", '') : _.get(formValues, "clientData.label", '')} <span className="long-txt-fix">(Only visible to you)</span></h6>
                }

              </div>
              <div className="d-block mt-3 mt-sm-0">
                {
                  _.get(formValues, "isPublished", '') == 0 && (
                    <button className="btn block-link-btn me-2" onClick={() => saveMemberWantAdData(false)}>Save Draft</button>
                  )
                }

                {/* <button className="btn btn-primary" onClick={() => saveMemberWantAdData(true)}>{wantAdId && _.get(formValues, "isEdit", '') == true && (_.get(formValues, "IsPublished", '') == true || _.get(formValues, "isPublished", '') == true )    ? "Save" : "Post"}</button> */}
              <button className="btn btn-primary" onClick={() => saveMemberWantAdData(true)}>Post</button> 
              </div>
            </div>
            <div className="ready-to-post-bottom">

              <div className="confirmation-top-sec d-md-flex justify-content-between">
                <div>
                  <div className="confirm-sub-title mb-1">
                    I'm looking to:
                    {loadAdTitle()}
                  </div>
                  <h2 className="long-txt-fix">
                    {_.get(formValues, "adTitle", "")}{" "}
                  </h2>
                  <span className="trailing-txt long-txt-fix">
                    {locationDisplay(formValues)}
                  
                  </span>
                </div>
                <button
                  type="button"
                  className="btn btn-outline-primary align-self-start"
                  onClick={() => navigate("/agent-edit-want-ad", {state:{isFromConfirmationPage: true}})}
                >
                  Edit
                </button>
              </div>


              <div className="confirm-map-wrap ">
                <OverlayTrigger
                  placement="bottom-start"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <div className="property-tile w-auto">
                    {propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY  ? (
                          <img
                            src={getPropertyIcon(propertyType)}
                            alt={getPropertyIcon(propertyType)}
                            className="property-icon-img"
                          />
                        ) : (
                      propertySubTypes &&
                      propertySubTypes.length > 0 &&
                      propertySubTypes.map((subtype) => {
                        return (
                          <img
                            src={getPropertyIcon(subtype)}
                            alt={getPropertyIcon(subtype)}
                            className="property-icon-img"
                          />
                        );
                      })
                        )
                    }


                  </div>
                </OverlayTrigger>
                <img src={mapImage + "?" + Math.random().toString(36) || ConfirmMap + "?" + Math.random().toString(36)} className="disp-map-img-fixed maxh-300" />
              </div>

              {loadAdMustHaves()}
              {!isItemNotExist ? <div className="nicetohaves-wrap">
                <h2>Nice-to-haves</h2>
                <div className="nicetohaves-inner-wrap">{finalNiceToHaveList()}</div>
              </div> : ''}
              {_.get(formValues, "adDesc", "") ? <div className="additional-details-wrap">
                <h2>Description</h2>
                <p className="long-txt-fix">{_.get(formValues, "adDesc", "")}</p>
              </div> : ''}


              <div className={(adType == BUY || _.get(formValues, "prprtyType", "") == IS_LAND) && _.get(formValues, "compenationType", "") ? "buyer-info nicetohaves-wrap": "buyer-info"}>
                <h2>Additional details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap">
                      <img src={ConsumerIcon} />
                    </span>
                    Represented by Agent

                  </li>
                  {loadTimeLine()}
                  {loadPreferredLocation()}
                  {loadStrengthenDetails()}
                </ul>
              </div>

              {(adType == BUY || _.get(formValues, "prprtyType", "") == IS_LAND) && (_.get(formValues, "compenationType", "") || _.get(formValues, "otherConcessions", "")) ? <div className="buyer-info">
                <h2>Requested Concessions From Seller</h2>
                <ul>                  
                  {(_.get(formValues, "compenationType", "")) ?
                    
                    (<li>
                      <span className="list-icn-wrap">
                        <img src={FinancingIcon} />
                      </span>
                      {loadCompensationText(parseInt(_.get(formValues, "compenationType", "")),_.get(formValues, "agentCompenation", ""))}
                     
                    </li>
                    ) :""
                  }               
                  {(_.get(formValues, "otherConcessions", "")) ?
                    
                    (<li>                      
                      {_.get(formValues, "otherConcessions", "")}                    
                    </li>
                    ) :""
                  }  
                </ul>
              </div> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgentWantAdConfirmation;
