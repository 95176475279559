/**
 * @file   src\components\Onboarding\Dashboard\PropertyListing.js
 * @brief  This file is responsible for showing each want ad in want ad listing page
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MapImage from "../../../images/map-image.png";
import HearttIcon from "../../../images/heart-icon.svg";
import HearttIconFilled from "../../../images/heart-icon-filled.svg";
import ShareIcon from "../../../images/share-icon.svg";
import LakeViewIcon from "../../../images/lake-view-icon.svg";
import SmallHomeIcon from "../../../images/small-home-icon.svg";
import LotIcon from "../../../images/lot-icon.svg";
import {
  loggedInUser,
  getLocalStorage,
  formateDate,
  durationForFlexibleDate,
  monthNameForFlexibleDate,
  getUserId,
  removeDecimalZeros,
  getUserType,
  locationDisplay,
} from "../../../helpers/common";
import { useSearchParams } from "react-router-dom";
import {
  ADTYPE_LTR,
  ADTYPE_STR,
  IN_CITY_LIMITS,
  RURAL,
  BUY,
  USER_INFO_KEY,
  LOT_LAND,
  BUDGET_NOMIN_NOMAX
} from "../../../constants/common";
import AddToClientWantAdModal from "../../MemberWantAd/AddToClientWantAdModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { setLotSizeValue } from "../../../helpers/createWantAd";
import { getAgentsList, getWantAdById } from "../../../services/wantAdService";
import { getPropertyIcon, getTooltipText , getCombinedText} from "../../../helpers/getPropertyIcons";
import { wantAdBudgetValue } from "../../../helpers/createWantAd";
import ShareYourAdModal from "../../MemberWantAd/ShareYourAdModal";
import { useSelector } from "react-redux";


const PropertyListing = ({
  handleFavoriteOrUnfavoriteWantAd,
  onChangeText,
  onAddClient,
  handleWantAdItemClick,
  ...props
}) => {
  const [verifyUser, setVerifyUser] = useSearchParams();
  const isUserVerified = verifyUser.get("verify");
  const [isFavourite, setIsFavourite] = useState(0);
  const [wantAdId, setWantAdId] = useState(0);
  const [modalShow, setModalShow] = useState(false); //
  const user = loggedInUser();
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const {
    Title_Text,
    Location,
    Bedrooms,
    Bathrooms,
    Budget_mn,
    Budget_mx,
    Sqft_mn,
    Sqft_mx,
    N2H,
    isLotLand,
    LotType_Rural_City,
    TotalAcre_mn,
    TotalAcre_mx,
    LotSz_mn,
    LotSz_mx,
    LotSz_ValTxt_Mx,
    LotSz_ValTxt_Mn,
    AdType,
    Want_ad_Id,
    IsFavourite,
    Location_Img,
    PropertyTypeId,
    subPropertyTypeId,
    Date_From,
    Date_To,
    DateType,
    Duration,
    Month,
    City,
    State,
    mapRadius,
    Latitude,
    CreatedBy
  } = props && props.list;

  const [showShareModal, setShareModal] = useState(false);
  const handleClose = () => setShareModal(false);
  const [selectedWantAd, setSelectedWantAd] = useState({})
  const loggedInUserId = getUserId();
  const [isPremiumUser , setIsPremiumUser] = useState(null);
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)
  const userType = getUserType();

  let budget = wantAdBudgetValue(Budget_mn,Budget_mx)
  if(budget != BUDGET_NOMIN_NOMAX){
    if (AdType === ADTYPE_LTR) {
      budget = budget + " /Month";
    } else if (AdType === ADTYPE_STR) {
      budget = budget + " /Night";
    }
  }


  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } 
  }, [userDetailsFromStore]);

  /**
   * To display nice to haves in want ad tile
   * @returns 
   */
  const loadNiceToHaveList = () => {
    // let count = 0;
    return (
      N2H &&
      N2H.map((nh, index) => {
        // if (count <= 3) {
          // count++;
          const id = _.get(nh, "N2H_Id");
          const itemText = _.get(nh, "Text", "");
          const icon = _.get(nh, "Icon", "");
          return (
            <div
              className="inner-slider-wrap"
              key={index + "_" + _.get(nh, "Want_ad_Id", "")}
            >
              <div className="property-tag mb-0">
                <div className="tag-icon">
                  <img
                    src={icon ? require("../../../images/" + icon) : LakeViewIcon}
                  />
                </div>
                <span>{itemText}</span>
              </div>
            </div>            
          );      
      })
    );
  };
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    swipeToSlide: true,
    // slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  };

  /**
   * To display must haves in want ad tile
   * @returns 
   */
  const loadMustHaves = () => {
    if (isLotLand) {
      if (LotType_Rural_City) {
        return (
          <div className="property-details">
            {LotType_Rural_City === 1 ? RURAL : IN_CITY_LIMITS}{" "}
            <span className="details-text">lot type</span>
            <span className="vertical-divider">|</span>
            {setLotSizeValue(removeDecimalZeros(LotSz_mn), removeDecimalZeros(LotSz_mx))}{" "}
            {setLotSizeValue(removeDecimalZeros(LotSz_mn), removeDecimalZeros(LotSz_mx))==='Any' ? <span className="details-text">lot size</span> : <></>}
          </div>
        );
      } else {
        return (
          <div className="property-details">
             {setLotSizeValue(removeDecimalZeros(LotSz_mn), removeDecimalZeros(LotSz_mx))}{" "}
             {setLotSizeValue(removeDecimalZeros(LotSz_mn), removeDecimalZeros(LotSz_mx))==='Any' ? <span className="details-text">lot size</span> : <></>}
          </div>
        );
      }
    } else {
      let bedRm;
      let bathRm;
      let sqFt;
      let timeLine;
      if (Bedrooms) {
        if (Bedrooms === 99) {
          bedRm = "Any ";
        } else {
          bedRm = Bedrooms + "+";
        }
      }
      if (Bathrooms) {
        if (Bathrooms === 99) {
          bathRm = "Any ";
        } else {
          bathRm = Bathrooms + "+";
        }
      }
      const sqFtMn = Sqft_mn && Sqft_mn * 1 //Multiplying by 1 for  avoiding zeros after the decimal part (eg: if value is 100.00 then it should show 100)
      let sqFtMx = Sqft_mx && Sqft_mx * 1
      if(sqFtMx==5000){
        sqFtMx = "5000+"
      }   
      if (sqFtMn && sqFtMx) {        
        sqFt = `${sqFtMn} - ${sqFtMx}`;
      } else if (!sqFtMn && sqFtMx) {
        sqFt = `No min - ${sqFtMx}`;
      } else if (sqFtMn && !sqFtMx) {
        sqFt = `${sqFtMn} - No max`;
      } else {
        //sqFt = `No min - No max`;
        sqFt = `Any`;
      }
      if(AdType === ADTYPE_STR){
        if(DateType===1 || DateType== null) {
          timeLine = formateDate(Date_From, "mm/dd/yyyy") +
          " - " +
          formateDate(Date_To, "mm/dd/yyyy");
        }
        else if(DateType===2) {
          timeLine =
            durationForFlexibleDate(Duration) +
            " - " +
            monthNameForFlexibleDate(Month);
        }
      }

      return (
        <div className="property-details">
          {bedRm} {bedRm && <span className="details-text">beds</span>}
          {bathRm ? <span className="vertical-divider">|</span> : ""}
          {bathRm} {bathRm && <span className="details-text">baths</span>}
          {sqFt ? <span className="vertical-divider">|</span> : ""}
          {sqFt && AdType!==ADTYPE_STR && (
            <>
              <span>{sqFt}</span> <span className="details-text"> sqft</span>
            </>
          )}
          {timeLine && AdType===ADTYPE_STR && (
            <>
              <span className="details-text">{timeLine}</span>
            </>
          )}

        </div>
      );
    }
  };


  /**
   * handle agents favorite action, to open client list popup
   */
  const handleAgentsFavoriteAction = (IsFavourite, Want_ad_Id) => {
    setIsFavourite(IsFavourite);
    setWantAdId(Want_ad_Id);
    setModalShow(true);
  };

  /**
   * to handle clients favorites action
   * @param {*} agentIds
   */
  const handleClientFavoriteWantAd = (agentIds) => {
    handleFavoriteOrUnfavoriteWantAd(isFavourite, wantAdId, agentIds);
  };

  /**
   * To get want ad details for share want ad
   * @param {*} wantAdId 
   */
  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setSelectedWantAd(responseResult);
            setShareModal(true);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } 
  };

/**
 * handle share want ad functionality
 * @param {*} e 
 * @param {*} Want_ad_Id 
 */
  const handleShare = (e, Want_ad_Id) =>{
    e.stopPropagation();
    getWantAdDetails(Want_ad_Id)
  }

/**
 * To show property type icons on map image
 * @param {*} props 
 * @returns 
 */
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" placement="bottom-start" className="custom-tooltip" {...props}>
      {
        subPropertyTypeId && subPropertyTypeId?.length > 0 && 
        getCombinedText(subPropertyTypeId) 
      }
    </Tooltip>
  );

  return (
    <>
      <a
        role="button"
        className="property-listing-link"
        href={void 0}
        onClick={() => handleWantAdItemClick(Want_ad_Id)}
      >
        <div className="property-listing">
          <div className="property-type-wrap">
          <OverlayTrigger
      placement="bottom-start"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
                <div className="property-tile w-auto">
                {
                <>
                  {subPropertyTypeId && subPropertyTypeId?.length > 0 && 
                  subPropertyTypeId.slice(0,3).map((subtype)=>{return <img src={getPropertyIcon(subtype)} alt={getPropertyIcon(subtype)} className="property-icon-img" />})}
                  {subPropertyTypeId?.length > 3 ? <span>{' '}+{subPropertyTypeId?.length - 3}</span>  : <></>}
                </>
                }         
                  
                </div>
                </OverlayTrigger>
          </div>

          <div className="property-img-wrap h-150">            
              <img
              src={Location_Img + "?" + Latitude || MapImage}
              alt={ MapImage}
            />            
          </div>

          <div className="property-info-outer">
            <div className="property-top">
              <div className="property-info-wrap">
                <div className="prize-range-badge">{budget}</div>
                
                <h6 className="long-txt-fix">
                  {Title_Text}{" "}
                  <span className="dot-color long-txt-fix">&bull;</span>{" "}
                  <span className="long-txt-fix">
                    {locationDisplay(props.list)}                   
                  </span>
                </h6>               
                {loadMustHaves()}
              </div>

              <div
                className="social-btns-wrap rmt-12"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {user && <a
                  role="button"
                  className="social-content-btn mr-12"
                  href={void 0}
                  onClick={(e) => handleShare(e,Want_ad_Id)}
                >
                  <img src={ShareIcon} />
                </a>}
                {(user || isUserVerified) && (CreatedBy != loggedInUserId) ? (
                  IsFavourite ? (
                    <a
                      role="button"
                      href={void 0}
                      onClick={() => {
                        handleFavoriteOrUnfavoriteWantAd(true, Want_ad_Id);
                      }}
                      className="social-content-btn"
                    >
                      <img src={HearttIconFilled} />
                    </a>
                  ) : (
                    <a
                      role="button"
                      href={void 0}
                      onClick={() => {
                        sessionData?.userInfo?.userType == 2 && isPremiumUser
                          ? handleAgentsFavoriteAction(false, Want_ad_Id)
                          : handleFavoriteOrUnfavoriteWantAd(false, Want_ad_Id);
                      }}
                      className="social-content-btn"
                    >
                      <img src={HearttIcon} />
                    </a>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="property-btm">

              <div
                className="sliderwrapper"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >               
                <Slider {...settings}>{loadNiceToHaveList()}</Slider>
              </div>
            </div>
          </div>
        </div>
      </a>
      {/*  Add to client favorite Modal */}
      <AddToClientWantAdModal
        show={modalShow}
        showAddToClientWantAdModal={() => setModalShow(true)}
        onHide={() => setModalShow(false)}
        data={props.results}
        handleSkip={() =>
          handleFavoriteOrUnfavoriteWantAd(isFavourite, wantAdId)
        }
        handleConfirm={handleClientFavoriteWantAd}
        searchText={props.clientSearchText}
        onChangeText={(e) => onChangeText(e)}
        onAddClient={() => onAddClient()}
      />
      { showShareModal && <ShareYourAdModal
        show={showShareModal}
        onHide={handleClose}
        adDetails={selectedWantAd}
        isWantAdCreated={false}
        AdType={_.get(selectedWantAd, "adType", "")=== "BUY" ? 1 : 2}
        isAdTypeIsLand={_.get(selectedWantAd, "prprtyType", "")=== 6}
        adTypeBasedOnProperty={_.get(selectedWantAd, "prprtyType", "")=== 6 ? LOT_LAND : _.get(selectedWantAd, "adType", "")}        
      />}
    </>
  );
};
export default PropertyListing;
