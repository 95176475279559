import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  messageCount: 0,
  chatMessages: [],
  paymentMethodsError: "",
  unreadNotificationMessageCount: 0, // receive into notification count
  unreadInappNotificationCount: 0 // in app notification unread count
};

export const unReadStatusSlice = createSlice({
  name: 'unreadCount',
  initialState: initialState,
  reducers: {
    initializeChatMessageCountValue: (state) => {
      state.messageCount = 0
    },
    setChatMessageCount: (state) => {
      state.messageCount += 1
    },
    setChatMessages: (state, payload) => {
      state.chatMessages = payload
    },

    initializeReceiveIntroCount: (state) => {
      state.unreadNotificationMessageCount = 0
    },

    setReceiveIntroCount: (state, payload) => {
      state.unreadNotificationMessageCount = payload
    },

    initializeInappNotificationCountValue: (state) => {
      state.unreadInappNotificationCount = 0
    },
    setInappNotificationCount: (state, payload) => {
      state.unreadInappNotificationCount = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setChatMessageCount,
  initializeChatMessageCountValue,
  setChatMessages,
  setReceiveIntroCount,
  initializeReceiveIntroCount,
  initializeInappNotificationCountValue,
  setInappNotificationCount } = unReadStatusSlice.actions

export default unReadStatusSlice.reducer

