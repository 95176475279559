import React, { useState, useEffect } from "react";
import MustHaveBtnGroupMob from "../MustHaveBtnGroupMob";
import { handleRadioButonChange } from "../../../actions/common";
import WantAdSquareFeet from "../../commonComponents/WantAdSquareFeet";
import { getLocalStorage, removeDecimalZeros } from "../../../helpers/common";
import {
  NEW_CONSTRUCTION,
  STORY_ANY,
  STORY_SINGLE,
  WANT_AD_KEY,
  YEAR_BUILT_RANGE,
} from "../../../constants/common";
import WantAdLotSize from "../../commonComponents/WantAdLotSize";
import TextBox from "../../../components/Common/TextBox";
import { handleTextBoxChange } from "../../../actions/common";
import {
  yearBuiltRangeMissing,
  yearBuiltMaxMissing,
  yearBuiltMinMissing,
  yrMinInValid,
  yrMaxInValid,
  yrBltMaxLesser,
  yrBltMinGreater,
} from "../../../constants/createWantAd";
const MustHavesProperties = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setDisableSave,
    validate,
    isEdit,
    isFromBuyerListingService
  } = props;

  const noOfBedrooms = _.get(formValues, "noBedRm", null);
  const noOfBedroomsinSession = _.get(getLocalStorage(WANT_AD_KEY), "noBedRm", null);
  const noOfBathrooms = _.get(formValues, "noBathRm", null);
  const noOfBathroomsinSession = _.get(getLocalStorage(WANT_AD_KEY), "noBathRm", null);
  const SFMin = removeDecimalZeros(_.get(formValues, "sqFtMin", null));
  const SFMinInSession = removeDecimalZeros(_.get(getLocalStorage(WANT_AD_KEY), "sqFtMin", null));
  const SFMax = removeDecimalZeros(_.get(formValues, "sqFtMax", null));
  const SFMaxinSession = removeDecimalZeros(_.get(getLocalStorage(WANT_AD_KEY), "sqFtMax", null));
  const garageSpace = _.get(formValues, "grgSpce", null);
  const garageSpaceInSession = _.get(getLocalStorage(WANT_AD_KEY), "grgSpce", null);
  const mustHaveErrors = _.get(formValues, "wantAdValErrors", null);
  const errorUpdatedAt = _.get(formValues, "updatedAt", null);
  const stories = _.get(formValues, "stories", null);
  const storiesInSession = _.get(getLocalStorage(WANT_AD_KEY), "stories", null);
  const minLtSz = _.get(formValues, "minLtSz", null);
  const minLtSzInSession = _.get(getLocalStorage(WANT_AD_KEY), "minLtSz", null);
  const maxLtSz = _.get(formValues, "maxLtSz", null);
  const maxLtSzInSession = _.get(getLocalStorage(WANT_AD_KEY), "maxLtSz", null);
  const yearBuiltOption = _.get(formValues, "yearBuiltOption", null);
  const yearBuiltOptionInSession = _.get(getLocalStorage(WANT_AD_KEY), "yearBuiltOption", null);
  const yearBuiltMin = _.get(formValues, "yrBltMin", null);
  const yearBuiltMinInSession = _.get(getLocalStorage(WANT_AD_KEY), "yrBltMin", null);
  const yearBuildMax = _.get(formValues, "yrBltMax", null);
  const yearBuildMaxInSession = _.get(getLocalStorage(WANT_AD_KEY), "yrBltMax", null);
  const adaAccess = _.get(formValues, "adaAccess", false);
  const adaAccessInSession = _.get(getLocalStorage(WANT_AD_KEY), "adaAccess", false);
  const [gSpace, setGSpace] = useState("");
  const [yearBuiltMinError, setYearBuiltMinError] = useState("");
  const [yearBuiltMaxError, setYearBuiltMaxError] = useState("");

  const YEAR_BUILT_MIN = 1900;
  const YEAR_BUILT_MAX = new Date().getFullYear();

  useEffect(() => {
    
    if(isEdit){
      let isDisable = true; 
      if(noOfBedrooms != noOfBedroomsinSession){
        isDisable = false;
      }
      if(noOfBathrooms != noOfBathroomsinSession){       
        isDisable = false;
      }
      if(SFMin != SFMinInSession){
        isDisable = false;
      }
      if(SFMax !=SFMaxinSession){
        isDisable = false;
      }
      if(garageSpace != garageSpaceInSession){
        isDisable = false;
      }

      if(stories != storiesInSession){
        isDisable = false;
      }
      if(minLtSz != minLtSzInSession){
        isDisable = false;
      }
      if(maxLtSz != maxLtSzInSession){
        isDisable = false;
      }
      if(yearBuiltOption != yearBuiltOptionInSession){
        isDisable = false;
      }
      if(yearBuiltMin != yearBuiltMinInSession){
        isDisable = false;
      }
      if(yearBuildMax != yearBuildMaxInSession){
        isDisable = false;
      }
      if(adaAccess != adaAccessInSession){
        isDisable = false
      }
      setDisableSave(isDisable);
    }
    else{
      if(noOfBedrooms && noOfBathrooms){
          handleContinueButton();
      }
    }
  }, [noOfBedrooms, noOfBathrooms, SFMin, SFMax, garageSpace, stories, minLtSz, maxLtSz, yearBuiltOption, yearBuiltMin, yearBuildMax, adaAccess]);

  useEffect(() => {
    setYearBuiltMinError("");
    setYearBuiltMaxError("");
    if (mustHaveErrors) {
      switch (mustHaveErrors) {
        case yearBuiltRangeMissing:
          setYearBuiltMinError("Enter Min Year");
          setYearBuiltMaxError("Enter Max Year");
          break;
        case yearBuiltMaxMissing:
          setYearBuiltMaxError("Enter Max Year");
          break;
        case yearBuiltMinMissing:
          setYearBuiltMinError("Enter Min Year");
          break;
        case yrMinInValid:
          setYearBuiltMinError(
            "Enter Valid Year. Minimum year should be 1900."
          );
          break;
        case yrMaxInValid:
          setYearBuiltMaxError("Enter Valid Year");
          break;
        case yrBltMaxLesser:
          setYearBuiltMaxError("Max year must be greater than min year");
          break;
        case yrBltMinGreater:
          setYearBuiltMaxError("Min year must be less than max year");
          break;
      }
      // validate();
    }
  }, [mustHaveErrors, errorUpdatedAt]);

  const handleContinueButton = () => {
    let isDisable = false;
    if (noOfBedrooms && noOfBathrooms) {
      setDisableSave(false);
    } else {
      isDisable = true;
      setDisableSave(isDisable);
    }
  };

  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
  };

  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    setGSpace(value);
    handleFormValues({ grgSpce: value });
  };

  const handleStorySelection = (selectedStory) => {
    if (selectedStory == stories) {
      handleFormValues({ stories: "" });
    } else {
      handleFormValues({ stories: selectedStory });
    }
  };

  const handleYearBuiltSelection = (selectedValue) => {
    if (selectedValue === yearBuiltOption) {
      handleFormValues({
        yearBuiltOption: "",
        yrBltMin: "",
        yrBltMax: "",
      });
    } else {
      handleFormValues({
        yearBuiltOption: selectedValue,
        yrBltMin: "",
        yrBltMax: "",
      });
    }
  };

  const handleYearBuiltMinchange = (e) => {
    const value = handleTextBoxChange(e);
    handleFormValues({ yrBltMin: value });
  };

  const handleYearBuiltMaxchange = (e) => {
    const value = handleTextBoxChange(e);
    handleFormValues({ yrBltMax: value });
  };

  const handleCheckBoxItemClick = (e) => {
    handleFormValues({ adaAccess: e.target.checked });
  };

  return (
    <>
      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-10">
          <h4>Bedrooms *</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBedrooms}
            items={["Any", "1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBedRoomClick(e)}
          />
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4>Bathrooms *</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBathrooms}
            items={["Any", "1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBathRoomClick(e)}
          />
        </div>
      </div>

      <WantAdSquareFeet
        handleFormValues={handleFormValues}
        SFMax={SFMax}
        SFMin={SFMin}
        sqFtError={mustHaveErrors}
        errorUpdatedAt={errorUpdatedAt}
      />

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4> Garage Spaces</h4>
          <MustHaveBtnGroupMob
            section="Garage"
            value={garageSpace}
            currentSelectedValue={gSpace}
            items={["Any", "1+", "2+", "3+"]}
            onClick={(e) => handleGarageClick(e)}
            handleFormValues={handleFormValues}
          />
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-2">
          <h4> Stories</h4>
          <div className="row">
            <div className="col-6">
              <a
                href={void 0}
                className={
                  stories === STORY_ANY
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleStorySelection(STORY_ANY);
                }}
              >
                {" "}
                Any{" "}
              </a>
            </div>
            <div className="col-6">
              <a
                href={void 0}
                className={
                  stories === STORY_SINGLE
                    ? "blockBtn text-center active"
                    : "blockBtn text-center"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleStorySelection(STORY_SINGLE);
                }}
              >
                {" "}
                Single Story Only{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <WantAdLotSize
          handleFormValues={handleFormValues}
          mnLotSze={minLtSz}
          mxLotSze={maxLtSz}
          lotSizeError={mustHaveErrors}
          errorUpdatedAt={errorUpdatedAt}
        />
      </div>

      <div className="stay-timing-wrap mb-4 pb-3">
        <div className="lp-btn-group-wrap">
          <h4> Year Built</h4>
          <div className="row">
            <div className="col-12">
              <div className="mob-fld-wrap position-relative ">
                <a
                  href={void 0}
                  className={
                    yearBuiltOption && yearBuiltOption == YEAR_BUILT_RANGE
                      ? "blockBtn text-left active  mb-3"
                      : "blockBtn text-left mb-3"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleYearBuiltSelection(YEAR_BUILT_RANGE);
                  }}
                >
                  {" "}
                  Set range{" "}
                </a>
              </div>
            </div>
            {yearBuiltOption === YEAR_BUILT_RANGE && (
              <div className="col-12">
                <ul className="range-values-year-list">
                  <li>
                    <TextBox
                      type="number"
                      minValue={YEAR_BUILT_MIN}
                      maxValue={YEAR_BUILT_MAX}
                      errorMessage={yearBuiltMinError}
                      errorClass="error-msg error-msg-height"
                      value={yearBuiltMin}
                      placeholder="Min year"
                      class={
                        yearBuiltMinError
                          ? "form-control validation-txtfld"
                          : "form-control"
                      }
                      onChange={(e) => {
                        handleYearBuiltMinchange(e);
                      }}
                    />
                  </li>

                  <li className="text-center">
                    <span className="middle-separator">-</span>
                  </li>
                  <li className="position-relative">
                    <TextBox
                      type="number"
                      minValue={YEAR_BUILT_MIN}
                      maxValue={YEAR_BUILT_MAX}
                      errorMessage={yearBuiltMaxError}
                      errorClass="error-msg error-msg-height"
                      value={yearBuildMax}
                      placeholder="Max year"
                      class={
                        yearBuiltMaxError
                          ? "form-control validation-txtfld"
                          : "form-control"
                      }
                      onChange={(e) => {
                        handleYearBuiltMaxchange(e);
                      }}
                    />
                  </li>
                </ul>
              </div>
            )}
            <div className="col-12">
              <div className="mob-fld-wrap position-relative ">
                <a
                  href={void 0}
                  className={
                    yearBuiltOption && yearBuiltOption == NEW_CONSTRUCTION
                      ? "blockBtn text-left active"
                      : "blockBtn text-left"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleYearBuiltSelection(NEW_CONSTRUCTION);
                  }}
                >
                  {" "}
                  New construction only{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4> Additional Requirements</h4>
          <div className="row">
            <div className="col-12">
              <a
                href={void 0}
                className="createAd-content-wrap  formCheckBoxwrap mb-3"
              >
                <label
                  class="form-check-label checkbox-label w-100"
                  for="IAmRepresenting1"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="IAmRepresenting1"
                    checked={adaAccess ? true : false}
                    onChange={(e) => handleCheckBoxItemClick(e)}
                  />
                  <span className=""> ADA Accessible</span>
                </label>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MustHavesProperties;
