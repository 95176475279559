/**
 * @file   src\actions\inventory.js
 * @brief  This file is responsible for redux actions related to inventories
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPropertyList , getInventoryById} from "../services/inventoryService";

export const fetchPropertyList = createAsyncThunk(
    "inventory/fetchPropertyList",
    (requestPayload) => {
      return getPropertyList(requestPayload);
    }
  );

  export const fetchInventoryDetailsById = createAsyncThunk(
    "inventory/fetchPropertyDetailsById",
    (requestPayload) => {
      return getInventoryById(requestPayload);
    }
  );