import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ReasonForNotFitModal from "./ReasonForNotFitModal";

const MarkFitModal = (props) => {
  const {
    titleText,
    description,
    onHide,
    showNotFirReasnPopup,
    handleNotFitConfirm,
    markFitReasonModalClose,
    reasonPopupTitle,
    reasonPopupDesc,
    introductionId,
    updateToNotFit,
    buttonTitle,
    name
  } = props;


  return (
    <>
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-settings"
      >
        <Modal.Body className="pt-32 pb-32">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => onHide()}
          ></button>
          <h3 className="text-start">{titleText}</h3>
          <p className="f-size-16 mb-4">{description}</p>

          <div className="modal-btn-wrap">
            <Button
              variant=""
              className="btn btn-tertiary me-2"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleNotFitConfirm()}>
              {props?.buttonTitle ? props?.buttonTitle : 'Confirm' }
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ReasonForNotFitModal
        showNotFirReasnPopup={showNotFirReasnPopup}
        onHide={markFitReasonModalClose}
        reasonPopupTitle={reasonPopupTitle}
        reasonPopupDesc ={reasonPopupDesc}
        introductionId = {introductionId}
        updateToNotFit ={updateToNotFit}
        name ={name}
      />
    </>
  );
  //Mark not a fit and end conversation
  //This action will end the introduction and associated conversation. Once you delete this it cannot be undone.
};
export default MarkFitModal;
