import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { capitalizeString, handleTextBoxChange } from "../../../actions/common";
import GeoLocation from "../../Common/GeoLocation";
import {
  checkIsNull,
  createS3Folder,
  getLocalStorage,
  getUserId,
  replaceComma,
  thousandSeperatedValue,
  trimString,
} from "../../../helpers/common";
import TextBox from "../../Common/TextBox";
import {
  convertToNumber,
  removeNegativeInput,
} from "../../../helpers/validations";
import CheckBox from "../../Common/CheckBox";
import {
  DEFAULT_INVENTORY_MAP_ZOOM,
  INVENTORY_KEY,
  IS_APARTMENT_CONDO,
  IS_ENTIRE_PLACE,
  IS_GUEST_HOUSE,
  IS_PRIVATE_ROOM,
  yrMaxInValid,
} from "../../../constants/common";
import InventoryLocation from "./InventoryLocation";
import {
  getGeoLocation,
  getPropertySearch,
  getPropertDetails,
} from "../../../services/commonServices";
import { usePrevious } from "../../../hooks/usePrevious";

const InventoryLocationWithMustHaves = (props) => {
  const {
    handleFormValues,
    locationAddress,
    noBedRm,
    noBathRm,
    sqFt,
    stories,
    lotSize,
    grgeSpots,
    adaAccess,
    IsNewConstruction,
    propertyType,
    propertySubType,
    neighborHood,
    highSchool,
    yearBuilt,
    validationErrors,
    isEdit = false,
    lat,
    lng,
    mapZoom,
    isAgentCreated = false,
    s3Imagefolder,
    mapType,
    locationError,
    sessionKey
  } = props;

  const [yearBuiltError, setYearBuiltError] = useState("");
  const [isPropertyDataExist, setDataExistFlag] = useState(false);

  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_INVENTORY_MAP_ZOOM,
  };

  const adaAccessCheckBox = "adaAccess";
  const newConstructionCheckBox = "newConstruction";

  const isLotSizeDisable =
    propertyType === IS_ENTIRE_PLACE &&
    (propertySubType === IS_APARTMENT_CONDO ||
      propertySubType === IS_GUEST_HOUSE);


  useEffect(() => {
    handleFormValues({ isPropertyExist: false, mapZoom: mapZoom || DEFAULT_INVENTORY_MAP_ZOOM })
    if (!locationAddress) {
      const inventoryDetails = getLocalStorage(INVENTORY_KEY);
      const s3Folder = s3Imagefolder;
      const userId = getUserId();
      if (!s3Folder) {
        getFolderName();
      }
      handlePropertyExist({ isExist: false })
    } else {
      handlePropertyExist({ isExist: true })
    }
  }, []);



  useEffect(() => {
  
    setYearBuiltError("");
    if (validationErrors && validationErrors.length > 0) {
      validationErrors.map((item) => {
     
        if (item == 'yearBuiltRange') {
          setYearBuiltError("Enter Valid year");
        }
      })
    }
  }, [validationErrors]);



  const getFolderName = async (userId) => {
    const isWantAd = false;
    await createS3Folder(isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };



  const updateValuesToState = (key, value) => {
    if (!checkIsNull(value)) {
      handleFormValues({ [key]: value });
    } else {
      handleFormValues({ [key]: "" });
    }
  };

  const handleBedRoomChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));
    if (value === "0") {
      value = 1;
    } else if (replaceComma(value) > 100) {
      value = 100;
    }
    updateValuesToState("noBedRm", replaceComma(value));
  };

  const handleBathRoomChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));
    if (value === "0") {
      value = 1;
    } else if (replaceComma(value) > 100) {
      value = 100;
    }
    updateValuesToState("noBathRm", replaceComma(value));
  };

  const handleSqFtChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));
    if (value === "0") {
      value = 1;
    } else if (replaceComma(value) > 10000) {
      value = 10000;
    }
    updateValuesToState("sqFt", replaceComma(value));
  };

  const handleStoryChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));

    updateValuesToState("stories", replaceComma(value));
  };

  const handleLotSizeChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("lotSize", replaceComma(value));
  };
  const handleGarrageSpotChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("grgeSpots", replaceComma(value));
  };

  const handleYearBuitChange = (e) => {
    setYearBuiltError("");
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("yearBuilt", value);
  };

  const handleNeighborhoodChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    updateValuesToState("neighborHood", value);
  };

  const handleHighSchoolChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    updateValuesToState("highSchool", value);
  };

  const handleCheckBoxClick = (e) => {
    if (e.target.id === adaAccessCheckBox) {
      handleFormValues({ adaAccess: e.target.checked });
    } else {
      handleFormValues({ IsNewConstruction: e.target.checked });
    }
  };

  const handlePropertyExist = ({ isExist }) => {
    setDataExistFlag(isExist);
    
    handleFormValues({ invalidLocationExist: true });
  }


  const isPrivateRoom = propertyType === IS_PRIVATE_ROOM

  return (
    <>
      {(!locationAddress && !lat && !lng) && <GeoLocation handleFormValues={handleFormValues} />}
      <>
        <div className="wantad-location-wrap mb-3">
          <InventoryLocation
            defaultProps={defaultProps}
            locationAddress={locationAddress}
            handleFormValues={handleFormValues}
            updateValuesToState={updateValuesToState}
            handlePropertyExist={handlePropertyExist}
            isAgentCreated={isAgentCreated}
         
            validationErrors={validationErrors}
            isPrivateRoom = {isPrivateRoom}
            mapType={mapType}
            locationError={locationError}
            sessionKey={sessionKey}

          ></InventoryLocation>
          <div className={isPrivateRoom ?"section-control mb-40" :(isPropertyDataExist?"section-control mb-40" : 'disabled-section-control mb-40')}>
          <h6>Basics</h6>
          {!isPrivateRoom && (
              <div className="row">
                <div className="col-md-6">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Neighborhood</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      //errorMessage= {minError}
                      value={neighborHood}
                      onChange={(e) => {
                        handleNeighborhoodChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>High School</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      //errorMessage= {minError}
                      value={highSchool}
                      onChange={(e) => {
                        handleHighSchoolChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>
                      Bedrooms
                    </Form.Label>
                    <TextBox
                      disabled={!isPropertyDataExist}
                      type="text"
                      class="form-control"
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(noBedRm) || ""}
                      onChange={(e) => {
                        handleBedRoomChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>
                      Bathrooms
                    </Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(noBathRm) || ""}
                      onChange={(e) => {
                        handleBathRoomChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>
                      Square footage
                    </Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(sqFt) || ""}
                      onChange={(e) => {
                        handleSqFtChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Stories</Form.Label>

                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(stories) || ""}
                      onChange={(e) => {
                        handleStoryChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                {!isLotSizeDisable && (
                  <div className="col-md-3">
                    <div className="common-input-wrap mb-2">
                      <Form.Label>Lot size (Acres)</Form.Label>

                      <TextBox
                        type="text"
                        class="form-control"
                        disabled={!isPropertyDataExist}
                        //errorMessage= {minError}
                        value={thousandSeperatedValue(lotSize) || ""}
                        onChange={(e) => {
                          handleLotSizeChange(e);
                        }}
                      ></TextBox>
                      <div className="error-msg"></div>
                    </div>
                  </div>
                )}

                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Garage Spots</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(grgeSpots) || ""}
                      onChange={(e) => {
                        handleGarrageSpotChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Year Built</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyDataExist}
                      errorMessage= {yearBuiltError}
                      value={yearBuilt}
                      onChange={(e) => {
                        handleYearBuitChange(e);
                      }}
                    ></TextBox>
                    {/* <div className="error-msg">{yearBuiltError}</div> */}
                  </div>
                </div>
              </div>
            )}

            <div className="row">

              {propertyType !== IS_PRIVATE_ROOM && (
                <div className="col-md-4">
                  <div className="field-wrap mt-0 mb-0">
                    <label
                      className="form-check-label checkbox-label"
                      htmlFor={newConstructionCheckBox}
                    >
                      <CheckBox
                        class="form-check-input"
                        type="checkbox"
                        disabled={!isPropertyDataExist}
                        id={newConstructionCheckBox}
                        checked={IsNewConstruction}
                        onChange={(e) => handleCheckBoxClick(e)}
                      />{" "}
                      New Construction
                    </label>
                  </div>
                </div>
              )}

              <div className="col-md-8">
                <div className="field-wrap mt-0 mb-0">
                  <label
                    className="form-check-label checkbox-label"
                    htmlFor={adaAccessCheckBox}
                  >
                    <CheckBox
                      class="form-check-input"
                      type="checkbox"
                      // disabled={!isPropertyDataExist}
                      id={adaAccessCheckBox}
                      checked={adaAccess}
                      onChange={(e) => handleCheckBoxClick(e)}
                    />{" "}
                    ADA Accessible
                  </label>
                </div>
              </div>

            </div>

          </div>
        </div>
      </>
    </>
  );
};

export default InventoryLocationWithMustHaves;
