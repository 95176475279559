/**
 * @file   Source/gitcha_Web/src/mobile/pages/CreateAWantAdNext.js
 * @brief  This file is responsible for loading ui components in create wantad flow in mobile view
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { WANT_AD_KEY, USER_INFO_KEY, WANT_AD_KEY_TEMP, USER_TYPE_AGENT, BUY, SHORT_RENT, LONG_RENT, INVESTOR_MEMBER_PLAN, PRO_MEMBER_PLAN } from "../../constants/common";
import { useNavigate, useLocation } from "react-router";
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorageOnLogout,
  getUserType,
  getUserId,
  checkTutorialDisplayStatus,
  clearLocalStorage,
} from "../../helpers/common";
import { userLogout } from "../../reducers/login";
import SelectAdType from "../components/SelectAdType";
import CreateWantAdSummary from "./CreateWantAdSummary";
import SelectClient from "../components/createWantAd/SelectClient";
import AddLocation from "../components/AddLocation";
import AddTimeLine from "../components/AddTimeline";
import AddBudget from "../components/createWantAd/AddBudget";
import AddPropertyType from "../components/AddPropertyType";
import AddMustHaves from "../components/createWantAd/AddMustHaves";
import NiceToHaves from "../components/createWantAd/NiceToHaves";
import AddTitle from "../components/createWantAd/AddTitle";
import ReadyToPost from "../components/createWantAd/ReadyTopost";
import AddPurchaseType from "../components/createWantAd/AddPurchaseType";
import AddFinancing from "../components/createWantAd/AddFinancing";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserDetails } from "../../actions/userprofile";
import AddCreditScore from "../components/createWantAd/AddcreditScore";
import ReadyToSaveDraftModal from "../commonComponents/ReadyToSaveDraftModal";
import { pageURLs } from "../../constants/pageURLs";
import { getEditAction, saveToDraft } from "../../helpers/createWantAd";
import { getWantAdById, saveMemberWantAdstrengthenData } from "../../services/wantAdService";
import AddCompensation from "../components/createWantAd/AddCompensation";
import InvestorBudget from "../components/createWantAd/InvestorBudget";
import InvestorProfileCreateWantAdSummary from "./InvestorProfileCreateWantAdSummary";
import InvestmentCriteria from "../components/createWantAd/InvestmentCriteria";
import userProfile from "../../appState/userProfile";
import { useSearchParams } from "react-router-dom";
import base64 from "base-64";
import utf8 from "utf8";
import ErrorMessage from "../components/createWantAd/ErrorMessage";
const CreateAWantAdNext = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let adAmenities = null;
  const [searchParams] = useSearchParams();
  searchParams.get("wantAdId");
  const id = searchParams.get("wantAdId");
  let decodedId;
  let idFromURL;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
    if (decodedId) {
      let decodedData = decodedId.split("-");
      idFromURL = decodedData.length > 0 ? decodedData[1] : null;
    }
  } catch { }

  const wantAdId = idFromURL;
  
  const [uiKey, setUIKey] = useState(null);
  const userType = getUserType();
  const [formValues, setFormValues] = useState(null);
  const [completedPercentage, setCompletedPercentage] = useState(7)
  const [showSaveExitModal, setShowSaveExitModal] = useState();
  const [isPremiumUser, setIsPremiumUser] = useState(null);
  const userId = getUserId();
  const [showWantadPublished, setShowWantadPublished] = useState(null);

  const isAgent = userType === USER_TYPE_AGENT ? true : false
  const isEdit = location.state && location.state['data'] || wantAdId? true : false
  const isFromMyWantAd = location && location?.state && location.state?.isFromMyWantAd
  const isFromUnPause = location && location?.state && location.state?.isFromUnPause
  const isFromNotification = location && location?.state && location.state?.isFromNotification
  const isFromBuyerListingNotification = location && location?.state && location.state?.isFromBuyerListingNotification || wantAdId
  const [isUnPauseAction, setIsUnPauseAction] = useState(true);
  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("")

  useEffect(() => { //Need to show want add onboard very first time
    let DisplayWantAddOnboard = checkTutorialDisplayStatus(6); //Want add onboard display
    if (DisplayWantAddOnboard) {
      navigate(pageURLs.wantAdTutorialResponsive);
    }
  }, []);

  //To fetch wantad details based on wantad id for handling want ad review link passed from email for buyerlisting want ad review
  const getWantAdDetails = async (id) => {
    await getWantAdById(id)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) { 
          if(responseResult?.isPublished === 1){
            setShowWantadPublished(true)
        }
         else{ 
            setShowWantadPublished(false)
            setLocalStorage(WANT_AD_KEY, responseResult)  
            setFormValues(responseResult) 
          }                    
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });    
};


  useEffect(() => {
    if(wantAdId != null) {//To fetch want ad details based on want ad id passed through url
      getWantAdDetails(wantAdId)
    }
    else if (location && location.state) {//To handle edit want ad
      setShowWantadPublished(false);
      if (location.state['data']) {
        setLocalStorage(WANT_AD_KEY, location.state['data'])
      }

      if ((location.state['isFromUnPause'] || location.state['isFromNotification'])) {
        let sessionValue = getLocalStorage(WANT_AD_KEY)
        //Need to clear dates when edit from unpause option
        const isArchiveValue = getEditAction(isFromUnPause, isFromNotification); // pass the url parameters to the function
        if (isArchiveValue == 0) { // unpause/ unarchive action occured
          setLocalStorage(WANT_AD_KEY, { ...sessionValue, isArchived: 0, ExpiryStatus: true });
          if (sessionValue?.adType == SHORT_RENT || sessionValue?.adType == LONG_RENT) {
            //  setIsUnPauseAction(false);
            setLocalStorage(WANT_AD_KEY, { ...sessionValue, startDate: null, endDate: null, duration: null, month: null, moveInTimeLine: null, moveInDate: null, ExpiryStatus: true })
          }
          setUIKey("timeLine")
        } else {
          setLocalStorage(WANT_AD_KEY, { ...sessionValue, ExpiryStatus: false })
        }
      }
    }
    else{
      setShowWantadPublished(false)
    }
  }, [wantAdId])


  useEffect(() => {
    if (userDetails) {
      if (userDetails?.length === 1) {
        if (userDetails[0]?.PlanName) {
          setUserPlan(userDetails[0]?.PlanName)
        }
      } else {
        if (userDetails[0]?.PlanName === 'Trial') {
          setUserPlan(userDetails[1]?.PlanName)
        } else {
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }
  }, [userDetails]);


  useEffect(() => {//state variable to check if user is a premium agent
    if (userPlan) {
      if (userPlan === PRO_MEMBER_PLAN) {
        setIsPremiumUser(true)
      }
      else {
        setIsPremiumUser(false)
      }
    }

  }, [userPlan])

  //to handle clear states for each step value changes in create want ad
  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearAll") {
        if (isEdit && !isPremiumUser) {
          setFormValues({
            wantAdId: _.get(formValues, "wantAdId", "")
          });
        }
        else if (isPremiumUser && isEdit) {
          setFormValues({
            clientId: _.get(formValues, "clientId", ""),
            clientName: _.get(formValues, "clientName", ""),
            clientData: _.get(formValues, "clientData", ""),
            wantAdId: _.get(formValues, "wantAdId", ""),
            userFirstName: _.get(formValues, "userFirstName", ""),
            userLastName: _.get(formValues, "userLastName", ""),
            isAgentAsSeller: _.get(formValues, "isAgentAsSeller", ""),
          });
        }
        else if (!isEdit && isPremiumUser) {
          setFormValues({
            clientId: _.get(formValues, "clientId", ""),
            clientName: _.get(formValues, "clientName", ""),
            clientData: _.get(formValues, "clientData", ""),
          });
        }
        else { setFormValues({}); }
      } else if (type === "clearFlexDates") {
        const array = { ...formValues }; // make a separate copy of the state
        ["duration", "month"].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "dateRange") {
        const array = { ...formValues }; // make a separate copy of the state
        ["startDate", "endDate"].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "moveInTypeChangeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["startDate"].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "nice2Have",
        "noBedRm",
        "noBathRm",
        "noOfBeds",
        "sqFtMin",
        "sqFtMax",
        "grgSpce",
        "minLtSz",
        "maxLtSz",
        "yrBltMin",
        "yrBltMax",
        "isNwCnstctn",
        "acreageMin",
        "acreageMax",
        "lotType",
        "lotTypeText",
        "roadType",
        "rdTypText",
        "isPrvtDrInst",
        "onSiteElec",
        "waterSupply",
        "wSText",
        "isDrldWelInst",
        "wasteWtrSys",
        "wWSysText",
        "isSepTankInst",
        "isSepTankAppr",
        "prptySubTypes",
        "adaAccess",
        "accsPvtBthRm",
        "alwPets",
        "alwCats",
        "alwLrgDogs",
        "alwSmlDogs",
        "prptySubTypes",
        "adTitle",
        "adDesc",
        "minBdgt",
        "maxBdgt",
        "stories",
        "yearBuiltOption",
        "mnLtSzId",
        "mnLtSzTxt",
        "mxLtSzId",
        "mxLtSzTxt",
      ].forEach((e) => delete array[e]);

      setFormValues(array);
    }
  };



  useEffect(() => {
    dispatch(fetchUserDetails({
      "userId": userId,
    }));
    adAmenities = getLocalStorage(WANT_AD_KEY);
    setFormValues(adAmenities);
    if (adAmenities?.cmpldPerctg) {
      setCompletedPercentage(adAmenities?.cmpldPerctg)
    }

    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), "userInfo", null);
    if (!userInfo) {
      handleStateClear();
    }

  }, [uiKey === null, isPremiumUser]);

  useEffect(() => {

    handleCreateWantAdUi();
    let wantAdData = getLocalStorage(WANT_AD_KEY);
    // to save the value to draft on each step completed in create want ad 
    if (wantAdData && wantAdData?.backArrowClicked == false && uiKey == null) {
      handleSaveToDraft();
    } else {
      setLocalStorage(WANT_AD_KEY, { ...wantAdData, backArrowClicked: false })
    }

  }, [uiKey])

  // to save value to draft
  const handleSaveToDraft = () => {
    let wantAdData = getLocalStorage(WANT_AD_KEY);
    if (wantAdData?.isPublished != 1) {
      saveWatAdData()
    }
  }
// To save want ad data while creating want ad
  const saveWatAdData = async () => {
    const requestPayLoad = getLocalStorage(WANT_AD_KEY);
    let arrayValue = [];
    if (requestPayLoad?.zipIdValues) {
      requestPayLoad?.zipIdValues.map((zip) => {
        arrayValue.push(zip?.zipId)
      })
      requestPayLoad.zipId = arrayValue;
    } else if (requestPayLoad?.zipCode) {
      requestPayLoad?.zipCode.map((zip) => {
        if (zip?.zipId && zip?.zipId != null) {
          arrayValue.push(zip?.zipId)
        }
      })
      requestPayLoad.zipId = arrayValue;
    }
    requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
    if (userId && requestPayLoad) {
      requestPayLoad.isAgentCreated = userType == USER_TYPE_AGENT ? 1 : 0;
      await saveToDraft({ requestPayLoad: requestPayLoad })
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {

            setLocalStorage(WANT_AD_KEY, { ...getLocalStorage(WANT_AD_KEY), wantAdId: responseResult.wantAdId });
            handleFormValues({ wantAdId: responseResult.wantAdId })
            if (
              requestPayLoad.purchaseType ||
              requestPayLoad.creditScore ||
              requestPayLoad.prchTypeId || requestPayLoad.pFinance
            ) {
              saveStrengthenData(responseResult.wantAdId);
            }

          } else {
            console.log("Something went wrong");
          }
        })
    } else {
      clearLocalStorage()
      navigate(pageURLs.home)
    }
  };

  //to save data entered for stregthen want ad fields
  const saveStrengthenData = async (wantAdIdValue) => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const wantAdId = wantAdIdValue;

    if (wantAd) {
      let requestPayLoad = {};
      let isApproveLoan = "";

      if (
        _.get(wantAd, "isPreApprLoan", null) === "Yes" ||
        _.get(wantAd, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = true;
      } else if (
        _.get(wantAd, "isPreApprLoan", null) === "No" ||
        _.get(wantAd, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = false;
      }
      requestPayLoad.isPreApprLoan === isApproveLoan;
      const is1031Exchange = (wantAd.is1031Exchange === "Yes" || wantAd.is1031Exchange === 1) ? true : false;
      requestPayLoad.wantAdId = wantAdId;
      requestPayLoad.isPreApprLoan = isApproveLoan;
      requestPayLoad.is1031Exchange = is1031Exchange;
      requestPayLoad.completedStep = 1;
      requestPayLoad.adType =
        wantAd.adType == BUY ? "BUY_STRENGTHEN" : "LTR_STRENGTHEN";
      requestPayLoad.creditScore = wantAd.creditScore;
      requestPayLoad.maxBdgt = wantAd.maxBdgt;
      requestPayLoad.minBdgt = wantAd.minBdgt;
      requestPayLoad.pFinance = wantAd.pFinance || wantAd.finTypeId;
      requestPayLoad.purchaseType = wantAd.purchaseType || wantAd.prchTypeId;

      await saveMemberWantAdstrengthenData(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {

          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };


  const adType = _.get(formValues, "adType", null);

  // to handle first step based on user plan
  useEffect(() => {
    setLocalStorage(WANT_AD_KEY_TEMP, formValues);
    if (!isEdit) {
      if ((formValues?.clientId == undefined) && isPremiumUser === true) {
        setUIKey("client");
      }
      else if (formValues?.adType == undefined && isPremiumUser === false) {
        setUIKey("adType");
      }
    }

  }, [formValues]);


  useEffect(() => {
    handleFormValues({ cmpldPerctg: completedPercentage });
  }, [completedPercentage])

  const handleStateClear = () => {
    // alert("Your session has expired. Not complete the request. Please log in");
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate('/mobile/home');
  };

  // function to handle want ad data object
  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };


  //To handle ui based on uiKey state variable
  const handleCreateWantAdUi = () => {
    switch (uiKey) {
      case "adType":
        return <SelectAdType
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isPremiumUser={isPremiumUser}
          isEdit={isEdit}
        />;
      case "client":
        return <SelectClient
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isEdit={isEdit}
        />;
      case "location":
        return <AddLocation
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />;
      case "timeLine":
        return <AddTimeLine
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />
      case "budget":
        return (
          userPlan === INVESTOR_MEMBER_PLAN && adType == BUY ?
            <InvestorBudget
              handleFormValues={handleFormValues}
              formValues={formValues}
              setUIKey={setUIKey}
              adType={adType}
              completedPercentage={completedPercentage}
              clearPreviousStates={clearPreviousStates}
              setCompletedPercentage={setCompletedPercentage}
              isAgent={isAgent}
              isEdit={isEdit}
            />
            :
            <AddBudget
              handleFormValues={handleFormValues}
              formValues={formValues}
              setUIKey={setUIKey}
              adType={adType}
              completedPercentage={completedPercentage}
              clearPreviousStates={clearPreviousStates}
              setCompletedPercentage={setCompletedPercentage}
              isAgent={isAgent}
              isEdit={isEdit}
            />
        );


      case "propertyType":
        return <AddPropertyType
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />
      case "mustHaves":
        return <AddMustHaves
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />
      case "niceToHaves":
        return <NiceToHaves
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />
      case "title":
        return <AddTitle
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isEdit={formValues?.isReadyToReview && !formValues?.isPublished ? false : isEdit}
          isAgent={isAgent}
        />
      case "compensation":
        return <AddCompensation
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isEdit={formValues?.isReadyToReview && !formValues?.isPublished ? false : isEdit}
          isAgent={isAgent}
        />
      case "purchaseType":
        return <AddPurchaseType
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />
      case "financing":
        return <AddFinancing
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
          isPremiumUser={isPremiumUser}
        />
      case "creditScore":
        return <AddCreditScore
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />
      case "readyToPost":
        return <ReadyToPost
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          handleSaveAndExit={handleSaveAndExit}
          isPremiumUser={isPremiumUser}
          userPlan={userPlan}
        />

      case "investmentCriteria":
        return <InvestmentCriteria
          handleFormValues={handleFormValues}
          formValues={formValues}
          setUIKey={setUIKey}
          adType={adType}
          completedPercentage={completedPercentage}
          clearPreviousStates={clearPreviousStates}
          setCompletedPercentage={setCompletedPercentage}
          isAgent={isAgent}
          isEdit={isEdit}
        />

      default:

        return (
          userPlan === INVESTOR_MEMBER_PLAN && adType == BUY ?
            <InvestorProfileCreateWantAdSummary
              setUIKey={setUIKey}
              formValues={formValues}
              completedPercentage={completedPercentage}
              uiKey={uiKey}
              handleSaveAndExit={handleSaveAndExit}
              setCompletedPercentage={setCompletedPercentage}
              isPremiumUser={isPremiumUser}
              isEdit={isEdit}
              isFromMyWantAd={isFromMyWantAd}
              isFromBuyerListingNotification={isFromBuyerListingNotification}
            />
            :
            <CreateWantAdSummary
              setUIKey={setUIKey}
              formValues={formValues}
              completedPercentage={completedPercentage}
              uiKey={uiKey}
              handleSaveAndExit={handleSaveAndExit}
              setCompletedPercentage={setCompletedPercentage}
              isPremiumUser={isPremiumUser}
              isEdit={isEdit}
              isFromMyWantAd={isFromMyWantAd}
              wantAdId={wantAdId}
              isFromBuyerListingNotification={isFromBuyerListingNotification}
              userPlan={userPlan}
            />
        );
    }
  };

  // To handle save and exit action in want ad creation
  const handleSaveAndExit = () => {
    if (getLocalStorage(WANT_AD_KEY) == null) {
      navigate(pageURLs.home);
    } else {
      setLocalStorage(WANT_AD_KEY, getLocalStorage(WANT_AD_KEY_TEMP));
      setShowSaveExitModal(true);
    }
  };

  //To handle save and exit modal show
  const handleSaveExitModal = (state) => {
    setShowSaveExitModal(state);
  };

  return <>
    {showSaveExitModal && <ReadyToSaveDraftModal
      handlePopUp={handleSaveExitModal}
      show={showSaveExitModal}
      formValues={formValues}
      isMobile={true}
      isPremiumUser={isPremiumUser}
    />}
    
    {showWantadPublished===true ?
    <ErrorMessage/>: 
    showWantadPublished===false ? 
    handleCreateWantAdUi() : <></>}
  </>;
};

export default CreateAWantAdNext;
