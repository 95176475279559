/**
 * @file   src\components\AgentWantAd\WantAdTiming.js
 * @brief  This file is responsible for handling timelines in want-ad creation.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { getTimeline } from "../../services/commonServices";
import { handleRadioButonChange } from "../../actions/common";
import { BUY } from "../../constants/common";

const WantAdTiming = (props) => {
  const {handleFormValues, timeLine , adType, timeLineError} = props;
  const [timeLineList, setTimeLineList] = useState([]);

  useEffect(() => {
   
    getTimeline(adType ?? BUY)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const timeLines = _.get(response, "result", null);
          setTimeLineList(timeLines);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  /**
   * To handle timeline selection
   * @param {*} e 
   * @param {*} timeLineText 
   */

  const getSelectedValue = (e, timeLineText) => {
    const value = handleRadioButonChange(e);
    if (value) {
      handleFormValues({ timeLine: value, tlText : timeLineText, focusOut: true });
    }
  };

  /**
   * To load timelines view during page initialization
   * @returns 
   */
  const loadTimeLines = () => {
    const timeLines =
      timeLineList &&
      timeLineList.map((tl) => {
        const timeLineId = _.get(tl, "TimeLineId");
        const timeLineText = _.get(tl, "TimeLineText", "");
        return (
            <label className="radio-wrap mb-3" key={timeLineId}>
              <input
                type="radio"
                name="radio_timing"
                value={timeLineId}
                onChange={(e) => getSelectedValue(e, timeLineText)}
                checked={timeLine && timeLine == timeLineId}
              />
              <span className={timeLineError && "red-marked-span"}>
                <div className="wantad-type-info">
                  <h2  className={timeLineError ? "pb-0 red-marked-subheading" : "pb-0" }>{timeLineText}</h2>
                </div>
              </span>
            </label>
        );
      });

    return timeLines;
  };
  return (
      <>
        {loadTimeLines()}
        </>

  );
};
export default WantAdTiming;
