import { useState } from "react";
import AddPropertyIcon from "../../../images/add-property-plus-icon.svg";

import { saveAgentClient } from "../../../services/agentClientsService";
import { toast } from "react-toastify";
import AddNewClientModal from "../../../components/MemberWantAd/AddNewClientModal";
import AddPropertyPlus from "../../../images/add-property-plus-icon.svg";

const AddClient = (props) => {
  const { userId } = props
  const [showAddClient, setShowAddClient] = useState(false);
  const handleClose = () => setShowAddClient(false);
  const handleShow = () => setShowAddClient(true);

  const addClientData = async (data) => {
    var param = {
      ...data,
    }
    await saveAgentClient(param)
      .then((response) => {
        if (response.errorCode === 0) {
          const userDetails = getLocalStorage(USER_INFO_KEY);
          const updatedUserDetails = [...userDetails, { ClientCount: userDetails?.ClientCount + 1 }];

          setLocalStorage(USER_INFO_KEY, updatedUserDetails);
          if (response.result.agentExist) {
            toast.success(response.message);
          } else {
            toast.success("Added client successfully!");
            handleClose();
          }
          // toast.success("Added client successfully!")

        } else {
          return;
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

  }

  return (
    <a role="button"

      href={void 0}
      onClick={() => handleShow()}

    >
      <div className="my-property-mob pt-0">
        <div className="add-property-smallbtn-mob">
          <img src={AddPropertyPlus} />
        </div>
        <div className="my-property-details-mob">
          <h3 className="mb-0">Add Client</h3>

        </div>
        </div>
        {/*     
      <div className="add-your-property-outermob">
        <div className="add-your-property-mob">
          <div className="add-property-smallbtn-mob">
            <img src={AddPropertyPlus} />
          </div>
          <div className="add-property-details-mob">
            <h3 className="mb-0">Add Client</h3>

          </div>

        </div>
      </div> */}

    </a>

  );
};

export default AddClient;
