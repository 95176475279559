import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLocalStorage, getLocalStorage, getUserId,
  removeLocalStorage, loggedInUser, capitalizeFirstString,
  thousandSeperatedValue, replaceComma, trimString, createS3Folder, upperCaseEachSentenceFirst,
  checkTutorialDisplayStatus,
  saveTutorialStatusToLocal
} from "../../helpers/common";
import { MEMBER_INTRODUCTION, AGENT_INTRODUCTION, INTRODUCTION_AMOUNT, INTRODUCTION_TAX, USER_TYPE_AGENT } from "../../constants/common";
import AdIntroIcon from "../../images/ad-intro-icon.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
// import Tooltip from "react-bootstrap/Tooltip";
import { fetchPropertyList, fetchInventoryDetailsById } from "../../actions/inventory";
import TextBox from "../Common/TextBox";
import { handleTextBoxChange } from "../../actions/common";
import TextArea from "../Common/TextArea";
import PropertySelection from "./PropertySelection";
import ReviewOrderModal from "./ReviewOrderModal";
import { saveIntroductions } from "../../services/introductionService";
import SuccessModal from "./SuccessModal";
import { toast } from "react-toastify";
import AgentPropertySelection from "./AgentPropertySelection";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { getIntroClients } from "../../actions/agentClients";
import { convertToNumber, removeNegativeInput } from "../../helpers/validations";
import { textValidations } from "../../helpers/validations";
import { ERROR_TEXT } from "../../constants/common";
import { addIntroduction, addMessage, loginOrSignUp } from "../../helpers/message";
import { getS3FolderName, saveTutorialStatus } from "../../services/commonServices";
import ToolTip from "../Common/ToolTip";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import DocumentationTutorial from "../Tutorial/DocumentationTutorial";
import IntroTutorialModal from "../Tutorial/IntroTutorialModal";
import IntroductionTutorialIconStep1 from "../../images/tut-property-icon.svg";
import IntroductionTutorialIconStep2 from "../../images/tut-askingprice-icon.svg";
import IntroductionTutorialIconStep3 from "../../images/tut-custommsg-icon.svg";
import IntroductionTutorialIconStep4 from "../../images/tut-send-icon.svg";



const SendIntroduction = ({ ...props }) => {
  const tutorialPage = 5;//Want add tutorial

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertyList = useSelector((state) => state.inventory.propertyList);
  const propertyListExist = ((propertyList?.propertyList && propertyList?.propertyList.length > 0)
    || (propertyList?.defaultProperty && propertyList?.defaultProperty.Inv_Id)) ? true : false;

  const propertyListItems = propertyList?.propertyList;
  const defaultProperty = propertyList?.defaultProperty?.Inv_Id ? propertyList?.defaultProperty : (propertyList?.propertyList && propertyList?.propertyList[0]);
  const wantAdId = props.wantAdId;

  const clientList = useSelector((state) => state.agentClients.clients);
  const clientListExist = clientList?.ClientList && clientList?.ClientList.length > 0;
  const clientListItems = clientList?.ClientList;
  const defaultClient = clientList?.DefaultClient?.ClientId ? clientList?.DefaultClient : (clientList?.ClientList && clientList?.ClientList[clientList?.ClientList.length - 1]);
  const [selectedClient, setSelectedClient] = useState('');
  const [displayTutorial, setDisplayTutorial] = useState(false);


  const inventoryDetails = useSelector((state) => state.inventory.inventoryDetails);

  const WantCreatedUserName = props.WantCreatedUserName;
  const WantCreatedUserId = props.WantCreatedUserId;
  const wantAdClientId = props.wantAdClientId;
  const wantAdDetails = props.wantAdDetails;


  const [selectedProperty, setSelectedProperty] = useState('');
  //form States
  const [formData, setFormData] = useState('');
  const [memberIntroduction, setMemberIntroduction] = useState('');

  const [showReviewOrderModal, setReviewOrderModal] = useState(false);
  const ReviewOrderModalClose = () => setReviewOrderModal(false);
  const ReviewOrderModalShow = () => setReviewOrderModal(true);

  const [showSuccessModal, setSuccessModal] = useState(false);
  const SuccessModalClose = () => setSuccessModal(false);
  const SuccessModalShow = () => setSuccessModal(true);

  const [showIntroTutorialModal, setIntroTutorialModal] = useState(false);
  const IntroTutorialModalClose = () => setIntroTutorialModal(false);
  const IntroTutorialShow = () => setIntroTutorialModal(true);


  const [reload, setReload] = useState(0);
  const [messageCharCount, setMessageCharCount] = useState(0);
  const [msgError, setMsgError] = useState('');

  const [isPremiumUser , setIsPremiumUser] = useState(null);
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );


  const userId = getUserId();
  const userDetails = loggedInUser();
  const userType = userDetails?.userInfo?.userType;

  const [isFree, setIsFree] = useState(false);
  let intoLinkDisplayStatus = checkTutorialDisplayStatus(tutorialPage)
  const [showIntroLink, setIntroLink] = useState(intoLinkDisplayStatus);
  const [isLoding, setIsLoading] = useState(false);

  const [messageCursorPos, setMessageCursorPos] = useState(0);
  const [askingPriceCursorPos, setAskingPriceCursorPos] = useState(0);
  const messageRef = useRef();
  const askingpriceRef = useRef();


  let session = userType == 2 ? AGENT_INTRODUCTION : MEMBER_INTRODUCTION;

  useEffect(() => {
    if(isPremiumUser){dispatch(getIntroClients(userId))}
  }, [userId, reload, isPremiumUser]);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } 
  }, [userDetailsFromStore]);


  useEffect(() => {
    let params = {};

    if (userType == 2 && isPremiumUser) {
      if (formData?.clientId >= 0) {
        params = {
          "wantAdId": wantAdId,
          "clientId": formData?.clientId
        }
        dispatch(fetchPropertyList(params))

      }
    } else {
      params = {
        "wantAdId": wantAdId,
      }
      dispatch(fetchPropertyList(params))
    }

  }, [formData?.clientId, isPremiumUser]);



  useEffect(() => {

    setSelectedProperty(defaultProperty);
    let initailData = {
      userId: userId,
      wantAdId: wantAdId,
      defaultAskingPrice: defaultProperty?.AskingPrice,
      selectedProperty: defaultProperty,
      inventoryId: defaultProperty?.Inv_Id,
    }
    setFormData(formData => ({ ...formData, ...initailData }));
    handlePropertyChange(defaultProperty);
  }, [propertyList])

  useEffect(() => {
    setSelectedClient(defaultClient);
    let initailData = {
      selectedClient: defaultClient,
      clientId: defaultClient?.ClientId
    }
    setFormData(formData => ({ ...formData, ...initailData }));
  }, [clientList])

  useEffect(() => {
    setLocalStorage(session, formData);
    setMemberIntroduction(getLocalStorage(session));
  }, [formData])

  useEffect(() => {
    if (formData?.inventoryId) {
      dispatch(fetchInventoryDetailsById(formData?.inventoryId))
    }

  }, [formData?.inventoryId])


  useEffect(() => {
    messageRef.current.setSelectionRange(messageCursorPos, messageCursorPos);
  }, [messageCursorPos]);

  useEffect(() => {
      askingpriceRef.current.setSelectionRange(askingPriceCursorPos, askingPriceCursorPos);
  }, [askingPriceCursorPos]);


  /**
  * To handle textbox value change
  * @param {any} event 
  */
  const onChangeText = (event) => {
    let value = handleTextBoxChange(event);
    if (event.target.name == 'message') {
      value = upperCaseEachSentenceFirst(value);
      setMessageCharCount(value.length);
      if (trimString(value) !== "" && textValidations(value)) {
        setMsgError('')
        setMessageCursorPos(event.target.selectionStart)
      } else {
        setMsgError(trimString(value) !== "" ? ERROR_TEXT : '')
      }

    }
    if (event.target.name == 'askingPrice') {
      value = removeNegativeInput(value);
      value = replaceComma(value)
      
     
      if ((value) !== "" ) {
        if( (thousandSeperatedValue(memberIntroduction?.askingPrice)?.length + 1) == thousandSeperatedValue(event.target.value)?.length){
          setAskingPriceCursorPos(event.target.selectionStart)
         } else if((thousandSeperatedValue(memberIntroduction?.askingPrice)?.length + 2) == thousandSeperatedValue(event.target.value)?.length){
          setAskingPriceCursorPos((event.target.selectionStart + 1) )
        } else{
          setAskingPriceCursorPos(event.target.selectionStart)
        }
       
      } 
    }

    setFormData(formData => ({ ...formData, [event.target.name]: value }));
  }

  const handlePropertyChange = (property) => {
    setSelectedProperty(property)
    setFormData(formData => ({
      ...formData, "inventoryId": property?.Inv_Id, "selectedProperty": property,
      defaultAskingPrice: property?.AskingPrice, askingPrice: '', message: ''
    }));
    // dispatch(fetchInventoryDetailsById(property?.Inv_Id))
  }

  const handleClientChange = (client) => {

    setFormData(formData => ({ "clientId": client?.ClientId, "selectedClient": client }));

    // dispatch(fetchPropertyList({
    //   "userId": userId,
    //   "wantAdId": wantAdId,
    //   "clientId": client?.ClientId

    //   // "userId": 93,
    //   // "wantAdId": 1051
    // }))
  }

  useEffect(() => {
    let photosAfterRemovingInvalid = inventoryDetails?.photos && inventoryDetails?.photos.filter(
      (item) => item.IsValid != 0
    );
    let photos = photosAfterRemovingInvalid && photosAfterRemovingInvalid.map((photo, index) => {
      return { ...photo, order: index + 1 };
    });


    let inventoryData = {
      originalPhotos: photos, // data from db to customize
      photosForPreview: photos, // data from db to customize
      originalpropertyFeatures: inventoryDetails?.nice2Have, // data from db to customize
      propertyFeaturesForPreview: inventoryDetails?.nice2Have, // data from db to customize
      noBedRm: inventoryDetails?.noBedRm,
      noBathRm: inventoryDetails?.noBathRm,
      sqFt: inventoryDetails?.sqFt,
      lotSize: inventoryDetails?.lotSize,
      locAddress: inventoryDetails?.locAddress,
      salesPercnt: inventoryDetails?.salesPercnt,
      insReport: inventoryDetails?.insReport,
      apprOnFile: inventoryDetails?.apprOnFile,
      floodCert: inventoryDetails?.floodCert,
      s3ImageFolder: inventoryDetails?.s3ImageFolder,
      propertyTypeId: inventoryDetails?.prprtyType,
      acreage: inventoryDetails?.acreage,
      lotType: inventoryDetails?.lotType,
      timeLineTxt: inventoryDetails?.timeLineTxt,
      floorPlan: inventoryDetails?.floorPlan,
      sellerFin: inventoryDetails?.sellerFin,
      mapImg: inventoryDetails?.mapImg,
      city: inventoryDetails?.city,
      state: inventoryDetails?.state,
      zip: inventoryDetails?.zip,
      lat: inventoryDetails?.lat,
      lng: inventoryDetails?.lng


    }

    if (inventoryDetails?.s3ImageFolder == '') {
      const userId = getUserId();
      getFolderName();

    }

    setFormData(formData => ({ ...formData, ...inventoryData }));
  }, [inventoryDetails])

  const getFolderName = async (userId) => {
    const isWantAd = false
    await createS3Folder(isWantAd).then(function (result) {
      let folderName = {
        s3ImageFolder: result,
      }
      setFormData(formData => ({ ...formData, ...folderName }));
    })
  }

  const updateReloadParam = () => {
    setReviewOrderModal(true);
  }

  const updateClientReload = () => {
    setReload(reload + 1)
  }
  const updateCardParam = (paymentMethod) => {
    let cardData = {
      "cardId": paymentMethod?.Payment_Mode_Id,
      "cardData": paymentMethod
    }
    setFormData(formData => ({ ...formData, ...cardData }));
    // setReviewOrderModal(true);
  }

  const handleCheckBoxItemClick = (e) => {
    setFormData(formData => ({ ...formData, isAdhereFFHAct : e.target.checked }));

  };

  const handleIsRepresentOwnerCheckBoxItemClick = (e) => {
    setFormData(formData => ({ ...formData, isRepresentOwner: e.target.checked }));

  };


  const onSavePropertyFeatureOrder = (orderdProperty) => {

    let selectedCoverPhotos = memberIntroduction?.photosForPreview?.filter((item) => item.isCover == 1)
    let newArray = {
      coverPhotos: selectedCoverPhotos,
      propertyFeatures: orderdProperty,
      originalpropertyFeatures: orderdProperty
    }
    setFormData(formData => ({ ...formData, ...newArray }));
    setReviewOrderModal(true)
  }

  const SavePhotoOrder = (photoOrder) => {
    let selectedCoverPhotos = photoOrder?.filter((item) => item.isCover == 1)
    let coverPhotos = {
      // coverPhotos: selectedCoverPhotos,
      photosForPreview: photoOrder,

    }
    setFormData(formData => ({ ...formData, ...coverPhotos }));

  }

  // const SaveChanges = () =>{
  //   let selectedCoverPhotos = selectedProperty?.originalPhotos?.filter((item) => item.isCover == 1)
  //   let newArray ={
  //     coverPhotos: selectedCoverPhotos,
  //     propertyFeatures:  selectedProperty?.originalpropertyFeatures,
  //   }

  // }


  useEffect(() => {
    let email = _.get(userDetails, "userInfo.email", null);
    let login = loginOrSignUp(email);

  }, [])

  const SendIntroduction = async () => {
    setIsLoading(true);

    let data = {
      wantAdId: wantAdId,
      wantAd_clientId: wantAdClientId,
      wantAd_UserId: WantCreatedUserId,
      inventoryId: memberIntroduction?.inventoryId,
      clientId: memberIntroduction?.clientId, // for agent
      askingPrice: memberIntroduction?.askingPrice ? memberIntroduction?.askingPrice : memberIntroduction?.defaultAskingPrice,
      message: memberIntroduction?.message,
      cardId: memberIntroduction?.cardId,
      amount: INTRODUCTION_AMOUNT,
      tax: INTRODUCTION_TAX,
      total: convertToNumber(INTRODUCTION_AMOUNT) + convertToNumber(INTRODUCTION_TAX),
      coverPhotos: memberIntroduction?.coverPhotos ? memberIntroduction?.coverPhotos : memberIntroduction?.originalPhotos,
      propertyFeatures: memberIntroduction?.propertyFeatures ? memberIntroduction?.propertyFeatures : memberIntroduction?.originalpropertyFeatures,
      s3ImageFolder: memberIntroduction?.s3ImageFolder,

      // data for firebase
      WantAdText: wantAdDetails?.adTitle,
      wantAdLocation: wantAdDetails?.location,
      wantAdClientName: wantAdDetails?.clientName ? wantAdDetails?.clientName : '',
      wantAdCreatedUserFirstName: wantAdDetails?.userFirstName,
      wantAdCreatedUserLastName: wantAdDetails?.userLastName,
      wantAdCreatedId: wantAdDetails?.userId,
      inventroyCreatedId: _.get(userDetails, "userInfo.userId", null),
      inventroyUserName: _.get(userDetails, "userInfo.name", null),
      lastestMessageSentUserId: _.get(userDetails, "userInfo.userId", null),
      wantAdPrprtyType: wantAdDetails?.prprtyType,
      isRepiled: false,
      isDeleted: false,
      isAccepted: false,
      acceptedTime: '',
      inventoryPhoto: memberIntroduction?.selectedProperty.photo,
      inventoryLocation: memberIntroduction?.locAddress,
      inventoryTypeId: memberIntroduction?.propertyTypeId,
      isFree: isFree ? 1 : 0,
      isAdhereFFHAct : memberIntroduction?.isAdhereFFHAct == true ? 1 : 0,
      isRepresentOwner:  memberIntroduction?.isRepresentOwner == true ? 1 : 0,

    }
    await saveIntroductions(data)
      .then((response) => {
        setIsLoading(false);
        if (response.errorCode == 0) {


          if (response.result.status == "created") {
            // if(memberIntroduction?.message){
            // sendMessage(response.result.introductionId);
            // console.log(response.result.introductionId);
            // }
            setReviewOrderModal(false);
            setFormData(formData => ({ ...formData, message: '', askingPrice: '' }));
            removeLocalStorage(session);

            setSuccessModal(true)
            let uploadImages = data.coverPhotos.length;
            let inValidImages = uploadImages - response.result?.validImageCount;
            if (inValidImages > 0) {
              let message = "Oops! You’ve uploaded invalid images. One or more images that you have uploaded for the following property do not conform to our image policies. Invalid images are not included in this introduction."
              toast.error(message)
            }


          } else {
            toast.error(response.message)
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });


  }

  const sendMessage = async (introId) => {

    let messageDoc = {
      filePath: '',
      createdUserId: _.get(userDetails, "userInfo.userId", null),
      // sentUserId: 3,
      uniqueId: introId,
      text: memberIntroduction?.message,
    };

    // let login = loginOrSignUp(email);
    // if (login) {


    if (memberIntroduction?.message) {

      await addMessage(introId, messageDoc).then(
        async (response) => {
          if (response == 1) {
            // setMessageSent(1);
          }
        }
      )
    }

    let introductionData = {
      IntroId: introId,
      wantAdId: wantAdDetails?.wantAdId,
      WantAdText: wantAdDetails?.adTitle,
      wantAdLocation: wantAdDetails?.location,
      wantAdClientName: wantAdDetails?.clientName ? wantAdDetails?.clientName : '',
      wantAdCreatedUserFirstName: wantAdDetails?.userFirstName,
      wantAdCreatedUserLastName: wantAdDetails?.userLastName,
      wantAdCreatedId: wantAdDetails?.userId,
      inventroyCreatedId: _.get(userDetails, "userInfo.userId", null),
      invetroyUserName: _.get(userDetails, "userInfo.name", null),
      lastestMessageSentUserId: _.get(userDetails, "userInfo.userId", null),
      wantAdPrprtyType: wantAdDetails?.prprtyType,
      latestMessage: memberIntroduction?.message,
      isRepiled: false,
      isDeleted: false,
      isAccepted: false,
      acceptedTime: '',
      propertyPhoto: memberIntroduction?.selectedProperty.photo,
      propertyLocation: memberIntroduction?.locAddress,
      propertyTypeId: memberIntroduction?.propertyTypeId,
      inventoryId: memberIntroduction?.inventoryId,


    }
    await addIntroduction(introId, introductionData).then(
      async (response) => {
        if (response == 1) {
          setReviewOrderModal(false);
          setFormData(formData => ({ ...formData, message: '', askingPrice: '' }));
          removeLocalStorage(session);

          setSuccessModal(true)
        }
      }
    )
    // }
  }


  const savePropertyFeaturesforPreview = (orderdProperty) => {
    let newArray = {
      propertyFeaturesForPreview: orderdProperty,
    }
    setFormData(formData => ({ ...formData, ...newArray }));
  }
  const setCustomizeIntroCancel = () => {
    setReviewOrderModal(true);
    let newArray = {
      propertyFeaturesForPreview: memberIntroduction?.originalpropertyFeatures,
      photosForPreview: memberIntroduction?.originalPhotos
    }
    setFormData(formData => ({ ...formData, ...newArray }));

  }

  //Tutorial documentation section
  //Defining tutorial steps
  const acceptTutorial = () => {
    setIntroTutorialModal(false)
    setDisplayTutorial(true);
  }
  const [tutorialSteps, setTutorialSteps] = useState(
    [
      {
        target: '#propertySelection',
        disableBeacon: true,
        placement: "left",
        title: "Choose a property to share",
        icon: IntroductionTutorialIconStep1,
        content: 'If you have multiple properties, you can swap out which one you want to introduce.',
        hideCloseButton: true,
        showProgress: true,
        showSkipButton: true
      },
      {
        target: '.property-price',
        disableBeacon: true,
        placement: "bottom",
        title: "Adjust asking price",
        icon: IntroductionTutorialIconStep2,
        content: 'This is just conversation starter. So try not to overthink it. You can always change it later.',
        hideCloseButton: true,
        showProgress: true,
        showSkipButton: true
      },
      {
        target: '.property-message',
        disableBeacon: true,
        placement: "top",
        title: "Write a custom message",
        icon: IntroductionTutorialIconStep3,
        content: 'This is your first chance to tell them why you think your property is a perfect match.',
        hideCloseButton: true,
        showProgress: true,
        showSkipButton: true
      },
      {
        target: '.sendMessageBtn',
        disableBeacon: true,
        placement: "left",
        title: "Review and send",
        icon: IntroductionTutorialIconStep4,
        content: "You'll get another chance to review your introduction before you finalize and send.",
        hideCloseButton: true,
        showProgress: true
      }
    ]
  );
  //Tutorial tooltip
  const TutorialToolTip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    size,
    isLastStep,
    skipProps
  }) => (

    <>
      <div {...tooltipProps}>
        <DocumentationTutorial continuous={continuous}
          index={index}
          step={step}
          backProps={backProps}
          skipProps={skipProps}
          primaryProps={primaryProps}
          isLastStep={isLastStep}
          size={size}
          finalStepBtn={'Got It!'}
        />
      </div>
    </>


  );
  //Tutorial status call back
  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type, lifecycle } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle == 'complete') {
      setDisplayTutorial(false);
      setIntroLink(false)
      saveTutorialStatusToLocal(tutorialPage)
      await saveTutorialStatus(tutorialPage, 1); //Save status
    }
  }

  return (
    <>
      {displayTutorial ? <JoyRide
        steps={tutorialSteps}
        tooltipComponent={TutorialToolTip}
        continuous
        callback={handleJoyrideCallback}
        disableScrolling={true}
      /> : ''}
      <div className="ad-strengthen-wrap mb-0 mb-sm-4">
        <div className="icon-wrap">
          <img src={AdIntroIcon} alt={AdIntroIcon} />
        </div>
        <h6>Send Them Your Property</h6>
        <p className="mb-4">
        Do you own or represent a property that sounds like a match? Introduce it!
        </p>
        {

          (userType == USER_TYPE_AGENT && isPremiumUser) ? (
            <AgentPropertySelection
              selectedProperty={memberIntroduction}
              propertyList={propertyListItems}
              clientList={clientListItems}
              handlePropertyChange={(property) => { handlePropertyChange(property) }}
              handleClientChange={(client) => { handleClientChange(client) }}
              updateClientReload={() => { updateClientReload() }}
              userType={userType}
              propertyListExist={propertyListExist}
              wantAdId={wantAdId}
            />
          ) : (
            <PropertySelection selectedProperty={memberIntroduction}
              propertyList={propertyListItems}
              // selectedPropertyId={selectedProperty?.Inv_Id}
              handlePropertyChange={(property) => { handlePropertyChange(property) }}
              propertyListExist={propertyListExist}
              userType={userType}
              wantAdId={wantAdId} />
          )
        }

        <div className="addyour-property-details-wrap position-relative property-price">
          <div className="property-dollar-icon">$</div>
          <ToolTip
            title={
              "This number is meant to start a conversation and can be changed as you negotiate"
            }
          />

          <label className="form-label">Asking price</label>
          <TextBox
            id="askingprice"
            name="askingPrice"
            type="text"
            value={formData?.askingPrice ? thousandSeperatedValue(formData?.askingPrice) : ''}
            placeholder={(memberIntroduction?.defaultAskingPrice ? (thousandSeperatedValue(memberIntroduction?.defaultAskingPrice)) : 0)}
            onChange={(e) => {
              onChangeText(e);
            }}
            class="property-txt-fld property-txt-leftpad"
            txtRef={askingpriceRef}
            disabled={selectedProperty?.Inv_Id && selectedProperty?.photo &&
              (
                ((selectedProperty?.IsIntroSent == 1 && selectedProperty?.IsCancelled == 1)
                  || (selectedProperty?.IsIntroSent == 0 || !selectedProperty?.IsIntroSent))
                && (selectedProperty?.IsDecline == 0 || !selectedProperty?.IsDecline)
                && (selectedProperty?.NotInterested == 0 || !selectedProperty?.NotInterested)
                && (selectedProperty?.Not_A_Fit == 0 || !selectedProperty?.Not_A_Fit)
              )
              ? false : true}
          // disabled={selectedProperty?.Inv_Id && !selectedProperty?.IntroDeclineDate && !selectedProperty?.IntroSentDate ? false : true}
          />
        </div>

        <div className="addyour-property-details-wrap mb-12 property-message">
          {/* <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span className="price-info-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.75 11.25C8.75 11.3983 8.70602 11.5433 8.6236 11.6667C8.54119 11.79 8.42406 11.8861 8.28701 11.9429C8.14997 11.9997 7.99917 12.0145 7.85368 11.9856C7.7082 11.9566 7.57456 11.8852 7.46967 11.7803C7.36478 11.6754 7.29335 11.5418 7.26441 11.3963C7.23548 11.2508 7.25033 11.1 7.30709 10.963C7.36386 10.8259 7.45999 10.7088 7.58333 10.6264C7.70666 10.544 7.85167 10.5 8 10.5C8.19892 10.5 8.38968 10.579 8.53033 10.7197C8.67098 10.8603 8.75 11.0511 8.75 11.25ZM8 4.5C6.62125 4.5 5.5 5.50938 5.5 6.75V7C5.5 7.13261 5.55268 7.25979 5.64645 7.35355C5.74022 7.44732 5.86739 7.5 6 7.5C6.13261 7.5 6.25979 7.44732 6.35356 7.35355C6.44732 7.25979 6.5 7.13261 6.5 7V6.75C6.5 6.0625 7.17313 5.5 8 5.5C8.82688 5.5 9.5 6.0625 9.5 6.75C9.5 7.4375 8.82688 8 8 8C7.86739 8 7.74022 8.05268 7.64645 8.14645C7.55268 8.24021 7.5 8.36739 7.5 8.5V9C7.5 9.13261 7.55268 9.25979 7.64645 9.35355C7.74022 9.44732 7.86739 9.5 8 9.5C8.13261 9.5 8.25979 9.44732 8.35356 9.35355C8.44732 9.25979 8.5 9.13261 8.5 9V8.955C9.64 8.74563 10.5 7.83625 10.5 6.75C10.5 5.50938 9.37875 4.5 8 4.5ZM14.5 8C14.5 9.28558 14.1188 10.5423 13.4046 11.6112C12.6903 12.6801 11.6752 13.5132 10.4874 14.0052C9.29973 14.4972 7.99279 14.6259 6.73192 14.3751C5.47104 14.1243 4.31285 13.5052 3.40381 12.5962C2.49477 11.6872 1.8757 10.529 1.6249 9.26809C1.37409 8.00721 1.50282 6.70028 1.99479 5.51256C2.48676 4.32484 3.31988 3.30968 4.3888 2.59545C5.45772 1.88122 6.71442 1.5 8 1.5C9.72335 1.50182 11.3756 2.18722 12.5942 3.40582C13.8128 4.62441 14.4982 6.27665 14.5 8ZM13.5 8C13.5 6.9122 13.1774 5.84883 12.5731 4.94436C11.9687 4.03989 11.1098 3.33494 10.1048 2.91866C9.09977 2.50238 7.9939 2.39346 6.92701 2.60568C5.86011 2.8179 4.8801 3.34172 4.11092 4.11091C3.34173 4.8801 2.8179 5.86011 2.60568 6.927C2.39347 7.9939 2.50238 9.09977 2.91867 10.1048C3.33495 11.1098 4.0399 11.9687 4.94437 12.5731C5.84884 13.1774 6.91221 13.5 8 13.5C9.45819 13.4983 10.8562 12.9184 11.8873 11.8873C12.9184 10.8562 13.4983 9.45818 13.5 8Z"
                  fill="black"
                />
              </svg>
            </span>
          </OverlayTrigger> */}
          <label className="form-label">Message (optional)</label>
          <TextArea
            name="message"
            id="message"
            className="form-control"
            errorMessage={msgError}
            value={formData?.message ? formData?.message : ''}
            maxLength={450}
            placeholder="Hey there! I think my property matches what you're looking for."
            onChange={(e) => {
              onChangeText(e);
            }}
            txtRef={messageRef}
            disabled={selectedProperty?.Inv_Id && selectedProperty?.photo &&
              (
                ((selectedProperty?.IsIntroSent == 1 && selectedProperty?.IsCancelled == 1)
                  || (selectedProperty?.IsIntroSent == 0 || !selectedProperty?.IsIntroSent))
                && (selectedProperty?.IsDecline == 0 || !selectedProperty?.IsDecline)
                && (selectedProperty?.NotInterested == 0 || !selectedProperty?.NotInterested)
                && (selectedProperty?.Not_A_Fit == 0 || !selectedProperty?.Not_A_Fit)
              ) ? false : true}
          // disabled={selectedProperty?.Inv_Id && !selectedProperty?.IntroDeclineDate && !selectedProperty?.IntroSentDate ? false : true}
          />
          <div className="des-count align-self-end">{messageCharCount}/450</div>
        </div>

        <Button
          variant="primary"
          className="w-100 mb-0 l-spacing-0 sendMessageBtn"
          disabled={selectedProperty?.Inv_Id && !msgError && selectedProperty?.photo &&
            (
              ((selectedProperty?.IsIntroSent == 1 && selectedProperty?.IsCancelled == 1)
                || (selectedProperty?.IsIntroSent == 0 || !selectedProperty?.IsIntroSent))
              && (selectedProperty?.IsDecline == 0 || !selectedProperty?.IsDecline)
              && (selectedProperty?.NotInterested == 0 || !selectedProperty?.NotInterested)
              && (selectedProperty?.Not_A_Fit == 0 || !selectedProperty?.Not_A_Fit)
            ) ? false : true}
          onClick={(e) => {
            e.preventDefault();
            setReviewOrderModal(true)
          }}
        >
          Review Introduction
        </Button>

        {showIntroLink && <Button
          variant=""
          className="w-100 l-spacing-0 btn-no-bg mt-3"
          onClick={(e) => {
            IntroTutorialShow()
          }}
        >
          Show me how this works</Button>}

        {/* <div className="intro-btm-sec">
          <div className="intro-lock-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 5H11V3.5C11 2.70435 10.6839 1.94129 10.1213 1.37868C9.55871 0.81607 8.79565 0.5 8 0.5C7.20435 0.5 6.44129 0.81607 5.87868 1.37868C5.31607 1.94129 5 2.70435 5 3.5V5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V6C14 5.73478 13.8946 5.48043 13.7071 5.29289C13.5196 5.10536 13.2652 5 13 5ZM6 3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5C8.53043 1.5 9.03914 1.71071 9.41421 2.08579C9.78929 2.46086 10 2.96957 10 3.5V5H6V3.5ZM13 13H3V6H13V13Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="intro-middle-wrap">
            <h3>Introductions are always private</h3>
            <p>
              No one other than the recipient will see your property.{" "}
              <a
                href={void 0}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Learn more
              </a>
            </p>
          </div>
        </div> */}
      </div>
      <IntroTutorialModal
        show={showIntroTutorialModal}
        onHide={IntroTutorialModalClose}
        accept={acceptTutorial}
      />

      <ReviewOrderModal
        show={showReviewOrderModal}
        onHide={ReviewOrderModalClose}
        updateReloadParam={() => { updateReloadParam() }}
        selectedProperty={memberIntroduction}
        updateCardParam={(paymentMethod) => updateCardParam(paymentMethod)}
        handleCheckBoxItemClick={(e) => handleCheckBoxItemClick(e)}
        handleIsRepresentOwnerCheckBoxItemClick={(e) => handleIsRepresentOwnerCheckBoxItemClick(e)}
        SendIntroduction={() => SendIntroduction()}
        WantCreatedUserName={WantCreatedUserName}
        onSavePropertyFeatureOrder={(orderdProperty) => { onSavePropertyFeatureOrder(orderdProperty) }}
        SavePhotoOrder={(photoOrder) => { SavePhotoOrder(photoOrder) }}
        viewReviewModal={() => { setCustomizeIntroCancel() }}
        savePropertyFeaturesforPreview={(orderdProperty) => { savePropertyFeaturesforPreview(orderdProperty) }}
        setIsFree={setIsFree}
        isLoding={isLoding}
      />

      <SuccessModal
        show={showSuccessModal}
        onHide={SuccessModalClose} />


    </>
  );

};
export default SendIntroduction;
