import React, {useState, useEffect } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Modal } from "react-bootstrap";
import { BUY, LONG_RENT, SHORT_RENT } from "../../../constants/common";

const AdBtnsWrap = (props) => {
  const { handleAdTypeClick, adType} = props;
  return (
    <>
    {/* <div className="adBtnsWrap d-flex justify-content-between ">
    <div className={adType === BUY && "btn-select" }>
        <Button variant="outline-secondary" id="button-addon2"  className="d-flex btnmr-right-8" onClick={()=>handleAdTypeClick(BUY)}>
          <span className="buyAdIcn"></span>  &nbsp;
          <span>Buy Ads</span>
        </Button>
    </div>
    <div className={adType === LONG_RENT && "btn-select" }>
        <Button variant="outline-secondary" id="button-addon2" className="d-flex" onClick={()=>handleAdTypeClick(LONG_RENT)}>
          <span className="rentAdIcn"></span>  &nbsp;
          <span>Rent Ads</span>
        </Button>
    </div>
    <div  className={adType === SHORT_RENT ? "btn-select me-0" :  " me-0"}>
        <Button variant="outline-secondary" id="button-addon2" className="d-flex btnmr-left-8" onClick={()=>handleAdTypeClick(SHORT_RENT)}>
        <span className="stayAdIcn"></span>  &nbsp;
          <span>Stay Ads</span>
        </Button>
    </div>
  </div> */}
  <div className="adBtnsWrap d-flex justify-content-between">
  <div className={adType === BUY ? "w-50 btn-select pr-6" : "w-50 pr-6"}>
        <Button variant="outline-secondary" id="button-addon2"  className="d-flex w-100 justify-content-center ln-20" onClick={()=>handleAdTypeClick(BUY)}>
          <span className="buyAdIcn"></span>  &nbsp;
          <span>Wanted to Buy</span>
        </Button>
    </div>
    <div  className={adType === LONG_RENT ? " btn-select w-50 pl-6" : "w-50 pl-6"}>
        <Button variant="outline-secondary" id="button-addon2" className="d-flex w-100 justify-content-center ln-20" onClick={()=>handleAdTypeClick(LONG_RENT)}>
        <span className="rentAdIcn"></span>  &nbsp;
          <span>Wanted to Rent</span>
        </Button>
    </div>
  </div>
  </>
  );
};
export default AdBtnsWrap;
