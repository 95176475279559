import React, { useState, useEffect } from "react";
import ProgressBarInventory from "./ProgressBarInventory";
import { getTimeline } from "../../../services/commonServices";
import {
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  INVENTORY_STRENGTHEN,
  INVENTORY_TYPE,
} from "../../../constants/common";
import { getLocalStorage, setBodyBackgroundWhite, setLocalStorage } from "../../../helpers/common";

const AddTimeLine = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    setCompletedPercentage,
    isAgent,
    isEdit,
    isStrengthenInventory,
  } = props;

  const [disableSave, setDisableSave] = useState(true);
  const timeLine = _.get(formValues, "timeLine", null);
  const timeLineInSession = _.get(getLocalStorage(INVENTORY_KEY), "timeLine", null);
  const [timeLineList, setTimeLineList] = useState([]);

  useEffect(() => {
    setBodyBackgroundWhite()
    if (!isEdit) {
      if (timeLine) {
        setDisableSave(false);
      } else {
        const isDisabled = true;
        setDisableSave(isDisabled);
      }
    }

    getTimeline(INVENTORY_TYPE)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const timeLines = _.get(response, "result", null);
          setTimeLineList(timeLines);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const getSelectedValue = (timeLineId, timeLineText) => {
    if (isEdit) {
      if (timeLineId != timeLineInSession) {
        setDisableSave(false);
      } else {
        setDisableSave(true);
      }
    } else {
      setDisableSave(false);
    }
    handleFormValues({ timeLine: timeLineId, tlText: timeLineText });
  };

  const loadTimeLines = () => {
    const timeLines =
      timeLineList &&
      timeLineList.map((tl) => {
        const timeLineId = _.get(tl, "TimeLineId");
        const timeLineText = _.get(tl, "TimeLineText", "");
        return (
          <a
            href={void 0}
            className={
              timeLine === timeLineId
                ? "createAd-content-wrap mb-4 active"
                : "createAd-content-wrap mb-4"
            }
            onClick={(e) => {
              e.stopPropagation();
              getSelectedValue(timeLineId, timeLineText);
            }}
          >
            <span className="labelTxt">{timeLineText}</span>
          </a>
        );
      });

    return timeLines;
  };
  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
          />

          <div className="inner-layout-content-new bg-white pb-0 position-relative">
            <div className="select-plan-wrap pl-0 pr-0 pt-0 position-relative">
              <div className="layout-content-btm-sec layout-content-btm-sec-stickyfix">
                <h6 className="">Timeline</h6>
                <h2 className="">
                  When will your client’s property be available?
                </h2>
              </div>

              {loadTimeLines()}
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if (isStrengthenInventory) {
                    setCompletedPercentage(100);
                  } else {
                    if (completedPercentage > 95) {
                      setCompletedPercentage(completedPercentage);
                    } else {
                      setCompletedPercentage(95);
                    }
                  }
                  setTimeout(() => {
                    if (isStrengthenInventory) {
                      setLocalStorage(
                        INVENTORY_STRENGTHEN,
                        getLocalStorage(INVENTORY_KEY_TEMP)
                      );
                    } else {
                      setLocalStorage(
                        INVENTORY_KEY,
                        getLocalStorage(INVENTORY_KEY_TEMP)
                      );
                    }
                    setUIKey(null);
                  }, 500);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTimeLine;
