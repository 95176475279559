/**
 * @file   src\components\MemberWantAd\EditWantAd\WantAdRentPropertyTypes.js
 * @brief  This file is responsible for handling property type for rent type in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from "react";
import { getPropertyTypes } from "../../../services/commonServices";
import { handleRadioButonChange } from "../../../actions/common";
import { usePrevious } from "../../../hooks/usePrevious";

const WantAdRentPropertyTypes = (props) => {
  const {
    handleFormValues,
    propertyType,
    adType,
    prptySubTypes,
    clearPreviousStates,
    propertyTypeError,
    subPropertyTypeError
  } = props;
  const [propertyList, setPropertyList] = useState([]);
  const [propertySubItems, setPropertySubItems] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState();
  useEffect(() => {
    if (propertyType) {
      if (Number(propertyType) === 10) {
        getProperiesSubItems("entirespace");
      } else if (Number(propertyType) === 11) {
        getProperiesSubItems("privateroom");
      } 
    }
   
   
    handlePropertySubTypes();
    const typeOfAd = "rent"; //adType === BUY ? "buy" : "rent";
    getProperties(typeOfAd);
  }, [propertyType]);

  const getProperties = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
       
          // if(!propertyType){
          //   handleFormValues({ prprtyType: propertyTypes[0].PropertyTypeId, prptyTypeTxt: propertyTypes[0].PropertyTypeText });
          // }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handlePropertySubTypes = () => {
      if (Number(propertyType) === 10 || Number(propertyType) === 11) {
       
        if(prptySubTypes.length !== 0 && propertySubItems.length ===  prptySubTypes.length ){
             setIsSelectAll(true)
         }
    } 
  };
  useEffect(() => {
    handlePropertySubTypes();
  }, [propertySubItems, prptySubTypes]);

  const getProperiesSubItems = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertySubItems(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const previousValue = usePrevious(propertyType);
  const handlePropertyClick = (e, typeText) => {
    setIsSelectAll(false)
    const value = handleRadioButonChange(e);
    if (value) {
      if (Number(value) === 10) {
        getProperiesSubItems("entirespace");
      } else if (Number(value) === 11) {
        getProperiesSubItems("privateroom");
      } else {
        setPropertySubItems([]);
      }
      clearPreviousSelectedValues(value);
      handleFormValues({ prprtyType: value, prptyTypeTxt: typeText });
    }
  };

  const handlePropertySubItemClick = (e) => {
    setIsSelectAll(false)
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray
      if(_.includes(prptySubTypes, Number(value))){
        subItemArray = prptySubTypes.filter((e) => e !== Number(value))
      } else {
          subItemArray = [
            ...prptySubTypes,
            Number(value),
          ];
      }
      handleFormValues({ prptySubTypes: subItemArray});
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    const isClearPrevious =
      !previousValue && (Number(previousValue) === 6 &&Number(currentSelectedValue) !== 6) 
      ||(Number(previousValue) !== 6 && Number(currentSelectedValue) === 6)
      ||(Number(previousValue) === 10 && Number(currentSelectedValue) === 11)
      ||(Number(previousValue) === 11 && Number(currentSelectedValue) === 10);
    if (isClearPrevious) {
      clearPreviousStates({ type: "clearErrorStateOnPropertySelection"});
    }
  };

  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const Img = _.get(pt, "Img", "");
        const Desc = _.get(pt, "Description", "");
        return (
          <div key={typeId} className="col-sm-12 col-lg-12 col-xl-12 col-xxl-12">
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio_property"
                value={typeId ? typeId : '' }
                checked={propertyType && propertyType == typeId || ''}
                onChange={(e) => handlePropertyClick(e, typeText)}
              />
              <span className={propertyTypeError && "red-marked-span"}>
                <div className="wantad-type-info">
                <h2 className={propertyTypeError ? "pb-0 red-marked-subheading" :"pb-0 " }>{_.get(pt, "PropertyTypeText", "")}</h2>
                </div>
                {/* <div className="right-img-wrap">
                  {Img ? (
                    <img src={require("../../images/" + Img)} />
                  ) : (
                    <img src={HouseImg} />
                  )}
                </div> */}
              </span>
            </label>
          </div>
        );
      });

    return List;
  };

  

  const loadPropertySubTypes = () => {
    const List =
      propertySubItems &&
      propertySubItems.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const Img = _.get(pt, "Img", "");
        const isChecked = isSelectAll ? true : prptySubTypes && _.includes(prptySubTypes, typeId)
        return (
          <div key={typeId} className="col-sm-12 col-lg-12 col-xl-12 col-xxl-12">
            <label className="check-wrap mb-3">
              <input
                type="checkbox"
                name="pst-checkbox"
                id={typeId}
                value={typeId ? typeId : ''}
                checked={isChecked}
                onChange={(e) => handlePropertySubItemClick(e)}
              />
              <span className={subPropertyTypeError && "red-marked-span"}>
                <div className="wantad-type-info">
                  <h2 className={subPropertyTypeError ? "pb-0 red-marked-subheading" : "pb-0"}>{_.get(pt, "PropertyTypeText", "")}</h2>
                </div>
                {/* <div className="right-img-wrap">
                  {Img ? (
                    <img src={require("../../images/" + Img)} />
                  ) : (
                    <img src={HouseImg} />
                  )}
                </div> */}
              </span>
            </label>
          </div>
        );
      });

    return List;
  };

  const handleSelectAll = (e) => {
    const isSelectAll= e.target.checked
    if(isSelectAll) {
        const filteredArray = propertySubItems.map(p => p.PropertyTypeId);
          handleFormValues({ prptySubTypes: filteredArray});
    } else {
        handleFormValues({ prptySubTypes: []});
    }
    setIsSelectAll(isSelectAll)
  }

  return (
    <>
      <div className="wantad-type-wrap pb-0">
        <div className="row">{loadPropertyTypes()}</div>
      </div>
      {propertySubItems && propertySubItems.length > 0 ? (
        // <div className="wantad-type-wrap select-type-wrap">
        <>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className={subPropertyTypeError ? "sub-head mb-0 red-marked-subhead" : "sub-head mb-0"}>Select types *</h6>
            <div className="field-wrap mt-0 mb-0">
              <label
                className={subPropertyTypeError ? "form-check-label checkbox-label checkbox-label-override red-marked-checklabel" : 
                "form-check-label checkbox-label checkbox-label-override"}
                htmlFor="propertySubType"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="propertySubType"
                  checked = {isSelectAll}
                  onClick={handleSelectAll}
                />
                Select All
              </label>
            </div>
          </div>
          <div className="row">{loadPropertySubTypes()}</div>
          </>
        // </div>
      ) : (
        ""
      )}
      </>
    // </div>
  );
};
export default WantAdRentPropertyTypes;
