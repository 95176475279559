import React, { useState, useEffect } from "react";
import { loadPhotos } from "../../helpers/common";
import FsLightbox from "fslightbox-react";
import inValidImage from "../../images/invalid-image-placeholder.png";
import { INTRODUCTION_DETAILS } from "../../constants/onBoarding";

const ViewImagesFullGallery = (props) => {
  const {
    photos,
    setUIKey,
    className = null,
    isFromDetailsPage,
    isFromConfirmationPage,
    isFromReceiveIntroDetailsPage,
    isFromMessagesIntroDetailsPage
  } = props;

  const photosCount = photos.length;
  const [toggler, setToggler] = useState(false);
  const [photoArray, setPhotoArray] = useState([]);
  const isEven = photosCount % 2 === 0 ? true : false;

  useEffect(() => {
    if (photosCount > 0) {
      var newArray = [];
      photos.map((photo) => {
        if (isFromDetailsPage) {
          const isValid = _.get(photo, "IsValid", null);
          if (isValid) {
            newArray.push(photo.image);
          } else {
            newArray.push(inValidImage);
          }
        } else if (isFromConfirmationPage) {
          const isValid = _.get(photo, "IsValid", null);
          if (isValid == 0) {
            newArray.push(inValidImage);
          } else {
            newArray.push(photo.image);
          }
        } else {
          newArray.push(photo.image);
        }
      });
      setPhotoArray(newArray);
    }
  }, [photos]);

  const loadPhotosInColums = (startIndex, lastIndex) => {
    let i = startIndex;
    let dom_content = [];
    while (i <= lastIndex) {
      dom_content.push(
        <div className="banner-column-full d-flex h-98">
          <div className="quarter-col-img-wrap-left w-50-percent">
            <img
              className="col-img-style"
              src={photoArray[i]}
              alt={photoArray[i]}
              onClick={() => setToggler(!toggler)}
            />
          </div>
          <div className="quarter-col-img-wrap-right w-50-percent">
            <img
              className="col-img-style"
              src={photoArray[i + 1]}
              alt={photoArray[i + 1]}
              onClick={() => setToggler(!toggler)}
            />
          </div>
        </div>
      );
      i = i + 2;
    }
    return dom_content;
  };

  const loadPhotos = () => {
    if (photosCount > 0) {
      if (photosCount === 1) {
        return (
          <div className="banner-column-full h-200">
            <div className="full-col-img-wrap h-100">
              <img
                className="brdr-top-10 col-img-style"
                src={photoArray[0]}
                alt={photoArray[0]}
                onClick={() => setToggler(!toggler)}
              />
            </div>
          </div>
        );
      }
      if (photosCount === 2) {
        return (
          <>
            <div className="banner-column-full h-200">
              <div className="full-col-img-wrap h-100">
                <img
                  className="brdr-top-10 col-img-style"
                  src={photoArray[0]}
                  alt={photoArray[0]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
            </div>
            <div className="banner-column-full h-200">
              <div className="full-col-img-wrap h-100">
                <img
                  className="brdr-top-10 col-img-style"
                  src={photoArray[1]}
                  alt={photoArray[1]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
            </div>
          </>
        );
      }
      if (photosCount >= 3) {
        return (
          <>
            <div className="banner-column-full h-200">
              <div className="full-col-img-wrap h-100">
                <img
                  className="brdr-top-10 col-img-style"
                  src={photoArray[0]}
                  alt={photoArray[0]}
                  onClick={() => setToggler(!toggler)}
                />
              </div>
            </div>
            {isEven
              ? loadPhotosInColums(1, photosCount - 2)
              : loadPhotosInColums(1, photosCount - 1)}
            {isEven && (
              <div className="banner-column-full h-200">
                <div className="full-col-img-wrap h-100">
                  <img
                    className="brdr-top-10 col-img-style"
                    src={photoArray[photosCount - 1]}
                    alt={photoArray[photosCount - 1]}
                    onClick={() => setToggler(!toggler)}
                  />
                </div>
              </div>
            )}
          </>
        );
      }
    }
  };

  const loadFullViewImageArray = () => {
    var fullViewImageArray = [];
    photoArray.map((photo) => {
      fullViewImageArray.push(<img src={photo} />);
    });
    return fullViewImageArray;
  };

  return (
    <>
      <div className="gitcha-main-wrapper light-box-wrapper">
        <div className="content-wrapper">
          <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="back-link-btn"
                  href={void 0}
                  onClick={() => isFromReceiveIntroDetailsPage ? setUIKey(INTRODUCTION_DETAILS) : isFromMessagesIntroDetailsPage ? setUIKey(INTRODUCTION_DETAILS)  :  setUIKey("readyToPost")}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>
                <div className="heading">Photos</div>
                <div className="right-mobbtn-wrap">
                  {/* <div className="social-btns-wrap">
                    <a className="mob-share-btn" href="#">
                      share
                    </a>
                    <IntroductionsActionDropdown />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="inner-layout-content-new h-100 bg-white">
            <div className="premium-content-wrapper p-3 min-h-100 bg-white">
              <div className="view-photo-wrap position-relative">
                <div className="banner-row">{loadPhotos()}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new inner-layout-footer-topfix">
          {/* <div className="bottomMenu bottom-menu-index">
            <MenuBottomBar />
          </div> */}
        </div>
      </div>
      <FsLightbox toggler={toggler} sources={loadFullViewImageArray()} />
    </>
  );
};

export default ViewImagesFullGallery;
