import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextArea from "../../../components/Common/TextArea";
import { capitalizeFirstString, getLocalStorage, getUserId } from "../../../helpers/common";
import { handleTextBoxChange } from "../../../actions/common";
import { USER_INFO_KEY } from "../../../constants/common";
import { getIntroUserReportingReasons } from "../../../services/commonServices";
import { useNavigate } from "react-router-dom";
import { reportIntroductionUser } from "../../../services/introductionService";
import { pageURLs } from "../../../constants/pageURLs";
import { toast } from "react-toastify";

const ReportUserModal = (props) => {

  const navigate = useNavigate();
  const { show, onHide, IntroductionId , reportedUserId, inventoryDetails, isFromMessage} = props;
  const [reportReasons, setReportReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const userId = getUserId();
  const inventoryId = _.get(inventoryDetails, "Inv_Id", "");

  useEffect(() => {
    fetchReportingReasons();
  }, []);


  const fetchReportingReasons = async () => {
    await getIntroUserReportingReasons().then((response) => {
      if (response?.errorCode === 0) {
        setReportReasons(response?.result);
      } else {
        setReportReasons([]);
      }
    });
  };

  const handleReportUser = async () => {
    const params = {
      "userId": userId,
      "introductionId" : IntroductionId, 
      "reportedUserId" : reportedUserId, 
      "reasonId" : selectedReason
    }

    await reportIntroductionUser(params).then((response)=>{
      props.onHide()
      if(response?.errorCode===0){
        toast.dismiss()
        toast.success("Report was successfully submitted")
        if(isFromMessage){
          props.onHide()
        } else{
          // navigate(pageURLs.allIntroductions)
        }
       
      }
      else{
        toast.dismiss()
        toast.error(response?.message)
      }

    }).catch((err)=>{
      toast.dismiss()
      toast.error(err)
    })
  }

  const handleCancel = () =>{
    setSelectedReason(null)
    props.onHide()
  }

  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup not-fit-modal">
        <Modal.Body className="text-center">
        <h3>Report this user</h3>
        <p>Your report is always anonymous. Once the report is made we’ll investigate the situation and determine what to do next.</p>
        <p className="note">Reason for reporting:</p>
        <div className="modal-controls-wrap">
        {reportReasons.length > 0 &&
            reportReasons.map((reason) => {
              return (
                <label className="radio-wrap mb-3">
                  <input 
                  type="radio" 
                  name="radio-ad-option" 
                  onChange={()=>{setSelectedReason(reason?.ReasonId)}}
                  checked={reason?.ReasonId === selectedReason} />
                  <span>{reason?.Reason_Text}</span>
                </label>
              );
            })}  
        </div>
        {/* <div className="textbox-wrap">
              <TextArea
                className="form-control txt-area"
                errorMessage=""
                value={reasonDetails}
                maxLength={500}
                placeholder="Enter reason here"
                onChange={(e) => {
                  handleDescriptionChange(e);
                }}
                disabled
              />
            </div> */}
              
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100"disabled={selectedReason==null} onClick={()=>handleReportUser()}>Report User</button>
            <button type="button" className="btn btn-secondary w-100"  onClick={handleCancel}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default ReportUserModal;
