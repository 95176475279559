import React, { useState, useEffect, useRef } from "react";
import WantadLeftSection from "../components/MemberWantAd/WantadLeftSection";
import WantadRightSection from "../components/MemberWantAd/WantadRightSection";
import {
  wantAdPageContents,
  propertySubTypesItems,
  EXACT_MOVE_DATE_TYPE,
  dateError,
  sqftMaxLesser,
  budgetMaxError,
  lotSizeMinMissing,
  lotSizeMaxMissing,
  sqFtMinMissing,
  sqFtMaxMissing,
  budgetMinMissing,
  budgetMaxMissing,
} from "../constants/createWantAd";
// import { wantAdPageContents, wantAdAmenities } from "../constants/createWantAd";
import {
  saveMemberWantAd,
  getWantAdById,
  saveMemberWantAdstrengthenData,
} from "../services/wantAdService";
import { pageURLs } from "../constants/pageURLs";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import {
  BUY,
  DATE_FORMAT_MM_DD_YYYY,
  DEFAULT_MAP_ZOOM,
  IMG_JPEG_FORMAT,
  INVESTOR_MEMBER_PLAN,
  IS_LAND,
  IS_PRIVATE_ROOM,
  LONG_RENT,
  SHORT_RENT,
  USER_INFO_KEY,
  WANT_AD_KEY,
  YEAR_BUILT_RANGE,
} from "../constants/common";
import {
  lotSizeMaxLesser,
  lotSizeMinGreater,
  yearBuiltMaxMissing,
  yearBuiltMinMissing,
  yearBuiltRangeMissing,
  yrBltMaxLesser,
  yrBltMinGreater,
  yrMaxInValid,
  yrMinInValid,
} from "../constants/createWantAd";
import { convertToNumber } from "../helpers/validations";
import ReadyToSaveDraftModal from "../components/MemberWantAd/ReadyToSaveDraftModal";
import {
  checkTutorialDisplayStatus,
  createPreSignedUrl,
  createStaticMapImage,
  getLocalStorage,
  isValidDate,
  removeLocalStorage,
  setLocalStorage,
  yearValidation,
} from "../helpers/common";
import { getUserId } from "../helpers/common";
import { clearLocalStorage } from "../helpers/common";
import { userLogout } from "../reducers/login";
import { useDispatch } from "react-redux";
import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";
import { dataURLToBlob } from "../helpers/createWantAd";
import { useSelector } from "react-redux";
import userProfile from "../appState/userProfile";


const CreateWantAd = () => {
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const [activeStep, setActiveStep] = useState(1);
  const [draftSelectedItem, setDraftSelectedItem] = useState(null);
  const [showSaveExitModal, setShowSaveExitModal] = useState();
  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("")

  useEffect(()=>{
    if(userDetails){
      if(userDetails?.length===1){
        if(userDetails[0]?.PlanName){
         setUserPlan(userDetails[0]?.PlanName)
        }
      }
      else {
        if(userDetails[0]?.PlanName === 'Trial'){
          setUserPlan(userDetails[1]?.PlanName)
        }
        else{
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }
  },[userDetails])

  const [hideContinueButton, setHideContinueButton] = useState(false);
  
  let adAmenities = null;
  let isDisabled = true;
  const checkDraft = state && state?.checkDraft;

  useEffect(() => { //Need to show want add onboard very first time
    let DisplayWantAddOnboard = checkTutorialDisplayStatus(6); //Want add onboard display
    if(DisplayWantAddOnboard) {
      navigate(pageURLs.gitchaMemberWelcome)
    }
    removeLocalStorage("userAction")
  }, []);

 
  useEffect(() => {
    adAmenities = getLocalStorage(WANT_AD_KEY);
    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), "userInfo", null);
    if (!userInfo) {
      handleStateClear();
    }
    const userId = _.get(userInfo, "userId", "");
    const currentActiveStep = _.get(adAmenities, "actvStep", "");
    const cmpldPerctg = _.get(adAmenities, "cmpldPerctg", "");
    // const s3Folder = _.get(adAmenities, "s3ImageFolder", null);
    // if (!s3Folder) {
    //   createS3Folder(userId);
    // }
    if (currentActiveStep) {
      setActiveStep(currentActiveStep);
      setCurrentCompletedPercentage(cmpldPerctg);
      setFormValues(adAmenities);
    } else {
      // adAmenities = _.get(wantAdAmenities, "memberBuy", "");
      //setActiveStep(1);
    }
  }, []);
  // to set is edit false
  useEffect(() => {
    let statesToBeUpdated = { actvStep: activeStep}
    if (activeStep == 9) {
      statesToBeUpdated = {
        isEdit: false,
        actvStep: activeStep
      }
    }
    handleFormValues(statesToBeUpdated)
  }, [activeStep]);

  const handleMapImageUpload = async (image) => {
    // const lat = _.get(formValues, "lat", "");
    // const lng = _.get(formValues, "lng", "");
    // const mapZoom = _.get(formValues, "mapZoom", DEFAULT_MAP_ZOOM);
    // const isFromWantAd = true
    const mapResponse = image//await createStaticMapImage(lat, lng, mapZoom, isFromWantAd);
    const isWantAd = true;
    const preSignedUrlRes = mapResponse
      ? await createPreSignedUrl(isWantAd)
      : alert("Something went wrong while fetching map image.");
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl ? s3FullUrl.split("?")[0] : "";
        // if(ContinueClick){
          handleFormValues({ mapImg: imageUrl });
          const isPostAd = false;
          saveMemberWantAdData(isPostAd, true, imageUrl); // true to check ContinueClick or not
        // } else{
         
          // handleFormValues({ mapImg: imageUrl });
        // }
       
      }
    }
  };

  const handleCaptureMapImage = () => {
    const fullScreenDiv = document.querySelector('.gm-fullscreen-control'); 
    const zoomIcons = document.querySelectorAll('.gmnoprint');
    const buttons = document.querySelectorAll('.gm-style-cc'); 
    const googleIcon = document.querySelector('img[alt="Google"]');
    const gmStyle = document.querySelector('.gm-style-moc');

    if (gmStyle) {
      gmStyle.style.display = 'none';
    }

    if(fullScreenDiv){
      fullScreenDiv.style.display = 'none';
    }

    if (googleIcon) {
      googleIcon.style.display = 'none';
    }


    zoomIcons.forEach((element) => {
      element.style.display = 'none';
    });
   
    buttons.forEach((element) => {
      element.style.display = 'none';
    });
    
    if (targetRef.current) {
      html2canvas(targetRef.current, { allowTaint: true, useCORS: true }
      ).then((canvas) => {
        var dataURL = canvas
          .toDataURL(IMG_JPEG_FORMAT)
          const binaryData = dataURLToBlob(dataURL)
          handleMapImageUpload(binaryData)
      });
    }
  };

 

  //adAmenities = _.get(wantAdAmenities, "memberBuy", "");
  const [continueButtonState, setContinueButtonState] = useState(isDisabled);
  const [formValues, setFormValues] = useState(adAmenities);
  const adType = _.get(formValues, "adType", "");
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const purchaseType = convertToNumber(_.get(formValues, "purchaseType", null)) || convertToNumber(_.get(formValues, "prchTypeId", null));
  let pageContents;
  if (!adType) {
    pageContents = _.get(wantAdPageContents, `step_${activeStep}`, "");
  }
  else if(userPlan===INVESTOR_MEMBER_PLAN && adType===BUY && propertyType != IS_LAND){
    if(purchaseType===3){
      pageContents = _.get(
        wantAdPageContents,
        `INVESTOR_${adType}_INVESTMENT.step_${activeStep}`,
        ""
      );
    }
    else{
      pageContents = _.get(
      wantAdPageContents,
      `INVESTOR_${adType}.step_${activeStep}`,
      ""
    );
  }
  } else if (propertyType === 6) {
    // If property type is lot/land then page contents should be different
    pageContents = _.get(
      wantAdPageContents,
      `${adType}_LAND.step_${activeStep}`,
      ""
    );
  }
   else {
    pageContents = _.get(
      wantAdPageContents,
      `${adType}.step_${activeStep}`,
      ""
    );
  }

  const {
    title,
    description,
    skipButtonEnable,
    exitButtonText,
    backButtonEnable,
    completedPercentage,
    continueButtonText,
  } = pageContents;
  const [currentCompletedPercentage, setCurrentCompletedPercentage] =
    useState(completedPercentage);
  const propertySubTypes = _.get(formValues, "prptySubTypes", []);
  let propertyText = _.get(formValues, "prptyTypeTxt", "")
    ? _.get(formValues, "prptyTypeTxt", "").toLowerCase()
    : "";

  if (propertyType !== 6 && adType === LONG_RENT) {
    if (propertySubTypes && propertySubTypes.length === 1) {
      const filteredArray = propertySubTypesItems.filter(
        (a) => a.id == propertySubTypes[0]
      );
      if (filteredArray) {
        propertyText =
          (activeStep === 8 ? " for your " : " in your ") +
          _.get(filteredArray[0], "text", "").toLowerCase();
      }
    } else if (propertySubTypes && propertySubTypes.length >= 1) {
      propertyText = " for your place";
    }
  }

  let newDescription = description;
  let isShowSkipButton = skipButtonEnable
  if ((adType === SHORT_RENT || adType === LONG_RENT) && activeStep === 6 && propertyType === IS_PRIVATE_ROOM) {
    isShowSkipButton =  true
    newDescription = "These items are optional. Feel free to skip this step if they don't apply.";
  }

  const newTitle =
    _.get(formValues, "actvStep", "") == 10 &&
    _.get(formValues, "isEdit", "") == true
      ? _.get(wantAdPageContents, `EDIT.title`, "")
      : title && title.replace("PROPERTY_TYPE", propertyText);

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      actvStep: activeStep,
      cmpldPerctg: currentCompletedPercentage,
    }));
  }, [currentCompletedPercentage]);

  useEffect(() => {
    setLocalStorage(WANT_AD_KEY, formValues);
  }, [formValues]);

  const getFinalStep = () =>{
    if(userPlan===INVESTOR_MEMBER_PLAN && adType===BUY && propertyType != IS_LAND){
      if(purchaseType===3){
        return 12
      }
      else{
        return 11
      }
    }
    else {
      return 10;
    }
  }

  const handleContinueClick = (currentStep) => {
    // to remove edit filed from session
    handleFormValues({ isEdit: false });
    let errorSection = null;
    const finalStep = getFinalStep();    
    if(currentStep == 3){
      handleCaptureMapImage()  
    }else if(currentStep != 1 && currentStep !=finalStep){      
      const isPostAd = false;
      saveMemberWantAdData(isPostAd, true); // true to check ContinueClick or not
    }
    switch (currentStep) {
      case 1:
        handleDraftItemClick(draftSelectedItem)
        //handleMapImageUpload();
        break;

      // case 3:
      //   handleCaptureMapImage()
      //   //handleMapImageUpload();
      //   break;
      case 4:
        if (adType === LONG_RENT) {
          const moveInTimeLine = convertToNumber(_.get(formValues, "moveInTimeLine", ""));
          const moveInDate = _.get(formValues, "startDate", "");
          if (moveInTimeLine === EXACT_MOVE_DATE_TYPE && moveInDate) {
            const isDateValid = isValidDate({dateString :moveInDate, format : DATE_FORMAT_MM_DD_YYYY});
            if (!isDateValid) {
              errorSection = dateError;
            }
          }
        }
        break;
      case 6:
        const mnLotSize = _.get(formValues, "minLtSz", "");
        const mxLotSize = _.get(formValues, "maxLtSz", "");
        const yrBltMin = _.get(formValues, "yrBltMin", "");
        const yrBltMax = _.get(formValues, "yrBltMax", "");
        const sFMin = _.get(formValues, "sqFtMin", "");
        const sFMax = _.get(formValues, "sqFtMax", "");
        const yearBuiltOption = _.get(formValues, "yearBuiltOption", null);

        if (sFMin && sFMax) {
          if (convertToNumber(sFMin) >= convertToNumber(sFMax)) {
            errorSection = sqftMaxLesser;
          }
        } 
        // else if(!sFMin && sFMax){
        //   errorSection = sqFtMinMissing;
        // } else if(sFMin && !sFMax){
        //   errorSection = sqFtMaxMissing;
        // }
        if (adType === BUY || propertyType === IS_LAND) {
          if (mnLotSize && mxLotSize) {
            // let minLotSizeValue = mnLotSize;
            // let maxLotSizeValue = mxLotSize;
            // if (minLotSizeValue === "1/4") {
            //   minLotSizeValue = ".25";
            // } else if (minLotSizeValue === "1/2") {
            //   minLotSizeValue = ".50";
            // }

            // if (maxLotSizeValue === "1/4") {
            //   maxLotSizeValue = ".25";
            // } else if (maxLotSizeValue === "1/2") {
            //   maxLotSizeValue = ".50";
            // }

            if (convertToNumber(mnLotSize) >= convertToNumber(mxLotSize)) {
              errorSection = lotSizeMaxLesser;
            } 
          } 
          // else if(!mnLotSize && mxLotSize){
          //   errorSection = lotSizeMinMissing;
          // } else if(mnLotSize && !mxLotSize){
          //   errorSection = lotSizeMaxMissing;
          // }
          if (adType === BUY) {
            if (yearBuiltOption === YEAR_BUILT_RANGE) {
              if (!yrBltMin && !yrBltMax) {
                errorSection = yearBuiltRangeMissing;
              }
              if (yrBltMin && !yrBltMax) {
                errorSection = yearBuiltMaxMissing;
              }
              if (!yrBltMin && yrBltMax) {
                errorSection = yearBuiltMinMissing;
              }

              if (yrBltMin && yrBltMax) {
                if (!yearValidation(yrBltMin)) {
                  errorSection = yrMinInValid;
                } else if (!yearValidation(yrBltMax)) {
                  errorSection = yrMaxInValid;
                }
                if (convertToNumber(yrBltMax) <= convertToNumber(yrBltMin)) {
                  errorSection = yrBltMaxLesser;
                } else if (
                  convertToNumber(yrBltMin) >= convertToNumber(yrBltMax)
                ) {
                  errorSection = yrBltMinGreater;
                }
              }
            }
          }
        }

        // //_.set(formValues, "wantAdValErrors", error);
        // if (errorSection) setActiveStep(activeStep);
        // else setActiveStep(activeStep + 1);

        break;

      case 8 :
        const minBdgt = _.get(formValues, "minBdgt", "");
        const maxBdgt = _.get(formValues, "maxBdgt", "");
        if (minBdgt && maxBdgt) {
          if (convertToNumber(minBdgt) >= convertToNumber(maxBdgt)) {
            errorSection = budgetMaxError;
          } 
        } 
        // else if(!minBdgt && maxBdgt){
        //   errorSection = budgetMinMissing;
        // } else if(minBdgt && !maxBdgt){
        //   errorSection = budgetMaxMissing;
        // }
       
        break;  
      case finalStep:
        setHideContinueButton(true);
        const isPostAd = true;
        saveMemberWantAdData(isPostAd);
        break;
    }
    if (errorSection) {
      handleFormValues({
        wantAdValErrors: errorSection,
        updatedAt: Date.now(),
      }); // 'updatedAt' used for rerendering the component if the form values are not changing.
    } else {
      const errorSection = _.get(
        getLocalStorage(WANT_AD_KEY),
        "wantAdValErrors"
      );
      if (errorSection) {
        handleFormValues({ wantAdValErrors: "", updatedAt: "" }); // Clear errosSection from state.
      }
      (currentStep!==1 && currentStep!==finalStep) && setActiveStep(activeStep + 1);
      if (currentCompletedPercentage <= completedPercentage) {
        setCurrentCompletedPercentage(completedPercentage);
      }
    }
  };

  const handleStateClear = () => {
    // alert("Your session has expired. Not complete the request. Please log in");
    dispatch(userLogout());
    clearLocalStorage();
    navigate(pageURLs.home);
  };

  const handleDraftItemClick = async (draftSelectedItem) => {
    const currentStep = _.get(draftSelectedItem, 'currentStep', null)
    const selectedId = _.get(draftSelectedItem, 'selectedId', null)
    if (selectedId) {
      await getWantAdById(selectedId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            //localStorage.setItem("memberBuy", JSON.stringify(responseResult));
            setFormValues(responseResult);
            if (currentStep === 1) setActiveStep(2);
            else setActiveStep(currentStep);
            setCurrentCompletedPercentage(_.get(responseResult, "cmpldPerctg"));
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };



  const saveMemberWantAdData = (isPostAd, ContinueClick = false, imageUrl = null) => { // ContinueClick true when clicking continue on each page on the final step it will be false
    let updatedState = {
      pTypeTxt: _.get(formValues, "prptyTypeTxt", ""),
      adType: _.get(formValues, "adType", "") + "_STRENGTHEN",
      isEdit: _.get(formValues, "isEdit", ""),
    };
    const userId = getUserId(); //_.get(user, "userInfo.userId", null);
    const requestPayLoad = getLocalStorage(WANT_AD_KEY);
    if (userId && requestPayLoad) {
      requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
      if(imageUrl){
        requestPayLoad.mapImg = imageUrl;
      }

      let arrayValue = [];
    if(requestPayLoad?.zipIdValues){
      requestPayLoad?.zipIdValues.map((zip)=>{
        arrayValue.push(zip?.zipId)
        })
        requestPayLoad.zipId = arrayValue;
    }  else if(requestPayLoad?.zipCode){
      requestPayLoad?.zipCode.map((zip)=>{
        if(zip?.zipId && zip?.zipId != null){
          arrayValue.push(zip?.zipId)
        }
      })
      requestPayLoad.zipId = arrayValue;
  }
    requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
     

      if (isPostAd) {
        requestPayLoad.IsSavedDraft = false;
        requestPayLoad.IsPublished = true;
        delete requestPayLoad.tlText;
        delete requestPayLoad.prptyTypeTxt;
        delete requestPayLoad.wantAdValErrors;
        delete requestPayLoad.updatedAt;
      } else {
        requestPayLoad.IsSavedDraft = true;
        requestPayLoad.IsPublished = false;
      }

      saveMemberWantAd(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            handleFormValues({wantAdId: responseResult.wantAdId})
            if (
              requestPayLoad.purchaseType ||
              requestPayLoad.creditScore ||
              requestPayLoad.prchTypeId || requestPayLoad.pFinance
            ) {
              saveStrengthenData(responseResult.wantAdId, isPostAd);
            } else {
              if (isPostAd) {
                updatedState.wantAdId = _.get(responseResult, "wantAdId", "");
                updatedState.minBdgt = _.get(formValues, "minBdgt", "");
                updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");

                setLocalStorage(WANT_AD_KEY, updatedState);
              } else {
                if(ContinueClick){
                  
                } else{
                  removeLocalStorage(WANT_AD_KEY);
                }
               
                //navigate(pageURLs.createwantad);
              }
            }
            if(!ContinueClick){
            navigate(pageURLs.wantAdDetails);
            }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const saveStrengthenData = async (wantAdIdValue, isPostAd) => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const wantAdId = wantAdIdValue;
    let updatedState = {
      pTypeTxt: _.get(formValues, "prptyTypeTxt", ""),
      adType: _.get(formValues, "adType", "") + "_STRENGTHEN",
      isEdit: _.get(formValues, "isEdit", ""),
    };

    if (wantAd) {
      let requestPayLoad = {};
      let isApproveLoan = "";

      if (
        _.get(formValues, "isPreApprLoan", null) === "Yes" ||
        _.get(formValues, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = true;
      } else if (
        _.get(formValues, "isPreApprLoan", null) === "No" ||
        _.get(formValues, "isPreApprLoan", null) == true
      ) {
        isApproveLoan = false;
      }
      requestPayLoad.isPreApprLoan === isApproveLoan;
      const is1031Exchange = (wantAd?.is1031Exchange === "Yes" || wantAd?.is1031Exchange == 1) ? true : false;
      requestPayLoad.wantAdId = wantAdId;
      requestPayLoad.isPreApprLoan = isApproveLoan;
      requestPayLoad.is1031Exchange = is1031Exchange;
      requestPayLoad.completedStep = 1;
      requestPayLoad.adType =
        wantAd.adType == BUY ? "BUY_STRENGTHEN" : "LTR_STRENGTHEN";
      requestPayLoad.creditScore = wantAd.creditScore;
      requestPayLoad.maxBdgt = wantAd.maxBdgt;
      requestPayLoad.minBdgt = wantAd.minBdgt;
      requestPayLoad.pFinance = wantAd.pFinance || wantAd.finTypeId;
      requestPayLoad.purchaseType = wantAd.purchaseType || wantAd.prchTypeId;

      await saveMemberWantAdstrengthenData(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            updatedState.wantAdId = wantAdIdValue;
            updatedState.minBdgt = _.get(formValues, "minBdgt", "");
            updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");
            if(isPostAd){
            setLocalStorage(WANT_AD_KEY, updatedState);
            navigate(pageURLs.wantAdDetails);}
          } else{
            removeLocalStorage(WANT_AD_KEY);
            navigate(pageURLs.wantAdDetails);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const handleBackButtonClick = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkipButtonClick = (step) => {
    switch (step) {
      case 6:
        if(adType === SHORT_RENT || adType === LONG_RENT){
          setFormValues((prevState) => ({
            // Clear data from states for nice to have ski
            ...prevState,
            accsPvtBthRm: false,
            adaAccess: false,
            alwPets: false,
            pvtEntrnce: false
          }));
        }
        break;
      case 7:
        setFormValues((prevState) => ({
          // Clear data from states for nice to have ski
          ...prevState,
          nice2Have: [],
        }));
        break;
    }
    if (currentCompletedPercentage <= completedPercentage) {
      setCurrentCompletedPercentage(completedPercentage);
    }
    setActiveStep(activeStep + 1);
  };

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearAll") {
        setFormValues({ actvStep: 2, cmpldPerctg: 0 });
        setCurrentCompletedPercentage(0);
      } else if (type === "clearFlexDates") {
        const array = { ...formValues }; // make a separate copy of the state
        ["duration", "month"].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "dateRange") {
        const array = { ...formValues }; // make a separate copy of the state
        ["startDate", "endDate"].forEach((e) => delete array[e]);
        setFormValues(array);
      } else if (type === "moveInTypeChangeLTR") {
        const array = { ...formValues }; // make a separate copy of the state
        ["startDate"].forEach((e) => delete array[e]);
        setFormValues(array);
      }
    } else {
      // clear previous state values if user changes property type (eg from lot-land to other types and vice versa)
      const array = { ...formValues }; // make a separate copy of the state
      [
        "nice2Have",
        "noBedRm",
        "noBathRm",
        "noOfBeds",
        "sqFtMin",
        "sqFtMax",
        "grgSpce",
        "minLtSz",
        "maxLtSz",
        "yrBltMin",
        "yrBltMax",
        "isNwCnstctn",
        "acreageMin",
        "acreageMax",
        "lotType",
        "lotTypeText",
        "roadType",
        "rdTypText",
        "isPrvtDrInst",
        "onSiteElec",
        "waterSupply",
        "wSText",
        "isDrldWelInst",
        "wasteWtrSys",
        "wWSysText",
        "isSepTankInst",
        "isSepTankAppr",
        "prptySubTypes",
        "adaAccess",
        "accsPvtBthRm",
        "alwPets",
        "alwCats",
        "alwLrgDogs",
        "alwSmlDogs",
        "prptySubTypes",
        "adTitle",
        "adDesc",
        "minBdgt",
        "maxBdgt",
        "stories",
        "yearBuiltOption",
        "mnLtSzId",
        "mnLtSzTxt",
        "mxLtSzId",
        "mxLtSzTxt",
        "moveInTimeLine",
        "neighborHood",
        "highSchool"
      ].forEach((e) => delete array[e]);

      setFormValues(array);
      setCurrentCompletedPercentage(completedPercentage);
    }
  };

  const handleContinueButtonState = (buttonState) => {
    isDisabled = false;
    setContinueButtonState(buttonState ? buttonState : isDisabled);
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  const handleSaveAndExit = (step) => {
    if (step === 1) {
      navigate(pageURLs.home);
    } else {
      setShowSaveExitModal(true);
    }
  };

  const handleSaveExitModal = (state) => {
    setShowSaveExitModal(state);
  };

  return (
    <div className="container-fluid position-relative half-fluid h-100 ps-0 pe-0">
   
   {showSaveExitModal &&  <ReadyToSaveDraftModal
        handlePopUp={handleSaveExitModal}
        show={showSaveExitModal}
        formValues={formValues}
      />}
    
      <div className="container-fluid container-height ps-0 pe-0">
        <div className="row h-100 g-0">
          <WantadLeftSection
            title={newTitle}
            description={newDescription}
            completedPercentage={currentCompletedPercentage}
          />
          <WantadRightSection
            setActiveStep = {setActiveStep}
            setDraftSelectedItem = {setDraftSelectedItem}
            draftSelectedItem = {draftSelectedItem}
            handleFormValues={handleFormValues}
            handleSaveAndExit={handleSaveAndExit}
            handleContinueClick={handleContinueClick}
            handleBackButtonClick={handleBackButtonClick}
            handleContinueButtonState={handleContinueButtonState}
            isDisabled={continueButtonState}
            skipButtonEnable={isShowSkipButton}
            exitButtonText={exitButtonText}
            backButtonEnable={backButtonEnable}
            continueButtonText={continueButtonText}
            formValues={formValues}
            step={activeStep}
            handleSkipButtonClick={handleSkipButtonClick}
            className={activeStep === 7 ? "alignment-override" : ""}
            bottomNavClass={activeStep === 7 ? "btm-nav-bar mt-auto" : ""}
            clearPreviousStates={clearPreviousStates}
            exitButtonDisable={
              _.get(formValues, "isPublished", "") == 1 ? true : false
            }
            targetRef = {targetRef}
            checkDraft={checkDraft}
            hideContinueButton={hideContinueButton}
            userPlan={userPlan}
            isFinalStep={activeStep === getFinalStep() ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateWantAd;
