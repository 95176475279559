/**
 * @file   src\components\AgentDashboard\ActionDropdown.js
 * @brief  This file is responsible for dropdown actions in dashboard
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import HomeBannerImg from "../images/agentImgBannerMob2x.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BUY, USER_TYPE_AGENT } from "../../constants/common";
import { getUserType } from "../../helpers/common";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const SearchLocationScreenMob = (props) => {
  const navigate = useNavigate();
  const userType = getUserType();
  const isAgent = userType === USER_TYPE_AGENT ? true : false;
  const { adType } = props;
  return (
    <>      
      <div className="agent-home-new text-center">
        <h2>
          See what {adType === BUY ? "buyers" : "renters"} are looking for near
          you.
        </h2>
        <img src={HomeBannerImg} className="mt-4 mb-4 img-br-3 img-wd" />

        <h3 className="mb-4">It’s easy, here’s how it works:</h3>

        {isAgent ? (
          <div className="how-it-works">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="d-flex align-items-start how-it-works-info">
                    <div className="list-number flex-shrink-0">1</div>
                    <div className="flex-grow-1 ms-3">
                      <h5>Post {adType===BUY ? "Buyer" : "Renter"} Wants & Needs</h5>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Post a want-ad for what your clients are looking for.
                    Express their must-haves & nice-to-haves, desired location,
                    timeline, financing details, your desired buyer’s agent
                    commission and more.
                  </p>{" "}
                  Once you post, your want-ad listings are searchable and
                  matchable for agents/owners to discover.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="d-flex align-items-start how-it-works-info">
                    <div className="list-number flex-shrink-0">2</div>
                    <div className="flex-grow-1 ms-3">
                      <h5>Privately Gauge Demand For Owners</h5>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  Rather than old data, Gitcha shows you what buyers & renters
                  are looking for in your area and what they're willing to pay.
                  Just privately load your properties (Inventory) into Gitcha,
                  select which one you want to gauge interest of under the
                  'match' tab, and see the real-time demand!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="d-flex align-items-start how-it-works-info">
                    <div className="list-number flex-shrink-0">3</div>
                    <div className="flex-grow-1 ms-3">
                      <h5>Privately Connect via Introductions</h5>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  If you loaded a property into your inventory, you can
                  privately introduce it to an agent/buyer's want-ad listing in
                  just seconds.
                  <br />
                  If you posted a want-ad listing, agents/owners can send you an
                  introduction of their property. You'll first receive a
                  preview, and to see the full address, description, photos and
                  to read a private note from the agent/owner, accept the
                  introduction. If you're intrigued, message them within 72 hrs.
                  If it's not a fit, just mark it “not a fit.”
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="d-flex align-items-start how-it-works-info">
                    <div className="list-number flex-shrink-0">4</div>
                    <div className="flex-grow-1 ms-3">
                      <h5>Manage and Organize by Client</h5>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  Professionals can subscribe to Gitcha's Premium Package for
                  access to the Agent Dashboard. With lots of clients with
                  multiple wants (some similar to others) and properties to keep
                  track of, the dashboard is a powerful tool.
                  <br />
                  Organize every client and prospect's wants & needs, store the
                  properties of current, past and potential clients in a private
                  inventory dashboard...keep notes for your teams, track key
                  contacts & info, client likes and dislikes, and more!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div className="d-flex align-items-start how-it-works-info">
                    <div className="list-number flex-shrink-0">5</div>
                    <div className="flex-grow-1 ms-3">
                      <h5>Watch Video Tutorials Here</h5>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  A comprehensive overview and instructional guide to everything
                  you need to know to utilize the power of Gitcha.{" "}
                  <a
                    href="https://loom.com/share/folder/e70fe7a5f7764334a30b557ccb9e7e6a"
                    target="blank"
                  >
                    Watch
                  </a>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : (
          <div className="tab-wrap">
            <Tabs
              defaultActiveKey="Buyers"
              id="fill-tab-example"
              className="mb-3"
              fill
            >
              <Tab eventKey="Buyers" title=" Buyers & renters">
                <div className="how-it-works ">
                  <div className="p-0">
                    <div className="how-it-works-right">
                      <Accordion defaultActiveKey="1" flush>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">1</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Dream</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Tell us all about your ideal property. What are the
                            must-haves and nice-to-haves on your list?
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">2</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Create a want-ad listing</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Get into the details of what you’re are looking by
                            creating a want-ad. This is your opportunity to
                            share exactly what you want and need.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">3</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Post to Gitcha’s marketplace</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Once you’re want-ad listing is live on Gitcha’s
                            marketplace, sit back and let property owners come
                            to you!
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">4</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Evaluate introductions</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Owners will introduce their property to you if they
                            think their property matches what you’re looking
                            for. After receiving a preview, accept their
                            introduction to view the full details of their
                            property.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">5</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Connect with property owners</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            When you’ve found the right property, initiate
                            communication with the owner or their licensed
                            representative through Gitcha’s private messenger.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="text-start pt-2 pb-4">
                        <Button variant="primary" className="fnt_12" onClick={()=>navigate(pageURLs?.createWantAdResponsive)}>
                        Create A Want-Ad
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="property" title="Property owners">
                <div className="how-it-works ">
                  <div>
                    <div className="how-it-works-right">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">1</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>
                                  Browse buyer and renter want-ad listings
                                </h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Gitcha allows you to browse the wants and needs of
                            buyers and renters in your area.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">2</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Add property to private inventory</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            When adding your property to Gitcha, it is not live
                            on the active market and completely private to you.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">3</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Gauge demand</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Once your property is stored, begin receiving
                            notifications of matches to buyer or renter want
                            ads.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">4</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Introduce it</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            Introduce your property to buyers and renters of
                            your choice. You can tailor the asking price and
                            personal message to pique their interest and they’ll
                            decide whether or not it’s a good fit.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            <div className="d-flex align-items-start how-it-works-info">
                              <div className="list-number flex-shrink-0">5</div>
                              <div className="flex-grow-1 ms-3">
                                <h5>Connect with buyers and renters</h5>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            If they’re interested in your property after
                            reviewing your introduction, they’ll initiate
                            Gitcha’s 2 way messenger to discuss any questions or
                            next steps!
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="text-start pt-2 pb-4">
                        <Button variant="primary" className="fnt_12" onClick={()=>navigate(pageURLs?.mobileCreateInventory)}>
                        Add Your Property
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        )}
      </div>      
    </>
  );
};
export default SearchLocationScreenMob;
