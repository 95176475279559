import React, { useState, useEffect } from "react";
import HouseImg from "../../../images/house-img.png";
import { getPropertyTypes } from "../../../services/commonServices";
import { handleRadioButonChange } from "../../../actions/common";
import { usePrevious } from "../../../hooks/usePrevious";
import { BUY, ENTIRE_SPACE_INVENTY_TYPE, IS_ENTIRE_PLACE,
  IS_PRIVATE_ROOM, PRIVATE_ROOM_INVENTY_TYPE, INVENTORY_TYPE, IS_ENTIRE_SPACE } from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";

const InventoryPropertyTypes = (props) => {
  const {
    // handleContinueButtonState,
    handleFormValues,
    propertyType,
    adType,
    prptySubType,
    clearPreviousStates,
    isEdit= false,
    propertyTypeError,
    subPropertyTypeError
  } = props;
  const [propertyList, setPropertyList] = useState([]);
  const [propertySubItems, setPropertySubItems] = useState([]);
  useEffect(() => {
    
    if (propertyType) {
      if (propertyType == IS_ENTIRE_PLACE) {
        getProperiesSubItems(ENTIRE_SPACE_INVENTY_TYPE);
      } else if (propertyType == IS_PRIVATE_ROOM) {
        getProperiesSubItems(PRIVATE_ROOM_INVENTY_TYPE);
      } else {
        // handleContinueButtonState()
      }
    }
   
    handlePropertySubTypes();
    getProperties(INVENTORY_TYPE);
  }, []);

  const getProperties = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handlePropertySubTypes = () => {
      if (propertyType == IS_ENTIRE_PLACE || propertyType == IS_PRIVATE_ROOM) {
        if (prptySubType) {
          // handleContinueButtonState();
        } else {
            const isDisabled = true
            // handleContinueButtonState(isDisabled);
        }
    } 
  };
  useEffect(() => {
    // if(adType !== BUY) {
    //   if (propertyType === 10 || propertyType === 11 ) {
    //    if(propertySubItems && propertySubItems.length>0) {
    //     handleContinueButtonState()
    //    }
    //   }
    // }
  
    handlePropertySubTypes();
  }, [propertySubItems, prptySubType]);

  const getProperiesSubItems = async (adType) => {
    // console.log(adType)
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertySubItems(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const previousValue = usePrevious(propertyType);
  const previousSubTypeValue = usePrevious(prptySubType);
  const handlePropertyClick = (e, typeText) => {
    const isDisabled = true
    // handleContinueButtonState(isDisabled);
    const value = handleRadioButonChange(e);
  
    if (value) {
      if (convertToNumber(value) == IS_ENTIRE_PLACE) {
        getProperiesSubItems(ENTIRE_SPACE_INVENTY_TYPE);
      } else if (convertToNumber(value) == IS_PRIVATE_ROOM) {
        getProperiesSubItems(PRIVATE_ROOM_INVENTY_TYPE);
      } else {
        // handleContinueButtonState();
        setPropertySubItems([]);
      }
      clearPreviousSelectedValues(value);
      handleFormValues({ prprtyType: value, prptyTypeTxt: typeText, prptySubType :'' });
    }
  };

  const handlePropertySubItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      // handleContinueButtonState()
      clearPreviousSelectedValues(value);
      handleFormValues({ prptySubType: value});
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    const isClearPrevious = (convertToNumber(previousValue) !== convertToNumber(currentSelectedValue) || convertToNumber(previousSubTypeValue) !== 
    convertToNumber(currentSelectedValue) ) 
      // (convertToNumber(previousValue) === IS_LAND && convertToNumber(currentSelectedValue) !== IS_LAND) 
      // ||(convertToNumber(previousValue) !== IS_LAND && convertToNumber(currentSelectedValue) === IS_LAND)
      // ||(convertToNumber(previousValue) === IS_ENTIRE_PLACE && convertToNumber(currentSelectedValue) === IS_PRIVATE_ROOM)
      // ||(convertToNumber(previousValue) === IS_PRIVATE_ROOM && convertToNumber(currentSelectedValue) === IS_ENTIRE_PLACE)
    if (isClearPrevious) {
      clearPreviousStates();
    }
  };

  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const Img = _.get(pt, "Img", "");
        const Desc = _.get(pt, "Description", "");
       
        return (
          <>
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio"
                value={typeId ? typeId : '' }
                checked={propertyType && propertyType == typeId || ''}
                onChange={(e) => handlePropertyClick(e, typeText)}
              />
              <span className={propertyTypeError && "red-marked-span"}>
                <div className="wantad-type-info">
                <h2 className={propertyTypeError ? "pb-0 red-marked-subheading" : "pb-0"}>
                      {_.get(pt, "PropertyTypeText", "")}
                    </h2>
                  {/* {adType !== BUY && Desc ? (
                    <>
                      {" "}
                      <h2>{_.get(pt, "PropertyTypeText", "")}</h2> <p>{Desc}</p>{" "}
                    </>
                  ) : (
                    <h2 className="pb-0">
                      {_.get(pt, "PropertyTypeText", "")}
                    </h2>
                  )} */}
                </div>
                {/* <div className="right-img-wrap">
                  {Img ? (
                    <img src={require("../../../images/" + Img)} />
                  ) : (
                    <img src={HouseImg} />
                  )}
                </div> */}
              </span>
              
            </label>
            {propertyType && propertyType == typeId &&

            propertySubItems && propertySubItems.length > 0 ? (
                <>
              <h6 class={subPropertyTypeError ? "sub-head mb-2 red-marked-subhead" : 'sub-head mb-2'}>Select type:* </h6>
              <div className="row">
              {loadPropertySubTypes()}
                </div>
                </>
      ): (
        ""
      )}
          </>
        );
      });

    return List;
  };

  

  const loadPropertySubTypes = () => {
    const List =
      propertySubItems &&
      propertySubItems.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const Img = _.get(pt, "Img", "");
        return (
          <div className="col-md-6 pe-md-2">
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="pst-radio"
                id={typeId}
                value={typeId ? typeId : ''}
                checked={prptySubType && prptySubType == typeId || ''}
                onChange={(e) => handlePropertySubItemClick(e)}
              />
               <span className={subPropertyTypeError && "red-marked-span"}>
                <div className="wantad-type-info">
                  <h2 className={subPropertyTypeError ? "pb-0 red-marked-subheading" : "pb-0"}>{_.get(pt, "PropertyTypeText", "")}</h2>
                </div>
                {/* <div className="right-img-wrap">
                  {Img ? (
                    <img src={require("../../../images/" + Img)} />
                  ) : (
                    <img src={HouseImg} />
                  )}
                </div> */}
              </span>
            </label>
          </div>
        );
      });

    return List;
  };

  

  return (
      // <div className="wantad-type-wrap pb-24">
        <>{loadPropertyTypes()}</>
      // </div>
  );
};
export default InventoryPropertyTypes;
