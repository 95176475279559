import React from "react";
import WantAdFeatures from "./WantAdFeatures";


const ClosedWantAds = () => {
  return (
   <WantAdFeatures/>
  );
};

export default ClosedWantAds;
