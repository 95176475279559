import React, { useState, useEffect } from "react";

const IntroductionSkeleton = (props) => {
  return (
    <>
      <div className="intro-skeleton-wrapper">
        <div class="flex-shrink-0">
          <div className="intro-skeleton-prof-wrap">prof image</div>
        </div>
        <div class="flex-grow-1 ml-10">
          <div className="intro-skeleton-content">
            <div className="intro-skeleton-content-top">top</div>
            <div className="intro-skeleton-content-btm">btm</div>
          </div>

          <div className="intro-skeleton-outer-propertywrap">
            <div className="intro-skeleton-imgwrap"></div>
            <div className="intro-skeleton-rightsec">
              <div className="intro-skeleton-rightsec-top">top</div>
              <div className="intro-skeleton-rightsec-btm">btm</div>
            </div>
          </div>
        </div>
      </div>

      <div className="intro-skeleton-wrapper">
        <div class="flex-shrink-0">
          <div className="intro-skeleton-prof-wrap">prof image</div>
        </div>
        <div class="flex-grow-1 ml-10">
          <div className="intro-skeleton-content">
            <div className="intro-skeleton-content-top">top</div>
            <div className="intro-skeleton-content-btm">btm</div>
          </div>

          <div className="intro-skeleton-outer-propertywrap">
            <div className="intro-skeleton-imgwrap"></div>
            <div className="intro-skeleton-rightsec">
              <div className="intro-skeleton-rightsec-top">top</div>
              <div className="intro-skeleton-rightsec-btm">btm</div>
            </div>
          </div>
        </div>
      </div>

      <div className="intro-skeleton-wrapper">
        <div class="flex-shrink-0">
          <div className="intro-skeleton-prof-wrap">prof image</div>
        </div>
        <div class="flex-grow-1 ml-10">
          <div className="intro-skeleton-content">
            <div className="intro-skeleton-content-top">top</div>
            <div className="intro-skeleton-content-btm">btm</div>
          </div>

          <div className="intro-skeleton-outer-propertywrap">
            <div className="intro-skeleton-imgwrap"></div>
            <div className="intro-skeleton-rightsec">
              <div className="intro-skeleton-rightsec-top">top</div>
              <div className="intro-skeleton-rightsec-btm">btm</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IntroductionSkeleton;
