/**
 * @file   src\actions\messages.js
 * @brief  This file is responsible for redux actions related to messages
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIntroMessageDetailsByUserId } from "../services/messagesService";

 export const fetchMessages = createAsyncThunk(
    "messages/fetchMessages",
    (requestPayload) => {
      return getIntroMessageDetailsByUserId(requestPayload);
    }
  );
  

