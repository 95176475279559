import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import SampleProfImg from "../../images/sample-profile-image.png";
import { fetchUserDetails } from "../../actions/userprofile";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../images/no-image-placeholder.png";
import { getUserDetails } from "../../services/userprofileService";


const AgentProfileViewModal = (props) => {
  const { isTitleShow } = props;
  const isFromMessage = props.isFromMessage ? props.isFromMessage : false;

  const dispatch = useDispatch()
  const userDetailsObject = useSelector((state) => state.userProfile.userDetails);
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    if(props?.userId){
      
      getUserDetailsById()
    }
  }, [props?.userId])

  // useEffect(() => {
  //   setUserDetails(userDetailsObject && userDetailsObject[0])
  // }, [userDetailsObject])

  const getUserDetailsById = async ()=>{
    await getUserDetails({"id": props?.userId})
    .then((response) => {
      const responseResult = _.get(response, "result", null);
      if (response.errorCode === 0 && responseResult) {
        setUserDetails(responseResult && responseResult[0])
      } else {
        console.log("Something went wrong");
      }
    })
    .catch((err) => {
      console.log("Error", err);
    });
  }

  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings agent-modal"
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => { props.onClose(isFromMessage) }}
        ></button>
        <div className="agent-title">
          <h3>Agent Profile</h3>
          {isTitleShow && <p className="f-size-16">A licensed real-estate agent is representing this property.</p>}
          {/* <p className="f-size-16">A licensed real-estate agent is representing this property.</p> */}
        </div>
        <div className="profile-pic-sec">
        {userDetails?.Img ?
                      <figure className="brdr-rs-200">
                        <img src={userDetails?.Img ?? noImage} className="brdr-rs-200" />
                      </figure>
                      :
                      <div className="profile-placeholder-wrap profile-bg-orange">
                        {(userDetails?.FirstName + ' ' + userDetails?.LastName).replace(/-/g, "").match(/\b(\w)/g)}
                      </div>
                    }
          {/* <figure><img src={userDetails?.Img ? userDetails?.Img : noImage} /></figure> */}
          <div className="profile-name">
            <h6 className="d-flex">{userDetails?.FirstName + ' ' + userDetails?.LastName}
              {
                userDetails?.PlanName === "Pro Member" &&
                <span class="pro-blue-badge">PRO</span>
              }
            </h6>
            <p className="f-size-16">Agent @ {userDetails?.Company}</p>
          </div>
        </div>
        {userDetails?.Bio &&
          <div className="bio">
            <p className="title">Bio</p>
            <p> {userDetails?.Bio}</p>
          </div>
        }
        <div className="agent-info">
          <div className="field-group">
            <label>Phone number</label>
            <p>{userDetails?.Phone}</p>
          </div>
          <div className="field-group">
            <label>Work location</label>
            <p>{userDetails?.Location}</p>
          </div>
          <div className="field-group">
            <label>Agency</label>
            <p>{userDetails?.Company}</p>
          </div>
          <div className="field-group">
            <label>License number</label>
            <p>{userDetails?.LicenseNo}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AgentProfileViewModal;
