export const inventoryPageContents = {
    step_1 : {
        title : 'Let’s get started!',
        description : 'Do you want to create a new property or pick up where you left off?',
        skipButtonEnable : false,
        completedPercentage : 0,
        exitButtonText : 'Exit',
        backButtonEnable : false,
        hideContinueButton : false
    },
    step_2 : {
        title : 'What type of property do you have?',
        description : '',
        skipButtonEnable : false,
        completedPercentage : 15,
        exitButtonText : 'Exit',
        backButtonEnable : false
    },
    step_3 : {
        title : "Let’s find your property. We'll take care of the details.",
        description : "If your property doesn't have an address, enter a nearby street name and adjust the pin on the map.",
        skipButtonEnable : false,
        completedPercentage : 30,
        exitButtonText : 'Exit',
        backButtonEnable : true
    },
    step_4 : {
        title : "What do you want to do with your property?",
        description : "Select all that apply. Please note that you're not publicly listing your property.",
        skipButtonEnable : false,
        completedPercentage : 45,
        exitButtonText : 'Exit',
        backButtonEnable : true
    },
    step_5 : {
        title : "What are the features about your property that stand out?",
        description : "Select up to 16.",
        skipButtonEnable : true,
        completedPercentage : 60,
        exitButtonText : 'Save & Exit',
        backButtonEnable : true
    },
    step_6 : {
        title : "[TITLE]",
        description : "[DESC]",
        skipButtonEnable : false,
        completedPercentage : 75,
        exitButtonText : 'Save & Exit',
        backButtonEnable : true
    },

    step_7 : {
        title : "Upload photos of your property.",
        description : "Using high quality images will increase the chances of people accepting your introductions.",
        skipButtonEnable : true,
        completedPercentage : 90,
        exitButtonText : 'Save & Exit',
        backButtonEnable : true
    },

    step_8 : {
        title : "Add details about your property.",
        description : "Providing a description about your property will make your introductions stronger.",
        skipButtonEnable : true,
        completedPercentage : 100,
        exitButtonText : 'Save & Exit',
        backButtonEnable : true
    },
    step_9 : {
        title : "Ready to add?",
        description : "Confirm the details about your inventory.",
        skipButtonEnable : false,
        completedPercentage : 100,
        exitButtonText : 'Save & Exit',
        backButtonEnable : true
    },
   
   
    EDIT:{
        title:'Ready to save changes?'
    }
}

export const strengthenPageContents = {
    
        step_1 : {
            title : 'Tell us more about your property.',
            description : 'Adding additional information gives people a better idea of what you have.',
            skipButtonEnable : true,
            exitButtonText : 'Save & Exit',
            completedPercentage : 0,
            backButtonEnable : true
        },
        step_2 : {
            title : 'When will your property be available to [TYPE]?',
            description : '',
            skipButtonEnable : true,
            exitButtonText : 'Save & Exit',
            backButtonEnable : true,
            completedPercentage : 100
        },
      
    
}

export const inventoryAmenities = {
    memberInventory : {
        invntryId : '',
        userId : '',
        actvStep : '',
        cmpldPerctg : '',
        prprtyType : '',
        prptyTypeTxt : '',
        prptySubType :'',
        locAddress : '',
        neighborHood: '',
        highSchool: '',
        yearBuilt: '',
        noBedRm : '',
        noBathRm :'',
        noOfBeds:'',
        sqFt : '',
        stories :'',
        lotSize : '',
        grgeSpots : '',
        adaAccess : '', 
        IsNewConstruction: '',
        acreage : '',
        lotType :'',
        ltTypeTxt : '',
        roadType :'',
        rdTypeTxt :'',
        isPowerAccess: '',
        waterSuply: '',
        wSText : '',
        wasteWtrSys :'',
        WstWatrSys : '',
        watrWtrText : '',
        isPrvtDrInst: '',
        isDrldWelInst: '',
        isDrldWelAppr: '',
        isSepTankInst: '',
        isSepTankAppr: '',
        isApprovedStrctr :'',
        isInFloodPlain : '',
        proprtyPurpose :[],
        alwLrgDogs : '',
        alwSmlDogs : '',
        alwCats : '',
        alwPets : '',
        accsPvtBthRm : '',
        pvtEntrnce: '',
        isConfirmOwner :'',
        isConfirmAll :'',
        isConfirmPropLoc:'',
        nice2Have : [],
        sellAskngPrice : '',
        LTRAskngPrce: '',
        STRAskngPrce: '',
        isCompnsteAgent:'',
        isCompnsteAgent_LTR:'',
        isCompnsteAgent_STR:'',
        salesPercnt_LTR:'',
        salesPercnt_STR:'',
        salesPercnt :'',
        photos :[],
        invtryDetails :''
    },
    
    strengthenInvntry : {
       inventoryId :'',
       userId : '',
       apprOnFile: false,
       insReport:false,
       sellerFin : false,
       floorPlan:false,
       floodCert:false,
       assMortgage: false,
       timeLine: ''
    }
    
}


export const propertySubTypesItems = [
    {id : 1, text : 'house' },
    {id : 2, text : 'condo/apartment' },
    {id : 3, text : 'town home/attached home' },
    {id : 8, text : 'guest house' },
    {id : 5, text : 'manufactured' },
    {id : 9, text : 'villa' }
]

export const propertyDocuments = [
    {id : 1, text : 'Appraisal on file' },
    {id : 2, text : 'Inspection report on file' },
    {id : 3, text : 'Seller finance available' },
    {id : 4, text : 'Floor plan on file' },
    {id : 5, text : 'manufactured' },
    {id : 6, text : 'Flood certificate available' }
]

export const TITLE_FOR_PROPERTY_DETAILS_LOADING = "Does this information look right?";
export const DESC_FOR_PROPERTY_DETAILS_LOADING = "If anything doesn't accurate, update it with the correct information. ";
export const yrMaxInValid= 'yrMaxInValid'
   






    

