import React, { useState } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";

const FilterKeywordSearch = (props) => {
const {handleSearch, keyWord} = props
  return (
    <InputGroup className="search_field">
      <FormControl
        aria-label="Example text with button addon"
        aria-describedby="basic-addon1"
        placeholder="Search keyword"
        value={keyWord}
        onChange={handleSearch}
      />
      {/*<div className="search-icn" id="button-addon1"></div>*/}
      <Button className="search-icn" variant="secondary" id="button-addon1">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.875 3.75C6.93997 3.75 3.75 6.93997 3.75 10.875C3.75 14.81 6.93997 18 10.875 18C14.81 18 18 14.81 18 10.875C18 6.93997 14.81 3.75 10.875 3.75ZM2.25 10.875C2.25 6.11154 6.11154 2.25 10.875 2.25C15.6385 2.25 19.5 6.11154 19.5 10.875C19.5 15.6385 15.6385 19.5 10.875 19.5C6.11154 19.5 2.25 15.6385 2.25 10.875Z"
            fill="#6A768F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.9134 15.9134C16.2063 15.6205 16.6812 15.6205 16.9741 15.9134L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L15.9134 16.9741C15.6205 16.6812 15.6205 16.2063 15.9134 15.9134Z"
            fill="#6A768F"
          />
        </svg>
      </Button>
    </InputGroup>
  );
};
export default FilterKeywordSearch;
