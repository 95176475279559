import react from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";



const PlanUpgradeRestrictionModal = (props) => {
  const {show, onHide, currentActivePlan, selectedPeriod, handleContinueButton} = props;

    const getPeriod = () =>{
      if(currentActivePlan?.period===3){
        return "annual"
      }
      else{
        return "monthly"
      }
    }

    const getSelectedPeriod = () =>{
      if(selectedPeriod===3){
        return "annual"
      }
      else if(selectedPeriod===2){
        return "monthly"
      }
      else{
        return "lite"
      }
    }

    return (
    <>    
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings"
      backdrop='static'
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=> onHide()}
        ></button>
        <h3 className="text-start">Are you sure you don’t want the {getPeriod()} plan? You only need to pay the difference.</h3>
        <p className="f-size-16 mb-4">Changing your profile to a {getSelectedPeriod()} subscription will forfeit any prepaid {getPeriod()} rate for your current profile type.
        <br/><br/>Do you wish to proceed? </p>        

        <div className="modal-btn-wrap">
            <Button variant="" className="btn btn-tertiary me-3" onClick={()=>onHide()}>Back</Button>
            <Button variant="primary" onClick={()=>handleContinueButton()}>Continue</Button>
        </div>
      </Modal.Body>      
    </Modal>
    </>
  );
};
export default PlanUpgradeRestrictionModal;
