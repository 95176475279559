import React, { useState, useEffect } from "react";
import "../../../styles/clients.scss";
import { Elements } from "@stripe/react-stripe-js";
import PaymentComponent from "../../../components/Settings/PaymentComponent";
import { loadStripe } from "@stripe/stripe-js";

const AddPaymentMode = (props) => {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)
  );
  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
  }, []);

  return (
    <div className="gitcha-main-wrapper view-client-wrap select-payment-wrap pb-102">
      <Elements stripe={stripePromise}>
        <PaymentComponent {...props} />
      </Elements>
    </div>
  );
};

export default AddPaymentMode;
