import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { getIntroUserReportingReasons } from "../../services/commonServices";
import { getUserId } from "../../helpers/common";
import { reportIntroductionUser } from "../../services/introductionService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { pageURLs } from "../../constants/pageURLs";

const ReportUserModal = (props) => {
  const navigate = useNavigate();
  const { show, onHide, IntroductionId , reportedUserId, inventoryDetails, isFromMessage} = props;
  const [reportReasons, setReportReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const userId = getUserId();
  const inventoryId = _.get(inventoryDetails, "Inv_Id", "");

  useEffect(() => {
    fetchReportingReasons();
  }, []);

  const fetchReportingReasons = async () => {
    await getIntroUserReportingReasons().then((response) => {
      if (response?.errorCode === 0) {
        setReportReasons(response?.result);
      } else {
        setReportReasons([]);
      }
    });
  };

  const handleReportUser = async () => {
    const params = {
      "introductionId" : IntroductionId, 
      "reportedUserId" : reportedUserId, 
      "reasonId" : selectedReason
    }

    await reportIntroductionUser(params).then((response)=>{
      props.onHide()
      if(response?.errorCode===0){
        toast.dismiss()
        toast.success("Report was successfully submitted")
        if(isFromMessage){
          props.onHide()
        } else{
          navigate(pageURLs.allIntroductions)
        }
       
      }
      else{
        toast.dismiss()
        toast.error(response?.message)
      }

    }).catch((err)=>{
      toast.dismiss()
      toast.error(err)
    })
  }

  const handleCancel = () =>{
    setSelectedReason(null)
    props.onHide()
  }

  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings modal-payment cancel-plan"
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={props.onHide}
        ></button>
        <h3 className="text-start">Report user</h3>
        <p className="desc">Your report is always anonymous. Once the report is made we'll investigate the situation and determine what to do next.</p>
        <p className="note">Reason for reporting:</p>
        <div className="modal-controls-wrap">      
        {reportReasons.length > 0 &&
            reportReasons.map((reason) => {
              return (
                <label className="radio-wrap mb-3">
                  <input 
                  type="radio" 
                  name="radio-ad-option" 
                  onChange={()=>{setSelectedReason(reason?.ReasonId)}}
                  checked={reason?.ReasonId === selectedReason} />
                  <span>{reason?.Reason_Text}</span>
                </label>
              );
            })}  
         </div>
        

        <div className="modal-btn-wrap">
            <Button className="btn btn-tertiary me-2" onClick={handleCancel} >Cancel</Button>
            <Button variant="primary" disabled={selectedReason==null} onClick={()=>handleReportUser()}>Report user</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ReportUserModal;
