import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { useEffect } from "react";
import BottomStepperMob from "../components/BottomStepperMob";

const ProgressBar = (props) => {

  
  const { completedPercentage, setUIKey, uiKey} = props;
  const navigate = useNavigate();

  useEffect(()=>{
    document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
  },[])


  return (
    <div className="inner-layout-header-new bg-white">
      <div className="top-three-comp-header w-100 flex-column">
        <div className="top-three-comp-header-inner w-100">
          <a
            className="d-block back-link-big me-4"
            href={void 0}
            onClick={(e) => {
              if(uiKey===null){
                navigate(pageURLs?.mobileHome)
              }
              else {setUIKey(null)}
              }}
          >
            back
          </a>
          <div className="progress-wrap-mob">
           <BottomStepperMob completedPercentage={completedPercentage} />
          </div>

          <button
            className="btn top-exit-btn ms-4"
            onClick={() => {              
              navigate("/mobile/home")
            }}
          >
            Exit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
