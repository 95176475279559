import React from 'react';

const TextBox = props => {
  return (
    <>
      <label className= {props.label ? 'd-block' :'d-none' } >{props.label}</label>
      <input
        className={props.class ? `form-control ${props.class}` : "form-control"}
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        error={props.error}
        autoComplete={props.autoComplete}
        onChange={props.onChange}
        onBlur={props.onBlur}
        minLength={props.minLength}
        maxLength={props.maxLength}
        disabled={props.disabled}
        background-color={props.bgColor}
        ref={props.txtRef}
        min={props.minValue}
        max={props.maxValue}
        step = {props.step}
        readOnly = {props.readonly}
        tabIndex={props.tabIndex}
        autoFocus ={props.autoFocus}
        pattern={props.pattern}
        inputmode={props.inputmode}
        onKeyDown={props.onKeyDown}
        
      />
      {!props.notShowError && (props.errorClass ?  <div  className={props.errorClass}>{props.errorMessage}</div> :
        <div  className='error-msg'>
        {props.errorMessage && props.errorMessage !== '' && <span className='error-msg'>{props.errorMessage}</span>}
      </div>)}
    
   </>
  );
}

export default (TextBox);
