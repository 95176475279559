import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

const MarkNotFitDropdown = (props) => {
  return (
    <div>
        <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-client" className="action-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.4167 11.6666C11.3371 11.6666 12.0833 10.9204 12.0833 9.99992C12.0833 9.07944 11.3371 8.33325 10.4167 8.33325C9.49619 8.33325 8.75 9.07944 8.75 9.99992C8.75 10.9204 9.49619 11.6666 10.4167 11.6666Z" fill="#1B1C1E" />
                    <path d="M15 11.6666C15.9205 11.6666 16.6667 10.9204 16.6667 9.99992C16.6667 9.07944 15.9205 8.33325 15 8.33325C14.0795 8.33325 13.3333 9.07944 13.3333 9.99992C13.3333 10.9204 14.0795 11.6666 15 11.6666Z" fill="#1B1C1E" />
                    <path d="M5.83333 11.6666C6.75381 11.6666 7.5 10.9204 7.5 9.99992C7.5 9.07944 6.75381 8.33325 5.83333 8.33325C4.91286 8.33325 4.16667 9.07944 4.16667 9.99992C4.16667 10.9204 4.91286 11.6666 5.83333 11.6666Z" fill="#1B1C1E" />
                </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
                <Dropdown.Item className="delete" onClick={()=>props.markNotFitModelShow()}>Mark not a fit</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
    
  );
};
export default MarkNotFitDropdown;
