/**
 * @file   src\pages\ReceiveIntroduction.js
 * @brief  This file is responsible for showing introduction details
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import CommonHeader from "../components/Onboarding/CommonHeader";
import LakeViewIcon from "../images/lake-view-icon.svg";
import WantAdMoreIcon from "../images/want-ad-more-icon.svg";
import noImage from "../images/no-image-placeholder.png";
import MarkFitModal from "../components/Introduction/MarkFitModal";
import AgentProfileViewModal from "../components/Introduction/AgentProfileViewModal";

import ReasonForNotFitModal from "../components/Introduction/ReasonForNotFitModal";
import CheckCircleIcon from "../images/check-circle-icon.svg";
import {
  calculateTimeLeft,
  convertToCurrencySystem,
  filterArray,
  getLocalStorage,
  getPropertyPurpose,
  getUserId,
  getUserType,
  loggedInUser,
  removeDecimalZeros,
} from "../helpers/common";
import {
  AGENT_USER_MONTHLY_PRO_MEMBER,
  AGENT_USER_YEARLY_PRO_MEMBER,
  BUY,
  INTRODUCTION_EXPIRATION_TIME,
  INVENTORY_KEY,
  INVENTORY_LAND,
  INVESTOR_MEMBER_PLAN,
  IS_LAND,
  LOT_TYPES,
  PRO_MEMBER_PLAN,
  ROAD_TYPES,
  USER_TYPE_AGENT,
  WANT_AD_KEY,
  WASTE_WATER_SYSTEM,
  WATER_SUPPLY,
} from "../constants/common";
import { createIntroductionShareLink, getIntroductionInventoryDetailsById, updateIntroNotFit } from "../services/introductionService";
import { useLocation, useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import { convertToNumber } from "../helpers/validations";
import ReceiveIntroMessage from "../components/Messages/ReceiveIntroMessage";
import { updateIntroduction } from "../helpers/message";
import IntroExpiredModal from "../components/Introduction/IntroExpiredModal";
import { buildInventoryMustHaveHtml, buildSchoolNeigborhoodHtml, checkInventoryType } from "../helpers/inventory";
import PhotoGallery from "../components/Common/PhotoGallery";
import ReportUserModal from "../mobile/components/ReceiveIntroduction/ReportUserModal";
import NeedAnAgentModal from "../components/Onboarding/NeedAnAgentModal";
import { getUserPlanDetails } from "../services/userprofileService";
import ShareLinkModal from "../components/Introduction/ShareLinkModal";
import { useSelector } from "react-redux";
import userProfile from "../appState/userProfile";
import moment from "moment";

const ReceiveIntroduction = (props) => {
  const navigate = useNavigate();
  const location = useLocation()
  const [showMarkFitModal, setMarkFitModal] = useState(false);
  const markFitModalClose = () => setMarkFitModal(false);
  const markFitModalShow = () => setMarkFitModal(true);
  const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);

  const markFitReasonModalClose = () => setNotFitReasonPopup(false);

  const [showAgentProfileViewModal, setAgentProfileViewModal] = useState(false);
  const [isMessageSent, setMessageSent] = useState(false);
  const agentProfileViewModalClose = () => setAgentProfileViewModal(false);
  const agentProfileViewModalShow = () => setAgentProfileViewModal(true);

  const [showReportUserModal, setReportUserModal] = useState(false);
  const reportUserModalClose = () => setReportUserModal(false);
  const reportUserModalShow = () => setReportUserModal(true);

  const [showMarkFitConfirmModal, setMarkFitConfirmModal] = useState(false);
  const markFitConfirmModalClose = () => setMarkFitConfirmModal(false);

  const [showExpireModal, setExpireModal] = useState(false);
  const expireModalClose = () => setExpireModal(false);
  const expireModalShow = () => setExpireModal(true);
  const [timeOutStatus, setTimeOutStatus] = useState(false);

  const [showNeedAnAgentModal, setShowNeedAnAgemtModal] = useState(false);
  const needAnAgentModalOpen = () => {
    setShowNeedAnAgemtModal(true)
  }
  const needAnAgentModalClose = () => {
    setShowNeedAnAgemtModal(false)
  }
  const userType = getUserType();
  const [inventoryDetails, setInventoryDetails] = useState();
  const photos = _.get(inventoryDetails, "photos", []).filter(
    (item) => item.IsValid != 0
  );
  const photoClass = "inventory-banner position-relative mb-0";
  const propertyType = convertToNumber(
    _.get(inventoryDetails, "Sub_PropertyTypeId", "")
  );
  const isLand = propertyType === IS_LAND;
  const nice2Have = _.get(inventoryDetails, "nice2Have", []);
  const askingPrice = _.get(inventoryDetails, "AskingPrice", "");

  const noBedRm = removeDecimalZeros(_.get(inventoryDetails, "Bedrooms", ""));
  const noBathRm = removeDecimalZeros(_.get(inventoryDetails, "Bathrooms", ""));
  const sqFt = removeDecimalZeros(_.get(inventoryDetails, "Sqft", ""));
  const mapImg = _.get(inventoryDetails, "Location_Img", "");
  const highSchool = _.get(inventoryDetails, "HighSchool", null);
  const neighborHood = _.get(inventoryDetails, "NeighborHood", null);
  const stories =
    _.get(inventoryDetails, "Stories", "") === 0
      ? null
      : _.get(inventoryDetails, "Stories", "");

  const grgeSpots =
    _.get(inventoryDetails, "GarageSpots", "") === 0
      ? null
      : _.get(inventoryDetails, "GarageSpots", "");
  const lotSize =
    _.get(inventoryDetails, "LotSz", "") === 0
      ? null
      : _.get(inventoryDetails, "LotSz", "");
  const description = _.get(inventoryDetails, "Add_Details", "");
  const salesPercnt = _.get(inventoryDetails, "Compensate_Percent", "");
  const inventory = getLocalStorage(INVENTORY_KEY);
  const isAgent = _.get(inventoryDetails, "UserTypeId", "") === USER_TYPE_AGENT;
  const firstName = _.get(inventoryDetails, "FirstName", "");
  const lastName = _.get(inventoryDetails, "LastName", "");
  const fullName = firstName + " " + lastName;
  const introduction = getLocalStorage(INVENTORY_KEY);
  const introductionId = _.get(introduction, "introductionId", "");
  const introAcceptedDate = _.get(inventoryDetails, "Accept_Date", "");
  const userId = getUserId();

  const Sub_PropertyTypeId = convertToNumber(
    _.get(inventoryDetails, "Sub_PropertyTypeId", "")
  );
  const propertyPurpose = getPropertyPurpose(
    _.get(inventoryDetails, "Is_Seller", ""), _.get(inventoryDetails, "Is_ShortTerm_Renter", ""), _.get(inventoryDetails, "Is_LongTerm_Renter", "")
  );
  const invType = checkInventoryType(propertyPurpose);
  const typeBasedOnProperty = inventoryDetails?.PropertyTypeId == IS_LAND ? INVENTORY_LAND :
    Sub_PropertyTypeId === IS_LAND ? INVENTORY_LAND : invType;

  const is_Seller = _.get(inventoryDetails, "Is_Seller", 0) === 1 ? true : false;
  const wantAd = getLocalStorage(WANT_AD_KEY);
  const adType = _.get(wantAd, "wantAdType", "");
  const searchParams = new URLSearchParams(location.search);
  const stateData = Object.fromEntries(searchParams.entries());

  const fromMessagesScreen = stateData && stateData['fromMessagesScreen'];
  const userDeleted = stateData && stateData['userDeleted'];
  const [inventoryCreatedUserPlan, setInventoryCreatedUserPlan] = useState("");

  // const fromMessagesScreen =  location.state && location.state['fromMessagesScreen'];

  const [showCustomLinkPopuop, setShowCustomLinkPopup] = useState(false);
  const handleCustomLinkPopupOpen = () => {
    setShowCustomLinkPopup(true);
  };
  const handleCustomLinkPopupClose = () => {
    setShowCustomLinkPopup(false);
  };

  const agent = loggedInUser();
  const [linkToShare, setLinkToShare] = useState("");
  const [introShareLinkCreatedDate, setIntroShareLinkCreatedDate] = useState(null)
  const [shareLinkExpired, setShareLinkExpired] = useState(false)

  // const introAcceptedDate =  new Date(_.get(inventoryDetails, "Accept_Date", ""));


  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("")
  const [isPremiumUser, setIsPremiumUser] = useState(null);

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.length === 1) {
        if (userDetails[0]?.PlanName) {
          setUserPlan(userDetails[0]?.PlanName)
        }
      } else {
        if (userDetails[0]?.PlanName === 'Trial') {
          setUserPlan(userDetails[1]?.PlanName)
        } else {
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }
  }, [userDetails]);


  useEffect(() => {
    if (userPlan) {
      if (userPlan === PRO_MEMBER_PLAN) {
        setIsPremiumUser(true)
      }
      else {
        setIsPremiumUser(false)
      }
    }

  }, [userPlan])



  useEffect(() => {
    const checkShareLinkExpired = () => {
      if (introShareLinkCreatedDate) {
        const updatedIntroShareLinkCreatedDate = moment.utc(introShareLinkCreatedDate); // Replace with your UTC date string

      // Calculate expiration date
      const introShareLinkCreatedDate72 = moment.utc(updatedIntroShareLinkCreatedDate).add(INTRODUCTION_EXPIRATION_TIME, 'hours').toISOString();
      
      // Compare expiration date with current time
      if ((new Date(introShareLinkCreatedDate72) - new Date()) <= 0) {
          setShareLinkExpired(true);
        } else {
          setShareLinkExpired(false);
        }

      }


    }
    // Initial check
    checkShareLinkExpired();

    // Set an interval to check visibility every second
    const interval = setInterval(() => {
      checkShareLinkExpired();
    }, 1000);

    return () => clearInterval(interval);
  }, [introShareLinkCreatedDate]);



  const createShareLink = async () => {
    let reqPayload = {
      introductionId: introductionId
    }
    if (reqPayload) {
      await createIntroductionShareLink(reqPayload)
        .then(async (response) => {
          const responseResult = _.get(response, "result", null);
          if (responseResult && responseResult.length !== 0) {
            setLinkToShare((process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL + "/form/introduction/" + agent?.userInfo?.firstName + "-" + agent?.userInfo?.lastName + "-" + responseResult?.Intro_Unique_Id))
            setIntroShareLinkCreatedDate(responseResult?.Intro_Unq_ExpDt != null ? responseResult?.Intro_Unq_ExpDt : null)
            handleCustomLinkPopupOpen();
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  const updateToNotFit = async (reqPayload, isSkip) => {
    if (reqPayload) {
      await updateIntroNotFit(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isdeleted: true,
            }

            let updateresponse = await updateIntroduction(reqPayload?.IntroductionId, data);
            navigate(pageURLs.allIntroductions)

          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };


  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueType}>
            <div className="nicetohaves-tag-icon">
              <img src={icon ? require("../images/" + icon) : LakeViewIcon} />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const loadDetails = () => {
    return buildInventoryMustHaveHtml({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: inventoryDetails,
      isFromIntroduction: true
    });    
  };

  useEffect(() => {

    if (!introductionId) {
      navigate(pageURLs.home);
    }
    getInventoryDetails(introductionId);
  }, []);

  const getInventoryDetails = async (introductionId) => {
    if (introductionId) {
      await getIntroductionInventoryDetailsById(introductionId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
            setLinkToShare((process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL + "/form/introduction/" + agent?.userInfo?.firstName + "-" + agent?.userInfo?.lastName + "-" + responseResult?.Intro_Unique_Id))
            setIntroShareLinkCreatedDate(responseResult?.Intro_Unq_ExpDt != null ? (responseResult?.Intro_Unq_ExpDt) : null)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.home);
    }
  };

  useEffect(() => {
    const Inv_Id = _.get(inventoryDetails, "Inv_Id", null)
    if (Inv_Id) {
      getCreatedUserPlan(Inv_Id);
    }

  }, [inventoryDetails])

  const getCreatedUserPlan = async (Inv_Id) => {
    if (Inv_Id) {
      await getUserPlanDetails({ inventoryId: Inv_Id })
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryCreatedUserPlan(responseResult[0]?.PlanName);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const handleNotFitConfirm = () => {
    markFitModalClose()
    setNotFitReasonPopup(true)
  }
  const isNiceToHaveExist = nice2Have.length > 0;
  const messagePlaceHolderText = `Hey ${firstName}! Tell me more about your property.`;

  return (
    <>
      <NeedAnAgentModal
        show={showNeedAnAgentModal}
        onHide={needAnAgentModalClose}
      />
      <div className="outer-wrap modal-blur-wrap position-relative mw-1168">
        <CommonHeader />
        {!fromMessagesScreen &&
          <a role="button" className="bottom-back-btn mb-24 d-inline-block" href={void 0} onClick={() => navigate("/all-introductions")}>
            <span class="left-arrow">
              <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.10033 0.399663C8.39322 0.692556 8.39322 1.16743 8.10033 1.46032L3.31066 6.24999H18.5C18.9142 6.24999 19.25 6.58578 19.25 6.99999C19.25 7.41421 18.9142 7.74999 18.5 7.74999H3.31066L8.10033 12.5397C8.39322 12.8326 8.39322 13.3074 8.10033 13.6003C7.80744 13.8932 7.33256 13.8932 7.03967 13.6003L0.96967 7.53032C0.829018 7.38967 0.75 7.19891 0.75 6.99999C0.75 6.80108 0.829018 6.61031 0.96967 6.46966L7.03967 0.399663C7.33256 0.106769 7.80744 0.106769 8.10033 0.399663Z"
                  fill="#1B1C1E"
                />
              </svg>
            </span>
            Back
          </a>
        }
        <div className="row">
          <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-8 ps-xl-2">
            <div className="inventory-confirmation-wrap">
              <div className="confirmation-top-sec d-md-flex justify-content-between">
                <h2 className="long-txt-fix mb-0">{_.get(inventoryDetails, "Location", "")}</h2>

                {(!isAgent && userType != USER_TYPE_AGENT && adType == BUY) && <div className="confirm-top-right flex-shrink-0">
                  <button className="btn btn-secondary need-agent-btn" onClick={needAnAgentModalOpen}>
                    Need An Agent?
                  </button>
                </div>}
              </div>
              <PhotoGallery photos={photos} photoClass={photoClass} isFromDetailsPage={true} />

              <div className="musthave-wrap">
                <h2>Details</h2>
                {loadDetails()}
              </div>

              {description && (
                <div className="additional-details-wrap">
                  <h2>Description</h2>
                  <p className="long-txt-fix"> {description}</p>
                </div>
              )}
              {isNiceToHaveExist ? (
                <div className="nicetohaves-wrap">
                  <h2>Features</h2>
                  <div className="nicetohaves-inner-wrap">
                    {finalNiceToHaveList()}
                  </div>
                </div>
              ) : (
                ""
              )}


              {buildSchoolNeigborhoodHtml({ mapImg: mapImg, highSchool: highSchool, neighborHood: neighborHood })}


              {salesPercnt ||_.get(inventoryDetails, "TimeLineText", null) || 
              _.get(inventoryDetails, "Appraisal_on_File", false) || _.get(inventoryDetails, "Inspection_Report", false) ||
              _.get(inventoryDetails, "Seller_Finance", false) || _.get(inventoryDetails, "Flood_Certificate", false) ||
              _.get(inventoryDetails, "Floor_Plan", false) || _.get(inventoryDetails, "Assumable_mortgage", false)
               ?
               <div className="buyer-info">
                <h2 className="mb-16">Additional details</h2>
                <ul>
                  {salesPercnt && (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      <div>
                        <span className="f-bold">
                          {salesPercnt}% compensation&nbsp;
                        </span>{" "}
                        to buyers agent
                      </div>
                    </li>
                  )}

                  {_.get(inventoryDetails, "TimeLineText", null) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Timeline: {_.get(inventoryDetails, "TimeLineText", null)}
                    </li>
                  ) : (
                    ""
                  )}



                  {_.get(inventoryDetails, "Appraisal_on_File", false) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Appraisal on file
                    </li>
                  ) : (
                    ""
                  )}

                  {_.get(inventoryDetails, "Inspection_Report", false) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Inspection report on file
                    </li>
                  ) : (
                    ""
                  )}

                  {_.get(inventoryDetails, "Seller_Finance", false) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Seller finance available
                    </li>
                  ) : (
                    ""
                  )}
                  {_.get(inventoryDetails, "Flood_Certificate", false) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Flood certificate available
                    </li>
                  ) : (
                    ""
                  )}

                  {_.get(inventoryDetails, "Floor_Plan", false) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Floor plan on file
                    </li>
                  ) : (
                    ""
                  )}

                  {_.get(inventoryDetails, "Assumable_mortgage", false) ? (
                    <li className="f-size-16">
                      <img
                        src={CheckCircleIcon}
                        className="align-text-bottom pe-2 mt-1"
                      />
                      Assumable mortgage
                    </li>
                  ) : (
                    ""
                  )}

                </ul>
              </div> : <></>}
            </div>
          </div>

          <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2">
            <div className="ad-strengthen-wrap agent-info-wraper">
              {isAgent ? (
                <>
                  <div className="title-sec">
                    <h5>Agent Information</h5>
                    {!userDeleted && _.get(inventoryDetails, "Agent_User_Id", "") != userId &&
                      <div className="social-btns-wrap">
                        <Dropdown>
                          <Dropdown.Toggle variant="" id="dropdown-basic1">
                            <img src={WantAdMoreIcon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item onClick={reportUserModalShow}>
                              Report
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }
                  </div>
                  <div className="profile-pic-sec">
                    {_.get(inventoryDetails, 'Img', '') ?
                      <figure className="brdr-rs-200">
                        <img className="brdr-rs-200" src={_.get(inventoryDetails, 'Img', '') ?? noImage} />
                      </figure>
                      :
                      <div className="profile-placeholder-wrap profile-bg-orange flex-shrink-0">
                        {fullName.replace(/-/g, "").match(/\b(\w)/g)}
                      </div>
                    }
                    <div className="profile-name">
                      <h6 className="d-flex">
                        {fullName} {_.get(inventoryDetails, 'PlanId', '') === AGENT_USER_MONTHLY_PRO_MEMBER
                          || _.get(inventoryDetails, 'PlanId', '') === AGENT_USER_YEARLY_PRO_MEMBER &&
                          <span class="pro-blue-badge">PRO</span>}
                      </h6>
                      <p className="position">
                        Agent @ {_.get(inventoryDetails, "Company", "")}
                      </p>
                      {!userDeleted &&
                        <a role="button" onClick={agentProfileViewModalShow}>
                          View bio
                        </a>
                      }
                    </div>
                  </div>

                </>
              ) : (
                <div class="nicetohaves-wrap  pt-0">
                  <div className="title-sec">
                    <h5>Owner Information</h5>
                    {!userDeleted && _.get(inventoryDetails, "Agent_User_Id", "") != userId &&
                      <div className="social-btns-wrap">
                        <Dropdown>
                          <Dropdown.Toggle variant="" id="dropdown-basic1">
                            <img src={WantAdMoreIcon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item onClick={reportUserModalShow}>
                              Report
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }
                  </div>
                  <div className="profile-pic-sec mb-0">
                    <div className="profile-placeholder-wrap profile-bg-orange flex-shrink-0">
                      {fullName.replace(/-/g, "").match(/\b(\w)/g)}
                    </div>
                    <div className="profile-name">
                      <h6 className="d-flex mb-0">{fullName} {inventoryCreatedUserPlan === INVESTOR_MEMBER_PLAN &&
                        <span className="pro-gold-badge">Investor Member</span>} </h6>
                      <p className="position">
                        Property Owner
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {_.get(inventoryDetails, "Message", "") &&
                <div className="agent-testimonial">
                  <p>{firstName} said</p>
                  <div className="content-block">
                    {_.get(inventoryDetails, "Message", "")}
                  </div>
                </div>
              }

              {_.get(inventoryDetails, "Agent_User_Id", "") != userId &&

                <ReceiveIntroMessage
                  messagePlaceHolderText={messagePlaceHolderText}
                  IntroductionId={introductionId}
                  introAcceptedDate={introAcceptedDate}
                  firstName={firstName}
                  IsReplied={_.get(inventoryDetails, "IsReplied", "")}
                  msgSentStatus={(status) => { setMessageSent(status) }}
                  timeOutStatus={(status) => { setTimeOutStatus(status) }}
                />
              }

              <div className="rht-wrap-style mt-4">
                {(_.get(inventoryDetails, "Accept_Date", "") &&
                  _.get(inventoryDetails, "Agent_User_Id", "") != userId) &&
                  (_.get(inventoryDetails, "IsReplied", "") == 0 && !isMessageSent) &&

                  <Button
                    variant="outline"
                    className="w-100 btn-outline-primary btn-fit "
                    onClick={markFitModalShow}
                  >
                    Mark not a fit
                  </Button>
                }
              </div>

              {isPremiumUser && shareLinkExpired == false && _.get(inventoryDetails, "ClientId", null) &&
                <>
                  <div className="rht-wrap-style  border-0 pt-4">
                    <h5>
                      Want to share this property with your client?
                    </h5>
                    <p className="pt-3 pb-3">Once you create your private viewing link, it will expire after 72 hours.</p>
                    <a role="button" className="btnStyle" onClick={() => { createShareLink() }} >Create share link</a>
                  </div>
                </>
              }
            </div>




          </div>
        </div>
      </div>
      <ShareLinkModal
        show={showCustomLinkPopuop}
        onHide={() => handleCustomLinkPopupClose()}
        image={photos.length > 0 ? photos[0]?.image : ''}
        linkToShare={linkToShare}
      />
      <MarkFitModal
        show={showMarkFitModal}
        onHide={markFitModalClose}
        titleText="Mark not a fit and end conversation."
        description="This action will end the introduction and associated conversation. Once you delete this it cannot be undone."
        updateToNotFit={updateToNotFit}
        introductionId={introductionId}
        showNotFirReasnPopup={showNotFirReasnPopup}
        handleNotFitConfirm={handleNotFitConfirm}
        markFitReasonModalClose={markFitReasonModalClose}
        name={firstName}
      />
      <AgentProfileViewModal
        show={showAgentProfileViewModal}
        onClose={agentProfileViewModalClose}
        userId={_.get(inventoryDetails, 'Agent_User_Id', '')}
        isTitleShow={true}
      />
      <ReportUserModal
        show={showReportUserModal}
        onHide={reportUserModalClose}
        IntroductionId={introductionId}
        inventoryDetails={inventoryDetails}
        reportedUserId={_.get(inventoryDetails, 'Agent_User_Id', '')}
      />
      <ReasonForNotFitModal
        show={showMarkFitConfirmModal}
        onHide={markFitConfirmModalClose}
      />

      <IntroExpiredModal
        show={(!_.get(inventoryDetails, 'IsReplied', '') && timeOutStatus) || _.get(inventoryDetails, 'IsExpired', '') == 1}
        onHide={expireModalClose}
      />
    </>
  );
};

export default ReceiveIntroduction;
