/**
 * @file   src\appState\paymentMethodState.js
 * @brief  This file is responsible for fetching payments data from redux
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentMethods } from "../actions/payments";
import {useEffect } from "react";
import { getUserId } from "../helpers/common";

function paymentMethodState(props) {
  
  const dispatch = useDispatch();
  useEffect(() => {
   
    const userId = getUserId();
    if (userId) {
      dispatch(fetchPaymentMethods({ UserId: userId }))
    }
  }, [props.reload]);
    const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);
    return paymentMethods;
}
export default paymentMethodState