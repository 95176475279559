import React, { useEffect } from "react";
import ExploreMenuSelect from "./exploreMenuSelect";
import ExploreMenu from "./exploreMenu";
import PortfolioMenu from "./portfolioMenu";
import AddMenu from "./addMenu";
import MenuCloseIcon from "./menuClose";
import IntroMenu from "./introductionMenu";
import MessageMenu from "./messageMenu.js";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import userProfile from "../../appState/userProfile.js";
import {
  Button,
  Offcanvas,
  Tab,
  Tabs,
  Table,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import WantAd from "./wantAd";
import PropertyIcn from "./propertyAd";
import ProDashboardMenu from "./ProDashboardMenu.js";
import { useNavigate } from "react-router";
import { pageURLs } from "../../constants/pageURLs.js";
import { INVENTORY_KEY, INVENTORY_KEY_TEMP, INVESTOR_MEMBER_PLAN, NOT_FREE_OR_MEMBER_PERIOD, UNREADCHATMESSAGESCOUNT, USER_INFO_KEY, USER_TYPE_AGENT, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../constants/common.js";
import { checkTutorialDisplayStatus, getCurrentPath, getLocalStorage, getUserId, getUserType, loggedInUser, removeLocalStorage, saveTutorialStatusToLocal } from "../../helpers/common.js";
import { useSelector } from "react-redux";
import { fetchUserPrivileges, fetchUserStatistics } from "../../actions/userprofile.js";
import { useDispatch } from "react-redux";
import DocumentationTutorial from "../../components/Tutorial/DocumentationTutorial.js";
import IntroductionTutorialIcon from "../../images/tut-firstintro-icon.svg";
import  JoyRide,  { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { saveTutorialStatus } from '../../services/commonServices.js';

const MenuBottomBar = (props) => {
  const {  scrollDirection } = props;
  const [sessionData, setSessionData] = useState(
    getLocalStorage(USER_INFO_KEY)
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const WANT_AD = "wantad";
  const INVENTORY = "inventory";
  const AGENT_DASHBOARD = "AGENT_DASHBOARD";
  const userDetailsFromStore = useSelector((state) => state.userProfile.userDetails);//  userProfile()
  const [selectedMenu, setSelectedMenu] = useState(null);
  const userType = getUserType();
  let currentURL = getCurrentPath();
  let user = loggedInUser();
  const [isPremiumUser, setIsPremiumUser] = useState(null);
  const userStatictics = useSelector(
    (state) => state?.userProfile?.userStatictics
  );
  const userPrivileges = useSelector(
    (state) => state?.userProfile?.userPrivileges
  );
  const [userCount, setUserCount] = useState({});
  const [allowedCount, setAllowedCount] = useState({});

  let DisplayWantAddOnboard = checkTutorialDisplayStatus(6); //Want add onboard display
  let DisplayInventoryAddOnboard = checkTutorialDisplayStatus(7); //Inventory onboard display

  const tutorialPage = 2;
  const [displayTutorial, setDisplayTutorial] = useState(false);

  const {messageCount} = useSelector((state)=>state.unreadCount);

  let {unreadNotificationMessageCount} = useSelector((state)=>state.unreadCount);
   unreadNotificationMessageCount = unreadNotificationMessageCount?.payload


  const [unReadChatMessagesCount, setUnReadChatMessagesCount] = useState(messageCount);

  const [userPlan, setUserPlan] = useState("");

  // useEffect(()=>{
  //   console.log(getLocalStorage(UNREADCHATMESSAGESCOUNT))
  //   setUnReadChatMessagesCount(getLocalStorage(UNREADCHATMESSAGESCOUNT));

  // },[getLocalStorage(UNREADCHATMESSAGESCOUNT)])

  useEffect(() => {
      if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if (userDetailsFromStore[0]?.period !== NOT_FREE_OR_MEMBER_PERIOD && userType === USER_TYPE_AGENT) {
        setIsPremiumUser(true);
      }
      else {
        setIsPremiumUser(false);
      }

      if (userDetailsFromStore?.length === 1) {
        if (userDetailsFromStore[0]?.PlanName) {
          setUserPlan(userDetailsFromStore[0]?.PlanName)
        }
      } else {
        if (userDetailsFromStore[0]?.PlanName === 'Trial') {
          setUserPlan(userDetailsFromStore[1]?.PlanName)
        } else {
          setUserPlan(userDetailsFromStore[0]?.PlanName)
        }
      }
    }
    const payload = {
      UserPlanId: userDetailsFromStore && userDetailsFromStore[0]?.User_Plan_Id,
    };

    !scrollDirection && dispatch(fetchUserStatistics(payload));
    const data = {
      PlanId: userDetailsFromStore && userDetailsFromStore[0]?.PlanId,
    };
    !scrollDirection && dispatch(fetchUserPrivileges(data));
  }, [userDetailsFromStore]);

  useEffect(() => {
    if (userPrivileges !== null && userPrivileges.length > 0) {
      setAllowedCount(userPrivileges[0]);
    } else {
      setAllowedCount({});
    }
  }, [userPrivileges]);

  useEffect(() => {
    if (userStatictics !== null && userStatictics.length > 0) {
      setUserCount(userStatictics[0]);
    } else {
      setUserCount({});
    }
  }, [userStatictics]);

  useEffect(() => {
    if (user) {
      if (selectedMenu === WANT_AD) {
        removeLocalStorage(WANT_AD_KEY);
        removeLocalStorage(WANT_AD_KEY_TEMP);
        if (DisplayWantAddOnboard) {
          navigate(pageURLs.wantAdTutorialResponsive, {
            state: { isPremiumUser: isPremiumUser },
          });
        } else {
          if(isPremiumUser){
            navigate(pageURLs.mobilePremiumAgentCreateWantAd)
          }
          else {navigate(pageURLs.createWantAdResponsive);}
      }
      } else if (selectedMenu === INVENTORY) {
          if (DisplayInventoryAddOnboard) {
            navigate(pageURLs.inventoryTutorialResponsive);
          } else {
            if (userDetailsFromStore[0]?.period == NOT_FREE_OR_MEMBER_PERIOD){
              if(userCount?.Inventory != null){
                if(userCount?.Inventory >= 2){
                  navigate(pageURLs.updatePlan, {
                    state: { isFromSettings: true },
                  })
                }              
                else{
                  removeLocalStorage(INVENTORY_KEY);
                  removeLocalStorage(INVENTORY_KEY_TEMP);
                  navigate(pageURLs.mobileCreateInventory);
                }
              }
            }            
            else{
              console.log("else")
              removeLocalStorage(INVENTORY_KEY);
              removeLocalStorage(INVENTORY_KEY_TEMP);
              navigate(pageURLs.mobileCreateInventory);
            }
          }        
      } else if (selectedMenu === AGENT_DASHBOARD) {
        navigate(pageURLs.proDashboard);
      } else if (selectedMenu === 'message') {
        navigate(pageURLs.mobileMessages);
      } else if (selectedMenu === "explore" && currentURL != '/mobile/home') {
        navigate(pageURLs.mobileHome);
      }
    } else {
      if ((currentURL != '/mobile/home' && currentURL != '/mobile/view-ad') || (selectedMenu != null && selectedMenu != 'explore')) {
        navigate(pageURLs.mobileSignIn);
      }


    }
  }, [selectedMenu, user, userCount, userDetailsFromStore]);

  useEffect(() => {
    //if(isListItems) {
      if(checkTutorialDisplayStatus(tutorialPage)) {

        setTimeout(function() { setDisplayTutorial(true) }, 100);
      }
    //}
  }, [unreadNotificationMessageCount]);

  useEffect(() => {
   !_.get(props, 'noScrollBounce', false) &&
   document.getElementById("scroll-chain").classList.add("scroll-chaining-fix") // Scroll chaing fix in iphone(jumping effect)
  }, []);

  function OptionalMenu() {
    if (isPremiumUser != null || userPlan) {
      if (isPremiumUser == true || userPlan === INVESTOR_MEMBER_PLAN) {
        return (
          <ProDashboardMenu
            handleNavigation={handleNavigation}
            agent_dashboard={AGENT_DASHBOARD}
          />
        );
      } else {
        return <PortfolioMenu handleNavigation={handleNavigation}
          agent_dashboard={AGENT_DASHBOARD} />;
      }
    } else if (!user) { // to show menu if not logged in
      return <PortfolioMenu handleNavigation={handleNavigation}
        agent_dashboard={AGENT_DASHBOARD} />;
    }
    return <PortfolioMenu handleNavigation={handleNavigation}
    agent_dashboard={AGENT_DASHBOARD} />;
  }


  const [tutorialSteps, setTutorialSteps] = useState( 
    [
      {
        target: '.tutorial-intro',
        disableBeacon: true,
        placement : "left",
        title :"You received your first introduction!",
        icon : IntroductionTutorialIcon,
        content: 'Tap to view the preview of this property.',
        hideCloseButton : true,
        showProgress : false,
        showSkipButton : false
      }
    ]
  );
  //Tutorial tooltip
  const TutorialToolTip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    size,
    isLastStep,
    skipProps
  }) => (
   
    <>
    <div {...tooltipProps}>
    <DocumentationTutorial continuous= {continuous} 
      index ={index} 
      step ={step} 
      backProps = {backProps} 
      skipProps ={skipProps} 
      primaryProps ={primaryProps}
      isLastStep = {isLastStep}
      size ={size}
      finalStepBtn = {'Got It!'}
      hideBtns= {true}
      hideCounter={true}
    />
      </div>
    </>

    
  );
  //Tutorial status call back
  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type, lifecycle } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)){
      setDisplayTutorial(false)
      //Need to call API
      
    } 
    if(index == 0 && action =='update') {
      saveTutorialStatusToLocal(tutorialPage)
      if(sessionData &&  sessionData?.userInfo?.userId) {
        await saveTutorialStatus(tutorialPage, 1); //Save status
      }
    }
  }




  const handleNavigation = (selectedOption) => {
    setSelectedMenu(selectedOption);
  };



  return (
    <div className="d-flex justify-content-between btm-menufix">
      {displayTutorial ? 
        <JoyRide
            steps={tutorialSteps}
            tooltipComponent={TutorialToolTip}
            continuous
            callback={handleJoyrideCallback}
            disableScrolling  = {true}
            isFixed ={true}
            run = {true}
        /> : ''}
      <ExploreMenuSelect handleNavigation={handleNavigation} />
      <OptionalMenu />
      <Button
        variant="primary"
        onClick={() => handleShow()}
        className="addBtn addBtn-fix"
      >
        <AddMenu />
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="newOffCanvas newOffCanvas-btm-fix"
      >
        <Offcanvas.Body>
          <button
            type="button"
            class="btn-close offcanvas-close-btn "
            aria-label="Close"
            onClick={() => handleClose()}
          ></button>
          <ul>
            <li className="sepLine" onClick={() => handleNavigation(WANT_AD)}>
              <WantAd />
            </li>
            <li onClick={() => handleNavigation(INVENTORY)}>
              <PropertyIcn />
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <IntroMenu unreadNotificationMessageCount={unreadNotificationMessageCount} />
      <MessageMenu handleNavigation={handleNavigation} unReadChatMessagesCount={messageCount} />
    </div>
  );
};

export default MenuBottomBar;
