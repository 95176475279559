import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { InputGroup, FormControl, Row, Col, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const PropCollapse = (props) => {
  const {
    typeText,
    description,
    handlePropertyClick,
    typeId,
    propertySubItems,
    handlePropertySubItemClick,
    prptySubTypes,
    eventKey
  } = props;  

  const loadPropertySubTypes = () => {
    const List =
      propertySubItems &&
      propertySubItems.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const Img = _.get(pt, "Img", "");
        const isChecked = prptySubTypes && _.includes(prptySubTypes, typeId);
        return (
          <div key={typeId} className={isChecked ? "checkbox-wrap mb-2 checked-brdr" : "checkbox-wrap mb-2"}>
            <label className="form-check-label checkbox-label">
              <input
                type="checkbox"
                name="pst-checkbox"
                className="form-check-input"
                id={typeId}
                value={typeId ? typeId : ""}
                checked={isChecked}
                onChange={(e) => handlePropertySubItemClick(e)}
              />
              <span className="labelTxt">
                {_.get(pt, "PropertyTypeText", "")}
              </span>
            </label>
          </div>
        );
      });

    return List;
  };


  return (    
      <Accordion.Item className="mb-3" eventKey={eventKey}>
        <Accordion.Header onClick={() => handlePropertyClick(typeText, typeId)}>
          <h4>{typeText}</h4>
          <p>{description}.</p>
        </Accordion.Header>
        {propertySubItems && propertySubItems.length > 0 && (
          <Accordion.Body>
            <h6>Select Types:</h6>
            <div className="createAd-blueWrap">{loadPropertySubTypes()}</div>
          </Accordion.Body>
        )}
      </Accordion.Item>
  );
};
export default PropCollapse;
