/**
 * @file   src\components\Common\DropZoneFileUpload.js
 * @brief  This file is responsible for dropzone component for uploading photos
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";
import FileUploadIcon from "../../images/file-upload-icon.svg";
import Dropzone from "react-dropzone";

const DropZoneFileUpload = (props) => {
  const { uploadFile, uploadIconExist, uploadTitle, uploadDesc } = props;
  return (
    <Dropzone onDrop={(uploadedFiles) => uploadFile(uploadedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <section className="">
          <div className="file-upload-container" {...getRootProps()}>
            <input {...getInputProps()} />
            {uploadIconExist && (
              <div className="upload-img-wrap">
                <img src={FileUploadIcon} alt={FileUploadIcon} />
              </div>
            )}
            {uploadTitle && <h6>{uploadTitle}</h6>}
            {uploadDesc && <p>{uploadDesc}</p>}

            <button type="button" className="btn btn-primary">
              Upload Photos
            </button>
          </div>
        </section>
      )}
    </Dropzone>
  );
};
export default DropZoneFileUpload;
