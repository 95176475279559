import React from "react";
import NiceToHaveHelper from "./NiceToHaveHelper";

const OtherNiceToHaves = (props) => {
    const {others, handleItemClick, itemArray} = props
    return  <NiceToHaveHelper
       list={others}
       selectedItems={itemArray}
       propertyClick={handleItemClick}
    />
};
export default OtherNiceToHaves;
