/**
 * @file   src\mobile\components\Messages\Contact.js
 * @brief  This file is responsible for handling messaging/chat contact details.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react'
import { getInitials, setBodyBackgroundWhite, truncate } from '../../../helpers/common';
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import ModalMoreIcn from "../../../images/modal-more-btn.svg";
import { updateIntroduction } from '../../../helpers/message';
import { updateIntroNotFit } from '../../../services/introductionService';
// import ReportUserModal from '../MemberWantAd/ReportUserModal';
import UserIcon from "../../../images/intro-prof-placeholder.svg";
import { MESSAGES_VIEW } from '../../../constants/onBoarding';

function Contact(props) {
  const msgData = props.msgData;
  const userId = props.userId;

  const [userData, setUserData] = useState('');

  const calculateTimeAgo = () => {
    if (typeof msgData?.createdat == 'string') {
      let timeAgo = moment(new Date(msgData?.createdat).toUTCString()).fromNow(true);
      return timeAgo;
    } else {
      let timeAgo = moment(new Date(msgData?.createdat?.toDate()).toUTCString()).fromNow(true);
      return timeAgo;
    }
  }

  const [timeAgo, setTimeAgo] = useState(calculateTimeAgo());

  useEffect(() => {
    const interval = setInterval(() => setTimeAgo(calculateTimeAgo()), 1000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (msgData.introductionId) {
      if (msgData?.wantAdCreatedId == userId) {
        let nameSplit = msgData?.invetroyUserName.split(" ", 1)
        let { initials, backgroundColor } =
          getInitials(msgData?.invetroyUserName);
        setUserData({
          initials: initials,
          backgroundColor: backgroundColor,
          name: nameSplit[0],
          userId: msgData?.wantAdCreatedId,
          photo: msgData?.inventoryCreatedUserImage
        })
      } else if (msgData?.inventroyCreatedId == userId) {
        let { initials, backgroundColor } =
          getInitials(msgData?.wantAdCreatedUserFirstName + ' ' + msgData?.wantAdCreatedUserLastName);
        setUserData({
          initials: initials,
          backgroundColor: backgroundColor,
          name: msgData?.wantAdCreatedUserFirstName,
          userId: msgData?.inventroyCreatedId,
          photo: msgData?.wantAdCreatedUserImage
        })
      }
    }
  }, [msgData])


  return (
    <>
      <div class="msg-left-wrap mb-0 bdrbtm position-relative bg-white" onClick={() => { props.handleWantAdSelection(msgData); props.setUIKey(MESSAGES_VIEW) }}>
        {
          (msgData?.isrepiled == false && msgData?.wantAdCreatedId != userId)
            ?
            (
              <div className="intro-prof-wrap profile-bg-paleblue flex-shrink-0">
                <img className="prof-init-icon" src={UserIcon} />
              </div>

            )
            : (

              userData?.photo ?
                (
                  <div className={"msg-prof-wrap  flex-shrink-0 " + userData?.backgroundColor}>
                    <img src={userData?.photo} />
                  </div>
                ) : (
                  <div className={"msg-prof-wrap  flex-shrink-0 " + userData?.backgroundColor}>{userData?.initials}</div>
                )
            )
        }
        <div class="msg-details flex-grow-1">
          <div class="msg-main-heading">{(msgData?.isrepiled == false && msgData?.wantAdCreatedId != userId) ? "Waiting for a response" : userData?.name}
            {/* <span className="badge-msg-gold">PRO</span><span> • </span> */}
            {
              msgData?.badgeType && (msgData?.isrepiled == true) && <span class="pro-blue-badge d-inline-block align-middle ms-0">PRO</span>
            }
            <span> &bull; </span>
            {
              msgData?.inventroyCreatedId == userId ? msgData?.wantAdText && truncate(msgData?.wantAdText, 25) :
                msgData?.inventoryLocation && truncate(msgData?.inventoryLocation, 25)
            }

          </div>
          <div class="msg-main-text">  {msgData?.latestMessage && truncate((msgData?.latestMessage), 20)}</div>
        </div>

        <div>
          <span className="date-text ">  {calculateTimeAgo() ?? timeAgo}</span>
          {(msgData?.wantAdCreatedId == userId && msgData?.isWantAdOwnerHasUnreadMessage)  &&
            <div className="dot-indicator dot-bg-red ms-auto mt-1"></div>
          }
          { (msgData?.inventroyCreatedId == userId && msgData?.isInventoryOwnerHasUnreadMessage) &&
           <div className="dot-indicator dot-bg-red ms-auto mt-1"></div>
          }
        </div>
      </div>
      
    </>
  )
}
export default Contact