import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import TextArea from "../../../components/Common/TextArea";
import TextBox from "../../../components/Common/TextBox";
import FldCloseIcon from "../../images/round-close-icon.svg";
import { handleTextBoxChange } from "../../../actions/common";
import {
  capitalizeFirstString,
  getFileType,
  s3ImageBaseFolder,
  toTitleCase,
  getLocalStorage,
  reSizeImage,
  trimString,
  formatPhoneNumber,
  upperCaseEachSentenceFirst,
} from "../../../helpers/common";
import {
  alphaNumericValidation,
  phoneNumberValidation,
  textValidations,
} from "../../../helpers/validations";
import { getPreSignedUrl } from "../../../services/fileUploadService";
import { AGENT_USER_FREE_PLAN, ERROR_TEXT } from "../../../constants/common";
import { fetchUserDetails } from "../../../actions/userprofile";
import { USER_INFO_KEY } from "../../../constants/common";
import Loader from "../../../components/Common/Loader";
import { getAgentDetails } from "../../../services/agentClientsService";

import ValidationAlertIcon from "../../images/validation-alert-icon.svg";
import SparkleBigIcon from "../../images/sparkle-big-icon.svg";

import ProfImgPlaceholder from "../../images/prof-img-placeholder.svg";
import ProfileImageCropper from "./ProfileImageCropper";

const AgentProfile = (props) => {
  const dispatch = useDispatch();
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const userId = _.get(sessionData, "userInfo.userId", "");
  const userType = _.get(sessionData, "userInfo.userType", "");
  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const [userDetails, setUserDetails] = useState(null);
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFNError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLNError] = useState("");
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [license, setLicense] = useState("");
  const [licenseError, setLicenseError] = useState("");
  const [bio, setBio] = useState("");
  const [bioError, setBioError] = useState("");
  const [bioCount, setBioCount] = useState(0);
  const [saveDisabled, setDisabled] = useState(false);
  const [fileType, setFileType] = useState();
  const [imgError, setImgError] = useState("");
  const [reload, setReload] = useState(0);
  const supportedImageFormats = ["jpg", "jpeg", "png"];
  const { handleAgentDetailsSave } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [showPremiumPlanModal, setPremiumPlanModal] = useState(false);
  const PremiumPlanModalClose = () => setPremiumPlanModal(false);
  const PremiumPlanModalShow = () => setPremiumPlanModal(true);
  const s3ProfileImageFolder = s3ImageBaseFolder() + "/profileImage/Images/";
  const BIO_MAX_COUNT = 500;
  const MAX_FILE_SIZE = 25000; // In Bytes
  const MAX_WIDTH = 100;
  const MAX_HEIGHT = 100;
  const MIN_WIDHT = 700;
  const MIN_HEIGHT = 400;

  const [bioCursorPos, setBioCursorPos] = useState(0);
  const bioRef = useRef();

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [changedImage, setChangedImage] = useState(null);
  const [showPofileImageUploadModal, setShowPofileImageUploadModal] = useState(false);
  const ShowPofileImageUploadModalClose = () => setShowPofileImageUploadModal(false);
  const ShowPofileImageUploadModalShow = () => setShowPofileImageUploadModal(true);

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchUserDetails()
      );
    }
  }, [reload, userId]);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[userDetailsFromStore?.length - 1]);
    }
  }, [userDetailsFromStore]);

  useEffect(() => {
    if (userId) {
      getAgentInfo(userId);
    }
  }, []);

  const getAgentInfo = async (userId) => {
    await getAgentDetails(userId).then((response) => {
      if (response) {
        const result = _.get(response, "result[0]", null);
        setLocation(_.get(result, "Location", null));
        setCompany(_.get(result, "Company", null));
        setLicense(_.get(result, "LicenseNo", null));
        setImageUrl(_.get(result, "Img", ""));
        setFirstName(_.get(result, "FirstName", null));
        setLastName(_.get(result, "LastName", null));
        setBio(_.get(result, "Bio", null));
      }
    });
  };

  const handleLocationChange = (value) => {
    setLocationError("");
    if (trimString(value) !== "") {
      if (!textValidations(value.trim())) {
        setLocationError("Invalid input");
      } else {
        setLocation(value);
      }
    } else {
      setLocationError("Enter Location");
      setLocation("");
    }
  };

  const handleFirstNameChange = (e) => {
    setFNError("");
    const value = e.target.value;
    if (trimString(value) !== "") {
      if (!textValidations(value.trim())) {
        setFNError("Invalid input");
      } else {
        setFirstName(value);
      }
    } else {
      setFNError("Enter FirstName");
      setFirstName("");
    }
  };

  const handleLastNameChange = (e) => {
    setLNError("");
    const value = e.target.value;
    if (trimString(value) !== "") {
      if (!textValidations(value.trim())) {
        setLNError("Invalid input");
      } else {
        setLastName(value);
      }
    } else {
      setLNError("Enter LastName");
      setLastName("");
    }
  };

  const handleCompany = (value) => {
    setCompanyError("");
    if (trimString(value) !== "") {
      if (!alphaNumericValidation(value.trim())) {
        setCompanyError("Invalid input");
      } else {
        setCompany(value);
      }
    } else {
      setCompanyError("Enter Company");
      setCompany("");
    }
  };

  const handleLicenseChange = (value) => {
    setLicenseError("");
    if (trimString(value) !== "") {
      if (!alphaNumericValidation(value.trim())) {
        setLicenseError("Invalid input");
      } else {
        setLicense(value);
      }
    } else {
      setLicenseError("Enter License");
      setLicense("");
    }
  };

  const handlePhone = (value) => {
    setPhoneError("");
    if (trimString(value) !== "") {
      value = formatPhoneNumber(value);
        setPhone(value);
    } else {
     
      setPhoneError("Enter Phone Number");
      setPhone("");
    }
  };

  const validatePhoneNumber = ()=>{
    setPhoneError("");
    if (trimString(phone) !== "") {
      if(!phoneNumberValidation(phone.trim())){
        setPhoneError("Enter Valid Phone Number");
      } else {
        setPhone(phone);
      }
    } else {
      setPhoneError("Enter Phone Number");
      setPhone("");
    }

  }

  useEffect(() => {
    if(bioRef.current){
      bioRef.current.setSelectionRange(bioCursorPos, bioCursorPos);
    }
   
  }, [bioCursorPos]);

  const handleBioChange = (e) => {
    setBioError("");
    // setBioError("")
    const _bio = handleTextBoxChange(e);
    if (!textValidations(_bio)) {
      setBioError(ERROR_TEXT);
    }
    let value = capitalizeFirstString(_bio);
    value = upperCaseEachSentenceFirst(value);
    if(trimString(value) != ""){
      setBioCursorPos(e.target.selectionStart)
    }  
    setBioCount(value.length);
    setBio(value);
  };

  const updateReloadParam = () => {
    setReload(reload + 1);
  };

  const handleImageUpload = async (e) => {
    // setIsUploading(true);
    const selectedFileType = getFileType(_.get(e.target.files[0], "name"));
    const file = e.target.files[0];

    setImgError("");
    let isValid = true;
    if (!_.includes(supportedImageFormats, selectedFileType)) {
      isValid = false;
      // setIsUploading(false);
      setImgError("Select Valid Image");
    }
    if (isValid) {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setChangedImage(reader.result)
          ShowPofileImageUploadModalShow();
        };
        reader.readAsDataURL(file);
        // Reset the file input
        e.target.value = null;
      }
     

      // const resizedImage =
      //   fileSize > MAX_FILE_SIZE
      //     ? await reSizeImage({
      //         file: file,
      //         width: MIN_WIDHT,
      //         height: MIN_HEIGHT,
      //         fileType: selectedFileType,
      //       })
      //     : file;
      // const reqPayload = {
      //   key: s3ProfileImageFolder + userId + "." + selectedFileType,
      // };

      // await getPreSignedUrl(reqPayload)
      //   .then((response) => {
      //     if (response.errorCode === 0) {
      //       try {
      //         const headersValue = new Headers({ "Content-Type": file.type });
      //         fetch(response.result, {
      //           method: "PUT",
      //           headers: headersValue,
      //           body: resizedImage,
      //         })
      //           .then((response) => {
      //             const status = _.get(response, "status", "");
      //             const fileFullUrl = _.get(response, "url", "");
      //             const fileS3Url = fileFullUrl
      //               ? fileFullUrl.split("?")[0]
      //               : ""; // For excluding Aws credentials from the url and access s3 image Url.

      //             if (status === 200) {
      //               setImageUrl(fileS3Url);

      //               toast.success("Profile image changed successfully");
      //               setIsUploading(false);
      //             }
      //           })
      //           .catch((error) => {
      //             setIsUploading(false);
      //             toast.error(error);
      //           });
      //       } catch (error) {
      //         setIsUploading(false);
      //         toast.error(error);
      //       }
      //     } else {
      //       setIsUploading(false);
      //       toast.dismiss();
      //       toast.error(response.errorMessage);
      //     }
      //   })
      //   .catch((err) => {
      //     setIsUploading(false);
      //     toast.dismiss();
      //     toast.error(err);
      //   });
    }
  };

  const checkValid = () => {
    if(userDetails?.PlanName !=AGENT_USER_FREE_PLAN){
      if(location && license && company && firstName && lastName && phone){
        if(locationError || licenseError || companyError || firstNameError|| lastNameError || phoneError){
          return true
        }
        return false
      } else{
        return true
      }
    } else{
      if (location && license && company && firstName && lastName) {
        if (
          locationError ||
          licenseError ||
          companyError ||
          firstNameError ||
          lastNameError
        ) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  };

  const agentInfo = {
    location: location,
    company: company,
    licenseNo: license,
    bio: bio,
    img: imageUrl || "",
    plan: 0,
    firstName: firstName,
    lastName: lastName,
    phone: phone
  };

  return (
    <div className="gitcha-main-wrapper pb-0 bg-grey-variant">
      {isUploading ? <Loader /> : ""}
      <div className="content-wrapper pt-0 h-100">
        <div className="inner-layout-header-new bg-transparent d-none">
          <div className="review-plan-header w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              {/* <a className="back-link" href="#">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>

        <div className="inner-layout-content-new h-100">
          {userDetails &&
            userDetails?.PlanName &&
            userDetails?.PlanName === AGENT_USER_FREE_PLAN && (
              <div className="get-agent-wrap pt-5 clearfix">
                <h2>Let’s get your agent info</h2>
                <p className="pb-3 mb-0">
                  These details will be shown to clients after an introduction
                  has been accepted. It will remain anonymous until then.
                </p>
              </div>
            )}
          {userDetails &&
            userDetails?.PlanName &&
            userDetails?.PlanName !== AGENT_USER_FREE_PLAN && (
              <div className="welcome-premium-content">
                <h4 className="text-center pt-5">
                  Welcome to Pro Member{" "}
                  <img
                    className="align-text-baseline mb-1"
                    src={SparkleBigIcon}
                  />
                </h4>
                <p className="text-left">
                  With your Pro subscription, you can add a photo and bio to
                  your profile to help your introductions feel more
                  personalized. These details will be shown to clients after an
                  introduction has been accepted. It will remain anonymous until
                  then.
                </p>

                <div className="profile-wrap">
                  <div className="profile-wrap-img">
                    <figure>
                      {
                        <img
                          src={
                            imageUrl !== "" && imageUrl !== null
                              ? imageUrl + "?" + Math.random().toString(36)
                              : ProfImgPlaceholder
                          }
                        />
                      }
                    </figure>
                    <div className="file-upload">
                      <input
                        type="file"
                        name="profileImage"
                        id="file-3"
                        onChange={(e) => handleImageUpload(e)}
                        className="inputfile inputfile-3"
                        disabled={
                          userDetails?.PlanName !== AGENT_USER_FREE_PLAN
                            ? false
                            : true
                        }
                      />
                      <label for="file-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                        >
                          <g transform="translate(-2 -2)">
                            <path
                              d="M2,8a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,0,1H2.5A.5.5,0,0,1,2,8Z"
                              fill="#fff"
                              fill-rule="evenodd"
                            />
                            <path
                              d="M8,2a.5.5,0,0,1,.5.5v11a.5.5,0,0,1-1,0V2.5A.5.5,0,0,1,8,2Z"
                              fill="#fff"
                              fill-rule="evenodd"
                            />
                          </g>
                        </svg>
                      </label>
                      <span className="error-msg w-125 h-auto">{imgError}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="d-block pe-3 ps-3">
            <div className="row">
              {userDetails &&
                userDetails?.PlanName &&
                userDetails?.PlanName !== AGENT_USER_FREE_PLAN && (
                  <div className="col-12">
                    <div className="mob-fld-wrap position-relative mb-0">
                      <label className="form-label">Bio</label>
                      <TextArea
                        disabled={
                          userDetails?.PlanName !== AGENT_USER_FREE_PLAN
                            ? false
                            : true
                        }
                        className="form-control"
                        errorMessage={bioError}
                        maxLength={500}
                        value={bio ? bio : ""}
                        placeholder="Tell other agents and prospective clients a little bit about yourself..."
                        onChange={(e) => {
                          handleBioChange(e);
                        }}
                        txtRef={bioRef}
                      />
                      <div className="des-count mb-0">
                        <span>{bioCount}</span>/<span>{BIO_MAX_COUNT}</span>
                      </div>
                      <br></br> <br></br>
                      {/* <div className="mob-error-msg invisible">
                    Invalid email address
                  </div> */}
                    </div>
                  </div>
                )}

              <div className="col-6 pe-2">
                <div className="mob-fld-wrap position-relative mb-2">
                  <label className="form-label">
                    First name <span>*</span>
                  </label>

                  <TextBox
                    name="firstName"
                    type="text"
                    //errorMessage={firstNameError}
                    value={toTitleCase(firstName)}
                    placeholder="First name"
                    notShowError = {true}
                    class={
                      firstNameError
                        ? "form-control mob-pr-44 validation-alert-brdr"
                        : "form-control mob-pr-44"
                    }
                    onChange={(e) => {
                      handleFirstNameChange(e);
                    }}
                  />
                  {firstName && !firstNameError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => setFirstName("")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {firstNameError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}

                  <div className="mob-error-msg">{firstNameError}</div>
                </div>
              </div>
              <div className="col-6 ps-2">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">
                    Last name <span>*</span>
                  </label>

                  <TextBox
                    name="lastName"
                    type="text"
                    notShowError = {true}
                    class={
                      lastNameError
                        ? "form-control mob-pr-44 validation-alert-brdr"
                        : "form-control mob-pr-44"
                    }
                    //errorMessage={lastNameError}
                    value={toTitleCase(lastName)}
                    placeholder="Last name"
                    //errorClass="error-validation-msg"
                    onChange={(e) => {
                      handleLastNameChange(e);
                    }}
                  />
                  {lastName && !lastNameError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => setLastName("")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {lastNameError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}

                  <div className="mob-error-msg">{lastNameError}</div>
                </div>
              </div>
              {userDetails &&
                userDetails?.PlanName &&
                userDetails?.PlanName !== AGENT_USER_FREE_PLAN && (
              <div className="col-12">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">
                  Phone Number <span>*</span>
                  </label>

                  <TextBox
                    name="phone"
                    notShowError = {true}
                    type="text"
                    class={
                      phoneError
                        ? "form-control mob-pr-44 validation-alert-brdr"
                        : "form-control mob-pr-44"
                    }
                    //errorMessage={phoneError}
                    value={phone}
                    placeholder="321-456-7891"
                    //errorClass="error-validation-msg"
                    onChange={(e) => handlePhone(e.target.value)}
                    onBlur={()=>{validatePhoneNumber()}}
                    maxLength={14}
                    pattern="[0-9]*" 
                    inputmode="numeric"
                  />
                  {phone && !phoneError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => setLocation("")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {phoneError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}

                  <div className="mob-error-msg">{phoneError}</div>
                </div>
              </div>
                )}

              <div className="col-12">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">
                    Location <span>*</span>
                  </label>

                  <TextBox
                    name="location"
                    notShowError = {true}
                    type="text"
                    class={
                      locationError
                        ? "form-control mob-pr-44 validation-alert-brdr"
                        : "form-control mob-pr-44"
                    }
                    //errorMessage={locationError}
                    value={toTitleCase(location)}
                    placeholder="City, State"
                    //errorClass="error-validation-msg"
                    onChange={(e) => {
                      handleLocationChange(e.target.value);
                    }}
                    maxLength={40}
                  />
                  {location && !locationError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => setLocation("")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {locationError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}

                  <div className="mob-error-msg">{locationError}</div>
                </div>
              </div>

              <div className="col-12">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">
                    Company/Agency <span>*</span>
                  </label>

                  <TextBox
                    name="company"
                    type="text"
                    notShowError = {true}
                    value={toTitleCase(company)}
                    placeholder="Company or agency name"
                    //errorClass="error-validation-msg"
                    onChange={(e) => handleCompany(e.target.value)}
                    //errorMessage={companyError}
                    class={
                      companyError
                        ? "form-control mob-pr-44 validation-alert-brdr"
                        : "form-control mob-pr-44"
                    }
                    maxLength={50}
                  />
                  {company && !companyError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => setCompany("")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {companyError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}

                  <div className="mob-error-msg">{companyError}</div>
                </div>
              </div>

              <div className="col-12">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">
                    License number <span>*</span>
                  </label>

                  <TextBox
                    name="company"
                    type="text"
                    notShowError = {true}
                    class={
                      licenseError
                        ? "form-control mob-pr-44 validation-alert-brdr"
                        : "form-control mob-pr-44"
                    }
                    value={toTitleCase(license)}
                    placeholder="License number"
                    onChange={(e) => handleLicenseChange(e.target.value)}
                    maxLength={40}
                  />
                  {license && !licenseError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => setLicense("")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {licenseError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}

                  <div className="mob-error-msg">{licenseError}</div>
                </div>
              </div>
            </div>
            <div className="btn-wrap text-center pb-4 pt-2">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={checkValid()}
                onClick={() => handleAgentDetailsSave(agentInfo)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      <ProfileImageCropper
        userId={userId}
        image={changedImage}
        show={showPofileImageUploadModal}
        onHide={() => { ShowPofileImageUploadModalClose(); }}
        updateImageAfterCrop={(imageUrl) => { setImageUrl(imageUrl); ShowPofileImageUploadModalClose(); }}
      />

      {/*<div className="btm-fixed-nav"><button type="button" class="btn btn-primary w-100 mb-0" disabled>Confirm</button></div>*/}
    </div>
  );
};

export default AgentProfile;
