import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../../Common/TextBox";
import { nameValidation } from "../../../helpers/validations";
import { getLocalStorage, toTitleCase } from "../../../helpers/common";
import { USER_INFO_KEY } from "../../../constants/common";

const MemberUserProfileModal = (props) => {
  const {
    continueClick
  } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const[firstNameError, setFirstNameError] = useState('')
  const[lastNameError, setLastNameError] = useState('')
 
  useEffect(() => {
    const userDetails = getLocalStorage(USER_INFO_KEY)
    const firstName = _.get(userDetails, 'userInfo.firstName', null)
    const lastName = _.get(userDetails, 'userInfo.lastName', null)
    firstName && setFirstName(firstName)
    lastName && setLastName(lastName)
  }, []);

  const handleFirstName =(name) =>{
    setFirstNameError("");
    setFirstName(name);
  }

  const handleLastName =(name) =>{
    setLastNameError("");
    setLastName(name);
  }

  const handleContinue = () =>{
    setFirstNameError("");
    setLastNameError("");
    let isValid =  true
    if (firstName && !nameValidation(firstName.trim())) {
      isValid = false
      setFirstNameError("Invalid input");
    }
   if (lastName && !nameValidation(lastName.trim())) {
      isValid = false
      setLastNameError("Invalid input");
    }
    if(isValid){
      continueClick(firstName, lastName)
    }
    
  }

  
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body>
        {/* <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>{
            props.onHide()
            setFirstName("")
            setLastName("")
            setFirstNameError("")
            setLastNameError("")
          }}
        ></button> */}
        <h2 className="text-center">Let's complete your profile</h2>

        <Row>
        
        </Row>

        <Row>
          <Col xl={6}>
            <div className="textbox-wrap mb-4 mb-sm-4">
              <label className="form-label">First name</label>
              {/* <input
                placeholder="First name"
                type="text"
                className="form-control"
                onBlur={handleFirstNamelOnBlur}
              ></input> */}

              <TextBox
                id="fName"
                name="fName"
                type="text"
                value={toTitleCase(firstName)}
                placeholder="First name"
                class= {firstNameError ? "validation-txtfld form-control": "form-control"}
                onChange={(e)=>handleFirstName(e.target.value)}
                errorClass = "error-validation-msg"
                errorMessage={firstNameError}
              />

            </div>
          </Col>

          <Col xl={6}>
            <div className="textbox-wrap mb-4 mb-sm-4">
              <label className="form-label">Last name</label>
              {/* <input
                placeholder="Last name"
                type="email"
                className="form-control"
                onBlur={handleLastNamelOnBlur}
              ></input> */}

              <TextBox
                id="lName"
                name="lName"
                type="text"
                value={toTitleCase(lastName)}
                placeholder="Last name"
                class= {lastNameError ? "validation-txtfld form-control": "form-control"}
                errorClass = "error-validation-msg"
                onChange={(e)=>handleLastName(e.target.value)}
                errorMessage={lastNameError}
              />

            </div>
          </Col>
        </Row>

   

        <Button
          variant="primary"
          className="btn w-100"
          disabled={(firstName==="" || lastName==="") ? true : false}
          onClick={()=>handleContinue()}
        >
          Continue
        </Button>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default MemberUserProfileModal;
