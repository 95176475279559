/**
 * @file   src\components\Common\ButtonGroups.js
 * @brief  This file is responsible for button groups components such as bedrooms, bathrooms etc..
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { convertToNumber } from "../../helpers/validations";

const ButtonGroups = (props) => {
  const { variant, items, value, onClick, section, handleFormValues } = props;
  const previousValue = usePrevious(value);
  const [isDeselectItem, setDeSelect] = useState(false); // for deselect an item
 
 useEffect(() => { // This useeffet for clear the state value for garragespace(buy) and beds(short-term-rent) in want ad must haves when deselecting item. 
  if(previousValue && previousValue === value){
    if(section === 'Garage'){
      handleFormValues({ grgSpce: '' });
    } else if(section === 'Beds') {
      handleFormValues({ noOfBeds: '' });
    }
  }
 },[isDeselectItem])

 /**
  * To load buttons on page load
  * @returns 
  */
  const loadButtons = () => {
    const list =
      items &&
      items.map((itm, index) => {
        const itemValue = itm !== 'Any' ? convertToNumber(itm.replace("+", "")) :itm
        const selectedValue = value && value!== 'Any' ? convertToNumber(value) : value
       
        if(section === 'Garage' ||section === 'Beds') {
          return (
            <Button
              key={index}
              value={itemValue}
              onClick={()=>setDeSelect(!isDeselectItem)} // for manage deselecting an item 
              variant={selectedValue === itemValue ? variant + " active" : variant}
            >
              {itm}
            </Button>
          );

        } else {
          return (
            <Button
              key={index}
              value={itemValue}
              variant={selectedValue === itemValue ? variant + " active" : variant}
            >
              {itm}
            </Button>
          );
        }
      
      });
    return list;
  };
  return (
    <ButtonGroup aria-label="Basic example" onClick={onClick}>
      {loadButtons()}
    </ButtonGroup>
  );
};

export default ButtonGroups;