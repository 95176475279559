import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BedIcon from "../../../images/bed-icon.svg";
import BathIcon from "../../../images/bath-icon.svg";
import SqftIcon from "../../../images/sqft-icon.svg";
import SampleImage from "../../../images/img-house-1.png";
import MarkNotFitDropdown from "../MarkNotFitDropdown";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { getLocalStorage, getpropertyName, removeDecimalZeros, setBodyBackgroundGrey, thousandSeperatedValue } from "../../../helpers/common";
import { INTRODUCTION_ACCEPT, INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST, INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST, USER_INFO_KEY, WANT_AD_KEY } from "../../../constants/common";
import { usePrevious } from "../../../hooks/usePrevious";
import { getAcceptedIntroductions, getIntroductions, updateIntroNotFit } from "../../../services/introductionService";
import IntroductionData from "./IntroductionData";
import NotAFitModal from "./NotAFitModal";
import ReasonNotAFitModal from "./ReasonNotAFitModal";
import { updateIntroduction } from "../../../helpers/message";
import noImage from "../../../images/no-image-placeholder.png";
import HandleScroll from "../../commonComponents/HandleScroll";
import { INTRODUCTION_DETAILS, INTRODUCTION_PREVIEW } from "../../../constants/onBoarding";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";

const WantAdIntroductions = (props) => {
   
    const navigate = useNavigate();
    const [pendingList, setPendingList] = useState([]);
    const [acceptedList, setAcceptedList] = useState([]);
    const pendingScrollSection = "pending";
    const acceptedScrollSection = "accepted";
    const [pendingListCount, setPendingCount] = useState(0);
    const [acceptedListCount, setAcceptedCount] = useState(0);
    const [statusChange, setStatusChange] = useState("");
    const [pendingListPage, setPendingListPage] = useState(1);
    const [introDetails, setIntroDetails] = useState([]);
    const [acceptedListPage, setAcceptedListPage] = useState(1);
    const [introductionId, setIntroId] = useState("");
    const [markNotFitStatus, setMarkNotFitStatus] = useState([]);
    const [userFirstName, setUserFirstName] = useState(null);
    const [showMarkFitModal, setMarkFitModal] = useState(false);
    const markFitModalClose = () => setMarkFitModal(false);

    const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);

    const markFitReasonModalClose = () => setNotFitReasonPopup(false);

    const INTRO_LIST_PAGE_LIMIT = 10;

    const prevPending = usePrevious(pendingListPage);
    const prevAccepted = usePrevious(acceptedListPage);
   
    let  scrollStates = HandleScroll()
    const [pendingIntroductionsResponseGot, setPendingIntroductionsResponseGot] = useState(true);
    const [acceptedIntroductionsResponseGot, setAcceptedIntroductionsResponseGot] = useState(true);
    const isReachedBottom =  _.get(scrollStates, 'isReachedBottom', null)
  
    useEffect(() => {
        
        const wantAd = getLocalStorage(WANT_AD_KEY);
        const wantAdId = _.get(wantAd, "wantAdId", null);
        if (!wantAdId) {
            navigate(pageURLs.mobileHome);
        }
        const userId = _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", "");
        if (statusChange === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST) {
            setStatusChange("");
            setPendingListPage(1);
        } else if (statusChange === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST) {
            setStatusChange("");
            setAcceptedListPage(1);
        } else if (statusChange === INTRODUCTION_ACCEPT) {
            setStatusChange("");
            setAcceptedListPage(1);
            setPendingListPage(1);
        }
        if (
            (!prevPending && !prevAccepted) ||
            statusChange === INTRODUCTION_ACCEPT
        ) {
            getPendingIntroductions(pendingListPage, wantAdId, userId);
            getAcceptdIntroductions(acceptedListPage, wantAdId, userId);
        } else{
            if (
                prevPending !== pendingListPage ||
                statusChange === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST
                // ||statusChange === INTRODUCTION_DECLINE_UNDO
            ) {
                getPendingIntroductions(pendingListPage, wantAdId, userId);
            } 
            if (
                prevAccepted !== acceptedListPage ||
                statusChange === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST
            ) {
                getAcceptdIntroductions(acceptedListPage, wantAdId, userId);
            }
        }
        
    }, [pendingListPage, acceptedListPage, statusChange]);

    const getPendingIntroductions = async (page, wantAdId, userId) => {
        setPendingIntroductionsResponseGot(false)
        const reqPayload = {
            wantAdId: wantAdId,
            page: page,
            limit: INTRO_LIST_PAGE_LIMIT,
            isAccept: "0",
        };
        await getIntroductions(reqPayload).then((response) => {
            if (response) {
                const list = _.get(response, "result.List", null);
                const count = _.get(response, "result.Count", "");
                if (response.errorCode === 0) {
                    setPendingIntroductionsResponseGot(true)
                    setPendingList([...pendingList, ...list]);
                    setPendingCount(count);
                } else {
                    console.log(`####error`);
                }
            }
        });
    };

    const getAcceptdIntroductions = async (page, wantAdId, userId) => {
        setAcceptedIntroductionsResponseGot(false)
        const reqPayload = {
            wantAdId: wantAdId,
            page: page,
            limit: INTRO_LIST_PAGE_LIMIT,
        };
        await getAcceptedIntroductions(reqPayload).then(async (response) => {
            if (response) {
                const list = _.get(response, "result.List", null);
                const count = _.get(response, "result.Count", "");
                const titleText = _.get(response, "result.TitleText", "");
                if (response.errorCode === 0) {
                    setAcceptedIntroductionsResponseGot(true)
                    setAcceptedList([...acceptedList, ...list]);
                    setAcceptedCount(count);
                    //   setTitleText(titleText);
                } else {
                    console.log(`####error`);
                }
            }
        });
    };



    useEffect(() => {
        if (isReachedBottom && pendingIntroductionsResponseGot && acceptedIntroductionsResponseGot) {
            setPendingListPage((prevState) => prevState + 1);
            setAcceptedListPage((prevState) => prevState + 1);
        }
      }, [isReachedBottom]);

      useEffect(() => {
       setBodyBackgroundGrey()
      }, []);

    const leadingActions = () => (
        <LeadingActions>
            <SwipeAction onClick={() => console.info('swipe action triggered')}>
                Action name
            </SwipeAction>
        </LeadingActions>
    );

    const trailingActions = () => (
        <TrailingActions>
            <SwipeAction
                destructive={true}
                onClick={() => console.info('swipe action triggered')}
            >
                Delete
            </SwipeAction>
        </TrailingActions>
    );


    const markNotFitModelShow = (introId, status, name) => {

        setIntroId(introId);
        setMarkNotFitStatus(status);
        setMarkFitModal(true);
        setUserFirstName(name)
    };


    const handleNotFitConfirm = () => {
        markFitModalClose()
        setNotFitReasonPopup(true)
    }

    const updateToNotFit = async (reqPayload, isSkip) => {
        if (reqPayload) {
            await updateIntroNotFit(reqPayload)
                .then(async (response) => {
                    if (response.errorCode === 0) {
                        let data = {
                            isdeleted: true,
                        }

                        let updateresponse = await updateIntroduction(reqPayload?.IntroductionId, data);
                        setNotFitReasonPopup(false);
                        toast.success("Mark not a fit was successfully submitted.");
                        markNotFitStatus === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST && setPendingList([]);
                        markNotFitStatus === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST && setAcceptedList([]);
                        setStatusChange(markNotFitStatus);
                        setIntroId("");

                    } else {
                        console.log("Something went wrong");
                    }
                })
                .catch((err) => {
                    console.log("Error", err);
                });
        }
    };


    // function to load IntroductionData
    function IntroductionData (intro) {
        const introList = intro;
        const bedRoom = removeDecimalZeros(_.get(introList, "Bedrooms", ""));
        const bathRoom = removeDecimalZeros(_.get(introList, "Bathrooms", ""));
        const sqFt = removeDecimalZeros(_.get(introList, "Sqft", ""));
        const acreage = removeDecimalZeros(_.get(introList, "TotalAcre", ""));
        let lotSizeUnit = _.get(introList, "Acre_Sqft", "");
        if (lotSizeUnit && lotSizeUnit !== 0) {
          lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft";
        }
        return (
          <>
      
            <div className="make-intro-details pe-1" id="introTop">
              <h3 className="mb-0 long-txt-fix intro-head-medium"> {getpropertyName(introList)} in {_.get(introList, "City", "")}, {_.get(introList, "State", "")}</h3>
              <ul className="intro-icons-list">
                {bedRoom ?
                  <li>
                    <span className="list-txt">{bedRoom}</span>
                    <div className="list-icns"><img src={BedIcon} /></div>
                  </li> : <></>
                }
                {bathRoom ?
                  <li>
                    <span className="list-txt">{bathRoom}</span>
                    <div className="list-icns">
                      <img src={BathIcon} />
                    </div>
                  </li> : <></>
                } {sqFt ?
                  <li>
                    <span className="list-txt">{sqFt} sqft</span>
                    <div className="list-icns">
                      <img src={SqftIcon} />
                    </div>
                  </li> : <></>
                }
                {acreage ?
                  <li>
                    <span className="list-txt">{acreage + lotSizeUnit}</span>
                    <div className="list-icns">
                      <img src={SqftIcon} />
                    </div>
                  </li> : <></>
                }
              </ul>
              <div className="intro-amt">
                ${introList?.AskingPrice && thousandSeperatedValue(introList?.AskingPrice)}
              </div>
            </div>
          </>
        );
    }
    return (
        <>
            <div className="gitcha-main-wrapper my-client-wrap recieve-intorductions-wrap">
                <div className="content-wrapper">
                    <div className="inner-layout-header-new bg-white ">
                        <div className="review-plan-header header-brdr-btm w-100">
                            <div className="signup-login-header-top signup-header-txt clearfix">
                                <a className="back-link-small" href={void (0)} onClick={() => { props.setUIKey("") }}><svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                                </svg></a>
                                <span>Want-Ad Introductions</span>
                            </div>
                        </div>
                    </div>
                    <div className="inner-layout-content-new h-100 ">
                        <div className="notif-content-wrapper">
                            <div className="wantad-sec">
                                {pendingListCount > 0 &&
                                    <div className="wantad-list">
                                        <div className="title">Pending ({pendingListCount})</div>
                                        {pendingList?.map((intro) => {
                                            const introId = _.get(intro, "IntroductionId", "")
                                            const inventoryImage = (_.get(intro, "Photo_1", '') !== 'null' && _.get(intro, "Photo_1", '') !== 'undefined' && _.get(intro, "Photo_1", '') !== null)
                                                ? _.get(intro, "Photo_1", '') : noImage;

                                            return (
                                                <div className="add-your-property-wrap bg-white notif-brdr"   key={introId}>
                                                    {/* <div className="dot-indicator dot-bg-red m-0"></div> */}
                                                    <div className="make-intro-img flex-shrink-0">
                                                        <img src={inventoryImage} />
                                                    </div>
                                                    {IntroductionData(intro)}
                                                    {/* <IntroductionData introDetails={intro} /> */}
                                                    <button type="button" className="btn btn-primary btn-view" onClick={() => { props.setUIKey(INTRODUCTION_PREVIEW); props.setIntroductionId(introId) }}>View</button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                                {acceptedListCount > 0 &&
                                    <div className="wantad-list">
                                        <div className="title">Accepted</div>
                                        {acceptedList.map((intro) => {

                                            const introId = _.get(intro, "IntroductionId", "")
                                            const FirstName = _.get(intro, "FirstName", "")
                                            const inventoryImage = (_.get(intro, "Photo_1", '') !== 'null' && _.get(intro, "Photo_1", '') !== 'undefined' && _.get(intro, "Photo_1", '') !== null)
                                                ? _.get(intro, "Photo_1", '') : noImage;
                                            return (
                                                <div className="add-your-property-wrap bg-white notif-brdr"
                                                    onClick={() => {
                                                        _.get(intro, "IsExpired", "") != 1 && props.setUIKey(INTRODUCTION_DETAILS); props.setIntroductionId(introId)
                                                    }}
                                                    key={introId}>
                                                    <div className="make-intro-img flex-shrink-0">
                                                        <img src={inventoryImage} />
                                                    </div>

                                                    {IntroductionData(intro)} 
                                                    {/* <IntroductionData introDetails={intro}/> */}

                                                    {_.get(intro, "IsExpired", "") != 1 ?

                                                        <div className="social-btns-wrap" onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}><MarkNotFitDropdown
                                                                markNotFitModelShow={
                                                                    () => {
                                                                        markNotFitModelShow(introId, INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST, FirstName)
                                                                    }}
                                                            /></div>
                                                        :
                                                        <div className="property-action drop-pos-fix flex-shrink-0 modal-wrap-label-orange f-size-14 me-0">
                                                            Expired
                                                        </div>
                                                    }
                                                </div>

                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <NotAFitModal
                show={showMarkFitModal}
                onHide={markFitModalClose}
                titleText="Are you sure you want to mark this introduction as not fit?"
                description="This action will end the introduction and associated conversation. Once you delete this it cannot be undone."
                buttonTitle="Confirm"
                updateToNotFit={updateToNotFit}
                introductionId={introductionId}
                showNotFirReasnPopup={showNotFirReasnPopup}
                // handleNotFitConfirm={()=>{
                //  updateIntroStatus(markNotFitStatus)
                // }}
                handleNotFitConfirm={handleNotFitConfirm}
                markFitReasonModalClose={markFitReasonModalClose}
                name={userFirstName}
            />

            {/* <ReasonNotAFitModal
                show={showReasonNotAFitModal}
                onHide={ReasonNotAFitModalClose}
            /> */}
        </>
    );
};

export default WantAdIntroductions;
