import { INVENTORY_KEY, INVENTORY_KEY_TEMP } from "../../../constants/common";
import { pageURLs } from "../../../constants/pageURLs";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../helpers/common";
import BottomStepperMob from "../BottomStepperMob";
import { useNavigate } from "react-router-dom";
const ProgressBarInventory = (props) => {
    const navigate = useNavigate();
    const {completedPercentage, setUIKey,uiKey, isPremiumUser , isEdit} = props;
    const formValues= getLocalStorage(INVENTORY_KEY);
    const handleBackButtonClick = () =>{
        setLocalStorage(INVENTORY_KEY_TEMP, getLocalStorage(INVENTORY_KEY));
        if(uiKey==='client' && (!formValues || !formValues?.prprtyType || !formValues?.locAddress)){
            removeLocalStorage(INVENTORY_KEY)
            removeLocalStorage(INVENTORY_KEY_TEMP)
            navigate(pageURLs.mobileHome);
        }
        else if(uiKey==='propertyType' && (!formValues?.prprtyType || !formValues?.locAddress)){
            if(isPremiumUser){
                setUIKey("client")
            }
            else {navigate(pageURLs.mobileHome);}
        }
        else if(uiKey==='location' && formValues?.locAddress==undefined){
            setUIKey('propertyType');
        }
        else if(uiKey==='readyToPost'){
            setUIKey('description')
        }
        else{
            setUIKey(null);
        }
    }

    return(
        <div className="inner-layout-header-new bg-white">
                    <div className="top-three-comp-header w-100 flex-column">
                        <div className="top-three-comp-header-inner w-100">
                            <a className="d-block back-link-big me-4" 
                            href={void 0}
                            onClick={()=>handleBackButtonClick()}
                            >
                                back
                            </a>
                            {!isEdit &&<div className="progress-wrap-mob">
                                <BottomStepperMob
                                    completedPercentage={completedPercentage}
                                />
                            </div>}
                            <button className="btn top-exit-btn ms-4" onClick={()=>{
                                if(uiKey==="readyToPost"){
                                 setUIKey(null)
                                }
                                else {navigate(pageURLs.mobileHome)}
                                }}>{uiKey==="readyToPost" ? "Edit" : "Exit"}</button>
                        </div>
                    </div>
                </div>
    )
}

export default ProgressBarInventory;