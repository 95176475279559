/**
 * @file   src\components\Common\ErrorMessage.js
 * @brief  This file is responsible for showing Error states in buyerlisting form
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Col, Row } from "react-bootstrap";
import BuyerLogo from "../../images/the-buyer-logo.svg";

const ErrorMessage = (props) => {

  const { message} = props;
  return (<div class="outer-wrap outer-wrap-padding position-relative  pb-0" id="pageTop">
    <div class="top-header mb-24">
      <div class="w-100 h-100 d-flex p-0 flex-column flex-sm-column flex-md-row justify-content-between align-items-center">
        <div class="d-flex align-items-center align-items-md-start align-items-lg-end flex-column flex-lg-row">
          <div class="navbar-brand p-0 me-lg-3 text-center">
            <a href={void 0} className="d-block ">
              <img
                src={BuyerLogo}
                alt={BuyerLogo}
                className="logo-mob-width"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <Row>
      <Col className="text-center">
        <h2>{message}</h2>
      </Col>
    </Row>
  </div>

  );
}

export default ErrorMessage