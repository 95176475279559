import React, { useState, useEffect } from "react";
import { getInitials, loggedInUser, setBodyBackgroundWhite } from "../../helpers/common";
import { renameKey, combineKey } from "../../helpers/common";
import AddNewClient from "./ClientPopup";
import { getAgentsList, saveMemberWantAd } from "../../services/wantAdService";
import ProgressBarCreateAdOptions from "./ProgressBarCreateAdOptions";
import CustomLinkOffCanvas from "./CustomLinkOffCanvas";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { BUY } from "../../constants/common";

const ClientSelection = (props) => {
  const { completedPercentage, setUIKey, setCompletedPercentage } = props;
  const [show, setShow] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [clientId, setClientId] = useState(null);
  const [clientsList, setClientsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const agent = loggedInUser();

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    getClientList();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getClientList(); // to load client list on page load
  }, [searchText]);

  useEffect(() => {
    setBodyBackgroundWhite();
  }, []);  

  const getClientList = async () => {
    let query = {
      search: searchText ? searchText : null,
    };
    await getAgentsList(query)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          responseResult.forEach((obj) => renameKey(obj, "ClientId", "value"));
          responseResult.forEach((obj) =>
            combineKey(obj, "FirstName", "LastName", "label")
          );
          setClientsList(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleChangeClient = (label, value) => {
    setSelectedClient({
      clientId: value,
      clientName: label,
      clientData: {
        value: value,
        label: label,
      },
      focusOut: true,
    });
    setClientId(value);
    setDisableSave(false);
    setCompletedPercentage(60);
  };

  const handleNext = () => {
    const requestPayLoad = {...
      selectedClient,
      isFilledByClient : true,
      isAgentAsSeller : false,
      IsSavedDraft : true,
      IsPublished : false,
      cmpldStep : 1,
      cmpldPerctg : 7,
      adType : BUY,
      isAgentCreated : true
    }
    saveMemberWantAd(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          const linkToShare = process.env.REACT_APP_BUYERLISTING_SERVICE_BASE_URL+"/form/"+agent?.userInfo?.firstName+"-"+agent?.userInfo?.lastName+"-"+responseResult?.client_Want_ad_Id
          navigate(pageURLs?.mobileHome,{
            state: { showShareModal: true, url:linkToShare},
          })          
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });  
    
  };

  return (
    <>
      
      <div
        className={
          clientsList.length === 0
            ? "gitcha-main-wrapper pb-118 bg-white"
            : "gitcha-main-wrapper pb-168 bg-white"
        }
      >
        <div
          className={
            clientsList.length === 0
              ? "content-wrapper content-wrapper-h-118"
              : "content-wrapper content-wrapper-h-168"
          }
        >
          <ProgressBarCreateAdOptions
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
          />

          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
            <div className="layout-content-btm-sec position-static pe-0 ps-0">
              <h6 className="">Client</h6>
              <div className="mt-3 pt-0 mb-0 titleText">
                Select the client you’re representing for this want-ad
              </div>
              <div className="titleTextpara">
                Your client’s name is just for organization and hidden to
                everyone else but you.
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-3">
                <div className=" position-relative">
                  <div className="search_field_input position-relative mb-0 input-group d-flex">
                    <input
                      aria-label="Example"
                      aria-describedby="basic-addon3"
                      placeholder="Client name"
                      class="form-control pr-0"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    ></input>

                    <button
                      type="button"
                      id="button-addon3"
                      class="search-icon"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            {clientsList.length === 0 && (
              <div className="add-first-clientwrap">
                <p>Let’s add your first client!</p>
                <button className="btn btn-primary" onClick={handleShow}>
                  Add New Client
                </button>
              </div>
            )}

            <div
              className={
                clientsList.length == 0
                  ? "title-selection-wrap d-none"
                  : "title-selection-wrap "
              }
            >
              {clientsList && clientsList.length > 0 ? (
                clientsList.map((client) => {
                  return (
                    <a
                      href={void 0}
                      className={
                        clientId === client?.value
                          ? "createAd-content-wrap mb-3 d-flex align-items-center active"
                          : "createAd-content-wrap mb-3 d-flex align-items-center"
                      }
                      onClick={() =>
                        handleChangeClient(client?.label, client?.value)
                      }
                    >
                      <span
                        className={
                          "profileIcnDiv me-2 align-items-center " +
                          getInitials(
                            client?.FirstName + " " + client?.LastName
                          )?.backgroundColor
                        }
                      >
                        {
                          getInitials(
                            client?.FirstName + " " + client?.LastName
                          )?.initials
                        }
                      </span>
                      <span className="labelTxt"> {client?.label}</span>
                    </a>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav position-static">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-3"
                disabled={disableSave}
                onClick={() => handleNext()}
              >
                Next
              </button>
              <button
                type="button"
                className={
                  clientsList.length == 0
                    ? "btn btn-secondary w-100 d-none "
                    : "btn btn-secondary w-100"
                }
                onClick={handleShow}
              >
                Add New Client
              </button>
            </div>
          </div>
        </div>

        <AddNewClient
          show={show}
          onHide={handleClose}
          clientDetails={null}
          isEdit={false}
        />
      </div>
    </>
  );
};

export default ClientSelection;
