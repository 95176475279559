import React, { useState, useEffect } from "react";

import ProgressBar from "../ProgressBar";
import { getLocalStorage, setLocalStorage } from "../../../helpers/common";
import { STRENGTHEN_WANT_AD_KEY, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../../constants/common";
import { getCreditScores, getFinanceType } from "../../../services/wantAdService";
import ConfirmApprovalModal from "../../../components/MemberWantAd/ConfirmApprovalModal";

const AddCreditScore = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    setCompletedPercentage,
    isAgent,
    isStrengthenWantAd,
    isEdit
  } = props;

  const creditScore = _.get(formValues, "creditScore", null)
  const creditScoreInSession = _.get(getLocalStorage(WANT_AD_KEY), "creditScore", null)
  const [disableSave, setDisableSave] = useState(true);
  const [creditScoreList, setCSList] = useState([]);
  useEffect(() => {
    if (creditScore) {
      if(isEdit && creditScore===creditScoreInSession){
        setDisableSave(true)
      }
      else{ setDisableSave(false);}
    } else {
      const isDisable = true;
      setDisableSave(true);
    }
    getCreditDetails();
  }, [creditScore]);

 

  const getCreditDetails = async () => {
    await getCreditScores()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const creditScores = _.get(response, "result", null);
          setCSList(creditScores);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleItemClick = (value, text,desc) => {
    if (value) {
      handleFormValues({ creditScore: value , creditScoreText : text , creditScoreDesc : desc });
    }
  };

  const loadCreditScores = () => {
    const List =
      creditScoreList &&
      creditScoreList.map((pt) => {
        const id = _.get(pt, "CreditScoreId", "");
        const text = _.get(pt, "CreditScoreText", "");
        const desc = _.get(pt, "CreditScoreDesc", "");
        return (
            <a
            href={void 0}
            className={
              creditScore && creditScore == id
                ? "createAd-content-wrap mb-4 active"
                : "createAd-content-wrap mb-4"
            }
            onClick={(e) => {
              e.stopPropagation();
              handleItemClick(id, text, desc);
            }}
          >
            <span className="labelTxt">{text}<p>{desc}</p></span>
          </a>        
        );
      });

    return List;
  };

 

  return (
    <>
      <div className="gitcha-main-wrapper pb-102">
        <div className="content-wrapper content-wrapper-h-102">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
          />

          <div className="inner-layout-content-new bg-white pb-0 position-relative ">
            <div
              className="layout-content-btm-sec position-static"
            >
              <h6 className="">Credit score</h6>
              <h2 className="">{isAgent ? "What range is your client's credit score?": "What range is your credit score?"}</h2>
            </div>

            <div className="title-selection-wrap pt-0 pe-3 ps-3">
              {loadCreditScores()}
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  if(isAgent){
                    if(completedPercentage>80){
                      setCompletedPercentage(completedPercentage)
                    }
                    else{
                      setCompletedPercentage(80)
                    }
                  }
                  else{
                    setCompletedPercentage(100)
                  } 
                  setTimeout(() => {
                    if(isStrengthenWantAd){
                    setLocalStorage(
                      STRENGTHEN_WANT_AD_KEY,
                      getLocalStorage(WANT_AD_KEY_TEMP)
                    );
                    setUIKey(null);}
                    else{
                      setLocalStorage(
                        WANT_AD_KEY,
                        getLocalStorage(WANT_AD_KEY_TEMP)
                      );
                      setUIKey(null);
                    }
                  }, 500);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>      
    </>
  );
};

export default AddCreditScore;
