import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";

import TextArea from "../../Common/TextArea";
import { handleTextBoxChange } from "../../../actions/common";
import { checkIsNull, trimString } from "../../../helpers/common";
import { textValidations } from "../../../helpers/validations";
import { ERROR_TEXT } from "../../../constants/common";

const InventoryPropertyDetails = (props) => {
  const { handleContinueButtonState, handleFormValues, invtryDetails } = props;
  const [detailCharCount, setDetailCharCount] = useState(0);
  const [descError, setDescError] = useState('');

  useEffect(() => {
    setDetailCharCount(invtryDetails && invtryDetails.length ? invtryDetails.length : 0);
    if(invtryDetails){
    if(descError){
      disableContinueButton()
    }
    else if (trimString(invtryDetails) !== "" && textValidations(invtryDetails)) {
      handleContinueButtonState();
    } else {
      setDescError(ERROR_TEXT)
      disableContinueButton()
    }
  } else {
    disableContinueButton()
  }

  }, [invtryDetails, descError]);

  const disableContinueButton = () => {
    const isDisabled = true
    handleContinueButtonState(isDisabled)
  }


  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDetailsChange = (e) => {
    setDescError(null)
    const value = capitalizeFirstString(handleTextBoxChange(e));
    if (!checkIsNull(value)) {
      if(!textValidations(value)){
        setDescError(ERROR_TEXT)
      } 
      handleFormValues({ invtryDetails: value });
      setDetailCharCount(value.length);
    } else {
      handleFormValues({ invtryDetails: "" });
    }
  };
  return (
    <div className="center-content-sec mw-524">
      <div className="wantad-des-wrap">
        <div className="position-relative">
          <FormLabel>What should people know about your property?</FormLabel>

          <TextArea
            className="form-control txt-area"
            errorMessage ={descError}
            maxLength={2500}
            value={invtryDetails ? invtryDetails : ''}
            placeholder="Ex. We bought this house in 2000 and are considering selling it with the right offer. We've taken very good care of various maintenance...."
            onChange={(e) => {
              handleDetailsChange(e);
            }}
          />

          <div className="des-count mb-4">
            <span>{detailCharCount}</span>/<span>2500</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryPropertyDetails;
