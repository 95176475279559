import * as endPoints from "../constants/endPoints";
import { loggedInUser } from "../helpers/common";
import * as apiService from "./apiService";

export async function saveMemberInventoryData(requestPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_INVENTORY,
    "POST",
    requestPayload
  );
}

export async function saveMemberWantAdstrengthenData(requestPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_MEMBER_WANT_AD_STRENGTHN,
    "POST",
    requestPayload
  );
}

export async function getInventoryById(inventoryId) {
  const user = loggedInUser();
  const userId = _.get(user, "userInfo.userId", "");
  return await apiService.apiCall(
    endPoints.GET_INVENTORY_BY_ID +
      `?inventoryId=${inventoryId}`,
    "GET",
    {}
  );
}

export async function getInventoryMyDrafts(userId) {
  return await apiService.apiCall(
    endPoints.GET_INVENTORY_MY_DRAFTS,
    "GET",
    {}
  );
}

export async function saveInventoryStrengthenData(requestPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_STRENGTHEN_INVENTORY,
    "POST",
    requestPayload
  );
}

export async function getInventoryDocuments() {
  return await apiService.apiCall(endPoints.INVENTORY_DOCUMENTS, "GET", {});
}

export async function getMyInventoryList(params) {
  return await apiService.apiCall(
    endPoints.MY_INVENTORY_LISTING +
      `?status=${_.get(
        params,
        "status",
        ""
      )}&sortBy=${_.get(params, "sortBy", "")}&page=${_.get(
        params,
        "page",
        ""
      )}&limit=${_.get(params, "limit", "")}`,
    "GET",
    {}
  );
}

export async function deleteMyInventory(inventoryId) {
  return await apiService.apiCall(
    endPoints.DELETE_INVENTORY + `?inventoryId=${inventoryId}`,
    "DELETE",
    {}
  );
}

export async function inventoryMarkAsSold(payload) {
  return await apiService.apiCall(
    endPoints.INVENTORY_MARK_AS_SOLD + `?inventoryId=${payload?.inventoryId}`+`&didGitchHelped=${payload?.didGitchHelped}`+`&salesPrice=${payload?.salesPrice}`,
    "PUT",
    {}
  );
}

export async function getPropertyList(payload) {
  return await apiService.apiCall(endPoints.PROPERTY_LIST, "GET", payload);
}

export async function getInventoryListByUserId(payload) {
  return await apiService.apiCall(endPoints.GET_INVENTORY_LIST_BY_USER_ID, "GET", payload);
}

