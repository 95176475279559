import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import PremiumSparkleIcon from "../../images/premium-sparkle-icon.svg";
import { clearLocalStorageOnLogout, getUserType, setBodyBackgroundWhite } from "../../helpers/common";
import { pageURLs } from "../../constants/pageURLs";
import ContactModalMob from "../components/ContactModalMob";
import userProfile from "../../appState/userProfile";
import { NOT_FREE_OR_MEMBER_PERIOD, USER_TYPE_AGENT } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";
import EmailNotification from "./EmailNotification";

const Settings = () => {
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState(null);
  const userDetailsFromStore = userProfile();
  const planPeriod = convertToNumber(_.get(userDetails, "period", null));
  const handleLogOut = () => {
    clearLocalStorageOnLogout();
    //signOutFirebase();
    navigate(pageURLs.mobileHome);
    window.location.reload();
  };
 
  useEffect(()=>{
    document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
  },[])
  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserDetails(userDetailsFromStore[0]);
    } else {
      setUserDetails(null);
    }
  }, [userDetailsFromStore]);

  const handleNavigation = () => { 
    //alert('Not implemented')
    navigate(pageURLs.settings);
  }
  const [contactModalState, setContactModalState] = useState(false);
  const ContactModalClose = () => setContactModalState(false);
  const ContactModalShow = () => setContactModalState(true);

  return (
    <>
      <div className="gitcha-main-wrapper pb-0">
        <div className="content-wrapper h-100">
          <div className="inner-layout-header-new bg-white ">
            <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a className="back-link-btn" href={void 0} role="button" onClick={()=>navigate(pageURLs.mobileHome)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>
                <div className="heading">Settings</div>
                <div class="p-0 toplinks-mobile">
                  <div className="topbar-links notif-delete-btn d-none"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="inner-layout-content-new h-100 ">
            <div className="settings-wrap-mob min-h-100 bg-grey-variant">
              {planPeriod && planPeriod === 1 &&
                 <div className="upgrade-plan-wrap-mob upgrade-plan-bg-grey ">
                 <h2 className="text-center">
                   <span className="f-extrabold">Gitcha</span>{" "}
                   <span className="f-medium">{getUserType()===USER_TYPE_AGENT ? "Pro Member" :  "Member"}</span>{" "}
                   <img src={PremiumSparkleIcon} />
                 </h2>
                 <p className="para-color-blue-mob pl-20 pr-20 mb-0">
                   Upgrade and get 10 free introductions per month
                 </p>
               </div>}
           
              <div className="settings-middle-sec-mob">
                <a role="button" href={void 0} onClick={()=> navigate(pageURLs.mobileAccountSettings)}>
                  <div className="settings-display-mob mb-3">
                    <div className="settings-display-img-mob">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.00008 3C6.06709 3 4.50008 4.567 4.50008 6.5C4.50008 8.433 6.06709 10 8.00008 10C9.93308 10 11.5001 8.433 11.5001 6.5C11.5001 4.567 9.93308 3 8.00008 3ZM3.50008 6.5C3.50008 4.01472 5.5148 2 8.00008 2C10.4854 2 12.5001 4.01472 12.5001 6.5C12.5001 8.98528 10.4854 11 8.00008 11C5.5148 11 3.50008 8.98528 3.50008 6.5Z"
                          fill="#4A4C4F"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.00008 10.9995C6.85899 10.9995 5.73801 11.2998 4.74982 11.8704C3.76164 12.441 2.94107 13.2617 2.37062 14.25C2.23257 14.4891 1.92678 14.5711 1.68762 14.433C1.44846 14.295 1.3665 13.9892 1.50455 13.75C2.16276 12.6097 3.10957 11.6628 4.24978 11.0044C5.39 10.3461 6.68344 9.99945 8.00008 9.99945C9.31673 9.99945 10.6102 10.3461 11.7504 11.0044C12.8906 11.6628 13.8374 12.6097 14.4956 13.75C14.6337 13.9892 14.5517 14.295 14.3125 14.433C14.0734 14.5711 13.7676 14.4891 13.6295 14.25C13.0591 13.2617 12.2385 12.441 11.2503 11.8704C10.2622 11.2998 9.14117 10.9995 8.00008 10.9995Z"
                          fill="#4A4C4F"
                        />
                      </svg>
                    </div>
                    <div className="settings-display-details-mob">
                      <h3 className="mb-0">Account</h3>
                      <p>Profile, subscription, account info</p>
                    </div>

                    <span role="button" class="settings-right-arrow-link">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.64645 3.14645C5.84171 2.95118 6.15829 2.95118 6.35355 3.14645L11.3536 8.14645C11.5488 8.34171 11.5488 8.65829 11.3536 8.85355L6.35355 13.8536C6.15829 14.0488 5.84171 14.0488 5.64645 13.8536C5.45118 13.6583 5.45118 13.3417 5.64645 13.1464L10.2929 8.5L5.64645 3.85355C5.45118 3.65829 5.45118 3.34171 5.64645 3.14645Z"
                          fill="#1B1C1E"
                        />
                      </svg>
                    </span>
                  </div>
                </a>

                {/* <a role="button" href={void 0} onClick={()=>handleNavigation()}> */}
                <a role="button" href={void 0} onClick={()=> navigate(pageURLs.mobilePaymentHistory)}>
                  <div className="settings-display-mob mb-3">
                    <div className="settings-display-img-mob">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 6C7.50555 6 7.0222 6.14662 6.61107 6.42133C6.19995 6.69603 5.87952 7.08648 5.6903 7.54329C5.50108 8.00011 5.45157 8.50277 5.54804 8.98773C5.6445 9.47268 5.8826 9.91814 6.23223 10.2678C6.58186 10.6174 7.02732 10.8555 7.51227 10.952C7.99723 11.0484 8.49989 10.9989 8.95671 10.8097C9.41352 10.6205 9.80397 10.3 10.0787 9.88893C10.3534 9.4778 10.5 8.99445 10.5 8.5C10.5 7.83696 10.2366 7.20107 9.76777 6.73223C9.29893 6.26339 8.66304 6 8 6ZM8 10C7.70333 10 7.41332 9.91203 7.16664 9.7472C6.91997 9.58238 6.72771 9.34811 6.61418 9.07403C6.50065 8.79994 6.47094 8.49834 6.52882 8.20736C6.5867 7.91639 6.72956 7.64912 6.93934 7.43934C7.14912 7.22956 7.41639 7.0867 7.70736 7.02882C7.99834 6.97094 8.29994 7.00065 8.57403 7.11418C8.84811 7.22771 9.08238 7.41997 9.2472 7.66664C9.41203 7.91332 9.5 8.20333 9.5 8.5C9.5 8.89782 9.34196 9.27936 9.06066 9.56066C8.77936 9.84196 8.39782 10 8 10ZM15 4H1C0.867392 4 0.740215 4.05268 0.646447 4.14645C0.552678 4.24021 0.5 4.36739 0.5 4.5V12.5C0.5 12.6326 0.552678 12.7598 0.646447 12.8536C0.740215 12.9473 0.867392 13 1 13H15C15.1326 13 15.2598 12.9473 15.3536 12.8536C15.4473 12.7598 15.5 12.6326 15.5 12.5V4.5C15.5 4.36739 15.4473 4.24021 15.3536 4.14645C15.2598 4.05268 15.1326 4 15 4ZM12.1031 12H3.89687C3.729 11.4323 3.42175 10.9155 3.00311 10.4969C2.58447 10.0782 2.06775 9.771 1.5 9.60312V7.39687C2.06775 7.229 2.58447 6.92175 3.00311 6.50311C3.42175 6.08447 3.729 5.56775 3.89687 5H12.1031C12.271 5.56775 12.5782 6.08447 12.9969 6.50311C13.4155 6.92175 13.9323 7.229 14.5 7.39687V9.60312C13.9323 9.771 13.4155 10.0782 12.9969 10.4969C12.5782 10.9155 12.271 11.4323 12.1031 12ZM14.5 6.33563C13.9003 6.07775 13.4223 5.59973 13.1644 5H14.5V6.33563ZM2.83562 5C2.57774 5.59973 2.09973 6.07775 1.5 6.33563V5H2.83562ZM1.5 10.6644C2.09973 10.9223 2.57774 11.4003 2.83562 12H1.5V10.6644ZM13.1644 12C13.4223 11.4003 13.9003 10.9223 14.5 10.6644V12H13.1644Z"
                          fill="#4A4C4F"
                        />
                      </svg>
                    </div>
                    <div className="settings-display-details-mob">
                      <h3 className="mb-0">Payments</h3>
                      <p>Methods, history</p>
                    </div>

                    <span role="button" class="settings-right-arrow-link">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.64645 3.14645C5.84171 2.95118 6.15829 2.95118 6.35355 3.14645L11.3536 8.14645C11.5488 8.34171 11.5488 8.65829 11.3536 8.85355L6.35355 13.8536C6.15829 14.0488 5.84171 14.0488 5.64645 13.8536C5.45118 13.6583 5.45118 13.3417 5.64645 13.1464L10.2929 8.5L5.64645 3.85355C5.45118 3.65829 5.45118 3.34171 5.64645 3.14645Z"
                          fill="#1B1C1E"
                        />
                      </svg>
                    </span>
                  </div>
                </a>

                <a role="button" href={void 0}  onClick={()=> navigate(pageURLs.mobileEmailNotification)} userDetails={userDetails} >
                  <div className="settings-display-mob mb-3">
                    <div className="settings-display-img-mob">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5001 14.5C10.5001 14.6326 10.4474 14.7598 10.3537 14.8536C10.2599 14.9473 10.1327 15 10.0001 15H6.00012C5.86751 15 5.74033 14.9473 5.64656 14.8536C5.55279 14.7598 5.50012 14.6326 5.50012 14.5C5.50012 14.3674 5.55279 14.2402 5.64656 14.1464C5.74033 14.0527 5.86751 14 6.00012 14H10.0001C10.1327 14 10.2599 14.0527 10.3537 14.1464C10.4474 14.2402 10.5001 14.3674 10.5001 14.5ZM13.8657 12.5C13.779 12.6528 13.653 12.7797 13.5009 12.8676C13.3487 12.9555 13.1758 13.0012 13.0001 13H3.00012C2.82433 12.9998 2.6517 12.9532 2.49965 12.865C2.3476 12.7768 2.22148 12.65 2.13402 12.4975C2.04656 12.345 2.00084 12.1722 2.00147 11.9964C2.0021 11.8206 2.04906 11.6481 2.13762 11.4963C2.48449 10.8988 3.00012 9.20875 3.00012 7C3.00012 5.67392 3.5269 4.40215 4.46458 3.46447C5.40226 2.52678 6.67403 2 8.00012 2C9.3262 2 10.598 2.52678 11.5357 3.46447C12.4733 4.40215 13.0001 5.67392 13.0001 7C13.0001 9.20813 13.5164 10.8988 13.8632 11.4963C13.9527 11.6483 14 11.8215 14.0003 11.9979C14.0007 12.1743 13.954 12.3476 13.8651 12.5H13.8657ZM13.0001 12C12.517 11.1706 12.0001 9.25313 12.0001 7C12.0001 5.93913 11.5787 4.92172 10.8285 4.17157C10.0784 3.42143 9.06098 3 8.00012 3C6.93925 3 5.92183 3.42143 5.17169 4.17157C4.42154 4.92172 4.00012 5.93913 4.00012 7C4.00012 9.25375 3.48262 11.1713 3.00012 12H13.0001Z"
                          fill="#4A4C4F"
                        />
                      </svg>
                    </div>
                    <div className="settings-display-details-mob">
                      <h3 className="mb-0">Notifications</h3>
                      <p>Control notification types</p>
                      {/* <EmailNotification userDetails={userDetails} /> */}
                    </div>

                    <span role="button" class="settings-right-arrow-link">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.64645 3.14645C5.84171 2.95118 6.15829 2.95118 6.35355 3.14645L11.3536 8.14645C11.5488 8.34171 11.5488 8.65829 11.3536 8.85355L6.35355 13.8536C6.15829 14.0488 5.84171 14.0488 5.64645 13.8536C5.45118 13.6583 5.45118 13.3417 5.64645 13.1464L10.2929 8.5L5.64645 3.85355C5.45118 3.65829 5.45118 3.34171 5.64645 3.14645Z"
                          fill="#1B1C1E"
                        />
                      </svg>
                    </span>
                  </div>
                </a>
              </div>

              <div className="settings-middle-sec-mob">
                <a role="button" href={void 0} onClick={()=>ContactModalShow()}>
                  <div className="settings-display-mob mb-3">
                    <div className="settings-display-img-mob">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.75 11.25C8.75 11.3983 8.70602 11.5433 8.6236 11.6667C8.54119 11.79 8.42406 11.8861 8.28701 11.9429C8.14997 11.9997 7.99917 12.0145 7.85368 11.9856C7.7082 11.9566 7.57456 11.8852 7.46967 11.7803C7.36478 11.6754 7.29335 11.5418 7.26441 11.3963C7.23548 11.2508 7.25033 11.1 7.30709 10.963C7.36386 10.8259 7.45999 10.7088 7.58333 10.6264C7.70666 10.544 7.85167 10.5 8 10.5C8.19892 10.5 8.38968 10.579 8.53033 10.7197C8.67098 10.8603 8.75 11.0511 8.75 11.25ZM8 4.5C6.62125 4.5 5.5 5.50938 5.5 6.75V7C5.5 7.13261 5.55268 7.25979 5.64645 7.35355C5.74022 7.44732 5.86739 7.5 6 7.5C6.13261 7.5 6.25979 7.44732 6.35356 7.35355C6.44732 7.25979 6.5 7.13261 6.5 7V6.75C6.5 6.0625 7.17313 5.5 8 5.5C8.82688 5.5 9.5 6.0625 9.5 6.75C9.5 7.4375 8.82688 8 8 8C7.86739 8 7.74022 8.05268 7.64645 8.14645C7.55268 8.24021 7.5 8.36739 7.5 8.5V9C7.5 9.13261 7.55268 9.25979 7.64645 9.35355C7.74022 9.44732 7.86739 9.5 8 9.5C8.13261 9.5 8.25979 9.44732 8.35356 9.35355C8.44732 9.25979 8.5 9.13261 8.5 9V8.955C9.64 8.74563 10.5 7.83625 10.5 6.75C10.5 5.50938 9.37875 4.5 8 4.5ZM14.5 8C14.5 9.28558 14.1188 10.5423 13.4046 11.6112C12.6903 12.6801 11.6752 13.5132 10.4874 14.0052C9.29973 14.4972 7.99279 14.6259 6.73192 14.3751C5.47104 14.1243 4.31285 13.5052 3.40381 12.5962C2.49477 11.6872 1.8757 10.529 1.6249 9.26809C1.37409 8.00721 1.50282 6.70028 1.99479 5.51256C2.48676 4.32484 3.31988 3.30968 4.3888 2.59545C5.45772 1.88122 6.71442 1.5 8 1.5C9.72335 1.50182 11.3756 2.18722 12.5942 3.40582C13.8128 4.62441 14.4982 6.27665 14.5 8ZM13.5 8C13.5 6.9122 13.1774 5.84883 12.5731 4.94436C11.9687 4.03989 11.1098 3.33494 10.1048 2.91866C9.09977 2.50238 7.9939 2.39346 6.92701 2.60568C5.86011 2.8179 4.8801 3.34172 4.11092 4.11091C3.34173 4.8801 2.8179 5.86011 2.60568 6.927C2.39347 7.9939 2.50238 9.09977 2.91867 10.1048C3.33495 11.1098 4.0399 11.9687 4.94437 12.5731C5.84884 13.1774 6.91221 13.5 8 13.5C9.45819 13.4983 10.8562 12.9184 11.8873 11.8873C12.9184 10.8562 13.4983 9.45818 13.5 8Z"
                          fill="#4A4C4F"
                        />
                      </svg>
                    </div>
                    <div className="settings-display-details-mob">
                      <h3 className="mb-0">Help</h3>
                    </div>
                  </div>
                </a>

                <a role="button" href={void 0} onClick={() => handleLogOut()}>
                  <div className="settings-display-mob mb-3">
                    <div className="settings-display-img-mob">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 13.5C7 13.6326 6.94732 13.7598 6.85355 13.8536C6.75979 13.9473 6.63261 14 6.5 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6.5C6.63261 2 6.75979 2.05268 6.85355 2.14645C6.94732 2.24021 7 2.36739 7 2.5C7 2.63261 6.94732 2.75979 6.85355 2.85355C6.75979 2.94732 6.63261 3 6.5 3H3V13H6.5C6.63261 13 6.75979 13.0527 6.85355 13.1464C6.94732 13.2402 7 13.3674 7 13.5ZM13.8538 7.64625L11.3538 5.14625C11.2599 5.05243 11.1327 4.99972 11 4.99972C10.8673 4.99972 10.7401 5.05243 10.6462 5.14625C10.5524 5.24007 10.4997 5.36732 10.4997 5.5C10.4997 5.63268 10.5524 5.75993 10.6462 5.85375L12.2931 7.5H6.5C6.36739 7.5 6.24021 7.55268 6.14645 7.64645C6.05268 7.74021 6 7.86739 6 8C6 8.13261 6.05268 8.25979 6.14645 8.35355C6.24021 8.44732 6.36739 8.5 6.5 8.5H12.2931L10.6462 10.1462C10.5524 10.2401 10.4997 10.3673 10.4997 10.5C10.4997 10.6327 10.5524 10.7599 10.6462 10.8538C10.7401 10.9476 10.8673 11.0003 11 11.0003C11.1327 11.0003 11.2599 10.9476 11.3538 10.8538L13.8538 8.35375C13.9002 8.30731 13.9371 8.25217 13.9623 8.19147C13.9874 8.13077 14.0004 8.06571 14.0004 8C14.0004 7.93429 13.9874 7.86923 13.9623 7.80853C13.9371 7.74783 13.9002 7.69269 13.8538 7.64625Z"
                          fill="#4A4C4F"
                        />
                      </svg>
                    </div>
                    <div className="settings-display-details-mob">
                      <h3 className="mb-0">Log out</h3>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <ContactModalMob
        show={contactModalState}
        onHide={ContactModalClose}
        sourceType={1} // help menu
      />
      </div>
    </>
  );
};

export default Settings;
