import LotIcon from "../images/lot-icon.svg";
import HouseTagIcon from "../images/property-icon-house.svg";
import ApartmentIcon from "../images/property-icon-apartment.svg";
import GuestHouseIcon from "../images/property-icon-guesthouse.svg";
import TownHomeIcon from "../images/property-icon-townhome.svg";
import ManufacturedIcon from "../images/property-icon-manufactured.svg";
import MultiFamilyComplexIcon from "../images/property-icon-multifamilycomplex.svg";
import HotelIcon from "../images/property-icon-hotel.svg";
import VillaIcon from "../images/property-icon-villa.svg";
import PrivateRoomIcon from "../images/property-icon-privateroom.svg";
import OtherIcon from "../images/property-icon-other.svg";
import { setLotSizeValue, wantAdSqftValue } from "./createWantAd";
import { removeDecimalZeros } from "./common";

export const getPropertyIcon = (propertyId) => {
  let icon;
  switch (propertyId) {
    case 1:
      icon = HouseTagIcon;
      break;
    case 2:
      icon = ApartmentIcon;
      break;
    case 3:
      icon = TownHomeIcon;
      break;
    case 4:
      icon = MultiFamilyComplexIcon;
      break;
    case 5:
      icon = ManufacturedIcon;
      break;
    case 6:
      icon = LotIcon;
      break;
    case 7:
      icon = OtherIcon;
      break;
    case 8:
      icon = GuestHouseIcon;
      break;
    case 9:
      icon = VillaIcon;
      break;
    case 11:
      icon = PrivateRoomIcon;
      break;
    case 12:
      icon = HotelIcon;
      break;
    default:
      icon = HouseTagIcon;
  }
  return icon;
};

export const getTooltipText = (propertyId) => {
  let text;
  switch (propertyId) {
    case 1:
      text = "House";
      break;
    case 2:
      text = "Condo/Apartment";
      break;
    case 3:
      text = "Town Home/Attached Home";
      break;
    case 4:
      text = "Multi-family Complex";
      break;
    case 5:
      text = "Manufactured";
      break;
    case 6:
      text = "Lot / Land";
      break;
    case 7:
      text = "Other";
      break;
    case 8:
      text = "Guesthouse";
      break;
    case 9:
      text = "Villa";
      break;
    case 11:
      text = "Private Room";
      break;
    case 12:
      text = "Hotel";
      break;
    default:
      text = "House";
  }
  return text;
};

export const getCombinedText = (subtypes) => {
  if (subtypes && subtypes?.length > 0) {
    let combinedText = getTooltipText(subtypes[0]);    
    if(subtypes?.length > 1){for (let i = 1; i < subtypes?.length-1; i++) {
      combinedText = combinedText + ", " + getTooltipText(subtypes[i]);
    }
    combinedText = combinedText+' or '+getTooltipText(subtypes[subtypes?.length-1]);}
    return combinedText;
  }
};

export const getAdTypeText = (adType) => {
  if(adType==='BUY'){
      return "Buy a"
  }
  else if(adType==="LTR"){
      return "Rent a"
  }
  else if(adType==="STR"){
      return "Stay at a"
  }
}

const getSubPropertyTypeText = (subtypes)=>{
  if (subtypes && subtypes?.length > 0) {
      let combinedText = subtypes[0];    
      if(subtypes?.length > 1){
      for (let i = 1; i < subtypes?.length-1; i++) {
        combinedText = combinedText + ", " + subtypes[i];
      }
      combinedText = combinedText+' or '+subtypes[subtypes?.length-1];
      }
      return combinedText;
    
  }

}

export const getPropertyText = (adDetails) => {
  const adType = adDetails?.adType;
  // if(adType==='BUY'){
  //     return adDetails.prptyTypeTxt?.toLowerCase()
  // }
  // else{
      return getCombinedText(adDetails?.prptySubTypes)?.toLowerCase()
  // }
  
}

  
  
export const getDescriptionText = (adDetails) => {
  const prprtyType = _.get(adDetails, "prprtyType", []);
  const lotType = _.get(adDetails, "lotType", null);
  const adType = _.get(adDetails, "adType", null);
  const garageSpace = _.get(adDetails, "grgSpce", null);
  const stories = _.get(adDetails, "stories", null);
  const minLtSz = _.get(adDetails, "minLtSz", null);
  const maxLtSz = _.get(adDetails, "maxLtSz", null);
  const yearBuiltOption = _.get(adDetails, "yearBuiltOption", null);
  const yearBuiltMin = _.get(adDetails, "yrBltMin", null);
  const yearBuildMax = _.get(adDetails, "yrBltMax", null);
  const accsPvtBthRm= _.get(adDetails, "accsPvtBthRm", null) ? "Access to private bathroom" : null;
  const alwPets= _.get(adDetails, "alwPets", null)  ? "Allows pets" : null;
  const pvtEntrnce= _.get(adDetails, "pvtEntrnce", null)  ? "Access to private entrance" : null;
  const adaAccess = _.get(adDetails, "adaAccess", null) ? "Ada accessible" : null;
  let ltSize = "";
  ltSize = setLotSizeValue( _.get(adDetails, "minLtSz", null), _.get(adDetails, "maxLtSz", null));
  let sqFt = wantAdSqftValue(_.get(adDetails, "sqFtMin", ""), _.get(adDetails, "sqFtMax", "") )
  let noBathRoom = _.get(adDetails, "noBathRm", "") || "Any";
  let noBedRoom = _.get(adDetails, "noBedRm", "") || "Any";
  let noBeds = _.get(adDetails, "noOfBeds", "") || "Any";
  if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
  if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";  
  if (noBeds && noBeds !== "Any") noBeds = noBeds + "+";  
  const lotTypeText = lotType ? (lotType === "1" ? 'Rural' : 'In city limits') : '';
  if(adType==='STR'){
      if(prprtyType==6){
          return lotTypeText+" lot type | "+ltSize+" lot size"
      }
      else if(prprtyType==11){
        return ""
      }
      else{
          return noBedRoom +" beds | "+noBathRoom+" baths | "+noBeds+" beds"
      } 
  }
  else {
      if(prprtyType==6){
          return lotTypeText+" lot type | "+ltSize+" lot size"
      }
      else if(prprtyType==11){
        return ""
      }
      else{
          return noBedRoom +" beds | "+noBathRoom +" baths | "+sqFt+" sqft"
      }
  }
  
} 

export const getDescriptionTextForInventory = (adDetails) => {
  const prprtyType = _.get(adDetails, "prprtyType", []);
  const lotType = _.get(adDetails, "lotType", null);
  const adType = _.get(adDetails, "adType", null);
  const garageSpace = _.get(adDetails, "grgSpce", null);
  const stories = _.get(adDetails, "stories", null);
  const minLtSz = _.get(adDetails, "minLtSz", null);
  const maxLtSz = _.get(adDetails, "maxLtSz", null);
  const yearBuiltOption = _.get(adDetails, "yearBuiltOption", null);
  const yearBuiltMin = _.get(adDetails, "yrBltMin", null);
  const yearBuildMax = _.get(adDetails, "yrBltMax", null);
  const accsPvtBthRm= _.get(adDetails, "accsPvtBthRm", null) ? "Access to private bathroom" : null;
  const alwPets= _.get(adDetails, "alwPets", null)  ? "Allows pets" : null;
  const pvtEntrnce= _.get(adDetails, "pvtEntrnce", null)  ? "Access to private entrance" : null;
  const adaAccess = _.get(adDetails, "adaAccess", null) ? "Ada accessible" : null;
  let ltSize = "";
  ltSize = setLotSizeValue( _.get(adDetails, "minLtSz", null), _.get(adDetails, "maxLtSz", null));
  let sqFt = wantAdSqftValue(_.get(adDetails, "sqFtMin", ""), _.get(adDetails, "sqFtMax", "") )
  let noBathRoom = _.get(adDetails, "noBathRm", "") || "Any";
  let noBedRoom = _.get(adDetails, "noBedRm", "") || "Any";
  let noBeds = _.get(adDetails, "noOfBeds", "") || "Any";
  // if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom;
  // if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom;  
  // if (noBeds && noBeds !== "Any") noBeds = noBeds;  
  const lotTypeText = lotType ? (lotType === "1" ? 'Rural' : 'In city limits') : '';
  if(adType==='STR'){
      if(prprtyType==6){
          return lotTypeText+" lot type | "+ltSize+" lot size"
      }
      else if(prprtyType==11){
        return ""
      }
      else{
          return noBedRoom +" beds | "+noBathRoom+" baths | "+noBeds+" beds"
      } 
  }
  else {
      if(prprtyType==6){
          return lotTypeText+" lot type | "+ltSize+" lot size"
      }
      else if(prprtyType==11){
        return ""
      }
      else{
          return noBedRoom +" beds | "+noBathRoom +" baths | "+sqFt+" sqft"
      }
  }
  
} 

const getPrivateRoomMustHaves = (alwPets,adaAccess,pvtEntrnce,accsPvtBthRm) => {
  let str = ""
  if(adaAccess){
    str = "Ada accessible"
  }
  if(alwPets){
    if(str?.length > 0){
      str = str + " | Allows pets"
    }
    else {
      str = "Allows pets"
    }
  }
  if(pvtEntrnce){
    if(str?.length > 0){
      str = str + " | Access to private entrance"
    }
    else {
      str = "Access to private entrance"
    }
  }
  if(accsPvtBthRm){
    if(str?.length > 0){
      str = str + " | Access to private bathroom"
    }
    else {
      str = "Access to private bathroom"
    }
  }
  return str
}


export const getMustHavesText = (adDetails) => {
  const prprtyType = _.get(adDetails, "prprtyType", []);
  const lotType = _.get(adDetails, "lotType", null);
  const adType = _.get(adDetails, "adType", null);
  const garageSpace = _.get(adDetails, "grgSpce", null);
  const stories = _.get(adDetails, "stories", null);
  const minLtSz = adDetails?.minLtSz;
  const maxLtSz = adDetails?.maxLtSz;
  const yearBuiltOption = _.get(adDetails, "yearBuiltOption", null);
  const yearBuiltMin = _.get(adDetails, "yrBltMin", null);
  const yearBuildMax = _.get(adDetails, "yrBltMax", null);
  const accsPvtBthRm= _.get(adDetails, "accsPvtBthRm", null) ? "Access to private bathroom" : null;
  const alwPets= _.get(adDetails, "alwPets", null)  ? "Allows pets" : null;
  const pvtEntrnce= _.get(adDetails, "pvtEntrnce", null)  ? "Access to private entrance" : null;
  const adaAccess = _.get(adDetails, "adaAccess", null) ? "Ada accessible" : null;
  const privateRoomMustHaves = getPrivateRoomMustHaves(alwPets,adaAccess,pvtEntrnce,accsPvtBthRm);  
  let ltSize = "";
  ltSize = setLotSizeValue( _.get(adDetails, "minLtSz", null), _.get(adDetails, "maxLtSz", null));
  let sqFt = wantAdSqftValue(_.get(adDetails, "sqFtMin", ""), _.get(adDetails, "sqFtMax", "") )
  let noBathRoom = _.get(adDetails, "noBathRm", "");
  let noBedRoom = _.get(adDetails, "noBedRm", "");
  if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
  if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";  
  const lotTypeText = lotType ? (lotType === "1" ? 'Rural lot type | ' : 'In city limits lot type | ') : '';
  if(adType==='STR'){
      if(prprtyType==6){
          return minLtSz !== undefined && maxLtSz !== undefined ? lotTypeText+ltSize+" lot size" :  ""
      }
      else if(prprtyType==11){
        return privateRoomMustHaves
      }
      else{
          return noBedRoom && noBathRoom ? noBedRoom +" beds | "+noBathRoom+" baths" : ""
      } 
  }
  else {
      if(prprtyType==6){
          return minLtSz !== undefined && maxLtSz !== undefined ? lotTypeText+ltSize+" lot size" : ""
      }
      else if(prprtyType==11){
        return privateRoomMustHaves
      }
      else{
          return noBedRoom && noBathRoom ? noBedRoom +" beds | "+noBathRoom +" baths | "+sqFt+" sqft" : ""
      }
  }
  
} 

export const getReceiveIntroDescriptionText = (adDetails) => {
  const prprtyType = _.get(adDetails, "Sub_PropertyTypeId", []);
  const lotType = _.get(adDetails, "LotType_Rural_City", null);
  const adType = _.get(adDetails, "adType", null);
  let ltSize = "";
  ltSize = (_.get(adDetails, "LotSz", null));
  let sqFt = removeDecimalZeros(_.get(adDetails, "Sqft", ""))
  let noBathRoom = removeDecimalZeros(_.get(adDetails, "Bathrooms", ""));
  let noBedRoom = removeDecimalZeros(_.get(adDetails, "Bedrooms", ""));
  let noBeds = removeDecimalZeros(_.get(adDetails, "noBeds", ""));
  const lotTypeText = lotType ? (lotType === "1" ? 'Rural' : 'In city limits') : '';
  if (adType === 'STR') {
    if (prprtyType == 6) {
      return (
        <>
          {lotTypeText &&
            <>
              {lotTypeText} <span className="details-text-mob">lot type</span>
              <span className="vertical-divider-mob">|</span>
            </>
          }
          {ltSize &&
            <>
              {ltSize} <span className="details-text-mob">lot size</span>
            </>

          }
        </>
      )
    }
    else if (prprtyType == 11) {
      return ""
    }
    else {
      return (
        <>
          {noBedRoom ?
            <>
              {noBedRoom} <span className="details-text-mob">Beds</span>
              <span className="vertical-divider-mob">|</span>
            </> : <></>
          }
          {noBathRoom ?
            <>
              {noBathRoom} <span className="details-text-mob">Baths</span>
              <span className="vertical-divider-mob">|</span>
            </> : <></>
          }
          {noBeds ?
            <>
              {noBeds}
              <span className="details-text-mob"> beds</span>
            </> : <></>
          }
        </>

      )


    }
  }
  else {
    if (prprtyType == 6) {
      return (
        <>
          {lotTypeText ?
            <>
              {lotTypeText} <span className="details-text-mob">lot type</span>
              <span className="vertical-divider-mob">|</span>
            </> : <></>
          }
          {ltSize ?
            <>
              {ltSize} <span className="details-text-mob">lot size</span>
            </> : <></>

          }
        </>
      )
    }
    else if (prprtyType == 11) {
      return ""
    }
    else {
      return (
        <>
          {noBedRoom ?
            <>
              {noBedRoom} <span className="details-text-mob">Beds</span>
              <span className="vertical-divider-mob">|</span>
            </> : <></>
          }
          {noBathRoom ?
            <>
              {noBathRoom} <span className="details-text-mob">Baths</span>
              <span className="vertical-divider-mob">|</span>
            </> : <></>
          }
          {sqFt ?
            <>
              <span>{sqFt}</span>
              <span className="details-text-mob"> sqft</span>
            </> :<></>
          }
        </>

      )
    }
  }

}


export const getInvestmentCriteriaText = (propertyId) => {
  let text;
  switch (propertyId) {
    case 1:
      text = "Fixer-upper";
      break;
    case 2:
      text = "Short sale or foreclosure";
      break;
    case 3:
      text = "Government assistance programs/Section 8";
      break;
    case 4:
      text = "Existing long-term rental";
      break;
    case 5:
      text = "Existing vacation rental";
      break;
    case 6:
      text = " Vacant property";
      break;
    default:
      text = "Fixer-upper";
  }
  return text;
};


export const getCombinedTextForInvestmentCriteria = (investmentId) => {
  if (investmentId && investmentId?.length > 0) {
    let combinedText = getInvestmentCriteriaText(investmentId[0]);    
    if(investmentId?.length > 1){for (let i = 1; i < investmentId?.length; i++) {
      combinedText = combinedText + ", " + getInvestmentCriteriaText(investmentId[i]);
    }
    // combinedText = combinedText+' or '+getInvestmentCriteriaText(investmentId[investmentId?.length-1]);
  }
    return combinedText;
  }
};

export const getFinanceTypeDisplay = (formValues)=>{
  const pFinanceText = _.get(formValues, "finTypeTxt", null) || _.get(formValues, "pFinanceText", null);
  const isPreApprLoan = _.get(formValues, "isPreApprLoan", []);
  let text = '';
  if(pFinanceText){
    text = text+", " + pFinanceText;

  }
  if(isPreApprLoan == 'Yes' || isPreApprLoan == 1){
    text = text+", " + "pre approved";
  }
  return text;
}