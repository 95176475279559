/**
 * @file   src\actions\agentClients.js
 * @brief  This file is responsible for redux actions related to clients
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIntroClientList, getClientNoteListForExport } from "../services/agentClientsService";

 export const getIntroClients = createAsyncThunk(
    "agentClients/getIntroClients",
    (requestPayload) => {
      return getIntroClientList(requestPayload);
    }
  );

export const fetchClientNotesForExports = createAsyncThunk(
  "agentClients/fetchClientNotesForExports",
  (requestPayload) => {
    return getClientNoteListForExport(requestPayload);
  }
);