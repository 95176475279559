import React from "react";

import GitchaWhiteLogo from "../../images/gitcha-logo-white.svg";
//import BottomStepper from "../components/BottomStepper";
import BottomStepper from "../Common/BottomStepper";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { getLocalStorage, removeLocalStorage } from "../../helpers/common";
import { STRENGTHEN_WANT_AD_KEY, WANT_AD_KEY } from "../../constants/common";

const WantadLeftSection = (props) => {
  const navigate = useNavigate();
  const logoClick = () => {
    const adDetails = getLocalStorage(WANT_AD_KEY);
    const adType = _.get(adDetails, "adType", "");
    if (adType) {
      if (confirm("Are you sure you want to move before save ad details")) {
        removeLocalStorage(WANT_AD_KEY);
        removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
        navigate(pageURLs.home);
      }
    } else {
      removeLocalStorage(WANT_AD_KEY);
      removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
      navigate(pageURLs.home);
    }
  };
  const { title, description, completedPercentage } = props;
  return (
    <div className="col-lg-5 h-100 position-lg-absolute left-pane-bg left-half">
      <div className="left-col-wrap h-100">
        <div className="left-col-content h-100">
          <div className="mb-0">
            <a href={void 0} role="button" onClick={() => logoClick()}>
              <img src={GitchaWhiteLogo} />
            </a>
          </div>
          <div className="left-middle-sec pt-5 pb-5">
            <h2 className="ow">{title}</h2>
            <p className="ow">{description}</p>
          </div>
          <div>
            <BottomStepper completedPercentage={completedPercentage} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantadLeftSection;
