import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const DeletePropertyModal = (props) => {
  const { show, handleConfirm,  onHide } = props;
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Are you sure you want to permanently delete your property?</h3>
        <p>Deleting this property from your inventory will remove any pending and accepted introductions sent by you.</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-delete w-100" onClick={handleConfirm}>Delete Property</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default DeletePropertyModal;
