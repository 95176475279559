/**
 * @file   src\components\AgentWantAd\WantAdPurchaseFinance.js
 * @brief  This file is responsible for handling financing type selection in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { getFinanceType } from "../../services/wantAdService";
import { handleRadioButonChange } from "../../actions/common";
import ConfirmApprovalModal from "../MemberWantAd/ConfirmApprovalModal";

const WantAdPurchaseFinance = (props) => {
  const {
    handleFormValues,
    financeType,
    isPreApprLoan,
    agreePreApprovedLoan,
    errors,
    isEdit = false,
    isFromBuyersListingService
  } = props;
  const [financeList, setFinanceList] = useState([]);
  const [isConventionalMortage, setConventionalMortage] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [approveError, setApproveError] = useState(false);

  let isUnCheckItem = false;

  useEffect(() => {
   if(agreePreApprovedLoan){
    handleFormValues({  agreePreApprovedLoan: agreePreApprovedLoan});
   }
  }, []);

  useEffect(() => {
    if (financeType && (financeType === "2" || financeType == 2)) {
      setConventionalMortage(true);
    }

    getFinanceType()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const financeList = _.get(response, "result", null);
          setFinanceList(financeList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);
  

  const closeConfirmPopup = () => {
    setShowConfirmPopup(false);
    handleFormValues({ isPreApprLoan: "" });
  };

  const handleAdjustBudgetClick = () => {
    handleFormValues({ isAdjustBdget: true })
    if (isEdit == 'true') {
      setShowConfirmPopup(false);
    }
    // handleContinueClick()
  };

  const handleItemClick = (e, FinanceText) => {
    setConventionalMortage(false);
    const value = handleRadioButonChange(e);
    if (value === financeType) {
      handleFormValues({ pFinance: "", pFinanceText: "",finTypeId:"", finTypeTxt: "", isPreApprLoan: "", agreePreApprovedLoan: '',  focusOut: true });
      setSelectedValue("")
      setConventionalMortage(false);
      isUnCheckItem = true;
    } else {
      if (value === "2") {
        setConventionalMortage(true);
      } else {
        handleFormValues({ isPreApprLoan: "", agreePreApprovedLoan: '' ,  focusOut: true});
        setConventionalMortage(false);
      }
      handleFormValues({ pFinance: value, pFinanceText: FinanceText, finTypeId:value, finTypeTxt: FinanceText,   focusOut: true });
      setSelectedValue(value)
    }
  };
  const handlePreApproveClick = (e) => {
    const selectedValue = handleRadioButonChange(e);
    // if (selectedValue === "Yes") setShowConfirmPopup(true);
    handleFormValues({ isPreApprLoan: selectedValue, agreePreApprovedLoan: '',  focusOut: true  });
  };

  const confirmPreApprovalStatus = () => {
    setShowConfirmPopup(false);
  };

  
  useEffect(() => {
    if (errors && errors.length > 0) {
      errors.map((item) => {
        if (item === 'agreePreApprovedLoan') {
          setApproveError(`Please check the confirmation box`);
        } 
      })
    }

  }, [errors]);

  const loadFinanceTypes = () => {
    const financeLists =
      financeList &&
      financeList.map((tl) => {
        const FinanceTypeId = _.get(tl, "FinanceTypeId");
        const FinanceTypeText = _.get(tl, "FinanceTypeText", "");
        return (
          <label className="radio-wrap mb-3" key={FinanceTypeId}>
            <input
              type="radio"
              name="pf-radio"
              value={FinanceTypeId}
              onClick={(e) => handleItemClick(e, FinanceTypeText)}
              onChange={(e) => handleItemClick(e, FinanceTypeText)}
              checked={
                isUnCheckItem
                  ? false
                  : selectedValue ? (selectedValue == FinanceTypeId) : (financeType && financeType == FinanceTypeId)

              }
            />
            <span>
              <div className="timing-wrap-info">
                <h2>{FinanceTypeText}</h2>
              </div>
            </span>
          </label>
        );
      });

    return financeLists;
  };

  const handleCheckBoxItemClick = (e) => {
    setApproveError("")
    handleFormValues({ agreePreApprovedLoan: e.target.checked });
  };

  return (
    <>
      <div className="wantad-timing-wrap pb-0">
        {loadFinanceTypes()}
        {isConventionalMortage && (
          <div className="inner-select-type-wrap">
            <div>
              <h6 className="form-label pt-8">Are you pre-approved for a loan?</h6>
            </div>
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio-preApprove"
                value="Yes"
                checked={isPreApprLoan && (isPreApprLoan === "Yes" || isPreApprLoan == true)}
                onClick={(e) => handlePreApproveClick(e)}
                onChange={(e) => handlePreApproveClick(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>Yes</h2>
                </div>
              </span>
            </label>

            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio-preApprove"
                value="No"
                checked={(isPreApprLoan === "No" || isPreApprLoan == false) }
                onClick={(e) => handlePreApproveClick(e)}
                onChange={(e) => handlePreApproveClick(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>No</h2>
                </div>
              </span>
            </label>

            {isPreApprLoan && (isPreApprLoan === "Yes" || isPreApprLoan == true) &&
            <div className={approveError ? "field-wrap mt-4 mb-0 red-marked-innerfield-wrap" : "field-wrap mt-4 mb-0"}>
              <label
                className= {approveError ? "form-check-label checkbox-label red-marked-innerfldlabel align-items-center" : "form-check-label checkbox-label align-items-center"}
                htmlFor="agreepreapproved"
              >
                <input
                  checked={agreePreApprovedLoan === "Yes" || agreePreApprovedLoan == true}
                  className="form-check-input align-check-center"
                  type="checkbox"
                  id="agreepreapproved"
                  onClick={(e)=>{handleCheckBoxItemClick(e)}}

                />
               {isFromBuyersListingService ?"I agree that I am pre-approved up to the maximum amount set in my budget." : "I agree that my clients are pre-approved up to the maximum amount set in my budget."}
              </label>

              {/* <span className='error-msg'>{approveError}</span> */}
            </div>
}

          </div>


        )}
      </div>
      <ConfirmApprovalModal
        show={showConfirmPopup}
        onHide={closeConfirmPopup}
        confirmPreApprovalStatus={confirmPreApprovalStatus}
        handleAdjustBudgetClick={() => handleAdjustBudgetClick()}
      />
    </>
  );
};
export default WantAdPurchaseFinance;
