/**
 * @file   src\pages\InventoryAllSentIntroductions.js
 * @brief  This file is responsible for handling inventry wise sent introductions list.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
// import CancelIntroductionModal from "../components/Introduction/CancelIntroductionModal";
// import ViewIntroductionModal from "../components/MemberWantAd/ViewIntroductionModal";
// import AcceptIntroductionModal from "../components/MemberWantAd/AcceptIntroductionModal";
import { getUserId, loggedInUser } from "../helpers/common";
import { useLocation, useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import { getMyAcceptedIntroductionByInvId, getMyPendingIntroductionByInvId, cancelIntroduction, getIntroductionWantAdDetailsByIntroId } from "../services/introductionService";
import { usePrevious } from "../hooks/usePrevious";
import ViewWantAdModal from "../components/Introduction/ViewWantAdModal";
import { toast } from "react-toastify";
import AgentProfileViewModal from "../components/Introduction/AgentProfileViewModal";
import { deleteIntroduction, deleteMessage, loginOrSignUp, updateIntroduction } from "../helpers/message";
import { db } from "../firebase";
import { collection, onSnapshot, orderBy, query, addDoc, limit, doc, where, or, getDoc } from "firebase/firestore";
import PendingSendIntroductionList from "../components/Introduction/PendingSendIntroductionList";
import AcceptedSendIntroductionList from "../components/Introduction/AcceptedSendIntroductionList";

const InventoryAllSentIntroductions = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showCancelIntroductionModal, setCancelIntroductionModal] =
    useState(false);
  const CancelIntroductionModalClose = () =>
    setCancelIntroductionModal(false);
  const CancelIntroductionModalShow = () => setCancelIntroductionModal(true);

  const [showViewWantAdModal, setViewWantAdModal] = useState(false);
  const ViewWantAdModalClose = () => setViewWantAdModal(false);
  const ViewWantAdModalShow = () => setViewWantAdModal(true);

  const [showProfileModal, setProfileModal] = useState(false);
  const ProfileModalClose = () => setProfileModal(false);
  const ProfilerModalShow = () => setProfileModal(true);

  const [selectedUserId, setSelectedUserId] = useState(0);
  const [wantAdViewInventoryId, setWantAdViewInventoryId] = useState(0);



  const [showAcceptIntroductionModal, setAcceptIntroductionModal] =
    useState(false);
  const AcceptIntroductionModalClose = () => setAcceptIntroductionModal(false);
  const AcceptIntroductionModalShow = () => setAcceptIntroductionModal(true);
  const [pendingList, setPendingList] = useState([]);
  const [acceptedList, setAcceptedList] = useState([]);
  const [pendingListPage, setPendingListPage] = useState(1);
  const [acceptedListPage, setAcceptedListPage] = useState(1);
  const [pendingListCount, setPendingCount] = useState(0);
  const [acceptedListCount, setAcceptedCount] = useState(0);
  const pendingScrollSection = 'pending'
  const acceptedScrollSection = 'accepted'
  const [scrollSection, setScrollSection] = useState(pendingScrollSection);
  const INTRO_LIST_PAGE_LIMIT = 4;

  const [reload, setReload] = useState(0);
  const userDetails = loggedInUser();


  const inventoryId = location.state && location.state['inventoryId'];
  const title = location.state && location.state['title'];

  const [wantAdDetails, setWantAdDetails] = useState([]);

  const updateIntroPageOnScroll = (section) => {
    setScrollSection(section)
    if (section === pendingScrollSection) {
      setPendingListPage((prevState) => prevState + 1);
    } else if (section === acceptedScrollSection) {
      setAcceptedListPage((prevState) => prevState + 1);
    }
  };

  const prevPending = usePrevious(pendingListPage);
  const prevAccepted = usePrevious(acceptedListPage);

  useEffect(() => {
    if (!inventoryId) {
      navigate(pageURLs.home)
    }
    const userId = getUserId();
    if (!prevPending && !prevAccepted) {
      getPendingIntroductions(pendingListPage, inventoryId, userId);
      getAcceptdIntroductions(acceptedListPage, inventoryId, userId);
    } else if (prevPending !== pendingListPage) {
      getPendingIntroductions(pendingListPage, inventoryId, userId);
    } else if (prevAccepted !== acceptedListPage) {
      getAcceptdIntroductions(acceptedListPage, inventoryId, userId);
    }

  }, [pendingListPage, acceptedListPage]);

  // useEffect(() => {
  //   let email = _.get(userDetails, "userInfo.email", null);
  //   let login = loginOrSignUp(email);

  //   if (login) {
  //     console.log(login)

  //   }

  // }, []);





  const getPendingIntroductions = async (page, inventoryId, userId) => {
    const reqPayload = {
      inventoryId: inventoryId,
      page: page,
      limit: INTRO_LIST_PAGE_LIMIT,
    };
    await getMyPendingIntroductionByInvId(reqPayload).then((response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        const count = _.get(response, "result.Count", '');
        if (response.errorCode === 0) {
          setPendingList([...pendingList, ...list]);
          setPendingCount(count)
        } else {
          console.log(`####error`);
        }
      }
    });
  };

  const getAcceptdIntroductions = async (page, inventoryId, userId) => {
    const reqPayload = {
      inventoryId: inventoryId,
      page: page,
      limit: INTRO_LIST_PAGE_LIMIT,
    };
    await getMyAcceptedIntroductionByInvId(reqPayload).then((response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        const count = _.get(response, "result.Count", '');
        if (response.errorCode === 0) {
          setAcceptedList([...acceptedList, ...list]);
          setAcceptedCount(count)
        } else {
          console.log(`####error`);
        }
      }
    });
  };

  const onClickCancelIntroduction = async (introductionId) => {
    const reqPayload = {
      introductionId: introductionId,
    };
    await cancelIntroduction(reqPayload).then(async (response) => {
      if (response) {
        if (response.errorCode == 0) {

          // let email = _.get(userDetails, "userInfo.email", null);
          // let login = await loginOrSignUp(email);
          // if (login) {
          //  let response = await deleteIntroduction(introductionId);
          let data = {
            isdeleted: true,
          }

          let updateresponse = await updateIntroduction(introductionId, data);
         
          //  let response1 = await deleteMessage(introductionId);
          // if (updateresponse == 1) {
            toast.success(response.message)
            setPendingList((current) =>
              current.filter((lst) => lst.IntroductionId !== introductionId)
            );
          // }
          // }

          // let response = await deleteIntroduction(introductionId);
          // let response1 = await deleteMessage(introductionId);


          // await deleteIntroduction(introductionId).then(
          //   async (response) => {
          //       if (response == 1) {
          //           SetMessage('');
          //           // props.updateOrder( selectedData?.IntroId);
          //       }
          //   }
          // )




        } else {
          console.log(`####error`);
        }
      }
    });

  }

  const ViewWantAd = async (introductionId) => {
    setWantAdViewInventoryId(introductionId);
    const reqPayload = {
      introductionId: introductionId,
    };
    await getIntroductionWantAdDetailsByIntroId(reqPayload).then((response) => {
      if (response) {
        if (response.errorCode == 0) {
          setWantAdDetails(response.result);
          setViewWantAdModal(true);

        } else {
          console.log(`####error`);
        }
      }
    });

  }

  const ViewProfile = (agentId) => {
    setViewWantAdModal(false);
    setSelectedUserId(agentId);
    setProfileModal(true);
  }

  const closeAgentModal = () => {
    ViewWantAd(wantAdViewInventoryId)
    // setViewWantAdModal(true);
    setProfileModal(false);
  }

  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">
            <div className="edit-want-ad-wrap introduction-wrap mw-697">
              <a role="button" className="bottom-back-btn" href={void 0} onClick={() => navigate(pageURLs.memberInventoryDetails)}>
                <span class="left-arrow">
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.10033 0.399663C8.39322 0.692556 8.39322 1.16743 8.10033 1.46032L3.31066 6.24999H18.5C18.9142 6.24999 19.25 6.58578 19.25 6.99999C19.25 7.41421 18.9142 7.74999 18.5 7.74999H3.31066L8.10033 12.5397C8.39322 12.8326 8.39322 13.3074 8.10033 13.6003C7.80744 13.8932 7.33256 13.8932 7.03967 13.6003L0.96967 7.53032C0.829018 7.38967 0.75 7.19891 0.75 6.99999C0.75 6.80108 0.829018 6.61031 0.96967 6.46966L7.03967 0.399663C7.33256 0.106769 7.80744 0.106769 8.10033 0.399663Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </span>
              </a>
              <h3>
                All introductions – <span>{title}</span>
              </h3>
              <div className="payments-wrap intro-content-block">

                <PendingSendIntroductionList
                  list={pendingList}
                  // count={pendingListCount}
                  count={pendingList.length}
                  acceptedCount={acceptedList.length}
                  updateIntroPageOnScroll={updateIntroPageOnScroll}
                  CancelIntroduction={(introductionId) => { onClickCancelIntroduction(introductionId) }}
                  ViewWantAd={(id) => { ViewWantAd(id) }} />
                <AcceptedSendIntroductionList
                  list={acceptedList}
                  // count={acceptedListCount}
                  count={acceptedList.length}
                  updateIntroPageOnScroll={updateIntroPageOnScroll}
                  AcceptIntroductionModalShow={AcceptIntroductionModalShow}
                  ViewWantAd={(id) => { ViewWantAd(id) }} />

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CancelIntroductionModal
        show={showCancelIntroductionModal}
        onHide={CancelIntroductionModalClose}
      /> */}

      <ViewWantAdModal
        show={showViewWantAdModal}
        onHide={ViewWantAdModalClose}
        wantAdDetails={wantAdDetails}
        ViewProfileModal={(agentId) => { ViewProfile(agentId) }}
      />

      <AgentProfileViewModal
        show={showProfileModal}
        onHide={ProfileModalClose}
        userId={selectedUserId}
        onClose={() => { closeAgentModal() }} />


      {/* <AcceptIntroductionModal
        show={showAcceptIntroductionModal}
        onHide={AcceptIntroductionModalClose}
      /> */}
    </>
  );
};

export default InventoryAllSentIntroductions;
