import { useState } from "react";
import AddPropertyIcon from "../../../images/add-property-plus-icon.svg";
import AddNewClientModal from "../../MemberWantAd/AddNewClientModal";
import { saveAgentClient } from "../../../services/agentClientsService";
import { toast } from "react-toastify";

const AddClient = (props) => {
    const {userId} = props
    const [showAddClient, setShowAddClient] = useState(false);
    const handleClose = () => setShowAddClient(false);
    const handleShow = () => setShowAddClient(true);

    const addClientData = async (data) => {
        var param = {
            ...data,
        }
        await saveAgentClient(param)
            .then((response) => {
                if (response.errorCode === 0) {
                  const userDetails = getLocalStorage(USER_INFO_KEY);
                  const updatedUserDetails = [...userDetails, {ClientCount: userDetails?.ClientCount + 1}];
                  console.log(updatedUserDetails)
                  setLocalStorage(USER_INFO_KEY, updatedUserDetails );
                  if(response.result.agentExist){
                    toast.success(response.message);
                  } else{
                    toast.success("Added client successfully!");
                    handleClose();
                  }
                    // toast.success("Added client successfully!")
                  
                } else {
                    return;
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });

    }

  return (
    <>
      <a
        href={void(0)}
        className="add-your-property-wrap link-wrap mb-0"
        onClick={() => handleShow()}
      >
        <div className="add-property-btn">
          <img src={AddPropertyIcon} alt={AddPropertyIcon} />
        </div>
        <div className="make-intro-details">
          <h3 className="mb-0">Add your client</h3>
        </div>
        <span role="button" class="right-arrow-link" href={void 0}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
              fill="#000022"
            />
          </svg>
        </span>
      </a>
      <AddNewClientModal show={showAddClient}
                onHide={handleClose}
                addClient={addClientData} />
    </>
  );
};

export default AddClient;
