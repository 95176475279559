/**
 * @file   src\pages\MemberEditWantAd.js
 * @brief  This file is responsible for handling member edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CommonHeader from "../components/Onboarding/CommonHeader";

import {
  LONG_RENT,
  SHORT_RENT,
  BUY,
  IS_PRIVATE_ROOM,
  IS_LAND,
  WANT_AD_KEY,
  WANT_AD_KEY_EDIT,
  IMG_JPEG_FORMAT,
  DEFAULT_MAP_ZOOM,
  DATE_FORMAT_MM_DD_YYYY,
  INVESTOR_MEMBER_PLAN,
} from "../constants/common";
import { convertToNumber, textValidations } from "../helpers/validations";

import WantAdLandMustHaves from "../components/MemberWantAd/EditWantAd/WantAdLandMustHaves";
import WantAdBudget from "../components/MemberWantAd/EditWantAd/WantAdBudget";
import WantAdType from "../components/MemberWantAd/EditWantAd/WantAdType";
import WantAdDescription from "../components/MemberWantAd/EditWantAd/WantAdDescription";
import WantAdTiming from "../components/MemberWantAd/EditWantAd/WantAdTiming";
import WantAdLocation from "../components/MemberWantAd/EditWantAd/WantAdLocation";
import WantAdOption from "../components/MemberWantAd/EditWantAd/WantAdOption";
import WantAdCalendar from "../components/MemberWantAd/EditWantAd/WantAdCalendar";
import WantAdRentPropertyTypes from "../components/MemberWantAd/EditWantAd/WantAdRentPropertyTypes";
import WantAdRentMustHavePrivate from "../components/MemberWantAd/EditWantAd/WantAdRentMustHavePrivate";
import WantAdMustHaves from "../components/MemberWantAd/EditWantAd/WantAdMustHaves";
import WantAdShortRentMstHves from "../components/MemberWantAd/EditWantAd/WantAdShortRentMstHves";
import WantAdRentMustHaves from "../components/MemberWantAd/EditWantAd/WantAdRentMustHaves";
import WantAdPurchaseFinance from "../components/MemberWantAd/EditWantAd/WantAdPurchaseFinance";
import WantAdRentCreditScore from "../components/MemberWantAd/EditWantAd/WantAdRentCreditScore";
import WantAdPropertyPurchase from "../components/MemberWantAd/EditWantAd/WantAdPropertyPurchase";
import NiceToHave from "../components/Common/NiceToHave/NiceToHave";

import { uploadMapImageToS3 } from "../helpers/S3/uploadToS3";
import {
  createPreSignedUrl,
  getLocalStorage,
  isValidDate,
  removeLocalStorage,
  setLocalStorage,
} from "../helpers/common";
import { EXACT_MOVE_DATE_TYPE } from "../constants/createWantAd";
import html2canvas from "html2canvas";
import { dataURLToBlob, getEditAction } from "../helpers/createWantAd";
import GitchaNotAvailableModal from "../components/Common/GitchaNotAvailableModal";
import { checkGitchaAvailable } from "../services/commonServices";
import RequestStayAvailability from "../components/Common/RequestStayAvailability";
import userProfile from "../appState/userProfile";
import WantAdInvestmentCriteria from "../components/MemberWantAd/EditWantAd/WantAdInvestmentCriteria";

const MemberEditWantAd = (props) => {
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const browserLocation = getLocalStorage("userLocation");

  const YEAR_BUILT_MIN = 1900;
  const YEAR_BUILT_MAX = new Date().getFullYear();
  const isFromWantAdConfirmation = location.state["isFromWantAdConfirmation"] ? location.state["isFromWantAdConfirmation"] : false;
  const [wantAdData, setWantAdData] = useState(
    location.state ? location.state["data"] : getLocalStorage(WANT_AD_KEY)
  ); // to set the want-ad data
  const [selectedniceToHavesList, setSelectedNiceToHavesList] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const [gitchaModalShow, setGitchaModal] = useState(false);

  // error tracking
  const [typeSelectionError, setTypeSelectionError] = useState(false);
  const [locationCityError, setLocationCityError] = useState(false);
  const [locationRadiusError, setLocationRadiusError] = useState(false);
  const [timeLineError, setTimeLineError] = useState(false);
  const [timelineCalenderError, setTimeCalenderError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [subPropertyTypeError, setSubPropertyTypeError] = useState(false);
  const [requirementBedroomsError, setRequirementBedroomsError] =
    useState(false);
  const [requirementBathroomsError, setRequirementBathroomsError] =
    useState(false);
  const [requirementSquareFootageError, setRequirementSquareFootageError] =
    useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [titleTextBoxError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [exactMoveDateError, setExactMoveDateError] = useState(false);
  const [requirementTextColor, setRequirementTextColor] = useState(false);
  const [dateTextBoxError, setDateTextBoxError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [locationError, setLocationError] = useState("");
  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("");
  const purchaseType = convertToNumber(_.get(wantAdData, "purchaseType", null)) || convertToNumber(_.get(wantAdData, "prchTypeId", null));
  const [showRequestStayPopuop, setShowRequestStayPopup] = useState(false);
  const handleStayRequestPopupOpen = () => {
    setShowRequestStayPopup(true);
  };

  const handleStayRequestPopupClose = () => {
    setShowRequestStayPopup(false);
  };

  const adTypeRef = useRef(null);
  const locationRef = useRef(null);
  const timeLineRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const mustHaveRef = useRef(null);
  const BudgetRef = useRef(null);
  const DescriptionRef = useRef(null);

  const isFromNotification =
    location.state && location.state["isFromNotification"];
  const isFromUnPause = location.state && location.state["isFromUnPause"];

  useEffect(() => {
    if (userDetails) {
      if (userDetails?.length === 1) {
        if (userDetails[0]?.PlanName) {
          setUserPlan(userDetails[0]?.PlanName);
        }
      } else {
        if (userDetails[0]?.PlanName === "Trial") {
          setUserPlan(userDetails[1]?.PlanName);
        }
        else{
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }
  }, [userDetails]);

  useEffect(() => {
    wantAdData?.nice2Have
      ? setSelectedNiceToHavesList(wantAdData?.nice2Have)
      : setSelectedNiceToHavesList([]);
    setLocalStorage(WANT_AD_KEY_EDIT, wantAdData);

    if (wantAdData) {
      if (wantAdData?.adType) {
        setTypeSelectionError(false);
      }
      if (
        (wantAdData?.location && wantAdData?.county) ||
        wantAdData?.zipCode?.length > 0
      ) {
        setLocationCityError(false);
        setZipCodeError(false);
      }
      if (wantAdData?.mapRadius) {
        setLocationRadiusError(false);
      }

      if (wantAdData?.adType == BUY && wantAdData?.timeLine) {
        setTimeLineError(false);
        setTimeCalenderError(false);
      }

      if (wantAdData?.adType == LONG_RENT && wantAdData?.moveInTimeLine) {
        setTimeLineError(false);
        setTimeCalenderError(false);
      }
      if (
        wantAdData?.adType == LONG_RENT &&
        wantAdData?.moveInTimeLine == EXACT_MOVE_DATE_TYPE &&
        wantAdData?.startDate
      ) {
        const isDateValid = isValidDate({
          dateString: wantAdData?.startDate,
          format: DATE_FORMAT_MM_DD_YYYY,
        });
        if (!isDateValid) {
        } else {
          setExactMoveDateError(false);
        }
      }
      if (wantAdData?.adType == SHORT_RENT && wantAdData?.dateType) {
        setTimeCalenderError(false);
        setTimeLineError(false);
      }
      if (
        wantAdData?.adType == SHORT_RENT &&
        wantAdData?.dateType &&
        ((wantAdData?.startDate && wantAdData?.endDate) ||
          (wantAdData?.duration && wantAdData?.month))
      ) {
        setDateTextBoxError(false);
      }
      if (wantAdData?.prprtyType != IS_LAND && wantAdData?.prprtyType) {
        setPropertyTypeError(false);
        if (wantAdData?.prptySubTypes) {
          setSubPropertyTypeError(false);
        }
      }

      if (wantAdData?.noBedRm) {
        setRequirementBedroomsError(false);
      }

      if (wantAdData?.noBathRm) {
        setRequirementBathroomsError(false);
      }

      if (wantAdData?.adTitle && textValidations(wantAdData?.adTitle)) {
        setTitleError(false);
      }
      if (wantAdData?.noBedRm && wantAdData?.noBathRm) {
        setRequirementTextColor(false);
      }

      if (textValidations(wantAdData?.adDesc)) {
        setDescriptionError(false);
      }
    }
  }, [wantAdData]);

  useEffect(() => {
    handleFormValues({ isEdit: true });
  }, []);

  useEffect(() => {
    const isArchiveValue = getEditAction(isFromUnPause, isFromNotification); // pass the url parameters to the function
    if (isArchiveValue == 0) {
      // unpause/ unarchive action occured
      handleFormValues({ isArchived: 0 });
      if (wantAdData?.adType == SHORT_RENT || wantAdData?.adType == LONG_RENT) {
        handleFormValues({
          startDate: null,
          endDate: null,
          duration: null,
          month: null,
          moveInTimeLine: null,
          moveInDate: null,
        });
      }
    }
    if (isFromUnPause || isFromNotification) {
      if (isFromUnPause == true || isFromNotification == true) {
        handleFormValues({ ExpiryStatus: true }); // for make the notification table entry marked as renewed is the edit is from renew/unpause action
      } else {
        handleFormValues({ ExpiryStatus: false });
      }
    }
  }, [isFromUnPause, isFromNotification]);

  const clearPreviousStates = (params) => {
    if (params) {
      const { type } = params;
      if (type === "clearAll") {
        setWantAdData({
          actvStep: 2,
          cmpldPerctg: 0,
          wantAdId: wantAdData.wantAdId,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          s3ImageFolder: wantAdData?.s3ImageFolder,
          userId: wantAdData?.userId,
          isPublished: wantAdData?.isPublished,
          isEdit: true,
        });
        setLocationCityError(false);
        setLocationRadiusError(false);
        setPropertyTypeError(false);
        setRequirementBathroomsError(false);
        setRequirementBedroomsError(false);
        setRequirementSquareFootageError(false);
        setBudgetError(false);
        setTimeLineError(false);
        setTimeCalenderError(false);
        setDescriptionError(false);
      } else if (type === "clearFlexDates") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["duration", "month"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type === "dateRange") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["startDate", "endDate"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type === "moveInTypeChangeLTR") {
        const array = { ...wantAdData }; // make a separate copy of the state
        ["startDate"].forEach((e) => delete array[e]);
        setWantAdData(array);
      } else if (type == "clearErrorStateOnPropertySelection") {
        setWantAdData({
          s3ImageFolder: wantAdData?.s3ImageFolder,
          userId: wantAdData?.userId,
          lat: wantAdData?.lat,
          lng: wantAdData?.lng,
          city: wantAdData?.city,
          isZipcodeBased : wantAdData?.isZipcodeBased,
          zipCode : wantAdData?.zipCode,
          zipId : wantAdData?.zipId,
          zipIdValues : wantAdData?.zipIdValues,
          state: wantAdData?.state,
          county: wantAdData?.county,
          location: wantAdData?.location,
          mapRadius: wantAdData?.mapRadius,
          mapZoom: wantAdData?.mapZoom,
          timeLine: wantAdData?.timeLine,
          tlText: wantAdData?.tlText,
          dateType: wantAdData?.dateType,
          duration: wantAdData?.duration,
          month: wantAdData?.month,
          moveInTimeLine: wantAdData?.moveInTimeLine,
          moveInTxt: wantAdData?.moveInTxt,
          startDate: wantAdData?.startDate,
          neighborHood: wantAdData?.neighborHood,
          highSchool: wantAdData?.highSchool,
          prprtyType: wantAdData?.prprtyType,
          prptyTypeTxt: wantAdData?.prptyTypeTxt,
          adType: wantAdData?.adType,
          isPublished: wantAdData?.isPublished,
          isEdit: true,
          validationErrors: [],
        });

        setRequirementBathroomsError(false);
        setRequirementBedroomsError(false);
        setRequirementSquareFootageError(false);
        setBudgetError(false);
        setTimeLineError(false);
        setTimeCalenderError(false);
        setDescriptionError(false);
      }
    }
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setWantAdData((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  async function handleSave() {
    let error = [];
    setDisableButton(true);

    if (!wantAdData?.adType) {
      setTypeSelectionError(true);
      error.push("adTypeRef", "adType");
    }
    if ((!wantAdData?.location || !wantAdData?.county) && (!wantAdData?.zipCode || wantAdData?.zipCode.length == 0 )) {
      setLocationCityError(true)
      setZipCodeError(true)
      error.push("locationRef", "city")
    } else{
      if(wantAdData?.location || wantAdData?.location!= "" || !wantAdData?.zipCode.length>0){
        if (!wantAdData?.mapRadius) {
          setLocationRadiusError(true);
          error.push("locationRef", "mapRadius");
        }
      }
    }

    if (wantAdData?.adType == BUY && !wantAdData?.timeLine) {
      setTimeLineError(true);
      error.push("timeLineRef", "timeLine");
    }

    if (wantAdData?.adType == LONG_RENT && !wantAdData?.moveInTimeLine) {
      setTimeLineError(true);
      error.push("timeLineRef", "timeLine");
    }
    if (
      wantAdData?.adType == LONG_RENT &&
      wantAdData?.moveInTimeLine == EXACT_MOVE_DATE_TYPE &&
      !wantAdData?.startDate
    ) {
      setExactMoveDateError(true);
      error.push("timeLineRef", "startDate");
    }
    if (
      wantAdData?.adType == LONG_RENT &&
      wantAdData?.moveInTimeLine == EXACT_MOVE_DATE_TYPE &&
      wantAdData?.startDate
    ) {
      const isDateValid = isValidDate({
        dateString: wantAdData?.startDate,
        format: DATE_FORMAT_MM_DD_YYYY,
      });
      if (!isDateValid) {
        setExactMoveDateError(true);
        error.push("timeLineRef", "startDate");
      }
    }
    if (wantAdData?.adType == SHORT_RENT && !wantAdData?.dateType) {
      setTimeCalenderError(true);
      error.push("timeLineRef", "dateType");
    }
    if (
      wantAdData?.adType == SHORT_RENT &&
      wantAdData?.dateType == 1 &&
      (!wantAdData?.startDate || !wantAdData?.endDate)
    ) {
      setDateTextBoxError(true);
      error.push("timeLineRef", "dateType");
    }

    if (
      wantAdData?.adType == SHORT_RENT &&
      wantAdData?.dateType == 2 &&
      (!wantAdData?.duration || !wantAdData?.month)
    ) {
      setDateTextBoxError(true);
      error.push("timeLineRef", "dateType");
    }
    if (!wantAdData?.prprtyType) {
      setPropertyTypeError(true);
      error.push("propertyTypeRef", "prprtyType");
    } else {
      if (
        (wantAdData?.adType != BUY &&
          wantAdData?.prprtyType != IS_LAND &&
          !wantAdData?.prptySubTypes) ||
        wantAdData?.prptySubTypes?.length == 0
      ) {
        setSubPropertyTypeError(true);
        error.push("propertyTypeRef", "prptySubTypes");
      }
    }

    if (
      wantAdData?.prprtyType != IS_PRIVATE_ROOM &&
      wantAdData?.prprtyType != IS_LAND
    ) {
      if (!wantAdData?.noBedRm) {
        setRequirementBedroomsError(true);
        error.push("mustHaveRef", "noBedRm");
      }

      if (!wantAdData?.noBathRm) {
        setRequirementBathroomsError(true);
        error.push("mustHaveRef", "noBathRm");
      }
      if (!wantAdData?.noBedRm || !wantAdData?.noBathRm) {
        setRequirementTextColor(true);
      }
    }

    // }

    if (wantAdData?.sqFtMax && wantAdData?.sqFtMin) {
      if (
        convertToNumber(wantAdData?.sqFtMin) >=
        convertToNumber(wantAdData?.sqFtMax)
      ) {
        error.push("mustHaveRef", "sqftMaxLesser");
        setRequirementTextColor(true);
      }
    }

    // else if(!wantAdData?.sqFtMin && wantAdData?.sqFtMax){
    //   error.push("mustHaveRef", "sqftMinMissing");
    //   setRequirementTextColor(true)
    // } else if(wantAdData?.sqFtMin && !wantAdData?.sqFtMax){
    //   error.push("mustHaveRef", "sqftMaxMissing");
    //   setRequirementTextColor(true)
    // }

    if (wantAdData?.minLtSz && wantAdData?.maxLtSz) {
      if (
        convertToNumber(wantAdData?.minLtSz) >=
        convertToNumber(wantAdData?.maxLtSz)
      ) {
        error.push("mustHaveRef", "lotSizeMaxLesser");
        setRequirementTextColor(true);
      }
    }
    // else if(!wantAdData?.minLtSz && wantAdData?.maxLtSz){
    //   error.push("mustHaveRef", "lotSizeMinMissing");
    //   setRequirementTextColor(true)
    // } else if(wantAdData?.minLtSz && !wantAdData?.maxLtSz){
    //   error.push("mustHaveRef", "lotSizeMaxMissing");
    //   setRequirementTextColor(true)
    // }

    if (wantAdData?.yearBuiltOption == 1) {
      if (wantAdData.yrBltMin && wantAdData.yrBltMax) {
        if (
          convertToNumber(wantAdData.yrBltMax) < YEAR_BUILT_MIN ||
          convertToNumber(wantAdData.yrBltMax) > YEAR_BUILT_MAX ||
          convertToNumber(wantAdData.yrBltMin) < YEAR_BUILT_MIN ||
          convertToNumber(wantAdData.yrBltMin) > YEAR_BUILT_MAX
        ) {
          (convertToNumber(wantAdData.yrBltMax) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMax) > YEAR_BUILT_MAX) &&
            error.push("yearBuiltMaxRange");
          (convertToNumber(wantAdData.yrBltMin) < YEAR_BUILT_MIN ||
            convertToNumber(wantAdData.yrBltMin) > YEAR_BUILT_MAX) &&
            error.push("yearBuiltMinRange");
        } else if (
          convertToNumber(wantAdData.yrBltMax) <
          convertToNumber(wantAdData.yrBltMin)
        ) {
          error.push("mustHaveRef", "yearBuiltError");
        } else if (
          convertToNumber(wantAdData.yrBltMin) <
          convertToNumber(wantAdData.yrBltMin)
        ) {
          error.push("mustHaveRef", "yearBuiltError");
        }
      } else if (!wantAdData.yrBltMin || !wantAdData.yrBltMax) {
        !wantAdData.yrBltMin && error.push("mustHaveRef", "yearBuiltMinDate");
        !wantAdData.yrBltMax && error.push("mustHaveRef", "yearBuiltMaxDate");
      }
    }

    if (wantAdData?.minBdgt && wantAdData?.maxBdgt) {
      if (
        convertToNumber(wantAdData?.minBdgt) >=
        convertToNumber(wantAdData?.maxBdgt)
      ) {
        error.push("BudgetRef", "maxBdgt");
        setBudgetError(true);
      }
    }
    // else if(!wantAdData?.minBdgt && wantAdData?.maxBdgt){
    //   error.push("BudgetRef", "bdgtMinMissing");
    //   setBudgetError(true)
    // } else if(wantAdData?.minBdgt && !wantAdData?.maxBdgt){
    //   error.push("BudgetRef", "bdgtMaxMissing");
    //   setBudgetError(true)
    // }

    if (!wantAdData?.adTitle) {
      setTitleError(true);
      error.push("DescriptionRef", "adTitleTextBox");
    }
    if (wantAdData?.adTitle && !textValidations(wantAdData?.adTitle)) {
      error.push("DescriptionRef", "adTitle");
      setTitleError(true);
    }

    if (wantAdData?.adDesc && !textValidations(wantAdData?.adDesc)) {
      error.push("DescriptionRef", "adDesc");
      setDescriptionError(true);
    }

    if (
      (wantAdData?.isPreApprLoan == 1 || wantAdData?.isPreApprLoan == "Yes") &&
      !wantAdData?.agreePreApprovedLoan
    ) {
      error.push("DescriptionRef", "agreePreApprovedLoan");
    }

    if (wantAdData?.agentCompenation == 0) {
      handleFormValues({ agentCompenation: "" });
    }

    if (error.length == 0) {
      handleFormValues({ validationErrors: [], updatedAt: Date.now() });
      handleCaptureMapImage();
    } else {
      if (error[0] == "adTypeRef") {
        window.scrollTo(0, adTypeRef.current.offsetTop);
      } else if (error[0] == "locationRef") {
        window.scrollTo(0, locationRef.current.offsetTop);
      } else if (error[0] == "timeLineRef") {
        window.scrollTo(0, timeLineRef.current.offsetTop);
      } else if (error[0] == "propertyTypeRef") {
        window.scrollTo(0, propertyTypeRef.current.offsetTop);
      } else if (error[0] == "mustHaveRef") {
        window.scrollTo(0, mustHaveRef.current.offsetTop);
      } else if (error[0] == "BudgetRef") {
        window.scrollTo(0, BudgetRef.current.offsetTop);
      } else if (error[0] == "DescriptionRef") {
        window.scrollTo(0, DescriptionRef.current.offsetTop);
      }
      setDisableButton(false);

      handleFormValues({ validationErrors: error, updatedAt: Date.now() });
    }
  }

  const handleCaptureMapImage = () => {
    const fullScreenDiv = document.querySelector(".gm-fullscreen-control");
    const zoomIcons = document.querySelectorAll(".gmnoprint");
    const googleIcon = document.querySelector('img[alt="Google"]');
    const buttons = document.querySelectorAll(".gm-style-cc");
    const gmStyle = document.querySelector(".gm-style-moc");

    if (gmStyle) {
      gmStyle.style.display = "none";
    }

    if (fullScreenDiv) {
      fullScreenDiv.style.display = "none";
    }

    if (googleIcon) {
      googleIcon.style.display = "none";
    }

    zoomIcons.forEach((element) => {
      element.style.display = "none";
    });

    buttons.forEach((element) => {
      element.style.display = "none";
    });

    if (targetRef.current) {
      html2canvas(targetRef.current, { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          let dataURL = canvas.toDataURL(IMG_JPEG_FORMAT);
          const binaryData = dataURLToBlob(dataURL);
          handleMapImageUpload(binaryData);
        }
      );
    }
  };

  const getFinalStep = () =>{
    if(userPlan===INVESTOR_MEMBER_PLAN && wantAdData?.adType===BUY && wantAdData?.prprtyType != IS_LAND){
      if(purchaseType===3){
        return 12
      }
      else{
        return 11
      }
    }
    else {
      return 10;
    }
  }

  const handleMapImageUpload = async (image) => {
    const finalStep = getFinalStep();
    const mapResponse = image;
    const preSignedUrlRes = mapResponse
      ? await createPreSignedUrl(true, _.get(wantAdData, "s3ImageFolder", ""))
      : alert("Something went wrong while fetching map image.");
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl ? s3FullUrl.split("?")[0] : "";
        if (
          !wantAdData?.isGitchaAvailable &&
          wantAdData?.zipCode?.length == 0
        ) {
          checkGitchAvailability({
            state: _.get(wantAdData, "state", ""),
            county: _.get(wantAdData, "county", ""),
          }).then((response) => {
            const isGitchaAvailable = _.get(response, "result", "");
            handleFormValues({ isGitchaAvailable: isGitchaAvailable });
            if (!isGitchaAvailable) {
              setLocalStorage(WANT_AD_KEY, {
                ...wantAdData,
                mapImg: imageUrl,
                cmpldPerctg: 100,
                actvStep: 3,
                validationErrors: [],
                isEdit:
                  _.get(wantAdData, "isPublished", null) == 1 ? true : false,
              }); // copy edit session to member by
              removeLocalStorage(WANT_AD_KEY_EDIT); // remove the edit want- ad session
              setGitchaModal(true);
              setDisableButton(false);
            } else {
              setLocalStorage(WANT_AD_KEY, {
                ...wantAdData,
                mapImg: imageUrl,
                cmpldPerctg: 100,
                actvStep: finalStep,
                validationErrors: [],
                isEdit:
                  _.get(wantAdData, "isPublished", null) == 1 ? true : false,
              }); // copy edit session to member by
              removeLocalStorage(WANT_AD_KEY_EDIT); // remove the edit want- ad session

              setTimeout(() => {
                navigate("/create-want-ad", { state: { checkDraft: true } });
              }, 10);
            }
          });
        } else {
          setLocalStorage(WANT_AD_KEY, {
            ...wantAdData,
            mapImg: imageUrl,
            cmpldPerctg: 100,
            actvStep: finalStep,
            isEdit: _.get(wantAdData, "isPublished", null) == 1 ? true : false,
          }); // copy edit session to member by
          removeLocalStorage(WANT_AD_KEY_EDIT); // remove the edit want- ad session

          setTimeout(() => {
            navigate("/create-want-ad", { state: { checkDraft: true } });
          }, 10);
        }
      } else {
        setDisableButton(false);
      }
    } else {
      setDisableButton(false);
    }
  };

  const checkGitchAvailability = async ({ zip, state, city, county }) => {
    const reqPayLoad = { county: county, state: state };
    return await checkGitchaAvailable(reqPayLoad).then((data) => {
      return data;
    });
  };

  return (
    <>
      <RequestStayAvailability
        show={showRequestStayPopuop}
        locationFromSTorage={browserLocation}
        onHide={handleStayRequestPopupClose}
      />
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">
            <div className="edit-want-ad-wrap mw-626">
              <h2 className="pt-3">
                {_.get(wantAdData, "isPublished", null) == 1 ||
                _.get(wantAdData, "isEdit", false)
                  ? "Edit"
                  : "Add"}{" "}
                Want-Ad
              </h2>
              <h6 className="mb-40">
                Update and confirm the details of your want-ad.
              </h6>

              {/* ------------------------------------------TYPE------------------------------------------ */}
              <div
                className="wantad-type-wrap select-type-wrap red-marked-wrap"
                ref={adTypeRef}
              >
                <h6 className={typeSelectionError && "red-marked-heading"}>
                  Type <span>*</span>
                </h6>
                <WantAdOption
                  handleFormValues={handleFormValues}
                  adType={wantAdData.adType}
                  clearPreviousStates={clearPreviousStates}
                  typeSelectionError={typeSelectionError}
                  handleStayRequestPopupOpen={handleStayRequestPopupOpen}
                />
              </div>
              {/* ------------------------------------------/TYPE------------------------------------------ */}

              {/* ------------------------------------------LOCATION------------------------------------------ */}

              <div
                className={
                  locationRadiusError &&
                  "wantad-location-wrap select-type-wrap red-marked-wrap"
                }
                ref={locationRef}
              >
                <h6
                  className={
                    (locationCityError || locationRadiusError) &&
                    "red-marked-heading"
                  }
                >
                  Location
                </h6>
                <WantAdLocation
                  handleFormValues={handleFormValues}
                  location={_.get(wantAdData, "location", null)}
                  s3Imagefolder={_.get(wantAdData, "s3ImageFolder", false)}
                  lat={_.get(wantAdData, "lat", null)}
                  lng={_.get(wantAdData, "lng", null)}
                  mapZoom={_.get(wantAdData, "mapZoom", null)}
                  mapRadius={_.get(wantAdData, "mapRadius", null)}
                  neighborHood={_.get(wantAdData, "neighborHood", null)}
                  highSchool={_.get(wantAdData, "highSchool", null)}
                  zip={_.get(wantAdData, "zip", null)}
                  adType={_.get(wantAdData, "adType", null)}
                  city={_.get(wantAdData, "city", null)}
                  state={_.get(wantAdData, "state", null)}
                  county={_.get(wantAdData, "county", null)}
                  targetRef={targetRef}
                  isGitchaAvailable={_.get(
                    wantAdData,
                    "isGitchaAvailable",
                    null
                  )}
                  locationCityError={locationCityError}
                  locationRadiusError={locationRadiusError}
                  session={WANT_AD_KEY_EDIT}
                  zipCode={_.get(wantAdData, "zipCode", null)}
                  zipIdValues={_.get(wantAdData, "zipIdValues", null)}
                  zipCodeError={zipCodeError}
                  setLocationCityError={setLocationCityError}
                  setLocationError={setLocationError}
                  locationError={locationError}
                  isFromWantAdConfirmation={isFromWantAdConfirmation}
                />
              </div>
              {/* ------------------------------------------/LOCATION------------------------------------------ */}

              {/* ------------------------------------------TIMELINE------------------------------------------ */}
              <div
                className="wantad-type-wrap select-type-wrap red-marked-wrap"
                ref={timeLineRef}
              >
                <h6
                  className={
                    (timeLineError || timelineCalenderError) &&
                    "red-marked-heading"
                  }
                >
                  Timeline <span>*</span>
                </h6>

                {wantAdData.adType == LONG_RENT ||
                wantAdData.adType == SHORT_RENT ? (
                  <WantAdCalendar
                    handleFormValues={handleFormValues}
                    sDate={_.get(wantAdData, "startDate", null)}
                    eDate={_.get(wantAdData, "endDate", null)}
                    adType={_.get(wantAdData, "adType", null)}
                    dateType={_.get(wantAdData, "dateType", null)}
                    duration={_.get(wantAdData, "duration", null)}
                    month={_.get(wantAdData, "month", null)}
                    moveInTimeLine={convertToNumber(
                      _.get(wantAdData, "moveInTimeLine", null)
                    )}
                    moveInDate={_.get(wantAdData, "startDate", null)}
                    clearPreviousStates={clearPreviousStates}
                    timeLineError={timeLineError}
                    timelineCalenderError={timelineCalenderError}
                    exactMoveDateError={exactMoveDateError}
                    dateTextBoxError={dateTextBoxError}
                  />
                ) : (
                  <WantAdTiming
                    handleFormValues={handleFormValues}
                    timeLine={_.get(wantAdData, "timeLine", null)}
                    adType={_.get(wantAdData, "adType", null)}
                    timeLineError={timeLineError}
                  />
                )}
              </div>

              {/* ------------------------------------------/TIMELINE------------------------------------------ */}

              {/* ------------------------------------------Property type------------------------------------------ */}
              <div
                className={
                  propertyTypeError || subPropertyTypeError
                    ? "wantad-type-wrap select-type-wrap red-marked-wrap"
                    : "wantad-type-wrap select-type-wrap"
                }
                ref={propertyTypeRef}
              >
                <h6 className={propertyTypeError && "red-marked-heading"}>
                  Property type <span>*</span>
                </h6>
                {wantAdData.adType == LONG_RENT ||
                wantAdData.adType == SHORT_RENT ? (
                  <WantAdRentPropertyTypes
                    handleFormValues={handleFormValues}
                    propertyType={_.get(wantAdData, "prprtyType", null)}
                    adType={_.get(wantAdData, "adType", null)}
                    clearPreviousStates={clearPreviousStates}
                    prptySubTypes={_.get(wantAdData, "prptySubTypes", [])}
                    propertyTypeError={propertyTypeError}
                    subPropertyTypeError={subPropertyTypeError}
                  />
                ) : (
                  <WantAdType
                    handleFormValues={handleFormValues}
                    propertyType={_.get(wantAdData, "prprtyType", "")}
                    adType={_.get(wantAdData, "adType", "")}
                    clearPreviousStates={clearPreviousStates}
                    propertyTypeError={propertyTypeError}
                    prptySubTypes={_.get(wantAdData, "prptySubTypes", [])}
                  />
                )}
              </div>

              {/* ------------------------------------------/Property type------------------------------------------ */}

              {/* ------------------------------------------Requirements------------------------------------------ */}

              <div
                className={
                  requirementTextColor
                    ? "requirements-wrap red-marked-wrap mb-3"
                    : "requirements-wrap mb-3"
                }
                ref={mustHaveRef}
              >
                <h6>Requirements</h6>
                {wantAdData.adType == SHORT_RENT &&
                  wantAdData.prprtyType != IS_LAND &&
                  wantAdData.prprtyType != IS_PRIVATE_ROOM && (
                    <WantAdShortRentMstHves
                      handleFormValues={handleFormValues}
                      noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                      noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                      noOfBeds={_.get(wantAdData, "noOfBeds", null)}
                      alwPets={_.get(wantAdData, "alwPets", "")}
                      adaAccess={_.get(wantAdData, "adaAccess", "")}
                      requirementBedroomsError={requirementBedroomsError}
                      requirementBathroomsError={requirementBathroomsError}
                    />
                  )}
                {wantAdData.adType == LONG_RENT &&
                  wantAdData.prprtyType != IS_LAND &&
                  wantAdData.prprtyType != IS_PRIVATE_ROOM && (
                    <WantAdRentMustHaves
                      handleFormValues={handleFormValues}
                      noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                      noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                      SFMin={_.get(wantAdData, "sqFtMin", null)}
                      SFMax={_.get(wantAdData, "sqFtMax", null)}
                      // SFMin={_.get(wantAdData, "sqFtMin", null)}
                      // SFMax={_.get(wantAdData, "sqFtMax", null)}
                      garageSpace={_.get(wantAdData, "grgSpce", null)}
                      entreSpceMstHve={_.get(wantAdData, "entreSpceMstHve", [])}
                      alwLrgDogs={_.get(wantAdData, "alwLrgDogs", "")}
                      alwSmlDogs={_.get(wantAdData, "alwSmlDogs", "")}
                      alwCats={_.get(wantAdData, "alwCats", "")}
                      adaAccess={_.get(wantAdData, "adaAccess", "")}
                      stories={_.get(wantAdData, "stories", null)}
                      mustHaveErrors={_.get(
                        wantAdData,
                        "validationErrors",
                        null
                      )}
                      requirementBedroomsError={requirementBedroomsError}
                      requirementBathroomsError={requirementBathroomsError}
                    />
                  )}

                {((wantAdData.prprtyType != IS_LAND &&
                  wantAdData.adType == BUY) ||
                  (Number(wantAdData.prprtyType) != IS_PRIVATE_ROOM &&
                    Number(wantAdData.prprtyType) != IS_LAND &&
                    wantAdData.adType != LONG_RENT &&
                    wantAdData.adType != SHORT_RENT)) && (
                  <WantAdMustHaves
                    handleFormValues={handleFormValues}
                    noOfBedrooms={_.get(wantAdData, "noBedRm", null)}
                    noOfBathrooms={_.get(wantAdData, "noBathRm", null)}
                    SFMin={_.get(wantAdData, "sqFtMin", null)}
                    SFMax={_.get(wantAdData, "sqFtMax", null)}
                    garageSpace={_.get(wantAdData, "grgSpce", null)}
                    yearBuiltMin={_.get(wantAdData, "yrBltMin", null)}
                    yearBuildMax={_.get(wantAdData, "yrBltMax", null)}
                    mustHaveErrors={_.get(wantAdData, "validationErrors", null)}
                    stories={_.get(wantAdData, "stories", null)}
                    yearBuiltOption={_.get(wantAdData, "yearBuiltOption", null)}
                    minLtSz={_.get(wantAdData, "minLtSz", null)}
                    maxLtSz={_.get(wantAdData, "maxLtSz", null)}
                    adaAccess={_.get(wantAdData, "adaAccess", null)}
                    requirementBedroomsError={requirementBedroomsError}
                    requirementBathroomsError={requirementBathroomsError}
                    propertyType={wantAdData?.prprtyType}
                  />
                )}
                {Number(wantAdData.prprtyType) === IS_PRIVATE_ROOM && (
                  <WantAdRentMustHavePrivate
                    handleFormValues={handleFormValues}
                    accsPvtBthRm={_.get(wantAdData, "accsPvtBthRm", "")}
                    alwPets={_.get(wantAdData, "alwPets", "")}
                    adaAccess={_.get(wantAdData, "adaAccess", "")}
                    pvtEntrnce={_.get(wantAdData, "pvtEntrnce", "")}
                  />
                )}
                {Number(wantAdData.prprtyType) == IS_LAND && (
                  <WantAdLandMustHaves
                    handleFormValues={handleFormValues}
                    lotType={_.get(wantAdData, "lotType", null)}
                    roadType={_.get(wantAdData, "roadType", null)}
                    isPrivtDrilInstld={_.get(wantAdData, "isPrvtDrInst", null)}
                    onSiteElec={_.get(wantAdData, "onSiteElec", null)}
                    waterSupply={_.get(wantAdData, "waterSupply", null)}
                    isDrldWelInstld={_.get(wantAdData, "isDrldWelInst", null)}
                    wasteWtrSys={_.get(wantAdData, "wasteWtrSys", null)}
                    isSepTankInstld={_.get(wantAdData, "isSepTankInst", null)}
                    isSepTankApprvd={_.get(wantAdData, "isSepTankAppr", null)}
                    // mnLtSzId={_.get(wantAdData, "mnLtSzId", null)}
                    // mnLtSzTxt={_.get(wantAdData, "mnLtSzTxt", null)}
                    // mxLtSzId={_.get(wantAdData, "mxLtSzId", null)}
                    // mxLtSzTxt={_.get(wantAdData, "mxLtSzTxt", null)}
                    minLtSz={_.get(wantAdData, "minLtSz", null)}
                    maxLtSz={_.get(wantAdData, "maxLtSz", null)}
                    mustHaveErrors={_.get(wantAdData, "validationErrors", null)}
                    propertyType={wantAdData?.prprtyType}
                  />
                )}
              </div>

              {/* ------------------------------------------/Requirements------------------------------------------ */}

              {/* ------------------------------------------Nice-to-haves------------------------------------------ */}
              <NiceToHave
                selectedNiceToHave={wantAdData?.nice2Have}
                selectedniceToHavesList={selectedniceToHavesList}
                propertyType={wantAdData?.prprtyType}
                adType={wantAdData?.adType}
                handleFormValues={handleFormValues}
                title="Nice-to-haves"
              />

              {/* ------------------------------------------/Nice-to-haves------------------------------------------ */}
              {/* ------------------------------------------Finance/Purchase type ------------------------------------------ */}
              <div
                className={
                  budgetError
                    ? "finance-wrap red-marked-wrap pb-24"
                    : "finance-wrap pb-24"
                }
                ref={BudgetRef}
              >
                <h6>Finance</h6>
                <WantAdBudget
                  handleFormValues={handleFormValues}
                  budgetMin={_.get(wantAdData, "minBdgt", null)}
                  budgetMax={_.get(wantAdData, "maxBdgt", null)}
                  agentCompenation={_.get(wantAdData, "agentCompenation", null)}
                  isAdjustBdget={_.get(wantAdData, "isAdjustBdget", null)}
                  isPreApprLoan={_.get(wantAdData, "isPreApprLoan", null)}
                  adType={wantAdData?.adType}
                  errors={_.get(wantAdData, "validationErrors", null)}
                />

                {userPlan === INVESTOR_MEMBER_PLAN &&
                wantAdData?.adType === BUY &&
                Number(wantAdData.prprtyType) != IS_LAND ? (
                  <div className="wantad-type-wrap select-type-wrap pb-2">
                  {
                  // ((wantAdData?.purchaseType || wantAdData?.prchTypeId) && wantAdData.wantAdId) &&
                  (wantAdData?.adType == BUY && wantAdData?.prprtyType != IS_LAND && wantAdData.wantAdId) &&
                    <>
                      <label className="form-label">Financing</label>
                      <WantAdPurchaseFinance
                        handleFormValues={handleFormValues}
                        timeLine={_.get(wantAdData, "timeLine", null)}
                        financeType={_.get(wantAdData, "finTypeId", null) || _.get(wantAdData, "pFinance", null)}
                        isPreApprLoan={_.get(wantAdData, "isPreApprLoan", null)}
                        agreePreApprovedLoan={_.get(wantAdData, "agreePreApprovedLoan", null) ?? _.get(wantAdData, "isPreApprLoan", null)}
                        errors={_.get(wantAdData, 'validationErrors', null)}
                        isEdit='true'

                      />
                    </>
                  }
                  {(wantAdData?.adType == BUY && wantAdData?.prprtyType != IS_LAND && wantAdData.wantAdId) &&
                    <>
                      <label className="form-label">Purchase Type</label>
                      <WantAdPropertyPurchase
                        handleFormValues={handleFormValues}
                        propertyPurchase={ _.get(wantAdData, "purchaseType", null) || _.get(wantAdData, "prchTypeId", null)}
                        is1031Exchange={_.get(wantAdData, "is1031Exchange", null)}
                      />
                    </>
                  }
                  {(wantAdData.wantAdId && purchaseType === 3) &&
                    <>
                      <label className="form-label">Investment Criteria</label>
                      <WantAdInvestmentCriteria
                        handleFormValues={handleFormValues}
                        investmentId={_.get(wantAdData, "investmentId", [])}
                        capRateId={_.get(wantAdData, "capRateId", "")}
                      />
                    </>
                  }
                                  
                </div>
                ) : (
                  <div className="wantad-type-wrap select-type-wrap pb-2">
                    {wantAdData?.adType == BUY &&
                      wantAdData?.prprtyType != IS_LAND &&
                      wantAdData.wantAdId && (
                        <>
                          <label className="form-label">Purchase Type</label>
                          <WantAdPropertyPurchase
                            handleFormValues={handleFormValues}
                            propertyPurchase={
                              _.get(wantAdData, "purchaseType", null) || _.get(wantAdData, "prchTypeId", null)
                              
                            }
                            is1031Exchange={_.get(
                              wantAdData,
                              "is1031Exchange",
                              null
                            )}
                          />
                        </>
                      )}
                    {wantAdData?.adType == LONG_RENT && wantAdData.wantAdId && (
                      <>
                        <label className="form-label">Credit score range</label>
                        <WantAdRentCreditScore
                          handleFormValues={handleFormValues}
                          creditScore={_.get(wantAdData, "creditScore", null)}
                        />
                      </>
                    )}
                    {
                      // ((wantAdData?.purchaseType || wantAdData?.prchTypeId) && wantAdData.wantAdId) &&
                      wantAdData?.adType == BUY &&
                        wantAdData?.prprtyType != IS_LAND &&
                        wantAdData.wantAdId && (
                          <>
                            <label className="form-label">Financing</label>
                            <WantAdPurchaseFinance
                              handleFormValues={handleFormValues}
                              timeLine={_.get(wantAdData, "timeLine", null)}
                              financeType={
                                _.get(wantAdData, "finTypeId", null) ||
                                _.get(wantAdData, "pFinance", null)
                              }
                              isPreApprLoan={_.get(
                                wantAdData,
                                "isPreApprLoan",
                                null
                              )}
                              agreePreApprovedLoan={
                                _.get(
                                  wantAdData,
                                  "agreePreApprovedLoan",
                                  null
                                ) ?? _.get(wantAdData, "isPreApprLoan", null)
                              }
                              errors={_.get(
                                wantAdData,
                                "validationErrors",
                                null
                              )}
                              isEdit="true"
                            />
                          </>
                        )
                    }
                  </div>
                )}
              </div>
              {/* ------------------------------------------/Finance/Purchase type------------------------------------------ */}
              {/* ------------------------------------------Description------------------------------------------ */}
              <div
                className={
                  titleTextBoxError || descriptionError
                    ? "description-wrap red-marked-wrap"
                    : "description-wrap"
                }
                ref={DescriptionRef}
              >
                <h6
                  className={
                    titleTextBoxError ||
                    (descriptionError && "red-marked-heading")
                  }
                >
                  Description
                </h6>
                <WantAdDescription
                  handleFormValues={handleFormValues}
                  adTitle={_.get(wantAdData, "adTitle", "")}
                  adDescription={_.get(wantAdData, "adDesc", "")}
                  errors={_.get(wantAdData, "validationErrors", null)}
                  titleTextBoxError={titleTextBoxError}
                  descriptionError={descriptionError}
                />
              </div>
              {/* ------------------------------------------/Description------------------------------------------ */}
            </div>

            <div className="edit-want-ad-btnwrap mt-4">
              <Button
                variant="primary"
                className="btn"
                disabled={disableButton}
                onClick={handleSave}
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </div>
      <GitchaNotAvailableModal
        show={gitchaModalShow}
        session={WANT_AD_KEY}
        onHide={() => setGitchaModal(false)}
        title={"Gitcha isn't currently available in this location yet."}
        desc={
          "We are diligently working to open up your area! Please type in the cities where you need us, and we'll take it up with the bosses."
        }
      />
    </>
  );
};

export default MemberEditWantAd;
