import React from "react";
import CreateNewBtn from "../../MemberWantAd/CreateNewBtn";
import SegmentDivider from "../../MemberWantAd/SegmentDivider";
import MyDrafts from "../../Common/MyDrafts";

const InventoryStartPage = (props) => {
  const {
    handleContinueClick,
    myDraft,
    handleContinueButtonState,
    setDraftSelectedItem,
    draftSelectedItem,
  } = props;
  const loadDrafts = () => {
    const myDraftList =
      myDraft &&
      myDraft.map((md) => {
        const locAddress = _.get(md, "Location", "");
        const inventoryId = _.get(md, "Inv_Id");
        const CompletedPercentage = _.get(md, "CompletedPercentage", "");
        const Current_Step = _.get(md, "Current_Step", "");
        const InventoryPhoto = _.get(md, "InventoryPhoto", null);
        var lastUpdatedDate = new Date(_.get(md, "LastUpdatedTS", ""));
        var formattedDate = [
          ("0" + (lastUpdatedDate.getMonth() + 1)).slice(-2),
          ("0" + lastUpdatedDate.getDate()).slice(-2),
          lastUpdatedDate.getFullYear(),
        ].join("/");

        return (
          <MyDrafts
            setDraftSelectedItem={setDraftSelectedItem}
            handleContinueButtonState={handleContinueButtonState}
            draftSelectedItem={draftSelectedItem}
            section="inventory"
            key={inventoryId}
            itemId={inventoryId}
            CompletedPercentage={CompletedPercentage}
            locationAddress={locAddress}
            Current_Step={Current_Step}
            latUpdatedDate={formattedDate}
            InventoryPhoto = {InventoryPhoto}
          />
        );
      });

    return myDraftList;
  };
  return (
    <>
      <div className="center-content-sec mw-444">
        <CreateNewBtn
          handleContinueClick={handleContinueClick}
          labelText="Add new property"
        />
        {myDraft && (
          <>
            <SegmentDivider />
            <div className="my-drafts-sec">
              <h2 className="mt-3">My Drafts</h2>

              {loadDrafts()}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default InventoryStartPage;
