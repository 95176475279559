import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import LakeViewIcon from "../../../images/lake-view-icon.svg";
import ConsumerIconImage from "../../../images/consumer-icon.svg";
import NeighborIconImage from "../../../images/neighborhood-icon.svg";
import PrefschoolIconImage from "../../../images/school-icon.svg";
import ProgressBar from "../ProgressBar";
import {
  getUserId,
  getUserType,
  removeDecimalZeros,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  getInitials,
  setBodyBackgroundWhite,
  clearLocalStorageOnLogout,
  loadPreferredLocationHtml,
  loadCompensationText,
  locationDisplay,
} from "../../../helpers/common";
import {
  USER_TYPE_AGENT,
  LONG_RENT,
  SHORT_RENT,
  IS_LAND,
  IS_MULTI_FAMILY,
  BUY,
  STORY_ANY,
  NEW_CONSTRUCTION,
  LOT_LAND,
  WANT_AD_KEY,
  BUY_LAND,
  WANT_AD_KEY_TEMP,
  INVESTOR_MEMBER_PLAN,
  BUDGET_NOMIN_NOMAX,
} from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
import {
  getCombinedText,
  getPropertyIcon,
  getDescriptionText,
} from "../../../helpers/getPropertyIcons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  loadInvestmentCriteria,
  setLotSizeValue,
  wantAdBudgetValue,
} from "../../../helpers/createWantAd";
import { getNiceToHaves } from "../../../services/commonServices";
import { loadTimeLineHtml } from "../../../helpers/common";
import { saveMemberWantAd, saveMemberWantAdstrengthenData } from "../../../services/wantAdService";
import PrResIconImage from "../../../images/primary-residence-icon.svg";
import FinanceIconImage from "../../../images/financing-icon.svg";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { buildWantAdMustHavesHTML } from "../../../helpers/WantAdDetails";
import { userLogout } from "../../../reducers/login";
import MinCapIcon from "../../../images/minimum-icn.svg";

const ReadyToPost = (props) => {
  const {
    completedPercentage,
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    clearPreviousStates,
    handleSaveAndExit,
    isPremiumUser,
    userPlan
  } = props;

  const [disableSave, setDisableSave] = useState(true);
  const navigate = useNavigate();
  const userType = getUserType();
  const isAgent = userType === USER_TYPE_AGENT ? true : false;
  const mapImage = _.get(formValues, "mapImg", null);
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const propertySubTypes = _.get(formValues, "prptySubTypes", []);
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const clientName = _.get(formValues, "clientName", "");
  let budget = wantAdBudgetValue(
    _.get(formValues, "minBdgt", ""),
    _.get(formValues, "maxBdgt", "")
  );
  if(budget != BUDGET_NOMIN_NOMAX){
    if (adType === LONG_RENT) {
      budget = budget + " /Month";
    } else if (adType === SHORT_RENT) {
      budget = budget + " /Night";
    }
  }
  let ltSize = "";
  ltSize = setLotSizeValue(
    removeDecimalZeros(_.get(formValues, "minLtSz", null)),
    removeDecimalZeros(_.get(formValues, "maxLtSz", null))
  );

  let garrageSize = _.get(formValues, "grgSpce", null);
  if (garrageSize) {
    if (garrageSize !== "Any") {
      garrageSize = garrageSize + "+ cars";
    }
  }
  let stories = _.get(formValues, "stories", null);
  if (stories) {
    stories = stories === STORY_ANY ? "Any" : "Single Story Only";
  }

  const yearBuiltOption = _.get(formValues, "yearBuiltOption", null);
  const builtYrMin = _.get(formValues, "yrBltMin", "");
  const builtYrMax = _.get(formValues, "yrBltMax", "");
  const adaAccess = _.get(formValues, "adaAccess", null);
  const alwPets = _.get(formValues, "alwPets", null);
  const accsPvtBthRm = _.get(formValues, "accsPvtBthRm", null);
  const alwLrgDogs = _.get(formValues, "alwLrgDogs", null);
  const alwSmlDogs = _.get(formValues, "alwSmlDogs", null);
  const alwCats = _.get(formValues, "alwCats", null);
  const pvtEntrnce = _.get(formValues, "pvtEntrnce", null);
  const adTypeBasedOnProperty = propertyType === IS_LAND ? LOT_LAND : adType;
  const nice2Have = _.get(formValues, "nice2Have", []);
  const isNiceToHaveExist = nice2Have.length > 0;
  const consumerType = adType === BUY ? "Buyer" : "Renter";
  const neighborhood = _.get(formValues, "neighborHood", null);
  const school = _.get(formValues, "highSchool", null);
  const purchaseType = convertToNumber(_.get(formValues, "prchTypeId", '')) || convertToNumber(_.get(formValues, "purchaseType", ''));
  const isInvestor = userPlan===INVESTOR_MEMBER_PLAN ? true : false;
  useEffect(() => {
    setBodyBackgroundWhite()
    getNiceToHaves(
      adTypeBasedOnProperty === LOT_LAND ? BUY_LAND : adTypeBasedOnProperty
    )
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    const isDisable = false;
    setDisableSave(isDisable);
  }, []);

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    const masterArray = _.get(niceToHavesList, section, []);
    if (selectedItems.length) {
      return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
    } else {
      return "";
    }
  };

  const loadTimeLine = () => {
    const isMobileView = true;
    return loadTimeLineHtml(
      adType,
      adTypeBasedOnProperty,
      formValues,
      isMobileView
    );
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      adTypeBasedOnProperty === LOT_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    return finalList;
  };

  const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        const icon = _.get(masterItem[0], "Icon", "");
        return (
          <div className="property-tag-mobile mb-2" key={id + uniqueKey}>
            <div className="tag-icon-mobile">
              <img
                src={icon ? require("../../../images/" + icon) : LakeViewIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  let builtYear = "";
  if (yearBuiltOption) {
    builtYear =
      yearBuiltOption === NEW_CONSTRUCTION
        ? "New Construction"
        : `${builtYrMin} - ${builtYrMax}`;
  }
  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }

    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );
  };

  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY
        ? getTooltipText(propertyType)
        : propertySubTypes &&
        propertySubTypes.length > 0 &&
        getCombinedText(propertySubTypes)}
    </Tooltip>
  );

  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(formValues, "isMobileView");
  };

  const loadStrengthenDetails = () => {
    if (adType === BUY) {
      return (
        <>
          {" "}
          {(_.get(formValues, "prchTypeTxt", "") ||
            _.get(formValues, "purchaseText", "")) && (
              <li>
                <span className="list-icn-wrap-mob">
                  <img src={PrResIconImage} />
                </span>
                {_.get(formValues, "prchTypeTxt", "") ||
                  _.get(formValues, "purchaseText", "")}
                {(_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
              </li>
            )}
          {(_.get(formValues, "finTypeTxt", "") ||
            _.get(formValues, "pFinanceText", "")) && (
              <li>
                <span className="list-icn-wrap-mob">
                  <img src={FinanceIconImage} />
                </span>
                Financing:{" "}
                {_.get(formValues, "finTypeTxt", "") ||
                  _.get(formValues, "pFinanceText", "")}
                {(_.get(formValues, "isPreApprLoan", "") == "Yes" ||
                  _.get(formValues, "isPreApprLoan", "") == 1) &&
                  " - pre approved"}
              </li>
            )}
        </>
      );
    } else if (adType === LONG_RENT) {
      return (
        <>
          {" "}
          {_.get(formValues, "creditScoreText", "") && (
            <li>
              <span className="list-icn-wrap-mob">
                <img src={FinanceIconImage} />
              </span>
              Credit Score : {_.get(formValues, "creditScoreText", "")}
            </li>
          )}
        </>
      );
    }
  };

  const saveStrengthenData = async (wantAdIdValue, isPostAd) => {
    const wantAdId = wantAdIdValue;
    let updatedState = {
      pTypeTxt: _.get(formValues, "prptyTypeTxt", ""),
      adType: _.get(formValues, "adType", "") + '_STRENGTHEN'
    };
    let requestPayLoad = {};
    let isApproveLoan = ""
    if (_.get(formValues, "isPreApprLoan", null) === 'Yes' || _.get(formValues, "isPreApprLoan", null) == true) {
      isApproveLoan = true
    } else if (_.get(formValues, "isPreApprLoan", null) === 'No' || _.get(formValues, "isPreApprLoan", null) == true) {
      isApproveLoan = false
    }
    requestPayLoad.isPreApprLoan = isApproveLoan;
    const is1031Exchange = (_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) ? true : false;
    requestPayLoad.wantAdId = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.completedStep = 1;
    requestPayLoad.adType = _.get(formValues, "adType", "") == BUY ? 'BUY_STRENGTHEN' : 'LTR_STRENGTHEN';
    requestPayLoad.creditScore = _.get(formValues, "creditScore", "");
    requestPayLoad.maxBdgt = _.get(formValues, "maxBdgt", "");
    requestPayLoad.minBdgt = _.get(formValues, "minBdgt", "");
    requestPayLoad.pFinance = _.get(formValues, "pFinance", "") || _.get(formValues, "finTypeId", "");
    requestPayLoad.purchaseType = _.get(formValues, "purchaseType", "") || _.get(formValues, "prchTypeId", "");
    await saveMemberWantAdstrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);

        if (response.errorCode === 0 && responseResult) {
          updatedState.wantAdId = wantAdId;
          if (isPostAd) {
            updatedState.wantAdId = wantAdId;
            updatedState.minBdgt = _.get(formValues, "minBdgt", "");
            updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");

            setLocalStorage(WANT_AD_KEY, updatedState);
            localStorage.removeItem(WANT_AD_KEY_TEMP);
            navigate(pageURLs.viewAd);
          } else {
            localStorage.removeItem(WANT_AD_KEY_TEMP);
            localStorage.removeItem(WANT_AD_KEY);
            navigate(pageURLs.wantAdListing, { state: { showDraft: true } })
          }

        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleStateClear = () => {
    // alert("Your session has expired. Not complete the request. Please log in");
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate('/mobile/home');
  };

  const saveMemberWantAdData = (isPostAd) => {
    let updatedState = {
      pTypeTxt: _.get(formValues, "prptyTypeTxt", ""),
      adType: _.get(formValues, "adType", "") + "_STRENGTHEN",
      isEdit: _.get(formValues, "isEdit", ""),
    };
    const userId = getUserId(); //_.get(user, "userInfo.userId", null);
    const requestPayLoad = getLocalStorage(WANT_AD_KEY);
    if (userId && requestPayLoad) {
      requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
      requestPayLoad.userId = userId;
      if (isAgent) {
        requestPayLoad.isAgentCreated = true;
      }
      if (isAgent && !isPremiumUser) {
        requestPayLoad.isAgentAsSeller = true;
      }
      if (isPostAd) {
        requestPayLoad.IsSavedDraft = false;
        requestPayLoad.IsPublished = true;
        delete requestPayLoad.tlText;
        delete requestPayLoad.prptyTypeTxt;
        delete requestPayLoad.wantAdValErrors;
        delete requestPayLoad.updatedAt;
      } else {
        requestPayLoad.IsSavedDraft = true;
        requestPayLoad.IsPublished = false;
      }
      let arrayValue = [];
      if (requestPayLoad?.zipIdValues) {
        requestPayLoad?.zipIdValues.map((zip) => {
          arrayValue.push(zip?.zipId)
        })
        requestPayLoad.zipId = arrayValue;
      } else if (requestPayLoad?.zipCode) {
        requestPayLoad?.zipCode.map((zip) => {
          if (zip?.zipId && zip?.zipId != null) {
            arrayValue.push(zip?.zipId)
          }
        })
        requestPayLoad.zipId = arrayValue;
      }
      requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;

      saveMemberWantAd(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (
              requestPayLoad.purchaseType ||
              requestPayLoad.creditScore ||
              requestPayLoad.prchTypeId
            ) {
              saveStrengthenData(responseResult.wantAdId, isPostAd);
            } else {
              if (isPostAd) {
                updatedState.wantAdId = _.get(responseResult, "wantAdId", "");
                updatedState.minBdgt = _.get(formValues, "minBdgt", "");
                updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");
                removeLocalStorage(WANT_AD_KEY_TEMP);
                setLocalStorage(WANT_AD_KEY, updatedState);
                navigate(pageURLs.viewAd);
              } else {
                removeLocalStorage(WANT_AD_KEY);
                removeLocalStorage(WANT_AD_KEY_TEMP);
                //navigate(pageURLs.createwantad);
              }
            }
            // navigate(pageURLs.wantAdDetails);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      formValues: formValues,
    });
  };

  return (
    <div className="gitcha-main-wrapper pb-154 bg-white">
      <div className="content-wrapper content-wrapper-h-102-percent">
        <ProgressBar
          completedPercentage={completedPercentage}
          setUIKey={setUIKey}
          isFromReadyToPost={true}
        />
        <div className="inner-layout-content-new bg-white h-100">
          <div className="premium-content-wrapper pe-3 ps-3 pt-2 bg-white min-h-100">
            <div className="ready-to-post-mobheader mb-3">
              <h2>Ready to post?</h2>
              <p>Confirm the details about your want-ad. </p>
            </div>

            {isPremiumUser && (
              <div className="readytopost-client-info mb-3">
                <div className="client-info-name">
                  Client <span>This information is only visible to you. </span>
                  <div className="client-profile-small ">
                    <div
                      className={
                        "client-profile-img-small " +
                        getInitials(clientName)?.backgroundColor
                      }
                    >
                      {getInitials(clientName)?.initials}
                    </div>
                    <div className="client-profile-txt">{clientName}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="ready-to-post-mob-wrap mb-3">
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    I'm looking to: {loadAdTitle()}
                  </div>
                  <h2>{_.get(formValues, "adTitle", "")}</h2>
                  <span className="trailing-txt pb-1">
                    {locationDisplay(formValues)}
                    {/* Within {_.get(formValues, "mapRadius", "")} mi of{" "}
                    {_.get(formValues, "city", "")}
                    {", "}
                    {_.get(formValues, "state", "")} */}
                  </span>
                </div>
                <div className="property-details-mob mb-4">
                  <span className="details-text-mob">
                    {getDescriptionText(formValues)}
                  </span>
                </div>
              </div>

              <div className="property-listing-ad-mob">
                <div className="property-type-wrap-mob top-pos-0 left-pos-0">
                  <OverlayTrigger
                    placement="bottom-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <div className="property-tile w-auto">
                      {propertyType === IS_LAND ||
                        propertyType === IS_MULTI_FAMILY ? (
                        <img
                          src={getPropertyIcon(propertyType)}
                          alt={getPropertyIcon(propertyType)}
                          className="property-icon-img"
                        />
                      ) : (
                        propertySubTypes &&
                        propertySubTypes.length > 0 &&
                        propertySubTypes.map((subtype) => {
                          return (
                            <img
                              src={getPropertyIcon(subtype)}
                              alt={getPropertyIcon(subtype)}
                              className="property-icon-img"
                            />
                          );
                        })
                      )}
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="property-img-wrapad-mob h-200">
                  <img
                    src={
                      mapImage + "?" + Math.random().toString(36) || ConfirmMap
                    }
                  />
                </div>
              </div>

              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h2>Must-haves</h2>
                <div className="row">
                  {loadAdMustHaves()}                  
                </div>
              </div>
              {isNiceToHaveExist ? <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                <h2>Nice-to-haves</h2>
                {finalNiceToHaveList()}
              </div> : <></>}

              {_.get(formValues, "adDesc", "") ? (
                <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                  <h2>Description</h2>
                  <p>{_.get(formValues, "adDesc", "")}</p>
                </div>
              ) : (
                ""
              )}

              <div className="buyer-info-mob brdr-top-common-wrap pb-0 mb-4">
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    <span className="list-icn-wrap-mob">
                      <img src={ConsumerIconImage} />
                    </span>
                    {isAgent ? "Represented by Agent" : consumerType} {isInvestor ? <span className="pro-gold-badge">Investor</span> : ""}
                  </li>         

                  {isAgent && (
                    <>
                      {neighborhood && (
                        <li>
                          <span className="list-icn-wrap-mob">
                            <img src={NeighborIconImage} />
                          </span>
                          Preferred Neighborhood: {neighborhood}
                        </li>
                      )}

                      {school && (
                        <li>
                          <span className="list-icn-wrap-mob">
                            <img src={PrefschoolIconImage} />
                          </span>
                          Preferred School: {school}
                        </li>
                      )}
                    </>
                  )}
                  {loadTimeLine()}
                  {loadStrengthenDetails()}                  
                </ul>
              </div>
              {(adType == BUY || _.get(formValues, "prprtyType", "") == IS_LAND) &&
                   isAgent && (_.get(formValues, "compenationType", "") || _.get(formValues, "otherConcessions", "")) &&<div className="buyer-info-mob brdr-top-common-wrap pb-0">
                <h2>Requested Concessions From Seller</h2>
                <ul>                  
                  {_.get(formValues, "compenationType", "") ? (
                    <li>
                      <span className="list-icn-wrap-mob">
                        <img src={FinanceIconImage} />
                      </span>
                      {loadCompensationText(parseInt(_.get(formValues, "compenationType", "")), _.get(formValues, "agentCompenation", ""))}
                      
                    </li>
                  ) : ""}
                  {_.get(formValues, "otherConcessions", "") ? (
                    <li>
                     
                      {_.get(formValues, "otherConcessions", "")}
                      
                    </li>
                  ) : ""}
                                    
                </ul>
              </div>}
              {isInvestor && purchaseType===3 && 
              <div className="buyer-info mt-0">
                {_.get(formValues, "investmentId", [])?.length>0 || _.get(formValues, "capRateId", null) ? <h2>What This Investor is Open to</h2>: ""}
                <ul>
                  {loadInvestmentCriteria(formValues)}
                  {_.get(formValues, "capRateId", "") &&
                    <li>
                      <span className="list-icn-wrap">
                        <img src={MinCapIcon} />
                      </span>
                      Minimum cap rate of {_.get(formValues, "capRateId", "")}%
                    </li>
                  }
                </ul>
              </div>
             }

              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav">
          <div className="btn-wrap text-center pb-40 pt-0">
            <button
              type="button"
              className="btn btn-primary w-100 mb-20"
              disabled={disableSave}
              onClick={() => {
                const isPostAd = true;
                saveMemberWantAdData(isPostAd);
              }}
            >
              Post
            </button>
            <button
              type="button"
              onClick={() => handleSaveAndExit()}
              class="btn btn-no-bg btn-no-bg-fix w-100"
            >
              Save & Exit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadyToPost;
