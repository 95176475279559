import React, { useState, useEffect } from "react";
import { Offcanvas, FormControl, Row, Col, Button } from "react-bootstrap";
import InputGroupControl from "../../../components/Common/InputGroup";
import { USER_INFO_KEY } from "../../../constants/common";
import { getLocalStorage, setLocalStorage } from "../../../helpers/common";
import AddNewClientModal from "../../../components/MemberWantAd/AddNewClientModal";
import { saveAgentClient } from "../../../services/agentClientsService";

const ViewAddClientsModal = (props) => {
  const {
    show,
    onHide,
    handleConfirm,
    handleSkip,
    data,
    onChangeText,
    clientSearchText,
    onAddClient,
    showAddToClientWantAdModal,
    agent,
    searchText,
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => setShowPopup(false);
  const handleShow = () => setShowPopup(true);
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const [agentIds, setAgentIds] = useState([]);
  /**
   * add client
   * @param {json} data
   */

  const addClientData = async (data) => {
    var updatedData = {
      ...data,
    };

    await saveAgentClient(updatedData)
      .then((response) => {
        if (response.errorCode == 0) {
          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["ClientCount"] = userInfo.userInfo?.ClientCount
            ? userInfo.userInfo?.ClientCount + 1
            : 1;
          setLocalStorage(USER_INFO_KEY, userInfo);

          setShowPopup(false);
          showAddToClientWantAdModal();
          onAddClient();
        } else {
          return;
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  /**
   * To handle checkbox selection
   * @param {*} e
   */
  const handleAgents = (clientId, isSelected) => {
    if (!isSelected) {
      setAgentIds([...agentIds, clientId]);
    } else {
      var filtered = agentIds.filter(function (id, index) {
        return id != clientId;
      });
      setAgentIds(filtered);
    }
  };
  
  /**
   * To list Agents
   * @param props
   * @returns HTML
   */
  function Agents(props) {
    const agent = props.agent;
    const isSelected = agentIds.includes(agent.ClientId)
    const agentFullName = agent.FirstName + " " + agent.LastName;
    return (
      <a
        href={void 0}
        role="button"
        onClick={() => handleAgents(agent.ClientId, isSelected)}
        className={
          isSelected
            ? "createAd-content-wrap mb-3 d-flex align-items-center active"
            : "createAd-content-wrap mb-3 d-flex align-items-center"
        }
      >
        <span className="profile-bg-meroon profileIcnDiv me-2 align-items-center">
          {agentFullName.replace(/-/g, "").match(/\b(\w)/g)}
        </span>
        <span className="labelTxt"> {agentFullName}</span>
      </a>
    );
  }
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Offcanvas
          className="mobile-offcanvas offcanvas-fixed property-offcanvas btm-pad-0"
          show={show}
          onHide={onHide}
          placement="bottom"
          {...props}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Want-Ad Favorited</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body-btm-pad-132">
            <div className="offcanvas-client-search">
              <div className="popupStyle">
                <div className="titleText text-center pl-16 pr-16">
                  Would you like to add this for a specific client?
                </div>
                <div
                  className="myClientAdd d-flex pl-16 pr-16 justify-content-between pt-4 pb-2"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <span>My Clients</span>

                  <a
                    className="back-link-btn "
                    href={void 0}
                    role="button"
                    onClick={() => {
                      onHide();
                      setShowPopup(true);
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10Z"
                        fill="#4A4C4F"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.25 10C6.25 9.65482 6.52982 9.375 6.875 9.375H13.125C13.4702 9.375 13.75 9.65482 13.75 10C13.75 10.3452 13.4702 10.625 13.125 10.625H6.875C6.52982 10.625 6.25 10.3452 6.25 10Z"
                        fill="#4A4C4F"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V13.125C10.625 13.4702 10.3452 13.75 10 13.75C9.65482 13.75 9.375 13.4702 9.375 13.125V6.875C9.375 6.52982 9.65482 6.25 10 6.25Z"
                        fill="#4A4C4F"
                      />
                    </svg>
                  </a>
                </div>
                <div className="search-sec">
                  <div className="search_field_input position-relative mb-0 input-group d-flex h-auto ">
                    {/* <input
                  aria-label="Example"
                  aria-describedby="basic-addon3"
                  placeholder=" Client Name"
                  class="form-control pr-0"
                ></input> */}
                    <InputGroupControl
                      placeholder=" Client Name"
                      inputGroupClass="form-control pr-0"
                      ariaLabel="Example"
                      ariaDescribedBy="basic-addon3"
                      name="search"
                     
                      onChange={(e) => {
                        onChangeText(e);
                      }}
                      iconClass="search-icon"
                      isIconEnable={true}
                      iconId="button-addon3"
                    />
                    {/* <button
                  type="button"
                  id="button-addon3"
                  class="search-icon "
                ></button> */}
                  </div>
                </div>
                <div>
                  <div className="title-selection-wrap pl-16 pr-16 pb-2 title-selection-scroll text-center">
                    {data !== null && data?.length > 0 ? (
                      data?.map((agent, i) => {
                        return <Agents key={i} agent={agent} ival={i} />;
                      })
                    ) : (
                      <p>{props.searchText ? `No results for${props.searchText}`: "No Clients added" }</p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="inner-btm-fixed-nav paddingTwoBtns"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="btn-wrap text-center">
                  <Button
                    variant="primary"
                    className="btn btn-primary w-100"
                    disabled={agentIds.length > 0 ? false : true}
                    onClick={() => {
                      handleConfirm(agentIds);
                    }}
                  >
                    Save favorites
                  </Button>
                  <Button
                    variant=""
                    className="btn btn-no-bg  w-100"
                    onClick={() => {
                      handleSkip();
                    }}
                  >
                    Skip
                  </Button>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {/* Add new client popup */}
        <AddNewClientModal
          show={showPopup}
          showAddToClientWantAdModal={showAddToClientWantAdModal}
          fromAddToClientWantAdModal={true}
          onHide={handleClose}
          addClient={addClientData}
        />
      </div>
    </>
  );
};
export default ViewAddClientsModal;
