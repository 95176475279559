import Form from "react-bootstrap/Form";
import { capitalizeString, handleTextBoxChange } from "../../../actions/common";
import { checkIsNumber, removeDecimalZerosFromTextField, replaceComma, thousandSeperatedValue } from "../../../helpers/common";
import { removeNegativeInput } from "../../../helpers/validations";
import { yrMaxInValid } from "../../../constants/createWantAd";
import { useState , useEffect} from "react";
import TextBoxMob from "../../components/TextBoxMob"
const MustHavesProperties = (props) => {
  const {
    isPrivateRoom,
    updateValuesToState,
    neighborHood,
    highSchool,
    isPropertyExist,
    noBedRm,
    noBathRm,
    sqFt,
    stories,
    lotSize,
    grgeSpots,
    yearBuilt,
    invFormError,
    errorUpdatedAt,
    adaAccess,
    IsNewConstruction,
    handleFormValues
  } = props;

  const adaAccessCheckBox = "adaAccess";
  const newConstructionCheckBox = "newConstruction";
  const [yearBuiltError, setYearBuiltError] = useState("");

  const handleNeighborhoodChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    updateValuesToState("neighborHood", value);
  };

  const handleHighSchoolChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    updateValuesToState("highSchool", value);
  };

  const handleBedRoomChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));    
     if (replaceComma(value) > 100) {
      value = 100;
    }
    updateValuesToState("noBedRm", replaceComma(value));
  };

  const handleBathRoomChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));    
     if (replaceComma(value) > 100) {
      value = 100;
    }
    updateValuesToState("noBathRm", replaceComma(value));
  };

  const handleSqFtChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));    
    updateValuesToState("sqFt", replaceComma(value));
  };

  const handleStoryChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("stories", replaceComma(value));
  };

  const handleLotSizeChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("lotSize", replaceComma(value));
  };

  const handleGarrageSpotChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("grgeSpots", replaceComma(value));
  };

  const handleYearBuitChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    if (checkIsNumber(value)) {
      updateValuesToState("yearBuilt", value);
    }
  };

  const handleCheckBoxClick = (e) => {
    if (e.target.id === adaAccessCheckBox) {
      handleFormValues({ adaAccess: e.target.checked });
    } else {
      handleFormValues({ IsNewConstruction: e.target.checked });
    }
  };

  useEffect(() => {
    setYearBuiltError("");

    if (invFormError) {
      switch (invFormError) {
        case yrMaxInValid:
          setYearBuiltError("Enter Valid year");
          break;
         
      }
    }
  }, [invFormError, errorUpdatedAt]);

  return (
    <>
      <div className="pt-20">
        <p className="mt-0 mb-2 fnt12">DETAILS</p>
        <h2 className="mt-0 pt-0 mb-2 titleText">
          Does this information look right?
        </h2>
        <p className="fnt16">
          If anything doesn’t look accurate, update it with the correct
          information.
        </p>
      </div>

      {!isPrivateRoom ? (
        <>
          <div class="row">
            <div class="col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Neighborhood</Form.Label>
                <Form.Control
                  type="text"
                //   disabled={!isPropertyExist}
                  onChange={(e) => {
                    handleNeighborhoodChange(e);
                  }}
                  value={neighborHood}
                //   placeholder="Neighborhood"
                />
              </Form.Group>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">High School</Form.Label>
                <Form.Control
                  type="text"
                  value={highSchool}
                //   disabled={!isPropertyExist}
                  onChange={(e) => {
                    handleHighSchoolChange(e);
                  }}
                //   placeholder="High School"
                />
              </Form.Group>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">
                  Bedrooms<span>*</span>
                </Form.Label>
                <Form.Control
                //   placeholder="Bedrooms"
                //   disabled={!isPropertyExist}
                  // type="text"
                  pattern="[0-9]*" 
                  inputmode="numeric"
                  className="form-control"
                  //errorMessage= {minError}
                  value={
                    noBedRm
                      ? thousandSeperatedValue(
                          removeDecimalZerosFromTextField(noBedRm)
                        )
                      : ""
                  }
                  onChange={(e) => {
                    handleBedRoomChange(e);
                  }}
                />
              </Form.Group>
            </div>
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Bathrooms<span>*</span></Form.Label>
                <Form.Control 
                // type="text"
                pattern="[0-9]*" 
                inputmode="numeric"
                class="form-control"
                // disabled={!isPropertyExist}
                //errorMessage= {minError}
                value={noBathRm? thousandSeperatedValue(removeDecimalZerosFromTextField(noBathRm)) : ""}
                onChange={(e) => {
                  handleBathRoomChange(e);
                }} 
                />
              </Form.Group>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Square Footage<span>*</span></Form.Label>
                <Form.Control 
                // type="text"
                pattern="[0-9]*" 
                inputmode="numeric"
                class="form-control"
                // disabled={!isPropertyExist}
                //errorMessage= {minError}
                value={sqFt? thousandSeperatedValue(removeDecimalZerosFromTextField(sqFt)) : ""}
                onChange={(e) => {
                  handleSqFtChange(e);
                }} 
                />
              </Form.Group>
            </div>
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Stories</Form.Label>
                <Form.Control 
                // type="text"
                inputmode="numeric"
                class="form-control"
                // disabled={!isPropertyExist}
                //errorMessage= {minError}
                value={thousandSeperatedValue(stories) || ""}
                onChange={(e) => {
                  handleStoryChange(e);
                }}
                />
              </Form.Group>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Lot Size (Acres)</Form.Label>
                <Form.Control 
                // type="text"
                pattern="[0-9.]*" 
                inputmode="numeric"
                class="form-control"
                // disabled={!isPropertyExist}
                //errorMessage= {minError}
                value={lotSize? thousandSeperatedValue(removeDecimalZerosFromTextField(lotSize)) : ""}
                onChange={(e) => {
                  handleLotSizeChange(e);
                }}
                />
              </Form.Group>
            </div>
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Garage Spaces</Form.Label>
                <Form.Control 
                // type="text"
                pattern="[0-9]*" 
                inputmode="numeric"
                class="form-control"
                // disabled={!isPropertyExist}
                //errorMessage= {minError}
                value={thousandSeperatedValue(grgeSpots) || ""}
                onChange={(e) => {
                  handleGarrageSpotChange(e);
                }}
                />
              </Form.Group>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="fnt14">Year Built</Form.Label>
                <TextBoxMob
                // type="text"
                pattern="[0-9]*" 
                inputmode="numeric"
                class="form-control"
                // disabled={!isPropertyExist}
                errorMessage={yearBuiltError}
                value={yearBuilt}
                onChange={(e) => {
                  handleYearBuitChange(e);
                }}
                />
              </Form.Group>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pe-2 pb-3">
              <label
                class="form-check-label checkbox-label"
                for={newConstructionCheckBox}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                //   disabled={!isPropertyExist}
                  id={newConstructionCheckBox}
                  checked={IsNewConstruction}
                  onChange={(e) => handleCheckBoxClick(e)}
                />
                <span className="fnt14"> New Construction</span>
              </label>
            </div>
            <div class="col-6 ps-2 pb-3">
              <label
                class="form-check-label checkbox-label"
                for={adaAccessCheckBox}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                //   disabled={isPrivateRoom ? false : !isPropertyExist}
                  id={adaAccessCheckBox}
                  checked={adaAccess}
                  onChange={(e) => handleCheckBoxClick(e)}
                />
                <span className="fnt14"> ADA Accessible</span>
              </label>
            </div>
          </div>
        </>
      ) : (
        <div class="col-6 ps-2 pb-3">
          <label class="form-check-label checkbox-label" for={adaAccessCheckBox}>
            <input
              class="form-check-input"
              type="checkbox"
            //   disabled={isPrivateRoom ? false : !isPropertyExist}
              id={adaAccessCheckBox}
              checked={adaAccess}
              onChange={(e) => handleCheckBoxClick(e)}
            />
            <span className="fnt14"> ADA Accessible</span>
          </label>
        </div>
      )}
    </>
  );
};

export default MustHavesProperties;
