import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const ConfirmApprovalModal = (props) => {
  const {show, onHide, confirmPreApprovalStatus, handleAdjustBudgetClick, isMobileView, isPremiumUser} = props
  return (
    <Modal
      show = {show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        ></button>
        <h2 className="text-center">Confirm pre-approval status</h2>

        <Row>
          <Col xl={12}>
            <p className=" mb-5 text-center f-size-16">
            {isPremiumUser ? "By confirm, you agree that your client is pre-approved up to the maximum amount set in your budget." : "By confirm, you agree that you are pre-approved up to the maximum amount set in my budget." }
            </p>
         </Col>
          <Col xl={6} className={isMobileView ? "order-2" : ""}> 
          <Button variant="secondary" className={isMobileView ? "btn w-100 btn-secondary-small mb-0" : "btn w-100 btn-secondary-small"} onClick={handleAdjustBudgetClick}>
            Adjust Budget
            </Button>
            </Col>
          <Col xl={6} className={isMobileView ? "order-1" : ""} >
          <Button variant="primary" className={isMobileView ? "btn w-100 mb-3" : "btn w-100"} onClick={confirmPreApprovalStatus}>
            Confirm
            </Button>
            </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default ConfirmApprovalModal;
