/**
 * @file   src\components\AgentWantAd\WantAdBudget.js
 * @brief  This file is responsible for handling wantad budget in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from "react";
import { handleNegativeInput, removeNegativeInput } from "../../helpers/validations";
import TextBox from "../Common/TextBox";
import { getAgentStateValue, thousandSeperatedValue } from '../../helpers/common'
import { BUY, IS_LAND, LONG_RENT, SHORT_RENT } from "../../constants/common";
import InfoIcon from "../../images/info-icon.svg";


const WantAdBudget = (props) => {
  const {
    handleFormValues,
    budgetMin,
    budgetMax,
    isAdjustBdget,
    isPreApprLoan,
    adType,
    errors } = props


  const [budgetRange, setBudgetRange] = useState([budgetMin ? budgetMin : '' , budgetMax ? budgetMax : '']);
  const [minError, setMinError] = useState("")
  const [maxError, setMaxError] = useState("")
  const prprtyType = getAgentStateValue('prprtyType')
 
  const [budgetMinCursorPos, setBudgetMinCursorPos] = useState(0);
  const [budgetMaxCursorPos, setBudgetMaxCursorPos] = useState(0);

  const minBudgetRef = useRef();
  const maxBudgetRef = useRef();



  // set budget label based on ad type
  let label = 'Budget'
  if (adType == SHORT_RENT) {
    label = 'Budget (per night)'
  } else if (adType == LONG_RENT) {
    label = 'Budget (per month)'
  }

  useEffect(() => {
    if (isAdjustBdget == true &&
      (isPreApprLoan == true ||
        isPreApprLoan == 'Yes')
    ) {
      minBudgetRef.current.focus();
      handleFormValues({
        isAdjustBdget: false
      })
    }
  }, [budgetMin, budgetMax, isAdjustBdget, isPreApprLoan]);


  // to show error message
  useEffect(() => {
   
    if (errors && errors.length > 0) {
      if(errors[0] == 'maxBdgt'){
        maxBudgetRef.current.focus()
      }
      
      errors.map((item) => {
        if (item === 'maxBdgt'){
          setMaxError("Value Must be greater than minimum");
        }
      })
    }
  }, [errors]);


  useEffect(() => {
    
    minBudgetRef.current.setSelectionRange(budgetMinCursorPos, budgetMinCursorPos);
  }, [budgetMinCursorPos]);

  useEffect(() => {
    maxBudgetRef.current.setSelectionRange(budgetMaxCursorPos, budgetMaxCursorPos);
  }, [budgetMaxCursorPos]);

  //Handle value change in min budget text field
  const handleBudgetMinChange = (e) => {
    setMinError("")
    const budgetMax = budgetRange && budgetRange[1]
    const budgetMin = budgetRange && budgetRange[0]
    const value = handleNegativeInput(e.target.value)
     if(value != 0){
      setBudgetRange([value, budgetMax]);
      saveBudgetValuesToState()

        if( (thousandSeperatedValue(budgetMin)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos(e.target.selectionStart)
         } else if((thousandSeperatedValue(budgetMin)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
          setBudgetMinCursorPos((e.target.selectionStart + 1) )
        } else{
          setBudgetMinCursorPos(e.target.selectionStart)
        }
       
      
    } else{
      setBudgetRange(['', budgetMax]);
      saveBudgetValuesToState()
    }
  };

    //Handle value change in max budget text field
  const handleBudgetMaxChange = (e) => {
    setMaxError("")
    const budgetMin = budgetRange && budgetRange[0]
    const budgetMax = budgetRange && budgetRange[1]
    const value = handleNegativeInput(e.target.value)
    if(value != 0){
      setBudgetRange([budgetMin, value]);
      saveBudgetValuesToState()
   
      if( (thousandSeperatedValue(budgetMax)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
        setBudgetMaxCursorPos(e.target.selectionStart)
       } else if((thousandSeperatedValue(budgetMax)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
        setBudgetMaxCursorPos((e.target.selectionStart + 1) )
      } else{
        setBudgetMaxCursorPos(e.target.selectionStart)
      }
    } else{
      setBudgetRange([budgetMin, '']);
      saveBudgetValuesToState()
    }
  };


  const saveBudgetValuesToState = (e) => {
    if (budgetRange) {
      handleFormValues(
        {
          minBdgt: (budgetRange[0].toString().replace(/,/g, '')),
          maxBdgt: (budgetRange[1].toString().replace(/,/g, ''))
        })
    }

  };

  useEffect(() => {
    saveBudgetValuesToState()
  }, [budgetRange]);

  const handleAgentCompenationChange = (e) =>{
   
    let value = removeNegativeInput(e.target.value)
 
    if (value > 10) {
      value = 10;
    } 
    handleFormValues(
      {
        agentCompenation: value
      })

  }

  return (
    <div className="range-values-wrap mb-0">
      <div className="row">
        <div className="col-12">
          <label class="form-label mb-12">{label} <span>*</span></label>
          <ul class="range-values-list">
            <li className="position-relative">
              <span className="dollar-sign-icon">$</span>
              <TextBox
                type="text"
                class={minError ? "pad-left-25 red-marked-formcontrol" : "pad-left-25"}
                errorMessage={minError}
                value={(thousandSeperatedValue(budgetRange) && thousandSeperatedValue(budgetRange[0])) || ''}
                placeholder="No min"
                onChange={(e) => {
                  handleBudgetMinChange(e);
                }}
                txtRef={minBudgetRef}
                onBlur={()=>{handleFormValues({focusOut: true})}}></TextBox>
            </li>
            <li className="text-center"><span className="middle-separator">-</span></li>
            <li className="position-relative">
              <span className={ maxError? "dollar-sign-icon red-marked-dollar-sign": "dollar-sign-icon "}>$</span>
              <TextBox
                type="text"
                class={maxError ? "pad-left-25 red-marked-formcontrol" : "pad-left-25"}
                errorMessage={maxError}
                value={(thousandSeperatedValue(budgetRange) && thousandSeperatedValue(budgetRange[1])) || ''}
                placeholder="No max"
                onChange={(e) => {
                  handleBudgetMaxChange(e);
                }}
                txtRef={maxBudgetRef}
                onBlur={()=>{handleFormValues({focusOut: true})}}
              ></TextBox>

            </li>
          </ul>

          {/* {(adType == BUY  || prprtyType == IS_LAND) &&
            <div class="w-100 position-relative range-values-sublist">
              <label class="d-none"></label>
              <span className="percent-sign-icon">%</span>
              <label className="form-label ">Minimum compensation for buyer's agent<span className="range-value-info"><img src={InfoIcon} /></span></label>
              <input class="form-control pad-left-25" limit="2" step="0.25" type="number" value={agentCompenation}    onChange={(e) => {
                  handleAgentCompenationChange(e);
                }}
                onBlur={()=>{handleFormValues({focusOut: true})}}/>
              <div class="error-msg"></div>
            </div>
          } */}

        </div>
      </div>
    </div>
  );
};
export default WantAdBudget;
