import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DeleteModal = (props) => {
  const { show, handleConfirm, title, message, buttonText, onHide } = props;


  return (
    <Modal
      show={show}
      // {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={onHide}
        ></button>
        <h2 className="text-center f-size-24 mb-4 l-spacing">{title}</h2>

        <Row>
          {/* <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-start">
              {message}
            </p>

          </Col> */}
          <Col xl={12} className="">
            <div className="modal-btn-wrap flex-column">
              <Button variant="" className="btn btn-tertiary me-0 w-100 mb-3" onClick={onHide}>
                Cancel
              </Button>
              <Button variant="" className="btn btn-delete w-100" onClick={handleConfirm}>
                {buttonText}

              </Button>

            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default DeleteModal;
