/**
 * @file   src\components\AgentWantAd\WantAdOption.js
 * @brief  This file is responsible for handling wantad types selection in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import HouseImg from "../../images/house-img.png";
import { handleRadioButonChange } from "../../actions/common";
import { BUY, LONG_RENT, SHORT_RENT } from '../../constants/common'
import { usePrevious } from "../../hooks/usePrevious";
const WantAdOption = (props) => {
  const { handleFormValues, adType, clearPreviousStates, typeSelectionError, handleStayRequestPopupOpen} = props;
  const turnOffStay = process.env.REACT_APP_TURN_OFF_STAY ? true : false;


  const previousAdType = usePrevious(adType);

  const getSelectedValue = (e) => {
    const typeOfAd = handleRadioButonChange(e);
    if(turnOffStay && typeOfAd===SHORT_RENT){
      handleStayRequestPopupOpen()
    }
    else {
      if (typeOfAd) {
        const value = {
          adType: typeOfAd
        };
        clearPreviousSelectedValues(value);
        handleFormValues(value, {focusOut: true});
      }
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    const isClearPrevious =
      (previousAdType !== currentSelectedValue);
    if (isClearPrevious) {
      clearPreviousStates({ type: 'clearAllExceptClient' });
      handleFormValues({focusOut: true});
    }
  };

  return (
    <>
      <label className="radio-wrap mb-3">
        <input type="radio" name="radio-ad-option" value={BUY} checked={adType && adType === BUY} onChange={getSelectedValue} />
        <span className={typeSelectionError &&"red-marked-span"}>
          <div className="wantad-type-info">
            <h2 className={typeSelectionError ? "pb-0 red-marked-subheading" : "pb-0"}>Buy</h2>
            <p className={typeSelectionError  && "red-marked-subpara" }>Purchasing property</p>

          </div>
          {/* <div className="right-img-wrap">
                      <img src={HouseImg} />
                    </div> */}

        </span>
      </label>

      <label className="radio-wrap mb-3">
        <input type="radio" name="radio-ad-option" value={LONG_RENT} checked={adType && adType === LONG_RENT} onChange={getSelectedValue} />
        <span className={typeSelectionError &&"red-marked-span"}>
          <div className="wantad-type-info">
            <h2 className={typeSelectionError ? "pb-0 red-marked-subheading" : "pb-0"}>Rent (Long-term)</h2>
            <p className={typeSelectionError  && "red-marked-subpara" }>Stay longer than 30 days</p>
          </div>
          {/* <div className="right-img-wrap">
                      <img src={HouseImg} />
                    </div> */}

        </span>
      </label>
      <label className="radio-wrap mb-3">
        <input type="radio" name="radio-ad-option" value={SHORT_RENT} checked={adType && adType === SHORT_RENT} onChange={getSelectedValue} />
        <span className={typeSelectionError &&"red-marked-span"}>
          <div className="wantad-type-info">
            <h2 className={typeSelectionError && "red-marked-subheading"}>Rent (Short-term)</h2>
            <p className={typeSelectionError  && "red-marked-subpara" }>Stay less than 30 days</p>

          </div>
          {/* <div className="right-img-wrap">
                      <img src={HouseImg} />
                    </div> */}

        </span>
      </label>
    </>
  );
};
export default WantAdOption;
