/**
 * @file   src\components\MemberWantAd\EditWantAd\WantAdRentCreditScore.js
 * @brief  This file is responsible for handling wantad credit score strengthen data  in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from "react";
import { getCreditScores } from "../../../services/wantAdService";
import { handleRadioButonChange } from "../../../actions/common";

const WantAdRentCreditScore = (props) => {
  const {
    handleFormValues,
    creditScore,
  } = props;
  const [creditScoreList, setCSList] = useState([]);

  useEffect(() => {

    getCreditDetails();
  }, [creditScore]);

  const getCreditDetails = async () => {
    await getCreditScores()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const creditScores = _.get(response, "result", null);
          setCSList(creditScores);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    let currentselected = creditScoreList.filter((elem) => elem.CreditScoreId == value);
    if (value) {
      handleFormValues({
        creditScore: value,
        creditScoreText: currentselected[0].CreditScoreText
      });
    }
  };

  const loadCreditScores = () => {
    const List =
      creditScoreList &&
      creditScoreList.map((pt) => {
        const id = _.get(pt, "CreditScoreId", "");
        const text = _.get(pt, "CreditScoreText", "");
        const desc = _.get(pt, "CreditScoreDesc", "");
        return (
          <div key={id} className="col-sm-12 col-lg-12 col-xl-12 col-xxl-6">
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="rc-radio"
                value={id}
                checked={creditScore && creditScore == id}
                onChange={(e) => handleItemClick(e)}
              />
              <span>
                <div className="wantad-type-info">
                  <h2>{text}</h2> <p>{desc}</p>
                </div>

              </span>
            </label>
          </div>
        );
      });

    return List;
  };

  return (
    <>
      <div className="wantad-type-wrap pb-24">
        <div className="row">{loadCreditScores()}</div>
      </div>

    </>
  );
};
export default WantAdRentCreditScore;
