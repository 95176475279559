import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import {
  getLocalStorage,
  getUserId,
  getUserName,
  getUserType,
  loadCompensationText,
  loadTimeLineString,
  removeLocalStorage,
  setBodyBackgroundWhite,
  setLocalStorage,
} from "../../helpers/common";
import NxtArrow from "../components/nextArrow";
import BottomStepperMob from "../components/BottomStepperMob";
import CheckIcn from "../images/checkIcn.svg";
import { getAdTypeText, truncate } from "../../helpers/common";
import { getNiceToHaves } from "../../services/commonServices";
import {
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  BUY_LAND,
  BUY,
  IS_LAND,
  WANT_AD_KEY_TEMP,
  WANT_AD_KEY,
  USER_TYPE_AGENT,
  BUDGET_NOMIN_NOMAX,
} from "../../constants/common";
import { loadInvestmentCriteria, wantAdBudgetValue } from "../../helpers/createWantAd";
import {
  getCombinedText,
  getCombinedTextForInvestmentCriteria,
  getFinanceTypeDisplay,
  getMustHavesText,
} from "../../helpers/getPropertyIcons";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import {
  saveMemberWantAd,
  saveMemberWantAdstrengthenData,
} from "../../services/wantAdService";
import { toast } from "react-toastify";
const InvestorProfileCreateWantAdSummary = (props) => {
  const {
    completedPercentage,
    setUIKey,
    formValues,
    uiKey,
    setCompletedPercentage,
    handleSaveAndExit,
    isPremiumUser,
    isEdit,
    isFromMyWantAd,
    isFromBuyerListingNotification,
  } = props;
  const navigate = useNavigate();
  const adType = _.get(formValues, "adType", null);
  const location = _.get(formValues, "location", null);
  const mapRadius = _.get(formValues, "mapRadius", null);
  const city = _.get(formValues, "city", null);
  const state = _.get(formValues, "state", null);
  const budgetMin = formValues?.minBdgt;
  const budgetMax = formValues?.maxBdgt;
  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const propertyType = _.get(formValues, "prprtyType", null);
  const nice2Have = _.get(formValues, "nice2Have", null);
  const isAgentAsSeller = _.get(formValues, "isAgentAsSeller", null);
  const purchaseText =
    _.get(formValues, "purchaseText", null) ||
    _.get(formValues, "prchTypeTxt", null);
  const is1031Exchange =
    (_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) &&
    " - 1031 exchange";
  const purchaseType =
    _.get(formValues, "purchaseType", null) ||
    _.get(formValues, "prchTypeId", null);
  const pFinance =
    _.get(formValues, "pFinance", null) || _.get(formValues, "finTypeId", null);
  const pFinanceText =
    _.get(formValues, "pFinanceText", null) ||
    _.get(formValues, "finTypeTxt", null);
  const title = _.get(formValues, "adTitle", "");
  const desc = _.get(formValues, "adDesc", "");
  const compenationType = _.get(formValues, "compenationType", null);
  const agentCompenation = _.get(formValues, "agentCompenation", null);

  const [adTypeError, setAdTypeError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [timeLineError, setTimeLineError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [mustHavesError, setMustHavesError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const adTypeBasedOnProperty = prptySubTypes?.includes(6) ? LOT_LAND : adType;
  const isAgent = getUserType() === USER_TYPE_AGENT ? true : false;
  const mustHaveText =
    getMustHavesText(formValues) != ""
      ? truncate(getMustHavesText(formValues), 30)
      : "";

  const [niceToHavesList, setNiceToHavesList] = useState([]);
  let isDisabled = false;
  if (adType != BUY && propertyType === IS_LAND) {
    isDisabled = false;
  } else if (prptySubTypes && prptySubTypes?.length == 0) {
    isDisabled = true;
  }

  const investmentCriteria = _.get(formValues, "investmentId", []);
  const capRateId = _.get(formValues, "capRateId", "");

  const zipCodeValue = [];
  if (formValues?.zipCode) {
    formValues?.zipCode.map((zip) => {
      zipCodeValue.push(zip?.zipCode)
    })
  }

  useEffect(() => {
    if (nice2Have && nice2Have?.length > 0 === true) {
      getNiceToHaves(
        adTypeBasedOnProperty === LOT_LAND ? BUY_LAND : adTypeBasedOnProperty
      )
        ?.then((response) => {
          if (response.errorCode === 0 && _.get(response, "result", null)) {
            const list = _.get(response, "result", null);
            setNiceToHavesList(list);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, [uiKey === null]);

  useEffect(() => {
    setBodyBackgroundWhite();
  }, []);

  let budget;
  if (budgetMin !== undefined || budgetMax !== undefined) {
    budget = wantAdBudgetValue(budgetMin, budgetMax);
    if(budget != BUDGET_NOMIN_NOMAX){
      if (adType === LONG_RENT) {
        budget = budget + " /Month";
      } else if (adType === SHORT_RENT) {
        budget = budget + " /Night";
      }
    }
  } else {
    budget = null;
  }

  const loadTimeLine = () => {
    return loadTimeLineString(adType, formValues);
  };

  const loadNiceToHaveList = (masterList, selectedItems) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        return itemText;
      })
    );
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);

    const masterArray = _.get(niceToHavesList, section, []);

    if (selectedItems?.length) {
      return loadNiceToHaveList(masterArray, selectedItems);
    } else {
      return "";
    }
  };

  const getNiceToHavesDisplaytext = () => {
    let resultText = "";
    const niceToHaveMasterArray =
      adTypeBasedOnProperty === LOT_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    const combinedArray =
      finalList && finalList.length > 0
        ? finalList?.filter((item) => item != "")
        : [];
    let merged =
      combinedArray && combinedArray.length > 0
        ? combinedArray.reduce(function (prev, next) {
          return prev.concat(next);
        })
        : [];

    merged = merged?.filter((item) => item != "");
    if (merged?.length > 0) {
      resultText = merged[0];
      if (merged?.length > 1) {
        for (let i = 1; i < merged?.length - 1; i++) {
          resultText = resultText + ", " + merged[i];
        }
        resultText = resultText + " or " + merged[merged?.length - 1];
      }
      return truncate(resultText, 30);
    } else {
      return "";
    }
  };

  const getNiceToHaveText = () => {
    if (isDisabled) {
      return "Select property type first";
    } else if (nice2Have && nice2Have?.length > 0) {
      return getNiceToHavesDisplaytext();
    } else {
      return "Select your nice to haves";
    }
  };

  const getBudgetText = () => {
    if (isDisabled) {
      return "Select property type first";
    } else if (budget != null) {
      return budget;
    } else {
      return "Select your budget";
    }
  };

  const getMustHaveText = () => {
    if (isDisabled) {
      return "Select property type first";
    } else {
      if (mustHaveText != "") {
        return mustHaveText;
      } else return "";
    }
  };

  const saveStrengthenData = async (wantAdIdValue, isPostAd) => {
    const wantAdId = wantAdIdValue;
    let updatedState = {};
    let requestPayLoad = {};
    let isApproveLoan = "";
    if (
      _.get(formValues, "isPreApprLoan", null) === "Yes" ||
      _.get(formValues, "isPreApprLoan", null) == true
    ) {
      isApproveLoan = true;
    } else if (
      _.get(formValues, "isPreApprLoan", null) === "No" ||
      _.get(formValues, "isPreApprLoan", null) == true
    ) {
      isApproveLoan = false;
    }
    requestPayLoad.isPreApprLoan = isApproveLoan;
    const is1031Exchange =
      (_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) ? true : false;
    requestPayLoad.wantAdId = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan;
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.completedStep = 1;
    requestPayLoad.adType =
      _.get(formValues, "adType", "") == BUY
        ? "BUY_STRENGTHEN"
        : "LTR_STRENGTHEN";
    requestPayLoad.creditScore = _.get(formValues, "creditScore", "");
    requestPayLoad.maxBdgt = _.get(formValues, "maxBdgt", "");
    requestPayLoad.minBdgt = _.get(formValues, "minBdgt", "");
    requestPayLoad.pFinance =
      _.get(formValues, "pFinance", "") || _.get(formValues, "finTypeId", "");
    requestPayLoad.purchaseType =
      _.get(formValues, "purchaseType", "") ||
      _.get(formValues, "prchTypeId", "");
    await saveMemberWantAdstrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);

        if (response.errorCode === 0 && responseResult) {
          updatedState.wantAdId = wantAdId;
          if (isPostAd) {
            updatedState.wantAdId = wantAdId;
            updatedState.isEdit = true;
            setLocalStorage(WANT_AD_KEY, updatedState);
            localStorage.removeItem(WANT_AD_KEY_TEMP);
            navigate(pageURLs.viewAd);
            toast.success("Want-Ad successfully edited");
          } else {
            localStorage.removeItem(WANT_AD_KEY_TEMP);
            localStorage.removeItem(WANT_AD_KEY);
            navigate(pageURLs.wantAdListing, { state: { showDraft: true } });
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const saveMemberWantAdData = (isPostAd) => {
    let updatedState = {};
    const userId = getUserId(); //_.get(user, "userInfo.userId", null);
    const requestPayLoad = getLocalStorage(WANT_AD_KEY);
    if (userId && requestPayLoad) {
      let arrayValue = [];
      if (requestPayLoad?.zipIdValues) {
        requestPayLoad?.zipIdValues.map((zip) => {
          arrayValue.push(zip?.zipId)
        })
        requestPayLoad.zipId = arrayValue;
      } else if (requestPayLoad?.zipCode) {
        requestPayLoad?.zipCode.map((zip) => {
          if (zip?.zipId && zip?.zipId != null) {
            arrayValue.push(zip?.zipId)
          }
        })
        requestPayLoad.zipId = arrayValue;
      }
      requestPayLoad.isZipcodeBased = requestPayLoad.zipId?.length > 0 ? true : false;
      requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
      requestPayLoad.userId = userId;
      if (isAgent) {
        requestPayLoad.isAgentCreated = true;
      }
      if (isAgent && !isPremiumUser) {
        requestPayLoad.isAgentAsSeller = true;
      }
      if (isPostAd) {
        requestPayLoad.IsSavedDraft = false;
        requestPayLoad.IsPublished = true;
        delete requestPayLoad.tlText;
        delete requestPayLoad.prptyTypeTxt;
        delete requestPayLoad.wantAdValErrors;
        delete requestPayLoad.updatedAt;
      } else {
        requestPayLoad.IsSavedDraft = true;
        requestPayLoad.IsPublished = false;
      }

      saveMemberWantAd(requestPayLoad)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            if (
              requestPayLoad.purchaseType ||
              requestPayLoad.creditScore ||
              requestPayLoad.prchTypeId ||
              requestPayLoad.pFinance
            ) {
              saveStrengthenData(responseResult.wantAdId, isPostAd);
            } else {
              if (isPostAd) {
                updatedState.wantAdId = _.get(responseResult, "wantAdId", "");
                updatedState.isEdit = true;
                removeLocalStorage(WANT_AD_KEY_TEMP);
                setLocalStorage(WANT_AD_KEY, updatedState);
                toast.success("Want-Ad successfully edited");
                navigate(pageURLs.viewAd);
              } else {
                removeLocalStorage(WANT_AD_KEY);
                removeLocalStorage(WANT_AD_KEY_TEMP);
              }
            }
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      handleStateClear();
    }
  };

  const validate = () => {

    let error = [];
    let isValid = true;
    if (!adType) {
      setAdTypeError(true);
      error.push("adTypeRef");
      isValid = false;
    }
    if (!location && zipCodeValue?.length == 0) {
      setLocationError(true);
      error.push("locationRef");
      isValid = false;
    }
    if (loadTimeLine() == "" || loadTimeLine() == null) {
      setTimeLineError(true);
      error.push("timeLineRef");
      isValid = false;
    }
    if (prptySubTypes?.length <= 0) {
      error.push("propertyTypeRef");
      setPropertyTypeError(true);
      isValid = false;
    }

    if (propertyType != 11 && getMustHaveText() === "") {
      setMustHavesError(true);
      error.push("mustHaveRef");
      isValid = false;
    }
    if (!budget) {
      setBudgetError(true);
      error.push("BudgetRef");
      isValid = false;
    }
    if (isEdit && title === "") {

      setTitleError(true);
      error.push("DescriptionRef");
      isValid = false;

    }

    if (error.length != 0) {
      if (error[0] == "adTypeRef") {
        document
          .getElementById("adTypeId")
          ?.scrollIntoView({ behavior: "smooth" });
      } else if (error[0] == "locationRef") {
        document
          .getElementById("locationId")
          ?.scrollIntoView({ behavior: "smooth" });
      } else if (error[0] == "timeLineRef") {
        document
          .getElementById("timeLineId")
          ?.scrollIntoView({ behavior: "smooth" });
      } else if (error[0] == "propertyTypeRef") {
        document
          .getElementById("propertyTypeId")
          ?.scrollIntoView({ behavior: "smooth" });
      } else if (error[0] == "mustHaveRef") {
        document
          .getElementById("mustHaveId")
          ?.scrollIntoView({ behavior: "smooth" });
      } else if (error[0] == "BudgetRef") {
        document
          .getElementById("budgetId")
          ?.scrollIntoView({ behavior: "smooth" });
      } else if (error[0] == "DescriptionRef") {
        document
          .getElementById("descriptionId")
          ?.scrollIntoView({ behavior: "smooth" });
      }
      else if (error[0] == "compensationRef") {
        document
          .getElementById("compensationId")
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
    return isValid;
  };

  const getClassName = (condition, error) => {
    if (error) {
      return "selectBoxWrap d-flex mb-3 error-brdr";
    } else if (condition) {
      return "selectBoxWrap select d-flex mb-3";
    } else {
      return "selectBoxWrap d-flex mb-3";
    }
  };
  return (
    <>
      <div className="gitcha-main-wrapper pb-0 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 flex-column ">
              <div className="top-three-comp-header-inner w-100">
                <a
                  className="d-block back-link-big me-4"
                  href={void 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isEdit) {
                      setLocalStorage(WANT_AD_KEY, {
                        wantAdId: _.get(formValues, "wantAdId", null),
                        isFromHomeFeed: true,
                      });
                      if (isFromMyWantAd) {
                        navigate(pageURLs.myWantAds);
                      } else if (isFromBuyerListingNotification) {
                        navigate(pageURLs?.mobileInapp);
                      } else {
                        navigate(pageURLs.viewAd);
                      }
                    } else {
                      setUIKey("adType");
                    }
                  }}
                >
                  back
                </a>
                {!isEdit && (
                  <div className="progress-wrap-mob">
                    <BottomStepperMob
                      completedPercentage={completedPercentage}
                    />
                  </div>
                )}

                <button className="btn top-exit-btn ms-4">&nbsp;</button>
              </div>
            </div>
          </div>
          <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">

            <div className="">
              <div className="mt-0 pt-3 mb-0 titleText">
                {isEdit ? "Edit Want-Ad" : "Create a Want-Ad"}
              </div>
              <p className="titleTextpara">
                {isEdit
                  ? "Confirm the details about your want-ad. Select an item to edit it."
                  : "Tap on the section to add your response. Items marked with an asterisk have required fields."}
              </p>
            </div>
            <div
              className={getClassName(adType, adTypeError)}
              onClick={() => setUIKey("adType")}
              id="adTypeId"
            >
              <div className="flex-grow-1">
                <h4>Ad Type * {adType ? <img src={CheckIcn} /> : <></>}</h4>
                <h5>{adType ? getAdTypeText(adType) : "Select ad type"}</h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={getClassName(zipCodeValue?.length > 0 ? zipCodeValue : location, locationError)}
              onClick={() => setUIKey("location")}
              id="locationId"
            >
              <div className="flex-grow-1">
                <h4>Location * {(location || zipCodeValue?.length > 0) ? <img src={CheckIcn} /> : <></>}</h4>
                {zipCodeValue?.length > 0 ? (
                  <>
                    <h5>Within ZIP code {zipCodeValue.join(', ')}</h5>
                  </>
                ) :
                  (
                    <>
                      {location ? (
                        <h5>
                          {mapRadius} mi within {city}, {state}
                        </h5>
                      ) : (
                        <h5>Select location</h5>
                      )}
                    </>
                  )}
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={getClassName(
                loadTimeLine() != "" && loadTimeLine() != null,
                timeLineError
              )}
              onClick={() => setUIKey("timeLine")}
              id="timeLineId"
            >
              <div className="flex-grow-1">
                <h4>
                  Timeline *{" "}
                  {loadTimeLine() != "" && loadTimeLine() != null ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>
                  {loadTimeLine() != "" && loadTimeLine() != null
                    ? loadTimeLine()
                    : "Select your timeline"}
                </h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={getClassName(
                prptySubTypes && prptySubTypes?.length > 0,
                propertyTypeError
              )}
              onClick={() => setUIKey("propertyType")}
              id="propertyTypeId"
            >
              <div className="flex-grow-1">
                <h4>
                  Property Type *{" "}
                  {prptySubTypes && prptySubTypes?.length > 0 ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>
                  {prptySubTypes && prptySubTypes?.length > 0
                    ? truncate(getCombinedText(prptySubTypes), 30)
                    : "Select your property"}
                </h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={
                !isDisabled
                  ? getClassName(getMustHaveText() != "", mustHavesError)
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("mustHaves");
                }
              }}
              id="mustHaveId"
            >
              <div className="flex-grow-1">
                <h4>
                  Must Haves {propertyType != 11 ? "*" : ""}{" "}
                  {getMustHaveText() != "" && !isDisabled ? (
                    <img src={CheckIcn} />
                  ) : (
                    ""
                  )}
                </h4>
                <h5>
                  {getMustHaveText() != ""
                    ? getMustHaveText()
                    : "Select your must haves"}
                </h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={
                !isDisabled
                  ? nice2Have && nice2Have?.length > 0 != ""
                    ? "selectBoxWrap d-flex mb-3 select"
                    : "selectBoxWrap d-flex mb-3"
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("niceToHaves");
                }
              }}
            >
              <div className="flex-grow-1">
                <h4>
                  Nice-to-Haves{" "}
                  {nice2Have && nice2Have?.length > 0 && !isDisabled ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>{getNiceToHaveText()}</h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={
                !isDisabled
                  ? getClassName(budget != null, budgetError)
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("budget");
                }
              }}
              id="budgetId"
            >
              <div className="flex-grow-1">
                <h4>
                  Budget *{" "}
                  {budget != null && !isDisabled ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>{getBudgetText()}{truncate(getFinanceTypeDisplay(formValues), 34)}</h5>
              </div>

              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            {
              isEdit && (
                <div
                  className={
                    !isDisabled
                      ? getClassName(title != "", titleError)
                      : "selectBoxWrap d-flex mb-3 disable"
                  }
                  onClick={() => {
                    setUIKey("title");
                  }}
                  id="descriptionId"
                >
                  <div className="flex-grow-1">
                    <h4>
                      Title & Description *
                      {title != "" && !isDisabled ? (
                        <img src={CheckIcn} />
                      ) : (
                        <></>
                      )}
                    </h4>
                    <h5>
                      {title != ""
                        ? desc != ""
                          ? title + " & " + desc
                          : title
                        : "Add title and description"}
                    </h5>
                  </div>

                  <div className="align-self-center ">
                    <Button variant="" id="button-addon2">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>
              )}

            <>
              <div
                className={
                  !isDisabled
                  ?
                  purchaseType
                    ? "selectBoxWrap select d-flex mb-3"
                    : "selectBoxWrap d-flex mb-3"
                    :  "selectBoxWrap d-flex mb-3 disable"
                }
                onClick={() => setUIKey("purchaseType")}
              >
                <div className="flex-grow-1">
                  <h4>
                    Purchase Type{" "}
                    {purchaseType ? <img src={CheckIcn} /> : <></>}
                  </h4>
                  <h5>{purchaseType ? (purchaseText + (is1031Exchange ? is1031Exchange : '')) : "Select property type first"}</h5>
                </div>
                <div className="align-self-center ">
                  <Button variant="" id="button-addon2">
                    <NxtArrow />
                  </Button>
                </div>
              </div>

              {purchaseType == 3 && (

                <div
                className={
                  !isDisabled
                    ? ((investmentCriteria && investmentCriteria?.length > 0) || (capRateId && capRateId  != ""))
                      ? "selectBoxWrap d-flex mb-3 select"
                      : "selectBoxWrap d-flex mb-3"
                    : "selectBoxWrap d-flex mb-3 disable"
                }

                  // className={getClassName(
                  //   investmentCriteria && investmentCriteria?.length > 0,
                  //   propertyTypeError
                  // )}
                  onClick={() => setUIKey("investmentCriteria")}
                  id="investmentCriteriaId"
                >
                  <div className="flex-grow-1">
                    <h4>
                      Investment Criteria {" "}
                      {((investmentCriteria && investmentCriteria?.length > 0) || (capRateId && capRateId  != "")) ? (
                        <img src={CheckIcn} />
                      ) : (
                        <></>
                      )}
                    </h4>
                    <h5>
                      {investmentCriteria && investmentCriteria?.length > 0
                        ? truncate(getCombinedTextForInvestmentCriteria(investmentCriteria) +  ((capRateId  != "" &&  capRateId)  ? ", " + `Minimum cap rate of ${capRateId}%` : "") , 30)
                        : capRateId && capRateId  != "" ? `Minimum cap rate of ${capRateId}%`
                        :
                        "Select Investment Criteria"}
                    </h5>
                  </div>
                  <div className="align-self-center ">
                    <Button variant="" id="button-addon2">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>

              )}

            </>

            <div className="inner-btm-fixed-nav shadow-none pe-0 ps-0">
              <div className="btn-wrap text-center pb-40 pt-0">
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-20"
                  onClick={() => {
                    const valid = validate();
                    if (valid) {
                      if (!isEdit) {
                        setLocalStorage(
                          WANT_AD_KEY,
                          getLocalStorage(WANT_AD_KEY_TEMP)
                        );
                        setUIKey("title");
                      } else {
                        const isPostAd = true;
                        saveMemberWantAdData(isPostAd);
                      }
                    }
                  }}
                >
                  {!isEdit ? "Next" : "Update Changes"}
                </button>
                {!isEdit && (
                  <button
                    type="button"
                    onClick={() => handleSaveAndExit()}
                    class="btn btn-no-bg btn-no-bg-fix w-100"
                  >
                    Save & Exit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorProfileCreateWantAdSummary;
