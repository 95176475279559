import HouseTagIcon from "../../../images/house-img.png";
import { useState, useEffect } from "react";
import AddProperty from "./AddProperty";
import AddClient from "./AddClient";
import { getInitials } from "../../../helpers/common";
import noImage from "../../../images/no-image-placeholder.png";

const AgentPropertyDetails = (props) => {
    const {
        propertyList,
        clientList,
        setShowSelectProperty,
        setShowSelectClients,
        userId,
        selectedClient,
        selectedProperty,
        userDetails
    } = props;
    const [initials, setInitials] = useState(null);

    useEffect(() => {
        setInitials(
            getInitials(selectedClient?.FirstName + " " + selectedClient?.LastName)
        );
    }, [selectedClient]);

    return (
        <div className="my-property-outermob">
            <div className="my-property-innermob">
                {!clientList ? (
                    <AddClient userId={userId} userDetails={userDetails} />
                ) : (
                    <a
                    role="button"
                       
                        onClick={() => setShowSelectClients(true)}
                    //   className="add-your-property-wrap link-wrap mb-0"
                    >
                        <div className="my-property-mob pb-12 my-property-brdr-btm">
                            <div className="my-property-prof-outer">
                                <div className={"my-property-prof-wrap " + initials?.backgroundColor}>  {initials?.initials}</div>
                            </div>
                            <div className="my-property-details-mob">
                                <h3 className="mb-0">  {selectedClient?.FirstName + " " + selectedClient?.LastName}</h3>

                            </div>
                            <span className="right-arrow-link-mob-last">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 3.96967C8.76256 3.67678 9.23744 3.67678 9.53033 3.96967L17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L9.53033 20.0303C9.23744 20.3232 8.76256 20.3232 8.46967 20.0303C8.17678 19.7374 8.17678 19.2626 8.46967 18.9697L15.4393 12L8.46967 5.03033C8.17678 4.73744 8.17678 4.26256 8.46967 3.96967Z" fill="#1B1C1E" />
                                </svg>
                            </span>
                        </div>

                    </a>
                )}
                {!propertyList ? (
                    <AddProperty isAgent={true} selectedClient={selectedClient} userDetails={userDetails} />
                ) : (
                    <a
                    role="button"
                        onClick={() => setShowSelectProperty(true)}
                    //   className="add-your-property-wrap link-wrap"
                    >
                        <div className="my-property-mob pt-12">
                            <div className="my-property-img-mob ">
                                <img src={selectedProperty?.photo ? selectedProperty?.photo : noImage} />
                            </div>
                            <div className="my-property-details-mob">
                                <h3>{selectedProperty?.Location}</h3>
                                <p>{selectedProperty?.City},{selectedProperty?.State}</p>
                            </div>
                            <span className="right-arrow-link-mob-last">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 3.96967C8.76256 3.67678 9.23744 3.67678 9.53033 3.96967L17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L9.53033 20.0303C9.23744 20.3232 8.76256 20.3232 8.46967 20.0303C8.17678 19.7374 8.17678 19.2626 8.46967 18.9697L15.4393 12L8.46967 5.03033C8.17678 4.73744 8.17678 4.26256 8.46967 3.96967Z" fill="#1B1C1E" />
                                </svg>
                            </span>
                        </div>



                    </a>
                )}
            </div>
        </div>
    );
};
export default AgentPropertyDetails;
