/**
 * @file   src\components\MemberWantAd\EditWantAd\WantAdBudget.js
 * @brief  This file is responsible for handling wantad budget in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from "react";
import { handleNegativeInput } from "../../../helpers/validations";
import TextBox from "../../Common/TextBox";
import { LONG_RENT, SHORT_RENT } from "../../../constants/common";
import { thousandSeperatedValue } from '../../../helpers/common';


const WantAdBudget = (props) => {
  const {
    handleFormValues,
    budgetMin,
    budgetMax,
    isAdjustBdget,
    isPreApprLoan,
    adType,
    errors } = props


  const [budgetRange, setBudgetRange] = useState([budgetMin ? budgetMin : '' , budgetMax ? budgetMax : '']);
  const [minError, setMinError] = useState("")
  const [maxError, setMaxError] = useState("")
  const [budgetMinCursorPos, setBudgetMinCursorPos] = useState(0);
  const [budgetMaxCursorPos, setBudgetMaxCursorPos] = useState(0);

  const minBudgetRef = useRef();
  const maxBudgetRef = useRef();
  // const adType = getStatePropertyValue('adType')

  // set budget label based on ad type
  let label = 'Budget'
  if (adType == SHORT_RENT) {
    label = 'Budget (per night)'
  } else if (adType == LONG_RENT) {
    label = 'Budget (per month)'
  }

  useEffect(() => {
    if (isAdjustBdget == true &&
      (isPreApprLoan == true ||
        isPreApprLoan == 'Yes')
    ) {
      minBudgetRef.current.focus();
      handleFormValues({
        isAdjustBdget: false
      })
    }

    // if (budgetMin || budgetMax) {
    //   setBudgetRange([budgetMin, budgetMax]);
    // }

  }, [budgetMin, budgetMax, isAdjustBdget, isPreApprLoan]);


  // to show error message
  useEffect(() => {
    if (errors && errors.length > 0) {
      if(errors[0] == 'maxBdgt'){
        maxBudgetRef.current.focus()
      }

      errors.map((item) => {
        if (item === 'maxBdgt'){
          setMaxError("Value Must be greater than minimum");
        } 
        // else if (item == 'bdgtMinMissing') {
        //   setMinError('Enter min budget');
        // } else if (item == 'bdgtMaxMissing') {
        //   setMaxError('Enter max budget');
        // }
          
      })
    }
  }, [errors]);

  useEffect(() => {
    
    minBudgetRef.current.setSelectionRange(budgetMinCursorPos, budgetMinCursorPos);
  }, [budgetMinCursorPos]);

  useEffect(() => {
    maxBudgetRef.current.setSelectionRange(budgetMaxCursorPos, budgetMaxCursorPos);
  }, [budgetMaxCursorPos]);

  const handleBudgetMinChange = (e) => {
    setMinError("")
    const budgetMax = budgetRange && budgetRange[1]
    const budgetMin = budgetRange && budgetRange[0]
    const value = handleNegativeInput(e.target.value)
    if(value != 0){
      setBudgetRange([value, budgetMax]);
      saveBudgetValuesToState()
      if( (thousandSeperatedValue(budgetMin)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
        setBudgetMinCursorPos(e.target.selectionStart)
       } else if((thousandSeperatedValue(budgetMin)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
        setBudgetMinCursorPos((e.target.selectionStart + 1) )
      } else{
        setBudgetMinCursorPos(e.target.selectionStart)
      }
    } else{
      setBudgetRange(['', budgetMax]);
      saveBudgetValuesToState()
    }
   
  };

  const handleBudgetMaxChange = (e) => {
    setMaxError("")
    const budgetMin = budgetRange && budgetRange[0]
    const value = handleNegativeInput(e.target.value)
    if(value != 0){
      setBudgetRange([budgetMin, value]);
      saveBudgetValuesToState()
      if( (thousandSeperatedValue(budgetMax)?.length + 1) == thousandSeperatedValue(e.target.value)?.length){
        setBudgetMaxCursorPos(e.target.selectionStart)
       } else if((thousandSeperatedValue(budgetMax)?.length + 2) == thousandSeperatedValue(e.target.value)?.length){
        setBudgetMaxCursorPos((e.target.selectionStart + 1) )
      } else{
        setBudgetMaxCursorPos(e.target.selectionStart)
      }
    }else{
      setBudgetRange([budgetMin, '']);
      saveBudgetValuesToState()
    }
   
  };


  const saveBudgetValuesToState = (e) => {
    if (budgetRange) {
      handleFormValues(
        {
          minBdgt: (budgetRange[0].toString().replace(/,/g, '')),
          maxBdgt: (budgetRange[1].toString().replace(/,/g, ''))
        })
    }

  };

  useEffect(() => {
    saveBudgetValuesToState()
  }, [budgetRange]);

  return (
    <div className="range-values-wrap mb-3">
      <div className="row">
        <div className="col-12">
          <label class="form-label">{label} <span>*</span></label>
          <ul class="range-values-list">
            <li className="position-relative">
              <span className="dollar-sign-icon">$</span>
              <TextBox
                type="text"
                class={minError ? "pad-left-25 red-marked-formcontrol" : "pad-left-25"}
                errorMessage={minError}
                value={(thousandSeperatedValue(budgetRange) && thousandSeperatedValue(budgetRange[0])) || ''}
                placeholder="No min"
                onChange={(e) => {
                  handleBudgetMinChange(e);
                }}
                txtRef={minBudgetRef}></TextBox>
            </li>
            <li className="text-center"><span className="middle-separator">-</span></li>
            <li className="position-relative">
              <span className={ maxError? "dollar-sign-icon red-marked-dollar-sign": "dollar-sign-icon "}>$</span>
              <TextBox
                type="text"
                class={maxError ? "pad-left-25 red-marked-formcontrol" : "pad-left-25"}
                errorMessage={maxError}
                value={(thousandSeperatedValue(budgetRange) && thousandSeperatedValue(budgetRange[1])) || ''}
                placeholder="No max"
                onChange={(e) => {
                  handleBudgetMaxChange(e);
                }}
                txtRef={maxBudgetRef}
              ></TextBox>

            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default WantAdBudget;
