/**
 * @file   src\actions\payments.js
 * @brief  This file is responsible for redux actions related to payments
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPaymentMethods, updateDefaultPayment } from "../services/paymentsService";

 export const fetchPaymentMethods = createAsyncThunk(
    "paymentMethods/fetchPaymentMethods",
    (requestPayload) => {
      return getPaymentMethods(requestPayload);
    }
  );
  

