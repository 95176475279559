import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
// import TextBox from "../../c";
import { thousandSeperatedValue } from "../../helpers/common";

const MarkAsSoldStep3Modal = (props) => {
  const {show, onHide,inventoryId, handleInventoryMarkAsSold} = props;
  const [isGitchaHelped, setIsGitchaHelped] = useState(null);
  const [salesPrice, setSalesPrice] = useState(null);

  const re = /^[0-9, $]+$/;

  const handleChangeSalesPrice = (value) => {
    if (re.test(value)) {
      const newValue = thousandSeperatedValue(value);
      setSalesPrice(newValue ? "$"+newValue : "");
    }
  };
  return (
    <Modal show={show} {...props} onHide={onHide} centered className="modalPopupstyle delete-popup sold-popup">
        <Modal.Body className="text-center">
        <h3>Did Gitcha help with this transaction?</h3>
        <div className="questions-block">
            <div className="options">
            
                <a  onClick={() => setIsGitchaHelped(true)}  checked={isGitchaHelped === true} className={isGitchaHelped===true ? "blockBtn text-center active" : "blockBtn text-center"}>Yes</a>
                <a  checked={isGitchaHelped === false}
                      onClick={() => setIsGitchaHelped(false)}
                       className={isGitchaHelped === false ? "blockBtn text-center active" : "blockBtn text-center"} > No 
                       </a>
            </div>
            {isGitchaHelped && (

            <div className="price-info">
                <h3>What was the sales price of the transaction?</h3>
                <Form.Control type="text" placeholder="$0" 
                 id="sales-price"
                name="salesprice"
                onChange={(e) => handleChangeSalesPrice(e.target.value)}
                value={salesPrice} />
            </div>
            )}
        </div>
        <div className="btn-wrap">
            <div className="btn-block">
                <button type="button" className="btn btn-primary btn-skip w-100" onClick={()=>handleInventoryMarkAsSold(inventoryId, isGitchaHelped,salesPrice)}>Skip <i className="ic-arrow"></i></button>
                {isGitchaHelped !== null && (
                <button type="button" className="btn btn-primary w-100" onClick={()=>handleInventoryMarkAsSold(inventoryId, isGitchaHelped,salesPrice)}>Confirm</button>
                )}
            </div>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Back</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default MarkAsSoldStep3Modal;
