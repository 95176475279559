import React, { useState, useEffect } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
import ConsumerIcon from "../images/consumer-icon.svg";
import InventoryLocationMap from "../images/inventory-location-map.png";
import SchoolSmallIcon from "../images/school-icon.svg";
import NeighborhoodIcon from "../images/neighborhood-icon.svg";
import { getNiceToHaves } from "../services/commonServices";
import { convertToNumber } from "../helpers/validations";
import {
  INVENTORY_LAND,
  INVENTORY_LTR,
  INVENTORY_LTR_STR,
  INVENTORY_SELL,
  INVENTORY_SELL_LTR,
  INVENTORY_SELL_STR,
  INVENTORY_STR,
  IS_LAND,
  MEMBER_INVENTORY_EDIT_KEY,
  USER_INFO_KEY,
  INVENTORY_KEY,
  AGENT_INVENTORY_CREATE_KEY,
  AGENT_INVENTORY_EDIT_KEY,
  WANT_AD_KEY,
  USER_TYPE_AGENT,
  LONG_RENT,
  SELL,
  SHORT_RENT,
} from "../constants/common";
import {
  buildInventoryMustHaveHtml,
  checkInventoryType,
} from "../helpers/inventory";
import { convertToCurrencySystem, getUserType, loadPhotos, removeDecimalZeros } from "../helpers/common";
import { saveInventoryStrengthenData } from "../services/inventoryService";

import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../helpers/common";
import { useNavigate, useLocation } from "react-router-dom";
import { saveMemberInventoryData } from "../services/inventoryService";
import { pageURLs } from "../constants/pageURLs";
import PlaceHolderIcon from "../images/dpdown-placeholder-icon.svg";
import TimelineIcon from "../images/timeline-icon.svg";
import { deleteFile } from "../services/fileUploadService";
import { useRef } from "react";
import PhotoGallery from "../components/Common/PhotoGallery";
import { useSelector } from "react-redux";

const InventoryConfirmation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageTopRef = useRef(null);
  const inventoryId = location.state && location.state["inventoryId"];
  const sessionKey =
    location.state && location.state["inventoryId"]
      ? location.state["isAgentCreated"]
        ? AGENT_INVENTORY_EDIT_KEY
        : MEMBER_INVENTORY_EDIT_KEY
      : location.state["isAgentCreated"]
      ? AGENT_INVENTORY_CREATE_KEY
      : MEMBER_INVENTORY_EDIT_KEY;

  const [formValues, setFormValues] = useState(getLocalStorage(sessionKey));
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const proprtyPurpose = _.get(formValues, "proprtyPurpose", []);
  const sellAskingPrice = _.get(formValues, "sellAskngPrice", "");
  const LTRAskngPrce = _.get(formValues, "LTRAskngPrce", "");
  const STRAskngPrce = _.get(formValues, "STRAskngPrce", "");
  const noBedRm = _.get(formValues, "noBedRm", "");
  const noBathRm = _.get(formValues, "noBathRm", "");
  const timeLineTxt = _.get(formValues, "timeLineTxt", "") || _.get(formValues, "tlText", "");
  const sqFt = _.get(formValues, "sqFt", "");
  const stories =
    _.get(formValues, "stories", "") === 0 ||
    _.get(formValues, "stories", "") === "0"
      ? null
      : _.get(formValues, "stories", "");
  const grgeSpots =
    _.get(formValues, "grgeSpots", "") === 0 ||
    _.get(formValues, "grgeSpots", "") === "0"
      ? null
      : _.get(formValues, "grgeSpots", "");
  const lotSize =
    _.get(formValues, "lotSize", "") === 0 ||
    _.get(formValues, "lotSize", "") === "0"
      ? null
      : _.get(formValues, "lotSize", "");
  const description = _.get(formValues, "invtryDetails", "");
  // const salesPercnt = _.get(formValues, "salesPercnt", "");
  // const salesPercntLTR = _.get(formValues, "salesPercnt_LTR", "");
  // const salesPercntSTR = _.get(formValues, "salesPercnt_STR", "");
  const salesPercnt = removeDecimalZeros(
    _.get(formValues, "salesPercnt", "")
  );
  const salesPercntLTR = removeDecimalZeros(
    _.get(formValues, "salesPercnt_LTR", "")
  );
  const salesPercntSTR = removeDecimalZeros(
    _.get(formValues, "salesPercnt_STR", "")
  );
  const nice2Have = _.get(formValues, "nice2Have", []);
  const agent = _.get(formValues, "isAgentCreated", "");
  const isAgentAsSeller = _.get(formValues, "isAgentAsSeller", "");
  const userType = getUserType();
  const clientName =
    _.get(formValues, "clientName", "") ??
    _.get(formValues, "clientData.label", "");
  const isEdit = _.get(formValues, "inventoryId", "") ? true : false;

  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const invType = checkInventoryType(proprtyPurpose);
  const typeBasedOnProperty =
    propertyType === IS_LAND ? INVENTORY_LAND : invType;

  const [isPremiumUser , setIsPremiumUser] = useState(null);
  const userDetailsFromStore = useSelector((state)=>state.userProfile.userDetails)

  const isAgent = userType=== USER_TYPE_AGENT ? true : false;

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if(userDetailsFromStore[0]?.period!==1 && userType===2){
        setIsPremiumUser(true);
      }
      else{
        setIsPremiumUser(false);
      }
    } 
  }, [userDetailsFromStore]);

  useEffect(()=>{

    if(formValues== null){
      navigate(pageURLs.home);
    }
  },[formValues])

  let titleText = "";
  switch (invType) {
    case INVENTORY_SELL:
      titleText = " selling";
      break;
    case INVENTORY_LTR:
      titleText = " renting";
      break;
    case INVENTORY_STR:
      titleText = " renting";
      break;
    case INVENTORY_LTR_STR:
      titleText = " renting";
      break;
    case INVENTORY_SELL_LTR:
      titleText = " selling, renting";
      break;
    case INVENTORY_SELL_STR:
      titleText = " selling, renting";
      break;
    default:
      titleText = " selling, renting";
      break;
  }

  const loadRentingPricesHTML = () => {
    let rentingPrice;
    if (LTRAskngPrce && STRAskngPrce) {
      return (
        <li>
          <span className="musthave-label">Renting Price</span>
          <span className="musthave-value">
            {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}
          </span>
          <span className="musthave-value">
            {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}
          </span>
        </li>
      );
    } else if (LTRAskngPrce && !STRAskngPrce) {
      return (
        <li>
          <span className="musthave-label">Renting Price</span>
          <span className="musthave-value">
            {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}
          </span>
        </li>
      );
    } else if (STRAskngPrce && !LTRAskngPrce) {
      return (
        <li>
          <span className="musthave-label">Renting Price</span>
          <span className="musthave-value">
            {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}
          </span>
        </li>
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section
    getNiceToHaves(typeBasedOnProperty)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    const isDisable = false;
    // handleContinueButtonState(isDisable);
  }, []);

  const loadDetails = () => {
    return buildInventoryMustHaveHtml({
      typeBasedOnProperty: typeBasedOnProperty,
      formValues: formValues,
    });
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    const masterArray = _.get(niceToHavesList, section, []);
    if (selectedItems.length) {
      return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
    } else {
      return "";
    }
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      typeBasedOnProperty === INVENTORY_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    return finalList;
  };

  const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        const icon = _.get(masterItem[0], "Icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueKey}>
            <div className="nicetohaves-tag-icon">
              <img
                src={icon ? require("../images/" + icon) : PlaceHolderIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const handleCancelClick = () => {
    if (
      confirm("Are you sure you want to move before post inventory details")
    ) {
      removeLocalStorage(sessionKey);
      _.get(formValues, "Inv_Id", "")
        ? navigate(pageURLs.inventoryListing)
        : navigate(pageURLs.home);
    }
  };

  const handlePostClick = (isAddInventry) => {
    // to remove edit filed from session
    // handleFormValues({ isEdit: false });
    const isConfirmAll = _.get(formValues, "isConfirmAll", false);

    // if (currentStep == 9) {
    // const isAddInventry = true;
    saveMemberInventory(isAddInventry);
    // }
  };

  const saveMemberInventory = (isAddInventry) => {
    //////////////////// need to remove after edit want-ad flow completed (issue 169)
    // handleFormValues({ isEdit: false });

    //let updatedState ={}
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const userId = _.get(userDetails, "userInfo.userId", null);

    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(sessionKey);
    }

    requestPayLoad.cmpldStep = _.get(formValues, "actvStep", 1);
    if(isAgent && !isPremiumUser){
      requestPayLoad.isAgentAsSeller = true;
    }
    if (isAddInventry) {
      requestPayLoad.IsSavedDraft = false;
      requestPayLoad.IsPublished = true;
      delete requestPayLoad.tlText;
      delete requestPayLoad.prptyTypeTxt;
      delete requestPayLoad.step6Errors;
    } else {
      requestPayLoad.IsSavedDraft = true;
      requestPayLoad.IsPublished = false;
    }

    // requestPayLoad.photos =  _.get(formValues, "photos", []).map(({ id, ...rest }) => rest);

    const deleteRequest = {
      inventoryId: inventoryId,
      key: _.get(formValues, "deletedImages", []),
    };
    // const removeImage = deleteImages(deleteRequest);

    saveMemberInventoryData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);

        if (response.errorCode === 0 && responseResult) {
          if (isAddInventry) {
            if(!inventoryId){
               // for setting the match tab client data
                setLocalStorage("MatchSelectedClient",  
                {FirstName: requestPayLoad.clientName, 
                  LastName: requestPayLoad.clientName,
                  AgentAsSeller: requestPayLoad.isAgentCreated,
                  label: requestPayLoad.clientName,
                  value:  requestPayLoad.clientId
                });

                let propertyPurpose = _.get(requestPayLoad, "proprtyPurpose", []);

                setLocalStorage("selectedPropertyId", 
                {
                  Inv_Id: _.get(responseResult, "inventoryId", ""),
                  Location: requestPayLoad.locAddress,
                  City: requestPayLoad.city,
                  Is_LongTerm_Renter: _.includes(propertyPurpose, LONG_RENT),
                  Is_Seller: _.includes(propertyPurpose, SELL),
                  Is_ShortTerm_Renter: _.includes(propertyPurpose, SHORT_RENT),
                  State: requestPayLoad.state,
                  photo: requestPayLoad.photos && requestPayLoad.photos[0]

                });

            }
           
          }
          if (
            requestPayLoad.apprOnFile ||
            requestPayLoad.insReport ||
            requestPayLoad.sellerFin ||
            requestPayLoad.floorPlan ||
            requestPayLoad.floodCert ||
            requestPayLoad.tlText ||
            !requestPayLoad.apprOnFile ||
            !requestPayLoad.insReport ||
            !requestPayLoad.sellerFin ||
            !requestPayLoad.floorPlan ||
            !requestPayLoad.floodCert ||
            !requestPayLoad.tlText
          ) {
            saveStrengthenData(responseResult.inventoryId, isAddInventry);
          } else {
            removeLocalStorage(sessionKey);
            removeLocalStorage("PhotosAfterDelete")
            if (isAddInventry) {
              //updatedState.inventoryId = _.get(responseResult, "inventoryId", "");
              // updatedState.minBdgt = _.get(formValues, "minBdgt", "");
              // updatedState.maxBdgt = _.get(formValues, "maxBdgt", "");
              // setLocalStorage(INVENTORY_KEY, {
              //   inventoryId: _.get(responseResult, "inventoryId", ""),
              //   isAgentCreated: agent,
              //   showSuccessModal : true // This key-value is for showing the success pop up for the first time in details page after save
              // });

              if (_.get(formValues, "isFromIntroduction", "")) {
                setLocalStorage(WANT_AD_KEY, {
                  wantAdId: _.get(formValues, "wantAdId", ""),
                  isFromHomeFeed: true,
                });
                removeLocalStorage(sessionKey);
                navigate(pageURLs.wantAdDetails);
              } else {
                let clientName = _.get(formValues, "clientName", "")
                  ? _.get(formValues, "clientName", "")
                  : _.get(formValues, "clientData.label", "");
                let isAgentAsSeller = _.get(formValues, "isAgentAsSeller", "");
                removeLocalStorage(sessionKey);
                setLocalStorage(INVENTORY_KEY, {
                  inventoryId: _.get(responseResult, "inventoryId", ""),
                  isAgentCreated: agent,
                  showSuccessModal: true, // This key-value is for showing the success pop up for the first time in details page after save
                  clientName: clientName,
                  isAgentAsSeller: isAgentAsSeller,
                  isEdit: isEdit,
                });
                navigate(pageURLs.memberInventoryDetails);
              }

            } else {
              removeLocalStorage(sessionKey);
              removeLocalStorage("PhotosAfterDelete")
              navigate(pageURLs.inventoryListing, { showDraft: true })
              // navigate(pageURLs.agentDraftList);
              // navigate(pageURLs.memberInventoryDetails);
            }
          }
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const saveStrengthenData = async (InventoryId, isAddInventry) => {
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(sessionKey);
    }
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const userId = _.get(userDetails, "userInfo.userId", null);
    requestPayLoad.inventoryId = InventoryId;
    requestPayLoad.isStrengthen = true;
    await saveInventoryStrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          // setLocalStorage(INVENTORY_KEY, {
          //   inventoryId: _.get(responseResult, "inventoryId", ""),
          //   showSuccessModal: true // This key-value is for showing the success pop up for the first time in details page after save
          // });

          if (isAddInventry) {
            if (_.get(formValues, "isFromIntroduction", "")) {
              setLocalStorage(WANT_AD_KEY, {
                wantAdId: _.get(formValues, "wantAdId", ""),
                isFromHomeFeed: true,
              });
              removeLocalStorage(sessionKey);
              navigate(pageURLs.wantAdDetails);
            } else {
              let clientName = _.get(formValues, "clientName", "")
                ? _.get(formValues, "clientName", "")
                : _.get(formValues, "clientData.label", "");
              let isAgentAsSeller = _.get(formValues, "isAgentAsSeller", "");
              removeLocalStorage(sessionKey);
              removeLocalStorage("PhotosAfterDelete")
              setLocalStorage(INVENTORY_KEY, {
                inventoryId: _.get(responseResult, "inventoryId", ""),
                isAgentCreated: agent,
                showSuccessModal: true, // This key-value is for showing the success pop up for the first time in details page after save
                clientName: clientName,
                isAgentAsSeller: isAgentAsSeller,
                isEdit: isEdit,
              });
              // removeLocalStorage(sessionKey);
              navigate(pageURLs.memberInventoryDetails);
            }
          } else {
            removeLocalStorage(sessionKey);
            removeLocalStorage("PhotosAfterDelete")
            navigate(pageURLs.inventoryListing, { showDraft: true })
            // navigate(pageURLs.agentDraftList);
            // navigate(pageURLs.memberInventoryDetails);
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const deleteImages = async (deleteRequest) => {
    await deleteFile(deleteRequest)
      .then((response) => {
        if (response.errorCode === 0) {
          return true;
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const isNiceToHaveExist = nice2Have.length > 0;
  const photos = _.get(formValues, "photos", []);
  const highSchool = _.get(formValues, "highSchool", null);
  const neighborHood = _.get(formValues, "neighborHood", null);

  return (
    <>
      <div className="outer-wrap position-relative mw-1168" ref={pageTopRef}>
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">
            <div className="ready-to-post-wrap mw-724">
              <div className="ready-to-post-top d-sm-flex justify-content-between mt-3">
                <div>
                  {inventoryId ? (
                    <h2>Ready to save changes?</h2>
                  ) : (
                    <h2>Ready to add to inventory?</h2>
                  )}

                  {isPremiumUser && <>
                  {_.get(formValues, "isAgentCreated", "") == true &&
                    (_.get(formValues, "isAgentAsSeller", "") == "true" ||
                    _.get(formValues, "isAgentAsSeller", "") == 1 ? (
                      <h6>Represented by Agent</h6>
                    ) : (
                      <h6>
                        Client:{" "}
                        {_.get(formValues, "clientName", "")
                          ? _.get(formValues, "clientName", "")
                          : _.get(formValues, "clientData.label", "")}{" "}
                        <span>(Only visible to you)</span>
                      </h6>
                    ))}
                    </>}
                </div>
                <div className="d-block mt-3 mt-sm-0">
                  {inventoryId ? (
                    <button
                      className="btn block-link-btn me-2"
                      onClick={() => handleCancelClick()}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      className="btn block-link-btn me-2"
                      onClick={() => handlePostClick(false)}
                    >
                      Save Draft
                    </button>
                  )}

                  <button
                    className="btn btn-primary"
                    onClick={() => handlePostClick(true)}
                  >
                    {" "}
                    {inventoryId ? "Save" : "Confirm"}
                  </button>
                </div>
              </div>

              <div className="inventory-confirmation-wrap">
                <div className="confirmation-top-sec d-md-flex justify-content-between">
                  <div>
                    <div className="confirm-sub-title mb-1">
                      I'm open to:{" "}
                      <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                        {titleText}
                      </span>
                    </div>
                    <h2 className="long-txt-fix">
                      {_.get(formValues, "locAddress", "")}
                    </h2>
                    {/* <h6>Eugene, OR 99999</h6> */}
                  </div>

                  <button
                    type="button"
                    className="btn btn-outline-primary align-self-start"
                    onClick={() =>
                      _.get(formValues, "isAgentCreated", "")
                        ? navigate(pageURLs.agentCreateInventory, {
                            state: {
                              inventoryId: _.get(formValues, "inventoryId", ""),
                            },
                          })
                        : navigate(pageURLs.memberEditInventory)
                    }
                  >
                    Edit
                  </button>
                </div>
                <PhotoGallery photos={photos} isFromConfirmationPage={true}/>
                {/* {loadPhotos(photos)} */}
                
                {/* <div className="inventory-banner position-relative mt-30">
                  <div className="upload-cover-btn upload-cover-btn-pos">+4</div>
                  <div className="banner-row">
                    <div className="banner-column-half">
                      <div className="half-col-img-wrap">
                        <img
                          className="brdr-left-8 brdr-t-r-8 no-brder-b-l-0 brdr-top-left-8 brdr-btm-left-8"
                          src={InventoryImgFirst}
                        />
                      </div>
                    </div>
                    <div className="banner-column-quarter">
                      <div className="quarter-col-img-wrap-top">
                        <img src={InventoryImgFirst} />
                      </div>
                      <div className="quarter-col-img-wrap-btm">
                        <img src={InventoryImgFirst} />
                      </div>
                    </div>
                    <div className="banner-column-quarter">
                      <div className="quarter-col-img-wrap-top">
                        <img
                          className="brdr-top-right-8 no-brdr-t-r-0"
                          src={InventoryImgFirst}
                        />
                      </div>
                      <div className="quarter-col-img-wrap-btm">
                        <img
                          className="brdr-btm-right-8 brdr-b-l-8"
                          src={InventoryImgFirst}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="musthave-wrap">
                  <h2>Details</h2>
                  {loadDetails()}
                </div>

                <div className="Inventory-Location-wrap pb-32 ">
                  <h2>Location</h2>
                  <div className="Inventory-Location-map-wrap mb-3 h-264">
                    <img
                      src={
                        _.get(formValues, "mapImg", "") + "?" + Math.random().toString(36)  ?? InventoryLocationMap + "?" + Math.random().toString(36)
                      }
                    />
                  </div>
                  <ul>
                    {highSchool && (
                      <li>
                        <span className="Inventory-icn-wrap">
                          <img src={SchoolSmallIcon} />
                        </span>
                        {highSchool}
                      </li>
                    )}
                    {neighborHood && (
                      <li>
                        <span className="Inventory-icn-wrap">
                          <img src={NeighborhoodIcon} />
                        </span>
                        {neighborHood}
                      </li>
                    )}
                  </ul>
                </div>
                {isNiceToHaveExist ? (
                  <div className="nicetohaves-wrap">
                    <h2>Features</h2>
                    <div className="nicetohaves-inner-wrap">
                      {finalNiceToHaveList()}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {description && (
                  <div className="additional-details-wrap">
                    <h2>Description</h2>
                    <p className="long-txt-fix">{description}</p>
                  </div>
                )}
                <div className="buyer-info">
                  {/* <h2>
                    {" "}
                    {!agent ? "Additional Information" : "Owner Information"}
                  </h2> */}
                 <h2>Additional Details</h2>
                  <ul>
                    <li>
                      <span className="list-icn-wrap flex-shrink-1">
                        <img src={ConsumerIcon} />
                      </span>

                      <span className="list-icn-text">
                        {!agent ? "Property Owner" : "Represented by Agent"}
                      </span>
                    </li>
                    {salesPercnt && (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ConsumerIcon} />
                  </span>
                  {!agent
                        ? "Seller is offering " +
                          salesPercnt +
                          "% to buyer's agent"
                        : salesPercnt + "% compensation to buyers agent"}
                </li>
              )}
              {salesPercntLTR && (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ConsumerIcon} />
                  </span>
                  {!agent
                        ? "Long-term Renter is offering " +
                          salesPercntLTR +
                          "% to renter's agent"
                        : salesPercntLTR + "% compensation to renter's agent"}
                </li>
              )}
              {salesPercntSTR && (
                <li>
                  <span className="list-icn-wrap">
                    <img src={ConsumerIcon} />
                  </span>
                  {!agent
                        ? "Short-term Renter is offering " +
                          salesPercntSTR +
                          "% to renter's agent"
                        : salesPercntSTR + "% compensation to renter's agent"}
                </li>
              )}

                  {timeLineTxt && (
                    <li>
                      <span className="list-icn-wrap">
                        <img src={TimelineIcon} />
                      </span>
                      Timeline: {timeLineTxt}
                    </li>
                  )}
                    {_.get(formValues, "apprOnFile", false) ? (
                      <li>
                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        Appraisal on file
                      </li>
                    ) : (
                      ""
                    )}

                    {_.get(formValues, "insReport", false) ? (
                      <li>
                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        Inspection report on file
                      </li>
                    ) : (
                      ""
                    )}
                    {_.get(formValues, "sellerFin", false) ? (
                      <li>
                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        Seller finance available
                      </li>
                    ) : (
                      ""
                    )}

                    {_.get(formValues, "floorPlan", false) ? (
                      <li>
                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        Floor plan on file
                      </li>
                    ) : (
                      ""
                    )}
                    {_.get(formValues, "floodCert", false) ? (
                      <li>
                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        Flood certificate available
                      </li>
                    ) : (
                      ""
                    )}
                     {_.get(formValues, "assMortgage", false) ? (
                    <li>
                      <span className="list-icn-wrap">
                        <img src={ConsumerIcon} />
                      </span>
                      Assumable mortgage
                    </li>
                  ) : (
                    ""
                  )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryConfirmation;
