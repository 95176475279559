//   export const baseURL = 'http://192.168.1.2:5200/'
// export const baseURL = 'http://localhost:5200/'
export const baseURL = (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : `https://gitcha-api.zcodemo.com/`
// export const baseURL = 'http://192.168.1.2:5200/'

//export const baseURL = 'http://192.168.1.4:5200/'

//  export const baseURL = ' http://192.168.1.2:5200/'
//export const baseURL = 'http://localhost:5200/'
// export const baseURL = 'https://gitcha-api.zcodemo.com/'
//export const baseURL = 'https://staging-api.gitcha.com/'
// export const baseURL = 'https://api.gitcha.com/'
// export const baseURL = 'https://sec-api.gitcha.com/'

export const siteBaseURL = (process.env.REACT_APP_SITE_BASE_URL) ? process.env.REACT_APP_SITE_BASE_URL : `https://gitcha-app.zcodemo.com/`
// export const siteBaseURL = 'https://gitcha-app.zcodemo.com/'
 //export const siteBaseURL = 'https://staging.gitcha.com/'
//export const siteBaseURL = 'https://app.gitcha.com/'
// export const siteBaseURL = 'https://sec-web.gitcha.com/'

export const marketingSiteURL = "https://www.gitcha.com/"
export const GET_WANT_ADS = baseURL + 'api/wantad/getWantAdListByFilters'
export const GET_GOOGLE_USER_INFO = 'https://www.googleapis.com/oauth2/v3/userinfo'
export const LOGIN = baseURL + 'api/auth/login'
export const GET_USER_IP = 'https://ipapi.co/json/'//'https://geolocation-db.com/json/'
export const VERIFY_EMAIL = baseURL + 'api/auth/verifyEmail';
export const CHANGE_EMAIL_ADDRESS = baseURL + 'api/auth/changeEmailAddress';
export const SIGNUP = baseURL + 'api/auth/signup';
export const REFRESH_TOKEN = baseURL + 'api/auth/refreshToken';
export const VERIFY_USER = baseURL + 'api/auth/verifyUser';
export const VERIFY_USER_ACTION = baseURL + 'api/auth/verifiyUserAction';
export const GET_USER_DETAILS = baseURL + 'api/user/getUserAccountDetails';
export const CHANGE_USER_NAME = baseURL + 'api/user/changeUserName';
export const DELETE_USER = baseURL + 'api/user/deleteUser';
export const RESEND_EMAIL = baseURL + 'api/auth/resendEmail';
export const GET_PLAN_TO_BUY = baseURL + 'api/master/getTimelinesByType';
export const GET_PROPERTY_TYPE = baseURL + 'api/master/getPropertyTypes';
export const SAVE_GOOGLE_LOGIN_USER = baseURL + 'api/auth/googleLogin';
export const GET_NICE_TO_HAVES = baseURL + 'api/master/getNiceToHave';
export const SAVE_MEMBER_WANT_AD = baseURL +'api/wantad/saveWantAd';
export const SAVE_MEMBER_WANT_AD_STRENGTHN = baseURL +'api/wantad/saveStrengthenWantAd';
export const GET_PURCHASE_TYPE = baseURL + 'api/master/getPurchaseTypes';
export const GET_FINANCE_TYPE = baseURL + 'api/master/getAllFinanceTypes';
export const GET_WANT_ID_BY_ID = baseURL + 'api/wantad/getWantAdById';
export const GET_WANT_AD_DETAILS_BY_ID = baseURL + 'api/wantad/getWantAdDetailsById';
export const SHARE_WANTAD = baseURL + 'api/sharead/share-wantad';
export const GET_MY_DRAFT = baseURL + 'api/wantad/getMyDraftWantAdList';
export const GET_CREDIT_SCORE = baseURL + 'api/master/getCreditScoreDetails';
export const GET_MY_WANT_ADS = baseURL + 'api/wantad/getMyWantAdList';
export const SAVE_AGENT_INFO = baseURL +'api/user/saveAgentDetails'
export const GET_CLIENTS_LISTING =  baseURL + 'api/client/getAgentClientList';
export const GET_AGENT_DASHBOARD = baseURL +'api/client/getAgentDashboard'
export const GET_AGENT_DASHBOARD_WANT_AD_LIST = baseURL +'api/wantad/getAgentDashboardWantAdList';
export const GET_AGENT_DASHBOARD_INVENTORY_LIST = baseURL +'api/inventory/getAgentDashboardInventoryList';
export const SAVE_AGENT_CLIENT = baseURL +'api/client/saveAgentClient';
export const GET_AGENTS_LIST = baseURL +'api/client/getClientList';
export const SAVE_AGENT_PICTURE = baseURL +'api/user/profileImageUpload';
export const DELETE_WANTAD = baseURL+ 'api/wantad/deleteMyWantAd';
export const ARCHIVE_WANTAD = baseURL + 'api/wantad/archiveMyWantAd';
export const UNARCHIVE_WANTAD = baseURL + 'api/wantad/unarchiveMyWantAd';
export const FAVORITE_WANTAD = baseURL + 'api/favourite/favouriteWantAd';
export const FAVORITE_WANTAD_LIST= baseURL + 'api/favourite/getFavWantAdList';
export const UNFAVORITE_WANTAD = baseURL + 'api/favourite/unfavouriteWantAd';
export const FAVOURITE_CLIENTS = baseURL + 'api/favourite/getFavClientList';
export const SAVE_INVENTORY = baseURL + 'api/inventory/saveInventory';	
export const UNFAVORITE_ALL_WANTAD = baseURL + 'api/favourite/unfavouriteAllWantAd';
export const GET_INVENTORY_BY_ID = baseURL + 'api/inventory/getInventoryById';
export const GET_INVENTORY_MY_DRAFTS = baseURL + 'api/inventory/getMyDraftInventoryList';
export const SAVE_STRENGTHEN_INVENTORY = baseURL + 'api/inventory/saveStrengthenInventory';
export const INVENTORY_DOCUMENTS = baseURL + 'api/master/getAboutPropertyDetails';
export const MY_INVENTORY_LISTING = baseURL + 'api/inventory/getMyInventoryList';
export const DELETE_INVENTORY = baseURL + 'api/inventory/deleteMyInventory';
export const INVENTORY_MARK_AS_SOLD = baseURL + 'api/inventory/markAsSoldInventory';
export const GET_S3_DETAILS = baseURL + 'api/master/getS3Details';
export const GET_PRESIGNED_URLS = baseURL+ 'api/inventory/generatePresignedUrls'
export const GET_PRESIGNED_URL= baseURL+ 'api/user/generatePresignedUrl'
export const SAVE_NOTIFICATION_SETTINGS = baseURL + 'api/notification/saveNotificaionSettings';
export const GET_NOTIFICATION_SETTINGS = baseURL + 'api/notification/getNotificationSettings';
export const GET_CLIENT_DETAILS = baseURL + 'api/client/getClientDashboard';
export const PROPERTY_LIST = baseURL + 'api/introduction/getMyPropertyList';
export const SAVE_PAYMENT_DETAILS = baseURL + 'api/payments/savePaymentMethod';
export const GET_PAYMENT_METHODS = baseURL + 'api/payments/getPaymentMethodList';
export const REMOVE_PAYMENT_METHOD = baseURL + 'api/payments/removePaymentMethod';
export const UPDATE_DEFAULT_PAYMENT = baseURL + 'api/payments/updateDefaultPaymentMethod';
export const SAVE_INTRODUCTIONS = baseURL + 'api/introduction/saveIntroductions';
export const GET_CLIENT_INFO = baseURL + 'api/client/getClientDetailsByClientId';
export const DELETE_CLIENT = baseURL+ 'api/client/deleteClientByClientId';
export const SAVE_CLIENT_NOTE = baseURL+ 'api/client/saveClientNote';
export const GET_CLIENT_NOTES = baseURL+ 'api/client/getClientNoteList';
export const DELETE_CLIENT_NOTE = baseURL+ 'api/client/deleteClientNote';
export const GET_INTRO_CLIENT_LIST = baseURL + 'api/introduction/getIntroClientList';
export const DELETE_S3_FILES = baseURL + 'api/inventory/deleteS3Files';
export const GET_DASHBOARD_INTRODUCTION_LIST = baseURL + 'api/introduction/getReceiveIntroductionList'
export const UPDATE_INTRO_STATUS = baseURL + 'api/introduction/saveIntroductionReadStaus'
export const GET_INTRODUCTION_DETAILS = baseURL + 'api/introduction/getIntroductionDetailsByIntroId'
export const GET_ACCEPTED_INTRODUCTION_LIST = baseURL + 'api/introduction/getAcceptedReceiveIntroList';
export const PENDING_INTRODUCTIONS_LIST = baseURL + 'api/introduction/getMyPendingIntroductionByInvId';
export const ACCEPTED_INTRODUCTIONS_LIST = baseURL + 'api/introduction/getMyAcceptedIntroductionByInvId';
export const CANCEL_INTRODUCTION = baseURL + 'api/introduction/cancelMyIntroduction';
export const UPDATE_INTRODUCTION_STATUS = baseURL + 'api/introduction/updateIntroductionStatus';
export const UNDO_DECLINED_INTRODUCTION = baseURL +'api/introduction/updateIntroDeclineUndo'
export const CREATE_SUBSCIPTION_PLAN = baseURL + 'api/payments/createSubscriptionPlan';
export const INTRODUCTION_WANTAD_DETAILS = baseURL + 'api/introduction/getIntroductionWantAdDetailsByIntroId';
export const UPDATE_INTRODUCTION_NOT_A_FIT = baseURL + 'api/introduction/updateIntroNotFitStatus';
export const EDIT_PREMIUM_AGENT_PROFILE = baseURL + 'api/user/editPremiumAgentProfile';
export const GET_INTRODUCTION_INVENTORY_DETAILS = baseURL + 'api/introduction/getIntroductionInventorydDetailsByIntroId';
export const GET_GEOLOCATION = 'https://maps.googleapis.com/maps/api/geocode/json';
export const GET_MAP_STATIC_IMAGE = 'https://maps.googleapis.com/maps/api/staticmap';
export const UPDATE_INTRO_REPLY_STATUS = baseURL +  'api/introduction/updateIntroReplyStatus';
export const GET_INTRODUCTION_MESSAGE_DETAILS  = baseURL +  'api/introduction/getIntroMessageDetailsByUserId';
export const GET_CLIENT_NOTES_FOR_EXPORT = baseURL + 'api/client/getClientNoteListForExport';
export const GET_INVENTORY_LIST_BY_USER_ID = baseURL + 'api/inventory/getInventoryListByUserId';
export const GET_FOLDER_NAME = baseURL + 'api/master/getUniqueFolderName';
export const GET_GITCHA_GENIE_MATCHED_WANTADS = baseURL + 'api/wantad/getGitchaGenieMatchedWantAds';
export const DELETE_S3_FILE = baseURL + 'api/inventory/deleteS3File';
export const WANT_AD_LOCATION_AUTO_COMPLETE = 'https://api.realestateapi.com/v2/AutoComplete'
export const GET_WANT_AD_REPORTING_REASONS = baseURL + 'api/master/getWantAdReportingReasons';
export const REPORT_WANT_AD = baseURL + 'api/wantad/reportWantAd';
export const GET_INTRO_USER_REPORTING_REASONS = baseURL+ 'api/master/getIntroUserReportingReasons';
export const REPORT_INTRODUCTION_USER = baseURL + 'api/introduction/reportIntroductionUser';
export const GET_CANCEL_PLAN_REASONS = baseURL + 'api/master/getCancelPlanReasons';
export const GET_PAYMENT_HISTORY = baseURL + 'api/payments/getPaymentHistory';
export const DOWNGRADE_PLAN = baseURL + 'api/payments/downgradePlan';
export const GET_USER_PRIVILEGES = baseURL + 'api/user/getUserPrevilagesByPlanId';
export const GET_USER_STATISTICS = baseURL + 'api/user/getUserStatistics';
export const WANT_AD_LOT_SIZE_LIST_API = baseURL + 'api/master/getWantAdLotSizeList';
export const WANT_AD_SQUARE_FEET_LIST_API = baseURL + 'api/master/getWantSquareFeetList';
export const PROPERTY_SEARCH_API = baseURL + 'api/realestate/getPropertySearch';
export const PROPERTY_DETAILS_API = baseURL + 'api/realestate/getPropertyDetails';
export const RADIUS_LIST_API = baseURL + 'api/master/getRadiusList';
export const GITCHA_AVAILABLE_CHECK = baseURL + 'api/wantad/getGitchaAvailable';
export const SAVE_ZIP_CODES = baseURL + 'api/wantad/getGitchaAvailableRequest';
export const GET_PROMO_CODE_DETAILS = baseURL + "api/payments/getPromoCodeDetails";
export const USER_SIGN_UP = baseURL + "api/auth/signon";
export const USER_LOGIN = baseURL + "api/auth/logon";
export const CREATE_PLAN_ON_SIGNUP = baseURL + "api/payments/createSubscriptionPlanOnSignup";
export const GET_AGENT_DETAILS = baseURL + "api/user/getAgentDetails";
export const RESET_PASSWORD_EMAIL = baseURL + "api/auth/resetPassword";
export const UPDATE_PASSWORD = baseURL + "api/auth/updatePassword";
export const VERIFY_USER_EMAIL = baseURL + "api/auth/verifyEmailAction";
export const INAPP_NOTIFICATIONS = baseURL + "api/notification/getNotifications";
export const INAPP_NOTIFICATIONS_DELETE_SATUS_UPDATE = baseURL + "api/notification/deleteNotifications";
export const INAPP_NOTIFICATIONS_READSTATUS_UPDATE = baseURL +"api/notification/updateReadStatusNotifications";
export const FETCH_TUTORIAL_STATUS = baseURL + "api/tutorial/getTutorialSettings";
export const UPDATE_TUTORIAL_STATUS = baseURL + "api/tutorial/saveTutorialSettings";
export const SAVE_GITCHA_AVAILABLE_LOCATION_REQUEST = baseURL + "api/wantad/saveGitchaAvailableLocationRequest";
export const GET_GITCHA_AVAILABLE_CITIES = baseURL + "api/wantad/getGitchaAvailableCities";
export const GET_GITCHA_AVAILABLE_STATES = baseURL + "api/wantad/getGitchaAvailableState";
export const GET_USER_INVENTORY_COUNT = baseURL + "api/inventory/getInventoryCountByUserId";
export const SAVE_USERTYPE_AND_TITLE_API = baseURL + "api/user/saveUserTypeAndTitle";
export const SAVE_GITCHA_CONTACT_US = baseURL + "api/user/saveGitchaWebContactUs";
export const CHAT_EMAIL= baseURL+ 'api/notification/chatMail';
export const GET_CLIENT_NOTES_FOR_PDF_EXPORT = baseURL+ 'api/client/getClientNoteListPdfExport';
export const GET_ACTIVE_PROMOCODE_DETAILS = baseURL + 'api/payments/getActivePromoCodeDetails';
export const GET_CLIENT_DETAILS_MOBILE = baseURL + 'api/client/getClientDashboardMob';
export const SAVE_NEED_AGENT_CONTACT_INFO = baseURL + 'api/user/saveNeedAnAgentContactInfo';
export const GET_MATCH_CLIENT_LIST = baseURL+'api/client/getMatchTabClientList';
export const GET_BOUNDARY_COORDINATES = baseURL+'api/wantad/getMultipleZipCodes';
export const GET_BUYERLIST_WANTAD_BY_ID = baseURL+"api/wantad/getBuyerListWantAdDetailsById";
export const SAVE_BUYER_LISTING_WANTAD = baseURL+"api/wantad/saveBuyerListWantAd";
export const SAVE_BUYER_LIST_AGENT_CLIENT = baseURL+"api/client/saveBuyerListAgentClient";
export const SAVE_BUYER_LIST_STRENGTHEN_WANTAD = baseURL+"api/wantad/saveBuyerListStrengthenWantAd";
export const GET_BUYERLIST_UNIQUE_FOLDERNAME = baseURL+'api/master/getBuyerListUniqueFolderName';
export const GET_INVESTMENT_CRITERIALIST = baseURL+'api/master/getInvestmentCriteriaList';
export const GET_INVESTMENT_CRITERIA_CAPLIST = baseURL+'api/master/getInvestmentCriteriaCapList';
export const GET_USER_PLAN_DETAILS = baseURL+'api/user/getUserPlanDetails';
export const GET_PREVIOUS_PLAN_BALANCE_AMOUNT = baseURL+'api/payments/getSubscriptionRemainingAmount';
export const SAVE_PRELAUNCH_INTEREST = baseURL+'api/user/savePreLaunchInterest';
export const GET_SHARE_INTRODUCTION_DETAILS = baseURL +'api/introduction/getShareIntroductionInventorydDetailsByIntroUniqueId';
export const CREATE_SHARE_LINK= baseURL + 'api/introduction/updateIntroShareableLink';
