/**
 * @file   src\components\AgentWantAd\WantAdLandMustHaves.js
 * @brief  This file is responsible for handling must haves section in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import RadioButton from "../Common/RadioButton";
import SelectDropDown from "../Common/SelectDropDown";
import {
  getLotSizeLists,
  filterArrayBasedOnKey,
} from "../../helpers/common";
import {
  MAX_LT_DROPDOWN,
  MIN_LT_DROPDOWN,
  NO_MAX_TEXT,
  NO_MIN_TEXT
} from "../../constants/common";
import WantAdLotSize from "../Common/WantAdLotSize";

const WantAdLandMustHaves = (props) => {
  const {
    handleFormValues,
    lotType,
    roadType,
    isPrivtDrilInstld,
    onSiteElec,
    waterSupply,
    isDrldWelInstld,
    wasteWtrSys,
    // mnLtSzId,
    // mnLtSzTxt,
    // mxLtSzId,
    // mxLtSzTxt,
    isSepTankInstld,
    isSepTankApprvd,
    mustHaveErrors,
    minLtSz,
    maxLtSz,
    propertyType
  } = props;


  const [roadTypePrivteInstalled, setRoadTypePrivteInstalled] = useState(false);
  const [waterSupplyDrilledWell, setWaterSupplyDrilledWell] = useState(false);
  const [septicTankInstalled, setSepticTankInstalled] = useState(false);
  // const [lotSizeMinError, setLotSizeMinError] = useState("");
  // const [lotSizeMaxError, setLotSizeMaxError] = useState("");
  // const [lotSizeList, setLotSizeList] = useState([]);


  useEffect(() => {
    roadType && handleRoadTypePrivteInstalled(roadType);
    waterSupply && handleWaterSupplyDrilledWellInstalled(waterSupply);
    wasteWtrSys && handleSepticTankInstalled(wasteWtrSys);
  
    if (!onSiteElec != "" && onSiteElec == 0) handleFormValues({ onSiteElec: "false" });
    if (!onSiteElec != "" && onSiteElec == 1) handleFormValues({ onSiteElec: "true" });

    // getLotSizeLists().then((list) => {
    //   setLotSizeList(list);
    // });

  }, []);


  const handleRoadTypePrivteInstalled = (rt) => {
    setRoadTypePrivteInstalled(false);
    if (rt == "2") {
      setRoadTypePrivteInstalled(true);
    } else {
      handleFormValues({ isPrvtDrInst: "", focusOut: true });
    }
  };

  const handleRoadTypeSelection = (selectedValue, selectedText) => {
    if (selectedValue == roadType) {
      handleFormValues({
        roadType: "",
        rdTypText: "",
        isPrvtDrInst: "",
        focusOut: true
      });
      setRoadTypePrivteInstalled(false);
    } else {
      handleRoadTypePrivteInstalled(selectedValue);
      handleFormValues({ roadType: selectedValue, rdTypText: selectedText, focusOut: true });
    }
  };

  const handleWaterSupplyDrilledWellInstalled = (ws) => {
    setWaterSupplyDrilledWell(false);
    if (ws == "2") {
      setWaterSupplyDrilledWell(true);
    } else {
      handleFormValues({ isDrldWelInst: "" , focusOut: true});
    }
  };
  const handleWaterSupplySelection = (selectedValue, selectedText) => {
    if (selectedValue == waterSupply) {
      handleFormValues({
        waterSupply: "",
        wSText: "",
        isDrldWelInst: "",
        focusOut: true
      });
      setWaterSupplyDrilledWell(false);
    } else {
      handleWaterSupplyDrilledWellInstalled(selectedValue);
      handleFormValues({ waterSupply: selectedValue, wSText: selectedText, focusOut: true });
    }
  };

  const handleSepticTankInstalled = (rt) => {
    setSepticTankInstalled(false);
    if (rt == "2") {
      setSepticTankInstalled(true);
    } else {
      handleFormValues({ isSepTankInst: "", isSepTankAppr: "", focusOut: true });
    }
  };

  const handleWasteWaterSystemSelection = (selectedValue, selectedText) => {
    if (selectedValue == wasteWtrSys) {
      handleFormValues({
        wasteWtrSys: "",
        wWSysText: "",
        isSepTankInst: "",
        isSepTankAppr: "",
        focusOut: true
      });
      setSepticTankInstalled(false);
    } else {
      handleSepticTankInstalled(selectedValue);
      handleFormValues({ wasteWtrSys: selectedValue, wWSysText: selectedText, focusOut: true });
    }
  };

  const handleElectricityOnsiteSelection = (e) => {
    const selection = e.target.value;
    if (onSiteElec == selection) {
      handleFormValues({ onSiteElec: "", focusOut: true });
    } else {
      handleFormValues({ onSiteElec: selection, focusOut: true });
    }

  };

  const handleLotTypeSelection = (selectedValue, selectedText) => {
    if (selectedValue == lotType) {
      handleFormValues({
        lotType: "",
        lotTypeText: "",
        focusOut: true
      });
    } else {
      handleFormValues({ lotType: selectedValue, lotTypeText: selectedText,focusOut: true });
    }
  };

  const handlePrivateDriveInstall = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isPrvtDrInst: selection,focusOut: true });
  };

  const handleSepticTankInstalledStatus = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isSepTankInst: selection, focusOut: true });
  };

  const handleSepticTankApprovedStatus = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isSepTankAppr: selection, focusOut: true });
  };

  const handleDrilledWellInstall = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isDrldWelInst: selection, focusOut: true });
  };

  // const handleLotSizeSelection = (e, dropSection) => {
  //   setLotSizeMaxError("")
  //   const values = _.get(e, "value", "").split("_");
  //   const selectedId = values?.[0];
  //   const valueText = values?.[1];
  //   const displayText = valueText > 1 ? "acres" : "acre";

  //   if (dropSection === MIN_LT_DROPDOWN) {
  //     if (selectedId === mnLtSzId && valueText === mnLtSzTxt) {
  //       handleFormValues({
  //         mnLtSzId: "",
  //         mnLtSzTxt: "",
  //       }); // Handle clear selection
  //     } else {
  //       handleFormValues({ mnLtSzId: selectedId, mnLtSzTxt: valueText });
  //     }
  //   } else {
  //     if (selectedId === mxLtSzId && valueText === mxLtSzTxt) {
  //       handleFormValues({
  //         mxLtSzId: "",
  //         mxLtSzTxt: "",
  //         mxLtSzDisplay :""
  //       }); // Handle clear selection
  //     } else {
  //       handleFormValues({
  //         mxLtSzId: selectedId,
  //         mxLtSzTxt: valueText,
  //         mxLtSzDisplay: displayText,
  //       });
  //     }
  //   }
  // };


  // useEffect(() => {
  //   if (mustHaveErrors && mustHaveErrors.length > 0) {
  //     mustHaveErrors.map((item) => {
       
  //       if (item === 'lotSizeMaxLesser') {
  //         setLotSizeMaxError('Value Must be greater than minimum');
  //       }
  //     })
  //   }

  // }, [mustHaveErrors]);
  
  // const minLotSizeList = filterArrayBasedOnKey({list: lotSizeList, filterKey: 'label', value: NO_MAX_TEXT, isNotEqual: true})
  // const maxLotSizeList = filterArrayBasedOnKey({list: lotSizeList, filterKey: 'label', value: NO_MIN_TEXT, isNotEqual: true})

  return (
    <>
      <div className="wantad-timing-wrap">
      <WantAdLotSize
          handleFormValues={handleFormValues}
          mnLotSze={minLtSz}
          mxLotSze={maxLtSz}
          lotSizeError={mustHaveErrors}
          isFromLongForm={true}
          propertyType={propertyType}
          />       
      </div>

      <div className="wantad-timing-wrap">
        <div className="land-radio-wrap">
          <h6>Lot Type </h6>
          <div className="row">
            <div className="col-md-6">
              <label className='radio-wrap' >
                <RadioButton
                  name="lt-radio"
                  value="1"
                  checked={lotType == "1" ? true : false}
                  onClick={() => handleLotTypeSelection("1", "Rural")} />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Rural</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className='radio-wrap'>
                <RadioButton
                  name="lt-radio"
                  value="2"
                  checked={lotType == "2" ? true : false}
                  onClick={() => handleLotTypeSelection("2", "In city limits")} />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">In city limits</h2>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="land-radio-wrap">
          <h6>Road Type</h6>
          <div className="row">
            <div className="col-md-6">
              <label className= 'radio-wrap'>
                <input
                  type="radio"
                  name="rt-radio"
                  value="1"
                  checked={roadType == "1" ? true : false}
                  onClick={() => handleRoadTypeSelection("1", "City roads")}
                  onChange={() => { }}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">City roads</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className= 'radio-wrap' >
                <input
                  type="radio"
                  name="rt-radio"
                  value="2"
                  checked={roadType == "2" ? true : false}
                  onClick={() => handleRoadTypeSelection("2", "Private drive")}
                  onChange={() => { }}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Private drive</h2>
                  </div>
                </span>
              </label>
              {roadTypePrivteInstalled && (
                <label className='check-wrap mt-3'>
                  <input
                    type="checkbox"
                    name="pd-checkbox"
                    onChange={handlePrivateDriveInstall}
                    checked={isPrivtDrilInstld}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0">Installed</h2>
                    </div>
                  </span>
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="land-radio-wrap">
          <h6>Electricity On-Site</h6>
          <div className="row">
            <div className="col-md-6">
              <label className='radio-wrap'>
                <input
                  type="radio"
                  name="eos-radio"
                  value={true}
                  onClick={handleElectricityOnsiteSelection}
                  onChange={() => { }}
                  checked={onSiteElec == "true"}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Yes</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className='radio-wrap'>
                <input
                  type="radio"
                  name="eos-radio"
                  value={false}
                  onClick={handleElectricityOnsiteSelection}
                  onChange={() => { }}
                  checked={onSiteElec == "false"}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">No</h2>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="land-radio-wrap">
          <h6>Water Supply</h6>
          <div className="row">
            <div className="col-md-6">
              <label className='radio-wrap'>
                <input
                  type="radio"
                  name="ws-radio"
                  value="1"
                  checked={waterSupply == "1" ? true : false}
                  onClick={() =>
                    handleWaterSupplySelection("1", "Domestic water supply")
                  }
                  onChange={() => { }}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Domestic water supply</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className='radio-wrap'>
                <input
                  type="radio"
                  name="ws-radio"
                  value="2"
                  checked={waterSupply == "2" ? true : false}
                  onClick={() =>
                    handleWaterSupplySelection("2", "Drilled well")
                  }
                  onChange={() => { }}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Drilled well</h2>
                  </div>
                </span>
              </label>
              {waterSupplyDrilledWell && (
                <label className="check-wrap mt-3">
                  <input
                    type="checkbox"
                    name="dw-checkbox"
                    onChange={handleDrilledWellInstall}
                    checked={isDrldWelInstld}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0">Installed</h2>
                    </div>
                  </span>
                </label>
              )}
            </div>
          </div>
        </div>

        <div className="land-radio-wrap">
          <h6>Wastewater System</h6>
          <div className="row">
            <div className="col-md-6">
              <label className= 'radio-wrap' >
                <input
                  type="radio"
                  name="wws-radio"
                  value="1"
                  checked={wasteWtrSys == "1" ? true : false}
                  onClick={() => handleWasteWaterSystemSelection("1", "Sewer")}
                  onChange={() => { }}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Sewer</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className='radio-wrap'>
                <input
                  type="radio"
                  name="wws-radio"
                  value="2"
                  checked={wasteWtrSys == "2" ? true : false}
                  onClick={() =>
                    handleWasteWaterSystemSelection("2", "Septic system")
                  }
                  onChange={() => { }}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Septic system</h2>
                  </div>
                </span>
              </label>
              {septicTankInstalled && (
                <>
                  <label className='check-wrap mt-3'>
                    <input
                      type="checkbox"
                      name="st-checkbox"
                      onChange={handleSepticTankInstalledStatus}
                      checked={isSepTankInstld}
                    />
                    <span>
                      <div className="timing-wrap-info">
                        <h2 className="pb-0">Installed</h2>
                      </div>
                    </span>
                  </label>
                  <label className='check-wrap mt-3'>
                    <input
                      type="checkbox"
                      name="st-checkbox"
                      onChange={handleSepticTankApprovedStatus}
                      checked={isSepTankApprvd}
                    />
                    <span>
                      <div className="timing-wrap-info">
                        <h2 className="pb-0">Approved</h2>
                      </div>
                    </span>
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WantAdLandMustHaves;
