import React from "react";
import _ from "lodash";
import { filterArray, getpropertyName, removeDecimalZeros, thousandSeperatedValue } from "../../helpers/common";
import { IS_LAND, LOT_TYPES } from "../../constants/common";
import noImage from "../../images/no-image-small.png";
import BedIcon from "../../images/bed-icon.svg";
import BathIcon from "../../images/bath-icon.svg";
import SqftIcon from "../../images/sqft-icon.svg";

const IntroductionDetail = (props) => {
  const { introDetails } = props;

  const bedRoom = removeDecimalZeros(_.get(introDetails, "Bedrooms", ""));
  const bathRoom = removeDecimalZeros(_.get(introDetails, "Bathrooms", ""));
  const sqFt = removeDecimalZeros(_.get(introDetails, "Sqft", ""));
  const acreage = removeDecimalZeros(_.get(introDetails, "TotalAcre", ""));
  let lotSizeUnit = _.get(introDetails, "Acre_Sqft", "");
  if (lotSizeUnit && lotSizeUnit !== 0) {
    lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft";
  }


  const isLand = _.get(introDetails, "PropertyTypeId", "") === IS_LAND;
  const lotType = _.get(introDetails, "LotType_Rural_City", "");
  const askingPrice = _.get(introDetails, "AskingPrice", "");
  // let lotSizeUnit = _.get(introDetails, "Acre_Sqft", "");
  // if(lotSizeUnit && lotSizeUnit !== 0){
  //   lotSizeUnit = lotSizeUnit === 1? " Acres" : " Sqft"
  // }
//   const inventoryImage =
//   (_.get(introDetails, "Photo_1", '')!== 'null' && !_.get(introDetails, "Photo_1", ''))
//       ? _.get(introDetails, "Photo_1", "")
//       : noImage;
      const inventoryImage = (_.get(introDetails, "Photo_1", '')!== 'null' && _.get(introDetails, "Photo_1", '')!== 'undefined' &&_.get(introDetails, "Photo_1", '')!== null)
      ? _.get(introDetails, "Photo_1", '') : noImage; 
  return (
    <>
      <figure className="flex-shrink-0">
        <img src={inventoryImage} alt={inventoryImage} />
      </figure>
      <div className="detail">
        <h5>
          {" "}
          {getpropertyName(introDetails)} in {_.get(introDetails, "City", "")}, {_.get(introDetails, "State", "")}
          {/* {getpropertyName(introDetails)} in {_.get(introDetails, "Location", "")} */}
        </h5>
        <ul className="wantad-btm-list mt-2">
                {bedRoom ? (
                  <li> {bedRoom} Beds</li>
                ) : <></>}

                {bathRoom ? (
                  <li>
                   {bathRoom} Baths </li>
                ) : <></>}
                {sqFt ? (
                  <li> {sqFt} sqft</li>
                ) : <></>}

                {acreage ? (
                  <li>{acreage + lotSizeUnit}</li>
                ) : <></>}
              </ul>

        {/* {isLand ? (
          <ul>
            <li>{_.get(introDetails, "TotalAcre", "") +  lotSizeUnit}</li>
            {lotType && (
              <li>
                {filterArray(
                  LOT_TYPES,
                  "value",
                  _.get(introDetails, "LotType_Rural_City", "")
                )}
              </li>
            )}
          </ul>
        ) : (
          <ul>
            <li>{removeDecimalZeros(_.get(introDetails, "Bedrooms", "")) } Beds</li>
            <li>
              {removeDecimalZeros(_.get(introDetails, "Bathrooms", ""))} Baths
            </li>
            <li>
              {removeDecimalZeros(_.get(introDetails, "Sqft", ""))} sqft
            </li>
          </ul>
        )} */}

        <p> ${askingPrice && thousandSeperatedValue(askingPrice)}</p>
      </div>
    </>
  );
};
export default IntroductionDetail;
