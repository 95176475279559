/**
 * @file   src\components\MemberWantAd\EditWantAd\WantAdRentMustHaves.js
 * @brief  This file is responsible for handling wantad must haves in rent type in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormLabel } from "react-bootstrap";
import { handleRadioButonChange } from "../../../actions/common";
import {
  handleNegativeInput,
  validateInputValues,
  checkMaxValue,
  convertToNumber,
} from "../../../helpers/validations";
import RangeSliderComponent from "../../Common/RangeSlider";
import TextBox from "../../Common/TextBox";
import ButtonGroups from "../../Common/ButtonGroups";
import { thousandSeperatedValue } from '../../../helpers/common';
import SqareFeet from "../../AgentWantAd/SqareFeet";
import { STORY_ANY, STORY_SINGLE } from "../../../constants/common";

const WantAdRentMustHaves = (props) => {
  const {
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    SFMin,
    SFMax,
    garageSpace,
    alwLrgDogs,
    alwSmlDogs,
    alwCats,
    adaAccess,
    isEdit = false,
    stories,
    mustHaveErrors,
    requirementBedroomsError,
    requirementBathroomsError
  } = props;


  const [gSpace, setGSpace] = useState("");

  
  useEffect(() => {
    clearGarrageLocalState();
   
  }, []);


  const clearGarrageLocalState = () => {
    setGSpace("");
  };


  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
    clearGarrageLocalState();
  };


  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
    clearGarrageLocalState();
  };



  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    setGSpace(value);
    handleFormValues({ grgSpce: value });
  };

  const handleStorySelection = (selectedStory) => {
    if (selectedStory == stories) {
      handleFormValues({ stories: "" });
    } else {
      handleFormValues({ stories: selectedStory });
    }
  };

  const handleCheckBoxItemClick = (e) => {
    clearGarrageLocalState();
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 10:
        handleFormValues({ alwLrgDogs: e.target.checked });
        break;
      case 20:
        handleFormValues({ alwSmlDogs: e.target.checked });
        break;
      case 30:
        handleFormValues({ alwCats: e.target.checked });
        break;
      case 40:
        handleFormValues({ adaAccess: e.target.checked });
        break;
    }
  };

  const loadCheckBoxItems = () => {
    const itemArray = [
      { id: 10, text: "Allows large dogs" },
      { id: 20, text: "Allows small dogs" },
      { id: 30, text: "Allows cats" },
      { id: 40, text: "ADA Accessible" },
    ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 10:
          isChecked = alwLrgDogs;
          break;
        case 20:
          isChecked = alwSmlDogs;
          break;
        case 30:
          isChecked = alwCats;
          break;
        case 40:
          isChecked = adaAccess;
          break;
      }
      
      return (
        <div className="row">
          <div className="wantad-timing-wrap">
            <div className="col-md-12">
              <label className="check-wrap">
                <input
                  type="checkbox"
                  name={id}
                  id={id}
                  checked={isChecked}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0"> {text}</h2>
                  </div>
                </span>
              </label>

            </div>
          </div>
        </div>
      );
    });

    return List;
  };


  return (
    <>
      <div className={requirementBedroomsError ? "btn-group-wrap red-marked-btngroup mb-24" : "btn-group-wrap mb-24"}>
        <FormLabel className={requirementBedroomsError && "red-marked-formlabel"}>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
        />
      </div>
      <div className={requirementBathroomsError ? "btn-group-wrap red-marked-btngroup mb-24" : "btn-group-wrap mb-24"}>
        <FormLabel className={requirementBathroomsError  && "red-marked-formlabel"}>
          Bathrooms<span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>
      <SqareFeet
        handleFormValues={handleFormValues}
        SFMax={SFMax}
        SFMin={SFMin}
        errors={mustHaveErrors} />
      
      <div className="btn-group-btm-wrap">
        <FormLabel>Garage Spaces</FormLabel>

        <ButtonGroups
          variant="outline-primary"
          section="Garage"
          value={garageSpace}
          currentSelectedValue={gSpace}
          items={["Any", "1+", "2+", "3+"]}
          onClick={(e) => handleGarageClick(e)}
          handleFormValues={handleFormValues}
        />
      </div>
      <div className="btn-group-btm-wrap mb-24">
        <FormLabel>Stories</FormLabel>
        <ButtonGroup aria-label="Basic example">
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_ANY)}
            variant={
              stories === STORY_ANY
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Any
          </Button>
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_SINGLE)}
            variant={
              stories === STORY_SINGLE
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Single Story Only
          </Button>
        </ButtonGroup>
      </div>
      <div className="range-values-wrap">
        <label className="form-label">Additional Requirements</label>
        {loadCheckBoxItems()}

      </div>
    </>

  );
};
export default WantAdRentMustHaves;
