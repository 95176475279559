import React, { useState, useEffect } from "react";

import { getInvestmentCriteriaCapList, getInvestmentCriteriaList, getPropertyTypes } from "../../services/commonServices";
import { handleRadioButonChange } from "../../actions/common";
import { usePrevious } from "../../hooks/usePrevious";
import { BUY, WANT_AD_BUY_PROPERTY_ID } from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";
import SelectDropDown from "../Common/SelectDropDown";
import { combineKey, filterArrayBasedOnKey, renameKey } from "../../helpers/common";

const WantAdInvestmentCriteria = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    investmentId,
    capRateId,
    isEdit = false,
  } = props;
  const [criteriaList, setCriteriaList] = useState([]);
  const [minCapList, setMinCapList] = useState([]);

  useEffect(() => {
    handleContinueButtonState();    
    getInvestmentCriteriaList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setCriteriaList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

      getInvestmentCriteriaCapList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          list?.forEach((obj) =>
            renameKey(obj, "CapRateId", "value")
          );
          list?.forEach((obj) =>
            renameKey(obj, "CapRate_Text", "label")
          );
          list.map((rate)=>rate.label = rate.label+"%")
          setMinCapList([{ value: null, label: "Select min rate" },...list]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handlePropertyMultipleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      if (_.includes(investmentId, convertToNumber(value))) {
       
        subItemArray = investmentId.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {
         subItemArray = investmentId;
         subItemArray = [...subItemArray, convertToNumber(value)];
      }
      handleFormValues({ investmentId: subItemArray});
    }
  };

  const handleMinCapSelection = (e) => {
    console.log("e", e)
    handleFormValues({ capRateId: e.value});
  }


  const loadInvestmentCriteria = () => {
    const List =
      criteriaList &&
      criteriaList.map((pt) => {
        const typeId = _.get(pt, "Inv_Cr_Id", "");
        const typeText = _.get(pt, "Inv_Cr_Text", "");
        const isChecked =  investmentId && _.includes(investmentId, typeId);        
        return (
             <label className="check-wrap mb-3" key={typeId}>
            
            { <input
                type="checkbox"
                name="property_checkbox"
                id={typeId}
                value={typeId ? typeId : ""}
                checked={isChecked}
                onChange={(e) => handlePropertyMultipleItemClick(e)}
              />       
             
            }
            <span>
              <div className="timing-wrap-info">
                <h2>{typeText}</h2>
              </div>
            </span>
          </label>
        );
      });

    return List;
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-444" : ""}>
      <div className="wantad-timing-wrap">{loadInvestmentCriteria()}</div>
      <div className="wantad-timing-wrap">
      <div className="lotsize-selection-wrap">
        <h6>
          {" "}
          Minimum cap rate %
          
        </h6>
        <div className="row">
          <div className="col-md-12 pe-md-12">
            <label className= "lot-select-label">Min</label>
            <div className="lotsize-selection-wrap">
              <SelectDropDown
                name="colors3"
                options={minCapList}
                //className="lotsize-select custom-lotsize-color"
                className="lotsize-select custom-lotsize-color"
                classNamePrefix="select"
                placeholder="Select min rate"
                value={filterArrayBasedOnKey({
                    list: minCapList,
                    filterKey: "value",
                    value: convertToNumber(capRateId),
                  })}
                // onInputChange={(e) => handleMinCapSelection(e)}
                onChange={(e) => handleMinCapSelection(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                  }),
                }}
                errorClass="error-msg error-msg-height"
                onBlur={()=>{handleFormValues({focusOut: true})}}
              />
            </div>
          </div>          
        </div>
      </div>
      </div>
    </div>
  );
};
export default WantAdInvestmentCriteria;
