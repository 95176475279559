import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import { emailValidation } from "../../helpers/validations";
import {deleteUser} from "../../services/userprofileService";
import { toast } from "react-toastify";
import CheckEmailDeletionModal from "../Onboarding/UserRegister/CheckEmailDeletionModal";
import { pageURLs } from "../../constants/pageURLs";
import { clearLocalStorageOnLogout } from "../../helpers/common";


const DeleteAccountModal = (props) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState("");
  // const [checkEmailPopup, setCheckEmailPopup] = useState(false);

  useEffect(()=>{
    setEmail(props.Email)
  },[props.Email])

  const handleEmailChange = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    validate(e.target.value);
  };
  const validate = (mail) => {
    let isValid = true;
    setEmailError("");
    if (mail.trim() === "") {
      isValid = false;
      setEmailError("Enter Email");
    } else if (!emailValidation(mail.trim())) {
      isValid = false;
      setEmailError("Enter Valid email");
    } 
    return isValid;
  };

  const handleCancel =() =>{
    setEmailError("");
    props.onHide()
  }

 const handleDeleteAccount = async () => {

  const payload = {
    email: email
  }
  await deleteUser(payload).then((response)=>{
    if(response.errorCode===0){
      props.onHide()
      clearLocalStorageOnLogout();
      navigate(pageURLs.home, {
        state : {
            deleteAccount : true
          }
        }
      )
      // setCheckEmailPopup(true)
    }
    else{
      toast.error(response.message)
    }
  }).catch((error)=>{
    toast.error(error)
  })
 }

  return (
    <>
    {/* {checkEmailPopup ? (
        <CheckEmailDeletionModal process="Delete" email={email} />
      ) : (
        ""
      )} */}
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings"
      backdrop='static'
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
        <h3 className="text-start">Delete your account</h3>
        <p className="f-size-16 mb-4">If you continue, we will permanently delete your Gitcha account and all data. Visitors will no longer 
        be able to see your want ads. Please verify your email to confirm.</p>
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">Email</label> 
              <TextBox
                    id="mailId"
                    name="emailId"
                    value={email}
                    disabled={true}
                    type="text"
                    placeholder="Email"
                    className="form-control"
                    onChange={(e)=>handleEmailChange(e)}
                    errorClass = "error-validation-msg"
                    errorMessage={emailError}
                />
            </div>
          </Col>
        </Row>

        <div className="modal-btn-wrap">
            <Button variant="" className="btn btn-tertiary me-3" onClick={handleCancel}>Cancel</Button>
            <Button variant="" className="btn btn-delete" onClick={handleDeleteAccount}>Delete Account</Button>
        </div>
      </Modal.Body>      
    </Modal>
    </>
  );
};
export default DeleteAccountModal;
