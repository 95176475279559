/**
 * @file   src\mobile\components\DeleteGitchaAccountModal.js
 * @brief  This file is responsible for delete account
 * @date   April, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import TextBox from "../../components/Common/TextBox";
import { Modal } from "react-bootstrap";
import { emailValidation } from "../../helpers/validations";
import { deleteUser } from "../../services/userprofileService";
import { toast } from "react-toastify";
import { pageURLs } from "../../constants/pageURLs";
import { clearLocalStorageOnLogout } from "../../helpers/common";
import Form from "react-bootstrap/Form";
const DeleteGitchaModal = (props) => {
  const { show, onHide, userEmail } = props;

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [checkEmailPopup, setCheckEmailPopup] = useState(false);

  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);

  /**
   * To handle textbox change in email field
   * @param {*} e 
   */
  const handleEmailChange = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    //validate(e.target.value);
  };

  /**
   * To validate the email id populated 
   * @param {*} mail 
   * @returns 
   */
  const validate = (mail) => {
    let isValid = true;
    setEmailError("");
    if (mail.trim() === "") {
      isValid = false;
      setEmailError("Enter Email");
    } else if (!emailValidation(mail.trim())) {
      isValid = false;
      setEmailError("Enter Valid email.");
    } else if (userEmail.toLowerCase() !== mail.toLowerCase()) {
      isValid = false;
      setEmailError("Enter Valid email.");
    }
    return isValid;
  };

  /**
   * To handle cancel popup
   */
  const handleCancel = () => {
    setEmail("");
    setEmailError("");
    onHide();
  };

  /**
   * To handle delete account
   */
  const handleDeleteAccount = async () => {
    if (validate(email)) {
      const payload = {
        email: email,
      };
      await deleteUser(payload)
        .then((response) => {
          if (response.errorCode === 0) {
            // props.onHide()
            clearLocalStorageOnLogout();
            navigate(pageURLs.mobileSignIn, {
              state: {
                deleteAccount: true,
              },
            });
            // setCheckEmailPopup(true)
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="modalPopupstyle delete-popup"
    >
      <Modal.Body className="text-center">
        <h3>Delete your account</h3>
        <p>
          If you continue, we will permanently delete your Gitcha account and
          all data. Visitors will no longer be able to see your want-ads. Please
          verify your email to confirm.
        </p>
        <div>
          {/* <Form.Group className="mb-3 mt-3 text-start" controlId="exampleForm.ControlInput1">
<label className="fnt14 mb-1">Email </label>
                      <Form.Control type="email" placeholder="" value="" />
                    </Form.Group> */}

          <div className="mb-3 mt-3 text-start">
            <label className="fnt14 mb-1">Email</label>
            <TextBox
              id="mailId"
              name="emailId"
              value={email}
              type="email"
              placeholder="Email"
              className="form-control"
              onChange={(e) => handleEmailChange(e)}
              errorClass="error-validation-msg"
              errorMessage={emailError}
            />
          </div>
        </div>
        <div className="btn-wrap">
          {/* <button type="button" className="btn btn-delete w-100" onClick={onHide}>Delete Account</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Cancel</button> */}

          <Button
            variant=""
            className="btn btn-delete w-100"
            onClick={() => handleDeleteAccount()}
          >
            Delete Account
          </Button>
          <Button
            variant=""
            className="btn btn-secondary w-100"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeleteGitchaModal;
