import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';



const PhotoTipsOffcanvas = (props) => {
  const {show, onHide} = props;
  return (

    <Offcanvas
     className="mobile-offcanvas ps-0 pe-0 offcanvas-filter-share-fix"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}> 
    <Offcanvas.Header className="d-none" closeButton>
      <Offcanvas.Title>Offcanvas</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <div className="offcanvas-sharead-wrap ">
        <div className="share-yourad-topsec mb-20 ps-3 pe-3 pt-12">
          
        <div className="share-yourad-boldheading">Photo tips and guidelines</div>  
        <div></div>  
      </div>
          
          <div className="pb-0 ps-3 pe-3">

            
            <p className="text-start popup-para">We recommend photographing all rooms in your home. To take the best photos, we recommend the following:</p>
            <ul className="photo-tips-list">
              <li>Prepare and declutter each room.</li>
              <li>Turn on all the lights during the daytime.</li>
              <li>Shoot your photos in a corner.</li>
              <li>Capture the details that make your home unique.</li>
              <li>Select the most attractive photos the first 3 photos on your inventory. These are the first ones buyers will see when you introduce your home.</li>
            </ul>

           

           
           
           
           
             
         </div>
       
          
        
          
        
      </div>
    
      
     
    </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default PhotoTipsOffcanvas;
