import React, { useState, useEffect, useRef } from "react";
// import Button from "react-bootstrap/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuBottomBar from "../components/menuBottomMobile";
import { getFavClientList } from "../../services/wantAdService";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import { classesBasedOnScroll, getUserId, getUserType, isIphone } from "../../helpers/common";
import moment from "moment";
import Sortoffcanvas from "../components/Common/Sortoffcanvas";
import userProfile from "../../appState/userProfile";
import { FREE_PLAN, SCROLL_DIRECTION_DOWN, USER_TYPE_AGENT } from "../../constants/common";
import HandleScroll from "../commonComponents/HandleScroll";

const ClientFavorites = (props) => {
  const scrollStates = HandleScroll()
  const isReachedBottom =  _.get(scrollStates, 'isReachedBottom', null)
  const scrollDirection = _.get(scrollStates, 'direction', null)
  const INITIAL_PAGE_INDEX = 1
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const userDetailsFromStore = userProfile()
  const userType =  getUserType()
  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix");
  }, []);

  useEffect(()=>{
    if(userType !== USER_TYPE_AGENT){
      navigate(pageURLs.proDashboard)
    } else if(userDetailsFromStore !== null && userDetailsFromStore?.length>0){
      if( userDetailsFromStore[0]?.period &&  userDetailsFromStore[0]?.period === FREE_PLAN){
        navigate(pageURLs.proDashboard)
      }
    }
       
  },[userDetailsFromStore])

  const [showSort, setShowSort] = useState(false);
  const [currPage, setCurrPage] = useState(INITIAL_PAGE_INDEX); // storing current page number
  const [searchKey, setSearchKey] = useState(null); // storing current page number
  const [favClients, setFavClients] = useState(null); // storing current page number

  const options = [
    { value: "recently added", label: "Recently Favourited" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];
  const sortClose = () => setShowSort(false);
  const sortShow = () => setShowSort(true);
  const [sortParam, setSortParam] = useState(options[0]);

  useEffect(() => {
    const reqPayload = {
      sortBy: sortParam?.value,
      sortDir: sortParam?.label === "Oldest" ? "asc" : "desc",
      page: currPage,
      limit: 10,
      search: searchKey,
    };
    getFavoriteClientList(reqPayload);
  }, [currPage, searchKey, sortParam]);

  const handleSearchOnChange = (e)=>{
    setCurrPage(INITIAL_PAGE_INDEX)
    setSearchKey(e.target.value)
  }

  function Clients(props) {
    const clients = props.clients;
    let clientFullName = null;
    if (clients.FirstName && !clients.LastName) {
      clientFullName = clients.FirstName;
    } else if (!clients.FirstName && clients.LastName) {
      clientFullName = clients.LastName;
    } else if (clients.FirstName && clients.LastName) {
      clientFullName = clients.FirstName + " " + clients.LastName;
    } else {
      clientFullName = "Unassigned";
    }

    return (
      <a
        href={void 0}
        onClick={() =>
          navigate(pageURLs.mobileWantAdFavorites, {
            state: { clientName: clientFullName, clientId: clients.ClientId, favCount: clients.FavCount },
          })
        }
        className="list-Whitewrap mb-3 d-flex align-items-center"
        role="button"
      >
        <span className="profile-bg-meroon profileIcnDiv me-2 align-items-center">
          {clientFullName?.replace(/-/g, "").match(/\b(\w)/g)}
        </span>
        <div>
          <span className="labelTxt">
            {" "}
            {clients.ClientId == null ? "Unassigned" : clientFullName}
            {clients.FavCount > 1 && "(" + clients.FavCount + ")"}
          </span>
          <p>
            {" "}
            Last added{" "}
            {clients.CreatedOn
              ? moment(new Date(clients.CreatedOn)).format("MM/DD/YY")
              : ""}
          </p>
        </div>
      </a>
    );
  }

  // const handleScroll = () => {
  //   const el = scrollRef.current;
  //   const scrollHeight = el.scrollHeight;
  //   const scrollTop = el.scrollTop;
  //   const clientHeight = el.clientHeight;
  //   if (
  //     scrollHeight !== clientHeight &&
  //     scrollTop !== 0 &&
  //     Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1
  //   ) {
  //     setCurrPage((prevState) => prevState + 1);
  //   } else if (scrollTop === 0 && scrollHeight !== clientHeight) {
  //     //setCurrPage(prevState => prevState -1);
  //   }
  // };

  useEffect(() => {
    if (isReachedBottom) {
      setCurrPage((prevState) => prevState + 1);
    }
  }, [isReachedBottom]);

  const getFavoriteClientList = async (reqPayload) => {
    await getFavClientList(reqPayload)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          if (reqPayload.page === 1) {
            setFavClients(list);
          } else {
            setFavClients([...favClients, ...list]);
          }
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleSortSelection = (selectedItem) => {
    setSortParam(selectedItem);
    sortClose();
  };

  // let mainWrapperClass ="gitcha-main-wrapper my-client-wrap view-client-wrap pb-90 windowscroll-fix"
  // let contentWrapperClass = "content-wrapper content-wrapper-h-90-percent windowscroll-fix"
  // if(scrollDirection === SCROLL_DIRECTION_DOWN){
  //   if(isIphone()){
  //     mainWrapperClass ="gitcha-main-wrapper my-client-wrap view-client-wrap pad-btm-0 windowscroll-fix"
  //   } else{
  //     mainWrapperClass ="gitcha-main-wrapper my-client-wrap pad-btm-0 windowscroll-fix"
  //   }

  //    contentWrapperClass = "content-wrapper h-100 windowscroll-fix"
  // }

  const {mainWrapperClass, contentWrapperClass } = classesBasedOnScroll(scrollDirection)

  return (
    <div className={mainWrapperClass}>
      <div className= {contentWrapperClass}>
        <div className="inner-layout-header-new bg-white ">
          <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
            <div className="top-three-comp-header-inner w-100">
              <a
                className="back-link-btn"
                href={void 0}
                role="button"
                onClick={() => navigate(pageURLs.proDashboard)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
              <div className="heading">Client Favorites</div>
              <div class="p-0 toplinks-mobile">
                <div
                  className="topbar-links sort-icon-header"
                  onClick={() => sortShow()}
                ></div>
              </div>
            </div>

            <Sortoffcanvas
              show={showSort}
              onHide={sortClose}
              handleSortParam={handleSortSelection}
              options={options}
              sortParam={sortParam}
            />
          </div>
        </div>

        <div className="home-layout-content">
          <div className="pe-3 ps-3 pt-4 ">
            <div className="row">
              <div className="col-12 mb-3">
                <div className=" position-relative">
                  <div className="search_field_input position-relative mb-0 input-group d-flex">
                    <input
                      aria-label="Example"
                      aria-describedby="basic-addon3"
                      placeholder="Client Name"
                      class="form-control pr-0"
                      value={searchKey}
                      onChange={(e) =>handleSearchOnChange(e)}
                    ></input>

                    <button
                      type="button"
                      id="button-addon3"
                      class="search-icon "
                    ></button>
                  </div>
                </div>
              </div>
            </div>

            {favClients?.length > 0 && (
              <div className="title-selection-wrap">
                {favClients?.map((clients, i) => {
                  return <Clients key={i} clients={clients} ival={i} />;
                })}
                
              </div>
            )}

            {favClients && favClients?.length === 0 && (
              <div className="no-data no-data-heightfix d-flex align-items-center  justify-content-center flex-column text-center">
                <h4>You don’t favorited any want-ads.</h4>
                <p className="m-0">
                  Use the explore tab to browse for want-ads. When you find one
                  you want to favorite, use the Heart icon in the top right
                  corner.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

     
      {scrollDirection !== SCROLL_DIRECTION_DOWN && (
          <div className="inner-layout-footer-new inner-layout-footer-topfix">
            <div className="bottomMenu bottom-menu-index">
              <MenuBottomBar scrollDirection = {scrollDirection} />
            </div>
          </div>
        )}
    </div>
  );
};

export default ClientFavorites;
