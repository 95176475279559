import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const RangeSliderComponent = (props) => {
    const {min, max, value, onInput, defaultValue, onThumbDragStart, sliderClass, stepValue = 0, rangeSlideDisabled, thumbsDisabled } = props
  return (
    <RangeSlider
     className = {sliderClass}
      min={min}
      max={max}
      value={value}
      onInput={onInput}
      step={stepValue}
      defaultValue={defaultValue}
      onThumbDragStart ={onThumbDragStart}
      thumbsDisabled= {thumbsDisabled}
      rangeSlideDisabled= {rangeSlideDisabled}
    />
  );
};
export default RangeSliderComponent;
