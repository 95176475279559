import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import ConfettiColoredIcon from "../../images/confetti-colored-icon.svg";
import { USER_TYPE_AGENT, USER_TYPE_MEMBER } from "../../../constants/common";
import { setBodyBackgroundGrey } from "../../../helpers/common";

const UserType = (props) => {
  const { handleUserTypeSelection, userType, handleUserTypeContinueClick } = props;
  useEffect(() => {
    setBodyBackgroundGrey();

  }, []);

  return (
    <div className="gitcha-main-wrapper pb-0 bg-grey-variant">
        <div className="content-wrapper pt-0 h-100">
        <div className="inner-layout-content-new h-100 ps-3 pe-3">
        <div className="signup-login-header clearfix">
          <div className="confetti-colored-icon mt-5 mx-auto">
            <img src={ConfettiColoredIcon} />
          </div>
          <h2 className="pt-2 pb-2 text-center">Let’s create your profile!</h2>
          <p className="text-center mb-18">
            We’re here to help meet you and your real estate goals. Let’s set up
            your profile.
          </p>
        </div>
        <div className="title-selection-wrap ">
          <p className="mb-3">Are you a licensed real estate agent or property manager? </p>

          <a
            role="button"
            href={void 0}
            className={userType === USER_TYPE_AGENT ? "title-selection-innner mb-3 active": "title-selection-innner mb-3"}
            onClick={() => handleUserTypeSelection(USER_TYPE_AGENT)}
          >
            Yes
          </a>

          <a
            role="button"
            href={void 0}
            className={userType === USER_TYPE_MEMBER ? "title-selection-innner mb-4 active": "title-selection-innner mb-4"}
            onClick={() => handleUserTypeSelection(USER_TYPE_MEMBER)}
          >
            No
          </a>
        </div>

        <Button
          variant="primary"
          className="btn w-100 mb-4"
          disabled={userType ? false : true}
          onClick={()=>handleUserTypeContinueClick()}
        >
          Continue
        </Button>
      </div>
    </div>
    </div>
  );
};

export default UserType;
