import {
  INVENTORY_KEY,
  MAP_ICON_S3_URL,
  MAP_TYPE_ROADMAP,
  WANT_AD_KEY,
  USER_INFO_KEY
} from "../constants/common";
import * as endPoints from "../constants/endPoints";
import { getLocalStorage, getLocalStorageValue } from "../helpers/common";
import * as apiService from "./apiService";
import axios from "axios";

export async function getuserIP() {
  return await apiService.apiCall(endPoints.GET_USER_IP, "GET", {});
}


export async function getTimeline(type) {
  return await apiService.apiCall(
    endPoints.GET_PLAN_TO_BUY + `?Type=${type}`,
    "GET",
    {}
  );
}

export async function getGeoLocation(params) {
  //&components=country:US
  const response = await fetch(
    `${endPoints.GET_GEOLOCATION}?${params}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );
  try {
    const data = await response.json();
    return data;
  } catch {
    console.log(`Error`);
    return "";
  }
}



export async function getMapStaticImage(params) {
  const isFromWantAd = _.get(params, "isFromWantAd", false);
  const mapType = getLocalStorageValue({
    localStorageKey: isFromWantAd ? WANT_AD_KEY : INVENTORY_KEY,
    searchKey: "mapType",
    defaultValue: MAP_TYPE_ROADMAP,
  });

  const url = `${endPoints.GET_MAP_STATIC_IMAGE}?center=${_.get(
    params,
    "latitude",
    ""
  )},${_.get(params, "longitude", "")}&zoom=${_.get(
    params,
    "zoom",
    ""
  )}&format=${_.get(params, "format", "")}&maptype=${mapType}&size=${_.get(
    params,
    "size",
    ""
  )}&key=${
    process.env.REACT_APP_GOOGLE_API_KEY
  }&markers=icon:${MAP_ICON_S3_URL}%7C${_.get(params, "latitude", "")},${_.get(
    params,
    "longitude",
    ""
  )}`;
  const response = await fetch(url);
  try {
    return response;
  } catch {
    console.log(`Error`);
    return "";
  }
}



export async function getPropertyTypes(type) {
  return await apiService.apiCallWithoutToken(
    endPoints.GET_PROPERTY_TYPE + `?Type=${type}`,
    "GET",
    {}
  );
}

export async function getInvestmentCriteriaList() {
  return await apiService.apiCall(
    endPoints.GET_INVESTMENT_CRITERIALIST,
    "GET",
    {}
  );
}

export async function getInvestmentCriteriaCapList() {
  return await apiService.apiCall(
    endPoints.GET_INVESTMENT_CRITERIA_CAPLIST,
    "GET",
    {}
  );
}

export async function getNiceToHaves(type) {
  return await apiService.apiCall(
    endPoints.GET_NICE_TO_HAVES + `?Type=${type}`,
    "GET",
    {}
  );
}

export async function getS3FolderName(params) {
  return await apiService.apiCall(
    endPoints.GET_FOLDER_NAME +
      `?isWantAd=${_.get(
        params,
        "isWantAd",
        false
      )}`,
    "GET",
    {}
  );
}

export async function getWantAdAutoCompleteData(searchKey) {
  let headers = {
    "x-api-key": process.env.REACT_APP_REAL_ESTATE_API_KEY,
  };
  const body = { search: "22205" };

  let { data } = await axios.post(
    endPoints.WANT_AD_LOCATION_AUTO_COMPLETE,
    body,
    { headers }
  );

}

export async function getWantAdReportingReasons() {
  return await apiService.apiCall(
    endPoints.GET_WANT_AD_REPORTING_REASONS,
    "GET",
    {}
  );
}

export async function getIntroUserReportingReasons() {
  return await apiService.apiCall(
    endPoints.GET_INTRO_USER_REPORTING_REASONS,
    "GET",
    {}
  );
}

export async function getCancelPlanReasons() {
  return await apiService.apiCall(endPoints.GET_CANCEL_PLAN_REASONS, "GET", {});
}

export async function getPropertySearch(requestPayload) {
  return await apiService.apiCall(
    endPoints.PROPERTY_SEARCH_API,
    "POST",
    requestPayload
  );
}

export async function getPropertyDetails(requestPayload) {
  return await apiService.apiCall(
    endPoints.PROPERTY_DETAILS_API,
    "POST",
    requestPayload
  );
}

export async function saveZipCodes(requestPayload) {
  return await apiService.apiCall(
    endPoints.SAVE_ZIP_CODES,
    "POST",
    requestPayload
  );
}


export async function checkGitchaAvailable(params) {
  return await apiService.apiCallWithoutToken( endPoints.GITCHA_AVAILABLE_CHECK +
    `?county=${_.get(params, "county", "")}&state=${_.get(
             params,
            "state",
            ""
          )}`, "GET", {});
}

export async function fetchTutorialStatus(page) {
  let userDetails = getLocalStorage(USER_INFO_KEY);
  let userId = _.get(userDetails, "userInfo.userId", null);
  // 1= Home page left side menu tutorial
  // 2 = Introduction dropdown tutorial in header dropdown;
  // 3 = Introduction details popup tutorial
  // 4 = Agent dashboard tutorial
  // 5= Wantadd display tutorial
  // 6= Wantadd add  tutorial
  // 7= Inventory(property) add  tutorial
  return await apiService.apiCall(
    endPoints.FETCH_TUTORIAL_STATUS+ `?page=${page}`,
    "GET"
  );
}

export async function saveTutorialStatus(page, status) {
  let userDetails = getLocalStorage(USER_INFO_KEY);
  let userId = _.get(userDetails, "userInfo.userId", null);
  // 1= Home page left side menu tutorial
  // 2 = Introduction dropdown tutorial in header dropdown;
  // 3 = Introduction details popup tutorial
  // 4 = Agent dashboard tutorial
  // 5 = Want add tutorial
  let requestPayload = {
    "pageNo" : page,
    "status": status,
    "userId" : userId
  }
  return await apiService.apiCall(
    endPoints.UPDATE_TUTORIAL_STATUS,
    "POST",
    requestPayload
  );
}

export async function saveGitchaAvailableLocationRequest(payload) {
  return await apiService.apiCall( endPoints.SAVE_GITCHA_AVAILABLE_LOCATION_REQUEST, "POST", payload);
}

export async function getGitchaAvailableStates() {
  return await apiService.apiCallWithoutToken( endPoints.GET_GITCHA_AVAILABLE_STATES, "GET");
}

export async function getGitchaAvailableCities(payload) {
  return await apiService.apiCallWithoutToken( endPoints.GET_GITCHA_AVAILABLE_CITIES, "GET", payload);
}
export async function saveGitchaContactUs(payload) {
  return await apiService.apiCall( endPoints.SAVE_GITCHA_CONTACT_US, "POST", payload);
}

export async function saveGitchaNeedAgentContactUs(payload) {
  return await apiService.apiCall( endPoints.SAVE_NEED_AGENT_CONTACT_INFO, "POST", payload);
}

export async function getBuyerListUniqueFolderName(params) {
  return await apiService.apiCallWithoutToken(
    endPoints.GET_BUYERLIST_UNIQUE_FOLDERNAME +
      `?client_Want_ad_Id=${_.get(
        params,
        "client_Want_ad_Id",
        0
      )}&isWantAd=${_.get(
        params,
        "isWantAd",
        false
      )}`,
    "GET",
    {}
  );
}