import React, { useState, useEffect } from "react";
import RadioButton from "../Common/RadioButton";
import { IN_CITY_LIMITS } from "../../constants/common";
import WantAdLotSize from "../Common/WantAdLotSize";

const WantAdLandMustHaves = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    lotType,
    roadType,
    isPrivtDrilInstld,
    onSiteElec,
    waterSupply,
    isDrldWelInstld,
    wasteWtrSys,
    isSepTankInstld,
    isSepTankApprvd,
    minLtSz,
    maxLtSz,
    mustHaveErrors,
    errorUpdatedAt,
    isEdit = false,
  } = props;

  const [roadTypePrivteInstalled, setRoadTypePrivteInstalled] = useState(false);
  const [waterSupplyDrilledWell, setWaterSupplyDrilledWell] = useState(false);
  const [septicTankInstalled, setSepticTankInstalled] = useState(false);

  useEffect(() => {
    roadType && handleRoadTypePrivteInstalled(roadType);
    waterSupply && handleWaterSupplyDrilledWellInstalled(waterSupply);
    wasteWtrSys && handleSepticTankInstalled(wasteWtrSys);
    handleContinueButtonState();
  }, []);

  const handleRoadTypePrivteInstalled = (rt) => {
    setRoadTypePrivteInstalled(false);
    if (rt === "2") {
      setRoadTypePrivteInstalled(true);
    } else {
      handleFormValues({ isPrvtDrInst: "" });
    }
  };

  const handleRoadTypeSelection = (selectedValue, selectedText) => {
    if (selectedValue === roadType) {
      handleFormValues({
        roadType: "",
        rdTypText: "",
        isPrvtDrInst: "",
      });
      setRoadTypePrivteInstalled(false);
    } else {
      handleRoadTypePrivteInstalled(selectedValue);
      handleFormValues({ roadType: selectedValue, rdTypText: selectedText });
    }
  };

  const handleWaterSupplyDrilledWellInstalled = (ws) => {
    setWaterSupplyDrilledWell(false);
    if (ws === "2") {
      setWaterSupplyDrilledWell(true);
    } else {
      handleFormValues({ isDrldWelInst: "" });
    }
  };
  const handleWaterSupplySelection = (selectedValue, selectedText) => {
    if (selectedValue === waterSupply) {
      handleFormValues({
        waterSupply: "",
        wSText: "",
        isDrldWelInst: "",
      });
      setWaterSupplyDrilledWell(false);
    } else {
      handleWaterSupplyDrilledWellInstalled(selectedValue);
      handleFormValues({ waterSupply: selectedValue, wSText: selectedText });
    }
  };

  const handleSepticTankInstalled = (rt) => {
    setSepticTankInstalled(false);
    if (rt === "2") {
      setSepticTankInstalled(true);
    } else {
      handleFormValues({ isSepTankInst: "", isSepTankAppr: "" });
    }
  };

  const handleWasteWaterSystemSelection = (selectedValue, selectedText) => {
    if (selectedValue === wasteWtrSys) {
      handleFormValues({
        wasteWtrSys: "",
        wWSysText: "",
        isSepTankInst: "",
        isSepTankAppr: "",
      });
      setSepticTankInstalled(false);
    } else {
      handleSepticTankInstalled(selectedValue);
      handleFormValues({ wasteWtrSys: selectedValue, wWSysText: selectedText });
    }
  };

  const handleElectricityOnsiteSelection = (e) => {
    const selection = e.target.value;
    if (onSiteElec === selection) {
      handleFormValues({ onSiteElec: "" });
    } else {
      handleFormValues({ onSiteElec: selection });
    }
  };

  const handleLotTypeSelection = (selectedValue, selectedText) => {
    if (selectedValue === lotType) {
      handleFormValues({
        lotType: "",
        lotTypeText: "",
      });
    } else {
      handleFormValues({ lotType: selectedValue, lotTypeText: selectedText });
    }
  };

  const handlePrivateDriveInstall = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isPrvtDrInst: selection });
  };

  const handleSepticTankInstalledStatus = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isSepTankInst: selection });
  };

  const handleSepticTankApprovedStatus = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isSepTankAppr: selection });
  };

  const handleDrilledWellInstall = (e) => {
    const selection = e.target.checked;
    handleFormValues({ isDrldWelInst: selection });
  };

  let isOnSiteElecCheckedYes = false;
  let isOnSiteElecCheckedNo = false;
  if (onSiteElec === "true" || onSiteElec === 1) {
    isOnSiteElecCheckedYes = true;
  } else if (onSiteElec === "false" || onSiteElec === 0) {
    isOnSiteElecCheckedNo = true;
  }

  return (
    <div className={!isEdit ? "center-content-sec mw-524" : ""}>
      <div className="wantad-timing-wrap">
        <WantAdLotSize
          handleFormValues={handleFormValues}
          mnLotSze={minLtSz}
          mxLotSze={maxLtSz}
          lotSizeError={mustHaveErrors}
          errorUpdatedAt={errorUpdatedAt}
        />
      </div>

      <div className="wantad-timing-wrap">
        <div className="land-radio-wrap">
          <h6>Lot Type</h6>
          <div className="row">
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <RadioButton
                  name="lt-radio"
                  value="1"
                  checked={lotType == "1" ? true : false}
                  onClick={() => handleLotTypeSelection("1", "Rural")}
                  onChange={() => {}} // Avoid warnings in console
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Rural</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <RadioButton
                  name="lt-radio"
                  value="2"
                  checked={lotType == "2" ? true : false}
                  onClick={() => handleLotTypeSelection("2", IN_CITY_LIMITS)}
                  onChange={() => {}} // Avoid warnings in console
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">{IN_CITY_LIMITS}</h2>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="land-radio-wrap">
          <h6>Road Type</h6>
          <div className="row">
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="rt-radio"
                  value="1"
                  checked={roadType == "1" ? true : false}
                  onClick={() => handleRoadTypeSelection("1", "City roads")}
                  onChange={() => {}}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">City roads</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="rt-radio"
                  value="2"
                  checked={roadType == "2" ? true : false}
                  onClick={() => handleRoadTypeSelection("2", "Private drive")}
                  onChange={() => {}} // Avoid warnings in console
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Private drive</h2>
                  </div>
                </span>
              </label>
              {roadTypePrivteInstalled && (
                <label
                  className={!isEdit ? "check-wrap mb-0" : "check-wrap mt-3"}
                >
                  <input
                    type="checkbox"
                    name="pd-checkbox"
                    onChange={handlePrivateDriveInstall}
                    checked={isPrivtDrilInstld}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0">Installed</h2>
                    </div>
                  </span>
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="land-radio-wrap">
          <h6>Electricity On-Site</h6>
          <div className="row">
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="eos-radio"
                  value={true}
                  onClick={handleElectricityOnsiteSelection}
                  onChange={() => {}} // Avoid warnings in console
                  checked={isOnSiteElecCheckedYes}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Yes</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="eos-radio"
                  value={false}
                  onClick={handleElectricityOnsiteSelection}
                  onChange={() => {}} // Avoid warnings in console
                  checked={isOnSiteElecCheckedNo}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">No</h2>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="land-radio-wrap">
          <h6>Water Supply</h6>
          <div className="row">
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="ws-radio"
                  value="1"
                  checked={waterSupply && waterSupply === "1"}
                  onClick={() =>
                    handleWaterSupplySelection("1", "Domestic water supply")
                  }
                  onChange={() => {}} // Avoid warnings in console
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Domestic water supply</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="ws-radio"
                  value="2"
                  checked={waterSupply && waterSupply === "2"}
                  onClick={() =>
                    handleWaterSupplySelection("2", "Drilled well")
                  }
                  onChange={() => {}}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Drilled well</h2>
                  </div>
                </span>
              </label>
              {waterSupplyDrilledWell && (
                <label
                  className={!isEdit ? "check-wrap mb-0" : "check-wrap mt-3"}
                >
                  <input
                    type="checkbox"
                    name="dw-checkbox"
                    onChange={handleDrilledWellInstall}
                    checked={isDrldWelInstld}
                  />
                  <span>
                    <div className="timing-wrap-info">
                      <h2 className="pb-0">Installed</h2>
                    </div>
                  </span>
                </label>
              )}
            </div>
          </div>
        </div>

        <div className="land-radio-wrap">
          <h6>Wastewater System</h6>
          <div className="row">
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="wws-radio"
                  value="1"
                  checked={wasteWtrSys && wasteWtrSys === "1"}
                  onClick={() => handleWasteWaterSystemSelection("1", "Sewer")}
                  onChange={() => {}} // Avoid warnings in console
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Sewer</h2>
                  </div>
                </span>
              </label>
            </div>
            <div className="col-md-6">
              <label className={!isEdit ? "radio-wrap mb-3" : "radio-wrap"}>
                <input
                  type="radio"
                  name="wws-radio"
                  value="2"
                  checked={wasteWtrSys && wasteWtrSys === "2"}
                  onClick={() =>
                    handleWasteWaterSystemSelection("2", "Septic system")
                  }
                  onChange={() => {}} // Avoid warnings in console
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0">Septic system</h2>
                  </div>
                </span>
              </label>
              {septicTankInstalled && (
                <>
                  <label
                    className={!isEdit ? "check-wrap mb-3" : "check-wrap mt-3"}
                  >
                    <input
                      type="checkbox"
                      name="st-checkbox"
                      onChange={handleSepticTankInstalledStatus}
                      checked={isSepTankInstld}
                    />
                    <span>
                      <div className="timing-wrap-info">
                        <h2 className="pb-0">Installed</h2>
                      </div>
                    </span>
                  </label>
                  <label
                    className={!isEdit ? "check-wrap mb-0" : "check-wrap mt-3"}
                  >
                    <input
                      type="checkbox"
                      name="st-checkbox"
                      onChange={handleSepticTankApprovedStatus}
                      checked={isSepTankApprvd}
                    />
                    <span>
                      <div className="timing-wrap-info">
                        <h2 className="pb-0">Approved</h2>
                      </div>
                    </span>
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantAdLandMustHaves;
