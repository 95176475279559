/**
 * @file   src\components\AgentDashboard\TableHeader.js
 * @brief  This file is responsible for handling TableHeader component
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";

const TableHeader = (props) => {
  const { columns, handleSort, sort } = props;

  return (
    <thead>
      <tr>
        {columns.map((column, index) => {
          const sortIcon = () => {
            if (column.sortKey === sort.orderBy) {
              if (sort.order === "asc") {
                return "sorting_asc";
              }
              return "sorting_desc";
            } else {
              return "sorting";
            }
          };
          return (
            <th key={index}>
              {column.sorting == "Yes" && (
                <span
                  className={sortIcon() + " first-head"}
                  onClick={() => handleSort(column.sortKey)}
                >
                  {column.label}
                </span>
              )}
              {column.sorting == "No" && (
                <span className="sorting-heading">{column.label}</span>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
export default TableHeader;
