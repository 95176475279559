import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function getGoogleUserInfo() {
  return await apiService.apiCall(endPoints.GET_GOOGLE_USER_INFO, "GET", {}, '123');
}

export async function login(payload) {
    return await apiService.apiCall(endPoints.LOGIN, "POST", payload);
  }

  export async function userVerify(payload) {
    return await apiService.apiCall(endPoints.VERIFY_USER, "POST", payload);
  }

  export async function verifyUserAction(payload) {
    return await apiService.apiCallWithoutToken(endPoints.VERIFY_USER_ACTION, "POST", payload);
  }

  export async function saveGoogleLoginUser(payload) {
    return await apiService.apiCallWithoutToken(endPoints.SAVE_GOOGLE_LOGIN_USER, "POST", payload);
  }

  export async function tokenRefresh(payload) {
    return await apiService.apiCallWithoutToken(endPoints.REFRESH_TOKEN, "POST", payload, 'refreshToken');
  }
  export async function resetPassword(payload) {
    return await apiService.apiCallWithoutToken(endPoints.RESET_PASSWORD_EMAIL, "POST", payload);
  }
  export async function updatePassword(payload) {
    return await apiService.apiCallWithoutToken(endPoints.UPDATE_PASSWORD, "POST", payload);
  }
  
  