import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MarkAsSoldConfirmModal = (props) => {
  const { handleInventoryMarkAsSold, show, onHide, inventoryId } = props;

  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-custom-width"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => onHide(false)}
        ></button>
        <h2 className="text-start f-size-24 mb-2 l-spacing">
          Ready to mark this property as sold?
        </h2>

        <Row>
          <Col xl={12} className="">
            <p className=" mb-4 f-size-16 text-start">
              Marking this property as sold will remove any pending
              introductions sent by you.
            </p>
          </Col>
          <Col xl={12} className="">
            <div className="modal-btn-wrap">
              <Button
                variant=""
                className="link-btn-primary-outline me-3"
                onClick={() => onHide(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="btn"
                onClick={() => handleInventoryMarkAsSold(inventoryId)}
              >
                Mark As Sold
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default MarkAsSoldConfirmModal;
