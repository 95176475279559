import React from "react";

const Loader = (props) => {
  const { loaderText } = props;
  return (
    <div className="overlay-photo-upload">
      <div className="overlay-inner-photo-upload">
        <div className="overlay-content-photo-upload">
          <span className="spinner-photo-upload"></span>
          <span className="spinner-text">{loaderText}</span>
        </div>
      </div>
    </div>
  );
};
export default Loader;
