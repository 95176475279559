import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import HouseTagIcn from "../../images/house-tag-icon.svg";
import ModalWantadMap from "../../images/my-wantad-map.png";
import ExtLinkIcon from "../../images/external-link-icn.svg";
import SampleProfImg from "../../images/sample-profile-image.png";
import LotIcon from "../../images/lot-icon.svg";
import moment from "moment";
import { convertToCurrencySystem, locationDisplay, locationDisplayInListing, removeDecimalZeros, setLocalStorage, thousandSeperatedValue, truncate } from "../../helpers/common";
import { convertToNumber } from "../../helpers/validations";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  IS_PRIVATE_ROOM,
  WANT_AD_KEY,
  IN_CITY_LIMITS,
  RURAL,
  BUDGET_NOMIN_NOMAX,
} from "../../constants/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import noImage from "../../images/no-image-placeholder.png";
import { setLotSizeValue, wantAdBudgetValue, wantAdSqftValue } from "../../helpers/createWantAd";

const ViewWantAdModal = (props) => {
  const { show, onHide, wantAdDetails } = props;
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Close
    </Tooltip>
  );
  const navigate = useNavigate()
  const adDetails = wantAdDetails[0];
  const adTypeValue = _.get(adDetails, "Buy_or_Rent", "");
  const adType = adTypeValue == 1 ? BUY : adTypeValue == 1 ? LONG_RENT : SHORT_RENT;
  const propertyType = convertToNumber(_.get(adDetails, "PropertyTypeId", ""));
  const adTypeBasedOnProperty = propertyType === 6 ? LOT_LAND : adType;
  const nicetohavesData = adDetails?.nice2Have;
  const nicetohaves = nicetohavesData ? nicetohavesData.slice(0, 7) : nicetohavesData;
  const nicetohavesLastData = nicetohavesData ? nicetohavesData.slice(7) : [];
  const details = adType?.Add_Details ? truncate(adType?.Add_Details, 100) : '';
  const [displayMoreNicetoHave, setDisplayMoreNicetoHave] = useState(false);
  const [displayMoreDetails, setDisplayMoreDetails] = useState(false);

  const budget = wantAdBudgetValue(_.get(adDetails, "Budget_mn", ""),_.get(adDetails, "Budget_mx", "") )


  const loadAdMustHaves = () => {
    // let garrageSize = _.get(adDetails, "grgSpce", "") || "Any";
    // if (garrageSize) {
    //   if (garrageSize !== "Any") {
    //     garrageSize = garrageSize + "+";
    //   }
    // }

    // const ltSizeDisplayUnit = _.get(adDetails, "mxLtSzDisplay", null);
    const adaAccess = _.get(adDetails, "adaAccess", "");
    let ltSize = "";
    ltSize = setLotSizeValue( _.get(adDetails, "minLtSz", null), _.get(adDetails, "maxLtSz", null));
  // const maxLtSz = setLotSizeValue(_.get(adDetails, "maxLtSz", null));
    // if (minLtSz && maxLtSz) {
    //   ltSize = `${minLtSz} - ${maxLtSz} ${ltSizeDisplayUnit}`;
    // } else if (!minLtSz && maxLtSz) {
    //   ltSize = `No min - ${maxLtSz} ${ltSizeDisplayUnit}`;
    // } else if (minLtSz && !maxLtSz) {
    //   ltSize = `${minLtSz} - No max`;
    // } else {
    //   ltSize = `No min - No max`;
    // }

    let sqFt = wantAdSqftValue(_.get(adDetails, "Sqft_mn", ""), _.get(adDetails, "Sqft_mx", "") )
    let garrageSize = _.get(adDetails, "grgSpce", "") || "Any";
    if (garrageSize) {
      if (garrageSize !== "Any") {
        garrageSize = garrageSize + "+";
      }
    }

   

    // const budget =
    //   convertToCurrencySystem(_.get(adDetails, "minBdgt", "")) +
    //   " - " +
    //   convertToCurrencySystem(_.get(adDetails, "maxBdgt", ""));
    if (adTypeBasedOnProperty === BUY) {
      // const minStories = _.get(adDetails, "Stories_mn", "");
      // const maxStories = _.get(adDetails, "Stories_mx", "");
     

      // const minLtSz = _.get(adDetails, "LotSz_mn", "");
      // const maxLtSz = _.get(adDetails, "LotSz_mx", "");
      // const lotSize =
      //   minLtSz && maxLtSz ? minLtSz.toString().concat(" - ", maxLtSz) : "Any";

      // const isNwCnstctn = _.get(adDetails, "isNwCnstctn", false);
      // const yrBltMin = _.get(adDetails, "YrBuilt_mn", "");
      // const yrBltMax = _.get(adDetails, "YrBuilt_mx", "");
      // const builtYear = isNwCnstctn
      //   ? "New Construction"
      //   : yrBltMin && yrBltMax
      //     ? yrBltMin.toString().concat(" - ", yrBltMax)
      //     : "Any";

      const yearBuiltOption = _.get(adDetails, "yearBuiltOption", null);
      const builtYrMin = _.get(adDetails, "yrBltMin", "");
      const builtYrMax = _.get(adDetails, "yrBltMax", "");
  
      let builtYear = "";
      if (yearBuiltOption) {
        builtYear =
          yearBuiltOption === NEW_CONSTRUCTION
            ? "New Construction"
            : `${builtYrMin} - ${builtYrMax}`;
      }

      let stories = _.get(adDetails, "stories", null);
      if (stories) {
        stories = stories === STORY_ANY ? "Any" : "Single Story Only";
      }
      let noBathRoom = _.get(adDetails, "Bathrooms", "");
      let noBedRoom = _.get(adDetails, "Bedrooms", "");
      if (noBathRoom != "99") noBathRoom = noBathRoom ? removeDecimalZeros(noBathRoom) + "+" : noBathRoom;
      if (noBedRoom != "99") noBedRoom = noBedRoom ? removeDecimalZeros(noBedRoom) + "+" : noBedRoom;

      return (
        <>
          <div className="musthave-wrap">
            <h6>Must-haves</h6>
            <ul>
              {/* <li className="w-33">
                <span className="musthave-label">Budget</span>
                <span className="musthave-value"> {budget}</span>
              </li> */}
              <li className="w-33">
                <span className="musthave-label">Beds</span>
                <span className="musthave-value">{removeDecimalZeros(noBedRoom == 99) ? 'Any' : (noBedRoom) }</span>
              </li>
              <li className="w-33">
                <span className="musthave-label">Baths</span>
                <span className="musthave-value">{removeDecimalZeros(noBathRoom == 99) ? 'Any' : (noBathRoom)}</span>
              </li>
              <li className="w-33">
                <span className="musthave-label">Sqft</span>
                <span className="musthave-value">
                {sqFt}
               
                </span>
              </li>

            </ul>
            <ul>
              <li className="w-33">
                <span className="musthave-label">Stories</span>
                <span className="musthave-value">
                  {stories}
                </span>
              </li>
              <li  className="w-33">
              <span className="musthave-label">Garage spaces</span>
              <span className="musthave-value">{garrageSize}</span>
            </li>
            {ltSize && (
              <li className="w-33">
                <span className="musthave-label">Lot size</span>
                <span className="musthave-value">{ltSize}</span>
              </li>
            )}

            {builtYear && (
              <li className="w-33">
                <span className="musthave-label">Year Built</span>
                <span className="musthave-value">{builtYear}</span>
              </li>
            )}
            {adaAccess && (
              <li className="w-33">
                <span className="musthave-label">ADA Accessible</span>
                <span className="musthave-value">
                  {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                  Yes
                </span>
              </li>
            )}
            </ul>
          </div>
        </>
      );
    } else if (adTypeBasedOnProperty === LONG_RENT) {
      // let noBathRoom = _.get(adDetails, "noBathRm", "") || "Any";
      // let noBedRoom = _.get(adDetails, "noBedRm", "") || "Any";
      // if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
      // if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
      let noBathRoom = _.get(adDetails, "Bathrooms", "");
      let noBedRoom = _.get(adDetails, "Bedrooms", "");
      if (noBathRoom !== "Any") noBathRoom = noBathRoom ? noBathRoom + "+" : noBathRoom;
      if (noBedRoom !== "Any") noBedRoom = noBedRoom ? noBedRoom + "+" : noBedRoom;
      const alwLargeDogs = _.get(adDetails, "alwLrgDogs", "");
      const alwSmlDogs = _.get(adDetails, "alwSmlDogs", "");
      const alwCats = _.get(adDetails, "alwCats", "");
  
      const alwPets = _.get(adDetails, "alwPets", "");
      const accsPvtBthRm = _.get(adDetails, "accsPvtBthRm", "");
      let stories = _.get(adDetails, "stories", null);
      if (stories) {
        stories = stories === STORY_ANY ? "Any" : "Single";
      }
      return (
        <>
          <div className="musthave-wrap">
            <h6>Must-haves</h6>
            <ul>
              {/* <li className="w-33">
                <span className="musthave-label">Budget</span>
                <span className="musthave-value text-lowercase">
                  {" "}
                  ${budget + "/month"}
                </span>
              </li> */}
              {propertyType !== IS_PRIVATE_ROOM ? (
                <>
                  <li className="w-33">
                    <span className="musthave-label">Beds</span>
                    <span className="musthave-value">{noBedRoom}</span>
                  </li>
                  <li className="w-33">
                    <span className="musthave-label">Baths</span>
                    <span className="musthave-value">{noBathRoom}</span>
                  </li>
                  <li className="w-33">
                    <span className="musthave-label">Sqft</span>
                    <span className="musthave-value">
                    {sqFt}
                  </span>
                  </li>
                  {/* <li className="w-33">
                    <span className="musthave-label">Garage spaces</span>
                    <span className="musthave-value">{garrageSize}</span>
                  </li> */}
                  <li className="w-33">
                  <span className="musthave-label">Garage spaces</span>
                  <span className="musthave-value">{garrageSize}</span>
                </li>
                {stories != 0 && (
                  <li className="w-33">
                    <span className="musthave-label">Stories</span>
                    <span className="musthave-value"> {stories}</span>
                  </li>
                )}

                {alwLargeDogs && (
                  <li className="w-33">
                    <span className="musthave-label">Allows large dogs</span>
                    <span className="musthave-value">
                      {/* {" "}
                      {_.get(formValues, "alwLrgDogs", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {alwSmlDogs && (
                  <li className="w-33">
                    <span className="musthave-label">Allows small dogs</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "alwSmlDogs", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {alwCats && (
                  <li className="w-33">
                    <span className="musthave-label">Allows cats</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "alwCats", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {adaAccess && (
                  <li className="w-33">
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                </>
              ) : (
                <>
                  {" "}
                  {adaAccess && (
                  <li className="w-33">
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                   {alwPets && (
                  <li className="w-33">
                    <span className="musthave-label">Pets allowed</span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "alwPets", "") ? "All" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {accsPvtBthRm && (
                  <li className="w-33">
                    <span className="musthave-label">
                      Access to private bathroom
                    </span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "accsPvtBthRm", null) ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                </>
              )}
            </ul>
          </div>
        </>
      );
    } else if (adTypeBasedOnProperty === LOT_LAND) {
      let onSiteElectricity = _.get(adDetails, "Electricity_Yes_No", "");
      // ----------------------------------commented on 05-03-2023----------------------------------//
      // if (onSiteElectricity === true) {
      //   onSiteElectricity = "Yes";
      // } else if (onSiteElectricity === false) {
      //   onSiteElectricity = "No";
      // } else {
      //   onSiteElectricity = "";
      // }
      // ----------------------------------commented on 05-03-2023----------------------------------//
      if (onSiteElectricity === 1) {
        onSiteElectricity = "Yes";
      } else if (onSiteElectricity === 0) {
        onSiteElectricity = "No";
      } else {
        onSiteElectricity = "Any";
      }

      const lotType = _.get(adDetails, "LotType_Rural_City", null);
      const lotTypeText = lotType && (lotType === 1 ? RURAL : IN_CITY_LIMITS);

      const roadType = _.get(adDetails, "RoadType_City_Ptivate", null);
      const rdTypText =
        roadType && (roadType === 1 ? "City roads" : "Private drive");

      const waterSupply = _.get(adDetails, "Water_DrilledWell_Installed", "");
      const wSText =
        waterSupply &&
        (waterSupply === 1 ? "Domestic water supply" : "Drilled well");

      const wasteWtrSys = _.get(adDetails, "Waste_Sewer_Septic", null);
      const wWSysText =
        wasteWtrSys && (wasteWtrSys === 1 ? "Sewer" : "Septic system");
      return (
        <>
          <div className="musthave-wrap">
            <h6>Must-haves</h6>
            <ul >
              {/* <li className="w-33">
                <span className="musthave-label text-capitalize list-item-color">
                  Budget
                </span>
                <span className="musthave-value"> ${budget}</span>
              </li> */}
                
            {ltSize && (
              <li className="w-33">
                <span className="musthave-label">Lot size</span>
                <span className="musthave-value">{ltSize}</span>
              </li>
            )}
              <li className="w-33">
                <span className="musthave-label text-capitalize list-item-color">
                  Lot Type
                </span>
                <span className="musthave-value">{lotTypeText || "Any"}</span>
              </li>
              <li className="w-33">
                <span className="musthave-label text-capitalize list-item-color">
                  Road Type
                </span>
                <span className="musthave-value">
                  {rdTypText ?? "Any"}

                  {_.get(adDetails, "isPrvtDrInst", "") ? ", Installed" : ""}
                </span>
              </li>
              <li className="w-33">
                <span className="musthave-label text-capitalize list-item-color">
                  Electricity
                </span>
                <span className="musthave-value">{onSiteElectricity}</span>
              </li>
              <li className="w-33">
                <span className="musthave-label text-capitalize list-item-color">
                  Water Supply
                </span>
                <span className="musthave-value">{wSText ?? "Any"}</span>
              </li>
              <li className="w-33">
                <span className="musthave-label text-capitalize list-item-color">
                  Wastewater
                </span>
                <span className="musthave-value">
                  {wWSysText ?? "Any"}
                  {_.get(adDetails, "Waste_Septic_Installed", "") ? ", Installed" : ""}
                  {_.get(adDetails, "Waste_Septic_Approved", "") ? ", Approved" : ""}
                </span>
              </li>
            </ul>{" "}
          </div>
        </>
      );
    } else if (adTypeBasedOnProperty === SHORT_RENT) {
      // let noBathRoom = _.get(adDetails, "noBathRm", "") || "Any";
      // let noBedRoom = _.get(adDetails, "noBedRm", "") || "Any";
      // if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
      // if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
      let noBathRoom = _.get(adDetails, "Bathrooms", "");
      let noBedRoom = _.get(adDetails, "Bedrooms", "");
      if (noBathRoom !== "Any") noBathRoom = noBathRoom ? noBathRoom + "+" : noBathRoom;
      if (noBedRoom !== "Any") noBedRoom = noBedRoom ? noBedRoom + "+" : noBedRoom;

      const alwPets = _.get(adDetails, "alwPets", "");
      const pvtEntrnce = _.get(adDetails, "pvtEntrnce", "");
      const accsPvtBthRm = _.get(adDetails, "accsPvtBthRm", "");
      // let Dates = "";
      // if (
      //   _.get(adDetails, "startDate", "") &&
      //   _.get(adDetails, "endDate", "")
      // ) {
      //   Dates =
      //     formateDate(_.get(adDetails, "startDate", ""), "mm/dd/yyyy") +
      //     " - " +
      //     formateDate(_.get(adDetails, "endDate", ""), "mm/dd/yyyy");
      // } else {
      //   Dates =
      //     durationForFlexibleDate(_.get(adDetails, "duration", "")) +
      //     " in " +
      //     monthNameForFlexibleDate(_.get(adDetails, "month", ""));
      // }

      return (
        <>
          <div className="musthave-wrap">
            <h6>Must-haves</h6>
            <ul >
              <li className="w-33">
                <span className="musthave-label">Budget</span>
                <span className="musthave-value text-lowercase">
                  {" "}

                  { budget != BUDGET_NOMIN_NOMAX ?budget + "/night" : budget}
                </span>
              </li>
              {propertyType !== IS_PRIVATE_ROOM ? (
                <>
                  <li className="w-33">
                    <span className="musthave-label">Beds</span>
                    <span className="musthave-value">{noBedRoom}</span>
                  </li>
                  <li className="w-33">
                    <span className="musthave-label">Baths</span>
                    <span className="musthave-value">{noBathRoom}</span>
                  </li>
                  {adaAccess && (
                    <li>
                      <span className="musthave-label">ADA Accessible</span>
                      <span className="musthave-value">
                        {/* {_.get(formValues, "adaAccess", "") ? "Yes" : "No"} */}
                        Yes
                      </span>
                    </li>
                  )}
                  {alwPets && (
                    <li>
                      <span className="musthave-label">Pets allowed</span>
                      <span className="musthave-value">
                        {/* {allowPets ? "All" : "No"} */}
                        Yes
                      </span>
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li className="w-33">
                    <span className="musthave-label">ADA Accessible</span>
                    <span className="musthave-value">
                      {" "}
                      {_.get(adDetails, "adaAccess", "") ? "Yes" : "No"}
                    </span>
                  </li>
                  <li>
                    <span className="musthave-label">Pets allowed</span>
                    <span className="musthave-value">
                      {allowPets ? "All" : "No"}
                    </span>
                  </li>
                </>
              )}

{accsPvtBthRm && (
                  <li>
                    <span className="musthave-label">
                      Access to private bathroom
                    </span>
                    <span className="musthave-value">
                      {/* {_.get(formValues, "accsPvtBthRm", null) ? "Yes" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
                {pvtEntrnce && (
                  <li>
                    <span className="musthave-label">Private entrance</span>
                    <span className="musthave-value">
                      {/* {allowPets ? "All" : "No"} */}
                      Yes
                    </span>
                  </li>
                )}
            </ul>
            {/* {propertyType !== IS_PRIVATE_ROOM && (
              <ul >
                <li className="w-33">
                  <span className="musthave-label">ADA Accessible</span>
                  <span className="musthave-value">
                    {" "}
                    {_.get(adDetails, "ADA_Accessible", "") ? "Yes" : "No"}
                  </span>
                </li>
                <li className="w-33">
                  <span className="musthave-label">Pets allowed</span>
                  <span className="musthave-value">
                    {allowPets ? "All" : "No"}
                  </span>
                </li>
              </ul>
            )} */}
          </div>
        </>
      );
    }
  };

  const openInNewTab = () => {
    setLocalStorage(WANT_AD_KEY, { wantAdId: adDetails?.Want_ad_Id, isFromAllIntroduction: true });
    window.open(pageURLs?.wantAdDetails, "_blank")
  }

  return (
   
    <Modal
      show={show}
      {...props}
      backdrop="static"
      aria-labelledby="accept-modal-title-vcenter"
      className="client-notes-modal accept-intro-modal right"
    >
      <Modal.Body className="pb-26">
        <div className="client-notes-modal-top pb-20 mb-0">
          <div className="flex-grow-1">
            <div className="modal-subhead-medium long-txt-fix">{adDetails?.Title_Text}</div>

            <div className="modal-subhead-small long-txt-fix">  
            {locationDisplayInListing(adDetails)}
             {/* Within {_.get(adDetails, "Radius", "")} mi of {_.get(adDetails, "City", "")}{", "}{_.get(adDetails, "State", "")} */}
             </div>
            <div className="modal-subhead-extrasmall">{adDetails?.IsAccept == 1 ? 'ACCEPTED' : 'SENT'} {moment(new Date(adDetails?.Intro_Sent_Date)).format("MM/DD/YYYY")}</div>
            <h5>{budget}</h5>
          </div>
          <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
          <button
            type="button"
            class="btn btn-slide-right flex-shrink-0"
            aria-label="Close"
            onClick={() => onHide(false)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.781 12.781L5.28104 20.281C5.14031 20.4218 4.94944 20.5008 4.75042 20.5008C4.55139 20.5008 4.36052 20.4218 4.21979 20.281C4.07906 20.1403 4 19.9494 4 19.7504C4 19.5514 4.07906 19.3605 4.21979 19.2198L11.1901 12.2504L4.21979 5.28104C4.07906 5.14031 4 4.94944 4 4.75042C4 4.55139 4.07906 4.36052 4.21979 4.21979C4.36052 4.07906 4.55139 4 4.75042 4C4.94944 4 5.14031 4.07906 5.28104 4.21979L12.781 11.7198C12.8508 11.7894 12.9061 11.8722 12.9438 11.9632C12.9816 12.0543 13.001 12.1519 13.001 12.2504C13.001 12.349 12.9816 12.4466 12.9438 12.5376C12.9061 12.6287 12.8508 12.7114 12.781 12.781ZM20.281 11.7198L12.781 4.21979C12.6403 4.07906 12.4494 4 12.2504 4C12.0514 4 11.8605 4.07906 11.7198 4.21979C11.5791 4.36052 11.5 4.55139 11.5 4.75042C11.5 4.94944 11.5791 5.14031 11.7198 5.28104L18.6901 12.2504L11.7198 19.2198C11.5791 19.3605 11.5 19.5514 11.5 19.7504C11.5 19.9494 11.5791 20.1403 11.7198 20.281C11.8605 20.4218 12.0514 20.5008 12.2504 20.5008C12.4494 20.5008 12.6403 20.4218 12.781 20.281L20.281 12.781C20.3508 12.7114 20.4061 12.6287 20.4438 12.5376C20.4816 12.4466 20.501 12.349 20.501 12.2504C20.501 12.1519 20.4816 12.0543 20.4438 11.9632C20.4061 11.8722 20.3508 11.7894 20.281 11.7198Z"
                fill="black"
              />
            </svg>
          </button>
          </OverlayTrigger>
        </div>
        <div className="modal-banner-outerwrap pb-20">
          <div className="modal-banner-wrap">
            <div className="modal-property-tile tile-loation">
              {adTypeBasedOnProperty === LOT_LAND ? (
                <img src={LotIcon} alt={LotIcon} />
              ) : (
                <img src={HouseTagIcn} />
              )
              }


            </div>
            
            <img src={adDetails?.Location_Img || ModalWantadMap} />

          </div>
        </div>

        {loadAdMustHaves()}
        {(nicetohaves?.length > 0) &&
          <div className="nicetohaves-wrap">
            <h6 className="pb-12 mb-0">Nice to haves</h6>
            <div className="nicetohaves-inner-wrap">
              {nicetohaves &&
                nicetohaves.map((nicetohave) => {
                  return (
                    <div class="nicetohaves-tag">
                      <div class="nicetohaves-tag-icon">
                        <img src={nicetohave?.icon ? require("../../images/" + nicetohave?.icon) : ''} alt='' />
                      </div>
                      <span>{nicetohave?.text}</span>
                    </div>
                  )
                })}

              {nicetohavesLastData && displayMoreNicetoHave &&
                nicetohavesLastData.map((nicetohave) => {
                  return (
                    <div class="nicetohaves-tag">
                      <div class="nicetohaves-tag-icon">
                        <img src={nicetohave?.icon ? require("../../images/" + nicetohave?.icon) : ''} alt='' />
                      </div>
                      <span>{nicetohave?.text}</span>
                    </div>
                  )
                })}

            </div>
            {nicetohavesLastData.length > 0 && !displayMoreNicetoHave &&
              <a role="button" href={void(0)} className="show-more-link" onClick={() => { setDisplayMoreNicetoHave(true) }}>Show more</a>
            }
          </div>

        }

        {adDetails?.Add_Details &&
          <div className="modal-addl-details">
            <div className="modal-addl-head">Additional details</div>
            {!displayMoreDetails ?
              <p className="long-txt-fix">{details}</p>
              :
              <p className="long-txt-fix">{adDetails?.Add_Details}</p>

            }
            {details.length > 100 && !displayMoreDetails &&
              <a role="button" href={void(0)} className="show-more-link" onClick={() => { setDisplayMoreDetails(true) }}>Show more</a>
            }

            {/* <p className="long-txt-fix">{adDetails?.Add_Details}</p> */}
          </div>
        }

        {adDetails?.UserTypeId == 2 && adDetails?.IsAccept != 1 &&
          <div class="nicetohaves-wrap">

            <div className="add-profile-wrap">
              <div className="prof-img mr-12">
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                    fill="#A0A9BA"
                  />
                </svg>
              </div>

              <h6>
                Represented by Agent <span class="pro-blue-badge">PRO</span>
              </h6>
              

            </div>
          </div>
        }

        {(adDetails?.IsAccept == 1 && adDetails?.UserTypeId == 2) &&

          <div class="nicetohaves-wrap">
            <div className="profile-pic-sec mb-0">
              <figure  className="brdr-rs-200"><img className="brdr-rs-200" src={adDetails?.Img? adDetails?.Img :  noImage} /></figure>
              <div className="profile-name">
                <h6 className="d-flex mb-1">{adDetails?.FirstName + ' ' +adDetails?.LastName}<span class="pro-blue-badge">PRO</span></h6>
                <p className="position">Agent @ {adDetails?.Company}</p>
                <a role="button" onClick={()=>{props.ViewProfileModal(adDetails?.Want_Ad_UserId)}}>View bio</a>
              </div>
            </div>
          </div>
        }
        {(adDetails?.IsAccept == 1 && adDetails?.UserTypeId == 1) &&

          <div class="nicetohaves-wrap">

            <div className="profile-pic-sec mb-0">
              <div className="profile-placeholder-wrap profile-bg-orange">
                JS
              </div>
              <div className="profile-name">
                <h6 className="d-flex mb-0">{adDetails?.FirstName + ' ' +adDetails?.LastName} </h6>
                <p className="position">Joined {moment(new Date(adDetails?.JoinedDate)).format("MMMM YYYY")}</p>
              </div>
            </div>
          </div>
        }

        <div class="buyer-info buyer-info-btmborder">
          <h6>Buyer details</h6>
          <ul className="mb-2">
            <li className="consumer-bg"> {adDetails?.UserTypeId == 2 ? 'Represented by Agent' : 'Consumer'}</li>
            {adDetails?.TimeLineText &&
              <li className="timeline-bg">Timeline:  {adDetails?.TimeLineText}</li>
            }
            {adDetails?.PurchaseTypeText &&
              <li className="home-bg">{adDetails?.PurchaseTypeText}</li>
            }
            {adDetails?.FinanceTypeText &&
              <li className="finance-bg">Financing: {adDetails?.FinanceTypeText}
             { (_.get(adDetails, "Finance_IsApprovedForLoan", "") == 1) &&
                  " - pre approved"
                }
                </li>
            }
          </ul>
        </div>
        <div className="modal-btn-wrap pe-3 ps-3 mt-4">

          <Button variant="primary" className="w-100 btn-fix" onClick={() => { openInNewTab() }}>
            Open In New Tab
            <img src={ExtLinkIcon} alt={ExtLinkIcon} />
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  );
};
export default ViewWantAdModal;
