/**
 * @file   Source/gitcha_Web/src/pages/Payments.js
 * @brief  This file is responsible for handling payments section in Account settings.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchPaymentMethods} from "../actions/payments";
import { updateDefaultPayment } from "../services/paymentsService";
import RemoveCardModal from "../components/MemberWantAd/RemoveCardModal";
import AddPaymentModalStripe from "../components/Payments/AddPaymentModalStripe";
import WantAdMoreIcon from "../images/want-ad-more-icon.svg";
import PaymentHistory from "./PaymentHistory";
import {getCardIcon, getUserId} from "../helpers/common";


const Payments = ({ ...props }) => {
  const dispatch = useDispatch()
  const [reload, setReload] = useState(0)
  const [showAddPaymentModal, setAddPaymentModal] = useState(false);
  const AddPaymentModalClose = () => setAddPaymentModal(false);
  const AddPaymentModalShow = () => setAddPaymentModal(true);
  const [showRemoveCardModal, setRemoveCardModal] = useState(false);
  const RemoveCardModalClose = () => setRemoveCardModal(false);
  const RemoveCardModalShow = () => setRemoveCardModal(true);
  const userDetails = useSelector((state) => state.userProfile.userDetails);
  const paymentMethods = useSelector((state)=>state.paymentMethods.paymentMethods)
  const [userPaymentMethods, setUserPaymentmethods] = useState(paymentMethods)
  const [currentPaymentMethod, setcurrentPaymentMethod] = useState(null)
  const userId = getUserId();
  const {currentTab, addNewCard} = props;
  useEffect(()=>{
    dispatch(fetchPaymentMethods())
  },[userId, reload])

  useEffect(()=>{
    if(addNewCard){
      AddPaymentModalShow();
    }
  }, [addNewCard])

  useEffect(()=>{
    if(paymentMethods && paymentMethods.length>0) {
      const defaultArray = paymentMethods?.filter((item)=>item.IsDefault===1)
      const OtherMethods = paymentMethods?.filter((item)=>item.IsDefault!==1)
      setUserPaymentmethods(defaultArray.concat(OtherMethods))
    }
    else {
      setUserPaymentmethods([])
    }
  },[paymentMethods])  


  const handleClickRemoveCard = (paymentMethod) => {
    setcurrentPaymentMethod(paymentMethod)
    RemoveCardModalShow()
  }

  const updateReloadParam = () => {
    setReload(reload+1)
  }

  const handleSetAsDefault = async (paymentMethod) => {
    const payload = {
      PaymentMethodId : paymentMethod?.Payment_Mode_Id
    }
    await updateDefaultPayment(payload)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.dismiss()
          toast.success("Successfully updated the default payment method..")
          setTimeout(()=>{
            updateReloadParam()
          }, 500)
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  return (
    <>
      {!userPaymentMethods?.length>0 ? 
      (<div className="payments-wrap">
        <div className="title-sec">
          <h4>Payment method</h4>
        </div>
        <Button
          variant="primary"
          className="mt-3"
          onClick={AddPaymentModalShow}
        >
          Add Payment Method
        </Button>
      </div>) : 
      (<div className="payments-wrap">
        <div className="title-sec">
          <h4>Payment method</h4>
          <a role="button" className="add-card" onClick={AddPaymentModalShow}>
            Add
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.91669 7C1.91669 6.75838 2.11256 6.5625 2.35419 6.5625H11.9792C12.2208 6.5625 12.4167 6.75838 12.4167 7C12.4167 7.24162 12.2208 7.4375 11.9792 7.4375H2.35419C2.11256 7.4375 1.91669 7.24162 1.91669 7Z"
                fill="#022964"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.16669 1.75C7.40831 1.75 7.60419 1.94588 7.60419 2.1875V11.8125C7.60419 12.0541 7.40831 12.25 7.16669 12.25C6.92506 12.25 6.72919 12.0541 6.72919 11.8125V2.1875C6.72919 1.94588 6.92506 1.75 7.16669 1.75Z"
                fill="#022964"
              />
            </svg>
          </a>
        </div>
        {userPaymentMethods?.map((paymentMethod, key=index)=>{
          return (
            <div className="credit-card-wrap">
              <div className="card-info-sec">
                <div className="card-logo">
                  <img src={getCardIcon(paymentMethod.Brand)}/>
                </div>
                <div className="card-data">
                  <div className="number">
                    <p>{paymentMethod.Brand} *****{paymentMethod.Last4}</p>{" "}
                    {paymentMethod?.IsDefault===1 && <span className="table-badge-blue">Default</span>}
                  </div>
                  <p className="expiry">Expiration : {paymentMethod?.Exp_Month<10? '0'+paymentMethod?.Exp_Month:''+paymentMethod?.Exp_Month}/{String(paymentMethod?.Exp_Year).slice(-2)}</p>
                </div>
              </div>
              <div className="card-action">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" variant="" align="end">
                    <img src={WantAdMoreIcon} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dpdown-menu-style" align={"end"}>
                    <Dropdown.Item onClick={()=>handleClickRemoveCard(paymentMethod)}>
                      Remove card
                    </Dropdown.Item>
                    {paymentMethod?.IsDefault!==1 && <Dropdown.Item onClick={()=>handleSetAsDefault(paymentMethod)}>Set as default</Dropdown.Item>}
                    {/* <Dropdown.Item>Last year</Dropdown.Item>
                    <Dropdown.Item>All time</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )
        })}        
      </div>)}
      <PaymentHistory currentTab={currentTab}/>
      <AddPaymentModalStripe
        show={showAddPaymentModal}
        updateReloadParam={updateReloadParam}
        onHide={AddPaymentModalClose}
      />
      <RemoveCardModal
        show={showRemoveCardModal}
        currentPaymentMethod={currentPaymentMethod}
        updateReloadParam={updateReloadParam}
        onHide={RemoveCardModalClose}
      />
    </>
  );
};

export default Payments;
