import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextArea from "../../components/Common/TextArea";

const ReasonNotAFitModal = (props) => {
  const {show, onHide} = props;

 
  const intitalCheckListStates =  {
  locNotFit : false,
  notMyStyle: false ,
  price: false,
  size: false,
  other: false
}
  const [notFitReasons, setNotFitReasons] = useState(intitalCheckListStates);
  const [isSubmitDisabled, setSubmitDisable] = useState(false);
  const [detailsCount, setDetailsCount] = useState(0);
  const [reasonDetails, setDetails] = useState('');
  useEffect(() => {
    if (
      _.get(notFitReasons, "locNotFit", false) ||
      _.get(notFitReasons, "notMyStyle", false) ||
      _.get(notFitReasons, "price", false) ||
      _.get(notFitReasons, "size", false) ||
      _.get(notFitReasons, "other", false) ||
      reasonDetails
    ) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
    setDetailsCount(reasonDetails && reasonDetails.length ? reasonDetails.length : 0);
  }, [notFitReasons, reasonDetails]);

  const handleDescriptionChange = (e) => {
    const value = capitalizeFirstString(handleTextBoxChange(e));
    if (trimString(value) !== "") {
      setDetailsCount(value.length);
      setDetails(value);
    } else {
      setDetails('');
    }
  };
  const checkListOnchnge = (e) => {
    const key = e.target.id;
    setNotFitReasons((prevState) => ({
      ...prevState,
      [key]: e.target.checked,
    }));
  };

 

  const buildPayload = (isSkip) => {
    const checkLists = isSkip ? intitalCheckListStates : notFitReasons
    const description = isSkip ? '' : reasonDetails
    return {
       IntroductionId : introductionId,
       UserId : _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", ""),
       Location_Not_Right : _.get(checkLists, 'locNotFit', ''),
       Not_my_Style : _.get(checkLists, 'notMyStyle', ''),
       Price : _.get(checkLists, 'price', ''),
       Size : _.get(checkLists, 'size', ''),
       Other : _.get(checkLists, 'other', ''),
       Reason_Text : description
      }
  }

  const handleButtonClick = async (isSkip = false) => {
    const  reqPayload = buildPayload(isSkip)
    await updateToNotFit(reqPayload, isSkip)
  };
  const checkListArray = [
    { text: "Location wasn’t right for me", id: "locNotFit" },
    { text: "Not my style", id: "notMyStyle" },
    { text: "Price", id: "price" },
    { text: "Size", id: "size" },
    { text: "Other", id: "other" },
  ];
  
  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup not-fit-modal">
        <Modal.Body className="text-center">
        <h3>Tell why their introduction wasn’t a good fit.</h3>
        <p>This will help send better, more accurate introductions in the future.</p>
        <p className="note">Reason for ending conversation:</p>
        <div className="modal-controls-wrap">
          {checkListArray.map((item, index) => {
            return (
              <label className="check-wrap mb-3">
                <input
                  type="checkbox"
                  name="check-confirm-option"
                  id={_.get(item, "id", "")}
                  onChange={checkListOnchnge}
                />
                <span>{_.get(item, "text", "")}</span>
              </label>
            );
          })}
        </div>
        <div className="textbox-wrap">
              <TextArea
                className="form-control txt-area"
                errorMessage=""
                value={reasonDetails}
                maxLength={500}
                placeholder="Enter reason here"
                onChange={(e) => {
                  handleDescriptionChange(e);
                }}
                disabled
              />
            </div>
              
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={onHide} disabled>Submit</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Skip</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default ReasonNotAFitModal;
