import React, { useState, useEffect } from "react";
import ConfirmMap from "../../images/confirm-map.png";
import LakeViewIcon from "../../images/lake-view-icon.svg";
import ConsumerIcon from "../../images/consumer-icon.svg";
import HouseTagIcon from "../../images/house-tag-icon.svg";
import LotIcon from "../../images/lot-icon.svg";
import  MinCapIcon from "../../images/minimum-icn.svg";

import {
  loadPreferredLocationHtml,
  loadTimeLineHtml,
  locationDisplay,
} from "../../helpers/common";
import { getNiceToHaves } from "../../services/commonServices";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  BUY_LAND,
  IS_LAND,
  IS_MULTI_FAMILY,
  INVESTOR_MEMBER_PLAN,
} from "../../constants/common";
import { useNavigate } from "react-router-dom";
import { convertToNumber } from "../../helpers/validations";
import FinancingIcon from "../../images/financing-icon.svg";
import PrResIcon from "../../images/primary-residence-icon.svg";
import { buildWantAdMustHavesHTML, loadInvestmentCriteria } from "../../helpers/createWantAd";
import { getCombinedText, getPropertyIcon } from "../../helpers/getPropertyIcons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const WantAdConfirmation = (props) => {
  const navigate = useNavigate();

  const { formValues, handleContinueButtonState, userPlan } = props;
  const [niceToHavesList, setNiceToHavesList] = useState([]);
  const adType = _.get(formValues, "adType", "");
  const propertyType = convertToNumber(_.get(formValues, "prprtyType", ""));
  const adTypeBasedOnProperty = propertyType === IS_LAND ? LOT_LAND : adType;
  const nice2Have = _.get(formValues, "nice2Have", []);
  const mapImage = _.get(formValues, "mapImg", null);
  const consumerType = adType === BUY ? "Buyer" : "Renter";
  const propertySubTypes = _.get(formValues, "prptySubTypes", []);
  const purchaseType = convertToNumber(_.get(formValues, "purchaseType", null)) || convertToNumber(_.get(formValues, "prchTypeId", null));
  const isInvestor = userPlan===INVESTOR_MEMBER_PLAN ? true : false;
  

  useEffect(() => {
    getNiceToHaves(
      adTypeBasedOnProperty === LOT_LAND ? BUY_LAND : adTypeBasedOnProperty
    )
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          setNiceToHavesList(list);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    const isDisable = false;
    handleContinueButtonState(isDisable);
  }, []);

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    const masterArray = _.get(niceToHavesList, section, []);
    if (selectedItems.length) {
      return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
    } else {
      return "";
    }
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray =
      adTypeBasedOnProperty === LOT_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    return finalList;
  };

  const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        const icon = _.get(masterItem[0], "Icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueKey}>
            <div className="nicetohaves-tag-icon">
              <img
                src={icon ? require("../../images/" + icon) : LakeViewIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };

  const isNiceToHaveExist = nice2Have.length > 0;
  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
{propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY  ? 
      getTooltipText(propertyType) 
      : propertySubTypes &&
        propertySubTypes.length > 0 &&
        getCombinedText(propertySubTypes)}
    </Tooltip>
  );


  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }

    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );
  };

  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(formValues);
  };

  const loadStrengthenDetails = () => {
    if (adType === BUY) {
      return (
        <>
          {" "}
          {(_.get(formValues, "prchTypeTxt", "") ||
            _.get(formValues, "purchaseText", "")) && (
            <li>
              <span className="list-icn-wrap">
                <img src={PrResIcon} />
              </span>
              {_.get(formValues, "prchTypeTxt", "") ||
                _.get(formValues, "purchaseText", "")}
                  {(_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
            </li>
          )}
          {(_.get(formValues, "finTypeTxt", "") ||
            _.get(formValues, "pFinanceText", "")) && (
            <li>
              <span className="list-icn-wrap">
                <img src={FinancingIcon} />
              </span>
              Financing:{" "}
              {_.get(formValues, "finTypeTxt", "") ||
                _.get(formValues, "pFinanceText", "")}
              {(_.get(formValues, "isPreApprLoan", "") == "Yes" ||
                _.get(formValues, "isPreApprLoan", "") == 1) &&
                " - pre approved"}
            </li>
          )}
        </>
      );
    } else if (adType === LONG_RENT) {
      return (
        <>
          {" "}
          {_.get(formValues, "creditScoreText", "") && (
            <li>
              <span className="list-icn-wrap">
                <img src={FinancingIcon} />
              </span>
              Credit Score : {_.get(formValues, "creditScoreText", "")}
            </li>
          )}
        </>
      );
    }
  };

  const loadTimeLine = () => {
    return loadTimeLineHtml(adType, adTypeBasedOnProperty, formValues);
  };

  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      formValues: formValues,
    });
  };

  return (
    <div className="center-content-sec mw-100">
      <div className="wantad-confirmation-wrap">
        <div className="confirmation-top-sec d-md-flex justify-content-between">
          <div>
            <div class="confirm-sub-title mb-1">
              I'm looking to:
              {loadAdTitle()}
            </div>
            <h2 className="long-txt-fix">
              {_.get(formValues, "adTitle", "")}{" "}
              {/* <span className="grey-dot">&bull;</span>{" "} */}
            </h2>
            <span className="trailing-txt long-txt-fix">
              {locationDisplay(formValues)}

              {/* {_.get(formValues, "location", "")} */}
              {/* Within {_.get(formValues, "mapRadius", "")} mi of{" "}
              {_.get(formValues, "city", "")}
              {", "}
              {_.get(formValues, "state", "")} */}
            </span>
          </div>
          <button
            type="button"
            className="btn btn-outline-primary align-self-start"
            onClick={() => navigate("/edit-want-ad",{ state: { data: formValues, isFromWantAdConfirmation : true } })}
          >
            Edit
          </button>
        </div>

        <div className="confirm-map-wrap">
        <OverlayTrigger
                      placement="bottom-start"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
          <div className="property-tile w-auto">
            {
               propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY  ? (
                <img
                  src={getPropertyIcon(propertyType)}
                  alt={getPropertyIcon(propertyType)}
                  className="property-icon-img"
                />
              ) : (
            propertySubTypes &&
            propertySubTypes.length > 0 &&
            propertySubTypes.map((subtype) => {
              return (
                <img
                  src={getPropertyIcon(subtype)}
                  alt={getPropertyIcon(subtype)}
                  className="property-icon-img"
                />
              );
            })
              )
          }
       
          
          </div>
          </OverlayTrigger>

          <img
            src={mapImage + "?" + Math.random().toString(36) || ConfirmMap}
            className="disp-map-img-fixed"
          />
        </div>

        {loadAdMustHaves()}
        {isNiceToHaveExist ? (
          <div className="nicetohaves-wrap">
            <h2>Nice-to-haves</h2>
            <div className="nicetohaves-inner-wrap">
              {finalNiceToHaveList()}
            </div>
          </div>
        ) : (
          ""
        )}
        {_.get(formValues, "adDesc", "") ? (
          <div className="additional-details-wrap">
            <h2>Description</h2>
            <p className="long-txt-fix">{_.get(formValues, "adDesc", "")}</p>
          </div>
        ) : (
          ""
        )}

        <div className="buyer-info">
          {/* <h2>{consumerType} Information</h2> */}
          <h2>Additional details</h2>
          <ul>
            <li>
              <span className="list-icn-wrap">
                <img src={ConsumerIcon} />
              </span>
              {consumerType} {isInvestor ? <span className="pro-gold-badge">Investor Member</span> : ""}
            </li>

            {loadTimeLine()}
            {loadPreferredLocation()}
            {loadStrengthenDetails()}
          </ul>
        </div>

        {isInvestor && purchaseType===3 && 
        <div className="buyer-info">
          {_.get(formValues, "investmentId", [])?.length>0 || _.get(formValues, "capRateId", null) ? <h2>What This Investor is Open to</h2>: ""}
          <ul>
            {loadInvestmentCriteria(formValues)}
            {_.get(formValues, "capRateId", null) && <li>
              <span className="list-icn-wrap">
                <img src={MinCapIcon} />
              </span>
              Minimum cap rate of {_.get(formValues, "capRateId", "")==15 ? "15+" : _.get(formValues, "capRateId", "")}%
            </li>}
          </ul>
        </div>}
      </div>
    </div>
  );
};
export default WantAdConfirmation;
