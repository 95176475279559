import React, { useState, useEffect } from "react";
import mapImg01 from "../../images/map.png";
import ProgressBarInventory from "./ProgressBarInventory";
import InventoryLocation from "./InventoryLocation";
import {
  DEFAULT_INVENTORY_MAP_ZOOM,
  DEFAULT_MAP_ZOOM,
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  IS_LAND,
  IS_PRIVATE_ROOM,
} from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
import GeoLocation from "../../../components/Common/GeoLocation";
import MustHavesProperties from "./MustHavesProperties";
import { checkIsNull, createPreSignedUrl, createS3Folder, createStaticMapImage, getLocalStorage, getUserId, setLocalStorage, trimString, yearValidation } from "../../../helpers/common";
import { uploadMapImageToS3 } from "../../../helpers/S3/uploadToS3";
import { yrMaxInValid } from "../../../constants/createWantAd";
import MustHavesLand from "./MustHavesLand";

const AddLocationLotLand = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    completedPercentage,
    setCompletedPercentage,
    isAgent,
    uiKey,
    isEdit
  } = props;
  const locationAddress =_.get(formValues, "locAddress", null);
  const propertyType = _.get(formValues, "prprtyType", null);
  const acreage = _.get(formValues, "acreage", null);
  const lotType = convertToNumber(_.get(formValues, "lotType", null));
  const roadType = convertToNumber(_.get(formValues, "roadType", null));
  const isPowerAccess = _.get(formValues, "isPowerAccess", null);
  const waterSuply = convertToNumber(
    _.get(formValues, "waterSuply", null)
);
const wasteWtrSys = convertToNumber(
    _.get(formValues, "wasteWtrSys", null)
);
const isApprovedStrctr = _.get(formValues, "isApprovedStrctr", null);
const isInFloodPlain = _.get(formValues, "isInFloodPlain", null);
const lat = _.get(formValues, "lat", null);
const lng = _.get(formValues, "lng", null);
const mapZoom = _.get(formValues, "mapZoom", null);
const isPrvtDrInst = _.get(formValues, "isPrvtDrInst", null);
const isDrldWelInst = _.get(formValues, "isDrldWelInst", null);
const isDrldWelAppr = _.get(formValues, "isDrldWelAppr", null);
const isSepTankInst = _.get(formValues, "isSepTankInst", null);
const isSepTankAppr = _.get(formValues, "isSepTankAppr", null);
const mapType = _.get(formValues, "mapType", null);
const isPropertyExist = _.get(formValues, "isPropertyExist", null);
const [isImageUploaded, setIsImageUploaded] = useState(false);
const acre_Sqft= _.get(formValues, "acre_Sqft", null)


const locationAddressInSession =_.get(getLocalStorage(INVENTORY_KEY), "locAddress", null);
  const acreageInSession = _.get(getLocalStorage(INVENTORY_KEY), "acreage", null);
  const lotTypeInSession = convertToNumber(_.get(getLocalStorage(INVENTORY_KEY), "lotType", null));
  const roadTypeInSession = convertToNumber(_.get(getLocalStorage(INVENTORY_KEY), "roadType", null));
  const isPowerAccessInSession = _.get(getLocalStorage(INVENTORY_KEY), "isPowerAccess", null);
  const waterSuplyInSession = convertToNumber(
    _.get(getLocalStorage(INVENTORY_KEY), "waterSuply", null)
);
const wasteWtrSysInSession = convertToNumber(
    _.get(getLocalStorage(INVENTORY_KEY), "wasteWtrSys", null)
);
const isApprovedStrctrInSession = _.get(getLocalStorage(INVENTORY_KEY), "isApprovedStrctr", null);
const isInFloodPlainInSession = _.get(getLocalStorage(INVENTORY_KEY), "isInFloodPlain", null);
const isPrvtDrInstInSession = _.get(getLocalStorage(INVENTORY_KEY), "isPrvtDrInst", null);
const isDrldWelInstInSession = _.get(getLocalStorage(INVENTORY_KEY), "isDrldWelInst", null);
const isDrldWelApprInSession = _.get(getLocalStorage(INVENTORY_KEY), "isDrldWelAppr", null);
const isSepTankInstInSession = _.get(getLocalStorage(INVENTORY_KEY), "isSepTankInst", null);
const isSepTankApprInSession = _.get(getLocalStorage(INVENTORY_KEY), "isSepTankAppr", null);
const acre_SqftInSession = _.get(getLocalStorage(INVENTORY_KEY), "acre_Sqft", null)
const handleContinueButton = () => {
  let isDisable;
  if (trimString(locationAddress) !== "" && acreage && lotType) {
    isDisable = false;
  } else {
    isDisable = true;
  }
  
  setDisableSave(isDisable);
};

useEffect(()=>{
  if(isEdit && 
    locationAddress=== locationAddressInSession &&
    convertToNumber(acreage)=== convertToNumber(acreageInSession) &&
    lotType===lotTypeInSession &&
    roadType=== roadTypeInSession &&
    isPowerAccess===isPowerAccessInSession &&
    waterSuply===waterSuplyInSession &&
    isApprovedStrctr=== isApprovedStrctrInSession &&
    isInFloodPlain===isInFloodPlainInSession &&
    isPrvtDrInst===isPrvtDrInstInSession &&
    isDrldWelInst===isDrldWelInstInSession &&
    isDrldWelAppr===isDrldWelApprInSession &&
    isSepTankInst=== isSepTankInstInSession &&
    isSepTankAppr===isSepTankApprInSession &&
    convertToNumber(acre_Sqft) === convertToNumber(acre_SqftInSession)
    ){
      setDisableSave(true)
    }
    else{
      handleContinueButton()
    }
},[locationAddress, acreage, lotType, roadType, isPowerAccess, waterSuply, wasteWtrSys, isApprovedStrctr, isInFloodPlain, isPrvtDrInst, isDrldWelInst, isDrldWelAppr, isSepTankInst, isSepTankAppr,acre_Sqft])

  useEffect(() => {
    if (!locationAddress) {
      const inventoryDetails = getLocalStorage(INVENTORY_KEY);
      const s3Folder = _.get(inventoryDetails, "s3ImageFolder", null);
      const userId = getUserId();
      if (!s3Folder) {
        getFolderName(userId);
      }
    } 
  }, []);

  const getFolderName = async (userId) => {
    const isWantAd = false;
    await createS3Folder(userId, isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };

  const [disableSave, setDisableSave] = useState(true);
  const isPrivateRoom = propertyType === IS_PRIVATE_ROOM;

  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_INVENTORY_MAP_ZOOM,
  };

  const updateValuesToState = (key, value) => {
    handleFormValues({ [key]: value });
  };

  const handleMapImageUpload = async () => {
    setIsImageUploaded(false);
    const lat = _.get(formValues, "lat", "");
    const lng = _.get(formValues, "lng", "");
    const mapZoom = _.get(formValues, 'mapZoom', DEFAULT_MAP_ZOOM)
    const mapResponse = await createStaticMapImage(lat, lng, mapZoom )
    const preSignedUrlRes = mapResponse ? await createPreSignedUrl(false, _.get(formValues, "s3ImageFolder", "")) : alert('Something went wrong while fetching map image.');
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if(s3FullUrl){
        const imageUrl = s3FullUrl
        ? s3FullUrl.split("?")[0]
        : "";
        handleFormValues({mapImg : imageUrl})
        setIsImageUploaded(true);

      }     
    }
  };


  useEffect(()=>{
    if(isImageUploaded){
      // if (isAgent) {
      //   if (completedPercentage > 20) {
      //     setCompletedPercentage(completedPercentage);
      //   } else {
      //     setCompletedPercentage(20);
      //   }
      // } else {
        if (completedPercentage > 25) {
          setCompletedPercentage(completedPercentage);
        } else {
          setCompletedPercentage(25);
        }
      // } 
      setTimeout(()=>{         
        setLocalStorage(
          INVENTORY_KEY,
          getLocalStorage(INVENTORY_KEY_TEMP)
        );
        setUIKey(null)
      },500)
  }

  },[isImageUploaded])

  return (
    <>
    {!locationAddress && !lat && !lng && (
        <GeoLocation handleFormValues={handleFormValues} />
      )}
      <div className="gitcha-main-wrapper pb-102">
        <div className="content-wrapper content-wrapper-h-102">
          <ProgressBarInventory 
          setUIKey={setUIKey}
          completedPercentage={completedPercentage} 
          uiKey={uiKey}
          isEdit={isEdit}
          />

          <div className="inner-layout-content-new min-h-100 bg-white pl-16 pr-16 pb-0 ">
            <div>
              <p className="mt-0 mb-2 fnt12">LOCATION</p>
              {isAgent ? <h2 className="mt-0 pt-0 mb-2 titleText">Let’s find your client’s property. We’ll take care of the details.</h2> : 
              <h2 className="mt-0 pt-0 mb-2 titleText">
                Let’s find your property. We’ll take care of the details.
              </h2>}
              <p className="fnt14">
                If {isAgent ? "the" : "your"} property doesn’t have an address, enter a nearby street
                name and adjust the pin on the map.
              </p>
            </div>
            <InventoryLocation
            defaultProps={defaultProps}
            locationAddress={locationAddress}
            handleFormValues={handleFormValues}
            setDisableSave={setDisableSave}
            updateValuesToState={updateValuesToState}
            isLot_Land =  {true}
            mapType = {mapType}
            lotSize = {acreage}
            lotType = {lotType}
            
          ></InventoryLocation>
            <MustHavesLand
            updateValuesToState={updateValuesToState}
            isPropertyExist={isPropertyExist}
            lotSize={acreage}
            // invFormError={invFormError}
            // errorUpdatedAt={errorUpdatedAt}
            // adaAccess={adaAccess}
            handleFormValues={handleFormValues}
            acreage={acreage}
            lotType={lotType}
            roadType={roadType}
            isPrvtDrInst={isPrvtDrInst}
            isPowerAccess={isPowerAccess}
            isApprovedStrctr={isApprovedStrctr}
            isInFloodPlain={isInFloodPlain}
            waterSuply={waterSuply}
            isDrldWelAppr={isDrldWelAppr}
            isDrldWelInst={isDrldWelInst}
            wasteWtrSys={wasteWtrSys}
            isSepTankInst={isSepTankInst}
            isSepTankAppr={isSepTankAppr}
            acre_Sqft={acre_Sqft}
            />
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                onClick={() => {                
                    handleMapImageUpload();
                  }}
                disabled={disableSave}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLocationLotLand;
