import React, { useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { handleRadioButonChange } from "../../actions/common";
import ButtonGroups from "../Common/ButtonGroups";
import { convertToNumber } from "../../helpers/validations";
const WantAdShortRentMstHves = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    alwPets,
    adaAccess,
    noOfBeds,
    isEdit = false,
  } = props;

  useEffect(() => {
    handleContinueButton();
  }, []);

  useEffect(() => {
    handleContinueButton();
  }, [noOfBedrooms, noOfBathrooms]);

  const handleContinueButton = () => {
    let isDisable = false;
    if (noOfBedrooms && noOfBathrooms) {
      handleContinueButtonState(isDisable);
    } else {
      isDisable = true;
      handleContinueButtonState(isDisable);
    }
  };

  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
  };

  const handleBedsClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noOfBeds: value });
  };

  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ adaAccess: e.target.checked });
        break;
      case 2:
        handleFormValues({ alwPets: e.target.checked });
        break;
    }
  };

  const loadCheckBoxItems = () => {
    const itemArray = [
      { id: 1, text: "ADA Accessible" },
      { id: 2, text: "Allows pets" },
    ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 1:
          isChecked = adaAccess ;
          break;
        case 2:
          isChecked = alwPets;
          break;
      }
      return (
        <label className="check-wrap mb-3" key={id}>
          <input
            className="form-check-input"
            type="checkbox"
            name="chkbox"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <span>
            <div className="wantad-type-info">
              <h2 className="pb-0">{text}</h2>
            </div>
          </span>
        </label>
      );
    });

    return List;
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-524" : ""}>
      <div className="btn-group-wrap">
        <FormLabel>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
        />
      </div>
      <div className="btn-group-wrap">
        <FormLabel>
          Bathrooms<span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>
      <div className="btn-group-wrap">
        <FormLabel>
          Beds<span></span>
        </FormLabel>

        <ButtonGroups
          section="Beds"
          handleFormValues={handleFormValues}
          variant="outline-primary"
          value={noOfBeds}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedsClick(e)}
        />
      </div>
      <label className="form-label">Additional Requirements</label>
      <div className="wantad-type-wrap">{loadCheckBoxItems()}</div>
    </div>
  );
};
export default WantAdShortRentMstHves;
