import React, { useState, useEffect } from "react";
import WantadLeftSection from "../components/MemberWantAd/WantadLeftSection";
import WantadRightSection from "../components/MemberWantAd/WantadRightSection";
import {
  budgetMaxError,
  budgetMaxMissing,
  budgetMinMissing,
  strengthenPageContents,
} from "../constants/createWantAd";
import { useNavigate } from "react-router-dom";
import { saveMemberWantAdstrengthenData } from "../services/wantAdService";
import { pageURLs } from "../constants/pageURLs";
import {
  LTR_STRENGTHEN,
  STRENGTHEN_WANT_AD_KEY,
  WANT_AD_KEY,
} from "../constants/common";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../helpers/common";
import { convertToNumber } from "../helpers/validations";

const StrengthenMemberWantAd = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const strengthenDetails = getLocalStorage(STRENGTHEN_WANT_AD_KEY);

    setFormValues(strengthenDetails);
  }, []);

  const [activeStep, setActiveStep] = useState(1);
  const [completedStep, setCompletedStep] = useState(1);
  let isDisabled = true;
  const [continueButtonState, setContinueButtonState] = useState(isDisabled);
  const [formValues, setFormValues] = useState();
  const isAdjustBudgetState = _.get(formValues, "isAdjustBdget", "");
  const pTypeTxt = _.get(formValues, "pTypeTxt", "")
    ? _.get(formValues, "pTypeTxt", "").toLowerCase()
    : "";
  const adType = _.get(formValues, "adType", "");
  const {
    title,
    description,
    skipButtonEnable,
    exitButtonText,
    backButtonEnable,
    completedPercentage,
    continueButtonText,
    backButtonText,
    exitButtonDisable,
  } = isAdjustBudgetState
    ? _.get(strengthenPageContents, `BUY_STRENGTHEN.step_3`, "") //For render budget component for adusting the budget
    : _.get(
        strengthenPageContents,
        `${adType.toUpperCase()}.step_${activeStep}`,
        ""
      );

  const updatedLeftSectionTitle =
    title && title.replace("[PROPERTY_TYPE]", pTypeTxt);
  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      actvStep: activeStep,
      completedStep: completedStep,
      // completedPercentage: currentCompletedPercentage,
    }));
  }, [completedStep]);
  useEffect(() => {
    setLocalStorage(STRENGTHEN_WANT_AD_KEY, formValues);
  }, [formValues]);

  const handleContinueClick = () => {
    let errorSection = null;
    if (adType === LTR_STRENGTHEN) {
      saveStrengthenData();
    } else {
      if (activeStep === 2 && isAdjustBudgetState) {
        const minBdgt = _.get(formValues, "minBdgt", "");
        const maxBdgt = _.get(formValues, "maxBdgt", "");

        if (minBdgt && maxBdgt) {
          if (convertToNumber(minBdgt) >= convertToNumber(maxBdgt)) {
            errorSection = budgetMaxError;
          }
        }
        //  else if (!minBdgt && maxBdgt) {
        //   errorSection = budgetMinMissing;
        // } else if (minBdgt && !maxBdgt) {
        //   errorSection = budgetMaxMissing;
        // }
        setActiveStep(activeStep);
        let statesToBeUpdated = {};
        if (errorSection) {
          statesToBeUpdated.wantAdValErrors = errorSection;
          statesToBeUpdated.updatedAt = Date.now();
        } else {
          statesToBeUpdated.isAdjustBdget = false;
        }
        handleFormValues(statesToBeUpdated); // For move back to the purchase finance step after budget adjustment
      } else if (activeStep === 2 && !isAdjustBudgetState) {
        saveStrengthenData();
      } else {
        setActiveStep(activeStep + 1);
      }
      isDisabled = true;
      setContinueButtonState(isDisabled);

      if (completedStep <= activeStep) {
        setCompletedStep(activeStep);
      }
    }
    if (!errorSection) {
      const errorSection = _.get(
        getLocalStorage(STRENGTHEN_WANT_AD_KEY),
        "wantAdValErrors"
      );
      if (errorSection) {
        handleFormValues({ wantAdValErrors: "", updatedAt: "" }); // Clear errosSection from state.
      }
    }
  };

  const saveStrengthenData = async () => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const strengthenData = getLocalStorage(STRENGTHEN_WANT_AD_KEY);
    const wantAdId = _.get(wantAd, "wantAdId", null);

    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = wantAd;
    }
    let isApproveLoan = "";
    if (_.get(formValues, "isPreApprLoan", null) === "Yes") {
      isApproveLoan = true;
    } else if (_.get(formValues, "isPreApprLoan", null) === "No") {
      isApproveLoan = false;
    }
    requestPayLoad.isPreApprLoan === isApproveLoan;
    const is1031Exchange =
      requestPayLoad.is1031Exchange === "Yes" ? true : false;
    requestPayLoad.wantAdId = wantAdId;
    requestPayLoad.isPreApprLoan = isApproveLoan;
    requestPayLoad.is1031Exchange = is1031Exchange;
    requestPayLoad.maxBdgt = _.get(strengthenData, "maxBdgt", wantAd.maxBdgt);
    requestPayLoad.minBdgt = _.get(strengthenData, "minBdgt", wantAd.minBdgt);
    delete requestPayLoad.completedPercentage;
    delete requestPayLoad.isAdjustBdget;
    delete requestPayLoad.pFinanceText;
    delete requestPayLoad.purchaseText;
    delete requestPayLoad.userId;
    delete requestPayLoad.pTypeTxt;

    let updatedObjectTolocalStorage = { wantAdId: wantAdId };
    if (adType === LTR_STRENGTHEN) {
      updatedObjectTolocalStorage.showSuccess = true;
    }
    await saveMemberWantAdstrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
          setLocalStorage(WANT_AD_KEY, updatedObjectTolocalStorage);
          navigate(pageURLs.wantAdDetails);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handleBackButtonClick = () => {
    if (adType === LTR_STRENGTHEN && activeStep === 1) {
      removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
      handleFormValues({ creditScore: "" });
      navigate(pageURLs.wantAdDetails);
    } else {
      if (activeStep === 2 && isAdjustBudgetState) {
        setActiveStep(activeStep);
        handleFormValues({ isAdjustBdget: false });
      } else {
        setActiveStep(activeStep - 1);
      }
    }
  };

  const handleSkipButtonClick = (step) => {
    switch (step) {
      case 1:
        setFormValues((prevState) => ({
          ...prevState,
          purchaseType: "",
          purchaseText: "",
          is1031Exchange: "",
        }));
        break;
      case 2:
        setFormValues((prevState) => ({
          ...prevState,
          pFinance: "",
          pFinanceText: "",
          is1031Exchange: "",
        }));
        break;
    }
    setActiveStep(activeStep + 1);
    if (step === 2) {
      navigate(pageURLs.wantAdDetails);
    }
  };

  const handleContinueButtonState = (buttonState) => {
    isDisabled = false;
    setContinueButtonState(buttonState ? buttonState : isDisabled);
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };
  const handleSaveAndExit = () => {
    removeLocalStorage(STRENGTHEN_WANT_AD_KEY);
    navigate(pageURLs.wantAdDetails);
  };

  return (
    <div className="container-fluid position-relative half-fluid h-100 ps-0 pe-0">
      <div className="container-fluid container-height ps-0 pe-0">
        <div className="row h-100 g-0">
          <WantadLeftSection
            title={updatedLeftSectionTitle}
            description={description}
            completedPercentage={completedPercentage}
          />
          <WantadRightSection
            handleFormValues={handleFormValues}
            handleSaveAndExit={handleSaveAndExit}
            handleContinueClick={handleContinueClick}
            handleBackButtonClick={handleBackButtonClick}
            handleContinueButtonState={handleContinueButtonState}
            isDisabled={continueButtonState}
            skipButtonEnable={skipButtonEnable}
            exitButtonText={exitButtonText}
            backButtonEnable={backButtonEnable}
            continueButtonText={continueButtonText}
            formValues={formValues}
            step={activeStep}
            handleSkipButtonClick={handleSkipButtonClick}
            exitButtonDisable={exitButtonDisable}
            backButtonText={backButtonText}
          />
        </div>
      </div>
    </div>
  );
};

export default StrengthenMemberWantAd;
