import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../images/black-calendar-icon.svg";
import TextBoxMob from "../components/TextBoxMob";
import { formateDate } from "../../helpers/common";
import TextBox from "../../components/Common/TextBox";

const DateRangeSelectorMob = (props) => {
  const {startDate, endDate, setStartDate, setEndDate} = props;
  const [showCalendar, setShowCalendar] = useState(false)
  const [selectDate, setSelectDate] = useState("start")
  

  const onChange = (date) => {    
    if(selectDate==="start"){setStartDate(date);}
    if(selectDate==="end"){setEndDate(date);}
    setShowCalendar(false);    
  };

  const handleOnChange=(type)=>{
    setShowCalendar(true)
    setSelectDate(type)
  }
  

  return (
    <>
        <div className="wantad-calendar-top position-relative">
          <ul className="wantadfilter-calendar-list">
            <li>
              <div class="input-group position-relative">
                <TextBoxMob
                  type="text"
                  class="form-control"
                  disabled={true}
                  value={startDate ? formateDate(startDate, "mm/dd/yyyy") : ""}
                ></TextBoxMob>
                <div className="wantad-calendar-icon"  onClick={()=>handleOnChange("start")} >
                  <img src={CalendarIcon} alt={CalendarIcon} className=""/>
                </div>
              </div>
            </li>
            <li className="text-center"><span className="middle-separator">-</span></li>
            <li>
              <div class="input-group position-relative">
                <TextBoxMob
                  type="text"
                  disabled={true}
                  class="form-control"
                  value={endDate ? formateDate(endDate, "mm/dd/yyyy") : ""}
                //   onChange ={()=>handleOnChange()}
                ></TextBoxMob>
                <div className="wantad-calendar-icon" onClick={()=>handleOnChange("end")} >
                  <img src={CalendarIcon} alt={CalendarIcon} className="" />
                </div>
              </div>
            </li>
          </ul>
        </div>
        {showCalendar && <DatePicker
          calendarStartDay={1}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}        
          selectsStart={selectDate==="start" ? true : false}
          selectsEnd={selectDate==="end" ? true : false}
          startDate={startDate && new Date(startDate)}
          endDate={endDate && new Date(endDate)}
          minDate={new Date()}
        //   maxDate={maxEndDate}
          inline
          monthsShown={1}
          onChange={onChange}
          disabledKeyboardNavigation
        />}
    </>
  );
};

export default DateRangeSelectorMob;
