import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import passwordIcon from "../../../images/password-toggle-icon.svg";
import passwordClosedIcon from "../../../images/password-toggle-icon-closed.svg";
import {
  emailValidation,
  passwordValidation,
} from "../../../helpers/validations";
import TextBox from "../../Common/TextBox";
import { userSignUp, verifyEmail } from "../../../services/signupService";
import {
  resetModalemailOnly,
  resetModalpwdOnly,
} from "../../../constants/onBoarding";

import _ from "lodash";
import { getLocalStorage } from "../../../helpers/common";
import { USER_INFO_KEY } from "../../../constants/common";
import { LOGIN, SIGNUP } from "../../../constants/endPoints";

const ResetPassword = (props) => {
  const {
    resetPwdModal,
    handleResetPwdModal,
    resetPwdModalType,
    resetPassWord,
    handleAgentOnboardingModal
  } = props;
  const showModal = _.get(resetPwdModal, "showResetModal", "");
  const isFromSettings = _.get(resetPwdModal, "isFromSettings", "");
  const userId = _.get(resetPwdModal, "userId", "");
  const token = _.get(resetPwdModal, "token", "");
  const action = _.get(resetPwdModal, "action", "");

  const isEmailView = resetPwdModalType === resetModalemailOnly;

  const initialFormData = {
    email: "",
    pwd: "",
    confPwd: "",
  };
  const initialErrors = {
    emailError: "",
    pwdError: "",
    confPwdError: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isShowPassWord, setPassWordShow] = useState(false);
  const [isShowConfPassWord, setConfPassWordShow] = useState(false);
  const [passwordType, setPassWordType] = useState("password");
  const [confPasswordType, setConfPassWordType] = useState("password");

  const onTextChange = (e) => {
    const value = e.target.value;
    setFormData((formData) => ({ ...formData, [e.target.name]: value }));
  };

  useEffect(() => {
    const email = _.get(formData, "email", null);
    const pwd = _.get(formData, "pwd", null);
    const confPwd = _.get(formData, "confPwd", null);
    let disableButton;
    if (isEmailView) {
      if (email) {
        disableButton = false;
      } else {
        disableButton = true;
      }
    } else {
      if (pwd && confPwd) {
        disableButton = false;
      } else {
        disableButton = true;
      }
    }
    setButtonDisable(disableButton);
  }, [formData]);

  useEffect(() => {
    if (isShowPassWord) {
      setPassWordType("text");
    } else {
      setPassWordType("password");
    }
    if (isShowConfPassWord) {
      setConfPassWordType("text");
    } else {
      setConfPassWordType("password");
    }
  }, [isShowPassWord, isShowConfPassWord]);

  useEffect(() => {
    setFormErrors(initialErrors);
    setFormData(initialFormData);
  }, [isEmailView]);

  const checkEmailExist = async (email) => {
    const userEmail = {
      Email: email,
    };
    return await verifyEmail(userEmail).then((response) => {
      return response?.result;
    });
  };

  const validate = async () => {
    setFormErrors(initialErrors);
    let isValid = true;
    const email = _.get(formData, "email", null).trim();

    if (isEmailView) {
      if (!emailValidation(email)) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          emailError: "InValid Email",
        }));
        isValid = false;
      } else {
        const res = await checkEmailExist(email);
        const userExist = _.get(res, "UserExists", false);
        const userVerified = _.get(res, "IsVerified", false);
        if (userExist && userVerified) {
          isValid = true;
        } else {
          setFormErrors((formErrors) => ({
            ...formErrors,
            emailError: "Invalid user",
          }));
          isValid = false;
        }
      }
      if(isFromSettings){
        const userDetails = getLocalStorage(USER_INFO_KEY)
        const userInfo = _.get(userDetails, 'userInfo', '')
        const userEmail = _.get(userInfo, 'email', '')
        if(userEmail.trim() !== email.trim()){
          setFormErrors((formErrors) => ({
            ...formErrors,
            emailError: "InValid Email",
          }));
          isValid = false;
        }
      }
    } else {
      const pwd = _.get(formData, "pwd", null).trim();
      const passWordError = passwordValidation(pwd);
      if (passWordError) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          pwdError: passWordError,
        }));
        isValid = false;
      } else {
        const confPwd = _.get(formData, "confPwd", null).trim();
        if (pwd !== confPwd) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            confPwdError: "Password not matching",
          }));
          isValid = false;
        } else {
          isValid = true;
        }
      }
    }

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      if (isEmailView) {
        const email = _.get(formData, "email", "");
        let reqPayload = { email: email }
        if(isFromSettings){
          reqPayload.isFromSettings = true
        }
     
        const res = await resetPassWord(reqPayload);
        const message = _.get(res, "message", null);
        if (message) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            emailError: message,
          }));
        }

        //handleResetPwdModal({showResetModal: true, resetPwdModalType: resetModalpwdOnly})
      } else {
        const pwd = _.get(formData, "pwd", "");
        const res = await resetPassWord({ resetPassword: pwd, userId: userId, token: token, action : action });

        if (_.get(res, "errorCode", "") !== 0) {
          setFormErrors((formErrors) => ({
            ...formErrors,
            pwdError: _.get(res, "message", null),
          }));
        }
      }
    }
  };

  const handlePassWordShow = () => {
    setPassWordShow(!isShowPassWord);
  };
  const handleConfPassWordShow = () => {
    setConfPassWordShow(!isShowConfPassWord);
  };

  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="modal-width-548"
    >
      {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {
            if(userId){
              handleResetPwdModal({
                showResetModal: false,
                resetPwdModalType: null,
              });
            } else{
              handleAgentOnboardingModal({ showPopup: true,  userAction: SIGNUP });
            }
            
          }}
        ></button>
        <h2 className="text-center">Reset Password</h2>
        {resetPwdModalType === resetModalemailOnly && (
          <div className="textbox-wrap mb-4 mb-sm-4 position-relative">
            <label className="form-label">Email</label>
            <TextBox
              name="email"
              type="text"
              errorMessage={_.get(formErrors, "emailError", "")}
              value={_.get(formData, "email", "")}
              placeholder="yourname@email.com"
              class={
                _.get(formErrors, "emailError", null)
                  ? "form-control validation-txtfld"
                  : "form-control"
              }
              onChange={(e) => {
                onTextChange(e);
              }}
            />
          </div>
        )}
        {resetPwdModalType === resetModalpwdOnly && (
          <>
            <div className="textbox-wrap mb-0 mb-sm-0 position-relative">
              <label className="form-label">Password</label>
              <TextBox
                name="pwd"
                type={passwordType}
                errorMessage={_.get(formErrors, "pwdError", "")}
                value={_.get(formData, "pwd", "")}
                placeholder="Create a Password"
                errorClass="error-msg error-msg-height"
                class={
                  _.get(formErrors, "pwdError", null)
                    ? "pad-right-40 validation-txtfld"
                    : "pad-right-40"
                }
                onChange={(e) => {
                  onTextChange(e);
                }}
              />
              <Button
                variant=""
                className="btn btn-pwd-toggle"
                onClick={() => handlePassWordShow()}
              >
                <img
                  src={isShowPassWord ? passwordClosedIcon : passwordIcon}
                  alt="pwdicon"
                />
              </Button>
            </div>

            <div className="textbox-wrap mb-4 mb-sm-4 position-relative">
              <label className="form-label">Confirm Password</label>
              <TextBox
                name="confPwd"
                type={confPasswordType}
                errorMessage={_.get(formErrors, "confPwdError", "")}
                value={_.get(formData, "confPwd", "")}
                placeholder="Confirm Password"
                class={
                  _.get(formErrors, "confPwdError", null)
                    ? "form-control validation-txtfld"
                    : "form-control"
                }
                onChange={(e) => {
                  onTextChange(e);
                }}
              />
              <Button
                variant=""
                className="btn btn-pwd-toggle"
                onClick={() => handleConfPassWordShow()}
              >
                <img
                  src={isShowConfPassWord ? passwordClosedIcon : passwordIcon}
                  alt="pwdicon"
                />
              </Button>
            </div>
          </>
        )}
        {isFromSettings ? (
          <div className="modal-btn-wrap">
            <Button
              variant=""
              className="btn btn-tertiary me-2"
              onClick={() =>
                handleResetPwdModal({
                  showResetModal: false,
                  resetPwdModalType: null,
                })
              }
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="w-25 mb-1"
              disabled={buttonDisable}
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </div>
        ) : (
          <Button
            variant="primary"
            className="w-100 mb-4"
            disabled={buttonDisable}
            onClick={() => handleSubmit()}
          >
            Continue
          </Button>
        )}
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default ResetPassword;
