import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { createPortal } from 'react-dom';

const WantAdRenewConfirmationModal = (props) => {
  const navigate = useNavigate();
  const { onHide, firstName } = props;
  const [domReady, setDomReady] = React.useState(false)

  const divStyle = {
    display: props.show ? 'flex' : 'none'
  };

  React.useEffect(() => {
    setDomReady(true)
  }, [])
  return domReady ? createPortal((

    <div className="modal-new"
      style={divStyle} onFocusOut={props.onHide}>
      <div className="modal-content-new">
        {/*<span className="close" onClick={props.onHide}>&times;</span>*/}
        <h3>Are you sure you want to renew this Want ad?</h3>
        <button className="btn btn-primary w-100" onClick={() => { props.onConfirmReniewWantAd() }}>
          Confirm
        </button>

        <button variant=""
          onClick={props.onHide} className="btn btn-cancel w-100">
          Cancel
        </button>

      </div>
    </div>

  ), document.querySelector(".notif-modal-container")) : null;
};
export default WantAdRenewConfirmationModal;
