import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import {
  clearLocalStorageOnLogout,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../helpers/common";
import NxtArrow from "../components/nextArrow";
import BottomStepperMob from "../components/BottomStepperMob";
import CheckIcn from "../images/checkIcn.svg";
import { 
  USER_INFO_KEY,
  INVENTORY_STRENGTHEN,
  STRENGTHEN_INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  INVENTORY_KEY,
} from "../../constants/common";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { truncate } from "../../helpers/common";
import AddTimeLine from "../components/CreateInventory/AddTimeline";
import AddAdditionalDetails from "../components/CreateInventory/AddAdditionalDetails";
import {
  saveInventoryStrengthenData,
} from "../../services/inventoryService";
import { userLogout } from "../../reducers/login";
const StrengthenInventoryResponsive = (props) => {
  const [formValues, setFormValues] = useState(null);
  const navigate = useNavigate();
  let adAmenities = null;
  const [uiKey, setUIKey] = useState(null);
  const [completedPercentage, setCompletedPercentage] = useState(7);
  useEffect(() => {
    adAmenities = getLocalStorage(INVENTORY_STRENGTHEN);
    const userInfo = _.get(getLocalStorage(USER_INFO_KEY), "userInfo", null);
    if (!userInfo) {
      handleStateClear();
    }
    setFormValues(adAmenities);
  }, [uiKey === null]);

  useEffect(() => {
    setLocalStorage(INVENTORY_KEY_TEMP, formValues);
  }, [formValues]);

  useEffect(() => {
    handleFormValues({ cmpldPerctg: completedPercentage });
  }, [completedPercentage]);

  const apprOnFile = _.get(formValues, "apprOnFile", null);
  const insReport = _.get(formValues, "insReport", null);
  const sellerFin = _.get(formValues, "sellerFin", null);
  const floorPlan = _.get(formValues, "floorPlan", null);
  const floodCert = _.get(formValues, "floodCert", null);
  const assMortgage = _.get(formValues, "assMortgage", null);
  const timeLine = _.get(formValues, "timeLine", null);
  const tlText = _.get(formValues, "tlText", null);

  const handleStateClear = () => {
    // alert("Your session has expired. Please login to continue.");
    dispatch(userLogout());
    clearLocalStorageOnLogout();
    navigate("/mobile/home");
  };
  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };

  useEffect(() => {
    handleStrengthenInventoryUi();
  }, [uiKey]);

  const getinventoryId = () => {
    const inventoryDetails = getLocalStorage(INVENTORY_KEY);
    const inventoryId = _.get(inventoryDetails, "inventoryId", null);
    return inventoryId;
  };

  const updateLocalStorage = (localState) => {
    setLocalStorage(INVENTORY_KEY, localState);
    toast.success("You’ve strengthened your property!");
    removeStengthenDataAndRedirectToDetailsPage();
  };

  const saveStrengthenData = async (isStrengthen) => {
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(STRENGTHEN_INVENTORY_KEY);
    }
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const userId = _.get(userDetails, "userInfo.userId", null);
    requestPayLoad.inventoryId = getinventoryId();
    requestPayLoad.userId = userId;
    requestPayLoad.isStrengthen = isStrengthen;
    await saveInventoryStrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          const localState = {
            inventoryId: getinventoryId(),
          };
          updateLocalStorage(localState);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getAdditiionalDetailsText = () => {
    let resultText = "";
    if (apprOnFile) {
      resultText = "Appraisal on file";
    }
    if (insReport) {
      resultText =
        resultText.length > 0
          ? resultText + ", Inspection report on file"
          : "Inspection report on file";
    }
    if (sellerFin) {
      resultText =
        resultText.length > 0
          ? resultText + ", Seller finance available"
          : "Seller finance available";
    }
    if (floorPlan) {
      resultText =
        resultText.length > 0
          ? resultText + ", Floor plan on file"
          : "Floor plan on file";
    }
    if (floodCert) {
      resultText =
        resultText.length > 0
          ? resultText + ", Flood certificate available"
          : "Flood certificate available";
    }
    if (assMortgage) {
      resultText =
        resultText.length > 0
          ? resultText + ", Assumable mortgage"
          : "Assumable mortgage";
    }

    return truncate(resultText, 30);
  };

  let isDisableSave = true;
  if (timeLine && getAdditiionalDetailsText() != "") {
    isDisableSave = false;
  } else {
    isDisableSave = true;
  }

  const handleStrengthenInventoryUi = () => {
    switch (uiKey) {
      case "additionalInformation":
        return (
          <AddAdditionalDetails
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            completedPercentage={completedPercentage}
            setCompletedPercentage={setCompletedPercentage}
            isStrengthenInventory={true}
          />
        );
      case "timeline":
        return (
          <AddTimeLine
            handleFormValues={handleFormValues}
            formValues={formValues}
            setUIKey={setUIKey}
            completedPercentage={completedPercentage}
            setCompletedPercentage={setCompletedPercentage}
            isStrengthenInventory={true}
          />
        );
    }
  };

  const removeStengthenDataAndRedirectToDetailsPage = () => {
    removeLocalStorage(INVENTORY_STRENGTHEN);
    removeLocalStorage(INVENTORY_KEY_TEMP);
    navigate(pageURLs.mobilememberInventoryDetails);
  };

  const isAnyPropertyDocumentOptionSelected = () => {
    const strengthenData = getLocalStorage(STRENGTHEN_INVENTORY_KEY);
    if (strengthenData) {
      const apprOnFile = _.get(strengthenData, "apprOnFile", "");
      const insReport = _.get(strengthenData, "insReport", "");
      const sellerFin = _.get(strengthenData, "sellerFin", "");
      const floorPlan = _.get(strengthenData, "floorPlan", "");
      const floodCert = _.get(strengthenData, "floodCert", "");
      if (apprOnFile || insReport || sellerFin || floorPlan || floodCert) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleSaveAndExit = () => {
    const isCheckedPropertyDocItems = isAnyPropertyDocumentOptionSelected();
    if (!isCheckedPropertyDocItems) {
      setFormValues((prevState) => ({
        ...prevState,
        apprOnFile: false,
        insReport: false,
        sellerFin: false,
        floorPlan: false,
        floodCert: false,
      }));
    }
    const isStrengthen = false;
    saveStrengthenData(isStrengthen);
    removeStengthenDataAndRedirectToDetailsPage();
  };

  const handleBackButtonClick = () => {
    setFormValues((prevState) => ({
      ...prevState,
      inventoryId: "",
      userId: "",
      apprOnFile: "",
      insReport: "",
      sellerFin: "",
      floorPlan: "",
      floodCert: "",
      timeLine: "",
    }));
    removeStengthenDataAndRedirectToDetailsPage();
  };

  return (
    <>
      {uiKey === null ? (
        <div className="gitcha-main-wrapper pb-154">
          <div className="content-wrapper content-wrapper-h-154">
            <div className="inner-layout-header-new bg-white">
              <div className="top-three-comp-header w-100 flex-column">
                <div className="top-three-comp-header-inner w-100">
                  <a
                    className="d-block back-link-big me-4"
                    href={void 0}
                    onClick={() => handleBackButtonClick()}
                  >
                    back
                  </a>
                  <div className="progress-wrap-mob">
                    <BottomStepperMob
                      completedPercentage={completedPercentage}
                    />
                  </div>

                  <button className="btn top-exit-btn ms-4">&nbsp;</button>
                </div>
              </div>
            </div>
            <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
              <div className="">
                <div className="mt-3 pt-0 mb-0 titleText">
                  Strengthen Want-Ad
                </div>
                <p className="titleTextpara">
                  Tap on the section to add your response. Items marked with an
                  asterisk have required fields.{" "}
                </p>
              </div>
              <div
                className={
                  getAdditiionalDetailsText() != ""
                    ? "selectBoxWrap mb-3 d-flex select"
                    : "selectBoxWrap mb-3 d-flex"
                }
                onClick={() => setUIKey("additionalInformation")}
              >
                <div className="flex-grow-1">
                  <h4>
                    Additional Information <img src={CheckIcn} />
                  </h4>
                  <h5>
                    {getAdditiionalDetailsText() != ""
                      ? getAdditiionalDetailsText()
                      : "Add information"}{" "}
                  </h5>
                </div>
                <div className="align-self-center">
                  <Button variant="">
                    <NxtArrow />
                  </Button>
                </div>
              </div>
              <div
                className={
                  timeLine
                    ? "selectBoxWrap mb-3 d-flex select"
                    : "selectBoxWrap mb-3 d-flex"
                }
                onClick={() => setUIKey("timeline")}
              >
                <div className="flex-grow-1">
                  <h4>Timeline {timeLine ? <img src={CheckIcn} /> : <></>}</h4>
                  <h5>{timeLine ? tlText : "Select timeline"} </h5>
                </div>
                <div className="align-self-center">
                  <Button variant="">
                    <NxtArrow />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-layout-footer-new">
            <div className="inner-btm-fixed-nav ">
              <div className="btn-wrap text-center pb-40 pt-0">
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-20"
                  disabled={isDisableSave}
                  onClick={() => {
                    const isStrengthen = true;
                    saveStrengthenData(isStrengthen);
                  }}
                >
                  Update Ad
                </button>
                <button
                  type="button"
                  onClick={() => handleSaveAndExit()}
                  class="btn btn-no-bg btn-no-bg-fix w-100"
                >
                  Save & Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        handleStrengthenInventoryUi()
      )}
    </>
  );
};

export default StrengthenInventoryResponsive;
