import React from "react";
import _ from "lodash";
import { filterArray, getpropertyName, removeDecimalZeros, thousandSeperatedValue } from "../../../helpers/common";
import SampleImage from "../../../images/img-house-1.png";
import BedIcon from "../../../images/bed-icon.svg";
import BathIcon from "../../../images/bath-icon.svg";
import SqftIcon from "../../../images/sqft-icon.svg";
import MarkNotFitDropdown from "../MarkNotFitDropdown";
import { wantAdSqftValue } from "../../../helpers/createWantAd";

const IntroductionData = (props) => {
  const {introDetails,ViewWantAd }=props
  const introList = introDetails;
  const introId =  _.get(introList, "IntroductionId", "")
  const bedRoom = removeDecimalZeros(introList.Bedrooms == 99) ? 'Any' : removeDecimalZeros(introList.Bedrooms) + "+" ;
  const bathRoom =removeDecimalZeros(introList.Bathrooms)  == 99 ? 'Any' : removeDecimalZeros(introList.Bathrooms) + "+" ;
  const sqFt = wantAdSqftValue(introList?.Sqft_mn, introList?.Sqft_mx);
  
  return (
    <>

      <div className="make-intro-details pe-1">
      {/* <div
                    className="property-info property-info-hoverstyling"
                    onClick={()=>{ViewWantAd(introId)}}
                  ></div> */}
        <h3 className="mb-0 long-txt-fix intro-head-medium">{introList.Title_Text}</h3>
        <ul className="intro-icons-list">
          {bedRoom &&
            <li>
              <span className="list-txt">{bedRoom}</span>
              <div className="list-icns"><img src={BedIcon} /></div>
            </li>
          }
          {bathRoom &&
            <li>
              <span className="list-txt">{bathRoom}</span>
              <div className="list-icns">
                <img src={BathIcon} />
              </div>
            </li>
          } {sqFt &&
            <li>
              <span className="list-txt">{sqFt} sqft</span>
              <div className="list-icns">
                <img src={SqftIcon} />
              </div>
            </li>
          }
          {/* {acreage &&
            <li>
              <span className="list-txt">{acreage + lotSizeUnit}</span>
              <div className="list-icns">
                <img src={SqftIcon} />
              </div>
            </li>
          } */}
        </ul>
        <div className="intro-amt">
          ${introList?.AskingPrice && thousandSeperatedValue(introList?.AskingPrice)}
        </div>
      </div>
    </>
  );
};
export default IntroductionData;
