import React, { useState, useEffect } from "react";
import mapImg01 from "../../images/map.png";
import ProgressBarInventory from "./ProgressBarInventory";
import InventoryLocation from "./InventoryLocation";
import {
  DEFAULT_INVENTORY_MAP_ZOOM,
  DEFAULT_MAP_ZOOM,
  INVENTORY_KEY,
  INVENTORY_KEY_TEMP,
  IS_LAND,
  IS_PRIVATE_ROOM,
} from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
import GeoLocation from "../../../components/Common/GeoLocation";
import MustHavesProperties from "./MustHavesProperties";
import { checkIsNull, createPreSignedUrl, createS3Folder, createStaticMapImage, getLocalStorage, getUserId, setBodyBackgroundWhite, setLocalStorage, trimString, yearValidation } from "../../../helpers/common";
import { uploadMapImageToS3 } from "../../../helpers/S3/uploadToS3";
import { yrMaxInValid } from "../../../constants/createWantAd";

const AddLocation = (props) => {
  const {
    handleFormValues,
    formValues,
    setUIKey,
    adType,
    completedPercentage,
    setCompletedPercentage,
    isAgent,
    uiKey,
    isEdit
  } = props;
  const locationAddress = _.get(formValues, "locAddress", null);
  const noBedRm = _.get(formValues, "noBedRm", null);
  const noBathRm = _.get(formValues, "noBathRm", null);
  const sqFt = _.get(formValues, "sqFt", null);
  const stories = _.get(formValues, "stories", null);
  const lotSize = _.get(formValues, "lotSize", null);
  const grgeSpots = _.get(formValues, "grgeSpots", null);
  const adaAccess = _.get(formValues, "adaAccess", false);
  const IsNewConstruction = _.get(formValues, "IsNewConstruction", false);
  const propertyType = _.get(formValues, "prprtyType", null);
  const lat = _.get(formValues, "lat", null);
  const lng = _.get(formValues, "lng", null);
  const mapZoom = _.get(formValues, "mapZoom", null);
  const neighborHood = _.get(formValues, "neighborHood", null);
  const highSchool = _.get(formValues, "highSchool", null);
  const yearBuilt = _.get(formValues, "yearBuilt", null);
  const invFormError = _.get(formValues, "invFormError", null);
  const errorUpdatedAt = _.get(formValues, "updatedAt", null);
  const mapType = _.get(formValues, "mapType", null);
  const isPropertyExist = _.get(formValues, "isPropertyExist", null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const locationAddressInSession = _.get(getLocalStorage(INVENTORY_KEY), "locAddress", null);
  const noBedRmInSession = _.get(getLocalStorage(INVENTORY_KEY), "noBedRm", null);
  const noBathRmInSession = _.get(getLocalStorage(INVENTORY_KEY), "noBathRm", null);
  const sqFtInSession = _.get(getLocalStorage(INVENTORY_KEY), "sqFt", null);
  const storiesInSession = _.get(getLocalStorage(INVENTORY_KEY), "stories", null);
  const lotSizeInSession = _.get(getLocalStorage(INVENTORY_KEY), "lotSize", null);
  const grgeSpotsInSession = _.get(getLocalStorage(INVENTORY_KEY), "grgeSpots", null);
  const adaAccessInSession = _.get(getLocalStorage(INVENTORY_KEY), "adaAccess", false);
  const IsNewConstructionInSession = _.get(getLocalStorage(INVENTORY_KEY), "IsNewConstruction", false);
  const neighborHoodInSession = _.get(getLocalStorage(INVENTORY_KEY), "neighborHood", null);
  const highSchoolInSession = _.get(getLocalStorage(INVENTORY_KEY), "highSchool", null);
  const yearBuiltInSession = _.get(getLocalStorage(INVENTORY_KEY), "yearBuilt", null);

  const handleContinueButton = () => {
    let isDisable;
    
      if (isPrivateRoom) {
        if (trimString(locationAddress) !== "") {
          isDisable = false;
        } else {
          isDisable = true;
        }
      } else {
        if (trimString(locationAddress) !== "" && noBedRm && noBathRm && sqFt) {
          isDisable = false;
        } else {
          isDisable = true;
        }
      }
    
    setDisableSave(isDisable);
  };

  useEffect(()=>{
    if(isEdit && 
      locationAddress=== locationAddressInSession && 
      convertToNumber(noBedRm)===convertToNumber(noBedRmInSession) && 
      convertToNumber(noBathRm)===convertToNumber(noBathRmInSession )&&
      convertToNumber(sqFt)===convertToNumber(sqFtInSession) &&
      convertToNumber(stories)===convertToNumber(storiesInSession) &&
      convertToNumber(lotSize)===convertToNumber(lotSizeInSession) &&
      convertToNumber(grgeSpots)===convertToNumber(grgeSpotsInSession) &&
      convertToNumber(adaAccess)== convertToNumber(adaAccessInSession) &&
      convertToNumber(IsNewConstruction)== convertToNumber(IsNewConstructionInSession) &&
      neighborHood===neighborHoodInSession &&
      highSchool===highSchoolInSession
      ){
        setDisableSave(true)
      }
      else{
        handleContinueButton()
      }
  }, [locationAddress,noBedRm, noBathRm, sqFt , stories, lotSize, grgeSpots, adaAccess, IsNewConstruction, neighborHood, highSchool])

  useEffect(() => {
    setBodyBackgroundWhite()
    if (!locationAddress) {
      const inventoryDetails = getLocalStorage(INVENTORY_KEY);
      const s3Folder = _.get(inventoryDetails, "s3ImageFolder", null);
      const userId = getUserId();
      if (!s3Folder) {
        getFolderName(userId);
      }
    } 
  }, []);

  const getFolderName = async (userId) => {
    const isWantAd = false;
    await createS3Folder(userId, isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };

  const [disableSave, setDisableSave] = useState(true);
  const isPrivateRoom = propertyType === IS_PRIVATE_ROOM;

  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_INVENTORY_MAP_ZOOM,
  };

  const updateValuesToState = (key, value) => {
    if (!checkIsNull(value)) {
      handleFormValues({ [key]: value });
    } else {
      handleFormValues({ [key]: "" });
    }
  };

  const handleMapImageUpload = async () => {
    setIsImageUploaded(false);
    const lat = _.get(formValues, "lat", "");
    const lng = _.get(formValues, "lng", "");
    const mapZoom = _.get(formValues, 'mapZoom', DEFAULT_MAP_ZOOM)
    const mapResponse = await createStaticMapImage(lat, lng, mapZoom )
    const preSignedUrlRes = mapResponse ? await createPreSignedUrl(false, _.get(formValues, "s3ImageFolder", "")) : alert('Something went wrong while fetching map image.');
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if(s3FullUrl){
        const imageUrl = s3FullUrl
        ? s3FullUrl.split("?")[0]
        : "";
        handleFormValues({mapImg : imageUrl})
        setIsImageUploaded(true);

      }     
    }
  };


  useEffect(()=>{
    if(isImageUploaded){
      // if (isAgent) {
      //   if (completedPercentage > 20) {
      //     setCompletedPercentage(completedPercentage);
      //   } else {
      //     setCompletedPercentage(20);
      //   }
      // } else {
        if (completedPercentage > 25) {
          setCompletedPercentage(completedPercentage);
        } else {
          setCompletedPercentage(25);
        }
      // } 
      setTimeout(()=>{         
        setLocalStorage(
          INVENTORY_KEY,
          getLocalStorage(INVENTORY_KEY_TEMP)
        );
        setUIKey(null)
      },500)
  }

  },[isImageUploaded])

  return (
    <>
    {!locationAddress && !lat && !lng && (
        <GeoLocation handleFormValues={handleFormValues} />
      )}
      <div className="gitcha-main-wrapper pb-102 bg-white">
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBarInventory 
          setUIKey={setUIKey}
          completedPercentage={completedPercentage} 
          uiKey={uiKey}
          isEdit={isEdit}
          />

          <div className="inner-layout-content-new min-h-100 bg-white pl-16 pr-16 pb-0 ">
            <div>
              <p className="mt-0 mb-2 fnt12">LOCATION</p>
             {isAgent ? <h2 className="mt-0 pt-0 mb-2 titleText">Let’s find your client’s property. We’ll take care of the details.</h2>
              : 
              <h2 className="mt-0 pt-0 mb-2 titleText">
                Let’s find your property. We’ll take care of the details.
              </h2>}
              <p className="fnt14">
              If {isAgent ? "the" : "your"} property doesn’t have an address, enter a nearby street
                name and adjust the pin on the map.
              </p>
            </div>
            <InventoryLocation
            defaultProps={defaultProps}
            locationAddress={locationAddress}
            handleFormValues={handleFormValues}
            setDisableSave={setDisableSave}
            updateValuesToState={updateValuesToState}
            isPrivateRoom={isPrivateRoom}
            mapType={mapType}
            noBedRm ={noBedRm}
            noBathRm = {noBathRm}
            sqFt = {sqFt}
            
          ></InventoryLocation>
            <MustHavesProperties
            updateValuesToState={updateValuesToState}
            isPrivateRoom={isPrivateRoom}
            neighborHood={neighborHood}
            highSchool={highSchool}
            isPropertyExist={isPropertyExist}
            noBedRm ={noBedRm}
            noBathRm={noBathRm}
            sqFt={sqFt}
            stories={stories}
            lotSize={lotSize}
            grgeSpots={grgeSpots}
            yearBuilt={yearBuilt}
            invFormError={invFormError}
            errorUpdatedAt={errorUpdatedAt}
            adaAccess={adaAccess}
            IsNewConstruction={IsNewConstruction}
            handleFormValues={handleFormValues}
            />
          </div>
        </div>
        <div className="inner-layout-footer-new">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                onClick={() => {
                  if (yearBuilt && !yearValidation(yearBuilt.toString())) {
                    handleFormValues({
                      invFormError: yrMaxInValid,
                      updatedAt: Date.now(),
                    });
                  } else {
                    handleFormValues({
                      invFormError: "",
                      updatedAt: "",
                    });
                    handleMapImageUpload();
                  }                                    
                  }}
                disabled={disableSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLocation;
