import WantAdConfirmation from "./WantAdConfirmation";
import WantAdLocation from "./WantAdLocation";
import WantAdTiming from "./WantAdTiming";
import WantAdDescription from "./WantAdDescription";
import WantAdLandMustHaves from "./WantAdLandMustHaves";
import WantAdLandNiceToHaves from "./WantAdLandNiceToHaves";
import WantAdBudget from "./WantAdBudget";
import { convertToNumber } from "../../helpers/validations";

const WantAdMemberLand = (props) => {
  const { step, handleContinueButtonState, handleFormValues, formValues } =
    props;

  switch (step) {
    case 3:
      return (
        <WantAdLocation
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          location={_.get(formValues, "location", null)}
          zipCode={_.get(formValues, "zipCode", null)}
          zipIdValues={_.get(formValues, "zipIdValues", null)}
        />
      );
    case 4:
      return (
        <WantAdTiming
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          timeLine={_.get(formValues, "timeLine", null)}
        />
      );

    case 6:
      return (
        <WantAdLandMustHaves
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          lotType={_.get(formValues, "lotType", null)}
          roadType={_.get(formValues, "roadType", null)}
          isPrivtDrilInstld={_.get(formValues, "isPrvtDrInst", null)}
          onSiteElec={_.get(formValues, "onSiteElec", null)}
          waterSupply={_.get(formValues, "waterSupply", null)}
          isDrldWelInstld={_.get(formValues, "isDrldWelInst", null)}
          wasteWtrSys={_.get(formValues, "wasteWtrSys", null)}
          isSepTankInstld={_.get(formValues, "isSepTankInst", null)}
          isSepTankApprvd={_.get(formValues, "isSepTankAppr", null)}
          minLtSz={_.get(formValues, "minLtSz", null)}
          maxLtSz={_.get(formValues, "maxLtSz", null)}
          mustHaveErrors={_.get(formValues, "wantAdValErrors", null)}
          errorUpdatedAt={_.get(formValues, "errorUpdatedAt", null)}
        />
      );
    case 7:
      return (
        <WantAdLandNiceToHaves
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          nice2Have={_.get(formValues, "nice2Have", null)}
        />
      );
    case 8:
      return (
        <WantAdBudget
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          budgetMin={_.get(formValues, "minBdgt", "")}
          budgetMax={_.get(formValues, "maxBdgt", "")}
          MAX_LAND_BUDGET={100000000}
          propertyType={convertToNumber(_.get(formValues, "prprtyType", ""))}
          adType={_.get(formValues, "adType", "")}
          budgetErrors={_.get(formValues, "wantAdValErrors", null)}
          errorUpdatedAt={_.get(formValues, "updatedAt", null)}
        />
      );
    case 9:
      return (
        <WantAdDescription
          handleContinueButtonState={handleContinueButtonState}
          handleFormValues={handleFormValues}
          adTitle={_.get(formValues, "adTitle", "")}
          adDescription={_.get(formValues, "adDesc", "")}
        />
      );
    case 10:
      return (
        <WantAdConfirmation
          handleContinueButtonState={handleContinueButtonState}
          formValues={formValues}
        />
      );
  }
};
export default WantAdMemberLand;
