import React, { useState, useEffect } from "react";
import InventoryLeftSection from "../components/Inventory/CreateInventory/InventoryLeftSection";
import InventoryRightSection from "../components/Inventory/CreateInventory/InventoryRightSection";

import {
  strengthenPageContents,
  inventoryAmenities,
} from "../constants/createInventory";
import { useNavigate } from "react-router-dom";
import {
  saveInventoryStrengthenData,
  getInventoryById,
} from "../services/inventoryService";
import { pageURLs } from "../constants/pageURLs";

import {
  INVENTORY_KEY,
  INVENTORY_STRENGTHEN,
  SELL,
  STRENGTHEN_INVENTORY_KEY,
  USER_INFO_KEY,
} from "../constants/common";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../helpers/common";
import _ from "lodash";

const StrengthenInventory = () => {
  let strengthenAd = null;
  const navigate = useNavigate();

  strengthenAd = _.get(inventoryAmenities, STRENGTHEN_INVENTORY_KEY, "");
  _.set(strengthenAd, "proprtyPurpose", INVENTORY_STRENGTHEN);

  const [activeStep, setActiveStep] = useState(1);

  let isDisabled = true;
  const [continueButtonState, setContinueButtonState] = useState(isDisabled);
  const [formValues, setFormValues] = useState();
  const pageContents = _.get(strengthenPageContents, `step_${activeStep}`, "");

  const {
    title,
    description,
    skipButtonEnable,
    exitButtonText,
    backButtonEnable,
    completedPercentage,
    continueButtonText,
    backButtonText,
    exitButtonDisable,
  } = pageContents;
  const selectedPurposes = _.get(strengthenAd, "selectedPurposes", null);

  let updatedTitle = title;
  if (selectedPurposes && _.includes(selectedPurposes, SELL)) {
    updatedTitle = title && title.replace("[TYPE]", "sell");
  } else {
    updatedTitle = title && title.replace("[TYPE]", "rent out");
  }

  useEffect(() => {
    const inventoryDetails = getLocalStorage(INVENTORY_KEY);
    const inventoryId = _.get(inventoryDetails, "inventoryId", "");
    getInventoryDetails(inventoryId);
  }, []);

  const getInventoryDetails = (inventoryId) => {
    if (inventoryId) {
      getInventoryById(inventoryId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);

          if (response.errorCode === 0 && responseResult) {
            _.set(strengthenAd, "proprtyPurpose", INVENTORY_STRENGTHEN);
            _.set(
              strengthenAd,
              "apprOnFile",
              _.get(responseResult, "apprOnFile", false)
            );
            _.set(
              strengthenAd,
              "floodCert",
              _.get(responseResult, "floodCert", false)
            );
            _.set(
              strengthenAd,
              "floorPlan",
              _.get(responseResult, "floorPlan", false)
            );
            _.set(
              strengthenAd,
              "insReport",
              _.get(responseResult, "insReport", false)
            );
            _.set(
              strengthenAd,
              "sellerFin",
              _.get(responseResult, "sellerFin", false)
            );
            _.set(
              strengthenAd,
              "timeLine",
              _.get(responseResult, "timeLine", false)
            );
            _.set(
              strengthenAd,
              "selectedPurposes",
              _.get(responseResult, "proprtyPurpose", [])
            );
            setFormValues(strengthenAd);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.memberCreateInventory);
    }
  };

  useEffect(() => {
    setLocalStorage(STRENGTHEN_INVENTORY_KEY, formValues);
  }, [formValues]);

  const handleContinueClick = () => {
    if (activeStep === 2) {
      const isStrengthen = true;
      saveStrengthenData(isStrengthen);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const getinventoryId = () => {
    const inventoryDetails = getLocalStorage(INVENTORY_KEY);
    const inventoryId = _.get(inventoryDetails, "inventoryId", null);
    return inventoryId;
  };

  const updateLocalStorage = (localState) => {
    setLocalStorage(INVENTORY_KEY, localState);
    removeStengthenDataAndRedirectToDetailsPage();
  };

  const removeStengthenDataAndRedirectToDetailsPage = () => {
    removeLocalStorage(STRENGTHEN_INVENTORY_KEY);
    navigate(pageURLs.memberInventoryDetails);
  };

  const saveStrengthenData = async (isStrengthen) => {
    let requestPayLoad = formValues;
    if (!requestPayLoad) {
      requestPayLoad = getLocalStorage(STRENGTHEN_INVENTORY_KEY);
    }
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const userId = _.get(userDetails, "userInfo.userId", null);
    requestPayLoad.inventoryId = getinventoryId();
    requestPayLoad.isStrengthen = isStrengthen;
    await saveInventoryStrengthenData(requestPayLoad)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          const localState = {
            showStrengthenSuccessModal: isStrengthen ? true : false,
            inventoryId: getinventoryId(),
          };
          updateLocalStorage(localState);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const isAnyPropertyDocumentOptionSelected = () => {
    const strengthenData = getLocalStorage(STRENGTHEN_INVENTORY_KEY);
    if (strengthenData) {
      const apprOnFile = _.get(strengthenData, "apprOnFile", "");
      const insReport = _.get(strengthenData, "insReport", "");
      const sellerFin = _.get(strengthenData, "sellerFin", "");
      const floorPlan = _.get(strengthenData, "floorPlan", "");
      const floodCert = _.get(strengthenData, "floodCert", "");
      if (apprOnFile || insReport || sellerFin || floorPlan || floodCert) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleBackButtonClick = () => {
    if (activeStep === 1) {
      setFormValues((prevState) => ({
        ...prevState,
        inventoryId: "",
        userId: "",
        apprOnFile: "",
        insReport: "",
        sellerFin: "",
        floorPlan: "",
        floodCert: "",
        timeLine: "",
      }));
      removeStengthenDataAndRedirectToDetailsPage();
    } else {
      setActiveStep(activeStep - 1);
    }
  };
  const handleSkipButtonClick = (step) => {
    switch (step) {
      case 1:
        setFormValues((prevState) => ({
          ...prevState,
          apprOnFile: false,
          insReport: false,
          sellerFin: false,
          floorPlan: false,
          floodCert: false,
        }));
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setFormValues((prevState) => ({
          ...prevState,
          timeLine: "",
        }));
        const isStrengthen = true;
        saveStrengthenData(isStrengthen);
        break;
    }
  };

  const handleContinueButtonState = (buttonState) => {
    isDisabled = false;
    setContinueButtonState(buttonState ? buttonState : isDisabled);
  };

  const handleFormValues = (stateObject) => {
    Object.keys(stateObject).forEach(function (key) {
      setFormValues((prevState) => ({
        ...prevState,
        [key]: stateObject[key],
      }));
    });
  };
  const handleSaveAndExit = () => {
    const isCheckedPropertyDocItems = isAnyPropertyDocumentOptionSelected();
    if (!isCheckedPropertyDocItems) {
      setFormValues((prevState) => ({
        ...prevState,
        apprOnFile: false,
        insReport: false,
        sellerFin: false,
        floorPlan: false,
        floodCert: false,
      }));
    }
    const isStrengthen = false;
    saveStrengthenData(isStrengthen);
    removeStengthenDataAndRedirectToDetailsPage();
  };

  return (
    <div className="container-fluid position-relative half-fluid h-100 ps-0 pe-0">
      <div className="container-fluid container-height ps-0 pe-0">
        <div className="row h-100 g-0">
          <InventoryLeftSection
            title={updatedTitle}
            description={description}
            completedPercentage={completedPercentage}
          />
          <InventoryRightSection
            handleFormValues={handleFormValues}
            handleSaveAndExit={handleSaveAndExit}
            handleContinueClick={handleContinueClick}
            handleBackButtonClick={handleBackButtonClick}
            handleContinueButtonState={handleContinueButtonState}
            isDisabled={continueButtonState}
            skipButtonEnable={skipButtonEnable}
            exitButtonText={exitButtonText}
            backButtonEnable={backButtonEnable}
            continueButtonText={continueButtonText}
            formValues={formValues}
            step={activeStep}
            handleSkipButtonClick={handleSkipButtonClick}
            exitButtonDisable={exitButtonDisable}
            backButtonText={backButtonText}
            isStrengthenInventory={true}
          />
        </div>
      </div>
    </div>
  );
};

export default StrengthenInventory;
