import { formatDisplayPhoneNumber } from "../helpers/common";
import BuyerLogo from "../images/theBuyerLogo.svg";

const BuyerlistingHeader = (props) => {
    const {wantAdData} = props;
    return(
        <div class="top-header mb-24">
        <div class="w-100 h-100 d-flex p-0 flex-column flex-sm-column flex-md-row justify-content-between align-items-center">
          <div class="d-flex align-items-center align-items-md-start align-items-lg-end flex-column flex-lg-row">
            <div class="navbar-brand p-0 me-lg-3 text-center">
              <a href={void 0} className="d-block ">
                <img
                  src={BuyerLogo}
                  alt={BuyerLogo}
                  className="logo-mob-width"
                />
              </a>
            </div>
          </div>
          <ul class="d-flex flex-row navbar-nav flex-column flex-md-row">
            <li class="">
              <div class="headerProfile d-flex ">
                {wantAdData?.userImage !== "" ? (
                  <div className="proflieHeadImg">
                  <img                        
                    src={
                      wantAdData?.userImage +
                      "?" +
                      Math.random().toString(36)
                    }
                  />
                  </div>
                ) : (
                  <div className="proflieNoImg">
                  <svg                        
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                      fill="#A0A9BA"
                    />
                  </svg>
                  </div>
                )}
                <div>
                  <h5>
                    {wantAdData?.userFirstName} {wantAdData?.userLastName}{" "}
                    {wantAdData?.isPremiumAgent ? (
                      <span className="pro-badge">PRO</span>
                    ) : (
                      <></>
                    )}
                  </h5>
                  Agent @ {wantAdData?.userCompany}
                  <br />
                  {formatDisplayPhoneNumber(wantAdData?.Phone)}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
}

export default BuyerlistingHeader;