/**
 * @file   src\components\AgentWantAd\WantAdShortRentMstHves.js
 * @brief  This file is responsible for handling must haves for rent type in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React from "react";
import { FormLabel } from "react-bootstrap";
import { handleRadioButonChange } from "../../actions/common";
import ButtonGroups from "../Common/ButtonGroups";

const WantAdShortRentMstHves = (props) => {
  const {
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    noOfBeds,
    alwPets,
    adaAccess,
    requirementBedroomsError,
    requirementBathroomsError
  } = props;

  /**
   * To handle bedroom count selection
   * @param {*} e 
   */
  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value, focusOut: true });
  };

  /**
   * To handle bathroom count selection
   * @param {*} e 
   */
  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value, focusOut: true });
  };

  /**
   * To handle number of beds count selection
   * @param {*} e 
   */
  const handleBedClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noOfBeds: value, focusOut: true });
  };

  /**
   * To handle checkbox fields selection
   * @param {*} e 
   */
  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id
 
    switch (Number(id)) {
      case 100:
        handleFormValues({ adaAccess: e.target.checked, focusOut: true });
        break;
      case 200:
        handleFormValues({ alwPets : e.target.checked, focusOut: true });
        break;

    }
  };

  /**
   * To load checkbox items
   * @returns 
   */
  const loadCheckBoxItems = () => {
    const itemArray = [ { id: 100, text: 'ADA Accessible' },
      { id: 200, text: 'Allows pets' }
   ]
    const List =
      itemArray.map((pt) => {
        const id = Number(_.get(pt, "id", ""));
        const text = _.get(pt, "text", "");
        let isChecked
        switch (id) {
          case 200:
            isChecked = alwPets
            break;
          case 100:
            isChecked = adaAccess
            break;
        }
        return (
          <div className="row">
          <div className="wantad-timing-wrap">
            <div className="col-md-12">
              <label className="check-wrap">
                <input
                  type="checkbox"
                  name={id}
                  id={id}
                  checked={isChecked}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span>
                  <div className="timing-wrap-info">
                    <h2 className="pb-0"> {text}</h2>
                  </div>
                </span>
              </label>

            </div>
          </div>
        </div>

        );
      });

    return List;
  };

  return (
    <>
      <div className={requirementBedroomsError ? "btn-group-wrap red-marked-btngroup mb-24" : "btn-group-wrap mb-24"}>
        <FormLabel className={requirementBedroomsError && "red-marked-formlabel"}>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
          
        />
      </div>
      <div className={requirementBathroomsError ? "btn-group-wrap red-marked-btngroup mb-24" : "btn-group-wrap mb-24"}>
        <FormLabel className={requirementBathroomsError  && "red-marked-formlabel"}>
          Bathrooms<span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>

      <div className="btn-group-wrap mb-24">
        <FormLabel>
          Beds
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBeds}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedClick(e)}
        />
      </div>


      <div className="range-values-wrap">
        <label className="form-label">Additional Requirements</label>
        {loadCheckBoxItems()}

      </div>
    </>
  );
};
export default WantAdShortRentMstHves;
