import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { convertToNumber } from "../../helpers/validations";

const MemberPropertyListCollapse = (props) => {
  const {typeText, description, typeId, handlePropertyClick, propertySubItems, handlePropertySubItemClick, prptySubType,eventKey} = props;        
  const loadPropertySubTypes = () => {
    const List =
      propertySubItems &&
      propertySubItems.map((pt) => {
        const subTypeId = _.get(pt, "PropertyTypeId", "");
        const Img = _.get(pt, "Img", "");
        const isChecked = convertToNumber(prptySubType) == convertToNumber(subTypeId) ? true : false;    
        return (
            <div key={subTypeId} className={isChecked ? "checkbox-wrap mb-2 checked-brdr" : "checkbox-wrap mb-2"}>
              <label class="form-check-label checkbox-label " htmlFor={subTypeId} key={subTypeId}>
                <input 
                class="form-check-input form-radio-input" 
                type="radio" 
                value={subTypeId}  
                checked={isChecked}
                onChange={(e) => handlePropertySubItemClick(e, _.get(pt, "PropertyTypeText", ""))}
                id={subTypeId}  />
                <span className="labelTxt"> {_.get(pt, "PropertyTypeText", "")}</span>
              </label>  
            </div>
        );
      });
    return List;
  };
  return (
      <Accordion.Item eventKey={eventKey} className="mb-3">
        <Accordion.Header onClick={() => handlePropertyClick(typeText, typeId)}>
          <h4>{typeText}</h4>
          <p>{description}.</p>
        </Accordion.Header>
        {propertySubItems && propertySubItems.length > 0 && (
          <Accordion.Body>
            <h6>Select Types:</h6>
            <div className="createAd-blueWrap">{loadPropertySubTypes()}</div>
          </Accordion.Body>
        )}
      </Accordion.Item>
  );
};
export default MemberPropertyListCollapse;
