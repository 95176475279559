import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ConfettiSmallIcon1 from "../../images/confetti_icon.svg";
import { getUserType } from "../../../helpers/common";
import { USER_TYPE_AGENT } from "../../../constants/common";

const ShowInventoryCreationSuccess = (props) => {
  const { show, onHide, isFromMatch, isFromSendIntroduction } = props;
  const isAgent = getUserType()===USER_TYPE_AGENT  ? true : false ;

  const getDescription = ()=>{
    if(isFromSendIntroduction){
      return "You can now introduce your property to any want-ad. You can use the “Match” option on the filter panel to find want-ads that match your property."
    }
    else if(isFromMatch){
      return "You can now use the “Match” option on the filter panel to find want-ads that match your property. When you find one that sounds like a good fit, you can introduce it!"
    }
    else {
      return "Start browsing the ad-feed to find people who are looking for what you have. You can use the “Match” option on the filter panel to find want-ads that match your client’s property."
    }
  }

  return (
    <Offcanvas
      className="mobile-offcanvas"
      show={show}
      onHide={onHide}
      placement="bottom"
      {...props}
    >
      <Offcanvas.Header className="d-none" closeButton>
        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="top-icon-wrap mb-2">
          <img src={ConfettiSmallIcon1} />
        </div>
        <h2>You’ve successfully added to your inventory! </h2>
        <p className="modaltext">
          {getDescription()}
        </p>

        <div className="btn-wrap text-center pb-0">
          <button type="button" 
          onClick={()=>onHide()}
          class="btn btn-primary w-100 mb-3">
            Continue
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default ShowInventoryCreationSuccess;
