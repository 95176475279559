import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ProfilePhoto from "../../images/ProfilePhoto.png";
import { fetchUserDetails } from "../../../actions/userprofile";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../services/userprofileService";

const AgentProfileInfoModal = (props) => {
  const { isTitleShow, show, onHide } = props;
  const isFromMessage = props.isFromMessage ? props.isFromMessage : false;

  const dispatch = useDispatch()
  const userDetailsObject = useSelector((state) => state.userProfile.userDetails);
  const [userDetails, setUserDetails] = useState([]);

  // useEffect(() => {
  //   if(props?.userId){
  //     dispatch(fetchUserDetails({
  //       "userId": props?.userId,
  //     }))
  //   }
  // }, [props?.userId])

  // useEffect(() => {
  //   setUserDetails(userDetailsObject && userDetailsObject[0])
  // }, [userDetailsObject])

  useEffect(() => {
    if(props?.userId){
      getUserDetailsById()
    }
  }, [props?.userId])

  const getUserDetailsById = async ()=>{
    await getUserDetails({"id": props?.userId})
    .then((response) => {
      const responseResult = _.get(response, "result", null);
      if (response.errorCode === 0 && responseResult) {
        setUserDetails(responseResult && responseResult[0])
      } else {
        console.log("Something went wrong");
      }
    })
    .catch((err) => {
      console.log("Error", err);
    });
  }

  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup profile-modal">
      <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
        <h3>Agent Profile!</h3>
        {isTitleShow && <p className="f-size-16">A licensed real-estate agent is representing this property.</p>}
        <div className="agent-profile-information text-start">
          <div className="sepLine">
            <div className="profile-details">
                <div className="d-flex align-items-center">
                {userDetails?.Img ?
                    <span className="profile-pic"><img src={userDetails?.Img ?? noImage} /></span>
                    :
                    <div className="profile-placeholder-wrap profile-bg-orange">
                      {(userDetails?.FirstName + ' ' + userDetails?.LastName).replace(/-/g, "").match(/\b(\w)/g)}
                    </div>
                  }
                    <div className="profile-name">
                        <div className="title d-flex align-items-center">
                            <h2> {userDetails?.FirstName + ' ' + userDetails?.LastName}</h2>
                            {
                userDetails?.PlanName === "Pro Member" &&
                <span className="pro-blue-badge ms-2">PRO</span>
              }
                          
                        </div>
                        {userDetails?.Company && 
                        <p className="m-0">Agent @ {userDetails?.Company}</p>
}
                    </div>
                </div>
            </div>
            {userDetails?.Bio &&
           <div className="bio">
           <span className="smallTitle">Bio</span>
           <p> {userDetails?.Bio}</p>
         </div>
        }
          
          </div>
          <div className="agent-personal-info d-flex flex-column">
            <div className="info-group d-flex justify-content-between">
              <p className="title m-0">Phone number</p>
              <p className="m-0">{userDetails?.Phone}</p>
            </div>
            <div className="info-group d-flex justify-content-between">
              <p className="title m-0">Work location</p>
              <p className="m-0">{userDetails?.Location}</p>
            </div>
            <div className="info-group d-flex justify-content-between">
              <p className="title m-0">Agency</p>
              <p className="m-0 text-right">{userDetails?.Company}</p>
            </div>
            <div className="info-group d-flex justify-content-between">
              <p className="title m-0">License number</p>
              <p className="m-0">{userDetails?.LicenseNo}</p>
            </div>
          </div>

        </div>
        </Modal.Body>
    </Modal>
  );
};
export default AgentProfileInfoModal;
