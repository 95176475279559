import React, { useEffect, useState, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import RangeSliderMobile from "../components/RangeSliderMobile";
import PropertyTypeBtns from "../components/PropertyTypeBtns";
import DateRangeSelectorMob from "../components/DateRangeSelectorMob";
import { BUY, LONG_RENT, SHORT_RENT } from "../../constants/common";
import TextBox from "../../components/Common/TextBox";
import MobileDropdownSelect from "./MobileDropdownSelect";
import { thousandSeperatedValueFilter } from "../../helpers/common";
import { getPropertyTypes } from "../../services/commonServices";
import { getSquareFeetList } from "../../services/wantAdService";
import {
  convertToNumber,
  handleNegativeInput,
} from "../../helpers/validations";
import {
  replaceComma,
  getLocalStorage,
  replaceString,
  checkIsNumber,
  arraySplice,
  filterArrayBasedOnKey,
} from "../../helpers/common";

import { usePrevious } from "../../hooks/usePrevious";

const AdFilterPopup = (props) => {
  const {
    updateFilter,
    filterQuery,
    adType,
    clearFilterQuery,
    handleFilterApply,
    showFilterPopup,
    handleFilterPopUpClose,
    handleFilterPopUpShow,
    filterQueryFromStorage,
    setFilterQueryForApply,
    filterQueryForApply,
    setLocation
  } = props;
  
  // const propertyCount =  _.get(filterQuery, 'propertyType', [])?.length
  // const tPCount = _.get(filterQuery, 'typeOfPlace', [])?.length
  const MIN_SF_DROPDOWN = "minSFDrop";
  const MAX_SF_DROPDOWN = "maxSFDrop";
  const [typeOfPlaceList, setTypeOfPlaceList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [selectedTypeOfPlace, setSelectedTypeOfPlace] = useState([]);
  const [budgetRange, setBudgetRange] = useState("");
  const [squareFootageMinValue, setSquareFootageMinValue] = useState(null);
  const [squareFootageMaxValue, setSquareFootageMaxValue] = useState(null);
  const [propertyCount, setPropertyCount] = useState(0);
  const [tPCount, setTPCount] = useState(0);
  const [squareFeetList, setSqareFeetList] = useState([]);
  const [isPrivateRoomSelected, setIsPrivateRoomSelected] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [squareFeetMinList, setSqareFeetMinList] = useState([]);
  const [squareFeetMaxList, setSqareFeetMaxList] = useState([]);
  const alwPetsChkBx = "alwPets";
  const accsPvtBthRmchkBx = "pvtBath";
  const pvtEntrnceChkBx = "pvtEntrance";
  const adaAccessibleChkBx = "adaAccess";
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const INDEX_TO_BE_SLICE = 15;
  let DEFAULT_MAX_BUDGET;
  const DEFAULT_MIN_BUDGET = 0;
  let DEFAULT_STEP_VALUE;

  if (adType === BUY) {
    DEFAULT_MAX_BUDGET = 5000000;
    DEFAULT_STEP_VALUE = 25000;
  } else if (adType === LONG_RENT) {
    DEFAULT_MAX_BUDGET = 7500;
    DEFAULT_STEP_VALUE = 100;
  } else {
    DEFAULT_MAX_BUDGET = 5000;
    DEFAULT_STEP_VALUE = 50;
  }

  const budgetMin = _.get(filterQueryForApply, "priceMin") || _.get(filterQueryFromStorage, "priceMin");
  const budgetMax = _.get(filterQueryForApply, "priceMax") || _.get(filterQueryFromStorage, "priceMax");
  const STR_OPTION_DETAILS_PVT_ROOM = [
    { id: adaAccessibleChkBx, text: "ADA Accessible" },
    { id: alwPetsChkBx, text: "Allows pets" },
    { id: accsPvtBthRmchkBx, text: "Access to private bathroom" },
    { id: pvtEntrnceChkBx, text: "Private Entrance" },
  ];

  const STR_OPTION_DETAILS_ALL = [
    { id: adaAccessibleChkBx, text: "ADA Accessible" },
    { id: alwPetsChkBx, text: "Allows pets" },
  ];

  useEffect(() => {
    setPropertyCount(_.get(filterQueryForApply, 'propertyType', [])?.length)
    setTPCount(_.get(filterQueryForApply, 'typeOfPlace', [])?.length)
    if (adType === BUY) {
      getPropertyTypes(adType)
        .then((response) => {
          if (response.errorCode === 0 && _.get(response, "result", null)) {
            const propertyTypes = _.get(response, "result", null);
            setPropertyList(propertyTypes);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      setPropertyList([]);
    }

    let DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET];
    if (budgetMin && !budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, DEFAULT_MAX_BUDGET];
    } else if (!budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [DEFAULT_MIN_BUDGET, budgetMax];
    } else if (budgetMin && budgetMax) {
      DEFAULT_BUDGET_RANGE = [budgetMin, budgetMax];
    }
    setBudgetRange(DEFAULT_BUDGET_RANGE);
    setSelectedTypeOfPlace(
      _.get(filterQueryFromStorage, "typeOfPlace", []) || [10, 11, 6]
    );
    //updateFilter({ typeOfPlace: [10, 11, 6] });
    getSquareFeetList()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const list = _.get(response, "result", null);
          let newList = [];
          list.map((lst) => {
            const value = _.get(lst, "Sqft_Value");
            const text = _.get(lst, "Sqft_Value_Text", "");
            newList.push({ value: value, label: text });
          });
          if (_.get(filterQueryFromStorage, "propertyType", [])) {
            setPropertyCount(
              _.get(filterQueryFromStorage, "propertyType", []).length
            );
          } else {
            setPropertyCount(0);
          }
          setSqareFeetMinList([{ value: null, label: "No min" }, ...newList]);
          setSqareFeetMaxList([{ value: null, label: "No max" }, ...newList]);
          const maxValue =
            _.get(filterQueryFromStorage, "sqrftMax") != null
              ? {
                  value: _.get(filterQueryFromStorage, "sqrftMax", null),
                  label: _.get(filterQueryFromStorage, "sqrftMax"),
                }
              : { value: null, label: "No max" };
          const minValue =
            _.get(filterQueryFromStorage, "sqrftMin") != null
              ? {
                  value: _.get(filterQueryFromStorage, "sqrftMin", null),
                  label: _.get(filterQueryFromStorage, "sqrftMin"),
                }
              : { value: null, label: "No min" };
          setSquareFootageMaxValue(maxValue);
          setSquareFootageMinValue(minValue);
          setStartDate(_.get(filterQueryFromStorage, "fromDate") || null);
          setEndDate(_.get(filterQueryFromStorage, "toDate") || null);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, [adType]);

  useEffect(() => {
    if (adType !== BUY) {
      let type = "";
      if (
        selectedTypeOfPlace.includes(11) &&
        selectedTypeOfPlace.includes(10)
      ) {
        type = "es-pr";
      } else if (
        selectedTypeOfPlace.includes(11) &&
        selectedTypeOfPlace.includes(6)
      ) {
        type = "pr-land";
      } else if (
        selectedTypeOfPlace.includes(10) &&
        selectedTypeOfPlace.includes(6)
      ) {
        type = "es-land";
      }
      if (
        selectedTypeOfPlace.includes(11) &&
        selectedTypeOfPlace.includes(10) &&
        selectedTypeOfPlace.includes(6)
      ) {
        type = "all";
      }
      if (selectedTypeOfPlace.length == 1 && selectedTypeOfPlace.includes(11)) {
        type = "privateroom";
      }
      if (selectedTypeOfPlace.length == 1 && selectedTypeOfPlace.includes(10)) {
        type = "entirespace";
      }
      if (selectedTypeOfPlace.length == 1 && selectedTypeOfPlace.includes(6)) {
        type = "land";
      } else {
        setPropertyList([]);
      }
      getPropertyTypes(type)
        .then((response) => {
          if (response.errorCode === 0 && _.get(response, "result", null)) {
            const propertyTypes = _.get(response, "result", null);
            setPropertyList(propertyTypes);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });

      if (selectedTypeOfPlace.includes(11)) {
        setIsPrivateRoomSelected(true);
      } else {
        setIsPrivateRoomSelected(false);
      }
    }
  }, [selectedTypeOfPlace]);

  const prevMinBdgt = usePrevious(budgetRange[0]);
  const prevMaxBdgt = usePrevious(budgetRange[1]);

  useEffect(() => {
    updateRangeSliders();
  }, [startDate, endDate]);

  useEffect(() => {
    if (prevMinBdgt && prevMinBdgt !== budgetRange[0]) {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        priceMin: budgetRange[0]
      }));
      //updateFilter({ priceMin: budgetRange[0] });
    }
  }, [budgetRange[0]]);

  useEffect(() => {
    const mxBdgt = budgetRange[1] === DEFAULT_MAX_BUDGET ? "" : budgetRange[1];
    if (prevMaxBdgt && prevMaxBdgt !== budgetRange[1]) {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        priceMin: budgetMin, priceMax: mxBdgt
      }));
      //updateFilter({ priceMin: budgetMin, priceMax: mxBdgt });
    }
  }, [budgetRange[1]]);

  useEffect(() => {
    if (adType !== BUY) {
      ///      For loading type of place
      getPropertyTypes("rent")
        .then((response) => {
          if (response.errorCode === 0 && _.get(response, "result", null)) {
            const propertyTypes = _.get(response, "result", null);
            setTypeOfPlaceList(propertyTypes);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, [adType]);

  const handlePropertyClick = (value) => {
    const selectedItemArray = getSelectedItems("propertyType");
   
    if (value) {
      let itemArray;
      if (_.includes(selectedItemArray, value)) {
        itemArray = selectedItemArray.filter((e) => e !== value);
      } else {
        itemArray = [...selectedItemArray, value];
      }
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        propertyType: itemArray,
      }));
      //updateFilter({ propertyType: itemArray });
      // handleFormValues({ prptySubTypes: subItemArray});
      setPropertyCount(itemArray.length);
    }
  };

  const handleTypeOfPlaceClick = (value) => {
    const selectedItemArray = getSelectedItems("typeOfPlace");
    if (value) {
      let itemArray;
      if (_.includes(selectedItemArray, value)) {
        itemArray = selectedItemArray.filter((e) => e !== value);
      } else {
        itemArray = [...selectedItemArray, value];
      }
      setSelectedTypeOfPlace(itemArray);
      if (itemArray.includes(11)) {
        setIsPrivateRoomSelected(true);
      } else {
        setIsPrivateRoomSelected(false);
      }

      //updateFilter({ typeOfPlace: itemArray });
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        typeOfPlace: itemArray,
      }));
      // handleFormValues({ prptySubTypes: subItemArray});
      setTPCount(itemArray.length);
    }
  };

  const clearFilter = () => {
    clearFilterQuery();
    // setSquareFootageValues([0, 20000]);
    setSquareFootageMinValue({ value: null, label: "No min" });
    setSquareFootageMaxValue({ value: null, label: "No max" });
    setBudgetRange([DEFAULT_MIN_BUDGET, DEFAULT_MAX_BUDGET]);
    setPropertyCount(0);
    setTPCount(0);
    setLocation("")
    handleFilterPopUpClose()
  };

  const getSelectedItems = (property) => {
    const selectedItemArray = _.get(filterQueryForApply, property, []);
    return selectedItemArray;
  };

  const updateRangeSliders = async () => {
    //updateFilter({ fromDate: startDate, toDate: endDate });
    setFilterQueryForApply((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate
    }));
  };
  const handleAdditionalRequirementsClick = (e, typeId) => {
    const selectedItemArray = getSelectedItems(e.target.value);
    if (selectedItemArray == 0 || selectedItemArray == null) {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        [typeId]: 1
      }));
      //updateFilter({ [typeId]: 1 });
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        [typeId]: 1
      }));
    } else {
      //updateFilter({ [typeId]: 0 });
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        [typeId]: 0
      }));
    }
  };

  const loadAdditionalRequirements = () => {
    //let count = 1;
    const options = isPrivateRoomSelected
      ? STR_OPTION_DETAILS_PVT_ROOM
      : STR_OPTION_DETAILS_ALL;

    const List =
      options &&
      options.map((pt) => {
        const typeId = _.get(pt, "id", "");
        const typeText = _.get(pt, "text", "");
        const isChecked = getSelectedItems(typeId);
        return (
          <div className="checkbox-wrap mb-3">
            <label
              key={typeId}
              className="form-check-label checkbox-label"
              for={typeId}
            >
              <input
                className="form-check-input"
                type="checkbox"
                name="chkType"
                value={typeId ? typeId : ""}
                id={typeId}
                checked={isChecked == 1 ? true : false}
                onChange={(e) => handleAdditionalRequirementsClick(e, typeId)}
              />
              {typeText}
            </label>
          </div>
        );
        // } else {
        //   return null;
        // }
      });

    return List;
  };

  const handleLocationSearch = (e) => {
    updateFilter({ location: e.target.value });
  };

  const handleKeyWordSearch = (e) => {
    updateFilter({ search: e.target.value });
  };

  const handleBudgetMinChange = (e) => {
    const budgetMax = budgetRange && budgetRange[1];
    const value = handleNegativeInput(e.target.value);
    //checkBudgetValues();
    if (value) {
      setBudgetRange([parseInt(replaceComma(value)), budgetMax]);
    } else {
      setBudgetRange([null, budgetMax]);
    }
    // updateFilter({ priceMin: replaceComma(value) });
  };

  const handleBudgetMaxChange = (e) => {
    const budgetMin = budgetRange && budgetRange[0];
    const value = handleNegativeInput(e.target.value);
    if (parseInt(replaceComma(value))) {
      setBudgetRange([budgetMin, parseInt(replaceComma(value))]);
    } else {
      setBudgetRange([budgetMin, null]);
    }
    // updateFilter({ priceMax: replaceComma(value) });
    //validateValues(SFMinValue,value)
    // checkBudgetValues();
    //saveBudgetValuesToState()
  };

  const handleSquareFootMinChange = (e) => {
    // const SFMaxValue = squareFootageValues && squareFootageValues[1];
    const value = _.get(e, "value", "");
    setSquareFootageMinValue(e);
    setFilterQueryForApply((prevState) => ({
      ...prevState,
      sqrftMin: value
    }));
   //updateFilter({ sqrftMin: value });
    // saveSqFtValuesToState();
  };

  const handleSquareFootMaxChange = (e) => {
    const value = _.get(e, "value", "");
    setSquareFootageMaxValue(e);
    setFilterQueryForApply((prevState) => ({
      ...prevState,
      sqrftMax: value
    }));
    //updateFilter({ sqrftMax: value });
    //saveSqFtValuesToState();
  };

  const handleBedRoomClick = (value) => {
    const noOfBedrooms = getSelectedItems("bedrooms");
    const selectedValue = replaceString(value, "+");
    if (selectedValue == noOfBedrooms) {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        bedrooms: null
      }));
      //updateFilter({ bedrooms: null });
    } else {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        bedrooms: selectedValue
      }));
     // updateFilter({ bedrooms: selectedValue });
    }
    //handleContinueButton()
  };

  const handleBedsClick = (value) => {
    const noOfBeds = getSelectedItems("beds");

    const selectedValue = replaceString(value, "+");
    if (selectedValue == noOfBeds) {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        beds: null
      }));
      //updateFilter({ beds: null });
    } else {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        beds: selectedValue
      }));
      //updateFilter({ beds: selectedValue });
    }
    //handleContinueButton()
  };

  const handleBathRoomClick = (value) => {
    const noOfBathrooms = getSelectedItems("baths");

    const selectedValue = replaceString(value, "+");
    if (selectedValue == noOfBathrooms) {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        baths: null
      }));
      //updateFilter({ baths: null });
    } else {
      setFilterQueryForApply((prevState) => ({
        ...prevState,
        baths: selectedValue
      }));
      //updateFilter({ baths: selectedValue });
    }
    //handleContinueButton()
  };

  const addNewOptionsToMinList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMinList(customList);
  };

  const addNewOptionsToMaxList = (list, newOption) => {
    const customList = buildCustomList(list, newOption);
    setSqareFeetMaxList(customList);
  };

  const buildCustomList = (list, newOption) => {
    const filteredArray = filterArrayBasedOnKey({
      list: list,
      filterKey: "value",
      value: _.get(newOption, "value", null),
    });
    let customOptions = [...list];
    if (filteredArray?.length === 0 && _.get(newOption, "value", null)) {
      customOptions = [...list, newOption];
    }
    return customOptions;
  };

  const handleOnInputChange = (e, dropSection) => {
    // setSquareFootageMaxError("");
    if (dropSection === "minSFDrop") {
      if (minRef.current && minRef.current.contains(document.activeElement)) {
        if (checkIsNumber(e)) {
          const options = { value: convertToNumber(e), label: e };
          addNewOptionsToMinList(
            arraySplice(squareFeetMinList, INDEX_TO_BE_SLICE),
            options
          );
          setSquareFootageMinValue(options);
          setFilterQueryForApply((prevState) => ({
            ...prevState,
            sqrftMin: e
          }));
          //updateFilter({ sqrftMin: e });
        }
      }
    } else {
      if (maxRef.current && maxRef.current.contains(document.activeElement)) {
        if (checkIsNumber(e)) {
          const options = { value: convertToNumber(e), label: e };
          addNewOptionsToMaxList(
            arraySplice(squareFeetMaxList, INDEX_TO_BE_SLICE),
            options
          );
          setSquareFootageMaxValue(options);
          setFilterQueryForApply((prevState) => ({
            ...prevState,
            sqrftMax: e
          }));
          //updateFilter({ sqrftMax: e });
        }
      }
    }
  };

  const loadBedRooms = () => {
    const items = ["1+", "2+", "3+", "4+", "5+"];
    const noOfBedrooms = getSelectedItems("bedrooms");
    const list =
      items &&
      items.map((itm, index) => {
        const itmValue = replaceString(itm, "+");
        return (
          <button
            type="button"
            value={itmValue}
            class={
              noOfBedrooms === itmValue
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            key={index}
            onClick={(e) => handleBedRoomClick(itmValue)}
          >
            {itm}
          </button>
        );
      });
    return list;
  };

  const loadBeds = () => {
    const items = ["1+", "2+", "3+", "4+", "5+"];
    const noOfBedrooms = getSelectedItems("beds");
    const list =
      items &&
      items.map((itm, index) => {
        const itmValue = replaceString(itm, "+");
        return (
          <button
            type="button"
            value={itmValue}
            class={
              noOfBedrooms === itmValue
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            key={index}
            onClick={(e) => handleBedsClick(itmValue)}
          >
            {itm}
          </button>
        );
      });
    return list;
  };

  const loadBathRooms = () => {
    const items = ["1+", "2+", "3+", "4+", "5+"];
    const noOfBathrooms = getSelectedItems("baths");
    const list =
      items &&
      items.map((itm, index) => {
        const itmValue = replaceString(itm, "+");
        return (
          <button
            type="button"
            value={itmValue}
            class={
              noOfBathrooms === itmValue
                ? "btn btn-primary active"
                : "btn btn-primary"
            }
            key={index}
            onClick={(e) => handleBathRoomClick(itmValue)}
          >
            {itm}
          </button>
        );
      });
    return list;
  };

  return (
    <Offcanvas
      className="mobile-offcanvas zIndexPopup offcanvas-filter-height-fix offcanvas-filter-share-fix"
      show={showFilterPopup}
      onHide={handleFilterPopUpClose}
      placement="bottom"
      backdrop="static"
      {...props}
    >
      <Offcanvas.Header className="filter-offcanvas-header">
      <div className="offcanvas-sharead-wrap w-100 pe-3 ps-3">
          <div className="share-yourad-topsec w-100 pt-32 mb-20">
            <div>
              <a
                class="back-link-btn"
                href={void 0}
                role="button"
                onClick={() => handleFilterPopUpClose()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39966C10.3932 5.69256 10.3932 6.16743 10.1003 6.46032L5.31066 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3074 10.1003 18.6003C9.80744 18.8932 9.33256 18.8932 9.03967 18.6003L2.96967 12.5303C2.82902 12.3897 2.75 12.1989 2.75 12C2.75 11.8011 2.82902 11.6103 2.96967 11.4697L9.03967 5.39966C9.33256 5.10677 9.80744 5.10677 10.1003 5.39966Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a>
            </div>
            <div className="share-yourad-heading">Filters</div>
            <div></div>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        

        <div className="overflowXhide accordianStyle pe-3 ps-3">
          <div className="overflowXhide accordianStyle">
            <Accordion>
              {adType !== BUY && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Type of place{" "}
                    {tPCount && tPCount !== 0 ? `(${tPCount})` : ""}
                  </Accordion.Header>
                  <Accordion.Body>
                    <PropertyTypeBtns
                      list={typeOfPlaceList}
                      updateFilter={updateFilter}
                      filterQuery={filterQueryForApply}
                      section="typeOfPlace"
                      onClick={handleTypeOfPlaceClick}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Property Type{" "}
                  {propertyCount && propertyCount !== 0
                    ? `(${propertyCount})`
                    : ""}
                </Accordion.Header>
                <Accordion.Body>
                  <PropertyTypeBtns
                    list={propertyList}
                    updateFilter={updateFilter}
                    filterQuery={filterQueryForApply}
                    section="propertyType"
                    onClick={handlePropertyClick}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Price
                  {adType === LONG_RENT
                    ? "(monthly)"
                    : adType === SHORT_RENT
                    ? "(nightly)"
                    : ""}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="mt-2 mb-2">
                    <RangeSliderMobile
                      min={DEFAULT_MIN_BUDGET}
                      max={DEFAULT_MAX_BUDGET}
                      defaultValue={budgetRange}
                      value={
                        budgetRange && [
                          budgetRange[0] == null
                            ? DEFAULT_MIN_BUDGET
                            : budgetRange[0],
                          budgetRange[1] == null
                            ? DEFAULT_MAX_BUDGET
                            : budgetRange[1],
                        ]
                      }
                      stepValue={DEFAULT_STEP_VALUE}
                      onInput={setBudgetRange}
                    />
                  </div>

                  <div className="d-flex justify-content-between pt-4 pb-2">
                    <div className="position-relative">
                      <span className="dollar-sign-icon-mob">$</span>
                      <TextBox
                        type="text"
                        class=" pt-2 pb-2 pad-left-25"
                        value={
                          budgetRange && budgetRange[0] === ""
                            ? ""
                            : thousandSeperatedValueFilter(budgetRange[0])
                        }
                        placeholder="0"
                        onChange={(e) => {
                          handleBudgetMinChange(e);
                        }}
                      ></TextBox>
                    </div>
                    <div className="ps-2 pe-2 pt-2">-</div>
                    <div className="position-relative">
                    <span className="dollar-sign-icon-mob">$</span>
                      <TextBox
                        type="text"
                        class="pt-2 pb-2 pad-left-25"
                        value={
                          budgetRange && budgetRange[1] === DEFAULT_MAX_BUDGET
                            ? "No max"
                            : thousandSeperatedValueFilter(budgetRange[1])
                        }
                        placeholder="No max"
                        onChange={(e) => {
                          handleBudgetMaxChange(e);
                        }}
                      ></TextBox>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {adType === SHORT_RENT && (
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Dates</Accordion.Header>
                  <Accordion.Body>
                    <DateRangeSelectorMob
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {adType === SHORT_RENT ? "Rooms & beds" : "Beds and baths"}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="lp-btn-group-wrap mt-2 mb-2">
                    <h6>{adType === BUY || adType === LONG_RENT ? "Beds": "BedRooms"}</h6>

                    <div
                      className="btn-group marginBtn mb-3"
                      role="group"
                      aria-label="Basic example"
                    >
                      {loadBedRooms()}
                    </div>
                    {adType === SHORT_RENT && (
                      <>
                        <h6>Beds</h6>

                        <div
                          className="btn-group marginBtn mb-3"
                          role="group"
                          aria-label="Basic example"
                        >
                          {loadBeds()}
                        </div>
                      </>
                    )}

                    <h6>Baths</h6>

                    <div
                      className="btn-group marginBtn mb-3"
                      role="group"
                      aria-label="Basic example"
                    >
                      {loadBathRooms()}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Square Footage</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex justify-content-between pt-0 pb-2">
                    <div className="w-100" ref={minRef}>
                      <MobileDropdownSelect
                        hideMenuPortal={true}
                        id={MIN_SF_DROPDOWN}
                        name="colors3"
                        options={squareFeetMinList}
                        className="mobile-select"
                        classNamePrefix="select"
                        placeholder="No min"
                        value={squareFootageMinValue}
                        onInputChange={(e) =>
                          handleOnInputChange(e, MIN_SF_DROPDOWN)
                        }
                        onChange={(e) =>
                          handleSquareFootMinChange(e)
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        styles={{
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                            transform:
                              state.selectProps.menuIsOpen && "rotate(180deg)",
                          }),
                        }}
                        errorClass="error-msg error-msg-height"
                      />
                    </div>
                    <div className="pt-2 text-center pe-3 ps-3">-</div>
                    <div className="w-100" ref={props.maxRef}>
                      <MobileDropdownSelect
                        hideMenuPortal={true}
                        id={MAX_SF_DROPDOWN}
                        name="colors5"
                        options={squareFeetMaxList}
                        className="mobile-select"
                        classNamePrefix="select"
                        placeholder="No max"
                        value={squareFootageMaxValue}
                        onInputChange={(e) =>
                          handleOnInputChange(e, MAX_SF_DROPDOWN)
                        }
                        onChange={(e) =>
                          handleSquareFootMaxChange(e)
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        styles={{
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                            transform:
                              state.selectProps.menuIsOpen && "rotate(180deg)",
                          }),
                        }}
                        errorClass="error-msg error-msg-height"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {adType === SHORT_RENT && (
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Additional requirements</Accordion.Header>
                  <Accordion.Body>
                    {loadAdditionalRequirements()}
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>

            {/* <button type="button" className="btn btn-primary w-100 mb-4" >Apply</button> */}
            
              <div className="inner-btm-fixed-nav filter-btm-fixed-btnswrap">
                <div className="btn-wrap text-center pt-0">
                  <button
                    type="button"
                    className="btn btn-primary w-100 mb-20"
                    onClick={() => handleFilterApply()}
                  >
                    Apply
                  </button>
                  <a
                    href={void 0}
                    role="button"
                    className="blue-text-link noIcn pe-0 pt-0 pb-32 d-block"
                    onClick={() => clearFilter()}
                  >
                    Clear all
                  </a>
                </div>
              </div>
            
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default AdFilterPopup;
