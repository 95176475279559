import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { pageURLs } from "../../../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const IntroductionSuccessOffcanvas = (props) => {
  const {show, onHide} = props;
  const navigate = useNavigate();
  return (

    <Offcanvas
     className="mobile-offcanvas success-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}> 
        <Offcanvas.Body>
              <div className="success-wrap d-flex flex-column align-items-center justify-content-center">
                <div className="fireworks-overlay-top">
                  <div class="pyro pyro-left-top">
                    <div class="before"></div>
                    <div class="after"></div>
                  </div>
                </div>
                <i className="success-icon"></i>
                <h3>Introduction Sent!</h3>
                <p className="text-center">We’ll keep you updated on your introduction status. When they accept it, 
                    they’ll have 72 hours to send you a message. Good luck!</p>
                <button type="button" className="btn btn-primary w-100" onClick={()=>{navigate(pageURLs?.mobileHome)}}>Close</button>
              </div>
              
        </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default IntroductionSuccessOffcanvas;
