import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'

const loggerMiddleware = createLogger({ duration: true, diff: false })



const middleware = [
    thunkMiddleware,
    loggerMiddleware
  ]
  
  const setupStore = (preloadedState) => configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware,
  })
  
  export default setupStore