import React, { useState, useEffect } from "react";
import AdFilterIcon from "../components/adFilterIcn";
import FeedSortPopup from "../components/FeedSortPopup";

const PropertyListingHeader = (props) => {
  const { handleSortParam, title, sortParam, titleClass, options, isGitchaGenieFilter,selectedProperty, adList} = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sortParamSelected = (value) => {
    handleSortParam(value);
    handleClose();
  };
  return (
    <>
      {<div className={titleClass || "d-flex pb-4 pt-4"}>
         <div className="flex-grow-1 ad-feed-top-heading">{title}</div>
         {adList && adList.length>0 ?

        <div className="ad-feed-topright-sort">
          <a
            className="selected"
            href={void 0}
            role="button"
            onClick={handleShow}
          >
            {sortParam?.label}&nbsp;
            <AdFilterIcon />
          </a>
        </div> : <></>
        }
      </div>}
      <FeedSortPopup
        show={show}
        onHide={handleClose}
        handleSortParam={(value) => {
          sortParamSelected(value);
        }}
        sortParam={sortParam}
        options = {options}
      />
    </>
  );
};

export default PropertyListingHeader;
