import ProfileHeaderImg from "../../images/profilePic.jpg";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import NxtArrow from "../components/nextArrow";
import { formatDisplayPhoneNumber, loadTimeLineString, toTitleCase, truncate } from "../../helpers/common";
import { getCombinedText, getMustHavesText } from "../../helpers/getPropertyIcons";
import { BUY, BUY_LAND, IS_LAND, LOT_LAND } from "../../constants/common";
import { getNiceToHaves } from "../../services/commonServices";
import BottomStepperMob from "../components/BottomStepperMob";
import { wantAdBudgetValue } from "../../helpers/createWantAd";
import CheckIcn from "../images/checkIcn.svg";


const WantAdSummary = (props) => {
  const {
    completedPercentage,
    setUIKey,
    formValues,
    uiKey,
    setCompletedPercentage,
    saveWantAdData
  } = props;
  const location = _.get(formValues, "location", null);
  const adType = _.get(formValues, "adType", null);
  const prptySubTypes = _.get(formValues, "prptySubTypes", []);
  const propertyType = _.get(formValues, "prprtyType", null);
  const budgetMin = formValues?.minBdgt;
  const budgetMax = formValues?.maxBdgt;
  const mapRadius = _.get(formValues, "mapRadius", null);
  const city = _.get(formValues, "city", null);
  const state = _.get(formValues, "state", null);
  const [timeLineError, setTimeLineError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [budgetError, setBudgetError] = useState(false);
  const [mustHavesError, setMustHavesError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const nice2Have = _.get(formValues, "nice2Have", null);
  const title = _.get(formValues, "adTitle", "");
  const desc = _.get(formValues, "adDesc", "");
  const purchaseText = _.get(formValues, "purchaseText", null) || _.get(formValues, "prchTypeTxt", null);
  const is1031Exchange =
    (_.get(formValues, "is1031Exchange", "") == "Yes" || _.get(formValues, "is1031Exchange", "") == 1) &&
    " - 1031 exchange";
  const purchaseType = _.get(formValues, "purchaseType", null) || _.get(formValues, "prchTypeId", null);
  const pFinance = _.get(formValues, "pFinance", null) || _.get(formValues, "finTypeId", null);
  const pFinanceText = _.get(formValues, "pFinanceText", null) || _.get(formValues, "finTypeTxt", null) ;
  const adTypeBasedOnProperty = prptySubTypes?.includes(6) ? LOT_LAND : adType;
  const [niceToHavesList, setNiceToHavesList] = useState([]);


  const mustHaveText =
    getMustHavesText(formValues) != ""
      ? truncate(getMustHavesText(formValues), 30)
      : "";


    let isDisabled = false;
    if (adType != BUY && propertyType === IS_LAND) {
    isDisabled = false;
    } else if (prptySubTypes && prptySubTypes?.length == 0) {
    isDisabled = true;
    }

    let budget;
    if (budgetMin!==undefined || budgetMax!==undefined) {
        budget = wantAdBudgetValue(budgetMin, budgetMax);        
    } else {
        budget = null;
    }

    const getBudgetText = () => {
        if (isDisabled) {
          return "Select property type first";
        } else if (budget != null) {
          return budget;
        } else {
          return "Select your budget";
        }
      };

  const zipCodeValue = [];
  if(formValues?.zipCode){
    formValues?.zipCode.map((zip)=>{
      zipCodeValue.push(zip?.zipCode)
      })
  }


    useEffect(() => {
        if (nice2Have && nice2Have?.length > 0 === true) {
          getNiceToHaves(
            adTypeBasedOnProperty === LOT_LAND ? BUY_LAND : adTypeBasedOnProperty
          )
            ?.then((response) => {
              if (response.errorCode === 0 && _.get(response, "result", null)) {
                const list = _.get(response, "result", null);
                setNiceToHavesList(list);
              }
            })
            .catch((err) => {
              console.log("Error", err);
            });
        }
      }, [uiKey === null]);

  const getClassName = (condition, error) => {
    if (error) {
      return "selectBoxWrap d-flex mb-3 error-brdr";
    } else if (condition) {
      return "selectBoxWrap select d-flex mb-3";
    } else {
      return "selectBoxWrap d-flex mb-3";
    }
  };

  const loadTimeLine = () => {
    return loadTimeLineString(adType, formValues);
  };

  const getMustHaveText = () => {
    if (isDisabled) {
      return "Select property type first";
    } else {
      if (mustHaveText != "") {
        return mustHaveText;
      } else return "";
    }
  };

  const getNiceToHaveText = () => {
    if (isDisabled) {
      return "Select property type first";
    } else if (nice2Have && nice2Have?.length > 0) {
      return getNiceToHavesDisplaytext();
    } else {
      return "Select your nice to haves";
    }
  };

  const loadNiceToHaveList = (masterList, selectedItems) => {
    return (
      selectedItems &&
      selectedItems.map((nt) => {
        const id = _.get(nt, "id");
        const masterItem =
          masterList && masterList.filter((item) => item.Id === id);
        const itemText = _.get(masterItem[0], "Text", "");
        return itemText;
      })
    );
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);

    const masterArray = _.get(niceToHavesList, section, []);

    if (selectedItems?.length) {
      return loadNiceToHaveList(masterArray, selectedItems);
    } else {
      return "";
    }
  };

  const getNiceToHavesDisplaytext = () => {
    let resultText = "";
    const niceToHaveMasterArray =
      adTypeBasedOnProperty === LOT_LAND
        ? ["Views", "Characteristics"]
        : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        const list = loadEachNiceToHave(nh);
        if (list.length) {
          return list;
        } else {
          return "";
        }
      });
    const combinedArray =
      finalList && finalList.length > 0
        ? finalList?.filter((item) => item != "")
        : [];
    let merged =
      combinedArray && combinedArray.length > 0
        ? combinedArray.reduce(function (prev, next) {
            return prev.concat(next);
          })
        : [];

    merged = merged?.filter((item) => item != "")
    if (merged?.length > 0) {
      resultText = merged[0];
      if (merged?.length > 1) {
        for (let i = 1; i < merged?.length - 1; i++) {
          resultText = resultText + ", " + merged[i];
        }
        resultText = resultText + " or " + merged[merged?.length - 1];
      }
      return truncate(resultText, 30);
    } else {
      return "";
    }
  };

  const validate = () =>{
    let error = [];
    let isValid = true;    
    if(!location && zipCodeValue?.length ==0){
      setLocationError(true);
      error.push("locationRef")
      isValid = false;
    }
    if(loadTimeLine() == "" || loadTimeLine()== null){
      setTimeLineError(true);
      error.push("timeLineRef")
      isValid = false;
    }
    if(prptySubTypes?.length <= 0){
      error.push("propertyTypeRef")
      setPropertyTypeError(true);
      isValid = false;
    }
    
    if(propertyType != 11 && getMustHaveText() === "") {
      setMustHavesError(true)
      error.push("mustHaveRef")
      isValid = false;
    } 
    if(!budget){
      setBudgetError(true)
      error.push("BudgetRef")
      isValid = false;
    }
    // if(isEdit && title === ""){
    //   setTitleError(true);
    //   error.push("DescriptionRef")
    //   isValid = false;
    // }
    if (error.length != 0) {
      if (error[0] == 'ClientRef') {
        document.getElementById('clientSelectionId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'adTypeRef') {
        document.getElementById('adTypeId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'locationRef') {
        document.getElementById('locationId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'timeLineRef') {
        document.getElementById('timeLineId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'propertyTypeRef') {
        document.getElementById('propertyTypeId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'mustHaveRef') {
        document.getElementById('mustHaveId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'BudgetRef') {
        document.getElementById('budgetId')?.scrollIntoView({ behavior: 'smooth' });
      } else if (error[0] == 'DescriptionRef') {
        document.getElementById('descriptionId')?.scrollIntoView({ behavior: 'smooth' });
      }
    }    
    return isValid;

  }

  return (
    <div className="gitcha-main-wrapper pb-168 bg-white" id="pageTop">
      <div className="content-wrapper content-wrapper-h-102-percent">
        <div className="inner-layout-header-new bg-white ">
          <div className="top-three-comp-header w-100 flex-column">
            <div className="top-three-comp-header-inner w-100">
              {/* <a className="d-block back-link-big me-4" href="#">
                back
              </a> */}
              <div className="progress-wrap-mob">
              <BottomStepperMob completedPercentage={completedPercentage} />
              </div>
            </div>
          </div>
        </div>

        <div className="inner-layout-content-new bg-white pl-16 pr-16 pb-0 position-relative">
          <div className="layout-content-btm-sec position-static pe-0 ps-0 pt-0">
            <div className="mt-0 pt-0 mb-0 title01 mb-3">
             <> The Buyer Listing Service<sup>TM</sup></>
            </div>

            <div class="buyerListProfile d-flex align-items-center">
            {formValues?.userImage !== "" ? (
              <div className="profile-img-wrap" >
                  <img src={formValues?.userImage + "?" + Math.random().toString(36)} />
              </div>
                ) : (
                  <div className="proflieNoImg">
                  <svg
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                      fill="#A0A9BA"
                    />
                  </svg>
                  </div>
                )}
              <div>
                <h5>
                {formValues?.userFirstName} {formValues?.userLastName} {formValues?.isPremiumAgent ? <span className="pro-badge">PRO</span> : <></>}
                </h5>
                Agent @ {formValues?.userCompany}
                <br />
                {formatDisplayPhoneNumber(formValues?.Phone)}
              </div>
            </div>
            <p className="mt-3 mb-3 ProfilegreyTxt">
              Let {toTitleCase(formValues?.userFirstName)} know what you’re looking to buy. <br />
              The more details, the better!
            </p>
          </div>
          <div
              className={getClassName(zipCodeValue?.length > 0 ? zipCodeValue : location, locationError)}
              onClick={() => setUIKey("location")}
              id="locationId"
            >
              <div className="flex-grow-1">
                <h4>Location * {(location || zipCodeValue?.length > 0) ? <img src={CheckIcn} /> : <></>}</h4>
                {zipCodeValue?.length > 0 ? (
                  <>
                  <h5>Within ZIP code {zipCodeValue.join(', ')}</h5>
                  </>
                ) : 
                (
                  <>
                 { location ? (
                  <h5>
                    {mapRadius} mi within {city}, {state}
                  </h5>
                ) : (
                  <h5>Select location</h5>
                )}
                  </>
                )}
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>
          {/* <div
            className={getClassName(location, locationError)}
            onClick={() => setUIKey("location")}
            id="locationId"
          >
            <div className="flex-grow-1">
              <h4>Location * {location ? <img src={CheckIcn} /> : <></>}</h4>
              {location ? (
                <h5>
                  {mapRadius} mi within {city}, {state}
                </h5>
              ) : (
                <h5>Select location</h5>
              )}
            </div>
            <div className="align-self-center ">
              <Button variant="" id="button-addon2">
                <NxtArrow />
              </Button>
            </div>
          </div> */}
          <div
            className={getClassName(
              loadTimeLine() != "" && loadTimeLine() != null,
              timeLineError
            )}
            onClick={() => setUIKey("timeLine")}
            id="timeLineId"
          >
            <div className="flex-grow-1">
              <h4>
                Timeline *{" "}
                {loadTimeLine() != "" && loadTimeLine() != null ? (
                  <img src={CheckIcn} />
                ) : (
                  <></>
                )}
              </h4>
              <h5>
                {loadTimeLine() != "" && loadTimeLine() != null
                  ? loadTimeLine()
                  : "Select your timeline"}
              </h5>
            </div>
            <div className="align-self-center ">
              <Button variant="" id="button-addon2">
                <NxtArrow />
              </Button>
            </div>
          </div>
          <div
              className={getClassName(prptySubTypes && prptySubTypes?.length > 0, propertyTypeError)}
              onClick={() => setUIKey("propertyType")}
              id="propertyTypeId"
            >
              <div className="flex-grow-1">
                <h4>
                  Property Type *{" "}
                  {prptySubTypes && prptySubTypes?.length > 0 ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>
                  {prptySubTypes && prptySubTypes?.length > 0
                    ? truncate(getCombinedText(prptySubTypes), 30)
                    : "Select your property"}
                </h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            <div
              className={
                !isDisabled
                  ? (getClassName(getMustHaveText() != "", mustHavesError))
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("mustHaves");
                }
              }}
              id="mustHaveId"
            >
              <div className="flex-grow-1">
                <h4>
                  Must Haves {propertyType != 11 ? "*" : ""}{" "}
                  {getMustHaveText() != "" && !isDisabled ? (
                    <img src={CheckIcn} />
                  ) : (
                    ""
                  )}
                </h4>
                <h5>
                  {getMustHaveText() != ""
                    ? getMustHaveText()
                    : "Select your must haves"}
                </h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>

            <div
              className={
                !isDisabled
                  ? (nice2Have && nice2Have?.length > 0 != "" ? "selectBoxWrap d-flex mb-3 select" : "selectBoxWrap d-flex mb-3")
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("niceToHaves");
                }
              }}
            >
              <div className="flex-grow-1">
                <h4>
                  Nice-to-Haves{" "}
                  {nice2Have && nice2Have?.length > 0 && !isDisabled ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>{getNiceToHaveText()}</h5>
              </div>
              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>
            
            <div
              className={
                !isDisabled
                  ? getClassName(budget != null, budgetError)
                  : "selectBoxWrap d-flex mb-3 disable"
              }
              onClick={() => {
                if (!isDisabled) {
                  setUIKey("budget");
                }
              }}
              id="budgetId"
            >
              <div className="flex-grow-1">
                <h4>
                  Budget *{" "}
                  {budget != null && !isDisabled ? (
                    <img src={CheckIcn} />
                  ) : (
                    <></>
                  )}
                </h4>
                <h5>{getBudgetText()}</h5>
              </div>

              <div className="align-self-center ">
                <Button variant="" id="button-addon2">
                  <NxtArrow />
                </Button>
              </div>
            </div>           
            <div
                  className={ purchaseType ? "selectBoxWrap select d-flex mb-3" : "selectBoxWrap d-flex mb-3"}
                  onClick={() => setUIKey("purchaseType")}
                >
                  <div className="flex-grow-1">
                    <h4>
                      Purchase Type{" "}
                      {purchaseType ? <img src={CheckIcn} /> : <></>}
                    </h4>
                    <h5>{purchaseType ? purchaseText + (is1031Exchange ? is1031Exchange : '') : "Select type"}</h5>
                  </div>
                  <div className="align-self-center ">
                    <Button variant="" id="button-addon2">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>
                <div
                  className={pFinance ? "selectBoxWrap select d-flex mb-3" : "selectBoxWrap d-flex mb-3"}
                  onClick={() => setUIKey("financing")}
                >
                  <div className="flex-grow-1">
                    <h4>
                      Financing {pFinance ? <img src={CheckIcn} /> : <></>}
                    </h4>
                    <h5>{pFinanceText ? pFinanceText : "Select financing"}</h5>
                  </div>
                  <div className="align-self-center ">
                    <Button variant="" id="button-addon2">
                      <NxtArrow />
                    </Button>
                  </div>
                </div>

        </div>
      </div>
      <div className="inner-layout-footer-new">
        <div className="inner-btm-fixed-nav position-static">
          <div className="btn-wrap text-center pb-0 pt-0">
            <button
              type="button"
              className="btn btn-primary w-100 mb-3"
              disabled=""
              onClick={() => {
                const valid = validate();
                if(valid){                
                  saveWantAdData('save');                
                }
              
              }}
            >
              Send to my agent
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WantAdSummary;
