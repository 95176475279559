/**
 * @file   src\public\ShowWantAd.js
 * @brief  This file is responsible for showing want ad details page public view
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
import LakeViewIcon from "../images/lake-view-icon.svg";
import ConsumerIcon from "../images/consumer-icon.svg";
import  MinCapIcon from "../images/minimum-icn.svg";
import FinancingIcon from "../images/financing-icon.svg";
import PrResIcon from "../images/primary-residence-icon.svg";
import WantadDetailsSkeleton from "../pages/WantadDetailsSkeleton";
import base64 from "base-64";
import {
  getPropertyIcon,
  getTooltipText,
  getCombinedText,
} from "../helpers/getPropertyIcons";
import utf8 from "utf8";
import { BUY, LONG_RENT, SHORT_RENT, LOT_LAND, IS_LAND } from "../constants/common";
import { loadCompensationText, loadPreferredLocationHtml, loadTimeLineHtml, locationDisplay } from "../helpers/common";
import { pageURLs } from "../constants/pageURLs";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { getWantAdDetailsById } from "../services/wantAdService";
import { add } from "lodash";
import { buildWantAdMustHavesHTML, loadInvestmentCriteria } from "../helpers/createWantAd";
import { siteBaseURL } from "../constants/endPoints";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ShowWantAd = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isFromHomeFeed =
    location.state && location?.state["isFromHomeFeed"] ? true : false;
  searchParams.get("wantAdId");
  const [skeltonLoad, setSkeltonLoad] = useState(true); //
  const id = searchParams.get("wantAdId");
  let decodedId;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
  } catch {}

  const [adDetails, setAdDetails] = useState();
  const adType = _.get(adDetails, "adType", "");
  const propertyType = Number(_.get(adDetails, "prprtyType", ""));
  const adTypeBasedOnProperty = propertyType === 6 ? LOT_LAND : adType;
  const isAdTypeIsLand = adTypeBasedOnProperty === LOT_LAND;
  const nice2Have = _.get(adDetails, "nice2Have", []);
  const mapImage = _.get(adDetails, "mapImg", null);
  const consumerType = adType == BUY ? "Buyer" : "Renter";
  const propertySubTypes = _.get(adDetails, "prptySubTypes", []);
  useEffect(() => {
    if (decodedId) {
      const wantadId = decodedId.split("-"); // Split decoded string for getting want ad id.
      if (wantadId && wantadId.length) {
        getWantAdDetails(wantadId[1]);
      } else {
        navigate(pageURLs.notFound);
      }
    } else {
      navigate(pageURLs.notFound);
    }
  }, []);
/**
 * To get wantad details
 * @param {*} wantAdId 
 */
  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdDetailsById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setAdDetails(responseResult);
            setSkeltonLoad(false);
          } else {
            navigate(pageURLs.notFound);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.no);
    }
  };
/**
 * To display nice to haves section
 * @param {*} section 
 * @returns 
 */
  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isAdTypeIsLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };
  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueType}>
            <div className="nicetohaves-tag-icon">
              <img
                src={icon ? require("../images/" + icon) : LakeViewIcon}
                alt={icon ? require("../images/" + icon) : LakeViewIcon}
              />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  }; 
/**
 * To display ad title
 * @returns 
 */
  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }
    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );
  };
/**
 * To display strengthen want ad details
 * @returns 
 */
  const loadStrengthenDetails = () => {
    if (_.get(adDetails, "isStrengthen", "")) {
      if (adType === BUY) {
        return (
          <>
            {" "}
            {_.get(adDetails, "prchTypeTxt", "") && (
              <li>
                <span className="list-icn-wrap">
                  <img src={PrResIcon} />
                </span>
                {_.get(adDetails, "prchTypeTxt", "")}
                {(_.get(adDetails, "is1031Exchange", "") == "Yes" || _.get(adDetails, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
              </li>
            )}
            {_.get(adDetails, "finTypeTxt", "") && (
              <li>
                <span className="list-icn-wrap">
                  <img src={FinancingIcon} />
                </span>
                Financing: {_.get(adDetails, "finTypeTxt", "")}
                {(_.get(adDetails, "isPreApprLoan", "") == "Yes" ||
                  _.get(adDetails, "isPreApprLoan", "") == 1) &&
                  "  - pre approved"}
              </li>
            )}
          </>
        );
      } else if (adType === LONG_RENT) {
        return (
          <>
            {" "}
            {_.get(adDetails, "creditScoreText", "") && (
              <li>
                <span className="list-icn-wrap">
                  <img src={FinancingIcon} />
                </span>
                Credit Score : {_.get(adDetails, "creditScoreText", "")}
              </li>
            )}
          </>
        );
      }
    }
  };
/**
 * To display preferred location
 * @returns 
 */
  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(adDetails);
  };

  /**
   * To display want ad timeline
   * @returns 
   */
  const loadTimeLine = () => {
    return loadTimeLineHtml(adType, adTypeBasedOnProperty, adDetails);
  };
/**
 * To display proprty type tile on map image
 * @param {*} props 
 * @returns 
 */
  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {adType === BUY
        ? getTooltipText(propertyType)
        : propertySubTypes &&
          propertySubTypes.length > 0 &&
          getCombinedText(propertySubTypes)}
    </Tooltip>
  );

  /**
   * To display want ad must haves
   * @returns 
   */
  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      formValues: adDetails,
    });
  };
  const isNiceToHaveExist = nice2Have.length > 0;
  return (
    <>
      {skeltonLoad ? (
        <WantadDetailsSkeleton />
      ) : (
        <>
          <div className="outer-wrap position-relative mw-1168">
            <CommonHeader  />
            {isFromHomeFeed && (
              <a
                role="button"
                className="bottom-back-btn mb-24 d-inline-block"
                onClick={() => navigate(-1)}
              >
                <span class="left-arrow">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                      fill="#205ECA"
                    ></path>
                  </svg>
                </span>
                Back
              </a>
            )}
            <div className="row">
              <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-9 ps-xl-2">
                <div className="wantad-confirmation-wrap mb-4">
                  <div className="confirmation-top-sec d-md-flex justify-content-between">
                    <div className="long-txt-fix">
                      <div class="confirm-sub-title mb-1">
                        I'm looking to:
                        {loadAdTitle()}
                      </div>
                      <h2>
                        {_.get(
                          adDetails,
                          "adTitle",
                          "Cozy home for small family"
                        )}{" "}
                      </h2>
                      <span className="trailing-txt">
                      {locationDisplay(adDetails)}
                        {/* Within {_.get(adDetails, "mapRadius", "")} mi of{" "}
                        {_.get(adDetails, "city", "")}
                        {", "}
                        {_.get(adDetails, "state", "")} */}
                      </span>
                    </div>
                  </div>
                  <div className="confirm-map-wrap">
                    <div className="property-tile w-auto">
                      <OverlayTrigger
                        placement="bottom-start"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <div className="property-tile w-auto">
                          {adType === BUY ? (
                            <img
                              src={getPropertyIcon(propertyType)}
                              alt={getPropertyIcon(propertyType)}
                              className="property-icon-img"
                            />
                          ) : (
                            propertySubTypes &&
                            propertySubTypes.length > 0 &&
                            propertySubTypes.map((subtype) => {
                              return (
                                <img
                                  src={getPropertyIcon(subtype)}
                                  alt={getPropertyIcon(subtype)}
                                  className="property-icon-img"
                                />
                              );
                            })
                          )}
                        </div>
                      </OverlayTrigger>
                    </div>
                    <img
                      src={mapImage + "?" + Math.random().toString(36)}
                      alt={mapImage}
                      className="disp-map-img"
                    />
                  </div>
                  {loadAdMustHaves()}

                  {isNiceToHaveExist ? (
                    <div className="nicetohaves-wrap">
                      <h2>Nice-to-haves</h2>
                      <div className="nicetohaves-inner-wrap">
                        {finalNiceToHaveList()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {_.get(adDetails, "adDesc", "") ? (
                    <div className="additional-details-wrap">
                      <h2>Additional details</h2>
                      <p>{_.get(adDetails, "adDesc", "")}</p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className={((adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&
                        _.get(adDetails, "isAgentCreated", "") == 1) ? "buyer-info nicetohaves-wrap" : "buyer-info"}>
                    {/* <h2>{consumerType} Information</h2> */}
                    <h2>Additional details</h2>
                    <ul>
                      <li>
                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        {_.get(adDetails, "isAgentCreated", "") == 1
                          ? " Represented by Agent"
                          : consumerType}
                      </li>            

                      {loadTimeLine()}
                      {loadPreferredLocation()}
                      {loadStrengthenDetails()}
                    </ul>
                  </div>
                  {((adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&
                        _.get(adDetails, "isAgentCreated", "") == 1) && (_.get(adDetails, "compenationType", "")) && 
                        <div className="buyer-info">
                    <h2>Requested Concessions From Seller</h2>
                    <ul>                      
                      {_.get(adDetails, "compenationType", "") ?(
                          <li>
                            <span className="list-icn-wrap">
                              <img src={FinancingIcon} />
                            </span>
                            {loadCompensationText(parseInt(_.get(adDetails, "compenationType", "")),_.get(adDetails, "agentCompenation", ""))}                            
                          </li>
                        ) : ""}  
                        {_.get(adDetails, "otherConcessions", "") ?(
                          <li>
                            {_.get(adDetails, "otherConcessions", "")}                         
                          </li>
                        ) : ""}                    
                    </ul>
                  </div>}
                  {(_.get(adDetails, "investmentId", [])?.length>0 || _.get(adDetails, "capRateId", null)) ? 
                    <div className="buyer-info">
                      <h2>What This Investor is Open to</h2>
                      <ul>
                        {loadInvestmentCriteria(adDetails)}
                        {_.get(adDetails, "capRateId", null) && <li>
                          <span className="list-icn-wrap">
                            <img src={MinCapIcon} />
                          </span>
                          Minimum cap rate of {_.get(adDetails, "capRateId", "")==15 ? "15+" : _.get(adDetails, "capRateId", "")}%
                        </li>}
                      </ul>
                    </div>:<></>}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShowWantAd;
