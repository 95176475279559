import React, { useState, useEffect } from "react";
import { getInAppNotications, updateInAppDeleteStatus } from "../../services/introductionService";
import { getLocalStorage, loggedInUser, setBodyBackgroundGrey, setBodyBackgroundWhite } from "../../helpers/common";
import { USER_INFO_KEY } from "../../constants/common";
import NotificationComponent from "../components/inapp/NotificationComponent";
import DeleteModal from "../components/Common/DeleteModal";

const Inapp = () => {

    const [notificationList, setNotificationList] = useState([]);
    const user = loggedInUser();
    const UserId =
        _.get(user, "userInfo.userId", null) ||
        _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", null);

    const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);
    const handleDeleteNotificationPopupClose = () =>
        setDeleteNotificationModal(false);
    const [isLoadNotificationList, setLoadNotificationList] = useState(false);


    // in-app notification
    useEffect(() => {
        console.log(UserId)
        if (UserId) {
            getNotificationList();
        }
    }, [UserId]);

    useEffect(() => {
        setBodyBackgroundGrey()
       
       }, []);

    const getNotificationList = async () => {
        setLoadNotificationList(false);
 
        await getInAppNotications().then((response) => {
            if (response) {
                const list = _.get(response, "result.List", null);

                if (response.errorCode === 0) {
                    setNotificationList(list);
                }
                setLoadNotificationList(true);
            } else{
                setLoadNotificationList(true);
            }
        });
    };



    const updateInAppDeleteStatusOnClick = async () => {
        const userId = _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", "");
        let notificationId = null;
        let subjectType = null;
        let deleteStatus = true;
        let deleteType = 2
        await updateInAppDeleteStatus(
            notificationId,
            deleteStatus,
            userId,
            deleteType,
            subjectType
        ).then((response) => {
            if (response) {
                if (response.errorCode === 0) {
                    setDeleteNotificationModal(false);
                    setNotificationList([]);
                }
            }
        });
    };


    const onDeleteInappNotification = () => {
        setDeleteNotificationModal(true);
    };

    return (
        <>
            <div className="gitcha-main-wrapper pb-0 h-100 bg-white">
                <div className="content-wrapper vh-100 android-h-fix">
                    <div className="inner-layout-header-new bg-white ">
                        <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
                            <div className="top-three-comp-header-inner w-100">
                                <a className="back-link-btn" href="/mobile/home"  >
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z" fill="#1B1C1E" />
                                    </svg>
                                </a>
                                <div className="heading">Notifications</div>
                                <div class="p-0 toplinks-mobile">
                                     {
                                        notificationList.length > 0 && 

                                        <div className="topbar-links notif-delete-btn" onClick={() => { onDeleteInappNotification() }} >

                                        </div>
                                     } 
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="inner-layout-content-new h-100 bg-grey-variant ">
                        {notificationList.length == 0 && isLoadNotificationList &&
                            <div className="no-data-txt h-100 d-flex align-items-center justify-content-center flex-column">

                                <p className="m-0 text-center">No new notifications </p>
                            </div>

                        }
                        <div>
                            <div className="notif-content-wrapper pt-0">
                                <NotificationComponent
                                    notificationListData={notificationList}
                                    reloadNotificationList={() => { getNotificationList() }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DeleteModal
                show={deleteNotificationModal}
                onHide={handleDeleteNotificationPopupClose}
                title={"Are you sure you want to delete all notifications?"}
                message={""}
                buttonText={"Confirm"}
                handleConfirm={updateInAppDeleteStatusOnClick}
            />

        </>
    );
};

export default Inapp;
