/**
 * @file   src\components\Common\CheckBox.js
 * @brief  This file is the checkboxComponent
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';

const CheckBox = props => {
  return (
      <input
        className={props.class}
        id={props.id}
        name={props.name}
        type= "checkbox"
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        onClick= {props.onClick}
        disabled = {props.disabled}
      />
  );
}
export default (CheckBox);
