import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../constants/pageURLs";
import BottomStepperMob from "./BottomStepperMob";
import {
  getLocalStorage,
  getUserType,
  removeLocalStorage,
  setLocalStorage,
} from "../../helpers/common";
import { BUY, IS_LAND, USER_TYPE_AGENT, WANT_AD_KEY, WANT_AD_KEY_TEMP } from "../../constants/common";
import { useEffect } from "react";

const ProgressBar = (props) => {
  useEffect(() => {
    document
      .getElementById("scroll-chain")
      .classList.remove("scroll-chaining-fix");
  }, []);
  const {
    completedPercentage,
    setUIKey,
    isFromReadyToPost,
    isFromAdType,
    isPremiumUser,
    isFromSelectClient,
    isEdit,
    isFromBuyerListingService,
    isFromAddCompensation,
  } = props;
  const data = getLocalStorage(WANT_AD_KEY);
  const userType = getUserType();

  const isAgent = userType===USER_TYPE_AGENT ? true : false

  const navigate = useNavigate();
  return (
    <div className="inner-layout-header-new bg-white">
      <div className="top-three-comp-header w-100 flex-column">
        <div className="top-three-comp-header-inner w-100">
          {isFromSelectClient && !data?.clientId || isFromSelectClient && !data?.adType  ? <></>:
          <a
            className="d-block back-link-big me-4"
            href={void 0}
            onClick={(e) => {
              setTimeout(() => {
              setLocalStorage(WANT_AD_KEY, {...data, backArrowClicked: true})
           
              if (isFromBuyerListingService) {
                setUIKey(null);
              } else {
                if (isEdit) {
                  setUIKey(null);
                } else {
                  if (!data?.adType) {
                    if (isFromSelectClient && !data?.adType) {
                      removeLocalStorage(WANT_AD_KEY);
                      removeLocalStorage(WANT_AD_KEY_TEMP);
                      navigate(pageURLs.mobilePremiumAgentCreateWantAd);
                    } else if (isFromAdType) {
                      removeLocalStorage(WANT_AD_KEY);
                      removeLocalStorage(WANT_AD_KEY_TEMP);
                      navigate(pageURLs.mobileHome);
                    }
                  }                   
                  else if (isFromAdType && isPremiumUser && !data.adType) {
                    setUIKey("client");
                  } 
                  else {
                    setLocalStorage(
                      WANT_AD_KEY_TEMP,
                      getLocalStorage(WANT_AD_KEY)
                    );
                    if (isFromReadyToPost) {
                      if(isAgent &&  (( _.get(data, "adType", "") == BUY ||  _.get(data, "prprtyType", "") == IS_LAND))){
                        setUIKey("compensation")
                      } 
                      else {setUIKey("title");}
                    }
                    else if(isFromAddCompensation){
                      setUIKey("title");
                    } else {
                      setUIKey(null);
                    }
                  }
                }
              }
            }, 300)
            }}
          >
            back
          </a>}
          <div className="progress-wrap-mob">
            {!isEdit && (
              <BottomStepperMob completedPercentage={completedPercentage} />
            )}
          </div>

          <button
            className="btn top-exit-btn ms-4"
            onClick={() => {
              if (isFromBuyerListingService) {
                setUIKey(null);
              } else {
                removeLocalStorage(WANT_AD_KEY);
                removeLocalStorage(WANT_AD_KEY_TEMP);
                navigate("/mobile/home");
              }
            }}
          >
            Exit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
