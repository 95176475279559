import React, { useState, useEffect } from "react";
import { getFinanceType } from "../../services/wantAdService";
import { handleRadioButonChange } from "../../actions/common";
import ConfirmApprovalModal from "../MemberWantAd/ConfirmApprovalModal";

const WantAdPurchaseFinance = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    financeType,
    isPreApprLoan,

    isEdit = false,
  } = props;
  const [financeList, setFinanceList] = useState([]);
  const [isConventionalMortage, setConventionalMortage] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  let isUnCheckItem = false;
  useEffect(() => {
    if (financeType && (financeType === "2" || financeType == 2)) {
      if (
        isPreApprLoan === "Yes" ||
        (isPreApprLoan == true && isEdit == false)
      ) {
        handleContinueButtonState();
        setShowConfirmPopup(true);
      } else if (!isPreApprLoan) {
        const isDisable = true;
        handleContinueButtonState(isDisable);
      }
      setConventionalMortage(true);
    } else if (financeType) {
      handleContinueButtonState();
    }
    getFinanceType()
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const financeList = _.get(response, "result", null);
          setFinanceList(financeList);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const closeConfirmPopup = () => {
    const isDisableButton = true;
    handleContinueButtonState(isDisableButton);
    setShowConfirmPopup(false);
    handleFormValues({ isPreApprLoan: "" });
  };

  const handleAdjustBudgetClick = () => {
    handleFormValues({ isAdjustBdget: true });
  };

  const handleItemClick = (e, FinanceText) => {
    setConventionalMortage(false);
    const isDisable = true;
    const value = handleRadioButonChange(e);
    if (value === financeType) {
      handleFormValues({ pFinance: "", pFinanceText: "", isPreApprLoan: "" });
      setConventionalMortage(false);
      handleContinueButtonState(isDisable);
      isUnCheckItem = true;
    } else {
      if (value === "2") {
        setConventionalMortage(true);
        handleContinueButtonState(isDisable);
      } else {
        handleFormValues({ isPreApprLoan: "" });
        setConventionalMortage(false);
        handleContinueButtonState();
      }
      handleFormValues({ pFinance: value, pFinanceText: FinanceText });
    }
  };
  const handlePreApproveClick = (e) => {
    const selectedValue = handleRadioButonChange(e);
    if (selectedValue === "Yes") setShowConfirmPopup(true);
    handleFormValues({ isPreApprLoan: selectedValue });
    handleContinueButtonState();
  };

  const confirmPreApprovalStatus = () => {
    setShowConfirmPopup(false);
  };

  const loadFinanceTypes = () => {
    const financeLists =
      financeList &&
      financeList.map((tl) => {
        const FinanceTypeId = _.get(tl, "FinanceTypeId");
        const FinanceTypeText = _.get(tl, "FinanceTypeText", "");
        return (
          <label className="radio-wrap mb-3" key={FinanceTypeId}>
            <input
              type="radio"
              name="pf-radio"
              value={FinanceTypeId}
              onClick={(e) => handleItemClick(e, FinanceTypeText)}
              onChange={(e) => handleItemClick(e, FinanceTypeText)}
              checked={
                isUnCheckItem
                  ? false
                  : financeType && financeType == FinanceTypeId
              }
            />
            <span>
              <div className="timing-wrap-info">
                <h2>{FinanceTypeText}</h2>
              </div>
            </span>
          </label>
        );
      });

    return financeLists;
  };

  return (
    <div className={isEdit == false ? "center-content-sec mw-444" : ""}>
      <div className="wantad-timing-wrap">
        {loadFinanceTypes()}
        {isConventionalMortage && (
          <div className="row">
            <div>
              <h6 className="">Are you pre-approved for a loan?</h6>
            </div>
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio-preApprove"
                value="Yes"
                checked={
                  isPreApprLoan &&
                  (isPreApprLoan === "Yes" || isPreApprLoan == true)
                }
                onClick={(e) => handlePreApproveClick(e)}
                onChange={(e) => handlePreApproveClick(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>Yes</h2>
                </div>
              </span>
            </label>

            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio-preApprove"
                value="No"
                checked={
                  isPreApprLoan &&
                  (isPreApprLoan === "No" || isPreApprLoan == false)
                }
                onClick={(e) => handlePreApproveClick(e)}
                onChange={(e) => handlePreApproveClick(e)}
              />
              <span>
                <div className="timing-wrap-info">
                  <h2>No</h2>
                </div>
              </span>
            </label>
          </div>
        )}
      </div>
      <ConfirmApprovalModal
        show={showConfirmPopup}
        onHide={closeConfirmPopup}
        confirmPreApprovalStatus={confirmPreApprovalStatus}
        handleAdjustBudgetClick={handleAdjustBudgetClick}
      />
    </div>
  );
};
export default WantAdPurchaseFinance;
