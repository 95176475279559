import React, { useState, useEffect } from "react";
import 'react-swipeable-list/dist/styles.css';

import MenuBottomBar from "../menuBottomMobile";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";
import MessageTopDropdown from "./MessageTopDropdown";

const NoMessagesMob = (props) => {
    const {scrollDirection, noScrollBounce} = props
    const navigate = useNavigate();
    return (
        <>
            <div className="gitcha-main-wrapper pb-102">
                <div className="content-wrapper content-wrapper-h-102">
                    <div className="inner-layout-header-new bg-white ">
                        <div className="top-three-comp-header w-100 flex-column top-three-comp-headerborder">
                            <div className="top-three-comp-header-inner w-100">
                                <div className="back-link-btn"> &nbsp; </div>
                                <div className="heading">Messages</div>
                                <div class="p-0 toplinks-mobile">
                                    <a className=" social-btns-wrap" href={void (0)}>
                                        <MessageTopDropdown filterList={props.filterList} handleFilter={props.handleFilter} />
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="h-100 inner-layout-content-new position-relative ">
                        <div className="no-message-wrap pl-16 pr-16">
                            <div className=" text-center">
                                <p>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="All - Messages">
                                            <path id="Vector" d="M20 7.5H17V4.5C17 4.10218 16.842 3.72064 16.5607 3.43934C16.2794 3.15804 15.8978 3 15.5 3H3.5C3.10218 3 2.72064 3.15804 2.43934 3.43934C2.15804 3.72064 2 4.10218 2 4.5V16.5C2.00044 16.6411 2.04068 16.7792 2.11608 16.8985C2.19149 17.0177 2.29901 17.1133 2.42629 17.1742C2.55358 17.2351 2.69546 17.2589 2.83564 17.2428C2.97581 17.2266 3.1086 17.1713 3.21875 17.0831L6.5 14.4375V17.25C6.5 17.6478 6.65804 18.0294 6.93934 18.3107C7.22064 18.592 7.60218 18.75 8 18.75H16.7741L20.2812 21.5831C20.414 21.6905 20.5793 21.7493 20.75 21.75C20.9489 21.75 21.1397 21.671 21.2803 21.5303C21.421 21.3897 21.5 21.1989 21.5 21V9C21.5 8.60218 21.342 8.22064 21.0607 7.93934C20.7794 7.65804 20.3978 7.5 20 7.5ZM5.98906 12.9169L3.5 14.9297V4.5H15.5V12.75H6.46063C6.28897 12.75 6.12252 12.8089 5.98906 12.9169ZM20 19.4297L17.5109 17.4169C17.3782 17.3095 17.2129 17.2507 17.0422 17.25H8V14.25H15.5C15.8978 14.25 16.2794 14.092 16.5607 13.8107C16.842 13.5294 17 13.1478 17 12.75V9H20V19.4297Z" fill="#636569" />
                                        </g>
                                    </svg>
                                </p>
                                <h3> No messages in your inbox</h3>
                                <p>Start a message thread by either sending or responding to an introduction on a want-ad</p>
                                <button type="button" className="btn btn-primary w-50 mb-4" onClick={() => { navigate(pageURLs.mobileHome) }} >Explore Want-Ads  &nbsp;
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.34375 2.6875C4.04832 2.6875 2.1875 4.54832 2.1875 6.84375C2.1875 9.13918 4.04832 11 6.34375 11C8.63918 11 10.5 9.13918 10.5 6.84375C10.5 4.54832 8.63918 2.6875 6.34375 2.6875ZM1.3125 6.84375C1.3125 4.06507 3.56507 1.8125 6.34375 1.8125C9.12243 1.8125 11.375 4.06507 11.375 6.84375C11.375 9.62243 9.12243 11.875 6.34375 11.875C3.56507 11.875 1.3125 9.62243 1.3125 6.84375Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28282 9.78282C9.45368 9.61197 9.73069 9.61197 9.90154 9.78282L12.5594 12.4406C12.7302 12.6115 12.7302 12.8885 12.5594 13.0594C12.3885 13.2302 12.1115 13.2302 11.9406 13.0594L9.28282 10.4015C9.11197 10.2307 9.11197 9.95368 9.28282 9.78282Z" fill="white" />
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="inner-layout-footer-new inner-layout-footer-topfix" >
                    <div className="bottomMenu bottom-menu-index">
                        <MenuBottomBar scrollDirection = {scrollDirection} noScrollBounce = {noScrollBounce} />
                    </div>
                </div>
            </div>



        </>
    );
};

export default NoMessagesMob;
