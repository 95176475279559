import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";

import TextArea from "../../Common/TextArea";
import { handleTextBoxChange } from "../../../actions/common";
import { checkIsNull, trimString } from "../../../helpers/common";
import { textValidations } from "../../../helpers/validations";
import { ERROR_TEXT } from "../../../constants/common";

const InventoryPropertyDetails = (props) => {
  const { handleFormValues, invtryDetails, descriptionError } = props;
  const [detailCharCount, setDetailCharCount] = useState(0);
  const [descError, setDescError] = useState('');

  useEffect(() => {
    setDetailCharCount(invtryDetails && invtryDetails.length ? invtryDetails.length : 0);
    // if (trimString(invtryDetails) !== "") {
    //   // handleContinueButtonState();
    // } else {
    //   const isDisabled = true
    //   // handleContinueButtonState(isDisabled)
    // }
    // if(invtryDetails){
    //  if (trimString(invtryDetails) !== "" && textValidations(invtryDetails)) {
    //   setDescError('')
    //     handleFormValues({ invalidInputExist: false });
    //   } else {
    //     setDescError(ERROR_TEXT)
    //     handleFormValues({ invalidInputExist: true });
    //   }
    // } else {
    //   setDescError('')
    //   handleFormValues({ invalidInputExist: false });
    // }

  }, [invtryDetails, descError]);

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDetailsChange = (e) => {
    const value = capitalizeFirstString(handleTextBoxChange(e));
    if (!checkIsNull(value)) {
      handleFormValues({ invtryDetails: value });
      setDetailCharCount(value.length);
    } else {
      handleFormValues({ invtryDetails: "" });
    }
  };
  return (
    <>
      <div className="wantad-des-wrap">
        <div className="position-relative">
          <FormLabel className={descriptionError && "red-marked-label"}>Additional details</FormLabel>

          <TextArea
            className={descriptionError? "form-control txt-area red-marked-formcontrol" : "form-control txt-area"}
            errorMessage ={descriptionError ? ERROR_TEXT : null}
            maxLength={2500}
            value={invtryDetails ? invtryDetails : ''}
            placeholder="Tell us more about this property…"
            onChange={(e) => {
              handleDetailsChange(e);
            }}
          />

          <div className={descriptionError ? "des-count red-marked-des-count mb-4" : "des-count  mb-4"}>
            <span>{detailCharCount}</span>/<span>2500</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryPropertyDetails;
