import React, { useState, useEffect } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import ValidationAlertIcon from "../images/validation-alert-icon.svg";

const EditClientoffcanvas = (props) => {
  const {show, onHide, clientDetails} = props;
  return (

    <Offcanvas
     className="mobile-offcanvas offcanvas-fixed edit-client-offcanvas"
     show={show}
      onHide={onHide}
      placement='bottom'
      {...props}>
        <Offcanvas.Header>
            <Offcanvas.Title>Edit Client (Only visible to you)</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="offcanvas-edit-wrap">
            <div className="edit-info">
                <div className="overflowXhide" >
                    <div className="field-group field-spacer">
                        <div className="mob-fld-wrap position-relative">
                            <label className="form-label">First name</label>
                            <input type="text" value={clientDetails?.FirstName} className="form-control mob-pr-44 validation-alert-brdr" name="email-fld" placeholder="First Name" />
                            <div className="validation-alert"><img src={ValidationAlertIcon} /></div>
                            <div className="mob-error-msg">Invalid</div>
                        </div>
                        <div className="mob-fld-wrap position-relative">
                            <label className="form-label">Last name</label>
                            <input type="text" value={clientDetails?.LastName} className="form-control mob-pr-44 validation-alert-brdr" name="email-fld" placeholder="Last name" />
                            <div className="validation-alert"><img src={ValidationAlertIcon} /></div>
                            <div className="mob-error-msg">Invalid </div>
                        </div>
                    </div>
                    <div className="mob-fld-wrap position-relative field-spacer">
                        <label className="form-label">Client’s Email</label>
                        <input type="email" value={clientDetails?.Email} className="form-control mob-pr-44 validation-alert-brdr" name="email-fld" placeholder="Email" />
                        <div className="validation-alert"><img src={ValidationAlertIcon} /></div>
                        <div className="mob-error-msg">Invalid email address</div>
                    </div>
                    <div className="mob-fld-wrap position-relative field-spacer">
                        <label className="form-label">Client’s Bio</label>
                        <textarea value={clientDetails?.Add_Info} class="form-control txtAreaStyle" rows="3" placeholder="Enter a brief description about your client" ></textarea>
                        <div className="mob-error-msg">Invalid</div>
                    </div>
                </div>
            </div>
            <div className="inner-btm-fixed-nav">
                    <div className="btn-wrap">
                        <button type="button" className="btn btn-primary w-100" disabled>Save Changes</button>
                    </div>
                </div>
        </div>
        </Offcanvas.Body>
  </Offcanvas>



    
  );
};
export default EditClientoffcanvas;
