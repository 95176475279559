import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import {
  PROPERTY_AD,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
  WANT_AD,
} from "../../../constants/common";
import { getLocalStorage } from "../../../helpers/common";

import { pageURLs } from "../../../constants/pageURLs";

const UserPreference = (props) => {
  const { show, onHide, handleUserPreferenceContinue } = props;
  const navigate = useNavigate()
  const userDetails = getLocalStorage(USER_INFO_KEY);
  const userType = _.get(userDetails, "userInfo.userType", null);
  const [preference, setPreference] = useState();

  const handlePreferenceSelection = (selectedPreference) => {
    setPreference(selectedPreference);
  };
  return (
    <Offcanvas
      className="mobile-offcanvas offcanvas-filter-share-fix"
      show={show}
      onHide={onHide}
      placement="bottom"
      {...props}
    >
      <Offcanvas.Header className="d-none" closeButton>
        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <h2 className="mt-2 mb-4">
          Let’s get whatcha want! What would you like to do first?
        </h2>

        <div
          className={preference === WANT_AD? "offcanvas-content-wrap mb-3 active": "offcanvas-content-wrap mb-3"}
          
          onClick={() => handlePreferenceSelection(WANT_AD)}
        >
          <h4>Create a want-ad</h4>
          {userType === USER_TYPE_AGENT ? (
            <p>
              You have a client seeking property to purchase, rent long-term or
              stay-in for vacation. Publicly list their wants & needs and let
              those with the right property come to you.
            </p>
          ) : (
            <p>
              You’re looking to buy, rent, or stay in a property. You want to
              share what you’re looking for to receive introductions from people
              with matching property.
            </p>
          )}
        </div>
        <div  className={preference === PROPERTY_AD? "offcanvas-content-wrap mb-3 active": "offcanvas-content-wrap mb-3"}
          
          onClick={() => handlePreferenceSelection(PROPERTY_AD)}>
          <h4>
            Add{userType !== USER_TYPE_AGENT && " your"} property to private inventory
          </h4>
          {userType === USER_TYPE_AGENT ? (
            <p>
              You have a client with property to sell or rent-out. We will match
              your property to want-ads, and you can privately introduce your
              property to buyers and renters.
            </p>
          ) : (
            <p>
              You own a property you’re considering selling or renting-out (long
              or short-term). You want to gauge demand or send a preview of your
              property to matching want-ads.
            </p>
          )}
        </div>
        <div className="btn-wrap text-center pb-4">
          <button type="button" class="btn btn-primary w-100 mb-4" disabled = {preference? false: true}
          onClick={()=> handleUserPreferenceContinue(preference)}>
            Start
          </button>

          <a href={void 0} role="button" class="blue-text-link" onClick={()=> handleUserPreferenceContinue()}>
          Let me look around first
          </a>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default UserPreference;
