import AddProperty from "./AddProperty";
import noImage from "../../../images/no-image-placeholder.png";
import moment from "moment";

const MemberPropertiesData = (props) => {
    const { propertyList, setShowSelectProperty, selectedProperty, userDetails } = props;

    return (
        propertyList.length == 0 ? (
            <AddProperty userDetails={userDetails} />) :
            (
                <a
                    role="button"
                    onClick={() => setShowSelectProperty(true)}
                >
                    <div className="add-your-property-mob">
                        <div className="add-property-img-mob">
                            <img src={selectedProperty?.photo ? selectedProperty?.photo : noImage} />
                        </div>
                        <div className="add-property-details-mob ">
                            <div className="title d-flex align-items-center">
                                <h3>{selectedProperty?.Location}</h3>

                            </div>
                            {(selectedProperty?.IsIntroSent == 1 &&
                                selectedProperty?.IsCancelled == 0
                                && selectedProperty?.IsDecline != 1 &&
                                selectedProperty?.NotInterested != 1
                                && selectedProperty?.Not_A_Fit != 1) ?
                                <div className="modal-wrap-label-grey">SENT {moment(
                                    new Date(selectedProperty?.IntroSentDate)
                                ).format("MMM Do, YYYY")}</div>

                                :
                                selectedProperty?.IsDecline == 1 ?
                                    <div className="modal-wrap-label-orange">DECLINED {moment(
                                        new Date(selectedProperty?.IntroDeclineDate)
                                    ).format("MMM Do, YYYY")}</div>

                                    :

                                    selectedProperty?.NotInterested == 1 ?
                                        <div className="modal-wrap-label-orange">NOT INTERESTED {moment(
                                            new Date(selectedProperty?.IntroDeclineDate)
                                        ).format("MMM Do, YYYY")}</div>

                                        :

                                        selectedProperty?.Not_A_Fit == 1 ?
                                            <div className="modal-wrap-label-orange">NOT A FIT {moment(
                                                new Date(selectedProperty?.IntroDeclineDate)
                                            ).format("MMM Do, YYYY")}</div>

                                            :

                                            !selectedProperty?.photo &&
                                            <div className="modal-wrap-label-blue">MUST ADD A PHOTO TO INTRODUCE</div>

                            }
                        </div>
                        <span className="right-arrow-mob">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 3.96967C8.76256 3.67678 9.23744 3.67678 9.53033 3.96967L17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L9.53033 20.0303C9.23744 20.3232 8.76256 20.3232 8.46967 20.0303C8.17678 19.7374 8.17678 19.2626 8.46967 18.9697L15.4393 12L8.46967 5.03033C8.17678 4.73744 8.17678 4.26256 8.46967 3.96967Z" fill="#1B1C1E" />
                            </svg>
                        </span>

                    </div>

                </a>
            )

    )
}

export default MemberPropertiesData
