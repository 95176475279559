import React from "react";
import NiceToHaveHelper from "./NiceToHaveHelper";

const Exterior = (props) => {
    const {exterior, handleItemClick, itemArray} = props
    return  <NiceToHaveHelper
       list={exterior}
       selectedItems={itemArray}
       propertyClick={handleItemClick}
    />
};
export default Exterior;
