import { combineReducers } from 'redux'
import wantAdReducer from './wantAds'
import loginReducer from './login'
import userProfileReducer from './userprofile'
import paymentMethodsReducer from './payments'
import inventoryReducer from './inventory'
import agentClientsReducer from './agentClients'
import messagesReducer from './messages'
import unReadStatusSliceReducer from './unReadStatus'
import sseDataSliceReducer from './sseData'
import locationsReducer from './locations'


const appReducer = combineReducers({
    wantAds: wantAdReducer,
    userInfo :loginReducer,
    userProfile : userProfileReducer,
    inventory: inventoryReducer,
    paymentMethods : paymentMethodsReducer,
    agentClients: agentClientsReducer,
    messages: messagesReducer,
    unreadCount: unReadStatusSliceReducer,
    sseData: sseDataSliceReducer,
    locations: locationsReducer
})

export default appReducer