import React, { useRef } from "react";
import {
    GoogleMap,
    Marker,
    useJsApiLoader,
    Circle,
} from "@react-google-maps/api";
import mapPinIcon from "../../images/map-icon.png";
import {
    MAP_TYPE_ROADMAP,
} from "../../constants/common";
import {
    getMeters,
} from "../../helpers/common";

const GoogleMaps = (props) => {

    const {
        defaultProps,
        mapHeight,
        mapWidth,
    } = props;

    const googleMapApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const mapRef = useRef(null);
    const mapRadius = 1;
    const radius = mapRadius && getMeters(mapRadius); // 10 kilometers

    const { isLoaded } = useJsApiLoader({
        // for checking map script is loaded in the UI.
        id: "google-map-script",
        googleMapsApiKey: googleMapApiKey,
    });

    const circleOptions = {
        strokeColor: "#338BA8",
        strokeOpacity: 0,
        strokeWeight: 1,
        fillColor: "#338BA8",
        fillOpacity: 0.35,
    };

    const containerStyle = {
        width: mapWidth,
        height: mapHeight,
    };

    return (

        isLoaded &&
        _.get(defaultProps.center, "lat", "") && (

            <GoogleMap
                mapTypeId={MAP_TYPE_ROADMAP}
                mapContainerStyle={containerStyle}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                mapContainerClassName="wantad-map-wrap overflow-hidden"
            >
                {/* <Marker
                    position={defaultProps.center}
                    draggable={true}
                    fillColor="#000000"
                    icon={{
                        url: mapPinIcon,
                    }}
                /> */}
                <Circle
                    center={defaultProps.center}
                    radius={radius}
                    options={circleOptions}

                />

            </GoogleMap>

        )
    );
};
export default GoogleMaps;
