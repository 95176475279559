import React from "react";
import FileUploadIcon from "../../../images/file-upload-icon.svg";
import Dropzone from "react-dropzone";

const DropZoneFileUploadMob = (props) => {
  const { uploadFile, uploadIconExist, uploadTitle, uploadDesc, imagesAdded, isAgent } = props;
  return (
    <Dropzone onDrop={(uploadedFiles) => uploadFile(uploadedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <section className="">
          <div className="file-upload-container-mob" {...getRootProps()}>
            <input {...getInputProps()} />
            {uploadIconExist && (
              <div className="upload-img-wrap-mob">
                <img src={FileUploadIcon} alt={FileUploadIcon} />
              </div>
            )}
            {uploadTitle && <h6>{uploadTitle}</h6>}
            {/*{uploadDesc && <p>{uploadDesc}</p>}*/}

            {!imagesAdded ? <button type="button" className="btn btn-primary">
              Upload Photos
              {!isAgent ? <span>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.5 2.6875C4.84213 2.6875 2.6875 4.84213 2.6875 7.5C2.6875 10.1579 4.84213 12.3125 7.5 12.3125C10.1579 12.3125 12.3125 10.1579 12.3125 7.5C12.3125 4.84213 10.1579 2.6875 7.5 2.6875ZM1.8125 7.5C1.8125 4.35888 4.35888 1.8125 7.5 1.8125C10.6411 1.8125 13.1875 4.35888 13.1875 7.5C13.1875 10.6411 10.6411 13.1875 7.5 13.1875C4.35888 13.1875 1.8125 10.6411 1.8125 7.5Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.875 7.5C4.875 7.25838 5.07088 7.0625 5.3125 7.0625H9.6875C9.92912 7.0625 10.125 7.25838 10.125 7.5C10.125 7.74162 9.92912 7.9375 9.6875 7.9375H5.3125C5.07088 7.9375 4.875 7.74162 4.875 7.5Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.5 4.875C7.74162 4.875 7.9375 5.07088 7.9375 5.3125V9.6875C7.9375 9.92912 7.74162 10.125 7.5 10.125C7.25838 10.125 7.0625 9.92912 7.0625 9.6875V5.3125C7.0625 5.07088 7.25838 4.875 7.5 4.875Z"
                    fill="white"
                  />
                </svg>
              </span> : <></>}
            </button> : <a href={void 0} className="add-photos-link">Add Photos</a>}
          </div>
        </section>
      )}
    </Dropzone>
  );
};
export default DropZoneFileUploadMob;
