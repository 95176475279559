import React, { useState, createContext, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LeftpanelTabContent from "./LeftpanelTabContent";
import LeftpanelTabContentRight from "./LeftpanelTabContentRight";
import { BUY } from "../../../constants/common";
import { getLocalStorage, getUserId, removeLocalStorage, setLocalStorage } from "../../../helpers/common";
import { useLocation, useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";

export const filterContext = createContext();
const LeftpanelTab = (props) => {
  const {
    updateFilter,
    filterQuery,
    handleGitchGenieFilter,
    clearFilterGitchaGenie,
    updateFilterGitchaGenie,
    selectedProperty,
    setSelectedProperty,
    setUserLocation,
    gitchaNotAvailble,
    searchedLocation,
    userLocation,
    checkGitchaAvailableInLoc
  } = props;
  const [key, setKey] = useState(null);
  const location = useLocation();
  const path = location.pathname;
  const navigate= useNavigate();
  useEffect(()=>{    
    if(path==="/match"){
      setKey("match")
      setLocalStorage("FilterTabKey", "match");      
      removeLocalStorage("selectedPropertyId");
      removeLocalStorage("MatchSelectedClient");
    }
    else if(getLocalStorage("FilterTabKey")){
        setKey(getLocalStorage("FilterTabKey"))
      }
    else{
      setKey("General")
    }
  },[path])

  useEffect(() => {
    if (key === "match") {
      handleGitchGenieFilter(true);
    } else { 
      handleGitchGenieFilter(false);
    }
  }, [key]);

  return (
    <>
   
    <Tabs
      className="mb-3"
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => {
        if(path==="/match" && k==="General"){
          navigate(pageURLs?.home)
        }
        setKey(k); 
        setLocalStorage("FilterTabKey", k)
        }
      }
    >
     
      <Tab eventKey="General" title="General">
        <LeftpanelTabContent
          updateFilter={updateFilter}
          filterQuery={filterQuery}
          setUserLocation={setUserLocation}
          userLocation={userLocation}
          gitchaNotAvailble={gitchaNotAvailble}
          searchedLocation={searchedLocation}
          key={key}
          checkGitchaAvailableInLoc={checkGitchaAvailableInLoc}
        />
      </Tab>
      <Tab eventKey="match" title="Match">
        <LeftpanelTabContentRight
          clearFilterGitchaGenie={clearFilterGitchaGenie}
          updateFilterGitchaGenie={updateFilterGitchaGenie}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Tab>
    </Tabs>
    </>
  );
};
export default LeftpanelTab;
