import React from "react";
import {useSelector} from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { getCardIcon, getUserId } from "../../helpers/common";
import {removePaymentMethod} from '../../services/paymentsService'

const RemoveCardModal = (props) => {
  const {currentPaymentMethod, updateReloadParam} = props;
  const userDetails = useSelector((state) => state.userProfile.userDetails);
  const userId = getUserId();
  const handleRemoveCard = async () => {
    const payload = {
      PaymentMethodId : currentPaymentMethod?.Payment_Mode_Id
    }
    await removePaymentMethod(payload)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.dismiss()
          toast.success("Payment method removed")
          props.onHide()
            setTimeout(()=>{
              updateReloadParam()
            }, 500)
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings modal-card-remove"
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={props.onHide}
        ></button>
        <div className="card-logo"><img src={getCardIcon(currentPaymentMethod?.Brand)}/></div>
        <h3 className="text-start">Are you sure you want to remove your {currentPaymentMethod?.Brand} card ending in {currentPaymentMethod?.Last4}?</h3>
        <p>Once deleted, this action cannot be undone.</p>
        <div className="modal-btn-wrap">
            <Button className="btn btn-tertiary me-3" onClick={props.onHide} >Cancel</Button>
            <Button variant="primary" onClick={()=>handleRemoveCard()} >Delete</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RemoveCardModal;
