import {
  LONG_RENT,
  SELL,
  SHORT_RENT,
  INVENTORY_SELL,
  INVENTORY_STR,
  INVENTORY_SELL_LTR,
  INVENTORY_ALL_TYPE,
  INVENTORY_SELL_STR,
  INVENTORY_LTR_STR,
  INVENTORY_LTR,
  INVENTORY_LAND,
  WATER_SUPPLY,
  WASTE_WATER_SYSTEM,
  LOT_TYPES,
  ROAD_TYPES,
} from "../constants/common";
import {
  convertToCurrencySystem,
  filterArray,
  removeDecimalZeros,
} from "./common";
import LakeViewIcon from "../images/lake-view-icon.svg";
import InventoryLocationMap from "../images/inventory-location-map.png";
import SchoolSmallIcon from "../images/school-icon.svg";
import NeighborhoodIcon from "../images/neighborhood-icon.svg";
import inValidImage from "../images/invalid-image-placeholder.png";

export const checkInventoryType = (propertyPurpose) => {
  let purchaseType;
  if (propertyPurpose && propertyPurpose.length > 0) {
    if (propertyPurpose.length === 1) {
      if (_.includes(propertyPurpose, SELL)) {
        purchaseType = INVENTORY_SELL;
      } else if (_.includes(propertyPurpose, LONG_RENT)) {
        purchaseType = INVENTORY_LTR;
      } else if (_.includes(propertyPurpose, SHORT_RENT)) {
        purchaseType = INVENTORY_STR;
      }
    } else if (propertyPurpose.length === 2) {
      if (
        _.includes(propertyPurpose, LONG_RENT) &&
        _.includes(propertyPurpose, SHORT_RENT)
      ) {
        purchaseType = INVENTORY_LTR_STR;
      } else if (
        _.includes(propertyPurpose, LONG_RENT) &&
        _.includes(propertyPurpose, SELL)
      ) {
        purchaseType = INVENTORY_SELL_LTR;
      } else if (
        _.includes(propertyPurpose, SHORT_RENT) &&
        _.includes(propertyPurpose, SELL)
      ) {
        purchaseType = INVENTORY_SELL_STR;
      }
    } else if (propertyPurpose.length === 3) {
      purchaseType = INVENTORY_ALL_TYPE;
    }
    return purchaseType;
  } else {
    return "";
  }
};

const loadRentingPricesHTML = ({ LTRAskngPrce, STRAskngPrce }) => {
  let rentingPrice;
  if (LTRAskngPrce && STRAskngPrce) {
    return (
      <li>
        <span className="musthave-label">Renting Price</span>
        <span className="musthave-value">
          {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}
        </span>
        <span className="musthave-value">
          {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}
        </span>
      </li>
    );
  } else if (LTRAskngPrce && !STRAskngPrce) {
    return (
      <li>
        <span className="musthave-label">Renting Price</span>
        <span className="musthave-value">
          {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}
        </span>
      </li>
    );
  } else if (STRAskngPrce && !LTRAskngPrce) {
    return (
      <li>
        <span className="musthave-label">Renting Price</span>
        <span className="musthave-value">
          {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}
        </span>
      </li>
    );
  } else {
    return "";
  }
};

const loadRentingPricesHTMLMob = ({ LTRAskngPrce, STRAskngPrce }) => {
  let rentingPrice;
  if (LTRAskngPrce && STRAskngPrce) {
    return (
      <div className="col-6 pe-2">
        <div className="musthave-content-mobwrap mb-3">
          <div className="musthave-content-label">Renting Price</div>
          <div className="musthave-content-text">
            {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}
          </div>
          <div className="musthave-content-text">
            {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}
          </div>
        </div>
      </div>
    );
  } else if (LTRAskngPrce && !STRAskngPrce) {
    return (
      <div className="col-6 pe-2">
        <div className="musthave-content-mobwrap mb-3">
          <div className="musthave-content-label">Renting Price</div>
          <div className="musthave-content-text">
            {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}
          </div>
        </div>
      </div>
    );
  } else if (STRAskngPrce && !LTRAskngPrce) {
    return (
      <div className="col-6 pe-2">
        <div className="musthave-content-mobwrap mb-3">
          <div className="musthave-content-label">Renting Price</div>
          <div className="musthave-content-text">
            {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export const buildInventoryMustHaveHtml = ({
  typeBasedOnProperty,
  formValues,
  isFromIntroduction
}) => {
  const askingPrice = _.get(formValues, "AskingPrice", "");
  const sellAskingPrice = _.get(formValues, "sellAskngPrice", "");
  const LTRAskngPrce = _.get(formValues, "LTRAskngPrce", "");
  const STRAskngPrce = _.get(formValues, "STRAskngPrce", "");
  let noBedRm = removeDecimalZeros(_.get(formValues, "noBedRm", "") || _.get(formValues, "Bedrooms", "")); // Multiply by 1 for remove unwanted zeros after decimal .
  const noOfBeds = removeDecimalZeros(_.get(formValues, "noOfBeds", ""));
  let noBathRm = removeDecimalZeros(_.get(formValues, "noBathRm", "") || _.get(formValues, "Bathrooms", ""));
  let sqFt = removeDecimalZeros(_.get(formValues, "sqFt", "") || _.get(formValues, "Sqft", ""));
  let lotSize = removeDecimalZeros(_.get(formValues, "lotSize", "") || _.get(formValues, "LotSz", ""));
  const stories =
    (_.get(formValues, "stories", "") || _.get(formValues, "Stories", "")) === 0 ||
      (_.get(formValues, "stories", "") || _.get(formValues, "Stories", "")) === "0"
      ? null
      : (_.get(formValues, "stories", "") || _.get(formValues, "Stories", ""));
  const grgeSpots =
    (_.get(formValues, "grgeSpots", "") || _.get(formValues, "GarageSpots", "")) === 0 ||
      (_.get(formValues, "grgeSpots", "") || _.get(formValues, "GarageSpots", "")) === "0"
      ? null
      : (_.get(formValues, "grgeSpots", "") || _.get(formValues, "GarageSpots", ""));

  if (noBedRm === 0 || noBedRm == "0") {
    noBedRm = "";
  }
  if (noBathRm === 0 || noBathRm == "0") {
    noBathRm = "";
  }
  if (sqFt === 0 || sqFt == "0") {
    sqFt = "";
  }

  if (lotSize === 0 || lotSize == "0") {
    lotSize = "";
  }

  if (typeBasedOnProperty === INVENTORY_LAND) {
    let acreage = removeDecimalZeros(_.get(formValues, "acreage", "") || _.get(formValues, "TotalAcre", ""));
    if (acreage === 0 || acreage == "0") {
      acreage = "";
    }
    let lotSizeUnit = _.get(formValues, "acre_Sqft", "") || _.get(formValues, "Acre_Sqft", "");

    if (lotSizeUnit && lotSizeUnit !== 0) {
      lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft";
    }
    const lotType = _.get(formValues, "lotType", "") || _.get(formValues, "LotType_Rural_City", "");
    const roadType = _.get(formValues, "roadType", "") || _.get(formValues, "RoadType_City_Ptivate", "");
    let isPowerAccess = _.get(formValues, "isPowerAccess", "") || _.get(formValues, "Electricity_Yes_No", "");
    const waterSuply = _.get(formValues, "waterSuply", "") || _.get(formValues, "Water_Domestic_DrilledWell", "");
    const wasteWtrSys = _.get(formValues, "wasteWtrSys", "") || _.get(formValues, "Waste_Sewer_Septic", "");
    let isApprovedStrctr = _.get(formValues, "isApprovedStrctr", "") || _.get(formValues, "Property_Approved_Structure", "");
    let isInFloodPlain = _.get(formValues, "isInFloodPlain", "") || _.get(formValues, "Floor_Plan", "");
    const privateDrillInstalled = _.get(formValues, "isPrvtDrInst", null) || _.get(formValues, "RoadType_Private_Installed", null);
    const waterSuplyDrldWellInstall = _.get(formValues, "isDrldWelInst", null) || _.get(formValues, "Water_DrilledWell_Installed", null);
    const waterSuplyDrldWellApprove = _.get(formValues, "isDrldWelAppr", null) || _.get(formValues, "Water_DrilledWell_Approved", null);
    const wasteSysSepTankInst = _.get(formValues, "isSepTankInst", null) || _.get(formValues, "Waste_Septic_Installed", null);
    const wasteSysSepTankAppr = _.get(formValues, "isSepTankAppr", null) || _.get(formValues, "Waste_Septic_Approved", null);

    if (isPowerAccess || isPowerAccess === 0) {
      if (isPowerAccess === "true" || isPowerAccess === 1) {
        isPowerAccess = "Yes";
      } else {
        isPowerAccess = "No";
      }
    }
    if (isApprovedStrctr || isApprovedStrctr === 0) {
      if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
        isApprovedStrctr = "Yes";
      } else {
        isApprovedStrctr = "No";
      }
    }
    if (isInFloodPlain || isInFloodPlain === 0) {
      if (isInFloodPlain === "true" || isInFloodPlain === 1) {
        isInFloodPlain = "Yes";
      } else {
        isInFloodPlain = "No";
      }
    }
    return (
      <>
        <ul>
          {isFromIntroduction ?
            (
              <li>
                <span className="musthave-label">Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(askingPrice)}
                </span>
              </li>
            ) :
            (
              <>
                {sellAskingPrice && (
                  <li>
                    <span className="musthave-label">Selling Price</span>
                    <span className="musthave-value">
                      ${convertToCurrencySystem(sellAskingPrice)}
                    </span>
                  </li>
                )}
                {loadRentingPricesHTML({
                  LTRAskngPrce: LTRAskngPrce,
                  STRAskngPrce: STRAskngPrce,
                })
                }

              </>
            )
          }         

          {acreage ? (
            <li>
              <span className="musthave-label">Acreage</span>
              <span className="musthave-value">{acreage + lotSizeUnit}</span>
            </li>
          ) : <></>}

          {lotType ? (
            <li>
              <span className="musthave-label">Lot Type</span>
              <span className="musthave-value">
                {filterArray(LOT_TYPES, "value", lotType)}
              </span>
            </li>
          ) : <></>}

          {roadType ? (
            <li>
              <span className="musthave-label">Road Type</span>
              <span className="musthave-value">
                {filterArray(ROAD_TYPES, "value", roadType)}
                {privateDrillInstalled && ", " + "Installed"}
              </span>
            </li>
          ) : <></>}
          {isPowerAccess ? (
            <li>
              <span className="musthave-label">Power in Property</span>
              <span className="musthave-value">{isPowerAccess}</span>
            </li>
          ) : <></>}

          {waterSuply ? (
            <li>
              <span className="musthave-label">Water Supply</span>
              <span className="musthave-value">
                {" "}
                {filterArray(WATER_SUPPLY, "value", waterSuply)}
                {waterSuplyDrldWellInstall && ", " + "Installed"}
                {waterSuplyDrldWellApprove && ", " + "Approved"}
              </span>
            </li>
          ) : <></>}
          {wasteWtrSys ? (
            <li>
              <span className="musthave-label">Waste Water System</span>
              <span className="musthave-value">
                {" "}
                {filterArray(WASTE_WATER_SYSTEM, "value", wasteWtrSys)}
                {wasteSysSepTankInst && ", " + "Installed"}
                {wasteSysSepTankAppr && ", " + "Approved"}
              </span>
            </li>
          ) : <></>}

          {isApprovedStrctr ? (
            <li>
              <span className="musthave-label">Approved for structure</span>
              <span className="musthave-value"> {isApprovedStrctr}</span>
            </li>
          ) : <></>}
          {isInFloodPlain ? (
            <li>
              <span className="musthave-label">Flood plain</span>
              <span className="musthave-value"> {isInFloodPlain}</span>
            </li>
          ) : <></>}
        </ul>
      </>
    );
  } else {
    let adaAccess = _.get(formValues, "adaAccess", null) || _.get(formValues, "ADA_Accessible", null);
    if (adaAccess === 0) {
      adaAccess = null;
    } else {
      if (adaAccess) {
        if (adaAccess === true || adaAccess === 1 || adaAccess === "true") {
          adaAccess = "Yes";
        }
        // } else {
        //   adaAccess = "No";
        // }
      }
    }

    let newConstruction = _.get(formValues, "IsNewConstruction", null);
    if (newConstruction === 0) {
      newConstruction = null;
    } else {
      if (newConstruction) {
        if (
          newConstruction === true ||
          newConstruction === 1 ||
          newConstruction === "true"
        ) {
          newConstruction = "Yes";
        }
        // } else {
        //   adaAccess = "No";
        // }
      }
    }
    const yrBuilt = _.get(formValues, "yearBuilt", null) || _.get(formValues, "YearBuilt", null);

    return (
      <>
        <ul>
          {isFromIntroduction ?
            (
              <li>
                <span className="musthave-label">Price</span>
                <span className="musthave-value">
                  ${convertToCurrencySystem(askingPrice)}
                </span>
              </li>
            ) : (
              <>
                {sellAskingPrice && (
                  <li>
                    <span className="musthave-label">Selling Price</span>
                    <span className="musthave-value">
                      ${convertToCurrencySystem(sellAskingPrice)}
                    </span>
                  </li>
                )}
                {loadRentingPricesHTML({
                  LTRAskngPrce: LTRAskngPrce,
                  STRAskngPrce: STRAskngPrce,
                })}
              </>
            )
          }

          

          {noBedRm ? (
            <li>
              <span className="musthave-label">Beds</span>
              <span className="musthave-value">{noBedRm}</span>
            </li>
          ) : <></>}

          
          {noBathRm ? (
            <li>
              <span className="musthave-label">Baths</span>
              <span className="musthave-value">{noBathRm}</span>
            </li>
          ) : <></>}

          {sqFt ? (
            <li>
              <span className="musthave-label">Sqft</span>
              <span className="musthave-value">{sqFt}</span>
            </li>
          ) : <></>}

          {stories ? (
            <li>
              <span className="musthave-label">Stories</span>
              <span className="musthave-value">{stories}</span>
            </li>
          ) : <></>}

          {grgeSpots ? (
            <li>
              <span className="musthave-label">Garage Spaces</span>
              <span className="musthave-value">{grgeSpots}</span>
            </li>
          ) : <></>}
          {yrBuilt ? (
            <li>
              <span className="musthave-label">Year built</span>
              <span className="musthave-value">{yrBuilt}</span>
            </li>
          ) : <></>}

          {lotSize ? (
            <li>
              <span className="musthave-label">Lot size</span>
              <span className="musthave-value">{lotSize + " Acres"}</span>
            </li>
          ) : <></>}

          {adaAccess ? (
            <li>
              <span className="musthave-label">ADA Accessible</span>
              <span className="musthave-value">{adaAccess}</span>
            </li>
          ) : <></>}
          {newConstruction ? (
            <li>
              <span className="musthave-label">New Construction</span>
              <span className="musthave-value">{newConstruction}</span>
            </li>
          ) : <></>}
        </ul>
      </>
    );
  }
};
export const buildInventoryMustHaveHtmlForMobile = ({
  typeBasedOnProperty,
  formValues,
}) => {
  const sellAskingPrice = _.get(formValues, "sellAskngPrice", "");
  const LTRAskngPrce = _.get(formValues, "LTRAskngPrce", "");
  const STRAskngPrce = _.get(formValues, "STRAskngPrce", "");
  let noBedRm = removeDecimalZeros(_.get(formValues, "noBedRm", "")); // Multiply by 1 for remove unwanted zeros after decimal .
  const noOfBeds = removeDecimalZeros(_.get(formValues, "noOfBeds", ""));
  let noBathRm = removeDecimalZeros(_.get(formValues, "noBathRm", ""));
  let sqFt = removeDecimalZeros(_.get(formValues, "sqFt", ""));
  let lotSize = removeDecimalZeros(_.get(formValues, "lotSize", ""));
  const stories =
    _.get(formValues, "stories", "") === 0 ||
      _.get(formValues, "stories", "") === "0"
      ? null
      : _.get(formValues, "stories", "");
  const grgeSpots =
    _.get(formValues, "grgeSpots", "") === 0 ||
      _.get(formValues, "grgeSpots", "") === "0"
      ? null
      : _.get(formValues, "grgeSpots", "");

  if (noBedRm === 0 || noBedRm == "0") {
    noBedRm = "";
  }
  if (noBathRm === 0 || noBathRm == "0") {
    noBathRm = "";
  }
  if (sqFt === 0 || sqFt == "0") {
    sqFt = "";
  }

  if (lotSize === 0 || lotSize == "0") {
    lotSize = "";
  }

  if (typeBasedOnProperty === INVENTORY_LAND) {
    let acreage = removeDecimalZeros(_.get(formValues, "acreage", ""));
    if (acreage === 0 || acreage == "0") {
      acreage = "";
    }
    let lotSizeUnit = _.get(formValues, "acre_Sqft", "");

    if (lotSizeUnit && lotSizeUnit !== 0) {
      lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft";
    }
    const lotType = _.get(formValues, "lotType", "");
    const roadType = _.get(formValues, "roadType", "");
    let isPowerAccess = _.get(formValues, "isPowerAccess", "");
    const waterSuply = _.get(formValues, "waterSuply", "");
    const wasteWtrSys = _.get(formValues, "wasteWtrSys", "");
    let isApprovedStrctr = _.get(formValues, "isApprovedStrctr", "");
    let isInFloodPlain = _.get(formValues, "isInFloodPlain", "");
    const privateDrillInstalled = _.get(formValues, "isPrvtDrInst", null);
    const waterSuplyDrldWellInstall = _.get(formValues, "isDrldWelInst", null);
    const waterSuplyDrldWellApprove = _.get(formValues, "isDrldWelAppr", null);
    const wasteSysSepTankInst = _.get(formValues, "isSepTankInst", null);
    const wasteSysSepTankAppr = _.get(formValues, "isSepTankAppr", null);

    if (isPowerAccess || isPowerAccess === 0) {
      if (isPowerAccess === "true" || isPowerAccess === 1) {
        isPowerAccess = "Yes";
      } else {
        isPowerAccess = "No";
      }
    }
    if (isApprovedStrctr || isApprovedStrctr === 0) {
      if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
        isApprovedStrctr = "Yes";
      } else {
        isApprovedStrctr = "No";
      }
    }
    if (isInFloodPlain || isInFloodPlain === 0) {
      if (isInFloodPlain === "true" || isInFloodPlain === 1) {
        isInFloodPlain = "Yes";
      } else {
        isInFloodPlain = "No";
      }
    }
    return (
      <>
        {sellAskingPrice ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Selling Price</div>
              <div className="musthave-content-text">
                ${convertToCurrencySystem(sellAskingPrice)}
              </div>
            </div>
          </div>
        ) : <></>}
        {loadRentingPricesHTMLMob({
          LTRAskngPrce: LTRAskngPrce,
          STRAskngPrce: STRAskngPrce,
        })}

        {acreage ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Acreage</div>
              <div className="musthave-content-text">{acreage + lotSizeUnit}</div>
            </div>
          </div>
        ) : <></>}

        {lotType ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot Type</div>
              <div className="musthave-content-text">
                {filterArray(LOT_TYPES, "value", lotType)}
              </div>
            </div>
          </div>
        ) : <></>}

        {roadType ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Road Type</div>
              <div className="musthave-content-text">
                {filterArray(ROAD_TYPES, "value", roadType)}
                {privateDrillInstalled && ", " + "Installed"}
              </div>
            </div>
          </div>
        ) : <></>}
        {isPowerAccess ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <span className="musthave-content-label">Power in Property</span>
              <span className="musthave-content-text">{isPowerAccess}</span>
            </div>
          </div>
        ) : <></>}

        {waterSuply ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-label">Water Supply</div>
              <div className="musthave-value">
                {" "}
                {filterArray(WATER_SUPPLY, "value", waterSuply)}
                {waterSuplyDrldWellInstall && ", " + "Installed"}
                {waterSuplyDrldWellApprove && ", " + "Approved"}
              </div>
            </div>
          </div>
        ) : <></>}
        {wasteWtrSys ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-label">Waste Water System</div>
              <div className="musthave-value">
                {" "}
                {filterArray(WASTE_WATER_SYSTEM, "value", wasteWtrSys)}
                {wasteSysSepTankInst && ", " + "Installed"}
                {wasteSysSepTankAppr && ", " + "Approved"}
              </div>
            </div>
          </div>
        ) : <></>}

        {isApprovedStrctr ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Approved for structure</div>
              <div className="musthave-content-text"> {isApprovedStrctr}</div>
            </div>
          </div>
        ) : <></>}
        {isInFloodPlain ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-label">Flood plain</div>
              <div className="musthave-value"> {isInFloodPlain}</div>
            </div>
          </div>
        ) : <></>}
      </>
    );
  } else {
    let adaAccess = _.get(formValues, "adaAccess", null);
    if (adaAccess === 0) {
      adaAccess = null;
    } else {
      if (adaAccess) {
        if (adaAccess === true || adaAccess === 1 || adaAccess === "true") {
          adaAccess = "Yes";
        }
      }
    }

    let newConstruction = _.get(formValues, "IsNewConstruction", null);
    if (newConstruction === 0) {
      newConstruction = null;
    } else {
      if (newConstruction) {
        if (
          newConstruction === true ||
          newConstruction === 1 ||
          newConstruction === "true"
        ) {
          newConstruction = "Yes";
        }
      }
    }
    const yrBuilt = _.get(formValues, "yearBuilt", null);

    return (
      <>
        {sellAskingPrice ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Selling Price</div>
              <div className="musthave-content-text">
                ${convertToCurrencySystem(sellAskingPrice)}
              </div>
            </div>
          </div>
        ) : <></>}
        {loadRentingPricesHTMLMob({
          LTRAskngPrce: LTRAskngPrce,
          STRAskngPrce: STRAskngPrce,
        })}
        {stories ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Stories</div>
              <div className="musthave-content-text">{stories}</div>
            </div>
          </div>
        ) : <></>}

        {grgeSpots ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Garage Spaces</div>
              <div className="musthave-content-text">{grgeSpots}</div>
            </div>
          </div>
        ) : <></>}
        {yrBuilt ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Year built</div>
              <div className="musthave-content-text">{yrBuilt}</div>
            </div>
          </div>
        ) : <></>}

        {lotSize ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot size</div>
              <div className="musthave-content-text">{lotSize + " Acres"}</div>
            </div></div>
        ) : <></>}

        {adaAccess ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">ADA Accessible</div>
              <div className="musthave-content-text">{adaAccess}</div>
            </div>
          </div>
        ) : <></>}
        {newConstruction ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">New Construction</div>
              <div className="musthave-content-text">{newConstruction}</div>
            </div>
          </div>
        ) : <></>}
      </>
    );
  }
};


export const buildReceiveIntroMustHaveHtmlForMobile = ({
  typeBasedOnProperty,
  formValues,
}) => {

  const askingPrice = _.get(formValues, "AskingPrice", "");
  let lotSize = (_.get(formValues, "LotSz", ""));
  const stories =
    _.get(formValues, "Stories", "") === 0 ||
      _.get(formValues, "Stories", "") === "0"
      ? null
      : _.get(formValues, "Stories", "");
  const grgeSpots =
    _.get(formValues, "GarageSpots", "") === 0 ||
      _.get(formValues, "GarageSpots", "") === "0"
      ? null
      : _.get(formValues, "GarageSpots", "");
  if (typeBasedOnProperty === INVENTORY_LAND) {
    const acreage = removeDecimalZeros(_.get(formValues, "TotalAcre", ""));
    if (acreage === 0 || acreage == "0") {
      acreage = ""
    }
    let lotSizeUnit = _.get(formValues, "Acre_Sqft", "");

    if (lotSizeUnit && lotSizeUnit !== 0) {
      lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft"
    }

    const lotType = _.get(formValues, "LotType_Rural_City", "");
    const roadType = _.get(formValues, "RoadType_City_Ptivate", "");
    let isPowerAccess = _.get(formValues, "Electricity_Yes_No", "");
    const waterSuply = _.get(
      formValues,
      "Water_Domestic_DrilledWell",
      ""
    );
    const wasteWtrSys = _.get(formValues, "Waste_Sewer_Septic", "");
    let isApprovedStrctr = _.get(
      formValues,
      "Property_Approved_Structure",
      ""
    );
    let isInFloodPlain = _.get(formValues, "Floor_Plan", "");
    if (isPowerAccess || isPowerAccess === 0) {
      if (isPowerAccess === "true" || isPowerAccess === 1) {
        isPowerAccess = "Yes";
      } else {
        isPowerAccess = "No";
      }
    }
    if (isApprovedStrctr || isApprovedStrctr === 0) {
      if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
        isApprovedStrctr = "Yes";
      } else {
        isApprovedStrctr = "No";
      }
    }
    if (isInFloodPlain || isInFloodPlain === 0) {
      if (isInFloodPlain === "true" || isInFloodPlain === 1) {
        isInFloodPlain = "Yes";
      } else {
        isInFloodPlain = "No";
      }
    }
    return (
      <>
        {askingPrice ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Price</div>
              <div className="musthave-content-text">
                ${convertToCurrencySystem(askingPrice)}
              </div>
            </div>
          </div>
        ) : <></>}


        {acreage ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Acreage</div>
              <div className="musthave-content-text">{acreage + lotSizeUnit}</div>
            </div>
          </div>
        ) : <></>}

        {lotType ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot Type</div>
              <div className="musthave-content-text">
                {filterArray(LOT_TYPES, "value", lotType)}
              </div>
            </div>
          </div>
        ) : <></>}

        {roadType ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Road Type</div>
              <div className="musthave-content-text">
                {filterArray(ROAD_TYPES, "value", roadType)}
              </div>
            </div>
          </div>
        ) : <></>}
        {isPowerAccess ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Power in Property</div>
              <div className="musthave-content-text">{isPowerAccess}</div>
            </div>
          </div>
        ): <></>}

        {waterSuply ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-label">Water Supply</div>
              <div className="musthave-value">
                {" "}
                {filterArray(WATER_SUPPLY, "value", waterSuply)}
              </div>
            </div>
          </div>
        ): <></>}
        {wasteWtrSys ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-label">Waste Water System</div>
              <div className="musthave-value">
                {" "}
                {filterArray(WASTE_WATER_SYSTEM, "value", wasteWtrSys)}
              </div>
            </div>
          </div>
        ): <></>}

        {isApprovedStrctr ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Approved for structure</div>
              <div className="musthave-content-text"> {isApprovedStrctr}</div>
            </div>
          </div>
        ): <></>}
        {isInFloodPlain ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-label">Flood plain</div>
              <div className="musthave-value"> {isInFloodPlain}</div>
            </div>
          </div>
        ) : <></>}
      </>
    );
  } else {
    let adaAccess = _.get(formValues, "ADA_Accessible", null);
    if (adaAccess || adaAccess === 0) {
      if (adaAccess === "true" || adaAccess === 1) {
        adaAccess = "Yes";
      } else {
        adaAccess = "No";
      }
    }

    return (
      <>
        {askingPrice ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Asking Price</div>
              <div className="musthave-content-text">
                ${convertToCurrencySystem(askingPrice)}
              </div>
            </div>
          </div>
        ) : <></>}
        {stories ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Stories</div>
              <div className="musthave-content-text">{stories}</div>
            </div>
          </div>
        ) : <></>}

        {grgeSpots ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Garage Spaces</div>
              <div className="musthave-content-text">{grgeSpots}</div>
            </div>
          </div>
        ) : <></>}        
        {lotSize ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot size</div>
              <div className="musthave-content-text">{lotSize + " Acres"}</div>
            </div></div>
        ): <></>}

        {adaAccess ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">ADA Accessible</div>
              <div className="musthave-content-text">{adaAccess}</div>
            </div>
          </div>
        ) : <></>}        
      </>
    );
  }
};


const loadEachNiceToHave = ({ section, masterList, nice2Have }) => {
  const selectedItems =
    nice2Have && nice2Have.filter((item) => item.tab === section);
  const masterArray = _.get(masterList, section, []);
  if (selectedItems.length) {
    return loadNiceToHaveList(masterArray, selectedItems, section.charAt(0));
  } else {
    return "";
  }
};

export const loadInventoryLocation = ({ city, state, zip }) => {
  return `${city}, ${state} ${zip}`;
};

export const finalNiceToHaveList = ({
  typeBasedOnProperty,
  niceToHavesMasterList,
  nice2Have,
}) => {
  const niceToHaveMasterArray =
    typeBasedOnProperty === INVENTORY_LAND
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
  const finalList =
    niceToHaveMasterArray &&
    niceToHaveMasterArray.map((nh) => {
      const list = loadEachNiceToHave({
        section: nh,
        masterList: niceToHavesMasterList,
        nice2Have: nice2Have,
      });
      if (list.length) {
        return list;
      } else {
        return "";
      }
    });
  return finalList;
};

const loadNiceToHaveList = (masterList, selectedItems, uniqueKey) => {
  return (
    selectedItems &&
    selectedItems.map((nt) => {
      const id = _.get(nt, "id");
      const masterItem =
        masterList && masterList.filter((item) => item.Id === id);
      const itemText = _.get(masterItem[0], "Text", "");
      const icon = _.get(masterItem[0], "Icon", "");
      return (
        <div className="nicetohaves-tag" key={id + uniqueKey}>
          <div className="nicetohaves-tag-icon">
            <img src={icon ? require("../images/" + icon) : LakeViewIcon} />
          </div>
          <span>{itemText}</span>
        </div>
      );
    })
  );
};

export const checkValidImage = ({ photos, index }) => {
  const isValid = _.get(photos[index], "IsValid", null);
  const image = _.get(photos[index], "Photo", null);
  if (isValid) {
    return image;
  }
  return inValidImage;
};

export const buildSchoolNeigborhoodHtml = ({
  mapImg,
  highSchool,
  neighborHood,
}) => {
  return (
    <div className="Inventory-Location-wrap pb-32">
      <h2>Location</h2>
      <div className="Inventory-Location-map-wrap mb-3 h-264">
        <img
          src={
            mapImg + "?" + Math.random().toString(36) ||
            InventoryLocationMap + "?" + Math.random().toString(36)
          }
          alt={mapImg || InventoryLocationMap}
        />
      </div>
      <ul>
        {highSchool && (
          <li>
            <span className="Inventory-icn-wrap">
              <img src={SchoolSmallIcon} />
            </span>
            {highSchool}
          </li>
        )}
        {neighborHood && (
          <li>
            <span className="Inventory-icn-wrap">
              <img src={NeighborhoodIcon} />
            </span>
            {neighborHood}
          </li>
        )}
      </ul>
    </div>
  );
};


export const buildSchoolNeigborhoodHtmlForMobile = ({
  mapImg,
  highSchool,
  neighborHood,
}) => {
  return (
    <div className="buyer-info-mob brdr-top-common-wrap mb-4">
      <h2>Location</h2>
      <div className="mb-3 h-200 photo-location-map">
        <img
          src={
            mapImg + "?" + Math.random().toString(36) ||
            InventoryLocationMap + "?" + Math.random().toString(36)
          }
          alt={mapImg || InventoryLocationMap}
        />
      </div>
      <ul>
        {highSchool && (
          <li>
            <span className="list-icn-wrap-mob">
              <img src={SchoolSmallIcon} />
            </span>
            {highSchool}
          </li>
        )}
        {neighborHood && (
          <li>
            <span className="list-icn-wrap-mob">
              <img src={NeighborhoodIcon} />
            </span>
            {neighborHood}
          </li>
        )}
      </ul>
    </div>
  );
};

export const getMobileScreenMustHaves = (details) => {
  const prprtyType = _.get(adDetails, "prprtyType", []);
  const lotType = _.get(adDetails, "lotType", null);
  const adType = _.get(adDetails, "adType", null);
  const garageSpace = _.get(adDetails, "grgSpce", null);
  const stories = _.get(adDetails, "stories", null);
  const minLtSz = _.get(adDetails, "minLtSz", null);
  const maxLtSz = _.get(adDetails, "maxLtSz", null);
  const yearBuiltOption = _.get(adDetails, "yearBuiltOption", null);
  const yearBuiltMin = _.get(adDetails, "yrBltMin", null);
  const yearBuildMax = _.get(adDetails, "yrBltMax", null);
  const accsPvtBthRm = _.get(adDetails, "accsPvtBthRm", null)
    ? "Access to private bathroom"
    : null;
  const alwPets = _.get(adDetails, "alwPets", null) ? "Allows pets" : null;
  const pvtEntrnce = _.get(adDetails, "pvtEntrnce", null)
    ? "Access to private entrance"
    : null;
  const adaAccess = _.get(adDetails, "adaAccess", null)
    ? "Ada accessible"
    : null;
  let ltSize = "";
  ltSize = setLotSizeValue(
    _.get(adDetails, "minLtSz", null),
    _.get(adDetails, "maxLtSz", null)
  );
  let sqFt = wantAdSqftValue(
    _.get(adDetails, "sqFtMin", ""),
    _.get(adDetails, "sqFtMax", "")
  );
  let noBathRoom = _.get(adDetails, "noBathRm", "") || "Any";
  let noBedRoom = _.get(adDetails, "noBedRm", "") || "Any";
  let noBeds = _.get(adDetails, "noOfBeds", "") || "Any";
  if (noBathRoom && noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
  if (noBedRoom && noBedRoom !== "Any") noBedRoom = noBedRoom + "+";
  if (noBeds && noBeds !== "Any") noBeds = noBeds + "+";
  const lotTypeText = lotType
    ? lotType === "1"
      ? "Rural"
      : "In city limits"
    : "";
  if (adType === "STR") {
    if (prprtyType == 6) {
      return lotTypeText + " lot type | " + ltSize + " lot size";
    } else if (prprtyType == 11) {
      return "";
    } else {
      return (
        noBedRoom + " beds | " + noBathRoom + " baths | " + noBeds + " beds"
      );
    }
  } else {
    if (prprtyType == 6) {
      return lotTypeText + " lot type | " + ltSize + " lot size";
    } else if (prprtyType == 11) {
      return "";
    } else {
      return noBedRoom + " beds | " + noBathRoom + " baths | " + sqFt + " sqft";
    }
  }
};


export const buildMobInventoryMustHaveHtml = ({
  typeBasedOnProperty,
  formValues,
}) => {
  const sellAskingPrice = _.get(formValues, "sellAskngPrice", "");
  const LTRAskngPrce = _.get(formValues, "LTRAskngPrce", "");
  const STRAskngPrce = _.get(formValues, "STRAskngPrce", "");
  let noBedRm = removeDecimalZeros(_.get(formValues, "noBedRm", "")); // Multiply by 1 for remove unwanted zeros after decimal .
  const noOfBeds = removeDecimalZeros(_.get(formValues, "noOfBeds", ""));
  let noBathRm = removeDecimalZeros(_.get(formValues, "noBathRm", ""));
  let sqFt = removeDecimalZeros(_.get(formValues, "sqFt", ""));
  let lotSize = removeDecimalZeros(_.get(formValues, "lotSize", ""));
  const stories =
    _.get(formValues, "stories", "") === 0 ||
      _.get(formValues, "stories", "") === "0"
      ? null
      : _.get(formValues, "stories", "");
  const grgeSpots =
    _.get(formValues, "grgeSpots", "") === 0 ||
      _.get(formValues, "grgeSpots", "") === "0"
      ? null
      : _.get(formValues, "grgeSpots", "");

  if (noBedRm === 0 || noBedRm == "0") {
    noBedRm = ""
  }
  if (noBathRm === 0 || noBathRm == "0") {
    noBathRm = ""
  }
  if (sqFt === 0 || sqFt == "0") {
    sqFt = ""
  }
  if (lotSize === 0 || lotSize == "0") {
    lotSize = ""
  }
  if (typeBasedOnProperty === INVENTORY_LAND) {
    let acreage = removeDecimalZeros(_.get(formValues, "acreage", ""));
    if (acreage === 0 || acreage == "0") {
      acreage = ""
    }
    let lotSizeUnit = _.get(formValues, "acre_Sqft", "");
    if (lotSizeUnit && lotSizeUnit !== 0) {
      lotSizeUnit = lotSizeUnit === 1 ? " Acres" : " Sqft"
    }
    const lotType = _.get(formValues, "lotType", "");
    const roadType = _.get(formValues, "roadType", "");
    let isPowerAccess = _.get(formValues, "isPowerAccess", "");
    const waterSuply = _.get(formValues, "waterSuply", "");
    const wasteWtrSys = _.get(formValues, "wasteWtrSys", "");
    let isApprovedStrctr = _.get(formValues, "isApprovedStrctr", "");
    let isInFloodPlain = _.get(formValues, "isInFloodPlain", "");
    const privateDrillInstalled = _.get(formValues, "isPrvtDrInst", null);
    const waterSuplyDrldWellInstall = _.get(formValues, "isDrldWelInst", null);
    const waterSuplyDrldWellApprove = _.get(formValues, "isDrldWelAppr", null);
    const wasteSysSepTankInst = _.get(formValues, "isSepTankInst", null);
    const wasteSysSepTankAppr = _.get(formValues, "isSepTankAppr", null);
    if (isPowerAccess || isPowerAccess === 0) {
      if (isPowerAccess === "true" || isPowerAccess === 1) {
        isPowerAccess = "Yes";
      } else {
        isPowerAccess = "No";
      }
    }
    if (isApprovedStrctr || isApprovedStrctr === 0) {
      if (isApprovedStrctr === "true" || isApprovedStrctr === 1) {
        isApprovedStrctr = "Yes";
      } else {
        isApprovedStrctr = "No";
      }
    }
    if (isInFloodPlain || isInFloodPlain === 0) {
      if (isInFloodPlain === "true" || isInFloodPlain === 1) {
        isInFloodPlain = "Yes";
      } else {
        isInFloodPlain = "No";
      }
    }
    return (
      <>

        {sellAskingPrice ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Selling Price</div>
              <div className="musthave-content-text"> ${convertToCurrencySystem(sellAskingPrice)}</div>
            </div>
          </div>
        ) : <></>}
        {loadMobRentingPricesHTML({
          LTRAskngPrce: LTRAskngPrce,
          STRAskngPrce: STRAskngPrce,
        })}
        {acreage ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Acreage</div>
              <div className="musthave-content-text"> {acreage + lotSizeUnit}</div>

            </div>
          </div>
        ): <></>}
        {lotType ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot Type</div>
              <div className="musthave-content-text"> {filterArray(LOT_TYPES, "value", lotType)}</div>
            </div>
          </div>
        ): <></>}
        {roadType ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Road Type</div>
              <div className="musthave-content-text"> {filterArray(LOT_TYPES, "value", lotType)} {privateDrillInstalled && ", " + "Installed"}</div>
            </div>
          </div>
        ): <></>}
        {isPowerAccess ? (

          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Road Type</div>
              <div className="musthave-content-text"> {filterArray(LOT_TYPES, "value", lotType)} {privateDrillInstalled && ", " + "Installed"}</div>
            </div>
          </div>
        ): <></>}
        {waterSuply ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Water Supply</div>
              <div className="musthave-content-text">
                {" "}
                {filterArray(WATER_SUPPLY, "value", waterSuply)}
                {waterSuplyDrldWellInstall && ", " + "Installed"}
                {waterSuplyDrldWellApprove && ", " + "Approved"}
              </div>
            </div>
          </div>
        ): <></>}
        {wasteWtrSys ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Waste Water System</div>
              <div className="musthave-content-text">
                {" "}
                {filterArray(WASTE_WATER_SYSTEM, "value", wasteWtrSys)}
                {wasteSysSepTankInst && ", " + "Installed"}
                {wasteSysSepTankAppr && ", " + "Approved"}
              </div>
            </div>
          </div>
        ): <></>}
        {isApprovedStrctr ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Approved for structure</div>
              <div className="musthave-content-text"> {isApprovedStrctr}</div>
            </div>
          </div>
        ) : <></>}
        {isInFloodPlain ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Flood plain</div>
              <div className="musthave-content-text"> {isInFloodPlain}</div>

            </div>
          </div>
        ): <></>}

      </>
    );
  } else {
    let adaAccess = _.get(formValues, "adaAccess", null);
    if (adaAccess === 0) {
      adaAccess = null;
    } else {
      if (adaAccess) {
        if (adaAccess === true || adaAccess === 1 || adaAccess === "true") {
          adaAccess = "Yes";
        }
      }
    }
    let newConstruction = _.get(formValues, "IsNewConstruction", null);
    if (newConstruction === 0) {
      newConstruction = null;
    } else {
      if (newConstruction) {
        if (
          newConstruction === true ||
          newConstruction === 1 ||
          newConstruction === "true"
        ) {
          newConstruction = "Yes";
        }
      }
    }
    const yrBuilt = _.get(formValues, "yearBuilt", null);
    return (
      <>

        {sellAskingPrice ? (
          <div className="col-6 pe-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Selling Price</div>
              <div className="musthave-content-text"> ${convertToCurrencySystem(sellAskingPrice)}</div>
            </div>
          </div>
        ) : <></>}
        {loadMobRentingPricesHTML({
          LTRAskngPrce: LTRAskngPrce,
          STRAskngPrce: STRAskngPrce,
        })}

        {stories ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Stories</div>
              <div className="musthave-content-text">{stories}</div>
            </div>
          </div>
        ): <></>}
        {grgeSpots ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Garage Spaces</div>
              <div className="musthave-content-text">{grgeSpots}</div>
            </div>
          </div>

        ): <></>}
        {yrBuilt ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Year built</div>
              <div className="musthave-content-text">{yrBuilt}</div>
            </div>
          </div>

        ): <></>}
        {lotSize ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">Lot size</div>
              <div className="musthave-content-text">{lotSize + " Acres"}</div>
            </div>
          </div>
        ): <></>}
        {adaAccess ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">ADA Accessible</div>
              <div className="musthave-content-text">{adaAccess}</div>
            </div>
          </div>

        ): <></>}
        {newConstruction ? (
          <div className="col-6 ps-2">
            <div className="musthave-content-mobwrap mb-3">
              <div className="musthave-content-label">New Construction</div>
              <div className="musthave-content-text">{newConstruction}</div>
            </div>
          </div>
        ): <></>}

      </>
    );
  }
};


const loadMobRentingPricesHTML = ({ LTRAskngPrce, STRAskngPrce }) => {
  let rentingPrice;
  if (LTRAskngPrce && STRAskngPrce) {
    return (
      <>
        <div className="col-6 ps-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Renting Price</div>
            <div className="musthave-content-text">  {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}</div>
          </div>
        </div>
        <div className="col-6 pe-2">
          <div className="musthave-content-mobwrap mb-3">
            <div className="musthave-content-label">Renting Price</div>
            <div className="musthave-content-text">  {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}</div>
          </div>
        </div>
      </>
    );
  } else if (LTRAskngPrce && !STRAskngPrce) {
    return (
      <div className="col-6 ps-2">
        <div className="musthave-content-mobwrap mb-3">
          <div className="musthave-content-label">Renting Price</div>
          <div className="musthave-content-text">   {"$" + convertToCurrencySystem(LTRAskngPrce) + "/Month"}</div>
        </div>
      </div>
    );
  } else if (STRAskngPrce && !LTRAskngPrce) {
    return (
      <div className="col-6 pe-2">
        <div className="musthave-content-mobwrap mb-3">
          <div className="musthave-content-label">Renting Price</div>
          <div className="musthave-content-text"> {"$" + convertToCurrencySystem(STRAskngPrce) + "/Night"}</div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

