import React, { useState, useEffect} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import TextBoxMob from "../components/TextBoxMob";
import TextAreaMob from "../components/TextAreaMob";
import { ERROR_TEXT, USER_INFO_KEY } from "../../constants/common";
import { saveAgentClient } from "../../services/agentClientsService";
import { handleTextBoxChange } from "../../actions/common";
import { emailValidation, nameValidation, textValidations } from "../../helpers/validations";
import { getLocalStorage, getUserId, setLocalStorage } from "../../helpers/common";

const AddNewClient = (props) => {
  const { show, onHide, clientDetails, isEdit } = props;

  let initialData = {
    firstName: "",
    lastName: "",
    email: "",
    info: ''
  };


  useEffect(() => {
    if (isEdit && clientDetails){
      const data = {
        clientId: clientDetails.ClientId,
        firstName: clientDetails.FirstName,
        lastName: clientDetails.LastName,
        email: clientDetails.Email,
        info: clientDetails.Add_Info
      };
      setFormData(data);
    }

  }, [isEdit, clientDetails]);

  //form States
  const [formData, setFormData] = useState(initialData);
  const [formErrorMessage, setFormErrorMessage] = useState(initialData);
  const [disableButton, setDisableButton] = useState(true);
  const [descError, setDescError] = useState(null);

   /**
  * To handle textbox value change
  * @param {any} event 
  */
   const onChangeText = (event) => {
    const value = event.target.name === 'email' ? handleTextBoxChange(event) :
      capitalizeFirstString(handleTextBoxChange(event))
    setFormData(formData => ({ ...formData, [event.target.name]: value }));
    if (event.target.name == 'firstName') {
      delete formErrorMessage.firstName;
    }
    if (event.target.name == 'lastName') {
      delete formErrorMessage.lastName;
    }
    if (event.target.name == 'info') {
      setDescError(null)

    }
    checkSubmitButtonEnableStatus({ ...formData, [event.target.name]: value })
  }

  const checkSubmitButtonEnableStatus = (data) => {
    if ((data.firstName || data.firstName.trim() != "")) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }


  /**
   * to validate add client form
   * @param {json} data 
   * @returns json
   */
  const validateForm = (data) => {
    let errors = {};
    if (!data.firstName || data.firstName.trim() == "") {
      errors.firstName = 'Enter First Name';
    }
    // if (!nameValidation(data.firstName)) {
    //   errors.firstName = 'Invalid input';
    // }

    // if (!data.lastName || data.lastName.trim() == "") {
    //   errors.lastName = 'Enter Last Name';
    // }
    // if (!nameValidation(data.lastName)) {
    //   errors.lastName = 'Invalid input';
    // }
    if (data.email) {

      if (!emailValidation(data.email.trim())) {
        errors.email = 'Enter Valid Email';
      }

    }

    const bio = data.info
    if (bio) {
      if (!textValidations(bio)) {
        errors.info = ERROR_TEXT
        setDescError(ERROR_TEXT)
      }
    }


    return errors;
  };

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };
  const addClient = async (data) => {
    var param = {
        ...data,
    }
    await saveAgentClient(param)
        .then((response) => {
            if (response.errorCode === 0) {
              onHide();
              setFormData(initialData);
              setFormErrorMessage({initialData});
              if(!isEdit) {
                const userDetails = getLocalStorage(USER_INFO_KEY);
                const updatedUserDetails = [...userDetails, {ClientCount: userDetails?.ClientCount + 1}];
                setLocalStorage(USER_INFO_KEY, updatedUserDetails );
              }
              if(response.result.agentExist){
                toast.success(response.message);
              } else{
                if(isEdit) {
                  toast.success("Edited client successfully!");
                } else {
                  toast.success("Added client successfully!");
                }
                handleClose();
              }
              
            } else {
                return;
            }
        })
        .catch((err) => {
            console.log("Error", err);
        });

}

  const submitForm = (formData) => {
    let error = validateForm({ ...formData })
    setFormErrorMessage(error);
    if (Object.keys(error).length == 0) {
      setDisableButton(false)
      addClient(formData);
    } else {
      setDisableButton(true)
    }
  }

  const handleOnHide = () => {
    onHide();
    setFormData(initialData);
    setFormErrorMessage({initialData});
  }

  return (
    <Offcanvas
      className="mobile-offcanvas"
      show={show}
      onHide={()=>handleOnHide()}
      placement="bottom"
      {...props}
    >
      <Offcanvas.Header className="d-none" closeButton>
        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="offcanvas-sharead-wrap">
          <div className="share-yourad-topsec flex-column mb-4">
            <div className="share-yourad-heading mt-2 mb-2">
              { isEdit ? 'Edit Client (Only visible to you)' : 'Add New Client'}
            </div>
            <div className="share-yourad-text">
              {isEdit ? '' : 'All client information can only be seen by you.'}
            </div>
          </div>

          <div className="overflowXhide">
            <div className="row">
              <div className="col-6 ">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">First name</label>
                  <TextBoxMob
                    id="fName"
                    name="firstName"
                    type="text"
                    value={formData.firstName}
                    placeholder="First"
                    closeIcon={formData.firstName!=""}
                    handleClear={()=>setFormData({ ...formData, firstName: '' })}
                    className="form-control mob-pr-44"
                    onChange={(e) => {
                      onChangeText(e);
                    }}
                    errorMessage={formErrorMessage.firstName}
                    maxLength={35}
                  />                  
                </div>
              </div>
              <div className="col-6 ">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">Last name</label>                  
                  <TextBoxMob
                    id="lName"
                    name="lastName"
                    type="text"
                    value={formData.lastName}
                    placeholder="Last"
                    closeIcon={formData.lastName!=""}
                    handleClear={()=>setFormData({ ...formData, lastName: '' })}
                    className="form-control mob-pr-44"
                    onChange={(e) => {
                      onChangeText(e);
                    }}
                    errorMessage={formErrorMessage.lastName}
                    maxLength={35}
                  />                  
                </div>
              </div>

              <div className="col-12 ">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">Client’s Email</label>
                  <TextBoxMob
                    id="email"
                    name="email"
                    type="email"
                    closeIcon={formData.email!=""}
                    value={formData.email}
                    handleClear={()=>setFormData({ ...formData, email: '' })}
                    placeholder="Email"
                    className="form-control mob-pr-44"
                    onChange={(e) => {
                      onChangeText(e);
                    }}
                    errorMessage={formErrorMessage.email}
                    maxLength={35}
                  />                  
                </div>
              </div>

              <div className="col-12 ">
                <div className="mob-fld-wrap position-relative mb-0">
                  <label className="form-label">Client’s Bio</label>
                  <TextAreaMob
                    name="info"
                    className="form-control txt-area"
                    errorMessage={descError}
                    value={formData.info}
                    maxLength={5000}
                    placeholder="Additional info about client"
                    onChange={(e) => {
                      onChangeText(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <button type="button" 
            className="btn btn-primary w-100 mb-4 mt-3"
            disabled={disableButton}
            onClick={() => {
              submitForm(formData)
            }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default AddNewClient;
