import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { capitalizeString, handleTextBoxChange } from "../../../actions/common";
import {
  getLocalStorage,
  removeFixedDecimalPart,
  trimString,
} from "../../../helpers/common";
import GoogleMap from "../../../components/Common/GoogleMap";
import {
  getGeoLocation,
  getPropertyDetails,
  getPropertySearch,
} from "../../../services/commonServices";
import { Button } from "react-bootstrap";
import { usePrevious } from "../../../hooks/usePrevious";
import {
  DECIMAL_POINTS_IN_COORDICATES,
  INVENTORY_KEY,
  INVENTORY_MAP_RADIUS,
  MAP_EVENT_CLICK,
  MAP_EVENT_LOCATION_CHANGE,
  MAP_EVENT_MARKER_DRAG,
} from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
import mapImg01 from "../../images/map.png";

const InventoryLocation = (props) => {
  const {
    setDisableSave,
    handleFormValues,
    locationAddress,
    isEdit = false,
    defaultProps,
    updateValuesToState,
    isLot_Land,
    isPrivateRoom,
    noBedRm,
    noBathRm,
    sqFt,
    mapType,
    lotType,
    lotSize,
  } = props;

  const [addressList, setAddressList] = useState([]);
  const [propertyAddress, setPropertyAddress] = useState("");
  const [showMarker, setShowMarker] = useState(true);
  const handleShowMarker = (markerStatus) => {
    setShowMarker(markerStatus);
  };
  const [isClickOrDrag, setClickDrag] = useState(false);
  const HandleMapClickOrDrag = (status) => {
    setClickDrag(status);
  };

  useEffect(() => {
    handleContinueButton();
  }, [locationAddress, noBedRm, noBathRm, sqFt, , lotSize, lotType]);

  useEffect(() => {
    handlePropertyExistCheck();
  }, []);

  const handlePropertyExistCheck = () => {
    let isExist;
    if (trimString(locationAddress) === "") {
      isExist = false;
    } else {
      isExist = true;
    }
    // if (isLot_Land) {
    //   if (trimString(locationAddress) !== "" && lotSize && lotType) {
    //     isExist = true;
    //   } else {
    //     isExist = false;
    //   }
    // } else {
    //   if (isPrivateRoom) {
    //     if (trimString(locationAddress) !== "") {
    //       isExist = true;
    //     } else {
    //       isExist = false;
    //     }
    //   } else {
    //     if (trimString(locationAddress) !== "" && noBedRm && noBathRm && sqFt) {
    //       isExist = true;
    //     } else {
    //       isExist = false;
    //     }
    //   }
    // }
    handleFormValues({ isPropertyExist: isExist });
  };

  const handleContinueButton = () => {
    let isDisable;
    if (isLot_Land) {
      if (trimString(locationAddress) !== "" && lotSize && lotType) {
        isDisable = false;
      } else {
        isDisable = true;
      }
    } else {
      if (isPrivateRoom) {
        if (trimString(locationAddress) !== "") {
          isDisable = false;
        } else {
          isDisable = true;
        }
      } else {
        if (trimString(locationAddress) !== "" && noBedRm && noBathRm && sqFt) {
          isDisable = false;
        } else {
          isDisable = true;
        }
      }
    }
    setDisableSave(isDisable);
  };

  const handleLocationFromMap = (address) => {
    const isDisableContinueButton =
      address && address.trim() !== "" ? false : true;
    if (address && address.trim() !== "") {
      // handleContinueButtonState(isDisableContinueButton);
      handleFormValues({ locAddress: address.trim() });
    } else {
      handleFormValues({ locAddress: "" });
    }
    updateValuesToState("locAddress", address);
  };

  const handleItemSelection = (address) => {
    handleShowMarker(true);
    HandleMapClickOrDrag(false);
    const formatted_address = _.get(address, "formatted_address", "");
    const latitude = _.get(address, "geometry.location.lat", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lat", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});
    const longitude = _.get(address, "geometry.location.lng", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lng", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});
    const reqPayLoad = {
      latitude: latitude,
      longitude: longitude,
      radius: INVENTORY_MAP_RADIUS,
      mls_active: true,
    };
    handleFormValues({
      locAddress: formatted_address?.trim(),
      lat: latitude,
      lng: longitude,
    });
    getPropertySearchDetails(reqPayLoad);
    clearAddressList();
  };

  const getPropertySearchDetails = async (reqPayLoad) => {
    // handleFormValues({
    //   noBedRm: "",
    //   noBathRm: "",
    //   sqFt: "",
    //   stories: "",
    //   yearBuilt: "",
    //   neighborHood: "",
    //   lotSize: "",
    //   highSchool: "",
    //   isPropertyExist: ""
    // });
    setPropertyAddress("");
    const inventoryDetails = getLocalStorage(INVENTORY_KEY);
    const street_no = _.get(inventoryDetails, "street_no", null);
    const route = _.get(inventoryDetails, "route", null);
    let streetAddress = null;
    if (street_no && route) {
      streetAddress = street_no + " " + route;
    } else if (street_no && !route) {
      streetAddress = street_no;
    } else if (!street_no && route) {
      streetAddress = route;
    }
    const isStreetExist = reqPayLoad.street;
    if (!isStreetExist) {
      reqPayLoad.street = streetAddress;
    }
    if (!isPrivateRoom) {
      await getPropertySearch(reqPayLoad).then((data) => {
        let statesToBeUpdated = { isPropertyExist: true };
        statesToBeUpdated.sellAskngPrice = "";
        //handlePropertyExist({ isExist: true });
        const searchData = _.get(data, "result");
        if (searchData) {
          // handlePropertyExist({ isExist: true });
          const lotSquareFeet = _.get(searchData, "lotSquareFeet", null);
          const proprtyAddrs = _.get(searchData, "address", "");
          const mlsActive = _.get(searchData, "mlsActive", "");
          if (mlsActive) {
            statesToBeUpdated.sellAskngPrice = _.get(
              searchData,
              "mlsListingPrice",
              null
            );
          }

          if (!isLot_Land) {
            setPropertyAddress(" (" + _.get(proprtyAddrs, "address", "") + ")");
            const yearBuilt = _.get(searchData, "yearBuilt", null);
            const neighborhood = _.get(searchData, "neighborhood", null);

            let lotInAcres = null;
            if (lotSquareFeet && lotSquareFeet !== 0) {
              try {
                lotInAcres = lotSquareFeet / 43560;
              } catch {}
            }

            statesToBeUpdated.noBedRm = _.get(searchData, "bedrooms", "");
            statesToBeUpdated.noBathRm = _.get(searchData, "bathrooms", "");
            statesToBeUpdated.sqFt = _.get(searchData, "squareFeet", "");
            // stories: _.get(searchData, "stories", ""),
            (statesToBeUpdated.yearBuilt = yearBuilt),
              (statesToBeUpdated.neighborHood = _.get(
                neighborhood,
                "name",
                null
              ));
            statesToBeUpdated.lotSize =
              lotInAcres &&
              removeFixedDecimalPart({ value: lotInAcres, decimalPoint: 2 });

            statesToBeUpdated.street = _.get(proprtyAddrs, "street", "");
            (statesToBeUpdated.city = _.get(proprtyAddrs, "city", "")),
              (statesToBeUpdated.county = _.get(proprtyAddrs, "county", ""));
            statesToBeUpdated.state = _.get(proprtyAddrs, "state", "");
            statesToBeUpdated.zip = _.get(proprtyAddrs, "zip", "");

            const propertyId = _.get(searchData, "propertyId", "");
            getProprtyDetailsBasedOnPropertyId({ propertyId: propertyId });
          } else {
            statesToBeUpdated.acreage = lotSquareFeet;
            statesToBeUpdated.acre_Sqft = 2;
            statesToBeUpdated.street = _.get(proprtyAddrs, "street", "");
            statesToBeUpdated.city = _.get(proprtyAddrs, "city", "");
            statesToBeUpdated.county = _.get(proprtyAddrs, "county", "");
            statesToBeUpdated.state = _.get(proprtyAddrs, "state", "");
            statesToBeUpdated.zip = _.get(proprtyAddrs, "zip", "");
          }
        }
        handleFormValues(statesToBeUpdated);
      });
    }
    //}
  };

  const getAddresseDetails = async ({ eventType, reqPayload }) => {
    handleFormValues({
      noBedRm: "",
      noBathRm: "",
      sqFt: "",
      stories: "",
      yearBuilt: "",
      neighborHood: "",
      lotSize: "",
      highSchool: "",
      isPropertyExist: false,
      street: "",
      city: "",
      county: "",
      country: "",
      state: "",
      zip: "",
      street_no: "",
      route: "",
    });

    //if (eventType === MAP_EVENT_LOCATION_CHANGE) {
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      if (results?.length > 0) {
        const formatted_address = _.get(results?.[0], "formatted_address", "");
        if (
          eventType === MAP_EVENT_LOCATION_CHANGE &&
          results?.length === 1 &&
          formatted_address !== "United States"
        ) {
          setAddressList(results);
        }

        const firstLocationResult = _.get(results[0], "geometry.location", "");
        const firstAddressComponent = _.get(
          results[0],
          "address_components",
          ""
        );
        const lat = _.get(firstLocationResult, "lat", "");
        const lng = _.get(firstLocationResult, "lng", "");
        let addressObject = { lat: lat, lng: lng };

        if (
          eventType === MAP_EVENT_CLICK ||
          eventType === MAP_EVENT_MARKER_DRAG
        ) {
          addressObject.locAddress = _.get(results[0], "formatted_address", "");
        }

        try {
          if (firstAddressComponent && firstAddressComponent.length > 0) {
            const street_no = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "street_number";
            });

            if (street_no.length) {
              addressObject.street_no = _.get(street_no?.[0], "short_name", "");
            }

            const route = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "route";
            });

            if (route.length) {
              addressObject.route = _.get(route?.[0], "short_name", "");
              addressObject.street = _.get(route?.[0], "short_name", "");
            }
            const locality = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "locality";
            });
            if (locality.length) {
              addressObject.city = _.get(locality?.[0], "long_name", "");
            }
            const county = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "administrative_area_level_2";
            });
            if (county.length) {
              addressObject.county = _.get(county?.[0], "short_name", "");
            }
            const state = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "administrative_area_level_1";
            });
            if (state.length) {
              addressObject.state = _.get(state?.[0], "short_name", "");
            }
            const zip = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "postal_code";
            });
            if (zip.length) {
              addressObject.zip = _.get(zip?.[0], "short_name", "");
            }
          }
        } catch {}
        const street_no = _.get(addressObject, "street_no", null);
        const route = _.get(addressObject, "route", null);
        let streetAddress = null;
        if (street_no && route) {
          streetAddress = street_no + " " + route;
        } else if (street_no && !route) {
          streetAddress = street_no;
        } else if (!street_no && route) {
          streetAddress = route;
        }
        handleFormValues(addressObject);
        const reqPayLoad = {
          latitude: lat,
          longitude: lng,
          radius: INVENTORY_MAP_RADIUS,
          street: streetAddress,
        };
        if (eventType !== MAP_EVENT_LOCATION_CHANGE) {
          getPropertySearchDetails(reqPayLoad);
        }
      } else {
        // const isDisable = true;
        // handleContinueButtonState(isDisable);
      }
    });
    //}
  };

  const clearLocationStates = () => {
    handleFormValues({
      noBedRm: "",
      noBathRm: "",
      sqFt: "",
      stories: "",
      yearBuilt: "",
      neighborHood: "",
      lotSize: "",
      highSchool: "",
      locAddress: "",
      street: "",
      city: "",
      county: "",
      country: "",
      state: "",
      zip: "",
      street_no: "",
      route: "",
      isPropertyExist: false,
      acreage: "",
    });
  };

  const getProprtyDetailsBasedOnPropertyId = async (reqPayload) => {
    if (!isLot_Land) {
      await getPropertyDetails(reqPayload).then((data) => {
        const result = _.get(data, "result.data");
        const propertyInfo = _.get(result, "propertyInfo");
        if (result) {
          let stateToBeUpdated = {};
          const schools = _.get(result, "schools", []);
          if (propertyInfo) {
            stateToBeUpdated.stories = _.get(propertyInfo, "stories", "");
          }
          if (schools.length > 0) {
            stateToBeUpdated.highSchool = _.get(result, "schools[0].name", "");
          }

          handleFormValues(stateToBeUpdated);
          //handlePropertyDetails({propertyDetails: result })
        }
      });
    }
  };

  const clearAddressList = () => {
    setAddressList([]);
  };

  const handleLocationChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    if (value === "") {
      clearLocationStates();
    } else {
      const reqPayload = `address=${value}&components=country:US`;
      getAddresseDetails({
        eventType: MAP_EVENT_LOCATION_CHANGE,
        reqPayload: reqPayload,
        address: value,
      });
    }
    handleFormValues({ locAddress: value });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="position-relative">
            {/* {addressList.length > 0 ? (
          <Button
            variant=""
            className="search-clear-btn"
            onClick={() => clearAddressList()}
          ></Button>
        ) : (
          ""
        )} */}
            <div className="search_field_input position-relative mb-0 input-group d-flex">
              <input
                className="form-control pr-0"
                ariaLabel="Example"
                ariaDescribedBy="basic-addon3"
                errorMessage=""
                iconClass="search-icon"
                placeholder="Street address"
                isIconEnable={true}
                iconId="button-addon3"
                value={locationAddress}
                onChange={handleLocationChange}
              ></input>
              <button
                type="button"
                id="button-addon3"
                className="search-icon "
              ></button>
            </div>
            {addressList.length > 0 ? (
              <div className="location-result-wrap">
                <ul>
                  {addressList?.map((address, i) => {
                    //console.log(`####address`, address)
                    return (
                      <li key={i}>
                        <a
                          role="button"
                          href={void 0}
                          onClick={(e) => {
                            handleItemSelection(address);
                          }}
                        >
                          {_.get(address, "formatted_address", "")}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="title-selection-wrap mt-4">
        <div className="location-map-wrap-mob  text-center">
        <div className="googlemap-responsive">
        <div className="map-outer">
          <GoogleMap
            defaultProps={defaultProps}
            mapHeight="100%"
            mapWidth="100%"
            handleFormValues={handleFormValues}
            handleLocationFromMap={handleLocationFromMap}
            isFromInventory={true}
            getAddresseDetails={getAddresseDetails}
            getPropertySearchDetails={getPropertySearchDetails}
            mapType={mapType}
            handleShowMarker={handleShowMarker}
            isClickOrDrag={isClickOrDrag}
            HandleMapClickOrDrag={HandleMapClickOrDrag}
            clearLocationStates={clearLocationStates}
            showMarker={showMarker}
            propertyAddress={propertyAddress}
          />
          {/* <img src={mapImg01} alt={mapImg01} /> */}
        </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default InventoryLocation;
