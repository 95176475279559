import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWantAds } from "../actions/wantAds";

const HomeLoadingSkeleton = (props) => {
  // const list = useSelector((state) => state.wantAds);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchWantAds());
  // }, []);

  return (
    <>
      <div className="skeleton-wrapper">
        <div className="skeleton-img-wrap-outer">
          <div className="skeleton-img-wrap"></div>
        </div>
        <div className="skeleton-info-wrap">
          <div className="skelton-info-top"></div>
          <div className="skelton-info-middle"></div>
          <div className="skelton-info-bottom"></div>
          <div className="skeleton-tag-wrap">
            <div className="skeleton-tag-switch"></div>
          </div>
        </div>
        <div className="skeleton-btn-wrap">
          <div className="skeleton-btn"></div>
          <div className="skeleton-btn"></div>
        </div>
      </div>

      <div className="skeleton-wrapper">
        <div className="skeleton-img-wrap-outer">
          <div className="skeleton-img-wrap"></div>
        </div>
        <div className="skeleton-info-wrap">
          <div className="skelton-info-top"></div>
          <div className="skelton-info-middle"></div>
          <div className="skelton-info-bottom"></div>
          <div className="skeleton-tag-wrap">
            <div className="skeleton-tag-switch"></div>
          </div>
        </div>
        <div className="skeleton-btn-wrap">
          <div className="skeleton-btn"></div>
          <div className="skeleton-btn"></div>
        </div>
      </div>

      <div className="skeleton-wrapper">
        <div className="skeleton-img-wrap-outer">
          <div className="skeleton-img-wrap"></div>
        </div>
        <div className="skeleton-info-wrap">
          <div className="skelton-info-top"></div>
          <div className="skelton-info-middle"></div>
          <div className="skelton-info-bottom"></div>
          <div className="skeleton-tag-wrap">
            <div className="skeleton-tag-switch"></div>
          </div>
        </div>
        <div className="skeleton-btn-wrap">
          <div className="skeleton-btn"></div>
          <div className="skeleton-btn"></div>
        </div>
      </div>
      <div className="skeleton-wrapper">
        <div className="skeleton-img-wrap-outer">
          <div className="skeleton-img-wrap"></div>
        </div>
        <div className="skeleton-info-wrap">
          <div className="skelton-info-top"></div>
          <div className="skelton-info-middle"></div>
          <div className="skelton-info-bottom"></div>
          <div className="skeleton-tag-wrap">
            <div className="skeleton-tag-switch"></div>
          </div>
        </div>
        <div className="skeleton-btn-wrap">
          <div className="skeleton-btn"></div>
          <div className="skeleton-btn"></div>
        </div>
      </div>

      {/*  <div className="no-result-wrap mw-100 h-100 pt-0 pb-0">
              <h3>There aren't any want-ads in your location yet</h3>
              <p>In the mean time, try broadening your location search.</p>
           </div>

           <div className="no-result-wrap mw-100 h-100 pt-0 pb-0">
              <h3>Gitcha isn't operating in this area (yet!)</h3>
              <p>Get notified when we're available in your city.</p>
  </div>*/}

      {/*<div className="row">
        <div className="col-12">
        <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src="holder.js/100px180" />
        <Card.Body>
          <Placeholder as={Card.Title} animation="wave">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="wave">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          <Placeholder.Button variant="primary" xs={6} />
        </Card.Body>
      </Card>
        </div>
      </div>*/}
    </>
  );
};

export default HomeLoadingSkeleton;
