import React, { useState, useEffect } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
import SegmentDivider from "../components/MemberWantAd/SegmentDivider";
import MyDrafts from "../components/Common/MyDrafts";
import { useNavigate } from "react-router-dom";
import { AGENT_WANTAD_KEY, USER_TYPE_AGENT } from "../constants/common";
import { getMyDraft, getWantAdById } from "../services/wantAdService";
import { pageURLs } from "../constants/pageURLs";
import {
  getUserId,
  checkTutorialDisplayStatus,
  getUserType,
} from "../helpers/common";
import BuyerList from "../images/buyer_listing_img.png";
import CreateYourCustomLink from "../components/Common/CreateYouCustomLink";
import { useSelector } from "react-redux";

const AgentCreateOrEditWantAdDraftListing = (props) => {
  const [showCreateYourCustomLinkPopuop, setShowCreateYourCustomLinkPopup] =
    useState(false);
  const handleCreateYourCustomLinkPopupOpen = () => {
    setShowCreateYourCustomLinkPopup(true);
  };
  const handleCreateYourCustomLinkPopupClose = () => {
    setShowCreateYourCustomLinkPopup(false);
  };

  const navigate = useNavigate();
  const [myDrafts, setMyDrafts] = useState(null);
  const userType = getUserType();
  const isAgent = userType === USER_TYPE_AGENT ? true : false;

  const userDetailsFromStore = useSelector(
    (state) => state.userProfile.userDetails
  );
  const [isPremiumUser, setIsPremiumUser] = useState(null);

  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      if (userDetailsFromStore[0]?.period !== 1 && userType === 2) {
        setIsPremiumUser(true);
      } else {
        setIsPremiumUser(false);
      }
    }
  }, [userDetailsFromStore]);

  useEffect(() => {
    //Need to show want add onboard very first time
    let DisplayWantAddOnboard = checkTutorialDisplayStatus(6); //Want add onboard display
    if (DisplayWantAddOnboard) {
      navigate(pageURLs.gitchaMemberWelcome);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem(AGENT_WANTAD_KEY);
    if(isPremiumUser != null){
        getMyDraft()
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (responseResult && responseResult.length !== 0) {
            setMyDrafts(responseResult);
          } else {
            if (!isPremiumUser) {
              navigate(pageURLs.agentCreatewantad);
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  }, [isPremiumUser]);

  const handleDraftItemClick = async (requestPayLoad) => {
    let wantAdId = requestPayLoad.selectedId;
    if (wantAdId) {
      await getWantAdById(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            localStorage.setItem(
              AGENT_WANTAD_KEY,
              JSON.stringify(responseResult)
            );
            navigate(pageURLs.agentCreatewantad);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const loadDrafts = () => {
    if (myDrafts) {
      const myDraftList = myDrafts?.map((md) => {
        const wantAdId = _.get(md, "Want_ad_Id");
        const CompletedPercentage = _.get(md, "CompletedPercentage", "");
        const adTypeId = _.get(md, "Buy_or_Rent", "");
        const Current_Step = _.get(md, "Current_Step", "");
        const IsReadyToReview = _.get(md, "IsReadyToReview", "");
        const IsFilledByClient = _.get(md, "IsFilledByClient", "");
        let lastUpdatedDate = new Date(_.get(md, "LastUpdatedTS", ""));
        let formattedDate = [
          ("0" + (lastUpdatedDate.getMonth() + 1)).slice(-2),
          ("0" + lastUpdatedDate.getDate()).slice(-2),
          lastUpdatedDate.getFullYear(),
        ].join("/");
        let adType = "";
        if (adTypeId === 1) {
          adType = "Buy";
        } else if (adTypeId === 2) {
          adType = "Rent (Long-term)";
        } else if (adTypeId === 3) {
          adType = "Rent (Short-term)";
        }
        let clientName;
        if (_.get(md, "ClientFirstName") && _.get(md, "ClientLastName")) {
          clientName =
            _.get(md, "ClientFirstName") + " " + _.get(md, "ClientLastName");
        } else if (_.get(md, "ClientFirstName")) {
          clientName = _.get(md, "ClientFirstName");
        }

        return (
          <MyDrafts
            key={wantAdId}
            itemId={wantAdId}
            CompletedPercentage={CompletedPercentage}
            adType={adType}
            Current_Step={Current_Step}
            setDraftSelectedItem={handleDraftItemClick}
            latUpdatedDate={formattedDate}
            clientName={clientName}
            isFromWantAd={true}
            IsFilledByClient={IsFilledByClient}
            IsReadyToReview={IsReadyToReview}
          />
        );
      });

      return myDraftList;
    }
  };

  return (
    <>
      <CreateYourCustomLink
        show={showCreateYourCustomLinkPopuop}
        onHide={()=>handleCreateYourCustomLinkPopupClose()}
      />
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        {/* {myDrafts && ( */}
        <div className="row">
          <div className="col-md-12">
            <div className="edit-want-ad-wrap mw-640">
              <h2>Create a Want-Ad Listing</h2>
              <h6 className="mb-40">
                Select how you would you like to create your Want-Ad Listing.{" "}
              </h6>
              {isPremiumUser && (
                <>
                  <div
                    className="create-new-wantad-wrap mb-3"
                    onClick={() => navigate("")}
                  >
                    <label className="radio-wrap">
                      <input
                        name="SELL4"
                        type="radio"
                        value="false"
                        onClick={() => handleCreateYourCustomLinkPopupOpen()}
                      />
                      <span>
                        <div className="timing-wrap-info">
                          <h2 className="pb-0">
                            Have my buyer fill out my branded Buyer Listing Form
                            link (like a DocuSign){" "}
                          </h2>
                        </div>
                      </span>
                    </label>
                  </div>

                  <div className="text-center">
                    <div className="mt-3 mb-3">
                      <img src={BuyerList} className="wdImg" />
                    </div>
                    <div className="wdImg m-auto mb-3 boldTxt">
                      {" "}
                      After your buyer submits their criteria to you via your
                      custom link, we notify you to review and post it from your
                      drafts. (Buy only)
                    </div>
                  </div>
                </>
              )}              
              {(myDrafts || isPremiumUser===true) && (
                <>
                  <SegmentDivider />
                  <div className="my-drafts-sec">
                    <div
                      className="create-new-wantad-wrap mt-4 mb-4"
                      onClick={() => navigate("/agent-create-want-ad")}
                    >
                      <label className="radio-wrap">
                        <input name="SELL4" type="radio" value="false" />
                        <span>
                          <div className="timing-wrap-info">
                            <h2 className="pb-0">
                              {isPremiumUser
                                ? "Fill it out myself"
                                : "Create New Want-Ad"}{" "}
                            </h2>
                          </div>
                        </span>
                      </label>
                    </div>
                    {myDrafts &&<>
                      <h2 className="mt-3">My Drafts</h2>
                      {loadDrafts()}
                    </>}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentCreateOrEditWantAdDraftListing;
