import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate } from "react-router-dom";
import { createPortal } from 'react-dom';

const DeleteNotificationModalNew = (props) => {
  const navigate = useNavigate();
  const { onHide, firstName  } = props;
  const [domReady, setDomReady] = React.useState(false)

  const divStyle = { 
    display: props.show ? 'flex' : 'none'
};

  React.useEffect(() => {
    setDomReady(true)
  }, [])
  return domReady? createPortal((
  //  <Modal
  //     show
  //     {...props}
  //     size="md"
  //     aria-labelledby="contained-modal-title-vcenter"
  //     centered
  //     className="modal-right-aligned-small"
      
      
  //   >
     
  //     <Modal.Body className="pt-32 pb-32">
        
  //       <h3 className="text-center mb-32">Are you sure you want to delete all notifications?</h3>

  //       <Row>
  //         <Col xl={12}>
            
  //           <Button variant="" className="btn btn-confirm w-100">
  //             Confirm
  //           </Button>
  //           <Button variant="" aria-label="Close"
  //         onClick={props.onHide} className="btn btn-cancel w-100 mt-20">
  //             Cancel
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Modal.Body>
      
  
  // </Modal>
  <div className="modal-new"
  style={divStyle} onFocusOut={props.onHide}>
       <div className="modal-content-new">
          {/*<span className="close" onClick={props.onHide}>&times;</span>*/}
           <h3>Are you sure you want to delete all notifications?</h3>
           <button className="btn btn-confirm w-100"  onClick={()=>{props.onConfirm(2)}}>
              Confirm
            </button>

            <button variant=""
          onClick={props.onHide} className="btn btn-cancel w-100">
              Cancel
            </button>

       </div>
     </div>
 
  ), document.querySelector(".notif-modal-container")) : null;
};
export default DeleteNotificationModalNew;
