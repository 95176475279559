/**
 * @file   src\components\Common\CountdownTimer.js
 * @brief  This file is responsible for count down timer
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from 'react';
import clock from "../../images/time-Icn.svg";
import { calculateTimeLeft } from '../../helpers/common';

const CountdownTimer = ({ startDate, isFromSharePublicPage }) => {
  

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(startDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate]);

  

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));

  /**
   * To show formatted time to view
   * @param {*} timeLeft 
   * @returns 
   */
  const formatTimeLeft = (timeLeft) => {
    if (timeLeft.hours > 0) {
        if (timeLeft.minutes > 0 || timeLeft.seconds > 0) {
          return `${timeLeft.hours + 1} hours`;
        }
        return `${timeLeft.hours} hours`;
      } else if (timeLeft.minutes > 0) {
        if ( timeLeft.seconds > 0) {
          return `${timeLeft.minutes + 1} minutes`;
        }
        return `${timeLeft.minutes} minutes`;
      } else {
        return `${timeLeft.seconds} seconds`;
      }
  };

  return (
    <>
    {isFromSharePublicPage &&
    <div className="linkExpiry">
    <img src={clock} className="pe-2" /> This link will expire in <b>{formatTimeLeft(timeLeft)}</b>
  </div>
    }
    </>
  );
};

export default CountdownTimer;