import React, { useState, useEffect } from "react";

import { getPropertyTypes } from "../../services/commonServices";
import { handleRadioButonChange } from "../../actions/common";
import { usePrevious } from "../../hooks/usePrevious";
import {
  BUY,
  ENTIRE_SPACE_TYPE,
  IS_ENTIRE_SPACE,
  IS_LAND,
  IS_PRIVATE_ROOM,
  PRIVATE_ROOM_TYPE,
} from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";

const WantAdRentPropertyTypes = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    propertyType,
    adType,
    prptySubTypes,
    clearPreviousStates,
    isEdit = false,
  } = props;
  const [propertyList, setPropertyList] = useState([]);
  const [propertySubItems, setPropertySubItems] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState();
  useEffect(() => {
    if (propertyType) {
      if (convertToNumber(propertyType) === IS_ENTIRE_SPACE) {
        getProperiesSubItems(ENTIRE_SPACE_TYPE);
      } else if (convertToNumber(propertyType) === IS_PRIVATE_ROOM) {
        getProperiesSubItems(PRIVATE_ROOM_TYPE);
      } else {
        handleContinueButtonState();
      }
    } else {
      const isDisable = true;
      handleContinueButtonState(isDisable);
    }

    handlePropertySubTypes();
    const typeOfAd = "rent"; //adType === BUY ? "buy" : "rent";
    getProperties(typeOfAd);
  }, []);

  const getProperties = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertyList(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const handlePropertySubTypes = () => {
    if (
      convertToNumber(propertyType) === IS_ENTIRE_SPACE ||
      convertToNumber(propertyType) === IS_PRIVATE_ROOM
    ) {
      if (prptySubTypes && prptySubTypes.length > 0) {
        handleContinueButtonState();
      } else {
        const isDisabled = true;
        handleContinueButtonState(isDisabled);
      }
      if (
        prptySubTypes.length !== 0 &&
        propertySubItems.length === prptySubTypes.length
      ) {
        setIsSelectAll(true);
      }
    }
  };
  useEffect(() => {
    handlePropertySubTypes();
  }, [propertySubItems, prptySubTypes]);

  const getProperiesSubItems = async (adType) => {
    await getPropertyTypes(adType)
      .then((response) => {
        if (response.errorCode === 0 && _.get(response, "result", null)) {
          const propertyTypes = _.get(response, "result", null);
          setPropertySubItems(propertyTypes);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const previousValue = usePrevious(propertyType);
  const handlePropertyClick = (e, typeText) => {
    const isDisabled = true;
    handleContinueButtonState(isDisabled);
    setIsSelectAll(false);
    const value = handleRadioButonChange(e);
    if (value) {
      console.log(convertToNumber(value));
      if (convertToNumber(value) === IS_ENTIRE_SPACE) {
        getProperiesSubItems(ENTIRE_SPACE_TYPE);
      } else if (convertToNumber(value) === IS_PRIVATE_ROOM) {
        getProperiesSubItems("privateroom");
      } else {
        handleContinueButtonState();
        setPropertySubItems([]);
      }
      clearPreviousSelectedValues(value);
      handleFormValues({ prprtyType: value, prptyTypeTxt: typeText });
    }
  };

  const handlePropertySubItemClick = (e) => {
    setIsSelectAll(false);
    const value = handleRadioButonChange(e);
    if (value) {
      let subItemArray;
      handleContinueButtonState();
      if (_.includes(prptySubTypes, convertToNumber(value))) {
        subItemArray = prptySubTypes.filter(
          (e) => e !== convertToNumber(value)
        );
      } else {
        subItemArray = [...prptySubTypes, convertToNumber(value)];
      }
      handleFormValues({ prptySubTypes: subItemArray });
    }
  };

  const clearPreviousSelectedValues = (currentSelectedValue) => {
    if (previousValue) {
      const isClearPrevious =
        (convertToNumber(previousValue) === IS_LAND &&
          convertToNumber(currentSelectedValue) !== IS_LAND) ||
        (convertToNumber(previousValue) !== IS_LAND &&
          convertToNumber(currentSelectedValue) === IS_LAND) ||
        (convertToNumber(previousValue) === IS_ENTIRE_SPACE &&
          convertToNumber(currentSelectedValue) === IS_PRIVATE_ROOM) ||
        (convertToNumber(previousValue) === IS_PRIVATE_ROOM &&
          convertToNumber(currentSelectedValue) === IS_ENTIRE_SPACE);
      if (isClearPrevious) {
        clearPreviousStates();
      }
    }
  };

  const loadPropertyTypes = () => {
    const List =
      propertyList &&
      propertyList.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const typeText = _.get(pt, "PropertyTypeText", "");
        const Img = _.get(pt, "Img", "");
        const Desc = _.get(pt, "Description", "");
        return (
          <div
            key={typeId}
            className={
              !isEdit
                ? "col-sm-12 col-lg-12 col-xl-12 col-xxl-6"
                : "col-sm-12 col-lg-12 col-xl-12 col-xxl-12"
            }
          >
            <label className="radio-wrap mb-3">
              <input
                type="radio"
                name="radio"
                value={typeId ? typeId : ""}
                checked={(propertyType && propertyType == typeId) || ""}
                onChange={(e) => handlePropertyClick(e, typeText)}
              />
              <span>
                <div className="wantad-type-info">
                  {adType !== BUY && Desc ? (
                    <>
                      {" "}
                      <h2>{_.get(pt, "PropertyTypeText", "")}</h2> <p>{Desc}</p>{" "}
                    </>
                  ) : (
                    <h2 className="pb-0">
                      {_.get(pt, "PropertyTypeText", "")}
                    </h2>
                  )}
                </div>
                {/* <div className="right-img-wrap">
                  {Img ? (
                    <img src={require("..//../images/" + Img)} />
                  ) : (
                    <img src={HouseImg} />
                  )}
                </div> */}
              </span>
            </label>
          </div>
        );
      });

    return List;
  };

  const loadPropertySubTypes = () => {
    const List =
      propertySubItems &&
      propertySubItems.map((pt) => {
        const typeId = _.get(pt, "PropertyTypeId", "");
        const Img = _.get(pt, "Img", "");
        const isChecked = isSelectAll
          ? true
          : prptySubTypes && _.includes(prptySubTypes, typeId);
        return (
          <div key={typeId} className="col-sm-12 col-lg-12 col-xl-12 col-xxl-6">
            <label className="check-wrap mb-3">
              <input
                type="checkbox"
                name="pst-checkbox"
                id={typeId}
                value={typeId ? typeId : ""}
                checked={isChecked}
                onChange={(e) => handlePropertySubItemClick(e)}
              />
              <span>
                <div className="wantad-type-info">
                  <h2 className="pb-0">{_.get(pt, "PropertyTypeText", "")}</h2>
                </div>
                {/* <div className="right-img-wrap">
                  {Img ? (
                    <img src={require("..//../images/" + Img)} />
                  ) : (
                    <img src={HouseImg} />
                  )}
                </div> */}
              </span>
            </label>
          </div>
        );
      });

    return List;
  };

  const handleSelectAll = (e) => {
    const isSelectAll = e.target.checked;
    if (isSelectAll) {
      const filteredArray = propertySubItems.map((p) => p.PropertyTypeId);
      handleFormValues({ prptySubTypes: filteredArray });
    } else {
      handleFormValues({ prptySubTypes: [] });
    }
    setIsSelectAll(isSelectAll);
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-100" : ""}>
      <div className="wantad-type-wrap pb-24">
        <div className="row">{loadPropertyTypes()}</div>
      </div>
      {propertySubItems && propertySubItems.length > 0 ? (
        <div className="wantad-type-wrap select-type-wrap">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className="sub-head mb-0">Select types{isEdit && "*"}:</h6>
            <div className="field-wrap mt-0 mb-0">
              <label
                className="form-check-label checkbox-label checkbox-label-override"
                htmlFor="flexCheckDefault78"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault78"
                  checked={isSelectAll}
                  onClick={handleSelectAll}
                />
                Select All
              </label>
            </div>
          </div>
          <div className="row">{loadPropertySubTypes()}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default WantAdRentPropertyTypes;
