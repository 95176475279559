import React, { useState } from "react"
import { InputGroup, FormControl, Button } from "react-bootstrap"
{/*import WantAdPropertyPurchase from "../components/MemberWantAd/WantAdPropertyPurchase";*/}

import { createRoot } from 'react-dom/client';




const NoPage = () => {
 
    return (
    <>
   {/* <WantAdPropertyPurchase/>*/}
    <h2>404-Page not found</h2>
    
    </>
    
    )
  };
  
  export default NoPage;
  