import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import _ from "lodash";
import TextBox from "../Common/TextBox";
import {
  emailValidation,
  nameValidation,
  phoneNumberValidation,
  textValidations,
} from "../../helpers/validations";
import TextArea from "../Common/TextArea";
import { handleRadioButonChange, handleTextBoxChange } from "../../actions/common";
import { getLocalStorage, formatPhoneNumber } from "../../helpers/common";
import { USER_INFO_KEY } from "../../constants/common";
import { saveGitchaNeedAgentContactUs } from "../../services/commonServices";
import { toast } from "react-toastify";

const NeedAnAgentModal = (props) => {
  const { show, process, onHide } = props;

  const initialData = {
    firstName: "",
    lastName: "",
    phone: "",
    email: getLocalStorage(USER_INFO_KEY)?.userInfo?.email,
    type : ""
  };

  //form States
  const [formData, setFormData] = useState(initialData);
  const [formErrorMessage, setFormErrorMessage] = useState(initialData);
  const [disableButton, setDisableButton] = useState(true); 

  /**
   * To handle textbox value change
   * @param {any} event
   */
  const onChangeText = (event) => {
    let value =
      event.target.name === "email" || event.target.name == "phone"
        ? handleTextBoxChange(event)
        : capitalizeFirstString(handleTextBoxChange(event));
    console.log("value", value)
    setFormData((formData) => ({ ...formData, [event.target.name]: value }));    
    if (event.target.name == "firstName") {
      delete formErrorMessage.firstName;
    }
    if (event.target.name == "lastName") {
      delete formErrorMessage.lastName;
    }

    if (event.target.name == "phone"){
      delete formErrorMessage.phone;
      value = formatPhoneNumber(value);
    }
    checkSubmitButtonEnableStatus({ ...formData, [event.target.name]: value });
  };

  const checkSubmitButtonEnableStatus = (data) => {
    if (
      (data.firstName || data.firstName.trim() != "") &&
      (data.lastName || data.lastName.trim() != "") &&
      (data.email || data.email.trim() != "") && 
      (data.phone || data.phone.trim() != "") &&
      (data.type || data.type.trim()!="")
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  /**
   * to validate add client form
   * @param {json} data
   * @returns json
   */
  const validateForm = (data) => {
    let errors = {};
    if (!data.firstName || data.firstName.trim() == "") {
      errors.firstName = "Enter First Name";
    }
    if (!nameValidation(data.firstName)) {
      errors.firstName = "Invalid input";
    }

    if (!data.lastName || data.lastName.trim() == "") {
      errors.lastName = "Enter Last Name";
    }
    if (!nameValidation(data.lastName)) {
      errors.lastName = "Invalid input";
    }
    if (!data.email || data.email.trim() == "") {
      errors.emailError = "Enter Email";
    } else {
      if (!emailValidation(data.email.trim())) {
        errors.emailError = "Enter Valid Email";
      }
    }
    if (!data.phone || data.phone.trim() == "") {
      errors.phoneError = "Enter Phone Number";
    } else {
      if (!phoneNumberValidation(data.phone.trim())) {
        errors.phoneError = "Enter Valid Phone Number";
      }
    }
    return errors;
  };

  const capitalizeFirstString = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const submitForm = (formData) => {
    let error = validateForm({ ...formData });
    setFormErrorMessage(error);
    if (Object.keys(error).length == 0) {
      setDisableButton(false);
      saveGitchaContactUsData(formData);
    } else {
      setDisableButton(true);
    }
  };

  const handleItemClick = (e) => {
    const value = handleRadioButonChange(e);
    if (value) {
      setFormData({...formData, type: value});
    }
    checkSubmitButtonEnableStatus({ ...formData, type: value});
  };

  const saveGitchaContactUsData = async (formData) =>{
    var updatedData = {
      ...formData,
    }

    await saveGitchaNeedAgentContactUs(updatedData)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.success(response.message);
          onHide();
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });

  }

  return (
    <>
      <Modal
        show
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExit={() => {
          setFormData(initialData);
          setFormErrorMessage("");
          setDisableButton(true);
        }}
      >
        {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="pt-32 pb-32">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => onHide({ modalState: false })}
          ></button>
          <h3 className="text-start">Contact Information</h3>
          <Row>
            <Col xl={6}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label">First name</label>     

                <TextBox
                  id="fName"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  placeholder="First name"
                  className="form-control"
                  onChange={(e) => {
                    onChangeText(e);
                  }}
                  errorMessage={formErrorMessage.firstName}
                  maxLength={35}
                />
              </div>
            </Col>

            <Col xl={6}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label">Last name</label>                
                <TextBox
                  id="lName"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  placeholder="Last name"
                  className="form-control"
                  onChange={(e) => {
                    onChangeText(e);
                  }}
                  errorMessage={formErrorMessage.lastName}
                  maxLength={35}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label">Phone Number</label>
                <TextBox
                  pattern="[0-9]*" 
                  inputmode="numeric"
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  placeholder="Phone Number"
                  className="form-control"
                  onChange={(e) => {
                    onChangeText(e);
                  }}
                  errorMessage={formErrorMessage.phoneError}
                  maxLength={14}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label">Email</label>
                <TextBox
                  id="email"
                  name="email"
                  type="text"
                  value={formData.email}
                  placeholder="Email"
                  className="form-control"
                  onChange={(e) => {
                    onChangeText(e);
                  }}
                  errorMessage={formErrorMessage.emailError}
                  maxLength={35}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="wantad-type-wrap need-agent-wrap">
                <label className="form-label">
                  Are you a buyer, seller, or both?
                </label>
                <div>
                  <label className="radio-wrap mb-3">
                    <input
                      type="radio"
                      name="rc-radio"
                      value={1}
                      checked={formData?.type && formData?.type == 1}
                      onChange={(e) => handleItemClick(e)}
                    />
                    <span>
                      <div>
                        <p>Buyer</p>
                      </div>
                    </span>
                  </label>
                  <label className="radio-wrap mb-3">
                    <input
                      type="radio"
                      name="rc-radio"
                      value={2}
                      checked={formData?.type && formData?.type == 2}
                      onChange={(e) => handleItemClick(e)}
                    />
                    <span>
                      <div>
                        <p>Seller</p>
                      </div>
                    </span>
                  </label>
                  <label className="radio-wrap mb-3">
                    <input
                      type="radio"
                      name="rc-radio"
                      value={3}
                      checked={formData?.type && formData?.type == 3}
                      onChange={(e) => handleItemClick(e)}
                    />
                    <span>
                      <div>
                        <p>Both</p>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
            </Col>
          </Row>

          <Button
            variant="primary"
            className="btn w-100"
            onClick={() => {
              submitForm(formData);
            }}
            disabled={disableButton}
          >
            Send message
          </Button>
        </Modal.Body>
        {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
      </Modal>
    </>
  );
};
export default NeedAnAgentModal;
