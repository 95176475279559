import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";

import MenuBottomBar from "../components/menuBottomMobile";
import FldCloseIcon from "../images/round-close-icon.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ValidationAlertIcon from "../images/validation-alert-icon.svg";
import SparkleBigIcon from "../images/sparkle-big-icon.svg";
import HouseMobIcon from "../images/house-mob-icon.svg";
import NotifMobIcon from "../images/notif-mob-icon.svg";
import ClientbookMobIcon from "../images/clientbook-mob-icon.svg";
import UserMobIcon from "../images/user-mob-icon.svg";
import PropIconHouse from "../../images/property-icon-house.svg";
import WantadMapImage from "../../images/confirm-map.png";
import BarnImage from "../../images/barn.svg";
import LakeViewImage from "../../images/lake-view-icon.svg";
import ArtificalturfImage from "../../images/artificial-turf.svg";
import BeachViewImage from "../../images/beach-view.svg";
import BonusRoomImage from "../../images/bonus-room.svg";
import ConsumerIconImage from "../../images/consumer-icon.svg";
import TimelineIconImage from "../../images/timeline-icon.svg";
import NeighborIconImage from "../../images/neighborhood-icon.svg";
import PrefschoolIconImage from "../../images/school-icon.svg";
import PrResIconImage from "../../images/primary-residence-icon.svg";
import FinanceIconImage from "../../images/financing-icon.svg";
import HearttIcon from "../../images/mob-like-outline-btn.svg";
import HearttIconFilled from "../../images/mob-like-filled-btn.svg";
import ViewADDropdown from "../components/ViewAdDropdown";

import { useRef } from "react";
import { favouriteWantAd, getAgentsList, getWantAdById, unFavouriteWantAd } from "../../services/wantAdService";
import { pageURLs } from "../../constants/pageURLs";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  WANT_AD_KEY,
  USER_INFO_KEY,
  INVENTORY_KEY,
  STRENGTHEN_WANT_AD_KEY,
  IS_LAND,
  IS_MULTI_FAMILY,
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
  USER_TYPE_AGENT,
  INVESTOR_MEMBER_PLAN,
  PRO_MEMBER_PLAN,
} from "../../constants/common";
import base64 from "base-64";
import utf8 from "utf8";
import {
  getPropertyIcon,
  getTooltipText,
  getCombinedText,
  getDescriptionText,
} from "../../helpers/getPropertyIcons";
import {
  SquareFeet,
  buildWantAdMustHavesHTML,
} from "../../helpers/WantAdDetails";
import {
  archiveWantAd,
  unArchiveWantAd,
  deleteWantAd,
} from "../../services/wantAdService";

import {
  loadTimeLineHtml,
  getLocalStorage,
  setLocalStorage,
  getUserId,
  loggedInUser,
  getUserType,
  loadPreferredLocationHtml,
  isIphone,
  checkTutorialDisplayStatus,
  saveTutorialStatusToLocal,
  loadCompensationText,
  locationDisplay,
} from "../../helpers/common";
import ShareWantAd from "../commonComponents/ShareWantAd";
import ReportWantAdModal from "../components/ReportWantAdModal";
import MakeIntroductionOffcanvas from "../components/SendIntroduction/MakeIntroductionOffcanvas";
import DeleteWantAdModal from "../components/DeleteWantAdModal";
import { usePrevious } from "../../hooks/usePrevious";
import _ from "lodash";
import LearnIntroductionModal from "../components/LearnIntroductionModal";
import ViewAddClientsModal from "../components/Favorites/ViewAddClientsModal";
import { handleTextBoxChange } from "../../actions/common";
import { toast } from "react-toastify";
import { VIEW_RECEIVED_INTRODUCTIONS } from "../../constants/onBoarding";
import { useSelector } from "react-redux";
import AgentProfileInfoModal from "../components/ReceiveIntroduction/AgentProfileInfoModal";
import noImage from "../../images/no-image-placeholder.png";
import { convertToNumber } from "../../helpers/validations";
import { loadInvestmentCriteria } from "../../helpers/createWantAd";
import MinCapIcon from "../../images/minimum-icn.svg";
import userProfile from "../../appState/userProfile";
import { getUserPlanDetails } from "../../services/userprofileService";

const ViewAd = (props) => {
  useEffect(() => {
    document.getElementById("scroll-chain").classList.remove("scroll-chaining-fix")
    document.body.classList.add("bg-offcanvas-fix");
    document.body.classList.add("bg-modal-fix");
    window.addEventListener("scroll", handleScroll); // attaching scroll event listener
  }, []);
  const tutorialPage = 5;//send intro tutorial
  const navigate = useNavigate();
  const location = useLocation();
  const wantAd = getLocalStorage(WANT_AD_KEY);
  const [searchParams] = useSearchParams();
  const [modalShow, setModalShow] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [reloadAgents, setReloadAgents] = useState(false);
  const [results, setResults] = useState([]);
  searchParams.get("wantAdId");
  const id = searchParams.get("wantAdId");
  let decodedId;
  let idFromURL;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
    if (decodedId) {
      let decodedData = decodedId.split("-");
      idFromURL = decodedData.length > 0 ? decodedData[1] : null;
    }
  } catch { }
  // const isFromHomeFeed =
  //   location.state && location?.state["isFromHomeFeed"] ? true : false;
  searchParams.get("wantAdId");
  const [skeltonLoad, setSkeltonLoad] = useState(true); //
  const isFromHomeFeed = idFromURL
    ? true
    : _.get(wantAd, "isFromHomeFeed", false);
  const [showShareModal, setShareModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showReportModal, setReportModal] = useState(false);

  const handleShareClose = () => {
    setShareModal(false);
  };
  const [showStrengthen, setShowStrengthen] = useState(false);
  const [showViewIntroductionModal, setViewIntroductionModal] = useState(false);
  const wantAdId = idFromURL ? idFromURL : _.get(wantAd, "wantAdId", null);
  const introId = _.get(wantAd, "introId", null);
  const isFromAllIntroduction = _.get(wantAd, "isFromAllIntroduction", false);
  const fromStrengthenWantAd =
    location.state && location.state["fromStrengthenWantAd"];


  const isFromInventory = location.state && location.state["isFromInventory"];
  const isEdit = _.get(wantAd, "isEdit", false);
  const [adDetails, setAdDetails] = useState();
  const [isFav, setIsFav] = useState("unload");
  const adType = _.get(adDetails, "adType", "");
  const propertyType = Number(_.get(adDetails, "prprtyType", ""));
  const PropertyTypeId = _.get(adDetails, "prprtyType", "");
  const subPropertyTypeId = _.get(adDetails, "prptySubTypes", "");
  const adTypeBasedOnProperty = propertyType === 6 ? LOT_LAND : adType;
  const isAdTypeIsLand = adTypeBasedOnProperty === LOT_LAND;
  const nice2Have = _.get(adDetails, "nice2Have", []);
  const mapImage = _.get(adDetails, "mapImg", null);
  const consumerType = adType == BUY ? "Buyer" : "Renter";
  const propertySubTypes = _.get(adDetails, "prptySubTypes", []);


  const [introductionList, setIntroductionList] = useState([]);
  const [introCount, setIntroCount] = useState(0);
  const WantCreatedUserId = _.get(adDetails, "userId", false);
  const userType = getUserType();
  const loggedInUserId = getUserId();
  const isLoadIntroductionList =
    (isFromHomeFeed &&
      WantCreatedUserId &&
      WantCreatedUserId === loggedInUserId) ||
    (introId ? true : false);

  const [reload, setReload] = useState(0);
  const pageTopRef = useRef(null);
  const scrollDivRef = useRef(null);
  const isArchived = _.get(adDetails, "isArchived", false);
  // Send Introduction
  const [showIntroductionOffcanvas, setShowIntroductionOffcanvas] =
    useState(false);
  const IntroductionOffcanvasClose = () => setShowIntroductionOffcanvas(false);
  const IntroductionOffcanvasShow = () => setShowIntroductionOffcanvas(true);

  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [displayTutorial, setDisplayTutorial] = useState(false);

  const [isPremiumUser, setIsPremiumUser] = useState(null);

  const [showAgentProfileViewModal, setAgentProfileViewModal] = useState(false);
  const agentProfileViewModalClose = () => setAgentProfileViewModal(false);
  const agentProfileViewModalShow = () => setAgentProfileViewModal(true);
  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("");
  const purchaseType = convertToNumber(_.get(adDetails, "prchTypeId", ''));
  const [wantAdCreatedUserPlan, setWantAdCreatedUserPlan] = useState("");

  useEffect(() => {
    if (idFromURL) {
      setLocalStorage(WANT_AD_KEY, { "wantAdId": idFromURL })
    }
  }, [idFromURL])


  useEffect(() => {
    if(userDetails){
      if(userDetails?.length===1){
        if(userDetails[0]?.PlanName){
         setUserPlan(userDetails[0]?.PlanName)
        }
      }
      else {
        if(userDetails[0]?.PlanName === 'Trial'){
          setUserPlan(userDetails[1]?.PlanName)
        } else{
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }    
  }, [userDetails]);


  useEffect(()=>{
    if(userPlan===PRO_MEMBER_PLAN){
      setIsPremiumUser(true)
    }
    else{
      setIsPremiumUser(false)
    }
  },[userPlan])

  useEffect(() => {
    setTimeout(function () {
      if (WantCreatedUserId && (WantCreatedUserId !== loggedInUserId) && !showShareModal) {
        let tutorialDisplayStatus = checkTutorialDisplayStatus(tutorialPage);
        setShowTutorialModal(tutorialDisplayStatus);
      } else {
        setShowTutorialModal(false);
      }
    }, 300);
  }, [WantCreatedUserId]);



  // useEffect(() => {
  //   if (decodedId) {
  //     const wantadId = decodedId.split("-"); // Split decoded string for getting want ad id.
  //     if (wantadId && wantadId.length) {
  //       getWantAdDetails(wantadId[1]);
  //     } else {
  //       navigate(pageURLs.notFound);
  //     }
  //   } else {
  //     navigate(pageURLs.notFound);
  //   }
  // }, []);

  useEffect(() => {
    if (props.showSendIntroPopUp) {
      setShowIntroductionOffcanvas(true);
    } else {
      setShowIntroductionOffcanvas(false);
    }
    getWantAdDetails(wantAdId);
  }, [props.showSendIntroPopUp]);

  useEffect(() => {
    getWantAdDetails(wantAdId);
    getCreatedUserPlan(wantAdId);
  }, [reload]);


  useEffect(() => {
    window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section

    const showSuccess = _.get(wantAd, "showSuccess", null);
    if (showSuccess && !isFromHomeFeed && !isFromAllIntroduction && !isFromInventory) {
      setSuccessModal(true);
    } else if (!isFromHomeFeed && !isFromAllIntroduction && !isFromInventory) {
      // showStrengthen && setShareModal(true);
      // setShareModal(false);
      if (!introId && !fromStrengthenWantAd && isEdit == false) {
        setShareModal(true);
      }

    }
    getWantAdDetails(wantAdId);
  }, [introId]);

  useEffect(() => {
    setIsFav(_.get(adDetails, "isFavourite", "unload"))
    if (_.get(adDetails, "isAgentCreated", "") != 1) {
      if (
        (adTypeBasedOnProperty === BUY ||
          adTypeBasedOnProperty === LONG_RENT) &&
        !_.get(adDetails, "isStrengthen", false) &&
        !isFromHomeFeed &&
        !isFromAllIntroduction &&
        !introId &&
        isEdit == false
      ) {
        setShowStrengthen(true);
      } else {
        setShowStrengthen(false);
      }
    }

    // setIsFavourite(isFavourite);
  }, [adDetails]);

  useEffect(() => {
    getAgentsClientsList();
  }, [clientSearch, reloadAgents]);

  const getAgentsClientsList = async (query) => {
    const reqPayload = {
      search: clientSearch,
      sortBy: "createdOn",
      sortDir: "desc",
    };
    await getAgentsList(reqPayload)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setResults(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdById(wantAdId, loggedInUserId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setSkeltonLoad(false);
            setAdDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      // navigate(pageURLs.createwantad);
      navigate(pageURLs.mobileHome);
    }
  };

  const getCreatedUserPlan = async (wantAdId) => {
    if (wantAdId) {
      await getUserPlanDetails({wantAdId:wantAdId})
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
           setWantAdCreatedUserPlan(responseResult[0]?.PlanName);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      // navigate(pageURLs.createwantad);
      navigate(pageURLs.home);
    }
  };

  /**
* to handle clients favorites action
* @param {*} agentIds
*/
  const handleClientFavoriteWantAd = (agentIds) => {
    handleFavoriteOrUnfavoriteWantAd(isFav, wantAdId, agentIds);
  };

  /**
* handle agents favorite action, to open client list popup
*/
  const handleAgentsFavoriteAction = () => {
    setModalShow(true);
  };

  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }
    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10 me-0">
          {adTypeTile}
        </span>
      </>
    );
  };

  const loadStrengthenDetails = () => {
    if (adType === BUY) {
      return (
        <>
          {" "}
          {(_.get(adDetails, "prchTypeTxt", "") ||
            _.get(adDetails, "purchaseText", "")) && (
              <li>
                <span className="list-icn-wrap-mob">
                  <img src={PrResIconImage} alt={PrResIconImage} />
                </span>
                {_.get(adDetails, "prchTypeTxt", "") ||
                  _.get(adDetails, "purchaseText", "")}
                {(_.get(adDetails, "is1031Exchange", "") == "Yes" || _.get(adDetails, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
              </li>
            )}
          {(_.get(adDetails, "finTypeTxt", "") ||
            _.get(adDetails, "pFinanceText", "")) && (
              <li>
                <span className="list-icn-wrap-mob">
                  <img src={FinanceIconImage} alt={FinanceIconImage} />
                </span>
                Financing:{" "}
                {_.get(adDetails, "finTypeTxt", "") ||
                  _.get(adDetails, "pFinanceText", "")}
                {(_.get(adDetails, "isPreApprLoan", "") == "Yes" ||
                  _.get(adDetails, "isPreApprLoan", "") == 1) &&
                  " - pre approved"}
              </li>
            )}
        </>
      );
    } else if (adType === LONG_RENT) {
      return (
        <>
          {" "}
          {_.get(adDetails, "creditScoreText", "") && (
            <li>
              <span className="list-icn-wrap-mob">
                <img src={FinanceIconImage} alt={FinanceIconImage} />
              </span>
              Credit Score : {_.get(adDetails, "creditScoreText", "")}
            </li>
          )}
        </>
      );
    }
  };

  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(adDetails, "isMobileView");
  };

  const loadTimeLine = () => {
    return loadTimeLineHtml(
      adType,
      adTypeBasedOnProperty,
      adDetails,
      "isMobileView"
    );
  };

  const handleStrengthenDismiss = () => setShowStrengthen(false);

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isAdTypeIsLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };
  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <>
            <div className="property-tag-mobile mb-2" key={id + uniqueType}>
              <div class="tag-icon-mobile">
                <img
                  src={icon ? require("../../images/" + icon) : LakeViewImage}
                  alt={icon ? require("../../images/" + icon) : LakeViewImage}
                />
              </div>
              <span>{itemText}</span>
            </div>
          </>
        );
      })
    );
  };

  const handleWantAdWebClick = (wantAdId) => {
    const sessionUserInfo = getLocalStorage(USER_INFO_KEY);
    console.log("sessionUserInfo", sessionUserInfo);
    if (sessionUserInfo?.userInfo?.userId) {
      setLocalStorage(WANT_AD_KEY, {
        wantAdId: wantAdId,
        isFromHomeFeed: true,
      });
      navigate(pageURLs.wantAdDetails);
    } else {
      setWantAdClickCounter((prevState) => prevState + 1);
      const wantAdArray = getLocalStorage(WANT_AD_ARRAY_KEY) || [];
      if (wantAdArray && wantAdArray.length < 3) {
        const bytes = utf8.encode(
          `abcdefghijklmnopqrstuvwxyz123456789abdefghijklmnopqrstuvwxyz-${wantAdId}-abcdefghijklmnopqrstuvwxyz123456789abdefghijklmnopqrstuvwxyz`
        );
        const encodedId = base64.encode(bytes);
        navigate(`${pageURLs.wantAdDetails}?wantAdId=${encodedId}`, {
          state: { isFromHomeFeed: true },
        });
      }
      if (!wantAdArray.includes(wantAdId) && wantAdArray.length < 3) {
        wantAdArray.push(wantAdId);
      }
      setLocalStorage(WANT_AD_ARRAY_KEY, wantAdArray);
    }
  };

  const isNiceToHaveExist = nice2Have.length > 0;

  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {adType === BUY
        ? getTooltipText(propertyType)
        : propertySubTypes &&
        propertySubTypes.length > 0 &&
        getCombinedText(propertySubTypes)}
    </Tooltip>
  );

  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      formValues: adDetails,
    });
  };

  const SquareFeetDetails = () => {
    return SquareFeet({
      formValues: adDetails,
    });
  };

  useEffect(() => {
    document.body.classList.add("bg-offcanvas-fix-new");
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleReportPopup = (isShow) => setReportModal(isShow);

  let noBathRoom = _.get(adDetails, "noBathRm", "");
  let noBedRoom = _.get(adDetails, "noBedRm", "");
  if (noBathRoom !== "Any") noBathRoom = noBathRoom + "+";
  if (noBedRoom !== "Any") noBedRoom = noBedRoom + "+";

  const handleStrengthenClick = () => {
    const adDetails = getLocalStorage(WANT_AD_KEY);
    setLocalStorage(STRENGTHEN_WANT_AD_KEY, adDetails);
    navigate(pageURLs.strengthenAdResponsive);
  };

  const updateLocalStorage = () => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const wantAdId = _.get(wantAd, "wantAdId", null);
    // console.log("wantAdId", wantAdId);
    setLocalStorage(WANT_AD_KEY, { wantAdId: wantAdId });
  };

  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const prevScrollPosition = usePrevious(scrollPosition);



  const handleScroll = () => {
    const isBottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (!isBottom && window.scrollY > 5) {
      setScrollPosition(window.scrollY);
    }
  };

  useEffect(() => {
    if (adDetails) {
      if (
        scrollPosition > prevScrollPosition &&
        scrollDirection !== SCROLL_DIRECTION_DOWN
      ) {
        //setHeight(0);
        //setShowFooter(true)
        setScrollDirection(SCROLL_DIRECTION_DOWN);
      } else if (
        scrollPosition < prevScrollPosition &&
        scrollDirection !== SCROLL_DIRECTION_UP
      ) {
        // const currentTab = getLocalStorage("FilterTabKey") || "General";
        // if (currentTab === "match") {
        //   //setHeight(divRef.current && divRef.current.clientHeight);
        // } else {
        //   //setHeight(divRef.current && divRef.current.clientHeight);
        // }
        // setShowFooter(false)
        setScrollDirection(SCROLL_DIRECTION_UP);
      }
    }
  }, [scrollPosition]);

  let mainWrapperClass = "gitcha-main-wrapper pb-180 bg-white windowscroll-fix";
  let contentWrapperClass = "content-wrapper content-wrapper-h-180-percent windowscroll-fix";
  let innerLayoutClass = "inner-layout-header-new bg-white";
  let bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix";

  let isEnableIntoduceButton = false;
  let isEnableIntoductions = false;
  let introduceBtnClass = "";
  if (
    isFromHomeFeed &&
    WantCreatedUserId &&
    loggedInUserId &&
    WantCreatedUserId != loggedInUserId
  ) {
    isEnableIntoduceButton = true;
    introduceBtnClass = "pb-90";
  } else {
    isEnableIntoduceButton = false;
    if (
      WantCreatedUserId &&
      WantCreatedUserId == loggedInUserId &&
      loggedInUserId && _.get(adDetails, "introCount", 0) > 0
    ) {
      isEnableIntoductions = true;
      introduceBtnClass = "pb-90";
    } else {
      isEnableIntoductions = false;
      introduceBtnClass = "pb-0";
    }
  }

  if (scrollDirection === SCROLL_DIRECTION_DOWN) {
    if (isEnableIntoduceButton || isEnableIntoductions || isArchived === 1) {
      mainWrapperClass = "gitcha-main-wrapper pb-90 bg-white windowscroll-fix";
    } else {
      mainWrapperClass = "gitcha-main-wrapper pb-0 bg-white windowscroll-fix";
    }

    contentWrapperClass = isIphone()
      ? `content-wrapper content-wrapper-h-180-percent windowscroll-fix ${introduceBtnClass}`
      : `content-wrapper content-wrapper-h-180-percent windowscroll-fix ${introduceBtnClass}`;
    //contentWrapperClass = "content-wrapper pad-top-0 h-100"
    innerLayoutClass = "inner-layout-header-new bg-white hide-layout-header";
    bottomMenuClass =
      "inner-layout-footer-new inner-layout-footer-topfix hide-bottom-footer";
  } else if (scrollDirection === SCROLL_DIRECTION_UP) {
    mainWrapperClass = "gitcha-main-wrapper pb-180 bg-white windowscroll-fix";
    contentWrapperClass = "content-wrapper content-wrapper-h-180-percent windowscroll-fix";
    innerLayoutClass = "inner-layout-header-new bg-white";
    bottomMenuClass = "inner-layout-footer-new inner-layout-footer-topfix";
  }

  if ((!isEnableIntoduceButton && !isEnableIntoductions) && isArchived !== 1) {
    contentWrapperClass = 'content-wrapper windowscroll-fix content-wrapper-h-90-percent'
    mainWrapperClass = "gitcha-main-wrapper pb-90 bg-white windowscroll-fix";
  }





  const handleBackClick = () => {
    const isFromFav = _.get(wantAd, "isFromFavorite", false);
    const isFromMyWantAd = _.get(wantAd, "isFromMyWantAd", false);
    const clientId = _.get(wantAd, "clientId", null);
    const clientName = _.get(wantAd, "clientName", null);
    const favCount = _.get(wantAd, "favCount", null);
    const isFromAgentDashboard = _.get(wantAd, "isFromAgentDashboard", false);
    if (isFromFav) {
      navigate(pageURLs.mobileWantAdFavorites, {
        state: {
          clientName: clientName,
          clientId: clientId,
          favCount: favCount,
        },
      });
    } else if (isFromMyWantAd) {
      navigate(pageURLs.myWantAds);
    } else if (isFromAgentDashboard) {
      navigate(pageURLs.mobileClientWantAd, { state: { clientId: clientId } });
    } else {
      navigate(pageURLs.mobileHome);
    }
  };

  const handleFavoriteOrUnfavoriteWantAd = async (
    IsFavourite,
    Want_ad_Id,
    clientId = []
  ) => {
    if (Want_ad_Id) {
      setReload(false);
      let data = {
        clientId: isFav == false ? clientId : null,
        wantAdId: Want_ad_Id,
      };
      await (IsFavourite == false
        ? favouriteWantAd(data)
        : unFavouriteWantAd(data)
      )
        .then((response) => {
          if (response.errorCode == 0) {
            let message = "";
            if (clientId.length > 0) {
              message =
                "Want-Ad has been favorited for " +
                clientId.length +
                (clientId.length > 1 ? " clients" : " client");
            } else {
              message =
                IsFavourite == true
                  ? "Want-Ad has been removed from your favorites"
                  : "Want-Ad has been added to your favorites";
            }
            setModalShow(false)
            toast.success(message);
            setIsFav(!isFav)
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const onChangeText = (event) => {
    let value = "";
    if (event) {
      value = handleTextBoxChange(event);
    }

    setClientSearch(value);
  };

  const handleFavClick = () => {
    if (isFav) {
      handleFavoriteOrUnfavoriteWantAd(isFav, wantAdId);
    } else {
      if (userType === USER_TYPE_AGENT && isPremiumUser) {
        handleAgentsFavoriteAction();
      } else {
        handleFavoriteOrUnfavoriteWantAd(isFav, wantAdId);
      }
    }
  };

  const handleSkipAll = () => {
    saveTutorialStatusToLocal(tutorialPage)
  }

  function BottomButton() {

    let buttonText = ""
    let onClick = ""
    let isShowButton = false
    if (isArchived === 1) {
      buttonText = "Paused"
      isShowButton = true
    } else if (isEnableIntoduceButton) {
      buttonText = "Introduce Your Property"
      isShowButton = true
      onClick = () => IntroductionOffcanvasShow()
    } else if (isEnableIntoductions) {
      buttonText = "Introductions (" + _.get(adDetails, "introCount", 0) + ")"
      onClick = () => { props.setWantAdAdType(adType); props.setUIKey(VIEW_RECEIVED_INTRODUCTIONS) }
      isShowButton = true
    }
    if (isShowButton) {
      return <div
        className={
          scrollDirection !== SCROLL_DIRECTION_DOWN
            ? "introduce-property-btnwrap-mob brdr-top-common-wrap"
            : "introduce-property-btnwrap-mob brdr-top-common-wrap bottom-0"
        }
      >
        <button
          type="button"
          class="btn btn-primary w-100 mb-0"
          // onClick={()=>{handleWantAdWebClick(wantAdId)}}
          disabled={isArchived}
          onClick={() => onClick()}
        >
          {buttonText}
        </button>
      </div>
    }
    return null
  }

  return (
    <>
      <LearnIntroductionModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
        handleShowTutorial={() => {
          IntroductionOffcanvasShow()
          setTimeout(() => {
            setDisplayTutorial(true)
          }, 500)
        }}
        handleSkipAll={handleSkipAll}
      />
      <span ref={pageTopRef}></span>
      <div className={mainWrapperClass}>
        <div className={contentWrapperClass}>
          <div className="inner-layout-header-new bg-white">
            <div className="top-three-comp-header w-100 top-three-comp-headerborder">
              <div className="top-three-comp-header-inner w-100">
                <a
                  role="button"
                  className="back-link-btn me-4"
                  onClick={() => handleBackClick()}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </a>

                <div>Want-Ad</div>

                <div className="right-mobbtn-wrap">
                  {(WantCreatedUserId === loggedInUserId) &&
                    <a
                      role="button"
                      className="mob-share-btn"
                      href={void 0}
                      onClick={() => setShareModal(true)}
                    >
                    </a>}
                  <div className="social-btns-wrap">
                    <ViewADDropdown
                      isFromHomeFeed={isFromHomeFeed}
                      isFromAllIntroduction={isFromAllIntroduction}
                      WantCreatedUserId={WantCreatedUserId}
                      wantAdId={wantAdId}
                      setShareModal={setShareModal}
                      adDetails={adDetails}
                      setReportModal={setReportModal}
                      setReload={setReload}
                      reload={reload}
                    />
                  </div>

                  {isFav !== "unload" && (WantCreatedUserId !== loggedInUserId) &&
                    <a
                      role="button"
                      className="heart-btn ms-2"
                      href={void 0}
                      onClick={() => handleFavClick()}
                    >
                      {<img src={isFav ? HearttIconFilled : HearttIcon} />}
                    </a>}

                </div>
              </div>
            </div>

          </div>

          <div
            className="home-layout-content h-100 bg-white windowscroll-fix"
          // onScroll={handleScroll}
          // ref={scrollDivRef}
          >
            <div className="premium-content-wrapper pe-3 ps-3 pt-4 bg-white h-100 windowscroll-fix">
              {showStrengthen && (
                <div className="strengthen-wantad-wrap mb-4">
                  <button
                    className="btn strengthen-ad-closebtn"
                    onClick={() => handleStrengthenDismiss()}
                  >
                    close
                  </button>
                  <h5>
                    Get more attention on your ad
                    <span className="ps-2 align-top">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.75 5C3.75 4.30964 4.30964 3.75 5 3.75H6.875C7.56536 3.75 8.125 4.30964 8.125 5V15C8.125 15.6904 7.56536 16.25 6.875 16.25H5C4.30964 16.25 3.75 15.6904 3.75 15V5ZM6.875 5H5V15H6.875V5Z"
                          fill="#1B1C1E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.875 5C11.875 4.30964 12.4346 3.75 13.125 3.75H15C15.6904 3.75 16.25 4.30964 16.25 5V15C16.25 15.6904 15.6904 16.25 15 16.25H13.125C12.4346 16.25 11.875 15.6904 11.875 15V5ZM15 5H13.125V15H15V5Z"
                          fill="#1B1C1E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15 6.25C15 5.90482 15.2798 5.625 15.625 5.625H17.5C17.8315 5.625 18.1495 5.7567 18.3839 5.99112C18.6183 6.22554 18.75 6.54348 18.75 6.875V13.125C18.75 13.4565 18.6183 13.7745 18.3839 14.0089C18.1495 14.2433 17.8315 14.375 17.5 14.375H15.625C15.2798 14.375 15 14.0952 15 13.75C15 13.4048 15.2798 13.125 15.625 13.125H17.5V6.875H15.625C15.2798 6.875 15 6.59518 15 6.25Z"
                          fill="#1B1C1E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.61612 5.99112C1.85054 5.7567 2.16848 5.625 2.5 5.625H4.375C4.72018 5.625 5 5.90482 5 6.25C5 6.59518 4.72018 6.875 4.375 6.875H2.5V13.125H4.375C4.72018 13.125 5 13.4048 5 13.75C5 14.0952 4.72018 14.375 4.375 14.375H2.5C2.16848 14.375 1.85054 14.2433 1.61612 14.0089C1.3817 13.7745 1.25 13.4565 1.25 13.125V6.875C1.25 6.54348 1.3817 6.22554 1.61612 5.99112Z"
                          fill="#1B1C1E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.875 10C6.875 9.65482 7.15482 9.375 7.5 9.375H12.5C12.8452 9.375 13.125 9.65482 13.125 10C13.125 10.3452 12.8452 10.625 12.5 10.625H7.5C7.15482 10.625 6.875 10.3452 6.875 10Z"
                          fill="#1B1C1E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.5 10C17.5 9.65482 17.7798 9.375 18.125 9.375H19.375C19.7202 9.375 20 9.65482 20 10C20 10.3452 19.7202 10.625 19.375 10.625H18.125C17.7798 10.625 17.5 10.3452 17.5 10Z"
                          fill="#1B1C1E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 10C0 9.65482 0.279822 9.375 0.625 9.375H1.875C2.22018 9.375 2.5 9.65482 2.5 10C2.5 10.3452 2.22018 10.625 1.875 10.625H0.625C0.279822 10.625 0 10.3452 0 10Z"
                          fill="#1B1C1E"
                        />
                      </svg>
                    </span>
                  </h5>
                  <p>
                    Adding additional information to your ad gives owners a
                    better idea of what you’re looking for.
                  </p>
                  <button
                    variant=""
                    onClick={() => handleStrengthenClick()}
                    className="btn strengthen-ad-btn"
                  >
                    Strengthen my Ad
                  </button>
                </div>
              )}
              <div className="confirmation-top-sec">
                <div className="long-txt-fix">
                  <div className="confirm-sub-title mb-1">
                    I'm looking to:{" "}
                    <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
                      {" "}
                      {loadAdTitle()}
                    </span>
                  </div>
                  <h2>{_.get(adDetails, "adTitle", "")} </h2>
                  <span className="trailing-txt pb-1">
                    {locationDisplay(adDetails)}
                    {/* Within {_.get(adDetails, "mapRadius", "")} mi of{" "}
                    {_.get(adDetails, "city", "")}
                    {", "}
                    {_.get(adDetails, "state", "")} */}
                  </span>
                </div>
                <div className="property-details-mob f-regular mb-4">
                  {getDescriptionText(adDetails)}
                </div>
              </div>

              <div className="property-listing-ad-mob">
                <div className="property-type-wrap-mob top-pos-0 left-pos-0">
                  <div className="property-tile w-auto">
                    <OverlayTrigger
                      placement="bottom-start"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <div className="property-tile w-auto">
                        {propertyType === IS_LAND ||
                          propertyType === IS_MULTI_FAMILY ? (
                          <img
                            src={getPropertyIcon(propertyType)}
                            alt={getPropertyIcon(propertyType)}
                            className="property-icon-img"
                          />
                        ) : (
                          propertySubTypes &&
                          propertySubTypes.length > 0 &&
                          propertySubTypes.map((subtype) => {
                            return (
                              <img
                                src={getPropertyIcon(subtype)}
                                alt={getPropertyIcon(subtype)}
                                className="property-icon-img"
                              />
                            );
                          })
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="property-img-wrapad-mob h-200">
                  <img
                    src={mapImage + "?" + _.get(adDetails, "lat", null)}
                    alt={mapImage}
                  />
                </div>
              </div>

              <div className="musthave-wrap-mob brdr-top-common-wrap mb-3">
                <h2>Must-haves</h2>
                <div className="row">
                  {loadAdMustHaves()}

                </div>
              </div>
              {isNiceToHaveExist ? (
                <div className="nicetohave-wrap-mob brdr-top-common-wrap mb-4">
                  <h2>Nice-to-haves</h2>
                  {finalNiceToHaveList()}
                </div>
              ) : (
                ""
              )}

              {_.get(adDetails, "adDesc", "") ? (
                <div className="description-wrap-mob brdr-top-common-wrap pb-32">
                  <h2>Description</h2>
                  <p>{_.get(adDetails, "adDesc", "")}</p>
                </div>
              ) : (
                ""
              )}

              <div className="buyer-info-mob brdr-top-common-wrap pb-32">
                <h2>Additional Details</h2>
                <ul>
                  <li>
                    {/* ------------------------- Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}
                    {/* { _.get(adDetails, "isPremiumAgent", "") == true && userType == USER_TYPE_AGENT && isPremiumUser  && WantCreatedUserId !==
                          loggedInUserId ? (
                  <div className="profile-details">
                    <div className="d-flex align-items-center">
                      {_.get(adDetails, 'userImage', '') ?
                        <span className="account-settings-proimg me-2"><img src={_.get(adDetails, 'userImage', '') ?? noImage} /></span>
                        :
                        <div className="profile-placeholder-wrap profile-bg-orange flex-shrink-0">
                          {(_.get(adDetails, 'userFirstName', '') + " " + _.get(adDetails, 'userLastName', '')).replace(/-/g, "").match(/\b(\w)/g)}
                        </div>
                      }

                      <div className="profile-name">
                        <div className="title d-flex align-items-center">
                          <h2 className="mb-0"> {_.get(adDetails, 'userFirstName', '') + " " + _.get(adDetails, 'userLastName', '')}</h2>
                         
                            <span className="pro-blue-badge ms-2">PRO</span>

                        </div>
                        <p className="m-0">Agent @  {_.get(adDetails, "userCompany", "")}</p>
                      </div>
                      
                 
                    <button type="button" className="btn btn-no-bg ms-4" onClick={agentProfileViewModalShow}>View Profile</button>
             
                    </div>


                  </div>
                ) :
                (<> */}
                    {/* ------------------------- /Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}
                    <span className="list-icn-wrap-mob">
                      <img src={ConsumerIconImage} />
                    </span>
                    {_.get(adDetails, "isAgentCreated", "") == 1
                      ? " Represented by Agent"
                      : consumerType} {wantAdCreatedUserPlan===INVESTOR_MEMBER_PLAN ? <span className="pro-gold-badge">Investor</span> : ""}
                    {/* ------------------------- Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}
                    {/* </>
                      )} */}
                    {/* ------------------------- /Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}

                  </li>                  
                  {loadTimeLine()}
                  {loadPreferredLocation()}
                  {loadStrengthenDetails()}
                </ul>
              </div>
              {((adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&
                        _.get(adDetails, "isAgentCreated", "") == 1) && (_.get(adDetails, "compenationType", "")) ? 
                        <div className="buyer-info-mob brdr-top-common-wrap pb-32">
                    <h2>Requested Concessions From Seller</h2>
                    <ul>                      
                      {_.get(adDetails, "compenationType", "") ?(
                          <li>
                            <span className="list-icn-wrap-mob">
                              <img src={FinanceIconImage} />
                            </span>
                            {loadCompensationText(parseInt(_.get(adDetails, "compenationType", "")),_.get(adDetails, "agentCompenation", ""))}                            
                          </li>
                        ) : ""}  
                        {_.get(adDetails, "otherConcessions", "") ?(
                          <li>
                            {_.get(adDetails, "otherConcessions", "")}                         
                          </li>
                        ) : ""}                    
                    </ul>
                  </div> : <></>}
              {((wantAdCreatedUserPlan===INVESTOR_MEMBER_PLAN) && (purchaseType===3)) ? 
              <div className="buyer-info mt-0 pt-0">
                {_.get(adDetails, "investmentId", [])?.length>0 || _.get(adDetails, "capRateId", null) ? <h2>What This Investor is Open to</h2> : ""}
                <ul>
                  {loadInvestmentCriteria(adDetails)}
                  {_.get(adDetails, "capRateId", "") &&
                    <li>
                      <span className="list-icn-wrap">
                        <img src={MinCapIcon} />
                      </span>
                      Minimum cap rate of {_.get(adDetails, "capRateId", "")==15 ? "15+" : _.get(adDetails, "capRateId", "")}%
                    </li>
                  }
                </ul>
              </div> : <></>
             }


              <Button variant="" className="btn round-back-btn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41678 4.49971C8.66086 4.74379 8.66086 5.13951 8.41678 5.38359L4.42539 9.37498H17.0832C17.4283 9.37498 17.7082 9.65481 17.7082 9.99998C17.7082 10.3452 17.4283 10.625 17.0832 10.625H4.42539L8.41678 14.6164C8.66086 14.8605 8.66086 15.2562 8.41678 15.5003C8.1727 15.7443 7.77697 15.7443 7.5329 15.5003L2.47456 10.4419C2.35735 10.3247 2.2915 10.1657 2.2915 9.99998C2.2915 9.83422 2.35735 9.67525 2.47456 9.55804L7.5329 4.49971C7.77697 4.25563 8.1727 4.25563 8.41678 4.49971Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>


        {scrollDirection !== SCROLL_DIRECTION_DOWN && (
          <div className="inner-layout-footer-new inner-layout-footer-topfix">
            <div className="bottomMenu bottom-menu-index">
              <MenuBottomBar scrollDirection={scrollDirection} noScrollBounce={true} />
            </div>
          </div>
        )}

      </div>
      {/* {isEnableIntoduceButton && (
        <div
          className={
            scrollDirection !== SCROLL_DIRECTION_DOWN
              ? "introduce-property-btnwrap-mob brdr-top-common-wrap"
              : "introduce-property-btnwrap-mob brdr-top-common-wrap bottom-0"
          }
        >
          <button
            type="button"
            class="btn btn-primary w-100 mb-0"
            // onClick={()=>{handleWantAdWebClick(wantAdId)}}
            onClick={IntroductionOffcanvasShow}
          >
            Introduce Your Property
          </button>
        </div>
      )}

      {isEnableIntoductions &&  (
        <div
          className={
            scrollDirection !== SCROLL_DIRECTION_DOWN
              ? "introduce-property-btnwrap-mob brdr-top-common-wrap"
              : "introduce-property-btnwrap-mob brdr-top-common-wrap bottom-0"
          }
        >
          <button
            type="button"
            class="btn btn-primary w-100 mb-0"
            // onClick={()=>{handleWantAdWebClick(wantAdId)}}
            // onClick={IntroductionOffcanvasShow}
            onClick={() => {
              props.setUIKey("viewWantAdReceivedIntroductions");
            }}
          >
            Introductions ({_.get(adDetails, "introCount", 0)})
          </button>
        </div>
      )} */}
      <BottomButton />
      {showShareModal && adDetails && <ShareWantAd
        show={showShareModal}
        onHide={handleShareClose}
        adDetails={adDetails}
        AdType={_.get(adDetails, "adType", "") === "BUY" ? 1 : 2}
        isAdTypeIsLand={isAdTypeIsLand}
        adTypeBasedOnProperty={adTypeBasedOnProperty}
      />}

      {/* Introduction */}
      <MakeIntroductionOffcanvas
        show={showIntroductionOffcanvas}
        onHide={IntroductionOffcanvasClose}
        wantAdId={wantAdId}
        showIntroductionPopup={IntroductionOffcanvasShow}
        wantAdDetails={adDetails}
        setUIKey={props.setUIKey}
        introductionSendingStatus={props.showSendIntroPopUp}
        displayTutorial={displayTutorial}
        setDisplayTutorial={setDisplayTutorial}
        isPremiumUser={isPremiumUser}
      />

      <ReportWantAdModal
        show={showReportModal}
        wantAdId={wantAdId}
        onHide={() => handleReportPopup(false)}
      />
      {/* <DeleteWantAdModal
            show={deleteModalShow}
            // wantAdId={wantAdId}
            onHide={() => setDeleteModalShow(false)}
            handleConfirm={() => handleDeleteWantAd()}
      /> */}
      {<ViewAddClientsModal
        show={modalShow}
        showAddToClientWantAdModal={() => setModalShow(true)}
        onHide={() => setModalShow(false)}
        data={results}
        handleSkip={() =>
          handleFavoriteOrUnfavoriteWantAd(isFav, wantAdId)
        }
        handleConfirm={handleClientFavoriteWantAd}
        searchText={clientSearch}
        onChangeText={(e) => onChangeText(e)}
        onAddClient={() => setReloadAgents(!reloadAgents)}
      />}

      <AgentProfileInfoModal
        show={showAgentProfileViewModal}
        onHide={agentProfileViewModalClose}
        userId={_.get(adDetails, 'userId', '')}
        isTitleShow={true}
      />
    </>
  );
};

export default ViewAd;
