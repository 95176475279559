import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReasonNotAFitModal from "./ReasonNotAFitModal";

const NotAFitModal = (props) => {
  const {
    titleText,
    description,
    onHide,
    showNotFirReasnPopup,
    handleNotFitConfirm,
    markFitReasonModalClose,
    reasonPopupTitle,
    reasonPopupDesc,
    introductionId,
    updateToNotFit,
    buttonTitle,
    name
  } = props;

  return (
    <>
    <Modal   show
        {...props} centered className="modalPopupstyle delete-popup not-fit-modal">
        <Modal.Body className="text-center">
        <h3>{titleText}</h3>
        <p>{description}</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={() => handleNotFitConfirm()}>
              {props?.buttonTitle ? props?.buttonTitle : 'Confirm' }</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Cancel</button>
        </div>
        </Modal.Body>
    </Modal>
     <ReasonNotAFitModal
     show={showNotFirReasnPopup}
     onHide={markFitReasonModalClose}
     reasonPopupTitle={reasonPopupTitle}
     reasonPopupDesc ={reasonPopupDesc}
     introductionId = {introductionId}
     updateToNotFit ={updateToNotFit}
     name ={name}
 />
 </>
  );
};
export default NotAFitModal;
