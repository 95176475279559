import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import DbPopupImg from "../../images/dashboard-popup-img.png";


const AgentDashboardModal = (props) => {
  const { show, handleConfirm, title, message, buttonText, onHide } = props;


  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-width-548"
    >

      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={() => {
            onHide()
          }}
        ></button>
        <h3 className="text-center">Use your agent dashboard to organize and manage your client's properties</h3>
        <p className="f-size-16 text-center mb-32">We're here to help make your job easier - and that starts with giving you some tools to keep you organized!</p>
        
        <img className="inner-disp-img mb-32" src={DbPopupImg} />
        
         


        <Button
          variant="primary"
          className="btn w-100" 
          onClick={() => {
            handleConfirm()
          }}  
        >
          Take a look
        </Button>

        <Button
          variant=""
          className="w-100 l-spacing-0 btn-no-bg mt-3" 
          onClick={() => {
            onHide()
          }}  
        >
          Skip tutorial
        </Button>
      </Modal.Body>
      {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
    </Modal>
  );
};
export default AgentDashboardModal;
