/**
 * @file   src\actions\common.js
 * @brief  This file is responsible for redux actions related to master data
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { convertToNumber } from "../helpers/validations";

export const handleTextBoxChange = (e) => {
  return e.target.value;
};

export const handleRadioButonChange = (e) => {
  return e.target.value;
};

export const handleItemClick = (clickedItemId, currentArray) => {
  const clickedId = convertToNumber(clickedItemId);
  let currentSelectedItemArray = [];
  let isItemIncluded = false
  if (_.includes(currentArray, clickedId)) {
    currentSelectedItemArray = currentArray.filter((e) => e !== clickedId);
    isItemIncluded = true
  } else {
    currentSelectedItemArray = [...currentArray, clickedId];
  }
  return {currentSelectedItemArray, isItemIncluded };
};

export const handleClickedOrder = (clickedId, currentArray, order) => {
  const filteredArray = currentArray.filter(function (ca) {
    return ca.id == clickedId;
  });

  if (filteredArray.length) {
    const newArray = currentArray.filter(function (ca) {
      return ca.id != clickedId;
    });
    const updatedArray = [];
    const selectedItemOrder = _.get(filteredArray[0], "order", null);
    newArray.map((ca, index) => {
      const order = _.get(ca, "order");
      const id = _.get(ca, "id");
      if (convertToNumber(order) > convertToNumber(selectedItemOrder)) {
        updatedArray.push({
          id: convertToNumber(id),
          order: convertToNumber(order) - 1,
        });
      } else {
        updatedArray.push({ id: convertToNumber(id), order: convertToNumber(order) });
      }
    });
    return updatedArray;
  } else {
    const currentOrderArray = [
      ...currentArray,
      { id: convertToNumber(clickedId), order: convertToNumber(order) },
    ];
    return currentOrderArray;
  }
};


export const handleClickedOrdertest = (section, itemId, currentArray, order) => {
  const filteredArray = currentArray.filter(function (ca) {
    return ca.id == itemId && section === 'section' ;
  });

  if (filteredArray.length) {
    const newArray = currentArray.filter(function (ca) {
      return ca.id != itemId && section === 'section' ;
    });
    const updatedArray = [];
    const selectedItemOrder = _.get(filteredArray[0], "order", null);
    newArray.map((ca, index) => {
      const order = _.get(ca, "order");
      const id = _.get(ca, "id");
      if (convertToNumber(order) > convertToNumber(selectedItemOrder)) {
        updatedArray.push({
          id: convertToNumber(id),
          order: convertToNumber(order) - 1,
        });
      } else {
        updatedArray.push({ id: convertToNumber(id), order: convertToNumber(order) });
      }
    });
    return updatedArray;
  } else {
    const currentOrderArray = [
      ...currentArray,
      { id: convertToNumber(itemId), order: convertToNumber(order) },
    ];
    return currentOrderArray;
  }
};


export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};