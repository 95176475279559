import React, { useState, useEffect, useRef } from "react";
import mapImg01 from "../images/map.png";
import BottomStepperMob from "../components/BottomStepperMob";
import MobileDropdownSelect from "../components/MobileDropdownSelect";
import {
  displayAd,
  getUserId,
  filterArrayBasedOnKey,
  setLocalStorage,
  getLocalStorage,
  setBodyBackgroundWhite,
  areArraysEqual,
  createS3FolderForBuyersListingService,
  clearLocationStatesExceptLatLng,
  getLocalityFromZipCode,
} from "../../helpers/common";
import { capitalizeString, handleTextBoxChange } from "../../actions/common";
import {
  getGeoLocation,
  checkGitchaAvailable,
} from "../../services/commonServices";
import { getRadiusList } from "../../services/wantAdService";
import {
  MAP_EVENT_LOCATION_CHANGE,
  MAP_EVENT_CLICK,
  MAP_EVENT_MARKER_DRAG,
  DEFAULT_WANT_AD_MAP_ZOOM,
  AGENT_WANTAD_KEY,
  WANT_AD_KEY,
  SHORT_RENT,
  IMG_JPEG_FORMAT,
  WANT_AD_KEY_TEMP,
} from "../../constants/common";
import GeoLocation from "../../components/Common/GeoLocation";
import WantAdGoogleMap from "../../components/MemberWantAd/WantAdGoogleMap";
import { convertToNumber } from "../../helpers/validations";
import { createS3Folder, createPreSignedUrl } from "../../helpers/common";
import { usePrevious } from "../../hooks/usePrevious";
import GitchaNotAvailableModal from "../../components/Common/GitchaNotAvailableModal";
import TextBox from "../../components/Common/TextBox";
import html2canvas from "html2canvas";
import { dataURLToBlob } from "../../helpers/createWantAd";
import { uploadMapImageToS3 } from "../../helpers/S3/uploadToS3";
import ProgressBar from "./ProgressBar";
import ZipCloseIcon from '../../images/zipcode-close-icon.svg'

const AddLocation = (props) => {
  const {
    completedPercentage,
    adType,
    handleFormValues,
    formValues,
    setUIKey,
    setCompletedPercentage,
    isAgent,
    isEdit,

    isFromBuyerListingService
  } = props;

  const location = _.get(formValues, "location", null);
  const lat = _.get(formValues, "lat", null);
  const lng = _.get(formValues, "lng", null);
  const mapZoom = _.get(formValues, "mapZoom", null);
  const mapRadius = _.get(formValues, "mapRadius", null);
  const mapType = _.get(formValues, "mapType", null);
  const city = _.get(formValues, "city", null);
  const state = _.get(formValues, "state", null);
  const county = _.get(formValues, "county", null);
  const isGitchaAvailable = _.get(formValues, "isGitchaAvailable", null);
  const s3Imagefolder = _.get(formValues, "s3ImageFolder", false);
  const neighborHood = _.get(formValues, "neighborHood", null);
  const neighborHoodInSession = _.get(getLocalStorage(WANT_AD_KEY), "neighborHood", null);
  const highSchool = _.get(formValues, "highSchool", null);
  const highSchoolInSession = _.get(getLocalStorage(WANT_AD_KEY), "highSchool", null);
  const session = WANT_AD_KEY_TEMP;
  const targetRef = useRef(null);
  const [disableSave, setDisableSave] = useState(true);
  const [eventType, setEventType] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [showMarker, setShowMarker] = useState(true);
  const [isClickOrDrag, setClickDrag] = useState(false);
  const [radiusList, setRadiusList] = useState([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const prevMapRadius = usePrevious(mapRadius);
  const [gitchaModalShow, setGitchaModal] = useState(false);
  const locationInSession = _.get(getLocalStorage(WANT_AD_KEY), "location", null);
  const radiusInSession = _.get(getLocalStorage(WANT_AD_KEY), "mapRadius", null);

  const zipCodeInSession = _.get(getLocalStorage(WANT_AD_KEY), "zipCode", null);
  const zipCode = _.get(formValues, "zipCode", null);
  const zipIdValues = _.get(formValues, "zipIdValues", null);
  const zipCodeError = _.get(formValues, "zipCodeError", null);

  const [zipCodeArray, setZipCodeArray] = useState([]);
  const [zipCodeEnterdValue, setZipCodeEnterdValue] = useState(null)
  const [showPolygon, setShowPolygon] = useState(zipCode?.length > 0 ? true : false);
  const [zipList, setZipList] = useState([]);
  const [locationError, setLocationError] = useState("");
  const client_Want_ad_Id = _.get(formValues, "client_Want_ad_Id", null);

  useEffect(() => {
    setZipCodeArray(zipCode?.length > 0 ? zipCode : [])
  }, [zipCode]);

  useEffect(() => {
    setBodyBackgroundWhite()
    getRadiusLists();
      if (!s3Imagefolder || isEdit) {
        if (isFromBuyerListingService && client_Want_ad_Id) {
          getFolderNameBuyersLstingService(client_Want_ad_Id)
        }
        else { getFolderName(); }
      }
    
  }, []);

  useEffect(() => {
    if (
      (eventType === MAP_EVENT_LOCATION_CHANGE &&
      isGitchaAvailable &&
      prevMapRadius === mapRadius &&
      !mapRadius) &&
      (zipCode?.length == 0 && !zipCodeEnterdValue)
    ) {
      handleFormValues({ mapRadius: 5, mapZoom: 10 });
    }

    const areEqual = areArraysEqual(zipCode, zipCodeInSession);

    if ((location && location !== "" && mapRadius && county) || (zipCode?.length > 0)) {
      if (!isEdit) { setDisableSave(false); }
      else {
        if (zipCode?.length > 0) {
          if ((areEqual)) {
            setDisableSave(true)
          }
          else {
            setDisableSave(false)
          }
        } else {
          if ((location === locationInSession && mapRadius === radiusInSession)) {
            setDisableSave(true)
          }
          else {
            setDisableSave(false)
          }
        }

      }
      handleFormValues({ invalidLocation: false });
    } else {
      setDisableSave(true);
      handleFormValues({ invalidLocation: true });
    }
  }, [location, mapRadius, city, state, isGitchaAvailable, eventType, zipCode]);

  const getFolderName = async () => {
    const isWantAd = true;
    await createS3Folder(isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };

  const getFolderNameBuyersLstingService = async (wantId) => {
    const isWantAd = true;
    await createS3FolderForBuyersListingService(wantId, isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };

  const getRadiusLists = async () => {
    await getRadiusList().then((data) => {
      const results = _.get(data, "result");
      if (results?.length > 0) {
        setRadiusList(results);
      } else {
      }
    });
  };

  const handleMapRadiusChanges = (e) => {
    const value = _.get(e, "value", "");
    let zoomLvelBasedOnRadius;

    switch (value) {
      case 1:
        zoomLvelBasedOnRadius = 12;
        break;
      case 5:
        zoomLvelBasedOnRadius = 10;
        break;
      case 10:
        zoomLvelBasedOnRadius = 9;
        break;
      case 15:
        zoomLvelBasedOnRadius = 9;
        break;
      case 20:
        zoomLvelBasedOnRadius = 8;
        break;
      case 25:
        zoomLvelBasedOnRadius = 8;
        break;
      case 30:
        zoomLvelBasedOnRadius = 8;
        break;
      case 50:
        zoomLvelBasedOnRadius = 7;

    }
    handleFormValues({
      mapRadius: value,
      mapZoom: zoomLvelBasedOnRadius,
    });
  };

  const handleGitchaAvailableModal = (state) => {
    if(state===false){
      clearLocationStates()
    }
    handleShowMarker(true);
    setGitchaModal(state);
  };
  const HandleMapClickOrDrag = (status) => {
    setClickDrag(status);
  };

  const handleLocTextBoxDisable = (state) => {
    // setDisableSave(false);
  };

  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_WANT_AD_MAP_ZOOM,
  };

  const handleShowMarker = (markerStatus) => {
    setShowMarker(markerStatus);
  };
  const clearAddressList = () => {
    setLocationList([]);
  };

  const clearLocationStates = () => {
    handleFormValues({
      location: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      lat: "",
      lng: "",
      isGitchaAvailable: null,
      forGitchaAvailablePopup : {}
    });
  };

  const handleLocationChange = (e) => {
    clearZipCodeList();
    handleFormValues({
      city: "",
      state: "",
      zip: "",
      county: "",
      mapRadius: "",
      neighborHood: "",
      highSchool: "",
      isGitchaAvailable: false,
      zipCode: [],
      zipIdValues: [],
      zipId:[],
      forGitchaAvailablePopup : {}
    });
    setZipCodeArray([]);
    setShowPolygon(false);
    setZipCodeEnterdValue(null)
    const value = capitalizeString(handleTextBoxChange(e));
    const reqPayload = `address=${value}&components=country:US`;
    if (value.trim() !== "" && value.length > 3) {
      getAddresseDetails({
        reqPayload: reqPayload,
        eventType: MAP_EVENT_LOCATION_CHANGE,
      });
    } else {
      clearAddressList();
    }
    if (value && value.trim() !== "") {
      handleFormValues({ location: value });
    } else {
      clearLocationStates();
    }
  };

  const getAddresseDetails = async ({ eventType, reqPayload }) => {
    setEventType(eventType);
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      let locArray = [];
      if (results?.length > 0) {
        const formatted_address = _.get(results?.[0], "formatted_address", "");
        if (
          eventType === MAP_EVENT_LOCATION_CHANGE &&
          results?.length > 0 &&
          formatted_address !== "United States"
        ) {
          locArray.push(results[0]);          
          setLocationList(locArray);
        }
        const firstLocationResult = _.get(results[0], "geometry.location", "");
        const firstAddressComponent = _.get(
          results[0],
          "address_components",
          ""
        );

        const lat = _.get(firstLocationResult, "lat", "");
        const lng = _.get(firstLocationResult, "lng", "");
        let addressObject = { lat: lat, lng: lng };
        if (
          eventType === MAP_EVENT_CLICK ||
          eventType === MAP_EVENT_MARKER_DRAG
        ) {
          try {
            if (firstAddressComponent && firstAddressComponent.length > 0) {
              const street_no = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "street_number";
              });

              if (street_no.length) {
                addressObject.street_no = _.get(
                  street_no?.[0],
                  "short_name",
                  ""
                );
              }

              const route = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "route";
              });

              if (route.length) {
                addressObject.route = _.get(route?.[0], "short_name", "");
                addressObject.street = _.get(route?.[0], "short_name", "");
              }
              const locality = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "locality";
              });
              if (locality.length) {
                addressObject.city = _.get(locality?.[0], "long_name", "");
              }
              const county = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "administrative_area_level_2";
              });
              if (county.length) {
                addressObject.county = _.get(county?.[0], "short_name", "");
              }

              const state = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "administrative_area_level_1";
              });
              if (state.length) {
                addressObject.state = _.get(state?.[0], "short_name", "");
              }
              const zip = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "postal_code";
              });
              if (zip.length) {
                addressObject.zip = _.get(zip?.[0], "short_name", "");
              }
            }
          } catch { }
          // console.log(addressObject)
          isGitchaAvailableLocation(addressObject);
          const city = _.get(addressObject, "city", null);
          const state = _.get(addressObject, "state", null);
          if (city && state) {
            addressObject.location = `${city}, ${state}`;
          }
          handleFormValues(addressObject);
        } else {
          handleFormValues(addressObject);
        }
      } else {
        const isDisable = true;
        handleFormValues({ invalidLocation: true });
      }
    });
  };

  const checkGitchAvailability = async ({ zip, state, city, county }) => {
    const reqPayLoad = { county: county, state: state };
    return await checkGitchaAvailable(reqPayLoad).then((data) => {
      return data;
    });
  };

  const isGitchaAvailableLocation = async (addressObject) => {
    const state = _.get(addressObject, "state", "");
    const city = _.get(addressObject, "city", "");
    let county = _.get(addressObject, "county", "");

    if (!county) {
      const reqPayload = `address=${city + ", " + state}`;
      await getGeoLocation(reqPayload).then((data) => {
        const results = _.get(data, "results");

        if (results?.length > 0) {
          const firstAddressComponent = _.get(
            results[0],
            "address_components",
            ""
          );

          try {
            if (firstAddressComponent && firstAddressComponent.length > 0) {
              const countyArray = firstAddressComponent.filter(function (ca) {
                return ca.types?.[0] === "administrative_area_level_2";
              });

              if (countyArray.length) {
                county = _.get(countyArray?.[0], "short_name", "");
              }
            }
          } catch { }
          handleFormValues({ county: county });
        }
      });
    }
    if (!county) {
      handleFormValues({ isGitchaAvailable: false });
      handleGitchaAvailableModal(true);
      clearZipCodeList();
      setZipCodeEnterdValue("")
    } else {
      checkGitchAvailability({
        county: county,
        state: state,
      }).then((response) => {
        const isGitchaAvailable = _.get(response, "result", "");
        handleFormValues({ isGitchaAvailable: isGitchaAvailable });
        if (!isGitchaAvailable) {
          handleGitchaAvailableModal(true);
          clearZipCodeList();
          setZipCodeEnterdValue("")
        }
      });
    }
    // else {
    //   checkGitchAvailability({
    //     county: county,
    //     state: state
    //   }).then((response) => {
    //     const isGitchaAvailable = _.get(response, "result", "");
    //     handleFormValues({isGitchaAvailable: isGitchaAvailable})
    //     if (!isGitchaAvailable) {
    //       handleGitchaAvailableModal(true);
    //     }
    //   });
    // }
  };

  const handleItemSelection = async (address) => {
    clearLocationStatesExceptLatLng(handleFormValues);
    handleShowMarker(true);
    HandleMapClickOrDrag(false);
    const formatted_address = _.get(address, "formatted_address", "");
    const firstAddressComponent = _.get(address, "address_components", "");
    const latitude = _.get(address, "geometry.location.lat", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lat", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});
    const longitude = _.get(address, "geometry.location.lng", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lng", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});

    let addressObject = {
      lat: latitude,
      lng: longitude,
      location: formatted_address?.trim(),
      zipCode: [],
      zipIdValues: [],
      zipId:[]
    };
    const zip = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "postal_code";
    });
    if (zip.length) {
      addressObject.zip = _.get(zip?.[0], "short_name", "");
    }
    const locality = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "locality";
    });
    if (locality.length) {
      addressObject.city = _.get(locality?.[0], "long_name", "");
    }
    else{
      await getLocalityFromZipCode(addressObject)
    }

    const state = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_1";
    });
    if (state.length) {
      addressObject.state = _.get(state?.[0], "short_name", "");
    }
    
    const county = firstAddressComponent.filter(function (ca) {
      return ca.types?.[0] === "administrative_area_level_2";
    });
    if (county.length) {
      addressObject.county = _.get(county?.[0], "short_name", "");
    }
    if(addressObject?.city== null || addressObject?.city == ""){
      setLocationError("Please enter a valid city and state. ")
      const isDisable = true;
      setDisableSave(isDisable);
      clearAddressList();
    }
    else{
      setLocationError("")
      isGitchaAvailableLocation(addressObject);
      handleFormValues(addressObject);
      clearAddressList();
    }
  };

  const handleNeighborhoodChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    if (isEdit && (neighborHoodInSession !== value)) {
      setDisableSave(false)
    }
    handleFormValues({ neighborHood: value });
  };
  const handleHighSchoolChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    if (isEdit && (value !== highSchoolInSession)) {
      setDisableSave(false)
    }
    handleFormValues({ highSchool: value });
  };

  const handleMapImageUpload = async (image) => {
    const mapResponse = image; //await createStaticMapImage(lat, lng, mapZoom, isFromWantAd);
    const isWantAd = true;
    const preSignedUrlRes = mapResponse
      ? await createPreSignedUrl(isWantAd, _.get(formValues, "s3ImageFolder", ""))
      : alert("Something went wrong while fetching map image.");
    if (preSignedUrlRes && _.get(preSignedUrlRes, "errorCode") === 0) {
      const preSignedUrlsArray = _.get(preSignedUrlRes, "result", []);
      const preSignedS3Url = preSignedUrlsArray
        ? _.get(preSignedUrlsArray[0], "presignedUrl", "")
        : "";
      const s3FullUrl = await uploadMapImageToS3(preSignedS3Url, mapResponse);
      if (s3FullUrl) {
        const imageUrl = s3FullUrl ? s3FullUrl.split("?")[0] : "";
        handleFormValues({ mapImg: imageUrl });
        setIsImageUploaded(true);
      }
    }
  };

  const handleCaptureMapImage = () => {
    setIsImageUploaded(false);
    const fullScreenDiv = document.querySelector(".gm-fullscreen-control");
    const zoomIcons = document.querySelectorAll(".gmnoprint");
    const buttons = document.querySelectorAll(".gm-style-cc");
    const googleIcon = document.querySelector('img[alt="Google"]');
    const gmStyle = document.querySelector(".gm-style-moc");

    if (gmStyle) {
      gmStyle.style.display = "none";
    }

    if (fullScreenDiv) {
      fullScreenDiv.style.display = "none";
    }

    if (googleIcon) {
      googleIcon.style.display = "none";
    }

    zoomIcons.forEach((element) => {
      element.style.display = "none";
    });

    buttons.forEach((element) => {
      element.style.display = "none";
    });

    if (targetRef.current) {
      html2canvas(targetRef.current, { allowTaint: true, useCORS: true }).then(
        (canvas) => {
          var dataURL = canvas.toDataURL(IMG_JPEG_FORMAT);
          const binaryData = dataURLToBlob(dataURL);
          handleMapImageUpload(binaryData);
        }
      );
    }
  };

  useEffect(() => {
    if (isImageUploaded) {
      if (isAgent) {
        if (completedPercentage > 20) {
          setCompletedPercentage(completedPercentage);
        } else {
          setCompletedPercentage(20);
        }
      } else {
        if (completedPercentage > 25) {
          setCompletedPercentage(completedPercentage);
        } else {
          setCompletedPercentage(25);
        }
      }
      setTimeout(() => {
        setLocalStorage(
          WANT_AD_KEY,
          getLocalStorage(WANT_AD_KEY_TEMP)
        );
        setUIKey(null)
      }, 500)
    }

  }, [isImageUploaded])

  const handleZipCodeChange = (e) => {
    setLocationError("")
    setZipCodeEnterdValue(e.target.value)
    handleFormValues({
      location: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      mapRadius: "",
      // neighborHood: "",
      // highSchool: "",
      isGitchaAvailable: false,
      zipCode: zipCode
    });
    const value = capitalizeString(e.target.value);
    const reqPayload = `address=${value}&components=country:US`;

    if (value.trim() !== "" && value?.length > 4) {
      getZipDetails({ reqPayload: reqPayload });
    } else {
      clearAddressList();
    }
    // if (value && value.trim() !== "") {
    //   setLocation(value);

    // }
    // else {
    //   clearLocationStates();
    // }
  };

  const getZipDetails = async ({ eventType, reqPayload }) => {
    clearZipCodeList();
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");

      if (results?.length > 0) {
        const formatted_address = _.get(results?.[0], 'formatted_address', '')
        // if(results?.length === 1 && formatted_address!== "United States"){
        //   setLocationList(results);
        // }
        const firstLocationResult = _.get(
          results[0],
          "geometry.location",
          ""
        );
        const firstAddressComponent = _.get(
          results[0],
          "address_components",
          ""
        );

        const lat = _.get(firstLocationResult, "lat", "");
        const lng = _.get(firstLocationResult, "lng", "");
        let addressObject = { lat: lat, lng: lng };


        try {

          if (firstAddressComponent && firstAddressComponent.length > 0) {
            const street_no = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "street_number";
            });

            if (street_no.length) {
              addressObject.street_no = _.get(street_no?.[0], "short_name", "");
            }

            const route = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "route";
            });

            if (route.length) {
              addressObject.route = _.get(route?.[0], "short_name", "");
              addressObject.street = _.get(route?.[0], "short_name", "");
            }
            const locality = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "locality";
            });
            if (locality.length) {
              addressObject.city = _.get(locality?.[0], "long_name", "");
            }
            const county = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "administrative_area_level_2";
            });
            if (county.length) {
              addressObject.county = _.get(county?.[0], "short_name", "");
            }

            const state = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "administrative_area_level_1";
            });
            if (state.length) {
              addressObject.state = _.get(state?.[0], "short_name", "");
            }
            const zip = firstAddressComponent.filter(function (ca) {
              return ca.types?.[0] === "postal_code";
            });
            if (zip.length) {
              addressObject.zip = _.get(zip?.[0], "short_name", "");
              results[0].zipCode = _.get(zip?.[0], "short_name", "");
              setZipList(results)
              handleFormValues({ forGitchaAvailablePopup: addressObject });
              isGitchaAvailableLocation(addressObject)
            }
          }
        } catch { }
        // console.log(addressObject)
        // console.log("addressObject", addressObject)
        // isGitchaAvailableLocation(addressObject)
        // const city = _.get(addressObject, 'city', null)
        // const state = _.get(addressObject, 'state', null)
        // if(city && state){
        //   addressObject.location = `${city}, ${state}`;
        // }
        // handleFormValues(addressObject);



      } else {
        const isDisable = true;
        // handleFormValues({ invalidLocation: true })
      }
    });
  };

  const handleZipCodeItemSelection = async (address) => {
    const exist = zipCodeArray.find(
      (loc) =>
        loc?.zipCode == address?.zipCode
    );

    if (!exist) {
      clearZipCodeList();
      setZipCodeArray([...zipCodeArray, { zipCode: address?.zipCode, zipId: null }])
      setZipCodeEnterdValue("")
      const formatted_address = _.get(address, "formatted_address", "");
      const firstAddressComponent = _.get(address, "address_components", "");
      const latitude = _.get(address, "geometry.location.lat", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lat", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});
      const longitude = _.get(address, "geometry.location.lng", ""); // removeFixedDecimalPart({value: _.get(address, "geometry.location.lng", ""), decimalPoint : DECIMAL_POINTS_IN_COORDICATES});

      let addressObject = {
        lat: "",
        lng: "",
        location: "",
        mapRadius: null,
        zipCode: [...zipCodeArray, { zipCode: address?.zipCode, zipId: null }]
      };


      handleFormValues(addressObject);
      clearZipCodeList();
      // getBoundaryCoordinatesOnFocusOut(address?.zipCode);
      setShowPolygon(true)
      setShowMarker(false)
    } else {
      setZipCodeEnterdValue("")
      clearZipCodeList();
    }
  };

  const handleRemoveLocation = (removeLocation) => {
    const someArray = zipIdValues.filter(loc => loc?.zipCode != removeLocation?.zipCode);
    handleFormValues({ zipCode: someArray, zipIdValues: someArray });
    setZipCodeArray(someArray);
  }

  const clearZipCodeList = () => {
    setZipList([]);
    // setZipCodeEnterdValue("")
  };


  return (
    <>
      <div className="gitcha-main-wrapper pb-102 bg-white">
        {!location && !lat && !lng && (
          <GeoLocation handleFormValues={handleFormValues} />
        )}
        <div className="content-wrapper content-wrapper-h-102-percent">
          <ProgressBar
            completedPercentage={completedPercentage}
            setUIKey={setUIKey}
            isEdit={isEdit}
            isFromBuyerListingService={isFromBuyerListingService}
          />
          <div className="inner-layout-content-new h-100 bg-white">
            <div className="select-plan-wrap position-relative">
              <div className="location-header-wrap">
                <div className="layout-content-btm-sec position-static top-three-comp-shadow">
                  <h6 className="">LOCATION</h6>
                  <h2 className="">
                    {isAgent
                      ? "Where does your client want to "
                      : "Where do you want to "}
                    {displayAd(adType)}?
                  </h2>
                </div>
              </div>
              <div className="location-conent-wrap p-3">


                <div className="row">
                  <div className="col-12 mb-3 stack-index-100">
                    <div className=" position-relative location-stack-order">
                      <div className="mob-fld-wrap">
                        <label className="form-label">
                          City and State<span>*</span>
                        </label>
                      </div>
                      <div className="search_field_input position-relative mb-0 input-group d-flex">
                        <input
                          aria-label="Example"
                          aria-describedby="basic-addon3"
                          placeholder="Enter city and state"
                          class="form-control pr-0"
                          value={location ? location : ""}
                          onChange={(e) => {
                            handleLocationChange(e);
                          }}
                        ></input>                        
                        <button
                          type="button"
                          id="button-addon3"
                          class="search-icon"
                        ></button>
                      </div>
                      {locationError && locationError !== '' ? <div  className='mob-error-msg'>
                          <span className='mob-error-msg'>{locationError}</span>
                      </div> : ""}
                      {locationList.length > 0 ? (
                        <div className="location-result-wrap top-75 mx-w-735">
                          <ul>
                            {locationList?.map((address, i) => {
                              return (
                                <li key={i}>
                                  <a
                                    role="button"
                                    href={void 0}
                                    onClick={(e) => {
                                      handleItemSelection(address);
                                    }}
                                  >
                                    {_.get(address, "formatted_address", "")}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className=" position-relative location-stack-order">
                      <label className="form-label">
                        Radius<span>*</span>
                      </label>
                      <MobileDropdownSelect
                        name="colors5"
                        className="mobile-select"
                        isSearchable={false}
                        options={radiusList || []}
                        classNamePrefix="select"
                        placeholder="Select mile radius"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={filterArrayBasedOnKey({
                          list: radiusList,
                          filterKey: "value",
                          value: mapRadius,
                        })}
                        onChange={(e) => handleMapRadiusChanges(e)}
                        styles={{
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                            transform:
                              state.selectProps.menuIsOpen && "rotate(180deg)",
                          }),
                        }}
                        errorClass="error-msg error-msg-height"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="segment-divider mb-3">OR</div>
                  </div>

                  <div className="col-12 mb-3">
                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        ZIP Code&#40;s&#41;<span>*</span>
                      </label>

                      <div className="textbox-wrap pr-42 position-relative mb-1">
                        <div className="search_field_input position-relative mb-0 input-group d-flex">
                          <input
                            aria-label="Example"
                            aria-describedby="basic-addon3"
                            placeholder="34567"
                            pattern="[0-9]*" 
                            class="form-control pr-0"
                            value={zipCodeEnterdValue ? zipCodeEnterdValue : ""}
                            onChange={(e) => {
                              handleZipCodeChange(e);
                            }}
                          ></input>

                          <button
                            type="button"
                            id="button-addon3"
                            class="search-icon"
                          ></button>
                        </div>
                        {zipList?.length > 0 ? (
                          <div className="location-result-wrap">
                            <ul>
                              {zipList?.map((address, i) => {
                                return (
                                  <li key={i}>
                                    <a
                                      role="button"
                                      href={void 0}
                                      onClick={(e) => {
                                        handleZipCodeItemSelection(address);
                                      }}
                                    >
                                      {address.zipCode}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}

                      </div>
                      {zipCodeArray.length > 0 ? (

                        <div className="zipcode-tag-wrap pb-3">
                          {zipCodeArray.map((loc) => {
                            return (
                              <div className="zipcode-tag me-3">
                                <button className="btn zipcode-close-btn me-1" onClick={() => handleRemoveLocation(loc)}>
                                  <img src={ZipCloseIcon} alt={ZipCloseIcon} className="" />
                                </button>
                                {loc?.zipCode}
                              </div>
                            );
                          })}
                        </div>
                      ) : ""}
                    </div>
                  </div>


                  {/* <div className="col-12 mb-3">
                <div className=" position-relative location-stack-order">
                  <div className="mob-fld-wrap">
                    <label className="form-label">
                    ZIP Code&#40;s&#41; Border<span>*</span>
                    </label>
                  </div>
                  <div className="search_field_input position-relative mb-0 input-group d-flex">
                    <input
                      aria-label="Example"
                      aria-describedby="basic-addon3"
                      placeholder="(34567, 37654)"
                      class="form-control pr-0"
                      value={location ? location : ""}
                      onChange={(e) => {
                        handleLocationChange(e);
                      }}
                    ></input>

                    <button
                      type="button"
                      id="button-addon3"
                      class="search-icon"
                    ></button>
                  </div>
                  {locationList.length > 0 ? (
                    <div className="location-result-wrap top-75 mx-w-735">
                      <ul>
                        {locationList?.map((address, i) => {
                          return (
                            <li key={i}>
                              <a
                                role="button"
                                href={void 0}
                                onClick={(e) => {
                                  handleItemSelection(address);
                                }}
                              >
                                {_.get(address, "formatted_address", "")}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}                  
                </div>
              </div> */}
                  <div className="col-12">
                    <div className="title-selection-wrap ">
                      <div className="map-img-wrap-mob text-center">
                        <div className="googlemap-responsive">
                          <div className="map-outer" ref={targetRef}>
                            <WantAdGoogleMap
                              defaultProps={defaultProps}
                              mapHeight="100%"
                              mapWidth="100%"
                              handleFormValues={handleFormValues}
                              mapType={mapType}
                              mapRadius={mapRadius}
                              handleShowMarker={handleShowMarker}
                              HandleMapClickOrDrag={HandleMapClickOrDrag}
                              isClickOrDrag={isClickOrDrag}
                              showMarker={showMarker}
                              getAddresseDetails={getAddresseDetails}
                              showPolygon={showPolygon}
                              zipCode={zipCode}
                              zipIdValues={zipIdValues}
                            />
                          </div>
                        </div>
                        <p className="mb-2 mt-2 f-size-12 f-italics">
                          Drag location pin to your desired area
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mob-fld-wrap position-relative mb-2 location-stack-order">
                      <label className="form-label">Preferred Neighborhood</label>
                      <TextBox
                        type="text"
                        value={neighborHood}
                        onChange={(e) => {
                          handleNeighborhoodChange(e);
                        }}
                        placeholder="Neighborhood"
                      ></TextBox>
                    </div>
                  </div>
                  {adType !== SHORT_RENT && (
                    <div className="col-12">
                      <div className="mob-fld-wrap position-relative mb-2 location-stack-order">
                        <label className="form-label">Preferred High School</label>
                        <TextBox
                          type="text"
                          value={highSchool}
                          onChange={(e) => {
                            handleHighSchoolChange(e);
                          }}
                          placeholder="High School"
                        ></TextBox>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-layout-footer-new stack-index-100">
          <div className="inner-btm-fixed-nav ">
            <div className="btn-wrap text-center pb-40 pt-0">
              <button
                type="button"
                className="btn btn-primary w-100 mb-0"
                disabled={disableSave}
                onClick={() => {
                  handleCaptureMapImage();
                }}
              >
                {isEdit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
        <GitchaNotAvailableModal
          show={gitchaModalShow}
          session={session}
          onHide={() => handleGitchaAvailableModal(false)}
          title={"Gitcha isn't currently available in this location yet."}
          desc={
            "We are diligently working to open up your area! Please type in the cities where you need us, and we'll take it up with the bosses."
          }
          isFromMobile={true}
          isFromBuyersListingService={isFromBuyerListingService}
          clearLocationFields={clearLocationStates}
        />
      </div>
    </>
  );
};

export default AddLocation;
