import React from "react";
import WantAdFeatures from "./WantAdFeatures";


const DraftWantAds = () => {
  return (
   <WantAdFeatures/>
  );
};

export default DraftWantAds;
