import { createSlice } from "@reduxjs/toolkit";
import { fetchGitchaAvailableCities } from "../actions/locations";
const initialState = {
  loading: false,
  availableCities: [],
  error: "",  
};

const locations = createSlice({
  name: "locations",
  initialState,  
  extraReducers: (builder) => {
    builder.addCase(fetchGitchaAvailableCities.pending, (state, action) => {
      state.loading = true;
      state.availableCities = state.availableCities;
      state.error = "";
    });
    builder.addCase(fetchGitchaAvailableCities.fulfilled, (state, action) => {
          state.loading = false;
          state.availableCities =  state.availableCities.concat(action.payload);
          state.error = "";        
      });
    
    builder.addCase(fetchGitchaAvailableCities.rejected, (state, action) => {
      state.loading = false;
      state.availableCities = state.availableCities;
      state.error = action.error.message;
    });    
  },
  
});

export default locations.reducer;
