import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";

const DeletionEmailResentModal = (props) => {
  const {email} = props
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >      
      <Modal.Body className="pt-32 pb-32">
        
        <h2 className="text-center">Email Resent</h2>

        <Row>
          <Col xl={12}>
            <p className="mb-2 text-center">
              We've sent a link to{" "}
              <strong>{email}</strong>{" "}
              where you can confirm email address change.
            </p>
            <p className="text-center">
             Still having trouble?{" "}
              <strong>
                <a href="#">Contact support.</a>
              </strong>
            </p>
          </Col>
        </Row>
      </Modal.Body>      
    </Modal>
  );
};
export default DeletionEmailResentModal;
