import React, { useState, useEffect } from "react";
import MustHaveBtnGroupMob from "../MustHaveBtnGroupMob";
import { handleRadioButonChange } from "../../../actions/common";
import WantAdSquareFeet from "../../commonComponents/WantAdSquareFeet";
import { getLocalStorage, removeDecimalZeros } from "../../../helpers/common";
import { STORY_ANY, STORY_SINGLE, WANT_AD_KEY } from "../../../constants/common";
import { convertToNumber } from "../../../helpers/validations";
const MustHavesPropertiesSTR = (props) => {
  const {
    handleFormValues,
    formValues,
    setDisableSave,
    isEdit
  } = props;

  const noOfBedrooms = _.get(formValues, "noBedRm", null);
  const noOfBedroomsInSession = _.get(getLocalStorage(WANT_AD_KEY), "noBedRm", null);
  const noOfBathrooms = _.get(formValues, "noBathRm", null);
  const noOfBathroomsInSession = _.get(getLocalStorage(WANT_AD_KEY), "noBathRm", null);
  const noOfBeds = _.get(formValues, "noOfBeds", null);
  const noOfBedsInSession = _.get(getLocalStorage(WANT_AD_KEY), "noOfBeds", null);
  const alwPets= _.get(formValues, "alwPets", "");
  const alwPetsInSession= _.get(getLocalStorage(WANT_AD_KEY), "alwPets", "");
  const adaAccess = _.get(formValues, "adaAccess", "");
  const adaAccessInSession = _.get(getLocalStorage(WANT_AD_KEY), "adaAccess", "");

  useEffect(() => {
    
    if(isEdit){
      let isDisable = true; 
      if(noOfBedrooms != noOfBedroomsInSession){
        isDisable = false;
      }
      if(noOfBathrooms != noOfBathroomsInSession){   
        isDisable = false;
      }
      if(noOfBeds != noOfBedsInSession){   
        isDisable = false;
      }     
      if(adaAccess != adaAccessInSession){
        isDisable = false
      }      
      if(alwPets != alwPetsInSession){
        isDisable = false
      }      
      setDisableSave(isDisable);
    }
    else{
      if(noOfBedrooms && noOfBathrooms){
          handleContinueButton();
      }
    }
  }, [noOfBedrooms, noOfBathrooms,  adaAccess, alwPets]);

  const handleContinueButton = () => {
    let isDisable = false;
    if (noOfBedrooms && noOfBathrooms) {
      setDisableSave(isDisable);
    } else {
      isDisable = true;
      setDisableSave(isDisable);
    }
  };
  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
  };

  const handleBedsClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noOfBeds: value });
  };
  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ adaAccess: e.target.checked });
        break;
      case 2:
        handleFormValues({ alwPets: e.target.checked });
        break;
    }
  };

  const loadCheckBoxItems = () => {
    const itemArray = [
        { id: 1, text: "ADA Accessible" },
        { id: 2, text: "Allows pets" },
      ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 1:
          isChecked = adaAccess ;
          break;
        case 2:
          isChecked = alwPets;
          break;
      }
      return (
        <div className="row">
          <div className="col-12">
            <a
              href={void 0}
              className="createAd-content-wrap  formCheckBoxwrap mb-3"
            >
              <label className="form-check-label checkbox-label w-100" key={id}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="chkbox"
                  id={id}
                  checked={isChecked}
                  onChange={(e) => handleCheckBoxItemClick(e)}
                />
                <span className=""> {text}</span>
              </label>
            </a>
          </div>
        </div>
      );
    });

    return List;
  };

  return (
    <>
      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap mb-10">
          <h4>Bedrooms *</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBedrooms}
            items={["Any", "1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBedRoomClick(e)}
          />
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4>Bathrooms *</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBathrooms}
            items={["Any","1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBathRoomClick(e)}
          />
        </div>
      </div>

      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4>Beds</h4>
          <MustHaveBtnGroupMob
            variant="outline-primary"
            value={noOfBeds}
            items={["Any","1+", "2+", "3+", "4+", "5+"]}
            onClick={(e) => handleBedsClick(e)}
          />
        </div>
      </div>
      <div className="stay-timing-wrap mb-4">
        <div className="lp-btn-group-wrap">
          <h4>Additional Requirements</h4>
          {loadCheckBoxItems()}
        </div>
      </div>
    </>
  );
};

export default MustHavesPropertiesSTR;
