/**
 * @file   src\components\Common\GoogleMap.js
 * @brief  This file is responsible for google maps shown in the app 
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useRef } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Circle,
} from "@react-google-maps/api";
import { getGeoLocation } from "../../services/commonServices";
import mapPinIcon from "../../images/map-icon.png";
import {
  INVENTORY_MAP_RADIUS,
  MAP_EVENT_CLICK,
  MAP_EVENT_DRAG,
  MAP_EVENT_MARKER_DRAG,
  MAP_TYPE_ROADMAP
} from "../../constants/common";
import {
  getMeters
} from "../../helpers/common";
import { usePrevious } from "../../hooks/usePrevious";


const GoogleMaps = (props) => {
  const {
    defaultProps,
    mapHeight,
    mapWidth,
    handleFormValues,
    handleLocationFromMap,
    isFromInventory,
    getAddresseDetails,
    handleShowMarker,
    HandleMapClickOrDrag,
    isClickOrDrag,
    clearLocationStates,
    showMarker,
    mapType,
    propertyAddress,
    mapRadius,
    isFromInventoryLongForm = false
  } = props;

  const googleMapApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const mapRef = useRef(null);
  const radius = mapRadius && getMeters(mapRadius); // 10 kilometers

  const { isLoaded } = useJsApiLoader({
    // for checking map script is loaded in the UI.
    id: "google-map-script",
    googleMapsApiKey: googleMapApiKey,
  });

 

  const circleOptions = {
    strokeColor: "#338BA8",
    strokeOpacity: 0,
    strokeWeight: 1,
    fillColor: "#338BA8",
    fillOpacity: 0.35,
  };


  const mapOptions = {
    mapTypeControl: false, // This will specifically hide the map type controls like satellite, road map etc
    streetViewControl: false, // Hide Pegman (Street View icon)
  };

  const polygonCoords = [
    { lat: 19.0759837, lng: 72.8776559 },
    { lat: 19.0759837, lng: 73.9306559 },
    { lat: 19.0859837, lng: 73.9306559 },
    { lat: 19.0859837, lng: 72.8776559 },
  ];

  const containerStyle = {
    width: mapWidth,
    height: mapHeight,
  };


  const restrictedBounds = {
    // For restricting map in the specific country, in this case restrcted only in usa.
    north: -171.791110603,
    south: 18.91619,
    east: -66.96466,
    west: 71.3577635769,
  };

  // This snippets specifically for to pointing the marker exactly in the specific coords when drag or click.
  const previousCenter = usePrevious(defaultProps.center);
  const currentCenter = defaultProps.center;
  let isCenterChanges = false;
  if (!previousCenter || propertyAddress) {
    isCenterChanges = true;
  } else if(isFromInventoryLongForm){
    isCenterChanges = true;
  }else {
    isCenterChanges = !_.isEqual(previousCenter, currentCenter);
  }

  // Handles the map coords changes
  const handleMapCenterChanges = async ({ coords, eventType = false }) => {
    const lat = coords.lat();
    const lng = coords.lng();
    if (eventType === MAP_EVENT_DRAG) {
      handleFormValues({ locAddress: "" });
    } else {
      if (isFromInventory) {
        await getAddresseDetails({
          eventType: eventType,
          reqPayload: `latlng=${lat},${lng}`,
          lat: lat,
          lng: lng,
        });
        const reqPayLoad = {
          latitude: lat,
          longitude: lng,
          radius: INVENTORY_MAP_RADIUS,
          mls_active: true,
        };
      } else {
        await getLocationAddress(lat, lng);
      }
    }

    handleFormValues({ lat: lat, lng: lng });
  };

  //Handle zoom leveles while clicking zoom in/out buttons
  const handleZoomLevel = () => {
    const zoom = mapRef.current.getZoom();
    if(isFromInventory){
      HandleMapClickOrDrag(false);
      handleShowMarker(true);
    }
    const newCenter = mapRef.current.getCenter().toJSON();
    handleFormValues({ mapZoom: zoom });
  };

  //Get address based on the searches
  const getLocationAddress = async (lat, lng) => {
    const reqPayload = `latlng=${lat},${lng}`;
    await getGeoLocation(reqPayload).then((data) => {
      const results = _.get(data, "results");
      if (results?.length > 0) {
        const firstLocationAddress = _.get(results[0], "formatted_address", "");
        if (firstLocationAddress) {
          handleLocationFromMap(firstLocationAddress);
        }
      } else {
        alert("Not data available");
      }
    });
  };

  // Handling cliks on the map for pointing the pin
  const handleMapClick = async (e) => {
    if (isFromInventory) {
      HandleMapClickOrDrag(false);
      handleShowMarker(true);
    }
    handleMapCenterChanges({ coords: e.latLng, eventType: MAP_EVENT_CLICK });
  };

  // Map type click like satellight, road map. Currently these options are hided.
  const handleMapTypeClick = () => {
    const mapRefCurrentExist = _.get(mapRef, "current", null);
    if (mapRefCurrentExist) {
      const mapeTypeId = mapRef.current.getMapTypeId();
      handleFormValues({ mapType: mapeTypeId });
    }
  };

  // Handles marker dragging
  const handleMarkerDragEnd = (e) => {
    HandleMapClickOrDrag(true);
    handleShowMarker(true);
    handleMapCenterChanges({ coords: e.latLng, eventType: MAP_EVENT_MARKER_DRAG });
  };
 
  // Handles map dragging
  const handleMapDragEnd = () => {
    if (isFromInventory) {
      clearLocationStates();
      HandleMapClickOrDrag(false);
      handleShowMarker(false);
    }
    handleMapCenterChanges({
      coords: mapRef.current.getCenter(),
      eventType: MAP_EVENT_DRAG,
    });
  };

  return (
    isLoaded &&
    _.get(defaultProps.center, "lat", "") && (
     
      <GoogleMap
        mapTypeId={mapType || MAP_TYPE_ROADMAP}
        mapContainerStyle={containerStyle}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
        onClick={isFromInventory ? handleMapClick : ""}
        onDragEnd={isFromInventory ? handleMapDragEnd : ""}
        onLoad={(map) => {
          mapRef.current = map;
          mapRef.current.addListener("zoom_changed", handleZoomLevel);
        }}
        mapContainerClassName="wantad-map-wrap overflow-hidden"
        options={mapOptions}
      >
        {isFromInventory && showMarker ? (
          isClickOrDrag ? (
            isCenterChanges ? (
              <Marker
                position={defaultProps.center}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
                icon={{
                  url: mapPinIcon,
                }}
              />
            ) : (
              ""
            )
          ) : (
            <Marker
              position={defaultProps.center}
              draggable={true}
              onDragEnd={handleMarkerDragEnd}
              fillColor="#000000"
              icon={{
                url: mapPinIcon,
              }}
            />
          )
        ) : !isFromInventory && mapRadius && <> <Circle
        center={defaultProps.center}
        radius={radius}
        options={circleOptions}
       
      />
      <Marker
      position={defaultProps.center}
      draggable={false}
    
      icon={{
        url: mapPinIcon,
      }}
    />
     </>  }
      </GoogleMap>
     
    )
  );
};
export default GoogleMaps;