import React, { useState, useEffect } from "react";
import noImage from "../../images/no-image-placeholder.png";
import inValidImage from "../../images/invalid-image-placeholder.png"
import FsLightbox from 'fslightbox-react';

const PhotoGallery = (props) => {
    const {
        photos,
        className = null,
        isFromDetailsPage,
        isFromConfirmationPage
    } = props;

    const photosCount = photos.length;
    const [toggler, setToggler] = useState(false);
    const [photoArray, setPhotoArray] = useState([]);


    useEffect(() => {
        if (photosCount > 0) {
            var newArray = []
            photos.map((photo) => {
                if(isFromDetailsPage){
                    const isValid = _.get(photo, 'IsValid', null)
                    if(isValid){
                        newArray.push(photo.image)
                    } else{
                        newArray.push(inValidImage)
                    }
                } else if(isFromConfirmationPage){
                    const isValid = _.get(photo, 'IsValid', null)
                    if(isValid == 0){
                        newArray.push(inValidImage)
                       
                    } else{
                        newArray.push(photo.image)
                    }
                } else{
                    newArray.push(photo.image)
                }
            })
            setPhotoArray(newArray)
        }
    }, [photos])

    const loadPhotos = (photos, className) => {
        var dom_content = [];
        if (photos.length === 0) {
            return (
                <div className={className || "inventory-banner position-relative mt-30"}>
                    <div class="banner-row">
                        {" "}
                        <div className="banner-column-full w-100 h-204">
                            <div className="full-col-img-wrap h-100 placeholder-wrap-bg brdr-8">
                                <img
                                    className="brdr-8 mw-100 placeholder-img-style"
                                    src={noImage}
                                    alt={noImage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (photosCount > 0) {
            if (photosCount === 1) {
                dom_content.push(
                    <div className="banner-column-full h-204">
                        <div className="full-col-img-wrap h-204">
                            <img
                                className="brdr-8 w-100 col-img-style"
                                src={photos[0]}
                                alt={photos[0]}
                                onClick={() => setToggler(!toggler)}
                            />
                        </div>
                    </div>
                );
            }
            if (photosCount === 2) {
                dom_content.push(
                    <>
                        <div className="banner-column-half h-204">
                            <div className="half-col-img-wrap h-204">
                                <img
                                    className="brdr-top-left-8 brdr-btm-left-8 brdr-t-r-8 no-brdr-b-l-0 col-img-style"
                                    src={photos[0]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                        <div className="banner-column-half h-204">
                            <div className="half-col-img-wrap-right h-204">
                                <img
                                    className="brdr-top-right-8 brdr-btm-right-8 no-brdr-t-r-0 brdr-b-r-8 brdr-b-l-8 col-img-style"
                                    src={photos[1]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                    </>
                );
            }

            if (photosCount === 3) {
                dom_content.push(
                    <>
                        <div className="banner-column-twothird h-204">
                            <div className="half-col-img-wrap h-204">
                                <img
                                    className="brdr-top-left-8 brdr-btm-left-8 brdr-t-r-8 no-brdr-b-l-0 col-img-style"
                                    src={photos[0]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                        <div className="banner-column-onethird h-204">
                            <div className="quarter-col-img-wrap-top h-50-percent rh-204">
                                <img
                                    className="brdr-top-right-8 no-brdr-t-r-0 col-img-style"
                                    src={photos[1]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                            <div className="quarter-col-img-wrap-btm h-50-percent rh-204">
                                <img
                                    className="brdr-btm-right-8 brdr-b-l-8 col-img-style"
                                    src={photos[2]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                    </>
                );
            }
            if (photosCount === 4) {
                dom_content.push(
                    <>
                        <div className="banner-column-half h-204">
                            <div className="half-col-img-wrap h-204">
                                <img
                                    src={photos[0]}
                                    className="brdr-top-left-8 brdr-btm-left-8 brdr-t-r-8 no-brdr-b-l-0 col-img-style"
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>

                        <div className="banner-column-30percent h-204">
                            <div className="percent30-col-img-wrap h-204">
                                <img
                                    src={photos[1]}
                                    className="col-img-style"
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                        <div className="banner-column-20percent h-204">
                            <div className="quarter-col-img-wrap-top h-50-percent rh-204">
                                <img
                                    src={photos[2]}
                                    className="brdr-top-right-8 no-brdr-t-r-0 col-img-style"
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>

                            <div className="quarter-col-img-wrap-btm h-50-percent rh-204">
                                <img
                                    src={photos[3]}
                                    className="brdr-btm-right-8 brdr-b-l-8 col-img-style"
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                    </>
                );
            }
            if (photosCount >= 5) {
                dom_content.push(
                    <>
                        <div className="banner-column-half h-204">
                            <div className="half-col-img-wrap h-204">
                                <img
                                    className="brdr-top-left-8 brdr-btm-left-8 brdr-t-r-8 no-brdr-b-l-0 col-img-style"
                                    src={photos[0]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>

                        <div className="banner-column-quarter h-204">
                            <div className="quarter-col-img-wrap-top h-50-percent rh-204">
                                <img
                                    className="col-img-style"
                                    src={photos[1]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                            <div className="quarter-col-img-wrap-btm h-50-percent rh-204">
                                <img
                                    className="col-img-style"
                                    src={photos[2]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>

                        <div className="banner-column-quarter h-204">
                            <div className="quarter-col-img-wrap-top h-50-percent rh-204">
                                <img
                                    className="brdr-top-right-8 no-brdr-t-r-0 col-img-style"
                                    src={photos[3]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                            <div className="quarter-col-img-wrap-btm h-50-percent rh-204 col-pad-btm">
                                <img
                                    className="brdr-btm-right-8 brdr-b-l-8 col-img-style"
                                    src={photos[4]}
                                    onClick={() => setToggler(!toggler)}
                                />
                            </div>
                        </div>
                    </>
                );
            }

            return (
                <div className={className || "inventory-banner position-relative mt-30"}>
                    {photosCount > 5 ? (
                        <button className="upload-cover-btn upload-cover-btn-pos" onClick={() => setToggler(!toggler)}>
                            +{photosCount - 5}
                        </button>
                    ) : (
                        ""
                    )}
                    <div class="banner-row">{dom_content}</div>
                </div>
            );
        } else {
            return "";
        }
    };

    const loadFullViewImageArray = () => {
        var fullViewImageArray = [];
        photoArray.map((photo) => {
            fullViewImageArray.push(<img src={photo} />)
        })
        return fullViewImageArray;
    }

    return (
        <>{loadPhotos(photoArray, className)}
            <FsLightbox
                toggler={toggler}
                sources={loadFullViewImageArray()}
            />
        </>
    )

};

export default PhotoGallery;
