import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";

import FldCloseIcon from "../../images/round-close-icon.svg";

import ValidationAlertIcon from "../../images/validation-alert-icon.svg";
import ConfettiColoredIcon from "../../images/confetti-colored-icon.svg";
import {
  BOTH_TITLE,
  PROPERTY_MANAGER,
  REAL_ESTATE_AGENT,
} from "../../../constants/common";
import { USER_TYPE } from "../../../constants/onBoarding";
const AgentTitle = (props) => {
  const {
    handleBackButtonClick,
    handleAgentTitleContinueClick,
    setTitle,
    title,
  } = props;
  console.log(`#####title`, title)
  return (
    <div className="gitcha-main-wrapper pb-0">
    <div className="content-wrapper vh-100">
      <div className="inner-layout-header-new bg-transparent">
        <div className="review-plan-header w-100">
          <div className="signup-login-header-top signup-header-txt clearfix">
            <a
              className="back-link"
              onClick={() => handleBackButtonClick({ prevPage: USER_TYPE })}
              role="button"
              href={void 0}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                  fill="#1B1C1E"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="inner-layout-content-new h-100">
        <div className="get-agent-wrap clearfix">
          <div className="confetti-colored-icon mt-5 mx-auto">
            <img src={ConfettiColoredIcon} />
          </div>
          <h2 className="pt-2 pb-2 text-center">Let’s create your profile!</h2>
          <p className="text-center mb-18">
          We’re here to help meet you and your client’s real estate goals. Let’s set up your profile.
          </p>
          <div className="title-selection-wrap ">
            <p className="mb-3">What’s your current title?</p>

            <a
              role="button"
              href={void 0}
              className={
                title === REAL_ESTATE_AGENT
                  ? "title-selection-innner mb-3 active"
                  : "title-selection-innner mb-3"
              }
              onClick={() => setTitle(REAL_ESTATE_AGENT)}
            >
              Real Estate Agent
            </a>

            <a
              role="button"
              href={void 0}
              className={
                title === PROPERTY_MANAGER
                  ? "title-selection-innner mb-3 active"
                  : "title-selection-innner mb-3"
              }
              onClick={() => setTitle(PROPERTY_MANAGER)}
            >
              Property Manager
            </a>

            <a
              role="button"
              href={void 0}
              className={
                title === BOTH_TITLE
                  ? "title-selection-innner mb-3 active"
                  : "title-selection-innner mb-3"
              }
              onClick={() => setTitle(BOTH_TITLE)}
            >
              Both
            </a>
          </div>
          <Button
            variant="primary"
            className="btn w-100 mb-4"
            disabled={title ? false : true}
            onClick={() => handleAgentTitleContinueClick()}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AgentTitle;
