/**
 * @file   src\pages\AllIntroductions.js
 * @brief  This file is responsible for handling edit want-ad.
 * @date   June, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import CommonHeader from "../components/Onboarding/CommonHeader";
import DeclineIntroductionModal from "../components/Introduction/DeclineIntroductionModal";
import ViewIntroductionModal from "../components/Introduction/ViewIntroductionModal";
import { getLocalStorage, setLocalStorage } from "../helpers/common";
import {
  INTRODUCTION_ACCEPT,
  INTRODUCTION_DECLINE,
  INTRODUCTION_DECLINE_UNDO,
  INTRODUCTION_NOT_INTERESTED,
  USER_INFO_KEY,
  WANT_AD_KEY,
  INTRODUCTION_NOT_A_FIT,
  INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST,
  INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST
} from "../constants/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import {
  getIntroductions,
  getAcceptedIntroductions,
  getIntroductionDetaisById,
  updateIntroNotFit,
} from "../services/introductionService";
import AcceptedIntroductionList from "../components/Introduction/AcceptedIntroductionList";
import PendingIntroductionList from "../components/Introduction/PendingIntroductionList";
import { usePrevious } from "../hooks/usePrevious";
import {
  updateIntroductionStatus,
  undoDeclinedIntroduction,
} from "../services/introductionService";
import { toast } from "react-toastify";
import { updateIntroduction } from "../helpers/message";
import MarkFitModal from "../components/Introduction/MarkFitModal";

const AllIntroductions = (props) => {
  const navigate = useNavigate();
  const [introductionId, setIntroId] = useState("");
  const [showMarkFitModal, setMarkFitModal] = useState(false);
  const markFitModalClose = () => setMarkFitModal(false);
  const [markNotFitStatus, setMarkNotFitStatus] = useState([]);

  const [showNotFirReasnPopup, setNotFitReasonPopup] = useState(false);

  const markFitReasonModalClose = () => setNotFitReasonPopup(false);

  const [showViewIntroductionModal, setViewIntroductionModal] = useState(false);
  const handleIntroDetailModal = (modalState) =>
    setViewIntroductionModal(modalState);
  const ViewIntroductionModalClose = () => setViewIntroductionModal(false);
  const ViewIntroductionModalShow = (introId) => {
    getIntroductionDetails(introId);
    setViewIntroductionModal(true);
  };

  const [pendingList, setPendingList] = useState([]);
  const [acceptedList, setAcceptedList] = useState([]);
  const [pendingListPage, setPendingListPage] = useState(1);
  const [introDetails, setIntroDetails] = useState([]);
  const [acceptedListPage, setAcceptedListPage] = useState(1);
  const [pendingListCount, setPendingCount] = useState(0);
  const [acceptedListCount, setAcceptedCount] = useState(0);
  const [statusChange, setStatusChange] = useState("");
  const [titleText, setTitleText] = useState("");
  const pendingScrollSection = "pending";
  const acceptedScrollSection = "accepted";

  const INTRO_LIST_PAGE_LIMIT = 4;

  const [userFirstName, setUserFirstName] = useState(null);

  const updateIntroPageOnScroll = (section) => {
    if (section === pendingScrollSection) {
      setPendingListPage((prevState) => prevState + 1);
    } else if (section === acceptedScrollSection) {
      setAcceptedListPage((prevState) => prevState + 1);
    }
  };



  const getIntroductionDetails = async (introId) => {
    handleIntroDetailModal(true);
    if (introId) {
      await getIntroductionDetaisById(introId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setIntroDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  // const toastCustomMessage = (introId) => {
  //   return (
  //     <div className="d-flex align-items-center justify-content-between">
  //       <span className="d-flex flex-grow-1">Introduction declined</span>

  //       <button
  //         variant=""
  //         className="btn-primary text-nowrap me-2"
  //         onClick={() => {
  //           undoDeclinedIntro(introId);
  //         }}
  //       >
  //         {" "}
  //         Undo
  //       </button>
  //     </div>
  //   );
  // };

  // const undoDeclinedIntro = async (introId) => {
  //   const reqPayload = {
  //     userId: _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", ""),
  //     introductionId: introId,
  //   };
  //   if (introId) {
  //     await undoDeclinedIntroduction(reqPayload)
  //       .then(async (response) => {
  //         if (response.errorCode === 0) {
  //           let data = {
  //             isdeleted: false,
  //           }

  //           let updateresponse = await updateIntroduction(introId, data);

  //           //  let response1 = await deleteMessage(introductionId);
  //           // if (updateresponse == 1) {
  //             toast.success("Introduction successfully revert back to pending.");
  //             setPendingList([]);
  //             setStatusChange(INTRODUCTION_DECLINE_UNDO);
  //           // }
  //         } else {
  //           console.log("Something went wrong");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("Error", err);
  //       });
  //   }
  // };



  // const updateIntroStatus = async (status, introId, fullName) => {
  //   const introdctionId = introductionId || introId;
  //   const reqPayload = {
  //     userId: _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", ""),
  //     introductionId: introdctionId,
  //     status: status,
  //   };

  //   if (introdctionId) {
  //     await updateIntroductionStatus(reqPayload)
  //       .then(async (response) => {
  //         if (response.errorCode === 0) {
  //           let data = {
  //             isdeleted: true,
  //           }

  //           let updateresponse = await updateIntroduction(introId, data);

  //           //  let response1 = await deleteMessage(introductionId);
  //           // if (updateresponse == 1) {
  //             if (status === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST || 
  //               status === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST) {
  //               // declineIntroductionModalClose();
  //               toast.success("Mark not a fit was successfully submitted.");
  //               // toast.success(toastCustomMessage(introdctionId));
  //               //   setPendingList((current) =>
  //               //     current.filter((lst) => lst.IntroductionId !== introductionId)
  //               //   );
  //               status === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST && setPendingList([]);
  //               status === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST && setAcceptedList([]);
  //               setStatusChange(markNotFitStatus);
  //               setIntroId("");
  //               setMarkFitModal(false);
  //             } 
  //             // else if (status === INTRODUCTION_NOT_A_FIT) {
  //             //   setAcceptedList([]);
  //             //   setStatusChange(INTRODUCTION_NOT_INTERESTED);
  //             // }
  //           // }
  //         } else {
  //           console.log("Something went wrong");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("Error", err);
  //       });
  //   }
  // };

  const markNotFitModelShow = (introId, status, firstName = null) => {
    setIntroId(introId);
    setMarkNotFitStatus(status);
    setMarkFitModal(true);
    setUserFirstName(firstName)
  };

  const prevPending = usePrevious(pendingListPage);
  const prevAccepted = usePrevious(acceptedListPage);

  useEffect(() => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const wantAdId = _.get(wantAd, "wantAdId", null);
    if (!wantAdId) {
      navigate(pageURLs.home);
    }
    const userId = _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", "");
    if (statusChange === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST) {
      setStatusChange("");
      setPendingListPage(1);
    } else if (statusChange === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST) {
      setStatusChange("");
      setAcceptedListPage(1);
    } else if (statusChange === INTRODUCTION_ACCEPT) {
      setStatusChange("");
      setAcceptedListPage(1);
      setPendingListPage(1);
    }
    if (
      (!prevPending && !prevAccepted) ||
      statusChange === INTRODUCTION_ACCEPT
    ) {
      getPendingIntroductions(pendingListPage, wantAdId, userId);
      getAcceptdIntroductions(acceptedListPage, wantAdId, userId);
    } else if (
      prevPending !== pendingListPage ||
      statusChange === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST
      // ||statusChange === INTRODUCTION_DECLINE_UNDO
    ) {
      getPendingIntroductions(pendingListPage, wantAdId, userId);
    } else if (
      prevAccepted !== acceptedListPage ||
      statusChange === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST
    ) {
      getAcceptdIntroductions(acceptedListPage, wantAdId, userId);
    }
  }, [pendingListPage, acceptedListPage, statusChange]);

  const getPendingIntroductions = async (page, wantAdId, userId) => {
    const reqPayload = {
      wantAdId: wantAdId,
      page: page,
      limit: INTRO_LIST_PAGE_LIMIT,
      isAccept: "0",
    };
    await getIntroductions(reqPayload).then((response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        const count = _.get(response, "result.Count", "");
        if (response.errorCode === 0) {
          setPendingList([...pendingList, ...list]);
          setPendingCount(count);
        } else {
          console.log(`####error`);
        }
      }
    });
  };

  const getAcceptdIntroductions = async (page, wantAdId, userId) => {
    const reqPayload = {
      wantAdId: wantAdId,
      page: page,
      limit: INTRO_LIST_PAGE_LIMIT,
    };
    await getAcceptedIntroductions(reqPayload).then(async (response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        const count = _.get(response, "result.Count", "");
        const titleText = _.get(response, "result.TitleText", "");
        if (response.errorCode === 0) {
          setAcceptedList([...acceptedList, ...list]);
          setAcceptedCount(count);
          setTitleText(titleText);
        } else {
          console.log(`####error`);
        }
      }
    });
  };


  const handleNotFitConfirm = () => {
    markFitModalClose()
    setNotFitReasonPopup(true)
  }

  const updateToNotFit = async (reqPayload, isSkip) => {
    if (reqPayload) {
      await updateIntroNotFit(reqPayload)
        .then(async (response) => {
          if (response.errorCode === 0) {
            let data = {
              isdeleted: true,
            }

            let updateresponse = await updateIntroduction(reqPayload?.IntroductionId, data);
            setNotFitReasonPopup(false);
            toast.success("Mark not a fit was successfully submitted.");
            markNotFitStatus === INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST && setPendingList([]);
            markNotFitStatus === INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST && setAcceptedList([]);
            setStatusChange(markNotFitStatus);
            setIntroId("");

          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
        <CommonHeader />
        <div className="row">
          <div className="col-md-12">
            <div className="edit-want-ad-wrap introduction-wrap mw-697">
              <a
                role="button"
                className="bottom-back-btn"
                href={void 0}
                onClick={() => {
                  setLocalStorage(WANT_AD_KEY, { ...getLocalStorage(WANT_AD_KEY), isFromHomeFeed: true })
                  navigate(pageURLs.wantAdDetails)
                }}
              >
                <span class="left-arrow">
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.10033 0.399663C8.39322 0.692556 8.39322 1.16743 8.10033 1.46032L3.31066 6.24999H18.5C18.9142 6.24999 19.25 6.58578 19.25 6.99999C19.25 7.41421 18.9142 7.74999 18.5 7.74999H3.31066L8.10033 12.5397C8.39322 12.8326 8.39322 13.3074 8.10033 13.6003C7.80744 13.8932 7.33256 13.8932 7.03967 13.6003L0.96967 7.53032C0.829018 7.38967 0.75 7.19891 0.75 6.99999C0.75 6.80108 0.829018 6.61031 0.96967 6.46966L7.03967 0.399663C7.33256 0.106769 7.80744 0.106769 8.10033 0.399663Z"
                      fill="#1B1C1E"
                    />
                  </svg>
                </span>
              </a>
              <h3>
                All introductions – <span>{titleText}</span>
              </h3>
              <div className="payments-wrap intro-content-block">
                <PendingIntroductionList
                  list={pendingList}
                  count={pendingListCount}
                  updateIntroPageOnScroll={updateIntroPageOnScroll}
                  markNotFitModelShow={
                    (introdctionId, INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST) => {
                      markNotFitModelShow(introdctionId, INTRODUCTION_NOT_A_FIT_FROM_PENDING_LIST)
                    }}
                  ViewIntroductionModalShow={ViewIntroductionModalShow}
                />
                <AcceptedIntroductionList
                  list={acceptedList}
                  count={acceptedListCount}
                  updateIntroPageOnScroll={updateIntroPageOnScroll}
                  markNotFitModelShow={
                    (introdctionId, INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST, firstName) => {
                      markNotFitModelShow(introdctionId, INTRODUCTION_NOT_A_FIT_FROM_ACCEPTED_LIST, firstName)
                    }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DeclineIntroductionModal
        show={showDeclineIntroductionModal}
        onHide={declineIntroductionModalClose}
        updateIntroStatus={updateIntroStatus}
      /> */}

      {introDetails.length > 0 && (
        <ViewIntroductionModal
          details={introDetails?.[0]}
          show={showViewIntroductionModal}
          onHide={handleIntroDetailModal}
        // updateIntroStatus={updateIntroStatus}
        />
      )}


      <MarkFitModal
        show={showMarkFitModal}
        onHide={markFitModalClose}
        titleText="Are you sure you want to mark this introduction as not fit?"
        description="This action will end the introduction and associated conversation. Once you delete this it cannot be undone."
        buttonTitle="Confirm"
        updateToNotFit={updateToNotFit}
        introductionId={introductionId}
        showNotFirReasnPopup={showNotFirReasnPopup}
        // handleNotFitConfirm={()=>{
        //  updateIntroStatus(markNotFitStatus)
        // }}
        handleNotFitConfirm={handleNotFitConfirm}
        markFitReasonModalClose={markFitReasonModalClose}
      name={userFirstName}
      />
    </>
  );
};

export default AllIntroductions;
