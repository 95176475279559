/**
 * @file   Source/gitcha_Web/src/mobile/pages/UserRegister.js
 * @brief  This file is responsible for handling user login, sign up and subscription process in responsive view
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CreateAccount from "../components/Onboarding/CreateAccount";
import UserType from "../components/Onboarding/UserType";
import AgentTitle from "../components/Onboarding/AgentTitle";
import MemberPlan from "../components/Common/UserPlan/MemberPlan";
import AgentPlan from "../components/Common/UserPlan/AgentPlan";
import ReviewPlan from "../components/Common/UserPlan/ReviewPlan";
import MemberProfile from "../components/Onboarding/MemberProfile";
import AgentProfile from "../components/Onboarding/AgentProfile";
import UserPreference from "../components/Onboarding/UserPreference";
import PaymentMethods from "../components/Common/UserPlan/PaymentMethods";
import PaymentComponent from "../components/Settings/PaymentComponent";
import CheckDeleteAccountModal from "../components/CheckDeleteAccountModal";
import Home from "./Home";
import {
  PROPERTY_AD,
  USER_INFO_KEY,
  USER_TYPE_AGENT,
  USER_TYPE_MEMBER,
  WANT_AD,
} from "../../constants/common";
import { convertToNumber } from "../../helpers/validations";
import { checkTutorialDisplayStatus, decryption } from "../../helpers/common";
import {
  ADD_PAY_METHOD,
  AGENT_PLAN,
  AGENT_PROFILE,
  AGENT_TITLE,
  EDIT_PAYMENT,
  EMAIL_VERIFICATION,
  HOME,
  LOGIN,
  MEMBER_PLAN,
  MEMBER_PROFILE,
  REVIEW_PLAN,
  SIGNUP,
  USER_PREFERENCE,
  USER_TYPE,
  DELETE_ACCOUNT,
} from "../../constants/onBoarding";
import { toast } from "react-toastify";
import {
  getLocalStorage,
  getUserId,
  setLocalStorage,
} from "../../helpers/common";
import { pageURLs } from "../../constants/pageURLs";
import { useGoogleLogin } from "@react-oauth/google";
import {
  saveAgentInfo,
  saveUserType_Title,
  userEmailVerification,
} from "../../services/signupService";
import {
  getGoogleUserInfo,
  saveGoogleLoginUser,
} from "../../services/loginService";
import userProfile from "../../appState/userProfile";
import { changeUserName } from "../../services/userprofileService";
import _ from "lodash";
import AddPaymentMode from "../components/Common/UserPlan/AddPaymentMode";

const UserRegister = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [activePage, setActivePage] = useState();
  const [userType, setUserType] = useState(null);
  const [title, setTitle] = useState(null);
  const [userPlanDetails, setUserPlanDetails] = useState(null);
  const [upComingPlanDetails, setupComingPlanDetails] = useState(null)
  const userDetailsFromStore = userProfile();
  const state = location?.state;
  const isFromSettings = state && state?.isFromSettings;
  const UpgradeToAgent = state && state?.UpgradeToAgent;
  const isEditPlan = state && state?.isEditPlan;
  const [reviewPlanDetails, setReviewPlan] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const deleteAccount = state && state?.deleteAccount;
  const [checkAccountDeletePopup, setCheckAccountDeletePopup] = useState(false);
  const [stateValue, setStateValue] = useState(state);
  const [currentActivePlan, setCurrentActivePlan] = useState(null);
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  useEffect(() => {
    if (userDetailsFromStore !== null && userDetailsFromStore?.length > 0) {
      setUserPlanDetails(userDetailsFromStore[0]);
      if(userDetailsFromStore?.length>1) {
        setupComingPlanDetails(userDetailsFromStore[userDetailsFromStore?.length-1])
      }
      else{
        setupComingPlanDetails(null)
      }
      const planCurrent = userDetailsFromStore.filter((pl)=>pl?.Is_Upcoming===0 && pl?.IsActive === 1)
      setCurrentActivePlan(planCurrent[0])
    } else {
      setUserPlanDetails(null);
    }
  }, [userDetailsFromStore]);



  useEffect(() => {
    const userId = searchParams.get("Id");
    const gCode = searchParams.get("g_code");
    const action = searchParams.get("action");
    if (
      action &&
      convertToNumber(action) === EMAIL_VERIFICATION &&
      userId &&
      gCode
    ) {
      const decryptedKey = decryption(userId);
      if (decryptedKey) {
        const reqPayload = {
          action: action,
          token: gCode,
          userId: decryptedKey,
          isEmailVerified: 1,
        };
        emailVerification(reqPayload);
      }
    } else {
      handleActivePage();
    }
  }, []);

  useEffect(() => {
    if (activePage === REVIEW_PLAN && isFromSettings && isEditPlan) {
      const planDetails = {
        period: _.get(upComingPlanDetails, "period", null) || _.get(userPlanDetails, "period", null),
        selectedPlan: _.get(upComingPlanDetails, "PlanName", null) || _.get(userPlanDetails, "PlanName", null),
        payModeId : _.get(userPlanDetails, "Payment_Mode_Id", null),
        fromSignUp: false,
        enablePromocode: false,
        enablePromocodePeriod: false,
      };
      setReviewPlan(planDetails);
    }
  }, [userPlanDetails, upComingPlanDetails]);

  const handleUserTypeSelection = (userType) => {
    setUserType(userType);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      if (_.get(tokenResponse, "access_token", null)) {
        const token = {
          accessToken: tokenResponse.access_token,
          googleLogin: true,
        };
        setLocalStorage(USER_INFO_KEY, { token: token });
        handleGoogleLogin();
      }
    },
  });

  const handleGoogleLogin = async () => {
    await getGoogleUserInfo()
      .then((response) => {
        const reqPayload = {
          FirstName: _.get(response, "given_name", ""),
          LastName: _.get(response, "family_name", ""),
          Email: _.get(response, "email", ""),
          IsGoogleVerified: _.get(response, "email_verified", ""),
        };
        saveGoogleUserDetails(reqPayload);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const saveGoogleUserDetails = async ({
    FirstName,
    LastName,
    Email,
    IsGoogleVerified,
  }) => {
    const reqPayLoad = {
      FirstName: FirstName,
      LastName: LastName,
      Email: Email,
      IsVerified: true,
      IsGoogleVerified: IsGoogleVerified,
      UserPreference: HOME,
    };

    await saveGoogleLoginUser(reqPayLoad).then((response) => {
      if (response) {
        const result = _.get(response, "result", null);
        const userTypeId = convertToNumber(_.get(result, "UserTypeId", ""));
        const IsVerified = _.get(result, "IsVerified", "") === 1;
        if (response.errorCode === 0 && IsVerified) {
          //const userPreference = _.get(result, "User_Preference", "");
          const IsLogin = _.get(result, "IsLogin", "");
          const firstName = _.get(result, "FirstName", "");
          const lastName = _.get(result, "LastName", "");
          const name = firstName + " " + lastName;
          const userDetails = {
            token: {
              accessToken: _.get(result, "sessionToken", ""),
              refreshToken: _.get(result, "refreshToken", ""),
              googleLogin: true,
            },
            userInfo: {
              userId: _.get(result, "UserId", ""),
              email: _.get(result, "Email", ""),
              firstName: firstName,
              lastName: lastName,
              name: name,
              userPreference: null,
              userType: userTypeId,
              userIsVerified:
                _.get(result, "IsGoogleVerified", "") === "false"
                  ? false
                  : true,
              planStatus:
                _.get(result, "planStatus", "") === "false" ? false : true,
              userStatus:
                _.get(result, "UserStatus", "") === "false" ? false : true,
            },
          };
          if (userTypeId === USER_TYPE_AGENT) {
            const agentTitle = _.get(result, "Agent_Title", "");
            userDetails.userInfo.agentTitle = agentTitle;
          }
          setLocalStorage(USER_INFO_KEY, userDetails);
          
          //Saving tutorial displayed status page
          const tutorialStatus = _.get(result, 'tutorialStatus', '')
          if (tutorialStatus && tutorialStatus.length) {
            let tutorialDisplayedPages = [];
            for (let i = 0; i < tutorialStatus.length; i++) {
              if (tutorialStatus[i].PageNo && tutorialStatus[i].Status == 1 && !tutorialDisplayedPages.includes(tutorialStatus[i].PageNo)) {
                tutorialDisplayedPages.push(tutorialStatus[i].PageNo)
              }
            }
            setLocalStorage('tutorialDisplayedPages', tutorialDisplayedPages);
          }

          handleActivePage();
        }
      }
    });
  };

  const handleAgentDetailsSave = async (agentDetails) => {
    const userId = _.get(
      getLocalStorage(USER_INFO_KEY),
      "userInfo.userId",
      null
    );
    const firstName = _.get(agentDetails, "firstName", "");
    const lastName = _.get(agentDetails, "lastName", "");
    const name = firstName + " " + lastName;
    const agentInfo = {
      // UserId: userId,
      // Title: 1,
      FirstName: firstName,
      LastName: lastName,
      Location: _.get(agentDetails, "location", ""),
      Company: _.get(agentDetails, "company", ""),
      LicenseNo: _.get(agentDetails, "licenseNo", ""),
      Bio: _.get(agentDetails, "bio", ""),
      Img: _.get(agentDetails, "img", ""),
      Phone: _.get(agentDetails, "phone", ""),
    };
    await saveAgentInfo(agentInfo).then((response) => {
      if (response) {
        if (response.errorCode === 0) {
          const userDetails = getLocalStorage(USER_INFO_KEY);
          if (userDetails) {
            userDetails.userInfo.userStatus = true;
            userDetails.userInfo.firstName = firstName;
            userDetails.userInfo.lastName = lastName;
            userDetails.userInfo.name = name;
            userDetails.userInfo.image = _.get(agentDetails, "img", "");
          }
          setLocalStorage(USER_INFO_KEY, userDetails);
          handlePageToggle({ activePage: USER_PREFERENCE });
        } else {
          console.log("Something went wrong");
        }
      }
    });
  };

  const handlMemberDeatilsSave = async (firstName, lastName) => {
    const reqPayload = {
      // userId: getUserId(),
      firstName: firstName,
      lastName: lastName,
    };

    const res = await changeUserName(reqPayload);
    const name = firstName + " " + lastName;
    if (_.get(res, "errorCode", "") === 0) {
      const userDetails = getLocalStorage(USER_INFO_KEY);
      if (userDetails) {
        userDetails.userInfo.userStatus = true;
        userDetails.userInfo.firstName = firstName;
        userDetails.userInfo.lastName = lastName;
        userDetails.userInfo.name = name;
      }
      setLocalStorage(USER_INFO_KEY, userDetails);
      handlePageToggle({ activePage: USER_PREFERENCE });
    } else {
      alert("Something went wrong.");
    }
  };

  const handleActivePage = () => {
    const loggedInUser = getLocalStorage(USER_INFO_KEY);
    const userType = _.get(loggedInUser, "userInfo.userType", null);
    const agentTitle = _.get(loggedInUser, "userInfo.agentTitle", null);
    const isVerified = _.get(loggedInUser, "userInfo.userIsVerified", null);
    const planStatus = _.get(loggedInUser, "userInfo.planStatus", null);
    const userStatus = _.get(loggedInUser, "userInfo.userStatus", null);
    if (isFromSettings) {
      // For plan update from settings page
      if (isEditPlan) {
        setActivePage(REVIEW_PLAN);
      } else {
        if (userType === USER_TYPE_AGENT || UpgradeToAgent) {
          setActivePage(AGENT_PLAN);
        } else if(userType === USER_TYPE_MEMBER){
          setActivePage(MEMBER_PLAN);
        }
        else{
          setActivePage(SIGNUP)
        }
      }
    } else if (deleteAccount) {
      setActivePage(DELETE_ACCOUNT);
      setCheckAccountDeletePopup(true);
    } else {
      setUserType(userType);
      if (loggedInUser) {
        if (userType) {
          if (isVerified && planStatus && userStatus) {
            if(stateValue?.IsFromMarketing){
              const userActionCreateWantAd = "POSTWANTAD";
              if (stateValue?.action === userActionCreateWantAd) {
                return navigate(pageURLs.createWantAdResponsive);
              } else{
                navigate(pageURLs.mobileHome);
              }

            }else{
              navigate(pageURLs.mobileHome);
            }
           
          } else {
            if (convertToNumber(userType) === USER_TYPE_AGENT) {
              setTitle(agentTitle);
              if (!agentTitle) {
                setActivePage(AGENT_TITLE);
              } else if (!planStatus) {
                setActivePage(AGENT_PLAN);
              } else if (!userStatus) {
                setActivePage(AGENT_PROFILE);
              }
            } else {
              if (!planStatus) {
                setActivePage(MEMBER_PLAN);
              } else if (!userStatus) {
                setActivePage(MEMBER_PROFILE);
              }
            }
          }
        } else {
          setActivePage(USER_TYPE);
        }
      } else {
        setActivePage(SIGNUP);
      }
    }
  };

  const handleBackButtonClick = ({ prevPage }) => {
    setActivePage(prevPage);
  };
  const handlePageToggle = (pageDetails) => {
    const planDetails = _.get(pageDetails, "planDetails", null);
    if (planDetails) {
      setReviewPlan(planDetails);
    }
    // if (isFromSettings && isEditPlan) {
    //   const selectedPayMode = _.get(pageDetails, "selectedPaymentMethod", null);
    //   const paymentMethods = _.get(pageDetails, "paymentMethods", null);
    //   setReviewPlan({
    //     planDetails,
    //     selectedPayMode: selectedPayMode,
    //     paymentMethods: paymentMethods,
    //   });
    // }
    setActivePage(_.get(pageDetails, "activePage", LOGIN));
  };

  const saveUserTypeAndTitle = async (reqPayload) => {
    return await saveUserType_Title(reqPayload).then((response) => {
      return response;
    });
  };

  const handleUserTypeContinueClick = async () => {
    const selectedUserType = convertToNumber(userType);
    const reqPayload = {
      // userId: getUserId(),
      userTypeId: selectedUserType,
    };
    const res = await saveUserTypeAndTitle(reqPayload);
    if (_.get(res, "errorCode", "") === 0) {
      const userDetails = getLocalStorage(USER_INFO_KEY);
      userDetails.userInfo.userType = selectedUserType;
      if (selectedUserType === USER_TYPE_MEMBER) {
        userDetails.userInfo.agentTitle = null;
      }
      setLocalStorage(USER_INFO_KEY, userDetails);
      if (convertToNumber(selectedUserType) === USER_TYPE_AGENT) {
        setActivePage(AGENT_TITLE);
      } else {
        setTitle(null);
        setActivePage(MEMBER_PLAN);
      }
    } else {
      alert("Something went wrong.");
    }
  };

  const handleAgentTitleContinueClick = async () => {
    const reqPayload = {
      // userId: getUserId(),
      title: title,
    };

    const res = await saveUserTypeAndTitle(reqPayload);
    if (_.get(res, "errorCode", "") === 0) {
      const userDetails = getLocalStorage(USER_INFO_KEY);
      userDetails.userInfo.agentTitle = convertToNumber(title);
      setLocalStorage(USER_INFO_KEY, userDetails);
      setActivePage(AGENT_PLAN);
    } else {
      alert("Something went wrong.");
    }
  };

  let DisplayWantAddOnboard = checkTutorialDisplayStatus(6); //Want add onboard display
  let DisplayInventoryAddOnboard = checkTutorialDisplayStatus(7); //Inventory onboard display

  const handleUserPreferenceContinue = (preference) => {
    const userType = _.get(getLocalStorage(USER_INFO_KEY), "userType", null);
    setLocalStorage("tutorialOnSignUp", 1);
    if (preference === WANT_AD) {
      if (DisplayWantAddOnboard) {
        navigate(pageURLs.wantAdTutorialResponsive);
      } else {
      navigate(pageURLs.createWantAdResponsive);
    }
    } else if (preference === PROPERTY_AD) {
      if (DisplayInventoryAddOnboard) {
        navigate(pageURLs.inventoryTutorialResponsive);
      } else {
      navigate(pageURLs.mobileCreateInventory);}
    } else {
      navigate(pageURLs.mobileHome);
    }
  };

  const emailVerification = async (reqPayload) => {
    await userEmailVerification(reqPayload).then((response) => {
      if (response) {
        const errorCode = _.get(response, "errorCode", null);
        if (errorCode === 0) {
          toast.success("Email verified Successfully.");
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  };

  const updateCard = (paymentMethod) => {
    setReviewPlan((prevState) => ({
      ...prevState,
      selectedPayMode: paymentMethod,
    }));
    if(upComingPlanDetails?.Is_Upcoming==1){
      setupComingPlanDetails((prevState) => ({
        ...prevState,
        Payment_Mode_Id: _.get(paymentMethod, 'Payment_Mode_Id', null ),
      }));
    } else{
      setUserPlanDetails((prevState) => ({
        ...prevState,
        Payment_Mode_Id: _.get(paymentMethod, 'Payment_Mode_Id', null ),
      }));
    }
    //setSelectedPaymentMethod(paymentMethod)
}

const handleCheckAccountDeletionModalClose = () => {
  console.log('---------Testm popup ---------')
  setCheckAccountDeletePopup(false);
  setActivePage(LOGIN)
  // window.location.reload();
};

  switch (activePage) {
    case HOME:
      navigate(pageURLs.mobileHome);

    case LOGIN:
      return (
        <CreateAccount
          handleBackButtonClick={handleBackButtonClick}
          handlePageToggle={handlePageToggle}
          activePage={activePage}
          googleLogin={googleLogin}
          stateValue={stateValue}
        />
      );
    case SIGNUP:
      return (
        <CreateAccount
          handleBackButtonClick={handleBackButtonClick}
          handlePageToggle={handlePageToggle}
          activePage={activePage}
          googleLogin={googleLogin}
        />
      );
    case USER_TYPE:
      return (
        <UserType
          handleUserTypeSelection={handleUserTypeSelection}
          userType={userType}
          handleUserTypeContinueClick={handleUserTypeContinueClick}
        />
      );
    case AGENT_TITLE:
      return (
        <AgentTitle
          handleBackButtonClick={handleBackButtonClick}
          setTitle={setTitle}
          title={title}
          handleAgentTitleContinueClick={handleAgentTitleContinueClick}
        />
      );
    case MEMBER_PLAN:
      return (
        <MemberPlan
          handleBackButtonClick={handleBackButtonClick}
          handlePageToggle={handlePageToggle}
          activePage={activePage}
          userDetails={upComingPlanDetails?.Is_Upcoming==1 ? upComingPlanDetails : userPlanDetails}
          isFromSettings={isFromSettings}
          currentActivePlan={currentActivePlan}
          setContinueButtonClicked={setContinueButtonClicked}
        />
      );
    case AGENT_PLAN:
      return (
        <AgentPlan
          handleBackButtonClick={handleBackButtonClick}
          handlePageToggle={handlePageToggle}
          userDetails={upComingPlanDetails?.Is_Upcoming==1 ? upComingPlanDetails : userPlanDetails}
          isFromSettings={isFromSettings}
          currentActivePlan={currentActivePlan}
          setContinueButtonClicked={setContinueButtonClicked}
          UpgradeToAgent={UpgradeToAgent}
        />
      );

    case REVIEW_PLAN:
      return (
        <ReviewPlan
          handleBackButtonClick={handleBackButtonClick}
          handlePageToggle={handlePageToggle}
          period={_.get(reviewPlanDetails, "period", null)}
          selectedPlan={_.get(reviewPlanDetails, "selectedPlan", null)}
          fromSignUp={_.get(reviewPlanDetails, "fromSignUp", null)}
          enablePromocode={_.get(reviewPlanDetails, "enablePromocode", null)}
          enablePromocodePeriod={_.get(
            reviewPlanDetails,
            "enablePromocodePeriod",
            null
          )}
          setSelectedPaymentMethod = {setSelectedPaymentMethod}
          selectedPaymentMethod = {selectedPaymentMethod}
          userDetails={upComingPlanDetails?.Is_Upcoming==1 ? upComingPlanDetails : userPlanDetails}
          isEditPlan = {isEditPlan}
          currentActivePlan={currentActivePlan}
          setContinueButtonClicked={setContinueButtonClicked}
          continueButtonClicked={continueButtonClicked}
          UpgradeToAgent={UpgradeToAgent}
        />
      );

    case MEMBER_PROFILE:
      return <MemberProfile handlMemberDeatilsSave={handlMemberDeatilsSave} />;

    case AGENT_PROFILE:
      return <AgentProfile handleAgentDetailsSave={handleAgentDetailsSave} />;

    case EDIT_PAYMENT:
      return (
        <PaymentMethods
          paymentMethods={_.get(reviewPlanDetails, "paymentMethods", null)}
          selectedCardId={
            _.get(reviewPlanDetails, "selectedPayMode", null)?.Payment_Mode_Id
          }
          updateCardParam={(paymentMethod) => { updateCard(paymentMethod) }}
          handlePageToggle = {handlePageToggle}
        />
      );

      case ADD_PAY_METHOD:
        return (
          <AddPaymentMode isFromReviewPlan = {true} handlePageToggle = {handlePageToggle}/>
        );

    case USER_PREFERENCE:
      return [
        <Home notShowGitchaPopup={true} />,
        <UserPreference
          show={true}
          handleUserPreferenceContinue={handleUserPreferenceContinue}
        />,
      ];
    case DELETE_ACCOUNT:
      return [
        <CreateAccount
          activePage={LOGIN}
        />,
        <CheckDeleteAccountModal
          show={checkAccountDeletePopup}
          onHide={handleCheckAccountDeletionModalClose}
        />
      ];
    default:
      break;
  }
  
};

export default UserRegister;
