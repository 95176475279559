import { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Select, { components } from 'react-select';

import SelectDropDown from "../components/Common/SelectDropDown";
import { getPaymentHistory } from "../services/paymentsService";
import { formateDate, getUserId } from "../helpers/common";

const PaymentHistory = ({ ...props }) => {
  const {currentTab} = props;
  const userId = getUserId();
  const [options, setOptions] = useState([
    { value: "last_30", label: "Last 30 days" },
    { value: "last_quarter", label: "Last quarter" },
    { value: "last_year", label: "Last year" },
    { value: "all_time", label: "All time" },
  ]);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(options[0]);
  const [paymentHistory, setPaymenthistory] = useState([]);

  useEffect(() => {
    if(currentTab==="payments"){
      fetchPaymentHistory();
    }
  }, [selectedTimePeriod, userId, currentTab]);

  const fetchPaymentHistory = async () => {
    const payload = {
      timePeriod: selectedTimePeriod?.value,
    };

    await getPaymentHistory(payload)
      .then((response) => {
        if (response.errorCode === 0) {
          setPaymenthistory(response?.result);
        } else {
          setPaymenthistory([]);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleSelectPeriod = (selectedParam) => {
    setSelectedTimePeriod(selectedParam);
  };

  return (
    <div className="payments-wrap history-sec">
      <div className="title-sec">
        <h4>Payment history</h4>
        <div className="period">
          <p>Time period:</p>
         {/* <Select
            id="multi3"
            name="colors3"
            options={options}
            value={selectedTimePeriod}
            onChange={handleSelectPeriod}
            className="basic-single mb-0 ms-3"
            classNamePrefix="select"
            placeholder="Select"
  />*/}

                        <SelectDropDown
                        
                        id="multi356"
                        name="colors356"
                        options={options}
                        className="basic-single payment-select mb-0"
                        classNamePrefix="select"
                        
                       // placeholder="Select"
                        //defaultValue={sortParam}
                        value={selectedTimePeriod}
                        onChange={handleSelectPeriod}
                       //components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                       styles={{
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          transform:
                            state.selectProps.menuIsOpen && "rotate(180deg)",
                        }),
                      }}

                        errorClass="error-msg error-msg-height d-none"
                        //errorMessage={lotSizeMaxError}
                      />
        </div>
      </div>
      <div className="payment-list-sec">
        {paymentHistory.length > 0 ?
          paymentHistory.map((paymentData) => {
            return (
              <div className="payment-data">
                <div className="payment-info">
                  {paymentData?.Is_Subs_Intro===1 ? <p>Subscription – {paymentData?.PlanName}</p> :
                  <p>Introduction – {paymentData?.Location}</p>}
                  <span>{formateDate(paymentData?.BillingDate, "shortMonth")}</span>
                </div>
                <div className="amount">${paymentData?.Total}</div>
              </div>
            );
          }) : <p className="text-center mt-3">No records available</p>}
        {/* <div className="payment-data">
            <div className="payment-info">
              <p>Introduction – 123 Gitcha street</p>
              <span>July, 23, 2022</span>
            </div>
            <div className="amount">$3.00</div>
          </div>
          <div className="payment-data">
            <div className="payment-info">
              <p>Introduction – 123 Gitcha street</p>
              <span>July, 23, 2022</span>
            </div>
            <div className="amount">$3.00</div>
          </div>
          <div className="payment-data">
            <div className="payment-info">
              <p>Introduction – 123 Gitcha street</p>
              <span>July, 23, 2022</span>
            </div>
            <div className="amount">$3.00</div>
          </div>
          <div className="payment-data">
            <div className="payment-info">
              <p>Introduction – 123 Gitcha street</p>
              <span>July, 23, 2022</span>
            </div>
            <div className="amount">$3.00</div>
          </div>
          <div className="payment-data">
            <div className="payment-info">
              <p>Introduction – 123 Gitcha street</p>
              <span>July, 23, 2022</span>
            </div>
            <div className="amount">$3.00</div>
          </div>
          <div className="payment-data">
            <div className="payment-info">
              <p>Introduction – 123 Gitcha street</p>
              <span>July, 23, 2022</span>
            </div>
            <div className="amount">$3.00</div>
          </div>*/}
      </div>
    </div>
  );
};

export default PaymentHistory;
