import * as endPoints from "../constants/endPoints";
import * as apiService from "./apiService";

export async function saveIntroductions(payload) {
  return await apiService.apiCall(
    endPoints.SAVE_INTRODUCTIONS,
    "POST",
    payload
  );
}

export async function getIntroductions(params) {
  return await apiService.apiCall(
    endPoints.GET_DASHBOARD_INTRODUCTION_LIST +
      `?wantAdId=${_.get(
        params,
        "wantAdId",
        ""
      )}&isAccept=${_.get(
        params,
        "isAccept",
        ""
      )}&page=${_.get(params, "page", "")}&limit=${_.get(params, "limit", "")}`,
    "GET",
    {}
  );
}

export async function getAcceptedIntroductions(params) {
  return await apiService.apiCall(
    endPoints.GET_ACCEPTED_INTRODUCTION_LIST +
      `?wantAdId=${_.get(
        params,
        "wantAdId",
        ""
      )}&page=${_.get(params, "page", "")}&limit=${_.get(params, "limit", "")}`,
    "GET",
    {}
  );
}

export async function updateIntroShowStatus(introIds, readType, userId) {
  return await apiService.apiCall(
    endPoints.UPDATE_INTRO_STATUS + `?introductionIds=${introIds}&readType=${readType}`,
    "PUT",
    {}
  );
}

export async function getIntroductionDetaisById(introId) {
  return await apiService.apiCall(
    endPoints.GET_INTRODUCTION_DETAILS + `?introductionId=${introId}`,
    "GET",
    {}
  );
}

export async function getMyPendingIntroductionByInvId(payload) {
  return await apiService.apiCall(
    endPoints.PENDING_INTRODUCTIONS_LIST,
    "GET",
    payload
  );
}

export async function getMyAcceptedIntroductionByInvId(payload) {
  return await apiService.apiCall(
    endPoints.ACCEPTED_INTRODUCTIONS_LIST,
    "GET",
    payload
  );
}

export async function cancelIntroduction(payload) {
  return await apiService.apiCall(endPoints.CANCEL_INTRODUCTION + `?introductionId=${payload.introductionId}`, "PUT", {});
}

export async function updateIntroductionStatus(params) {
  return await apiService.apiCall(
    endPoints.UPDATE_INTRODUCTION_STATUS +
      `?introductionId=${_.get(
        params,
        "introductionId",
        ""
      )}&status=${_.get(params, "status", "")}`,
    "PUT",
    {}
  );
}

export async function undoDeclinedIntroduction(params) {
  return await apiService.apiCall(
    endPoints.UNDO_DECLINED_INTRODUCTION +
      `?introductionId=${_.get(
        params,
        "introductionId",
        ""
      )}`,
    "PUT",
    {}
  );
}

export async function getIntroductionWantAdDetailsByIntroId(payload) {
  return await apiService.apiCall(
    endPoints.INTRODUCTION_WANTAD_DETAILS,
    "GET",
    payload
  );
}

export async function updateIntroNotFit(payload) {
  return await apiService.apiCall(
    endPoints.UPDATE_INTRODUCTION_NOT_A_FIT,
    "POST",
    payload
  );
}


export async function getIntroductionInventoryDetailsById(introductionId) {
  return await apiService.apiCall(
    endPoints.GET_INTRODUCTION_INVENTORY_DETAILS +
      `?introductionId=${introductionId}`,
    "GET",
    {}
  );
}

export async function reportIntroductionUser(requestPayload) {
  return await apiService.apiCall(
    endPoints.REPORT_INTRODUCTION_USER,
    "POST",
    requestPayload
  );
}

// inapp- notification 
export async function getInAppNotications(params) {
  return await apiService.apiCall(
    endPoints.INAPP_NOTIFICATIONS,
    "GET",
    {}
  );
}

export async function updateInAppReadStatus(userId) {
  return await apiService.apiCall(
    endPoints.INAPP_NOTIFICATIONS_READSTATUS_UPDATE,
    "PUT",
    {}
  );
}

export async function updateInAppDeleteStatus(notificationId, deleteStatus,  deleteType, subjectType) {
  return await apiService.apiCall(
    endPoints.INAPP_NOTIFICATIONS_DELETE_SATUS_UPDATE +
     `?notificationId=${notificationId}&isDeleted=${deleteStatus}&isDeleteType=${deleteType}&subjectType=${subjectType}`,
    "PUT",
    {}
  );
}

/**
 * To get the share introduction details
 * @param int introductionId 
 * @returns 
 */
export async function getShareIntroductionInventoryDetailsById(introductionId) {
  return await apiService.apiCall(
    endPoints.GET_SHARE_INTRODUCTION_DETAILS +
      `?introUniqueId=${introductionId}`,
    "GET",
    {}
  );
}

/**
 * save share link on clicking create share link in introduction details
 */

export async function createIntroductionShareLink(payload) {
  return await apiService.apiCall(
    endPoints.CREATE_SHARE_LINK +
    `?introductionId=${payload?.introductionId}`,
  "PUT",
  {}
  );
}