import React, { useState, useEffect } from "react";
import "../styles/clients.scss";
import { getLocalStorage, getUserId, getUserType, removeLocalStorage, setLocalStorage } from "../../helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { AGENT_INTRODUCTION, MEMBER_INTRODUCTION } from "../../constants/common";
import { fetchPaymentMethods } from "../../actions/payments";
import SelectPaymentMethod from "../components/SendIntroduction/SelectPaymentMethod";
import ReviewOrderData from "../components/SendIntroduction/ReviewOrderData";
import ViewAd from "./ViewAd";
import ReOrderFeatures from "../components/SendIntroduction/ReOrderFeatures";
import CustomizeIntro from "../components/SendIntroduction/CustomizeIntro";
import IntroEditPhotos from "../components/SendIntroduction/IntroEditPhotos";
// import AddPaymentMethod from "../components/SendIntroduction/AddPaymentMethod";
import WantAdIntroductions from "../components/ReceiveIntroduction/WantAdIntroductions";
import IntroductionPreview from "../components/ReceiveIntroduction/IntroductionPreview";
import IntroductionDetails from "../components/ReceiveIntroduction/IntroductionDetails";
import ViewImagesFullGallery from "../commonComponents/ViewImagesFullGallery";
import AddPaymentMode from "../components/Common/UserPlan/AddPaymentMode";
import { ADD_PAYMENT, CUSTOMIZE_INTRO, IMAGES_FULL_VIEW, INTRODUCTION_DETAILS, INTRODUCTION_PREVIEW, PAYMENT_LIST, REORDER_N2H, REORDER_PHOTOS, REVIEW_ORDER, VIEW_RECEIVED_INTRODUCTIONS } from "../../constants/onBoarding";
import { useLocation } from "react-router-dom";

const HandleViewWantAd = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [uiKey, setUIKey] = useState(null);
    const userId = getUserId();
    const userType = getUserType();
    let session = userType == 2 ? AGENT_INTRODUCTION : MEMBER_INTRODUCTION;
    const selectedProperty = getLocalStorage(session);
    //form States
    const [formData, setFormData] = useState(selectedProperty);
    const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);
    const [userPaymentMethods, setUserPaymentmethods] = useState(paymentMethods);
    const [deafaultPaymentMethod, setDeafaultPaymentMethod] = useState("");
    const [reload, setReload] = useState(0);
    const [showSendIntroPopUp, setShowSendIntroPopUp] = useState(false);
    const [introductionId, setIntroductionId] = useState(null);
    const [introductionPhotos, setIntroductionPhotos] = useState([]);
    const [wantadAdType, setWantAdAdType] = useState(null);
    
    const isFromNotification=location.state && location.state["isFromNotification"];

    useEffect(() => {
        dispatch(fetchPaymentMethods())
    }, [userId, reload, selectedProperty?.clientId]);

    useEffect(() => {
        setLocalStorage(session, formData);
    }, [formData])

    useEffect(() => {
        if(isFromNotification){
            setUIKey(VIEW_RECEIVED_INTRODUCTIONS)
        }
       
    }, [isFromNotification])

    useEffect(() => {
        removeLocalStorage(session)
    }, [])

    const updateCardParam = (paymentMethod) => {
        let cardData = {
            "cardId": paymentMethod?.Payment_Mode_Id,
            "cardData": paymentMethod
        }
        setDeafaultPaymentMethod(paymentMethod)
        setFormData(formData => ({ ...formData, ...selectedProperty, ...cardData }));
    }

    useEffect(() => {
        if (paymentMethods && paymentMethods.length > 0) {
            const defaultArray = paymentMethods?.filter((item) => item.IsDefault === 1)
            const OtherMethods = paymentMethods?.filter((item) => item.IsDefault !== 1)
            if (reload == 0) {
                setDeafaultPaymentMethod(defaultArray[0])
                updateCardParam(defaultArray[0])
            } else {
                if (OtherMethods.length > 0) {
                    setDeafaultPaymentMethod(OtherMethods[OtherMethods.length - 1])
                    updateCardParam(OtherMethods[OtherMethods.length - 1])
                } else if (defaultArray.length > 0) {
                    setDeafaultPaymentMethod(defaultArray[0])
                    updateCardParam(defaultArray[0])
                }
            }
            setUserPaymentmethods(defaultArray.concat(OtherMethods))
        }
        else {
            setUserPaymentmethods([])
        }

    }, [paymentMethods, reload])

    const saveCustomizeData = () => {
        let newArray = {
            originalpropertyFeatures: selectedProperty?.propertyFeaturesForPreview,
            originalPhotos: selectedProperty?.photosForPreview,
            propertyFeaturesForPreview: selectedProperty?.propertyFeaturesForPreview,
            photosForPreview: selectedProperty?.photosForPreview
        }
        setFormData(formData => ({ ...formData, ...newArray }));
        setUIKey(REVIEW_ORDER)
    }

    const handleReviewOrderBackButtonClick = () => {
        setShowSendIntroPopUp(true)
        setUIKey(null)
    }

    const revertCustomizationData = () => {
        let newArray = {
            propertyFeaturesForPreview: selectedProperty?.originalpropertyFeatures,
            photosForPreview: selectedProperty?.originalPhotos,
        }
        setFormData(formData => ({ ...formData, ...newArray }));
        setUIKey(REVIEW_ORDER)

    }

    const handlePageToggle = (pageDetails) =>{

        setReload(reload + 1);
        setUIKey(_.get(pageDetails, "activePage", null))

    }

    const handleViewWantAdUI = () => {

        switch (uiKey) {
            case PAYMENT_LIST:
                return <SelectPaymentMethod
                    setUIKey={setUIKey}
                    updateCardParam={(paymentMethod) => { updateCardParam(paymentMethod) }}
                    paymentMethods={userPaymentMethods}
                    selectedCardId={deafaultPaymentMethod?.Payment_Mode_Id}
                    setReload={() => { setReload(reload + 1) }}
                />;

            case CUSTOMIZE_INTRO:
                return <CustomizeIntro
                    setUIKey={setUIKey}
                    selectedProperty={selectedProperty}
                    deafaultPaymentMethod={deafaultPaymentMethod}
                    saveCustomizeData={saveCustomizeData}
                    handleBackButtonClick={revertCustomizationData}
                />;
            case REVIEW_ORDER:
                return <ReviewOrderData
                    setUIKey={setUIKey}
                    selectedProperty={selectedProperty}
                    deafaultPaymentMethod={deafaultPaymentMethod}
                    handleReviewOrderBackButtonClick={handleReviewOrderBackButtonClick}
                />;

            case REORDER_N2H:
                return <ReOrderFeatures
                    setUIKey={setUIKey}
                    selectedProperty={selectedProperty}
                />;

            case REORDER_PHOTOS:
                return <IntroEditPhotos
                    setUIKey={setUIKey}
                    selectedProperty={selectedProperty}
                />;

            case ADD_PAYMENT:
                return  <AddPaymentMode isFromSendIntroductionPlan = {true} handlePageToggle = {(pageDetails)=>{handlePageToggle(pageDetails)}}/>
                
                // <AddPaymentMethod
                //     setUIKey={setUIKey}
                //     setReload={() => { setReload(reload + 1) }}
                // />;
                
           
            case VIEW_RECEIVED_INTRODUCTIONS:
                return <WantAdIntroductions
                    setIntroductionId={setIntroductionId}
                    setUIKey={setUIKey}
                   
                />;

            case INTRODUCTION_PREVIEW:
                return <IntroductionPreview
                    introductionId={introductionId}
                    setUIKey={setUIKey}
                />;
          
            case INTRODUCTION_DETAILS:
                return <IntroductionDetails
                    introductionId={introductionId}
                    setUIKey={setUIKey}
                    setIntroductionPhotos={setIntroductionPhotos}
                    wantadAdType={wantadAdType}
                />;
                
                case IMAGES_FULL_VIEW :
                    return <ViewImagesFullGallery
                    photos={introductionPhotos}
                    setUIKey={setUIKey}
                    uiKey={uiKey}
                    isFromReceiveIntroDetailsPage={true}       
                  />
            default:
                return <ViewAd
                    setUIKey={setUIKey}
                    setWantAdAdType={setWantAdAdType}
                    showSendIntroPopUp={showSendIntroPopUp}
                />;
        }
    };

    return (
        <>
            {handleViewWantAdUI()}
        </>
    );
};

export default HandleViewWantAd;
