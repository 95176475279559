/**
 * @file   src\components\AgentWantAd\WantAdRentMustHavePrivate.js
 * @brief  This file is responsible for handling Must haves for Private room rent ad in edit want-ad.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, {useEffect } from "react";
import { handleRadioButonChange } from "../../actions/common";


const WantAdRentMustHavePrivate = (props) => {
  const { accsPvtBthRm, alwPets, adaAccess, pvtEntrnce,  handleFormValues } = props;



  
  const handleCheckBoxItemClick = (e) => {
    const id = e.target.id
    switch(Number(id)) {
      case 1 :
        handleFormValues({ adaAccess: e.target.checked, focusOut: true });
       break;
      case 2 :
        handleFormValues({ alwPets: e.target.checked, focusOut: true });
       break;
      case 3 :
        handleFormValues({ accsPvtBthRm: e.target.checked, focusOut: true });
       break;
       case 4 :
        handleFormValues({ pvtEntrnce: e.target.checked, focusOut: true });
       break;
    
    }
  };
  const loadCheckBoxItems = () => {
    const itemArray = [
      { id: 1, text: "ADA Accessible" },
      { id: 2, text: "Allows Pets" },
      { id: 3, text: "Access to private bathroom" },
      { id: 4, text: "Private entrance" },
    ];
    const List = itemArray.map((pt) => {
      const id = Number(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked
      switch (id) {
        case 1 :
          isChecked = adaAccess
          break;
        case 2 :
          isChecked = alwPets
          break;
        case 3 :
          isChecked = accsPvtBthRm
          break;
          case 4 :
            isChecked = pvtEntrnce
            break;
      }
   
      return (
        <label className="check-wrap mb-3" key={id}>
          <input
            type="checkbox"
            name="chk-mh"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <span>
            <div className="timing-wrap-info">
              <h2 className="pb-0">{text}</h2>
            </div>
          </span>
        </label>
      );
    });

    return List;
  };
  return (
      <div className="wantad-timing-wrap pb-1">{loadCheckBoxItems()}</div>
  );
};
export default WantAdRentMustHavePrivate;
