import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import CreateNewBtn from "./CreateNewBtn";
import SegmentDivider from "./SegmentDivider";
import MyDrafts from "../Common/MyDrafts";


const CreateWantAdSec = (props) => {
  const { handleContinueClick, myDraft, setDraftSelectedItem, draftSelectedItem, handleContinueButtonState } = props;
  const loadDrafts = () => {
    const myDraftList =
      myDraft &&
      myDraft.map((md) => {
        const wantAdItemId = _.get(md, "Want_ad_Id");
        const CompletedPercentage = _.get(md, "CompletedPercentage", "");
        const adTypeId = _.get(md, "Buy_or_Rent", "");
        const Current_Step = _.get(md, "Current_Step", "");
        var lastUpdatedDate = new Date(_.get(md, "LastUpdatedTS", ""));
        var formattedDate = [('0' + (lastUpdatedDate.getMonth()+1)).slice(-2), 
                              ('0' + lastUpdatedDate.getDate()).slice(-2), 
                              lastUpdatedDate.getFullYear()].join('/');
        let adType =''
        if(adTypeId === 1) {
          adType = 'Buy'
        } else if (adTypeId === 2) {
          adType = 'Rent (Long-term)'
        } else if(adTypeId === 3) {
          adType = 'Rent (Short-term)'
        }
        return (
          <MyDrafts
            setDraftSelectedItem = {setDraftSelectedItem} 
            handleContinueButtonState = {handleContinueButtonState}
            draftSelectedItem = {draftSelectedItem}
            key ={wantAdItemId}
            itemId={wantAdItemId}
            CompletedPercentage={CompletedPercentage}
            adType={adType}
            Current_Step={Current_Step}
            latUpdatedDate ={formattedDate}
          />
        );
      });

    return myDraftList;
  };
  return (
    <>
      <div className="center-content-sec mw-444">
        <CreateNewBtn handleContinueClick={handleContinueClick} />
        {myDraft &&  <><SegmentDivider />
        <div className="my-drafts-sec">
          <h2 className="mt-3">My Drafts</h2>

          {loadDrafts()}
        </div></>}
       
      </div>
    </>
  );
};
export default CreateWantAdSec;
