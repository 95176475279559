import React from "react";
import NiceToHaveHelper from "./NiceToHaveHelper";

const Layout = (props) => {
    const {layout, handleItemClick, itemArray} = props
    return  <NiceToHaveHelper
       list={layout}
       selectedItems={itemArray}
       propertyClick={handleItemClick}
    />
};
export default Layout;
