import HouseTagIcon from "../../../images/house-img.png";
import AddProperty from "./AddProperty";
import noImage from "../../../images/no-image-placeholder.png";
import { useState } from "react";
const MemberPropertiesData = (props) => {
    const {propertyList, setShowSelectProperty, selectedProperty, userDetails} = props;

    return (

            !propertyList ?
                <AddProperty userDetails={userDetails}/> :
        <div className="pe-3 ps-3 pb-12 pt-12">
            <a
                href="#"
                onClick={() => setShowSelectProperty(true)}
                className="add-your-property-wrap link-wrap property-wrap-heightfix mb-0"
              >
                <div className="make-intro-img">
                  <img className="display-thumb-img" src={selectedProperty?.photo ? selectedProperty?.photo : noImage } />
                </div>
                <div className="make-intro-details">
                  <h3>{selectedProperty?.Location}</h3>
                 <p>{selectedProperty?.City}, {selectedProperty?.State}</p>
                </div>
                <span role="button" className="right-arrow-link">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L7.94194 16.6919C7.69786 16.936 7.30214 16.936 7.05806 16.6919C6.81398 16.4479 6.81398 16.0521 7.05806 15.8081L12.8661 10L7.05806 4.19194C6.81398 3.94786 6.81398 3.55214 7.05806 3.30806Z"
                      fill="#000022"
                    ></path>
                  </svg>
                </span>
              </a>            
          </div>
    )
}

export default MemberPropertiesData
