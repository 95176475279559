import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch} from "react-redux";
import { toast } from "react-toastify";
import TextBox from "../Common/TextBox";
import { nameValidation } from "../../helpers/validations";
import { toTitleCase, getLocalStorage, setLocalStorage } from "../../helpers/common";
import { changeUserName } from "../../services/userprofileService";
import {USER_INFO_KEY } from "../../constants/common";

const EditUsernameModal = (props) => {   
  const dispatch = useDispatch();
    const [firstName, setFirstName] = useState(props.FirstName)
    const [lastName, setLastName] = useState(props.LastName)
    const[firstNameError, setFirstNameError] = useState('')
    const[lastNameError, setLastNameError] = useState('')

    useEffect(()=>{
      setFirstName(props.FirstName)
    },[props.FirstName])

    useEffect(()=>{
      setLastName(props.LastName)
    },[props.LastName])

    const handleFirstName =(name) =>{
      setFirstNameError("");
      setFirstName(name);
      if (name.trim() === "") {
        setFirstNameError("Enter First Name");
      } else if (!nameValidation(name.trim())) {
        setFirstNameError("Invalid input");
      }
    }

    const handleLastName =(name) =>{
      setLastNameError("");
      setLastName(name);
      if (name.trim() === "") {
        setLastNameError("Enter Last Name");
      } else if (!nameValidation(name.trim())) {
        setLastNameError("Invalid input");
      }
    }
    
    const handleCancel = () => {
      setFirstName(props?.FirstName); 
      setLastName(props?.LastName);
      props.onHide();
    }

    const handleSubmit = async() =>{
      const payload = {
        "firstName" : firstName,
        "lastName" : lastName
      }
      await changeUserName(payload)
      .then((response) => {
        if (response.errorCode == 0) {
          toast.success("User's name updated successfully.")
          const userInfo = getLocalStorage(USER_INFO_KEY);
          userInfo.userInfo["firstName"] = firstName;
          userInfo.userInfo["lastName"] = lastName;
          userInfo.userInfo["name"] =firstName+" "+lastName;
          setLocalStorage(USER_INFO_KEY, userInfo);  
          props.updateReloadParam();
          props.onHide();
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
      
    }
  return (
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings"
    >
      <Modal.Body className="pt-32 pb-32">
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
        <h3 className="text-start">Your name</h3>
        <Row>
          <Col md={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <label className="form-label">First name</label> 
                <TextBox
                    id="fName"
                    name="firstName"
                    type="text"
                    maxLength={30}
                    value={toTitleCase(firstName)}
                    placeholder="First name"
                    className="form-control"
                    onChange={(e)=>handleFirstName(e.target.value)}
                    errorClass = "error-validation-msg"
                    errorMessage={firstNameError}
                />
            </div>
          </Col>

          <Col md={6}>
            <div className="textbox-wrap mb-3 mb-sm-3">
                <label className="form-label">Last name</label>
                <TextBox
                    id="lName"
                    name="lastName"
                    maxLength={30}
                    value={toTitleCase(lastName)}
                    type="text"
                    placeholder="Last name"
                    className="form-control"
                    errorClass = "error-validation-msg"
                    onChange={(e)=>handleLastName(e.target.value)}
                    errorMessage={lastNameError}
                />
            </div>
          </Col>
        </Row>      

        <div className="modal-btn-wrap">
            <Button className="btn btn-tertiary me-3" onClick={()=>handleCancel()} >Cancel</Button>
            <Button variant="primary" 
            onClick={handleSubmit} 
            disabled={(firstName===props.FirstName && lastName===props.LastName) || (firstNameError!== '' || lastNameError!=='')? true : false}>
              Save Changes
            </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditUsernameModal;
