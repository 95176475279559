import React, { useState, useEffect } from "react";
import "../mobile/styles/mobile-style.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import FireWorkImgTop from "../images/party-popper.png";

import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import "../mobile/styles/manage-inventory.scss";
import { useLocation } from "react-router-dom";
import { formatDisplayPhoneNumber, toTitleCase } from "../helpers/common";

const FormSubmissionSuccessMob = (props) => {
  const location = useLocation();
  const state = location.state;
  const wantAdData = state && state.wantAdData ? state.wantAdData : null;

  const pagination = {
    clickable: true,
  };

  return (
    <div className="gitcha-main-wrapper submissionSuccess">
      <div className="content-wrapper ">
        <div className="inner-layout-content-new h-100  ">
          <h2 className="text-center ps-3 pe-3">
            You’re on the way to finding your perfect property!
          </h2>
          <div class="pyro pyro-mob pyro-mob-top">
            <div class="before"></div>
            <div class="after"></div>
          </div>
          <div className="text-center pt-2 pb-2">
            <img
              className="mw-28 "
              src={FireWorkImgTop}
              width={"28px"}
              height={"28px"}
            />
          </div>
          <p className="text-center ps-3 pe-3">
            Great work! We’ve sent this over to {toTitleCase(wantAdData?.userFirstName)} for review. Your agent
            will be doing everything in their power to find you the home or
            property of your dreams, and it starts here at the Buyer Listing
            Service.
          </p>

          <p>
            <div class="profileSection d-flex justify-content-center">
              {wantAdData?.userImage !== "" ? (
                <div className="profileSectionImg">
                  <img
                    src={
                      wantAdData?.userImage + "?" + Math.random().toString(36)
                    }
                  />
                </div>
              ) : (
                <div className="profileSectionNoImg">
                  <svg
                    width="24"
                    height="23"
                    viewBox="0 0 24 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3643 21.1875C21.7317 18.375 19.1183 16.264 16.0253 15.2594C17.5078 14.3778 18.6599 13.0338 19.3046 11.4339C19.9493 9.83406 20.0508 8.06678 19.5937 6.40358C19.1365 4.74039 18.146 3.27329 16.7741 2.22766C15.4023 1.18204 13.7252 0.615723 12.0003 0.615723C10.2754 0.615723 8.59824 1.18204 7.22643 2.22766C5.85462 3.27329 4.86405 4.74039 4.4069 6.40358C3.94975 8.06678 4.05131 9.83406 4.69597 11.4339C5.34063 13.0338 6.49273 14.3778 7.97529 15.2594C4.88227 16.264 2.2689 18.375 0.636225 21.1875C0.556066 21.3194 0.513672 21.4707 0.513672 21.625C0.513672 21.7793 0.556066 21.9307 0.636225 22.0625C0.711012 22.1966 0.820597 22.308 0.953423 22.385C1.08625 22.462 1.23739 22.5018 1.39091 22.5H22.6097C22.7632 22.5018 22.9143 22.462 23.0472 22.385C23.18 22.308 23.2896 22.1966 23.3643 22.0625C23.4445 21.9307 23.4869 21.7793 23.4869 21.625C23.4869 21.4707 23.4445 21.3194 23.3643 21.1875Z"
                      fill="#A0A9BA"
                    />
                  </svg>
                </div>
              )}
              <div>
                <h5>
                  {wantAdData?.userFirstName} {wantAdData?.userLastName}{" "}
                  {wantAdData?.isPremiumAgent ? (
                    <span className="pro-badge">PRO</span>
                  ) : (
                    <></>
                  )}
                </h5>
                Agent @ {wantAdData?.userCompany}
                <br />
                {formatDisplayPhoneNumber(wantAdData?.Phone)}
              </div>
            </div>
          </p>

          <div className="bullet-slider">
            <h3> How it works: </h3>
            <div className="tutorial-slider  slider-ht-0 pt-0 pb-5">
              <Swiper
                pagination={pagination}
                modules={[Pagination]}
                className="mySwiper"
                rewind={true}
                autoplay={{
                  delay: 800,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <div className="slider-content">
                    <h6>
                      Your agent shares all of the <br />
                      specifics of your search
                    </h6>
                    <p className="m-0">
                      The Buyer Listing Service is the place for your agent to
                      list and share your wants & needs with their professional
                      network to attract new opportunities, including upcoming
                      and off-market homes and properties.
                    </p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content ">
                    <h6>
                      The Buyer Listing Service gets to <br />
                      work for you and your agent
                    </h6>
                    <p className="">
                      Your criteria gets posted on the listing feed, where
                      agents will be automatically notified if what they have
                      matches what you’re looking for. To provide additional
                      exposure Gitcha will be marketing your agent’s
                      want-listing, too!
                    </p>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <h6>
                      Agents representing sellers <br />
                      cater to you for once
                    </h6>
                    <p className="m-0">
                      Your criteria gets posted on the listing feed, where
                      agents will be automatically notified if what they have
                      matches what you’re looking for. To provide additional
                      exposure Gitcha will be marketing your agent’s
                      want-listing, too!
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          {/* <div className="btn-wrap mt-5 ps-3 pe-3">
            <button
              type="button"
              class="btn btn-primary w-100"
              // onClick={() => redirectToPage("wantadd")}
            >
              Restart
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FormSubmissionSuccessMob;
