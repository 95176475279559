import { replace } from "lodash";
import { replaceString } from "./common";

export function emailValidation(value) {
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  let result = pattern.test(value);
  return result;
}

export function phoneNumberValidation(value) {
  let pattern = new RegExp(
    // /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/
    /^\(?\d{3}[-]?\s*\d{3}\s*[-]?\s*\d{4}/
  );
  let result = pattern.test(value);
  return result;
}

export function onlyNumberCheck(val) {
  const numberRegexpression = /^[0-9\b]+$/;
  if (numberRegexpression.test(val)) {
    return true;
  } else {
    return false;
  }
}

const isCheckNegativeValue = (value) => {
  if (Math.sign(value) === -1) {
    return true;
  }
  return false;
};

export const convertToNumber = (input) => {
  if (input) {
    return Number(input);
  }
  return "";
};

export const handleNegativeInput = (inputValue) => {
  if (isCheckNegativeValue(inputValue)) {
    return 0;
  }
  return inputValue;
};

export const removeNegativeInput = (inputValue) => {
  const string = replaceString(inputValue, ",");
  if (isCheckNegativeValue(string)) {
    return replaceString(string, "-");
  }
  return inputValue;
};

export const checkMaxValue = (inputValue, maxValue) => {
  if (
    convertToNumber(inputValue.toString().replace(/,/g, "")) >
    convertToNumber(maxValue)
  ) {
    return maxValue;
  }
  return inputValue;
};

export const checkCharLength = (value, length) => {
  let isValid = true;
  if (value && value.length > length) {
    isValid = false;
  }
  return isValid;
};

export const checkCharLimit = (value, limit) => {
  let isValid = true;
  if (value && value.length > length) {
    isValid = false;
  }
  return isValid;
};

export const compareInputValues = (inputValue, comparingValue) => {
  let isValid = true;
  if (convertToNumber(inputValue) < convertToNumber(comparingValue)) {
    isValid = false;
  }
  return isValid;
};

export const validateInputValues = (minValue, maxValue) => {
  const isValid = minValue < maxValue;
  return isValid;
};

// export const handleInputMaxValueLimits = (inputValue, valueToBeCompared) => {
//   inputValue = convertToNumber(inputValue)
//   valueToBeCompared = convertToNumber(valueToBeCompared)
//   if(isCheckNegativeValue(inputValue)) {
//     return 0
//   }
//   if(!valueToBeCompared) {
//     return inputValue
//   } else {
//     return (valueToBeCompared - inputValue ) + (inputValue);
//   }
// };

export const handlePercentageValue = (inputValue) => {
  if (isCheckNegativeValue(inputValue)) {
    return 0;
  } else if (inputValue < 0 || inputValue > 100) {
    return 0;
  }
  return inputValue;
};

export function nameValidation(val) {
  const pattern = /^([a-zA-Z]{2}[a-zA-Z0-9-'. ]*)$/;
  if (pattern.test(val)) {
    return true;
  } else {
    return false;
  }
}

export function alphaNumericValidation(val) {
  const pattern = /^([a-zA-Z0-9-'. ]*)$/;
  if (pattern.test(val)) {
    return true;
  } else {
    return false;
  }
}

export function textValidations(val) {
  const pattern = /^([a-zA-Z0-9-\n?()/\[|\]/_—'’"!#$%&*+?<>@=;:,. ]*)$/;
  if (pattern.test(val)) {
    return true;
  } else {
    return false;
  }
}

export function passwordValidation(value) {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{6,}/;
  const noSpaceRegExp = /^\S*$/;

  const passwordLength = value.length;
  const uppercasePassword = uppercaseRegExp.test(value);
  const lowercasePassword = lowercaseRegExp.test(value);
  const specialCharPassword = specialCharRegExp.test(value);
  const minLengthPassword = minLengthRegExp.test(value);
  const space = noSpaceRegExp.test(value);

  let errMsg = "";
  if (passwordLength === 0) {
    errMsg = "Enter Password.";
  } else if (!minLengthPassword) {
    errMsg =
      "Just a bit too short! you need 6+ characters with at least one uppercase and lowercase letter, one number and one special character.";
  } else if (!uppercasePassword) {
    errMsg = "Include at least one capital letter in the password.";
  } else if (!lowercasePassword) {
    errMsg = "Include at least one lowercase letter in the password.";
  } else if (!specialCharPassword) {
    errMsg = "Include at least one special character in the password.";
  } else if (!space) {
    errMsg = "Password should not contain space";
  } else {
    errMsg = "";
  }
  return errMsg;
}
