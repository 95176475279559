
import { auth, db } from "../firebase";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
// import { FIREBASE_PASSWORD } from "../constants/common";
import {
    query,
    collection,
    addDoc,
    serverTimestamp,
    orderBy,
    onSnapshot,
    limit,
    where,
    groupBy,
    setDoc,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    getDocs,


} from "firebase/firestore";

import moment from "moment";
import { getUserId, loggedInUser } from "./common";

const userDetails = loggedInUser();
const FIREBASE_PASSWORD = process.env.REACT_APP_FIREBASE_USER_PASSWORD

export async function loginOrSignUp(email) {
    const auth = getAuth();

    await signInWithEmailAndPassword(auth, email, FIREBASE_PASSWORD)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // console.log(userCredential);
            return 1;
            // ...
        })
        .catch(async (error) => {
            //   const errorCode = error.code;
            //   const errorMessage = error.message;
            await createUserWithEmailAndPassword(auth, email, FIREBASE_PASSWORD).then
                ((userCredential) => {

                    // Signed in 
                    const user = userCredential.user;
                    // console.log(userCredential);
                    return 1;
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        });
}

export const signOutFirebase = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        return 1 ;
    }).catch((error) => {
        return 0 ;
    });
}

export const addMessage = async (introId, data) => {
    const auth = getAuth();
    var user = auth.currentUser;
    if(!user){
        let email = _.get(userDetails, "userInfo.email", null);
         await loginOrSignUp(email);
    }

    try {
        const docRef = collection(db, "Chat", introId.toString(), "message");
        let response = await addDoc(docRef, { ...data, createdAt: serverTimestamp() });

        return 1;
    } catch (e) {

        return 0;
    }

}

export const addIntroduction = async (introId, data) => {
    const auth = getAuth();
    var user = auth.currentUser;
    if(!user){
        let email = _.get(userDetails, "userInfo.email", null);
         await loginOrSignUp(email);
    }


    // Create an initial document to update.
    // const docRef = doc(db, "Introduction", introId.toString(), "message");
    // let response = await addDoc(docRef, { ...data, createdAt: serverTimestamp() });

    try {
        let response = await setDoc(doc(db, "Introduction", introId.toString()), { ...data, createdat: serverTimestamp() });
        return 1;
    } catch (e) {
        return 0;
    }


}

export const updateIntroduction = async (introId, data) => {
    const auth = getAuth();
    var user = auth.currentUser;
    if(!user){
        let email = _.get(userDetails, "userInfo.email", null);
         await loginOrSignUp(email);
    }
   
    // Create an initial document to update.
    // const docRef =  collection(db, "Introduction", introId.toString(), "message");
    try {
        let response = await updateDoc(doc(db, "Introduction", introId.toString()), { ...data, createdat: serverTimestamp() });
      
        return 1;
    } catch (e) {
        return 0;
    }
}

export const updateIntroductionWithOutCreatedDate = async (introId, data) => {

    // Create an initial document to update.
    // const docRef =  collection(db, "Introduction", introId.toString(), "message");
    try {
        let response = await updateDoc(doc(db, "Introduction", introId.toString()), { ...data });
        return 1;
    } catch (e) {
        return 0;
    }
}

export const deleteIntroduction = async (introId) => {

    const docRef = doc(db, "Introduction", introId.toString());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //     console.log("Document data:", docSnap.data());
        try {

            let response = await deleteDoc(doc(db, "Introduction", introId.toString()));
            return 1

        } catch (e) {
            return 0
        }
    } else {
        return 1;
    }
    // try{

    //     let response = await deleteDoc(doc(db, "Introduction",  introId.toString()));
    //     return 1

    // }  catch (e) {
    //     return 0
    // }


    // return response;

}

// export const deleteMessage = async (introId) => {
//     console.log(introId)
//     const docRef = doc(db, "Chat",  introId.toString());
//     const docSnap = await getDoc(docRef);
//     console.log(docSnap)
//     if (docSnap.exists()) {

//     // console.log("sfd")
//     try {
//         const docRef = doc(db, "Chat", introId.toString());
//         let response = await deleteDoc(docRef);
//         return 1;
//     } catch (e) {
//         return 0;
//     }
// } else{
//     return 1;
// }

// }

export const deleteMessageDoc = async (introId) => {
    let userId = getUserId();

    const q = query(collection(db, "Chat", introId.toString(), "message"),
        where("isTyping", "==", true),
        where("createdUserId", "==", userId)
    );

    const querySnapshot = await getDocs(q);
    let id = []
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        id = doc.id;
    });


    const docRef = doc(db, "Chat", introId.toString(), "message", id.toString());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {

        // console.log("sfd")
        try {
            //   const docRef = doc(db, "Chat", introId.toString());
            let response = await deleteDoc(docRef);
            return 1;
        } catch (e) {
            return 0;
        }
    } else {
        return 1;
    }


}