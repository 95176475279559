import React  from "react";
import GitchaLogo from "../images/gitcha-logo.svg";


const GitchaPrivacyPolicy = (props) => {
  return (
    <>
      <div className="outer-wrap position-relative mw-1168">
      <div class="top-header mb-24">
            <div class="w-100 h-100 d-flex p-0 flex-column flex-sm-column flex-md-row justify-content-between align-items-center">
            <div className="navbar-brand brand-fix p-0">
          <a href="/home">
            <img src={GitchaLogo} alt={GitchaLogo} />
          </a>
        </div>
            </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="privacy-policy-wrap">
             <h2 className="text-center">Gitcha Privacy Policy</h2>
             {/*<ol className="first-level-list">
      <li>Element 1
        <ol className="second-level-list">
          <li>Sub element 1

            <ol className="alpha">
              <li>gfdg</li>
              <li>gfdg</li>

            </ol>
          </li>
              
          <li>Sub element 2</li>
          <li>Sub element 3</li>
        </ol>
      </li>
      <li>Element 2</li>
      <li>Element 3
        <ol>
          <li>Sub element 1</li>
          <li>Sub element 2</li>
          <li>Sub element 3</li>
        </ol>
      </li>
  </ol>*/}
              <p>
                Gitcha is a platform designed to allow buyers, sellers, renters
                and landlords to connect directly based on demand and
                availability of unique features and properties. This Privacy
                Policy (the “Policy”) applies to your use of the Gitcha website,
                app, and services (together, the “Services”) and describes how
                Gitcha Home LLC, an Oregon limited liability company (“Gitcha,”
                “we,” “us,” or “our”), collects, uses, and protects your
                Personal Information. When you submit personal information to
                Gitcha, whether through use or access to our Services or
                otherwise, you are consenting to the collection, use, transfer,
                and disclosure of your Personal Information as described in this
                Policy. If you have questions about this Policy, please contact
                Gitcha using the contact information located at the end of this
                Policy.
              </p>

              <p className="privacy-para-bold">
                1. Information Gitcha Collects.
              </p>
              <p>
                When you submit information to Gitcha via our Services, or
                otherwise correspond with Gitcha, Gitcha receives and stores
                that information, including Personal Information. “Personal
                Information” means any information you provide Gitcha that could
                reasonably be used to personally identify you. We may collect
                the following types of information, some of which may be
                considered Personal Information:
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                1.1. Information You Provide Directly to Us.
              </p>
              <ul className="firstlevel-od-list">
                <li>
                  <span className="f-bold">a. User Accounts.</span> There are
                  two types of registered users. “Individual Users” are
                  individuals accessing or using our Services to perform tasks
                  such as to review or post want-ads, available properties, or
                  to communicate with other users; and “Real Estate
                  Professionals” are professional real estate agents or brokers
                  accessing or using our Services to connect and communicate
                  with Individual Users as well as perform tasks such as to
                  review or post want-ads, or to track inventory or clients.
                  Both Individual Users and Real Estate Professionals must
                  create a user account. To create a user account, we require
                  you to provide certain registration information such as your
                  name, phone number, and e-mail address. Real Estate
                  Professionals must also provide information about their
                  company and their agent number.{" "}
                </li>

                <li>
                  <span className="f-bold">
                    b. Want Ads and Available Property Ads.
                  </span>{" "}
                  When you use the Services to post want-ads or ads for
                  available properties, you will need to provide certain
                  information relating to your posting. For want-ads, you must
                  indicate whether you are posting as a potential buyer or
                  renter, the location in which you are interested, your
                  timeline, budget, and general information about the type of
                  home you are seeking. You may also want to provide additional
                  optional information about what you are seeking, including,
                  for example the lot size, year built, and desired features. If
                  you are posting an ad for an available property, you must
                  provide information about the property, including the type of
                  property, location, and your asking price. You may also
                  include additional optional information about the features and
                  details of the property, for example your selling timeline,
                  and photos of the property.{" "}
                </li>
                <li>
                  <span className="f-bold">c. Real Estate Dashboard.</span> Real
                  Estate Professionals can also track current inventory,
                  clients, and want ads using the real estate dashboard feature.
                  Real Estate Professionals may include client names, contact
                  information, and notes related to their clients on their
                  dashboard. Information listed on their dashboard that is not
                  listed elsewhere (such as in a want-ad) is not visible to
                  other users.
                </li>

                <li>
                  <span className="f-bold">d. Payment Information.</span> We use
                  a third-party to process payment information. Third-party
                  payment processors have access to payment information needed
                  to perform their functions and may not use it for other
                  purposes.
                </li>
              </ul>

              <p className="pl-20 privacy-para-bold f-size-16">
                1.2. Technical Information.
              </p>

              <ul className="firstlevel-od-list">
                <li>
                  <span className="f-bold">a. Technical Information.</span>{" "}
                  Whether you are an unregistered visitor, a Individual User, or
                  a registered Real Estate Professional, when you visit or use
                  our Services, Gitcha automatically receives and records
                  information about how you and your device interact with our
                  Services, including, for example: (1) referring source (i.e.,
                  the site you visited prior to visiting our website; (2) date
                  and time of access our website; (3) pages visited and features
                  used, and the order of use; (4) type of computer or other
                  operating device accessing our Services, type of browser used
                  to access our Services, and type of operating system you use;
                  and (5) other similar information about the interaction
                  between your browser and our Services (“Technical
                  Information”). We collect and store Technical Information
                  through information gathering tools such as cookies, web
                  beacons, and other similar technologies.
                </li>

                <li>
                  <span className="f-bold">b. Cookies.</span> Cookies are stored
                  in a computer or other operating device’s memory during a
                  user’s browsing session. The cookies we use in connection with
                  our Services are automatically deleted from the user’s
                  computer or operating device when the browser is closed and
                  the session has ended. These cookies usually store a session
                  ID that does not personally-identify the user, and they allow
                  users to navigate our Services efficiently. These cookies
                  provide us information about features used and activities
                  conducted while using our Services. Generally, we use cookies
                  to customize your experience. We may use cookies to compile
                  aggregate data about user traffic on and user interaction with
                  our Services so we can offer better experiences and tools in
                  the future.
                  <span className="d-block mt-2">
                    Most browsers automatically accept cookies, but you can
                    block cookies at any time by changing the preferences in
                    your browser and/or deleting cookies from your hard drive or
                    other operating device. You can also have the browser notify
                    you when you receive a new cookie. Please refer to your
                    browser for information on how to disable and control
                    cookies. If you choose to block or delete cookies, our
                    Services may not function properly on your computer or other
                    operating device, and certain features may not be available
                    to you.
                  </span>
                  <span className="d-block mt-2">
                    In addition to browser cookies, we may use other locally
                    stored objects (sometimes referred to as “flash cookies”)
                    stored in your hard drive or other operating device. These
                    flash cookies will not be deleted when you delete cookies
                    from your browser, and even if you set your browser to not
                    accept cookies, these cookies will still be stored on your
                    hard drive or other operating device when you use our
                    Services. We may use this technology for purposes such as
                    information security and fraud prevention, and to collect
                    and store information about your preferences and navigation
                    to, from, and on our website. We do not use this technology
                    for online behavioral advertising purposes. If you choose to
                    disable or otherwise control flash cookies, our Services may
                    not function properly on your computer or other operating
                    device, and certain features may not be available to you.
                  </span>
                </li>
                <li>
                  <span className="f-bold">c. Web Beacons.</span> We may use web
                  beacons (also called clear GIFs or pixel tags) or similar
                  technologies to collect technical information about your use
                  of our Services and your viewing and use of emails we send to
                  you. Web beacons are graphic image files imbedded in a web
                  page, website feature, or email that provide a presence on
                  such web page, feature, or email and send back to its home
                  server information from the user’s browser. The information
                  collected by web beacons allows us to statistically monitor
                  how many people are using our website and opening our emails,
                  and for what purposes. For example, we might place a web
                  beacon in a marketing email to notify us when a user clicks on
                  the link in the email. We do not collect Personal Information
                  with web beacons, and we do not link information retrieved
                  from web beacons directly with any Personal Information you
                  have given us.
                </li>
                <li>
                  <span className="f-bold">d. Third-party Cookies and Similar Technologies.</span> Third-party partners display content, including ad​​​​vertisements, via our Services.  These third parties may place cookies, or similar technologies, on your browser that collect information about you.  Third parties may use the information they collect to provide you with interest-based advertising.  Such third parties may collect information about you over time and across different online services. Gitcha does not control third-party tracking technologies or their use. You can opt out of certain third-party interest-based advertising services we may use by visiting the opt-out page for each specific third-party on their website, for example, ​​for Google: Google Marketing Platform opt-out page; For Twitter: Twitter’s Privacy Controls for Personalized Ads; For Facebook: Facebook Ad Preferences; and for LinkedIn: LinkedIn General Advertising Preferences. You can learn more about interest-based advertising from the Network Advertising Initiative (NAI) and the Digital Advertising Alliance (DAA).  Both the NAI and DAA maintain consumer choices pages where you can opt out from receiving interest-based advertising from participating companies. 
                </li>
              </ul>
              <p class="pl-20 privacy-para-bold f-size-16">
                1.3. Other Types of Information.
              </p>

              <p class="pl-20">
                We are constantly working to expand and enhance our Services and
                doing so may result in the collection of new, and different,
                types of information.
              </p>

              <p class="pl-20 privacy-para-bold f-size-16">
                1.4. Publicly Available Information.
              </p>

              <p className="pl-20">
                “Personal Information” does not include any publicly available
                information about Real Estate Professionals or information that
                is posted to publicly accessible areas of the Services. Any
                information that you disclose in an ad is intentionally open to
                the public and is not in any way private. We recommend that you
                carefully consider whether to disclose any Personal Information
                in any public posting.
              </p>

              <p className="privacy-para-bold">
                2. How Gitcha Uses Your Personal Information.
              </p>

              <p>
                We will use your Personal Information for the purpose stated
                when we ask for such Personal Information. We also use Personal
                Information as described elsewhere in this Policy, including to
                provide our Services and their features and to send you
                communications.
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                2.1. Providing and Supporting our Services.
              </p>
              <p className="pl-20">
                We use your Personal Information to provide and support the
                operation of our Services, including:
              </p>

              <ul className="firstlevel-od-list">
                <li>
                  <span className="f-bold">a. Your use of our Services.</span>{" "}
                  We use Personal Information to facilitate your use of the
                  Services, to process your requests or transactions, to provide
                  you with information and Services you request, and to
                  administer and assist us with the operation of our business.
                </li>

                <li>
                  <span className="f-bold">b. Customer Support.</span> We may
                  use Personal Information to help diagnose problems with our
                  website and app, to make them more compatible with the
                  technology used by our users, to analyze statistical use
                  patterns, and to otherwise improve our website and app. For
                  example, we may use Personal Information to track what our
                  users look at most so we can recognize and provide more of the
                  desired content.{" "}
                </li>
              </ul>
              <p className="pl-20 privacy-para-bold f-size-16">
                2.2. Communications.
              </p>

              <p className="pl-20">
                We may use your Personal Information to provide you information
                about Gitcha’s Services or other services that may be of
                interest to you, only if you have opted-in to receiving such
                information. We will never share your Personal Information with
                a third party for their own marketing or independent uses,
                except: (1) where you consent to such information sharing and
                (2) as described in this Policy.
              </p>
              <p className="pl-20">
                If we use your Personal Information to send you marketing
                communications, we will provide you with an unsubscribe
                mechanism to opt-out of receiving future marketing
                communications, or you can contact us to request that we not
                send you marketing communications. If you request that we not
                contact you for marketing purposes (either by using the
                unsubscribe mechanism or contacting us), we reserve the right to
                contact you for non-marketing purposes relating to your user
                account or other use of our Services.
              </p>

              <p className="privacy-para-bold">
                3. Information Shared with Third Parties.
              </p>

              <p>
                Except in the circumstances described in this Policy, Gitcha
                does not share, sell, rent, license, trade, or otherwise
                transfer or disclose your Personal Information with or to third
                parties for their promotional purposes. We may share
                de-identified, aggregated information without restriction.
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                3.1. Third-Party Service Providers.
              </p>

              <p className="pl-20">
                We share information with our third-party service providers who
                assist us by performing services related to our operation of our
                website and app (such as hosting, data storage, payment
                processing, and security) and to conduct marketing on our
                behalf. For more information regarding third parties who will
                have access to your credit card information, see Section 1.1.{" "}
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                3.2. Third-Party Analytics Services.{" "}
              </p>
              <p className="pl-20">
                We may use third-party analytics services to assist us in better
                understanding users of our Services. For example, we use these
                services to track and analyze certain information, such as
                feature usage. We only ever provide these service providers with
                (or allow these service providers to collect on our behalf) the
                technical information described in Section 1.2. We use the
                information collected from these service providers to make our
                Services better and easier to use. The information we receive
                from these third parties may be re-associated with your Personal
                Information after we receive it back from these third parties.
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                3.3. Third-Party Advertising Partners.{" "}
              </p>
              <p className="pl-20">
                We partner with third parties to select, deliver, and display
                ads for our Services. The ads we select may be based on your
                current location or the content you are viewing. Other ads are
                targeted based on your likely interests or other information
                that we learn about you over time using demographic data,
                searches, interests and favorites, location data, and usage data
                from our website and app and the sites and services of our
                advertisers and partners. We share information with these third
                parties to facilitate the selection and serving of
                advertisements. Advertisers may use cookies, web beacons, and
                similar technologies to personalize their advertising content
                and measure its effectiveness.{" "}
              </p>

              <p className="pl-20">
                Our advertising partners may also serve ads to you via other
                sites (including ads for our Services) based on information
                collected from you while using our website and app. For example,
                when you visit a site serviced by one of our advertising
                partners, we may use “remarketing” services to target ads to you
                based on your prior use of our Services.{" "}
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                3.4. Merger, Acquisition, or Sale.{" "}
              </p>

              <p className="pl-20">
                We may transfer your Personal Information to another entity if
                Gitcha Home LLC is involved in a merger, acquisition,
                reorganization, restructuring, or other sale or transfer of all
                or any portion of its assets or business, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding in which information of our Services users are among
                the assets transferred. In this event, we will, if allowed, use
                reasonable efforts to notify you when your information is
                transferred to a third party (this notification may be made via
                email to you or by posting a prominent notice). Unless you
                consent otherwise, your Personal Information will remain subject
                to the promises made in this Policy, even after the transfer.
                But any information you provide after the transfer will likely
                be subject to a new privacy policy, and you should review that
                policy.
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                3.5. Required by Law.{" "}
              </p>

              <p className="pl-20">
                Gitcha reserves the right to release your information if Gitcha
                believes in good faith that (i) such release is required to
                comply with any law, rule, or regulation, or any search warrant,
                subpoena, court order, or similar legal process; or (ii)
                disclosure is necessary to protect or enforce Gitcha’s rights or
                property, to protect the rights or property of others, to
                protect your safety or the safety of others, in emergency
                situations, to defend against legal claims, to enforce our
                contractual or other rights, or to take action regarding illegal
                activities or suspected fraud.
              </p>

              <p className="pl-20 privacy-para-bold f-size-16">
                3.6. Public Areas of Sites.
              </p>
              <p className="pl-20">
                As described in Section 1.1, our Services enable users to post
                ads that will be viewable by other users of our Services. You
                should be aware that when you voluntarily disclose Personal
                Information for public viewing, the information may be
                collected, stored, used, and disclosed by other users of our
                Services. Such activities are beyond our control, and we shall
                have no responsibility for or liability regarding the protection
                or security of such content. Do not post Personal Information
                that you want to keep private.{" "}
              </p>

              <p className="privacy-para-bold">4. Do Not Track.</p>

              <p>
                Gitcha does not track its users over time and across third-party
                websites to provide interest-based advertising and therefore
                does not respond to Do Not Track signals. However, third parties
                may keep track of your browsing activities to provide
                interest-based advertising. For more information about
                third-party interest-based advertising, please refer to Section
                3.3.
              </p>

              <p className="privacy-para-bold">
                5. Changing or Updating Your Information.
              </p>

              <p>
                You can access or correct certain information by logging into
                your user account and changing the information, and you may also
                delete certain information by closing your user account. You can
                obtain access to the registration information Gitcha currently
                has on file for you and/or correct inaccuracies in such Personal
                Information by making a written request to Gitcha by mail or
                e-mail. For your protection, you may be asked to provide
                additional information to verify your identity before being
                granted access to your Personal Information.{" "}
              </p>

              <p>
                California Residents: Under California Civil Code sections
                1798.83-.84, California residents are entitled to ask for a
                notice identifying the categories of personal customer
                information that we share with our affiliates and/or third
                parties for marketing purposes and providing contact information
                for such affiliates and/or third parties. If you are a
                California resident and would like a copy of this notice, please
                submit a written request to:{" "}
              </p>
              <p className="f-medium">
                Gitcha Home LLC
                <br />
                450 Country Club Road, Suite 260
                <br />
                Eugene, Oregon 97401
              </p>
              <p>Attn:</p>

              <p className="privacy-para-bold">
                6. How Gitcha Protects Your Personal Information.
              </p>

              <p>
                Gitcha and its third-party providers have security measures in
                place at their physical facilities to protect against the loss,
                misuse or alteration of Personal Information collected from you.
                When Gitcha or its third-party providers transfer and receive
                certain types of sensitive information online, such as financial
                information, visitors are redirected to a secure server.
                However, due to computer hackers, electronic malfunctions, and
                other events, Gitcha cannot guarantee that such safeguards will
                always protect your Personal Information.
              </p>

              <p>
                Certain portions of our Services are only accessible by users
                who have an account. Your user account can only be accessed by
                entering the correct password. You are solely responsible for
                protecting your password, and you should not share your password
                or Personal Information with others. Please remember, we will
                never ask you for your password.
              </p>

              <p className="privacy-para-bold">7. Children's Privacy.</p>
              <p>
                Our site is intended for a general audience and Gitcha does not
                knowingly collect Personal Information from children under the
                age of 18. If we learn that we have collected a child’s Personal
                Information without parental consent, we will delete such
                information from our system. If you learn that your child has
                provided us with information, please let us know.
              </p>

              <p className="privacy-para-bold">8. Updates.</p>
              <p>
                Please visit this Policy from time to time, as Gitcha reserves
                the right to periodically update it. When the Policy is changed
                in a material way, we may provide you notice through your user
                account or the e-mail address you provided us along with the
                updated Policy. Your continued use of our Services after an
                update to this Policy indicates your acceptance of the terms of
                the updated Policy.
              </p>
              <p className="privacy-para-bold">9. Links.</p>
              <p>
                The Services may contain links to external websites and users
                may also post links to external websites. Gitcha is not
                responsible for the privacy practices or the content of such
                websites. You should check the applicable privacy policies of
                the third parties responsible for such websites when providing
                information on a feature or page operated by a third party.
              </p>

              <p className="privacy-para-bold">10. Contact Us.</p>
              <p>You can contact Gitcha:</p>
              <p>By mail:</p>
              <p className="f-medium">
                Gitcha Home LLC
                <br />
                450 Country Club Road, Suite 260
                <br />
                Eugene, Oregon 97401
              </p>
              <p>Attn:</p>
              <p>By e-mail:</p>

              <p>
                When you contact Gitcha, please provide your contact information
                and a detailed description of your request or privacy concern.
                Please include your exact name, address, telephone number, and
                email address at the time of your request.
              </p>

              <p className="f-size-14 f-medium mb-0">
                Last Updated: 11-09-2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GitchaPrivacyPolicy;
