import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import CreatePlusIcon from "../../images/create-plus-icon.svg";

const CreateNewBtn = (props) => {
 const {handleContinueClick, labelText} = props
  return (
    <a role="button"
    href={void 0} className="create-new-btn mb-3" onClick={()=>handleContinueClick()}>
      <span className="create-plus-icon">
        <img src={CreatePlusIcon} />
      </span>
      {labelText || 'Create New Want-Ad' }
      
    </a>
  );
};
export default CreateNewBtn;
