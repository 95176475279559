/**
 * @file   src\actions\wantAds.js
 * @brief  This file is responsible for redux actions related to wantads
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWantAds, getMyWantAds, getFavouriteWantAdList, getFavClientList, getWantAdById } from "../services/wantAdService";

// export const fetchWantAds = createAsyncThunk('wantAds/fetchAds', () => {
//     return getWantAds()
//   })

export const fetchWantAds = createAsyncThunk("wantAds/fetchAds", (filterQuery) => {
  return getWantAds(filterQuery);
});

export const fetchMyWantAds = createAsyncThunk(
  "wantAds/fetchMyWantAds",
  (requestPayload) => {
    return getMyWantAds(requestPayload);
  }
);

export const fetchFavouriteWantAds = createAsyncThunk(
  "wantAds/fetchFavouriteWantAds",
  (requestPayload) => {
    return getFavouriteWantAdList(requestPayload);
  }
);

export const fetchFavouriteClients = createAsyncThunk(
  "wantAds/fetchFavouriteClients",
  (requestPayload) => {
    return getFavClientList(requestPayload);
  }
);


export const fetchAdById = createAsyncThunk("wantAds/fetchAdById", (filterQuery) => {
  return getWantAdById(filterQuery);
});