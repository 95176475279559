import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { capitalizeString, handleTextBoxChange } from "../../../actions/common";
import GeoLocation from "../../Common/GeoLocation";
import {
  checkIsNull,
  checkIsNumber,
  createS3Folder,
  getLocalStorage,
  getUserId,
  removeDecimalZerosFromTextField,
  replaceComma,
  thousandSeperatedValue,
  trimString
} from "../../../helpers/common";
import TextBox from "../../Common/TextBox";
import {
  convertToNumber,
  removeNegativeInput,
} from "../../../helpers/validations";
import CheckBox from "../../Common/CheckBox";
import {
  DEFAULT_INVENTORY_MAP_ZOOM,
  INVENTORY_KEY,
  IS_APARTMENT_CONDO,
  IS_ENTIRE_PLACE,
  IS_GUEST_HOUSE,
  IS_PRIVATE_ROOM,
} from "../../../constants/common";
import { yrMaxInValid } from "../../../constants/createInventory";
import InventoryLocation from "./InventoryLocation";


const InventoryLocationWithMustHaves = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    locationAddress,
    noBedRm,
    noBathRm,
    sqFt,
    stories,
    lotSize,
    grgeSpots,
    adaAccess,
    IsNewConstruction,
    propertyType,
    propertySubType,
    neighborHood,
    highSchool,
    yearBuilt,
    invFormError,
    errorUpdatedAt,
    isEdit = false,
    lat,
    lng,
    mapZoom,
    mapType,
    city,
    state,
    isPropertyExist
  } = props;

  const allowedCountriesShortCodes = ["IN", "US"]
  const [yearBuiltError, setYearBuiltError] = useState("");
  

  const defaultProps = {
    center: {
      lat: convertToNumber(lat),
      lng: convertToNumber(lng),
    },
    zoom: mapZoom || DEFAULT_INVENTORY_MAP_ZOOM,
  };

  const adaAccessCheckBox = "adaAccess";
  const newConstructionCheckBox = "newConstruction";

  const isLotSizeDisable =
    propertyType === IS_ENTIRE_PLACE &&
    (propertySubType === IS_APARTMENT_CONDO ||
      propertySubType === IS_GUEST_HOUSE);


  useEffect(() => {
    if (!locationAddress) {
      const inventoryDetails = getLocalStorage(INVENTORY_KEY);
      const s3Folder = _.get(inventoryDetails, "s3ImageFolder", null);
      const userId = getUserId();
      if (!s3Folder) {
        getFolderName();
      }
    } 
  }, []);

  useEffect(() => {
    setYearBuiltError("");

    if (invFormError) {
      switch (invFormError) {
        case yrMaxInValid:
          setYearBuiltError("Enter Valid year");
          break;
         
      }
    }
  }, [invFormError, errorUpdatedAt]);

  const getFolderName = async (userId) => {
    const isWantAd = false;
    await createS3Folder(userId, isWantAd).then(function (result) {
      const folderName = result;
      handleFormValues({ s3ImageFolder: folderName });
    });
  };



  const updateValuesToState = (key, value) => {
    if (!checkIsNull(value)) {
      handleFormValues({ [key]: value });
    } else {
      handleFormValues({ [key]: "" });
    }
  };

  const handleBedRoomChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));
    // if (value === "0") {
    //   value = 1;
    // } 
     if (replaceComma(value) > 100) {
      value = 100;
    }
    updateValuesToState("noBedRm", replaceComma(value));
  };

  const handleBathRoomChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));
    // if (value === "0") {
    //   value = 1;
    // } 
     if (replaceComma(value) > 100) {
      value = 100;
    }
    updateValuesToState("noBathRm", replaceComma(value));
  };

  const handleSqFtChange = (e) => {
    let value = removeNegativeInput(handleTextBoxChange(e));
    // if (value === "0") {
    //   value = 1;
    // }
    // else if (replaceComma(value) > 10000) {
    //   value = 10000;
    // }
    updateValuesToState("sqFt", replaceComma(value));
  };

  const handleStoryChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("stories", replaceComma(value));
  };

  const handleLotSizeChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("lotSize", replaceComma(value));
  };
  const handleGarrageSpotChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    updateValuesToState("grgeSpots", replaceComma(value));
  };

  const handleYearBuitChange = (e) => {
    const value = removeNegativeInput(handleTextBoxChange(e));
    if (checkIsNumber(value)) {
      updateValuesToState("yearBuilt", value);
    }
  };

  const handleNeighborhoodChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    updateValuesToState("neighborHood", value);
  };

  const handleHighSchoolChange = (e) => {
    const value = capitalizeString(handleTextBoxChange(e));
    updateValuesToState("highSchool", value);
  };

  const handleCheckBoxClick = (e) => {
    if (e.target.id === adaAccessCheckBox) {
      handleFormValues({ adaAccess: e.target.checked });
    } else {
      handleFormValues({ IsNewConstruction: e.target.checked });
    }
  };



  const isPrivateRoom = propertyType === IS_PRIVATE_ROOM;

  return (
    <>
      {!locationAddress && !lat && !lng && (
        <GeoLocation handleFormValues={handleFormValues} />
      )}
      <div className="center-content-sec mw-524">
        <div className="wantad-location-wrap mb-3">
          <InventoryLocation
            defaultProps={defaultProps}
            locationAddress={locationAddress}
            handleFormValues={handleFormValues}
            handleContinueButtonState={handleContinueButtonState}
            updateValuesToState={updateValuesToState}
            isPrivateRoom={isPrivateRoom}
            mapType={mapType}
            noBedRm ={noBedRm}
            noBathRm = {noBathRm}
            sqFt = {sqFt}
            
          ></InventoryLocation>
          <div
            className={
              isPrivateRoom
                ? "section-control"
                : isPropertyExist
                ? "section-control"
                : "disabled-section-control"
            }
          >
            {!isPrivateRoom && (
              <div className="row">
                <div className="col-md-6">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Neighborhood</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      //errorMessage= {minError}
                      value={neighborHood}
                      onChange={(e) => {
                        handleNeighborhoodChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>High School</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      //errorMessage= {minError}
                      value={highSchool}
                      onChange={(e) => {
                        handleHighSchoolChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>
                      Bedrooms<span>*</span>
                    </Form.Label>
                    <TextBox
                      disabled={!isPropertyExist}
                      type="text"
                      class="form-control"
                      //errorMessage= {minError}
                      value={noBedRm ? thousandSeperatedValue(removeDecimalZerosFromTextField(noBedRm)) : ""}
                      onChange={(e) => {
                        handleBedRoomChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>
                      Bathrooms<span>*</span>
                    </Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      //errorMessage= {minError}
                      value={noBathRm? thousandSeperatedValue(removeDecimalZerosFromTextField(noBathRm)) : ""}
                      onChange={(e) => {
                        handleBathRoomChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>
                      Square footage<span>*</span>
                    </Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      //errorMessage= {minError}
                      value={sqFt? thousandSeperatedValue(removeDecimalZerosFromTextField(sqFt)) : ""}
                      onChange={(e) => {
                        handleSqFtChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Stories</Form.Label>

                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(stories) || ""}
                      onChange={(e) => {
                        handleStoryChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                {!isLotSizeDisable && (
                  <div className="col-md-3">
                    <div className="common-input-wrap mb-2">
                      <Form.Label>Lot size (Acres)</Form.Label>

                      <TextBox
                        type="text"
                        class="form-control"
                        disabled={!isPropertyExist}
                        //errorMessage= {minError}
                        value={lotSize? thousandSeperatedValue(removeDecimalZerosFromTextField(lotSize)) : ""}
                        onChange={(e) => {
                          handleLotSizeChange(e);
                        }}
                      ></TextBox>
                      <div className="error-msg"></div>
                    </div>
                  </div>
                )}

                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Garage Spots</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      //errorMessage= {minError}
                      value={thousandSeperatedValue(grgeSpots) || ""}
                      onChange={(e) => {
                        handleGarrageSpotChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="common-input-wrap mb-2">
                    <Form.Label>Year Built</Form.Label>
                    <TextBox
                      type="text"
                      class="form-control"
                      disabled={!isPropertyExist}
                      errorMessage={yearBuiltError}
                      value={yearBuilt}
                      onChange={(e) => {
                        handleYearBuitChange(e);
                      }}
                    ></TextBox>
                    <div className="error-msg"></div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-4">
                <div className="field-wrap mt-0 mb-0">
                  <label
                    className="form-check-label checkbox-label"
                    htmlFor={adaAccessCheckBox}
                  >
                    <CheckBox
                      class="form-check-input"
                      type="checkbox"
                      disabled={isPrivateRoom ? false : !isPropertyExist}
                      id={adaAccessCheckBox}
                      checked={adaAccess}
                      onChange={(e) => handleCheckBoxClick(e)}
                    />{" "}
                    ADA Accessible
                  </label>
                </div>
              </div>
              {!isPrivateRoom && (
                <div className="col-md-8">
                  <div className="field-wrap mt-0 mb-0">
                    <label
                      className="form-check-label checkbox-label"
                      htmlFor={newConstructionCheckBox}
                    >
                      <CheckBox
                        class="form-check-input"
                        type="checkbox"
                        disabled={!isPropertyExist}
                        id={newConstructionCheckBox}
                        checked={IsNewConstruction}
                        onChange={(e) => handleCheckBoxClick(e)}
                      />{" "}
                      New Construction
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryLocationWithMustHaves;
