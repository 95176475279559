import React from "react";
import NiceToHaveHelper from "./NiceToHaveHelper";

const Property = (props) => {
  const { properties, itemArray, handleItemClick } = props;
  return (
    <NiceToHaveHelper
      list={properties}
      selectedItems={itemArray}
      propertyClick={handleItemClick}
    />
  );
};
export default Property;
