import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getInventoryById } from "../../../services/inventoryService";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../../../constants/pageURLs";

const AddPhotosModal = (props) => {
  const {show, onHide, inventoryId} = props;
  const navigate = useNavigate();
  const [inventoryDetails, setInventoryDetails] = useState();

  useEffect(()=>{
    getInventoryDetails(inventoryId)
   
  }, [inventoryId])

  const onEdit = () => {
    navigate(pageURLs.mobileEditInventory, {
      state: {
        data: inventoryDetails,
        inventoryId: inventoryId,
      },
    });

  };

  const getInventoryDetails = async (inventoryId) => {
    if (inventoryId) {
      await getInventoryById(inventoryId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setInventoryDetails(responseResult);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } 
  };


  return (
    <Modal show={show} onHide={onHide} centered className="modalPopupstyle delete-popup">
        <Modal.Body className="text-center">
        <h3>Add photos to property</h3>
        <p>In order to introduce this property, you'll have to add at least one image of your property. 
            We recommend adding at least 5 to give the person a good idea of what you have.</p>
        <div className="btn-wrap">
            <button type="button" className="btn btn-primary w-100" onClick={()=>{onEdit()}}>Add Photos</button>
            <button type="button" className="btn btn-secondary w-100" onClick={onHide}>Not right now</button>
        </div>
        </Modal.Body>
    </Modal>
  );
};
export default AddPhotosModal;
