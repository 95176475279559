import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormLabel } from "react-bootstrap";
import { handleRadioButonChange } from "../../actions/common";
import ButtonGroups from "../Common/ButtonGroups";
import { STORY_ANY, STORY_SINGLE } from "../../constants/common";
import WantAdSquareFeet from "../Common/WantAdSquareFeet";
import { convertToNumber } from "../../helpers/validations";

const WantAdRentMustHaves = (props) => {
  const {
    handleContinueButtonState,
    handleFormValues,
    noOfBedrooms,
    noOfBathrooms,
    SFMin,
    SFMax,
    garageSpace,
    alwLrgDogs,
    alwSmlDogs,
    alwCats,
    adaAccess,
    stories,
    isEdit = false,
    mustHaveErrors,
    errorUpdatedAt,
  } = props;

  const [gSpace, setGSpace] = useState("");

  useEffect(() => {
    handleContinueButton();
    clearGarrageLocalState();
  }, []);

  useEffect(() => {
    handleContinueButton();
  }, [noOfBedrooms, noOfBathrooms]);

  const clearGarrageLocalState = () => {
    setGSpace("");
  };

  const handleContinueButton = () => {
    clearGarrageLocalState();
    let isDisable = false;

    if (noOfBedrooms && noOfBathrooms) {
      handleContinueButtonState(isDisable);
    } else {
      isDisable = true;
      handleContinueButtonState(isDisable);
    }
  };

  const handleBedRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBedRm: value });
    clearGarrageLocalState();
    //handleContinueButton()
  };

  const handleGarageClick = (e) => {
    const value = handleRadioButonChange(e);
    setGSpace(value);
    handleFormValues({ grgSpce: value });
  };

  const handleStorySelection = (selectedStory) => {
    if (selectedStory === stories) {
      handleFormValues({ stories: "" });
    } else {
      handleFormValues({ stories: selectedStory });
    }
  };

  const handleCheckBoxItemClick = (e) => {
    clearGarrageLocalState();
    const id = e.target.id;
    switch (convertToNumber(id)) {
      case 1:
        handleFormValues({ alwLrgDogs: e.target.checked });
        break;
      case 2:
        handleFormValues({ alwSmlDogs: e.target.checked });
        break;
      case 3:
        handleFormValues({ alwCats: e.target.checked });
        break;
      case 4:
        handleFormValues({ adaAccess: e.target.checked });
        break;
    }
  };

  const handleBathRoomClick = (e) => {
    const value = handleRadioButonChange(e);
    handleFormValues({ noBathRm: value });
    clearGarrageLocalState();
    //handleContinueButton()
  };

  const loadCheckBoxItems = () => {
    const itemArray = [
      { id: 1, text: "Allows large dogs" },
      { id: 2, text: "Allows small dogs" },
      { id: 3, text: "Allows cats" },
      { id: 4, text: "ADA Accessible" },
    ];
    const List = itemArray.map((pt) => {
      const id = convertToNumber(_.get(pt, "id", ""));
      const text = _.get(pt, "text", "");
      let isChecked;
      switch (id) {
        case 1:
          isChecked = alwLrgDogs;
          break;
        case 2:
          isChecked = alwSmlDogs;
          break;
        case 3:
          isChecked = alwCats;
          break;
        case 4:
          isChecked = adaAccess;
          break;
      }
      return (
        <label className="check-wrap mb-3" key={id}>
          <input
            className="form-check-input"
            type="checkbox"
            name="chkbox"
            id={id}
            checked={isChecked}
            onChange={(e) => handleCheckBoxItemClick(e)}
          />
          <span>
            <div className="wantad-type-info">
              <h2 className="pb-0">{text}</h2>
            </div>
          </span>
        </label>
      );
    });

    return List;
  };

  return (
    <div className={!isEdit ? "center-content-sec mw-524" : ""}>
      <div className="btn-group-wrap">
        <FormLabel>
          Bedrooms<span>*</span>
        </FormLabel>
        <ButtonGroups
          variant="outline-primary"
          value={noOfBedrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBedRoomClick(e)}
        />
      </div>
      <div className="btn-group-wrap">
        <FormLabel>
          Bathrooms<span>*</span>
        </FormLabel>

        <ButtonGroups
          variant="outline-primary"
          value={noOfBathrooms}
          items={["Any", "1+", "2+", "3+", "4+", "5+"]}
          onClick={(e) => handleBathRoomClick(e)}
        />
      </div>
      <div className="wantad-timing-wrap">
        <WantAdSquareFeet
          handleFormValues={handleFormValues}
          SFMax={SFMax}
          SFMin={SFMin}
          sqFtError={mustHaveErrors}
          errorUpdatedAt={errorUpdatedAt}
        />
      </div>

      <div className="btn-group-btm-wrap">
        <FormLabel>Garage Spaces</FormLabel>

        <ButtonGroups
          variant="outline-primary"
          section="Garage"
          value={garageSpace}
          currentSelectedValue={gSpace}
          items={["Any", "1+", "2+", "3+"]}
          onClick={(e) => handleGarageClick(e)}
          handleFormValues={handleFormValues}
        />
      </div>

      <div className="btn-group-btm-wrap">
        <FormLabel>Stories</FormLabel>
        <ButtonGroup aria-label="Basic example">
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_ANY)}
            variant={
              stories === STORY_ANY
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Any
          </Button>
          <Button
            className="w-50"
            value={stories}
            onClick={() => handleStorySelection(STORY_SINGLE)}
            variant={
              stories === STORY_SINGLE
                ? "outline-primary active"
                : "outline-primary"
            }
          >
            Single Story Only
          </Button>
        </ButtonGroup>
      </div>
      <div className="field-wrap mt-3 mb-0">
        <label className="form-label">Additional Requirements</label>
        <div className="wantad-type-wrap">{loadCheckBoxItems()}</div>
      </div>
    </div>
  );
};
export default WantAdRentMustHaves;
