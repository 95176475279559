import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { nameValidation } from "../../../helpers/validations";
import { getLocalStorage, toTitleCase } from "../../../helpers/common";
import Button from "react-bootstrap/Button";
import FldCloseIcon from "../../images/round-close-icon.svg";
import TextBox from "../../../components/Common/TextBox";
import ValidationAlertIcon from "../../images/validation-alert-icon.svg";
import { USER_INFO_KEY } from "../../../constants/common";

const MemberProfile = (props) => {
  const { handlMemberDeatilsSave } =
    props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const handleFirstName = (name) => {
    setFirstNameError("");
    setFirstName(name);
  };

  const handleLastName = (name) => {
    setLastNameError("");
    setLastName(name);
  };

  const clearField = (field) => {
    if (field === "fName") {
      setFirstName("");
    } else {
      setLastName("");
    }
  };

  useEffect(() => {
    const userDetails = getLocalStorage(USER_INFO_KEY);
    const firstName = _.get(userDetails, "userInfo.firstName", null);
    const lastName = _.get(userDetails, "userInfo.lastName", null);
    firstName && setFirstName(firstName);
    lastName && setLastName(lastName);
  }, []);

  const handleContinue = () => {
    setFirstNameError("");
    setLastNameError("");
    let isValid = true;
    if (firstName && !nameValidation(firstName.trim())) {
      isValid = false;
      setFirstNameError("Invalid input");
    }
    if (lastName && !nameValidation(lastName.trim())) {
      isValid = false;
      setLastNameError("Invalid input");
    }
    if (isValid) {
      handlMemberDeatilsSave(firstName, lastName);
    }
  };
  return (
    <div className="gitcha-main-wrapper pb-0 bg-grey-variant">
      <div className="content-wrapper pt-0 h-100">
        <div className="inner-layout-header-new bg-transparent d-none">
          <div className="review-plan-header w-100">
            <div className="signup-login-header-top signup-header-txt clearfix">
              {/* <a className="back-link" href={void 0} role="button">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1003 5.39972C10.3932 5.69262 10.3932 6.16749 10.1003 6.46038L5.31066 11.2501H20.5C20.9142 11.2501 21.25 11.5858 21.25 12.0001C21.25 12.4143 20.9142 12.7501 20.5 12.7501H5.31066L10.1003 17.5397C10.3932 17.8326 10.3932 18.3075 10.1003 18.6004C9.80744 18.8933 9.33256 18.8933 9.03967 18.6004L2.96967 12.5304C2.82902 12.3897 2.75 12.199 2.75 12.0001C2.75 11.8011 2.82902 11.6104 2.96967 11.4697L9.03967 5.39972C9.33256 5.10683 9.80744 5.10683 10.1003 5.39972Z"
                    fill="#1B1C1E"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
        <div className="inner-layout-content-new h-100">
          <div className="get-agent-wrap pt-5 clearfix">
            <h2 className="pb-3">Let’s complete your profile</h2>

            <div className="row">
              <div className="col-6 pe-2">
                <div className="mob-fld-wrap position-relative mb-2">
                  <label className="form-label">
                    First name <span>*</span>
                  </label>

                  <TextBox
                    id="fName"
                    name="fName"
                    type="text"
                    value={toTitleCase(firstName)}
                    placeholder="First name"
                    class={
                      firstNameError
                        ? "validation-alert-brdr form-control mob-pr-44"
                        : "form-control mob-pr-44"
                    }
                    onChange={(e) => handleFirstName(e.target.value)}
                    errorClass="error-validation-msg"
                    errorMessage={firstNameError}
                  />
                  {firstName && !firstNameError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => clearField("fName")}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {firstNameError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6 ps-2">
                <div className="mob-fld-wrap position-relative mb-2">
                  <label className="form-label">
                    Last name <span>*</span>
                  </label>

                  <TextBox
                    id="lName"
                    name="lName"
                    type="text"
                    value={toTitleCase(lastName)}
                    placeholder="Last name"
                    class={
                      lastNameError
                        ? "validation-alert-brdr form-control mob-pr-44"
                        : "form-control mob-pr-44"
                    }
                    errorClass="error-validation-msg"
                    onChange={(e) => handleLastName(e.target.value)}
                    errorMessage={lastNameError}
                  />
                  {lastName && !lastNameError && (
                    <Button
                      variant=""
                      className="fld-close-btn"
                      onClick={() => clearField()}
                    >
                      <img src={FldCloseIcon} />
                    </Button>
                  )}
                  {lastNameError && (
                    <div className="validation-alert">
                      <img src={ValidationAlertIcon} />
                    </div>
                  )}
                </div>
              </div>

              {/*<div className="col-12">
        <label class="form-check-label checkbox-label mb-28" for="IAmRepresenting1">
          <input class="form-check-input" type="checkbox" id="IAmRepresenting1" />
          I'm a real-estate professional
        </label>
  </div>*/}
            </div>

            <Button
              variant="primary"
              className="btn w-100 mb-4"
              disabled={firstName === "" || lastName === "" ? true : false}
              onClick={() => handleContinue()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberProfile;
