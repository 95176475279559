import axios from "axios";
import jwtDecode from "jwt-decode"
import { clearLocalStorageOnLogout, getLocalStorage } from "../helpers/common";
import { USER_INFO_KEY } from "../constants/common";
import { tokenRefresh } from "./loginService";
import { GET_GOOGLE_USER_INFO } from "../constants/endPoints";

const clearSession = () =>{
  clearLocalStorageOnLogout() 
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) || ( window.location.pathname === "/home")
  ) {
    window.location.reload();
  }
}

const getHeaders = async (accessToken, refToken, userInfo, header) => {   
  const decodedToken = jwtDecode(accessToken)
  const expirationTime = decodedToken?.exp * 1000 - 60000

  if (Date.now() < expirationTime) {
    if(header){
      return {header}
    } else {
      return {Authorization: accessToken}
    }
    
  } else {
    const decodedRefreshToken = jwtDecode(refToken)
    if (Date.now() < decodedRefreshToken?.exp * 1000){ 
     return await tokenRefresh({'refreshToken': refToken}).then((res)=>{
        if(res?.accessToken) {
          Object.entries(userInfo?.token).forEach(([key, value]) => {
            if(key === "accessToken"){     
              userInfo.token["accessToken"] = res.accessToken;
             }
          });
          localStorage.setItem(USER_INFO_KEY,JSON.stringify(userInfo))
          return {Authorization: res.accessToken};
        }  
        else{
          // alert("Your session has expired. Please login to continue.");
          clearSession();
        }      
      }).catch((error)=>{
        // alert("Your session has expired. Please login to continue.");
        clearSession()  
      })
    }
    else {
      // alert("Your session has expired. Please login to continue.");
      clearSession()
    }
  }
}

export async function apiCall(url, method, data, userId = null, token = null, header=null, isFileUploadToS3 = false) {
  let accessToken = null;
  let refreshToken = null;
  let userInfo = null;
  if(token) {
    accessToken = token;
  } else {
     userInfo = getLocalStorage(USER_INFO_KEY)
    if (
      userInfo &&
      userInfo !== ""
    ) {
      accessToken =  _.get(userInfo, 'token.accessToken', null);
      refreshToken = _.get(userInfo, 'token.refreshToken', null);
    }
  }

  let headers = { Authorization: 'Bearer '+ accessToken}  
  if(userInfo != null && refreshToken) {
    const response = await getHeaders(accessToken, refreshToken, userInfo, header)
    headers = response
  }
  
  if(url != GET_GOOGLE_USER_INFO){
    headers = {...headers, 
      "api-key" : process.env.REACT_APP_REST_API_KEY,
    } 
  }
  const response = await axios({
    url,
    method,
    params: method === "GET" && data ? data : {},
    data,
    headers,
  });
 

  if (response) {
    if(isFileUploadToS3) {   // For S3 file upload response is different than internal API response. Data is empty in this case, so return response itself instead of response data.
      return response;
    } else {
      return response.data;
    }
  } else {
    return [];
  }
}

export async function apiCallWithoutToken(url, method, data, userId = null, token = null, endPoint) {  
   const headers = {
   'Content-Type': 'application/json',
   "api-key" : process.env.REACT_APP_REST_API_KEY
  }
  const response = await axios({
    url,
    method,
    params: method === "GET" && data ? data : {},
    data,    
    headers
  });
  if (response) {
    return response.data;
  } else {
    return [];
  }
}


export async function fileUploadApiCall(url, method, data, userId = null, token = null, endPoint) {  
  const headers = {'Content-Type': 'image/jpeg'}
  const options = {
    headers: {
      'ContentType': 'image/jpeg',
    },
  };

  const response = await axios.put(url, data, options);
//  const response = await axios({
//    url,
//    method,
//    params: method === "GET" && data ? data : {},
//    data,    
//    headers
//  });

 if (response) {
   return response;
 } else {
   return [];
 }
}
