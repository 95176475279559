/**
 * @file   src\components\Common\FenceIcn.js
 * @brief  This file is responsible for showing Icons in property display
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from "react";
import FenceIcon from "../../images/fence-icon.svg";

const FenceIcn = (props) => {
  const { icon } = props;
  return (
    <>
      <div className="fence-icn-wrap">
        {icon ? (
          <img src={require("..//../images/" + icon)} />
        ) : (
          <img src={FenceIcon} />
        )}
      </div>     
    </>
  );
};
export default FenceIcn;
