import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

const NotesDeleteDropdown = (props) => {
    const { clientId, deleteNote, noteId } = props;
  return (
    <div>
        <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-notes" className="action-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M9.33333 8.3335C9.33333 7.59712 8.73638 7.00016 8 7.00016C7.26362 7.00016 6.66667 7.59712 6.66667 8.3335C6.66667 9.06988 7.26362 9.66683 8 9.66683C8.73638 9.66683 9.33333 9.06988 9.33333 8.3335Z" fill="#4A4C4F" />
                    <path d="M9.33333 4.66683C9.33333 3.93045 8.73638 3.3335 8 3.3335C7.26362 3.3335 6.66667 3.93045 6.66667 4.66683C6.66667 5.40321 7.26362 6.00016 8 6.00016C8.73638 6.00016 9.33333 5.40321 9.33333 4.66683Z" fill="#4A4C4F" />
                    <path d="M9.33333 12.0002C9.33333 11.2638 8.73638 10.6668 8 10.6668C7.26362 10.6668 6.66667 11.2638 6.66667 12.0002C6.66667 12.7365 7.26362 13.3335 8 13.3335C8.73638 13.3335 9.33333 12.7365 9.33333 12.0002Z" fill="#4A4C4F" />
                </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
                <Dropdown.Item onClick={()=>deleteNote({clientId : clientId, noteId: noteId})}>
                    Delete Note
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
    
  );
};
export default NotesDeleteDropdown;
