/**
 * @file   src\pages\WantadDetails.js
 * @brief  This file is responsible for showing want ad details page
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import CommonHeader from "../components/Onboarding/CommonHeader";
import StrengthenIcon from "../images/ad-strengthen-icon.svg";
import AdIntroIcon from "../images/ad-intro-icon.svg";
import LakeViewIcon from "../images/lake-view-icon.svg";
import ConsumerIcon from "../images/consumer-icon.svg";
import FinancingIcon from "../images/financing-icon.svg";
import PrResIcon from "../images/primary-residence-icon.svg";
import ShareIcon from "../images/share-icon.svg";
import MoreIcon from "../images/more-icon.svg";
import ReportWantAdModal from "../components/MemberWantAd/ReportWantAdModal";
import ViewWantAdIntroductions from "../components/Introduction/ViewWantAdIntroductions";
import ViewIntroductionModal from "../components/Introduction/ViewIntroductionModal";
import { usePrevious } from "../hooks/usePrevious";
import ArchiveModal from "../components/MyWantAd/ArchiveModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  getPropertyIcon,
  getTooltipText,
  getCombinedText,
} from "../helpers/getPropertyIcons";
import {
  BUY,
  LONG_RENT,
  SHORT_RENT,
  LOT_LAND,
  WANT_AD_KEY,
  USER_INFO_KEY,
  INVENTORY_KEY,
  STRENGTHEN_WANT_AD_KEY,
  IS_MULTI_FAMILY,
  IS_LAND,
  USER_TYPE_AGENT,
  PRO_MEMBER_PLAN,
  INVESTOR_MEMBER_PLAN,
} from "../constants/common";
import ShareYourAdModal from "../components/MemberWantAd/ShareYourAdModal";
import ShareMyWantAdSmallModal from "../components/MemberWantAd/ShareMyWantAdSmallModal";
import {
  loadTimeLineHtml,
  getLocalStorage,
  setLocalStorage,
  getUserId,
  loggedInUser,
  getUserType,
  loadPreferredLocationHtml,
  loadCompensationText,
  locationDisplay,
} from "../helpers/common";
import { pageURLs } from "../constants/pageURLs";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  favouriteWantAd,
  getAgentsList,
  getWantAdById,
  unFavouriteWantAd,
  archiveWantAd,
  unArchiveWantAd,
  deleteWantAd,
} from "../services/wantAdService";
import { convertToNumber } from "../helpers/validations";
import SendIntroduction from "../components/Introduction/SendIntroduction";
import HearttIcon from "../images/heart-icon.svg";
import HearttIconFilled from "../images/heart-icon-filled.svg";
import { getIntroductions } from "../services/introductionService";
import { getIntroductionDetaisById } from "../services/introductionService";
import AddToClientWantAdModal from "../components/MemberWantAd/AddToClientWantAdModal";
import { handleTextBoxChange } from "../actions/common";
import { toast } from "react-toastify";
import DeleteModal from "../components/Common/DeleteModal";
import { buildWantAdMustHavesHTML, loadInvestmentCriteria } from "../helpers/createWantAd";
import WantadDetailsSkeleton from "./WantadDetailsSkeleton";
import base64 from "base-64";
import utf8 from "utf8";
import { useRef } from "react";
import { useSelector } from "react-redux";
import AgentProfileViewModal from "../components/Introduction/AgentProfileViewModal";
import noImage from "../images/no-image-placeholder.png";
import  MinCapIcon from "../images/minimum-icn.svg";
import userProfile from "../appState/userProfile";
import { getUserPlanDetails } from "../services/userprofileService";

const WantadDetails = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  searchParams.get("wantAdId");
  const id = searchParams.get("wantAdId");
  let decodedId;
  let idFromURL;
  try {
    const bytes = base64.decode(id);
    decodedId = utf8.decode(bytes);
    if (decodedId) {
      let decodedData = decodedId.split("-");
      idFromURL = decodedData.length > 0 ? decodedData[1] : null;
    }
  } catch { }

  const [showShareModal, setShareModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showReportModal, setReportModal] = useState(false);
  const handleClose = () => setShareModal(false);
  const [showStrengthen, setShowStrengthen] = useState(false);
  const [adDetails, setAdDetails] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [showViewIntroductionModal, setViewIntroductionModal] = useState(false);
  const adType = _.get(adDetails, "adType", "");
  const propertyType = convertToNumber(_.get(adDetails, "prprtyType", ""));
  const adTypeBasedOnProperty = propertyType === 6 ? LOT_LAND : adType;
  const isAdTypeIsLand = adTypeBasedOnProperty === LOT_LAND;
  const nice2Have = _.get(adDetails, "nice2Have", []);
  const [introductionList, setIntroductionList] = useState([]);

  const [introCount, setIntroCount] = useState(0);
  const wantAd = getLocalStorage(WANT_AD_KEY);
  const userType = getUserType();
  //This variable is used for hiding strengthen want ad section and change intro title and description, if this page reaches from home feed.
  const isFromHomeFeed = idFromURL
    ? true
    : _.get(wantAd, "isFromHomeFeed", false);
  //This variable is used for hiding strengthen want ad section and change intro title and description, if this page reaches from all introductions.
  const isFromAllIntroduction = _.get(wantAd, "isFromAllIntroduction", false);
  const wantAdId = idFromURL ? idFromURL : _.get(wantAd, "wantAdId", null);
  const introId = _.get(wantAd, "introId", null);
  const isEdit = _.get(wantAd, "isEdit", false);
  const WantCreatedUserId = _.get(adDetails, "userId", false);
  const loggedInUserId = getUserId();
  const [introDetails, setIntroDetails] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false); //
  const WantCreatedUserName =
    _.get(adDetails, "userFirstName", "") +
    " " +
    _.get(adDetails, "userLastName", "");
  const wantAdClientId = _.get(adDetails, "clientId", "");

  const isFavourite = _.get(adDetails, "isFavourite", false);
  const propertySubTypes = _.get(adDetails, "prptySubTypes", []);
  const handleIntroDetailModal = (modalState) =>
    setViewIntroductionModal(modalState);
  const INTRO_LIST_PAGE_LIMIT = 3;
  const previousPage = usePrevious(currPage);
  const isLoadIntroductionList =
    (isFromHomeFeed &&
      WantCreatedUserId &&
      WantCreatedUserId === loggedInUserId) ||
    (introId ? true : false);
  const mapImage = _.get(adDetails, "mapImg", null);

  const [isFavouriteStatus, setIsFavourite] = useState("");

  const [modalShow, setModalShow] = useState(false); //
  const [searchText, setSearchText] = useState(""); //
  const [results, setResults] = useState([]);
  const [reloadAgents, setReloadAgents] = useState(0);
  const user = loggedInUser();
  const sessionData = getLocalStorage(USER_INFO_KEY);
  const consumerType = adType == BUY ? "Buyer" : "Renter";
  const [archiveModalShow, setArchiveModalShow] = useState(false); //
  const [archiveStatus, setArchiveStatus] = useState(false); //
  const [reload, setReload] = useState(0);
  const [isWantAdCreated, setWantAdCreated] = useState(false);

  const [skeltonLoad, setSkeltonLoad] = useState(true); //
  const pageTopRef = useRef(null);

  const [isPremiumUser, setIsPremiumUser] = useState(null);
  const userDetailsFromStore = useSelector((state) => state.userProfile.userDetails)

  const [showAgentProfileViewModal, setAgentProfileViewModal] = useState(false);
  const agentProfileViewModalClose = () => setAgentProfileViewModal(false);
  const agentProfileViewModalShow = () => setAgentProfileViewModal(true);
  const purchaseType = convertToNumber(_.get(adDetails, "prchTypeId", ''));
  const userDetails = userProfile();
  const [userPlan, setUserPlan] = useState("");
  const [wantAdCreatedUserPlan, setWantAdCreatedUserPlan] = useState("");

  useEffect(()=>{
    if(idFromURL){
      setLocalStorage(WANT_AD_KEY, {"wantAdId":idFromURL})
    }
  },[idFromURL])


  useEffect(() => {
    if(userDetails){
      if(userDetails?.length===1){
        if(userDetails[0]?.PlanName){
         setUserPlan(userDetails[0]?.PlanName)
        }
      }
      else {
        if(userDetails[0]?.PlanName === 'Trial'){
          setUserPlan(userDetails[1]?.PlanName)
        }
        else{
          setUserPlan(userDetails[0]?.PlanName)
        }
      }
    }    
  }, [userDetails]);


  useEffect(()=>{
    if(userPlan===PRO_MEMBER_PLAN){
      setIsPremiumUser(true)
    }
    else{
      setIsPremiumUser(false)
    }
  },[userPlan])

  useEffect(() => {
    getWantAdDetails(wantAdId);
    getCreatedUserPlan(wantAdId);
  }, [reload]);

  useEffect(() => {
    window.scrollTo(0, pageTopRef.current.offsetTop); // focus to the top section
    const showSuccess = _.get(wantAd, "showSuccess", null);
    if (showSuccess && !isFromHomeFeed && !isFromAllIntroduction) {
      setSuccessModal(true);
    } else if (!isFromHomeFeed && !isFromAllIntroduction) {
      // showStrengthen && setShareModal(true);
      // setShareModal(false);
      if (!introId) {
        setShareModal(true);
        setWantAdCreated(true);
      }
    }
    getWantAdDetails(wantAdId);
  }, [introId]);

  useEffect(() => {
    if (_.get(adDetails, "isAgentCreated", "") != 1) {
      if (
        (adTypeBasedOnProperty === BUY ||
          adTypeBasedOnProperty === LONG_RENT) &&
        !_.get(adDetails, "isStrengthen", false) &&
        !isFromHomeFeed &&
        !isFromAllIntroduction &&
        !introId &&
        isEdit == false
      ) {
        setShowStrengthen(true);
      } else {
        setShowStrengthen(false);
      }
    }

    setIsFavourite(isFavourite);
  }, [adDetails]);

  //Fetch wantad details
  const getWantAdDetails = async (wantAdId) => {
    if (wantAdId) {
      await getWantAdById(wantAdId, loggedInUserId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            setSkeltonLoad(false);
            setAdDetails(responseResult);
            setLocalStorage(WANT_AD_KEY, { ...getLocalStorage(WANT_AD_KEY), wantAdType: responseResult?.adType })
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      navigate(pageURLs.home);
    }
  };

  
//To fetch current subscription plan of user who has created wantad
  const getCreatedUserPlan = async (wantAdId) => {
    if (wantAdId) {
      await getUserPlanDetails({wantAdId:wantAdId})
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
           setWantAdCreatedUserPlan(responseResult[0]?.PlanName);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } 
  };

  const updateIntroPageOnScroll = () => {
    setCurrPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    isLoadIntroductionList && getIntroductionList(currPage, wantAdId);
  }, [currPage, introId, isLoadIntroductionList]);

  //Get introductions received against the wantad
  const getIntroductionList = async (page, wantAdId) => {
    const userId = _.get(getLocalStorage(USER_INFO_KEY), "userInfo.userId", "");
    const reqPayload = {
      wantAdId: wantAdId,
      page: previousPage === currPage ? 1 : page,
      limit: INTRO_LIST_PAGE_LIMIT,
      isAccept: "1",
    };
    await getIntroductions(reqPayload).then((response) => {
      if (response) {
        const list = _.get(response, "result.List", null);
        const count = _.get(response, "result.Count", "");
        if (response.errorCode === 0) {
          if (!previousPage || previousPage === currPage) {
            setIntroductionList(list); // Loading new introduction list when selecting each introduction. So not appending with the existing list.
          } else if (previousPage !== currPage) {
            setIntroductionList([...introductionList, ...list]);
          }
          setIntroCount(count);
        } else {
          console.log(`####error`);
        }
      }
    });
  };
// To show the share wantad popup
  const handleOnShare = () => {
    setWantAdCreated(false);
    setShareModal(true);
  };

  const handleStrengthenDismiss = () => setShowStrengthen(false);

  const showShareModalPopup = () => {
    setShareModal(true);
    setSuccessModal(false);
    updateLocalStorage();
  };
 
  /**
   *  Get introduction details by passsing introduction id
   */
  const getIntroductionDetails = async (introId, IsAccept = 0) => {
    if (IsAccept == 1 || IsAccept == true) {
      setLocalStorage(INVENTORY_KEY, { introductionId: introId, adType: adType });
      navigate(pageURLs.viewIntroducedInventory);
    } else {
      handleIntroDetailModal(true);
      if (introId) {
        await getIntroductionDetaisById(introId)
          .then((response) => {
            const responseResult = _.get(response, "result", null);
            if (response.errorCode === 0 && responseResult) {
              setIntroDetails(responseResult);
            } else {
              console.log("Something went wrong");
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
      }
    }
  };

  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    updateLocalStorage();
  };

  const handleReportPopup = (isShow) => setReportModal(isShow);

  const updateLocalStorage = () => {
    const wantAd = getLocalStorage(WANT_AD_KEY);
    const wantAdId = _.get(wantAd, "wantAdId", null);
    // console.log("wantAdId", wantAdId);
    setLocalStorage(WANT_AD_KEY, { wantAdId: wantAdId });
  };

  const loadEachNiceToHave = (section) => {
    const selectedItems =
      nice2Have && nice2Have.filter((item) => item.tab === section);
    return loadNiceToHaveList(selectedItems, section.charAt(0));
  };

  const finalNiceToHaveList = () => {
    const niceToHaveMasterArray = isAdTypeIsLand
      ? ["Views", "Characteristics"]
      : ["Property", "Layout", "Interior", "Exterior", "Other"];
    const finalList =
      niceToHaveMasterArray &&
      niceToHaveMasterArray.map((nh) => {
        return loadEachNiceToHave(nh);
      });
    return finalList;
  };
/**
   * Navigate to edit wantad on selecting edit option from dropdown menu 
   */
  const onEdit = () => {
    if (userType == 2) {
      navigate("/agent-create-want-ad", { state: { data: adDetails } });
    } else {
      navigate("/edit-want-ad", { state: { data: adDetails } });
    }
  };

  const onWantAdDelete = () => {
    setDeleteModalShow(true);
  };

  /**
   * To handle the want-ad delete
   */
  const handleDeleteWantAd = async () => {
    // setLoading(true)
    if (wantAdId) {
      await deleteWantAd(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);

          if (response.errorCode === 0 && responseResult) {
            toast.success("Your want-ad has been deleted.");
            setDeleteModalShow(false);
            navigate("/my-want-ad");
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };
  /**
   * Show popup to handle pause want ad
   * @param {*} id 
   */
  const onWantAdArchived = (id) => {
    setArchiveModalShow(true);
  };
/**
 * To handle the want-ad  pause
 */
  const handleArchiveWantAd = async () => {
    if (wantAdId) {
      await archiveWantAd(wantAdId)
        .then((response) => {
          const responseResult = _.get(response, "result", null);
          if (response.errorCode === 0 && responseResult) {
            toast.dismiss();
            toast.success("Your want-ad has been paused.");
            setArchiveModalShow(false);
            setArchiveStatus(!archiveStatus);
            setReload(reload + 1);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  /**
   * To handle the want-ad un pause
   */
  const handleUnArchiveWantAd = async (wantAd) => {
    if (wantAd?.wantAdId) {
      if ((wantAd?.Buy_or_Rent == 2 && wantAd?.Move_in_TimeLine == 11) || (wantAd?.Buy_or_Rent == 3)) {
        if (userType == 2) {
          navigate("/agent-create-want-ad", { state: { data: adDetails,  isFromUnPause: true } });
        } else {
          navigate("/edit-want-ad", { state: { data: adDetails,  isFromUnPause: true } });
        }
      } else {
        await unArchiveWantAd(wantAd?.wantAdId)
          .then((response) => {
            if (response.errorCode == 0) {
              toast.dismiss();
              toast.success("Your paused want-ad has been made public again.");
              setArchiveStatus(!archiveStatus);
              setReload(reload + 1);
            } else {
              console.log("Something went wrong");
            }
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    }
  };
/**
 * To load nice to have list view 
 * @param {*} list 
 * @param {*} uniqueType 
 * @returns 
 */
  const loadNiceToHaveList = (list, uniqueType) => {
    return (
      list &&
      list.map((tl) => {
        const id = _.get(tl, "id");
        const itemText = _.get(tl, "text", "");
        const icon = _.get(tl, "icon", "");
        return (
          <div className="nicetohaves-tag" key={id + uniqueType}>
            <div className="nicetohaves-tag-icon">
              <img src={icon ? require("../images/" + icon) : LakeViewIcon} />
            </div>
            <span>{itemText}</span>
          </div>
        );
      })
    );
  };
/**
 * To handle display adTitle
 * @returns 
 */
  const loadAdTitle = () => {
    let adTypeTile = "Buy";
    if (adType === LONG_RENT) {
      adTypeTile = "Rent";
    } else if (adType === SHORT_RENT) {
      adTypeTile = "Stay";
    }
    return (
      <>
        {" "}
        <span className="want-ad-tag align-middle green-tag-bg mb-0 f-size-10">
          {adTypeTile}
        </span>
      </>
    );
  };
/**
 * To handle strengthen want ad details display
 * @returns 
 */
  const loadStrengthenDetails = () => {
    if (_.get(adDetails, "isStrengthen", "")) {
      if (adType === BUY) {
        return (
          <>
            {" "}
            {_.get(adDetails, "prchTypeTxt", "") && (
              <li>
                <span className="list-icn-wrap">
                  <img src={PrResIcon} />
                </span>
                {_.get(adDetails, "prchTypeTxt", "")}
                {(_.get(adDetails, "is1031Exchange", "") == "Yes" || _.get(adDetails, "is1031Exchange", "") == 1) &&
                  " - 1031 exchange"
                }
              </li>
            )}
            {_.get(adDetails, "finTypeTxt", "") && (
              <li>
                <span className="list-icn-wrap">
                  <img src={FinancingIcon} />
                </span>
                Financing: {_.get(adDetails, "finTypeTxt", "")}
                {(_.get(adDetails, "isPreApprLoan", "") == "Yes" ||
                  _.get(adDetails, "isPreApprLoan", "") == 1) &&
                  "  - pre approved"}
              </li>
            )}
          </>
        );
      } else if (adType === LONG_RENT) {
        return (
          <>
            {" "}
            {_.get(adDetails, "creditScoreText", "") && (
              <li>
                <span className="list-icn-wrap">
                  <img src={FinancingIcon} />
                </span>
                Credit Score : {_.get(adDetails, "creditScoreText", "")}
              </li>
            )}
          </>
        );
      }
    }
  };
/**
 * To display timeline in details
 * @returns 
 */
  const loadTimeLine = () => {
    return loadTimeLineHtml(adType, adTypeBasedOnProperty, adDetails);
  };
/**
 * To display preferred location
 * @returns 
 */
  const loadPreferredLocation = () => {
    return loadPreferredLocationHtml(adDetails);
  };
/**
 * To display musthaves section
 * @returns 
 */
  const loadAdMustHaves = () => {
    return buildWantAdMustHavesHTML({
      adType: adType,
      adTypeBasedOnProperty: adTypeBasedOnProperty,
      formValues: adDetails,
    });
  };

  /**
   * To handle the want-ad favorite/unfavorite
   */
  const handleFavoriteOrUnfavoriteWantAd = async (
    IsFavourite,
    Want_ad_Id,
    clientId = []
  ) => {
    if (Want_ad_Id) {
      let data = {
        clientId: IsFavourite == false ? clientId : null,
        wantAdId: Want_ad_Id,
      };
      await (IsFavourite == false
        ? favouriteWantAd(data)
        : unFavouriteWantAd(data)
      )
        .then((response) => {
          if (response.errorCode == 0) {
            let message = "";
            if (clientId.length > 0) {
              message =
                "Want-Ad has been favorited for " +
                clientId.length +
                (clientId.length > 1 ? " clients" : " client");
            } else {
              message =
                IsFavourite == true
                  ? "Want-Ad has been removed from your favorites"
                  : "Want-Ad has been added to your favorites";
            }

            toast.success(message);
            setModalShow(false);
            setIsFavourite(!IsFavourite);

            // updateQuery(initialQuery);
            // setCurrPage(1);
            // setAdList([]);
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  /**
   *
   */
  useEffect(() => {
    getAgentsClientsList();
  }, [sessionData?.userInfo?.userId, searchText, reloadAgents]);

  /**
   * To handle textbox value change on agent client search
   * @param {any} event
   */
  const onChangeText = (event) => {
    let value = "";
    if (event) {
      value = handleTextBoxChange(event);
    }

    setSearchText(value);
  };

  /**
   * To get agents clients list
   * @param {*} query
   */
  const getAgentsClientsList = async (query) => {
    let a = {
      search: searchText,
      sortBy: "createdOn",
      sortDir: "desc",
    };
    await getAgentsList(a)
      .then((response) => {
        const responseResult = _.get(response, "result", null);
        if (response.errorCode === 0 && responseResult) {
          setResults(responseResult);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  /**
   * to handle clients favorites action
   * @param {*} agentIds
   */
  const handleClientFavoriteWantAd = (agentIds) => {
    handleFavoriteOrUnfavoriteWantAd(isFavourite, wantAdId, agentIds);
  };

  /**
   * handle agents favorite action, to open client list popup
   */
  const handleAgentsFavoriteAction = () => {
    setModalShow(true);
  };

  const handleStrengthenClick = () => {
    const adDetails = getLocalStorage(WANT_AD_KEY);
    setLocalStorage(STRENGTHEN_WANT_AD_KEY, adDetails);
    //removeLocalStorage(WANT_AD_KEY)
    navigate(pageURLs.wantAdStrengthen);
  };

  const isNiceToHaveExist = nice2Have.length > 0;
  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      placement="bottom-start"
      className="custom-tooltip"
      {...props}
    >
      {propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY ?
        getTooltipText(propertyType)
        : propertySubTypes &&
        propertySubTypes.length > 0 &&
        getCombinedText(propertySubTypes)}
    </Tooltip>
  );
  return (
    <>
      <span ref={pageTopRef}></span>

      {skeltonLoad ? (
        <WantadDetailsSkeleton />
      ) : (
        <>
          <div className="outer-wrap position-relative mw-1168">
            <CommonHeader />
            {(isFromHomeFeed || isFromAllIntroduction || introId) && (
              <a
                role="button"
                className="bottom-back-btn mb-24 d-inline-block"
                onClick={() => navigate(-1)}
              >
                <span class="left-arrow">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                      fill="#205ECA"
                    ></path>
                  </svg>
                </span>
                Back
              </a>
            )}
            <div className="row">
              <div className="col-12 col-md-7 ps-md-3 col-lg-8 ps-lg-3 col-xl-8 ps-xl-2">
                <div className="wantad-confirmation-wrap mb-4">
                  <div className="confirmation-top-sec d-md-flex justify-content-between">
                    <div className="long-txt-fix">
                      <div class="confirm-sub-title mb-1">
                        I'm looking to:
                        {loadAdTitle()}
                      </div>
                      <h2>
                        {_.get(
                          adDetails,
                          "adTitle",
                          "Cozy home for small family"
                        )}{" "}
                      </h2>
                      <span className="trailing-txt">
                      {locationDisplay(adDetails)}
                        {/* Within {_.get(adDetails, "mapRadius", "")} mi of{" "}
                        {_.get(adDetails, "city", "")}
                        {", "}
                        {_.get(adDetails, "state", "")} */}
                      </span>
                    </div>

                    {(isFromHomeFeed || isFromAllIntroduction) &&
                      WantCreatedUserId !== sessionData?.userInfo?.userId ? (
                      <div className="social-btns-wrap">
                        <Dropdown className="mr-12">
                          <Dropdown.Toggle variant="" id="dropdown-basic1">
                            <img src={MoreIcon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item onClick={() => handleOnShare()}>
                              Share
                            </Dropdown.Item>
                            {WantCreatedUserId !==
                              sessionData?.userInfo?.userId && (
                                <Dropdown.Item
                                  onClick={() => handleReportPopup(true)}
                                >
                                  Report
                                </Dropdown.Item>
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                        <a
                          role="button"
                          href={void 0}
                          className="social-content-btn"
                        >
                          {isFavouriteStatus ? (
                            <img
                              src={HearttIconFilled}
                              onClick={() => {
                                handleFavoriteOrUnfavoriteWantAd(
                                  true,
                                  wantAdId
                                );
                              }}
                            />
                          ) : (
                            <img
                              src={HearttIcon}
                              onClick={() => {
                                sessionData?.userInfo?.userType == 2 && isPremiumUser
                                  ? handleAgentsFavoriteAction()
                                  : handleFavoriteOrUnfavoriteWantAd(
                                    false,
                                    wantAdId
                                  );
                              }}
                            />
                          )}
                        </a>
                      </div>
                    ) : (
                      <div className="social-btns-wrap">
                        <a
                          role="button"
                          className="social-content-btn mr-12"
                          onClick={() => handleOnShare()}
                        >
                          <img src={ShareIcon} />
                        </a>

                        <Dropdown>
                          <Dropdown.Toggle variant="" id="dropdown-basic1">
                            <img src={MoreIcon} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            {/* <Dropdown.Item href="#/action-1">Share</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => onEdit()}>
                              Edit
                            </Dropdown.Item>
                            {adDetails?.isArchived == 0 && (
                              <Dropdown.Item
                                onClick={() => onWantAdArchived(wantAdId)}
                              >
                                Pause
                              </Dropdown.Item>
                            )}
                            {adDetails?.isArchived == 1 && (
                              <Dropdown.Item
                                onClick={() => handleUnArchiveWantAd(adDetails)}
                              >
                                Unpause
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => onWantAdDelete()}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>

                  <div className="confirm-map-wrap">
                    <OverlayTrigger
                      placement="bottom-start"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <div className="property-tile w-auto">
                        {
                          propertyType === IS_LAND || propertyType === IS_MULTI_FAMILY ? (
                            <img
                              src={getPropertyIcon(propertyType)}
                              alt={getPropertyIcon(propertyType)}
                              className="property-icon-img"
                            />
                          ) : (
                            propertySubTypes &&
                            propertySubTypes.length > 0 &&
                            propertySubTypes.map((subtype) => {
                              return (
                                <img
                                  src={getPropertyIcon(subtype)}
                                  alt={getPropertyIcon(subtype)}
                                  className="property-icon-img"
                                />
                              );
                            })
                          )
                        }

                      </div>
                    </OverlayTrigger>
                    <img
                      src={mapImage + "?" + Math.random().toString(36)}
                      className="disp-map-img-fixed"
                    />
                  </div>
                  {loadAdMustHaves()}

                  {isNiceToHaveExist ? (
                    <div className="nicetohaves-wrap">
                      <h2>Nice-to-haves</h2>
                      <div className="nicetohaves-inner-wrap">
                        {finalNiceToHaveList()}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {_.get(adDetails, "adDesc", "") ? (
                    <div className="additional-details-wrap">
                      <h2>Description</h2>
                      <p className="long-txt-fix">
                        {_.get(adDetails, "adDesc", "")}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className={((adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&
                        _.get(adDetails, "isAgentCreated", "") == 1) && _.get(adDetails, "compenationType", "")>0 ? "buyer-info nicetohaves-wrap" : "buyer-info"}>
                    {/* <h2>{consumerType} Information</h2> */}
                    <h2>Additional details</h2>
                    <ul>
                      <li>
                        {/* ------------------------- Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}
                        {/* {
                          _.get(adDetails, "isPremiumAgent", "") == true && userType === USER_TYPE_AGENT && isPremiumUser && WantCreatedUserId !==
                          sessionData?.userInfo?.userId  ?

                            <div className="profile-pic-sec">
                              {_.get(adDetails, 'userImage', '') ?
                                <figure className="brdr-rs-200">
                                  <img className="brdr-rs-200" src={_.get(adDetails, 'userImage', '') ?? noImage} />
                                </figure>
                                :
                                <div className="profile-placeholder-wrap profile-bg-orange flex-shrink-0">
                                  {(_.get(adDetails, 'userFirstName', '') + " " + _.get(adDetails, 'userLastName', '')).replace(/-/g, "").match(/\b(\w)/g)}
                                </div>
                              }
                              <div className="profile-name">
                                <h6 className="d-flex mb-0">
                                  {_.get(adDetails, 'userFirstName', '') + " " + _.get(adDetails, 'userLastName', '')}
                                  <span class="pro-blue-badge">PRO</span>
                                </h6>
                                <p className="position mb-0">
                                  Agent @ {_.get(adDetails, "userCompany", "")}
                                </p>
                                <a role="button" onClick={agentProfileViewModalShow}>
                                  View bio
                                </a>
                              </div>
                            </div>

                            :
                            <> */}
                        {/* ------------------------- Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}

                        <span className="list-icn-wrap">
                          <img src={ConsumerIcon} />
                        </span>
                        {_.get(adDetails, "isAgentCreated", "") == 1
                          ? " Represented by Agent"
                          : consumerType} {wantAdCreatedUserPlan===INVESTOR_MEMBER_PLAN ? <span className="pro-gold-badge">Investor Member</span> : ""}
                        {/* ------------------------- Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}
                        {/* </>
                        } */}
                        {/* ------------------------- Commented on 02-03-2024 based on basecamp post Premium Agent to Premium Agent Visabile Profiles------------------- */}
                      </li>              

                      {loadTimeLine()}
                      {loadPreferredLocation()}
                      {loadStrengthenDetails()}
                    </ul>
                  </div>

                  {((adType == BUY || _.get(adDetails, "prprtyType", "") == IS_LAND) &&
                        _.get(adDetails, "isAgentCreated", "") == 1) && (_.get(adDetails, "compenationType", "")>0) && 
                        <div className="buyer-info">
                    <h2>Requested Concessions From Seller</h2>
                    <ul>                      
                      {_.get(adDetails, "compenationType", "") ?(
                          <li>
                            <span className="list-icn-wrap">
                              <img src={FinancingIcon} />
                            </span>
                            {loadCompensationText(parseInt(_.get(adDetails, "compenationType", "")),_.get(adDetails, "agentCompenation", ""))}                            
                          </li>
                        ) : ""}  
                        {_.get(adDetails, "otherConcessions", "") ?(
                          <li>
                            {_.get(adDetails, "otherConcessions", "")}                         
                          </li>
                        ) : ""}                    
                    </ul>
                  </div>}
                  {((wantAdCreatedUserPlan===INVESTOR_MEMBER_PLAN) && (purchaseType===3)) && 
                    <div className="buyer-info">
                      {/* <h2>{consumerType} Information</h2> */}
                      {_.get(adDetails, "investmentId", [])?.length>0 || _.get(adDetails, "capRateId", null) ? <h2>What This Investor is Open to</h2> : ""}
                      <ul>
                        {loadInvestmentCriteria(adDetails)}
                        {_.get(adDetails, "capRateId", null) && <li>
                          <span className="list-icn-wrap">
                            <img src={MinCapIcon} />
                          </span>
                          Minimum cap rate of {_.get(adDetails, "capRateId", "")==15 ? "15+" : _.get(adDetails, "capRateId", "")}%
                        </li>}
                      </ul>
                    </div>}
                </div>
              </div>

              {!isFromAllIntroduction && (
                <div className="col-12 col-md-5 pe-md-3 col-lg-4 pe-lg-3 col-xl-4 pe-xl-2">
                  {showStrengthen && (
                    <div className="ad-strengthen-wrap mb-4">
                      <div className="icon-wrap">
                        <img src={StrengthenIcon} alt={StrengthenIcon} />
                      </div>
                      <h4>Want to get more attention on your ad?</h4>
                      <p className="mb-4">
                        Adding additional information to your ad gives owners a
                        better idea of what you're looking for.{" "}
                      </p>
                      <Button
                        variant="primary"
                        className="w-100 mb-4"
                        onClick={() => handleStrengthenClick()}
                      >
                        Strengthen my ad
                      </Button>
                      <a
                        href={void 0}
                        role="button"
                        className="dismiss-btn d-block"
                        onClick={() => handleStrengthenDismiss()}
                      >
                        Dismiss
                      </a>
                    </div>
                  )}

                  {isFromHomeFeed &&
                    WantCreatedUserId &&
                    WantCreatedUserId != loggedInUserId &&
                    loggedInUserId ? (
                    <SendIntroduction
                      wantAdId={wantAdId}
                      WantCreatedUserName={WantCreatedUserName}
                      WantCreatedUserId={WantCreatedUserId}
                      wantAdClientId={wantAdClientId}
                      wantAdDetails={adDetails}
                    />
                  ) : isLoadIntroductionList && introductionList?.length ? (
                    <ViewWantAdIntroductions
                      introCount={introCount}
                      list={introductionList}
                      updateIntroPageOnScroll={updateIntroPageOnScroll}
                      getIntroductionDetails={getIntroductionDetails}
                    />
                  ) : (
                    <div className="ad-strengthen-wrap mb-0 mb-sm-4">
                      <div className="icon-wrap">
                        <img src={AdIntroIcon} alt={AdIntroIcon} />
                      </div>
                      <h6>
                        When you receive a new introduction you'll see it here
                      </h6>
                      <p className="mb-2">
                        Start a message thread by either sending or responding
                        to an introduction on a want ad
                      </p>
                      {/* <Button variant="primary" className="w-100 mb-0" disabled>
                          View all
                        </Button> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {showShareModal && <ShareYourAdModal
            show={showShareModal}
            onHide={handleClose}
            adDetails={adDetails}
            AdType={_.get(adDetails, "adType", "") === "BUY" ? 1 : 2}
            isWantAdCreated={isWantAdCreated}
            isAdTypeIsLand={isAdTypeIsLand}
            adTypeBasedOnProperty={adTypeBasedOnProperty}
          />}

          <ShareMyWantAdSmallModal
            show={showSuccessModal}
            onHide={handleSuccessModalClose}
            showShareModalPopup={showShareModalPopup}
          />
          <ReportWantAdModal
            show={showReportModal}
            wantAdId={wantAdId}
            onHide={() => handleReportPopup(false)}
          />
          {introDetails.length > 0 && (
            <ViewIntroductionModal
              details={introDetails?.[0]}
              show={showViewIntroductionModal}
              onHide={handleIntroDetailModal}
            />
          )}
          {/*  Add to client favorite Modal */}
          <AddToClientWantAdModal
            show={modalShow}
            showAddToClientWantAdModal={() => setModalShow(true)}
            onHide={() => setModalShow(false)}
            data={results}
            handleSkip={() =>
              handleFavoriteOrUnfavoriteWantAd(isFavourite, wantAdId)
            }
            handleConfirm={handleClientFavoriteWantAd}
            searchText={searchText}
            onChangeText={(e) => onChangeText(e)}
            onAddClient={() => setReloadAgents(reloadAgents + 1)}
          />
          <DeleteModal
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
            title={"Are you sure you want to permanently delete your want-ad?"}
            message={
              "Deleting this ad will stop any further introductions from being sent to you."
            }
            buttonText={" Delete Want-Ad"}
            handleConfirm={() => handleDeleteWantAd()}
          />
          <ArchiveModal
            show={archiveModalShow}
            onHide={() => setArchiveModalShow(false)}
            handleConfirm={() => handleArchiveWantAd()}
          />
          <AgentProfileViewModal
            show={showAgentProfileViewModal}
            onClose={agentProfileViewModalClose}
            userId={_.get(adDetails, 'userId', '')}
            isTitleShow={true}
          />
        </>
      )
      }
    </>
  );
};

export default WantadDetails;
