/**
 * @file   src\components\Common\PreLauchCityRequestModal.js
 * @brief  This file is responsible for popup showing when searched location is in Pre Launch
 * @date   June, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import TextBox from "../Common/TextBox";
import { emailValidation } from "../../helpers/validations";
import { removeLocalStorage } from "../../helpers/common";
import { savePreLaunchInterest } from "../../services/userprofileService";
import { toast } from "react-toastify";

const PreLaunchCityRequestModal = (props) => {
  const {setUserLocation, userLocation, isMobileView, PreLaunchStateName} = props;
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState("");

  

  const handleEmailChange = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    // validate(e.target.value);
  };
  const validate = (mail) => {
    let isValid = true;
    setEmailError("");
    if (mail.trim() === "") {
      isValid = false;
      setEmailError("Enter Email");
    } else if (!emailValidation(mail.trim())) {
      isValid = false;
      setEmailError("Enter Valid email");
    } 
    return isValid;
  };

  const handleCancel =() =>{
    setEmailError("");
    removeLocalStorage("searchedLocation");
    setUserLocation(null);
    setEmail("");
    props.onHide();

  } 

  const handleSubmit = async () => {
    const isValid = validate(email);
    if(isValid){
      const payload = {
        email: email,
        city : userLocation?.city,
        state : userLocation?.state
      }
      await savePreLaunchInterest(payload).then((response)=>{
        if(response.errorCode===0){     
          toast.success(response?.message)   
          removeLocalStorage("searchedLocation");
          setUserLocation(null);
          props.onHide();
        }
        else{
          toast.error(response.message)
        }
      }).catch((error)=>{
        toast.error(error)
      })
    }
   }

  return (
    <>   
    <Modal
      show
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-settings"
      backdrop='static'
    >
      <Modal.Body className="pt-32 pb-32 modalPadding">
      {isMobileView ? "" : <a
            role="button"
            href={void 0}
            className="bottom-back-btn"
            onClick={() => {
              handleCancel();
            }}
          >
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.73359 3.59957C6.92886 3.79483 6.92886 4.11142 6.73359 4.30668L3.54048 7.49979H13.6667C13.9429 7.49979 14.1667 7.72365 14.1667 7.99979C14.1667 8.27593 13.9429 8.49979 13.6667 8.49979H3.54048L6.73359 11.6929C6.92886 11.8882 6.92886 12.2048 6.73359 12.4C6.53833 12.5953 6.22175 12.5953 6.02649 12.4L1.97982 8.35335C1.88605 8.25958 1.83337 8.1324 1.83337 7.99979C1.83337 7.86718 1.88605 7.74001 1.97982 7.64624L6.02649 3.59957C6.22175 3.40431 6.53833 3.40431 6.73359 3.59957Z"
                  fill="#205ECA"
                />
              </svg>
            </span>
            Back
          </a>}
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={handleCancel}
        ></button>
        {isMobileView ? <>  
          <h4 className="text-center mt-4 fntModalHead-mob">{PreLaunchStateName} Agents, <br/>get ready and tell your friends...<br/>
          The Buyer Listing Service<sup className="trademark-symbol-mob ">TM</sup>  <br/> is launching soon! 🚀 </h4>
          <p className="f-size-16 mb-4 pt-3 text-center">Would you like to access to our pre-launch? We’re providing an exclusive concierge service to a select group of early adopters & top teams in the area. Enter your email if you’re interested:</p>
        </> 
        : 
        <>  
          <h4 className="text-start mt-4 fntModalHead">{PreLaunchStateName} Agents, get ready and tell your friends...<br/>
          The Buyer Listing Service<sup className="trademark-symbol-mob ">TM</sup>  is launching soon! 🚀 </h4>
          <p className="f-size-16 mb-4   text-start">Would you like to access to our pre-launch? We’re providing an exclusive concierge service to a select group of early adopters & top teams in the area. Enter your email if you’re interested:</p>
        </>}
        <Row>
          <Col xl={12}>
            <div className="textbox-wrap mb-3 mb-sm-3">
              <TextBox
                    id="mailId"
                    name="emailId"
                    value={email}
                    type="email"
                    placeholder="Email address"
                    className="form-control"
                    onChange={(e)=>handleEmailChange(e)}
                    errorClass = "error-validation-msg"
                    errorMessage={emailError}
                />
            </div>
          </Col>
        </Row>

        <div className="modal-btn-wrap">
            <Button variant="" className={isMobileView ? "btn btn-primary btnPadding-mob" : "btn btn-primary mt-2"} onClick={()=>handleSubmit()}>Submit</Button>
            {isMobileView ? <button
                    type="button"
                    onClick={() => handleCancel()}
                    class="btn btn-no-bg btn-no-bg-fix btn-no-bg-black"
                  >
                    Back
                  </button> : <></>}
        </div>
      </Modal.Body>      
    </Modal>
    </>
  );
};
export default PreLaunchCityRequestModal;
