import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Button } from "react-bootstrap";
import EmailResentModal from "./EmailResentModal";
import _ from "lodash";
import { resendEmail } from "../../../services/signupService";

const CheckResetEmailModal = (props) => {
  const { email, show, process, onHide } = props;
  const [showResentPopup, setResentPopup] = useState(false);
  const [showCheckEmailPopup, setShowCheckEmailPopup] = useState(true);
  const [counter, setCounter] = useState(60);
  const isFromSettings = process === "ResetPwdFromSetting"

  const resentEmail = () => {
    const userInfo = {
      Email: email,
      Process: process,
    };
   
    resendEmail(userInfo).then((response) => {
      if (response) {
        if (_.get(response, "errorCode", false) === 0) {
          showResetSuccessPopup();
        }
      }
    });
  };

  useEffect(() => {
    if(show){
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
   
  }, [counter, show]);

  const showResetSuccessPopup = () => {
    
    setShowCheckEmailPopup(false);
    setResentPopup(true);
  };

  return (
    <>
      <Modal
        show={show}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/*<Modal.Header closeButton>
        
      </Modal.Header>*/}
        <Modal.Body className="pt-32 pb-32">
          {isFromSettings &&   <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>onHide({modalState: false})}
        ></button> }
        
          <button type="button" class="btn-close" aria-label="Close" onClick={()=>onHide({modalState: false})}></button>
          <h2 className="text-center">Check your {isFromSettings ? "inbox": "email"} </h2>

          <Row>
            <Col xl={12}>
              <p className="mb-2 text-center">
                We've sent a reset link to{" "}
                <strong>{email}.</strong>
              </p>
              {isFromSettings ? <p className="text-center">
                Didn't get an email?{" "}
                <strong>
                  <a href="#" onClick={()=>resentEmail()}>
                    {" "}
                    Resend email.
                  </a>
                  {/* {isLogin ? <a href="#">Let's try resending it.</a> : <a href="#">Resend email.</a>} */}
                </strong>
              </p>
              : counter === 0 ?  <p className="text-center">
              Didn't get an email?{" "}
              <strong>
                <a href="#" onClick={()=>resentEmail()}>
                  {" "}
                  Let's try resending it.
                </a>
                {/* {isLogin ? <a href="#">Let's try resending it.</a> : <a href="#">Resend email.</a>} */}
              </strong>
            </p>: <p className="text-center">Resend  <strong>{counter} seconds.</strong> </p>}
             
            
            </Col>
          </Row>
        </Modal.Body>
        {/*<Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>*/}
      </Modal>
      <EmailResentModal
        show={showResentPopup}
        centered
        email={email}
        
      ></EmailResentModal>
    </>
  );
};
export default CheckResetEmailModal;
